import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import { ObjectType } from '../masterView/common';
import { Theme, Grid, FormLabel } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

interface ToggleGroupProps {
    alignment: string;
    handleChange: (event: any, newAlignment: string) => void;
    classes?: any;
    toggleOptions: ObjectType[];
    groupLabel?: string;
    required?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerButton: {
            marginLeft: 24,
            marginTop: 10
        },
        button: {
            backgroundColor: "#f7fafc",
            fontSize: "12px",
            fontWeight: 400,
            height: "35px",
            borderWidth: "2px",
            borderColor: "#E5E4E2",
            padding: "10px 20px",
            letterSpacing: "0.5px",
            color: theme.palette.text.secondary,
            "&.$Mui-selected": {
                backgroundColor: "#5e72e4 !important",
                fontWeight: "600 !important",
                color: "#fff !important"
            },
            "&:disabled": {
                color: "#32325d",
                opacity: 0.5,
                fontWeight: 600,
                cursor: "no-drop",
                borderColor: "#d8d5d5 !important",
            },
            textTransform: "none"
        },
        formLabel: {
            paddingBottom: "0.8rem",
            fontSize: "12px",
            fontWeight: "normal"
        }
    }));

export const ToggleGroup = (props: ToggleGroupProps) => {
    const { alignment, handleChange, classes, toggleOptions, groupLabel, required } = props;
    let toggleGroupClasses = useStyles();
    toggleGroupClasses = classes || toggleGroupClasses;
    return (
        <Grid>
            {groupLabel && <div>
                <FormLabel component="div" required={required} className={classes.formLabel}>{groupLabel}</FormLabel>
            </div>}
            <ToggleButtonGroup
                value={alignment}
                onChange={handleChange}
                exclusive
                className={toggleGroupClasses.headerButton}
            >
                {toggleOptions.map((toggleOption: ObjectType) => (
                    <ToggleButton key={toggleOption.value} disabled={toggleOption?.disabled} value={toggleOption.value} className={toggleGroupClasses.button} classes={classes}>
                        {toggleOption.label}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </Grid>
    )
}