import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Header } from "../../create-loan/home/Header";
import { setTabView } from "../../stores/SettlementAndPurchase/SettlementAndPurchase.reducer";
import { isOrigUser } from "../../utils/AccessManagement";
import { DrawDetails } from "./DrawDetails";
import { useDrawsStyles } from "./Draws.styles";
import { RootState } from "../../stores/rootReducer";
import {
  DrawStage,
  WarningModal,
  WarningModalProps
} from "@toorak/tc-common-fe-sdk";
import { DrawRequestStore } from "../../stores/drawRequest/drawRequest.interface";
import {
  getFieldHistory,
  getDrawDetailsByDrawId
} from "../../stores/drawRequest/drawRequest.action";
import { isBulkDrawViewEnabled } from "./DrawDetailsConstants";
import { updateDrawExcelData } from "../../stores/drawRequest/drawRequest.reducer";

export const BulkDrawDetails = () => {
  const navigate = useNavigate();
  const classes = useDrawsStyles();
  const dispatch = useDispatch();
  const isOrig = isOrigUser();
  const { drawId } = useParams<any>() as any;
  const [extractedDraws, setExtractedDraws] = useState<any>([]);
  const [isBulkDraw, setIsBulkDraw] = useState(false);
  const [openWarning, setOpenWarning] = useState<WarningModalProps | null>(
    null
  );
  let { excelData, drawDetails } = useSelector<RootState, DrawRequestStore>(
    (state) => state.drawRequestStore
  );
  const { stage } = drawDetails;
  const { draft, drawSubmitted, DrawInQueue } = DrawStage;
  useEffect(() => {
    if (isBulkDrawViewEnabled() && isOrig) {
      setIsBulkDraw(true);
    }
    //eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (excelData.length) {
      setExtractedDraws(excelData);
    } else {
      const excelUploadDataInSession =
        sessionStorage.getItem("drawExcelUpload");
      if (excelUploadDataInSession) {
        const excelUploadData = JSON.parse(excelUploadDataInSession);
        setExtractedDraws(excelUploadData);
      }
    }
    //eslint-disable-next-line
  }, [excelData]);

  useEffect(() => {
    if (!drawId) return;
    dispatch(getDrawDetailsByDrawId(drawId, isOrig));
  }, [drawId, dispatch, isOrig]);

  useEffect(() => {
    if (isBulkDrawViewEnabled() && isOrig) {
      const excelUploadDataInSession =
        sessionStorage.getItem("drawExcelUpload");
      if (excelUploadDataInSession) {
        const excelUploadData = JSON.parse(excelUploadDataInSession);
        const drawIndex = excelUploadData.findIndex(
          (item: { drawId: string }) => item.drawId.toString() === drawId
        );

        excelUploadData[drawIndex] = { ...excelUploadData[drawIndex], stage };
        sessionStorage.setItem(
          "drawExcelUpload",
          JSON.stringify(excelUploadData)
        );
        dispatch(updateDrawExcelData(excelUploadData));
      }
    }
  }, [stage, drawId, isOrig, dispatch]);

  useEffect(() => {
    if (isBulkDrawViewEnabled() && isOrig) {
      dispatch(getFieldHistory(drawId, isOrig));
    }
  }, [drawId, isOrig, dispatch]);

  const handleSelectedDrawChange = (index: number) => {
    let tempObj = JSON.parse(JSON.stringify(extractedDraws));
    const drawIndex = tempObj.findIndex((x: any) => x.selected === true);

    if (drawIndex !== index) {
      if (stage !== drawSubmitted) {
        setOpenWarning({
          isOpen: true,
          header: "Warning",
          handleOptional: () => {
            setOpenWarning(null);
          },
          body: "Are you sure you want to leave? Click on Save button to retain the progress.",
          primaryBtnName: "Okay",
          handleClose: () => {
            tempObj.forEach((element: any) => {
              element.selected = false;
            });
            tempObj[index].selected = true;
            setExtractedDraws(tempObj);
            sessionStorage.setItem("drawExcelUpload", JSON.stringify(tempObj));
            setOpenWarning(null);
            navigate(`/drawDetails/${tempObj[index].drawId}`);
          }
        });
      } else {
        tempObj.forEach((element: any) => {
          element.selected = false;
        });
        tempObj[index].selected = true;
        setExtractedDraws(tempObj);
        sessionStorage.setItem("drawExcelUpload", JSON.stringify(tempObj));
        navigate(`/drawDetails/${tempObj[index].drawId}`);
      }
    }
  };
  const getDrawCount = (type: string) => {
    let filteredDraw: any = [];
    filteredDraw = extractedDraws.filter((key: any) => key.stage === type);
    return filteredDraw.length;
  };
  return (
    <Grid item container xs={12} sm={12} md={12} xl={12}>
      <Grid item container xs={12} sm={12} md={12} xl={12}>
        <Header
          value={isOrig ? 8 : 3}
          setTabValue={setTabView}
          showTab={true}
          showNotificationIcon={false}
        />
        <Grid item container xs={12} sm={12} md={12} xl={12}>
          {isBulkDraw ? (
            <Grid item container xs={12} sm={12} md={12} xl={12}>
              <Grid
                item
                container
                xs={12}
                sm={3}
                md={2}
                xl={2}
                className={classes.excelDraw}
              >
                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  className={classes.excelContainer}
                >
                  <div className={classes.countContainer}>
                    <p className={classes.drawTitle}>
                      <span className={classes.drawCount}>
                        {extractedDraws.length}
                      </span>
                      Draw Requests
                    </p>
                  </div>
                  <div className={classes.individualCount}>
                    <div className={classes.draftContainer}>
                      DRAFT ({getDrawCount(draft)})
                    </div>
                    <div className={classes.submittedContainer}>
                      DRAW SUBMITTED ({getDrawCount(drawSubmitted)})
                    </div>
                    <div className={classes.submittedContainer}>
                      DRAW IN QUEUE ({getDrawCount(DrawInQueue)})
                    </div>
                  </div>
                  {extractedDraws?.length > 0 &&
                    extractedDraws.map((item: any, index: number) => (
                      <div
                        className={
                          !item.selected
                            ? classes.drawInfo
                            : [classes.drawInfo, classes.drawInfoSelected].join(
                                " "
                              )
                        }
                        onClick={(e) => handleSelectedDrawChange(index)}
                      >
                        <p>{item.drawId}</p>
                        <p>Primary Loan Id</p>
                        {item.selected && (
                          <div
                            className={classes.selected}
                            data-testid="selected-loan"
                          />
                        )}
                        <p>{item.primaryLoanId}</p>
                      </div>
                    ))}
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={9}
                md={10}
                xl={10}
                className={classes.drawView}
              >
                <DrawDetails
                  drawId={drawId}
                  isBulkDraw={isBulkDraw}
                ></DrawDetails>
              </Grid>
            </Grid>
          ) : (
            <DrawDetails drawId={drawId} isBulkDraw={isBulkDraw}></DrawDetails>
          )}
        </Grid>
      </Grid>
      {openWarning && (
        <WarningModal
          isOpen={openWarning.isOpen}
          handleClose={openWarning.handleClose}
          header={openWarning.header}
          handleOptional={openWarning.handleOptional}
          body={openWarning.body}
          primaryBtnName={openWarning.primaryBtnName}
        />
      )}
    </Grid>
  );
};
