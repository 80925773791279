/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from "@reduxjs/toolkit";
// import { produce } from "immer";
// import {
//   LOAN_COUNTER_INCREMENT,
//   LOAN_COUNTER_DECRERMENT,
//   CLEAR_LOADER,
//   STORE_API_ERROR,
//   CLEAR_ERROR
// } from "./loaderAndException.action";

export interface LoaderAndExceptionsStore {
  higherOrderLoaderCount: number;
  loaderContext: string[];
  loaderCount: number;
  apiErrors: any[];
}
const initialState: LoaderAndExceptionsStore = {
  loaderCount: 0,
  higherOrderLoaderCount: 0,
  apiErrors: [],
  loaderContext: []
};

const loaderAndExceptionSlice = createSlice({
  name: "loaderAndException",
  initialState,
  reducers: {
    loanCounterIncrement: (state, action) => {
      const { higherOrderLoader, context } = action.payload;
      if (higherOrderLoader) {
        state.higherOrderLoaderCount += 1;
      } else {
        state.loaderCount += 1;
      }
      if (context) {
        state.loaderContext.push(context);
      }
    },
    loanCounterDecrement: (state, action) => {
      const { higherOrderLoader, context } = action.payload;
      if (higherOrderLoader) {
        state.higherOrderLoaderCount =
          state.higherOrderLoaderCount > 0
            ? state.higherOrderLoaderCount - 1
            : 0;
      } else {
        state.loaderCount = state.loaderCount > 0 ? state.loaderCount - 1 : 0;
      }
      if (context) {
        state.loaderContext = state.loaderContext.filter(
          (ele) => ele !== context
        );
      }
    },
    clearLoader: (state) => {
      state.higherOrderLoaderCount = 0;
      state.loaderCount = 0;
      state.loaderContext = [];
    },
    storeApiError: (state, action) => {
      const { errorConfigToSave } = action.payload;
      if (
        errorConfigToSave.errorData?.config?.errorConfig
          ?.dontSkipIfAlreadyPresent
      ) {
        state.apiErrors = [...state.apiErrors, errorConfigToSave];
      } else {
        const { apiErrors } = state;
        const isErrorAlreadyPresent = apiErrors.find(
          (item) =>
            item.errorData.config.url ===
            errorConfigToSave.errorData?.config?.url
        );
        if (!isErrorAlreadyPresent) {
          state.apiErrors = [...state.apiErrors, errorConfigToSave];
        }
      }
    },
    clearError: (state, action) => {
      const { errorId, autoClose, closeAll } = action.payload;
      if (closeAll) {
        state.apiErrors = [];
      } else {
        if (autoClose && state.apiErrors.length > 1) {
          return;
        }
        state.apiErrors = state.apiErrors.filter((ele) => ele.id !== errorId);
      }
    }
  }
});

export const {
  loanCounterIncrement,
  loanCounterDecrement,
  clearLoader,
  storeApiError,
  clearError
} = loaderAndExceptionSlice.actions;

export const LoaderAndExceptionReducer = loaderAndExceptionSlice.reducer;

// export const LoaderAndExceptionReducer = (
//   state = initialState,
//   action: any
// ) => {
//   switch (action.type) {
//     /** Loading section starts here */
//     case LOAN_COUNTER_INCREMENT: {
//       const { higherOrderLoader, context } = action.payload;
//       const nextState = produce(state, draftState => {
//         if (higherOrderLoader) {
//           draftState.higherOrderLoaderCount += 1;
//         } else {
//           draftState.loaderCount += 1;
//         }
//         if (context) {
//           draftState.loaderContext.push(context);
//         }
//       });
//       return nextState;
//     }
//     case LOAN_COUNTER_DECRERMENT: {
//       const { higherOrderLoader, context } = action.payload;
//       const nextState = produce(state, draftState => {
//         if (higherOrderLoader) {
//           draftState.higherOrderLoaderCount =
//             draftState.higherOrderLoaderCount > 0
//               ? draftState.higherOrderLoaderCount - 1
//               : 0;
//         } else {
//           draftState.loaderCount =
//             draftState.loaderCount > 0 ? draftState.loaderCount - 1 : 0;
//         }
//         if (context) {
//           draftState.loaderContext = draftState.loaderContext.filter(
//             ele => ele !== context
//           );
//         }
//       });
//       return nextState;
//     }
//     case CLEAR_LOADER: {
//       const nextState = produce(state, draftState => {
//         draftState.higherOrderLoaderCount = 0;
//         draftState.loaderCount = 0;
//         draftState.loaderContext = [];
//       });
//       return nextState;
//     }
//     case STORE_API_ERROR: {
//       const { errorConfigToSave } = action.payload;
//       const nextState = produce(state, draftState => {
//         if (
//           errorConfigToSave.errorData?.config?.errorConfig
//             ?.dontSkipIfAlreadyPresent
//         ) {
//           draftState.apiErrors = [...draftState.apiErrors, errorConfigToSave];
//         } else {
//           const { apiErrors } = state;
//           const isErrorAlreadyPresent = apiErrors.find(
//             item =>
//               item.errorData.config.url ===
//               errorConfigToSave.errorData?.config?.url
//           );
//           if (!isErrorAlreadyPresent) {
//             draftState.apiErrors = [...draftState.apiErrors, errorConfigToSave];
//           }
//         }
//       });
//       return nextState;
//     }
//     case CLEAR_ERROR: {
//       const { errorId, autoClose, closeAll } = action.payload;
//       const nextState = produce(state, draftState => {
//         if (closeAll) {
//           draftState.apiErrors = [];
//         } else {
//           if (autoClose && draftState.apiErrors.length > 1) {
//             return;
//           }
//           draftState.apiErrors = draftState.apiErrors.filter(
//             ele => ele.id !== errorId
//           );
//         }
//       });
//       return nextState;
//     }
//     default:
//       return state;
//   }
// };
