/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox, Divider, List, ListItem, ListItemIcon, ListItemText, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from "react";

interface ListOptions {
  id?: string;
  key: string;
  isDisabled?: boolean;
  label?: string;
  viewText?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuList: {
      padding: 0
    },
    listIconRoot: {
      minWidth: 20,
      position: "relative",
      paddingRight: 13,
      left: 10,
      "& .MuiSvgIcon-root": {
        width: 18,
        height: 18
      }
    }
  })
);

export const LoanTypeFilter = (props: any) => {
  const {
    aggregatedOptions,
    classes,
    handleListKeyDown,
    handleCheckBoxToggle,
    selected
  } = props;
  const defaultStyles = useStyles();
  return (
    <div>
      {aggregatedOptions.map((item: any) => {
        return (
          <>
            <div style={item?.style}>
              <List
                onKeyDown={handleListKeyDown}
                className={`${classes.menuList || defaultStyles.menuList}`}
              >
                {item.options?.map((value: ListOptions) => {
                  return (
                    <ListItem
                      key={Math.random()}
                      onClick={handleCheckBoxToggle}
                      disableGutters
                      dense
                      button
                      id={value.key}
                      data-type={item.id}
                      style={{ paddingLeft: "6px" }}
                      disabled={value.isDisabled}
                    >
                      <ListItemIcon
                        classes={{
                          root:
                            classes.listIconRoot || defaultStyles.listIconRoot
                        }}
                      >
                        <Checkbox
                          color="primary"
                          size="small"
                          checked={selected?.[item.id]?.includes(value.key)}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={value.viewText || value.label || value.key}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </div>
            <Divider />
          </>
        );
      })}
    </div>
  );
};
