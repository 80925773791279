import { Box, Button, Grid, TextField } from "@mui/material";
import React from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import {
  addProvision,
  deleteProvision,
  getProvisionHistory,
  updateElasticSearchResponse,
  updateProvision
} from "../../../stores/drawRequest/drawRequest.action";
import { useDrawsStyles } from "../Draws.styles";
import { Header } from "../../../create-loan/home/Header";
import { setTabView } from "../../../stores/SettlementAndPurchase/SettlementAndPurchase.reducer";
import { ProvisionHistory } from "./ProvisionHistory";

const AddProvisionView = () => {
  const navigate = useNavigate();
  const classes = useDrawsStyles();
  const dispatch = useDispatch();
  const redirectToProvisionView = () => {
    navigate("/drawDetails/provision");
  };

  const [provisionHistory, setProvisionHistory] = useState<any>([]);

  const [provisionVal, setProvisionVal] = useState<any>("");

  const [loanDetails, setLoanDetails] = useState<any>();

  // const [isMinimizedProvision, setIsMinimizedProvision] = useState<boolean>(
  //   false
  // );

  const { loanId } = useParams<any>() as any;
  const handleChange = (val: any) => {
    setProvisionVal(val);
  };

  const provisionHistorycallBack = (data: any, primaryLoanId: string) => {
    setProvisionHistory(data);
    let value: string = "No";
    if (0 !== data.length) {
      value = "Yes";
    }
    dispatch(updateProvision(primaryLoanId, value));
  };

  useEffect(() => {
    const callBack = (response: any) => {
      // if (!response[0]) {
      //   console.error("error occured in view", "No proper response");
      //   return;
      // }
      const originatorLoanId = response[0]
        ? response[0]["_source"]?.["loan"]?.["loanInfo"]?.["primaryLoanId"]
        : loanId;
      const toorakLoanId = response[0]
        ? response[0]["_source"]?.["loan"]?.["loanDetailId"]?.["toorakLoanId"]
        : "NA";
      const originatorName =
        response[0]?.["_source"]?.["originator"]?.["accountName"];
      setLoanDetails({
        originatorLoanId: originatorLoanId,
        toorakLoanId: toorakLoanId,
        originatorName: originatorName
      });
      dispatch(getProvisionHistory(loanId, provisionHistorycallBack));
    };
    dispatch(updateElasticSearchResponse(loanId, callBack));
    // eslint-disable-next-line
  }, [loanId]);

  const editCallback = () => {
    dispatch(
      getProvisionHistory(
        loanDetails.originatorLoanId,
        provisionHistorycallBack
      )
    );
  };

  const editProvision = (
    originatorLoanId: any,
    toorakLoanId: any,
    provisionVal: any,
    provisionId: any
  ) => {
    dispatch(
      addProvision(
        originatorLoanId,
        toorakLoanId,
        provisionVal,
        provisionId,
        editCallback
      )
    );
  };

  const deleteCallback = () => {
    dispatch(
      getProvisionHistory(
        loanDetails.originatorLoanId,
        provisionHistorycallBack
      )
    );
  };
  const delProvision = (provisionId: any) => {
    dispatch(deleteProvision(provisionId, deleteCallback));
  };
  return (
    <>
      <Header
        value={3}
        setTabValue={setTabView}
        showTab={true}
        showNotificationIcon={false}
      />
      <Grid item container xs={12} className={classes.provisionHeader}>
        <Grid
          item
          container
          xs={6}
          sm={6}
          md={8}
          xl={8}
          style={{ marginTop: "10px" }}
        >
          <Box
            className="button-typography-wrapper-left"
            style={{ padding: "4px 0px", marginLeft: "20px" }}
          >
            <Button
              variant="contained"
              data-testid="back-button"
              color="primary"
              startIcon={<KeyboardArrowLeftIcon />}
              onClick={redirectToProvisionView}
            >
              Back
            </Button>
          </Box>
          <span
            className={classes.drawHeaderDetails}
            style={{ marginTop: "10px" }}
          >
            <span className={classes.itemHeaderText}>
              Primary Loan Id: {loanId}
            </span>
          </span>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        data-testid="draw-view-itemContainer"
        className={classes.itemContainer}
      >
        <Grid item container xs={10} className={classes.itemHeaderText}>
          <h5 className={classes.itemHeaderText}>Existing Provisions</h5>
        </Grid>
        {/* <Grid
          item
          container
          xs={2}
          sm={2}
          md={2}
          xl={2}
          className={classes.expandCollapse}
        >
          <ExpandCollapse
            isMinimize={isMinimizedProvision}
            clicked={() => {
              setIsMinimizedProvision(!isMinimizedProvision);
            }}
            keyDown={() => {
              setIsMinimizedProvision(!isMinimizedProvision);
            }}
          />
        </Grid> */}
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={12}
          xl={12}
          data-testid="draw-view-fieldContainer"
          className={classes.fieldContainer}
        >
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            xl={12}
            data-testid="draw-view-primary-loan"
            className={classes.fieldRow}
          >
            {provisionHistory && (
              <ProvisionHistory
                historyData={provisionHistory}
                actionReq={true}
                editProvision={editProvision}
                delProvision={delProvision}
              />
            )}
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          className={classes.itemHeaderText}
          data-testid="draw-view-itemHeaderText"
        >
          <TextField
            variant="filled"
            value={provisionVal}
            placeholder="Enter Provision"
            onChange={(e) => handleChange(e.target.value)}
            style={{
              width: "100%",
              border: "1px solid #000000",
              marginTop: "40px"
            }}
          />
        </Grid>
        <Grid item container xs={12} data-testid="draw-view-itemHeaderText">
          <Button
            variant="contained"
            color="primary"
            disabled={provisionVal === "" || loanDetails.toorakLoanId === "NA"}
            classes={{ root: classes.provisionButton }}
            onClick={() => {
              if (
                loanId &&
                loanDetails?.originatorLoanId &&
                provisionVal !== ""
              ) {
                dispatch(
                  addProvision(
                    loanDetails.originatorLoanId,
                    loanDetails.toorakLoanId,
                    provisionVal,
                    false,
                    () => {
                      dispatch(
                        getProvisionHistory(
                          loanDetails.originatorLoanId,
                          provisionHistorycallBack
                        )
                      );
                    }
                  )
                );
                setProvisionVal("");
              }
            }}
          >
            Add
          </Button>
        </Grid>
        <Grid
          item
          container
          xs={12}
          className={classes.itemHeaderText}
          style={{ marginTop: "40px" }}
          data-testid="draw-view-itemHeaderText"
        >
          Loan Details
        </Grid>
        <Grid
          item
          container
          xs={12}
          data-testid="draw-view-fieldContainer"
          className={classes.fieldContainer}
        >
          <Grid item container xs={12}>
            <Grid
              item
              container
              xs={5}
              data-testid="draw-view-primary-loan"
              className={classes.fieldRow}
            >
              <div className={classes.itemBox}>
                <p
                  className={classes.itemSubHeaderText}
                  data-testid="draw-primary-loan-id"
                >
                  Toorak Loan Id
                </p>
                {loanDetails?.toorakLoanId ? (
                  <p
                    className={classes.itemValueText}
                    data-testid="originator-loanId-value"
                  >
                    {loanDetails.toorakLoanId}
                  </p>
                ) : (
                  <p
                    className={classes.itemValueText}
                    data-testid="originator-loanId-blank"
                  >
                    __ __
                  </p>
                )}
              </div>
            </Grid>
            <Grid
              item
              container
              xs={5}
              data-testid="draw-view-primary-loan"
              className={classes.fieldRow}
            >
              <div className={classes.itemBox}>
                <p
                  className={classes.itemSubHeaderText}
                  data-testid="draw-primary-loan-id"
                >
                  Primary Loan Id
                </p>
                {loanDetails?.originatorLoanId ? (
                  <p
                    className={classes.itemValueText}
                    data-testid="originator-loanId-value"
                  >
                    {loanDetails.originatorLoanId}
                  </p>
                ) : (
                  <p
                    className={classes.itemValueText}
                    data-testid="originator-loanId-blank"
                  >
                    __ __
                  </p>
                )}
              </div>
            </Grid>
            <Grid
              item
              container
              xs={2}
              data-testid="draw-view-primary-loan"
              className={classes.fieldRow}
            >
              <div className={classes.itemBox}>
                <p
                  className={classes.itemSubHeaderText}
                  data-testid="draw-primary-loan-id"
                >
                  Originator Name
                </p>
                {loanDetails?.originatorName ? (
                  <p
                    className={classes.itemValueText}
                    data-testid="originator-loanId-value"
                  >
                    {loanDetails.originatorName}
                  </p>
                ) : (
                  <p
                    className={classes.itemValueText}
                    data-testid="originator-loanId-blank"
                  >
                    __ __
                  </p>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AddProvisionView;
