/* eslint-disable @typescript-eslint/camelcase */
import React from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  IconButton
} from "@mui/material";
import {withStyles} from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import MuiDialogTitle from "@mui/material/DialogTitle";
import { useStyles } from "../documents/DocumentsForm.style";

export const ShowDialog = (props: any) => {
  const { dialogdetails, setOpenDialog, openDialog } = props;
  let isClicked = false;
  const { head, btnText, contentText, btnFunction } = dialogdetails;
  const classes = useStyles();
  const {
    dialogDeleteButton,
    dialogActionArea,
    fss_normal,
    fwss_normal,
    dialogCancelBtn,
    wh_16,
    dialogHead,
    dialogText
  } = classes;
  const styles = (theme: {
    spacing: {
      (arg0: number): void;
      (arg0: number): void;
      (arg0: number): void;
    };
    palette: { grey: any[] };
  }) => ({
    root: {
      margin: 0,
      paddingLeft: "20px",
      width: "500px",
      padding: theme.spacing(2)
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });
  const DialogTitle = withStyles(styles as any)(
    // eslint-disable-next-line no-shadow
    (props: {
      [x: string]: any;
      children: any;
      classes: any;
      onClose: any;
    }) => {
      // eslint-disable-next-line no-shadow
      const { children, classes, onClose, ...other } = props;
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <MuiDialogTitle  className={classes.root} {...other}>
          <Typography variant="h6" className={`${dialogHead} ${fss_normal}`}>
            {children}
          </Typography>
          {onClose ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
              size="large"
            >
              <CloseIcon className={wh_16} />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
      );
    }
  );

  return (
    <Dialog
      onClose={() => setOpenDialog(false)}
      aria-labelledby="customized-dialog-title"
      open={openDialog}
      test-dataid="dialog"
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={() => setOpenDialog(false)}
      >
        {head}
      </DialogTitle>
      <DialogContent>
        <Typography gutterBottom className={`${dialogText} ${fwss_normal}`}>
          <span>{contentText}</span>
        </Typography>
      </DialogContent>
      <DialogActions className={dialogActionArea}>
        <Button
          variant="contained"
          color="primary"
          className={dialogDeleteButton}
          onClick={() => {
            if (!isClicked) btnFunction();
            isClicked = true;
          }}
          data-testid="delete-confirm-btn"
        >
          {btnText}
        </Button>
        {dialogdetails.isCancelDisable ? null : (
          <Button
            autoFocus
            onClick={() => setOpenDialog(false)}
            color="primary"
            className={`${dialogCancelBtn} ${dialogDeleteButton}`}
          >
            Cancel
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
