import React from "react";

export const DateRangeIcon = () => {
  return (
    <svg
      width="12px"
      height="12px"
      viewBox="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="First-Open-Condition-Posted"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="First-open-condition-date-LAT"
          transform="translate(-1257.000000, -218.000000)"
          fill="#32325D"
        >
          <g id="Group-32" transform="translate(1257.000000, 212.000000)">
            <g
              id="Icons-/-Dark-/-Calendar_today-filed"
              transform="translate(0.000000, 6.000000)"
            >
              <path
                d="M1.5,4.5 L10.5,4.5 L10.5,10.25 C10.5,10.8022847 10.0522847,11.25 9.5,11.25 L2.5,11.25 C1.94771525,11.25 1.5,10.8022847 1.5,10.25 L1.5,4.5 L1.5,4.5 Z"
                id="Rectangle"
              />
              <path
                d="M2.5,1.5 L9.5,1.5 C10.0522847,1.5 10.5,1.94771525 10.5,2.5 L10.5,3.6 L10.5,3.6 L1.5,3.6 L1.5,2.5 C1.5,1.94771525 1.94771525,1.5 2.5,1.5 Z"
                id="Rectangle"
              />
              <rect id="Rectangle" x="3" y="0.525" width="1.125" height="1.5" />
              <rect
                id="Rectangle"
                x="8.25"
                y="0.525"
                width="1.125"
                height="1.5"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
