import { Chip, Grid, Typography } from "@mui/material";
import React from "react";

const CompensatingFactors = ({ compensatingFactors }: any) => {
  return compensatingFactors?.length ? (
    <Grid
      container
      style={{
        margin: "0.5rem 0",
        background: "#f6f9fc",
        padding: "0.5rem 1rem"
      }}
    >
      <Typography
        style={{
          fontSize: 11,
          color: "#32325d",
          fontWeight: 600,
          marginTop: 5,
          marginBottom: 5
        }}
      >
        Compensating Factors
      </Typography>

      {compensatingFactors?.map((factor: string) => (
        <Chip
          style={{
            background: "#11CEEF",
            color: "#fff",
            textTransform: "uppercase",
            fontWeight: 400,
            height: 20,
            fontSize: 10,
            border: "none",
            borderRadius: 6,
            margin: 0,
            marginTop: 5,
            marginBottom: 5,
            marginLeft: 8
          }}
          variant="outlined"
          label={factor}
        />
      ))}
    </Grid>
  ) : null;
};
export default CompensatingFactors;
