/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
  useCallback
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import queryString from "query-string";
import { Grid, Typography, Button, Tabs, Tab } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { WarningModal } from "@toorak/tc-common-fe-sdk";
import { RootState } from "../../stores/rootReducer";
import { GenericSummaryDropDown } from "../form-components/SummaryInformation";
import iconMinimize from "../../images/icon_minimize.svg";
import iconMaximize from "../../images/icon_maximize.svg";
import editImage from "../../images/Edit.svg";

import { useStyles } from "../guideline-outputs/GuidelineOutputs.style";
import {
  evaluateLoan,
  // MODIFY_WFL_RULE,
  // GET_OVERRIDDER_VALUES,
  postOverrideOutput
  // SET_RULES_PREVIOUS_STATE
} from "../../stores/EvaluationResults/EvaluationResults.action";
import { getEvaluationRequestBody } from "../evaluation-results/helpers/ruleRequestBody";
import { getLoanType } from "../../config/config";
import { isViewOnly, MergeRecursive } from "../../utils/formatChecks";
import { isLATUser } from "../../utils/AccessManagement";
import { getCookie } from "../../utils/cookies";
import {
  showLoader,
  hideLoader
} from "../../stores/loaderAndException/loaderAndException.action";
import { editCommentMissing, updateEditComments } from "../create-loan.action";
import {
  requestWaiverMessage
} from "../../utils/constants";
import {
  equity,
  fieldArrMap,
  fundingRadioMap,
  testResultsArray,
  getWareHouseLines,
  getSecurtizationGrades
} from "./METRulesFields";
import { ObjectType } from "../../masterView/common";
import { BasicDropDown } from "../../ui-components/BasicDropDown";
import { CodeSnippetModal } from "../../ui-components/CodeSnippetModal";
import moment from "moment";
import {
  getOverriderValues,
  modifyWFLRule,
  setRulesPreviousState
} from "../../stores/EvaluationResults/EvaluationResults.reducer";
import { fetchEditHistory } from "../create-loan.reducer";
import { getSpecificAuditList } from "../../network/apiService";

export const aggregatePropertyData = (propertyDetails: ObjectType[]) => {
  let originalAppraisalValue = 0;
  let originalRepairedAppraisalValue = 0;
  let propertyValuationDate = "";
  let propertyType = "";
  let state = "";
  propertyDetails.forEach((propertyData: ObjectType, index: number) => {
    const { propertyEconomics, propertyInformation, propertyLocation } =
      propertyData;
    originalAppraisalValue =
      originalAppraisalValue +
      parseFloat(
        (propertyEconomics?.payload?.originalAppraisalValue ?? "")
          ?.replace("$", "")
          .replace(",", "")
      );
    originalRepairedAppraisalValue =
      originalRepairedAppraisalValue +
      parseFloat(
        (propertyEconomics?.payload?.originalRepairedAppraisalValue ?? "")
          .replace("$", "")
          .replace(",", "")
      );
    propertyValuationDate = moment(propertyValuationDate).isSameOrAfter(
      propertyInformation?.payload?.propertyValuationDate ?? ""
    )
      ? propertyValuationDate
      : propertyInformation?.payload?.propertyValuationDate;
    propertyType = `${propertyType}${index === 0 ? "" : ","}Property ${
      index + 1
    }: ${propertyInformation?.payload?.propertyType ?? ""}`;
    state = `${state}${index === 0 ? "" : ","}Property ${index + 1}: ${
      propertyLocation?.payload?.state ?? ""
    }`;
  });
  return {
    originalAsIsAppraisalValue: originalAppraisalValue,
    originalRepairedAppraisalValue,
    propertyValuationDate,
    propertyType,
    state
  };
};

let commentObj: any = [];
function prepareCommentObject(
  field: string,
  value: any,
  comment: string,
  clearComments?: boolean,
  valueChange?: boolean
) {
  if (clearComments) {
    commentObj = [];
    return;
  }
  if (!isLATUser()) {
    comment = "";
  }
  //
  for (let i = 0; i < commentObj.length; i++) {
    if (commentObj[i].field === field) {
      if (valueChange) {
        commentObj[i].value = value;
        return;
      }
      if (isLATUser() && comment.trim() === "") {
        commentObj.splice(i, 1);
        return;
      }

      commentObj[i].comment = comment;
      commentObj[i].value = value;
      return;
    }
  }
  if (isLATUser() && comment.trim() === "" && !valueChange) {
    return;
  }
  const fieldCommentObj = {
    field,
    value,
    comment,
    // prevValue:"2",
    updatedBy: getCookie("person_id")
  };
  commentObj.push(fieldCommentObj);
}

const useStylesTable = makeStyles({
  table: {
    // width: "353px",
    height: "180px",
    borderRadius: "4px",
    border: "solid 1px #ebebeb"
  },
  marginText: {
    marginLeft: "10px"
  },
  tableRow: {
    height: "50px"
  },
  disabledClass: {
    pointerEvents: "all",
    cursor: "not-allowed !important"
  },
  testWrapper: {
    margin: "0.5rem",
    width: "calc(25% - 1rem)",
    padding: "1rem 24px"
  },
  statusContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 24px"
  },
  statusWrapper: {
    marginTop: "1rem",
    display: "flex",
    flexDirection: "row"
  },
  finalStatusContainer: {
    margin: "0.5rem",
    display: "flex",
    flexDirection: "column",
    borderRadius: "4px",
    border: "1px solid rgb(233, 236, 239)",
    padding: "14px 16px 14px 12px"
  },
  finalStatusHeader: {
    fontSize: "14px",
    color: "#424242",
    marginBottom: "10px"
  },
  failStatus: {
    color: "#f75676"
  },
  passStatus: {
    color: "#417505"
  },
  fundingSelection: {
    margin: "24px 0px"
  },
  cancelButton: {
    width: 69,
    height: 28,
    borderRadius: 4,
    marginLeft: 10,
    boxShadow: "0 1px 3px 0 rgb(0 0 0 / 20%)",
    color: "#32325d",
    backgroundColor: "#fff",
    "&:hover": {
      background: "none"
    },
    border: "none"
  },
  fundingSourceItem: {
    marginLeft: "1rem"
  },
  tabsRoot: {
    borderBottom: "1px solid #f2f2f2",
    padding: "0px 24px",
    "& .MuiTabs-indicator": {
      border: "2px solid #5e72e4",
      borderRadius: "5px"
    }
  },
  tabSelected: {
    fontWeight: "bold"
  }
});

export const NewFundingSource = () => {
  const evaluationResults = useSelector<RootState, any>(
    (state) => state.evaluationResultsStore.loanEvaluationResult
  );
  const { takeoutPartnerId } = useSelector<RootState, any>(
    (state) =>
      state.createLoanStore?.loanDetails.thirtyYearLoanInformation.payload
  );

  const { loanId, loanStage, loanType }: any = useParams<any>() as any;
  const { fundingSourceType }: any = queryString.parse(
    new URL(window.location.href).search
  );

  const defaultFunding = useMemo(() => {
    const fundingSource =
      evaluationResults.loanResult?.[loanId]?.summary?.fundingSource ?? "";
    return fundingSource;
  }, [evaluationResults, loanId]);

  const originalFinalWhlValue = useMemo(() => {
    return JSON.parse(JSON.stringify(evaluationResults?.loanResult[loanId]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isEditable, setEditable] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedFundingSource, setSelectedFundingSource] =
    useState(defaultFunding);
  const [fetchingAudit, setFetchingAudit] = useState<any>({});
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableclass: any = useStylesTable();

  const { editHistory, isWithdrawnLoan } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );

  const overriddenValues = useSelector<RootState, any>(
    (state) => state.evaluationResultsStore.overriddenValues
  );

  const {
    bridgeLoanBorrowerInformation,
    bridgeLoanGuarantorInformation,
    bridgeLoanInformation,
    bridgeLoanEconomics,
    propertyDetails,
    thirtyYearLoanEconomics,
    thirtyYearLoanInformation,
    loanRuleVersions,
    loanSummary,
    loanState
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useSelector<RootState, any>(
    (state: { createLoanStore: { loanDetails: any } }) =>
      state.createLoanStore.loanDetails
  );

  const [changedValueFieldsMain, setChangedValueFieldsMain] = useState<{
    [index: string]: { [key: string]: boolean };
  }>({});

  const [isMinimized, setMinimized] = useState(false);

  const finalKey =
    loanType === getLoanType[0].displayValue ? "finalWhl" : "summary";
  // const isWithdrawnLoan:boolean = loanState === LoanStatusEnum.Withdrawn

  const editedFields = useSelector<RootState, any>(
    (state: any) => state.createLoanStore.editedFields
  );

  useLayoutEffect(() => {
    if (fundingSourceType) setSelectedTab(parseInt(fundingSourceType, 10));
  }, [fundingSourceType]);

  useEffect(() => {
    equity.isDisabled = isWithdrawnLoan;
  }, [isWithdrawnLoan]);

  const wareHouseLines: ObjectType[] = useMemo(() => {
    const securtizationLines =
      loanType === getLoanType[0].displayValue ? getSecurtizationGrades() : [];

    const isLineDisabeld = (mappingKey: string, summaryKey?: string) => {
      return isWithdrawnLoan
        ? true
        : evaluationResults?.loanResult?.[loanId]?.[mappingKey]?.[
            summaryKey || finalKey
          ] !== "PASS";
    };
    const warehouseLines = getWareHouseLines(loanType).map((lineData: ObjectType) => {
      return {
        ...lineData,
        disabled: isLineDisabeld(lineData.mappingKey),
        customClass: tableclass.fundingSourceItem
      };
    });
    const securtizationLinesData = securtizationLines.map(
      (lineData: ObjectType) => {
        return {
          ...lineData,
          disabled: isLineDisabeld(lineData.mappingKey, "summary"),
          customClass: tableclass.fundingSourceItem
        };
      }
    );
    return [...warehouseLines, ...securtizationLinesData];
  }, [
    tableclass,
    evaluationResults,
    loanId,
    finalKey,
    isWithdrawnLoan,
    loanType
  ]);

  const selectedWarehouseLine = useMemo(
    () => wareHouseLines[selectedTab].key,
    [selectedTab, wareHouseLines]
  );

  const fundingTypeKey = useMemo(() => {
    return fundingRadioMap?.[selectedWarehouseLine];
  }, [selectedWarehouseLine]);

  const selectedWareHouseLineData: ObjectType = useMemo(() => {
    return evaluationResults?.loanResult[loanId]?.[fundingTypeKey] || {};
  }, [evaluationResults, loanId, fundingTypeKey]);

  const warehouseLinesData: ObjectType = useMemo(
    () => evaluationResults?.loanResult[loanId],
    [evaluationResults, loanId]
  );

  const fieldArr: ObjectType[] = useMemo(() => {
    return fieldArrMap?.[loanType]?.[selectedWarehouseLine] || [];
  }, [selectedWarehouseLine, loanType]);

  const loanData = useMemo(() => {
    const bridgeData = {
      borrowerInformation: bridgeLoanBorrowerInformation,
      guarantorInformation: bridgeLoanGuarantorInformation,
      loanInformation: bridgeLoanInformation.payload,
      loanEconomics: bridgeLoanEconomics.payload,
      loanRuleVersions,
      loanSummary
    };
    const thirtyYrData = {
      borrowerInformation: bridgeLoanBorrowerInformation,
      guarantorInformation: bridgeLoanGuarantorInformation,
      loanInformation: thirtyYearLoanInformation.payload,
      loanEconomics: thirtyYearLoanEconomics.payload,
      loanRuleVersions,
      loanSummary
    };

    if (loanType === getLoanType[0].displayValue) {
      return bridgeData;
    }
    return thirtyYrData;
  }, [
    loanType,
    bridgeLoanBorrowerInformation,
    bridgeLoanGuarantorInformation,
    bridgeLoanInformation,
    bridgeLoanEconomics,
    loanRuleVersions,
    loanSummary,
    thirtyYearLoanInformation,
    thirtyYearLoanEconomics
  ]);

  const flattenedLoanData = useMemo(() => {
    const {
      loanEconomics,
      loanInformation,
      borrowerInformation,
      guarantorInformation,
      loanSummary
    } = loanData;
    const borrowerCount =
      borrowerInformation?.filter((borrowerInfoObj: ObjectType) => {
        return borrowerInfoObj.payload.borrowerType === "Individual";
      })?.length ?? 0;
    const guarantorCount = guarantorInformation?.length ?? 0;
    const guarantorAndBorrowerCount = guarantorCount + borrowerCount;
    const aggregatedPropertyData = aggregatePropertyData(propertyDetails);
    const {
      loanCharacterisations,
      loanFeatures,
      borrowerInfo,
      loanEconomics: loanResultEconomics,
      loanPricing
    } = evaluationResults.loanResult[loanId];
    const flatData: ObjectType = {
      loanEconomics,
      loanInformation,
      loanCharacterisations,
      loanFeatures,
      borrowerInfo,
      loanResultEconomics,
      loanSummary,
      loanPricing
    };
    const transformedData: ObjectType = {};
    Object.keys(flatData)?.forEach((key: string) => {
      const obj = flatData?.[key] ?? {};
      Object.keys(obj)?.forEach((fieldId: string) => {
        transformedData[`${key}.${fieldId}`] = obj[fieldId];
      });
    });
    return {
      ...transformedData,
      ...aggregatedPropertyData,
      guarantorAndBorrowerCount
    };
  }, [loanData, evaluationResults, loanId, propertyDetails]);

  const updateFundingSource = async (FundingSource: string) => {
    const overriddenValuesToUse = overriddenValues;
    const newDs = {
      loanResult: {
        [loanId]: {
          summary: {
            fundingSource: FundingSource
          }
        }
      }
    };
    const results =
      overriddenValuesToUse.loanResult ||
      overriddenValuesToUse.propertiesResults
        ? MergeRecursive(overriddenValuesToUse, newDs)
        : newDs;
    try {
      await postOverrideOutput(loanId, loanStage, newDs, takeoutPartnerId);
      // dispatch({
      //   type: GET_OVERRIDDER_VALUES,
      //   payload: {
      //     overidden: results
      //   }
      // });
      dispatch(
        getOverriderValues({
          overidden: results
        })
      );

      autoSaveRuleChanges(results);
    } catch (e) {
      console.log(e);
    }
  };

  function autoSaveRuleChanges(updatedRuleValue?: any) {
    const ruleData = loanData;
    getEvaluationRequestBody(
      loanId,
      loanStage,
      ruleData,
      evaluationResults,
      propertyDetails,
      loanType
    ).then(({ evaluate, loanEvaluationRequest }) => {
      if (evaluate && loanEvaluationRequest) {
        const request = { ...loanEvaluationRequest };
        request.ruleAttributs = {
          ruleGroup: [],
          ruleField: [],
          ruleResult: []
        };
        request.resultObject = updatedRuleValue;

        dispatch(
          evaluateLoan(request, loanId, loanStage, true, false, propertyDetails)
        );
      }
    });
  }

  const runReevaluation = () => {
    setShowPopup(false);
    saveOnFieldChange(changedValueFieldsMain, overriddenValues).then(
      (res: any) => {
        if (res !== "false") {
          setChangedValueFieldsMain({});
          setEditable(!isEditable);
        }
      }
    );
  };
  const handleChange = (keyName: string, value: ObjectType) => {
    setChangedValueFieldsMain({
      ...changedValueFieldsMain,
      [fundingTypeKey]: {
        ...changedValueFieldsMain?.[fundingTypeKey],
        [keyName]: value.value
      }
    });
    // dispatch({
    //   type: MODIFY_WFL_RULE,
    //   payload: {
    //     changedValue: value.value,
    //     loanId,
    //     fieldName: keyName,
    //     sectionName: fundingTypeKey
    //   }
    // });
    dispatch(
      modifyWFLRule({
        changedValue: value.value,
        loanId,
        fieldName: keyName,
        sectionName: fundingTypeKey
      })
    );
  };

  const saveOnFieldChange = async (
    changedValueFields: any,
    overRiddenValuePaas?: any,
    resultEdit: any = "line"
  ) => {
    dispatch(showLoader());
    let overriddenValuesToUse = overriddenValues;

    let results: any;

    const totalFieldsEdited = wareHouseLines.reduce((acc, lineData) => {
      return (
        acc +
        Object.keys(changedValueFields?.[lineData.mappingKey] ?? {}).length
      );
    }, 0);

    overriddenValuesToUse = overRiddenValuePaas;

    if (isLATUser() && !resultEdit) {
      let commentMissing = false;
      // eslint-disable-next-line
      commentObj.map((item: any) => {
        if (item.comment === "") {
          commentMissing = true;
        }
      });
      if (commentMissing || commentObj.length !== totalFieldsEdited) {
        dispatch(editCommentMissing(true));
        dispatch(hideLoader());
        return Promise.resolve("false");
      }
    }

    if (totalFieldsEdited) {
      const newDs = {
        loanResult: {
          [loanId]: {
            ...changedValueFields
          }
        }
      };
      results =
        overriddenValuesToUse?.loanResult ||
        overriddenValuesToUse?.propertiesResults
          ? MergeRecursive(overriddenValuesToUse, newDs)
          : newDs;
      try {
        await postOverrideOutput(loanId, loanStage, results, takeoutPartnerId);

        // dispatch({
        //   type: GET_OVERRIDDER_VALUES,
        //   payload: {
        //     overidden: results
        //   }
        // });
        dispatch(
          getOverriderValues({
            overidden: results
          })
        );
      } catch (e) {
        dispatch(hideLoader());
        return Promise.resolve("false");
      }
    }
    const data = {
      borrowerInformation: bridgeLoanBorrowerInformation,
      guarantorInformation: bridgeLoanGuarantorInformation,
      loanInformation: null,
      loanEconomics: null,
      loanRuleVersions,
      loanSummary
    };
    switch (loanType) {
      case `${getLoanType[0].displayValue}`:
        data.loanInformation = bridgeLoanInformation.payload;
        data.loanEconomics = bridgeLoanEconomics.payload;
        break;
      case `${getLoanType[1].displayValue}`:
        data.loanEconomics = thirtyYearLoanEconomics.payload;
        data.loanInformation = thirtyYearLoanInformation.payload;
        break;
      default:
        break;
    }

    getEvaluationRequestBody(
      loanId,
      loanStage,
      data,
      evaluationResults,
      propertyDetails,
      loanType
    ).then(({ evaluate, loanEvaluationRequest }) => {
      if (evaluate && loanEvaluationRequest) {
        const request = { ...loanEvaluationRequest };
        request.ruleAttributs = {
          ruleGroup: [],
          ruleField: [],
          ruleResult: []
        };
        request.resultObject = resultEdit ? results : overriddenValuesToUse;

        dispatch(
          evaluateLoan(request, loanId, loanStage, true, false, propertyDetails)
        );
        if (resultEdit !== true) {
          dispatch(updateEditComments(`${loanId}_${loanStage}`, commentObj));
          commentObj = [];
        }
        dispatch(hideLoader());
        return Promise.resolve("true");
      }
    });
  };

  const cancelEdit = () => {
    // dispatch({
    //   type: SET_RULES_PREVIOUS_STATE,
    //   payload: {
    //     prevObj: originalFinalWhlValue,
    //     section: "",
    //     loanId
    //   }
    // });
    dispatch(
      setRulesPreviousState({
        prevObj: originalFinalWhlValue,
        // activePropertyId: propertyId,
        section: "",
        loanId: loanId
      })
    );

    setEditable(!isEditable);
    setChangedValueFieldsMain({});
    prepareCommentObject("", "", "", true);
  };

  const handleComments = (event: any, fieldName: string) => {
    if (
      event?.target.parentElement?.parentElement?.innerText.includes(
        "Reason For Editing"
      )
    ) {
      prepareCommentObject(
        `data.result.loanResult.${loanId}.${fundingTypeKey}.${fieldName}`,
        evaluationResults?.loanResult[loanId]?.[fundingTypeKey]?.[fieldName],
        event.target.value
      );
    }
  };

  const handleEditClick = () => {
    setEditable(!isEditable);
  };

  const handleCardCollapse = () => {
    setMinimized(!isMinimized);
  };

  const handleFundingSourceChange = (e: any) => {
    const val = e?.target?.value ?? "";
    setSelectedFundingSource(val);
    updateFundingSource(val);
  };

  const isViewMode = useMemo(() => {
    return loanState === "Purchased"
      ? true
      : isViewOnly(loanStage, loanId) ||
          !isEditable ||
          !wareHouseLines[selectedTab]?.isEditable;
  }, [loanState, loanStage, loanId, isEditable, selectedTab, wareHouseLines]);

  const renderCustomInfoTag = useCallback(
    (item: ObjectType) => {
      return (
        <CodeSnippetModal
          formulaLable={item.label}
          formulaText={item?.formulaText ?? ""}
          formulaVariables={item?.formulaVariables ?? []}
          data={flattenedLoanData}
        />
      );
    },
    [flattenedLoanData]
  );

  const getEditedTagData = (fundingTypeKey: string, item: any) => {
    const fieldPathStr = `data.result.loanResult.${loanId}.${fundingTypeKey}.${item.keyName}`;
    return editedFields.includes(fieldPathStr);
  };

  const onEditTagHover = (fundingTypeKey: string, item: any) => {
    let keyToBePassed = item.keyName;
    let fieldPath = `data.result.loanResult.${loanId}.${fundingTypeKey}.${item.keyName}`;

    setFetchingAudit({ ...fetchingAudit, [keyToBePassed]: true });
    if (fetchingAudit[keyToBePassed]) return;
    getSpecificAuditList(`${loanId}_${loanStage}`, fieldPath, "loans").then((res: any) => {
      setFetchingAudit({ ...fetchingAudit, [keyToBePassed]: false });
      dispatch(fetchEditHistory({ ...res?.data, noKeysUpdate: true }));
    })
  }

  return (
    <>
      <WarningModal
        isOpen={showPopup}
        handleClose={runReevaluation}
        handleOptional={() => {
          setShowPopup(false);
          setChangedValueFieldsMain({});
          cancelEdit();
        }}
        primaryBtnName="Proceed"
        header="Warning"
        body={requestWaiverMessage}
      />
      <div className={classes.container}>
        <Grid container spacing={3} className={classes.sectionWrapper}>
          <Grid
            item
            xs={10}
            sm={11}
            style={{
              display: "flex"
            }}
          >
            <Grid item xs={2}>
              <Typography className={classes.headingTypography}>
                Funding Source
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={1} sm={1} className={classes.headingImageContainer}>
            {!isViewOnly(loanStage, loanId) && !isWithdrawnLoan && (
              <Button
                className={
                  isEditable ? classes.editImageDisplay : classes.editImage
                }
                onClick={handleEditClick}
                onKeyDown={handleEditClick}
              >
                <img
                  src={editImage}
                  style={{ height: "16px" }}
                  alt="Edit Section"
                />
              </Button>
            )}
            <Button
              className={classes.headingImage}
              onClick={handleCardCollapse}
              onKeyDown={handleCardCollapse}
            >
              <img
                style={{ height: "16px" }}
                src={isMinimized ? iconMaximize : iconMinimize}
                alt={isMinimized ? "Maximize section" : "Minimize section"}
              />
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          style={{
            display: isMinimized ? "none" : ""
          }}
        >
          <div className={classes.divider} />
        </Grid>
        <div
          style={{
            width: "100%",
            padding: "24px 0px",
            display: isMinimized ? "none" : ""
          }}
        >
          <div className={tableclass.statusContainer}>
            <div className={classes.heading2Typography}>
              {`Warehouse Lines & Eligibility`}
            </div>
            <div className={tableclass.statusWrapper}>
              {wareHouseLines.map((item: ObjectType) => (
                <div
                  className={tableclass.finalStatusContainer}
                  key={item.label}
                >
                  <div style={{ marginBottom: "1rem" }}>
                    <span className={tableclass.finalStatusHeader}>
                      {item.label}
                    </span>
                  </div>
                  {["Eligibility", "Concentration"].map((key: string) => {
                    return (
                      <div key={key}>
                        <span>{key} : </span>
                        <span
                          className={
                            tableclass?.[
                              `${warehouseLinesData?.[item.mappingKey]?.[
                                key.toLocaleLowerCase()
                              ]?.toLowerCase()}Status`
                            ]
                          }
                        >
                          {
                            warehouseLinesData?.[item.mappingKey]?.[
                              key.toLocaleLowerCase()
                            ]
                          }
                        </span>
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          </div>
          <div>
            <Tabs
              value={selectedTab}
              onChange={(e: any, val: number) => {
                setSelectedTab(val);
              }}
              classes={{
                root: tableclass.tabsRoot
              }}
            >
              {wareHouseLines?.map((item: ObjectType) => (
                <Tab
                  key={item.tabLabel}
                  label={item.tabLabel}
                  style={{ textTransform: "unset", color: "#32325D" }}
                  classes={{ selected: tableclass.tabSelected }}
                />
              ))}
            </Tabs>
            {wareHouseLines?.map((line: ObjectType, index: number) => (
              <>
                {index === selectedTab && (
                  <div key={line.key}>
                    {fieldArr?.map(({ groupLabel, key, tests }: ObjectType) => {
                      return (
                        <div style={{ display: "flex column" }} key={key}>
                          <div
                            style={{
                              margin: "1rem 0.5rem 0",
                              padding: "0 24px",
                              fontSize: "14px",
                              fontWeight: "bold"
                            }}
                          >
                            <span>{groupLabel} - </span>
                            <span
                              className={
                                tableclass?.[
                                  `${selectedWareHouseLineData?.[
                                    key
                                  ]?.toLowerCase()}Status`
                                ]
                              }
                            >
                              {selectedWareHouseLineData?.[key]}
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap"
                            }}
                          >
                            {tests?.map((item: ObjectType) => {
                              return (
                                <div
                                  key={item.label}
                                  className={tableclass.testWrapper}
                                >
                                  {item.fieldComponent &&
                                    item.fieldComponent(item)}
                                  {!item.fieldComponent && (
                                    <GenericSummaryDropDown
                                      label={item.label}
                                      isViewMode={isViewMode}
                                      selectionValue={
                                        selectedWareHouseLineData?.[
                                          item.keyName
                                        ]
                                      }
                                      selectionData={
                                        item.dropDownValues || testResultsArray
                                      }
                                      selectionOnChange={(e: any) =>
                                        handleChange(item.keyName, e.target)
                                      }
                                      isLATView={isLATUser()}
                                      editedLabel={
                                        changedValueFieldsMain?.[
                                          fundingTypeKey
                                        ]?.[item.keyName]
                                      }
                                      onBlur={(e) =>
                                        handleComments(e, item.keyName)
                                      }
                                      editedData={
                                        editHistory?.[
                                          `data.result.loanResult.${loanId}.${fundingTypeKey}.${item.keyName}`
                                        ] ?? []
                                      }
                                      showEditedTag={getEditedTagData(fundingTypeKey,item)}
                                      onHover={() => onEditTagHover(fundingTypeKey,item)}
                                      infoIconData={
                                        item.value === "FAIL" &&
                                        evaluationResults?.reasonForFail
                                          ?.templateMessage?.[
                                          `loan.loanResult.${fundingTypeKey}.${item.keyName}.message.FAIL`
                                        ]
                                      }
                                      renderCustomInfoTag={() =>
                                        renderCustomInfoTag(item)
                                      }
                                      formControlTestId={item.keyName}
                                      helperText=""
                                      inputLabelId={item.keyName}
                                      inputLabelTestId={item.keyName}
                                      selectTestId={item.keyName}
                                      selectId={item.KeyName}
                                    />
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            ))}
          </div>
          {isEditable && (
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              xl={12}
              style={{ margin: "1rem 0.5rem", padding: "0px 24px" }}
            >
              <Button
                variant="contained"
                color="info"
                className={classes.savebutton}
                disabled={Object.keys(changedValueFieldsMain).length === 0}
                onClick={() => {
                  setShowPopup(true);
                }}
              >
                Save
              </Button>
              <Button
                className={tableclass.cancelButton}
                variant="outlined"
                onClick={cancelEdit}
              >
                Cancel
              </Button>
            </Grid>
          )}
          <div
            className={`${classes.divider} ${tableclass.fundingSelection}`}
          />
          <div style={{ padding: "0px 24px", color: "#32325D" }}>
            <BasicDropDown
              menuOptions={[equity, ...wareHouseLines]}
              selected={selectedFundingSource}
              handleChange={handleFundingSourceChange}
              selectStyles={classes}
              dropDownLabel="Select the Funding Source"
              isDisabled={isWithdrawnLoan}
              placeHolderText={"Select"}
            />
          </div>
        </div>
      </div>
    </>
  );
};
