/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */

// import { produce } from "immer";
// import {
//   DELETE_DOCUMENT,
//   DELETE_NOTE_DOCS,
//   GET_ALL_NOTES,
//   GET_NOTES_CHATS,
//   RESET_NOTE_STORE,
//   UPDATE_AWS_DOCUMENT_URL,
//   UPDATE_DOCS_IN_NOTES,
//   UPDATE_HAS_DOCUMENT_STATUS
// } from "./Notes.constant";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export const selectAllName: string = "Select All";
export const usersKey: string = "Users";
export const tagsKey: string = "Tags Attached";
export const originatorsLabel: string = "Originators";
export const toorakUsersLabel: string = "Toorak Users";
export const noteEntriesPerPage: number = 10;

export interface NoteModel {
  customers: any[];
  accountMapping: any[];
  accounts: any[];
  chats: any[];
  documents: any[];
}

export interface FilteredNote {
  partyId: string;
  updatedOn: string;
  createdOn: string;
  chatLineSequenceId: string;
  chatId: string;
  chatLineId: string;
  firstName: string;
  lineText: string;
  fileInfo?: string[];
}

export interface NoteStore {
  notesRes: FilteredNote[];
  chats: any[];
  docFiles: { [key: string]: string | undefined };
  awsUrls: string[];
  hasDocuments: boolean;
}

const initialState: NoteStore = {
  notesRes: [
    {
      partyId: "",
      chatLineSequenceId: "",
      chatId: "",
      chatLineId: "",
      updatedOn: "",
      createdOn: "",
      firstName: "",
      lineText: "",
      fileInfo: []
    }
  ],
  chats: [],
  docFiles: {},
  awsUrls: [],
  hasDocuments: false
};

const NoteSlice = createSlice({
  name: "note",
  initialState,
  reducers: {
    setAllNotes: (
      state,
      action: PayloadAction<{ notesRes: FilteredNote[] }>
    ) => {
      state.notesRes = action.payload.notesRes;
    },
    setNotesChats: (state, action: PayloadAction<{ chats: any[] }>) => {
      state.chats = action.payload.chats;
    },
    updateDocsInNotes: (
      state,
      action: PayloadAction<{ bucketId: string; files: any }>
    ) => {
      const { bucketId, files } = action.payload;
      state.docFiles[bucketId] = files;
    },
    updateAwsDocumentUrl: (
      state,
      action: PayloadAction<{ awsUrl: string }>
    ) => {
      state.awsUrls.push(action.payload.awsUrl);
    },
    updateHasDocumentStatus: (
      state,
      action: PayloadAction<{ hasDocuments: boolean }>
    ) => {
      state.hasDocuments = action.payload.hasDocuments;
    },
    deleteNoteDocs: (state) => {
      state.docFiles = {};
    },
    deleteDocument: (state, action: PayloadAction<{ docFiles: any }>) => {
      state.docFiles = action.payload.docFiles;
    },
    resetNoteStore: () => initialState
  }
});

// Export actions and reducer
export const {
  setAllNotes,
  setNotesChats,
  updateDocsInNotes,
  updateAwsDocumentUrl,
  updateHasDocumentStatus,
  deleteNoteDocs,
  deleteDocument,
  resetNoteStore
} = NoteSlice.actions;
export const NoteReducer = NoteSlice.reducer;

// export const NoteReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case GET_ALL_NOTES: {
//       const { notesRes } = action.payload;
//       return produce(state, draftState => {
//         draftState.notesRes = notesRes;
//       });
//     }
//     case GET_NOTES_CHATS: {
//       const { chats } = action.payload;
//       return produce(state, draftState => {
//         draftState.chats = chats;
//       });
//     }
//     case UPDATE_DOCS_IN_NOTES: {
//       const { bucketId, files } = action.payload;
//       return produce(state, (draftState: any) => {
//         const documentFiles: any = { ...draftState.docFiles };
//         documentFiles[bucketId] = files;
//         draftState.docFiles = documentFiles;
//       });
//     }
//     case UPDATE_AWS_DOCUMENT_URL: {
//       const { awsUrl } = action.payload;
//       return produce(state, draftState => {
//         draftState.awsUrls.push(awsUrl);
//       });
//     }
//     case UPDATE_HAS_DOCUMENT_STATUS: {
//       const { hasDocuments } = action.payload;
//       return produce(state, draftState => {
//         draftState.hasDocuments = hasDocuments;
//       });
//     }
//     case DELETE_NOTE_DOCS: {
//       return produce(state, (draftState: any) => {
//         draftState.docFiles = {};
//       });
//     }

//     case DELETE_DOCUMENT: {
//       const { docFiles } = action.payload;

//       return produce(state, (draftState: any) => {
//         draftState.docFiles = docFiles;
//       });
//     }
//     case RESET_NOTE_STORE: {
//       return initialState;
//     }
//     default:
//       return state;
//   }
// };
