import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  LoanStage,
  waiverStatusLowerCase
  // WarningModal,
  // WarningModalProps
} from "@toorak/tc-common-fe-sdk";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import { FailedSectionWrapper } from "./FailedTestSectionWrapper";
import { alterEvalRequestBody, numberOfWaivers } from "./EvaluationHelper";
import { PassedSectionWrapper } from "./PassedSectionWrapper";
import { RootState } from "../../stores/rootReducer";
import { WaiverPendingWrapper } from "../guideline-outputs/WaiverPending";
import { WaiverApproved } from "../guideline-outputs/WaiverApproved";
import { isLATUser, isPurchasedLoansOnly } from "../../utils/AccessManagement";
import { waiverDetails } from "../../stores/WaiverRequest/WaiverRequest.action";
import addGeneralWaiver from "../../images/add-general-waiver.svg";
import { AttachScenarioDialog } from "../scenario/AttachScenarioDialog";
import {
  showLoader,
  hideLoader
  //clearLoader
} from "../../stores/loaderAndException/loaderAndException.action";
import {
  attachScenarioToLoans,
  getScenarioListFromElasticSearch,
  updateScenarioDetailsListInRedux,
  getScenariosByLoanIds
} from "../../stores/Scenario/ScenarioActions";
import { ScenarioCompactView } from "../scenario/ScenarioCompactView";
import { getAllDiscussions } from "../../stores/Discussions/Discussions.action";
import { InputSectionWrapper } from "../../credit-evaluation/InputSectionWrapper";
import { isNullOrUndefined } from "../../utils/formatChecks";
import { getLatestWaiverResponse } from "../../utils/utils";
import { ToorvalAppraisalStatus } from "./toorval-appraisal-status/ToorvalAppraisalStatus";
import { waiverRequestConfig } from "../../utils/constants";
import { clearLoader } from "../../stores/loaderAndException/loaderAndException.reducer";

export interface FailedSectionWrapperProps {
  item: RepresentableEvaluationResults;
  failureCardSize?: {
    xs?: GridSize["size"];
    md?: GridSize["size"];
    sm?: GridSize["size"];
    xl?: GridSize["size"];
  };
  isMinimizable?: boolean;
}
export interface GridSize {
  size:
    | boolean
    | "auto"
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | undefined;
}
export interface EvaluationResultsProps {
  evaluationResults: any;
  evalTemplateRules: {
    templateMessage: any;
    inputToRunEval: { propertyResult: any; loanResult: any };
  };
  propertyInputs?: any;
  loanInputs?: boolean;
  dividerRequired?: boolean;
  redirectionRequired?: boolean; // this is false if coming from loan/property tab. true in guideline output tab.
  showIntermediateValues?: boolean; // this is true if you want to show intermediate results and passed tests. current use case guideline tab should show intermediate values for LAT.
  failureCardSize?: {
    xs?: GridSize["size"];
    md?: GridSize["size"];
    sm?: GridSize["size"];
    xl?: GridSize["size"];
  };
  isMinimizable?: boolean;
  showInfo?: boolean;
  isWaiverPending?: boolean;
  isWaiverApproved?: boolean;
  resultType?: string;
  infoContentResult?: any;
  isFailedTestTab?: boolean;
  hideDuplicateFails?: boolean;
  evaluationPanel?: any;
  isToorvalLoan?: boolean;
  propInfotoorvalId?: string | null;
}

export interface EvaluationResultsFailureData {
  testGroup: string;
  testName: string;
  representableTestName: string;
  reasonForFailure: string;
  waiverData?: any;
  redirectionButtonName?: string;
  propertyId?: string;
  isWaiverPending?: boolean;
  isWaiverApproved?: boolean;
}

export interface RepresentableEvaluationResults {
  testGroupName: string;
  representableTestGroupName: string;
  overAllResults: "PASS" | "FAIL";
  overallFailingTests: "PASS" | "FAIL";
  failingTests: EvaluationResultsFailureData[];
  intermediateValues?: {
    fieldName: any;
    fieldValue: any;
    fieldType?: string;
    propertyId?: string;
  }[];
  isWaiverPending?: boolean;
  isWaiverApproved?: boolean;
  isFailedTestTab?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    viewDetailsbutton: {
      borderRadius: "4px",
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4",
      marginTop: "12px"
    },
    divider: {
      border: "1px solid #f2f2f2",
      height: "1px",
      padding: "0px !important"
    },
    scenarioButton: {
      borderRadius: "4px",
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4",
      marginTop: "12px",
      marginLeft: "10px"
    },
    cardWrap: { backgroundColor: "#f8f9ff" }
  })
);

export function EvaluationResults(props: EvaluationResultsProps) {
  const {
    evaluationResults,
    failureCardSize,
    isMinimizable,
    showInfo,
    evalTemplateRules,
    propertyInputs,
    loanInputs,
    dividerRequired,
    redirectionRequired,
    showIntermediateValues,
    isWaiverPending,
    isWaiverApproved,
    resultType,
    infoContentResult,
    isFailedTestTab,
    hideDuplicateFails,
    evaluationPanel,
    isToorvalLoan,
    propInfotoorvalId
  } = props;
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogdetails, setDialogdetails] = React.useState<any>({});
  const [selectedTags, setSelectedTags] = useState([]);
  const [scenarioIDArr, setScenarioIDArr] = useState<any[]>([]);
  const [generalWaiverRequest, setGeneralWaiver] = useState<any[]>([]);
  const attachedScenarioIdsArr: any[] = [];
  const navigate = useNavigate();
  const { waiversCreated } = useSelector<RootState, any>(
    (state) => state.evaluationResultsStore
  );
  const [testsFailCount, setTestFailCount] = useState(0);

  const errorAddressList = useSelector<RootState, any>(
    (state) =>
      state.evaluationResultsStore.loanEvaluationResult?.errorAddressList
  );

  // const scenarioListArr = useSelector<
  //   RootState,
  //   { scenarioListResult: any[] | any }
  // >(
  //   (state: { scenarioResultsStore: { scenarioListResult: any } }) =>
  //     state.scenarioResultsStore?.scenarioListResult
  // );

  //= ====================================general waiver request=====================================================================================================
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loanId, loanStage, loanType } = useParams() as any;
  const { isViewMode, isWithdrawnLoan } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );
  const { loanState, loanDetailId } = useSelector<RootState, any>(
    (state) => state.createLoanStore.loanDetails
  );
  const { thirtyYearLoanInformation, fesLoanInformation } = useSelector<
    RootState,
    any
  >((state) => state.createLoanStore?.loanDetails);
  const takeoutPartnerId = useMemo(() => {
    if (loanStage === LoanStage.fes) {
      return fesLoanInformation?.takeoutPartnerId;
    }
    return thirtyYearLoanInformation?.payload?.takeoutPartnerId;
  }, [loanStage, fesLoanInformation, thirtyYearLoanInformation]);

  // const [openWarning, setOpenWarning] = useState<WarningModalProps | null>(
  //   null
  // );
  const originatorPartyId = useMemo(
    () => loanDetailId?.originatorPartyId,
    [loanDetailId]
  );
  const showWaiverRequestButton = useMemo(() => {
    if (!waiverRequestConfig?.[loanType]?.showForOrgs) return true;
    return waiverRequestConfig?.[loanType]?.showForOrgs.some(
      (id: string) => id === originatorPartyId
    );
  }, [loanType, originatorPartyId]);

  // const handleRequestWaiver = () => {
  //   // if (
  //   //   loanStage === LoanStage.fes &&
  //   //   !numberOfWaivers(waiversCreated) &&
  //   //   !isLATUser()
  //   // ) {
  //   //   setOpenWarning({
  //   //     isOpen: true,
  //   //     handleClose: () => {
  //   //       redirectToWaiverRequest();
  //   //     },
  //   //     header: "Warning",
  //   //     primaryBtnName: "Proceed",
  //   //     body:
  //   //       "Once you request a waiver, you will not be allowed to edit any of the input fields to evaluate guideline again.",
  //   //     handleOptional: () => {
  //   //       setOpenWarning(null);
  //   //     }
  //   //   });
  //   // } else
  //   if (numberOfWaivers(waiversCreated) >= 5) {
  //     setOpenWarning({
  //       isOpen: true,
  //       handleClose: () => {
  //         setOpenWarning(null);
  //       },
  //       header: "Waivers Requested: 5.",
  //       body:
  //         "You have reached the limit of the maximum number of waiver requests allowed."
  //     });
  //   } else {
  //     redirectToWaiverRequest();
  //   }

  //   // redirectToWaiverRequest();
  // };
  const redirectToWaiverRequest = () => {
    dispatch(
      waiverDetails(
        "General Waiver Request",
        "",
        {
          tab: "GeneralWaiver",
          id: "",
          backLocation: window.location.pathname,
          testGroup: "",
          representableTestName: "General Waiver Request",
          waiverId: ""
        },
        {}
      )
    );
    navigate(
      `/loan/waiver/request/${loanStage}/${loanId}?pId=${takeoutPartnerId}`
    );
  };

  //= ================================================================================================================================================================

  const { Purchased, Withdrawn } = LoanStatusEnum;
  const [representableEvaluationResults, setRepresentableEvaluationResults] =
    useState<RepresentableEvaluationResults[]>([]);

  // comment these because its breaking the excel upload loan creation
  // useEffect(() => {
  //   dispatch(getAllDiscussions(loanId, loanStage, 0, 0, {}, "", true));
  // }, [loanId, loanStage, dispatch]);

  useEffect(() => {
    dispatch(getScenariosByLoanIds(loanId, loanStage));
  }, [loanId, loanStage, dispatch]);

  useEffect(() => {
    const tempResponse: {
      [index: string]: string;
    } = evalTemplateRules.templateMessage || {};
    const temp = alterEvalRequestBody(
      evaluationResults,
      tempResponse,
      loanInputs,
      propertyInputs,
      evalTemplateRules,
      loanId,
      waiversCreated,
      showIntermediateValues,
      loanStage
    );
    if (hideDuplicateFails) {
      const finalResult = temp.map((ele) => {
        if (
          ele.failingTests.length > 1 &&
          ele.testGroupName !== "General Waiver Request"
        ) {
          const actualFails = ele.failingTests.filter(
            (thing, index, self) =>
              index === self.findIndex((t) => t.testName === thing.testName)
          );
          return { ...ele, ...{ failingTests: actualFails } };
        }
        return ele;
      });
      setRepresentableEvaluationResults(finalResult);
    } else {
      setRepresentableEvaluationResults(temp);
    }
  }, [
    evaluationResults,
    evalTemplateRules,
    loanInputs,
    propertyInputs,
    showIntermediateValues,
    waiversCreated,
    loanId,
    loanStage,
    hideDuplicateFails
  ]);
  const getItem = (item: any) => {
    if (isFailedTestTab) {
      if (item.overallFailingTests === "PASS") {
        const tempItem = JSON.stringify(item);
        const testItem = JSON.parse(tempItem);
        testItem.failingTests = [];
        return testItem;
      }
      return item;
    }
    return item;
  };

  useEffect(() => {
    const generalWaiver = representableEvaluationResults.filter(
      (item: any) => item.testGroupName === "General Waiver Request"
    );
    // Logic to show only the latest rescind waiver
    const newGeneralWaiver: any[] = [];
    let latestTimeRescindWaiver = "";
    if (generalWaiver?.[0]?.failingTests?.length > 1) {
      let rescindWaiver = 0;
      let latestTime = "";
      generalWaiver[0].failingTests.forEach((item: any) => {
        if (
          item.waiverData.waiverInfo?.finalResponse.toLowerCase() ===
            waiverStatusLowerCase.rescind &&
          item.waiverData.waiverResponses?.length
        ) {
          const getLatestTime = getLatestWaiverResponse(
            item.waiverData.waiverResponses
          ).pop().responseDate;
          if (rescindWaiver > 0) {
            if (new Date(getLatestTime) > new Date(latestTime)) {
              latestTime = getLatestTime;
              // newGeneralWaiver.push(item);
              latestTimeRescindWaiver = item;
            }
          } else {
            latestTime = getLatestTime;
            // newGeneralWaiver.push(item)
            latestTimeRescindWaiver = item;
          }
          rescindWaiver++;
        } else {
          newGeneralWaiver.push(item);
        }
      });
    }
    if (latestTimeRescindWaiver) {
      newGeneralWaiver.push(latestTimeRescindWaiver);
    }
    const generalWaiverCopy = JSON.parse(JSON.stringify(generalWaiver));

    if (generalWaiverCopy[0]?.failingTests?.length > 1) {
      generalWaiverCopy[0].failingTests = newGeneralWaiver;
    }
    setGeneralWaiver(generalWaiverCopy);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
  }, [representableEvaluationResults]);

  useEffect(() => {
    const testsArray = representableEvaluationResults.filter(
      (item: any) => item.testGroupName !== "General Waiver Request"
    );
    let count = 0;
    testsArray.forEach((item: any) => {
      if (item.overallResults !== "PASS" && item.failingTests) {
        count += item.failingTests.length;
      }
    });
    setTestFailCount(count);
  }, [representableEvaluationResults]);

  //= ==================================== Add Scenario =====================================================================================================

  const scenarioArr: any[] = [];
  const attachedScenarioListArr = useSelector<
    RootState,
    { attachedScenarioDetails: any[] | any }
  >(
    (state: { scenarioResultsStore: { attachedScenarioDetails: any } }) =>
      state.scenarioResultsStore?.attachedScenarioDetails
  );

  if (
    attachedScenarioListArr instanceof Array &&
    attachedScenarioListArr?.length
  ) {
    attachedScenarioListArr?.forEach(
      (item: { _source: { scenarioId: any } }) => {
        attachedScenarioIdsArr.push(item._source?.scenarioId);
      }
    );
  }
  const handleAddActionClick = (e: any) => {
    dispatch(showLoader());
    const val = e.target.value?.trim();
    const fromPreSubmitLoan = true;
    const listView = val
      ? getScenarioListFromElasticSearch(
          fromPreSubmitLoan,
          loanType,
          "",
          150,
          attachedScenarioIdsArr,
          val
        )
      : getScenarioListFromElasticSearch(
          fromPreSubmitLoan,
          loanType,
          "",
          150,
          attachedScenarioIdsArr
        );
    listView
      .then((response: any) => {
        dispatch(
          updateScenarioDetailsListInRedux(
            response.data?.response?.hits?.hits,
            response.data?.response?.hits?.total?.value
          )
        );
        const data = response.data?.response?.hits?.hits;
        if (!isNullOrUndefined(data)) {
          data.forEach((item: any) => {
            if (item?._source?.scenarioId) {
              const scenarioID = {
                scenarioId: `${item?._source?.scenarioId} - ${
                  item?._source?.waiver.title || ""
                }`
              };
              scenarioArr.push(scenarioID);
            }
          });
          setScenarioIDArr(scenarioArr);
          dailogHandler();
          dispatch(hideLoader());
        } else {
          dispatch(hideLoader());
        }
      })
      .catch((err: any) => {
        dispatch(clearLoader());
        console.log("err", err);
      });
  };

  const handleAddScenario = () => {
    dispatch(showLoader());
    setOpenDialog(false);
    attachScenarioToLoans(loanId, selectedTags)
      .then((res: any) => {
        setTimeout(() => {
          dispatch(getAllDiscussions(loanId, loanStage, 0, 0, {}, "", true));
          setSelectedTags([]);
          dispatch(hideLoader());
        }, 3000);
      })
      .catch((err: any) => {
        console.log("err", err);
        dispatch(clearLoader());
      });
  };
  const dailogHandler = () => {
    const dialogData = {
      topic: "delete",
      // head: "Attach to Scenario Id"
      // head: "Scenario"
      head: "Attach Scenario ID to Loan ID"
    };
    setDialogdetails(dialogData);
    setOpenDialog(true);
  };

  //= ================================================================================================================================================================

  return (
    <>
      <Grid>
        {/* {openWarning && (
          <WarningModal
            isOpen={openWarning.isOpen}
            handleClose={openWarning.handleClose}
            handleOptional={openWarning.handleOptional}
            primaryBtnName={openWarning.primaryBtnName}
            header={openWarning.header}
            body={openWarning.body}
          />
        )} */}
        {showInfo && (
          <Grid
            style={{
              padding: "15px 0"
            }}
          >
            <Grid
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 9,
                backgroundColor: "#e9fafd",
                borderRadius: 4,
                margin: "0 15px 15px"
              }}
            >
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center"
                }}
              >
                <InfoOutlinedIcon
                  style={{ width: 15, height: 15 }}
                  htmlColor="#32325d"
                />
                <Typography
                  variant="h4"
                  style={{
                    fontSize: 14,
                    color: "#32325d",
                    fontWeight: 600,
                    marginLeft: 9
                  }}
                >
                  Info
                </Typography>
              </Grid>
              <Typography
                variant="h4"
                style={{ fontSize: 12, color: "#32325d", marginTop: 9 }}
              >
                You can request a waiver if any of the tests ‘Fail’.{" "}
                {!isLATUser() && (
                  <span>
                    Click on “General Waiver Request” if you want to request
                    waiver for any other reason.
                  </span>
                )}
              </Typography>
            </Grid>

            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              // className={classes.viewDetailsConatiner}
              style={{ borderTop: "1px solid #f2f2f2", padding: "0 15px" }}
            >
              {!isLATUser() && showWaiverRequestButton && (
                <Button
                  variant="contained"
                  size="small"
                  data-testid="request-waiver-btn"
                  className={classes.viewDetailsbutton}
                  startIcon={
                    <img src={addGeneralWaiver} alt="Request Waiver" />
                  }
                  color="info"
                  // onClick={handleRequestWaiver}
                  onClick={redirectToWaiverRequest} //no restriction on number of waivers TA-4097
                  disabled={
                    isViewMode ||
                    isWithdrawnLoan ||
                    (loanStage !== LoanStage.fes &&
                      (loanState === LoanStatusEnum.DataEntry ||
                        loanState === LoanStatusEnum.Submit))
                  }
                >
                  General Waiver Request
                </Button>
              )}

              <Button
                variant="contained"
                size="small"
                data-testid="request-waiver-btn"
                className={classes.scenarioButton}
                // startIcon={<img src={addIcon} alt="add scenario" />}
                onClick={handleAddActionClick}
                disabled={
                  isViewMode ||
                  [Purchased, Withdrawn].includes(loanState) ||
                  isPurchasedLoansOnly()
                }
                color="info"
              >
                Attach Scenario
              </Button>
            </Grid>
          </Grid>
        )}

        {/* request failed */}
        <Grid style={{ border: "solid 1px #f2f2f2", height: 1 }} />
        {/* {representableEvaluationResults?.length <= 0 && (
          <Grid
            xs={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid style={{ marginTop: 15 }}>
              <CircularProgress size={20} />
            </Grid>
          </Grid>
        )} */}
        {!isWaiverPending &&
          !isWaiverApproved &&
          generalWaiverRequest.map((item: RepresentableEvaluationResults) => {
            return (
              <Grid spacing={2} style={{ backgroundColor: "#f8f9ff" }}>
                <InputSectionWrapper
                  sectionLabel={`GENERAL WAIVER (${
                    generalWaiverRequest[0].failingTests &&
                    generalWaiverRequest[0].failingTests.length
                  })`}
                  sectionId="input-scenario-id"
                >
                  {item.overAllResults === "PASS" && !isWaiverPending ? (
                    <div className={classes.cardWrap}>
                      <PassedSectionWrapper
                        item={item}
                        isMinimizable={showIntermediateValues}
                        showIntermediateValues={showIntermediateValues}
                        resultType={resultType}
                        infoContentResult={infoContentResult}
                      />
                    </div>
                  ) : (
                    <div className={classes.cardWrap}>
                      <FailedSectionWrapper
                        item={getItem(item)}
                        errorAddressList={errorAddressList}
                        failureCardSize={failureCardSize}
                        isMinimizable={isMinimizable}
                        dividerRequired={dividerRequired}
                        redirectionRequired={redirectionRequired}
                        showIntermediateValues={showIntermediateValues}
                        numberOfWaiversCreated={numberOfWaivers(waiversCreated)}
                        isWaiverPending={isWaiverPending}
                        resultType={resultType}
                        infoContentResult={infoContentResult}
                        isFailedTestTab={isFailedTestTab}
                        evaluationPanel={evaluationPanel}
                      />
                    </div>
                  )}
                </InputSectionWrapper>
              </Grid>
            );
          })}
        {!isWaiverPending && !isWaiverApproved && (
          <>
            {" "}
            {/* <div className={classes.divider} /> */}
            <Grid spacing={2}>
              <InputSectionWrapper
                sectionLabel={`TESTS (${testsFailCount})`}
                sectionId="input-scenario-id"
              >
                {isToorvalLoan ? (
                  <ToorvalAppraisalStatus
                    propInfotoorvalId={propInfotoorvalId || null}
                  />
                ) : null}
                {representableEvaluationResults.map(
                  (item: RepresentableEvaluationResults) => {
                    if (item.testGroupName !== "General Waiver Request")
                      return (
                        <>
                          {(item.overAllResults === "PASS" ||
                            (item.overallFailingTests === "PASS" &&
                              item.failingTests.length === 0)) &&
                          !isWaiverPending ? (
                            <div
                              style={{
                                backgroundColor: evaluationPanel
                                  ? "#f8f9ff"
                                  : "#ffffff"
                              }}
                            >
                              <div
                                style={{
                                  border: "1px solid #e9ecef",
                                  margin: "5px",
                                  borderRadius: 4
                                }}
                              >
                                <PassedSectionWrapper
                                  item={item}
                                  isMinimizable={showIntermediateValues}
                                  showIntermediateValues={
                                    showIntermediateValues
                                  }
                                  resultType={resultType}
                                  infoContentResult={infoContentResult}
                                />
                              </div>
                            </div>
                          ) : (
                            <FailedSectionWrapper
                              item={getItem(item)}
                              errorAddressList={errorAddressList}
                              failureCardSize={failureCardSize}
                              isMinimizable={isMinimizable}
                              dividerRequired={dividerRequired}
                              redirectionRequired={redirectionRequired}
                              showIntermediateValues={showIntermediateValues}
                              numberOfWaiversCreated={numberOfWaivers(
                                waiversCreated
                              )}
                              isWaiverPending={isWaiverPending}
                              resultType={resultType}
                              infoContentResult={infoContentResult}
                              isFailedTestTab={isFailedTestTab}
                              evaluationPanel={evaluationPanel}
                            />
                          )}
                        </>
                      );
                    return null;
                  }
                )}
              </InputSectionWrapper>
              {!isWaiverPending ? (
                <Grid style={{ border: "solid 1px #f2f2f2", height: 1 }} />
              ) : null}
            </Grid>
          </>
        )}

        {/* 
        {/* waiver pending */}
        {/* {isWaiverPending || isWaiverApproved ? (
          <Grid style={{ border: "solid 1px #f2f2f2", height: 1 }} />
        ) : null} */}
        {representableEvaluationResults.map(
          (item: RepresentableEvaluationResults) => {
            return (
              <Grid spacing={2}>
                {/* eslint-disable-next-line */}
                {item.testGroupName === "General Waiver Request" ||
                (item.overAllResults === "FAIL" &&
                  item?.overallFailingTests !== "PASS" &&
                  isWaiverPending &&
                  !isWaiverApproved) ? (
                  <WaiverPendingWrapper
                    item={item}
                    errorAddressList={errorAddressList}
                    failureCardSize={failureCardSize}
                    isMinimizable={isMinimizable}
                    dividerRequired={dividerRequired}
                    redirectionRequired={redirectionRequired}
                    showIntermediateValues={showIntermediateValues}
                    numberOfWaiversCreated={numberOfWaivers(waiversCreated)}
                    isWaiverPending={isWaiverPending}
                  />
                ) : null}
              </Grid>
            );
          }
        )}

        {/* waiver Approved */}
        {/* {isWaiverPending || isWaiverApproved ? (
          <Grid style={{ border: "solid 1px #f2f2f2", height: 1 }} />
        ) : null} */}
        {representableEvaluationResults.map(
          (item: RepresentableEvaluationResults) => {
            return (
              <Grid spacing={2}>
                {/* eslint-disable-next-line */}
                {item.testGroupName === "General Waiver Request" ||
                (item.overAllResults === "FAIL" &&
                  !isWaiverPending &&
                  isWaiverApproved) ? (
                  <WaiverApproved
                    item={item}
                    errorAddressList={errorAddressList}
                    failureCardSize={failureCardSize}
                    isMinimizable={isMinimizable}
                    dividerRequired={dividerRequired}
                    redirectionRequired={redirectionRequired}
                    showIntermediateValues={showIntermediateValues}
                    numberOfWaiversCreated={numberOfWaivers(waiversCreated)}
                    isWaiverApproved={isWaiverApproved}
                  />
                ) : null}
              </Grid>
            );
          }
        )}
        {/* Sceanrio Attached */}
        <Grid>
          <ScenarioCompactView />
        </Grid>
      </Grid>
      <AttachScenarioDialog
        dialogDetails={dialogdetails}
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
        setSelectedTags={setSelectedTags}
        selectedTags={selectedTags}
        tagData={scenarioIDArr}
        handleSave={handleAddScenario}
        name="scenarioId"
        label="Select Scenario"
        handleChange={handleAddActionClick}
      />
    </>
  );
}
