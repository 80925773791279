import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: "100%",
      backgroundColor: "#F8F9FF",
      overflow: "hidden"
    },
    subHeaderContainer: {
      alignItems: "center",
      width: "100%",
      height: 64,
      zIndex: 1,
      position: "relative",
      marginTop: "60px",
      padding: "0 20px 0 0",
      backgroundColor: "#ffffff",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "70px"
      }
    },
    documentData: {
      color: "#5e72e4",
      cursor: "pointer"
    },
    headerText: {
      fontSize: "14px",
      color: "#32325d"
    },
    header: {
      padding: "16px 10px 16px 10px",
      color: "#32325d",
      fontSize: "14px",
      fontWeight: 600,
      backgroundColor: "#f6f9fc"
    },
    completedDocumentHeader: {
      backgroundColor: "#f6f9fc",
      paddingLeft: "15px"
    },
    tableContent: {
      display: "flex",
      padding: "8px 0",
      alignItems: "center",
      borderBottom: "1px solid #dddddd",
      "&:hover": {
        backgroundColor: "#f8f9ff",
        border: "1px solid #5f72e4",
        borderRadius: "4px"
      }
    },
    controlsBox: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#ffffff",
      width: "100%",
      margin: "0",
      padding: "5px 20px 5px 20px",
      maxHeight: 60,
      borderTop: "solid 1px #e9ecef"
    },
    saveBtn: {
      padding: "7px 24px 7px 24px",
      borderRadius: 4,
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      borderWidth: 1,
      borderColor: "#ececec",
      width: "90px",
      height: "36px"
    },
    splitBtn: {
      padding: "7px 24px 7px 24px",
      borderRadius: 4,
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      borderWidth: 1,
      marginLeft: "7px",
      borderColor: "#ececec",
      height: "36px"
    },
    rightBoxContainer: {
      width: "48%",
      height: "89%",
      backgroundColor: "#ffffff",
      overflowY: "scroll",
      borderTop: "solid 1px #e9ecef"
    },
    completeContainer: {
      width: "96%",
      minHeight: "75vh",
      backgroundColor: "#ffffff",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      overflowY: "scroll"
    },
    dragBtn: {
      display: "flex",
      width: "5%",
      justifyContent: "center"
    },
    showAll: {
      display: "flex",
      alignItems: "center",
      fontWeight: 600,
      fontSize: "14px",
      marginRight: "15px"
    },
    clearIcon: {
      width: "30px",
      height: "30px",
      marginLeft: "15px"
    },
    clearSearchIcon: {
      width: "16px",
      height: "16px",
      cursor: "pointer"
    },
    divider: {
      height: "80px",
      width: "1.5px",
      backgroundColor: "#d7d8db"
    },
    tabTypography: {
      display: "flex",
      justifyContent: "start",
      paddingLeft: "30px",
      padding: "0 10px 0 0",
      width: "100%",
      height: "100%",
      cursor: "pointer",
      borderRight: "solid 1px #e9ecef"
    },
    selectedTab: {
      display: "flex",
      justifyContent: "start",
      paddingLeft: "30px",
      padding: "0 10px 0 0",
      width: "100%",
      height: "100%",
      cursor: "pointer",
      color: "black",
      borderRight: "solid 1px #e9ecef",
      fontWeight: 600
    },
    selectedStatusLabel: {
      fontSize: "smaller",
      color: "#1079EE",
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      textTransform: "uppercase"
    },
    statusLabel: {
      fontSize: "smaller",
      display: "flex",
      justifyContent: "start",
      textTransform: "uppercase"
    },
    completedLabel: {
      fontSize: "smaller",
      color: "#66DBA9",
      display: "flex",
      justifyContent: "start",
      textTransform: "uppercase"
    },
    skippedLabel: {
      fontSize: "smaller",
      color: "yellow",
      display: "flex",
      justifyContent: "start",
      textTransform: "uppercase"
    },
    checkIcon: {
      color: "#66DBA9",
      height: "25px",
      fontWeight: 600
    },
    tabContainer: {
      width: "100%",
      height: "80px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      position: "relative",
      top: -7
    },
    dataText: {
      fontSize: "13px",
      color: "#32325d",
      fontWeight: "normal",
      padding: "15px"
    },
    dropDownContainer: {
      width: "90%",
      position: "relative",
      backgroundColor: "white",
      borderRadius: "4px",
      "& .MuiInputBase-root": {
        fontSize: "12px"
      },
      "&:hover": {
        backgroundColor: "white",
        border: "none"
      },
      "& .MuiSelect-select": {
        color: "#32325d",
        fontSize: "13px",
        "&:focus": {
          margin: "2px 0 0 0 !important",
          backgroundColor: "white !important",
          padding: "10px 14px !important"
        }
      }
    },
    documentInfoCheckData: {
      flexGrow: 0,
      maxWidth: "30%",
      flexBasis: "30%",
      display: "flex",
      height: "50px",
      "& .MuiButtonBase-root ": {
        minWidth: "0px"
      },
      position: "relative",
      left: "5%"
    },
    lowConfidence: {
      color: "#f75676"
    },
    lowConfidenceDrop: {
      border: "0.7px solid #f75676",
      "&:hover": {
        backgroundColor: "white",
        border: "0.7px solid #f75676"
      }
    },
    dropdown: {
      backgroundColor: "white",
      "& .MuiSelect-select": {
        color: "#32325d",
        fontSize: "13px",
        "&:focus": {
          margin: "2px 0 0 0 !important",
          backgroundColor: "white !important",
          padding: "10px 14px !important"
        }
      }
    },

    arrow: {
      color: "white"
    },
    tooltip: {
      color: "#32325d",
      backgroundColor: "white",
      fontFamily: `"Open Sans", sans-serif !important`,
      fontSize: 12,
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      minWidth: "185px",
      maxWidth: "400px",
      minHeight: "40px",
      padding: "9px"
    },
    inputValue: {
      border: "solid 1px #cad1d7",
      borderRadius: "4px",
      background: "#ffffff",
      color: "#000000",
      padding: "2px 5px",
      height: "36px",
      margin: "10px 0",
      width: "85%",
      fontSize: 13
    },
    documentInfo: {},
    dateContainer: {
      width: "85% !important",
      position: "relative",
      paddingTop: "10px",
      "& input": {
        visibility: "hidden"
      },
      "& fieldset": {
        height: 41
      },
      "& label": {
        color: "#000000",
        top: "2px",
        padding: "2px 8px !important"
      },
      "& label + div": {
        marginTop: "0px !important",
        height: 36,
        background: "#ffffff"
      },
      "& span": {
        color: "black !important",
        fontWeight: "normal !important"
      }
    },
    checkBox: {
      alignSelf: "center"
    },
    dropDownText: {
      fontSize: "13px",
      fontWeight: 600
    },
    statusText: {
      color: "#10010090",
      fontSize: "11px"
    },
    width100: {
      width: "100%"
    },
    width90: {
      width: "90%"
    },
    width75: {
      width: "75%"
    },
    width60: {
      width: "60%"
    },
    width50: {
      width: "50%"
    },
    width45: {
      width: "45%"
    },
    width40: {
      width: "40%"
    },
    width35: {
      width: "35%"
    },
    width30: {
      width: "30%"
    },
    width25: {
      width: "25%"
    },
    width20: {
      width: "20%"
    },
    width15: {
      width: "15%"
    },
    width10: {
      width: "10%"
    },
    width5: {
      width: "5%"
    },
    rightBtn: {
      color: "#008000"
    },
    crossBtn: {
      color: "#FF0000"
    },
    defaultBtn: {
      color: "#000000"
    },
    pageOutline: {
      border: "1px solid rgb(160 160 253 / 41%)",
      boxShadow:
        " 0 -5px 5px -5px rgba(50, 50, 93, 0.21), -5px 0 5px -5px rgba(50, 50, 93, 0.21), 5px 0 5px -5px rgba(50, 50, 93, 0.21)",
      padding: "3px",
      borderBottom: 0,
      display: "flex",
      position: "relative",
      justifyContent: "center"
    },
    centerCanvas: {
      "& canvas": {
        display: "table",
        margin: "0 auto"
      }
    },
    pdfContainer: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      borderRadius: 4,
      justifyContent: "start",
      overflowX: "auto",
      alignItems: "start",
      width: "100%",
      height: "100%",
      background: "none",
      msOverflowStyle: "none",
      "& .rpv-default-layout__container": {
        border: "none"
      },
      "& .rpv-default-layout__sidebar--ltr": {
        border: "none"
      },
      "& .rpv-default-layout__sidebar-headers": {
        backgroundColor: "transparent"
      },
      "& .rpv-default-layout__toolbar": {
        backgroundColor: "transparent",
        border: "none"
      },
      "& .rpv-core__inner-pages--vertical": {
        top: 13
      },
      "& .rpv-default-layout__body": {
        overflow: "hidden"
      }
    },
    highLightClass: {
      border: "1px solid #5e72e4",
      boxShadow:
        " 0 -5px 5px -5px rgba(50, 50, 93, 0.21), -5px 0 5px -5px rgba(50, 50, 93, 0.21), 5px 0 5px -5px rgba(50, 50, 93, 0.21)",
      padding: "3px",
      borderBottom: 0,
      display: "flex",
      position: "relative",
      justifyContent: "center"
    },
    pageOutlinePageNumber: {
      textAlign: "center",
      border: "1px solid rgb(160 160 253 / 41%)",
      boxShadow:
        "0 5px 5px -5px rgba(50, 50, 93, 0.21), -5px 0 5px -5px  rgba(50, 50, 93, 0.21),5px 0 5px -5px rgba(50, 50, 93, 0.21)",
      padding: "3px",
      borderTop: 0
    },
    highLightPageNumber: {
      textAlign: "center",
      border: "1px solid #5e72e4",
      boxShadow:
        "0 5px 5px -5px rgba(50, 50, 93, 0.21), -5px 0 5px -5px  rgba(50, 50, 93, 0.21),5px 0 5px -5px rgba(50, 50, 93, 0.21)",
      padding: "3px",
      borderTop: 0
    },
    loaderContainer: {
      zIndex: 1,
      display: "flex",
      position: "relative",
      borderRadius: 4,
      width: "100%",
      height: "100%",
      backgroundColor: "#ffffff",
      opacity: 0.6,
      overflowX: "hidden"
    },
    pageInput: {
      padding: "0px 2px",
      width: 90,
      display: "flex",
      alignItems: "center",
      "& input": {
        paddingLeft: 5
      }
    },
    toolbarIcon: {
      padding: 7,
      "&:disabled": {
        opacity: 0.5
      },
      "&:hover": {
        transform: "scale(1.2)"
      }
    },
    zoomOut: {
      "&:hover": {
        transform: "scale(0.8)"
      }
    },
    highlightCheckbox: {
      position: "relative",
      margin: 0,
      "& .MuiTypography-body1": {
        fontSize: 12,
        fontWeight: "normal"
      }
    },
    searchButton: {
      "& svg": {
        transform: "translateY(3px)"
      }
    }
  })
);
