/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import { FrontendSizerSectionMapping } from "./FrontendSizerSectionMapping";
import {
  fesBridgeProperty,
  fesThirtyYearProperty
} from "./FrontendSizerTemplate";
import { RootState } from "../stores/rootReducer";
import { LoanDetails } from "../create-loan/create-loan.reducerHelper";
import { MultipleUnitsWrapper } from "../create-loan/property-details/30-year-loan-property-details/MultipleUnitsWrapper";
import { getLoanType } from "../config/config";

export function FrontendSizerProperty(props: {
  isEvaluationPanelVisible: boolean;
}) {
  const { activePropertyId, propertyDetails, loanState } = useSelector<
    RootState,
    LoanDetails
  >((state) => state.createLoanStore.loanDetails);
  const { isViewMode } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );
  const { loanType } = useParams<any>() as any;
  const { isEvaluationPanelVisible } = props;
  const template =
    loanType === getLoanType[0].displayValue
      ? fesBridgeProperty
      : fesThirtyYearProperty;
  return (
    <Grid style={{ flexBasis: "revert" }} xs={12}>
      {template.map((section) => {
        if (section.sectionName === "unitInformation") {
          return (
            <MultipleUnitsWrapper
              unitInformation={
                propertyDetails.find(
                  (element) => element.propertyId === activePropertyId
                )?.unitsInformation
              }
              isEvaluationPanelVisible={isEvaluationPanelVisible}
              unitInfoFields={section.fields}
              isAllowedToEdit={
                [LoanStatusEnum.Approved, LoanStatusEnum.Rejected].filter(
                  (state: LoanStatusEnum) => loanState === state
                ).length === 0 && !isViewMode
              }
            />
          );
        }
        return (
          <FrontendSizerSectionMapping
            sectionData={section}
            isEvaluationPanelVisible={isEvaluationPanelVisible}
            tabType="properties"
            activePropertyId={activePropertyId}
          />
        );
      })}
    </Grid>
  );
}
