import React from "react";
import {
  DropDown,
  GridTextFieldForm as TextFieldForm,
  GridDatePickerForm as DatePickerForm
} from "@toorak/tc-common-fe-sdk";
import {
  GridTextFieldProps as TextFieldProps,
  DropDownProps,
  DatePickerProps
} from "./FormComponentsProps";
import { CustomizedTextField } from "../../utils/customizedTextField";

export function NoOfPropertiesTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    isViewMode,
    editedData,
    infoIconData,
    onBlur,
    editedLabel,
    enableReason,
    isServicerMandatory,
    label
  } = props;

  return (
    <TextFieldForm
      required
      value={value}
      onChange={onChange}
      label={label ? label : "Number of Properties"}
      error={error}
      infoIconData={infoIconData}
      editedData={editedData}
      isViewMode={isViewMode}
      helperText={helperText}
      data-testid="no-of-properties-id"
      valueTestId="no-of-properties-id-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
}

export function InterestAccrualDateField(props: DatePickerProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    isViewMode,
    onBlur,
    infoIconData,
    editedData,
    editedLabel,
    required,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <DatePickerForm
      value={value as any}
      onChange={onChange as any}
      label="Interest Accrual Date"
      required={required}
      error={error}
      infoIconData={infoIconData}
      onBlur={onBlur}
      editedData={editedData}
      isViewMode={isViewMode}
      helperText={helperText}
      editedLabel={editedLabel}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
      renderInput={(props: any) => (
        <CustomizedTextField
          props={props}
          data-testid="interest-accrual-date"
          value={value}
          error={error}
          helperText={helperText}
          isViewMode={isViewMode}
          valueTestId="interest-accrual-date-value"
        />
      )}
    />
  );
}

export function NextPaymentDateField(props: DatePickerProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    isViewMode,
    onBlur,
    infoIconData,
    editedData,
    editedLabel,
    required,
    enableReason,
    isServicerMandatory
  } = props;
  return (
    <DatePickerForm
      value={value as any}
      onChange={onChange as any}
      label="Next Payment Due Date"
      required={required}
      error={error}
      infoIconData={infoIconData}
      onBlur={onBlur}
      editedData={editedData}
      isViewMode={isViewMode}
      helperText={helperText}
      // valueTestId="first-payment-date-value"
      // data-testid="first-payment-date"
      editedLabel={editedLabel}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
      renderInput={(props: any) => (
        <CustomizedTextField
          props={props}
          data-testid="next-payment-date"
          value={value}
          error={error}
          helperText={helperText}
          isViewMode={isViewMode}
          valueTestId="next-payment-date-value"
        />
      )}
    />
  );
}

export function PrimaryLoanIDTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    isViewMode,
    onBlur,
    editedData,
    editedLabel,
    infoIconData,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      required
      value={value}
      editedData={editedData}
      onChange={onChange}
      label="Primary Loan Id"
      error={error}
      isViewMode={isViewMode}
      helperText={helperText}
      data-testid="primary-loan-id"
      valueTestId="primary-loan-id-value"
      onBlur={onBlur}
      infoIconData={infoIconData}
      editedLabel={editedLabel}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
}

export function LoanStructureDropDown(props: DropDownProps) {
  const {
    selectionValue,
    selectionOnChange,
    selectionData,
    editedData,
    helperText,
    isViewMode,
    onBlur,
    infoIconData,
    editedLabel,
    isLATView,
    notRequired,
    enableReason,
    isServicerMandatory
  } = props;
  return (
    <DropDown
      isViewMode={isViewMode}
      formControlTestId="loan-structure"
      inputLabelId="loan-structure-label"
      inputLabelTestId="loan-structure-label-testid"
      label="Loan Structure"
      infoIconData={infoIconData}
      editedData={editedData}
      selectId="loan-structure-selectid"
      selectTestId="loan-structure-select-testid"
      selectionValue={selectionValue}
      isLATView={isLATView}
      selectionOnChange={selectionOnChange}
      selectionData={selectionData}
      helperText={helperText}
      onBlur={onBlur}
      // style={{ marginTop: 38 }}
      valueTestId="loan-structure-value"
      data-testid="loan-structure-value"
      editedLabel={editedLabel}
      notRequired={notRequired}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
}
export function LoanPurposeDropDown(props: DropDownProps) {
  const {
    selectionValue,
    selectionOnChange,
    selectionData,
    editedData,
    helperText,
    infoIconData,
    isViewMode,
    isLATView,
    onBlur,
    editedLabel,
    notRequired,
    enableReason,
    isServicerMandatory
  } = props;
  return (
    <DropDown
      isViewMode={isViewMode}
      formControlTestId="loan-purpose"
      inputLabelId="loan-purpose-label"
      inputLabelTestId="loan-purpose-testid"
      label="Loan Purpose"
      editedData={editedData}
      isLATView={isLATView}
      infoIconData={infoIconData}
      selectId="loan-purpose-selectid"
      selectTestId="loan-purpose-select-testid"
      selectionValue={selectionValue}
      onBlur={onBlur}
      selectionOnChange={selectionOnChange}
      selectionData={selectionData}
      helperText={helperText}
      // style={{ marginTop: 15 }}
      valueTestId="loan-purpose-value"
      editedLabel={editedLabel}
      notRequired={notRequired}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
}

export function CutOffDateTextField(props: DatePickerProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    infoIconData,
    isViewMode,
    onBlur,
    editedData,
    editedLabel,
    required,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <DatePickerForm
      value={value as any}
      onChange={onChange as any}
      label="Cut-off Date"
      required={required}
      error={error}
      infoIconData={infoIconData}
      onBlur={onBlur}
      editedData={editedData}
      isViewMode={isViewMode}
      helperText={helperText}
      // valueTestId="cut-off-date-value"
      // data-testid="cut-off-date"
      editedLabel={editedLabel}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
      renderInput={(props: any) => (
        <CustomizedTextField
          props={props}
          data-testid="cut-off-date"
          value={value}
          error={error}
          helperText={helperText}
          isViewMode={isViewMode}
          valueTestId="cut-off-date-value"
        />
      )}
    />
  );
}

export function OriginationDateTextField(props: DatePickerProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    isViewMode,
    editedData,
    infoIconData,
    onBlur,
    editedLabel,
    required,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <DatePickerForm
      value={value as any}
      onChange={onChange as any}
      label="Origination Date"
      required={required}
      error={error}
      infoIconData={infoIconData}
      isViewMode={isViewMode}
      onBlur={onBlur}
      editedData={editedData}
      helperText={helperText}
      // valueTestId="origination-date-value"
      // data-testid="origination-date"
      editedLabel={editedLabel}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
      renderInput={(props: any) => (
        <CustomizedTextField
          props={props}
          data-testid="origination-date"
          value={value}
          error={error}
          helperText={helperText}
          isViewMode={isViewMode}
          valueTestId="origination-date-value"
        />
      )}
    />
  );
}

export function FirstPaymentDateTextField(props: DatePickerProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    isViewMode,
    onBlur,
    infoIconData,
    editedData,
    editedLabel,
    required,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <DatePickerForm
      value={value as any}
      onChange={onChange as any}
      label="First Payment Date of Loan"
      required={required}
      error={error}
      infoIconData={infoIconData}
      onBlur={onBlur}
      editedData={editedData}
      isViewMode={isViewMode}
      helperText={helperText}
      // valueTestId="first-payment-date-value"
      // data-testid="first-payment-date"
      editedLabel={editedLabel}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
      renderInput={(props: any) => (
        <CustomizedTextField
          props={props}
          data-testid="first-payment-date"
          value={value}
          error={error}
          helperText={helperText}
          isViewMode={isViewMode}
          valueTestId="first-payment-date-value"
        />
      )}
    />
  );
}

export function OriginalMaturityDateTextField(props: DatePickerProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    isViewMode,
    onBlur,
    editedData,
    editedLabel,
    infoIconData,
    required,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <DatePickerForm
      value={value as any}
      onChange={onChange as any}
      required={required}
      label="Original Maturity Date"
      error={error}
      onBlur={onBlur}
      infoIconData={infoIconData}
      isViewMode={isViewMode}
      editedData={editedData}
      helperText={helperText}
      valueTestId="original-maturity-date-value"
      data-testid="original-maturity-date"
      editedLabel={editedLabel}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
      renderInput={(props: any) => (
        <CustomizedTextField
          props={props}
          data-testid="original-maturity-date"
          value={value}
          error={error}
          helperText={helperText}
          isViewMode={isViewMode}
          valueTestId="original-maturity-date-value"
        />
      )}
    />
  );
}
export function RecourseDropDown(props: DropDownProps) {
  const {
    selectionValue,
    selectionOnChange,
    selectionData,
    helperText,
    editedData,
    infoIconData,
    isViewMode,
    onBlur,
    isLATView,
    editedLabel,
    notRequired,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <DropDown
      isViewMode={isViewMode}
      formControlTestId="recourse"
      inputLabelId="recourse-label"
      inputLabelTestId="recourse-testid"
      label="Recourse"
      editedData={editedData}
      infoIconData={infoIconData}
      selectId="recourse-selectid"
      selectTestId="recourse-select-testid"
      onBlur={onBlur}
      selectionValue={selectionValue}
      selectionOnChange={selectionOnChange}
      selectionData={selectionData}
      helperText={helperText}
      editedLabel={editedLabel}
      isLATView={isLATView}
      // style={{ marginTop: 38 }}
      valueTestId="recourse-value"
      notRequired={notRequired}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
}
export function PledgeOfEquity(props: DropDownProps) {
  const {
    selectionValue,
    selectionOnChange,
    selectionData,
    helperText,
    editedData,
    infoIconData,
    isViewMode,
    editedLabel,
    onBlur
  } = props;

  return (
    <DropDown
      isViewMode={isViewMode}
      formControlTestId="pledgeOfEquity"
      inputLabelId="pledgeOfEquity-label"
      inputLabelTestId="pledgeOfEquity-testid"
      label="Pledge Of Equity"
      editedData={editedData}
      selectId="pledgeOfEquity-selectid"
      selectTestId="pledgeOfEquity-select-testid"
      selectionValue={selectionValue}
      selectionOnChange={selectionOnChange}
      selectionData={selectionData}
      helperText={helperText}
      infoIconData={infoIconData}
      onBlur={onBlur}
      valueTestId="pledgeOfEquity-value"
      editedLabel={editedLabel}
    />
  );
}
export function CrossDropDown(props: DropDownProps) {
  const {
    selectionValue,
    selectionOnChange,
    selectionData,
    helperText,
    infoIconData,
    editedData,
    isViewMode,
    onBlur,
    editedLabel,
    notRequired,
    isLATView,
    enableReason,
    isServicerMandatory
  } = props;
  return (
    <DropDown
      isViewMode={isViewMode}
      formControlTestId="cross"
      inputLabelId="cross-label"
      inputLabelTestId="cross-testid"
      label="Cross Collaterlization (Y/N)"
      selectId="cross-selectid"
      selectTestId="cross-select-testid"
      selectionValue={selectionValue}
      infoIconData={infoIconData}
      selectionOnChange={selectionOnChange}
      selectionData={selectionData}
      helperText={helperText}
      editedData={editedData}
      onBlur={onBlur}
      isLATView={isLATView}
      // style={{ marginTop: 38 }}
      valueTestId="cross-value"
      editedLabel={editedLabel}
      notRequired={notRequired}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
}

export function ExtensionOptionTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    isViewMode,
    editedData,
    onBlur,
    infoIconData,
    editedLabel,
    enableReason,
    isServicerMandatory
  } = props;
  return (
    <TextFieldForm
      required
      value={value}
      onChange={onChange}
      label="Extension Option (Months)"
      error={error}
      isViewMode={isViewMode}
      infoIconData={infoIconData}
      editedData={editedData}
      helperText={helperText}
      data-testid="extension-option"
      valueTestId="extension-option-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
}

export function ToorakProductDropDown(props: DropDownProps) {
  const {
    selectionValue,
    selectionOnChange,
    selectionData,
    helperText,
    editedData,
    infoIconData,
    isViewMode,
    editedLabel,
    isLATView,
    notRequired,
    onBlur,
    enableReason,
    isServicerMandatory
  } = props;
  return (
    <DropDown
      isViewMode={isViewMode}
      formControlTestId="toorak-product"
      inputLabelId="toorak-product-label"
      inputLabelTestId="toorak-product-testid"
      label="Toorak Product"
      infoIconData={infoIconData}
      selectId="toorak-product-selectid"
      selectTestId="toorak-product-select-testid"
      selectionValue={selectionValue}
      selectionOnChange={selectionOnChange}
      selectionData={selectionData}
      editedData={editedData}
      helperText={helperText}
      editedLabel={editedLabel}
      onBlur={onBlur}
      isLATView={isLATView}
      valueTestId="toorak-product-value"
      notRequired={notRequired}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
};

export function FundingChannelDropDown(props: DropDownProps) {
  const {
    selectionValue,
    selectionOnChange,
    selectionData,
    helperText,
    editedData,
    infoIconData,
    isViewMode,
    editedLabel,
    isLATView,
    notRequired,
    onBlur,
    enableReason,
    isServicerMandatory
  } = props;
  return (
    <DropDown
      isViewMode={isViewMode}
      formControlTestId="funding-channel"
      inputLabelId="funding-channel-label"
      inputLabelTestId="funding-channel-testid"
      label="Funding Channel"
      infoIconData={infoIconData}
      selectId="funding-channel-selectid"
      selectTestId="funding-channel-select-testid"
      selectionValue={selectionValue}
      selectionOnChange={selectionOnChange}
      selectionData={selectionData}
      editedData={editedData}
      helperText={helperText}
      editedLabel={editedLabel}
      onBlur={onBlur}
      isLATView={isLATView}
      valueTestId="funding-channel-value"
      notRequired={notRequired}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
};

export function ClosingDateTextField(props: DatePickerProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    isViewMode,
    editedLabel,
    infoIconData
  } = props;

  return (
    <DatePickerForm
      value={value as any}
      onChange={onChange as any}
      label="When is this 30YR loan expected to close by?"
      error={error}
      isViewMode={isViewMode}
      helperText={helperText}
      infoIconData={infoIconData}
      // valueTestId="closing-date-value"
      // data-testid="closing-date"
      editedLabel={editedLabel}
      renderInput={(props: any) => (
        <CustomizedTextField
          props={props}
          data-testid="closing-date"
          value={value}
          error={error}
          helperText={helperText}
          isViewMode={isViewMode}
          valueTestId="closing-date-value"
        />
      )}
    />
  );
}

export function MaturityDateTextField(props: DatePickerProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    isViewMode,
    editedLabel,
    editedData,
    onBlur,
    infoIconData,
    required,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <DatePickerForm
      value={value as any}
      onChange={onChange as any}
      label="Maturity Date"
      error={error}
      onBlur={onBlur}
      required={required}
      isViewMode={isViewMode}
      helperText={helperText}
      editedData={editedData}
      infoIconData={infoIconData}
      valueTestId="acquiring-date-value"
      data-testid="acquiring-date"
      editedLabel={editedLabel}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
      renderInput={(props: any) => (
        <CustomizedTextField
          props={props}
          data-testid="acquiring-date"
          value={value}
          error={error}
          helperText={helperText}
          isViewMode={isViewMode}
          valueTestId="acquiring-date-value"
        />
      )}
    />
  );
}

export function AppraisalVendorDropDown(props: DropDownProps) {
  const {
    selectionValue,
    selectionOnChange,
    selectionData,
    helperText,
    isViewMode,
    editedLabel,
    infoIconData
  } = props;
  return (
    <DropDown
      isViewMode={isViewMode}
      formControlTestId="appraisal-vendor"
      inputLabelId="appraisal-vendor-label"
      inputLabelTestId="appraisal-vendor-label-testid"
      label="Appraisal Vendor"
      selectId="appraisal-vendor-selectid"
      selectTestId="appraisal-vendor-select-testid"
      selectionValue={selectionValue}
      selectionOnChange={selectionOnChange}
      selectionData={selectionData}
      helperText={helperText}
      valueTestId="appraisal-vendor-value"
      editedLabel={editedLabel}
      infoIconData={infoIconData}
    />
  );
}

export function LoanAmountTextField(props: TextFieldProps) {
  const { value, onChange, error, helperText, onBlur, isViewMode } = props;
  return (
    <TextFieldForm
      required
      value={value}
      onChange={onChange}
      label="Initial Loan Amount"
      error={error}
      isViewMode={isViewMode}
      helperText={helperText}
      valueTestId="loan-amount-value"
      data-testid="loan-amount"
      onBlur={onBlur}
    />
  );
}

export function ReceivingProceedsDropDown(props: DropDownProps) {
  const {
    selectionValue,
    selectionOnChange,
    selectionData,
    helperText,
    isViewMode
  } = props;
  return (
    <DropDown
      isViewMode={isViewMode}
      formControlTestId="receiving-proceeds"
      inputLabelId="receiving-proceeds-label"
      inputLabelTestId="receiving-proceeds-label-testid"
      label="Is the borrower receiving proceeds at closing more than 2% of the
    loan amount?"
      selectId="receiving-proceeds-selectid"
      selectTestId="receiving-proceeds-select-testid"
      selectionValue={selectionValue}
      selectionOnChange={selectionOnChange}
      selectionData={selectionData}
      helperText={helperText}
      // style={{ marginTop: 38 }}
      valueTestId="receiving-proceeds-value"
    />
  );
}

export function FinalCharactersticsTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    isViewMode,
    infoIconData,
    onBlur
  } = props;
  return (
    <TextFieldForm
      required
      value={value}
      onChange={onChange}
      label="Final loan characterstics"
      error={error}
      isViewMode={isViewMode}
      infoIconData={infoIconData}
      helperText={helperText}
      data-testid="final-characterstics"
      valueTestId="final-characterstics-value"
      onBlur={onBlur}
    />
  );
}

export function OriginalDebtDropDown(props: DropDownProps) {
  const {
    selectionValue,
    selectionOnChange,
    selectionData,
    helperText,
    isViewMode,
    infoIconData
  } = props;
  return (
    <DropDown
      isViewMode={isViewMode}
      formControlTestId="originally-debt"
      inputLabelId="originally-debt-label"
      inputLabelTestId="originally-debt-label-testid"
      label="Was the property originally purchased with debt?"
      selectId="originally-debt-selectid"
      selectTestId="originally-debt-select-testid"
      selectionValue={selectionValue}
      selectionOnChange={selectionOnChange}
      selectionData={selectionData}
      infoIconData={infoIconData}
      helperText={helperText}
      // style={{ marginTop: 38 }}
      valueTestId="originally-debt-value"
    />
  );
}

export function PrimaryLoanID30YearLoanTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    editedLabel,
    helperText,
    isViewMode,
    editedData,
    onBlur,
    infoIconData,
    enableReason,
    isServicerMandatory
  } = props;
  return (
    <TextFieldForm
      required
      value={value}
      onChange={onChange}
      label="Originator Loan Id"
      error={error}
      infoIconData={infoIconData}
      isViewMode={isViewMode}
      helperText={helperText}
      editedData={editedData}
      data-testid="primary-loan-id"
      valueTestId="primary-loan-id-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
}

export function CondoEligibilityDropDown(props: DropDownProps) {
  const {
    selectionValue,
    selectionOnChange,
    selectionData,
    helperText,
    isViewMode,
    infoIconData,
    editedData,
    onBlur,
    modalLink,
    isLATView,
    editedLabel,
    notRequired,
    enableReason,
    isServicerMandatory
  } = props;
  return (
    <DropDown
      isViewMode={isViewMode}
      formControlTestId="condo-eligibility"
      inputLabelId="condo-eligibility-label"
      inputLabelTestId="condo-eligibility-testid"
      label="Condo Eligibility"
      selectId="condo-eligibility-selectid"
      selectTestId="condo-eligibility-select-testid"
      selectionValue={selectionValue}
      infoIconData={infoIconData}
      modalLink={modalLink}
      editedData={editedData}
      isLATView={isLATView}
      onBlur={onBlur}
      selectionOnChange={selectionOnChange}
      selectionData={selectionData}
      helperText={helperText}
      editedLabel={editedLabel}
      // style={{ marginTop: 15 }}
      notRequired={notRequired}
      valueTestId="condo-eligibility-value"
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
}

export function TotalOriginationDiscountPointsTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    infoIconData,
    isViewMode,
    editedLabel,
    required,
    editedData,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      editedData={editedData}
      label="Total Origination and Discount Points (%)"
      error={error}
      helperText={helperText}
      infoIconData={infoIconData}
      isViewMode={isViewMode}
      data-testid="origination-and-discount-points"
      valueTestId="origination-and-discount-points-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
}

export function CreditEventDropDown(props: DropDownProps) {
  const {
    selectionValue,
    selectionOnChange,
    selectionData,
    editedData,
    helperText,
    infoIconData,
    isViewMode,
    isLATView,
    onBlur,
    editedLabel,
    notRequired,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <DropDown
      isViewMode={isViewMode}
      formControlTestId="credit-event"
      inputLabelId="credit-event-label"
      inputLabelTestId="credit-event-testid"
      label="FC/SS/DIL/BK7 36 - 47mo"
      editedData={editedData}
      isLATView={isLATView}
      infoIconData={infoIconData}
      selectId="credit-event-selectid"
      selectTestId="credit-event-select-testid"
      selectionValue={selectionValue}
      onBlur={onBlur}
      selectionOnChange={selectionOnChange}
      selectionData={selectionData}
      helperText={helperText}
      // style={{ marginTop: 15 }}
      valueTestId="credit-event-value"
      editedLabel={editedLabel}
      notRequired={notRequired}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
}