import { Avatar, Chip, Grid, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import { deepPurple } from "@mui/material/colors";
import { CommentsData, userInitials } from ".";
import { constructName } from "../../utils/utils";

const cardStyles = makeStyles((theme: Theme) => ({
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500]
  },
  userName: {
    color: "#5b6880",
    fontSize: "12px"
  },
  commentText: {
    fontSize: "12px",
    color: "#32325d",
    wordBreak: "break-word"
  },
  chipRoot: {
    color: "#172b4d",
    fontSize: "10px",
    fontWeight: "bold",
    margin: "0px",
    borderRadius: "6px",
    border: "solid 1px #c4e1f6",
    backgroundColor: "#d9efff",
    height: "auto",
    marginRight: "4px",
    marginTop: "4px"
  },
  chipLabel: {
    padding: "4px"
  }
}));

const NewTag = () => {
  return (
    <div
      style={{
        fontSize: "10px",
        fontWeight: "bold",
        color: "#ffffff",
        borderRadius: "9px",
        background: "#f75676",
        padding: "1px 4px"
      }}
    >
      NEW
    </div>
  );
};

export const CommentCard = (props: CommentsData) => {
  const classes = cardStyles();
  const {
    firstName,
    middleName,
    lastName,
    comment,
    commentedDate,
    tags,
    unread
  } = props;
  return (
    <Grid container alignItems="flex-start" style={{ marginBottom: "25px" }}>
      <Grid item xs={1} style={{ justifyContent: "center", display: "flex" }}>
        <Avatar className={classes.purple}>
          {userInitials(firstName || "", lastName || "")}
        </Avatar>
      </Grid>
      <Grid item xs={10}>
        <Grid container direction="column">
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <span className={classes.userName}>
                  {constructName(firstName, middleName, lastName)}
                </span>
              </Grid>
              {unread ? (
                <Grid item>
                  <NewTag />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item>
            <div className={classes.commentText}>{comment}</div>
          </Grid>
          {tags?.length ? (
            <Grid item style={{ padding: "5px 0px" }}>
              {tags.map(tag => {
                return (
                  <Chip
                    label={tag}
                    classes={{
                      root: classes.chipRoot,
                      label: classes.chipLabel
                    }}
                  />
                );
              })}
            </Grid>
          ) : null}
          <Grid item>
            <span className={classes.userName}>{commentedDate}</span>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
