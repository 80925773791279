export enum ToorakStatusEnum {
  ignoreForFunding = "ignoreForFunding",
  satisfied = "satisfy",
  open = "open"
}

export const custodyActionDropdownValues = [
  {
    label: "Trailing Document",
    value: ToorakStatusEnum.ignoreForFunding
  }
];

export enum ActionLevel {
  documentTypeLevel = "documentTypeLevel",
  documentSakLevel = "documentSakLevel",
  exceptionSakLevel = "exceptionSakLevel",
  wholeCustodyDocsLevel = "wholeCustodyDocsLevel"
}
