import React from "react";
import { Grid, IconButton, SvgIcon } from "@mui/material";
import { renderAccountTypeImage } from "../dashboard/notification";
import {
  // AskAQuestion,
  WaiverRebuttalIcon,
  WaiverApprovedIcon,
  WaiverRejectedIcon,
  WaiverRequestIcon,
  WaiverReRequestIcon,
  WaiverReopenIcon
} from "../Icons";
import { InfoOnlyTooltip } from "../../utils/Tooltip";
// import { ReactComponent as WaiverRejected } from "../../images/waiver-rejected.svg";
// import { ReactComponent as WaiverApproved } from "../../images/waiver-approved.svg";
// import { ReactComponent as WaiverRequested } from "../../images/waiver-requested.svg";
// import { ReactComponent as WaiverRebuttal } from "../../images/waiver-rebuttal.svg";
import { ReactComponent as WaiverRescind } from "../../images/waiver-rescind.svg";
import { IconButtonDropdownStylesWithoutBG } from "../waiver-form/DocumentsForm.style";
import { IconButtonWhite } from "./DocumentsForm.style";
// import { ReactComponent as SatisfyConditions } from "../../images/satisfy-conditions.svg";
// import { ReactComponent as AskQuestion } from "../../images/ask-question.svg";
// import { ReactComponent as KeyDealMatrix } from "../../images/key-deal-matrix.svg";
//import { InfoOnlyTooltip } from "../../utils/Tooltip";
//import { IconButtonWhite } from "./DocumentsForm.style";

// const WaiverRejectedIcon = () => {
//   return (
//     <InfoOnlyTooltip title="Waiver Rejected" arrow placement="right">
//       <SvgIcon component={WaiverRejected} viewBox="0 5 14 14" />
//     </InfoOnlyTooltip>
//   );
// };

// const WaiverApprovedIcon = () => {
//   return (
//     <InfoOnlyTooltip title="Waiver Approved" arrow placement="right">
//       <SvgIcon component={WaiverApproved} viewBox="0 5 14 14" />
//     </InfoOnlyTooltip>
//   );
// };

// const WaiverRequestedIcon = () => {
//   return (
//     <InfoOnlyTooltip title="Waiver Requested" arrow placement="right">
//       <SvgIcon component={WaiverRequested} viewBox="0 0 14 14" />
//     </InfoOnlyTooltip>
//   );
// };

// const WaiverRebuttalIcon = () => {
//   return (
//     <InfoOnlyTooltip title="Waiver Rebuttal" arrow placement="right">
//       <SvgIcon component={WaiverRebuttal} viewBox="0 5 14 14" />
//     </InfoOnlyTooltip>
//   );
// };

const WaiverRescindIcon = () => {
  const iconButtonOverrideClasses = IconButtonWhite();
  return (
    // <InfoOnlyTooltip title="WAIVER RESCIND" arrow placement="right">
    <IconButton
      //aria-label="Waiver rescind history"
      component="button"
      classes={iconButtonOverrideClasses}
      onClick={() => {}}
      size="large"
    >
      <SvgIcon component={WaiverRescind} viewBox="0 0 16 16" />
    </IconButton>
    // </InfoOnlyTooltip>
  );
};

// const KeyDealMatrixIcon = () => {
//   const iconButtonOverrideClasses = IconButtonWhite();
//   return (
//     <InfoOnlyTooltip title="Key Deal Matrix" arrow placement="right">
//       <IconButton
//         aria-label="Key Deal Matrix"
//         component="button"
//         classes={iconButtonOverrideClasses}
//         onClick={() => {}}
//       >
//         <SvgIcon component={KeyDealMatrix} viewBox="0 0 16 16" />
//       </IconButton>
//     </InfoOnlyTooltip>
//   );
// };

// const SatisfyConditionsIcon = (props: { label: string }) => {
//   return (
//     <InfoOnlyTooltip title={props.label} arrow placement="right">
//       <SvgIcon component={SatisfyConditions} viewBox="0 0 14 14" />
//     </InfoOnlyTooltip>
//   );
// };

// const AskAQuestionIcon = () => {
//   return (
//     <InfoOnlyTooltip title="Ask a Question" arrow placement="right">
//       <SvgIcon component={AskQuestion} viewBox="0 0 14 14" />
//     </InfoOnlyTooltip>
//   );
// };
const getStatusValue = (value: any) => {
  switch (value) {
    case "WAIVER REQUESTED":
      return <WaiverRequestIcon />;
    case "WAIVER RE-REQUESTED":
      return <WaiverReRequestIcon />;
    case "WAIVER REOPENED":
      return <WaiverReopenIcon />;
    case "WAIVER REJECTED":
      return <WaiverRejectedIcon />;
    case "WAIVER RESCINDED":
      return <WaiverRescindIcon />;
    case "WAIVER APPROVED":
      return <WaiverApprovedIcon />;
    case "WAIVER REBUTTAL":
      return <WaiverRebuttalIcon />;
    case "WAIVER RESCIND":
      return <WaiverRescindIcon />;
    case "WAIVER APPROVED-CONDITIONALLY":
      return <WaiverApprovedIcon />;
    default:
      return;
  }
};

// const statusToIconMap: any = {
//   "WAIVER REQUESTED": <WaiverRequestedIcon />,
//   "WAIVER REJECTED": <WaiverRejectedIcon />,
//   "WAIVER APPROVED": <WaiverApprovedIcon />,
//   "WAIVER RESCINDED": <WaiverRescindIcon />,
//   "WAIVER RESCIND": <WaiverRescindIcon />,
//   "WAIVER REBUTTAL": <WaiverRebuttalIcon />
//   // "SATISFY CONDITION": <SatisfyConditionsIcon label="Satisfy Conditions" />,
//   // "SATISFY INQUIRY": <SatisfyConditionsIcon label="Satisfy Inquiries" />
// };

export const StatusComponent = (props: {
  status?: string;
  value?: string;
  waiverType?: any;
  fromDashboard?: boolean;
  style?: object;
  backgroundColorProps?: string;
}) => {
  const {
    status,
    backgroundColorProps,
    value = "",
    fromDashboard,
    waiverType,
    style = {}
  } = props;
  // eslint-disable-next-line no-var
  var backgroundColor = "#2ece89";
  const iconButtonDropdownStylesWithoutBG = IconButtonDropdownStylesWithoutBG();

  if (
    status === "REQUESTED" ||
    status === "INQUIRY RAISED" ||
    status === "OPEN" ||
    status === "SCENARIO REQUESTED" ||
    status === "Closing Comment" ||
    status === "RE-REQUESTED" ||
    status === "REBUTTAL" ||
    // status === "RESCIND" ||
    value?.toUpperCase() === "WAIVER REBUTTAL COMMENT" ||
    value?.toUpperCase() === "RESCIND WAIVER COMMENT" ||
    value?.toUpperCase() === "OPEN"
  )
    backgroundColor = "#fb623f";
  else if (
    status === "REJECTED" ||
    status === "Reject" ||
    status === "RESCINDED" ||
    status === "RESCIND" ||
    value === "RESCINDED LOAN INQUIRY" ||
    value === "REJECTED LOAN INQUIRY" ||
    value === "RESCIND LOAN INQUIRY" ||
    value?.toUpperCase() === "DOESN'T FIT TOORAK GUIDELINES" ||
    status?.toUpperCase() === "DOESN'T FIT TOORAK GUIDELINES" ||
    value?.toUpperCase() === "SCENARIO REJECTION COMMENT" ||
    value?.toUpperCase() === "RESCIND"
  )
    backgroundColor = "#f75676";
  else if (
    value?.toUpperCase() === "IN-PROGRESS" ||
    value?.toUpperCase() === "PENDING"
  )
    backgroundColor = "#172B4D";
  return (
    <>
      {(!fromDashboard || (fromDashboard && waiverType === "Scenario")) && (
        <span
          style={{
            color: "#ffffff",
            borderRadius: "6px",
            backgroundColor:
              value === "Visible only to "
                ? "#02467b"
                : backgroundColorProps
                ? backgroundColorProps
                : backgroundColor,
            padding: "3px 5px 3px 5px",
            // height: 21,
            display: "inline-block",
            alignItems: "center",
            fontWeight: 600,
            fontSize: 10,
            textTransform: "capitalize",
            whiteSpace: "nowrap",
            ...style
            // backgroundImage:
            //   value === "Visible only to Toorak"
            //     ? "linear-gradient(to right, #0fcbef, #1071ee)"
            //     : "none"
            // backgroundImage:
            //   value === "Visible only to Toorak" ? #02467b : "none"
          }}
        >
          {value?.toUpperCase() === "WAIVER RESCIND"
            ? "WAIVER RESCINDED"
            : value?.toUpperCase() === "CLOSE"
            ? "CLOSED"
            : value?.toUpperCase()}
          {value && value === "Visible only to "
            ? renderAccountTypeImage("LAT")
            : ""}
        </span>
      )}
      {/* {fromDashboard && (
        <div
          style={{
            borderRadius: "6px",
            backgroundColor,
            padding: "3px 5px 3px 5px",
            // height: 30,
            display: "flex",
            alignItems: "center",
            fontSize: "10px",
            color: "#ffffff",
            textTransform: "capitalize",
            fontWeight: 600
          }}
        >
          {value?.toUpperCase() === "WAIVER RESCIND"
            ? "WAIVER RESCINDED"
            : value?.toUpperCase()}
        </div>
      )} */}

      {fromDashboard && value && waiverType !== "Scenario" && (
        <>
          <InfoOnlyTooltip title={value?.toUpperCase()} arrow placement="right">
            <Grid item>
              <IconButton
                style={{ padding: 0 }}
                aria-label="notification-count"
                component="button"
                classes={iconButtonDropdownStylesWithoutBG}
                size="large"
              >
                {getStatusValue(value.toUpperCase())}
              </IconButton>
            </Grid>
          </InfoOnlyTooltip>
        </>
      )}
    </>
  );
};
