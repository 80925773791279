import { debounce, Grid, TextField, Tooltip, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Autocomplete } from "@mui/material";
import { LocationSuggestion, publicClient } from "@toorak/tc-common-fe-sdk";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConfig } from "../../../../config/config";
import { RootState } from "../../../../stores/rootReducer";
import arrowDown from "../../../../images/createloan/arrow-down.svg";
import { typeCast } from "../../../tape-to-file/TapeToFileUtils";
// import { UPDATE_FIELDSET } from "../../../../stores/documentReview/DocumentReview.action";
import {
  hideLoader,
  showLoader
} from "../../../../stores/loaderAndException/loaderAndException.action";
import { handlePropUpdateAfterSubmit } from "../../../property-details/PropertyDetailsForm";
import { getDuplicateCheckBody } from "../../../handle-duplicate-loans/DuplicateLoansFunctionality";
import { updateFieldSet } from "../../../../stores/documentReview/DocumentReview.reducer";
const locationMap: any = (value: any) => {
  return {
    addressLine1: {
      address: {
        street_line: value
      }
    },
    postalCode: {
      zipCode: value
    },
    city: {
      city: value
    },
    state: {
      state: value
    },
    locationValidationStatus: {
      locationValidationStatus: value
    }
  };
};

const processMap: any = {
  postalCode: "zipCode",
  city: "city",
  state: "state"
};

export const addressSuggestExtract = (payload: any) => {
  const propertyLocationObj = new Map();
  payload.forEach((element: any) => {
    if (
      ["addressLine1", "city", "state", "postalCode"].includes(element.field)
    ) {
      propertyLocationObj.set(element.loanPropertyId, {
        ...propertyLocationObj.get(element.loanPropertyId),
        ...locationMap(element.value)?.[element.field]
      });
      if (element.field === "addressLine1") {
        propertyLocationObj.set(element.loanPropertyId, {
          ...propertyLocationObj.get(element.loanPropertyId),
          ...locationMap(element.locationValidationStatus)?.[
            "locationValidationStatus"
          ]
        });
      }
    }
  });
  return propertyLocationObj;
};

export const processData = (payload: any, propertyFields: any) => {
  propertyFields.forEach((item: any) => {
    item.forEach((element: any) => {
      if (element.field === "addressLine1") {
        if (
          payload?.has(element.loanPropertyId) &&
          payload?.get(element.loanPropertyId)?.address?.street_line ===
            undefined
        ) {
          payload.set(element.loanPropertyId, {
            ...payload.get(element.loanPropertyId),
            address: {
              street_line: element?.loanInfo || "",
              locationValidationStatus: element?.locationValidationStatus
            }
          });
        }
      } else if (["postalCode", "city", "state"].includes(element.field)) {
        if (
          payload?.has(element.loanPropertyId) &&
          payload?.get(element.loanPropertyId)?.[processMap[element.field]] ===
            undefined
        ) {
          payload.set(element.loanPropertyId, {
            ...payload.get(element.loanPropertyId),

            [processMap[element.field]]: element?.loanInfo || ""
          });
        }
      }
    });
  });
  return payload;
};

export const duplicatePropertyMessage = (
  dispatch: any,
  setOpenWarning: any,
  isDupliAfterEdit: any
) => {
  dispatch(hideLoader(true));
  setOpenWarning({
    isOpen: true,
    header: "Duplicate Property",
    body: (
      <Grid container direction="column">
        <Grid item xs={12} style={{ padding: 15 }}>
          <div>
            Sorry the property cannot be modified. There is an existing loan{" "}
            <b>{isDupliAfterEdit.dupliData?.loanId || ""}</b> with the same
            property address <b>{isDupliAfterEdit.propString}</b>{" "}
            {isDupliAfterEdit.dupliData?.loanStage ? "in" : ""}{" "}
            <b>{isDupliAfterEdit.dupliData?.loanStage || ""}</b>{" "}
            {isDupliAfterEdit.dupliData?.loanStage ? "stage" : ""} that is
            submitted.
          </div>
        </Grid>
      </Grid>
    ),
    handleClose: async () => {
      setOpenWarning(null);
    }
  });
};

const checkForDuplicates = async (
  requestBody: any,
  updatedAddress: any,
  loanPropertyId: any,
  isDuplicate: any,
  dispatch: any,
  loanId: any,
  loanStage: any,
  setOpenWarning: any
) => {
  try {
    dispatch(showLoader(true));
    const isDupliAfterEdit = await handlePropUpdateAfterSubmit(
      loanId,
      loanStage,
      loanPropertyId,
      updatedAddress,
      requestBody
    );

    if (isDupliAfterEdit?.duplicateAmongstThemselves) {
      isDuplicate = true;
      dispatch(hideLoader(true));
      setOpenWarning({
        isOpen: true,
        header: "Duplicate Property",
        body: (
          <React.Fragment>
            <Grid container direction="column">
              <Grid item xs={12} style={{ padding: 15 }}>
                <div>Two properties cannot have the same address.</div>
              </Grid>
            </Grid>
          </React.Fragment>
        ),
        handleClose: async () => {
          setOpenWarning(null);
        }
      });
    } else if (isDupliAfterEdit?.isDupli) {
      isDuplicate = true;
      duplicatePropertyMessage(dispatch, setOpenWarning, isDupliAfterEdit);
    }
    dispatch(hideLoader(true));
    return isDuplicate;
  } catch (e) {
    console.log("error while deleting property", e);
    dispatch(hideLoader(true));
    return true;
  }
};

export const handleValidateDuplicate = async (
  duplicateCheck: any,
  dispatch: any,
  loanId: any,
  loanStage: any,
  setOpenWarning: any
) => {
  let isDupp: boolean = false;
  let isDuplicate: boolean = false;
  if (!duplicateCheck.size) {
    return isDupp;
  }
  const requestBody = await getDuplicateCheckBody(loanId, loanStage);
  for (const [key, value] of duplicateCheck) {
    isDupp = await checkForDuplicates(
      requestBody,
      value,
      key,
      isDuplicate,
      dispatch,
      loanId,
      loanStage,
      setOpenWarning
    );
    if (isDupp) break;
  }
  return isDupp;
};

export const AddressSuggestAndValidate = (props: {
  classes: any;
  propertyAddressField: any;
  fields: any[];
  tab: string;
  propNumber: number | undefined;
  error: boolean;
  width: string;
}) => {
  const [addressInputValue, setAddressInputValue] = React.useState("");
  const propertyDetails = useSelector<RootState, any[]>(
    (state) => state.createLoanStore.loanDetails.propertyDetails
  );
  const [addressOptions, setAddressOptions] = useState<LocationSuggestion[]>(
    []
  );
  const [activePropertyDetail, setActivePropertyDetail] = useState<any>(null);
  const {
    propertyAddressField,
    fields,
    tab,
    propNumber,
    classes,
    error,
    width
  } = props;

  const dispatch = useDispatch();
  useEffect(() => {
    const activePropertyDetails = propertyDetails.find(
      (element) => element.propertyId === propertyAddressField.loanPropertyId
    );

    setActivePropertyDetail(null);
    if (activePropertyDetails) {
      setActivePropertyDetail(activePropertyDetails);
    }
    // eslint-disable-next-line
  }, [propertyAddressField.propertyIndex, propertyDetails]);
  let propertyLocation: any = activePropertyDetail?.propertyLocation?.payload;

  useEffect(() => {
    let active = true;
    const property = propertyLocation?.address || "";
    if (addressInputValue === "" || addressInputValue === property.toString()) {
      setAddressOptions(
        propertyLocation?.address ? [propertyLocation?.address] : []
      );
      return undefined;
    }
    fetchAutoCompleteResults(
      { input: addressInputValue },
      (results?: LocationSuggestion[]) => {
        if (active) {
          let newOptions = [] as LocationSuggestion[];
          if (results) {
            newOptions = [...newOptions, ...results];
          }
          setAddressOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
    // eslint-disable-next-line
  }, [addressInputValue]);

  const setFullPropertyDetails = (newValue: any) => {
    const isManuallyEdited = newValue.city === null;
    const filterFields = JSON.parse(JSON.stringify(fields));
    filterFields?.forEach((item: any) => {
      if (propertyAddressField?.loanPropertyId === item?.loanPropertyId) {
        if (item?.field === "city") {
          if (newValue.city === null) {
            newValue.city = item.documentInfo;
          } else {
            item.documentInfo = typeCast(newValue?.city, item.type);
            removeSelectedValueInExtractionList(item, item.documentInfo);
          }
        } else if (item?.field === "postalCode") {
          if (newValue.zipcode === null) {
            newValue.zipcode = item.documentInfo;
          } else {
            item.documentInfo = typeCast(newValue?.zipcode, item.type);
            removeSelectedValueInExtractionList(item, item.documentInfo);
          }
        } else if (item?.field === "state") {
          if (newValue.state === null) {
            newValue.state = item.documentInfo;
          } else {
            item.documentInfo = typeCast(newValue?.state, item?.type);
            removeSelectedValueInExtractionList(item, item.documentInfo);
          }
        } else if (item?.type === "autoProperty") {
          item.documentInfo = typeCast(newValue?.street_line, item?.type);
          removeSelectedValueInExtractionList(item, item.documentInfo);
          if (item.documentInfo !== item.loanInfo) {
            item.locationValidationStatus = isManuallyEdited
              ? "OVERRIDDEN"
              : "COMPLETE";
          }
        }
      }
    });
    // dispatch({
    //   type: UPDATE_FIELDSET,
    //   payload: {
    //     tab,
    //     index: propNumber,
    //     updatedFieldSet: filterFields
    //   }
    // });
    dispatch(
      updateFieldSet({
        tab,
        index: propNumber,
        updatedFieldSet: filterFields
      })
    );
    return newValue;
  };

  const removeSelectedValueInExtractionList = (item: any, newDBValue: any) => {
    if (item.documentInfoDB !== newDBValue) {
      if (item?.extractedInfoDB?.length) {
        item?.extractedInfoDB.forEach((item: any) => {
          delete item?.selected;
        });
      }
    }
    item.documentInfoDB = newDBValue;
  };

  const fetchAutoCompleteResults = useCallback(
    debounce(
      (
        request: { input: string },
        callback: (results?: LocationSuggestion[]) => void
      ) => {
        publicClient
          .get(
            `https://us-autocomplete-pro.api.smartystreets.com/lookup?key=${
              getConfig().smartyStreetsApiKey
            }&search=${request.input}`
          )
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((results: any) =>
            callback(results.data.suggestions as LocationSuggestion[])
          )
          .catch(() => {});
      },
      300
    ),
    []
  );

  const uniqueArray = (a: any[]) =>
    Array.from(new Set(a.map((o) => JSON.stringify(o)))).map((s) =>
      JSON.parse(s)
    );

  const onChange = (event: any, newValue: any) => {
    let customValue = event?.target?.value;
    let updatedValue = JSON.parse(JSON.stringify(newValue));
    if (typeof newValue !== "string" && !customValue) {
      if (newValue) {
        let options = [newValue, ...addressOptions];
        options = uniqueArray(options);
        setAddressOptions(options);
      } else {
        const newAddressOptions = [...addressOptions];
        setAddressOptions(newAddressOptions);
      }
    } else {
      let value = customValue || newValue;
      if (value) {
        let splitUp = value.split(" ");
        const lastWord: any = splitUp[splitUp.length - 1];
        if (lastWord?.length === 5 && !isNaN(lastWord)) {
          value = splitUp.slice(0, -3).join(" ");
        }
      }
      updatedValue = {
        city: null,
        entries: 0,
        secondary: null,
        state: null,
        street_line: value,
        zipcode: null
      };
      setAddressInputValue(value);
    }
    setFullPropertyDetails(updatedValue);
  };
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      autoCompleteInput: {
        border: `${error ? "red" : "#cad1d7"} solid 1px`
      }
    })
  );

  return (
    <>
      <div style={{ width }}>
        <Autocomplete
          id="property-details-address"
          freeSolo
          autoSelect
          className={`${classes?.inputAutocompleteValue} ${
            useStyles()?.autoCompleteInput
          }`}
          popupIcon={
            <img
              src={arrowDown}
              alt="Expand"
              style={{
                width: "16px !important",
                height: "16px !important"
              }}
            />
          }
          disabled={propertyAddressField?.disabled}
          getOptionLabel={
            (option: any) =>
              typeof option === "string"
                ? option
                : option?.street_line
                ? `${option?.street_line} ${option?.city} ${option?.state} ${option?.zipcode}`
                : ""
            // eslint-disable-next-line react/jsx-curly-newline
          }
          filterOptions={(x: any) => x}
          options={addressOptions}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={propertyAddressField?.documentInfo}
          classes={{
            inputRoot: classes.inputRoot
          }}
          disableClearable
          onChange={(event: any, newValue: any) => {
            onChange(event, newValue);
          }}
          onInputChange={(event: any, newInputValue: any, reason: any) => {
            if (event?.type === "change") {
              onChange(event, newInputValue);
            } else {
              let value = newInputValue;
              if (newInputValue !== "") {
                let splitUp = newInputValue.split(" ");
                const lastWord: any = splitUp[splitUp.length - 1];
                if (lastWord?.length === 5 && !isNaN(lastWord)) {
                  value = splitUp.slice(0, -2).join(" ");
                }
              }
              let newValueObj = {
                city: "",
                state: "",
                street_line: "",
                zipcode: "",
                secondary: "",
                entries: 0
              };

              if (value === "") {
                setFullPropertyDetails(newValueObj);
              }
              setAddressInputValue(value);
              propertyAddressField.documentInfo = value;
            }
          }}
          renderInput={(params: any) => {
            params.inputProps.value = propertyAddressField.documentInfo || "";
            return (
              <Tooltip
                classes={{
                  tooltip: classes?.tooltip,
                  arrow: classes?.arrow
                }}
                title={propertyAddressField.documentInfo || ""}
                placement="right"
                arrow
              >
                <TextField
                  {...params}
                  value={propertyAddressField.documentInfo || ""}
                  data-testid={`document-info-input-${propertyAddressField.posIndex}`}
                  variant="outlined"
                  disabled={propertyAddressField.disabled}
                  placeholder="Enter Address Line 1"
                />
              </Tooltip>
            );
          }}
        />
      </div>
    </>
  );
};
