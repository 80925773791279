import React, { useState } from "react";
import { Grid, Chip, FormControlLabel, Checkbox } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { ActionButton } from "@toorak/tc-common-fe-sdk";

import fileIcon from "../../images/createloan/file_icon.svg";
import deleteImg from "../../images/exceptions/delete.svg";
import downloadImg from "../../images/createloan/download.svg";

const useStyles = makeStyles(() =>
  createStyles({
    progressContainer: {
      width: "100%",
      height: "8px",
      marginTop: "10px"
    },
    progressBar: {
      height: "inherit",
      backgroundColor: "#2ece89"
    },
    filebarChip: {
      "& .MuiChip-label": {
        color: "#454b60"
      }
    }
  })
);

const FileIcon = () => {
  return <img src={fileIcon} alt="file_icon" />;
};
export const FileBar = (props: any) => {
  const {
    value,
    deleteHandler,
    downloadHandler,
    tagClickedHandler,
    type,
    deleteDisabled,
    handleRetry,
    isChecked,
    isOnlyIcon
  } = props;
  const classes = useStyles();
  const [checked, setTagChecked] = useState(isChecked || false);
  // const [error, setError] = useState(false);
  const handleTagClick = () => {
    setTagChecked(!checked);
    tagClickedHandler(checked);
  };
  const handleRetryFile = () => {
    handleRetry();
  };

  let rightVal = 0;
  if (
    type?.toLowerCase() === "satisfy condition" ||
    type?.toLowerCase() === "satisfy inquiry"
  ) {
    rightVal += 153;
  }
  if (deleteHandler) {
    rightVal += 87;
  }
  if (handleRetry && value?.error !== null) {
    rightVal += 85;
  }
  if (downloadHandler) {
    rightVal += 100;
  }
  const rightBlock = `${rightVal}px`;
  const getWidth =
    (deleteHandler || downloadHandler) && isOnlyIcon ? 25 : rightBlock;
  return (
    <Grid
      item
      xs={12}
      style={{
        backgroundColor: "#f7fafc",
        padding: "4px 0px 4px 0px",
        // margin: value?.tags?.length > 0 ? "0px" : "10px 0px",
        margin: "0px",
        borderRadius: "4px"
      }}
      key={`${value?.uniqueId}` || value?.id}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item style={{ width: `calc(100% - ${rightBlock})` }}>
          <Chip
            // avatar={<InsertDriveFileOutlinedIcon />}
            avatar={<FileIcon />}
            label={value?.name || value?.body?.[0]?.name}
            style={{
              backgroundColor: "inherit",
              paddingLeft: "10px",
              color: "#454b60 !important",
              width: "100%",
              justifyContent: "left"
            }}
            title={value?.name || value?.body?.[0]?.name}
            className={classes.filebarChip}
          />
        </Grid>
        <Grid
          item
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "0px !important",
            fontSize: "12px",
            fontWeight: 500,
            width: getWidth
          }}
        >
          {type?.toLowerCase() === "satisfy condition" ||
          type?.toLowerCase() === "satisfy inquiry" ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleTagClick}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Tagging Required?"
            />
          ) : null}
          {downloadHandler && !isOnlyIcon ? (
            <ActionButton
              text="Download"
              action="download"
              onClick={downloadHandler}
              styles={{
                marginRight: "10px"
              }}
              testId="download-btn"
            />
          ) : null}
          {downloadHandler && isOnlyIcon ? (
            <img
              src={downloadImg}
              title="download"
              alt="download"
              style={{
                cursor: "pointer"
              }}
              onClick={downloadHandler}
            />
          ) : null}
          {deleteHandler && !isOnlyIcon ? (
            <ActionButton
              text="Delete"
              action="delete"
              onClick={deleteHandler}
              disabled={deleteDisabled || false}
              testId="delete-btn"
              styles={{
                padding: "7px"
              }}
            />
          ) : null}
          {deleteHandler && isOnlyIcon && (
            <img
              src={deleteImg}
              title="Delete"
              alt="Delete"
              style={{
                cursor: "pointer"
              }}
              onClick={deleteHandler}
            />
          )}
          {handleRetry && value?.error !== null ? (
            <ActionButton
              text="Retry"
              action="retry"
              onClick={handleRetryFile}
              // disabled={deleteDisabled || false}
              testId="retry-btn"
            />
          ) : null}
        </Grid>
        {value?.percentCompleted && value?.percentCompleted !== 100 ? (
          <Grid item xs={12}>
            <div className={classes.progressContainer}>
              <div
                className={classes.progressBar}
                style={{
                  width: `${value?.percentCompleted}%`
                }}
              />
            </div>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};
