/* eslint-disable no-shadow */
import { Grid, Backdrop, Typography, Button } from "@mui/material";
import ReactToPrint from "react-to-print";
import { LoanStage } from "@toorak/tc-common-fe-sdk";
import React, { useEffect, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { KeyDealMetricStyle } from "./keyDealMetricStyle";
import {
  getLoanById,
  getPropertyOriginalValuesByLoanId
} from "../create-loan/create-loan.action";
import {
  getRuleResult
  // getBorrowerUsedForCalculation
} from "../stores/EvaluationResults/EvaluationResults.action";
import { KeyDealSectionMap } from "./KeyDealSectionMap";
import { KeyDealMetricsExport } from "./keyDealMetricsExport";
import exportStartIcon from "../images/loanlistheader/uil-file-export-2.svg";
import { LoaderWithLogo } from "../create-loan/Loader";
import { getLoanType } from "../config/config";

export interface KeyDealMetricsProps {
  loanId: string;
  loanStage: LoanStage;
  loanType: string;
  onClose?: any;
  takeoutPartnerId?: string | null;
}
export const ExportStartIcon = () => {
  return (
    <>
      <img src={exportStartIcon} alt="export-start-icon" />
    </>
  );
};
const ConditionalWrapper = ({ condition, wrapper, children }: any) =>
  condition ? children : wrapper(children);

/** This is a KeyDealMetrics wrapper component that contains all state variables and logic */
export const KeyDealMetrics = (props: KeyDealMetricsProps) => {
  const componentRef: any = useRef<HTMLButtonElement>(null);
  const { loanId, loanType, loanStage, onClose, takeoutPartnerId } = props;
  const isFullScreenKDM = window.location.href.indexOf("kdm") !== -1;
  const classes = KeyDealMetricStyle();
  const [showLoader, setShowLoader] = React.useState<boolean>(true);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [data, setData] = React.useState<{
    loanDetails: any;
    propertyDetails: any;
    ruleResult: any;
    loanStage: string;
    loanType: string;
  } | null>(null);

  const getData = async (
    loanId: string,
    loanStage: LoanStage,
    loanType: string,
    takeoutPartnerId?: string | null
  ) => {
    try {
      const [loanDetails, propertyDetails, ruleResult]: any[] =
        await Promise.all([
          getLoanById(loanId, loanStage),
          getPropertyOriginalValuesByLoanId(loanId, loanStage),
          getRuleResult(loanId, loanStage, takeoutPartnerId)
        ]);
      // handle corrupted loan
      if (!ruleResult) {
        setErrorMessage("Failed to load data. Please try again.");
      }
      const ruleData = ruleResult;
      // if (!ruleResult?.loanResult[loanId].borrowerInfo) {
      //   const borrowerInfo = await getBorrowerUsedForCalculation(
      //     loanType,
      //     loanDetails.data.loanUserMap
      //   );
      //   if (borrowerInfo) {
      //     ruleData.loanResult[loanId].borrowerInfo = borrowerInfo;
      //   }
      // }
      setData({
        loanDetails: loanDetails.data,
        propertyDetails: propertyDetails.data,
        ruleResult: ruleData,
        loanStage,
        loanType
      });
      setShowLoader(false);
    } catch (error) {
      console.log(error);
      setErrorMessage("Failed to load data. Please try again.");
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (loanType === getLoanType[1].displayValue && !takeoutPartnerId) return;
    getData(loanId, loanStage, loanType, takeoutPartnerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanId, loanStage, loanType, takeoutPartnerId]);

  if (showLoader) {
    return (
      <ConditionalWrapper
        condition={isFullScreenKDM}
        wrapper={(children: any) => (
          <Backdrop
            className={classes.backDrop}
            open
            data-testid="KeyDealMetrics-backdrop"
          >
            {children}
          </Backdrop>
        )}
      >
        <LoaderWithLogo />
      </ConditionalWrapper>
    );
  }
  if (errorMessage) {
    return (
      <ConditionalWrapper
        condition={isFullScreenKDM}
        wrapper={(children: any) => (
          <Backdrop
            className={classes.backDrop}
            open
            data-testid="KeyDealMetrics-backdrop"
          >
            {children}
          </Backdrop>
        )}
      >
        <Grid
          className={classes.modal}
          style={isFullScreenKDM ? { width: "100%" } : {}}
        >
          <Grid
            className={classes.containerHeader}
            style={isFullScreenKDM ? { width: "100%" } : {}}
            container
          >
            <Grid item xs={10}>
              <span
                className={classes.heading}
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                Key Deal Metrics
              </span>
            </Grid>
            {!isFullScreenKDM && (
              <Grid item xs={2} style={{ textAlign: "right" }}>
                <CloseIcon
                  htmlColor="#414169"
                  className={classes.closeIcon}
                  onClick={onClose}
                />
              </Grid>
            )}
          </Grid>
          <Grid
            xs={12}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid style={{ marginTop: "40vh" }}>
              <Typography>{errorMessage}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </ConditionalWrapper>
    );
  }
  const getPageMargins = () => {
    return `@page { margin: ${50} ${0} ${50} ${10} !important; }`;
  };
  return (
    <>
      <ConditionalWrapper
        condition={isFullScreenKDM}
        wrapper={(children: any) => (
          <Backdrop
            className={classes.backDrop}
            open
            data-testid="KeyDealMetrics-backdrop"
          >
            {children}
          </Backdrop>
        )}
      >
        <Grid
          className={classes.modal}
          style={isFullScreenKDM ? { width: "100%" } : {}}
        >
          <style>{getPageMargins()}</style>
          <Grid
            className={classes.containerHeader}
            style={isFullScreenKDM ? { width: "100%" } : {}}
            container
          >
            <Grid item xs={10} style={{ display: "flex" }}>
              <span className={classes.heading}>Key Deal Metrics</span>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <ReactToPrint
                documentTitle={`Toorak Capital Partners | Key Deal Metrics Report | ${loanId}`}
                trigger={() => (
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<ExportStartIcon />}
                    className={classes.printButton}
                    color="info"
                  >
                    Print
                  </Button>
                )}
                content={() => componentRef.current}
                copyStyles
              />
              {!isFullScreenKDM && (
                <CloseIcon
                  htmlColor="#414169"
                  className={classes.closeIcon}
                  onClick={onClose}
                />
              )}
            </Grid>
          </Grid>
          {data && <KeyDealSectionMap data={data} />}
        </Grid>
      </ConditionalWrapper>
      <KeyDealMetricsExport
        loanId={loanId}
        loanStage={loanStage}
        loanType={loanType}
        componentRef={componentRef}
        takeoutPartnerId={takeoutPartnerId}
      />
    </>
  );
};
