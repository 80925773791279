/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import RenderInputParams from "@mui/material/Autocomplete";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useAutoCorrectStyles } from "@toorak/tc-common-fe-sdk/dist/components/input/autocomplete/AutoCompleteForm";
import { GridTextFieldForm, publicClient } from "@toorak/tc-common-fe-sdk";

import { throttle } from "../../utils/utils";
import { getConfig } from "../../config/config";

const useStyles = makeStyles((theme: Theme) => ({
  containerPadding: {
    paddingTop: 90,
    paddingBottom: theme.spacing(4)
  },
  innerPadding: {
    padding: "22px 24px"
  },
  headingText: {
    color: "#32325d",
    fontSize: "1rem"
  },
  fw600: {
    fontWeight: 600
  },
  borderBottom: {
    borderBottom: "1px solid #f2f2f2"
  },
  topBottomBorder: {
    borderBottom: "1px solid #f2f2f2",
    borderTop: "1px solid #f2f2f2"
  },
  submitBtn: {
    "& svg": {
      fontSize: theme.spacing(2),
      marginRight: theme.spacing(1)
    }
  },
  labelRoot: {
    color: "#8897aa",
    fontSize: 12
  },
  radioGroupRoot: {
    flexDirection: "row"
  },
  formControlLabel: {
    fontSize: 12,
    color: "#32325d",
    fontWeight: "normal"
  },
  asterisk: {
    color: "#f75676"
  },
  inputRoot: {
    border: "1px solid #cad1d7 !important",
    borderRadius: "4px"
  },
  textArea: {
    width: "100%",
    height: "100%",
    resize: "none",
    padding: "14px 12px 14px 12px"
  },
  formWrap: {
    "& .MuiGrid-item": {
      padding: "8px 0"
    }
  },
  saveBtn: {
    marginRight: theme.spacing(2)
  },
  pT: {
    paddingTop: "11px !important"
  }
}));

export interface ZipCodeSuggestion {
  city: string;
  state: string;
  state_abbreviation: string;
  zipCode: any;
}

interface LocationSuggestion {
  street_line: string;
  secondary: string;
  city: string;
  state: string;
  zipcode: string;
  entries: number;
}

export const PropertyLocation = (props: any) => {
  const classes = useStyles();
  const autoClasses = useAutoCorrectStyles();
  const { setPropertyChips, propertyChips, setPropertyObject, propertyObject } =
    props;
  const [addressInputValue, setAddressInputValue] = React.useState("");
  const [selectedAddressValue, setSelectedAddressValue] =
    React.useState<LocationSuggestion | null>(null);

  const [addressOptions, setAddressOptions] = React.useState<
    LocationSuggestion[]
  >([]);

  const [zipCodeInputValue, setZipCodeInputValue] = React.useState("");
  const [selectedZipCodeValue, setSelectedZipCodeValue] =
    React.useState<ZipCodeSuggestion | null>(null);
  const [zipCodeOptions, setZipCodeOptions] = React.useState<
    ZipCodeSuggestion[]
  >([]);

  const [cityInputValue, setCityInputValue] = React.useState("");
  const [stateInputValue, setStateInputValue] = React.useState("");
  /**
   * Address api call and its related methods
   */
  const fetchAddress = React.useMemo(
    () =>
      // @ts-ignore
      throttle(
        (
          request: { input: string },
          callback: (results?: LocationSuggestion[]) => void
        ) => {
          publicClient
            .get(
              `https://us-autocomplete-pro.api.smartystreets.com/lookup?key=${
                getConfig().smartyStreetsApiKey
              }&search=${request.input}`
            )
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((results: any) =>
              callback(results.data.suggestions as LocationSuggestion[])
            )
            .catch(() => {});
        },
        200
      ),
    []
  );

  React.useEffect(() => {
    let active = true;
    if (addressInputValue === "") {
      return undefined;
    }
    fetchAddress(
      { input: addressInputValue },
      (results?: LocationSuggestion[]) => {
        if (active) {
          let newOptions = [] as LocationSuggestion[];

          if (results) {
            newOptions = [...newOptions, ...results];
          }
          setAddressOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [addressInputValue]);

  const getAddressOptionLabel = (option: LocationSuggestion) => {
    let value;
    if (typeof option === "string") {
      value = option;
    } else if (option.street_line) {
      value = `${option.street_line} ${option.city} ${option.state} ${option.zipcode}`;
    } else {
      value = "";
    }
    return value;
  };

  const addressOnChange = async (
    event: React.ChangeEvent<{}>,
    newValue: LocationSuggestion | null
  ) => {
    setAddressOptions(
      newValue ? [newValue, ...addressOptions] : addressOptions
    );
    if (newValue) {
      setSelectedAddressValue(newValue);
      setCityInputValue(newValue.city);
      setStateInputValue(newValue.state);
      updatePostalCode(newValue);
    }
  };

  const addressOnInputChange = (
    event: React.ChangeEvent<{}>,
    value: string
  ) => {
    setAddressInputValue(value);
  };

  const renderAddressInput = (params: typeof RenderInputParams) => {
    return (
      <TextField
        {...params}
        value={selectedAddressValue?.street_line || ""}
        margin="dense"
        variant="outlined"
      />
    );
  };

  /**
   * ZipCode api call and its related methods
   */
  const fetchZipCode = React.useMemo(
    () =>
      // @ts-ignore
      throttle(
        (
          request: { input: string },
          callback: (results?: {
            zipSuggested: ZipCodeSuggestion[];
            zipcodes: any;
          }) => void
        ) => {
          publicClient
            .get(
              `https://us-zipcode.api.smartystreets.com/lookup?auth-id=${
                getConfig().smartyStreetsApiKey
              }&zipcode=${request.input}`
            )
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((results: any) =>
              callback({
                zipSuggested: results.data[0]
                  .city_states as ZipCodeSuggestion[],
                zipcodes: results.data[0].zipcodes
              })
            )
            .catch(() => {});
        },
        200
      ),
    []
  );

  React.useEffect(() => {
    let active = true;
    if (zipCodeInputValue.length < 4 || zipCodeInputValue.length > 7) {
      return undefined;
    }
    fetchZipCode(
      { input: zipCodeInputValue },
      (results?: { zipSuggested: ZipCodeSuggestion[]; zipcodes: any }) => {
        if (active) {
          if (results) {
            const { zipSuggested, zipcodes } = results;
            let newOptions = [] as ZipCodeSuggestion[];
            if (zipSuggested && zipcodes) {
              const zipCode = zipcodes[0].zipcode;
              const temp = zipSuggested.map((ele) => {
                return { ...ele, ...{ zipCode } };
              });
              newOptions = [...newOptions, ...temp];
            }
            setZipCodeOptions(newOptions);
          }
        }
      }
    );

    return () => {
      active = false;
    };
  }, [zipCodeInputValue]);

  const getPostalOptionLabel = (option: any) => {
    let value;
    if (typeof option === "string") {
      value = option;
    } else if (option.city) {
      value = `${option.city} ${option.state_abbreviation} ${option.zipCode}`;
    } else {
      value = "";
    }
    return value;
  };

  const postalOnChange = async (
    event: React.ChangeEvent<{}>,
    newValue: ZipCodeSuggestion | null
  ) => {
    setZipCodeOptions(
      newValue ? [newValue, ...zipCodeOptions] : zipCodeOptions
    );
    if (newValue) {
      setSelectedZipCodeValue(newValue.zipCode);
      setCityInputValue(newValue.city);
      setStateInputValue(newValue.state);
      setSelectedAddressValue(null);
    }
  };

  const updatePostalCode = (value: any) => {
    const createZipCodeObj = {
      city: value.city,
      state: value.state,
      // eslint-disable-next-line @typescript-eslint/camelcase
      state_abbreviation: value.secondary,
      zipCode: value.zipcode
    };
    setZipCodeOptions(
      createZipCodeObj ? [createZipCodeObj, ...zipCodeOptions] : zipCodeOptions
    );
    if (createZipCodeObj) {
      setSelectedZipCodeValue(createZipCodeObj.zipCode);
    }
  };

  const postalOnInputChange = (event: React.ChangeEvent<{}>, value: string) => {
    setZipCodeInputValue(value);
  };

  const renderPostalInput = (params: typeof RenderInputParams) => {
    return (
      <TextField
        {...params}
        value={selectedZipCodeValue?.zipCode || ""}
        margin="dense"
        variant="outlined"
      />
    );
  };

  const cityOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCityInputValue(event.target.value);
  };

  const stateOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStateInputValue(event.target.value);
  };

  const clearPropertyLocation = () => {
    setSelectedAddressValue(null);
    setSelectedZipCodeValue(null);
    setCityInputValue("");
    setStateInputValue("");
    setAddressInputValue("");
    setZipCodeInputValue("");
  };

  const checkEmptyValue = (text: any) => {
    if (text === null || text === undefined) {
      return "";
    }
    return text;
  };
  const street =
    selectedAddressValue === null || undefined
      ? addressInputValue
      : selectedAddressValue?.street_line;
  const zipCode =
    selectedZipCodeValue === null || undefined
      ? zipCodeInputValue
      : selectedZipCodeValue;
  const fullAddress = `${checkEmptyValue(
    street
  )} ${cityInputValue} ${stateInputValue} ${checkEmptyValue(zipCode)}`;

  const handleSave = () => {
    setPropertyChips([...propertyChips, fullAddress]);
    setPropertyObject([
      ...propertyObject,
      {
        addressLine1: street,
        addressLine2: "",
        city: cityInputValue,
        state: stateInputValue,
        postalCode: zipCode,
        country: "",
        locationValidationStatus: "OVERRIDDEN",
        duplicatePropertyInfo: null,
        duplicateLoanIds: null,
        geoLocation: null
      }
    ]);
    clearPropertyLocation();
  };
  const enableClear = street || cityInputValue || stateInputValue || zipCode;
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} xl={4} className={classes.pT}>
          <InputLabel classes={{ root: classes.labelRoot }}>Address</InputLabel>
          <Autocomplete
            id="address"
            freeSolo
            classes={autoClasses}
            options={addressOptions}
            filterOptions={(x) => x}
            filterSelectedOptions
            disableClearable
            value={selectedAddressValue as any}
            getOptionLabel={getAddressOptionLabel as any ?? ""}
            onChange={addressOnChange as any}
            inputValue={addressInputValue}
            onInputChange={addressOnInputChange}
            renderInput={renderAddressInput as any}
            selectOnFocus
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={4} className={classes.pT}>
          <InputLabel classes={{ root: classes.labelRoot }}>
            Postal Code
          </InputLabel>
          <Autocomplete
            id="postalCode"
            freeSolo
            classes={autoClasses}
            options={zipCodeOptions}
            filterOptions={(x) => x}
            filterSelectedOptions
            disableClearable
            value={selectedZipCodeValue as any}
            inputValue={zipCodeInputValue}
            getOptionLabel={getPostalOptionLabel}
            onChange={postalOnChange}
            onInputChange={postalOnInputChange}
            renderInput={renderPostalInput as any}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={4}>
          <GridTextFieldForm
            required
            label="City"
            error={false}
            valueTestId="city"
            value={cityInputValue}
            onChange={cityOnChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={4}>
          <GridTextFieldForm
            required
            label="State"
            error={false}
            valueTestId="state"
            value={stateInputValue}
            onChange={stateOnChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={4}>
          <Box display="flex" alignItems="flex-end" height="100%" pb="5px">
            <Button
              variant="contained"
              color="info"
              size="large"
              classes={{
                root: classes.saveBtn
              }}
              disabled={(stateInputValue && cityInputValue) === ""}
              onClick={handleSave}
            >
              Save
            </Button>
            {enableClear ? (
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={clearPropertyLocation}
              >
                Clear
              </Button>
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
