/* eslint-disable @typescript-eslint/no-empty-function */
import { useState, useEffect, useRef, useCallback } from "react";
import { publicClient } from "@toorak/tc-common-fe-sdk";

interface PollConfigType {
  url: string;
  interval?: number;
  method: string;
  body?: any;
  onSuccess: (res: any) => void;
  onFailure?: ((error: any) => void) | undefined;
  retryCount?: number;
  pollingWaitTime?: number;
  onExceedingPollingWaitTime: () => void;
}

const defaultConfig: PollConfigType = {
  url: "", // url/api to poll
  interval: 3000, // Interval of polling
  method: "GET", // HTTP Method of the api to call
  onSuccess: (res: any) => {}, // Callback function on successful polling. This should return true to continue polling
  onFailure: (error: any) => {}, // Callback function on failed polling or api failure
  body: undefined, // The data that need to be sent in a post/put call
  retryCount: 0, // Number of times to retry when an api polling call fails
  pollingWaitTime: 300000, // max time to poll
  onExceedingPollingWaitTime: () => {}
};

export const usePolling = () => {
  const [config, setConfig] = useState<PollConfigType>(defaultConfig);
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const [retryCount, setRetryCount] = useState<number | undefined>(
    defaultConfig.retryCount
  );

  const persistedIsPolling: any = useRef();
  const poll: any = useRef();

  persistedIsPolling.current = isPolling;

  useEffect(() => {
    return () => {
      stopPolling();
    };
  }, []);

  useEffect(() => {
    setRetryCount(config.retryCount);
  }, [config.retryCount]);

  const shouldRetry = !!config.retryCount;

  const stopPolling = () => {
    if (poll.current) {
      clearTimeout(poll.current);
      poll.current = null;
    }
    setIsPolling(false);
  };

  const startPolling = (newConfig: PollConfigType) => {
    const updatedConfig = { ...config, ...newConfig };
    setIsPolling(true);
    setConfig(updatedConfig);
  };

  useEffect(() => {
    if (!isPolling || config.url === "") return;
    runPolling();
    setPollingWaitTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPolling, config.url]);

  const setPollingWaitTime = () => {
    setTimeout(() => {
      config.onExceedingPollingWaitTime();
      stopPolling();
    }, config.pollingWaitTime);
  };

  const clientByMethod = () => {
    let client;
    switch (config.method) {
      case "GET":
      case "DELETE":
        client = publicClient.get(config.url);
        break;
      case "POST":
      case "PUT":
      case "PATCH":
        client = publicClient.post(config.url, config.body);
        break;
      default:
        client = publicClient.get(config.url);
    }
    return client;
  };

  const runPolling = useCallback(() => {
    if (config.url === "") return;
    const timeoutId = setTimeout(() => {
      /* onSuccess would be handled by the user of service which would either return true or false
       * true - This means we need to continue polling
       * false - This means we need to stop polling
       */
      const client = clientByMethod();
      client
        .then((res: any) => {
          return config.onSuccess(res);
        })
        .then((continuePolling: any) => {
          // eslint-disable-next-line no-unused-expressions
          persistedIsPolling.current && continuePolling
            ? runPolling()
            : stopPolling();
        })
        .catch((error: any) => {
          if (shouldRetry && retryCount && retryCount > 0) {
            // eslint-disable-next-line no-unused-expressions
            config.onFailure && config.onFailure(error);
            // eslint-disable-next-line no-plusplus
            setRetryCount((prevCount: any) => prevCount - 1);
            runPolling();
          } else {
            // eslint-disable-next-line no-unused-expressions
            config.onFailure && config.onFailure(error);
            stopPolling();
          }
        });
    }, config.interval);
    poll.current = timeoutId;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPolling, config.url]);

  return {
    isPolling,
    startPolling,
    stopPolling
  };
};
