import React, { useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";

import Grid from "@mui/material/Grid";
import { Autocomplete, FormControlLabel, Radio, RadioGroup, Theme, TextField, Tooltip } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

import { GridDatePickerForm as DatePickerForm, DropDown, GridTextFieldForm } from "@toorak/tc-common-fe-sdk";

import { CustomizedTextField } from "../../../utils/customizedTextField";

import { AutocompleteAddressField } from "../../../ui-components/AutoCompleteAddressField";
import { RootState } from "../../../stores/rootReducer";
import { messages } from "../../../utils/messages";
import { isLATUser } from "../../../utils/AccessManagement";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import { useParams } from "react-router-dom";
import { CheckBox } from "../../../ui-components/CheckBox";
import { MMMDDYYYYDateFormat } from "../../../utils/utils";
import { fundingChannelDropDown } from "../../../create-loan/constants/loanCreationDropDownValues";

import arrowDown from "../../../images/arrow-down.svg";
import { getSpecificAuditList } from "../../../network/apiService";
import { fetchEditHistory } from "../../../create-loan/create-loan.reducer";
import { checkFieldValidity, formatValueToUIByType } from "../../../utils/formatChecks";
import { ObjectType } from "../../../masterView/common";
import { getfundingChannelValue } from "./util";

const checkSubmission = (loanStateVal: any) => {
  return ![
    LoanStatusEnum.DataEntry,
    LoanStatusEnum.ExcelError,
    LoanStatusEnum.ExcelSuccess
  ].includes(loanStateVal);
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkBoxContainer: {
      border: "1px solid rgb(233, 236, 239)",
      padding: "14px 16px 14px 24px",
      borderRadius: "4px"
    },
    commentBox: {
      marginTop: "20px",
      "& label": {
        width: "100%",
        "& div": {
          "& input": {
            marginBottom: "20px"
          }
        }
      }
    },
    labelContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      // marginTop: "1px",
      width: "100%"
    },
    editedLabelContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: "1px",
      width: "100%"
    },
    labelInformation: {
      fontSize: 12,
      fontWeight: 600,
      color: "#8897aa",
      lineHeight: "16px",
      // height: "14px",
      "&:hover": {
        color: "#5e72e4"
      },
      display: "inline-block",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: "95%",
      textOverflow: "ellipsis",
      pointerEvents: "all"
    },
    labelRequired: {
      color: "#f75676",
      marginLeft: "4px",
      fontSize: "12px",
      // marginBottom: "4px",
      paddingTop: 1,
      display: "inline-block",
      verticalAlign: "top"
    },
    labelRequiredComments: {
      color: "#f75676",
      marginLeft: "4px",
      fontSize: "12px",
      marginBottom: "4px",
      marginTop: "5px"
    },
    iconInfoContainer: {
      height: "16px",
      textAlign: "end"
    },
    iconInfo: {
      width: "16px !important",
      height: "16px !important",
      color: "#32325d",
      "&:hover": {
        color: "#5e72e4"
      }
    },
    tooltip: {
      color: "#32325d",
      backgroundColor: "white",
      fontFamily: `"Open Sans", sans-serif !important`,
      fontSize: 12,
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      padding: "5px"
    },
    arrow: {
      color: "white"
    }
  }));

export default function GetFieldUI(props: any) {
  const {
    formFields,
    currentObjectValue,
    isEvaluationPanelVisible,
    isEditable,
    handleChange,
    onBlur,
    fieldsError,
    infoContentPath,
    CondoEligibilityText,
    orgSellerInfo,
    otherData,
    zipCodeData,
    editHistoryPath
  } = props;
  const { borrowerInfoData = [], takeOutPartnerList = [] } = otherData ?? {};

  const dispatch = useDispatch();
  const [fetchingAudit, setFetchingAudit] = React.useState<ObjectType>({});
  const { loanType, loanStage, loanId } = useParams<{
    loanType: string;
    loanStage: string;
    loanId: string;
  }>() as any;

  const editedFields = useSelector<RootState, any>(
    (state: any) => state.createLoanStore.editedFields
  );
  const editHistory = useSelector<RootState, any>(
    (state: any) => state.createLoanStore.editHistory
  );
  const infoContent = useSelector<RootState, any>(
    (state) => state.createLoanStore.infoContentLoan
  );
  const { loanState } = useSelector<RootState, any>((state: any) => state.createLoanStore.loanDetails);
  const { isDataEntry } = useSelector<RootState, any>((state) => state.loanDetailsStore);

  const loanInfoContent = useMemo(
    () => JSON.parse(JSON.stringify(infoContent || {})),
    [infoContent]
  );
  const { validate } = useSelector<
    RootState,
    { isSubmitValidation: boolean; validate: boolean }
  >(state => state.createLoanStore.validateForm);

  const isSubmitted = checkSubmission(loanState);

  const classes = useStyles();

  const normalizeDropDownData = (fieldName: any, item: any) => {
    if (fieldName === "sellerPartyId") {
      return orgSellerInfo
    }
    if (item?.getDropDownData) {
      return item?.getDropDownData(loanStage, fundingChannelDropDown)
    }
    return item.dropDownData;
  }

  const getFieldValue = (getValue: any, fieldName: any, type?: any) => {
    if (getValue) {
      return getValue(currentObjectValue)
    }
    let returnStr = "";
    if (fieldName === "address") {
      if (type === "autocomplete") {
        if (currentObjectValue?.locationValidationStatus === "OVERRIDDEN") {
          if (typeof currentObjectValue?.address === "object") {
            returnStr = `${currentObjectValue?.[fieldName]?.street_line} ${currentObjectValue?.[fieldName]?.city} ${currentObjectValue?.[fieldName]?.state} ${currentObjectValue?.[fieldName]?.zipcode}`
          } else if (currentObjectValue?.addressLine1) {
            returnStr = `${currentObjectValue?.addressLine1} ${currentObjectValue.city} ${currentObjectValue.state} ${currentObjectValue.postalCode}`;
          } else {
            returnStr = currentObjectValue?.[fieldName] ?? ""
          }
        } else if (currentObjectValue?.[fieldName]?.street_line) {
          returnStr = `${currentObjectValue?.[fieldName]?.street_line} ${currentObjectValue?.[fieldName]?.city} ${currentObjectValue?.[fieldName]?.state} ${currentObjectValue?.[fieldName]?.zipcode}`
        } else {
          returnStr = currentObjectValue?.[fieldName] ?? ""
        }
      } else if (currentObjectValue?.[fieldName]?.street_line) {
        returnStr = currentObjectValue?.[fieldName]?.street_line;
      }
    } else {
      if (type) {
        switch (type) {
          case "autocomplete":
            returnStr = currentObjectValue?.[fieldName] ?? "";
            break;
          case "radioGroup":
            returnStr = currentObjectValue?.[fieldName] ?? false;
            break;
          case "number":
            const tempNumber = currentObjectValue?.[fieldName];
            returnStr = checkFieldValidity(tempNumber, {
              fieldTpe: "number"
            }) ? tempNumber : `${tempNumber}`?.replace(/[^0-9.]/g, '');
            break;
          default:
            returnStr = currentObjectValue?.[fieldName] ?? "";
            break
        }
      } else {
        returnStr = currentObjectValue?.[fieldName] ?? "";
      }
    }
    return returnStr;
  }

  const getPostalOptionLabel = (option: any) => {
    let value;
    if (typeof option === "string") {
      value = option;
    } else if (option.city) {
      value = `${option.city} ${option.state_abbreviation} ${option.zipCode}`;
    } else {
      value = "";
    }
    return value;
  };

  const onEditTagHover = (item: any) => {
    const { fieldName, editedLabelPath, commentField, backendKey, fieldType, dropDownData, fieldLabel, isDecimalFixedTo4 } = item;
    let keyToBePassed = editedLabelPath || fieldName;
    let fieldPath = messages?.[`${keyToBePassed}AuditPath`];

    if (editHistoryPath) {
      const editCommentField = commentField || fieldName;
      fieldPath = `${editHistoryPath}.${editCommentField}`;
    }

    if (fieldName === "numberOfProperties") {
      fieldPath = messages?.[`${backendKey}AuditPath`];
    }

    if (["accrualDate", "nextPaymentDueDate"].includes(editedLabelPath)) {
      fieldPath = `data.result.loanResult.${loanId}.settlementResults.${editedLabelPath}`
    }
    setFetchingAudit({ ...fetchingAudit, [keyToBePassed]: true });
    if (fetchingAudit[keyToBePassed]) return;
    getSpecificAuditList(`${loanId}_${loanStage}`, fieldPath, "loans").then((res: any) => {
      setFetchingAudit(previous => {
        Object.keys(previous).forEach(key => {
          previous[key] = (key === keyToBePassed);
        })
        return previous;
      })
      const auditData = {
        noKeysUpdate: true,
        ...res?.data,
        audit: {
          ...res?.data?.audit,
          [fieldPath]: res?.data?.audit?.[fieldPath].map((item: any) => {
            if (fieldName === "sellerPartyId") {
              const value = orgSellerInfo.find((option: any) => option?.value === item?.value)?.label;
              const preValue = orgSellerInfo.find((option: any) => option?.value === item?.preValue)?.label;
              return {
                ...item,
                value,
                preValue
              }
            } else if (fieldName === "selectedFundingChannel") {
              const value = getfundingChannelValue(item.value);
              const preValue = getfundingChannelValue(item.preValue);
              return {
                ...item,
                value,
                preValue
              }
            } else if (fieldLabel === "Investor Name") {
              const value = takeOutPartnerList?.find((option: any) => option?.value === item?.value)?.label || null;
              const preValue = takeOutPartnerList?.find((option: any) => option?.value === item?.preValue)?.label || null;
              return {
                ...item,
                value,
                preValue
              }
            }
            else {
              return {
                ...item,
                value: formatValueToUIByType(item.value, fieldType(), dropDownData, isDecimalFixedTo4),
                preValue: formatValueToUIByType(item.preValue, fieldType(), dropDownData, isDecimalFixedTo4)
              }
            }
          })
        }
      }

      dispatch(fetchEditHistory(auditData));
    })
  }

  const getFieldPath = (item: any) => {
    const { fieldName, commentField, backendKey, editedLabelPath } = item;

    let fieldPath = messages?.[`${editedLabelPath}AuditPath`] || messages?.[`${fieldName}AuditPath`];
    if (editHistoryPath) {
      const editCommentField = commentField || backendKey || fieldName;
      fieldPath = `${editHistoryPath}.${editCommentField}`;
    }
    if (fieldName === "numberOfProperties") {
      fieldPath = messages?.[`${backendKey}AuditPath`];
    }
    if (["accrualDate", "nextPaymentDueDate"].includes(editedLabelPath)) {
      fieldPath = `data.result.loanResult.${loanId}.settlementResults.${editedLabelPath}`
    }
    return fieldPath;
  }

  const getEditedTagData = (item: any) => {
    const fieldPathStr = getFieldPath(item) || "";
    return editedFields.includes(fieldPathStr);
  }

  return <>
    {formFields.filter((formField: any) => formField?.isVisible(currentObjectValue, loanType, isSubmitted, otherData, loanStage)).map((item: any, index: number) => {
      const { colSpan, fieldName, fieldLabel, getValue, required } = item;
      let showError = validate && required(currentObjectValue, borrowerInfoData ?? [], loanStage) && getFieldValue(getValue, fieldName) === "";
      showError = fieldName === "borrowerEmail" && currentObjectValue?.[fieldName] ? fieldsError?.get?.(fieldName) : showError;
      switch (item.fieldType(zipCodeData?.editManually)) {
        case "dropDown":
        case "booleanDropDown":
          return (
            <Grid
              key={item.fieldName}
              container
              item
              xs={12}
              sm={6}
              md={colSpan}
            >
              <DropDown
                formControlTestId={`${fieldName}-test-id`}
                inputLabelId={`${fieldName}-lable-id`}
                inputLabelTestId={`${fieldName}-input-test-id`}
                notRequired={!required(currentObjectValue, borrowerInfoData ?? [], loanStage)}
                label={fieldLabel}
                selectId={`${fieldName}-select-id`}
                selectTestId={`${fieldName}-select-test-id`}
                selectionValue={getFieldValue(getValue, fieldName)}
                selectionOnChange={(e: any) => handleChange(e.target.value, item, fieldLabel)}
                selectionData={normalizeDropDownData(fieldName, item)}
                onBlur={(e: any) => onBlur(e.target.value, item)}
                helperText={showError ? fieldsError?.get?.(fieldName) ?? "" : ""}
                isViewMode={item?.isViewMode(isEvaluationPanelVisible, isEditable, loanState, loanStage) || isEvaluationPanelVisible && !isEditable}
                editedLabel={false}
                isLATView={isLATUser()}
                enableReason={false}
                editedData={editHistory?.[getFieldPath(item)] ?? []}
                showEditedTag={getEditedTagData(item)}
                onHover={() => onEditTagHover(item)}
                disabled={item?.disabled?.(isDataEntry)}
                infoIconData={loanInfoContent && loanInfoContent[`${infoContentPath}.${fieldName}`] || ""}
                modalLink={
                  fieldName === "condoEligibility" ? <CondoEligibilityText text="CHECK CONDO ELIGIBILITY" /> : null
                }
              />
            </Grid>
          );
        case "date":
          return (
            <Grid
              key={item.fieldName}
              container
              item
              xs={12}
              sm={6}
              md={colSpan}
            >
              <DatePickerForm
                value={getFieldValue(getValue, fieldName)}
                onChange={(e: any) => handleChange(MMMDDYYYYDateFormat(e), item, fieldLabel)}
                required={required(currentObjectValue, borrowerInfoData ?? [], loanStage)}
                label={fieldLabel}
                error={showError}
                helperText={showError ? fieldsError?.get?.(fieldName) ?? "" : ""}
                onBlur={(e: any) => onBlur(MMMDDYYYYDateFormat(e), item)}
                infoIconData={loanInfoContent && loanInfoContent[`${infoContentPath}.${fieldName}`] || ""}
                isViewMode={item?.isViewMode(isEvaluationPanelVisible, isEditable) || isEvaluationPanelVisible && !isEditable}
                editedLabel={false}
                editedData={editHistory?.[getFieldPath(item)] ?? []}
                showEditedTag={getEditedTagData(item)}
                onHover={() => onEditTagHover(item)}
                isServicerMandatory={false}
                enableReason={false}
                renderInput={(props: any) => (
                  <CustomizedTextField
                    props={props}
                    data-testid={fieldName}
                    value={getFieldValue(getValue, fieldName)}
                    error={showError}
                    helperText={showError ? fieldsError?.get?.(fieldName) ?? "" : ""}
                    isViewMode={item?.isViewMode(isEvaluationPanelVisible, isEditable) || isEvaluationPanelVisible && !isEditable}
                    valueTestId={`${fieldName}-value`}
                  />
                )}
                valueTestId={`${fieldName}-value`}
                data-testid={fieldName}
              />
            </Grid>
          );
        case "radioGroup":
          return (
            <Grid
              key={item.fieldName}
              container
              item
              xs={12}
              sm={6}
              md={colSpan}
              style={{ alignItems: "center" }}
            >
              <RadioGroup
                aria-label={item.fieldLabel}
                name={item.fieldLabel + index + 1}
                value={getFieldValue(getValue, fieldName, "radioGroup")}
                style={{ marginTop: 35 }}
              >
                <FormControlLabel
                  checked={getFieldValue(getValue, fieldName, "radioGroup")}
                  value={item.fieldLabel}
                  control={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Radio
                      style={{
                        width: "13px",
                        height: "13px",
                        padding: "15px",
                        color: "#7A8AA0"
                      }}
                      onClick={(e: any) => !(item?.isViewMode(isEvaluationPanelVisible, isEditable, loanState, loanStage) || isEvaluationPanelVisible && !isEditable) && handleChange(fieldName, item, fieldLabel)}
                      onFocus={() => !(item?.isViewMode(isEvaluationPanelVisible, isEditable, loanState, loanStage) || isEvaluationPanelVisible && !isEditable) && handleChange(fieldName, item, fieldLabel)}
                    />
                  }
                  label={item.fieldLabel}
                  labelPlacement="end"
                />
              </RadioGroup>
            </Grid>
          );
        case "autocomplete":
          return (
            <Grid
              key={item.fieldName}
              container
              item
              xs={12}
              sm={6}
              md={colSpan}
            >
              {
                ["address", "billingAddress"].includes(item?.fieldName) ?
                  <AutocompleteAddressField
                    required={item?.required(currentObjectValue, borrowerInfoData ?? [], loanStage)}
                    label={item?.fieldLabel}
                    value={getFieldValue(getValue, item.fieldName, "autocomplete")}
                    isViewMode={item?.isViewMode(isEvaluationPanelVisible, isEditable, zipCodeData?.editManually) || isEvaluationPanelVisible && !isEditable}
                    onBlur={(e: any, additionalData: any) => onBlur(e.target.value, item, false, additionalData)}
                    getOptionLabel={
                      (option: any) =>
                        typeof option === "string"
                          ? option
                          : option.street_line
                            ? `${option.street_line} ${option.city} ${option.state} ${option.zipcode}`
                            : ""
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    onReasonInputBlur={
                      () => null
                      //   handleOnChange(
                      //   item.fieldName,
                      //   item.fieldType(),
                      //   item.ruleFieldName,
                      //   item.commentField || fieldNameName,
                      //   false,
                      //   item.ruleImpactingField,
                      //   item
                      // )
                    }
                    editedLabel={false}
                    enableReason={false}
                    onChange={(e: any, newValue: any) => handleChange(newValue, item, fieldLabel)}
                    editedData={editHistory?.[getFieldPath(item)] ?? []}
                    showEditedTag={getEditedTagData(item)}
                    onHover={() => onEditTagHover(item)}
                    key={item?.fieldName}
                    infoIconData={loanInfoContent && loanInfoContent[`${infoContentPath}.${fieldName}`] || ""}
                    error={showError}
                    helperText={showError ? fieldsError?.get?.(fieldName) ?? "" : ""}
                    showErrorBorder={false}
                    data-testid={`${item?.fieldName}-test-id`}
                    valueTestId={`${item?.fieldName}-value-id`}
                    defaultValue={currentObjectValue?.[item?.fieldName] ?? ""}
                  />
                  : <>
                    <Autocomplete
                      id={`autocomplete-${item?.fieldName}`}
                      style={{
                        padding: "0px !important",
                        flex: 1,
                        marginTop: 4
                      }}
                      popupIcon={
                        <img
                          src={arrowDown}
                          alt="Expand"
                        />
                      }
                      getOptionLabel={getPostalOptionLabel}
                      filterOptions={x => x}
                      options={zipCodeData?.zipCodeOptions || []}
                      // classes={autoClasses}
                      autoComplete
                      includeInputInList
                      filterSelectedOptions
                      // value={propertyLocation!.address}
                      disableClearable
                      onChange={(event: any, newValue: string | null) => handleChange(newValue, item, fieldLabel)}
                      onInputChange={(event: any, newInputValue: any, reason: any) => {
                        zipCodeData?.setZipCodeInputValue(newInputValue);
                      }}
                      value={getFieldValue(getValue, item.fieldName, "autocomplete")}
                      renderInput={(params: any) => (
                        <TextField
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          label={
                            <Grid
                              container
                              item
                              xs={12}
                              sm={12}
                              className={classes.editedLabelContainer}
                            >
                              <Grid item xs={12} md={12} sm={12} container>
                                <Grid item xs={10} sm={10} md={12}>
                                  <Tooltip
                                    title={`${item?.fieldLabel}`}
                                    placement="right"
                                    arrow
                                    classes={{
                                      tooltip: classes.tooltip,
                                      arrow: classes.arrow
                                    }}
                                  >
                                    <span className={classes.labelInformation}>{item?.fieldLabel}</span>
                                  </Tooltip>
                                  {item?.required(currentObjectValue, borrowerInfoData ?? [], loanStage) ? (
                                    <span className={classes.labelRequired}>
                                      <sup>*</sup>
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          }
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              style={{
                                borderColor:
                                  validate && fieldsError?.get?.(item.fieldName)
                                    ? "#f75676"
                                    : "#cad1d7",
                                fontSize: 14,
                                color: "#323536",
                                padding: "0px"
                              }}
                              fullWidth
                              error={
                                validate && fieldsError?.get?.(item.fieldName) !== ""
                              }
                            />
                          )}
                          // label="Add a location"
                          variant="outlined"
                          style={{
                            flex: 1,
                            width: "100%",
                            borderColor: "#cad1d7",
                            fontSize: 14,
                            color: "black !imporrtant",
                            padding: "0px"
                          }}
                          InputLabelProps={{
                            shrink: false,
                            style: {
                              position: "relative",
                              fontWeight: 600,
                              fontSize: 12,
                              color: "#8897aa",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis"
                            }
                          }}
                          fullWidth
                        />
                      )}
                    />
                  </>
              }

            </Grid>
          );
        case "string":
        case "email":
        case "phone":
        case "currency":
        case "percentage":
        case "percentageWithout100x":
        case "percentageNoWithout100x":
        case "numberNoFormat":
        case "numberString":
        case "number":
        case "decimal":
        case "decimalFixedto4":
          return (
            <Grid
              key={item.fieldName}
              container
              item
              xs={12}
              sm={6}
              md={colSpan}
            >
              <GridTextFieldForm
                required={item.required(currentObjectValue, borrowerInfoData ?? [], loanStage)}
                value={getFieldValue(getValue, item.fieldName, item.fieldType(zipCodeData?.editManually))}
                // value={getFieldValue(getValue, fieldName)}
                isViewMode={item?.isViewMode(isEvaluationPanelVisible, isEditable, loanState, loanType, loanStage, loanStage) || isEvaluationPanelVisible && !isEditable}
                onChange={(e) => handleChange(e.target.value, item, fieldLabel)}
                isServicerMandatory={false}
                label={fieldLabel}
                error={showError}
                helperText={showError ? fieldsError?.get?.(fieldName) ?? "" : ""}
                data-testid={`${fieldName}-test-id`}
                valueTestId={`${fieldName}-value-id`}
                onBlur={(e: any) => onBlur(e.target.value, item)}
                editedLabel={false} // JSON.stringify(editedFieldArraystate).includes(fieldName + borrowerIndex)}
                key={fieldName}
                editedData={editHistory?.[getFieldPath(item)] ?? []}
                showEditedTag={getEditedTagData(item)}
                onHover={() => onEditTagHover(item)}
                infoIconData={loanInfoContent && loanInfoContent[`${infoContentPath}.${fieldName}`] || ""}
                enableReason={false}
              />
            </Grid>
          );
        case "checkBox":
          return (
            <Grid
              key={item.fieldName}
              container
              item
              xs={12}
              md={colSpan}
              sm={6}
              xl={colSpan}
            >
              <CheckBox
                label={fieldLabel}
                value={currentObjectValue?.includeOutOfPocketBudgetInARLTV ?? ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.checked, item, fieldLabel)}
                isViewMode={isEvaluationPanelVisible && !isEditable}
                editedLabel={false}
                editedData={editHistory?.[getFieldPath(item)] ?? []}
                showEditedTag={getEditedTagData(item)}
                onHover={() => onEditTagHover(item)}
                isLATView={isLATUser()}
                enableReason={false}
                onBlur={(e: any) => (e: any) => onBlur(e.target.checked, item)}
                gridClassName={classes.checkBoxContainer}
              />
            </Grid>
          );
        default:
      }
    })}
  </>
}
