import { Checkbox, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateIsFinalApprovalRequired } from "../../stores/drawRequest/drawRequest.action";
import { RootState } from "../../stores/rootReducer";
import { isOrigUser } from "../../utils/AccessManagement";
import { sanitizeCurrency } from "../../utils/formatChecks";
import { DrawStage } from "@toorak/tc-common-fe-sdk";
import { DrawRequestStore } from "../../stores/drawRequest/drawRequest.interface";
import { finalApprovalNotReqdDisableStages } from "./Constant";
import { changeDateFormat } from "../../utils/utils";
import { isAMTView } from "../AssetManagement.utils";

const getNetFundsValue = (netFundsToBorrower: any) => {
  return netFundsToBorrower
    ? ` ${sanitizeCurrency(netFundsToBorrower.toString())}`
    : " __ __";
};

export const DrawDetailsRecapInfo = ({
  drawStage,
  netFundsToBorrower,
  classes,
  drawId
}: {
  drawStage: DrawStage;
  netFundsToBorrower: string;
  classes: any;
  drawId: string;
}) => {
  const dispatch = useDispatch();
  const isOrig = isOrigUser();
  const [isFinalApprovalRequired, setIsFinalApprovalRequired] =
    useState<string>("");
  let { drawDetails } = useSelector<RootState, DrawRequestStore>(
    (state) => state.drawRequestStore
  );

  useEffect(() => {
    if (drawDetails) {
      setIsFinalApprovalRequired(drawDetails.finalApprovalNotRequired);
    }
    //eslint-disable-next-line
  }, [drawDetails.finalApprovalNotRequired]);
  const handleCheckboxChange = (event: any) => {
    const FinalApprovalVal = event?.target?.checked ? "yes" : "no";
    dispatch(updateIsFinalApprovalRequired(FinalApprovalVal, drawId, isOrig));
  };
  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      className={classes.itemContainer}
    >
      <Grid container item xs={9} sm={9} md={9} lg={9}>
        <Typography
          className={classes.currentRateVersion}
          data-testid="net-funds-recap-view"
        >
          Net Funds to Borrower($):{" "}
          <span className={classes.netFunds}>
            {getNetFundsValue(netFundsToBorrower)}
          </span>
        </Typography>
        <Typography
          className={classes.currentRateVersion}
          data-testid="net-funds-recap-view"
        >
          Date Requested:{" "}
          <span className={classes.netFunds}>
            {changeDateFormat(drawDetails.requestedDate)}
          </span>
        </Typography>
        <Typography
          className={classes.currentRateVersion}
          data-testid="net-funds-recap-view"
        >
          Last Updated:{" "}
          <span className={classes.netFunds}>
            {changeDateFormat(drawDetails.lastUpdatedDate)}
          </span>
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={3}
        sm={3}
        md={3}
        lg={3}
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        {isAMTView() && (
          <span style={{ paddingBottom: 5 }}>
            <Checkbox
              checked={isFinalApprovalRequired?.toLowerCase() === "yes"}
              onChange={(e) => handleCheckboxChange(e)}
              data-testid="final-approval-required"
              style={{ marginBottom: 3 }}
              disabled={finalApprovalNotReqdDisableStages.includes(drawStage)}
            />
            Final Approval not required
          </span>
        )}
      </Grid>
    </Grid>
  );
};
