import { LoanStage } from "@toorak/tc-common-fe-sdk";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import { ObjectType } from "../masterView/common";
import { getConfig } from "../config/config";

export const bucketName = "trk-prod-file-management-tl-us-es4-gcs";
export type URLParamsType = {
  loanId: string;
  loanStage: LoanStage;
  loanType: string;
  dataReconcile?: string;
  drawId?: string;
};

export enum WaiverResponseState {
  REQUESTED = "REQUESTED",
  REREQUESTED = "RE-REQUESTED",
  APPROVED = "APPROVED",
  CONDITIONALLYAPPROVED = "APPROVED-CONDITIONALLY",
  REJECTED = "REJECTED"
}

const {
  Approved,
  PurchaseInitiated,
  Purchased,
  DataEntry,
  ExcelError,
  ExcelSuccess,
  Submit,
  InitialReview,
  DueDiligenceReview,
  FinalReview
} = LoanStatusEnum;
export const PostApprovedStates = [Approved, PurchaseInitiated, Purchased];
export const prePurchaseInitiationStates = [
  LoanStatusEnum.Approved,
  LoanStatusEnum.DataEntry,
  LoanStatusEnum.ExcelError,
  LoanStatusEnum.ExcelSuccess,
  LoanStatusEnum.Submit,
  LoanStatusEnum.InitialReview,
  LoanStatusEnum.DueDiligenceReview,
  LoanStatusEnum.FinalReview
];
export const postApprovalStates = [
  LoanStatusEnum.PurchaseInitiated,
  LoanStatusEnum.Purchased
];
export const PreSubmitStates: string[] = [DataEntry, ExcelError, ExcelSuccess];
export const SubmitToDD = [Submit, InitialReview, DueDiligenceReview];
export const SubmitToFR = [...SubmitToDD, ...[FinalReview]];
export const IRToPIStates: string[] = [
  InitialReview,
  DueDiligenceReview,
  FinalReview,
  Approved,
  PurchaseInitiated
];
export const BundleStatuses = [
  "Loan Details Confirmation Pending",
  "Loan Details Confirmed",
  "Settlement & PA Generated",
  "Settlement Confirmation Pending",
  "Settlement Confirmed",
  "PA Signed",
  "Purchased",
];

export const settlementStatus: string[] = [
  "Settlement & PA Generated",
  "Settlement Confirmation Pending",
  "Settlement Confirmed",
  "PA Signed",
  "Purchased",
];

export const allPropertyFields = [
  "appraisalReportedNoiNcf",
  "originalAsIsAppraisalValue",
  "originalRepairedAppraisalValue",
  "purchasePrice",
  "appraisalReportedNoiNcf",
  "costBasisAfterCostIncurred",
  "grossPotentialRent",
  "annualPropertyTaxes",

  "changeInUseCase",
  "recentPropertyValuationDate",
  "propertyAcquisitionDate",
  "preRehabSquareFootage",
  "postRehabSquareFootage",
  "numberOfUnits",
  "percentageOccupied",
  "propertyType",
  "isPropertyPurchasedLast2Years",

  "city",
  "state",
  "postalCode"
];
export enum InternalDDStatusEnum {
  UnAssignedIR = "UnAssigned IR",
  IRPending = "IR Pending",
  IRInProgress = "IR InProgress",
  IRHold = "IR Hold",
  IRCompleted = "IR Completed",
  UnAssignedQC = "UnAssigned QC",
  QCPending = "QC Pending",
  QCInProgress = "QC InProgress",
  QCHold = "QC Hold",
  QCCompleted = "QC Completed",
  ApproverPending = "Approver Pending",
  ReviewInProgress = "Review InProgress",
  ReviewCleared = "Review Cleared",
  ReviewCompleted = "Review Process Completed"
}

export enum InternalRolesEnum {
  LAT_ADMIN = "LAT_ADMIN",
  LAT_IR = "LAT_IR",
  LAT_QC = "LAT_QC",
  LAT_APPROVER = "LAT_APPROVER",
  LAT_INQUIRY = "LAT_INQUIRY",
  LAT_VM = "LAT_VM",
  LAT_BD = "LAT_BD",
  LAT_CUSTODY_DOCS = "LAT_CUSTODY_DOCS",
  ORG_CUSTODY_DOCS = "ORG_CUSTODY_DOCS"
}

export enum DrawRolesEnum {
  DRAW_MEMBER = "AM_DRAW_MEMBER",
  DRAW_MANAGER = "AM_DRAW_MANAGER",
  ORIGINATOR_DRAW = "ORIGINATOR_DRAW_ROLE",
  ORIGINATOR_VIEW_DRAW = "ORIGINATOR_VIEW_DRAW"
}

export enum AssetManagementRolesEnum {
  AM_SO = "AM_SO"
}

export enum InquiryStatusEnum {
  Pending = "Pending",
  InProgress = "In-Progress",
  Completed = "Completed",
  Open = "Open"
}

export enum InternalStatusEnum {
  Open = "Open",
  Close = "Close"
}

export enum CompleteInquiryStatusActionsEnum {
  Waive = "Waive",
  Satisfied = "Satisfied"
}

export enum ResponseTypeEnum {
  Approver = "Approver Action and Comments",
  Recommendation = "Internal Recommendation and Comments",
  Inquiry = "Inquiry Action and Comments",
  OriginatorQuery = "Originator Query",
  SendtoOriginator = "Send to Originator",
  Query = "Query",
  Comment = "Comment",
  ASK_A_QUESTION = "ASK_A_QUESTION",
  SATISFY_EXCEPTION = "SATISFY_EXCEPTION",
  SATISFY_INQUIRY = "SATISFY_INQUIRY"
}

export enum InquiryLatestAction {
  StatusUpdateCondition = "Conclusion Comment",
  ASK_A_QUESTION = "Ask a question",
  SATISFY_EXCEPTION = "Satisfy Condition",
  WAIVER_REQUEST = "Waiver Request"
}

export enum ConditionStatusValues {
  Waive = "Waive",
  Rescind = "Rescind",
  Satisfy = "Satisfy"
}

export enum ConditionStatusDisplayValues {
  Waive = "Waived",
  Rescind = "Rescind",
  Satisfied = "Satisfied"
}

export enum ReviewerActionsEnum {
  Waive = "Waive",
  ReachouttoOriginator = "Reach out to Originator",
  ApproverDirections = "Approver Directions",
  InternalFindings = "Internal Findings",
  LooksGood = "Looks Good"
}

export enum ApproverActionsEnum {
  Waive = "Waive",
  Rescind = "Rescind",
  MakeitVisibletoOriginator = "Make it Visible to Originator"
  // Satisfy = "Satisfy"      //WF-2767
  // ApproveTheInquiry = "Approve the Inquiry", // commenting this option as per new requirement
  // ApproveConditionally = "Approve Conditionally",
  // Clear = "Clear"
}

export enum TextToBeCopiedFrom {
  CopyIr = "Copy - IR",
  CopyQc = "Copy - QC"
}

export enum ConditionStatusUpdates {
  Waive = "Waive",
  Rescind = "Rescind",
  Satisfied = "Satisfied"
}

export enum LoanProcessTypeEnum {
  Fantasy = "Fantasy",
  Express = "Express",
  Regular = "Regular",
  Premier = "Premier"
}

export enum tapeToFileStatusEnum {
  Completed = "TTF Completed",
  InProgress = "TTF InProgress",
  ToDo = "UnAssigned TTF",
  Pending = "TTF Pending",
  TTFHold = "TTF Hold"
}

export enum downloadDocsType {
  download = "DOWNLOAD",
  preview = "PREVIEW"
}
export const googleMapLink = `http://maps.google.com/?q=`;

export const showNewBudgetReview = () => {
  return true;

};

export const showBudgetReconcile = () => {
  return true;
};

export const iLPGoLiveDate =
  window.location.hostname === "dev.toorakcapital.info" ||
  window.location.hostname === "localhost"
    ? "2021-06-01T00:00:00Z"
    : window.location.hostname === "qa.toorakcapital.info"
    ? "2021-06-01T00:00:00Z"
    : window.location.hostname === "uat.toorakcapital.info"
    ? "2021-06-25T00:00:00Z"
    : ["www.gcp.toorakcapital.info", "www.toorakcapital.info"].includes(
        window.location.hostname
      )
    ? "2021-07-14T00:00:00Z"
    : ["perf.toorakcapital.info", "stage.toorakcapital.info"].includes(
        window.location.hostname
      )
    ? "2022-07-04T00:00:00Z"
    : new Date().toISOString(); // default case for which camunda is not configured in that environment

export const ttfGoLiveDate =
  window.location.hostname === "dev.toorakcapital.info" ||
  window.location.hostname === "localhost"
    ? "2021-10-27T00:00:00Z"
    : window.location.hostname === "qa.toorakcapital.info"
    ? "2021-10-29T00:00:00Z"
    : window.location.hostname === "uat.toorakcapital.info"
    ? "2021-11-19T10:00:00Z"
    : ["www.gcp.toorakcapital.info", "www.toorakcapital.info"].includes(
        window.location.hostname
      )
    ? "2021-11-24T05:00:00Z"
    : ["perf.toorakcapital.info", "stage.toorakcapital.info"].includes(
        window.location.hostname
      )
    ? "2022-07-04T00:00:00Z"
    : new Date().toISOString(); // default case for which camunda is not configured in that environment

export const originalInfoCutOff =
  window.location.hostname === "dev.toorakcapital.info" ||
  window.location.hostname === "localhost"
    ? "2021-11-26T00:00:00Z"
    : window.location.hostname === "qa.toorakcapital.info"
    ? "2021-11-30T07:00:00Z"
    : window.location.hostname === "uat.toorakcapital.info"
    ? "2021-11-30T10:00:00Z"
    : ["www.gcp.toorakcapital.info", "www.toorakcapital.info"].includes(
        window.location.hostname
      )
    ? "2021-12-01T12:30:00Z"
    : ["perf.toorakcapital.info", "stage.toorakcapital.info"].includes(
        window.location.hostname
      )
    ? "2022-07-04T00:00:00Z"
    : new Date().toISOString(); // default case for which camunda is not configured in that environment

export const loansToILPAtInitialReviewCutOff =
  window.location.hostname === "dev.toorakcapital.info" ||
  window.location.hostname === "localhost"
    ? "2022-07-05T10:30:00Z"
    : window.location.hostname === "qa.toorakcapital.info"
    ? "2022-07-11T06:45:00Z"
    : window.location.hostname === "uat.toorakcapital.info"
    ? "2022-07-12T12:30:00Z"
    : ["www.gcp.toorakcapital.info", "www.toorakcapital.info"].includes(
        window.location.hostname
      )
    ? "2022-07-29T08:50:00Z"
    : ["perf.toorakcapital.info", "stage.toorakcapital.info"].includes(
        window.location.hostname
      )
    ? "2022-10-01T12:00:00Z"
    : new Date().toISOString(); // default case for which this setup is not done

export const requestWaiverMessage = `Are you sure you want to edit the fields? The evaluation tests will
be run again for the fields and if there is a waiver request it
would be requested.`;

export const extensionList = [
  // doc format
  "docs",
  "docx",
  "doc",
  // pdf format
  "pdf",
  // image format
  "jpg",
  "jpeg",
  // "JPEG",
  "png",
  // "PNG",
  // excel format
  "xlsx",
  "xls",
  // text file
  "txt"
];

export const ruleV02_22 = "v-02-22";

export const TABLE_FUNDING = "table_funding";
export const WAREHOUSE_FUNDING = "Warehouse Funded";
export const ADVANCE_FUNDING = "advance_funding";

export const allDateFields = [
  "originalCreditReportDate",
  "cutOffDate",
  "originationDate",
  "firstPaymentDateOfLoan",
  "originalMaturityDate",
  "accrualDate",
  "nextPaymentDueDate",
  "recentPropertyValuationDate",
  "propertyAcquisitionDate",
  "originalCreditScoreReportDate",
  "expectedClosingDate",
  "pricingPaymentDueDate"
];

export enum APPRAISAL_STATUS_ENUM {
  DATA_ENTRY = "data entry",
  UNDER_REVIEW = "under review",
  APPROVED = "approved",
  APPROVED_CONDITIONALLY = "approved conditionally",
  REJECTED = "rejected"
}
export interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export enum documentReconcileStatus {
  inProgress = "In Progress",
  completed = "Completed",
  pending = "Pending"
}

export enum LoanServicerStatus {
  inProgress = "In Progress",
  initiated = "Initiated",
  transferred = "Transferred",
  completed = "Completed",
  reviewed = "Reviewed",
  pending = "Pending"
}

export enum LoanServicerNames {
  bsi = "BSI",
  fci = "FCI",
  situs = "SITUS",
  shellpoint = "SHELLPOINT",
  fay = "Fay"
}

export const waiverRequestConfig: ObjectType = {
  // BridgeLoan: {},
  // InvestorDSCR: {
  // "v-01-23": false,
  // showForOrgs: [
  //     "c46a2999-38e0-4c69-9dc2-9441924d1769", // dev
  //     "6fa8c7a7-442e-412c-b7f5-ee4a5d69276e", // stage
  //     "4e5e0727-7854-4428-898b-fba5f0b2985e", // qa
  //     "6fa8c7a7-442e-412c-b7f5-ee4a5d69276e",
  //     "c73040b6-de14-4681-936d-896cacf26e6f", // uat
  //     "27a76112-40ef-43e1-8792-c7954fcdc682", // prod
  //     "18067b04-121d-4b8e-84ce-36aa8e6543b7", // treat XYZ Capital as Merchants in uat
  //     "a9bf5cf8-7782-403f-a724-f90b74ab8b06", // test merchants account in UAT (not used)
  //     "8a751408-7603-4e4a-b6cb-799de029a098" // new test merchants account in UAT
  //   ]
  // }
};

// TODO Remove beolw code if not required in future
// export const takeoutPartnerIds: ObjectType = {
//   "f205d903-65e2-46e8-b5cd-f0cc858ca142": "Toorak",
//   "0a7df521-8f61-4631-a8a5-a875af4ce1d3": "GA",
//   "14d6ce44-5ea6-45ad-80df-051687956ec4": "Test TP"
// };
// export const getTakeoutPartnerNameById = (id: string) =>
//   takeoutPartnerIds?.[id] ?? "";
