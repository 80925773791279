/* eslint-disable no-unneeded-ternary */
import React, { useState } from "react";
import { Dialog, Button, Theme, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import CloseIcon from "@mui/icons-material/Close";
import { GridTextFieldForm } from "@toorak/tc-common-fe-sdk";
import { AggregatePropertyTable } from "./AggregatePropertyTable";
import { getColumns, getRowHeader } from "./configs/PropertyAggregateFields";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalButton: {
      marginRight: 12,
      color: "#ffffff",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      width: 80,
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      marginTop: 20,
      borderWidth: 0,
      "&:hover": {
        backgroundColor: "#5566d6 !important"
      }
    },
    dialogCancelBtn: {
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      backgroundColor: "#ffffff",
      border: "solid 1px #ececec",
      color: "#32325d",
      "&:hover": {
        backgroundColor: "#ffffff !important"
      }
    },
    dialogDeleteButton: {
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: 1.83,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#ffffff",
      // width: "89px",
      textTransform: "none",
      height: "36px",
      borderRadius: "4px",
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      "&:hover": {
        backgroundColor: "#5566d6 !important"
      }
    }
  })
);
export const EditModal = (props: any) => {
  const classes = useStyles();
  const { handleClose, handleSave, loanType, loanStage, data, askForComment } =
    props;
  const [comment, setComment] = useState<string>();
  const [commentMissingError, setCommentMissingError] = useState<
    string | null
  >();
  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="aggregate-property-edit"
      aria-describedby="aggregate-property-edit"
      data-testid="aggregate-property-edit"
      maxWidth="xl"
    >
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexGrow: 1,
            alignItems: "center",
            padding: 20
          }}
        >
          <div
            style={{
              fontSize: 14,
              fontWeight: 600,
              color: "#32325d",
              width: "100%"
            }}
            data-testid="header-text"
          >
            Edit Aggregated Property Details
          </div>
          <CloseIcon
            htmlColor="#414169"
            style={{ width: 16, height: 16, cursor: "pointer" }}
            onClick={handleClose}
          />
        </div>
        <div
          style={{
            fontSize: 12,
            lineHeight: 1.5,
            color: "#32325d",
            wordBreak: "break-word"
          }}
          data-testid="body-text"
        >
          <AggregatePropertyTable
            headers={[
              getRowHeader(loanType, loanStage, "firstRowForLabel"),
              getRowHeader(loanType, loanStage, "aggregateValueRow"),
              getRowHeader(loanType, loanStage, "aggregateDistributeRow")
            ]}
            stickyColumnRange={1}
            columnHeaders={getColumns(loanType, loanStage)}
            tableData={{
              ...data,
              columnValues: data.propertyDetails
            }}
          />
        </div>
        <div style={{ padding: "15px 20px 10px 20px" }}>
          {askForComment && (
            <Grid xs={12}>
              <Grid xs={12} sm={8} md={6} style={{ height: 80 }}>
                <GridTextFieldForm
                  required
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                    if (commentMissingError) setCommentMissingError(null);
                  }}
                  error={!!commentMissingError}
                  helperText={commentMissingError}
                  label="Edit Comment"
                />
              </Grid>
            </Grid>
          )}
          <Button
            variant="contained"
            color="primary"
            className={classes.modalButton}
            onClick={() => {
              if (askForComment && !comment) {
                setCommentMissingError("Please enter reason for edit.");
                return;
              }
              handleSave(comment);
            }}
            data-testid="primaryBtn"
          >
            Save
          </Button>
          <Button
            autoFocus
            onClick={handleClose}
            color="primary"
            className={`${classes.dialogCancelBtn} ${classes.modalButton}`}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
