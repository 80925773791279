import { Grid } from "@mui/material";
import React from "react";
// import { getAdditionalLoanEconomicsData } from "../../../stores/drawRequest/drawRequest.action";
// import { DrawRequestStore } from "../../stores/drawRequest/drawRequest.reducer";
import { sanitizeCurrency } from "../../../utils/formatChecks";
import { MMMDDYYYYDateFormat } from "../../../utils/utils";
import {
  additionalLoanEconomicsFields,
  additionalLoanEconomicsFieldsInterface
} from "../Constant";
import DrawBalanceToFinish from "../DrawBalanceToFinish";
import { useDrawsStyles } from "../Draws.styles";

export const DrawDetailsLoanEconomics = ({
  drawObj,
  fieldWidth
}: {
  drawObj: any;
  fieldWidth: 2 | 3;
}) => {
  const classes = useDrawsStyles();

  // const { additionalLoanEconomics } = useSelector<RootState, DrawRequestStore>(
  //   state => state.drawRequestStore
  // );
  const newFields: additionalLoanEconomicsFieldsInterface[] = additionalLoanEconomicsFields(
    drawObj
  );

  function getValueFormatted(field: additionalLoanEconomicsFieldsInterface) {
    if (field.fieldType === "date") {
      return field.value
        ? MMMDDYYYYDateFormat(field.value.toString())
        : "__ __";
    } else {
      return field.value ? field.value : "__ __";
    }
  }
  // const balanceFieldWidth = 6;

  // const [FieldData, setFieldData] = useState<any>([]);

  return (
    <Grid
      item
      container
      xs={12}
      data-testid="draw-view-itemContainer"
      className={classes.itemContainer}
    >
      <Grid
        item
        container
        xs={10}
        className={classes.itemHeaderText}
        data-testid="draw-view-itemHeaderText"
      >
        Loan Economics
      </Grid>

      {/* <Grid
        item
        container
        xs={2}
        data-testid="draw-view-expandCollapse"
        className={classes.expandCollapse}
      >
        <ExpandCollapse
          isMinimize={isMinimizedLoanEconomics}
          clicked={() => {
            setIsMinimizedLoanEconomics(!isMinimizedLoanEconomics);
          }}
          keyDown={() => {
            setIsMinimizedLoanEconomics(!isMinimizedLoanEconomics);
          }}
        />
      </Grid> */}
      <Grid
        item
        container
        xs={12}
        data-testid="draw-view-fieldContainer"
        className={classes.fieldContainer}
      >
        <Grid item container xs={fieldWidth} className={classes.fieldRow}>
          <div className={classes.itemBox}>
            <p className={classes.itemSubHeaderText}>
              Budget Financed By Toorak($)
            </p>
            {drawObj?.financedBudgetAmount ? (
              <p
                className={classes.itemValueText}
                data-testid="toorak-financedBudgetAmount-value"
              >
                {sanitizeCurrency(drawObj.financedBudgetAmount.toString())}
              </p>
            ) : (
              <p
                className={classes.itemValueText}
                data-testid="toorak-financedBudgetAmount-blank"
              >
                __ __
              </p>
            )}
          </div>
        </Grid>
        <Grid item container xs={fieldWidth} className={classes.fieldRow}>
          <div className={classes.itemBox}>
            <p className={classes.itemSubHeaderText}>
              Pre-Purchase Draw Amount($)
            </p>
            {drawObj?.prePurchaseDrawAmount ? (
              <p
                className={classes.itemValueText}
                data-testid="toorak-prePurchaseDrawAmount-value"
              >
                {sanitizeCurrency(drawObj.prePurchaseDrawAmount.toString())}
              </p>
            ) : (
              <p
                className={classes.itemValueText}
                data-testid="toorak-prePurchaseDrawAmount-blank"
              >
                __ __
              </p>
            )}
          </div>
        </Grid>
        {/* <Grid item container xs={balanceFieldWidth}> */}
        <DrawBalanceToFinish fieldWidth={fieldWidth} />
        {/* </Grid> */}
        {newFields.map((item: any, index: any) => {
          return (
            <Grid
              item
              container
              xs={fieldWidth}
              className={classes.fieldRow}
              key={`${item.field}${index}`}
            >
              <div className={classes.itemBox}>
                <p className={classes.itemSubHeaderText}>{item.label}</p>
                <p
                  className={classes.itemValueText}
                  data-testid={`toorak-${item.field}-value`}
                >
                  {getValueFormatted(item)}
                </p>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
