import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

export const formStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      borderRadius: 4,
      display: "flex",
      flexDirection: "column",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      backgroundColor: "#ffffff"
    },
    containerModal: {
      borderRadius: 4,
      display: "flex",
      flexDirection: "column",
      borderTop: "1px solid lightgrey",
      backgroundColor: "#ffffff"
    },
    containerForExceptions: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#ffffff"
    },
    generalWaiverContainer: {
      paddingTop: "60px"
    },
    generalContainer: {
      marginTop: "24px"
    },
    viewOneActionbutton: {
      borderRadius: "4px",
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4"
    },
    scrollToTop: {
      position: "absolute",
      right: "120px",
      bottom: "0px"
    },
    exceptioncontainer: {
      borderTop: "1px solid #F2F2F2",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#ffffff"
    },
    exceptionWaiverContainer: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#ffffff"
    },
    sectionContainer: {
      marginBottom: theme.spacing(3)
    },
    sectionWrapper: {
      padding: "20px 24px 8px 24px",
      display: "flex"
      // borderBottom: "1px solid #F2F2F2"
    },
    sectionWrapperExceptions: {
      // padding: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
      display: "flex",
      flexDirection: "column",
      borderBottom: "1px solid #F2F2F2",
      paddingBottom: "12px"
    },
    headingTypography: {
      textAlign: "left",
      fontSize: "14px !important",
      fontWeight: 600,
      marginRight: "10px",
      color: "#32325d",
      lineHeight: 1.57
    },
    fullWidth: {
      [theme.breakpoints.down("sm")]: {
        width: "100% !important"
      }
    },
    divider: {
      height: "1px",
      border: "solid 1px #f2f2f2",
      padding: "0px !important"
    },
    waiverFormContainer: {
      padding: "25px 25px 15px 25px"
    },
    avatar: {
      width: "28px",
      height: "28px"
    },
    avatarLabel: {
      display: "flex",
      alignItems: "center",
      fontSize: "11px",
      fontWeight: 600,
      lineHeight: 1.64,
      color: "#8897aa",
      fontStyle: "normal"
    },
    mobileViewStatus: {
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "left !important"
      }
    },
    removePaddingMobile: {
      padding: "0 !important"
    },
    commentCard: {
      backgroundColor: "#F6F9FC"
    }
  })
);

export const mitigatingClass = makeStyles((theme: Theme) =>
  createStyles({
    cardContainer: {
      paddingBottom: "30px"
    },
    subjectText: {
      fontFamily: "'Open Sans', sans-serif !important",
      color: "#8897aa",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      fontSize: "12px"
    },
    labelText: {
      fontFamily: "'Open Sans', sans-serif !important",
      color: "#8897aa",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      fontSize: "12px",
      paddingBottom: 8
    },
    viewDescriptionField: {
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      color: "#32325d",
      wordBreak: "break-all"
    }
  })
);

export const waiverFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    submitButton: {
      height: "33px"
    },
    submitandActionContainer: {
      paddingRight: "0 !important"
    },
    submitandAction: {
      borderRadius: "4px 0px 0px 4px"
    },
    uploadButton: {
      backgroundColor: "#ffffff",
      color: "#32325d",
      minWidth: "0px",
      minHeight: "0px",
      padding: "6px",
      height: "36px",
      width: "36px",
      margin: "0px"
    },
    fileBarWrapper: {
      padding: "10px 0px 0px 0px"
    },
    fileContainer: {
      backgroundColor: "#f7fafc",
      padding: "5px"
    },
    fileSectionWrappers: {
      marginBottom: "24px"
    },
    fileSectionWaiver: {
      marginBottom: "10px"
    },
    contentDisplay: {
      display: "flex"
    },
    multilineColor: {
      color: "black",
      padding: "11px 11px"
    }
  })
);
