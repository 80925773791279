import {
  aggregateDivide
  // cancelAggregateModal,
  // expandCollapseRowClick,
  // keepPrevReducerVal,
  // resetFieldValues
} from "./loanAndPropertyDetails.reducer";

// export const EXPAND_COLLAPSE_ROW_CLICK = "EXPAND_COLLAPSE_ROW_CLICK";
// export const AGGREGATE_DIVIDE = "AGGREGATE_DIVIDE";
// export const CANCEL_AGGREGATE_MODAL = "CANCEL_AGGREGATE_MODAL";
// export const KEEP_PREVIOUS_REDUCER_VALUE = "KEEP_PREVIOUS_REDUCER_VALUE";
// export const RESET_FIELD_VALUES = "RESET_FIELD_VALUES";
// export const handleExpandCollapseRow = (rowId: string) => {
//   return async (dispatch: any) => {
//     dispatch(expandCollapseRowClick({ rowId }));
//     // dispatch({
//     //   type: EXPAND_COLLAPSE_ROW_CLICK,
//     //   payload: { rowId }
//     // });
//   };
// };
// export const keepOldReducerValue = () => {
//   return async (dispatch: any) => {
//     dispatch(keepPrevReducerVal());
//     // dispatch({
//     //   type: KEEP_PREVIOUS_REDUCER_VALUE
//     // });
//   };
// };
export const handleAggregateDivide = (data: {
  sectionId: string;
  fieldId: string;
  value: any;
  perProperty?: boolean;
  loanPropertyId?: any;
  checkIsDisabledUnit?: (data: any) => boolean;
}) => {
  const {
    fieldId,
    sectionId,
    value,
    checkIsDisabledUnit,
    perProperty,
    loanPropertyId
  } = data;
  // return async (dispatch: any) => {
  return aggregateDivide({
    fieldId,
    sectionId,
    value,
    checkIsDisabledUnit,
    perProperty,
    loanPropertyId
  });
  // dispatch({
  //   type: AGGREGATE_DIVIDE,
  //   payload: {
  //     fieldId,
  //     sectionId,
  //     value,
  //     checkIsDisabledUnit,
  //     perProperty,
  //     loanPropertyId
  //   }
  // });
};

// export const resetFieldValue = (data: {
//   sectionId: string;
//   fieldId: string;
// }) => {
//   const { fieldId, sectionId } = data;
//   return async (dispatch: any) => {
//     dispatch(
//       resetFieldValues({
//         fieldId,
//         sectionId
//       })
//     );
//     // dispatch({
//     //   type: RESET_FIELD_VALUES,
//     //   payload: {
//     //     fieldId,
//     //     sectionId
//     //   }
//     // });
//   };
// };

// export const handleAggregateDivideUnit = (data: {
//   sectionId: string;
//   fieldId: string;
//   value: any;
//   checkIsDisabledUnit?: (data: any) => boolean;
// }) => {
//   const { fieldId, sectionId, value, checkIsDisabledUnit } = data;
//   return async (dispatch: any) => {
//     dispatch(
//       aggregateDivide({ fieldId, sectionId, value, checkIsDisabledUnit })
//     );
//     // dispatch({
//     //   type: AGGREGATE_DIVIDE,
//     //   payload: { fieldId, sectionId, value, checkIsDisabledUnit }
//     // });
//   };
// };

// export const closeAggregateModal = (reset: boolean) => {
//   return async (dispatch: any) => {
//     dispatch(cancelAggregateModal({ reset }));
//     // dispatch({
//     //   type: CANCEL_AGGREGATE_MODAL,
//     //   payload: { reset }
//     // });
//   };
// };
