/* eslint-disable @typescript-eslint/camelcase */
import { publicClient } from "@toorak/tc-common-fe-sdk";

import {
  showLoader,
  hideLoader
} from "../loaderAndException/loaderAndException.action";

import { getConfig } from "../../config/config";
import { ObjectType } from "../../masterView/common";
import { rejectLoanById } from "../../network/apiService";
import {
  getAllOriginatorNames,
  getErrorMessage,
  getInitiatedLoans,
  getNonInitiatedLoans,
  getUnassignedLoansAmount,
  getUnassignedLoansInfo,
  setActiveServicerTab,
  updateAllCreditLines
} from "./takeoutPartner.reducer";

export function setActiveTabServicer(activeServicerTab: string): any {
  //   return {
  //     type: SET_ACTIVE_SERVICER_TAB,
  //     payload: { activeServicerTab }
  //   };
  return async (dispatch: any) => {
    dispatch(setActiveServicerTab({ activeServicerTab }));
  };
}

export function getUnAssignedLoan(requestBody: any): any {
  return async (dispatch: any) => {
    const url = `${getConfig().apiUrl}/search/${
      getConfig().aggregateUrl
    }/_search`;

    try {
      dispatch(showLoader());
      const unassignedLoanResponse: any = await publicClient.post(
        url,
        requestBody
      );
      const { hits, aggregations } = unassignedLoanResponse.data.response;
      const initialReviewLoans = hits.hits.map((item: any) => {
        return { loanId: item._id, data: item._source };
      });

      const count = hits.total.value;
      const loanAmount = aggregations.unassignedLoanAmount.value;
      // dispatch({
      //   type: GET_UNASSIGNED_LOANS_AMOUNT,
      //   payload: {
      //     unAssignedLaonAmountAndCount: { count, loanAmount }
      //   }
      // });
      // dispatch({
      //   type: GET_UNASSIGNED_LOANS_INFO,
      //   payload: {
      //     initialReviewLoans
      //   }
      // });
      dispatch(
        getUnassignedLoansAmount({
          unAssignedLaonAmountAndCount: { count, loanAmount }
        })
      );

      // Dispatching GET_UNASSIGNED_LOANS_INFO action
      dispatch(
        getUnassignedLoansInfo({
          initialReviewLoans
        })
      );
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
    }
  };
}

export function getErrorMessages(templateKeys: any): any {
  return async (dispatch: any) => {
    const body = { templateKeys };
    try {
      dispatch(showLoader());
      const URL = `${
        getConfig().apiUrl
      }/template/fetch?locale=NA&countryCode=NA&partialProcess=true`;

      const errorMessageResponse: any = await publicClient.post(URL, body);
      const errorMessages = errorMessageResponse.data;
      // dispatch({
      //   type: GET_ERRROR_MESSAGE,
      //   payload: {
      //     errorMessages
      //   }
      // });
      dispatch(
        getErrorMessage({
          errorMessages
        })
      );
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
    }
  };
}

export function getDDReviewInitiatedLoan(requestBody: ObjectType): any {
  return async (dispatch: any) => {
    const url = `${getConfig().apiUrl}/search/${
      getConfig().aggregateUrl
    }/_search`;

    try {
      dispatch(showLoader());
      const ddReviewInitiatedLoanResponse: any = await publicClient.post(
        url,
        requestBody
      );
      const { hits } = ddReviewInitiatedLoanResponse.data.response;
      const DDInitiatedLoan =
        hits.hits.length > 0
          ? hits.hits.map((item: any) => {
              return { loanId: item._id, data: item._source };
            })
          : [];
      // dispatch({
      //   type: GET_INITIATED_LOANS,
      //   payload: {
      //     DDInitiatedLoan
      //   }
      // });
      dispatch(
        getInitiatedLoans({
          DDInitiatedLoan
        })
      );
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
    }
  };
}

export function getDDReviewNonInitiatedLoan(requestBody: any): any {
  return async (dispatch: any) => {
    const url = `${getConfig().apiUrl}/search/${
      getConfig().aggregateUrl
    }/_search`;

    try {
      dispatch(showLoader());
      const DDReviewNonInitiatedLoanResponse: any = await publicClient.post(
        url,
        requestBody
      );
      const { hits } = DDReviewNonInitiatedLoanResponse.data.response;
      const DDNonInitiatedLoan =
        hits.hits.length > 0
          ? hits.hits.map((item: any) => {
              return { loanId: item._id, data: item._source };
            })
          : [];
      // dispatch({
      //   type: GET_NON_INITIATED_LOANS,
      //   payload: {
      //     DDNonInitiatedLoan
      //   }
      // });
      dispatch(
        getNonInitiatedLoans({
          DDNonInitiatedLoan
        })
      );
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
    }
  };
}

// export const updateAppliedFilterStatus = (status: boolean) => {
//   return {
//     type: SET_APPLIED_FILTER_UPDATED,
//     payload: { status }
//   };
// };

export const rejectLoan = async (loan: ObjectType, cb?: Function) => {
  await rejectLoanById(loan);
  if (cb) cb();
};

export const assignLoansToPartner: any = (loans: any, cb: Function) => {
  return async (dispatch: any) => {
    const url = `${getConfig().apiUrl}/loans/bulk`;
    const body = loans;

    try {
      dispatch(showLoader());
      await publicClient.patch(url, body);
      dispatch(getAllCreditLines());
      dispatch(hideLoader());
      cb();
    } catch (error) {
      dispatch(hideLoader());
    }
  };
};

/**
 *
 *  this was never been used, so commented out
 */
// export function updateTKOPFilter(
//   loanTypeSelected: string[],
//   selectedScenarioStatusType: string[],
//   selectedDaysFilter: string[],
//   selectedOrgFilter: string[],
//   propertyTypeFilter: string[]
// ) {
//   return (dispatch: any) => {
//     // dispatch({
//     //   type: SET_TKOP_FILTERS,
//     //   payload: {
//     //     loanTypeSelected,
//     //     selectedScenarioStatusType,
//     //     selectedDaysFilter,
//     //     selectedOrgFilter,
//     //     propertyTypeFilter
//     //   }
//     // });

//   };
// }

export function getAlloriginatorNames(): any {
  return async (dispatch: any) => {
    try {
      const queryObject = {
        query: {
          bool: {
            must: [],
            must_not: []
          }
        },
        _source: ["*"],
        sort: [
          {
            updatedOn: "desc"
          },
          "_score"
        ],
        track_total_hits: true,
        aggs: {
          originatorName: {
            terms: {
              field: "originator.accountName.raw",
              size: 500
            }
          }
        },
        from: 0,
        size: 0
      };
      const url = `${getConfig().apiUrl}/search/${
        getConfig().aggregateUrl
      }/_search`;
      dispatch(showLoader());
      const unassignedLoanResponse: any = await publicClient.post(
        url,
        queryObject
      );

      const { aggregations } = unassignedLoanResponse.data.response;
      const orginatorNames = aggregations.originatorName.buckets?.map(
        (item: any) => {
          return { label: item.key, id: item.key };
        }
      );
      // dispatch({
      //   type: GET_ALL_ORGINATOR_NAMES,
      //   payload: {
      //     originatorNames: orginatorNames
      //   }
      // });
      dispatch(
        getAllOriginatorNames({
          originatorNames: orginatorNames
        })
      );
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
    }
  };
}

export function getAllCreditLines(): any {
  return async (dispatch: any) => {
    const url = `${
      getConfig().apiUrl
    }/credit-line/fetch?creditLineType=TAKEOUT_PARTNER`;
    try {
      dispatch(showLoader());
      const creditLineResponse: any = await publicClient.get(url);
      const creditLines = creditLineResponse.data;
      // dispatch({
      //   type: GET_ALL_CREDIT_LINES,
      //   payload: {
      //     creditLines
      //   }
      // });
      dispatch(
        updateAllCreditLines({
          creditLines: creditLines
        })
      );
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
    }
  };
}
