/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
// import { produce } from "immer";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import {
//   UPDATE_APPRAISAL_FORM_FIELD,
//   UPDATE_APPRAISAL_COMPARABLE,
//   GET_APPRAISAL_INFO_SUCCESS,
//   RESET_APPRAISAL_STORE,
//   UPDATE_HOUSE_CANARY_FORM_FIELD,
//   UPDATE_TOORAK_RECONCILIATION_FORM,
//   UPDATE_APPRAISAL_INFO_FAILURE,
//   UPDATE_APPRAISAL_INFO_SUCCESS,
//   CHECK_APPRAISAL_STATUS_APPROVE_CONDITIONALLY,
//   GET_HOUSE_CANARY_LISTING_HISTORY_SUCCESS,
//   UPDATE_HOUSE_CANARY_LISTING_HISTORY,
//   UPDATE_HOUSE_CANARY_LISTING_HISTORY_FIELD,
//   GET_HOUSE_CANARY_SALES_HISTORY_SUCCESS,
//   UPDATE_HOUSE_CANARY_SALES_HISTORY,
//   UPDATE_HOUSE_CANARY_SALES_HISTORY_FIELD,
//   UPDATE_SUBJECT_HISTORY_FORM_FIELD,
//   UPDATE_APPRAISAL_LANDMARK_SUCCESS
// } from "./appraisal.action";
import {
  AppraisalStatus,
  AppraisalStatusType
} from "../../create-loan/appraisal/util";
import { LoanStage } from "@toorak/tc-common-fe-sdk";

export type FormField = string | number | null;

export interface AppraisalForm {
  [key: string]: any;
  propertySquareFootageAsIs: FormField;
  propertySquareFootageProposed: FormField;
  bedroomsAsIs: FormField;
  bedroomsProposed: FormField;
  bathroomsAsIs: FormField;
  bathroomsProposed: FormField;
  comment: FormField;
  lenderProvidedSalesComparables: ComparableFields[];
  borrowerARV: FormField;
  lenderCalculatedAsRenovatedValue: FormField;
  status: AppraisalStatusType;
  postalCode: FormField;
  state: FormField;
  city: FormField;
  country: FormField;
  address: FormField;
  zillowLink: FormField;
  loanID: FormField;
  assignmentFee: FormField;
  proposedRenovationBudget: FormField;
  purchasePrice: FormField;
  convertedTo: LoanStage | null;
  toorakApprovedARV: FormField;
}

export function appraisalFormFields(): AppraisalForm {
  return {
    propertySquareFootageAsIs: null,
    propertySquareFootageProposed: null,
    bedroomsAsIs: null,
    bedroomsProposed: null,
    bathroomsAsIs: null,
    bathroomsProposed: null,
    comment: null,
    borrowerARV: null,
    lenderCalculatedAsRenovatedValue: null,
    lenderProvidedSalesComparables: [
      comparableFields(),
      comparableFields(),
      comparableFields()
    ],
    status: "data entry",
    zillowLink: null,
    loanID: null,
    address: null,
    country: "USA",
    city: null,
    state: null,
    postalCode: null,
    proposedRenovationBudget: null,
    assignmentFee: null,
    purchasePrice: null,
    convertedTo: null,
    toorakApprovedARV: null
  };
}

export interface HouseCanaryForm {
  [key: string]: any;
  value25: FormField;
  value5: FormField;
  value50: FormField;
  value75: FormField;
  value95: FormField;
  valueMax: FormField;
  hcAvmMin: FormField;
  hcAvmMedian: FormField;
  hcAvmMax: FormField;
}

export function houseCanaryFormFields(): HouseCanaryForm {
  return {
    value25: null,
    value5: null,
    value50: null,
    value75: null,
    value95: null,
    valueMax: null,
    hcAvmMin: null,
    hcAvmMedian: null,
    hcAvmMax: null
  };
}

export interface HouseCanaryListingHistory {
  [key: string]: any;
  listingDate: FormField;
  listingPrice: FormField;
  listingStatus: FormField;
  listingStatusDate: FormField;
}

export function houseCanaryListingHistory() {
  return {
    listingDate: null,
    listingPrice: null,
    listingStatus: null,
    listingStatusDate: null
  };
}

export interface ToorakReconciliationForm {
  [key: string]: any;
  convertYZ: FormField;
  propertyExplorer: FormField;
  msaRiskOfDecline: FormField;
  bpoValue: FormField;
  houseCanaryValue: FormField;
  houseCanaryRange: FormField;
  confidenceSummary: FormField;
  toorakApprovedARV: FormField;
  priceLwr: FormField;
  priceMean: FormField;
  fsd: FormField;
  priceUpr: FormField;
}
export function toorakReconciliationFormFields(): ToorakReconciliationForm {
  return {
    convertYZ: null,
    propertyExplorer: null,
    msaRiskOfDecline: null,
    bpoValue: null,
    houseCanaryValue: null,
    houseCanaryRange: null,
    confidenceSummary: null,
    houseCanarySummary: null,
    toorakApprovedARV: null,
    priceLwr: null,
    priceMean: null,
    fsd: null,
    priceUpr: null
  };
}

export interface HouseCanarySalesHistory {
  [key: string]: any;
  eventType: FormField;
  grantee1: FormField;
  grantee1ForeNames: FormField;
  grantee2: FormField;
  grantee2ForeNames: FormField;
  amount: FormField;
  grantor1: FormField;
  grantor1ForeNames: FormField;
  grantor2: FormField;
  grantor2ForeNames: FormField;
  apn: FormField;
  flips: FormField;
  recordDate: FormField;
  recordDoc: FormField;
  recordBook: FormField;
  recordPage: FormField;
  recordPropertyId: FormField;
  propertyID: FormField;
}

export function houseCanarySalesHistory(): HouseCanarySalesHistory {
  return {
    eventType: null,
    grantee1: null,
    grantee1ForeNames: null,
    grantee2: null,
    grantee2ForeNames: null,
    amount: null,
    grantor1: null,
    grantor1ForeNames: null,
    grantor2: null,
    grantor2ForeNames: null,
    apn: null,
    flips: null,
    recordDate: null,
    recordDoc: null,
    recordBook: null,
    recordPage: null,
    recordPropertyId: null,
    propertyID: null
  };
}

export interface SubjectHistoryForm {
  [key: string]: any;
  overbidAssignment: FormField;
}

export function subjectHistoryForm(): SubjectHistoryForm {
  return {
    overbidAssignment: null
  };
}

export interface AppraisalLandmarks {
  appraisal_landmarks_id: number;
  appraisal_id: string;
  appraisal_status: string;
  date: string;
  party_id: string;
}

export interface ComparableFields {
  [key: string]: any;
  bedrooms: FormField;
  comment: FormField;
  squareFee: FormField;
  salesPrice: FormField;
  salesDate: FormField;
  distanceToSubject: FormField;
  zillowLink: FormField;
  postalCode: FormField;
  state: FormField;
  city: FormField;
  address: FormField;
}

export function comparableFields(): ComparableFields {
  return {
    comment: null,
    bedrooms: null,
    squareFee: null,
    salesPrice: null,
    salesDate: null,
    distanceToSubject: null,
    zillowLink: null,
    postalCode: null,
    state: null,
    city: null,
    address: null
  };
}

export interface AppraisalState {
  form: AppraisalForm;
  houseCanaryForm: HouseCanaryForm;
  toorakReconciliationForm: ToorakReconciliationForm;
  showErrors: boolean;
  showApprovalCommentPopup: boolean;
  houseCanaryListingHistory: HouseCanaryListingHistory[];
  houseCanarySalesHistory: HouseCanarySalesHistory[];
  subjectHistoryForm: SubjectHistoryForm;
  appraisalLandmarks: AppraisalLandmarks[] | [];
}

export const initialState: AppraisalState = {
  showErrors: false,
  showApprovalCommentPopup: false,
  form: appraisalFormFields(),
  toorakReconciliationForm: toorakReconciliationFormFields(),
  houseCanaryForm: houseCanaryFormFields(),
  houseCanaryListingHistory: [houseCanaryListingHistory()],
  houseCanarySalesHistory: [houseCanarySalesHistory()],
  subjectHistoryForm: subjectHistoryForm(),
  appraisalLandmarks: []
};

const appraisalSlice = createSlice({
  name: "appraisal",
  initialState,
  reducers: {
    setAppraisalFormField: (
      state,
      action: PayloadAction<{ fieldName: string; fieldValue: any }>
    ) => {
      const { fieldName, fieldValue } = action.payload;
      if (state.form[fieldName] === fieldValue) return;
      state.form[fieldName] = fieldValue;
      if (
        fieldName === "status" &&
        fieldValue === AppraisalStatus.approvedConditionally
      ) {
        state.showApprovalCommentPopup = true;
      }
    },
    updateSubjectHistoryFormField: (
      state,
      action: PayloadAction<{ fieldName: string; fieldValue: any }>
    ) => {
      const { fieldName, fieldValue } = action.payload;
      if (state.subjectHistoryForm[fieldName] === fieldValue) return;
      state.subjectHistoryForm[fieldName] = fieldValue;
    },
    updateHouseCanaryFormFieldRedix: (
      state,
      action: PayloadAction<{ fieldName: string; fieldValue: any }>
    ) => {
      const { fieldName, fieldValue } = action.payload;
      if (state.houseCanaryForm[fieldName] === fieldValue) return;
      state.houseCanaryForm[fieldName] = fieldValue;
    },
    setToorakReconciliationFormField: (
      state,
      action: PayloadAction<{ fieldName: string; fieldValue: any }>
    ) => {
      const { fieldName, fieldValue } = action.payload;
      if (state.toorakReconciliationForm[fieldName] === fieldValue) return;
      state.toorakReconciliationForm[fieldName] = fieldValue;
    },
    setAppraisalComparable: (
      state,
      action: PayloadAction<{
        fieldName: string;
        fieldValue: any;
        comparableIndex: number;
      }>
    ) => {
      const { fieldName, fieldValue, comparableIndex } = action.payload;
      if (
        state.form.lenderProvidedSalesComparables[comparableIndex][
          fieldName
        ] === fieldValue
      )
        return;
      state.form.lenderProvidedSalesComparables[comparableIndex][fieldName] =
        fieldValue;
    },
    setAppraisalInfoSuccess: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      for (const key in appraisalFormFields()) {
        if (action.payload[key]) state.form[key] = action.payload[key];
      }
      for (const key in houseCanaryFormFields()) {
        if (action.payload[key])
          state.houseCanaryForm[key] = action.payload[key];
      }
      for (const key in toorakReconciliationFormFields()) {
        if (action.payload[key])
          state.toorakReconciliationForm[key] = action.payload[key];
      }
      for (const key in subjectHistoryForm()) {
        if (action.payload[key])
          state.subjectHistoryForm[key] = action.payload[key];
      }
    },
    setHouseCanaryListingHistorySuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      state.houseCanaryListingHistory = [action.payload];
    },
    setHouseCanaryListingHistory: (state, action: PayloadAction<any>) => {
      state.houseCanaryListingHistory = action.payload;
    },
    setHouseCanaryListingHistoryField: (
      state,
      action: PayloadAction<{
        fieldName: string;
        fieldValue: any;
        index: number;
      }>
    ) => {
      const { fieldName, fieldValue, index } = action.payload;
      if (
        state.houseCanaryListingHistory.length > 0 &&
        state.houseCanaryListingHistory[index][fieldName] === fieldValue
      )
        return;
      state.houseCanaryListingHistory[index][fieldName] = fieldValue;
    },
    setHouseCanarySalesHistorySuccess: (state, action: PayloadAction<any>) => {
      state.houseCanarySalesHistory = action.payload;
    },
    setHouseCanarySalesHistory: (state, action: PayloadAction<any>) => {
      state.houseCanarySalesHistory = action.payload;
    },
    setHouseCanarySalesHistoryField: (
      state,
      action: PayloadAction<{
        fieldName: string;
        fieldValue: any;
        index: number;
      }>
    ) => {
      const { fieldName, fieldValue, index } = action.payload;
      if (
        state.houseCanarySalesHistory.length > 0 &&
        state.houseCanarySalesHistory[index][fieldName] === fieldValue
      )
        return;
      state.houseCanarySalesHistory[index][fieldName] = fieldValue;
    },
    setFalseAppraisalInfoSuccess: (state) => {
      state.showApprovalCommentPopup = false;
    },
    setAppraisalInfoFailure: (state) => {
      state.showErrors = true;
    },
    setAppraisalLandmarkSuccess: (state, action: PayloadAction<any>) => {
      state.appraisalLandmarks = action.payload;
    },
    checkAppraisalStatusApproveConditionally: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.showApprovalCommentPopup = action.payload;
    },
    resetAppraisalStore: () => initialState
  }
});

export const {
  setAppraisalFormField,
  updateSubjectHistoryFormField,
  updateHouseCanaryFormFieldRedix,
  setToorakReconciliationFormField,
  setAppraisalComparable,
  setAppraisalInfoSuccess,
  setHouseCanaryListingHistorySuccess,
  setHouseCanaryListingHistory,
  setHouseCanaryListingHistoryField,
  setHouseCanarySalesHistorySuccess,
  setHouseCanarySalesHistory,
  setHouseCanarySalesHistoryField,
  setFalseAppraisalInfoSuccess,
  setAppraisalInfoFailure,
  setAppraisalLandmarkSuccess,
  checkAppraisalStatusApproveConditionally,
  resetAppraisalStore
} = appraisalSlice.actions;

export const AppraisalReducer = appraisalSlice.reducer;

// export const AppraisalReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case UPDATE_APPRAISAL_FORM_FIELD: {
//       const { fieldName, fieldValue } = action.payload;
//       if (state.form[fieldName] === fieldValue) return state; // nothing changed
//       return produce(state, draftState => {
//         draftState.form[fieldName] = fieldValue;
//         if (
//           fieldName === "status" &&
//           fieldValue === AppraisalStatus.approvedConditionally
//         )
//           draftState.showApprovalCommentPopup = true;
//       });
//     }
//     case UPDATE_SUBJECT_HISTORY_FORM_FIELD: {
//       const { fieldName, fieldValue } = action.payload;
//       if (state.subjectHistoryForm[fieldName] === fieldValue) return state; // nothing changed
//       return produce(state, draftState => {
//         draftState.subjectHistoryForm[fieldName] = fieldValue;
//       });
//     }
//     case UPDATE_HOUSE_CANARY_FORM_FIELD: {
//       const { fieldName, fieldValue } = action.payload;
//       if (state.houseCanaryForm[fieldName] === fieldValue) return state; // nothing changed
//       return produce(state, draftState => {
//         draftState.houseCanaryForm[fieldName] = fieldValue;
//       });
//     }
//     case UPDATE_TOORAK_RECONCILIATION_FORM: {
//       const { fieldName, fieldValue } = action.payload;
//       if (state.toorakReconciliationForm[fieldName] === fieldValue)
//         return state; // nothing changed
//       return produce(state, draftState => {
//         draftState.toorakReconciliationForm[fieldName] = fieldValue;
//       });
//     }
//     case UPDATE_APPRAISAL_COMPARABLE: {
//       const { fieldName, fieldValue, comparableIndex } = action.payload;
//       if (
//         state.form.lenderProvidedSalesComparables[comparableIndex][
//           fieldName
//         ] === fieldValue
//       )
//         return state; // nothing changed
//       return produce(state, draftState => {
//         draftState.form.lenderProvidedSalesComparables[comparableIndex][
//           fieldName
//         ] = fieldValue;
//       });
//     }
//     case GET_APPRAISAL_INFO_SUCCESS: {
//       return produce(state, draftState => {
//         for (const key in appraisalFormFields()) {
//           if (action.payload[key]) draftState.form[key] = action.payload[key];
//         }
//         for (const key in houseCanaryFormFields()) {
//           if (action.payload[key])
//             draftState.houseCanaryForm[key] = action.payload[key];
//         }
//         for (const key in toorakReconciliationFormFields()) {
//           if (action.payload[key])
//             draftState.toorakReconciliationForm[key] = action.payload[key];
//         }
//         for (const key in subjectHistoryForm()) {
//           if (action.payload[key])
//             draftState.subjectHistoryForm[key] = action.payload[key];
//         }
//       });
//     }
//     case GET_HOUSE_CANARY_LISTING_HISTORY_SUCCESS: {
//       return produce(state, draftState => {
//         draftState.houseCanaryListingHistory = [action.payload];
//       });
//     }
//     case UPDATE_HOUSE_CANARY_LISTING_HISTORY: {
//       return produce(state, draftState => {
//         draftState.houseCanaryListingHistory = action.payload;
//       });
//     }
//     case UPDATE_HOUSE_CANARY_LISTING_HISTORY_FIELD: {
//       const { fieldName, fieldValue, index } = action.payload;
//       if (
//         state.houseCanaryListingHistory.length > 0 &&
//         state.houseCanaryListingHistory[index][fieldName] === fieldValue
//       )
//         return state; // nothing changed
//       return produce(state, draftState => {
//         draftState.houseCanaryListingHistory[index][fieldName] = fieldValue;
//       });
//     }
//     case GET_HOUSE_CANARY_SALES_HISTORY_SUCCESS:
//     case UPDATE_HOUSE_CANARY_SALES_HISTORY: {
//       return produce(state, draftState => {
//         draftState.houseCanarySalesHistory = action.payload;
//       });
//     }
//     case UPDATE_HOUSE_CANARY_SALES_HISTORY_FIELD: {
//       const { fieldName, fieldValue, index } = action.payload;
//       if (
//         state.houseCanarySalesHistory.length > 0 &&
//         state.houseCanarySalesHistory[index][fieldName] === fieldValue
//       )
//         return state; // nothing changed
//       return produce(state, draftState => {
//         draftState.houseCanarySalesHistory[index][fieldName] = fieldValue;
//       });
//     }
//     case UPDATE_APPRAISAL_INFO_SUCCESS: {
//       return produce(state, draftState => {
//         draftState.showApprovalCommentPopup = false;
//       });
//     }
//     case UPDATE_APPRAISAL_INFO_FAILURE: {
//       return produce(state, draftState => {
//         draftState.showErrors = true;
//       });
//     }
//     case UPDATE_APPRAISAL_LANDMARK_SUCCESS: {
//       const { payload } = action;
//       return produce(state, draftState => {
//         draftState.appraisalLandmarks = payload;
//       });
//     }
//     case CHECK_APPRAISAL_STATUS_APPROVE_CONDITIONALLY: {
//       return produce(state, draftState => {
//         draftState.showApprovalCommentPopup = action.payload;
//       });
//     }
//     case RESET_APPRAISAL_STORE: {
//       return initialState;
//     }
//     default:
//       return state;
//   }
// };
