import { LoanStage, publicClient } from "@toorak/tc-common-fe-sdk";
import { getConfig } from "../../config/config";
import {
  // clearLoader,
  hideLoader,
  showLoader
} from "../loaderAndException/loaderAndException.action";
import {
  clickAndDownload,
  downloadExportedTape
  // updateTTFSubmitState
} from "../../create-loan/create-loan.action";
import { InternalDDStatusEnum } from "../../utils/constants";
import { clearLoader } from "../loaderAndException/loaderAndException.reducer";
import {
  setOriginalParams,
  setSavedParams,
  updateBulkTTFLoader,
  updateParamsRedux
} from "./TapeToFile.reducer";
import { updateTTFSubmitState } from "../../create-loan/create-loan.reducer";

// export const UPDATE_DATA_TO_TAPE = "UPDATE_DATA_TO_TAPE";
// export const UPDATE_DATA_TO_TAPE_PROPERTY = "UPDATE_DATA_TO_TAPE_PROPERTY";
// export const UPDATE_PARAMS = "UPDATE_PARAMS";
// export const CLEANUP_PARAMS = "CLEANUP_PARAMS";
// export const SET_SAVED_PARAMS = "SET_SAVED_PARAMS";
// export const SET_ORIGINAL_PARAMS = "SET_ORIGINAL_PARAMS";
// export const SET_TTF_STATUS = "SET_TTF_STATUS";
// export const UPDATE_BULK_TTF_LOADER = "UPDATE_BULK_TTF_LOADER";

export function updateParams(newObj: any, param: any, index?: any): any {
  return (dispatch: any) => {
    // dispatch({
    //   type: UPDATE_PARAMS,
    //   payload: newObj,
    //   param: param,
    //   index: index
    // });
    dispatch(
      updateParamsRedux({
        payload: newObj,
        param: param,
        index: index
      })
    );
  };
}

export function saveTapeToFileData(
  saveObj: any,
  loanId: string,
  loanStage: string
): any {
  return async (dispatch: any) => {
    const url = `${
      getConfig().apiUrl
    }/exceptionservice/${loanId}/diligenceinfo?loanStage=${loanStage}`;
    try {
      const response: any = await publicClient.post(url, saveObj);
      if (response) {
        dispatch(hideLoader());
      }
    } catch (e) {
      console.error(e);
      dispatch(hideLoader());
    }
  };
}

export function getSavedTapeToFileData(
  loanId: string,
  loanStage: string,
  workflowState?: string
): any {
  return async (dispatch: any) => {
    const url = `${
      getConfig().apiUrl
    }/exceptionservice/${loanId}/diligenceinfo?loanStage=${loanStage}`;
    try {
      dispatch(showLoader());
      const response: any = await publicClient.get(url);
      if (response) {
        const documentInfo = response?.data?.documentInfo || [];
        const diligenceInfo = response?.data?.diligenceInfo || [];
        const originalLoanInfo = response?.data?.originalLoanInfo || {};
        setTimeout(() => {
          // dispatch({
          //   type: SET_SAVED_PARAMS,
          //   diligencePayload: diligenceInfo,
          //   documentPayload: Array.isArray(documentInfo) ? documentInfo : [],
          //   originalLoanInfoPayload: originalLoanInfo,
          //   isILPStateCompleted:
          //     workflowState === InternalDDStatusEnum.ReviewCompleted
          // });
          // dispatch({
          //   type: SET_ORIGINAL_PARAMS,
          //   payload: originalLoanInfo
          // });
          dispatch(
            setSavedParams({
              diligencePayload: diligenceInfo,
              documentPayload: Array.isArray(documentInfo) ? documentInfo : [],
              originalLoanInfoPayload: originalLoanInfo,
              isILPStateCompleted:
                workflowState === InternalDDStatusEnum.ReviewCompleted
            })
          );

          dispatch(
            setOriginalParams({
              payload: originalLoanInfo
            })
          );
          dispatch(clearLoader());
        }, 2500);
      }
    } catch (e) {
      console.error(e);
      dispatch(clearLoader());
    }
  };
}

// export function clearParams(): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: CLEANUP_PARAMS
//     });
//   };
// }

export function getLoanInfo(loanId: string, loanStage: LoanStage) {
  const url = `${
    getConfig().apiUrl
  }/aggregate/loans/${loanId}?loanStage=${loanStage}&includeResults=true`;
  return new Promise((resolve, reject) => {
    publicClient
      .get(url)
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}

export function getPropertyInfo(loanId: string, loanStage: LoanStage) {
  const url = `${
    getConfig().apiUrl
  }/loan_property/${loanId}/properties?stage=${loanStage}`;
  return new Promise((resolve, reject) => {
    publicClient
      .get(url)
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}

export function exportTapeToFileData(loanId: string, loanStage: string): any {
  return async (dispatch: any) => {
    const url = `${
      getConfig().apiUrl
    }/aggregate/exceptionservice/export/${loanId}/tapeToFile?loanStage=${loanStage}`;
    try {
      dispatch(showLoader());
      const response: any = await publicClient.post(url);
      if (response) {
        downloadExportedTape(response.data);
        dispatch(hideLoader());
      }
    } catch (e) {
      console.error(e);
      dispatch(hideLoader());
    }
  };
}

export function exportBulkTapeToFileData(payload: any) {
  return async (dispatch: any) => {
    const url = `${getConfig().apiUrl}/aggregate/export/tapeToFile`;
    try {
      dispatch(updateBulkTTFLoader(true));
      const response: any = await publicClient.post(url, payload);
      if (response?.data?.link) {
        downloadExportedTape(response.data.link);
      }
      dispatch(updateBulkTTFLoader(false));
    } catch (e) {
      console.error(e);
      dispatch(updateBulkTTFLoader(false));
    }
  };
}

// export function updateBulkTTFLoader(status: boolean) {
//   return (dispatch: any) => {
//     dispatch({
//       type: UPDATE_BULK_TTF_LOADER,
//       payload: status
//     });
//   };
// }

export function postLoanResults(
  loanId: string,
  loanStage: LoanStage,
  takeoutPartner: string | null | undefined,
  obj: any
) {
  let url = `${
    getConfig().apiUrl
  }/evaluation/v2/${loanId}/partner/results?loanStage=${loanStage}&resultType=OVERRIDDEN`;
  if (takeoutPartner) {
    url += `&partnerId=${takeoutPartner}`;
  }
  return new Promise((resolve, reject) => {
    publicClient
      .post(url, {
        loanResult: {
          [loanId]: obj
        },
        metadata: {
          comment: ""
        }
      })
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}

export function downloadFileForTTF(
  fileName: string,
  docsId: any,
  primaryIdenValue: string,
  secondaryIden: string,
  dispatch: any
) {
  return new Promise(async (resolve, reject) => {
    dispatch(showLoader());
    let url = `${
      getConfig().apiUrl
    }/documentaggregatorservice/documentdetails/LOAN/${primaryIdenValue}/documents/${docsId}/download`;
    if (secondaryIden) {
      url += `?secondary_identifier_name=${secondaryIden}&secondary_identifier_value=${secondaryIden}`;
    }
    const awsUrl = await publicClient.post(url);
    if (awsUrl) {
      const { data } = await publicClient.get(awsUrl.data.url, {
        responseType: "blob"
      });
      dispatch(hideLoader());
      openFileInNewTab(data, fileName);
      resolve(true);
    } else {
      reject(awsUrl);
    }
  });
}

export function openFileInNewTab(data: any, fileName: string) {
  let type: string;
  let splitArray = fileName.split(".");
  let extension = splitArray[splitArray.length - 1].toLowerCase();
  if (
    extension === "png" ||
    extension === "jpeg" ||
    extension === "jpg" ||
    extension === "pdf"
  ) {
    if (extension === "pdf") {
      type = `application/pdf`;
    } else {
      type = `image/${extension}`;
    }
    const url = window.URL.createObjectURL(new Blob([data], { type }));
    const newPage: any = window.open(url, "_blank");
    // Fix for WF-1000. Need to see if there is a better solution rather than using setInterval and setTimeout
    const interval = setInterval(() => {
      newPage.document.title = fileName;
    }, 100);
    setTimeout(() => {
      clearInterval(interval);
    }, 8000);
  } else {
    clickAndDownload(data, fileName);
  }
}

export function updateSubmittedFlag(loanId: string): any {
  return async (dispatch: any) => {
    const url = `${getConfig().apiUrl}/loans/tapeToFile/${loanId}/submit`;
    try {
      const response: any = await publicClient.put(url);
      if (response) {
        dispatch(updateTTFSubmitState(true));
      }
    } catch (e) {
      console.error(e);
    }
  };
}
