/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-nested-ternary */
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Tooltip,
  Theme,
  MenuItem,
  Popper,
  Paper,
  styled,
  Typography,
  Box,
  CircularProgress
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import {
  SnackBarMessage,
  LoanStage,
  publicClient
} from "@toorak/tc-common-fe-sdk";
import { getConfig, getLoanType } from "../config/config";
import {
  redirectToLoanPage,
  // showSummaryCheckListModal,
  // singleLoanRowDetails,
  setActiveInteractionsModal
} from "../create-loan/create-loan.action";
import {
  formatValueByType,
  isNullOrUndefined,
  nameToInitials
} from "./formatChecks";
import { isLATUser, isILP, pathflow } from "./AccessManagement";
import { downloadDocsType, InquiryStatusEnum } from "./constants";
import { generateAgeingLabel } from "./utils";
import { UserDetailsCard } from "../create-loan/exceptions-and-enquiries/UserDetailsCard";
// import { setViewType } from "../stores/ILP/ILP.action";
import {
  downloadFileForTTF,
  openFileInNewTab
} from "../stores/TapeToFile/TapeToFile.action";
import { RootState } from "../stores/rootReducer";
import {
  showLoader,
  hideLoader
} from "../stores/loaderAndException/loaderAndException.action";
import { exportGCSpath } from "../stores/servicerOnboarding/servicerOnboarding.action";
import { setViewType } from "../stores/ILP/ILP.reducer";

import {
  singleLoanRow,
  showSummaryCheckListModal
} from "../create-loan/create-loan.reducer";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black
  },
  tooltip: {
    backgroundColor: theme.palette.common.black
  }
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headingImage: {
      width: 22,
      height: 22,
      objectFit: "contain",
      cursor: "pointer",
      [theme.breakpoints.down("lg")]: {
        position: "absolute",
        right: "-8px",
        top: "30px"
      },
      [theme.breakpoints.down("md")]: {
        marginTop: "10px",
        marginBottom: "5px",
        right: "auto",
        position: "absolute",
        top: "38px"
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "5px",
        marginBottom: "5px",
        right: "auto",
        position: "relative",
        top: "5px"
      },
      "&:hover": {
        color: "#5e72e4"
      }
    },
    inquiryVerticalImage: {
      width: 22,
      height: 22,
      objectFit: "contain",
      cursor: "pointer",
      "&:hover": {
        color: "#5e72e4"
      }
    },
    inquiries: {
      fontSize: "12px"
    },
    tooltip: {
      color: "black",
      backgroundColor: "white"
    },
    countContainer: {
      backgroundColor: "#fb623f",
      color: "#ffffff",
      fontSize: 12,
      height: 21,
      width: 21,
      borderRadius: "30%",
      cursor: "pointer",
      padding: "5px",
      textAlign: "center",
      fontWeight: "bold",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "8px",
      boxShadow: "1px 1px 2px 0 rgba(0, 0, 0, 0.24)",
      [theme.breakpoints.down("lg")]: {
        // position: "absolute",
        // right: "-30px",
        // margin: "0",
        // top: "30px",
        // zIndex: 1
      },
      [theme.breakpoints.down("md")]: {
        // position: "absolute",
        // right: "40px",
        // margin: "0",
        // top: "49px",
        // zIndex: 1
      },
      [theme.breakpoints.down("sm")]: {
        // position: "absolute",
        // right: "calc(100% - -46px)",
        // margin: "0",
        // top: "44px",
        // zIndex: 1
      }
    },
    countContainerMarginNormal: {
      margin: "auto 0px"
    },
    countContainerMarginDetailView: {
      position: "revert",
      flexDirection: "column",
      margin: "18px 0px 0px 50px"
    },
    pendingItem: {
      fontSize: 12,
      listStyleType: "none",
      marginTop: "2px",
      marginBottom: "2px"
    },
    loanIdText: {
      color: "#5566d6",
      fontSize: 12,
      fontWeight: "bold",
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline"
      },
      marginLeft: "10px"
    },
    key: {
      fontSize: "12px",
      opacity: 0.5,
      letterSpacing: "0.2px",
      fontWeight: 600
    },
    label: {
      fontSize: "12px",
      letterSpacing: "0.2px",
      fontWeight: 600,
      paddingLeft: "6px"
    },
    loanMetadataUI: {
      width: "100%",
      justifyContent: "flex-start",
      padding: 0,
      paddingTop: 15,
      paddingLeft: 10
    },
    metaDataItem: {
      listStyleType: "none",
      marginBottom: "10px"
    },
    ageingImage: {
      width: "14px",
      height: "14px",
      position: "absolute",
      left: "62px"
    },
    summaryBtn: {
      fontSize: 12,
      margin: 0,
      padding: 0,
      "&:hover": {
        backgroundColor: "transparent"
      }
    },
    summaryMenu: {
      fontSize: 12,
      margin: 0
    },
    holdTag: {
      fontSize: 8,
      backgroundColor: "#fb623f",
      width: "fit-content",
      padding: "2px 3px",
      fontWeight: "bold",
      color: "#fff",
      borderRadius: 8
    },
    holdTooltip: {
      maxWidth: "300px",
      padding: "10px",
      marginLeft: "10px"
    },
    holdTooltipText: {
      fontSize: 12,
      fontWeight: "normal"
    }
  })
);
export const TextOnlyTooltip = withStyles({
  tooltip: {
    color: "#32325d",
    backgroundColor: "white",
    fontFamily: `"Open Sans", sans-serif !important`,
    fontSize: "12px",
    boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
    // width: "170px",
    padding: "5px"
  },
  arrow: {
    color: "white"
  }
})(Tooltip);
export const AgeingTooltip = withStyles({
  tooltip: {
    color: "#32325d",
    backgroundColor: "white",
    fontFamily: `"Open Sans", sans-serif !important`,
    fontSize: 12,
    boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
    width: "190px",
    padding: "12px"
  },
  arrow: {
    color: "white"
  }
})(Tooltip);
export const InquiryTooltip = withStyles({
  tooltip: {
    color: "#32325d",
    backgroundColor: "white",
    fontFamily: `"Open Sans", sans-serif !important`,
    fontSize: 12,
    boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
    width: "90px",
    padding: "9px"
  },
  arrow: {
    color: "white"
  }
})(Tooltip);

export const InfoOnlyTooltip = withStyles({
  // "& .MuiTooltip-popper": {
  //   zIndex: "9999999 !important"
  // },
  tooltip: {
    color: "#32325d",
    backgroundColor: "#FFF",
    fontFamily: `"Open Sans", sans-serif !important`,
    fontSize: 12,
    boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)"
  },
  arrow: {
    color: "white",
    fontSize: 13,
    // boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)"
    boxShadow: "-1px -1px 0px -2px rgba(50, 50, 93, 0.21)"
  }
})(Tooltip);

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "white",
    color: "#32325d",
    fontSize: 12,
    fontFamily: `"Open Sans", sans-serif !important`,
    boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
    width: "200px"
  },
  arrow: {
    color: "white"
  }
}))(Tooltip);

const MasterMultiplePropertyTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "white",
    color: "#32325d",
    fontSize: 12,
    fontFamily: `"Open Sans", sans-serif !important`,
    boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
    minWidth: "100px"
  },
  arrow: {
    color: "white"
  }
}))(Tooltip);

const MultiplePropertyTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "white",
    color: "#32325d",
    fontSize: 12,
    fontFamily: `"Open Sans", sans-serif !important`,
    boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
    width: "200px"
  },
  arrow: {
    color: "white"
  }
}))(Tooltip);

const MetadataTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    width: "auto",
    margin: " 10px 134px 0 154px",
    padding: "0",
    paddingRight: 5,
    maxWidth: "unset",
    borderRadius: "5px",
    border: "solid 1px #979797",
    backgroundColor: "#ffffff",
    color: "#32325d",
    marginTop: "20px",
    boxShadow: "0 3px 4px 0 rgba(0, 0, 0, 0.5)"
  },
  arrow: {
    color: "#000000",
    left: "85px !important",
    top: "-4px !important",
    transform: "scale(2.5)",
    "&:before": {
      color: "#ffffff",
      border: "1px solid #0000003b"
    }
  }
}))(Tooltip);

const SummaryTooltip = withStyles({
  tooltip: {
    color: "#32325d",
    backgroundColor: "#ffffff",
    fontSize: 12,
    boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
    padding: "9px",
    margin: 0
  }
})(Tooltip);
export interface IconType {
  date: any;
  imageFile: string;
  status: string;
  days: any;
  topic?: any;
}

const propertyFormatter = (props: any) => {
  return (
    <>
      {props === "SFR" ? (
        <span title="Single Family Detached">Single Family Detached</span>
      ) : (
        <span title={props}>{props}</span>
      )}
    </>
  );
};

export const MasterMultiplePropTooltip = (props: any) => {
  return (
    <MasterMultiplePropertyTooltip
      arrow
      title={
        <>
          {props.props.propertyTypeList.length &&
            props.props.propertyTypeList.map((item: any, index: number) => {
              return (
                <Typography
                  style={{
                    padding: "2px",
                    fontSize: 12,
                    color: "#32325d",
                    lineHeight: 1.5
                  }}
                  key={index}
                >
                  {propertyFormatter(item)}
                </Typography>
              );
            })}
        </>
      }
    >
      <span>{propertyFormatter(props?.props?.propertyType)}</span>
    </MasterMultiplePropertyTooltip>
  );
};
export const GridTooltip = (props: IconType) => {
  const { date, imageFile, status, days } = props;
  const classes = useStyles();
  const iconSrc = require(`../images/${imageFile}.png`);
  const isItILP = isILP();
  const { createdOn, text } = useMemo(() => {
    const createdOn =
      !isItILP && ["PURCHASE INITIATED", "PURCHASED", "FUNDED"].includes(status)
        ? "Bundle created on "
        : "Created on ";
    const text = !isItILP
      ? `The loan is in ${status} for past `
      : `The loan is in ${status}, and has an age of `;
    return {
      createdOn,
      text
    };
  }, [isItILP, status]);
  return (
    <AgeingTooltip
      arrow
      placement="right"
      title={
        <>
          <span>
            {createdOn}
            <span style={{ fontWeight: 600, whiteSpace: "nowrap" }}>
              {date}.
            </span>{" "}
            {text}
            <span style={{ fontWeight: 600, color: "#f5365c" }}>
              {days} Days
            </span>
          </span>
        </>
      }
    >
      {days > -1 ? (
        <img className={classes.headingImage} src={iconSrc} alt="ageingIcon" />
      ) : (
        <></>
      )}
    </AgeingTooltip>
  );
};

export const InquiryStatusTooltip = (props: any) => {
  const classes = useStyles();
  let inquiries = props.parentProps.row.enquiries || [];
  inquiries = inquiries.filter(
    (inquiry: any) =>
      !inquiry.hold &&
      inquiry.type.toLowerCase() === "inquiry" &&
      (inquiry.workflowState === InquiryStatusEnum.Pending ||
        inquiry.workflowState === InquiryStatusEnum.InProgress ||
        inquiry.workflowState === InquiryStatusEnum.Completed)
  );
  const pendingInquiries = inquiries.filter(
    (inquiry: any) => inquiry.workflowState === InquiryStatusEnum.Pending
  );
  const inProgressInquiries = inquiries.filter(
    (inquiry: any) => inquiry.workflowState === InquiryStatusEnum.InProgress
  );
  const completedInquiries = inquiries.filter(
    (inquiry: any) => inquiry.workflowState === InquiryStatusEnum.Completed
  );
  return (
    <span>
      <span className={classes.inquiries}>{inquiries.length}</span>
      <InquiryTooltip
        arrow
        placement="right"
        title={
          <div style={{ paddingTop: "10px" }}>
            <div style={{ paddingBottom: "10px", fontSize: "10px" }}>
              <span>{InquiryStatusEnum.Pending}: </span>
              <span>{pendingInquiries.length}</span>
            </div>
            <div style={{ paddingBottom: "10px", fontSize: "10px" }}>
              <span>{InquiryStatusEnum.InProgress}: </span>
              <span>{inProgressInquiries.length}</span>
            </div>
            <div style={{ paddingBottom: "10px", fontSize: "10px" }}>
              <span>{InquiryStatusEnum.Completed}: </span>
              <span>{completedInquiries.length}</span>
            </div>
          </div>
        }
      >
        <span className={classes.inquiryVerticalImage}>
          <IconButton style={{ padding: 0 }} size="large">
            <MoreVertIcon />
          </IconButton>
        </span>
      </InquiryTooltip>
    </span>
  );
};

export const LoanMasterIdTooltip = (props: any) => {
  const navigate = useNavigate();
  const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false);
  let propsId = props?.idProps;
  const handleTooltipClose = () => {
    setTooltipIsOpen(false);
  };

  const handlenewTabClick = (loanid: any) => {
    setTooltipIsOpen(false);
    const subURL = isILP() ? "internal/" : "";
    window.open(
      `${getConfig().redirectUrl}${subURL}loan/createLoan/${propsId.loanType}/${
        propsId.loanStage
      }/${propsId.toorakLoanId}?from=masterView`
    );
  };
  const dispatch = useDispatch();
  const classes = useStyles();
  const snackBarDetails: any = {
    verticalPlacement: "top",
    horizontalPlacement: "center",
    autoHideDuration: 3000,
    messageType: "success",
    message: "Loan ID copied to clipboard successfully"
  };
  const [openSnackBar, setOpenSnackBar] = React.useState<boolean>(false);
  return (
    <>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <TextOnlyTooltip
          // arrow
          // interactive
          placement="bottom-start"
          open={tooltipIsOpen}
          style={{ fontSize: 12, margin: 0 }}
          title={
            <>
              <MenuItem
                style={{ fontSize: 12, margin: 0, paddingLeft: 0 }}
                onClick={(e) =>
                  handlenewTabClick(
                    props.primaryLoanType
                      ? propsId.primaryLoanId
                      : propsId.toorakLoanId
                  )
                }
              >
                Open in new tab
              </MenuItem>
              <MenuItem
                style={{ fontSize: 12, margin: 0, paddingLeft: 0 }}
                onClick={(e) => {
                  if (navigator.clipboard !== undefined) {
                    navigator.clipboard
                      .writeText(
                        props.primaryLoanType
                          ? propsId.primaryLoanId
                          : propsId.toorakLoanId
                      )
                      .then(
                        function () {
                          setOpenSnackBar(true);
                        },
                        function (err: any) {
                          console.error("Could not copy text: ", err);
                        }
                      );
                  }
                }}
              >
                Copy Loan ID
              </MenuItem>
            </>
          }
        >
          <span
            className={classes.loanIdText}
            onContextMenu={(event: any) => {
              event.preventDefault();
              setTooltipIsOpen(!tooltipIsOpen);
            }}
            onClick={() => {
              dispatch(setViewType("details"));
              dispatch(
                redirectToLoanPage(
                  propsId.loanType,
                  propsId.loanStage,
                  propsId.toorakLoanId,
                  navigate,
                  "masterView"
                )
              );
            }}
          >
            {props.primaryLoanType
              ? propsId.primaryLoanId
              : propsId.toorakLoanId}
          </span>
        </TextOnlyTooltip>
      </ClickAwayListener>

      <SnackBarMessage
        open={openSnackBar}
        verticalPlacement={snackBarDetails.verticalPlacement}
        horizontalPlacement={snackBarDetails.horizontalPlacement}
        autoHideDuration={snackBarDetails.autoHideDuration}
        handleClose={() => setOpenSnackBar(false)}
        messageType={snackBarDetails.messageType}
        message={snackBarDetails.message}
      />
    </>
  );
};

export const DocumentTooltip = (props: any) => {
  const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    value,
    loanId,
    secondaryIden,
    previewFile,
    lowCs,
    handleDownload,
    drawId
  } = props;
  const name = lowCs ? value.fileName : value.name;
  const handleTooltipClose = () => {
    setTooltipIsOpen(false);
  };

  const downloadDrawFileForTTF = async () => {
    return new Promise(async (resolve, reject) => {
      dispatch(showLoader());
      const awsUrl = await handleDownload(value, downloadDocsType.preview);
      if (awsUrl) {
        const { data } = await publicClient.get(awsUrl.data.url, {
          responseType: "blob"
        });
        dispatch(hideLoader());
        resolve(openFileInNewTab(data, value.fileName || value.name));
      } else {
        reject(awsUrl);
      }
    });
  };
  const docUrl = async () => {
    setIsLoading(true);
    const file = {
      docsId: value.docId,
      name: value.fileName
    };
    let res = await handleDownload(file, value);
    const { data } = await publicClient.get(res.data.url, {
      responseType: "blob"
    });
    openFileInNewTab(data, name);
    setIsLoading(false);
  };
  const dispatch = useDispatch();
  const classes = useStyles();
  return (
    <>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <TextOnlyTooltip
          // arrow
          // interactive
          placement="bottom-start"
          open={tooltipIsOpen}
          style={{ fontSize: 12, margin: 0 }}
          title={
            <MenuItem
              style={{ fontSize: 12, margin: 0 }}
              onClick={(e) =>
                !lowCs
                  ? drawId
                    ? downloadDrawFileForTTF()
                    : downloadFileForTTF(
                        name,
                        value.docsId,
                        loanId,
                        secondaryIden,
                        dispatch
                      )
                  : docUrl()
              }
            >
              Open in new tab
            </MenuItem>
          }
        >
          <span
            className={classes.loanIdText}
            onContextMenu={(event: any) => {
              event.preventDefault();
              setTooltipIsOpen(!tooltipIsOpen);
            }}
            onClick={(e) => previewFile(value)}
          >
            {isLoading ? (
              <CircularProgress
                size={12}
                style={{
                  position: "relative",
                  marginLeft: 25,
                  top: 2
                }}
              />
            ) : (
              name
            )}
          </span>
        </TextOnlyTooltip>
      </ClickAwayListener>
    </>
  );
};

export const LoanIDTooltip = (props: any) => {
  const navigate = useNavigate();
  const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setTooltipIsOpen(false);
  };
  const handlenewTabClick = (loanid: any) => {
    setTooltipIsOpen(false);
    const subURL = isILP() ? "internal/" : "";
    window.open(
      `${
        getConfig().redirectUrl
      }${subURL}loan/createLoan/${loanTypePath}/${loanStage}/${
        props?.parentProps?.row?.toorakLoanId
      }`
    );
  };
  // const handlenewWindowClick = (loanid:any) => {
  //   console.log(`clicked`, loanid);
  // }
  const loanTypePath = props.parentProps.row.loanTypeName.includes("30 Year")
    ? getLoanType[1].displayValue
    : "BridgeLoan";
  const loanStage =
    props.parentProps.row.loanStage === "Pre-Close"
      ? "PRE"
      : props.parentProps.row.loanStage === "Post-Close"
      ? "POST"
      : props.parentProps.row.loanStage === "Guideline Evaluation"
      ? "FES"
      : props.parentProps.row?.loanStageRaw === "TOORVAL"
      ? "TOORVAL"
      : "";
  const dispatch = useDispatch();
  const classes = useStyles();
  const snackBarDetails: any = {
    verticalPlacement: "top",
    horizontalPlacement: "center",
    autoHideDuration: 3000,
    messageType: "success",
    message: "Loan ID copied to clipboard successfully"
  };
  const [openSnackBar, setOpenSnackBar] = React.useState<boolean>(false);
  return (
    <>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <TextOnlyTooltip
          // arrow
          // interactive
          placement="bottom-start"
          open={tooltipIsOpen}
          style={{ fontSize: 12, margin: 0, position: "relative", top: 2 }}
          title={
            <>
              <MenuItem
                style={{ fontSize: 12, margin: 0, paddingLeft: 0 }}
                onClick={(e) =>
                  handlenewTabClick(props?.parentProps?.row?.toorakLoanId)
                }
              >
                Open in new tab
              </MenuItem>
              <MenuItem
                style={{ fontSize: 12, margin: 0, paddingLeft: 0 }}
                onClick={(e) => {
                  if (navigator.clipboard !== undefined) {
                    navigator.clipboard
                      .writeText(props?.parentProps?.row?.toorakLoanId)
                      .then(
                        function () {
                          setOpenSnackBar(true);
                        },
                        function (err: any) {
                          console.error("Could not copy text: ", err);
                        }
                      );
                  }
                }}
              >
                Copy Loan ID
              </MenuItem>
            </>
          }
        >
          <span
            className={classes.loanIdText}
            onContextMenu={(event: any) => {
              event.preventDefault();
              setTooltipIsOpen(!tooltipIsOpen);
            }}
            onClick={() => {
              dispatch(
                redirectToLoanPage(
                  loanTypePath,
                  loanStage,
                  props?.parentProps?.row?.toorakLoanId,
                  navigate
                )
              );
            }}
          >
            {props?.parentProps?.row?.toorakLoanId}
          </span>
        </TextOnlyTooltip>
      </ClickAwayListener>
      {loanStage !== LoanStage.tv && (
        <SummaryCheckListTooltip
          loanDetails={props?.parentProps?.row}
          placement="left"
        />
      )}
      <SnackBarMessage
        open={openSnackBar}
        verticalPlacement={snackBarDetails.verticalPlacement}
        horizontalPlacement={snackBarDetails.horizontalPlacement}
        autoHideDuration={snackBarDetails.autoHideDuration}
        handleClose={() => setOpenSnackBar(false)}
        messageType={snackBarDetails.messageType}
        message={snackBarDetails.message}
      />
    </>
  );
};
export const CountTooltip = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { viewtype } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );
  return (
    <TextOnlyTooltip
      arrow
      placement="right"
      title={
        <>
          {props.pendingObj.map((item: any, index: number) => {
            return (
              <li className={classes.pendingItem}>
                {item.option} : {item.count}
              </li>
            );
          })}
        </>
      }
    >
      <span
        className={
          props.detailViewStyle
            ? `${classes.countContainer} ${classes.countContainerMarginDetailView}`
            : `${classes.countContainer} ${classes.countContainerMarginNormal}`
        }
        onClick={() => {
          viewtype !== "Compact" &&
            dispatch(
              setActiveInteractionsModal(true, props.loanId, props.loanStage)
            );
        }}
      >
        {props.count ? props.count : props.totalCount}
      </span>
    </TextOnlyTooltip>
  );
};

export const HtmlToolTip = (props: any) => {
  const { properties, combinedAddress, spanClass, multipleProperty } = props;
  const items = properties.map(
    (item: any, index: any) =>
      [
        item.propertyLocation?.addressLine1,
        item.propertyLocation?.addressLine2,
        item.propertyLocation?.addressLine3,
        item.propertyLocation?.city,
        item.propertyLocation?.state,
        item.propertyLocation?.country,
        item.propertyLocation?.postalCode
      ]
        .filter(Boolean)
        .join(", ") && (
        <li
          style={{
            listStyleType: "none",
            borderBottom: "1px solid #8897aa",
            marginTop: "2px",
            marginBottom: "2px"
          }}
          key={item.loanId}
        >
          {[
            item.propertyLocation?.addressLine1,
            item.propertyLocation?.addressLine2,
            item.propertyLocation?.addressLine3,
            item.propertyLocation?.city,
            item.propertyLocation?.state,
            item.propertyLocation?.country,
            item.propertyLocation?.postalCode
          ]
            .filter(Boolean)
            .join(", ")}
        </li>
      )
  );
  return (
    <HtmlTooltip
      arrow
      title={
        <>
          {properties.length > 1 ? (
            <ul style={{ padding: 0, marginLeft: 0, marginRight: 0 }}>
              {items.every((element: any) => element === "") ? (
                <li
                  style={{
                    listStyleType: "none",
                    marginBottom: "2px",
                    textAlign: "center"
                  }}
                >
                  No data available
                </li>
              ) : (
                items
              )}
            </ul>
          ) : properties.length === 1 ? (
            combinedAddress
          ) : (
            ""
          )}
        </>
      }
    >
      <span className={spanClass}>
        {properties.length > 1
          ? multipleProperty
          : properties.length === 1
          ? combinedAddress
          : ""}
      </span>
    </HtmlTooltip>
  );
};

export const MultiplePropertyToolTip = (props: any) => {
  let propertyInfoFlag: boolean = false;
  let propertyEcononmicsFlag: boolean = false;
  const {
    properties,
    spanClass,
    multipleProperty,
    columnKey,
    mainKey,
    subKey,
    formatterType
  } = props;
  const propertyInfo: any = [
    "propertyType",
    "propertyUnits",
    "recentPropertyValuationDate",
    "propertyAcquisitionDate",
    "changeInUseCase",
    "preRehabSquareFootage",
    "postRehabSquareFootage",
    "occupiedPercentage"
  ];
  const propertyEconomics: any = [
    "originalAsIsAppraisalValue",
    "originalAsRepairedAppraisedValue",
    "purchasePrice",
    "costBasis",
    "grossPotentialRent",
    "insurance",
    "financedBudgetAmount",
    "annualPropertyTaxes",
    "rentalCashflowRatio",
    "appraisalReportedNoiNcf"
  ];
  propertyInfoFlag = propertyInfo.includes(columnKey);
  propertyEcononmicsFlag = propertyEconomics.includes(columnKey);
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec"
  ];
  const dateFormatter = (date: any) => {
    const propDate = new Date(date);
    return `${month[propDate.getMonth()]} ${`0${propDate.getDate()}`.slice(
      -2
    )}, ${propDate.getFullYear()}`;
  };
  let items: any;

  const getPercentageOccupied = (item: any) => {
    return (
      formatValueByType(item?.[mainKey]?.[subKey], formatterType) || "0.0000%"
    );
  };
  if (
    columnKey !== "changeInUseCase" &&
    columnKey !== "leaseStatus" &&
    properties?.length > 1
  ) {
    items = properties?.map(
      (item: any, index: any) =>
        (propertyInfoFlag ||
          (item.propertyEconomics[columnKey] !== null &&
            item.propertyEconomics[columnKey] !== undefined &&
            item.propertyEconomics[columnKey] !== "")) && (
          <li
            style={{
              listStyleType: "none",
              borderBottom: "1px solid #8897aa",
              marginTop: "2px",
              marginBottom: "2px"
            }}
            key={index}
          >
            {propertyInfoFlag
              ? columnKey === "recentPropertyValuationDate" ||
                columnKey === "propertyAcquisitionDate"
                ? item?.propertyinfo?.[columnKey] &&
                  dateFormatter(item?.propertyinfo?.[columnKey])
                : columnKey === "occupiedPercentage"
                ? getPercentageOccupied(item)
                : columnKey === "propertyType" &&
                  item?.propertyinfo?.[columnKey] === "SFR"
                ? "Single Family Detached"
                : item?.propertyinfo?.[columnKey]
              : propertyEcononmicsFlag
              ? typeof item.propertyEconomics?.[columnKey] !== "number" &&
                item.propertyEconomics?.[columnKey].toString().indexOf(".") ===
                  -1
                ? `${
                    item.propertyEconomics?.[columnKey]
                      .toString()
                      .indexOf("$") === -1
                      ? "$"
                      : ""
                  }${item.propertyEconomics?.[columnKey]}.00`
                : columnKey === "appraisalReportedNoiNcf" &&
                  typeof item.propertyEconomics?.[columnKey] === "number"
                ? item.propertyEconomics?.[columnKey]?.toString() - 1
                  ? `${
                      item.propertyEconomics?.[columnKey]
                        .toString()
                        .indexOf("$") === -1
                        ? "$"
                        : ""
                    }${item.propertyEconomics?.[columnKey]}.00`
                  : `${item.propertyEconomics?.[columnKey]}.00`
                : item.propertyEconomics?.[columnKey].indexOf(".") === -1
                ? `${
                    item.propertyEconomics?.[columnKey].indexOf("$") === -1
                      ? "$"
                      : ""
                  }${item.propertyEconomics?.[columnKey]}.00`
                : `${
                    item.propertyEconomics?.[columnKey].indexOf("$") === -1
                      ? "$"
                      : ""
                  }${item.propertyEconomics?.[columnKey]}`
              : "No data available"}
          </li>
        )
    );
  }
  if (columnKey === "changeInUseCase" && properties.length > 1) {
    items = properties.map(
      (item: any, index: any) =>
        item.propertyinfo?.[columnKey] !== "" &&
        item.propertyinfo?.[columnKey] !== null &&
        item.propertyinfo?.[columnKey] !== undefined && (
          <li
            style={{
              listStyleType: "none",
              borderBottom: "1px solid #8897aa",
              marginTop: "2px",
              marginBottom: "2px"
            }}
            key={index}
          >
            {item.propertyinfo?.[columnKey] === true
              ? "Yes"
              : item.propertyinfo?.[columnKey] === false
              ? "No"
              : ""}
          </li>
        )
    );
  }
  if (columnKey === "leaseStatus" && properties.length > 1) {
    const myFunc = (item: any, index: any, proper: any) => {
      return item.leaseStatus
        ? item.leaseStatus.concat(index !== proper.length - 1 ? "," : "")
        : "";
    };
    items = properties.map(
      (griditem: any, index: any) =>
        griditem?.propertyUnit !== null &&
        griditem?.propertyUnit !== undefined &&
        griditem?.propertyUnit.length !== 0 && (
          <li
            style={{
              listStyleType: "none",
              borderBottom: "1px solid #8897aa",
              marginTop: "2px",
              marginBottom: "2px"
            }}
            key={index}
          >
            {griditem.propertyUnit !== null &&
            griditem.propertyUnit !== undefined &&
            griditem.propertyUnit.length !== 0
              ? griditem.propertyUnit
                  .map((item: any, index: any) =>
                    myFunc(item, index, griditem.propertyUnit)
                  )
                  .filter(Boolean)
                  .join(" ")
              : ""}
          </li>
        )
    );
  }
  let combinedLeaseStatus;
  if (columnKey === "leaseStatus" && properties.length === 1) {
    const myFunc = (item: any, index: any, proper: any) => {
      return item.leaseStatus
        ? item.leaseStatus.concat(index !== proper.length - 1 ? "," : "")
        : "";
    };
    combinedLeaseStatus =
      properties[0].propertyUnit !== null &&
      properties[0].propertyUnit !== undefined &&
      properties[0].propertyUnit.length !== 0
        ? properties[0].propertyUnit
            .map((item: any, index: any) =>
              myFunc(item, index, properties[0].propertyUnit)
            )
            .filter(Boolean)
            .join(" ")
        : "";
  }
  const alignment: any = [
    "propertyType",
    "recentPropertyValuationDate",
    "propertyAcquisitionDate",
    "changeInUseCase"
  ];

  return (
    <MultiplePropertyTooltip
      arrow
      title={
        <>
          {properties.length > 1 ? (
            <ul style={{ padding: 0, marginLeft: 0, marginRight: 0 }}>
              {items.every((element: any) => element === null) ? (
                <li
                  style={{
                    listStyleType: "none",
                    marginTop: "2px",
                    textAlign: "center"
                  }}
                >
                  No data available
                </li>
              ) : (
                items
              )}
            </ul>
          ) : properties.length === 1 ? (
            propertyInfoFlag ? (
              columnKey === "recentPropertyValuationDate" ||
              columnKey === "propertyAcquisitionDate" ? (
                properties[0]?.propertyinfo?.[columnKey] &&
                dateFormatter(properties[0].propertyinfo?.[columnKey])
              ) : columnKey === "changeInUseCase" ? (
                properties[0].propertyinfo?.[columnKey] === true ? (
                  "Yes"
                ) : properties[0].propertyinfo?.[columnKey] === false ? (
                  "No"
                ) : (
                  properties[0].propertyinfo?.[columnKey]
                )
              ) : columnKey === "occupiedPercentage" ? (
                getPercentageOccupied(properties?.[0])
              ) : columnKey === "propertyType" &&
                properties[0].propertyinfo?.[columnKey] === "SFR" ? (
                "Single Family Detached"
              ) : (
                properties[0]?.propertyinfo?.[columnKey]
              )
            ) : propertyEcononmicsFlag === true &&
              !isNullOrUndefined(
                properties[0]?.propertyEconomics?.[columnKey]
              ) &&
              properties[0]?.propertyEconomics?.[columnKey] !== "" ? (
              typeof properties[0]?.propertyEconomics?.[columnKey] ===
                "number" &&
              properties[0]?.propertyEconomics?.[columnKey]
                .toString()
                .indexOf(".") === -1 ? (
                `${
                  properties[0].propertyEconomics?.[columnKey]
                    .toString()
                    .indexOf("$") === -1
                    ? "$"
                    : ""
                }${properties[0].propertyEconomics?.[columnKey]}.00`
              ) : (
                `${
                  properties[0].propertyEconomics?.[columnKey]
                    .toString()
                    .indexOf("$") === -1
                    ? "$"
                    : ""
                }${properties[0].propertyEconomics?.[columnKey]}${
                  properties[0].propertyEconomics?.[columnKey]
                    .toString()
                    .indexOf(".") === -1
                    ? ".00"
                    : ""
                }`
              )
            ) : columnKey === "leaseStatus" ? (
              combinedLeaseStatus
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </>
      }
    >
      <span
        className={spanClass}
        style={{
          // float: alignment.includes(columnKey) ? "left" : "right",
          marginLeft: alignment.includes(columnKey) ? 8 : 0,
          marginRight: alignment.includes(columnKey) ? 0 : 8
        }}
      >
        {properties.length > 1
          ? multipleProperty
          : properties.length === 1
          ? propertyInfoFlag
            ? columnKey === "recentPropertyValuationDate" ||
              columnKey === "propertyAcquisitionDate"
              ? properties[0]?.propertyinfo?.[columnKey] &&
                dateFormatter(properties[0]?.propertyinfo?.[columnKey])
              : columnKey === "changeInUseCase"
              ? properties[0]?.propertyinfo?.[columnKey] === true
                ? "Yes"
                : properties[0]?.propertyinfo?.[columnKey] === false
                ? "No"
                : properties[0]?.propertyinfo?.[columnKey]
              : columnKey === "occupiedPercentage"
              ? getPercentageOccupied(properties?.[0])
              : columnKey === "propertyType" &&
                properties[0]?.propertyinfo?.[columnKey] === "SFR"
              ? "Single Family Detached"
              : properties[0]?.propertyinfo?.[columnKey]
            : propertyEcononmicsFlag &&
              !isNullOrUndefined(
                properties[0]?.propertyEconomics?.[columnKey]
              ) &&
              properties[0]?.propertyEconomics?.[columnKey] !== ""
            ? typeof properties[0]?.propertyEconomics?.[columnKey] ===
                "number" &&
              properties[0]?.propertyEconomics?.[columnKey]
                .toString()
                .indexOf(".") === -1
              ? `${
                  properties[0]?.propertyEconomics?.[columnKey]
                    .toString()
                    .indexOf("$") === -1
                    ? "$"
                    : ""
                }${properties[0]?.propertyEconomics?.[columnKey]}.00`
              : `${
                  properties[0]?.propertyEconomics?.[columnKey]
                    ?.toString()
                    ?.indexOf("$") === -1
                    ? "$"
                    : ""
                }${properties[0]?.propertyEconomics?.[columnKey]}${
                  properties[0]?.propertyEconomics?.[columnKey]
                    ?.toString()
                    ?.indexOf(".") === -1
                    ? ".00"
                    : ""
                }`
            : columnKey === "leaseStatus"
            ? combinedLeaseStatus
            : columnKey === "leaseStatus"
            ? combinedLeaseStatus
            : ""
          : ""}
      </span>
    </MultiplePropertyTooltip>
  );
};

export const LoanMetadataToolTip = (props: {
  children: JSX.Element;
  items: any;
}) => {
  const { items, children } = props;
  const classes = useStyles();
  return (
    <MetadataTooltip
      arrow
      title={
        <ul className={classes.loanMetadataUI}>
          {items.map((item: any) => {
            return (
              <li className={classes.metaDataItem}>
                <span className={classes.key}>{item.key}</span>
                {item.key !== "Ageing:" ? (
                  <span className={classes.label}>{item.value}</span>
                ) : (
                  <img
                    className={classes.ageingImage}
                    src={require(`../images/${item.value}.png`)}
                    alt="ageingIcon"
                  />
                )}
              </li>
            );
          })}
        </ul>
      }
    >
      {children}
    </MetadataTooltip>
  );
};
export interface SummaryCheckListTooltipProps {
  loanDetails: any;
  placement?: string;
}

export const SummaryCheckListTooltip = (
  props: SummaryCheckListTooltipProps
) => {
  const { loanDetails, placement } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false);
  const { status, loanStageRaw } = loanDetails;
  return (
    <>
      {status !== "Data Entry" &&
        loanStageRaw !== LoanStage.fes &&
        isLATUser() && (
          <ClickAwayListener onClickAway={() => setTooltipIsOpen(false)}>
            <SummaryTooltip
              // interactive
              placement={placement === "left" ? "left-end" : "right-end"}
              open={tooltipIsOpen}
              className={classes.summaryBtn}
              title={
                <>
                  <MenuItem
                    onClick={(e) => {
                      dispatch(showSummaryCheckListModal());
                      dispatch(singleLoanRow(loanDetails));
                    }}
                    className={classes.summaryMenu}
                  >
                    Loan Summary Checklist & Comments
                  </MenuItem>
                </>
              }
            >
              <IconButton
                onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
                size="large"
              >
                <MoreVertIcon style={{ color: "#38345c" }} />
              </IconButton>
            </SummaryTooltip>
          </ClickAwayListener>
        )}
    </>
  );
};

const Arrow = styled("div")({
  position: "absolute",
  width: 15,
  height: 15,
  "&::before": {
    content: '""',
    margin: "auto",
    display: "block",
    width: 0,
    height: 0,
    borderStyle: "solid",
    borderColor: "transparent #ffffff transparent transparent",
    borderWidth: "5px 5px 5px 0"
  },
  "&::after": {
    content: '""',
    width: 0,
    height: 0,
    margin: "auto",
    display: "block",
    borderStyle: "solid",
    borderColor: "transparent #dde0e5 transparent transparent",
    borderWidth: "8px 8px 8px 0",
    marginTop: "-13px",
    zIndex: -1,
    position: "relative"
  }
});

export const OnHoldTooltip = (props: any) => {
  const classes = useStyles();
  const row = props?.parentProps;
  const holdDetails = row?.onHoldDetails;
  const holdDate = holdDetails?.onHoldDate;
  const holdLandMark = row?.loanLandmarks || [];
  const latestLandMark = holdLandMark.filter(
    (item: any) => item.name === "update_hold_status"
  );
  const currentLandMark = latestLandMark[latestLandMark.length - 1];
  const dateTime = new Date(holdDate).toLocaleDateString(undefined, {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    year: "numeric",
    month: "short",
    day: "2-digit"
  });
  const userFullName = `${holdDetails?.firstName} ${holdDetails?.lastName}`;
  const NameInitials = nameToInitials(userFullName);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);

  const handleToggle = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const generateHoldChipLabel = () => {
    const timeText = generateAgeingLabel(holdDate);
    return `ON HOLD - ${timeText}`;
  };

  const renderUserUI = () => {
    return (
      <UserDetailsCard
        userImg="some img will come here"
        date={dateTime}
        accountType={holdDetails?.accountType}
        userFullName={userFullName}
        accountNameImg={NameInitials}
      />
    );
  };

  return (
    <>
      <span
        className={classes.holdTag}
        ref={anchorRef}
        onMouseEnter={handleToggle}
        onMouseLeave={handleClose}
      >
        {generateHoldChipLabel()}
      </span>
      <Popper
        open={open}
        placement="right"
        anchorEl={anchorRef.current}
        disablePortal={false}
        modifiers={[
          {
            name: "flip",
            enabled: true
          },
          {
            name: "preventOverflow",
            enabled: true
          },
          {
            name: "arrow",
            enabled: true,
            options: {
              element: arrowRef
            }
          }
        ]}
        style={{ zIndex: 200 }}
      >
        <>
          <Arrow ref={setArrowRef} />
          <Paper className={classes.holdTooltip}>
            <Typography className={classes.holdTooltipText}>
              {currentLandMark?.comments}
            </Typography>
            <Box display="flex" alignItems="center" pt="10px">
              {renderUserUI()}
            </Box>
          </Paper>
        </>
      </Popper>
    </>
  );
};

// export const InfoTooltip = (props: any) => {
//   const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false);

//   const handleTooltipClose = () => {
//     setTooltipIsOpen(false);
//   };

//   return (
//       <ClickAwayListener onClickAway={handleTooltipClose}>
//         <TextOnlyTooltip
//           // arrow
//           // interactive
//           placement="bottom-start"
//           open={tooltipIsOpen}
//           style={{ fontSize: 12, margin: 0 }}
//           title="i will come once if u hver"
//         />
//       </ClickAwayListener>
//   );
// };

export const DrawIdTooltip = (props: any) => {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let propsId = props?.idProps;
  const handleTooltip = () => {
    setTooltipOpen(false);
  };
  const classes = useStyles();
  const handlenewTabClicks = (drawId: any) => {
    setTooltipOpen(false);
    const subURL = pathflow() ? "internal/" : "";
    window.open(`${getConfig().redirectUrl}${subURL}drawDetails/${drawId}`);
  };
  return (
    <>
      <ClickAwayListener onClickAway={handleTooltip}>
        <TextOnlyTooltip
          // interactive
          placement="bottom-start"
          open={tooltipOpen}
          style={{ fontSize: 12, margin: 0 }}
          title={
            // "drawId"

            <>
              <MenuItem
                style={{ fontSize: 12, margin: 0, paddingLeft: 0 }}
                onClick={() => {
                  return handlenewTabClicks(propsId.drawId);
                }}
              >
                Open in new tab
              </MenuItem>
            </>
          }
        >
          <span
            className={classes.loanIdText}
            onContextMenu={(event: any) => {
              event.preventDefault();
              setTooltipOpen(!tooltipOpen);
            }}
            onClick={() => {
              sessionStorage.setItem("bulkDrawView", "false");
              sessionStorage.setItem("drawExcelUpload", "");
              navigate(`/drawDetails/${propsId.drawId}`);
            }}
            // onClick={() => {
            //   return handlenewTabClicks(propsId.drawId);
            // }}
          >
            {propsId.drawId}
          </span>
        </TextOnlyTooltip>
      </ClickAwayListener>
    </>
  );
};

export const PurchasedLoansTooltip = (props: any) => {
  // const dispatch = useDispatch();
  let propsId = props?.idProps;
  const { identifier } = props;
  const classes = useStyles();
  const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false);
  const handleTooltipClose = () => {
    setTooltipIsOpen(false);
  };
  const handlenewTabClick = () => {
    setTooltipIsOpen(false);
    window.open(
      `${getConfig().redirectUrl}purchasedLoanDetails/loan/${
        propsId.toorakLoanId
      }`
    );
  };
  const handletab = () => {
    window.open(
      `${getConfig().redirectUrl}purchasedLoanDetails/loan/${
        propsId.toorakLoanId
      }`,
      "_self"
    );
  };
  return (
    <>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <TextOnlyTooltip
          // interactive
          placement="bottom-start"
          open={tooltipIsOpen}
          data-testid="purchased-loans-tooltip"
          style={{ fontSize: 12, margin: 0 }}
          title={
            <>
              <MenuItem
                style={{ fontSize: 12, margin: 0, paddingLeft: 0 }}
                onClick={(e) => handlenewTabClick()}
              >
                Open in new tab
              </MenuItem>
            </>
          }
        >
          <span
            className={classes.loanIdText}
            onContextMenu={(event: any) => {
              event.preventDefault();
              setTooltipIsOpen(!tooltipIsOpen);
            }}
            onClick={() => handletab()}
          >
            {propsId[identifier]}
          </span>
        </TextOnlyTooltip>
      </ClickAwayListener>
    </>
  );
};

export const OnBoardingTooltip = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let propsId = props?.idProps;
  const { identifier } = props;
  const classes = useStyles();
  const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false);
  const handleTooltipClose = () => {
    setTooltipIsOpen(false);
  };
  const handlenewTabClick = () => {
    setTooltipIsOpen(false);
    window.open(
      `${getConfig().redirectUrl}serviceronboarding/loan/${propsId.loanStage}/${
        propsId.toorakLoanId
      }/${propsId.onboardingRequestId}/${propsId.documentReconcile}`
    );
  };
  const handletab = () => {
    if (identifier === "toorakLoanId") {
      // navigate(
      //   `/serviceronboarding/loan/${propsId.loanStage}/${propsId.toorakLoanId}/${propsId.onboardingRequestId}/${propsId.documentReconcile}`
      // );
      window.open(
        `${getConfig().redirectUrl}serviceronboarding/loan/${
          propsId.loanStage
        }/${propsId.toorakLoanId}/${propsId.onboardingRequestId}/${
          propsId.documentReconcile
        }`,
        "_self"
      );
    } else {
      dispatch(exportGCSpath(propsId[identifier]));
    }
  };
  return (
    <>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <TextOnlyTooltip
          // interactive
          placement="bottom-start"
          open={tooltipIsOpen}
          style={{ fontSize: 12, margin: 0 }}
          title={
            <>
              <MenuItem
                style={{ fontSize: 12, margin: 0, paddingLeft: 0 }}
                onClick={(e) => handlenewTabClick()}
              >
                Open in new tab
              </MenuItem>
            </>
          }
        >
          <span
            className={classes.loanIdText}
            onContextMenu={(event: any) => {
              event.preventDefault();
              setTooltipIsOpen(!tooltipIsOpen);
            }}
            onClick={() => handletab()}
          >
            {propsId[identifier]}
          </span>
        </TextOnlyTooltip>
      </ClickAwayListener>
    </>
  );
};

export const AgeingGridTooltip = (props: IconType) => {
  const { imageFile, days } = props;
  const classes = useStyles();
  const iconSrc = require(`../images/${imageFile}.png`);
  const text = days > 0 ? "Days" : "Day";
  return (
    <AgeingTooltip
      arrow
      placement="right"
      title={
        <>
          <span>
            <span style={{ fontWeight: 600, color: "#f5365c" }}>
              {days} {text}
            </span>
          </span>
        </>
      }
    >
      {days > -1 ? (
        <img className={classes.headingImage} src={iconSrc} alt="ageingIcon" />
      ) : (
        <></>
      )}
    </AgeingTooltip>
  );
};
export const BootstrapTooltip = (props: any) => {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
};

export const HtmlWhiteTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#ffffff",
    color: "#32325d",
    fontSize: "12px",
    padding: "8px",
    boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
    maxWidth: 700,
  },
  arrow: {
    color: "#ffffff"
  }
}))(Tooltip);
