/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { TextField, Grid } from "@mui/material";
import { InfoOnlyTooltip } from "../../utils/Tooltip";
import { isNullOrUndefined } from "../../utils/formatChecks";

const icon = <CheckBoxOutlineBlankIcon fontSize="large" />;
const checkedIcon = <CheckBoxIcon fontSize="large" />;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    commentBox: {
      marginTop: "20px",
      "& label": {
        width: "100%",
        "& div": {
          "& input": {
            marginBottom: "20px"
          }
        }
      }
    },
    labelContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      // marginTop: "1px",
      width: "100%"
    },
    editedLabelContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: "1px",
      width: "100%"
    },
    labelInformation: {
      fontSize: 12,
      fontWeight: 600,
      color: "#8897aa",
      lineHeight: "16px",
      // height: "14px",
      "&:hover": {
        color: "#5e72e4"
      },
      display: "inline-block",
      whiteSpace: "normal"
    },
    labelRequired: {
      color: "#f75676",
      marginLeft: "4px",
      fontSize: "12px",
      // marginBottom: "4px",
      paddingTop: 1,
      display: "inline-block",
      verticalAlign: "top"
    },
    labelRequiredComments: {
      color: "#f75676",
      marginLeft: "4px",
      fontSize: "12px",
      marginBottom: "4px",
      marginTop: "5px"
    },
    iconInfoContainer: {
      height: "16px",
      textAlign: "end"
    },
    iconInfo: {
      width: "16px !important",
      height: "16px !important",
      color: "#32325d",
      "&:hover": {
        color: "#5e72e4"
      }
    },
    autoComplete: {
      "& .MuiChip-root": {
        backgroundColor: "#11ceef",
        color: "#FFF",
        borderRadius: "6px"
      },
      "& .MuiChip-label": {
        color: "#FFF"
      },
      "& .MuiAutocomplete-tag": {
        maxWidth: `calc(90% - 6px)`
      }
    },
    optionName: {
      width: "420px",
      overflow: "hidden",
      display: "inline-block",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    },
    optionWrapper: {
      display: "flex"
    }
  })
);

export function CheckboxAutoCompleteTag(props: any) {
  const classes = useStyles();
  const {
    tagData,
    setSelectedTags,
    name,
    label,
    selectedTags,
    setSearchedLoanId,
    getSearched
  } = props;
  const handleRenderOptions = (props: any, option: any, state: any) => {
    const selectedOption = selectedTags.find(
      (item: any) => item[name] === option[name]
    );
    // need only for scenario id subject visibility check name specifically for scenarioId
    const subject = !isNullOrUndefined(option[name])
      ? option[name].split(" - ")
      : name;
    return (
      <InfoOnlyTooltip
        title={name === "scenarioId" ? subject[1] : ""}
        arrow
        placement="right"
        {...props}
      >
        <span data-testid="options-wrapper" className={classes.optionWrapper}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selectedOption ? true : state.selected}
          />
          <span className={classes.optionName} data-tesid="options-name">
            {option[name]}
          </span>
        </span>
      </InfoOnlyTooltip>
    );
  };

  const handleRenderInput = (params: any) => {
    return (
      <TextField
        variant="outlined"
        className={`${classes.commentBox} autoCompleteTagWrap`}
        {...params}
        InputLabelProps={{
          style: {
            position: "relative",
            fontWeight: 600,
            fontSize: 12,
            color: "#32325d",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }
        }}
        label={
          <Grid item xs={12} sm={12} className={classes.labelContainer}>
            <Grid item xs={12} md={12} sm={12} container>
              <span className={classes.labelInformation}>{label}</span>
            </Grid>
          </Grid>
        }
        style={{
          flex: 1,
          width: "100%",
          marginTop: "20px !important"
        }}
      />
    );
  };
  const handleOptionChange = (option: any, event: any) => {
    setSelectedTags(option);
  };

  const handleOptionSelected = (option: any, value: any) => {
    const selectedOption = selectedTags.find(
      (item: any) => item[name] === option[name]
    );
    return selectedOption && true;
  };

  return (
    <Autocomplete
      multiple
      limitTags={3}
      autoComplete
      id="checkboxes-tags-demo"
      defaultValue={selectedTags}
      className={classes.autoComplete}
      options={tagData}
      disableCloseOnSelect={false}
      filterOptions={(x: any) => x}
      filterSelectedOptions
      getOptionLabel={(option: any) => option?.[name] ?? ""}
      isOptionEqualToValue={(option: any, value: any) =>
        handleOptionSelected(option, value)
      }
      renderOption={(props, option, state) => handleRenderOptions(props, option, state)}
      onChange={(event: any, selectedOption: any) => {
        handleOptionChange(selectedOption, event);
      }}
      onInputChange={(event: any, value: any) => {
        getSearched(event);
        if (setSearchedLoanId) {
          setSearchedLoanId(value);
        }
      }}
      style={{ width: 500 }}
      renderInput={(params) => handleRenderInput(params)}
    />
  );
}
