import React from "react";
import { Grid, Theme, Container } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import NoExceptionImg from "../../images/exceptions/octicon-comment-discussion.svg";

interface Props {
  fromComm?: any;
  message?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      background: "#FFF"
    },
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      maxWidth: "428px",
      height: "400px",
      fontSize: "14px",
      color: "#32325d",
      lineHeight: 0.9
    },
    fromDash: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      maxWidth: "428px",
      height: "auto",
      fontSize: "14px",
      color: "#32325d",
      lineHeight: 0.9
    },
    notCreatedExceptions: {
      margin: "auto",
      fontSize: "16px",
      textAlign: "center",
      color: "#32325d"
    },
    fontBold: {
      fontWeight: 600
    },
    fontNormal: {
      fontWeight: "normal"
    }
  })
);
export const ZeroState = (props: Props) => {
  const classes = useStyles();
  const { fromComm, message } = props;

  return (
    <>
      <Grid container className={classes.root} data-testid="zero-state-loan">
        {/* No table content */}
        <Grid item xs={12} sm={12}>
          <Container
            className={fromComm ? classes.fromDash : classes.container}
          >
            <div className={classes.notCreatedExceptions}>
              <img src={NoExceptionImg} alt="no-exception-content" />
              <p
                data-testid="no-exceptions-text"
                className={message ? classes.fontBold : classes.fontNormal}
              >
                {" "}
                {message ? message : "Nothing yet!"}{" "}
              </p>
            </div>
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export const ZeroStateCustomHeight = (props: any) => {
  const classes = useStyles();
  return (
    <>
      <Grid container className={classes.root} data-testid="zero-state-loan">
        {/* No table content */}
        <Grid item xs={12} sm={12}>
          <Container
            className={classes.container}
            style={{
              height: props?.externalHeight ? props.externalHeight : "200px"
            }}
          >
            <div className={classes.notCreatedExceptions}>
              <img src={NoExceptionImg} alt="no-exception-content" />
              <p data-testid="no-exceptions-text"> Nothing yet! </p>
            </div>
          </Container>
        </Grid>
      </Grid>
    </>
  );
};
