/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */

// import { produce } from "immer";
// import {
//   EXCEL_UPLOAD_LOADING,
//   GET_EXCEL_LOAN_SUCCESS,
//   SAVE_LOAN_WHILE_CHANGING,
//   UPDATE_LOAN_ERROR,
//   UPDATE_SUBMIT_STATUS,
//   CHANGE_SUBMIT_STATUS,
//   RESET_EXCEL_STORE,
//   TOGGLE_DELETE_LOAN,
//   TOGGLE_RERENDER_LOAN_ID_LIST,
//   SET_EXCEL_DATA
// } from "./excel-upload.action";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CreateLocationStore } from "../../create-loan/create-loan.reducerHelper";
import {
  EvaluationResultStore,
  initialStateEvaluationResult
} from "../EvaluationResults/EvaluationResults.reducer";
import {
  ExceptionsStore,
  initialStateExceptionsStore
} from "../ExceptionsResults/ExceptionsReducer";
import {
  initialStateWRStore,
  WRStore
} from "../WaiverRequest/WaiverRequest.reducer";

export interface SingleLoanData {
  createLoanStore: {
    errors: {
      loanLevelErrors: number;
      propertyLevelErrors: number;
      documentLevelErrors: number;
    };
    storeValue: CreateLocationStore | null; // if loan is rendered store full value of store here incase the user changes the loan
    // containsFullStore: boolean;
    // bitsOfCreateLoanStore: {
    //   loanDetails: LoanDetails;
    // } | null;
  };
  evaluationResultsStore: EvaluationResultStore;
  exceptionsResultsStore: ExceptionsStore;
  wrStore: WRStore;
}
export interface ExcelUploadStoreProps {
  loanDeleted?: boolean;
  rerenderLoanIdList?: boolean;
  loading: boolean;
  data: { [key: string]: SingleLoanData };
  isSubmitted?: boolean;
}
const initialState: ExcelUploadStoreProps = {
  loading: false,
  data: {},
  isSubmitted: false
};

const excelUploadSlice = createSlice({
  name: "excelUpload",
  initialState,
  reducers: {
    excelUploadLoading: (
      state,
      action: PayloadAction<{ showLoader: boolean }>
    ) => {
      state.loading = action.payload.showLoader;
    },
    getExcelLoanSuccess: (state, action: PayloadAction<{ data: any }>) => {
      const { data } = action.payload;
      state.loading = data.loading;
      state.data = data.data;
    },
    saveLoanWhileChanging: (state, action: PayloadAction<any>) => {
      const { loanId, storeData } = action.payload;
      if (state.data[loanId]) {
        const loanData = state.data[loanId];
        loanData.createLoanStore.storeValue = storeData.createLoanStore;
        loanData.evaluationResultsStore = storeData.evaluationResultsStore;
        loanData.exceptionsResultsStore = storeData.exceptionsResultsStore;
        loanData.wrStore = storeData.wrStore;
      } else {
        state.data[loanId] = {
          createLoanStore: {
            storeValue: storeData.createLoanStore,
            errors: {
              loanLevelErrors: 0,
              propertyLevelErrors: 0,
              documentLevelErrors: 0
            }
          },
          evaluationResultsStore: storeData.evaluationResultsStore,
          exceptionsResultsStore: storeData.exceptionsResultsStore,
          wrStore: storeData.wrStore
        };
      }
    },
    updateLoanError: (state, action: PayloadAction<any>) => {
      const { loanId, errors } = action.payload;
      if (state.data[loanId]) {
        state.data[loanId].createLoanStore.errors = errors;
      } else {
        state.data[loanId] = {
          createLoanStore: { errors, storeValue: null },
          evaluationResultsStore: initialStateEvaluationResult,
          exceptionsResultsStore: initialStateExceptionsStore,
          wrStore: initialStateWRStore
        };
      }
    },
    changeSubmitStatusInReducer: (state) => {
      state.isSubmitted = true;
    },
    toggleDeleteLoan: (state) => {
      state.loanDeleted = !state.loanDeleted;
    },
    toggleRerenderLoanIdList: (state, action: PayloadAction<boolean>) => {
      state.rerenderLoanIdList = action.payload;
    },
    updateSubmitStatus: () => ({
      loading: false,
      data: {},
      isSubmitted: true,
      loanDeleted: false,
      rerenderLoanIdList: false
    }),
    setExcelUploadData: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    resetExcelUploadReducer: () => initialState
  }
});

export const {
  excelUploadLoading,
  getExcelLoanSuccess,
  saveLoanWhileChanging,
  updateLoanError,
  changeSubmitStatusInReducer,
  toggleDeleteLoan,
  toggleRerenderLoanIdList,
  updateSubmitStatus,
  setExcelUploadData,
  resetExcelUploadReducer
} = excelUploadSlice.actions;
export const ExcelUploadReducer = excelUploadSlice.reducer;

// export const ExcelUploadReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     /** Loading section starts here */
//     case EXCEL_UPLOAD_LOADING: {
//       const { showLoader } = action.payload;
//       const nextState = produce(state, draftState => {
//         draftState.loading = showLoader;
//       });
//       return nextState;
//     }
//     /** Loading section ends here */
//     /** All logical state updates starts here */
//     case GET_EXCEL_LOAN_SUCCESS: {
//       const { data } = action.payload;
//       const nextState = produce(state, draftState => {
//         draftState.loading = data.loading;
//         draftState.data = data.data;
//       });
//       return nextState;
//     }
//     case SAVE_LOAN_WHILE_CHANGING: {
//       const { loanId, storeData } = action.payload;
//       const nextState = produce(state, draftState => {
//         if (draftState.data[loanId]) {
//           draftState.data[loanId].createLoanStore.storeValue =
//             storeData.createLoanStore;
//           draftState.data[loanId].evaluationResultsStore =
//             storeData.evaluationResultsStore;
//           draftState.data[loanId].exceptionsResultsStore =
//             storeData.exceptionsResultsStore;
//           draftState.data[loanId].wrStore = storeData.wrStore;
//         } else {
//           draftState.data = {
//             ...draftState.data,
//             ...{
//               [loanId]: {
//                 createLoanStore: { storeValue: storeData.createLoanStore },
//                 evaluationResultsStore: storeData.evaluationResultsStore,
//                 exceptionsResultsStore: storeData.exceptionsResultsStore,
//                 wrStore: storeData.wrStore
//               }
//             }
//           };
//         }
//       });
//       return nextState;
//     }
//     case UPDATE_LOAN_ERROR: {
//       const { loanId, errors } = action.payload;
//       const nextState = produce(state, draftState => {
//         if (draftState.data[loanId]) {
//           draftState.data[loanId].createLoanStore.errors = errors;
//         } else {
//           draftState.data = {
//             ...draftState.data,
//             ...{
//               [loanId]: {
//                 createLoanStore: { errors }
//               }
//             }
//           };
//         }
//       });
//       return nextState;
//     }
//     case CHANGE_SUBMIT_STATUS: {
//       const nextState = produce(state, draftState => {
//         draftState.isSubmitted = true;
//       });
//       return nextState;
//     }
//     case TOGGLE_DELETE_LOAN: {
//       const nextState = produce(state, draftState => {
//         draftState.loanDeleted = !draftState.loanDeleted;
//       });
//       return nextState;
//     }
//     case TOGGLE_RERENDER_LOAN_ID_LIST: {
//       const {value}=action.payload
//       const nextState = produce(state, draftState => {
//         draftState.rerenderLoanIdList =value;
//       });
//       return nextState;
//     }
//     case UPDATE_SUBMIT_STATUS: {
//       const finalState = {
//         loading: false,
//         data: {},
//         isSubmitted: true
//       };
//       return finalState;
//     }
//     case SET_EXCEL_DATA: {
//       const nextState = produce(state, draftState => {
//         draftState.data = action.payload;
//       });
//       return nextState;
//     }
//     case RESET_EXCEL_STORE:
//       return initialState;
//     default:
//       return state;
//   }
// };
