import { Grid } from "@mui/material";
import React from "react";
import { BankDetails } from "./BankDetails";
import { useDrawsStyles } from "../Draws.styles";
import { isOrigUser } from "../../../utils/AccessManagement";

export const BankDetailsWrapper = (props: any) => {
  const classes = useDrawsStyles();
  const {
    selectedLoanId,
    isSubmitClicked,
    drawObj,
    isEditing,
    fieldWidth
  } = props;

  return (
    <Grid
      item
      container
      xs={12}
      className={classes.itemContainer}
      data-testid="bank-details-header"
    >
      <Grid item container xs={10} className={classes.itemHeaderText}>
        Bank Details
      </Grid>
      <Grid
        item
        container
        xs={12}
        className={classes.fieldContainer}
        style={{ height: isOrigUser() ? "95%" : "85%" }}
      >
        <BankDetails
          selectedLoanId={selectedLoanId}
          isSubmitClicked={isSubmitClicked}
          drawObj={drawObj}
          isEditing={isEditing}
          fieldWidth={fieldWidth}
        />
      </Grid>
    </Grid>
  );
};
