import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/rootReducer";

import { useDrawsStyles } from "../Draws.styles";
import { DrawRequestStore } from "../../../stores/drawRequest/drawRequest.interface";
import { getDrawDateCreated, getInitials } from "../../AssetManagement.utils";
import { servicerStageHistoryInterface } from "../../../stores/servicerOnboarding/servicerOnboarding.interface";

export const StageAuditTrail = ({
  onboardingStatusHistory
}: {
  onboardingStatusHistory?: any[];
}) => {
  const classes = useDrawsStyles();
  const { drawStageHistory } = useSelector<RootState, DrawRequestStore>(
    state => state.drawRequestStore
  );
  const genericHistoryData: any[] = onboardingStatusHistory || drawStageHistory;
  return (
    <span
      className={classes.auditTrailParentDiv}
      style={{
        left: onboardingStatusHistory ? "40%" : undefined,
        top: onboardingStatusHistory ? 70 : undefined
      }}
    >
      {genericHistoryData?.map(
        (row: servicerStageHistoryInterface, idx: number) => (
          <>
            <div
              className={
                idx === genericHistoryData.length - 1
                  ? classes.verticalLinenew
                  : classes.verticalLine
              }
              key={`${row.updatedTimeStamp}_${idx}`}
            >
              <span className={classes.bullet} />
              <div className={classes.auditTrailStage}>
                {row.stageName || row.status}
              </div>
              {row.comment ? (
                <div
                  className={classes.auditCommentBlock}
                  style={{ width: "265px", wordWrap: "break-word" }}
                >
                  <span className={classes.auditTrailComment}>Comment:</span>
                  {row.comment}
                </div>
              ) : null}
              <div className={classes.origDetails}>
                <div className={classes.auditTrailavatar}>
                  {getInitials(row.updatedBy)}
                </div>
                <span className={classes.personName}>{row.updatedBy}</span>
                <span className={classes.personName}>
                  {getDrawDateCreated(row.updatedTimeStamp)}
                </span>
              </div>
            </div>
          </>
        )
      )}
      {genericHistoryData.length < 1 && (
        <div className={`${classes.auditCommentBlock} ${classes.noAuditData}`}>
          {"No Data Available"}
        </div>
      )}
    </span>
  );
};
