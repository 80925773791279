/* eslint-disable import/no-default-export */
import { Checkbox, Grid } from "@mui/material";
import React, { createContext, useContext, useState } from "react";
import { ObjectType } from "../masterView/common";
import LoanDetails from "./LoanDetails";
import { loanCardStyles } from "./styles";
import PartnerDetails from "./PartnerDetails";
import { TakeOutPartnerContext } from "./takeoutPartner";

export const RateLockcontext = createContext<any>(undefined);

export interface LoanCardProps {
  isSelected: boolean;
  updateSelectedLoans: Function;
  loanDetails: ObjectType;
  isDropDownNeeded?: boolean;
}

export default function LoanCard(props: LoanCardProps) {
  const {
    isSelected,
    updateSelectedLoans,
    isDropDownNeeded,
    loanDetails = {}
  } = props;

  const { activeServicerTab } = useContext(TakeOutPartnerContext);
  const [rateLockPeriodAndExpiry] = useState({
    [loanDetails?.loanId]: {
      rateLockPeriod:
        loanDetails?.data?.loan?.loanDetailId?.loanConfigId?.rateLockPeriod,
      rateLockExpiryDate: loanDetails?.data?.loan?.rateLockExpiryDate,
      takeoutPartnerId: loanDetails?.data?.loan?.loanInfo.takeoutPartner
    }
  });
  const handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateSelectedLoans(event.target.checked, loanDetails);
  };

  const classes = loanCardStyles();
  return (
    <Grid container className={classes.cardWrapper}>
      <Grid item sm={7} md={7} className={classes.loanDetailsWrapper}>
        {(activeServicerTab === "UnassignedLoans" || isDropDownNeeded) && (
          <Checkbox
            id={loanDetails.loanId}
            checked={isSelected}
            value={loanDetails.loanId}
            disableRipple
            onChange={handleCheckBox}
            style={{
              top: "5px",
              position: "absolute"
            }}
          />
        )}
        <LoanDetails
          loanId={loanDetails?.loanId}
          originatorLoanId={loanDetails?.data?.loan?.loanInfo.primaryLoanId}
          loanSubmissionDate={loanDetails?.data?.loan?.loanSubmissionDate}
          originatorName={loanDetails?.data?.originator?.accountName}
          loanStage={loanDetails?.data?.loan?.loanStage}
          loanType={loanDetails?.data?.loan?.loanType}
          loanState={loanDetails?.data?.loan?.loanState}
          finalToorakPrice={
            loanDetails?.data?.result?.loanResult?.loanPricing.finalToorakPrice
          }
          originatorInterestRate={
            loanDetails?.data?.loan?.loanEconomics?.interestRateAsOfCutOffDate
          }
          rateLockExpiryDate={loanDetails?.data?.loan?.rateLockExpiryDate}
          rateLockPeriod={
            loanDetails?.data?.loan?.loanDetailId.loanConfigId.rateLockPeriod
          }
          maxLoanAmount={
            loanDetails?.data?.loan.loanEconomics?.originalLoanAmount
          }
        />
      </Grid>
      <Grid item sm={5} md={5} className={classes.loanDetailsWrapper}>
        <RateLockcontext.Provider
          value={{
            rateLockPeriodAndExpiry
          }}
        >
          <PartnerDetails
            loanId={loanDetails?.loanId}
            eligiblePartners={loanDetails?.data?.loan?.eligibleTakeoutPartners}
            originatorId={
              loanDetails?.data?.loan?.loanDetailId.originatorPartyId
            }
            loanStage={loanDetails?.data?.loan?.loanStage}
            loanState={loanDetails?.data?.loan?.loanState}
            loanType={loanDetails?.data?.loan?.loanType}
            activeServicerTab={activeServicerTab}
            takeoutPartnerId={loanDetails?.data?.loan?.loanInfo.takeoutPartner}
            loanRuleVersions={loanDetails?.data?.loan?.loanRuleVersions}
          />
        </RateLockcontext.Provider>
      </Grid>
    </Grid>
  );
}
