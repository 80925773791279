/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { InputLabel, Grid, Tooltip, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { mitigatingClass } from "./Common.style";
import { validStringWithoutSpace } from "../exceptions-and-enquiries/ResponseAndDocumentUploadCard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textAreaWrapper: {
      width: "100%",
      height: "89px",
      border: "1px solid #cad1d7",
      borderRadius: "4px"
    },
    textArea: {
      fontFamily: "sans-serif !important",
      width: "100%",
      height: "100%",
      padding: "14px 12px 14px 12px",
      resize: "none",
      border: "none",
      borderRadius: "4px",
      fontSize: "14px"
    }
  })
);

interface Props {
  fieldKey: number;
  required: boolean;
  deleteCallback: any;
  index: number;
  formData: any;
  setFormData: any;
  isSubmitDisabled: boolean;
  setIsSubmitDisabled: any;
  exceptionId?: any;
  waiverMitigates?: any;
  isFirstTimeRender?: boolean;
}

export const MitigatingFactorField = (props: Props) => {
  const {
    fieldKey,
    required,
    deleteCallback,
    index,
    formData,
    setFormData,
    exceptionId,
    waiverMitigates
  } = props;
  const classes = useStyles();
  const commonClass = mitigatingClass();
  const [value, setValue] = useState<string>("");
  const [valid, setValid] = useState<boolean>(false);
  const runDefault = (fieldValue?: any) => {
    const val = fieldValue || value;
    setFormData({
      ...formData,
      [fieldKey]: {
        value: val,
        valid
      }
    });
  };

  useEffect(() => {
    if (waiverMitigates?.length >= fieldKey) {
      setValue(waiverMitigates[fieldKey - 1].description);
    }
  }, [waiverMitigates]);

  useEffect(() => {
    runDefault();
  }, [valid]);

  useEffect(() => {
    runDefault();
    if (validStringWithoutSpace(value) !== null && value.length >= 10) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [value]);

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  return (
    <Grid item xs={12}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <InputLabel required={required} className={commonClass.labelText}>
            {`Mitigating Factor ${index === 0 ? "" : index}`}
          </InputLabel>
        </Grid>
        {required ? null : (
          <Grid item>
            <Tooltip title="Delete">
              <DeleteOutlinedIcon
                fontSize="small"
                style={{ fontSize: "16px", cursor: "pointer" }}
                onClick={deleteCallback}
              />
            </Tooltip>
          </Grid>
        )}
      </Grid>
      <div className={classes.textAreaWrapper}>
        <textarea
          className={classes.textArea}
          required={required}
          value={value}
          name={`${fieldKey}`}
          onChange={handleChange}
          minLength={10}
          placeholder="Write here"
        />
      </div>
      {!exceptionId && value.length < 10 ? (
        <Grid
          container
          justifyContent="flex-end"
          style={{ margin: "4px 0 4px 0" }}
        >
          <Grid item>
            <InputLabel
              required
              style={{
                color: "red",
                fontSize: "11px"
              }}
            >
              (minimum characters required is 10)
            </InputLabel>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};
