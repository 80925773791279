import { produce } from "immer";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { SELECTED_ORIGINATOR } from './supportUI.action';

export interface SupportUIReducerType {
  selectedOriginator: string;
  // rowData: any;
}

const initialState: SupportUIReducerType = {
  selectedOriginator: ""
  // rowData: []
};

const supportUISlice = createSlice({
  name: "supportUI",
  initialState,
  reducers: {
    setSelectedOriginator: (state, action: PayloadAction<string>) => {
      state.selectedOriginator = action.payload;
    }
    // Add other reducers as needed
  }
});

export const { setSelectedOriginator } = supportUISlice.actions;

export const SupportUIReducer = supportUISlice.reducer;

// export const SupportUIReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case SELECTED_ORIGINATOR: {
//       const { selectedOriginator } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         draftState.selectedOriginator = selectedOriginator;
//       });
//       return nextState;
//     }
//     // case SELECTED_ROW_DATA: {
//     //     const { rowData } = action.payload;
//     //     const nextState = produce(state, draftState => {
//     //         draftState.rowData = rowData;
//     //     });
//     //     return nextState;
//     // }
//     default:
//       return state;
//   }
// };
