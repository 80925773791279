/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { GET_WAIVER_DETAILS, SET_ERROR_ADDRESSLIST } from "./WaiverRequest.action";
// import { RESET_WAIVER_STORE } from "../../create-loan/create-loan.action";

export interface WRStore {
  loading: boolean;
  waiverDetails: {
    subject: string;
    reason: string;
    details: any;
    errorAddressList?: any;
  };
}

export const initialStateWRStore: WRStore = {
  loading: false,
  waiverDetails: {
    subject: "",
    reason: "",
    details: {},
    errorAddressList: {}
  }
};

const WRSlice = createSlice({
  name: "wr",
  initialState: initialStateWRStore,
  reducers: {
    getWaiverDetails: (state, action: PayloadAction<any>) => {
      state.waiverDetails = action.payload;
    },
    setErrorAddressList: (state, action: PayloadAction<any>) => {
      state.waiverDetails.errorAddressList = action.payload;
    },
    resetWaiverStore: () => initialStateWRStore
  }
});

export const { getWaiverDetails, setErrorAddressList, resetWaiverStore } =
  WRSlice.actions;

export const WRReducer = WRSlice.reducer;

// export const WRReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case GET_WAIVER_DETAILS: {
//       const nextState = produce(state, draftState => {
//         draftState.waiverDetails = action.payload;
//       });
//       return nextState;
//     }
//     case SET_ERROR_ADDRESSLIST: {
//       const nextState = produce(state, draftState => {
//         draftState.waiverDetails.errorAddressList = action.payload;
//       });
//       return nextState;
//     }
//     case RESET_WAIVER_STORE: {
//       return initialState;
//     }
//     default:
//       return state;
//   }
// };
