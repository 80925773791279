import React, { ChangeEvent, useEffect } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogActions
} from "@mui/material";
import { DialogTitle, RadioButton } from "@toorak/tc-common-fe-sdk";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useStyles } from "./FrontendSizer.style";
import {
  sanitizeNumericInput,
  formatValueByType,
  sanitizeValueByType
} from "../utils/formatChecks";
import {
  AlphaNumericComponent,
  DatePickerComponent
} from "./FrontendSizerComponents";

import {
  Fields,
  fesThirtyYearCondominium,
  fesThirtyYearCondominiumQuestion
} from "./FrontendSizerTemplate";
import { RootState } from "../stores/rootReducer";
import {
  downLoadCondo,
  fetchCondoEligibility,
  postFesLoan
  // updateFesLoanDetails
} from "../stores/FrontEndSizer/fesLoanCreation.action";

import {
  // CONDO_QUEST_OPENED,
  formatLoanDetailsForRedux,
  postLoan,
  // POST_LOAN_SUCCESS,
  updateThirtyYearLoanDetails
} from "../create-loan/create-loan.action";
import { getLoanType } from "../config/config";
import { getBridgeLoanGuarantorInformationToPass } from "../create-loan/CreateLoanHeader";
import {
  condoQuestOpened,
  postLoanSuccess,
  updateFesLoanDetails
} from "../create-loan/create-loan.reducer";

let prevValueCondo: any;

export function ToorakCondoQuest(props: any) {
  const { loanId, loanType, loanStage } = useParams<any>() as any;
  const classes = useStyles();
  const { data } = props;
  // const [isMinimized, setIsMinimized] = useState<boolean>(false);
  let colSpan: 4 | 6 = 6;
  const dispatch = useDispatch();
  const {
    loanDetails,
    condoQuestOpen

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useSelector<RootState, any>((state) => state.createLoanStore);

  const updateRedux = (fieldToUpdate: string, formattedValue: any) => {
    dispatch(
      updateFesLoanDetails({
        sectionName: "fesLoanCondo",
        fieldName: fieldToUpdate,
        fieldValue: formattedValue
      })
    );
  };
  const handleChange =
    (fieldType: string, fieldName: string, ruleFieldName?: any) =>
    (event: ChangeEvent<HTMLInputElement> | ChangeEvent<{ value: string }>) => {
      if (ruleFieldName === undefined || ruleFieldName === "") {
        ruleFieldName = fieldName;
      }

      const formattedValue = sanitizeValueByType(event.target.value, fieldType);
      updateRedux(fieldName, formattedValue);
    };
  const handleBlur =
    (fieldType: string, fieldName: string, ruleFieldName?: any) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (ruleFieldName === undefined || ruleFieldName === "") {
        ruleFieldName = fieldName;
      }
      let formattedValue = event.target.value;
      switch (fieldType) {
        case "number":
          formattedValue = sanitizeNumericInput(event.target.value);
          break;
        case "percentage":
          formattedValue = formatValueByType(
            event.target.value,
            "percentageWithout100x"
          );
          break;
        case "currency":
          formattedValue = formatValueByType(event.target.value, "currency");
          break;
        default: // return same value
      }

      updateRedux(fieldName, formattedValue);
    };
  const onDateChange = (date: any, fieldName: string, fieldType: string) => {
    if (date) {
      updateRedux(fieldName, date);
    }
  };

  useEffect(() => {
    if (!prevValueCondo) {
      prevValueCondo = JSON.parse(
        JSON.stringify(loanDetails?.["fesLoanCondo"])
      );
    }
  }, [loanDetails]);
  const getValue = (fieldName: string) => {
    if (fieldName === "borrowerName") {
      if (loanDetails?.bridgeLoanBorrowerInformation.length === 1) {
        if (
          loanDetails?.bridgeLoanBorrowerInformation[0].payload[
            "borrowerType"
          ] === "Entity"
        ) {
          // updateRedux(fieldName, loanDetails?.bridgeLoanBorrowerInformation[0].payload["borrowingEntityName"]);
          const fieldValue =
            loanDetails?.bridgeLoanBorrowerInformation[0].payload[
              "borrowingEntityName"
            ];
          if (loanDetails?.fesLoanCondo[fieldName] !== fieldValue) {
            updateRedux(fieldName, fieldValue);
          }
          return fieldValue;
        }
        const fieldValue = `${loanDetails?.bridgeLoanBorrowerInformation[0].payload["firstName"]} ${loanDetails?.bridgeLoanBorrowerInformation[0].payload["lastName"]}`;
        if (loanDetails?.fesLoanCondo[fieldName] !== fieldValue) {
          updateRedux(fieldName, fieldValue);
        }
        return fieldValue;
      }
      let fieldValue = "";
      loanDetails?.bridgeLoanBorrowerInformation.map((key: any, index: any) => {
        if (key.payload.isPrimaryBorrower) {
          fieldValue = `${key.payload.firstName} ${key.payload.lastName}`;
        }
      });
      if (loanDetails?.fesLoanCondo[fieldName] !== fieldValue) {
        updateRedux(fieldName, fieldValue);
      }
      return fieldValue;
    }
    return loanDetails?.fesLoanCondo[fieldName];
  };

  // useEffect(() => {

  // }, [enableEvaluation]);
  const getAllFields = (
    fields: any,
    requiredField?: any,
    CondoFields?: boolean
  ) => {
    if (CondoFields === true) {
      colSpan = 6;
    } else {
      colSpan = 4;
    }
    return (
      <>
        {fields.map((item: Fields) => {
          const {
            fieldLabel,
            fieldName,
            fieldType,
            ruleFieldName,
            dependentQues,
            viewModeOnly,
            required
          } = item;

          if (dependentQues) {
            if (loanDetails?.fesLoanCondo[dependentQues] !== "Yes") {
              return "";
            }
          }
          switch (fieldType) {
            case "number":
            case "string":
            case "percentage":
            case "currency":
              return (
                <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
                  <AlphaNumericComponent
                    required={requiredField}
                    value={getValue(fieldName)}
                    onBlur={handleBlur(fieldType, fieldName, ruleFieldName)}
                    label={fieldLabel}
                    helperText=""
                    onChange={handleChange(fieldType, fieldName, ruleFieldName)}
                    isViewMode={!!viewModeOnly}
                  />
                </Grid>
              );
            case "date-time":
              return (
                <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
                  <DatePickerComponent
                    value={getValue(fieldName)}
                    data-testid="data-test-id"
                    onChange={(e) => {
                      // eslint-disable-next-line no-restricted-globals
                      // if (e && isNaN(e?.getTime())) return false;
                      onDateChange(e, fieldName, fieldType);
                    }}
                    label={fieldLabel}
                    error={!item.fieldName}
                    helperText=""
                    required
                  />
                </Grid>
              );

            case "radio":
              return (
                <Grid
                  container
                  className="quest-radio-group"
                  item
                  xs={12}
                  sm={6}
                  md={colSpan}
                  xl={colSpan}
                >
                  <RadioButton
                    label={fieldLabel}
                    radioGroupName="Foreign National Fla"
                    onChange={handleChange(fieldType, fieldName, ruleFieldName)}
                    // onBlur={handleBlur(foreignNationalFlag)}
                    value={getValue(fieldName)}
                    helperText="This field is required"
                    dataTestId="guarantor-radio-button"
                    data={[
                      {
                        dataTestId: "radio-fnp-yes",
                        label: "Yes",
                        value: "Yes"
                      },
                      {
                        dataTestId: "radio-fnp-no",
                        label: "No",
                        value: "No"
                      }
                    ]}
                    required={required}
                  />
                </Grid>
              );
            case "emptyGrid":
              return (
                <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan} />
              );

            default:
              return <div> No items to render</div>;
          }
        })}
      </>
    );
  };
  function exportHTML() {
    downLoadCondo(loanDetails, loanId, loanType).then((response) => {
      const source = response;
      const fileDownload = document.createElement("a");
      document.body.appendChild(fileDownload);
      fileDownload.href = source;
      fileDownload.download = "condoEligibility.doc";
      fileDownload.click();
      document.body.removeChild(fileDownload);
    });
  }
  const calculateEligibilty = () => {
    fetchCondoEligibility(loanDetails, loanId, loanType).then(
      (resposnse: any) => {
        if (loanType === getLoanType[1].displayValue) {
          const condoValue = resposnse[`rule.InvestorDSCR.condominium`];
          let condoValueDisplay = "";
          switch (condoValue) {
            case "Doesn't meet TRK Min Requirements": {
              condoValueDisplay = "Not Eligible";
              break;
            }
            case "Meets TRK Standard Requirements": {
              condoValueDisplay = "Standard";
              break;
            }
            case "Only Meets TRK Min Requirements": {
              condoValueDisplay = "Min";
              break;
            }
            default:
          }
          if (loanStage === "FES") {
            dispatch(
              updateFesLoanDetails({
                sectionName: "fesLoanInformation",
                fieldName: "calculatedCondoEligibility",
                fieldValue: condoValueDisplay
              })
            );
            dispatch(
              updateFesLoanDetails({
                sectionName: "fesLoanInformation",
                fieldName: "condoEligibility",
                fieldValue: condoValueDisplay
              })
            );
            postFesLoan(
              loanDetails,
              loanId,
              loanType,
              loanStage,
              true,
              condoValueDisplay
            );
          } else {
            postLoan(
              {
                loanId,
                loanType,
                borrowerInformation: data.borrowerInformation,
                guarantorInfo: getBridgeLoanGuarantorInformationToPass(
                  data.guarantorInformation
                ),
                ...data.loanInformation,
                ...data.loanEconomics,
                fesLoanCondo: loanDetails?.fesLoanCondo,
                loanConfig: loanDetails?.loanConfig
              },
              false,
              true,
              true,
              condoValueDisplay
            ).then((response: any) => {
              formatLoanDetailsForRedux(response, "");
              dispatch(
                postLoanSuccess({
                  reservation: response
                })
              );
            });
            dispatch(
              updateThirtyYearLoanDetails({
                thirtyYearLoanInformation: {
                  payload: {
                    calculatedCondoEligibility: condoValueDisplay
                  }
                }
              })
            );
            dispatch(
              updateThirtyYearLoanDetails({
                thirtyYearLoanInformation: {
                  payload: {
                    condoEligibility: condoValueDisplay
                  }
                }
              })
            );
          }
        }
      }
    );
  };
  return (
    <Dialog
      onClose={() => {
        // dispatch({
        //   type: CONDO_QUEST_OPENED,
        //   payload: {
        //     condoQuestOpen: false
        //   }
        // });
        dispatch(
          condoQuestOpened({
            condoQuestOpen: false
          })
        );
      }}
      aria-labelledby="customized-dialog-title"
      open={condoQuestOpen}
      test-dataid="dialog"
      classes={{ paper: classes.paper }}
      id="source-html"
      // contentStyle={{width: "100%", maxWidth: "none"}}
    >
      <DialogTitle
        id="LAT-dialog-title"
        style={{ paddingBottom: 7 }}
        onClose={() => {
          // dispatch({
          //   type: CONDO_QUEST_OPENED,
          //   payload: {
          //     condoQuestOpen: false
          //   }
          // });
          dispatch(
            condoQuestOpened({
              condoQuestOpen: false
            })
          );
        }}
      >
        Toorak Condominium Questionnaire
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={6} className={classes.sectionWrapper}>
          {getAllFields(fesThirtyYearCondominium, false)}
        </Grid>
        <hr />
        <Grid container spacing={8} className={classes.sectionWrapper}>
          {getAllFields(fesThirtyYearCondominiumQuestion, false, true)}
        </Grid>
        <hr />

        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          xl={12}
          style={{
            marginTop: "20px"
          }}
        >
          <Button
            variant="contained"
            // data-testid="save-button"
            // disabled={!false}
            className={classes.calculateCondoButton}
            color="info"
            onClick={() => {
              calculateEligibilty();
              prevValueCondo = null;
              dispatch(
                condoQuestOpened({
                  condoQuestOpen: false
                })
              );
              //dispatch({
              //               type: CONDO_QUEST_OPENED,
              //               payload: {
              //                 condoQuestOpen: false
              //                }
              //              });
            }}
          >
            Calculate Eligibility & Save
          </Button>
          <Button
            variant="contained"
            color="info"
            // data-testid="save-button"
            className={classes.downloadBtn}
            onClick={() => {
              exportHTML();
              // dispatch({
              //     type: CONDO_QUEST_OPENED,
              //     payload: {
              //         condoQuestOpen: false
              //     }
              // })
            }}
          >
            Download
          </Button>
          <Button
            variant="contained"
            color="info"
            // data-testid="save-button"
            className={classes.cancelCondoBtn}
            onClick={() => {
              // dispatch({
              //   type: CONDO_QUEST_OPENED,
              //   payload: {
              //     condoQuestOpen: false,
              //     clearCondoValue: true,
              //     prevValue: prevValueCondo
              //   }
              // });
              dispatch(
                condoQuestOpened({
                  condoQuestOpen: false,
                  clearCondoValue: true,
                  prevValue: prevValueCondo
                })
              );
            }}
          >
            Cancel
          </Button>
        </Grid>
      </DialogContent>
      <DialogContent />
      <DialogActions />
    </Dialog>
  );
}
