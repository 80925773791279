import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DocumentViewer } from "../../create-loan/ttf-review/document-review/document-viewer/DocumentViewer";
import { budgetReviewStatusEnum } from "../../ilp/list-view/BudgetTaskManagement";
import { setSelectedBudgetDoc } from "../../stores/budgetReview/budgetReview.reducer";
import { BudgetReviewStore } from "../../stores/budgetReview/budgetReview.interface";
import { RootState } from "../../stores/rootReducer";
import { HtmlWhiteTooltip } from "../../utils/Tooltip";
import { isLoginPartyId } from "../BudgetReview.constant";
import { useBudgetStyles } from "../BudgetReview.style";
import { DropDownCommon } from "../../utils/DropDownCommon";

export function BudgetDocsHeaderWrapper({ budgetDocs }: any) {
  const [isDocsListVisible, setIsDocsListVisible] = useState<boolean>(false);
  const [budgetDocsOption, setbudgetDocsOption] = useState<any[]>([]);
  const [budgetDocsSelected, setBudgetDocsSelected] = useState<any>("");
  const dispatch = useDispatch();
  const classes = useBudgetStyles();
  const {
    selectedBudgetDoc,
    isLineItemsEditDisable,
    budgetApprovalHistory,
    budgetStatusHistory,
    budgetReviewHeader
  } = useSelector<RootState, BudgetReviewStore>(
    (state: { budgetReviewStore: BudgetReviewStore }) => state.budgetReviewStore
  );

  const handleDocsChangeClick = (item: any) => {
    dispatch(setSelectedBudgetDoc(item));
    setIsDocsListVisible(false);
  };
  useEffect(() => {
    //updatePropertyDetails
    if (budgetDocs.length === 0) return;
    let tempOptions = [];
    if (
      budgetStatusHistory.length &&
      isLoginPartyId(budgetStatusHistory[0].assigneePartyId) &&
      [
        budgetReviewStatusEnum.InProgress,
        budgetReviewStatusEnum.ReadyForBudgetReview
      ].includes(budgetReviewHeader.budgetReviewStatus)
    ) {
      tempOptions = budgetDocs;
    }
    setbudgetDocsOption(tempOptions);
    if (!budgetReviewHeader?.selectedPropId) return;

    const selectDocs = budgetDocs.find((pr: any) =>
      pr.assets.find(
        // eslint-disable-next-line
        (it: any) => it.assetExternalId == budgetReviewHeader?.selectedPropId
      )
    );
    setBudgetDocsSelected(selectDocs); //default value
    dispatch(setSelectedBudgetDoc(selectDocs));
    // eslint-disable-next-line
  }, [budgetDocs, budgetApprovalHistory, dispatch, isLineItemsEditDisable]);

  const getLimitedText = (text: string) => {
    return String(text).length > 30
      ? `${String(text).slice(0, 30)}...`
      : String(text);
  };

  const getSpecialHTML = (title: string) => {
    if (String(title).length < 31) return title;
    return (
      <>
        <HtmlWhiteTooltip
          title={
            <>
              <Typography style={{ fontSize: 12, fontWeight: 400 }}>
                {title}
              </Typography>
            </>
          }
          placement="top"
          arrow
        >
          <span>{getLimitedText(title)}</span>
        </HtmlWhiteTooltip>
      </>
    );
  };

  useEffect(() => {
    if (!selectedBudgetDoc) return;
    if (budgetDocsSelected.id === selectedBudgetDoc.id) return;
    setBudgetDocsSelected(selectedBudgetDoc);
    dispatch(setSelectedBudgetDoc(selectedBudgetDoc));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBudgetDoc, budgetDocsSelected]);

  const onChangeHighlightSelect = () => {};
  const documentReviewDispatch = () => {};

  return (
    <div className={classes.pdfViewContainer}>
      <DropDownCommon
        availableOptions={budgetDocsOption}
        setIsMoreActionsVisible={setIsDocsListVisible}
        isMoreActionsVisible={isDocsListVisible}
        handleActionBtnClick={handleDocsChangeClick}
        headerText={getLimitedText(budgetDocsSelected?.title)}
        styleOuterContainer={{ alignItems: "flex-start" }}
        styleMenuItem={{ minWidth: 120, marginTop: 20 }}
        specialOptionsHtml={(title: any) => getSpecialHTML(title)}
        tooltipText={budgetDocsSelected?.title}
      />
      <div
        id="pdfViewArea"
        style={{
          backgroundColor: "white",
          height: "97%",
          marginTop: 15
        }}
      >
        <DocumentViewer
          state={null}
          documentReviewDispatch={documentReviewDispatch}
          document={budgetDocsSelected}
          highlightChecked={false}
          onChangeHighlightSelect={onChangeHighlightSelect}
          highlightDisabled={false}
        />
      </div>
    </div>
  );
}
