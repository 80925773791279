import { createSlice } from "@reduxjs/toolkit";
import {
  DrawObj,
  DrawRequestStore,
  selectedBankDetailsType
} from "./drawRequest.interface";
import { DrawStage } from "@toorak/tc-common-fe-sdk";
import { DOC_MAPPING_ENTITIES } from "../../create-loan/ttf-review/constants";

const initDrawObj: DrawObj = {
  primaryLoanId: "",
  servicerId: "",
  toorakLoanId: "",
  originatorId: "",
  originatorName: "",
  servicerName: "",
  loanType: "",
  drawNumber: "",
  finalApproval: "no",
  borrowerEntityName: "",
  borrowerAddress: "",
  financedBudgetAmount: 0,
  prePurchaseDrawAmount: 0,
  loanStatus: "",
  balanceToFinish: 0,
  gracePeriod: undefined,
  defaultWireFee: "95.00",
  payoffRequestDate: "",
  propertyDetails: [],
  drawType: "",
  stage: DrawStage.draft,
  finalApprovalNotRequired: "yes",
  originalMaturityDate: "",
  maturityDate: "",
  currentMaturityDate: "",
  nextPaymentDueDate: "",
  lastUpdatedDate: "",
  requestedDate: "",
  documentTaggingStatus: "",
  budgetReconcileStatus: "",
  inspectionReviewStatus: "",
  drawAmountDetails: {
    drawAmount: "0.00",
    wireReturnFee: "0.00",
    latestApprovedAmount: "0.00",
    // wireFee: "95.00",
    wireFee: "0.00",
    costOfInspection: "0.00",
    titleServiceFee: "0.00",
    netFundsToBorrower: null,
    edited: false,
    comments:"",
  }
};

function formatDrawUserInfo(
  user: any,
  type: DOC_MAPPING_ENTITIES,
  source: string
) {
  let value = user;
  return {
    value,
    assetExternalId: value,
    assetName: type
  };
}

function formatDrawData(drawDetails: any) {
  let guarantors: any[] = [];
  let borrowers: any[] = [];
  let address: any[] = [];
  if (drawDetails.guarantors) {
    // const guarantorsformatted = formatDrawUserInfo(
    //   user,
    //   DOC_MAPPING_ENTITIES.guarantor,
    //   "TC"
    // );
  }
  if (drawDetails.borrowerEntityName) {
    const borrowersformatted = formatDrawUserInfo(
      drawDetails.borrowerEntityName,
      DOC_MAPPING_ENTITIES.borrower,
      "TC"
    );
    borrowers.push(borrowersformatted);
  }

  drawDetails?.propertyDetails?.forEach((addList: any) => {
    address.push({
      value: addList.propertyAddress || addList,
      assetExternalId: addList.propertyAddress || addList,
      assetName: DOC_MAPPING_ENTITIES.property
    });
  });
  return {
    address,
    borrowers,
    guarantors
  };
}

export const initBankObj: selectedBankDetailsType = {
  accountNumber: 0,
  routing: "",
  bankName: "",
  accountName: "",
  bankDetailId: 0
};

const initialState: DrawRequestStore = {
  drawRequestResult: [],
  assignedToDropDownList: [],
  drawCount: 0,
  drawTaggedDocs: {},
  inspectionReviewData: {},
  inspectionReviewComments:[],
  budgetReviewComments:[],
  customColumnList: {
    asset: [],
    originator: []
  },
  tableFilterConfig: undefined,
  tableSortConfig: undefined,
  tableSearchConfig: undefined,
  selectedLoans: [],
  drawRequestType: "originator",
  drawHistory: [],
  sideFilterObjPayload: {
    asset: {},
    originator: {}
  },
  searchValue: "",
  loanIds: [],
  drawDetails: initDrawObj,
  additionalLoanEconomics: {
    originalMaturityDate: "",
    nextPaymentDueDate: "",
    currentMaturityDate: "",
    maturityDate: ""
  },
  selectedBankDetails: initBankObj,
  availableBankAccountDetails: [],
  generatedDrawId: "",
  drawDiscussions: [],
  drawInternalNotes: [],
  drawStageHistory: [],
  excelData: [],
  propertyDetailsOptions: [],
  drawDocs: {},
  currentFilesUploaded: 0,
  fieldsHistory: {},
  provisionData: [],
  totalProvisionsCount: 0,
  elastic_search_body: [],
  borrowers: [],
  addresses: [],
  guarantors: [],
  riskFactor: { passed: [], failed: [] },
  budgetReconcileData: [],
  propType: "",
  budgetReconcileReports: {},
  reconcileItemHistory: []
};

export function getFileType(filename: string) {
  // Get the position of the last dot (.)
  var lastDotIndex = filename.lastIndexOf(".");

  // Check if there is a dot in the filename
  if (lastDotIndex !== -1) {
    // Extract the file extension using substring or slice
    var fileType = filename.substring(lastDotIndex + 1); // Using substring
    // var fileType = filename.slice(lastDotIndex + 1); // Using slice
    return fileType;
  } else {
    // If there is no dot in the filename, return null or handle the case as needed
    return null;
  }
}

export const drawDocBodyForReview = (docs: []) => {
  const updatedDrawRes = docs?.map((drawDoc: any) => {
    return {
      assets: drawDoc.assets,
      boxDocumentPath: "<box_document_path>",
      chatDocument: null,
      createdBy: drawDoc.createdBy,
      createdOn: drawDoc.createdAt, // You might need to format this date as needed
      description: null, // You can set a description if needed
      docsId: drawDoc.documentId,
      envelopeId: null,
      fileType: getFileType(drawDoc.fileName), // You might need to determine the file type dynamically
      id: drawDoc.documentId, // Assuming documentId serves as the ID
      isLatest: drawDoc.isLatest,
      isPurchasePrimary: drawDoc.isPurchasePrimary,
      name: drawDoc.fileName,
      title: drawDoc.fileName,
      path: drawDoc.link,
      percentCompleted: 100, // Assuming the document is fully completed
      signeeEmail: null,
      signeeName: null,
      split_source_id: null, // Set as needed
      tag: [], // Set tags as needed
      TagsInternal: drawDoc.tags || [],
      trackingId: null,
      updatedBy: drawDoc.modifiedBy,
      updatedOn: drawDoc.updatedAt, // You might need to format this date as needed
      visibility: null, // Set visibility as needed,
      drawId: drawDoc.drawId
    };
  });
  return updatedDrawRes;
};

const drawRequestSlice: any = createSlice({
  name: "drawRequest",
  initialState,
  reducers: {
    updateDrawData: (state, action) => {
      state.drawRequestResult = action.payload;
    },
    updateAssigneeList: (state, action) => {
      state.assignedToDropDownList = action.payload;
    },
    updateDrawCount: (state, action) => {
      state.drawCount = action.payload;
    },
    setSelectedLoans: (state, action) => {
      state.selectedLoans = action.payload;
    },
    updateDrawHistory: (state, action) => {
      state.drawHistory = action.payload;
    },
    updateProvisionData: (state, action) => {
      state.provisionData =
        action.payload.totalProvisions > 0 ? action.payload.provisions : [];
      state.totalProvisionsCount = action.payload.totalProvisions;
    },
    setAdditionalLoanEconomicsData: (state, action) => {
      state.additionalLoanEconomics = action.payload;
    },
    updateElasticSearchBody: (state, action) => {
      state.elastic_search_body = action.payload;
    },
    updateTableSortOptions: (state, action) => {
      state.tableSortConfig = action.payload;
    },
    updateTableFilters: (state, action) => {
      state.tableFilterConfig = action.payload;
    },
    updateTableSearchOptions: (state, action) => {
      state.tableSearchConfig = action.payload;
    },
    updateSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setDrawType: (state, action) => {
      state.drawRequestType = action.payload;
    },
    updateLoanIds: (state, action) => {
      state.loanIds = action.payload;
    },
    updateDrawObj: (state, action) => {
      const tempObj = transformDrawObj(action.payload);
      const formattedDrawUsersData = formatDrawData(action.payload);
      const { borrowers, address, guarantors } = formattedDrawUsersData;
      state.borrowers = borrowers;
      state.guarantors = guarantors;
      state.addresses = address;
      state.drawDetails = tempObj;
    },
    updateDrawAvalBank: (state, action) => {
      state.availableBankAccountDetails = action.payload.bankDetails;
    },
    updateDrawPropOptions: (state, action) => {
      state.propertyDetailsOptions = action.payload;
      if (state.drawDetails.propertyDetails?.length === 0) {
        state.drawDetails.propertyDetails =
          action.payload?.length === 1 ? action.payload : [];
      }
    },
    setFieldsHistory: (state, action) => {
      state.fieldsHistory = action.payload;
    },
    updateDrawDiscussions: (state, action) => {
      state.drawDiscussions = action.payload || {};
    },
    updateDrawInternalNotes: (state, action) => {
      state.drawInternalNotes = action.payload || [];
    },
    updateDrawStageHistory: (state, action) => {
      state.drawStageHistory = action.payload || [];
    },
    updateInspectionReviewRedux: (state, action) => {
      const { lineItems } = action.payload;
      state.inspectionReviewData = action.payload;
      state.propType = lineItems[0]?.unit > 1 ? "Unit" : "Consolidated";
    },
    updateInspectCommentsRedux: (state, action) => {
      state.inspectionReviewComments = action.payload;
    },
    updateBudgetCommentsRedux: (state, action) => {
      state.budgetReviewComments = action.payload;
    },
    updateBudgetReconcileRedux: (state, action) => {
      state.budgetReconcileData = action.payload;
      state.propType =
        action.payload[0]?.budgetLineItem?.unit > 1 ? "Unit" : "Consolidated";
    },
    updateBudgetReconcileReportsRedux: (state, action) => {
      state.budgetReconcileReports = action.payload;
    },
    updateReconcileItemHistoryRedux: (state, action) => {
      state.reconcileItemHistory = action.payload;
    },
    // Uncomment and complete other cases as needed
    // updateDrawAmountHistory: (state, action) => {
    //   const tempObj = action.payload;
    //   console.log(tempObj[0]);
    //   state.drawDetails.drawAmountDetails = tempObj[0] || [];
    // },
    clearDrawRedux: (state) => {
      state.drawDetails = initDrawObj;
      state.fieldsHistory = {};
      state.drawHistory = [];
    },
    updateSelectedBankObj: (state, action) => {
      state.selectedBankDetails = action.payload;
    },
    updateDrawExcelData: (state, action) => {
      state.excelData = action.payload;
    },
    updateDrawSelectedProp: (state, action) => {
      state.drawDetails.propertyDetails = action.payload;
    },
    updateFinalApproval: (state, action) => {
      state.drawDetails.finalApprovalNotRequired = action.payload;
    },
    updateDrawStage: (state, action) => {
      state.drawDetails.stage = action.payload;
    },
    generateDrawId: (state, action) => {
      state.generatedDrawId = action.payload.drawId;
    },
    getDocsByDrawId: (state, action) => {
      const docs = action.payload;
      const taggedDrawDocs = drawDocBodyForReview(docs);
      state.drawDocs = docs;
      state.drawTaggedDocs = { data: taggedDrawDocs };
    },
    updateTaggedDocsByDrawId: (state, action) => {
      const { data: docs, isLoading, fromTaggingScreen } = action.payload;
      state.drawTaggedDocs = {
        data: docs,
        isLoading,
        fromTaggingScreen
      };
    },
    drawDocsCurrentUploadSuccess: (state, action) => {
      const { isClear } = action.payload;
      state.currentFilesUploaded = isClear ? 0 : state.currentFilesUploaded + 1;
    },
    updateBankList: (state, action) => {
      state.availableBankAccountDetails = action.payload.bankList;
      state.selectedBankDetails = initBankObj;
    },
    setRiskFactor: (state, action) => {
      state.riskFactor = action.payload;
    }
  }
});

function transformDrawObj(drawObj: any): DrawObj {
  if (!drawObj) return initDrawObj;
  let propertyDetails = drawObj?.propertyDetails;
  const isNew = sessionStorage.getItem("newDrawCreated");
  let newWireFee: string = drawObj?.drawAmountDetails?.wireFee;
  if (isNew === "true" && !drawObj.isManual) {
    newWireFee = drawObj?.defaultWireFee;
  }

  const tempDrawObj: DrawObj = {
    primaryLoanId: drawObj?.primaryLoanId || "",
    servicerId: drawObj?.servicerId || "",
    toorakLoanId: drawObj?.toorakLoanId || "",
    originatorId: drawObj?.originatorId || "",
    originatorName: drawObj?.originatorName || "",
    servicerName: drawObj?.servicerName || "",
    loanType: drawObj?.loanType || "",
    drawType: drawObj?.drawType || "",
    defaultWireFee: drawObj?.defaultWireFee || "95.00",
    drawNumber: drawObj?.drawNumber || "",
    finalApproval: drawObj?.finalApproval || "",
    finalApprovalNotRequired: drawObj?.finalApprovalNotRequired || "yes",
    borrowerEntityName: drawObj?.borrowerEntityName || "",
    borrowerAddress: drawObj?.borrowerAddress || "",
    financedBudgetAmount: drawObj?.financedBudgetAmount || 0,
    prePurchaseDrawAmount: drawObj?.prePurchaseDrawAmount || 0,
    balanceToFinish: drawObj?.balanceToFinish || 0,
    loanStatus: drawObj?.loanStatus || "",
    propertyDetails: propertyDetails,
    gracePeriod: drawObj.gracePeriod,
    payoffRequestDate: drawObj.payoffRequestDate,
    originalMaturityDate: drawObj.originalMaturityDate || "",
    currentMaturityDate: drawObj.currentMaturityDate || "",
    maturityDate: drawObj.maturityDate || "",
    nextPaymentDueDate: drawObj.nextPaymentDueDate || "",
    lastUpdatedDate: drawObj.lastUpdatedDate || "",
    requestedDate: drawObj.requestedDate || "",
    documentTaggingStatus: drawObj.documentTaggingStatus || "",
    budgetReconcileStatus: drawObj.budgetReconcileStatus || "",
    inspectionReviewStatus: drawObj.inspectionReviewStatus || "",
    drawAmountDetails: {
      drawAmount: drawObj?.drawAmountDetails?.drawAmount ?? "0.00",
      latestApprovedAmount:
        drawObj?.drawAmountDetails?.latestApprovedAmount ?? "0.00",
      wireFee: newWireFee,
      costOfInspection: drawObj?.drawAmountDetails?.costOfInspection ?? "0.00",
      titleServiceFee: drawObj?.drawAmountDetails?.titleServiceFee ?? "0.00",
      wireReturnFee: drawObj?.drawAmountDetails?.wireReturnFee ?? "0.00",
      netFundsToBorrower:
        drawObj?.drawAmountDetails?.netFundsToBorrower || "0.00",
      edited: drawObj?.drawAmountDetails?.edited || false,
      comments: drawObj?.drawAmountDetails?.comments || ""
    },

    stage: drawObj?.stage || DrawStage.draft //making draft as default stage
  };
  return tempDrawObj;
}

export const {
  updateDrawData,
  updateAssigneeList,
  updateDrawCount,
  setSelectedLoans,
  updateDrawHistory,
  updateProvisionData,
  setAdditionalLoanEconomicsData,
  updateElasticSearchBody,
  updateTableSortOptions,
  updateTableFilters,
  updateTableSearchOptions,
  updateSearchValue,
  setDrawType,
  updateLoanIds,
  updateDrawObj,
  updateDrawAvalBank,
  updateDrawPropOptions,
  setFieldsHistory,
  updateDrawDiscussions,
  updateDrawInternalNotes,
  updateDrawStageHistory,
  // Uncomment and complete other actions as needed
  // updateDrawAmountHistory,
  clearDrawRedux,
  updateSelectedBankObj,
  updateDrawExcelData,
  updateDrawSelectedProp,
  updateFinalApproval,
  updateDrawStage,
  updateInspectionReviewRedux,
  updateInspectCommentsRedux,
  updateBudgetCommentsRedux,
  updateTaggedDocsByDrawId,
  generateDrawId,
  getDocsByDrawId,
  drawDocsCurrentUploadSuccess,
  updateBankList,
  updateBudgetReconcileRedux,
  updateBudgetReconcileReportsRedux,
  updateReconcileItemHistoryRedux,
  setRiskFactor
} = drawRequestSlice.actions;

export default drawRequestSlice.reducer;
