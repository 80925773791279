/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormControl, FormLabel, MenuItem, Select, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { ObjectType } from "../masterView/common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      "&:focus": {
        margin: "0px",
        backgroundCOlor: "inherit !important"
      }
    },
    outLine: {
      marginTop: "0px !important",
      paddingRight: "24px !important"
    },
    iconOutlined: {
      right: "0px"
    },
    icon: {
      top: "auto"
    },
    menuItem: {
      fontSize: "12px",
      color: "#32325d"
    },
    formLabel: {
      paddingBottom: "0.8rem",
      fontSize: "12px",
      fontWeight: "normal",
      "& .MuiFormLabel-asterisk": {
        color: "red"
      }
    }
  })
);

export interface DropDownProps {
  selected?: string;
  handleChange: any;
  selectStyles?: any;
  menuOptions: ObjectType[];
  placeHolderText?: string;
  dropDownLabel?: string;
  width?: string;
  isDisabled?: boolean;
  required?: boolean;
  ovverrideCss?: boolean;
  labelAlignmnet?: string;
  height?: string;
}

export const BasicDropDown = (props: DropDownProps) => {
  const {
    selected,
    handleChange,
    selectStyles,
    menuOptions,
    placeHolderText,
    dropDownLabel,
    width,
    isDisabled,
    required,
    ovverrideCss,
    labelAlignmnet,
    height = "auto"
  } = props;
  const classes = useStyles();
  const labelAlignmnetCSS: any =
    labelAlignmnet === "row"
      ? {
          width: width,
          flexDirection: "row",
          alignItems: "baseline"
        }
      : { width: width, height: height };
  return (
    <FormControl component="fieldset" style={{ ...labelAlignmnetCSS }}>
      {dropDownLabel && (
        <FormLabel
          component="div"
          className={classes.formLabel}
          required={required}
          style={{ marginRight: labelAlignmnet === "row" ? "5px" : "" }}
        >
          {dropDownLabel}
        </FormLabel>
      )}
      <Select
        value={selected}
        onChange={handleChange}
        onClick={(event) => event.stopPropagation()}
        disabled={isDisabled}
        classes={{
          select: classes.select,
          outlined: classes.outLine,
          iconOutlined: classes.iconOutlined,
          icon: classes.icon
        }}
        style={
          ovverrideCss === true
            ? {
                borderRadius: "4px",
                fontSize: "12px",
                border: "solid 1px #ececec",
                background: "#fff",
                ...selectStyles
              }
            : {
                boxShadow: "0 2px 4px 0 rgb(50 50 93 / 21%)",
                borderRadius: "4px",
                border: "solid 1px #ececec",
                ...selectStyles
              }
        }
        MenuProps={{
          // getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right"
          }
        }}
        // variant="outlined"
      >
        {placeHolderText && (
          <MenuItem disabled className={classes.menuItem} value=" ">
            {placeHolderText}
          </MenuItem>
        )}
        {menuOptions?.map((item: any) => (
          <MenuItem
            className={classes.menuItem}
            key={item.value}
            value={item.value}
            style={item.style}
            disabled={item.disabled}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
