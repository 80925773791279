/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
import React, { useEffect, useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Badge,
  IconButton
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import momentz from "moment-timezone";
import { useParams } from "react-router-dom";
import {
  DialogTitle,
  LoanStage
  // ConfirmationDialog
} from "@toorak/tc-common-fe-sdk";
import { useDropzone } from "react-dropzone";
import { ExceptionDropDown } from "./ExceptionDropDown";
import { useStyles } from "./ExceptionCardStyle";
import { Comment } from "../loan-lists/comments-section/components/Comment";
import { isLATUser } from "../../utils/AccessManagement";
// import { WaiverActionsButton } from "../waiver-form/WaiverActionsButton";
import {
  isNullOrUndefined,
  isViewOnly,
  nameToInitials
} from "../../utils/formatChecks";
import {
  deleteEnquiryById,
  internalToOriginator,
  updateErrorCount,
  // updateReadStatus,
  getViewMoreDetails,
  // setActionClicks,
  setAskAQuestionClicked,
  setSatisfyExceptionsClicked,
  setWaiverRequestedClicked,
  // UPDATE_EXCEPTION,
  updateInquiries
} from "../../stores/ExceptionsResults/ExceptionActions";
import { setActiveTabAndProperty } from "../create-loan.action";
// import { FileBar } from "../waiver-form/FileBar";
import { handleDownload } from "./DocumentUploadApis";
import { getErrorCount } from "./ExceptionsAndEnquiries";
import { RootState } from "../../stores/rootReducer";
import { getCookie } from "../../utils/cookies";
// import { StatusComponent } from "../waiver-form/StatusComponent";
import { useStyles as useStyle } from "../documents/DocumentsForm.style";
import { IconButtonDropdownStylesWithoutBG } from "../waiver-form/DocumentsForm.style";
import {
  RedirectIcon,
  // CommentIcon,
  // DeleteIcon,
  // AskAQuestion
  ViewIcon,
  SatisfyIcon,
  WaiverRebuttalIcon,
  WaiverApprovedIcon,
  WaiverRejectedIcon,
  WaiverRequestedIcon,
  WaiverReopenIcon,
  AskAQuestion
  // WaiverReRequestIcon
} from "../Icons";
import { InfoOnlyTooltip } from "../../utils/Tooltip";
// import { UserDetailsCard } from "./UserDetailsCard";
import { constructName, getDateInFormat, uuidV4 } from "../../utils/utils";
import { ResponseTypeEnum } from "../../utils/constants";
import { getConfig } from "../../config/config";
import { ExceptionCardRescindIcon } from "./ExceptionCardRescindIcon";
import { ViewMoreExceptions } from "../loan-lists/DetailsView/ViewMoreExceptions";
import { OpenInNewTab } from "../OpenInNewTab";
import { ResponseAndDocumentUploadCard } from "./ResponseAndDocumentUploadCard";
import { WaiverContainer } from "../waiver-form/WaiverContainer";
import {
  hideLoader,
  showLoader
} from "../../stores/loaderAndException/loaderAndException.action";
import {
  setActionClicks,
  updateException
} from "../../stores/ExceptionsResults/ExceptionsReducer";

export interface ExceptionsCardProps {
  exceptionId: string;
  orgId: string;
  exceptionsResult?: any;
  isViewDetailsVisible?: boolean;
  loanDetails?: any;
  spanValue?: any;
  exceptionUserAndAccountDetails?: any;
  enquiryDocDetails?: any;
  sidePanel?: any;
  waivers?: any;
  setPassedFileViewDetails?: any;
  index?: number;
}
export const getAccountTypeFirstLetter = (accountType: string) => {
  return accountType.split("", 1);
};

const WaiverActionsButton = React.lazy(() =>
  import("../waiver-form/WaiverActionsButton").then((module) => ({
    default: module.WaiverActionsButton
  }))
);

const ConfirmationDialog = React.lazy(() =>
  import("@toorak/tc-common-fe-sdk").then((module) => ({
    default: module.ConfirmationDialog
  }))
);

const DeleteIcon = React.lazy(() =>
  import("../Icons").then((module) => ({
    default: module.DeleteIcon
  }))
);

const FileBar = React.lazy(() =>
  import("../waiver-form/FileBar").then((module) => ({
    default: module.FileBar
  }))
);

const CommentIcon = React.lazy(() =>
  import("../Icons").then((module) => ({
    default: module.CommentIcon
  }))
);

// const AskAQuestion = React.lazy(() =>
//   import("../Icons").then(module => ({
//     default: module.AskAQuestion
//   }))
// );
const StatusComponent = React.lazy(() =>
  import("../waiver-form/StatusComponent").then((module) => ({
    default: module.StatusComponent
  }))
);

const UserDetailsCard = React.lazy(() =>
  import("./UserDetailsCard").then((module) => ({
    default: module.UserDetailsCard
  }))
);

export const getUserFullAndOrgName = (
  exceptionUserAndAccountDetails: any,
  partyId?: string
) => {
  if (!exceptionUserAndAccountDetails || !partyId) return null;
  const { customers, accountMapping, accounts } =
    exceptionUserAndAccountDetails;
  const customerData =
    customers && customers?.find((item: any) => item.partyId === partyId);
  const accountMappingItem = accountMapping?.find(
    (item: any) => item.personId === partyId
  );
  const accountData =
    accounts &&
    accounts?.find(
      (item: any) => item.partyId === accountMappingItem?.accountId
    );
  const accountType = accountData?.accountType || "";
  const firstName = !isNullOrUndefined(customerData?.firstName)
    ? customerData?.firstName
    : "";
  const lastName = !isNullOrUndefined(customerData?.lastName)
    ? customerData?.lastName
    : "";
  const NameInitials = nameToInitials(`${firstName} ${lastName}`);
  const userFullName = constructName(
    customerData?.firstName,
    customerData?.middleName,
    customerData?.lastName
  );
  const accountTypeFirstLetter = accountType
    ? getAccountTypeFirstLetter(accountType)[0]
    : "";
  return [NameInitials, userFullName, accountTypeFirstLetter];
};

export const ShowDialog = (props: any) => {
  let isClicked = false;
  const dailogClasses = useStyle();
  const {
    head,
    btnText,
    contentText,
    contentText2,
    setOpenDialog,
    openDialog,
    handleInternalToOrg
  } = props;
  const { dialogDeleteButton, dialogCancelBtn, dialogActionArea } =
    dailogClasses;
  return (
    <Dialog
      //   open={openDialog}
      onClose={(event: any, reason: string) => {
        if (reason === "backdropClick") return;
        setOpenDialog(false);
      }}
      aria-labelledby="customized-dialog-title"
      open={openDialog}
      test-dataid="dialog"
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={() => setOpenDialog(false)}
        style={{ paddingTop: "20px" }}
      >
        {head}
      </DialogTitle>
      <DialogContent>
        <Typography
          gutterBottom
          // className={`${dialogText} ${fwss_normal}`}
          style={{
            padding: "24px 0px 30px 0px",
            fontSize: "12px",
            lineHeight: "1.8",
            fontWeight: "normal"
          }}
        >
          <span>{contentText}</span>
          <p>{contentText2}</p>
        </Typography>
      </DialogContent>
      <DialogActions className={dialogActionArea}>
        <Button
          variant="contained"
          color="primary"
          className={dialogDeleteButton}
          onClick={() => {
            if (!isClicked) {
              handleInternalToOrg();
            }
            isClicked = true;
          }}
          data-testid="delete-confirm-btn"
        >
          {btnText}
        </Button>
        <Button
          autoFocus
          onClick={() => setOpenDialog(false)}
          color="primary"
          className={`${dialogCancelBtn} ${dialogDeleteButton}`}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ExceptionsCard = (props: ExceptionsCardProps) => {
  const classes = useStyles();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [loading, setLoading] = useState<boolean>(false);
  const [fileReceived, setFileReceived] = useState<boolean>(false);
  const [passedFile, setPassedFile] = useState<any>(null);
  const [selectedCompensatingFactors, setselectedCompensatingFactors] =
    React.useState([]);

  const { visibilitySelected, waiverSelected, satisfiedSelected } = useSelector<
    RootState,
    any
  >((state) => state.exceptionsResultsStore);
  // eslint-disable-next-line
  const [files, setFiles] = useState<any[]>([]);
  const iconButtonDropdownStylesWithoutBG = IconButtonDropdownStylesWithoutBG();
  const { getRootProps, isDragActive, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true
  });

  const activeStyle = {
    border: "solid 2px #ffffff",
    borderRadius: "23px",
    opacity: 0.9
  };
  const baseStyle = {
    padding: 0
  };

  const isConditionsAndInquiriesTab =
    window.location.href.indexOf("createLoan") !== -1;

  useEffect(() => {
    if (acceptedFiles?.length && isDragNDropPermittedCondtionOrInquiry) {
      handleUpload(acceptedFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);
  useEffect(() => {
    setShowViewDetails(true);
  }, [visibilitySelected, waiverSelected, satisfiedSelected]);

  const clearUploadedFile = () => {
    acceptedFiles.length = 0;
    setPassedFile(null);
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {})
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDragActive]
  );
  const {
    exceptionsResult,
    isViewDetailsVisible,
    exceptionId,
    loanDetails,
    spanValue,
    exceptionUserAndAccountDetails,
    enquiryDocDetails,
    sidePanel,
    waivers,
    setPassedFileViewDetails,
    index,
    orgId
  } = props;
  const url = new URL(window.location.href);
  const isRescindView = url.searchParams.get("isRescindView");

  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogdetails, setDialogdetails] = React.useState<any>({});
  // eslint-disable-next-line
  const [actionID, setActionID] = React.useState(uuidV4());
  const dispatch = useDispatch();
  const { loanId, loanType, loanStage, visibility } = useParams<{
    loanId: string;
    loanStage: LoanStage;
    loanType: string;
    visibility: string;
  }>() as any;
  const actionClicks = useSelector<RootState, any>(
    (state) => state.exceptionsResultsStore?.actionClicks
  );
  const inquiryResponse = useSelector<RootState, { exceptions: any[] | any }>(
    (state) => state.exceptionsResultsStore?.responses
  );
  const waiversResult = useSelector<RootState, { waivers: any[] | any }>(
    (state) => state.exceptionsResultsStore?.waiverResult
  );
  const { isWithdrawnLoan } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );

  let isAskAQuestionClicked = useSelector<RootState, any>(
    (state) => state.exceptionsResultsStore?.isAskAQuestionClicked
  );

  let isSatisfyExceptionClicked = useSelector<RootState, any>(
    (state) => state.exceptionsResultsStore?.isSatisfyExceptionClicked
  );

  let isWaiverRequestClicked = useSelector<RootState, any>(
    (state) => state.exceptionsResultsStore?.isWaiverRequestClicked
  );

  isAskAQuestionClicked = isAskAQuestionClicked?.[exceptionId] || false;
  isSatisfyExceptionClicked = isSatisfyExceptionClicked?.[exceptionId] || false;
  isWaiverRequestClicked = isWaiverRequestClicked?.[exceptionId] || false;
  const [showViewDetails, setShowViewDetails] = React.useState(true);
  const [openConfirmBox, setOpenConfirmBox] = React.useState(false);
  const [satisfyExceptionAndInquiry, setSatisfyExceptionAndInquiry] =
    React.useState([]);

  useEffect(() => {
    const response: any =
      exceptionsResult.responses ?? exceptionsResult.response;
    const satisfyExceptionAndInquiryCount =
      response &&
      response?.filter(
        (item: any) =>
          item.responseType === "SATISFY_EXCEPTION" ||
          (item.responseType === "SATISFY_INQUIRY" &&
            item.visibility === "external")
      );
    setSatisfyExceptionAndInquiry(satisfyExceptionAndInquiryCount);
  }, [exceptionsResult]);

  const {
    commentsOnExceptionArr,
    type,
    isActioningForSDInquiryByLAT,
    visiblityText,
    visibilitySettings,
    status,
    statusDisable,
    askQuestionArray
  } = useMemo(() => {
    let commentsOnExceptionArr: any = [];
    let responses: any[] =
      exceptionsResult?.responses ?? exceptionsResult?.response ?? [];
    responses = responses.filter(
      (item: any) => item.responseType === ResponseTypeEnum.ASK_A_QUESTION
    );

    if (exceptionsResult?.exceptionId) {
      let name = exceptionsResult.name;
      let date = exceptionsResult.ddLastUpdate;
      if (exceptionsResult.source === "WORKFLOW") {
        name = "Toorak";
        date = "";
      }
      commentsOnExceptionArr = [
        {
          initialComment: "Initial Comment",
          description: exceptionsResult.initialComment,
          name,
          date
        },
        {
          initialComment: "Conclusion Comment",
          description: exceptionsResult.conclusionComment,
          name,
          date
        },
        {
          initialComment: "Client Response",
          description: exceptionsResult.clientResponse,
          name,
          date
        }
      ];
    }
    return {
      commentsOnExceptionArr,
      type:
        exceptionsResult &&
        exceptionsResult?.type !== "" &&
        exceptionsResult?.type?.toLowerCase() === "inquiry" &&
        exceptionsResult?.visibility?.toLowerCase() === "internal"
          ? "Inquiry"
          : "Condition",
      isActioningForSDInquiryByLAT:
        exceptionsResult?.source === "WORKFLOW" && isLATUser(),
      visiblityText:
        exceptionsResult?.visibility === "internal" ? "Visible only to " : "",
      visibilitySettings:
        (exceptionsResult && exceptionsResult?.visibility) || "external",
      status:
        exceptionsResult &&
        (exceptionsResult?.status?.toLowerCase() === "approved" ||
          exceptionsResult?.status?.toLowerCase() === "waived" ||
          exceptionsResult?.status?.toLowerCase() ===
            "approved-conditionally" ||
          exceptionsResult?.status?.toLowerCase() === "satisfied" ||
          exceptionsResult?.status?.toLowerCase() === "rescinded" ||
          exceptionsResult?.status?.toLowerCase() === "rescind" ||
          exceptionsResult?.status?.toLowerCase() === "rejected"),
      statusDisable:
        exceptionsResult &&
        (exceptionsResult?.status?.toLowerCase() === "waived" ||
          exceptionsResult?.status?.toLowerCase() === "satisfied"),
      askQuestionArray: responses
    };
  }, [exceptionsResult]);

  const personPartyId: string = getCookie("person_id") || "";
  const path = `loan/create/${loanType}/${loanStage}/${loanId}/${
    exceptionsResult?.type?.toLowerCase() === "inquiry" ? "Inquiry" : type
  }/${exceptionId}/${visibilitySettings}/view`;

  const filteredResponse =
    !isLATUser() && inquiryResponse instanceof Array
      ? inquiryResponse.filter((item) => item.visibility === visibility)
      : inquiryResponse;
  const waiverOnException = waiversResult?.waivers?.filter(
    (item: any) => item?.waiverKeys[0] === exceptionId
  );
  const waiverApproved = waiverOnException?.filter((item: any) =>
    item?.waiverResponses?.find((itemS: any) => itemS.isApproved === true)
  );

  const waiverResult =
    waivers &&
    waivers.reduce(function (r: any, a: any) {
      r[a.waiverInfo?.["finalResponse"]] =
        r[a.waiverInfo?.["finalResponse"]] || [];

      r[a.waiverInfo?.["finalResponse"]].push(a);
      return r;
    }, Object.create(null));

  let partyId = exceptionUserAndAccountDetails
    ? exceptionsResult?.createdBy
    : null;
  let date = exceptionsResult?.createdOn;
  let createdOnDate;
  // let chatLines: any[] = [];
  // if (exceptionUserAndAccountDetails) {
  //   chatLines = exceptionUserAndAccountDetails.chatLines;
  // }
  if (
    date &&
    date.charAt(date.length - 1) !== "Z" &&
    !date.includes("+00:00")
  ) {
    date += "Z";
    createdOnDate = getDateInFormat(date.replace(/ /g, "T"));
  }
  let sentToOriginatorInfo: any = [];
  if (exceptionsResult?.response) {
    // for queries that came from ilp we need to show the time when the query came instead of the inquiry timestamp
    sentToOriginatorInfo = exceptionsResult.response.find(
      (val: any) => val.responseType === ResponseTypeEnum.SendtoOriginator
    );
    if (sentToOriginatorInfo) {
      partyId = sentToOriginatorInfo.createdBy;
      createdOnDate = getDateInFormat(
        sentToOriginatorInfo.createdOn?.replace(/ /g, "T")
      );
    }
  }

  const userDetails =
    props?.exceptionUserAndAccountDetails && partyId
      ? getUserFullAndOrgName(
          props?.exceptionUserAndAccountDetails || [],
          partyId
        )
      : null;

  if (!type) return null;
  const nameLoanDetails = loanDetails && nameToInitials(loanDetails);
  const nameInitials =
    type.toLowerCase() !== "inquiry" &&
    exceptionsResult.source !== "CONNECT" &&
    exceptionsResult.source !== "WORKFLOW"
      ? nameLoanDetails
      : userDetails?.[0] ?? "";
  const userName =
    type.toLowerCase() !== "inquiry" &&
    exceptionsResult.source !== "CONNECT" &&
    exceptionsResult.source !== "WORKFLOW"
      ? loanDetails
      : userDetails?.[1] ?? "";
  const accountType =
    type?.toLowerCase() !== "inquiry" &&
    exceptionsResult.source !== "CONNECT" &&
    exceptionsResult.source !== "WORKFLOW"
      ? "D"
      : "T";

  const isDeletable =
    type?.toLowerCase() === "inquiry" &&
    isLATUser() &&
    !status &&
    waiverOnException &&
    waiverOnException?.length === 0 &&
    filteredResponse &&
    filteredResponse instanceof Array &&
    filteredResponse?.length === 0;
  const spanGrid1 =
    isViewDetailsVisible ||
    exceptionsResult?.status?.toLowerCase() === "approved-conditionally" ||
    isDeletable
      ? 5
      : 6;
  // const spanGrid2 =
  //   isViewDetailsVisible ||
  //   exceptionsResult?.status?.toLowerCase() === "approved-conditionally" ||
  //   isDeletable
  //     ? 7
  //     : 6;

  const isDragNDropPermittedCondtionOrInquiry =
    (type?.toLowerCase() === "condition" ||
      (type?.toLowerCase() === "inquiry" && !isLATUser())) &&
    !isAskAQuestionClicked &&
    !isWaiverRequestClicked &&
    !isWithdrawnLoan &&
    !status &&
    !isViewOnly(loanStage, loanId);
  const handleDeleteEnquiry = () => {
    if (exceptionId) {
      const promise1 = deleteEnquiryById(loanId, exceptionId, loanStage);

      promise1
        .then((response: any) => {
          if (response.status === 200) {
            navigate(`/loan/createLoan/${loanType}/${loanStage}/${loanId}`);
            dispatch(setActiveTabAndProperty("exceptionsEnquiries"));
            const errorCounts: any[] = getErrorCount(exceptionsResult) || [];
            dispatch(
              updateErrorCount([errorCounts[0], errorCounts[1]], loanId)
            );
          }
        })
        .catch((e) => console.log(e));
    }
  };
  const confirmDelete = () => {
    setOpenConfirmBox(true);
  };
  const handleConfirmation = () => {
    setOpenConfirmBox(false);
  };
  const loanDets = {
    loanId,
    loanStage,
    loanType,
    exceptionId,
    visibility: exceptionsResult?.visibility
  };
  const statusAlign = !spanValue ? "flex-end" : "flex-start";
  // const statusLength = exceptionsResult && exceptionsResult?.statuses?.length;
  // const inquiryComment =
  //   exceptionsResult &&
  //   statusLength &&
  //   exceptionsResult?.statuses[statusLength - 1];
  // const inquiryUserDetails =
  //   props && props.exceptionUserAndAccountDetails && partyId && inquiryComment
  //     ? getUserFullAndOrgName(
  //         props?.exceptionUserAndAccountDetails || [],
  //         inquiryComment.createdBy
  //       )
  //     : null;
  const roles: any = getCookie("roles");
  const approverRoles =
    roles && JSON.parse(roles).filter((item: any) => item === "LAT_MEMBER");
  const handleInternalToOrg = () => {
    dispatch(showLoader());
    const promise1 = internalToOriginator(loanId, loanStage, exceptionId);
    promise1
      .then((response: any) => {
        setOpenDialog(false);
        dispatch(hideLoader());
        if (response.status === 200) {
          // dispatch(push(`/loan/createLoan/${loanType}/${loanStage}/${loanId}`));
          // dispatch(setActiveTabAndProperty("exceptionsEnquiries"));
          // dispatch({
          //   type: UPDATE_EXCEPTION,
          //   payload: { exceptionId, updateObj: { visibility: "external" } }
          // });
          dispatch(
            updateException({
              exceptionId,
              updateObj: { visibility: "external" }
            })
          );
        }
      })
      .catch((e) => {
        dispatch(hideLoader());
        console.log(e);
      });
  };

  const addCommentCB = (text: any) => {
    if (
      exceptionsResult?.type?.toLowerCase() === "inquiry" &&
      exceptionsResult.visibility.toLowerCase() === "external" &&
      isLATUser()
    ) {
      const metaData = `${momentz()
        .tz("America/New_York")
        .format("YYYYMMDD")}/Toorak:`;
      updateInquiries(
        {
          tags: exceptionsResult.tags,
          dataPoint: exceptionsResult.datapoints,
          ExceptionId: exceptionsResult.exceptionId,
          initialComment:
            exceptionsResult.initialComment !== ""
              ? `${exceptionsResult.initialComment} ${metaData} ${text}`
              : `${metaData} ${text}`
        },
        loanId,
        loanStage,
        exceptionsResult.exceptionId,
        dispatch,
        null,
        false
        // isBulk
      );
    }
  };

  // const handlenewTabClick = () => {
  //   const resourceId: any = exceptionsResult?.resourceId;
  //   window.open(`${getConfig().redirectUrl}${path}`);
  //   if (exceptionId && personPartyId) {
  //     // const { exceptionId, resourceId, personPartyId } = secondaryParams;
  //     if (resourceId) {
  //       dispatch(updateReadStatus(exceptionId, resourceId, personPartyId));
  //     }
  //   }
  // };

  const dailogHandler = () => {
    const dialogData = {
      topic: "delete",
      head: "Warning",
      btnText: "Yes",
      contentText: `Are you sure you want to make the ${type} visible to the Originator ?`,
      contentText2: `Originator will not be able to see your actions and comments but
      only the ${type} details and the status of the ${type}.`,
      btnFunction: () => handleInternalToOrg()
    };
    setDialogdetails(dialogData);
    setOpenDialog(true);
  };

  const handleUpload = (e: any) => {
    if (!fileReceived) {
      if (!isSatisfyExceptionClicked) {
        dispatch(
          setActionClicks({
            type: `Satisfy ${type}`,
            exceptionId: exceptionsResult.exceptionId,
            actionId: actionID
          })
        );
        dispatch(setAskAQuestionClicked(false, exceptionsResult.exceptionId));
        dispatch(
          setSatisfyExceptionsClicked(true, exceptionsResult.exceptionId)
        );
        dispatch(
          setWaiverRequestedClicked(false, exceptionsResult.exceptionId)
        );
      }
      if (isConditionsAndInquiriesTab) {
        setPassedFile(acceptedFiles?.[0]);
      } else {
        setPassedFileViewDetails(acceptedFiles?.[0]);
      }
      setTimeout(() => {
        setFileReceived(false);
      }, 1000);
      setFileReceived(true);
    }
  };

  const getDetails = (flag?: boolean) => {
    const cardType = "Condition";
    const id = exceptionId;
    const visibility = exceptionsResult && exceptionsResult?.visibility;
    if (flag) {
      dispatch(getViewMoreDetails(loanId, loanStage, visibility, id, cardType));
    } else {
      setTimeout(() => {
        dispatch(
          getViewMoreDetails(loanId, loanStage, visibility, id, cardType)
        );
      }, 3000);
    }
  };

  const setCollapseScrollPosition = () => {
    const htmlEle = document.getElementsByTagName("html")[0];
    if (htmlEle) {
      const cardWrapper = document.getElementById(`exceptionsCard-${index}`);
      if (cardWrapper) {
        const scrollPos = htmlEle.scrollTop;
        htmlEle.scrollTop = scrollPos - (cardWrapper.clientHeight - 200);
      }
    }
  };

  return (
    <>
      <ShowDialog
        head={dialogdetails.head}
        btnText={dialogdetails.btnText}
        contentText={dialogdetails.contentText}
        contentText2={dialogdetails.contentText2}
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
        handleInternalToOrg={handleInternalToOrg}
      />
      {exceptionsResult?.exceptionId && (
        <Grid
          id={`exceptionsCard-${index}`}
          style={{ boxShadow: "rgb(0 0 0 / 15%) 0px 2px 8px" }}
        >
          <div
            data-testid="document-upload"
            {...getRootProps({ style })}
            style={{ position: "relative" }}
          >
            {isDragActive && isDragNDropPermittedCondtionOrInquiry && (
              <div
                {...getRootProps({ style })}
                style={{
                  backgroundColor: "white",
                  opacity: 0.9,
                  zIndex: 999,
                  border: "dotted 3px darkgrey",
                  borderRadius: "10px",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  margin: "2px"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyItems: "center",
                    justifyContent: "center",
                    height: "100%"
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "22px",
                      color: "black",
                      textAlign: "center"
                    }}
                  >
                    Drag & Drop Files here
                    <br />
                    <span style={{ fontSize: "17px" }}>&</span>
                    <br />
                    <span style={{ fontSize: "16px" }}>
                      Submit to Satisfy {type}
                    </span>
                  </span>
                </div>
              </div>
            )}
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={
                isViewDetailsVisible && spanValue
                  ? classes.exceptionContainerViewDetails
                  : classes.exceptionContainer
              }
              style={{
                paddingBottom: !isViewDetailsVisible ? "18px" : "10px",
                paddingTop: !isViewDetailsVisible ? "18px" : "15px",
                paddingLeft:
                  spanValue || !isConditionsAndInquiriesTab ? "21px" : "26px",
                paddingRight:
                  spanValue || !isConditionsAndInquiriesTab ? "21px" : "26px",
                borderBottom:
                  !isViewDetailsVisible && !spanValue
                    ? "1px solid #f2f2f2"
                    : "0px solid #f2f2f2"
              }}
            >
              <Grid
                item
                xs={12}
                sm={!spanValue ? spanGrid1 : 2}
                md={!spanValue ? spanGrid1 : 2}
                lg={!spanValue ? spanGrid1 : 2}
                xl={!spanValue ? spanGrid1 : 2}
                container
                alignItems="center"
                spacing={1}
                className={`${classes.leftColumn} exceptionCardLeftColumn`}
              >
                <UserDetailsCard
                  heading={!isLATUser() ? "Condition" : type} //all labels as Condition (instead of inquiry) for originator.
                  userImg="some img will come here"
                  accountNameImg={!spanValue ? nameInitials : ""}
                  userFullName={!spanValue ? userName : ""}
                  accountType={!spanValue ? accountType : ""}
                  date={!spanValue ? createdOnDate : ""}
                  sidePanel={sidePanel}
                  exceptionType={!spanValue ? type : undefined}
                  exceptionSource={
                    !spanValue ? exceptionsResult?.source : undefined
                  }
                />
              </Grid>
              {/* section for actions tabs */}
              <Grid
                container
                item
                // xs={!spanValue ? spanGrid2 : 10}
                justifyContent="flex-end"
                alignItems={!spanValue ? "center" : "flex-start"}
                className={`${classes.mobileViewButtonContainer} `}
                style={isConditionsAndInquiriesTab ? { paddingRight: 24 } : {}}
              >
                {!spanValue ? (
                  <>
                    {isLATUser() &&
                    exceptionsResult &&
                    visiblityText === "Visible only to " ? (
                      <Grid
                        item
                        style={{ marginRight: !spanValue ? "0px" : "-6px" }}
                        className={classes.inlineElement}
                      >
                        <StatusComponent
                          status={visiblityText}
                          value={visiblityText}
                        />
                      </Grid>
                    ) : null}
                    {/* status component */}
                    <Grid
                      item
                      xs={
                        spanValue && visiblityText !== "Visible only to " && 10
                      }
                      justifyContent={statusAlign}
                      className={classes.statusComponentContainer}
                      // style={{ paddingRight: spanValue ? "5px" : "10px" }}
                    >
                      <Grid
                        item
                        xs={
                          spanValue && visiblityText !== "Visible only to " && 5
                        }
                        justifyContent={statusAlign}
                        className={classes.statusComponentContainer}
                        // style={{ paddingLeft: 0 }}
                      >
                        <Grid
                          item
                          style={{
                            marginLeft: "0px"
                          }}
                        >
                          {isRescindView && (
                            <span className={classes.darkColor}>
                              Current Status -
                            </span>
                          )}
                          <StatusComponent
                            status={
                              exceptionsResult &&
                              exceptionsResult?.status?.toUpperCase()
                            }
                            value={
                              exceptionsResult &&
                              exceptionsResult?.status?.toUpperCase()
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* </Grid> */}
                  </>
                ) : null}
                {/* view details or delete section */}
                {isViewDetailsVisible &&
                satisfyExceptionAndInquiry &&
                satisfyExceptionAndInquiry.length > 0 ? (
                  <>
                    <InfoOnlyTooltip
                      title={satisfyExceptionAndInquiry?.length}
                      arrow
                      placement="right"
                    >
                      <Grid
                        item
                        // style={{ paddingRight: spanValue ? "5px" : "16px" }}
                        justifyContent={statusAlign}
                        className={`${classes.inlineElement} hide-print-view`}
                      >
                        <IconButton
                          aria-label="notification-count"
                          component="button"
                          classes={iconButtonDropdownStylesWithoutBG}
                          size="large"
                          // style={{ marginTop: "3px" }}
                        >
                          <React.Suspense fallback={null}>
                            <SatisfyIcon />
                          </React.Suspense>
                        </IconButton>
                      </Grid>
                    </InfoOnlyTooltip>
                  </>
                ) : null}

                {isViewDetailsVisible &&
                askQuestionArray &&
                askQuestionArray.length > 0 ? (
                  <>
                    <InfoOnlyTooltip
                      title={askQuestionArray.length}
                      arrow
                      placement="right"
                    >
                      <Grid
                        item
                        justifyContent={statusAlign}
                        className={`${classes.inlineElement} hide-print-view`}
                      >
                        <IconButton
                          aria-label="notification-count"
                          component="button"
                          classes={iconButtonDropdownStylesWithoutBG}
                          size="large"
                        >
                          <React.Suspense fallback={null}>
                            <AskAQuestion />
                          </React.Suspense>
                        </IconButton>
                      </Grid>
                    </InfoOnlyTooltip>
                  </>
                ) : null}

                {isViewDetailsVisible &&
                  waiverResult &&
                  "REOPENED" in waiverResult && (
                    <>
                      <InfoOnlyTooltip
                        title={waiverResult.REOPENED?.length}
                        arrow
                        placement="right"
                      >
                        <Grid
                          item
                          justifyContent={statusAlign}
                          className={`${classes.inlineElement} hide-print-view`}
                        >
                          <IconButton
                            aria-label="notification-count"
                            component="button"
                            classes={iconButtonDropdownStylesWithoutBG}
                            style={{ marginBottom: "3px", padding: 0 }}
                            size="large"
                          >
                            <WaiverReopenIcon />
                          </IconButton>
                        </Grid>
                      </InfoOnlyTooltip>
                    </>
                  )}

                {/* {isViewDetailsVisible &&
                  waiverResult &&
                  "RE-REQUESTED" in waiverResult && (
                    <>
                      <InfoOnlyTooltip
                        title={waiverResult.RE-REQUESTED?.length}
                        arrow
                        placement="right"
                      >
                        <Grid
                          item
                          justifyContent={statusAlign}
                          className={`${classes.inlineElement} hide-print-view`}
                        >
                          <IconButton
                            aria-label="notification-count"
                            component="button"
                            classes={iconButtonDropdownStylesWithoutBG}
                            style={{ marginBottom: "3px" }}
                          >
                            <WaiverReRequestIcon />
                          </IconButton>
                        </Grid>
                      </InfoOnlyTooltip>
                    </>
                  )} */}

                {isViewDetailsVisible &&
                  waiverResult &&
                  "REBUTTAL" in waiverResult && (
                    <>
                      <InfoOnlyTooltip
                        title={waiverResult.REBUTTAL?.length}
                        arrow
                        placement="right"
                      >
                        <Grid
                          item
                          justifyContent={statusAlign}
                          className={`${classes.inlineElement} hide-print-view`}
                        >
                          <IconButton
                            aria-label="notification-count"
                            component="button"
                            classes={iconButtonDropdownStylesWithoutBG}
                            style={{ marginBottom: "3px" }}
                          >
                            <WaiverRebuttalIcon />
                          </IconButton>
                        </Grid>
                      </InfoOnlyTooltip>
                    </>
                  )}

                {isViewDetailsVisible &&
                  waiverResult &&
                  "APPROVED-CONDITIONALLY" in waiverResult && (
                    <>
                      <InfoOnlyTooltip
                        title={
                          waiverResult &&
                          waiverResult["APPROVED-CONDITIONALLY"]?.length
                        }
                        arrow
                        placement="right"
                      >
                        <Grid
                          item
                          // style={{ paddingRight: spanValue ? "5px" : "16px" }}
                          justifyContent={statusAlign}
                          className={`${classes.inlineElement} hide-print-view`}
                        >
                          <IconButton
                            aria-label="notification-count"
                            component="button"
                            classes={iconButtonDropdownStylesWithoutBG}
                            style={{ marginBottom: "3px", padding: 0 }}
                          >
                            <WaiverApprovedIcon />
                          </IconButton>
                        </Grid>
                      </InfoOnlyTooltip>
                    </>
                  )}

                {isViewDetailsVisible &&
                  waiverResult &&
                  "APPROVED" in waiverResult && (
                    <>
                      <InfoOnlyTooltip
                        title={waiverResult.APPROVED?.length}
                        arrow
                        placement="right"
                      >
                        <Grid
                          item
                          // style={{ paddingRight: spanValue ? "5px" : "16px" }}
                          justifyContent={statusAlign}
                          className={`${classes.inlineElement} hide-print-view`}
                        >
                          <IconButton
                            aria-label="notification-count"
                            component="button"
                            classes={iconButtonDropdownStylesWithoutBG}
                            style={{ marginBottom: "3px", padding: 0 }}
                          >
                            <WaiverApprovedIcon />
                          </IconButton>
                        </Grid>
                      </InfoOnlyTooltip>
                    </>
                  )}

                {isViewDetailsVisible &&
                  waiverResult &&
                  "REJECTED" in waiverResult && (
                    <>
                      <InfoOnlyTooltip
                        title={waiverResult.REJECTED?.length}
                        arrow
                        placement="right"
                      >
                        <Grid
                          item
                          // style={{ paddingRight: spanValue ? "5px" : "16px" }}
                          justifyContent={statusAlign}
                          className={`${classes.inlineElement} hide-print-view`}
                        >
                          <IconButton
                            aria-label="notification-count"
                            component="button"
                            classes={iconButtonDropdownStylesWithoutBG}
                            style={{ marginBottom: "3px", padding: 0 }}
                          >
                            <WaiverRejectedIcon />
                          </IconButton>
                        </Grid>
                      </InfoOnlyTooltip>
                    </>
                  )}

                {isViewDetailsVisible && waiverResult && "" in waiverResult && (
                  <>
                    <InfoOnlyTooltip
                      title={waiverResult[""]?.length}
                      arrow
                      placement="right"
                    >
                      <Grid
                        item
                        // style={{ paddingRight: spanValue ? "5px" : "16px" }}
                        justifyContent={statusAlign}
                        className={`${classes.inlineElement} hide-print-view`}
                      >
                        <IconButton
                          aria-label="notification-count"
                          component="button"
                          classes={iconButtonDropdownStylesWithoutBG}
                          // style={{ marginTop: "4px" }}
                          style={{ padding: 0 }}
                        >
                          <WaiverRequestedIcon />
                        </IconButton>
                      </Grid>
                    </InfoOnlyTooltip>
                  </>
                )}

                {/* {isViewDetailsVisible && exceptionsResult?.eventcount && (
                  <>
                    <InfoOnlyTooltip
                      title="Notification Count"
                      arrow
                      placement="right"
                    >
                      <Grid
                        item
                        // style={{ paddingRight: spanValue ? "5px" : "16px" }}
                        justifyContent={statusAlign}
                        className={`${classes.inlineElement} hide-print-view`}
                      >
                        <IconButton
                          aria-label="notification-count"
                          component="button"
                          classes={iconButtonDropdownStylesWithoutBG}
                          style={{ marginBottom: "3px" }}
                        >
                          <WaiverRejectedIcon />
                        </IconButton>
                      </Grid>
                    </InfoOnlyTooltip>
                  </>
                )} */}

                {/* {isViewDetailsVisible && exceptionsResult?.eventcount && (
                  <>
                    <InfoOnlyTooltip
                      title="Open in new tab"
                      arrow
                      placement="right"
                    >
                      <Grid
                        item
                        justifyContent="center"
                        alignItems="center"
                        style={{ paddingLeft: spanValue ? "10px" : "5px" }}
                        className={`${classes.inlineElement} hide-print-view`}
                      >
                        <OpenInNewTab
                          path={path}
                          secondaryParams={{
                            exceptionId,
                            resourceId: exceptionsResult?.resourceId,
                            personPartyId
                          }}
                        />
        
                      </Grid>
                    </InfoOnlyTooltip>
                  </>
                )} */}

                {isViewDetailsVisible && exceptionsResult?.eventcount ? (
                  <>
                    <InfoOnlyTooltip
                      title="Notification Count"
                      arrow
                      placement="right"
                    >
                      <Grid
                        item
                        style={{ paddingRight: spanValue ? "5px" : "16px" }}
                        justifyContent={statusAlign}
                        className={`${classes.inlineElement} hide-print-view`}
                      >
                        <Badge
                          badgeContent={exceptionsResult?.eventcount}
                          data-testid="notification-badge"
                          overlap="circular"
                          variant="standard"
                          className="notificationNumberExceptions"
                        >
                          <IconButton
                            // style={{padding:0}}
                            aria-label="notification-count"
                            component="button"
                            classes={iconButtonDropdownStylesWithoutBG}
                          >
                            <React.Suspense fallback={<span>Loading...</span>}>
                              <CommentIcon />
                            </React.Suspense>
                          </IconButton>
                        </Badge>
                      </Grid>
                    </InfoOnlyTooltip>
                  </>
                ) : null}
                {/* rescind button component */}
                {isViewDetailsVisible ? (
                  <Grid
                    item
                    justifyContent={statusAlign}
                    key={`${exceptionsResult?.exceptionId}Rescind`}
                    data-testid="rescind-icon"
                    className={`${classes.inlineElement} hide-print-view`}
                  >
                    <ExceptionCardRescindIcon
                      waiverOnException={waiverOnException}
                      path={path}
                      stylePadding={{
                        paddingRight: spanValue || isDeletable ? "0px" : "16px"
                      }}
                    />
                  </Grid>
                ) : null}
                {/* action button component */}
                {!spanValue &&
                !isRescindView &&
                (type?.toLowerCase() !== "inquiry" || !isLATUser()) ? (
                  <Grid
                    item
                    justifyContent={statusAlign}
                    style={{
                      paddingRight: spanValue || isDeletable ? "5px" : "4px"
                    }}
                    className={`${classes.inlineElement} ${
                      isViewDetailsVisible ? "activeNewTab" : ""
                    } hide-print-view`}
                  >
                    <ExceptionDropDown
                      exceptionId={exceptionsResult?.exceptionId}
                      type={type}
                      actionID={actionID}
                      status={status}
                      approvalStatus={
                        exceptionsResult &&
                        exceptionsResult?.status?.toLowerCase()
                      }
                      // viewDetailsVisible={isViewDetailsVisible}
                      visibility={
                        exceptionsResult && exceptionsResult?.visibility
                      }
                      anchorRef={anchorRef}
                      isAskAQuestionClicked={isAskAQuestionClicked}
                      isSatisfyExceptionClicked={isSatisfyExceptionClicked}
                      isWaiverRequestClicked={isWaiverRequestClicked}
                      isWithdrawnLoan={isWithdrawnLoan}
                      exceptionData={exceptionsResult}
                      orgId={orgId}
                    />
                  </Grid>
                ) : null}
                {type?.toLowerCase() === "inquiry" &&
                isLATUser() &&
                !spanValue ? (
                  <Grid
                    item
                    justifyContent={statusAlign}
                    key={exceptionsResult?.exceptionId}
                    style={{
                      paddingRight: spanValue || isDeletable ? "5px" : "0"
                    }}
                    data-testid="lat-actions"
                    className={`${classes.inlineElement} hide-print-view`}
                  >
                    <React.Suspense fallback={<span>Loading...</span>}>
                      <WaiverActionsButton
                        waiverId={exceptionId}
                        loanId={loanId}
                        exceptionId={exceptionId}
                        name="Actions"
                        exceptionDetails={exceptionsResult}
                        loanStage={loanStage}
                        exceptionType={type}
                        status={exceptionsResult?.status}
                        statusDisable={statusDisable}
                        waiversCount={waiverOnException?.length}
                        approvedWaiverLength={waiverApproved?.length}
                        visiblity={
                          exceptionsResult && exceptionsResult?.visibility
                        }
                        isActioningForSDInquiryByLAT={
                          isActioningForSDInquiryByLAT
                        }
                        selectedCompensatingFactors={
                          selectedCompensatingFactors
                        }
                        setselectedCompensatingFactors={
                          setselectedCompensatingFactors
                        }
                      />
                    </React.Suspense>
                  </Grid>
                ) : null}
                {isDeletable && !isViewDetailsVisible ? (
                  <Grid
                    item
                    justifyContent="center"
                    alignItems="flex-end"
                    style={{ paddingLeft: "10px" }}
                  >
                    {!status ? (
                      <IconButton
                        aria-label="delete"
                        component="button"
                        classes={iconButtonDropdownStylesWithoutBG}
                        onClick={() => confirmDelete()}
                      >
                        <React.Suspense fallback={<span>Loading...</span>}>
                          <DeleteIcon />
                        </React.Suspense>
                      </IconButton>
                    ) : null}
                  </Grid>
                ) : null}
                {/* </Grid> */}
                {isViewDetailsVisible &&
                  !sidePanel &&
                  ((exceptionsResult.source === "WORKFLOW" && !isLATUser()) ||
                    exceptionsResult.source !== "WORKFLOW") && (
                    <InfoOnlyTooltip
                      title="Open in new tab"
                      arrow
                      placement="right"
                    >
                      <Grid
                        item
                        justifyContent="center"
                        alignItems="center"
                        style={{ paddingLeft: spanValue ? "10px" : "5px" }}
                        className={`${classes.inlineElement} hide-print-view`}
                      >
                        <OpenInNewTab
                          path={path}
                          secondaryParams={{
                            exceptionId,
                            resourceId: exceptionsResult?.resourceId,
                            personPartyId
                          }}
                        />
                        {/* <Button
                variant="contained"
                size="small"
                data-testId="view-details-btn"
                className={classes.viewDetailsbutton}
                component="button"
                onClick={() => {
                  window.open(`${getConfig().redirectUrl}${path}`);
                }}
              >
                View Details &nbsp;
                <RedirectIcon />
              </Button> */}
                      </Grid>
                    </InfoOnlyTooltip>
                  )}
              </Grid>
              {/* sections ends here */}
            </Grid>
            {/* comopact view status component */}
            {spanValue && (
              <Grid
                container
                item
                spacing={2}
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
                className={classes.responsivePaddingConditions}
              >
                {isLATUser() &&
                exceptionsResult &&
                visiblityText === "Visible only to " ? (
                  <Grid
                    item
                    style={{ marginRight: spanValue ? "0px" : "12px" }}
                  >
                    <React.Suspense fallback={<span>Loading...</span>}>
                      <StatusComponent
                        status={visiblityText}
                        value={visiblityText}
                      />
                    </React.Suspense>
                  </Grid>
                ) : null}
                {/* status component */}
                <Grid
                  item
                  xs={spanValue && visiblityText !== "Visible only to " && 12}
                  justifyContent={statusAlign}
                  className={classes.statusComponentContainer}
                  // style={{
                  //   marginRight: !spanValue ? "0px" : "12px",
                  //   paddingLeft: !spanValue ? "10px" : "0px"
                  // }}
                >
                  <Grid
                    item
                    xs={spanValue && visiblityText !== "Visible only to " && 5}
                    justifyContent={statusAlign}
                    className={classes.statusComponentContainer}
                  >
                    <Grid
                      item
                      style={{
                        marginLeft: "0px"
                      }}
                    >
                      <React.Suspense fallback={<span>Loading...</span>}>
                        <StatusComponent
                          status={
                            exceptionsResult &&
                            exceptionsResult?.status?.toUpperCase()
                          }
                          value={
                            exceptionsResult &&
                            exceptionsResult?.status?.toUpperCase()
                          }
                        />
                      </React.Suspense>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {/* tags wrapper */}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={`${
                !spanValue
                  ? classes.sectionContainer
                  : classes.responsiveSectionContainer
              } section-container-print`}
              data-testid="section-wrapper"
            >
              {exceptionsResult?.tags?.length > 0 && (
                <section
                  className={
                    !spanValue
                      ? classes.tagsWrapper
                      : classes.responsiveTagsWrapper
                  }
                  style={
                    isConditionsAndInquiriesTab
                      ? {
                          paddingTop: isViewDetailsVisible ? "10px" : "25px",
                          paddingLeft: 5
                        }
                      : {
                          paddingTop: isViewDetailsVisible ? "10px" : "25px"
                        }
                  }
                >
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={spanValue ? 12 : 8}
                    xl={spanValue ? 12 : 8}
                    // spacing={2}
                  >
                    {exceptionsResult
                      ? exceptionsResult.tags.map((item: string) => (
                          <div className={classes.tagsContainer} key={item}>
                            <Typography className={classes.tagsItem}>
                              {item}
                            </Typography>
                          </div>
                        ))
                      : null}
                  </Grid>
                </section>
              )}
              <section
                className={
                  isViewDetailsVisible ? "active-multiline-ellipsis" : ""
                }
                style={isConditionsAndInquiriesTab ? { paddingLeft: 2 } : {}}
              >
                <div className="multiline-ellipsis-container f-14">
                  <div className="multiline-content">
                    <Typography
                      className={classes.description}
                      style={{
                        paddingLeft: !spanValue
                          ? "7px !important"
                          : "3px !important",
                        paddingTop: "6px"
                      }}
                    >
                      {exceptionsResult && exceptionsResult.description !== ""
                        ? exceptionsResult.description
                        : exceptionsResult.source === "WORKFLOW"
                        ? exceptionsResult.initialDescription
                        : null}
                    </Typography>
                    <div className="ellipsis-dots">...</div>
                  </div>
                </div>
              </section>

              {/* {exceptionsResult &&
                exceptionsResult.source === "WORKFLOW" &&
                exceptionsResult.visibility !== "internal" && (
                  <ResponseOnAction
                    item={exceptionsResult}
                    loanId={loanId}
                    loanStage={loanStage}
                  />
                )} */}

              {commentsOnExceptionArr.map((item: any) => (
                <span>
                  {item.description !== "" && item.description !== null && (
                    <section
                      className={classes.sectionContainer}
                      data-testid="exceptions-comment-section"
                      style={
                        isConditionsAndInquiriesTab
                          ? {
                              paddingLeft: 0,
                              paddingRight: 0,
                              marginLeft: 5,
                              marginRight: 5
                            }
                          : { paddingLeft: 1, paddingRight: 1 }
                      }
                    >
                      <Grid className={classes.exceptionComment}>
                        <section className={classes.comments}>
                          <Comment
                            exceptionComment={item}
                            accountNameImg={
                              exceptionsResult?.source === "WORKFLOW"
                                ? ""
                                : nameInitials
                            }
                            orgType={
                              exceptionsResult?.source === "WORKFLOW"
                                ? "T"
                                : "D"
                            }
                          />
                        </section>
                      </Grid>
                    </section>
                  )}
                </span>
              ))}
              {isConditionsAndInquiriesTab &&
                actionClicks instanceof Array &&
                actionClicks.map(
                  (item: {
                    type: string;
                    actionId: string;
                    exceptionId: string;
                  }) => {
                    if (item.exceptionId === exceptionsResult.exceptionId)
                      return (
                        <>
                          <div
                            style={{
                              // borderBottom: "1px solid #f2f2f2",
                              margin: "10px 0px"
                            }}
                          />
                          {item.type !== "Request a Waiver" ? (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              className={classes.containerStyle}
                              key={item.actionId}
                            >
                              <ResponseAndDocumentUploadCard
                                type={item.type}
                                conditionType={type}
                                actionId={item.actionId}
                                actionType={item.type}
                                exceptionsResult={exceptionsResult.exceptions}
                                loanDetail={loanDets}
                                passedFile={passedFile}
                                exceptionId={exceptionsResult.exceptionId}
                                getDetails={getDetails}
                                showViewDetails={showViewDetails}
                                clearUploadedFile={clearUploadedFile}
                                exceptionData={exceptionsResult}
                              />
                            </Grid>
                          ) : (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              className={classes.containerStyle}
                              key={item.actionId}
                            >
                              <WaiverContainer
                                loanStage={loanStage}
                                loanId={loanId}
                                exceptionId={exceptionsResult.exceptionId}
                                actionId={item.actionId}
                                actionType={item.type}
                                waiverDetails={{}}
                                hasConditionProp={
                                  type.toLowerCase() === "condition"
                                }
                                visibility={exceptionsResult.visibility}
                                callbacks={{ isLoading: setLoading }}
                                setFiles={setFiles}
                                showViewDetails={showViewDetails}
                                getDetails={getDetails}
                              />
                            </Grid>
                          )}
                        </>
                      );
                    return <></>;
                  }
                )}

              {/* *****************View more strts here **************** */}

              {!sidePanel && showViewDetails && isConditionsAndInquiriesTab && (
                <section
                  className="active-multiline-ellipsis"
                  style={{
                    marginTop: "10px",
                    cursor: "pointer",
                    width: 90
                  }}
                >
                  <div className="multiline-ellipsis-container">
                    <div
                      role="button"
                      className="multiline-content"
                      onClick={() => {
                        setShowViewDetails(false);
                        getDetails(true);
                      }}
                    >
                      <Typography
                        className={classes.descriptionDashboard}
                        style={{
                          textAlign: "left",
                          color: "#5f72e4",
                          fontWeight: 600,
                          fontSize: "12px"
                        }}
                      >
                        + View More
                      </Typography>
                      <div className="ellipsis-dots">...</div>
                    </div>
                  </div>
                </section>
              )}

              {!showViewDetails && (
                <ViewMoreExceptions
                  waiversResult={exceptionsResult.waivers}
                  status={exceptionsResult?.status}
                  exception={exceptionsResult}
                  loanId={loanId}
                  loanType={loanType}
                  loanStage={loanStage}
                  visibility={exceptionsResult.visibility}
                  type={type}
                  resourceId={exceptionId}
                  exceptionId={exceptionId}
                  exceptionType={type}
                  loanDetails={loanDetails}
                  activeInteractionsModal={false}
                  getDetails={getDetails}
                  addCommentCB={addCommentCB}
                />
              )}

              {!showViewDetails && (
                <section
                  className="active-multiline-ellipsis"
                  style={{ marginTop: "10px", cursor: "pointer" }}
                >
                  <div className="multiline-ellipsis-container">
                    <div
                      className="multiline-content"
                      onClick={() => {
                        setCollapseScrollPosition();
                        setShowViewDetails(true);
                      }}
                    >
                      <Typography
                        className={classes.descriptionDashboard}
                        style={{
                          textAlign: "left",
                          color: "#5f72e4",
                          fontWeight: 600,
                          fontSize: "12px"
                        }}
                      >
                        - View Less
                      </Typography>
                      <div className="ellipsis-dots">...</div>
                    </div>
                  </div>
                </section>
              )}

              {spanValue && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  container
                  alignItems="center"
                  className={classes.compactUserCard}
                >
                  <React.Suspense fallback={<span>Loading...</span>}>
                    <UserDetailsCard
                      alternateText="raised by"
                      userImg="some img will come here"
                      accountNameImg={spanValue ? nameInitials : ""}
                      userFullName={spanValue ? userName : ""}
                      accountType={spanValue ? accountType : ""}
                      date={spanValue ? createdOnDate : ""}
                      sidePanel={sidePanel}
                      exceptionType={type}
                      exceptionSource={exceptionsResult?.source}
                    />
                    <Button
                      variant="contained"
                      color="info"
                      size="small"
                      data-testId="view-details-btn"
                      className={classes.viewDetailsbutton}
                      component="button"
                      endIcon={<RedirectIcon />}
                      onClick={() => {
                        window.open(`${getConfig().redirectUrl}${path}`);
                      }}
                    >
                      View Details &nbsp;
                    </Button>
                  </React.Suspense>
                </Grid>
              )}
              {!spanValue &&
                !isViewDetailsVisible &&
                enquiryDocDetails?.length > 0 && (
                  <Grid
                    className={classes.filesContainer}
                    data-testid="files-container"
                  >
                    {enquiryDocDetails &&
                      enquiryDocDetails.length > 0 &&
                      enquiryDocDetails?.map((item: any, key: number) => (
                        <section className={classes.fileBarWrapper}>
                          <Grid item xs={12} className={classes.fileContainer}>
                            <Grid container>
                              <React.Suspense
                                fallback={<span>Loading...</span>}
                              >
                                <FileBar
                                  downloadHandler={() =>
                                    handleDownload(
                                      item,
                                      exceptionId || "",
                                      "exception"
                                    )
                                  }
                                  type={type}
                                  value={item}
                                />
                              </React.Suspense>
                            </Grid>
                          </Grid>
                        </section>
                      ))}
                  </Grid>
                )}
              {!status &&
                !isWithdrawnLoan &&
                approverRoles[0] === "LAT_MEMBER" &&
                visiblityText === "Visible only to " && (
                  <Grid
                    item
                    data-testid="visible-org-btn"
                    style={{
                      paddingRight: "5px",
                      marginTop: "10px"
                    }}
                    justifyContent={statusAlign}
                  >
                    {type?.toLowerCase() !== "inquiry" && (
                      <Button
                        variant="contained"
                        size="small"
                        data-testid="view-details-button"
                        className={classes.viewDetailsbutton}
                        startIcon={<ViewIcon />}
                        onClick={() => dailogHandler()}
                        disabled={isViewOnly(loanStage, loanId) || false}
                        color="info"
                      >
                        Convert Condition Visible to Originator
                      </Button>
                    )}
                  </Grid>
                )}
            </Grid>
            <React.Suspense fallback={<span>Loading...</span>}>
              <ConfirmationDialog
                onCancel={handleConfirmation}
                onConfirm={handleDeleteEnquiry}
                open={openConfirmBox}
                msg="Are you sure you want to delete the Loan Enquiry?"
              />
            </React.Suspense>
          </div>
        </Grid>
      )}
    </>
  );
};
