/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {
  publicClient,
  // LoanEvaluationRequest,
  // ThirtyYearLoanEvaluationRequest,
  LoanStage
} from "@toorak/tc-common-fe-sdk";
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  LoanStatusEnum,
  transformLoanReevaluationData
} from "@toorak/tc-common-util-sdk";
import { getConfig, getLoanType } from "../../config/config";
import {
  getTemplateRequestBody,
  getRequestBodyForRunningEval
} from "../../create-loan/evaluation-results/EvaluationHelper";
import {
  dispatchAndUpdate,
  prepareObjectForTemplateMapping,
  updateEditComments
} from "../../create-loan/create-loan.action";
import { setAutoSaveCall } from "../../create-loan/create-loan.reducer";
import {
  showLoader,
  hideLoader
} from "../loaderAndException/loaderAndException.action";
import {
  EvaluationResultStore,
  changeEvalPanelVisibility,
  deleteUnreadWaiver,
  evaluateLoanFailed,
  evaluateLoanSuccess,
  getOverriderValues,
  requestedWaierUserName,
  setUnreadObjects,
  // requestedWaierUserName,
  setWaiverList,
  updateWaiverStatus,
  uploadExcelLoanEvalChange
} from "./EvaluationResults.reducer";
import { getExcelDataInSession } from "../../utils/AccessManagement";
// import { UPDATE_WAIVER_APPROVAL_STATUS } from "../ExceptionsResults/ExceptionActions";
import { isNullOrUndefined, nameToInitials } from "../../utils/formatChecks";
import { getAccountTypeFirstLetter } from "../../create-loan/exceptions-and-enquiries/ExceptionsCard";
import { getCookie } from "../../utils/cookies";
// import { UPDATE_POLLING_DATA } from "../SettlementAndPurchase/SettlementAndPurchase.action";
import {
  ErrorPriority,
  getErrorConfigObject
} from "../../ErrorHandler/ErrorConfigData";
import { errorMessageJson } from "../../ErrorHandler/ErrorMessageJson";
import {
  getAffectedWaivers,
  getPartyId
} from "../WaiverRequest/WaiverRequest.action";
import { showPopUpMessages } from "../loanAndPropertyDetails/loanAndPropertyDetails.reducer";
import {
  MessageBody,
  MessageHeader
} from "../../create-loan/createLoanHeaders/InfoPopUp";
import { messages } from "../../utils/messages";
import { setWaiverApprovalStatus } from "../ExceptionsResults/ExceptionsReducer";
import { updatePollingData } from "../SettlementAndPurchase/SettlementAndPurchase.reducer";
// import { getTasks, taskNames } from "../tasks/Tasks.action";
// export const UPDATE_RULE_RESULTS = "UPDATE_RULE_RESULTS";
// export const EVALUATE_LOAN_LOADING = "EVALUATE_LOAN_LOADING";
// export const EVALUATE_LOAN_SUCCESS = "EVALUATE_LOAN_SUCCESS";
// export const EVALUATE_LOAN_FAILED = "EVALUATE_LOAN_FAILED";
// export const UPDATE_LOAN_EVALUATION = "UPDATE_LOAN_EVALUATION";
// export const CHANGE_EVAL_PANEL_VISIBILITY = "CHANGE_EVAL_PANEL_VISIBILITY";
// export const GET_OVERRIDDER_VALUES = "GET_OVERRIDDER_VALUES";
// export const SET_RULES_PREVIOUS_STATE = "SET_RULES_PREVIOUS_STATE";
// export const GET_WAIVER_CUSTOMER_INFO = "GET_WAIVER_CUSTOMER_INFO";
// export const GET_WAIVER_LIST = "GET_WAIVER_LIST";
// export const UPDATE_WAIVER_LIST = "UPDATE_WAIVER_LIST";
// export const UPDATE_WAIVER_STATUS = "UPDATE_WAIVER_STATUS";
// export const DELETE_WAIVER = "DELETE_WAIVER";
// export const UPLOAD_EXCEL_LOAN_EVAL_CHANGE = "UPLOAD_EXCEL_LOAN_EVAL_CHANGE";
// export const MODIFY_WFL_RULE = "MODIFY_WFL_RULE";
// export const WAIVER_REQUESTED_USER_NAME = "WAIVER_REQUESTED_USER_NAME";
// export const SET_UNREAD_OBJECTS = "SET_UNREAD_OBJECTS";
// export const DELETE_UNREAD_WAIVER = "DELETE_UNREAD_WAIVER";
// export const SET_LATEST_AGGREGATE_RULE_VERSIONS =
//   "SET_LATEST_AGGREGATE_RULE_VERSIONS";
export function getRuleEvaluationByLoanId(
  loanId: string,
  loanStage: LoanStage,
  takeoutPartnerId?: string,
  isSubmitted: boolean = false,
  formattedPropertyData?: any,
  loanType?: any,
  dontShowLoader?: boolean
): any {
  return async (dispatch: any) => {
    try {
      if (isSubmitted && !dontShowLoader) {
        dispatch(showLoader());
        dispatch(changeEvalPanelVisibility(true));
      }
      let url = `${
        getConfig().apiUrl
      }/evaluation/v2/${loanId}/partner/results?loanStage=${loanStage}`;
      if (
        takeoutPartnerId &&
        !["null", "undefined"].includes(takeoutPartnerId)
      ) {
        url = `${url}&partnerId=${takeoutPartnerId}`;
      }
      const response = await publicClient.get(url, {
        errorConfig: getErrorConfigObject({
          errorPriority: isSubmitted
            ? ErrorPriority.critical
            : ErrorPriority.medium,
          customErrorMessage: {
            heading: errorMessageJson.getRuleResults.heading,
            body: errorMessageJson.getRuleResults.message
          }
        })
      } as any);
      /**
       * if loan is submitted, once we get evaluation results back we need to make 2 api calls.
       * 1)Get template message --->This will be used to compute reason for fail in evalution tests
       * 2)Get waiver data for the whole loan and save it in redux.(even if the template service call fails fetch this data)
       *
       * Also set evaluation panel visibility to true incase loan is submitted.
       */
      if (isSubmitted) {
        try {
          let errorAddress: any = {};
          if (formattedPropertyData) {
            errorAddress = getPropertiesWithFailedTest(
              response.data,
              formattedPropertyData
            );
          }
          prepareObjectForTemplateMapping(
            takeoutPartnerId,
            response.data,
            dispatch,
            "result",
            false,
            loanStage.toLowerCase(),
            loanId,
            loanType
          );
          const templateKeys = getTemplateRequestBody(response.data);

          const uniqueTemplateKeys = templateKeys.filter((c, index) => {
            return templateKeys.indexOf(c) === index;
          });
          const loanResultInputsToEval = Object.keys(
            response.data?.loanResult[loanId]
          )?.length
            ? getRequestBodyForRunningEval(
                Object.values(response.data.loanResult)[0]
              )
            : {};
          const orgPartyId =
            sessionStorage.getItem("originatorPartyIdNew") || "";
          let url = `${
            getConfig().apiUrl
          }/template/fetch?locale=NA&countryCode=NA&partialProcess=true&partyId=${orgPartyId}`;

          if (takeoutPartnerId) {
            url = `${url}&tpId=${takeoutPartnerId}`;
          }

          const templateResponse = await publicClient.post(
            url,
            { templateKeys: uniqueTemplateKeys },
            {
              errorConfig: getErrorConfigObject({
                skipErrorHandling: true
              })
            } as any
          );
          // dispatch({
          //   type: EVALUATE_LOAN_SUCCESS,
          //   payload: {
          //     loanEvaluationResult: response.data,
          //     reasonForFail: {
          //       templateMessage: templateResponse.data,
          //       inputToRunEval: {
          //         loanResult: loanResultInputsToEval,
          //         propertyResult: {}
          //       }
          //     },
          //     errorAddressList: errorAddress,
          //     isSubmitted
          //   }
          // });
          dispatch(
            evaluateLoanSuccess({
              loanEvaluationResult: response.data,
              reasonForFail: {
                templateMessage: templateResponse.data,
                inputToRunEval: {
                  loanResult: loanResultInputsToEval,
                  propertyResult: {}
                }
              },
              errorAddressList: errorAddress,
              isSubmitted
            })
          );

          dispatch(
            getWaiverList(
              loanId,
              loanStage,
              true,
              undefined,
              undefined,
              dontShowLoader
            )
          );
        } catch (error) {
          // dispatch({
          //   type: EVALUATE_LOAN_SUCCESS,
          //   payload: {
          //     loanEvaluationResult: response.data,
          //     reasonForFail: {},
          //     errorAddressList: {},
          //     isSubmitted
          //   }
          // });
          dispatch(
            evaluateLoanSuccess({
              loanEvaluationResult: response.data,
              reasonForFail: {},
              errorAddressList: {},
              isSubmitted
            })
          );

          dispatch(
            getWaiverList(
              loanId,
              loanStage,
              true,
              undefined,
              undefined,
              dontShowLoader
            )
          );
        }
        dispatch(hideLoader());
      } else {
        // dispatch({
        //   type: EVALUATE_LOAN_SUCCESS,
        //   payload: {
        //     loanEvaluationResult: response.data,
        //     reasonForFail: {},
        //     errorAddressList: {},
        //     isSubmitted
        //   }
        // });
        dispatch(
          evaluateLoanSuccess({
            loanEvaluationResult: response.data,
            reasonForFail: {},
            errorAddressList: {},
            isSubmitted
          })
        );
      }
    } catch (err) {
      const error: any = err;
      // dispatch({
      //   type: EVALUATE_LOAN_FAILED,
      //   error: {
      //     message: error.message
      //   }
      // });
      dispatch(
        evaluateLoanFailed({
          error: {
            message: error.message
          }
        })
      );

      if (isSubmitted && !dontShowLoader) {
        dispatch(hideLoader());
      }
    }
  };
}

export function getPropertiesWithFailedTest(results: any, propertyData: any) {
  const errorKeys: any = {};
  let propertyAddress: string;
  if (
    results &&
    propertyData &&
    results?.propertiesResults &&
    Object.values(results?.propertiesResults).length
  ) {
    Object.values(results?.propertiesResults).forEach((item: any) => {
      const propertyId = Object.keys(results?.propertiesResults).find(
        (key: any) => results?.propertiesResults[key] === item
      );
      propertyData.map((property: any) => {
        // eslint-disable-next-line eqeqeq
        if (property?.propertyId == propertyId) {
          const { address } = property?.propertyLocation?.payload;
          propertyAddress = `${address?.street_line} ${address?.city} ${address?.state} ${address?.zipcode}`;
        }
        return true;
      });
      if (Object.values(item).length) {
        Object.values(item).forEach((insideItem: any) => {
          Object.keys(insideItem).forEach((key: any) => {
            if (
              (insideItem[key] === "FAIL" || insideItem[key] === "WAIVED") &&
              key !== "sectionEligibility" &&
              propertyId &&
              propertyAddress
            ) {
              if (errorKeys[key]) {
                let isNewProperty: boolean = true;
                errorKeys[key].forEach((obj: any) => {
                  if (obj.propertyId === propertyId) {
                    isNewProperty = false;
                  }
                });
                if (isNewProperty) {
                  errorKeys[key].push({
                    propertyId,
                    propertyAddress
                  });
                }
              } else {
                errorKeys[key] = [{ propertyId, propertyAddress }];
              }
            }
            return true;
          });
          return true;
        });
      }
      return true;
    });
    return errorKeys;
  }
  return true;
}

export function getPropertiesWithFailedTests(
  waiverKeys: any,
  properties: any,
  waiverTitle?: any
) {
  const errorKeys: any = {};
  const filteredWaiverKeys = waiverKeys.filter(
    (item: any) => item.split(".")[0] === "propertiesResults"
  );
  let tempdata: any = [];
  if (waiverKeys?.length > 1) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < filteredWaiverKeys?.length; i++) {
      // const key = filteredWaiverKeys[i].split(".")[3];
      const key = waiverTitle;
      const propertyId = filteredWaiverKeys[i].split(".")[1];
      let propertyAddress: string = "";
      // eslint-disable-next-line array-callback-return
      properties.forEach((property: any) => {
        // eslint-disable-next-line radix
        if (propertyId && property.loanPropertyId === parseInt(propertyId)) {
          const address = property?.propertyLocation;
          propertyAddress = `${address?.addressLine1} ${address?.city} ${address?.state} ${address?.postalCode}`;
        }
      });
      if (propertyId && propertyAddress) {
        tempdata = [{ propertyId, propertyAddress }, ...tempdata];
        errorKeys[key] = tempdata;
      }
    }
  }
  return errorKeys;
}

export function postOverrideOutput(
  loanId: string,
  loanStage: LoanStage,
  body: any,
  takeoutPartnerId?: string
): Promise<any> {
  let url = `${
    getConfig().apiUrl
  }/evaluation/v2/${loanId}/partner/results?loanStage=${loanStage}&resultType=OVERRIDDEN`;
  if (takeoutPartnerId && !["null", "undefined"].includes(takeoutPartnerId)) {
    url = `${url}&partnerId=${takeoutPartnerId}`;
  }
  return new Promise((resolve, reject) => {
    publicClient
      .post(url, body)
      .then((res: any) => resolve(res.data))
      .catch(reject);
  });
}

export function deleteOverriddenResult(
  loanId: string,
  loanStage: LoanStage,
  body: any,
  takeoutPartnerId?: string
): Promise<any> {
  let url = `${
    getConfig().apiUrl
  }/evaluation/v2/${loanId}/partner/results/delete?loanStage=${loanStage}&resultType=OVERRIDDEN`;
  if (takeoutPartnerId && !["null", "undefined"].includes(takeoutPartnerId)) {
    url = `${url}&partnerId=${takeoutPartnerId}`;
  }
  return new Promise((resolve, reject) => {
    publicClient
      .post(url, body)
      .then((res: any) => resolve(res.data))
      .catch(reject);
  });
}

/**
 * * Evaluate refreshWaiver value before every edit.
 * * If EvaluationResults are effected by edited values and respective EvaluationResult has Waiver associated with it (refreshWaiver === true). Fetch waivers again.
 * @param evaluateLoanRequest
 * @param loanId
 * @param loanStage
 * @param isSubmitted
 * @param changeLoanState
 * @param formattedPropertyData
 * @param refreshWaiver: boolean To be passed as query params.
 * @param docsDetails
 * @returns
 */

// const submitCallback = (
//   dispatch: any,
//   loanId: string,
//   loanStage: LoanStage
// ) => {
//   getTasks(dispatch, {
//     resourceTypes: ["LOAN"],
//     resourceIds: [loanId],
//     taskNames: [
//       taskNames.TAG,
//       taskNames.SPLIT,
//       taskNames.REVIEW,
//       taskNames.RECONCILE,
//       taskNames.BUDGET_REVIEW,
//       taskNames.BUDGET_APPROVE
//     ]
//   });
// };

export function evaluateLoan(
  evaluateLoanRequest: any, // LoanEvaluationRequest | ThirtyYearLoanEvaluationRequest|
  loanId: string,
  loanStage: LoanStage,
  isSubmitted: boolean = false,
  changeLoanState?: boolean,
  formattedPropertyData?: any,
  refreshWaiver?: any,
  docsDetails?: any,
  auditSync?: any,
  commentObj?: any[] | null,
  cb?: any,
  errorCallback?: any,
  loanType?: string
): any {
  return async (dispatch: any) => {
    try {
      if (isSubmitted) dispatch(showLoader());
      const loanInfo = evaluateLoanRequest?.loanFact?.loan?.loanInfo;
      let ruleType = loanInfo?.loanType
        ? loanInfo?.loanType?.toLowerCase()
        : "bridge";
      ruleType = loanType ? loanType : ruleType;
      let url = `${
        getConfig().apiUrl
      }/aggregate/${loanId}/rules?stage=${loanStage}&loanRuleType=${ruleType}`;
      if (refreshWaiver) {
        url += `&refreshWaiver=true`;
      }
      if (auditSync) {
        url += `&auditSync=true`;
      }
      let filteredRequest = evaluateLoanRequest;
      if (evaluateLoanRequest) {
        filteredRequest = {
          ...evaluateLoanRequest,
          loanFact: {
            ...evaluateLoanRequest?.loanFact,
            loan: {
              ...evaluateLoanRequest?.loanFact?.loan,
              loanRuleVersions:
                evaluateLoanRequest?.loanFact?.loan?.loanRuleVersions?.filter(
                  (it: any) => it.version
                )
            }
          }
        };
      }

      const response = await publicClient.post(url, filteredRequest, {
        errorConfig: getErrorConfigObject({
          errorPriority: ErrorPriority.medium,
          customErrorMessage: {
            heading: errorMessageJson.ruleExecution.heading,
            body: errorMessageJson.ruleExecution.message
          }
        })
      } as any);

      const excelUploadData = getExcelDataInSession();
      let tempObj = excelUploadData?.loanIdList[loanId];
      if (tempObj) {
        tempObj = { ...tempObj, ...{ rulesExecuted: true } };
        excelUploadData.loanIdList[loanId] = tempObj;
        sessionStorage.setItem("excelUpload", JSON.stringify(excelUploadData));
      }
      if (response.data.error?.code === "2001") {
        if (isSubmitted) dispatch(hideLoader());
        // dispatch({
        //   type: EVALUATE_LOAN_SUCCESS,
        //   payload: {
        //     loanEvaluationResult: { loanResult: {}, propertiesResults: {} },
        //     reasonForFail: {},
        //     errorAddressList: {},
        //     isSubmitted
        //   }
        // });
        dispatch(
          evaluateLoanSuccess({
            loanEvaluationResult: { loanResult: {}, propertiesResults: {} },
            reasonForFail: {},
            errorAddressList: {},
            isSubmitted
          })
        );
        if (errorCallback) errorCallback();
      } else if (isSubmitted) {
        console.log("inside eval............");
        // isSubmitted is true in case loan is submitted.
        // if rule is retriggered, we need to start polling in settlement tab to keep it updated.
        if (loanStage === LoanStage.post || loanStage === LoanStage.pre) {
          // dispatch({
          //   type: UPDATE_POLLING_DATA,
          //   payload: {
          //     startPolling: true,
          //     timeInMs: 0
          //   }
          // });
          dispatch(
            updatePollingData({
              startPolling: true,
              timeInMs: 0
            })
          );
        }
        if (changeLoanState) {
          const nextStatus =
            loanStage === LoanStage.fes
              ? LoanStatusEnum.Submit
              : // docsDetails.length &&
                //   window.location.hostname === "www.toorakcapital.info"
                // ? LoanStatusEnum.DueDiligenceReview
                LoanStatusEnum.InitialReview;
          // if there are docs already before submit, no need to update status to IR; LPP-7568
          dispatch(
            dispatchAndUpdate(
              loanId,
              nextStatus,
              loanStage,
              dispatch,
              "",
              false,
              null,
              undefined
            )
          );

          // Show info message during submission as per LPP-7161
          if (ruleType === "30year" && loanStage !== LoanStage.fes) {
            dispatch(
              showPopUpMessages({
                message: MessageBody(),
                header: MessageHeader()
              })
            );
          }
        }
        try {
          /** now make api call to get template messages.this will be used to compute
           *  reason for fail in evalution tests.
           */
          let errorAddress: any = {};
          if (formattedPropertyData) {
            errorAddress = getPropertiesWithFailedTest(
              response.data,
              formattedPropertyData
            );
          }
          prepareObjectForTemplateMapping(
            null,
            response.data,
            dispatch,
            "result",
            false,
            loanStage.toLowerCase(),
            loanId,
            loanInfo.loanType
          );
          const templateKeys = getTemplateRequestBody(response.data);

          const uniqueTemplateKeys = templateKeys.filter((c, index) => {
            return templateKeys.indexOf(c) === index;
          });
          const loanResultInputsToEval = getRequestBodyForRunningEval(
            Object.values(response.data.loanResult)[0]
          );
          const orgPartyId =
            sessionStorage.getItem("originatorPartyIdNew") || "";
          const tpId = loanInfo?.takeoutPartnerId;
          let templateUrl = `/template/fetch?locale=NA&countryCode=NA&partialProcess=true&partyId=${orgPartyId}`;
          if (tpId) {
            templateUrl = `${templateUrl}&tpId=${tpId}`;
          }
          const templateResponse = await publicClient.post(
            `${getConfig().apiUrl}${templateUrl}`,
            { templateKeys: uniqueTemplateKeys }
          );
          if (isSubmitted) dispatch(hideLoader());
          // dispatch({
          //   type: EVALUATE_LOAN_SUCCESS,
          //   payload: {
          //     loanEvaluationResult: response.data,
          //     reasonForFail: {
          //       templateMessage: templateResponse.data,
          //       inputToRunEval: {
          //         loanResult: loanResultInputsToEval,
          //         propertyResult: {}
          //       }
          //     },
          //     errorAddressList: errorAddress,
          //     isSubmitted
          //   }
          // });
          dispatch(
            evaluateLoanSuccess({
              loanEvaluationResult: response.data,
              reasonForFail: {
                templateMessage: templateResponse.data,
                inputToRunEval: {
                  loanResult: loanResultInputsToEval,
                  propertyResult: {}
                }
              },
              errorAddressList: errorAddress,
              isSubmitted
            })
          );

          if (refreshWaiver) {
            dispatch(getWaiverList(loanId, loanStage));
          }
          // dispatch({
          //   type: GURRANTOR_FIELD_CHANGED,
          //   payload: {
          //     gurrantorChangedIds: ''
          //   }
          // });

          // TODO: hide loader forcely on success
          // need to find exact reason why loader is not going away
          hideLoader();
        } catch (error) {
          if (isSubmitted) dispatch(hideLoader());
          // dispatch({
          //   type: EVALUATE_LOAN_SUCCESS,
          //   payload: {
          //     loanEvaluationResult: response.data,
          //     reasonForFail: {},
          //     errorAddressList: {},
          //     isSubmitted
          //   }
          // });
          dispatch(
            evaluateLoanSuccess({
              loanEvaluationResult: response.data,
              reasonForFail: {},
              errorAddressList: {},
              isSubmitted
            })
          );
        }
      } else {
        if (isSubmitted) dispatch(hideLoader());
        // dispatch({
        //   type: EVALUATE_LOAN_SUCCESS,
        //   payload: {
        //     loanEvaluationResult: response.data,
        //     reasonForFail: {},
        //     errorAddressList: {},
        //     isSubmitted
        //   }
        // });
        dispatch(
          evaluateLoanSuccess({
            loanEvaluationResult: response.data,
            reasonForFail: {},
            errorAddressList: {},
            isSubmitted
          })
        );
      }
      if (commentObj) {
        dispatch(updateEditComments(`${loanId}_${loanStage}`, commentObj));
      }
      if (cb) cb();
      dispatch(setAutoSaveCall(false));
    } catch (err) {
      const error: any = err;
      dispatch(setAutoSaveCall(false));
      if (isSubmitted) {
        dispatch(hideLoader());
      }
      dispatch(
        evaluateLoanFailed({
          error: {
            message: error.message
          }
        })
      );
    }
  };
}

export function getOverriddenOutputs(
  loanId: string,
  loanStage: LoanStage,
  takeoutPartnerId?: string
): Promise<any> {
  let url = `${
    getConfig().apiUrl
  }/evaluation/v2/${loanId}/partner/results?loanStage=${loanStage}&resultType=OVERRIDDEN`;
  if (takeoutPartnerId && !["null", "undefined"].includes(takeoutPartnerId)) {
    url = `${url}&partnerId=${takeoutPartnerId}`;
  }
  return new Promise((resolve, reject) => {
    publicClient
      .get(url)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}
export function updateOverriddenInredux(resp: any): any {
  return async (dispatch: any) => {
    // dispatch({
    //   type: GET_OVERRIDDER_VALUES,
    //   payload: {
    //     overidden: resp?.data
    //   }
    // });
    dispatch(
      getOverriderValues({
        overidden: resp?.data
      })
    );
  };
}
export function getOverriddenOutputValues(
  loanId: string,
  loanStage: LoanStage,
  takeOutPartnerId?: string
): any {
  return async (dispatch: any) => {
    const resp = await getOverriddenOutputs(
      loanId,
      loanStage,
      takeOutPartnerId
    );
    dispatch(updateOverriddenInredux(resp));
  };
}

// export function setEvaluationPanelVisibility(visibility: boolean): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: CHANGE_EVAL_PANEL_VISIBILITY,
//       payload: {
//         visibility
//       }
//     });
//   };
// }

export const setWaiverNotificationCount: any = (data: any) => {
  return async (dispatch: any) => {
    let obj: { [index: string]: any } = {};
    data.forEach((element: any) => {
      const waiverId = element.resource?.resourceValue;
      if (waiverId) {
        if (!obj[waiverId]) {
          obj = { ...obj, ...{ [waiverId]: [element] } };
        } else {
          const temp = [...obj[waiverId], ...[element]];
          obj = { ...obj, ...{ [waiverId]: temp } };
        }
      }
    });
    // dispatch({
    //   type: SET_UNREAD_OBJECTS,
    //   payload: {
    //     unreadWaivers: obj
    //   }
    // });
    dispatch(
      setUnreadObjects({
        unreadWaivers: obj
      })
    );
  };
};

export const markNotificationAsRead: any = (data: any, waiverId?: string) => {
  return async (dispatch: any) => {
    const resourceIds = data.map((ele: any) => {
      return { resourceId: ele.resource.resourceId };
    });
    const personId = getCookie("person_id");
    const url = `${getConfig().apiUrl}/notification/${personId}/events`;
    await publicClient.post(url, resourceIds);
    if (waiverId) {
      // dispatch({ type: DELETE_UNREAD_WAIVER, payload: { waiverId } });
      dispatch(deleteUnreadWaiver({ waiverId }));
    }
  };
};

/** Get waiver for a particular loan Id */
export function getWaiverList(
  loanId: string,
  loanStage: LoanStage,
  getNotification?: boolean,
  excludeDetails?: boolean,
  waiverId?: string,
  dontShowLoader?: boolean,
  exceptionIds?: any
): any {
  return async (dispatch: any) => {
    if (!dontShowLoader) {
      dispatch(showLoader());
    }
    let url = `${
      getConfig().apiUrl
    }/aggregate/waiverservice/LOAN/${loanId}/waiver?secondaryIdentifierName=LOAN_STAGE&secondaryIdentifierValue=${loanStage}&includeHistory=true`;
    url = waiverId ? `${url}&waiverIds=${waiverId}` : url;
    url = !excludeDetails ? `${url}&excludeDetails=true` : url;
    if (exceptionIds) {
      url = exceptionIds.length
        ? `${url}&waiverKeys${exceptionIds.join()}`
        : url;
    }
    const resp = await publicClient.get(url, {
      errorConfig: getErrorConfigObject({
        errorPriority: ErrorPriority.low,
        customErrorMessage: {
          heading: errorMessageJson.getWaiver.heading,
          body: errorMessageJson.getWaiver.message
        }
      })
    } as any);
    if (resp.status === 200) {
      dispatch(updatePendingWaivers(resp.data.waivers, loanId));
      if (getNotification && resp.data?.waivers?.length) {
        const waiverNotifications = await getAllNotificationForResource(
          loanId,
          "WAIVER"
        );
        dispatch(setWaiverNotificationCount(waiverNotifications));
      }
      if (!dontShowLoader) {
        dispatch(hideLoader());
      }
      // dispatch({
      //   type: GET_WAIVER_LIST,
      //   payload: {
      //     waiver: resp.data.waivers,
      //     documents: resp.data.documents
      //   }
      // });
      // dispatch({
      //   type: GET_WAIVER_CUSTOMER_INFO,
      //   payload: resp.data
      // });
      dispatch(
        setWaiverList({
          waiver: resp.data.waivers,
          documents: resp.data.documents
        })
      );

      dispatch(requestedWaierUserName(resp.data));
    } else {
      dispatch(hideLoader());
    }
  };
}

export function updatePendingWaivers(waiverList: any, loanId: string): any {
  let waiversPending: number = 0;
  if (waiverList.length) {
    const pendingWaiversList = waiverList?.filter(
      (waiver: any) =>
        (waiver?.waiverType === "EvaluationResult" ||
          waiver?.waiverType === "GeneralWaiver" ||
          waiver?.waiverType === "AdjustmentWaiver") &&
        (!waiver?.waiverResponses || waiver?.waiverResponses?.length === 0)
    );
    waiversPending = pendingWaiversList.length;
  }

  return async (dispatch: any) => {
    const url = `${getConfig().apiUrl}/loans/${loanId}/errors`;
    const payload = {
      waiversPending
    };
    const header = {
      headers: { "Content-Type": "application/json" }
    };
    await publicClient.put(url, payload, {
      ...header,
      errorConfig: getErrorConfigObject({
        skipErrorHandling: true
      })
    } as any);
  };
}

export function updateWaiverApprovalStatus(
  waiverId: string,
  data: any,
  exceptionWaiverId?: string
): any {
  return async (dispatch: any) => {
    if (!exceptionWaiverId) {
      // dispatch({
      //   type: UPDATE_WAIVER_STATUS,
      //   payload: { waiverId, waiver: data }
      // });
      dispatch(
        updateWaiverStatus({
          waiverId,
          waiver: data
        })
      );
    } else if (exceptionWaiverId) {
      // dispatch({
      //   type: UPDATE_WAIVER_APPROVAL_STATUS,
      //   payload: { waiverId, waiver: data }
      // });
      dispatch(
        setWaiverApprovalStatus({
          waiverId,
          waiver: data
        })
      );
    }
  };
}
// export function deleteWaiverFromRedux(waiverId: string): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: DELETE_WAIVER,
//       payload: { waiverId }
//     });
//   };
// }
// export function updateWaiverList(waiverData: any): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: UPDATE_WAIVER_LIST,
//       payload: { waiver: waiverData }
//     });
//   };
// }

export const genFormattedUserDetails = (
  customerDetails: any,
  partyId?: string,
  createdOn?: any,
  useTokenData = true
) => {
  if (!customerDetails || !partyId) return null;
  const { customers, accountMapping, accounts } = customerDetails;
  const tokenData = useTokenData ? getPartyId() : {};
  const customerData = customers?.find((item: any) => {
    return item.partyId === partyId;
  });
  const accountMappingItem = accountMapping?.find(
    (item: any) => item.personId === partyId
  );
  const accountData = accounts?.find(
    (item: any) => item.partyId === accountMappingItem?.accountId
  );
  const accountType = accountData?.accountType || tokenData?.roles?.[0];
  const firstName = !isNullOrUndefined(customerData?.firstName)
    ? customerData?.firstName
    : "";
  const lastName = !isNullOrUndefined(customerData?.lastName)
    ? customerData?.lastName
    : "";
  const middleName = !isNullOrUndefined(customerData?.middleName)
    ? customerData?.middleName
    : "";
  const NameInitials = nameToInitials(`${firstName} ${lastName}`);
  const userFullName = firstName
    ? `${firstName} ${middleName} ${lastName}`
    : tokenData?.name ?? messages.unidentifiedUser;
  const accountTypeFirstLetter = accountType
    ? getAccountTypeFirstLetter(accountType)[0]
    : "";
  const dateTime = new Date(createdOn).toLocaleDateString(undefined, {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    year: "numeric",
    month: "short",
    day: "2-digit"
  });
  return { NameInitials, userFullName, accountTypeFirstLetter, dateTime };
};

export const getUserFullAndOrgName = (
  customerDetails: any,
  partyId?: string,
  createdOn?: any,
  useTokenData?: true
) => {
  if (!customerDetails || !partyId) return null;
  const { customers, accountMapping, accounts } = customerDetails;
  const tokenData = useTokenData ? getPartyId() : {};
  const customerData = customers?.find((item: any) => {
    return item.partyId === partyId;
  });
  const accountMappingItem = accountMapping?.find(
    (item: any) => item.personId === partyId
  );
  const accountData = accounts?.find(
    (item: any) => item.partyId === accountMappingItem?.accountId
  );
  const accountType =
    accountData?.accountType ||
    (tokenData?.roles?.length ? tokenData.roles[0] : "");
  const firstName = !isNullOrUndefined(customerData?.firstName)
    ? customerData?.firstName
    : "";
  const lastName = !isNullOrUndefined(customerData?.lastName)
    ? customerData?.lastName
    : "";
  const middleName = !isNullOrUndefined(customerData?.middleName)
    ? customerData?.middleName
    : "";
  const NameInitials: any = nameToInitials(`${firstName} ${lastName}`);
  const userFullName = firstName
    ? `${firstName} ${middleName} ${lastName}`
    : tokenData?.name;
  const accountTypeFirstLetter = accountType
    ? getAccountTypeFirstLetter(accountType)[0]
    : "";
  const dateTime = new Date(createdOn).toLocaleDateString(undefined, {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    year: "numeric",
    month: "short",
    day: "2-digit"
  });
  return { NameInitials, userFullName, accountTypeFirstLetter, dateTime };
};

// export function requestedWaierUserName(waiverRequestedInfo: any): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: GET_WAIVER_CUSTOMER_INFO,
//       payload: waiverRequestedInfo
//     });
//   };
// }

export function updateEvaluationResultsStoreForSelectedLoan(
  data: EvaluationResultStore
): any {
  return async (dispatch: any) => {
    // dispatch({
    //   type: UPLOAD_EXCEL_LOAN_EVAL_CHANGE,
    //   payload: { data }
    // });
    dispatch(uploadExcelLoanEvalChange({ data }));
  };
}

export function getAllNotificationForResource(
  loanId: string,
  resourceKey: string
): Promise<any> {
  const partyId = getCookie("person_id");
  const url = `${getConfig().apiUrl}/notification/${partyId}/events/fetch`;
  return new Promise((resolve, reject) => {
    const body = {
      parentId: loanId,
      resourceKey
    };
    publicClient
      .post(url, body, {
        errorConfig: getErrorConfigObject({
          skipErrorHandling: true
        })
      } as any)
      .then((res: any) => resolve(res.data))
      .catch(reject);
  });
}

export function getRuleResult(
  loanId: string,
  loanStage: LoanStage,
  takeoutPartnerId?: string | null
): Promise<any> {
  let url = `${
    getConfig().apiUrl
  }/evaluation/v2/${loanId}/partner/results?loanStage=${loanStage}`;
  if (takeoutPartnerId && !["null", "undefined"].includes(takeoutPartnerId)) {
    url = `${url}&partnerId=${takeoutPartnerId}`;
  }
  return new Promise((resolve, reject) => {
    publicClient
      .get(url)
      .then((res: any) => resolve(res.data))
      .catch(reject);
  });
}

export function getBorrowerUsedForCalculation(
  loanType: string,
  body: any
): Promise<any> {
  const loanTypeToSend =
    loanType === getLoanType[0].displayValue ? "bridge" : "30year";
  const url = `${
    getConfig().apiUrl
  }/ruleevaluation/borrowerinfo/${loanTypeToSend}`;
  return new Promise((resolve, reject) => {
    publicClient
      .post(url, body)
      .then((res: any) => resolve(res.data))
      .catch(reject);
  });
}

// export const upadteRuleResults: any = (ruleResults: any) => {
//   return async (dispatch: any) => {
//     dispatch({
//       type: UPDATE_RULE_RESULTS,
//       ruleResults
//     });
//   };
// };

/**
 * Generates rule request body and triggers evaluateLoan()
 */
export const reRunRules: any = (data: {
  waiversCreated: any;
  loanId: any;
  loanStage: any;
  loanType: string;
  overriddenValues: any;
  loanData: any;
  propertyData: any;
  ruleGroup: any;
  ruleField: any;
  evaluationData: any;
  changedFieldsArr: string[]; // TO-DO: This may not be needed. Do analysis and remove.
  changedRuleFields: string[];
  takeoutPartnerId?: string;
}) => {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader(true));
      const {
        changedRuleFields,
        waiversCreated,
        loanId,
        loanStage,
        loanType,
        overriddenValues,
        loanData,
        propertyData,
        ruleGroup,
        ruleField,
        takeoutPartnerId
      } = data || {};
      let overriddenValuesToUse = overriddenValues;
      if (changedRuleFields.length) {
        const { reRequestWaiverObject, openWaivers } = await getAffectedWaivers(
          changedRuleFields,
          waiversCreated,
          loanId,
          loanStage,
          loanType
        );
        const refreshWaiver =
          (typeof reRequestWaiverObject === "object" &&
            Object.keys(reRequestWaiverObject)?.length) ||
          openWaivers.length;
        if (refreshWaiver) {
          const resp = await getOverriddenOutputs(
            loanId,
            loanStage,
            takeoutPartnerId
          );
          overriddenValuesToUse = resp?.data;
          dispatch(updateOverriddenInredux(resp));
        }
        const ruleRequestBody = transformLoanReevaluationData(
          loanId,
          loanData,
          propertyData,
          ruleGroup,
          ruleField,
          overriddenValuesToUse
        );
        dispatch(
          evaluateLoan(
            ruleRequestBody,
            loanId,
            loanStage,
            true,
            false,
            propertyData,
            refreshWaiver
          )
        );
      }
      dispatch(hideLoader(true));
    } catch (error) {
      dispatch(hideLoader(true));
    }
  };
};

// export const setAggregateRuleVersions: any = (data: any) => {
//   return async (dispatch: any) => {
//     dispatch({
//       type: SET_LATEST_AGGREGATE_RULE_VERSIONS,
//       payload: data
//     });
//   };
// };
