/* eslint-disable @typescript-eslint/camelcase */

interface CreditLineEconomicsType {
  creditLineEconomicId: string;
  isCurrentCycle: boolean;
  baseLoanAmount: number;
  rolledOverLoanAmount: number;
  totalLoanAmount: number;
  purchasedLoanAmount: number;
  pipelineLoanAmount: number;
  startDate: Date;
  endDate: Date;
  creditLineLoanDetails: CreditLineLoanDetailsType[];
}

interface CreditLineLoanDetailsType {
  loanId: string;
  loanAmount: number;
  loanState: string;
}

interface CreditLineType {
  id: string;
  shortName: string;
  creditLineType: string;
  active: boolean;
  ddPartyId: string;
  term: number;
  rollOverAllowed: boolean;
  capacity: number;
  allowWaiver: boolean;
  adjustedRate: number;
  creditLineEconomics: CreditLineEconomicsType[];
}

export const getServicerTabs = (creditLines: CreditLineType[]) => {
  const servicerTabs: any = [
    {
      id: "UnassignedLoans",
      title: "Unassigned Loans"
    }
  ];

  const parsedCreditLines = creditLines.map((item: CreditLineType) => {
    const { id, shortName, creditLineEconomics } = item;
    const {
      totalLoanAmount,
      purchasedLoanAmount,
      pipelineLoanAmount
    } = creditLineEconomics.filter(cdlEcoItem => cdlEcoItem.isCurrentCycle)[0];
    return {
      id,
      shortName,
      totalLoanAmount,
      purchasedLoanAmount,
      pipelineLoanAmount
    };
  });
  parsedCreditLines.forEach(item => {
    const {
      id,
      totalLoanAmount,
      shortName,
      purchasedLoanAmount,
      pipelineLoanAmount
    } = item;
    servicerTabs.push({
      id,
      title: shortName,
      purchasedLoanAmount,
      pipelineLoanAmount,
      totalLoanAmount
    });
  });
  return servicerTabs;
};
