/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { produce } from "immer";
// import {
//   COMMENT_SUCCESS,
//   COMMENT_THREAD_SUCCESS,
//   ACTION_START,
//   ACTION_FAIL,
//   ACTION_SUCCESS,
//   RESET_COMMENTS,
//   INTERNAL_COMMENT_SUCCESS,
//   UPDATE_COMMENT
// } from "./comment.action";

export interface CommentStore {
  comments: any;
  thread: any;
  loading: boolean;
}

const initialState: CommentStore = {
  comments: {},
  thread: {},
  loading: false
};

const commentSlice = createSlice({
  name: "comment",
  initialState,
  reducers: {
    commentThreadSuccess(state, action: PayloadAction<any>) {
      const buckets = action.payload;
      const combinedDDData = { ...buckets, ...state.thread };
      state.thread = combinedDDData;
    },
    commentSuccess(state, action: PayloadAction<any>) {
      const { buckets, hostId, count } = action.payload;
      const combinedDDData = { ...buckets, count };
      if (hostId) {
        state.comments[hostId] = combinedDDData;
      }
    },
    updateCommentRedux(state, action: PayloadAction<any>) {
      const { value, loanId, commentID } = action.payload;
      const index = state.comments[loanId].chatLines.findIndex(
        (item: { id: string }) => item.id === commentID
      );
      if (index !== -1) {
        state.comments[loanId].chatLines[index].lineText = value.lineText;
        state.comments[loanId].chatLines[index].updatedOn = value.updatedOn;
      }
    },
    internalCommentSuccess(state, action: PayloadAction<any>) {
      const { buckets, hostId } = action.payload;
      if (hostId) {
        for (let i = 0; i < buckets.chatLines.length; i++) {
          const chatItem = state.comments[hostId].chatLines.filter(
            (chat: any) =>
              chat.chatLineSequenceId ===
              buckets.chatLines[i].chatLineSequenceId
          ).length;
          if (!chatItem) {
            state.comments[hostId].chatLines.push(buckets.chatLines[i]);
          }
        }
        for (let i = 0; i < buckets.documents.length; i++) {
          const docItem = state.comments[hostId].documents.filter(
            (docs: any) =>
              docs.secondaryIdentifierValue ===
              buckets.documents[i].secondaryIdentifierValue
          ).length;
          if (!docItem) {
            state.comments[hostId].documents.push(buckets.documents[i]);
          }
        }

        for (let i = 0; i < buckets.accountMapping.length; i++) {
          const accountMappingItem = state.comments[
            hostId
          ].accountMapping.filter(
            (acc: any) => acc.personId === buckets.accountMapping[i].personId
          ).length;
          if (!accountMappingItem) {
            state.comments[hostId].accountMapping.push(
              buckets.accountMapping[i]
            );
          }
        }

        for (let i = 0; i < buckets.accounts.length; i++) {
          const accountsItem = state.comments[hostId].accounts.filter(
            (acc: any) => acc.partyId === buckets.accounts[i].partyId
          ).length;
          if (!accountsItem) {
            state.comments[hostId].accounts.push(buckets.accounts[i]);
          }
        }

        for (let i = 0; i < buckets.customers.length; i++) {
          const customersItem = state.comments[hostId].customers.filter(
            (customer: any) => customer.partyId === buckets.customers[i].partyId
          ).length;
          if (!customersItem) {
            state.comments[hostId].customers.push(buckets.customers[i]);
          }
        }
        // Repeat similar logic for other properties in the buckets
      }
    },
    actionStart(state) {
      state.loading = true;
    },
    actionFail(state) {
      state.loading = false;
    },
    actionSuccess(state) {
      state.loading = false;
    },
    resetComments(state) {
      state.comments = {};
    }
  }
});

export const {
  commentThreadSuccess,
  commentSuccess,
  updateCommentRedux,
  internalCommentSuccess,
  actionStart,
  actionFail,
  actionSuccess,
  resetComments
} = commentSlice.actions;

export const commentReducer = commentSlice.reducer;

// export const commentReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case COMMENT_THREAD_SUCCESS: {
//       const buckets = action.payload;
//       const combinedDDData = { ...buckets, ...state.thread };
//       const nextState = produce(state, draftState => {
//         draftState.thread = combinedDDData;
//       });
//       return nextState;
//     }
//     case COMMENT_SUCCESS: {
//       const { buckets, hostId, count} = action.payload;
//       const combinedDDData = { ...buckets, count};
//       const nextState = produce(state, draftState => {
//         if (hostId) {
//           draftState.comments[hostId] = combinedDDData;
//         }
//       });
//       return nextState;
//     }
//     case UPDATE_COMMENT: {
//       const { value, loanId, commentID } = action.payload;
//       const nextState = produce(state, draftState => {
//         const index = draftState.comments[loanId].chatLines.findIndex(
//           (item: { id: string }) => item.id === commentID
//         );
//         if (index !== -1) {
//           draftState.comments[loanId].chatLines[index].lineText =
//             value.lineText;
//           draftState.comments[loanId].chatLines[index].updatedOn =
//             value.updatedOn;
//         }
//       });
//       return nextState;
//     }
//     case INTERNAL_COMMENT_SUCCESS: {
//       const { buckets, hostId } = action.payload;
//       const combinedDDData = { ...buckets };
//       const nextState = produce(state, draftState => {
//         if (hostId) {
//             for(let i =0; i<combinedDDData.chatLines.length; i++) {
//               const chatItem = draftState.comments[hostId].chatLines.filter((chat: any) => chat.chatLineSequenceId === combinedDDData.chatLines[i].chatLineSequenceId).length;
//               if(!chatItem) {
//                 draftState.comments[hostId].chatLines.push(combinedDDData.chatLines[i])
//               }
//             }
//             for(let i =0; i<combinedDDData.documents.length; i++) {
//               const docItem = draftState.comments[hostId].documents.filter((docs: any) => docs.secondaryIdentifierValue === combinedDDData.documents[i].secondaryIdentifierValue).length;
//               if(!docItem) {
//                 draftState.comments[hostId].documents.push(combinedDDData.documents[i])
//               }
//             }

//             for(let i =0; i<combinedDDData.accountMapping.length; i++) {
//               const accountMappingItem = draftState.comments[hostId].accountMapping.filter((acc: any) => acc.personId === combinedDDData.accountMapping[i].personId).length;
//               if(!accountMappingItem) {
//                 draftState.comments[hostId].accountMapping.push(combinedDDData.accountMapping[i])
//               }
//             }

//             for(let i =0; i<combinedDDData.accounts.length; i++) {
//               const accountsItem = draftState.comments[hostId].accounts.filter((acc: any) => acc.partyId === combinedDDData.accounts[i].partyId).length;
//               if(!accountsItem) {
//                 draftState.comments[hostId].accounts.push(combinedDDData.accounts[i])
//               }
//             }

//             for(let i =0; i<combinedDDData.customers.length; i++) {
//               const customersItem = draftState.comments[hostId].customers.filter((customer: any) => customer.partyId === combinedDDData.customers[i].partyId).length;
//               if(!customersItem) {
//                 draftState.comments[hostId].customers.push(combinedDDData.customers[i])
//               }
//             }
//         }
//       });
//       return nextState;
//     }
//     case ACTION_START: {
//       const nextState = produce(state, draftState => {
//         draftState.loading = true;
//       });
//       return nextState;
//     }
//     case ACTION_FAIL: {
//       const nextState = produce(state, draftState => {
//         draftState.loading = false;
//       });
//       return nextState;
//     }
//     case ACTION_SUCCESS: {
//       const nextState = produce(state, draftState => {
//         draftState.loading = false;
//       });
//       return nextState;
//     }
//     case RESET_COMMENTS: {
//       const nextState = produce(state, draftState => {
//         draftState.comments = {};
//       });
//       return nextState;
//     }
//     default:
//       return state;
//   }
// };
