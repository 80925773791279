import { Grid, Typography } from "@mui/material";
import { ConfirmFormModal } from "@toorak/tc-common-fe-sdk";
import React from "react";
import { NO_DOCUMENTS_WARNING_INFO } from "../ttf-review/constants";
import { useStyles } from "./DocumentsForm.style";

export const NoDocumentsModal = (props: any) => {
  const classes = useStyles();
  const { isConfirmationModalOpen, handleCloseWarningDialog } = props;
  const getBody = () => {
    return (
      <Grid style={{ marginBottom: "20px" }}>
        <Typography className={classes.warningModal}>
          {NO_DOCUMENTS_WARNING_INFO.body}
        </Typography>
      </Grid>
    );
  };

  return (
    <ConfirmFormModal
      isOpen={isConfirmationModalOpen}
      handleClose={(event: any, reason: string) => {
        if (reason !== "backdropClick") {
          handleCloseWarningDialog();
        }
      }}
      confirmCondition={false}
      successHandler={handleCloseWarningDialog}
      header={NO_DOCUMENTS_WARNING_INFO.header}
      body={getBody()}
      primaryBtnName={NO_DOCUMENTS_WARNING_INFO.okButtonLabel}
      hidePrimaryBtn={false}
      SecondaryBtnName=""
      hideSecondaryBtn
      headerStyles={{
        padding: 17,
        borderBottom: "solid 1px #dddddd"
      }}
      footerStyles={{
        borderTop: "solid 1px #dddddd",
        padding: "0px 0px 3px 17px",
        margin: "0 0px 14px 0px"
      }}
      className={classes.confirmFormModalClass}
      disableBackdropClick
    />
  );
};
