import React, { useEffect, useState } from "react";
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  ListItemText,
  Button
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useDrawsStyles } from "../Draws.styles";
import { ConfirmFormModal, DrawStage } from "@toorak/tc-common-fe-sdk";
import { deleteBankDetailsById } from "../../../stores/drawRequest/drawRequest.action";
import { RootState } from "../../../stores/rootReducer";
import { isOrigUser } from "../../../utils/AccessManagement";
import {
  typeCast,
  typeCastOnBlur
} from "../../../create-loan/tape-to-file/TapeToFileUtils";
import { DrawRequestStore } from "../../../stores/drawRequest/drawRequest.interface";
import { DrawViewModeTextField } from "../DrawViewModeTextField";
import { IconButton } from "@mui/material";
import { useStyles } from "../../AssetManagement.styles";
import deleteImage from "../../../images/Delete.svg";
import { AMNotEditingBankStages } from "../Constant";
import { updateSelectedBankObj } from "../../../stores/drawRequest/drawRequest.reducer";

export const BankDetails = (props: any) => {
  const styles = useStyles();
  const { selectedLoanId, isSubmitClicked, drawObj, fieldWidth, isEditing } =
    props;
  const dispatch = useDispatch();
  const classes = useDrawsStyles();
  const [bankDetailObj, setBankDetailObj] = useState<any>({});
  const [availableAccountIds, setAvailableAccountIds] = useState<any[]>([]);
  const [openFormModal, setOpenFormModel] = useState(false);
  const [showWaringMgs, setShowWaringMgs] = useState(false);
  const [selectedAccountNumber, setSelectedAccountNumber] = useState("");
  const isOrig = isOrigUser();
  const [addBankObj, setAddBankObj] = useState<any>({
    accountNumber: "",
    reAccountNumber: "",
    routing: "",
    bankName: "",
    accountName: "",
    bankDetailId: ""
  });
  const isDraft = drawObj.stage === DrawStage.draft;
  let {
    selectedBankDetails,
    availableBankAccountDetails,
    fieldsHistory,
    drawDetails
  } = useSelector<RootState, DrawRequestStore>(
    (state) => state.drawRequestStore
  );

  useEffect(() => {
    setBankDetailObj(selectedBankDetails);
    //eslint-disable-next-line
  }, [selectedBankDetails]);

  useEffect(() => {
    if (!availableBankAccountDetails) return;

    setAvailableAccountIds(() => availableBankAccountDetails);
  }, [availableBankAccountDetails]);

  useEffect(() => {
    setSelectedAccountNumber("");
    //eslint-disable-next-line
  }, [selectedLoanId]);

  const isBankedited = () => {
    if (isDraft) {
      return true;
    } else if (isOrig) {
      return isEditing;
    } else {
      return isEditing && !AMNotEditingBankStages.includes(drawDetails.stage);
    }
  };

  const showingEditBankDetails = () => {
    const hasBankInfo =
      bankDetailObj &&
      (bankDetailObj.accountNumber ||
        bankDetailObj.routing ||
        bankDetailObj.bankName ||
        bankDetailObj.accountName);

    if ((isEditing || isDraft) && hasBankInfo) {
      if (isOrig) {
        return true;
      } else {
        return !AMNotEditingBankStages.includes(drawDetails.stage);
      }
    }

    return false;
  };

  const checkIfBankDetailIdExists = () => {
    if (bankDetailObj?.bankDetailId) {
      return showingEditBankDetails() && bankDetailObj.bankDetailId;
    }

    const bankDetails = availableAccountIds.find(
      (bankDetail) =>
        bankDetail.accountNumber === bankDetailObj?.accountNumber &&
        bankDetail.accountName === bankDetailObj?.accountName
    );

    if (bankDetails) {
      handleEditBankDetails(bankDetails.bankDetailId, "bankDetailId");
      return showingEditBankDetails() && bankDetails.bankDetailId;
    }

    return false;
  };

  const handleEditBankDetails = (val: any, key: string, isBlur = false) => {
    let updatedBankDetailObj = JSON.parse(JSON.stringify(bankDetailObj));
    const formatterVal =
      key === "routing"
        ? isBlur
          ? typeCastOnBlur(val, "abaroutingnumber")
          : typeCast(val, "abaroutingnumber")
        : val;
    updatedBankDetailObj[key] = formatterVal;
    setBankDetailObj(updatedBankDetailObj);
    dispatch(updateSelectedBankObj(updatedBankDetailObj));
  };

  const handleBankDetailChange = (accountNo: any) => {
    let tempBankIdsList = availableBankAccountDetails || availableAccountIds;
    const selectedAccount: any = tempBankIdsList.find(
      (key: any) => key.accountNumber === accountNo
    );
    if (selectedAccount) {
      dispatch(updateSelectedBankObj({ ...selectedAccount }));
    }
    setSelectedAccountNumber(accountNo);
  };

  const handleAddNewOpen = () => {
    setAddBankObj({
      accountNumber: "",
      reAccountNumber: "",
      routing: "",
      bankName: "",
      accountName: "",
      bankDetailId: ""
    });
    setShowWaringMgs(false);
    setOpenFormModel(true);
  };

  const handleDeleteBank = async () => {
    await dispatch(deleteBankDetailsById(bankDetailObj.bankDetailId, drawObj));
  };

  const handleAddBankDetailChange = (val: any, key: string, isBlur = false) => {
    let updatedBankDetailObj = JSON.parse(JSON.stringify(addBankObj));
    const formatterVal =
      key === "routing"
        ? isBlur
          ? typeCastOnBlur(val, "abaroutingnumber")
          : typeCast(val, "abaroutingnumber")
        : val;
    updatedBankDetailObj[key] = formatterVal;
    setAddBankObj(updatedBankDetailObj);
  };

  const validateAccountNumber = (val: any) => {
    if (
      addBankObj.accountNumber &&
      addBankObj.reAccountNumber &&
      addBankObj.reAccountNumber !== addBankObj.accountNumber
    ) {
      setShowWaringMgs(true);
    } else {
      setShowWaringMgs(false);
    }
  };

  const bankFormBody = () => {
    return (
      <Grid item container xs={12} style={{ width: "400px" }}>
        <Grid item container xs={12} style={{ padding: "0px 20px" }}>
          <div
            className={classes.bankOption}
            data-testid="account-number-input"
          >
            <p className={classes.headerText}>
              Account Number<span className={`${classes.asterick}`}>*</span>
            </p>
            <input
              value={addBankObj.accountNumber}
              className={classes.BankInputValue}
              type={"password"}
              onChange={(e: any) =>
                handleAddBankDetailChange(e.target.value, "accountNumber")
              }
              onBlur={(e: any) => validateAccountNumber(e.target.value)}
            />
          </div>
          <div
            className={classes.bankOption}
            data-testid="reenter-account-number-input"
          >
            <p className={classes.headerText}>
              Reenter Account Number
              <span className={`${classes.asterick}`}>*</span>
            </p>
            <input
              value={addBankObj.reAccountNumber}
              className={`${
                showWaringMgs
                  ? classes.BankInputWithError
                  : classes.BankInputValue
              }`}
              onChange={(e: any) =>
                handleAddBankDetailChange(e.target.value, "reAccountNumber")
              }
              onBlur={(e: any) => validateAccountNumber(e.target.value)}
            />
            {showWaringMgs && (
              <span className={classes.errorMgs}>
                Entered account numbers don't match
              </span>
            )}
          </div>
          <div className={classes.bankOption} data-testid="routing-input">
            <p className={classes.headerText}>
              Routing<span className={`${classes.asterick}`}>*</span>
            </p>
            <input
              value={addBankObj.routing}
              className={classes.BankInputValue}
              onChange={(e: any) =>
                handleAddBankDetailChange(e.target.value, "routing")
              }
              onBlur={(e: any) =>
                handleAddBankDetailChange(e.target.value, "routing", true)
              }
            />
          </div>
          <div className={classes.bankOption} data-testid="bank-name-input">
            <p className={classes.headerText}>
              Bank Name<span className={`${classes.asterick}`}>*</span>
            </p>
            <input
              value={addBankObj.bankName}
              className={classes.BankInputValue}
              onChange={(e: any) =>
                handleAddBankDetailChange(e.target.value, "bankName")
              }
            />
          </div>
          <div className={classes.bankOption} data-testid="account-name-input">
            <p className={classes.headerText}>
              Account Name<span className={`${classes.asterick}`}>*</span>
            </p>
            <input
              value={addBankObj.accountName}
              className={classes.BankInputValue}
              onChange={(e: any) =>
                handleAddBankDetailChange(e.target.value, "accountName")
              }
            />
          </div>
        </Grid>
      </Grid>
    );
  };

  const isDisabled = () => {
    return !(
      addBankObj.accountNumber &&
      addBankObj.reAccountNumber &&
      addBankObj.routing &&
      addBankObj.bankName &&
      addBankObj.accountName &&
      addBankObj.accountNumber === addBankObj.reAccountNumber
    );
  };

  const updateSelectedBankDetails = () => {
    const tempObj = {
      accountNumber: addBankObj.accountNumber,
      routing: addBankObj.routing,
      bankName: addBankObj.bankName,
      accountName: addBankObj.accountName,
      bankDetailId: addBankObj.bankDetailId
    };
    setSelectedAccountNumber("");
    dispatch(updateSelectedBankObj(tempObj));
  };

  const showErrorMsg = (field: any) => {
    const returnJSX = (
      <div className={classes.errorMgs} data-testid="field-error-text">
        This is a mandatory field.
      </div>
    );
    switch (field) {
      case "accountNumber": {
        if (!bankDetailObj?.accountNumber) return returnJSX;
        else return null;
      }
      case "routing": {
        if (!bankDetailObj?.routing) return returnJSX;
        else return null;
      }
      case "bankName": {
        if (!bankDetailObj?.bankName) return returnJSX;
        else return null;
      }
      case "accountName": {
        if (!bankDetailObj?.accountName) return returnJSX;
        else return null;
      }
    }
  };

  return (
    <Grid item container xs={12}>
      {isBankedited() ? (
        <Grid item container xs={12}>
          <Grid
            item
            container
            xs={8}
            className={classes.fieldRow}
            data-testid="account-list-orig"
          >
            <p
              className={`${classes.headerLoanID} ${
                bankDetailObj?.accountNumber === "" ||
                !(availableAccountIds?.length > 0)
                  ? classes.disabledHeaderText
                  : classes.headerLoanID
              }`}
            >
              Account List
            </p>
            <div>
              <FormControl
                variant="outlined"
                className={classes.accountFormControl}
              >
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    }
                    // getContentAnchorEl: null
                  }}
                  value={selectedAccountNumber}
                  labelId="select-accounts"
                  id="demo-simple-select-outlined"
                  data-testid="select-accounts-orig"
                  className={classes.selectRoot}
                  disabled={
                    selectedLoanId === "" || !(availableAccountIds?.length > 0)
                  }
                  onChange={(e) => handleBankDetailChange(e.target.value)}
                >
                  {availableAccountIds?.length > 0 &&
                    availableAccountIds.map((item: any) => (
                      <MenuItem
                        key={item.accountNumber}
                        value={item.accountNumber}
                        style={{ display: "block" }}
                      >
                        <ListItemText className={classes.accountNumber}>
                          {item.accountNumber}
                        </ListItemText>
                        <ListItemText className={classes.otherInfo}>
                          {item.routing}, {item.bankName}, {item.accountName}
                        </ListItemText>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <span className={classes.addOption}>
                <span className={classes.or}>OR</span>
                <Button
                  variant="outlined"
                  disabled={selectedLoanId === ""}
                  className={classes.addnewbtn}
                  onClick={handleAddNewOpen}
                  data-testid="bank-details-add-new"
                >
                  Add New
                </Button>
              </span>
            </div>
          </Grid>
        </Grid>
      ) : null}
      <Grid item container xs={checkIfBankDetailIdExists() ? 11 : 12}>
        <Grid
          item
          container
          xs={fieldWidth}
          className={classes.fieldRow}
          data-testid="account-number-view"
          style={{ height: isOrigUser() ? "10%" : "25%" }}
        >
          <DrawViewModeTextField
            label={"Account Number"}
            value={
              bankDetailObj?.accountNumber ? bankDetailObj?.accountNumber : ""
            }
            editedData={fieldsHistory.accountNumber}
            isEditMode={showingEditBankDetails()}
          />
          {showingEditBankDetails() ? (
            <input
              value={bankDetailObj?.accountNumber}
              placeholder={"Enter account number"}
              data-testid="toorak-draw-amount"
              className={classes.inputValue}
              onChange={(e: any) =>
                handleEditBankDetails(e.target.value, "accountNumber")
              }
            />
          ) : null}
          {isSubmitClicked ? showErrorMsg("accountNumber") : null}
        </Grid>
        <Grid
          item
          container
          xs={fieldWidth}
          className={classes.fieldRow}
          data-testid="routing-view"
          style={{ height: isOrigUser() ? "10%" : "25%" }}
        >
          <DrawViewModeTextField
            label={"Routing"}
            value={bankDetailObj?.routing}
            editedData={fieldsHistory.routing}
            isEditMode={showingEditBankDetails()}
          />

          {showingEditBankDetails() ? (
            <input
              value={bankDetailObj?.routing}
              placeholder={"Enter routing"}
              data-testid="toorak-draw-amount"
              className={classes.inputValue}
              onChange={(e: any) =>
                handleEditBankDetails(e.target.value, "routing")
              }
              onBlur={(e: any) =>
                handleEditBankDetails(e.target.value, "routing", true)
              }
            />
          ) : null}
          {isSubmitClicked ? showErrorMsg("routing") : null}
        </Grid>
        <Grid
          item
          container
          xs={fieldWidth}
          className={classes.fieldRow}
          data-testid="bank-name-view"
          style={{ height: isOrigUser() ? "90%" : "75%" }}
        >
          <DrawViewModeTextField
            label={"Bank Name"}
            value={bankDetailObj?.bankName}
            editedData={fieldsHistory.bankName}
            isEditMode={showingEditBankDetails() ? true : false}
          />

          {showingEditBankDetails() ? (
            <input
              value={bankDetailObj?.bankName}
              placeholder={"Enter bank name"}
              data-testid="toorak-draw-amount"
              className={classes.inputValue}
              onChange={(e: any) =>
                handleEditBankDetails(e.target.value, "bankName")
              }
            />
          ) : null}
          {isSubmitClicked ? showErrorMsg("bankName") : null}
        </Grid>
        <Grid
          item
          container
          xs={fieldWidth}
          className={classes.fieldRow}
          data-testid="account-name-view"
          style={{ height: isOrigUser() ? "90%" : "75%" }}
        >
          <DrawViewModeTextField
            label={"Account Name"}
            value={bankDetailObj?.accountName}
            editedData={fieldsHistory.accountName}
            isEditMode={showingEditBankDetails()}
          />

          {showingEditBankDetails() ? (
            <input
              value={bankDetailObj?.accountName}
              placeholder={"Enter account name"}
              data-testid="toorak-draw-amount"
              className={classes.inputValue}
              onChange={(e: any) =>
                handleEditBankDetails(e.target.value, "accountName")
              }
            />
          ) : null}
          {isSubmitClicked ? showErrorMsg("accountName") : null}
        </Grid>
        {checkIfBankDetailIdExists() && isDraft ? (
          <Grid
            item
            container
            xs={fieldWidth}
            className={classes.fieldRow}
            data-testid="account-name-view"
            style={{ height: isOrigUser() ? "90%" : "75%", marginTop: "35px" }}
          >
            <IconButton onClick={handleDeleteBank} size="large">
              <img
                className={styles.deleteIcon}
                src={deleteImage}
                alt={"Delete icon"}
              />
            </IconButton>
          </Grid>
        ) : null}
      </Grid>
      {checkIfBankDetailIdExists() && !isDraft ? (
        <Grid item container xs={1} sm={1} md={1} xl={1}>
          <Grid
            item
            container
            xs={fieldWidth}
            className={classes.fieldRow}
            style={{ marginTop: "50%" }}
          >
            <IconButton onClick={handleDeleteBank} size="large">
              <img
                className={styles.deleteIcon}
                src={deleteImage}
                alt={"Delete icon"}
              />
            </IconButton>
          </Grid>
        </Grid>
      ) : null}
      <ConfirmFormModal
        isOpen={openFormModal}
        handleClose={(event: any, reason: string) => {
          if (reason == "backdropClick") return;
          setAddBankObj({
            accountNumber: "",
            reAccountNumber: "",
            routing: "",
            bankName: "",
            accountName: "",
            bankDetailId: ""
          });
          setShowWaringMgs(false);
          setOpenFormModel(false);
        }}
        successHandler={() => {
          updateSelectedBankDetails();
          setOpenFormModel(false);
        }}
        containerStyle={{ overflow: "auto" }}
        header="Add New Bank Account"
        body={bankFormBody()}
        primaryBtnName="Add"
        // disableBackdropClick={true}
        confirmCondition={isDisabled()}
        size="lg"
        headerStyles={{
          padding: 14,
          borderBottom: "solid 1px #dddddd"
        }}
        footerStyles={{
          borderTop: "solid 1px #dddddd",
          padding: "0px 0px 3px 17px",
          margin: "0 0px 14px 0px"
        }}
      />
    </Grid>
  );
};
