/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  Divider,
  Typography
} from "@mui/material";

import { DialogTitle, LoanStage, UserRole } from "@toorak/tc-common-fe-sdk";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";

import { onHoldStyles } from "./Common.style";
import { RootState } from "../../stores/rootReducer";
import { useHoldLandMark } from "./useHoldLandMark";
import { ViewHoldComment } from "./ViewHoldComment";
import {
  onHoldLoan,
  setOnHoldLoan,
  getLoanLandmarks,
  setLoanLandMark,
  // filterDecisionDocs,
  dispatchAndUpdate,
  getExceptionChatID,
  getNameFromPartyId
} from "../create-loan.action";
import {
  showLoader,
  hideLoader
} from "../../stores/loaderAndException/loaderAndException.action";
import { OptionalComment } from "../purchase-and-settlements/components/ConstantComponents";
import { isRole, isOrigUser } from "../../utils/AccessManagement";

enum Action {
  View = "VIEW",
  Remove = "REMOVE",
  History = "HISTORY"
}

interface ViewOnHoldDialogProps {
  action: "VIEW" | "REMOVE" | "HISTORY" | string;
  isOpen: boolean;
  handleClose: (arg0: boolean) => void;
}

export const ViewOnHoldDialog = (props: ViewOnHoldDialogProps) => {
  const { isOpen, handleClose, action } = props;
  const { loanId, loanStage } = useParams<{
    loanId: string;
    loanStage: LoanStage;
  }>() as any;

  const classes = onHoldStyles();

  const dispatch = useDispatch();

  const [actionDialog, setActionDialog] = useState<string>(action);
  const [dialogTitleText, setDialogTitleText] =
    useState<string>("Put Loan On Hold");
  const [comment, setComment] = useState<string>("");
  const [disableBtn, setDisableBtn] = useState(false);

  const isLATTreasurer: boolean = isRole(UserRole.LAT_TREASURER);

  const loanDetails = useSelector<RootState, any>(
    (state) => state.createLoanStore.loanDetails
  );

  const { /* documentDetails, */ loanState } = loanDetails;
  // const ttfVersionId = loanDetails?.loanDetailId?.loanConfigId?.ttfVersionId;
  const loanLandMark: any = useHoldLandMark(true);
  const fullLoanLandMark: any = useHoldLandMark();

  const removeHoldLoan = () => {
    dispatch(showLoader(true));
    const loanToHoldPromise = onHoldLoan(Action.Remove, loanId, comment);
    loanToHoldPromise
      .then((res: any) => {
        if (res) {
          // const filteredDecisionDocs: any[] = filterDecisionDocs(
          //   documentDetails?.files
          // );
          // if (
          //   filteredDecisionDocs.length &&
          //   loanState === LoanStatusEnum.InitialReview &&
          //   window.location.hostname === "www.toorakcapital.info"
          // ) {
          //   dispatch(
          //     dispatchAndUpdate(
          //       loanId,
          //       LoanStatusEnum.DueDiligenceReview,
          //       loanStage
          //     )
          //   );
          // }
          if (
            loanState === LoanStatusEnum.DueDiligenceReview ||
            loanState === LoanStatusEnum.InitialReview
          ) {
            //! if none of the conditions are OPEN and grade is A/B/CLEAR move to final review
            getExceptionChatID(loanId, loanStage).then((res: any) => {
              const { exceptions } = res.data;
              const { grades } = res.data;
              const [hasPassingGrades, hasOpenConditions] =
                finalReviewEligibility(exceptions, grades);
              if (hasPassingGrades && !hasOpenConditions) {
                const removeOnHoldCallBack = dispatchAndUpdate(
                  loanId,
                  LoanStatusEnum.FinalReview,
                  loanStage,
                  dispatch
                );
                removeOnHoldCallBack(dispatch);
              }
            });
          }
          getHoldLandMark();
        }
      })
      .catch((err: any) => {
        dispatch(hideLoader(true));
      });
  };

  const finalReviewEligibility = (exceptions: any[], grades: any[]) => {
    let hasPassingGrades: boolean = false;
    let hasOpenConditions: boolean = false;
    const passingGrades = ["A", "B", "CLEAR"];
    if (grades.length) {
      hasPassingGrades = grades.some(
        (ele: any) =>
          ele.name === "SECURITIZATION_GRADE" &&
          passingGrades.includes(ele.value.toUpperCase())
      );
    }
    if (hasPassingGrades) {
      const openConditions = exceptions.filter(
        (ele) => ele.status.toUpperCase() === "OPEN"
      );
      if (openConditions.length) {
        hasOpenConditions = true;
      }
    }
    return [hasPassingGrades, hasOpenConditions];
  };

  const getHoldLandMark = () => {
    const holdLandMarkPromise = getLoanLandmarks(loanId, loanStage);
    holdLandMarkPromise
      .then((res: any) => {
        if (res) {
          dispatch(setOnHoldLoan(res.data?.onHoldBy));
          dispatch(setLoanLandMark(res.data?.loanDetailId?.loanLandmarks));
          setComment("");
          dispatch(hideLoader(true));
          handleClose(true);
        }
      })
      .catch((err: any) => {
        dispatch(hideLoader(true));
      });
  };

  useEffect(() => {
    if (comment.trim().length > 0) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [comment]);

  useEffect(() => {
    if (actionDialog === Action.Remove) {
      setDialogTitleText("Remove Hold");
    } else if (actionDialog === Action.View) {
      setDialogTitleText("Loan is On Hold");
    } else if (actionDialog === Action.History) {
      setDialogTitleText("Hold Loan History");
    }
  }, [actionDialog]);

  useEffect(() => {
    if (fullLoanLandMark.length) {
      const getPartyIds = () => {
        const partyIdList: any = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < fullLoanLandMark.length; i++) {
          partyIdList.push(fullLoanLandMark[i]?.createdBy);
        }
        if (partyIdList.length > 0) {
          dispatch(getNameFromPartyId(partyIdList, true));
        }
      };
      getPartyIds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullLoanLandMark]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="hold-dialog-title"
      open={isOpen}
      test-dataid="dialog"
      classes={{
        paper: classes.paper,
        paperWidthSm: classes.paperWidthSm
      }}
    >
      <DialogTitle id="hold-dialog-title" onClose={handleClose}>
        {dialogTitleText}
        {actionDialog === Action.View && !isLATTreasurer && (
          <Button
            variant="contained"
            color="primary"
            className={classes.removeBtn}
            onClick={() => {
              setActionDialog(Action.Remove);
            }}
            disabled={loanLandMark?.isSystemUpdate && isOrigUser()}
          >
            Remove Hold
          </Button>
        )}
      </DialogTitle>
      <Divider classes={{ root: classes.dividerRoot }} />
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        {(actionDialog === Action.View || actionDialog === Action.Remove) && (
          <ViewHoldComment commentData={loanLandMark} />
        )}

        {actionDialog === Action.History && (
          <>
            {fullLoanLandMark.map((item: any) => (
              <ViewHoldComment commentData={item} key={item.loanLandmarkId} />
            ))}
          </>
        )}

        {actionDialog === Action.Remove && (
          <>
            <OptionalComment
              comment={comment}
              setComment={setComment}
              label="Add Comment to Remove Hold"
              customStyles={{
                body: {
                  width: "100%",
                  padding: "0px"
                },
                textAreaWrapper: {
                  height: "89px"
                },
                lable: {
                  marginBottom: "8px"
                }
              }}
              required
            />
            <Typography className={classes.noteText}>
              Note: By confirming this you are assuring that this loan will be
              moved back to loan pipeline.
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionsRoot }}>
        {actionDialog === Action.View || actionDialog === Action.History ? (
          <Button
            variant="contained"
            color="info"
            onClick={() => {
              handleClose(true);
            }}
          >
            OK
          </Button>
        ) : (
          <>
            <Button
              variant="contained"
              color="info"
              disabled={disableBtn}
              onClick={() => {
                removeHoldLoan();
              }}
            >
              Confirm
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleClose(true);
              }}
            >
              Cancel
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
