import React, { Suspense } from "react";
import { Loader } from "../create-loan/Loader";

/** Dummy component. To be replaced with design once done. */
export const FallBackLoader = () => {
  return <Loader />;
};

export const SuspenseWithFallBack = (props: { children: any }) => {
  const { children } = props;
  return <Suspense fallback={<FallBackLoader />}>{children}</Suspense>;
};
