import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

export const useBudgetStyles = makeStyles((theme: Theme) =>
  createStyles({
    budgetReviewContainer: {
      height: "100%",
      padding: 10,
      backgroundColor: "#fff"
    },
    calculatedAreaCont: {
      display: "flex",
      paddingRight: "8%",
      paddingTop: 5
    },
    calculatedAreaText: {
      fontWeight: 600,
      paddingRight: "20px"
    },
    dropdownBttn: {
      border: "1px solid lightgray",
      padding: "8px 6px",
      boxShadow: "0px 2px 4px rgb(0 0 0 / 20%)",
      borderRadius: "4px"
    },
    lineItemsContainer: {
      maxHeight: 500,
      overflowY: "scroll",
      borderBottom: "1px solid lightgrey"
    },
    extractionCircular: { marginBottom: 10, color: "#ffffff" },
    extractionHeader: { marginTop: 5, fontSize: 12 },
    extractionText: { marginTop: 25, fontSize: 13 },
    extractionButton: { marginTop: 15, background: "white", color: "black" },
    extractionPending: {
      paddingTop: 100,
      height: 498,
      width: "100%",
      background: "rgba(142, 142, 145, 0.85)",
      color: "#ffffff",
      zIndex: 1,
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      minHeight: 350
    },
    budgetButtons: {
      padding: "2px 10px",
      fontSize: 7,
      [theme.breakpoints.up(1600)]: {
        fontSize: 11
      },
      cursor: "default",
      marginRight: 5,
      pointerEvents: "none",
      background: "#1586f3"
    },
    lineItemTextField: {
      width: "95%",
      border: "1px solid #cad1d7",
      alignSelf: "center",
      position: "relative",
      backgroundColor: "white",
      borderRadius: "4px",
      "& .MuiInputBase-root": {
        fontSize: "12px",
        color: "#32325d"
      },
      "&:hover": {
        border: "none",
        backgroundColor: "#ffffff"
      }
    },
    lineItemTextFieldDisabled: {
      alignSelf: "center",
      position: "relative",
      backgroundColor: "white",
      borderRadius: "4px",
      "& .MuiInputBase-root": {
        fontSize: "12px"
      },
      "&:hover": {
        border: "none",
        backgroundColor: "#ffffff"
      }
    },
    budgetFieldsAreaContainer: {
      borderTop: "solid 1px #e9ecef",
      backgroundColor: "#ffffff",
      height: 560
      // overflowY: "auto",
    },
    budgetAddComment: {
      marginBottom: "0.5rem",
      marginTop: 20,
      paddingLeft: 20
    },
    noLineItemContainer: {
      height: 500,
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center"
    },
    noLineItemMmessage: {
      fontSize: 14,
      fontWeight: "bold"
    },
    pdfViewContainer: {
      backgroundColor: "rgb(230, 242, 255, 0.5)",
      height: 750,
      borderRadius: 4,
      border: "1px solid lightgrey",
      padding: 10
    },
    textAreaDescContainer: {
      width: "85%",
      alignSelf: "center",
      position: "relative",
      backgroundColor: "white",
      borderRadius: "4px",
      border: "1px solid lightgrey !important",
      "& .MuiInputBase-root": {
        fontSize: "12px"
      },
      "&:hover": {
        backgroundColor: "#ffffff"
      },
      height: 75,
      overflowY: "scroll"
    },
    budgetCommentHistory: {
      padding: "0px 20px",
      maxHeight: 250,
      overflowY: "scroll",
      minHeight: 150,
      minWidth: 400
    },
    noComments: {
      display: "flex",
      justifyContent: "center",
      paddingTop: 50
    },
    withComments: {
      color: "red",
      fontSize: "22px",
      marginLeft: "-3px"
    },
    infoIconContainer: {
      marginLeft: "auto"
    },
    budgetHistoryTableHeader: {
      fontWeight: "bold",
      backgroundColor: "rgb(246, 249, 252)",
      borderRight: "1px solid lightgrey",
      width: "20%",
      height: 50
    },
    budgetHistoryTableRow: {
      width: "20%",
      height: 50,
      borderRight: "1px solid lightgrey"
    },
    budgetTextView: {
      justifyContent: "center",
      width: "5%",
      alignSelf: "center",
      color: "#32325d"
    },
    budgetReviewButtons: {
      display: "flex",
      justifyContent: "end",
      alignItems: "center"
    }
    // budgetFieldsAreaContainer:{
    // }
  })
);
