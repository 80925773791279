import { budgetReviewStatusEnum } from "../ilp/list-view/BudgetTaskManagement";
import { getPartyId } from "../stores/WaiverRequest/WaiverRequest.action";

export enum PropTypeOptions {
  unit = "Unit",
  consolidated = "Consolidated"
}

export enum ExtractionProcessState {
  pending = "PREDICTION_PENDING",
  inProgress = "PREDICTION_IN_PROGRESS",
  completed = "PREDICTION_COMPLETED",
  failed = "PREDICTION_FAILED",
  predictionSkippedManualEntry = "PREDICTION_SKIPPED_MANUAL_ENTRY"
}

export const ExtractionInProgressState = [
  ExtractionProcessState.pending,
  ExtractionProcessState.inProgress
];

const { consolidated, unit } = PropTypeOptions;

const { ReadyForBudgetApproval, Approved, Rejected } = budgetReviewStatusEnum;

export const BudgetApproverStatus = [
  ReadyForBudgetApproval,
  Approved,
  Rejected
];

export const isLoginPartyId = (id: string) => {
  const { partyId } = getPartyId();
  return id === partyId;
};

export const BudgetFieldsActionOptions = [
  {
    title: "Add Row"
  },
  {
    title: "Comment"
  },
  {
    title: "Delete Row"
  }
];

export const PropTypeUpdateOption = [
  {
    title: consolidated
  },
  {
    title: unit
  }
];

export const AddRowsOption = [
  {
    title: "Add 20 Rows",
    val: 20
  },
  {
    title: "Add 10 Rows",
    val: 10
  },
  {
    title: "Add 5 Rows",
    val: 5
  }
];
