/* eslint-disable no-else-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-eval */
/* eslint-disable @typescript-eslint/camelcase */

import { BundleStatuses } from "@toorak/tc-common-util-sdk";
import { isLATUser } from "../../utils/AccessManagement";
import { sanitizePercentage } from "../../utils/formatChecks";
import { defaultDateFormat, yyyymmdd } from "../../utils/utils";

export const loanTypeConv: any = {
  Bridge: "Bridge Loan",
  DSCR: "30 Year Loan"
};

// export enum BundleStatuses {
//   LDCP = "Loan Details Confirmation Pending",
//   LDC = "Loan Details Confirmed",
//   // SG = "Settlement Generated",
//   SPAG = "Settlement & PA Generated",
//   SCP = "Settlement Confirmation Pending",
//   SC = "Settlement Confirmed",
//   // SF = "Settlement Finalized",
//   PAS = "PA Signed",
//   PURCHASED = "Purchased",
//   FUNDED = "Funded"
// }

// Alter Status assign if originator
export const maskValIfOrg: any = {
  [BundleStatuses.PURCHASED]: BundleStatuses.FUNDED,
  [BundleStatuses.SPAG]: BundleStatuses.LDC
};

export const genMaskedOrgBundleStatus = (bStatus: BundleStatuses) => {
  return !isLATUser() ? maskValIfOrg[bStatus] || bStatus : bStatus;
};

export enum PAconstants {
  secondaryIden = "PURCHASE_ADVICE_SIGNED",
  secondaryIdenVal = "PURCHASE_ADVICE_SIGNED_DOC_VAL",
  tagKey = "DSPA"
}

export enum formatType {
  currency = "amount",
  percentage = "percentage",
  percentageR2 = "percentageR2",
  pBy100 = "percentagemultiplyBy100",
  pBy100R2 = "percentagemultiplyBy100R2",
  date = "date",
  number = "number",
  boolean = "boolean",
  text = "text",
  render = "render",
  checkBox = "checkBox",
  dropdown = "dropdown",
  excelError = "excelError",
  excelRemove = "excelRemove"
}

export const sanitizeValue = (type: formatType, value: any) => {
  if (value === "Not Permitted" || value === "N/A" || value === "Not Qualified")
    return value;
  if (type === formatType.text) return value;
  if (type === formatType.currency) {
    const valv = Number(String(value).replace(/[$,]+/g, ""));
    const val: any = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD"
    }).format(valv);
    if (val === "$NaN")
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      }).format(0);
    return val;
  }
  if (type === formatType.percentage) {
    return !value ? sanitizePercentage("0") : sanitizePercentage(value);
  }
  if (type === formatType.percentageR2) {
    return !value
      ? sanitizePercentage("0", false, 2)
      : sanitizePercentage(value, false, 2);
  }
  if (type === formatType.pBy100) {
    return !value ? sanitizePercentage("0") : sanitizePercentage(value, true);
  }
  if (type === formatType.pBy100R2) {
    return !value
      ? sanitizePercentage("0", true, 2)
      : sanitizePercentage(value, true, 2);
  }
  if (type === formatType.date) {
    const retVal: any = defaultDateFormat(value);
    if (retVal.toString() === "Invalid date") {
      return "-";
    }
    return retVal;
  }
  if (type === formatType.number) {
    return !value ? "0" : String(value);
  }
  if (type === formatType.boolean && value !== "―") {
    return eval(value) ? "Yes" : "No";
  }
  return value;
};

export const reSanitizeData = (value: any, type: formatType) => {
  if (type === formatType.currency)
    return value
      .replace("$", "")
      .replace(/,/g, "")
      .replace(/-+/g, "-");
  if (type === formatType.percentage || type === formatType.percentageR2)
    return value.replace("%", "");
  if (type === formatType.pBy100 || type === formatType.pBy100R2)
    return String(Number(value.replace("%", "").replace(/,/g, "")) / 100);
  if (type === formatType.date) return yyyymmdd(value);
  if (type === formatType.number) {
    return isNaN(value) ? "" : value;
  }
  return value;
};

export const staticBundleStatus = (isLAT: boolean) => {
  const originator: any = {
    [BundleStatuses.LDCP]: {
      checked: true
    },
    [BundleStatuses.LDC]: {
      checked: true
    },
    [BundleStatuses.SPAG]: {
      checked: true
    },
    [BundleStatuses.SCP]: {
      checked: true
    },
    [BundleStatuses.SC]: {
      checked: true
    },
    [BundleStatuses.PASP]: {
      checked: true
    },
    [BundleStatuses.PAS]: {
      checked: true
    },
    [BundleStatuses.FUNDED]: {
      checked: false
    }
  };
  const LAT: any = {
    [BundleStatuses.LDCP]: {
      checked: true
    },
    [BundleStatuses.LDC]: {
      checked: true
    },
    [BundleStatuses.SPAG]: {
      checked: true
    },
    [BundleStatuses.SCP]: {
      checked: true
    },
    [BundleStatuses.SC]: {
      checked: true
    },
    [BundleStatuses.PASP]: {
      checked: true
    },
    [BundleStatuses.PAS]: {
      checked: true
    },
    [BundleStatuses.PURCHASED]: {
      checked: false
    }
  };
  return isLAT ? LAT : originator;
};

export const staticBundleStatusList = [
  BundleStatuses.LDCP,
  BundleStatuses.LDC,
  BundleStatuses.SPAG,
  BundleStatuses.SCP,
  BundleStatuses.SC,
  BundleStatuses.PASP,
  BundleStatuses.PAS,
  BundleStatuses.PURCHASED,
  BundleStatuses.FUNDED
];

export interface EditCellProps {
  onMouseEnter?: (...args: any) => any;
  onMouseLeave?: (...args: any) => any;
}
export interface ColumnObjProps {
  title: string;
  accessor: string;
  type: formatType;
  align?: string;
  stickyCell?: boolean;
  renderH?: tbCompTypes;
  renderV?: tbCompTypes;
  width: number;
  nonRstyles?: any;
  nonRHstyles?: any;
  hide?: boolean;
  renderVProps?: any;
  renderHProps?: any;
  auditInfoCB?: Function;
  editCellProps?: EditCellProps;
}
export const defaultBundleStatusItems: any = [
  {
    value: "selectAll",
    label: "Select All",
    checked: false
  },
  {
    value: BundleStatuses.LDCP,
    label: BundleStatuses.LDCP,
    checked: true
    // checkBoxDataTestId: "bridge-fltr-chx"
  },
  {
    value: BundleStatuses.LDC,
    label: BundleStatuses.LDC,
    checked: true
    // checkBoxDataTestId: "dscr-fltr-chx"
  }
];

export const defaultBundleStatusItemsLAT: any = [
  ...defaultBundleStatusItems,
  {
    value: BundleStatuses.SPAG,
    label: BundleStatuses.SPAG,
    checked: true
    // checkBoxDataTestId: "dscr-fltr-chx"
  },
  {
    value: BundleStatuses.SCP,
    label: BundleStatuses.SCP,
    checked: true
    // checkBoxDataTestId: "dscr-fltr-chx"
  },
  {
    value: BundleStatuses.SC,
    label: BundleStatuses.SC,
    checked: true
  },
  {
    value: BundleStatuses.PASP,
    label: BundleStatuses.PASP,
    checked: true
    // checkBoxDataTestId: "dscr-fltr-chx"
  },
  {
    value: BundleStatuses.PAS,
    label: BundleStatuses.PAS,
    checked: true
    // checkBoxDataTestId: "dscr-fltr-chx"
  },
  {
    value: BundleStatuses.PURCHASED,
    label: BundleStatuses.PURCHASED,
    checked: false
    // checkBoxDataTestId: "bridge-fltr-chx"
  }
];

export const defaultBundleStatusItemsORG: any = [
  ...defaultBundleStatusItems,
  // {
  //   value: BundleStatuses.SPAG,
  //   label: BundleStatuses.SPAG,
  //   checked: true
  //   // checkBoxDataTestId: "dscr-fltr-chx"
  // },
  {
    value: BundleStatuses.SCP,
    label: BundleStatuses.SCP,
    checked: true
    // checkBoxDataTestId: "dscr-fltr-chx"
  },
  {
    value: BundleStatuses.SC,
    label: BundleStatuses.SC,
    checked: true
  },
  {
    value: BundleStatuses.PASP,
    label: BundleStatuses.PASP,
    checked: true
    // checkBoxDataTestId: "dscr-fltr-chx"
  },
  {
    value: BundleStatuses.PAS,
    label: BundleStatuses.PAS,
    checked: true
    // checkBoxDataTestId: "dscr-fltr-chx"
  },
  {
    value: BundleStatuses.PURCHASED,
    label: BundleStatuses.FUNDED,
    checked: false
    // checkBoxDataTestId: "bridge-fltr-chx"
  }
];

export const initialConfirmTableInfo: any = {
  "Toorak Loan ID": {
    key: "loan?.loanDetailId?.loanId",
    type: formatType.text
  },
  "Originator Loan ID": {
    key: "loan?.loanInfo?.primaryLoanId",
    type: formatType.text
  },
  "Loan Structure": {
    key: "loan?.loanInfo?.loanStructure",
    type: formatType.text
  },
  "Draw Details": {
    key: null
  },
  Address: {
    key: "properties",
    innerValuePaths: [
      "propertyLocation?.addressLine1",
      "propertyLocation?.city",
      "propertyLocation?.state",
      "propertyLocation?.postalCode"
    ],
    type: "lengthToolTip",
    valueType: formatType.text,
    style: {
      text: {
        width: "200px"
      }
    }
  },
  "Initial Loan Amount": {
    key: "loan?.loanEconomics?.originalLoanAmount",
    type: formatType.currency
  },
  "COD Loan Amount": {
    key: "loan?.loanEconomics?.cutOffDateLoanAmount",
    type: formatType.currency
  },
  "COD Max Loan Amount": {
    key: "loan?.loanEconomics?.cutOffDateMaximumLoanAmount",
    type: formatType.currency
  },
  "Toorak Lending Amount": {
    key: "customFields?.lending$",
    type: formatType.currency
  }, // Lending amount
  "Total Budget ($)": {
    key: "loan?.loanEconomics?.totalBudgetAmount",
    type: formatType.currency
  },
  "As-Is $": {
    key: "properties",
    innerValuePaths: ["propertyEconomics?.originalAsIsAppraisalValue"],
    type: "lengthToolTip",
    valueType: formatType.currency,
    style: {
      text: {
        width: "150px"
      }
    }
  },
  "ARV $": {
    key: "properties",
    innerValuePaths: ["propertyEconomics?.originalAsRepairedAppraisedValue"],
    type: "lengthToolTip",
    valueType: formatType.currency,
    style: {
      text: {
        width: "150px"
      }
    }
  },
  "First Loss (%)": {
    key: "result?.loanResult?.firstLoss?.firstLossPercentage",
    type: formatType.pBy100
  },
  Experience: {
    key: "borrowerExperience",
    type: formatType.text
  },
  "Toorak Program": {
    key: "loan?.loanInfo?.toorakProduct",
    type: formatType.text
  },
  "Purchase Price (%)": {
    key: "result?.loanResult?.toorakInterests?.pricing",
    type: formatType.pBy100
  },
  "Purchase Price Discount": {
    key: "result?.loanResult?.toorakInterests?.pricingDiscount",
    type: formatType.currency
  },
  "Total Credit Reserve Holdback %": {
    key: "result.loanResult.firstLoss.firstLossPercentage",
    type: formatType.pBy100
  },
  "Total Credit Reserve Holdback Amount": {
    key: "result.loanResult.firstLoss.totalCreditReserveHoldback",
    type: formatType.currency
  }
};

export const initialConfirmTableInfo30Yr: any = {
  "Toorak Loan ID": {
    key: "loan?.loanDetailId?.loanId",
    type: formatType.text
  },
  "Originator Loan ID": {
    key: "loan?.loanInfo?.primaryLoanId",
    type: formatType.text
  },
  "Toorak Price": {
    key: "result.loanResult.loanPricing.finalToorakPrice",
    type: formatType.percentage
  },
  FPD: {
    key: "loan.loanInfo.firstPaymentDateOfLoan",
    type: formatType.date
  },
  "Toorak Product": {
    key: "loan?.loanInfo?.toorakProduct",
    type: formatType.text
  },
  Address: {
    key: "properties",
    innerValuePaths: [
      "propertyLocation?.addressLine1",
      "propertyLocation?.city",
      "propertyLocation?.state",
      "propertyLocation?.postalCode"
    ],
    type: "lengthToolTip",
    valueType: formatType.text,
    style: {
      text: {
        width: "200px"
      }
    }
  },
  "Loan Purpose": { key: "loan?.loanInfo?.loanPurpose", type: formatType.text },
  "Interest Rate": {
    key: "loan?.loanEconomics?.interestRateAsOfCutOffDate",
    type: formatType.percentage
  },
  Prepayment: {
    key: "result.loanResult.loanEconomics.prePaymentPenaltyMonths",
    type: formatType.text
  },
  "IO Period": {
    key: "loan.loanEconomics.interestOnlyPeriod",
    type: formatType.text
  },
  DSCR: {
    // key: "loan.loanEconomics.debtServiceCoverageRatio",
    key: "result.loanResult.loanEconomics.dscr",
    type: formatType.text
  },
  "As-Is LTV": {
    // key: "loanBundleDetails?.originalAsIsLtv",
    key: "result.loanResult?.loanFeatures?.originalAsIsLtv",
    type: formatType.pBy100R2,
    style: {
      text: {
        width: "100px"
      }
    }
  },
  "Original LTC/LTP": {
    // key: "loanBundleDetails.originalLtc",
    key: "result.loanResult?.loanFeatures?.originalLtc",
    type: formatType.pBy100R2,
    style: {
      text: {
        width: "100px"
      }
    }
  },
  "Initial Loan Amount": {
    key: "loan?.loanEconomics?.originalLoanAmount",
    type: formatType.currency
  },
  "Current Loan Amount": {
    key: "loan.loanEconomics.currentLoanBalance",
    type: formatType.currency
  },
  "As-Is Value": {
    key: "properties",
    innerValuePaths: ["propertyEconomics?.originalAsIsAppraisalValue"],
    type: "lengthToolTip",
    valueType: formatType.currency,
    style: {
      text: {
        width: "150px"
      }
    }
  }
};

export const drawAvailConfirmTableInfo = {
  "Toorak Loan ID": {
    key: "loan.loanDetailId.loanId",
    type: formatType.text
  },
  "Originator Loan ID": {
    key: "loan.loanInfo.primaryLoanId"
  },
  "Loan Structure": {
    key: "loan.loanInfo.loanStructure",
    type: formatType.text
  },
  "Draw Available?": {
    key: null
  },
  "Draw Date": {
    key: null
  },
  "Draw Amount": {
    key: null
  },
  Documents: {
    key: null
  },
  Address: {
    key: "properties",
    innerValuePaths: [
      "propertyLocation?.addressLine1",
      "propertyLocation?.city",
      "propertyLocation?.state",
      "propertyLocation?.postalCode"
    ],
    type: "lengthToolTip",
    valueType: formatType.text,
    style: {
      text: {
        width: "200px"
      }
    }
  },
  "Initial Loan Amount": {
    key: "loan?.loanEconomics?.originalLoanAmount",
    type: formatType.currency
  }, // doubt
  "COD Loan Amount": {
    key: "loan.loanEconomics.cutOffDateLoanAmount",
    // key: null,
    type: formatType.currency
  },
  "COD Max Loan Amount": {
    key: "loan.loanEconomics.cutOffDateMaximumLoanAmount",
    type: formatType.currency
  },
  "Toorak Lending Amount": {
    key: "customFields?.lending$",
    type: formatType.currency
  },
  "Total Budget ($)": {
    key: "loan.loanEconomics.totalBudgetAmount",
    type: formatType.currency
  },
  "As-Is $": {
    key: "properties",
    innerValuePaths: ["propertyEconomics?.originalAsIsAppraisalValue"],
    type: "lengthToolTip",
    valueType: formatType.currency,
    style: {
      text: {
        width: "150px"
      }
    }
  },
  "ARV $": {
    key: "properties",
    innerValuePaths: ["propertyEconomics?.originalAsRepairedAppraisedValue"],
    type: "lengthToolTip",
    valueType: formatType.currency,
    style: {
      text: {
        width: "150px"
      }
    }
  },
  "First Loss (%)": {
    key: "result.loanResult.firstLoss.firstLossPercentage",
    type: formatType.pBy100
  },
  Experience: {
    key: "borrowerExperience"
  },
  "Toorak Program": {
    key: "loan.loanInfo.toorakProduct"
  },
  "Purchase Price (%)": {
    key: "result?.loanResult?.toorakInterests?.pricing",
    type: formatType.pBy100
  },
  "Purchase Price Discount": {
    key: "result?.loanResult?.toorakInterests?.pricingDiscount",
    type: formatType.currency
  },
  "Total Credit Reserve Holdback %": {
    key: "result.loanResult.firstLoss.firstLossPercentage",
    type: formatType.pBy100
  },
  "Total Credit Reserve Holdback Amount": {
    key: "result.loanResult.firstLoss.totalCreditReserveHoldback",
    type: formatType.currency
  }
};

export const warehouseLenderConfirmTableInfo = {
  "New Originator Wire Counterparty": {
    key: "loan.loanDetailId.loanId"
  },
  Loans: {
    key: "loan.loanInfo.loanStructure"
  }
};

export enum valFilterTypes {
  noNegative = "NO_NEGATIVE"
}

export const TableInfoObj: any = {
  "Toorak Loan ID": {
    key: "loan?.loanDetailId?.loanId",
    type: formatType.text,
    fieldName: "loanId"
  },
  "Current Loan Balance": {
    key: "loan?.loanEconomics?.currentLoanBalance",
    type: formatType.currency,
    fieldName: "currentLoanBalance"
  },
  "Original Interest Rate": {
    key: "loanBundleDetails.originalInterestRate",
    type: formatType.percentage,
    fieldName: "originalInterestRate"
  },
  "Toorak Price": {
    key: "result.loanResult.loanPricing.finalToorakPrice",
    type: formatType.percentage,
    fieldName: "finalToorakPrice"
  },
  FPD: {
    key: "loan.loanInfo.firstPaymentDateOfLoan",
    type: formatType.date,
    fieldName: "firstPaymentDateOfLoan"
  },
  Prepayment: {
    key: "result.loanResult.loanEconomics.prePaymentPenaltyMonths",
    type: formatType.text,
    fieldName: "prePaymentPenaltyMonths"
  },
  "IO Period": {
    key: "loan.loanEconomics.interestOnlyPeriod",
    type: formatType.text,
    fieldName: "interestOnlyPeriod"
  },
  "As-Is LTV": {
    key: "result.loanResult?.loanFeatures?.originalAsIsLtv",
    type: formatType.pBy100R2,
    fieldName: "originalAsIsLtv"
  },
  "Original LTC/LTP": {
    key: "result.loanResult?.loanFeatures?.originalLtc",
    type: formatType.pBy100R2,
    fieldName: "originalLtc"
  },
  "As-Is Value": {
    key: "properties",
    innerValuePaths: ["propertyEconomics?.originalAsIsAppraisalValue"],
    type: "lengthToolTip",
    valueType: formatType.currency,
    fieldName: "properties"
  },
  DSCR: {
    key: "result.loanResult.loanEconomics.dscr",
    type: formatType.text,
    fieldName: "dscr"
  },
  "Interest Rate (As of Cut off Date)": {
    key: "loan?.loanEconomics?.interestRateAsOfCutOffDate",
    type: formatType.percentage,
    fieldName: "interestRateAsOfCutOffDate"
  },
  "Interest Rate": {
    key: "loan?.loanEconomics?.interestRateAsOfCutOffDate",
    type: formatType.percentage,
    fieldName: "interestRateAsOfCutOffDate"
  },
  Address: {
    key: "properties",
    innerValuePaths: ["addressLine1", "city", "state", "postalCode"],
    type: "lengthToolTip",
    valueType: formatType.text,
    fieldName: "propertiesAddrList"
  },
  "Borrowing Entity Name": {
    key: "customFields?.borrowerEntityName",
    type: formatType.text,
    fieldName: "borrowerName"
  },
  "Financed Interest Reserve ($)": {
    key: "loan?.loanEconomics?.financedInterestReserve",
    type: formatType.currency,
    fieldName: "financedInterestReserve",
    isEditable: true
  },
  "First Payment Date": {
    key: "loan?.loanInfo?.firstPaymentDateOfLoan",
    type: formatType.date,
    sortable: true,
    fieldName: "firstPaymentDateOfLoan"
  },
  "Loan Purpose": {
    key: "loan?.loanInfo?.loanPurpose",
    type: formatType.text,
    fieldName: "loanPurpose"
  },
  'Original "As Is" Appraisal Value': {
    key: "properties",
    innerValuePaths: ["propertyEconomics?.originalAsIsAppraisalValue"],
    type: "lengthToolTip",
    valueType: formatType.currency,
    fieldName: "properties"
  },
  "Original Credit Score (Median)": {
    key: "result?.settlementResults?.originalCreditScoreMedian",
    type: formatType.text,
    fieldName: "originalCreditScoreMedian"
  },
  "Original Credit Score": {
    key: "result.settlementResults.originalCreditScoreMedian",
    type: formatType.text,
    fieldName: "originalCreditScoreMedian"
  },
  "Initial Loan Amount": {
    key: "loan?.loanEconomics?.originalLoanAmount",
    type: formatType.currency,
    fieldName: "originalLoanAmount"
  },
  "Current Loan Amount": {
    key: "loan.loanEconomics.currentLoanBalance",
    type: formatType.currency,
    fieldName: "currentLoanBalance"
  },
  "Origination Date": {
    key: "loan.loanInfo.originationDate",
    type: formatType.date,
    fieldName: "originationDate"
  },
  "Originator Loan ID": {
    key: "loan?.loanInfo?.primaryLoanId",
    type: formatType.text,
    sortable: true,
    filter: true,
    filterType: "AutoCompleteSearchBoxFilter",
    fieldName: "primaryLoanId"
  },
  "Toorak Product": {
    key: "loan?.loanInfo?.toorakProduct",
    type: formatType.text,
    fieldName: "toorakProduct"
  },
  "Total Origination and Discount Points": {
    key: "loan?.loanEconomics?.totalOriginationAndDiscountPoints",
    type: formatType.percentage,
    fieldName: "totalOriginationAndDiscountPoints"
  },
  "Additional Guarantor Name": {
    key: "customFields?.additionalGuarantor",
    type: formatType.text,
    fieldName: "additionalGuarantor"
  },
  "Borrower Experience": {
    key: "customFields?.borrowerExperience",
    type: formatType.number,
    fieldName: "borrowerExperience"
  },
  "Cut off Date Loan Amount": {
    key: "loan?.loanEconomics?.cutOffDateLoanAmount",
    type: formatType.currency,
    sortable: true,
    fieldName: "cutOffDateLoanAmount"
  },
  "Cut off Date Maximum Loan Amount": {
    key: "loan?.loanEconomics?.cutOffDateMaximumLoanAmount",
    type: formatType.currency,
    sortable: true,
    fieldName: "cutOffDateMaximumLoanAmount"
  },
  "Total Budget Amount": {
    key: "loan?.loanEconomics?.totalBudgetAmount",
    type: formatType.currency,
    sortable: true,
    fieldName: "totalBudgetAmount"
  },
  "Guarantor 1 First Name": {
    key: "customFields?.guarantor1FirstName",
    type: formatType.text,
    fieldName: "guarantor1FirstName"
  },
  "Guarantor 1 Last Name": {
    key: "customFields?.guarantor1LastName",
    type: formatType.text,
    fieldName: "guarantor1LastName"
  },
  "Loan Structure": {
    key: "loan?.loanInfo?.loanStructure",
    type: formatType.text,
    sortable: true,
    fieldName: "loanStructure"
  },
  "As Repaired Value (ARV)": {
    key: "properties",
    innerValuePaths: ["propertyEconomics?.originalAsRepairedAppraisedValue"],
    type: "lengthToolTip",
    valueType: formatType.currency,
    fieldName: "properties"
  },
  "Toorak Lending Amount": {
    key: "customFields?.lending$",
    type: formatType.currency,
    fieldName: "toorakLendingAmount"
  },
  State: {
    key: "properties[0]?.propertyLocation?.state",
    type: formatType.text,
    fieldName: "state"
  },
  "Loan Type": {
    key: "loan?.loanTypeId?.loanTypeDescription",
    type: formatType.text,
    fieldName: "loanType"
  },
  Seller: {
    key: "loanBundleDetails?.sellerPartyId",
    valType: "partyId",
    type: formatType.dropdown,
    isEditable: true,
    fieldName: "sellerPartyId"
  },
  "Current Interest Rate": {
    key: "loanBundleDetails?.interestRateAsOfCutOffDate",
    type: formatType.percentage,
    fieldName: "interestRateAsOfCutOffDate"
  },
  "Borrower Name": {
    key: "customFields?.borrowerEntityName",
    type: formatType.text,
    fieldName: "borrowerName"
  },
  "Current Principal Balance": {
    key: "calculatedFields?.settlementResults?.currentPrincipalBalance",
    type: formatType.currency,
    fieldName: "currentPrincipalBalance"
  },
  "Less Purchaser Cost Allocation": {
    key: "loanBundleDetails?.lessPurchaserCostAllocation",
    type: formatType.currency,
    isEditable: true,
    fieldName: "lessPurchaserCostAllocation"
  },
  "Plus Accrued and Unpaid Interest": {
    key: "calculatedFields?.settlementResults?.plusAccruedAndUnPaidInterest",
    type: formatType.currency,
    isEditable: true,
    fieldName: "plusAccruedAndUnPaidInterest"
  },
  "Credit Reserve Holdback": {
    key: "calculatedFields?.settlementResults?.creditReserveHoldback",
    type: formatType.currency,
    fieldName: "creditReserveHoldback"
  },
  "Closing Date Payment": {
    key: "calculatedFields?.settlementResults?.closingDatePayment",
    type: formatType.currency,
    isEditable: true,
    fieldName: "closingDatePayment"
  },
  "Closing Date": {
    key: "bundle?.closingDate",
    type: formatType.date,
    fieldName: "closingDate"
  },
  "Funded Loan Amount": {
    key: "calculatedFields?.settlementResults?.fundedLoanAmount",
    type: formatType.currency,
    isEditable: true,
    fieldName: "fundedLoanAmount"
  },
  "Servicing Fee (per month)": {
    key: "calculatedFields?.settlementResults?.finalServicingFee",
    type: formatType.currency,
    isEditable: true,
    fieldName: "finalServicingFee"
  },
  "Servicing Fee (per month) ": {
    key: "loanBundleDetails?.servicingFeePerMonth",
    type: formatType.currency,
    isEditable: true,
    fieldName: "servicingFeePerMonth"
  },
  "Seller Retained Interest-Only Strip": {
    key:
      "calculatedFields?.settlementResults?.sellerRetainedInterest_onlyStrip",
    type: formatType.percentage,
    isEditable: true,
    fieldName: "sellerRetainedInterest_onlyStrip"
  },
  "Interest Accrual Date": {
    key: "result?.loanResult?.settlementResults?.accrualDate",
    type: formatType.date,
    isEditable: true,
    fieldName: "accrualDate"
  },
  "Accrued Interest Days": {
    key: "calculatedFields?.settlementResults?.accruedInterestDays",
    type: formatType.number,
    isEditable: true,
    fieldName: "accruedInterestDays"
  },
  Custodian: {
    key: "Custodian",
    value: "US Bank",
    type: formatType.text,
    fieldName: "Custodian"
  },
  Servicer: {
    key: "loanBundleDetails?.servicerPartyId",
    valType: "partyId",
    type: formatType.dropdown,
    isEditable: true,
    fieldName: "servicerPartyId"
  },
  Pricing: {
    key: "loanBundleDetails?.pricing",
    type: formatType.pBy100,
    fieldName: "pricing"
  },
  // For DSCR
  "Pricing ": {
    key: "loanBundleDetails?.finalToorakPrice",
    type: formatType.percentage,
    isEditable: true,
    fieldName: "finalToorakPrice"
  },
  "Toorak Yield": {
    key: "loanBundleDetails?.toorakYield",
    type: formatType.pBy100,
    isEditable: true,
    fieldName: "toorakYield"
  },
  "Maximum Principal Balance": {
    key: "calculatedFields?.settlementResults?.maximumPrincipalBalance",
    type: formatType.currency,
    isEditable: true,
    fieldName: "maximumPrincipalBalance"
  },
  "Dry Credit Reserve Holdback": {
    key: "calculatedFields?.settlementResults?.dryCreditReserveHoldback",
    type: formatType.currency,
    isEditable: true,
    fieldName: "dryCreditReserveHoldback"
  },
  Remic: {
    key: "calculatedFields?.settlementResults?.remic",
    type: formatType.text,
    isEditable: true,
    fieldName: "remic"
  },
  "Tax Escrow (Wire to Servicer)": {
    key: "calculatedFields?.settlementResults?.taxEscrowWireToServicer",
    type: formatType.currency,
    fieldName: "taxEscrowWireToServicer",
    isEditable: true
  },
  "Insurance Escrow (Wire to Servicer)": {
    key: "calculatedFields?.settlementResults?.insuranceEscrowWireToServicer",
    type: formatType.currency,
    fieldName: "insuranceEscrowWireToServicer",
    isEditable: true
  },
  "Next Payment Due Date": {
    key: "result?.loanResult?.settlementResults?.nextPaymentDueDate",
    type: formatType.date,
    isEditable: true,
    fieldName: "nextPaymentDueDate"
  },
  "Misc Fees": {
    key: "loanBundleDetails?.miscFees",
    type: formatType.currency,
    default: "0",
    isEditable: true,
    fieldName: "miscFees"
  },
  "New Originator Wire Counterparty": {
    type: formatType.dropdown,
    key: "loanBundleDetails?.warehouseLenderPartyId",
    valType: "partyId",
    isEditable: true,
    fieldName: "warehouseLenderPartyId"
  },
  Escrow: {
    key: "calculatedFields?.settlementResults?.escrow_wireToCohen",
    type: formatType.currency,
    isEditable: true,
    fieldName: "escrow_wireToCohen"
  },
  "Interest Reserve and others (Wire to Servicer)": {
    key:
      "calculatedFields?.settlementResults?.interestReservesAndOthersWireToServicer",
    type: formatType.currency,
    fieldName: "interestReservesAndOthersWireToServicer",
    isEditable: true
  },
  'Original "As Is" LTV': {
    key: "loanBundleDetails?.originalAsIsLtv",
    type: formatType.pBy100R2,
    fieldName: "originalAsIsLtv"
  },
  "Additional Credit Reserve Holdback ($)": {
    key: "loanBundleDetails?.additionalCreditReserveHoldback",
    type: formatType.currency,
    isEditable: true,
    fieldName: "additionalCreditReserveHoldback"
  },
  "Regular Credit Reserve Holdback (%)": {
    key: "loanBundleDetails?.regularCreditReserveHoldback",
    type: formatType.pBy100,
    isEditable: true,
    fieldName: "regularCreditReserveHoldback",
    valFilter: valFilterTypes.noNegative
  },
  "Max Loan Proceeds Before First Loss": {
    key: "loanBundleDetails?.maxLoanProceedsBeforeFirstLoss",
    type: formatType.currency,
    fieldName: "maxLoanProceedsBeforeFirstLoss"
  },
  "Final Toorak Yield": {
    key: "calculatedFields?.settlementResults?.finalToorakYield",
    type: formatType.pBy100,
    isEditable: true,
    fieldName: "finalToorakYield"
  },
  "Final Toorak Yield-SFR": {
    key: "calculatedFields?.settlementResults?.finalToorakYield_SFR",
    type: formatType.pBy100,
    isEditable: true,
    fieldName: "finalToorakYield_SFR"
  },
  "Purchase Price": {
    key: "calculatedFields?.settlementResults?.purchasePrice",
    type: formatType.currency,
    isEditable: true,
    fieldName: "purchasePrice"
  },
  "Property Purchase Price": {
    key: "customFields?.propertyPurchasePrice",
    type: formatType.currency,
    fieldName: "propertyPurchasePrice"
  },
  "Borrower Experience Tier": {
    key: "calculatedFields?.settlementResults?.borrowerExperienceLevel",
    type: formatType.number,
    fieldName: "borrowerExperienceLevel"
  },
  "Regular Toorak Yield": {
    key: "loanBundleDetails?.regularToorakYield",
    type: formatType.pBy100,
    isEditable: true,
    fieldName: "regularToorakYield"
  },
  "First Loss": {
    key: "result?.loanResult?.firstLoss?.firstLossPercentage",
    type: formatType.pBy100,
    sortable: true,
    default: "0",
    fieldName: "firstLossPercentage"
  },
  "Loan Stage": {
    key: "loan?.loanStage",
    type: formatType.text,
    fieldName: "loanStage"
  },
  "Loan Characterisation": {
    key: "result?.loanResult?.loanCharacterisations?.loanCharacterisation",
    type: formatType.text,
    fieldName: "loanCharacterisation"
  },
  "Created By": {
    key: "loan?.loanCreatedUser?.firstName",
    type: formatType.text,
    fieldName: "firstName"
  },
  "Latest Draw Date": {
    key: "customFields?.latestDrawDate",
    type: formatType.date,
    default: "-",
    condition: "pastOrCurrent",
    fieldName: "latestDrawDate"
  },
  "Total Draw Amount": {
    key: "customFields?.totalDrawAmount",
    type: formatType.currency,
    default: "0",
    fieldName: "totalDrawAmount"
  },
  "Number Of Draws": {
    key: "loanBundleDetails?.drawDetails?.length",
    type: formatType.number,
    default: "0",
    fieldName: "noOfDraws"
  },
  "Purchase Premium": {
    key: "result?.settlementResults?.purchasePremium",
    type: formatType.currency,
    isEditable: true,
    fieldName: "purchasePremium"
  },
  "Current Remaining Tax Escrow Balance": {
    key: "loan.loanDetailId?.loanSummaryId?.updatedTaxEscrowBalance",
    type: formatType.currency,
    fieldName: "updatedTaxEscrowBalance"
  },
  "Current Remaining Insurance Escrow Balance": {
    key: "loan.loanDetailId?.loanSummaryId?.updatedInsuranceEscrowBalance",
    type: formatType.currency,
    fieldName: "updatedInsuranceEscrowBalance"
  },
  "Taxes: total balance collected at closing": {
    key: "loan?.loanDetailId?.loanSummaryId?.escrowTaxes",
    type: formatType.currency,
    fieldName: "escrowTaxes"
  },
  "Insurance: total balance collected at closing": {
    key: "loan?.loanDetailId?.loanSummaryId?.escrowInsurance",
    type: formatType.currency,
    fieldName: "escrowInsurance"
  },
  "Securitization Grade": {
    key: "customFields?.grade",
    type: formatType.text,
    fieldName: "grade"
  },
  "Standard First Loss": {
    key: "result?.loanResult?.firstLoss?.standardFirstLoss",
    type: formatType.pBy100,
    fieldName: "standardFirstLoss"
  },
  "Standard First Loss Adjustment": {
    key: "loanBundleDetails?.standardFirstLossAdjustment",
    type: formatType.pBy100,
    isEditable: true,
    fieldName: "standardFirstLossAdjustment"
  },
  "Location Adjustment(TX)": {
    key: "loanBundleDetails?.locationAdjustment",
    type: formatType.pBy100,
    isEditable: true,
    fieldName: "locationAdjustment"
  },
  "Additional Seller Retained Interest": {
    key: "loanBundleDetails?.additionalSellerRetainedInterest",
    type: formatType.currency,
    isEditable: true,
    fieldName: "additionalSellerRetainedInterest"
  },
  "Table Fund Fee (%)": {
    key: "result?.settlementResults?.tableFundingFeePercent",
    type: formatType.pBy100,
    isEditable: true,
    fieldName: "tableFundingFeePercent"
  },
  "Table Fund Fee ($)": {
    key: "result?.settlementResults?.tableFundingFee",
    type: formatType.currency,
    isEditable: true,
    fieldName: "tableFundingFee"
  },
  "Wire to Escrow Agent": {
    key: "result?.settlementResults?.wireDueToEscrowAgent",
    type: formatType.currency,
    isEditable: true,
    fieldName: "wireDueToEscrowAgent"
  },
  "Wire to Originator": {
    key: "result?.settlementResults?.dueToOrFromOriginator",
    type: formatType.currency,
    isEditable: true,
    fieldName: "dueToOrFromOriginator"
  },
  "Net Wire Due to Acquisition Acct/Seller": {
    key: "result?.settlementResults?.netWireDueToAccOrSeller",
    type: formatType.currency,
    isEditable: true,
    fieldName: "netWireDueToAccOrSeller"
  },
  "Purchase Price Discount": {
    key: "result?.loanResult?.toorakInterests?.pricingDiscount",
    type: formatType.currency,
    fieldName: "pricingDiscount"
  },
  "Purchase Price (%)": {
    key: "result?.loanResult?.toorakInterests?.pricing",
    type: formatType.pBy100,
    fieldName: "pricing"
  },
  "Total Credit Reserve Holdback %": {
    key: "result.loanResult.firstLoss.firstLossPercentage",
    type: formatType.pBy100,
    fieldName: "firstLossPercentage"
  },
  "Total Credit Reserve Holdback Amount": {
    key: "result.loanResult.firstLoss.totalCreditReserveHoldback",
    type: formatType.currency,
    fieldName: "totalCreditReserveHoldback"
  }
};

export const BridgeLoanLATColumns: any = [
  "Originator Loan ID",
  "Address",
  // "Borrowing Entity Name",
  "Borrower Name",
  "Cut off Date Loan Amount",
  "Cut off Date Maximum Loan Amount",
  "Toorak Lending Amount",
  "Total Budget Amount",
  'Original "As Is" Appraisal Value',
  // "Original \"As Repaired\" Appraised Value",
  "As Repaired Value (ARV)",
  "First Loss",
  "First Payment Date",
  "Borrower Experience",
  "Loan Structure",
  "Loan Type",
  "Toorak Yield",
  "Purchase Price (%)",
  "Purchase Price Discount",
  "Total Credit Reserve Holdback %",
  "Total Credit Reserve Holdback Amount"
];

export const ThirtyYearLoanLATColumns: any = [
  "Originator Loan ID",
  "Toorak Price",
  "FPD",
  "Toorak Product",
  "Address",
  "Loan Purpose",
  // "Interest Rate",
  "Interest Rate (As of Cut off Date)",
  "Prepayment",
  "IO Period",
  "DSCR",
  "As-Is LTV",
  "Original LTC/LTP",
  // "Fico Score",
  "Initial Loan Amount",
  "Current Loan Amount",
  "As-Is Value", // Adjusted As-Is Value  is property level field need clarification
  "Purchase Price (%)",
  "Purchase Price Discount",
  "Total Credit Reserve Holdback %",
  "Total Credit Reserve Holdback Amount"
];

export const BridgeLoanOriginatorColumns: any = BridgeLoanLATColumns;

export const TrtyYearLoanLATColumns: any = ThirtyYearLoanLATColumns;

export const TrtyYearLoanOriginatorColumns: any = ThirtyYearLoanLATColumns;

export const SettlementGenBridgeOriginator: any = [
  "Originator Loan ID",
  "Seller",
  "Current Interest Rate",
  "Borrower Name",
  "Borrower Experience",
  "Current Principal Balance",
  "Less Purchaser Cost Allocation",
  "Plus Accrued and Unpaid Interest",
  "Purchase Price",
  "Credit Reserve Holdback",
  "Closing Date Payment",
  "Funded Loan Amount",
  "Servicing Fee (per month)",
  "Seller Retained Interest-Only Strip",
  "Interest Accrual Date",
  "Accrued Interest Days",
  "Custodian",
  "Servicer",
  "Pricing",
  "Toorak Yield",
  "Maximum Principal Balance",
  "Regular Credit Reserve Holdback (%)",
  "Additional Credit Reserve Holdback ($)",
  "Dry Credit Reserve Holdback",
  "Remic",
  "Next Payment Due Date",
  "Closing Date",
  "Misc Fees",
  "New Originator Wire Counterparty",
  "Escrow",
  "Interest Reserve and others (Wire to Servicer)", // duplicate same as Financed Interest Reserve ($)
  "Additional Seller Retained Interest"
];

export const SettlementGenBridgeLAT: any = [
  ...SettlementGenBridgeOriginator,
  "Final Toorak Yield",
  "Final Toorak Yield-SFR",
  "Total Draw Amount",
  "Latest Draw Date",
  "Number Of Draws",
  "Toorak Product",
  'Original "As Is" LTV',
  "Total Origination and Discount Points",
  "State",
  "Interest Rate (As of Cut off Date)",
  "Loan Structure",
  "Cut off Date Loan Amount",
  "Cut off Date Maximum Loan Amount",
  "Borrowing Entity Name",
  "Origination Date",
  "Financed Interest Reserve ($)", // duplicate same as Interest Reserve and others
  "Max Loan Proceeds Before First Loss",
  "Guarantor 1 Last Name",
  "Guarantor 1 First Name",
  "Original Credit Score (Median)",
  // "Fico Score",
  "Regular Toorak Yield",
  "Borrower Experience Tier",
  "Securitization Grade",
  "Standard First Loss",
  "Standard First Loss Adjustment",
  "Location Adjustment(TX)"
];

export const SettlementGen30YOriginator: any = [
  "Originator Loan ID",
  "Seller",
  "Current Interest Rate",
  "Borrower Name",
  "Current Principal Balance",
  "Less Purchaser Cost Allocation",
  "Plus Accrued and Unpaid Interest",
  "Purchase Price",
  "Purchase Premium", // new done
  "Closing Date Payment",
  "Funded Loan Amount",
  "Servicing Fee (per month) ", // space for DSCR
  "Interest Accrual Date",
  "Accrued Interest Days",
  "Custodian",
  "Servicer",
  "Pricing ",
  "Remic",
  "Next Payment Due Date",
  "Closing Date",
  "Misc Fees",
  "New Originator Wire Counterparty", // new done
  "Tax Escrow (Wire to Servicer)", // new
  "Insurance Escrow (Wire to Servicer)", // new
  "Interest Reserve and others (Wire to Servicer)" // duplicate same as Financed Interest Reserve ($)
];

export const SettlementGen30YLAT: any = [
  ...SettlementGen30YOriginator,
  "Toorak Product",
  'Original "As Is" LTV',
  "Total Origination and Discount Points",
  "Borrower Experience",
  "State",
  "Original Interest Rate", // done
  "Current Loan Balance",
  "Borrowing Entity Name",
  "Origination Date",
  "Guarantor 1 Last Name",
  "Guarantor 1 First Name",
  "Original Credit Score", // new done
  "Current Remaining Tax Escrow Balance", // new done
  "Current Remaining Insurance Escrow Balance", // new done
  "Taxes: total balance collected at closing", // new done
  "Insurance: total balance collected at closing", // new done
  "Financed Interest Reserve ($)",
  "Securitization Grade"
];

export const TableFundingColumns: any[] = [
  "Table Fund Fee (%)",
  "Table Fund Fee ($)",
  "Wire to Escrow Agent",
  "Wire to Originator"
];

export const WarehouseFundColumns: any[] = [
  "Net Wire Due to Acquisition Acct/Seller"
];

export const filterKeyMapping: any = {
  "loan.loanEconomics.interestRateAsOfCutOffDate": {
    key: "interestRate",
    type: "rate"
  },
  "loan.loanDetailId.loanId": {
    key: "toorakLoanId",
    type: "string"
  },
  "loan.loanInfo.primaryLoanId": {
    key: "originalLoanId",
    type: "string"
  }
};

export const GOAndSS_CommonFields = (loanId: string) => {
  return {
    regularCreditReserveHoldback: `data.result.loanResult.${loanId}.firstLoss.sellerCreditReserveHoldback`,
    additionalCreditReserveHoldback: `data.result.loanResult.${loanId}.firstLoss.additionalCreditReserveHoldback`,
    standardFirstLossAdjustment: `data.result.loanResult.${loanId}.firstLoss.standardFirstLossAdjustment`,
    standardFirstLoss: `data.result.loanResult.${loanId}.firstLoss.sellerCreditReserveHoldback`,
    sellerCreditReserveHoldback: `data.result.loanResult.${loanId}.firstLoss.sellerCreditReserveHoldback`,
    regularToorakYield: `data.result.loanResult.${loanId}.toorakProceedsTest.regularToorakYield`,
    finalToorakPrice: `data.result.loanResult.${loanId}.loanPricing.finalToorakPrice`,
    toorakYield: `data.result.loanResult.${loanId}.toorakInterests.toorakYield`,
    finalToorakYield_SFR: `data.result.loanResult.${loanId}.toorakInterests.finalToorakYield_SFR`,
    finalToorakYield: `data.result.loanResult.${loanId}.toorakInterests.finalToorakYield`,
    locationAdjustment: `data.result.loanResult.${loanId}.toorakInterests.locationAdjustment`,
    additionalSellerRetainedInterest: `data.result.loanResult.${loanId}.firstLoss.additionalSellerRetainedInterest`,
    lessPurchaserCostAllocation: `data.result.loanResult.${loanId}.toorakInterests.lessPurchaserCostAllocation`,
    finalServicingFee: `data.result.loanResult.${loanId}.toorakInterests.finalServicingFee`,
    accrualDate: `data.result.loanResult.${loanId}.settlementResults.accrualDate`,
    nextPaymentDueDate: `data.result.loanResult.${loanId}.settlementResults.nextPaymentDueDate`,
    financedInterestReserve: `data.loan.loanEconomics.financedInterestReserve`,
    cutOffDateLoanAmount: `data.loan.loanEconomics.cutOffDateLoanAmount`,
    interestRateAsOfCutOffDate: `data.loan.loanEconomics.interestRateAsOfCutOffDate`,
    servicingFeePerMonth: `data.result.loanResult.${loanId}.toorakInterests.servicingFeePerMonth`,
    servicer: `data.loan.loanDetailId.loanConfigId.servicerPartyId`,
    servicerPartyId: `data.loan.loanDetailId.loanConfigId.servicerPartyId`,
  };
};

export enum custodyDocuments {
  documentType = "custodyDocuments"
}

// Loan details and guidelines outputs common field for settlement flow
export const LDAndGOCommonField = [
  "primaryLoanID",
  "toorakProduct",
  "financedInterestReserve",
  "interestRate",
  "totalOriginationAndDiscountPoints",
  "originationDate",
  "currentLoanBalance",
  // Bridge Loan Information
  "primaryLoanId",
  "loanStructure",
  "originationDate",
  // Bridge Loan Economics
  "interestRateAsOfCutOffDate",
  "cutOffDateLoanAmount",
  "cutOffDateMaximumLoanAmount"
];

export const EHmap: any = {
  closingDate: (prop: any) => `data.bundle.closingDate`,
  cutOffDateLoanAmount: (prop: any) =>
    `data.loan.loanEconomics.cutOffDateLoanAmount`, // loan audit
  finalServicingFee: (prop: any) =>
    `data.result.loanResults.${prop.loanId}.settlementResults.finalServicingFee`, // loan audit
  lessPurchaserCostAllocation: (prop: any) =>
    `data.result.loanResult.${prop.loanId}.toorakInterests.lessPurchaserCostAllocation`, // loan audit
  plusAccruedAndUnPaidInterest: (prop: any) =>
    `data.result.loanResults.${prop.loanId}.settlementResults.plusAccruedAndUnPaidInterest`,
  closingDatePayment: (prop: any) =>
    `data.result.loanResults.${prop.loanId}.settlementResults.closingDatePayment`,
  fundedLoanAmount: (prop: any) =>
    `data.result.loanResults.${prop.loanId}.settlementResults.fundedLoanAmount`,
  sellerRetainedInterest_onlyStrip: (prop: any) =>
    `data.result.loanResults.${prop.loanId}.settlementResults.sellerRetainedInterest_onlyStrip`,
  accrualDate: (prop: any) =>
    `data.result.loanResult.${prop.loanId}.settlementResults.accrualDate`,
  accruedInterestDays: (prop: any) =>
    `data.result.loanResults.${prop.loanId}.settlementResults.accruedInterestDays`,
  toorakYield: (prop: any) => `data.bundle.loans[${prop.loanId}].toorakYield`,
  maximumPrincipalBalance: (prop: any) =>
    `data.result.loanResults.${prop.loanId}.settlementResults.maximumPrincipalBalance`,
  dryCreditReserveHoldback: (prop: any) =>
    `data.result.loanResults.${prop.loanId}.settlementResults.dryCreditReserveHoldback`,
  remic: (prop: any) =>
    `data.result.loanResults.${prop.loanId}.settlementResults.remic`,
  nextPaymentDueDate: (prop: any) =>
    `data.result.loanResult.${prop.loanId}.settlementResults.nextPaymentDueDate`,
  escrow_wireToCohen: (prop: any) =>
    `data.result.loanResults.${prop.loanId}.settlementResults.escrow_wireToCohen`,
  finalToorakYield: (prop: any) =>
    `data.result.loanResults.${prop.loanId}.toorakInterests.finalToorakYield`,
  finalToorakYield_SFR: (prop: any) =>
    `data.result.loanResults.${prop.loanId}.toorakInterests.finalToorakYield_SFR`,
  purchasePrice: (prop: any) =>
    `data.result.loanResults.${prop.loanId}.settlementResults.purchasePrice`,
  regularToorakYield: (prop: any) =>
    `data.bundle.loans[${prop.loanId}].regularToorakYield`,
  purchasePremium: (prop: any) =>
    `data.result.loanResults.${prop.loanId}.settlementResults.purchasePremium`,
  insuranceEscrowWireToServicer: (prop: any) =>
    `data.result.loanResults.${prop.loanId}.settlementResults.insuranceEscrowWireToServicer`,
  taxEscrowWireToServicer: (prop: any) =>
    `data.result.loanResults.${prop.loanId}.settlementResults.taxEscrowWireToServicer`,
  servicerPartyId: (prop: any) =>
    `data.loan.loanDetailId.loanConfigId.servicerPartyId`,
  servicer: (prop: any) => `data.loan.loanDetailId.loanConfigId.servicerPartyId.servicerPartyId`,
  sellerPartyId: (prop: any) =>
    `data.bundle.loans[${prop.loanId}].sellerPartyId`,
  sellerName: (prop: any) => `data.bundle.loans[${prop.loanId}].sellerPartyId`,
  finalToorakPrice: (prop: any) =>
    `data.bundle.loans[${prop.loanId}].finalToorakPrice`,
  miscFees: (prop: any) => `data.bundle.loans[${prop.loanId}].miscFees`,
  warehouseLenderPartyId: (prop: any) =>
    `data.bundle.loans[${prop.loanId}].warehouseLenderPartyId`,
  warehouseLender: (prop: any) =>
    `data.bundle.loans[${prop.loanId}].warehouseLenderPartyId`,
  regularCreditReserveHoldback: (prop: any) =>
    `data.bundle.loans[${prop.loanId}].regularCreditReserveHoldback`,
  additionalCreditReserveHoldback: (prop: any) =>
    `data.result.loanResult.${prop.loanId}.firstLoss.additionalCreditReserveHoldback`,
  additionalSellerRetainedInterest: (prop: any) =>
    `data.bundle.loans[${prop.loanId}].additionalSellerRetainedInterest`,
  interestReservesAndOthersWireToServicer: (prop: any) =>
    `data.result.loanResults.${prop.loanId}.settlementResults.interestReservesAndOthersWireToServicer`,
  locationAdjustment: (prop: any) =>
    `data.result.loanResults.${prop.loanId}.toorakInterests.locationAdjustment`,
  standardFirstLossAdjustment: (prop: any) =>
    `data.result.loanResult.${prop.loanId}.firstLoss.standardFirstLossAdjustment`,
  sellerCreditReserveHoldback: (prop: any) =>
    `data.result.loanResult.${prop.loanId}.firstLoss.sellerCreditReserveHoldback`,
  standardFirstLoss: (prop: any) =>
    `data.result.loanResult.${prop.loanId}.firstLoss.sellerCreditReserveHoldback`,
  financedInterestReserve: (prop: any) =>
    `data.loan.loanEconomics.financedInterestReserve`,
  tableFundingFeePercent: (prop: any) =>
    `data.result.loanResults.${prop.loanId}.settlementResults.tableFundingFeePercent`,
  tableFundingFee: (prop: any) =>
    `data.result.loanResults.${prop.loanId}.settlementResults.tableFundingFee`,
  dueToOrFromOriginator: (prop: any) =>
    `data.result.loanResults.${prop.loanId}.settlementResults.dueToOrFromOriginator`,
  wireDueToEscrowAgent: (prop: any) =>
    `data.result.loanResults.${prop.loanId}.settlementResults.wireDueToEscrowAgent`,
  currentPrincipalBalance: (prop: any) =>
    `data.result.loanResults.${prop.loanId}.settlementResults.currentPrincipalBalance`,
  legalFeeDeducted: (prop: any) =>
    `data.bundle.loans[${prop.loanId}].legalFeeDeducted`,
  servicingFeePerMonth: (prop: any) =>
    `data.bundle.loans[${prop.loanId}].servicingFeePerMonth`,
  netWireDueToAccOrSeller: (prop: any) =>
    `data.result.loanResults.${prop.loanId}.settlementResults.netWireDueToAccOrSeller`,
  currentInterestRate: (prop: any) =>
    `data.result.loanResults.${prop.loanId}.settlementResults.currentInterestRate`
};

export const editHistoryPathMap = (props: {
  fieldKey: string;
  loanId?: string;
}) => {
  const { loanId, fieldKey } = props;
  return fieldKey in EHmap ? EHmap[fieldKey]({ loanId }) : "";
};

export const loanAuditPaths: any = {
  lessPurchaserCostAllocation: true,
  servicingFeePerMonth: true,
  accrualDate: true,
  cutOffDateLoanAmount: true,
  nextPaymentDueDate: true,
  financedInterestReserve: true,
  standardFirstLossAdjustment: false,
  standardFirstLoss: true,
  sellerCreditReserveHoldback: true,
  regularCreditReserveHoldback: true,
  finalToorakYield: true,
  finalToorakYield_SFR: true,
  locationAdjustment:  true,
  servicerPartyId: true,
  additionalCreditReserveHoldback: true,
  servicer: true,
};

// filter DB save props
export const BundleSaveFilterProps = {
  pageId: "Main",
  viewId: {
    all: "all"
  },
  tabId: "PandS",
  filterName: "filter"
};

export enum tbCompTypes {
  viewLoan = "View Loan",
  selectCB = "Select CheckBox",
  loanEA = "Loan Edit Actions",
  loanIdWrap = "LoanId Wrapper",
  commentDisplay = "Comments Display",
  addressFormating = "Address Formatting",
  asISAVFormatting = "As Is Appraisal Value Formatting",
  asISRVFormating = "As Is Repaired Value Formatting",
  bundleIdFormatter = "Bundle Id Formatter",
  bundleAggCurrency = "Add Amount Field",
  bundleCreatedBy = "Bundle CreatedBy Formatting",
  bundleSelectAll = "Bundle Select All",
  bundleSelect = "Select Bundle",
  bundleStatusFormatter = "Bundle Status Formatter",
  bundleFundingFormatter = "Bundle Funding",
  bundleAssignedPartnerFormatter = "Assigned Partner"
}

export const inputCheckTypes: { [key: string]: boolean } = {
  [formatType.number]: true,
  [formatType.pBy100]: true,
  [formatType.percentage]: true,
  [formatType.currency]: true
};

export const inputCheck = (type: formatType, val: any) => {
  if (formatType.currency === type && /^[-]?[$]?[0-9,.]*$/.test(val))
    return true;
  else if (
    (formatType.pBy100 === type || formatType.percentage === type) &&
    /^[0-9]*\.{0,1}[0-9]*%?$/.test(val)
  )
    return true;
  else if (formatType.number === type && !isNaN(val)) return true;
  else if (formatType.text === type) return false;
  else return false;
};

export const PAsignedCount = (bundleSellerMapping: any[]) => {
  return bundleSellerMapping.filter((sellerInfo: any) => {
    if (!sellerInfo.bundleSellerSigneeMapping) return false;
    if (sellerInfo.signingType === "ANY_SIGNEE")
      return sellerInfo.bundleSellerSigneeMapping.some(
        (signeeInfo: any) => signeeInfo.status === "COMPLETED"
      );
    return sellerInfo.bundleSellerSigneeMapping.every(
      (signeeInfo: any) => signeeInfo.status === "COMPLETED"
    );
  }).length;
};
