import { Grid, Snackbar, TextField } from "@mui/material";
import { Alert } from "@mui/material";
import {
  ConfirmFormModal,
  useStylesStatusUpdate
} from "@toorak/tc-common-fe-sdk";
import React, { useState } from "react";
import { useStylesServicer } from "../../assetManagement/ServicerOnboarding/LoanDetails/ServicerOnboarding.styles";
import { useBudgetStyles } from "../BudgetReview.style";
import { ReadyForReviewBtn } from "./ReadyForReviewBtn";
import { TotalBudgetArea } from "./TotalBudgetArea";
import { budgetReviewStatusEnum } from "../../ilp/list-view/BudgetTaskManagement";

export const BudgetTotalAndActionBtnArea = (props: any) => {
  const { fieldsData, setFieldsData, isDisabled, budgetReviewHeader } = props;
  const budgetClasses = useBudgetStyles();
  const classes = useStylesServicer();
  const classesLoc = useStylesStatusUpdate();
  const [showCommentPopup, setShowCommentPopup] = useState<any>({
    comment: "",
    headerText: "Ready for Budget Approval",
    showComment: false
  });
  const [showToast, setShowToast] = useState({ isShown: false, message: "" });
  const [hasCallProceed, setHasCallProceed] = useState<any>({
    isProceed: false,
    isRedo: false
  });
  const { Approved, Rejected } = budgetReviewStatusEnum;
  const hideSnackBar = () => {
    setShowToast({ isShown: false, message: "" });
  };
  const handleProceedClick = (header: string) => {
    if (header === "Redo Budget Review") {
      setHasCallProceed({ isProceed: false, isRedo: true });
    } else {
      setHasCallProceed({ isProceed: true, isRedo: false });
    }
  };

  return (
    <>
      <Snackbar
        open={showToast.isShown}
        autoHideDuration={4000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        onClose={hideSnackBar}
      >
        <Alert severity="success">{showToast.message}</Alert>
      </Snackbar>
      <ConfirmFormModal
        isOpen={showCommentPopup.showComment}
        // size={"100%"}
        handleClose={() => {
          setShowCommentPopup({
            comment: "",
            headerText: showCommentPopup.headerText,
            showComment: false
          });
        }}
        confirmCondition={showCommentPopup.comment.trim() === ""}
        header={showCommentPopup.headerText}
        primaryBtnName={"Save"}
        body={
          <>
            <div className={budgetClasses.budgetAddComment}>
              Add your comment here
              <span className={`${classes.asterick} ${classes.asterick}`}>
                *
              </span>
            </div>
            <div className={classesLoc.checkLineWrapper}>
              <TextField
                id="update-multiline-comment"
                value={showCommentPopup.comment}
                onChange={(e: any) => {
                  // setComment(e.target.value);
                  setShowCommentPopup({
                    ...showCommentPopup,
                    ...{ comment: e.target.value }
                  });
                }}
                style={{
                  width: "100%",
                  minWidth: 460,
                  marginTop: 8,
                  padding: 0
                }}
                multiline
                inputRef={(input) => input && input.focus()}
                InputProps={{
                  className: classesLoc.input
                }}
                rows={3}
                // classes={autoClasses}
                placeholder="Write here"
                variant="outlined"
              />
            </div>
          </>
        }
        headerStyles={{
          padding: 17,
          borderBottom: "solid 1px #dddddd"
        }}
        footerStyles={{
          padding: "0px 0px 3px 17px",
          margin: "20px 0px 14px 0px"
        }}
        successHandler={() => {
          handleProceedClick(showCommentPopup.headerText);
        }}
      />
      <Grid item style={{ height: 50 }}>
        <TotalBudgetArea
          showCommentPopup={showCommentPopup}
          setFieldsData={setFieldsData}
          isDisabled={isDisabled}
          fieldsData={fieldsData}
          setShowCommentPopup={setShowCommentPopup}
          setShowToast={setShowToast}
          hasCallProceed={hasCallProceed}
          setHasCallProceed={setHasCallProceed}
        />
      </Grid>
      {![Approved, Rejected].includes(
        budgetReviewHeader.budgetReviewStatus
      ) && (
        <Grid
          item
          style={{ height: 50 }}
          className={budgetClasses.budgetReviewButtons}
        >
          <ReadyForReviewBtn
            hasCallProceed={hasCallProceed}
            setHasCallProceed={setHasCallProceed}
            showCommentPopup={showCommentPopup}
            setShowCommentPopup={setShowCommentPopup}
            setShowToast={setShowToast}
          />
        </Grid>
      )}
    </>
  );
};
