import React from "react";
import { Icon } from "./icon";

export const CloseIcon = (props: Icon) => {
  const { color } = props;
  return (
    <svg
      className="svg-icon"
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 16 16"
    >
      <path
        fill={color}
        d="M12.667 4.273L11.727 3.333 8 7.06 4.273 3.333 3.333 4.273 7.06 8 3.333 11.727 4.273 12.667 8 8.94 11.727 12.667 12.667 11.727 8.94 8z"
      />
    </svg>
  );
};