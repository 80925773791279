/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { useSelector } from "react-redux";

import { Box, Chip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import { RootState } from "../../stores/rootReducer";
import { OptionalComment } from "../purchase-and-settlements/components/ConstantComponents";
import { UserDetailsCard } from "../exceptions-and-enquiries/UserDetailsCard";
import { genFormattedUserDetails } from "../../stores/EvaluationResults/EvaluationResults.action";
import { isLoanOnHold } from "../../utils/AccessManagement";
import { getPartyId } from "../../stores/WaiverRequest/WaiverRequest.action";
import { nameToInitials } from "../../utils/formatChecks";
import { onHoldEnumValue } from "./AddOnHoldDialog";

const useStyles = makeStyles({
  chipRoot: {
    marginLeft: 10,
    height: 16
  },
  chipLabel: {
    fontSize: 10,
    fontWeight: 600,
    textTransform: "uppercase",
    color: "#fff"
  },
  chipPrimary: {
    backgroundColor: "#fb623f"
  },
  chipSecondary: {
    backgroundColor: "#2ece89"
  }
});

interface ViewHoldCommentProps {
  commentData: any;
}

export const ViewHoldComment = (props: ViewHoldCommentProps) => {
  const { commentData } = props;
  const classes = useStyles();
  const customerDetails = useSelector<RootState, any>(
    state => state.evaluationResultsStore.customerInfo
  );

  const customerInfo = useSelector<RootState, any>(
    state => state.createLoanStore.customerInfo
  );

  const renderUserUI = () => {
    const isCustomerDetails =
      Object.keys(customerDetails).length === 0 &&
      customerDetails.constructor === Object;
    const isCustomerInfo = customerInfo?.length > 0;

    if (!isCustomerDetails && isCustomerInfo) {
      const cloneCustomerDetails = JSON.parse(JSON.stringify(customerDetails));
      delete cloneCustomerDetails?.customers;
      const tempObj = { ...cloneCustomerDetails, customers: customerInfo };
      const tokenData = getPartyId();
      let userDetails: any = genFormattedUserDetails(
        tempObj,
        commentData?.createdBy,
        commentData?.date
      );
      const firstName = tokenData?.name.split(" ")[0];
      const lastName = tokenData?.name.split(" ")[2];
      const nameInitials =
        userDetails?.NameInitials !== ""
          ? userDetails?.NameInitials
          : nameToInitials(`${firstName} ${lastName}`);
      if (commentData?.isSystemUpdate) {
        userDetails.userFullName = onHoldEnumValue.systemValueToBeShown;
      }
      return (
        <UserDetailsCard
          userImg="some img will come here"
          date={userDetails?.dateTime}
          accountType={userDetails?.accountTypeFirstLetter}
          userFullName={userDetails?.userFullName}
          accountNameImg={nameInitials}
        />
      );
    }
  };

  const renderTagUI = () => {
    const loanOnHold = isLoanOnHold(commentData?.value);
    return (
      <Chip
        label={loanOnHold ? "Applying Hold Comment" : "hold removal comment"}
        size="small"
        color={loanOnHold ? "primary" : "secondary"}
        classes={{
          root: classes.chipRoot,
          label: classes.chipLabel,
          colorPrimary: classes.chipPrimary,
          colorSecondary: classes.chipSecondary
        }}
      />
    );
  };

  return (
    <Box mb={2}>
      <Box display="flex" alignItems="center" pb="10px">
        {renderUserUI()}
        {renderTagUI()}
      </Box>
      <OptionalComment
        comment={commentData?.comments}
        setComment
        customStyles={{
          body: {
            width: "100%",
            padding: "0px"
          },
          textAreaWrapper: {
            height: "89px",
            border: "transparent"
          },
          lable: {
            marginBottom: "8px"
          },
          textArea: {
            backgroundColor: "transparent",
            padding: 0
          }
        }}
        disableEdit
        noLabel
      />
    </Box>
  );
};
