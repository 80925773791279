/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../stores/rootReducer";
import {
  // clearPandSsorting,
  sortTrigger
} from "../../../stores/SettlementAndPurchase/SettlementAndPurchase.action";
import { setClearPAndSSorting } from "../../../stores/SettlementAndPurchase/SettlementAndPurchase.reducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sortIcon: {
      height: "16px",
      width: "16px",
      marginBottom: "0",
      cursor: "pointer",
      padding: "0"
    }
  })
);

export const SortButton = (props: any) => {
  const { fieldKey, ...rest } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [sortDirectionCustom, setSortDirectionCustom] = useState<string>("ASC");
  const [sortDirection, setSortDirection] = useState<string>("NONE");

  const clearPandSsortingVal = useSelector<RootState, any>(
    (state) => state.spStore.clearPandSsorting
  );

  const bundleInfo = useSelector<RootState, any>(
    (state) => state.spStore.bundleInfo
  );

  useEffect(() => {
    if (clearPandSsortingVal && !rest.isHistoryTable) {
      dispatch(setClearPAndSSorting(false));
      setSortDirectionCustom("ASC");
      setSortDirection("NONE");
    }
  }, [clearPandSsortingVal]);

  return (
    <IconButton
      className={classes.sortIcon}
      onClick={(_event) => {
        // sort(props.x.name, sortDirectionCustom);
        if (rest.isHistoryTable) {
          const sDir: string = sortDirectionCustom === "ASC" ? "DESC" : "ASC";
          setSortDirectionCustom(sDir);
          rest.sortCallBack(sDir);
        } else {
          dispatch(setClearPAndSSorting(true));
          setTimeout(() => {
            const sDir: string = sortDirectionCustom === "ASC" ? "DESC" : "ASC";
            setSortDirectionCustom(sDir);
            setSortDirection(sDir);
            sortTrigger(bundleInfo, fieldKey, sDir, dispatch);
          }, 0);
        }
      }}
      size="large"
    >
      <span
        className={
          sortDirection === "ASC"
            ? "sorting descending"
            : sortDirection === "DESC"
            ? "sorting ascending"
            : "sorting"
        }
      />
    </IconButton>
  );
};
