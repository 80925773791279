/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
// dummy comment
import { Button, Checkbox, Tooltip } from "@mui/material";
import React, { useRef, useState, useContext, useEffect } from "react";
// @ts-ignore
import { useDispatch } from "react-redux";
import { SearchBarForMobile } from "../create-loan/loan-lists/LoanListSubHeader/SearchBarForMobile";
import { BasicDropDown } from "../ui-components/BasicDropDown";
import { loanListStyles, useStylesBootstrap } from "./styles";

import { TakeOutPartnerContext } from "./takeoutPartner";
import { ObjectType } from "../masterView/common";
import {
  assignLoansToPartner,
  rejectLoan
} from "../stores/TKOP/takeoutPartner.action";
// import { BootstrapTooltip } from "../utils/Tooltip";
import {
  showLoader,
  hideLoader
} from "../stores/loaderAndException/loaderAndException.action";
import { intersection } from "../utils/utils";

function BootstrapTooltip(props: any) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} placement="left" />;
}

export interface ListHeaderProps {
  partners: { label: string; value: string; style?: any }[];
  onSelectAll: Function;
  selectedItems: Map<string, ObjectType>;
  itemsList: any[];
  onSuccessCallBack: Function;
  listHeaderText: string;
  isDropDownNeeded: boolean;
  idKey?: string;
}

export function ListHeader(props: ListHeaderProps) {
  const searchInput = useRef<HTMLDivElement>(null);
  const classes = loanListStyles();
  const {
    clearSearch,
    clearDDNonReviewSearch,
    clearDDReviewSearch,
    handleSearch,
    handleDDNonReviewSearch,
    handleDDReviewSearch,
    activeServicerTab,
    inputValue,
    setInputValue,
    dDReviewInputValue,
    dDNonReviewInputValue,
    setDDReviewInputValue,
    setDDNonReviewInputValue,
    setCurrentlySelectedTab
  } = useContext(TakeOutPartnerContext);
  const {
    onSelectAll,
    selectedItems,
    itemsList,
    partners,
    // onSuccessCallBack,
    listHeaderText,
    isDropDownNeeded,
    idKey = "id"
  } = props;
  const [allItemsSelected, setAllItemsSelected] = useState(false);
  const [
    eligiblePartnersForAssignment,
    setEligiblePartnersForAssignment
  ] = useState<ObjectType[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setAllItemsSelected(
      selectedItems.size === itemsList.length && itemsList.length !== 0
    );
  }, [selectedItems, itemsList]);

  useEffect(() => {
    let tempElPartnerIds: [][] = [];
    for (const value of selectedItems.values()) {
      const { data: { loan: { eligibleTakeoutPartners } = [] } = {} } = value;
      tempElPartnerIds.push(
        eligibleTakeoutPartners
          ? eligibleTakeoutPartners.map(
              (item: ObjectType) => item.takeoutPartnerPartyId
            )
          : []
      );
    }
    tempElPartnerIds = intersection(tempElPartnerIds);
    const tempPartners = partners?.filter((item: ObjectType) =>
      tempElPartnerIds?.includes(item.value)
    );
    setEligiblePartnersForAssignment([...tempPartners]);
  }, [selectedItems, partners]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (checked) {
      onSelectAll(
        new Map(itemsList.map((item: ObjectType) => [item?.[idKey], item]))
      );
    } else {
      onSelectAll(new Map());
    }
    setAllItemsSelected(checked);
  };

  const refreshPageContent = () => setCurrentlySelectedTab(activeServicerTab);

  const handleBulkPartnerChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const partnerId = event.target.value;
    const payload: ObjectType[] = [];
    selectedItems.forEach((itemDetails: ObjectType) => {
      const { loanId } = itemDetails;
      payload.push({
        loanId,
        loanDetails: {
          loanInfo: {
            takeoutPartner: partnerId
          }
        }
      });
    });
    dispatch(assignLoansToPartner(payload, refreshPageContent));
  };

  const handleBulkRejectionChange = async () => {
    const reqBodys: ObjectType[] = [];
    selectedItems.forEach((itemDetails: ObjectType) => {
      const {
        loanId,
        data: {
          loan: { loanStage }
        }
      } = itemDetails;

      const loanState = "Rejected";
      const payload: ObjectType = {
        loanId,
        loanStage,
        loanState
      };
      reqBodys.push(payload);
    });

    try {
      dispatch(showLoader());
      await Promise.all(reqBodys.map(reqBody => rejectLoan(reqBody)));
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
    }
    setCurrentlySelectedTab(activeServicerTab);
  };

  return (
    <div
      className={
        activeServicerTab === "UnassignedLoans"
          ? classes.loanListHeader
          : classes.accordianHeader
      }
    >
      <div className={classes.selecteAllCheckBox}>
        {(activeServicerTab === "UnassignedLoans" || isDropDownNeeded) && (
          <Checkbox
            id="1"
            checked={allItemsSelected}
            value="selectAll"
            disableRipple
            onClick={event => event.stopPropagation()}
            onChange={handleSelectAll}
          />
        )}
        <span>{listHeaderText}</span>
      </div>
      <div className={classes.searchWrapper}>
        {activeServicerTab === "UnassignedLoans" ? (
          <SearchBarForMobile
            setInputValue={setInputValue}
            inputValue={inputValue}
            searchInput={searchInput}
            handleSearch={handleSearch}
            clearSearch={clearSearch}
            customClassName="inputSearch"
            viewType="notDetail"
          />
        ) : (
          <SearchBarForMobile
            setInputValue={
              isDropDownNeeded
                ? setDDNonReviewInputValue
                : setDDReviewInputValue
            }
            inputValue={
              isDropDownNeeded ? dDNonReviewInputValue : dDReviewInputValue
            }
            searchInput={searchInput}
            handleSearch={
              isDropDownNeeded ? handleDDNonReviewSearch : handleDDReviewSearch
            }
            clearSearch={
              isDropDownNeeded ? clearDDNonReviewSearch : clearDDReviewSearch
            }
            customClassName="inputSearch"
            viewType="notDetail"
          />
        )}
      </div>
      {activeServicerTab === "UnassignedLoans" ? (
        <div>
          <BootstrapTooltip title="Select the Loan to Reject">
            <span>
              <Button
                variant="outlined"
                onClick={() => handleBulkRejectionChange()}
                style={{ background: "#fff" }}
                disabled={selectedItems.size === 0}
                data-testid="rejcet-bulk-loan"
              >
                Reject Loan(s)
              </Button>
            </span>
          </BootstrapTooltip>
        </div>
      ) : (
        isDropDownNeeded && (
          <div className={classes.wareHouseDropDown}>
            <BasicDropDown
              ovverrideCss
              menuOptions={
                eligiblePartnersForAssignment &&
                eligiblePartnersForAssignment.map(item => {
                  const disabled = item.value === activeServicerTab;
                  return { label: item.label, value: item.value, disabled };
                })
              }
              data-testid="bulk-partner-change"
              handleChange={handleBulkPartnerChange}
              placeHolderText="Assign Partner for Bulk Loans"
              width="60%"
              selected=" "
            />
          </div>
        )
      )}
    </div>
  );
}
