import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      input: {
        backgroundColor: "#ffffff !important"
      }
    },
    containerStyle: {
      padding: "15px 20px 15px 5px",
      // borderBottom: "solid 1px #f2f2f2",
      //   borderTop: "solid 1px #f2f2f2",
      // border: "solid 1px #f2f2f2",
      backgroundColor: "#ffffff"
    },
    containerShadow: {
      boxShadow: "2px 2px 6px 2px rgba(0, 0, 0, 0.1)",
      margin: "24px",
      borderRadius: "4px",
      border: "solid 1px #e1e1e1",
      backgroundColor: "#ffffff"
    },
    avatar: {
      display: "inline-block",
      marginRight: "10px",
      backgroundSize: "contain"
    },
    commentContainer: {
      display: "flex",
      margin: "8px 0px 16px 0px"
    },
    commentColRight: {
      display: "flex",
      flexWrap: "wrap",
      width: "calc(100% - 40px)",
      maxWidth: "calc(100% - 40px)",
      textAlign: "left"
      // "align-items": "center"
      // a: {
      //   textDecoration: "none"
      // }
    },
    viewDetailsStyle: {
      borderRadius: "4px",
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4"
    },
    commentTime: {
      fontSize: "10px",
      color: "#8897aa",
      paddingTop: "5px"
    },
    commentContent: {
      fontSize: "12px",
      flex: "1 100%",
      paddingBottom: 5,
      wordBreak: "break-all",
      fontSeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.67,
      letterSpacing: "normal",
      color: "#32325d"
    },
    commentTextContainer: {
      marginTop: "5px"
      // marginLeft: "11px",
    },
    inquiryShadow: {
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)"
    },
    unReadCount: {
      top: "-8px",
      right: "-8px",
      position: "absolute",
      backgroundColor: "#f75676",
      height: "16px",
      width: "16px",
      borderRadius: "50%",
      color: "white",
      border: "solid 1px #ffffff",
      lineHeight: 1.8,
      fontSize: "10px",
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
      textAlign: "center"
    },
    exceptionContainer: {
      // padding: "19px 20px 0px 20px" below padding changed to remove bug,
      // padding: "11px 8px 15px 25px",
      padding: "11px 0px 15px 25px",
      // borderTop: "solid 1px #f2f2f2",
      // border: "solid 1px #f2f2f2",
      backgroundColor: "#ffffff",
      /* [theme.breakpoints.down("xs")]: {
        paddingLeft: "10px !important"
      } */
      display: "table",
      tableLayout: "fixed",
      width: "100%"
    },
    closeButton: {
      padding: "0px 0px 1px 0px",
      marginLeft: "12px"
    },
    leftColumn: {
      display: "table-cell",
      width: "41.6%"
    },
    rightColumn: {
      display: "table-cell",
      width: "58.3%"
    },
    exceptionContainerViewDetails: {
      padding: "15px 0px 0px 15px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      // borderBottom: "solid 1px #f2f2f2",
      // borderBottom: "solid 1px #f2f2f2",
      backgroundColor: "#ffffff",
      borderRadius: "25px",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "10px !important"
      }
    },
    container: {
      padding: "25px 20px 15px 20px",
      // borderBottom: "solid 1px #f2f2f2",
      borderTop: "solid 1px #f2f2f2",
      // border: "solid 1px #f2f2f2",
      backgroundColor: "#ffffff"
    },
    radioContainer: {
      padding: "25px 20px 15px 20px",
      // borderBottom: "solid 1px #f2f2f2",
      // borderTop: "solid 1px #f2f2f2",
      // border: "solid 1px #f2f2f2",
      backgroundColor: "#ffffff"
    },
    containerWaiver: {
      padding: "25px 20px 15px 20px",
      borderTop: "solid 1px #f2f2f2",
      // border: "solid 1px #f2f2f2",
      backgroundColor: "#ffffff"
    },
    filesContainer: {
      // padding: "10px 20px",
      // border: "solid 1px #f2f2f2",
      backgroundColor: "#ffffff",
      borderBottom: "solid 1px #f2f2f2"
    },
    filesContainerScenario: {
      backgroundColor: "#ffffff",
      padding: "0px 20px"
    },
    discussionContainer: {
      padding: "0 10px 10px 0",
      backgroundColor: "#ffffff"
    },
    sectionContainer: {
      padding: "5px 20px 15px 20px",
      borderRadius: "4px",
      borderBottom: "solid 1px #f2f2f2",
      backgroundColor: "#ffffff",
      [theme.breakpoints.down("sm")]: {
        borderBottom: "0"
        // padding: "5px 10px 18px 10px"
      },
      [theme.breakpoints.down("md")]: {
        borderBottom: "0"
      },
      [theme.breakpoints.down("lg")]: {
        borderBottom: "0"
      }
    },
    containerActionCard: {
      padding: "25px 20px",
      border: "solid 1px #f2f2f2",
      backgroundColor: "#ffffff"
    },
    requestedUser: {
      display: "inline-flex",
      fontSize: "12px",
      color: "#32325d",
      fontWeight: 600,
      // padding: "0px 5px 2px 0px",
      padding: "0px 5px 0px 0px",
      textTransform: "capitalize"
      // marginRight: "10px"
    },
    requestedUserComm: {
      fontSize: "12px",
      color: "#32325d",
      fontWeight: 600,
      padding: "0px 5px 2px 0px",
      textTransform: "capitalize"
      // marginRight: "10px"
    },
    mobileViewRequestedUser: {
      [theme.breakpoints.down("sm")]: {
        display: "block !important",
        marginBottom: "5px",
        width: "100% !important"
      }
    },
    requestedUserDiscussion: {
      fontSize: "14px",
      color: "#32325d",
      fontWeight: 600,
      paddingRight: "5px",
      textTransform: "capitalize",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    mobileViewButtonContainer: {
      margin: "0",
      display: "flex",
      //  justifyContent: "end",
      // justifyContent:"end",
      alignItems: "center",
      "& > *": {
        marginLeft: "10px"
      }
      // paddingRight: "10px",
      // textAlign: "right",
      // [theme.breakpoints.down("xs")]: {
      //   maxWidth: "100% !important",
      //   flexBasis: "100% !important",
      //   justifyContent: "flex-start !important",
      //   marginTop: "5px"
      // },
      // [theme.breakpoints.up("sm")]: {
      //   position: "relative"
      // }
    },
    statusComponentContainer: {
      display: "inline-block",
      // paddingLeft: "10px",
      [theme.breakpoints.down("sm")]: {
        display: "inline-flex !important",
        maxWidth: "fit-content !important"
      }
    },
    inlineElement: {
      display: "inline-block",
      marginRight: 5
    },
    userIcon: {
      paddingRight: "9px",
      height: "28px"
    },
    mobileViewStatusDetail: {
      [theme.breakpoints.down("sm")]: {
        marginRight: "0px !important",
        width: "calc(100% - 30px) !important"
      }
    },
    mobileViewTimeInfo: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px !important"
      }
    },
    requestedDiv: {
      borderRadius: "6px",
      backgroundColor: "#fb623f",
      padding: "5px 8px",
      height: 25,
      display: "flex",
      alignItems: "center",
      fontWeight: 600
    },
    failedTypography: {
      fontSize: "10px",
      // fontWeight: "bold",
      color: "#ffffff",
      height: "14px"
    },
    statusRejected: {
      borderRadius: "6px",
      backgroundColor: " #f75676",
      padding: "5px 8px",
      height: 25,
      display: "flex",
      alignItems: "center",
      fontWeight: 600
    },
    viewDetailsConatiner: {
      display: "flex",
      justifyContent: "flex-end",
      height: "30px",
      position: "relative",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        display: "inline-block !important",
        marginLeft: "0 !important"
      },
      [theme.breakpoints.down("md")]: {
        display: "inline-block !important",
        marginLeft: "0 !important"
      },
      [theme.breakpoints.down("lg")]: {
        display: "inline-block !important",
        marginLeft: "10px !important"
      }
    },
    discussionViewDetail: {
      [theme.breakpoints.down("sm")]: {
        display: "none !important"
      }
    },
    viewDetailsContainerMobileView: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "flex !important",
        justifyContent: "flex-start",
        padding: "0 15px 15px 25px",
        height: "auto"
      }
    },
    buttonWrapper: {
      display: "inline-flex",
      position: "relative"
    },
    reRequestButton: {
      borderRadius: "4px",
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4",
      margin: "0px 10px"
    },
    viewDetailsbutton: {
      borderRadius: "4px",
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4",
      paddingTop: "5px",
      paddingBottom: "5px",
      marginTop: "10px"
    },
    wrapperViewDetails: {
      padding: "84px 0px 0px 0px"
    },
    dotColor: {
      color: "#8897aa",
      marginTop: "1px",
      marginRight: "5px"
    },
    wrapperActionsCard: {
      padding: "0px 0px 0px 0px"
    },
    tagsWrapper: {
      // padding: "20px 15px 10px 7px",
      padding: "15px 15px 5px 2px"
    },
    responsiveTagsWrapper: {
      padding: "15px 15px 5px 0px"
    },
    tagsWrapperComm: {
      padding: "20px 15px 15px 7px"
    },
    tagsContainer: {
      minHeight: 21,
      borderRadius: "6px",
      marginRight: "10px",
      backgroundColor: "#d9efff",
      display: "flex",
      alignItems: "center",
      padding: "4px 8px",
      marginBottom: "6px",
      width: "fit-content"
    },
    tagsStageContainer: {
      // minHeight: 21,
      borderRadius: "6px",
      marginRight: "10px",
      backgroundColor: "#172b4d",
      display: "flex",
      alignItems: "center",
      padding: "2px 5px",
      marginBottom: "6px",
      width: "fit-content"
    },
    tagsItem: {
      fontSize: "10px",
      fontWeight: "bold",
      color: "#172b4d",
      textTransform: "uppercase",
      textAlign: "left"
    },
    tagsItemBlack: {
      fontSize: "10px",
      fontWeight: 600,
      color: "#FFF",
      textTransform: "uppercase",
      textAlign: "left"
    },
    tagsStageItem: {
      fontSize: "10px",
      fontWeight: 600,
      color: "#fff",
      textTransform: "uppercase",
      textAlign: "left"
    },
    displayFex: {
      display: "flex",
      flexWrap: "wrap"
    },
    titleDiscussion: {
      paddingTop: "25px",
      fontSize: "14px",
      color: "#32325d",
      lineHeight: 1.73
    },
    description: {
      fontSize: "14px",
      color: "#32325d",
      lineHeight: 1.73,
      wordBreak: "break-word",
      fontWeight: 400
    },
    scenarioDescription: {
      fontSize: "12px",
      color: "#32325d",
      lineHeight: 1.73,
      wordBreak: "break-word",
      fontWeight: 400,
      paddingRight: "20px"
    },
    descriptionDashboard: {
      fontSize: "12px",
      color: "#32325d",
      lineHeight: 1.67,
      fontWeight: "normal",
      wordBreak: "break-all"
    },
    divider: {
      height: "1px",
      border: "solid 1px #f2f2f2",
      padding: "0px !important"
    },
    toorakBg: {
      fontSize: "11px",
      margin: "0px 3px",
      padding: "2px 5px 2px 6px",
      color: "#FFF",
      fontWeight: "bold",
      textAlign: "center",
      borderRadius: "21px",
      backgroundImage:
        "linear-gradient(139deg, #0fcbef 2%, #1071ee 95%), linear-gradient(to bottom, #d8d8d8, #d8d8d8)"
    },
    smallCircle: {
      width: "15px",
      height: "16px",
      borderRadius: "15px"
    },
    dueBg: {
      fontSize: "11px",
      fontWeight: "bold",
      textAlign: "center",
      color: "#ffffff",
      // backgroundImage: "linear-gradient(139deg, #808ff2 2%, #5566d6 95%)",
      borderRadius: "23px",
      margin: "0px 3px",
      padding: "1px 4px 2px 5px",
      lineHeight: "16px"
    },
    originatorBg: {
      fontSize: "11px",
      fontWeight: "bold",
      textAlign: "center",
      lineHeight: "16px",
      color: "#ffffff",
      // backgroundImage: "linear-gradient(139deg, #ff9e55 2%, #ae5f00 95%)",
      borderRadius: "23px",
      margin: "0px 3px",
      padding: "1px 4px 2px 5px"
    },
    userAvatarCircle: {
      display: "flex",
      width: "38px"
    },
    circle: {
      width: "28px",
      height: "28px",
      // backgroundImage:
      //   "linear-gradient(139deg, #0fcbef 2%, #1071ee 95%), linear-gradient(to bottom, #d8d8d8, #d8d8d8)",
      backgroundColor: "#9d5ce6",
      borderRadius: "50%",
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center"
    },
    commentCircle: {
      width: "28px",
      height: "28px",
      // backgroundImage:
      //   "linear-gradient(139deg, #0fcbef 2%, #1071ee 95%), linear-gradient(to bottom, #d8d8d8, #d8d8d8)",
      backgroundColor: "#9d5ce6",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    nameInitials: {
      // display: "block",
      color: "#FFF",
      // textAlign: "center",
      // lineHeight: "28px",
      fontWeight: "bold",
      fontSize: "14px"
    },
    lightColor: {
      color: "#8897aa",
      fontSize: 11,
      padding: "0px 5px 0px 5px",
      [theme.breakpoints.down("sm")]: {
        padding: "5px 0 5px 5px !important",
        // minWidth: "110px",
        textAlign: "left"
      }
    },
    darkColor: {
      color: "var(--dark-grey-blue)",
      fontSize: 12,
      padding: "0px 2px 0px 5px",
      [theme.breakpoints.down("sm")]: {
        padding: "5px 0 5px 5px !important",
        // minWidth: "110px",
        textAlign: "left"
      }
    },
    lightColorAlternate: {
      color: "#5b6880",
      fontSize: 12,
      padding: "0px",
      [theme.breakpoints.down("sm")]: {
        padding: "0 0 0 10px !important",
        // minWidth: "110px",
        textAlign: "left"
      },
      "&:first-letter": {
        textTransform: "capitalize"
      }
    },
    lightColorCompactUser: {
      color: "#5b6880",
      fontSize: 12,
      padding: "0px 5px 0px 5px",
      [theme.breakpoints.down("sm")]: {
        padding: "0 0 0 10px !important",
        // minWidth: "110px",
        textAlign: "left"
      }
    },
    lightColorDate: {
      color: "#5b6880",
      fontSize: 12,
      padding: "0px 5px 0px 0px",
      [theme.breakpoints.down("sm")]: {
        padding: "0 0 0 10px !important",
        // minWidth: "110px",
        textAlign: "left"
      }
    },
    lightColorDateBigText: {
      color: "#5b6880",
      fontSize: 12,
      padding: "2px 5px 0px 0px",
      [theme.breakpoints.down("sm")]: {
        padding: "0 0 0 10px !important",
        // minWidth: "110px",
        textAlign: "left"
      }
    },
    responsiveLightColor: {
      color: "#8897aa",
      fontSize: 11,
      padding: "0px 2px 0px 0px"
    },
    lightColorUserName: {
      color: "#8897aa",
      marginTop: "1px"
    },
    descriptionException: {
      fontSize: "14px",
      color: "#32325d",
      textAlign: "left",
      fontWeight: 500,
      padding: "25px 0px 10px 0px",
      wordBreak: "break-word",
      [theme.breakpoints.down("sm")]: {
        wordBreak: "break-all"
      }
    },
    statusApproved: {
      color: "#ffffff",
      borderRadius: "6px",
      backgroundColor: "#2ece89",
      padding: "5px 8px",
      height: 25,
      display: "flex",
      alignItems: "center",
      fontWeight: 600
    },
    exceptionComment: {
      marginTop: 14,
      borderRadius: "4px",
      backgroundColor: "#f8f9ff"
    },
    comments: {
      padding: "10px"
    },
    commentsContainerSC: {
      padding: "0px 22px 20px 22px",
      backgroundColor: "#ffffff"
    },
    commentEditBtn: {
      width: 16,
      height: 16
    },
    commentEditTextArea: {
      width: "100%",
      padding: "0 12px",
      border: "1px solid rgb(202, 209, 215) !important",
      borderRadius: "4px",
      minHeight: "60px",
      marginBottom: "10px"
    },
    commentFullWidth: {
      width: "100%"
    },
    commentBtn: {
      marginRight: "10px"
    },
    waiverContainer: {
      border: "solid 1px #f2f2f2",
      backgroundColor: "#ffffff"
    },
    contentDisplay: {
      display: "flex"
    },
    communicationCard: {
      padding: "25px 20px 0px",
      backgroundColor: "#ffffff",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        padding: "25px 10px 0px"
      }
    },
    loanId: {
      color: "#5566d6",
      marginRight: "10px",
      fontSize: "12px",
      fontWeight: "bold",
      minWidth: "85px"
    },
    exceptionResponsiveButton: {
      padding: "20px 25px 0px 50px"
    },
    responsivePadding: {
      padding: "10px 25px 10px 53px"
    },
    responsivePaddingConditions: {
      padding: "0 0 0 15px"
      // justifyContent: "flex-end"
      // maxWidth: "100%",
      // flexBasis: "100%"
    },
    responsiveTypography: {
      fontSize: "10px",
      fontWeight: "bold",
      color: "#ffffff",
      height: "14px",
      padding: "6px 25px 6px 50px"
    },
    compactUserCard: {
      padding: "20px 0px 0px 4px"
    },
    responsiveSectionContainer: {
      padding: "5px 20px 15px 15px",
      borderBottom: "solid 1px #f2f2f2",
      borderRadius: "8px",
      backgroundColor: "#ffffff",
      [theme.breakpoints.down("sm")]: {
        borderBottom: "0",
        padding: "5px 10px 18px 10px"
      },
      [theme.breakpoints.down("md")]: {
        borderBottom: "0"
      },
      [theme.breakpoints.down("lg")]: {
        borderBottom: "0"
      }
    },
    responsiveRequestedUser: {
      fontSize: "14px",
      color: "#32325d",
      fontWeight: 600,
      padding: "0px 10px 0px 26px",
      textTransform: "capitalize"
    },
    responsiveStatusApproved: {
      color: "#ffffff",
      borderRadius: "6px",
      backgroundColor: "#2ece89",
      padding: "5px 8px",
      height: 25,
      display: "flex",
      alignItems: "center",
      marginLeft: "50px",
      marginTop: "10px",
      fontWeight: 600
    },
    responsiveRequestedDiv: {
      borderRadius: "6px",
      backgroundColor: "#fb623f",
      padding: "5px 8px",
      height: 25,
      display: "flex",
      alignItems: "center",
      fontWeight: 600
    },
    responsiveReject: {
      borderRadius: "6px",
      backgroundColor: "#f75676",
      padding: "5px 8px",
      height: 25,
      display: "flex",
      alignItems: "center",
      fontWeight: 600
    },
    responsiveContents: {
      display: "contents"
    },
    accountPadding: {
      // padding: "0 5px 0 0",
      padding: "7px 5px 0 0",
      marginBottom: "2px"
    },
    fileBarWrapper: {
      padding: "10px 0px"
    },
    fileSectionWrappers: {
      marginBottom: "24px"
    },
    fileBarWrapperContainer: {
      padding: "10px 10px",
      width: "100%"
    },
    fileContainer: {
      backgroundColor: "#f7fafc",
      padding: "5px"
    },
    statusInfoMobileHide: {
      display: "flex",
      justifyContent: "flex-end",
      "& > *": {
        marginLeft: "10px"
      },
      [theme.breakpoints.down("xs")]: {
        display: "none !important"
      }
      /* [theme.breakpoints.down("sm")]: {
        display: "none !important"
      },
      [theme.breakpoints.down("md")]: {
        display: "none !important"
      } */
    },
    statusInfoMobileView: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        borderBottom: "1px solid #f2f2f2",
        display: "block !important",
        textAlign: "left",
        padding: "0 0 15px 20px"
      }
      /* [theme.breakpoints.down("sm")]: {
        borderBottom: "1px solid #f2f2f2",
        display: "block !important",
        textAlign: "left",
        padding: "0 0 15px 20px"
      },
      [theme.breakpoints.down("md")]: {
        borderBottom: "1px solid #f2f2f2",
        display: "block !important",
        textAlign: "left",
        padding: "0 0 15px 20px"
      } */
    },
    inlineBlockMobile: {
      display: "inline-block",
      marginRight: "12px"
    },
    header: {
      background: "#e7e7e7",
      width: "calc(100% - 40px)",
      padding: "10px 0 10px 12px"
    },
    headerText: {
      fontSize: "14px",
      color: "#32325d",
      lineHeight: "1"
    },
    wrapperCard: {
      padding: "20px 0 5px 20px",
      borderBottom: "solid 1px #f2f2f2"
    },
    textAreaWrapper: {
      width: "100%",
      height: "89px",
      border: "1px solid #cad1d7",
      borderRadius: "4px",
      marginTop: "20px"
    },
    textArea: {
      fontFamily: "sans-serif !important",
      width: "100%",
      height: "100%",
      padding: "14px 12px 14px 12px",
      resize: "none",
      border: "none",
      borderRadius: "4px",
      fontSize: "14px"
    },
    cancelButton: {
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      backgroundColor: "#ffffff",
      border: "solid 1px #ececec",
      color: "#32325d",
      "&:hover": {
        backgroundColor: "#ffffff !important"
      }
    },
    buttonContainer: {
      marginTop: "20px"
    },
    postQueryButton: {
      marginLeft: 12,
      marginRight: 12,
      color: "#ffffff",
      fontSize: 12,
      padding: "6px 16px",
      textTransform: "none",
      boxShadow: "0 1px 3px 0 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      "&:hover": {
        backgroundColor: "#5566d6 !important"
      }
    },

    queryInfoContainer: {
      marginTop: 0
    },
    queryStatus: {
      color: "#01757e",
      fontSize: "11px"
    },
    iconContainer: {
      marginLeft: 4,
      position: "relative",
      color: "#4a90e2",
      top: "-2px"
    },
    iconButton: {
      padding: 0
    },
    image: {
      width: 16,
      height: 16,
      marginLeft: 6,
      objectFit: "contain",
      cursor: "pointer"
    },
    editContainer: {
      padding: 15,
      border: "solid 1px #ececec",
      boxShadow: "0 1px 3px 0 rgb(0 0 0 / 22%)",
      marginBottom: 15
    },
    closeQuery: {
      border: "solid 1px #ececec",
      boxShadow: "0 1px 3px 0 rgb(0 0 0 / 22%)",
      padding: "5px 10px",
      position: "absolute",
      marginTop: 3,
      cursor: "pointer",
      width: 100
    },
    commentCard: {
      backgroundColor: "#F6F9FC",
      borderRadius: "5px",
      marginBottom: "23px",
      borderTop: "solid 1px #e2e4f152"
    },
    scenarioContainer: {
      // padding: "11px 0px 17px 22px",
      backgroundColor: "#ffffff",
      borderBottom: "solid 1px #e9ecef"
    },
    scenarionViewDetailsContainer: {
      // backgroundColor: "#ffffff"
      // borderBottom: "solid 1px #e9ecef"
    },
    scenarioUserContainer: {
      padding: "24px 0px 17px 28px",
      borderBottom: "solid 1px #e9ecef"
    },
    scenarioSectionsContainer: {
      padding: "5px 0px 0px 27px"
    },
    scenarioSectionListContainer: {
      padding: "0px 0px 0px 22px"
    },
    scenarioDescriptionContainer: {
      padding: "0px 0px 10px 24px"
    },
    scenarioDescriptionListContainer: {
      padding: "0px 0px 10px 19px"
    },
    scenarioListContainer: {
      padding: "20px 7px 1px 22px"
    },
    lightColorHeading: {
      color: "#32325d",
      fontSize: 12,
      padding: "0px 5px 0px 0px",
      [theme.breakpoints.down("sm")]: {
        padding: "0 0 0 10px !important",
        // minWidth: "110px",
        textAlign: "left"
      }
    },
    scenarioTagsWrapper: {
      padding: "8px 15px 5px 0px"
    },
    scenarioSubject: {
      padding: "12px 0px 6px 0px",
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      color: "#32325d"
    },
    launchIcon: {
      padding: 0,
      minWidth: "auto",
      border: 0,
      marginLeft: 5
    },
    onHoldTag: {
      fontSize: 8,
      fontWeight: "bold",
      color: "#fff",
      backgroundColor: "#fb623f",
      borderRadius: "8px",
      padding: "2px 5px",
      marginLeft: "3px"
    },
    kdmButtonClass: {
      minWidth: "unset",
      "& .MuiButton-startIcon": {
        marginLeft: 0,
        marginRight: 0
      }
    }
  })
);
