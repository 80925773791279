import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum formEditStatusEnum {
  notEditing= "NOT_EDITING",
  editing = "EDITING",
  notSaved = "NOT_SAVED",
};

export interface LoanDetailsStore {
  loanDetails: any;
  propertyDetails: any;
  autoSaveValues: any,
  isFormEditing: boolean;
  formEditStatus: string
};

const initialState: any = {
  loanDetails: {
    loanConfig: null,
    loanInfo: {
      existingDebtCurrency: null,
      closingCostsCurrency: null,
      appraiserDYAsStabilizedPercentage: null,
      exceptions: null,
      approved: null,
      comments: null,
      excessFirstLossCurrency: null,
      interestReserveIRCurrency: null,
      twelveMonthIRCurrency: null,
      annualDSInitialLoanCurrency: null,
      annualDSMaxLoanCurrency: null
    },
    loanCredit: {
      readyforPurchase: null,
      summaryOutputSent: null,
      purchaseAdviceRequirement: null,
      recommendation: null,
      additionalNotes: null,
      purchaseDate: null,
      inPlaceOccupancyNumeric: null,
      inPlaceNOINumeric: null,
      inPlaceDSCRNumeric: null,
      stabilizedDSCRNumeric: null,
      takeoutDSCRNumeric: null,
      covenants: null,
      appraiserName: null,
      appraisalCompany: null,
      appraisalManagementCompany: null,
      appraisalFormType: null,
      licenseNumber: null,
      appraisalDate: null,
      appraisalComments: null,
      restricted: null,
      appraisalReview: null,
      appraisalReviewDate: null,
      appraisalReviewComments: null,
      engineeringReview: null,
      engineeringReviewDate: null,
      engineeringReviewComments: null,
      environmentalReview: null,
      environmentalReviewDate: null,
      environmentalReviewComments: null,
      seismicReview: null,
      seismicReviewDate: null,
      seismicReviewComments: null,
      truliaCrimeCheck: null,
      phase1ClosingDate: null,
      phase1HoldbackPercentage: null,
      phase1HoldbackAmount: null,
      phase1ClosingDatePayment: null
    }
  },
  propertyDetails: [],
  autoSaveValues: {
    callAutoSave: false,
    tabType: "loan",
    changedFieldId: []
  },
  isFormEditing: false,
  isDataEntry: false,
  formEditStatus: formEditStatusEnum.notEditing,
  openCommentDialog: false,
  fieldEditCount: 0,
  showLockinPopup: false,
  isFetchLoanLandMarks: false,
  landMarks: []
};

const loanDetailSlice = createSlice({
  name: "loanDetails",
  initialState,
  reducers: {
    updateFormEditStatus: (state, action: PayloadAction<any>) => {
      const { value, editStatus }: { value: any; editStatus: any } =
        action.payload;
      let updatedStatus: any =
        editStatus == "notSaved"
          ? formEditStatusEnum.notSaved
          : editStatus == "editing"
          ? formEditStatusEnum.editing
          : formEditStatusEnum.notEditing;

      state.isFormEditing = value;
      state.formEditStatus = updatedStatus;
    },
    updateOpenCommentDialog: (state, action: PayloadAction<any>) => {
      const { value } = action.payload;
      state.openCommentDialog = value;
    },
    updateLoanStageStatus: (state, action: PayloadAction<any>) => {
      const { value } = action.payload;
      state.isDataEntry = value;
    },
    updateFieldEditCount: (state, action: PayloadAction<any>) => {
      state.fieldEditCount = action.payload;
    },
    updateShowLockinPopup: (state, action: PayloadAction<any>) => {
      state.showLockinPopup = action.payload
    },
    updateIsFetchLoanLandMarks: (state, action: PayloadAction<any>) => {
      state.isFetchLoanLandMarks = action.payload
    },
    updateLoanLandMarksForRateLocks: (state, action: PayloadAction<any>) => {
      state.landMarks = action.payload
    }
  }
});

export const loanDetailReducer = loanDetailSlice.reducer;

export const {
  updateFormEditStatus,
  updateOpenCommentDialog,
  updateLoanStageStatus,
  updateFieldEditCount,
  updateShowLockinPopup,
  updateIsFetchLoanLandMarks,
  updateLoanLandMarksForRateLocks
} = loanDetailSlice.actions;