/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography, Avatar } from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { APPRAISAL_STATUS_ENUM } from "../../../utils/constants";
import errorIcon from "../../../images/guidelineOutput/browse-copy.svg";
import { useStyles } from "./ToorvalStyles";
import { RootState } from "../../../stores/rootReducer";
import { LoanDetails } from "../../create-loan.reducerHelper";
import {
  getAppraisalLandmarks,
  getLoanAppraisalInformation,
  getAppraisalID
} from "../../../stores/appraisal/appraisal.action";
import {
  AppraisalLandmarks,
  AppraisalState
} from "../../../stores/appraisal/appraisal.reducer";
import { getUserName } from "../../create-loan.action";
import iconSuccess from "../../../images/icon_test_pass.svg";

const UI_INFO_BY_STATUS: {
  [key: string]: { color: string; infoMessage: string; key: string };
} = {
  [APPRAISAL_STATUS_ENUM.APPROVED_CONDITIONALLY]: {
    color: "#42ba96",
    infoMessage: "Conditionally Approved By",
    key: "APPROVED CONDITIONALLY"
  },
  [APPRAISAL_STATUS_ENUM.APPROVED]: {
    color: "#42ba96",
    infoMessage: "Approved By",
    key: "APPROVED"
  },
  [APPRAISAL_STATUS_ENUM.REJECTED]: {
    color: "#f75676",
    infoMessage: "Rejected By",
    key: "REJECTED"
  }
};

export const ToorvalAppraisalStatus = ({
  propInfotoorvalId
}: {
  propInfotoorvalId: string | null;
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [
    latestLandmark,
    setLatestLandmark
  ] = useState<AppraisalLandmarks | null>(null);
  const [appraisalUserData, setAppraisalUserData] = useState<any>(null);
  const appraisalID = getAppraisalID();

  let { loanId } = useSelector<RootState, LoanDetails>(
    state => state.createLoanStore?.loanDetails?.loanDetailId
  );
  if (propInfotoorvalId) {
    loanId = propInfotoorvalId;
  }

  const { appraisalLandmarks } = useSelector<RootState, AppraisalState>(
    state => state.appraisalStore
  );

  useEffect(() => {
    if (loanId) dispatch(getLoanAppraisalInformation(loanId));
  }, [loanId]);

  useEffect(() => {
    if (!appraisalID) return;
    dispatch(getAppraisalLandmarks(appraisalID));
  }, [appraisalID]);

  useEffect(() => {
    if (appraisalLandmarks.length === 0 || latestLandmark !== null) return;
    const latestLandmarkData = appraisalLandmarks.slice(-1);
    setLatestLandmark(latestLandmarkData[0]);
  }, [appraisalLandmarks]);

  const getUserData = async (partyId: string) => {
    const userData: any = await getUserName(partyId);
    setAppraisalUserData(userData.data);
  };

  useEffect(() => {
    if (latestLandmark === null || appraisalUserData !== null) return;
    getUserData(latestLandmark.party_id);
  }, [latestLandmark]);

  const getDate = (date: any) => {
    return moment.utc(date).format("MM/DD/YYYY");
  };

  const userInitials = useMemo(() => {
    const { firstName, lastName } = appraisalUserData || {};
    let intials = "";
    if (typeof firstName === "string") {
      intials += firstName.charAt(0);
    }
    if (typeof lastName === "string") {
      intials += lastName.charAt(0);
    }
    return intials;
  }, [appraisalUserData]);

  if (!latestLandmark) {
    return <></>;
  }

  return (
    <div
      style={{
        padding: "5px"
      }}
    >
      <div
        style={{ display: "flex", alignItems: "center", padding: "15px 15px" }}
      >
        <span style={{ fontSize: "12px", fontWeight: 600 }}>
          Toorval Appraisal
        </span>
        <span style={{ marginLeft: "5px" }}>
          {latestLandmark?.appraisal_status ===
          APPRAISAL_STATUS_ENUM.APPROVED ? (
            <img src={iconSuccess} alt="Test Success" />
          ) : (
            <img
              src={errorIcon}
              alt="Test Failure"
              style={{ marginLeft: 6, width: 16, height: 16 }}
            />
          )}
        </span>
      </div>
      <Grid
        container
        className={classes.approvedTestWrapper}
        style={{
          border: `1px solid ${
            UI_INFO_BY_STATUS[latestLandmark?.appraisal_status]?.color
          }`
        }}
      >
        <Grid xs={7} sm={7} item>
          <Typography
            variant="h2"
            className={classes.testNameText}
            data-testId="test-name"
          >
            Toorval Appraisal
          </Typography>
        </Grid>
        <Grid
          xs={5}
          sm={5}
          item
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div
            className={classes.approvedLabel}
            style={{
              backgroundColor:
                UI_INFO_BY_STATUS[latestLandmark?.appraisal_status]?.color
            }}
          >
            {latestLandmark?.appraisal_status ===
            APPRAISAL_STATUS_ENUM.REJECTED ? (
              <>
                <img
                  src={errorIcon}
                  alt="Test Failure"
                  style={{ marginLeft: 6, width: 16, height: 16 }}
                />
              </>
            ) : (
              <CheckRoundedIcon
                className={classes.approvedIcon}
                htmlColor="#2ece89"
              />
            )}

            <Typography
              className={classes.approvedText}
              data-testId="test-approved-label"
            >
              {UI_INFO_BY_STATUS[latestLandmark?.appraisal_status]?.key}
            </Typography>
          </div>
        </Grid>
        <Grid container xs={12} sm={12} className={classes.infoContainer}>
          <Typography className={classes.valueText}>
            {UI_INFO_BY_STATUS[latestLandmark?.appraisal_status]?.infoMessage}
          </Typography>
          <div className={classes.avatarClass} style={{ padding: "0px 10px" }}>
            <Avatar style={{ width: 30, height: 30 }}>
              <div id="profileImage" className={classes.profileImage}>
                {userInitials.toUpperCase()}
              </div>
            </Avatar>
          </div>

          <span className={classes.valueText}>
            {appraisalUserData?.firstName} <strong> • </strong>
            {getDate(latestLandmark?.date)}
          </span>
        </Grid>
      </Grid>
    </div>
  );
};
