/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import {
  ClickAwayListener,
  Paper,
  Popper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  IconButton,
  InputBase
} from "@mui/material";

import { filterMasterView, valueFormatterForFilter } from "./formatter";
import { useColumnTypeComponents } from "../custom-hooks/useColumnTypeComponents";
import { SearchIcon } from "../icons/search";

interface ListOptions {
  id?: string;
  key: string;
  viewText?: string;
}
interface ColumnTypeFilterProps {
  columnName: string;
  isLoading?: boolean;
  handleFilterToggle?: Function;
  handleCheckBoxChange?: Function;
  checked?: string[];
  options?: ListOptions[];
  overRideStyles?: any;
  filterTitle?: any;
  showSelectAll?: boolean;
  isFilterSearchRequired?: boolean;
  isFrozen: boolean;
}

const ColumnTypeFilter = (props: ColumnTypeFilterProps) => {
  const {
    columnName,
    isLoading,
    checked = [],
    options = [],
    filterTitle,
    handleFilterToggle = () => {
      /* default value */
    },
    handleCheckBoxChange = () => {
      /* default value */
    },
    overRideStyles,
    showSelectAll,
    isFilterSearchRequired,
    isFrozen
  } = props;
  const columnLoanTypeFilter = false;
  const {
    sortedOptions,
    handleListKeyDown,
    handleToggle,
    handleClose,
    handleCheckBoxToggle,
    open,
    anchorRef,
    useStyles
  } = useColumnTypeComponents({
    handleFilterToggle,
    columnName,
    loanTypeOptions: options,
    columnLoanTypeFilter,
    handleCheckBoxChange,
    checked
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const classes = useStyles();

  const changeZIndex = (ele: any, override?: boolean) => {
    const state = override ?? open;
    let headerEle = ele?.closest('.MuiTableCell-root');
    if (headerEle) {
      headerEle.style.zIndex = state ? isFrozen ? 1 : 0 : 2;
    }
  };

  const getsortedOptions = useMemo((): any[] => {
    if (!showSelectAll && !isFilterSearchRequired) {
      return sortedOptions;
    }
    let sortedOptionsTemp = sortedOptions;
    if (searchTerm.length > 1) {
      sortedOptionsTemp = sortedOptionsTemp.filter(
        (item) =>
          item.key.toLowerCase().includes(searchTerm.toLowerCase()) &&
          item.key !== "Select All"
      );
    }
    // If the sorted options array is not empty, add a "Select All" option at the beginning
    showSelectAll &&
      sortedOptionsTemp.length &&
      !sortedOptionsTemp.some((option) => option.key === "Select All") &&
      searchTerm.length <= 1 &&
      sortedOptionsTemp.unshift({
        key: "Select All",
        doc_count: Math.random()
      });

    return sortedOptionsTemp;
  }, [sortedOptions, searchTerm]);

  const handleSearchChange = (event: any) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
  };

  const handleCloseFilter = (event: any) => {
    setSearchTerm("");
    handleClose(event);
    changeZIndex(anchorRef.current);
  };

  // Conditionally set the height
  const heightStyle = isFilterSearchRequired && sortedOptions.length > 0
    ? { height: sortedOptions.length * 50 }
    : {};

  return (
    <div style={{ position: "relative" }}>
      {filterMasterView({
        open,
        handleToggle,
        anchorRef,
        isfilterApplied: checked.length > 0,
        classes,
        changeZIndex
      })}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
        style={{ zIndex: 1, position: "absolute", top: 20, willChange: "transform" }}
      >
        <ClickAwayListener onClickAway={(e) => handleCloseFilter(e)}>
          <Paper
            classes={{
              root:
                isFilterSearchRequired && sortedOptions.length > 0
                  ? classes.menuRootWithFilter
                  : classes.menuRoot
            }}
            style={heightStyle}
          >
            {isLoading && (
              <Typography
                classes={{
                  root: classes.zeroState
                }}
              >
                Fetching Results...
              </Typography>
            )}
            {isFilterSearchRequired && sortedOptions.length > 0 && (
              <div className={classes.filterSearch}>
                <Paper component="form" className={classes.inputSearch}>
                  <IconButton style={{ padding: 2 }} size="large">
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    placeholder="Search"
                    value={searchTerm}
                    style={{ padding: 0 }}
                    onChange={handleSearchChange}
                    onKeyDown={(event) => {
                      if (event.keyCode === 13) {
                        //Enter key pressed
                        event.stopPropagation();
                        event.preventDefault();
                        return;
                      }
                    }}
                  />
                </Paper>
              </div>
            )}

            {!isLoading && (
              <div>
                <List
                  onKeyDown={handleListKeyDown}
                  className={
                    isFilterSearchRequired && sortedOptions.length > 0
                      ? `${classes.menuListWithFilter} ${overRideStyles?.menuList}`
                      : `${classes.menuList} ${overRideStyles?.menuList}`
                  }
                  id={"filterOptions"}
                >
                  {getsortedOptions.map((value: ListOptions) => {
                    const viewText: any = value?.viewText ?? value?.key;
                    return (
                      <React.Fragment key={value.key}>
                        <ListItem
                          key={value.key}
                          onClick={(e) => handleCheckBoxToggle(e, filterTitle)}
                          disableGutters
                          dense
                          button
                          id={value.key}
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                        >
                          <ListItemIcon
                            classes={{
                              root: classes.listIconRoot
                            }}
                          >
                            <Checkbox
                              color="primary"
                              size="small"
                              checked={checked?.includes(value.key)}
                              tabIndex={-1}
                              disableRipple
                            />
                            {/* <input
                        type="checkbox"
                        checked={checked.indexOf(value.key) !== -1}
                      ></input> */}
                          </ListItemIcon>
                          <ListItemText
                            primary={valueFormatterForFilter(viewText)}
                          />
                        </ListItem>
                      </React.Fragment>
                    );
                  })}
                  {isFilterSearchRequired &&
                    !getsortedOptions.length &&
                    !isLoading && (
                      <Typography
                        classes={{
                          root: classes.zeroState
                        }}
                      >
                        No Data Found
                      </Typography>
                    )}
                </List>
              </div>
            )}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default ColumnTypeFilter;
