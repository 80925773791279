/* eslint-disable no-param-reassign */
/* eslint-disable no-else-return */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";
import { TableRow, TableCell, Theme, Grid, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { TextFieldForm } from "@toorak/tc-common-fe-sdk";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { SortButton } from "./SortButton";
import { CustomTooltip } from "./CustomToolTip";
import { SearchFilter } from "./FilterComponents/SearchFilter";
import { AutoCompleteSearchBoxFilter } from "./FilterComponents/AutoCompleteSearchBoxFilter";
import { getFilterOptions } from "../../../stores/SettlementAndPurchase/SettlementAndPurchase.action";
import { PopOverInfo } from "../../../key-deal-metrics/PopOverInfo";
import { OriginatorIconWithDots, ToorakIconWithDots } from "../../Icons";
import { InfoIcon } from "../../../icons/info";
import { HtmlWhiteTooltip } from "../../../utils/Tooltip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cell: {
      height: "56px",
      padding: "0px 10px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsi",
      // display: "flex",
      alignItems: "flex-end"
    },
    inputField: {
      minWidth: "150px",
      height: "38px"
    },
    viewField: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden"
    },
    headerText: {
      whiteSpace: "break-spaces"
    }
  })
);

interface Props {
  rowList: any;
  header?: boolean;
  style?: any;
  colSpan?: any;
}

interface InputProps {
  value: string;
  isEditable?: boolean;
  type?: any;
  key?: string;
  loanType?: string;
  originatorPartyId?: string;
  fieldKey?: string;
  updateCallBack?: any;
  loanId?: string;
  sortable?: boolean;
  filter?: boolean;
  toolTip?: boolean;
  toolTipData?: any[];
  filterType?: string;
  bundleId?: string;
  style?: any;
  editHistoryData?: any[];
  multipPropertyToolTip?: boolean;
  userDetails?: any;
}

export const TableRowComponent = (props: Props) => {
  const classes = useStyles();
  // const [ndate, setDate] = useState<any>({});
  const [filterOptions, setFilterOptions] = useState<any>({});
  const { rowList, header, style, colSpan, ...rest } = props;
  // const [updateFields, setUpdateFields] = useState<any>({});
  const valueCellBackground = {
    backgroundColor: "#ffffff",
    ...style?.td
  };
  const headerCellBackground = {
    backgroundColor: "#f6f9fc",
    ...style?.th
  };
  // eslint-disable-next-line react/destructuring-assignment
  const Style = header ? headerCellBackground : valueCellBackground;

  const [closeFilter, setCloseFilter] = useState<any>({});
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleFilterTooltipClose = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
    close: boolean = true
  ) => {
    setCloseFilter({
      ...closeFilter,
      [index]: close
    });
  };

  const ViewModeTextField = (prop: any) => {
    const { item } = prop;
    return (
      <div className={classes.viewField} style={item?.style?.text}>
        <TextFieldForm
          showEditHistoryOnLine
          isViewMode
          notRequired
          value={item?.value}
          infoIconData={null}
          editedLabel={false}
          editedData={item?.editHistoryData || []}
          style={{
            padding: "0px",
            border: "none",
            borderRadius: 0
          }}
          viewtextStyles={{
            fontSize: "12px",
            margin: 0,
            padding: 0,
            fontWeight: "normal",
            textOverflow: "ellipsis",
            // width: "100%",
            overflow: "hidden"
          }}
          fromSettelement
        />
      </div>
    );
  };
  return (
    <TableRow style={style?.parentStyle}>
      {rowList.map((item: InputProps, index: number) => {
        return (
          <TableCell
            key={index}
            align="left"
            className={classes.cell}
            style={Style}
            colSpan={colSpan}
          >
            {header ? (
              <Grid
                container
                justifyContent="space-between"
                style={{
                  flexWrap: "nowrap",
                  height: "100%",
                  display: "flex",
                  alignItems: "center"
                }}
                alignItems="flex-end"
              >
                <Grid
                  item
                  className={item?.style?.text ? classes.headerText : ""}
                  style={item?.style?.text}
                >
                  {item?.value}
                </Grid>
                {item?.sortable || item?.filter ? (
                  <Grid item>
                    <Grid
                      container
                      style={{ flexWrap: "nowrap", marginLeft: "10px" }}
                    >
                      {item?.filter ? (
                        <Grid item>
                          <IconButton
                            style={{ padding: 0 }}
                            onClick={async (
                              event: React.MouseEvent<HTMLElement>
                            ) => {
                              setFilterOptions([]);
                              setAnchorEl(event.currentTarget);
                              handleFilterTooltipClose(
                                event,
                                index,
                                !(closeFilter[index] || false)
                              );
                              if (
                                item?.filterType ===
                                  "AutoCompleteSearchBoxFilter" &&
                                !closeFilter[index]
                              ) {
                                const resp: any = await getFilterOptions(item);
                                setFilterOptions(
                                  resp.data?.response?.aggregations
                                );
                              }
                            }}
                            size="large">
                            {closeFilter[index] || false ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreOutlinedIcon />
                            )}
                          </IconButton>
                          <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            // getContentAnchorEl={null}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center"
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center"
                            }}
                            keepMounted
                            open={closeFilter[index] || false}
                            onClose={(event: React.MouseEvent<HTMLElement>) => {
                              handleFilterTooltipClose(
                                event,
                                index,
                                !(closeFilter[index] || false)
                              );
                            }}
                          >
                            <MenuItem style={{ padding: "0px 10px" }}>
                              {item?.filterType === "SearchBoxFilter" ? (
                                <SearchFilter fieldKey={item?.fieldKey} />
                              ) : item?.filterType ===
                                "AutoCompleteSearchBoxFilter" ? (
                                <AutoCompleteSearchBoxFilter
                                  data={item}
                                  filterOptions={filterOptions}
                                />
                              ) : null}
                            </MenuItem>
                          </Menu>
                        </Grid>
                      ) : null}
                      {item?.sortable ? (
                        <Grid item>
                          <SortButton fieldKey={item?.fieldKey} {...rest} />
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            ) : item?.userDetails ? (
              <Grid style={{ display: "flex", alignItems: "center" }}>
                <span style={{ paddingRight: "5px" }}>
                  {item?.userDetails.action}
                </span>{" "}
                by{" "}
                <span style={{ padding: "0 10px 0 5px" }}>
                  {" "}
                  {item?.userDetails.userFullName}{" "}
                </span>
                {item?.userDetails?.accountTypeFirstLetter && (
                  <span style={{ display: "inline-flex" }}>
                    {item?.userDetails?.accountTypeFirstLetter[0] !== "O" ? (
                      <ToorakIconWithDots />
                    ) : (
                      <OriginatorIconWithDots />
                    )}
                  </span>
                )}
                {
                  item.userDetails?.toolTip && (
                      <HtmlWhiteTooltip
                        title={item.userDetails?.toolTip}
                        placement="left"
                        arrow
                      >
                        <div style={{display:"flex", marginLeft:"0.5rem"}}>
                          <InfoIcon viewBox={"0 2 18 18"}/>
                        </div>
                      </HtmlWhiteTooltip>
                  )
                }
              </Grid>
            ) : !item?.toolTip ? (
              <ViewModeTextField item={item || "N/A"} />
            ) : item?.multipPropertyToolTip ? (
              <Grid container alignItems="center">
                <Grid
                  item
                  className={classes.viewField}
                  style={{
                    // color: "#424242",
                    fontSize: 12
                    // textDecoration: "underline #5f72e4"
                  }}
                >
                  {item?.value}
                </Grid>
                <Grid item style={{ display: "flex", paddingLeft: "3px" }}>
                  {item?.toolTipData && item?.toolTipData?.length > 1 ? (
                    <PopOverInfo popOverData={item?.toolTipData} />
                  ) : null}
                </Grid>
              </Grid>
            ) : (
              <CustomTooltip
                arrow
                title={
                  <>
                    {item?.toolTipData?.length ? (
                      <ul
                        style={{
                          padding: 0,
                          marginLeft: 0,
                          marginRight: 0,
                          marginBottom: 0
                        }}
                      >
                        {item?.toolTipData.map((data: any, index1: number) => (
                          <li
                            key={`tooltip-${index1}`}
                            style={{
                              listStyleType: "none",
                              padding: "4px 4px 4px 4px",
                              textAlign: "center",
                              borderBottom:
                                index1 !== (item?.toolTipData?.length || 0) - 1
                                  ? "1px solid black"
                                  : "none"
                            }}
                          >
                            {data}
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </>
                }
              >
                <div className={classes.viewField} style={item?.style?.text}>
                  {item?.value}
                </div>
              </CustomTooltip>
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
