/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/extensions */
import { Grid, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserTags } from "@toorak/tc-common-util-sdk";
import { useStyles } from "../exceptions-and-enquiries/ExceptionCardStyle";
import { ScenarioListView } from "./ScenariosListView";
import { DynamicHeader } from "../DynamicHeader";
import {
  getScenarioDetailFromAggregate,
  getUserLists,
  updateScenarioViewDetailsInRedux
  // updateUserListInRedux
} from "../../stores/Scenario/ScenarioActions";
import { RootState } from "../../stores/rootReducer";
import { getUserFullAndOrgName } from "../exceptions-and-enquiries/ExceptionsCard";
import { FileBar } from "../waiver-form/FileBar";
import { handleDownload } from "../exceptions-and-enquiries/DocumentUploadApis";
import { CommentsBlock } from "../loan-lists/comments-section/components/CommentsBlock";
import {
  // clearLoader,
  hideLoader,
  showLoader
} from "../../stores/loaderAndException/loaderAndException.action";
import { getDateInFormat } from "../../utils/utils";
import { PageNotFound } from "../../ui-components/pageNotFound";
// import { isEmptyValue } from "../../utils/formatChecks";
import { getCookie } from "../../utils/cookies";
import { clearLoader } from "../../stores/loaderAndException/loaderAndException.reducer";
import { updateUserList } from "../../stores/Scenario/ScenarioReducer";

export const generatePropertyChip = (scenarioPropertyDetails: any) => {
  const locationArr: any[] = [];
  if (scenarioPropertyDetails instanceof Array && scenarioPropertyDetails) {
    scenarioPropertyDetails.forEach((property: any) => {
      const ele =
        property &&
        property?.propertyLocation &&
        `${property?.propertyLocation?.addressLine1} ${property?.propertyLocation?.city} ${property?.propertyLocation?.state} ${property?.propertyLocation?.postalCode}`;
      locationArr.push(ele);
    });
  }
  return locationArr;
};

export const ScenarioViewDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { scenarioId } = useParams<any>() as any;
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    dispatch(showLoader());
    const scenarioDetails = getScenarioDetailFromAggregate(scenarioId);
    scenarioDetails
      .then((response: any) => {
        dispatch(updateScenarioViewDetailsInRedux(response));
        dispatch(hideLoader());
        setLoading(true);
      })
      .catch((err) => {
        dispatch(clearLoader());
        console.log("err", err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // get all user list for comments tag
  useEffect(() => {
    dispatch(showLoader());
    const userList = getUserLists();
    userList
      .then((resp: any) => {
        const hits: any[] = resp.data?.response?.hits?.hits
          ? resp.data.response.hits.hits
          : [];
        // const userListArray: any = [];
        const currentPersonId = getCookie("person_id");
        const userSortListArray = getUserTags(hits, currentPersonId);
        dispatch(updateUserList(userSortListArray));
        dispatch(hideLoader());
      })
      .catch((err: any) => {
        dispatch(clearLoader());
        console.log("err", err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const getPersonRole = (roleInfo: any) => {
  //   let role = "";
  //   const roleToCheck = ["LAT_MEMBER", "ORIGINATOR_MEMBER"];
  //   roleInfo.forEach((item: any) => {
  //     if (item.roleName === roleToCheck[0]) {
  //       role = "LAT (Toorak)";
  //     }
  //     if (item.roleName === roleToCheck[1]) {
  //       role = "Originators";
  //     }
  //   });
  //   return role;
  // };

  // const sortTags = (a: any, b: any) => {
  //   const tagA = a.tagType.toUpperCase();
  //   const tagB = b.tagType.toUpperCase();

  //   let comparison = 0;
  //   if (tagA > tagB) {
  //     comparison = 1;
  //   } else if (tagA < tagB) {
  //     comparison = -1;
  //   }
  //   return comparison;
  // };

  const scenarioViewDetails = useSelector<
    RootState,
    {
      waiverDetails: any[] | any;
      scenarioPropertyDetails: any[] | any;
      documentDetails: any[];
    }
  >((state) => state.scenarioResultsStore?.scenarioViewDetails);

  const userTags = useSelector<RootState, any>(
    (state) => state.scenarioResultsStore.userLists
  );

  const attachedLoanArr = useSelector<RootState, any>(
    (state: { scenarioResultsStore: { attachedLoanDetails: any } }) =>
      state.scenarioResultsStore?.attachedLoanDetails
  );
  const partyId =
    scenarioViewDetails?.waiverDetails instanceof Array
      ? scenarioViewDetails?.waiverDetails[0]?.createdBy
      : null;
  const userDetails = scenarioViewDetails
    ? getUserFullAndOrgName(scenarioViewDetails, partyId)
    : null;

  const nameInitials =
    userDetails && userDetails[0] !== "" ? userDetails[0] : null;
  const userName = userDetails && userDetails[1] !== "" ? userDetails[1] : "";
  let createdOnDate = "";
  let status = "";
  let subject = "";
  let description = "";
  let waiverId = "";

  if (
    scenarioViewDetails &&
    scenarioViewDetails?.waiverDetails &&
    scenarioViewDetails?.waiverDetails[0]
  ) {
    createdOnDate = getDateInFormat(
      scenarioViewDetails?.waiverDetails[0]?.createdOn?.replace(/ /g, "T")
    );
    status =
      scenarioViewDetails?.waiverDetails[0]?.waiverInfo &&
      scenarioViewDetails?.waiverDetails[0]?.waiverInfo?.finalResponse;

    subject = scenarioViewDetails?.waiverDetails[0]?.title;
    description = scenarioViewDetails?.waiverDetails[0]?.description;

    waiverId = scenarioViewDetails?.waiverDetails[0].waiverId;
  }

  const finalStatus =
    status && status === "open" ? "SCENARIO REQUESTED" : status;

  const scenarioPropertyDetails =
    scenarioViewDetails && scenarioViewDetails?.scenarioPropertyDetails;

  const scenarioLoanType =
    scenarioViewDetails &&
    scenarioViewDetails?.scenarioPropertyDetails &&
    scenarioViewDetails?.scenarioPropertyDetails[0] &&
    scenarioViewDetails?.scenarioPropertyDetails[0]?.loanType;
  const documentDetails =
    scenarioViewDetails && scenarioViewDetails?.documentDetails;

  const getHeadingText = () => {
    return `Scenario ID: ${scenarioId}`;
  };

  const generateBackLocationPath = () => {
    return `/loans/list?tab=4`;
  };
  return (
    <>
      {loading ? (
        <>
          {scenarioViewDetails?.waiverDetails.length ? (
            <Grid container data-testid="scenario-details">
              <DynamicHeader
                path="loans/list?tab=4"
                heading={getHeadingText()}
              />
              <Container style={{ paddingTop: "60px" }}>
                <Grid className={classes.containerShadow}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ScenarioListView
                      scenarioViewDetail={scenarioViewDetails}
                      nameInitials={nameInitials}
                      userName={userName}
                      createdOnDate={createdOnDate}
                      finalSTATUS={finalStatus}
                      subjecT={subject}
                      scenarioID={scenarioId}
                      descriptioN={description}
                      propertyChips={generatePropertyChip(
                        scenarioPropertyDetails
                      )}
                      scenarioLoanType={scenarioLoanType}
                      waiverId={waiverId}
                      attachedLoanArr={attachedLoanArr}
                      isDetailView
                    />
                  </Grid>
                  <Grid
                    className={classes.filesContainerScenario}
                    data-testid="files-container"
                  >
                    {documentDetails &&
                      documentDetails.length > 0 &&
                      documentDetails?.map((item: any, key: number) => (
                        <section
                          className={classes.fileBarWrapper}
                          key={item.name}
                        >
                          <Grid item xs={12} className={classes.fileContainer}>
                            <Grid container>
                              <FileBar
                                downloadHandler={() =>
                                  handleDownload(
                                    item,
                                    scenarioId || "",
                                    "scenario"
                                  )
                                }
                                value={item}
                              />
                            </Grid>
                          </Grid>
                        </section>
                      ))}
                  </Grid>
                  <Grid className={classes.commentsContainerSC}>
                    <CommentsBlock
                      hostId={scenarioId}
                      userTags={userTags}
                      hostType="scenario"
                      loanId={scenarioId}
                      loanStage={scenarioLoanType}
                      hostName="scenario"
                      // approvalType={approvalType}
                      // approvalComment={true}
                      // onApproval={approveWaiver}
                      // callBack={callBack}
                      showSenderAvatar
                    />
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          ) : (
            <PageNotFound
              header="Invalid Scenario"
              errorMsg="This Scenario is deleted"
              actionButtonText="Back"
              generateBackLocationPath={generateBackLocationPath}
            />
          )}
        </>
      ) : null}
    </>
  );
};
