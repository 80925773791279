/* eslint-disable react/no-array-index-key */
import React from "react";
import { TableBody } from "@mui/material";
import { TableRowComponent } from "./TableRowComponent";

export const TableBodyComponent = ({ data, style }: any) => {
  console.log({ data });

  return (
    <TableBody>
      {data.map((item: any, index: number) => {
        return <TableRowComponent key={index} rowList={item} style={style} />;
      })}
    </TableBody>
  );
};
