import React from "react";
import { useNavigate } from "react-router-dom";
import { WarningModal } from "@toorak/tc-common-fe-sdk";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";

interface PageNotFoundProps {
  header?: string;
  errorMsg?: string;
  generateBackLocationPath?: any;
  actionButtonText?: string;
}

export const PageNotFound = (props: PageNotFoundProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    generateBackLocationPath,
    header,
    errorMsg,
    actionButtonText
  } = props;
  return (
    <>
      <WarningModal
        isOpen
        primaryBtnName={actionButtonText || "Loan Status"}
        // handleClose={() => navigate('/loans/list'))}
        closeDisable
        handleClose={() => navigate(generateBackLocationPath())}
        header={header}
        body={errorMsg}
      />
    </>
  );
};
