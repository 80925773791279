/* eslint-disable no-console */
import localForage from "localforage";

// LIMIT set to 2 GB in bytes
export const DBMAXSIZE: number = 2147483648;
// export const DBMAXSIZE: number = 7340032;

const DBNAME = "CACHESIZE";

export const CacheSizeProvider = () => {
  const cacheProvider = localForage.createInstance({
    name: DBNAME
  });

  return cacheProvider;
};
