/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  AppraisalForm,
  initialState,
  AppraisalState
} from "../../../stores/appraisal/appraisal.reducer";
import { getLoanAppraisalInformation } from "../../../stores/appraisal/appraisal.action";
import { RootState } from "../../../stores/rootReducer";
//import { UPDATE_PREVIOUS_STAGE } from "../../create-loan.action";/
import { LoanStage } from "@toorak/tc-common-fe-sdk";
import { isPurchasedLoansOnly } from "../../../utils/AccessManagement";
import { updatePreviousStageRedux } from "../../create-loan.reducer";

let useLoanId: any = null;
let useLoanStage: any = null;

export function useAppraisalForm(toorvalLoanId?: string): AppraisalForm {
  const dispatch = useDispatch();
  const { loanId } = useParams<{ loanId: string }>();
  useConvertedAppraisalForm();
  const loanIDToCheck = toorvalLoanId || loanId;
  const store = useSelector<RootState, AppraisalState>(
    (state) => state.appraisalStore,
    shallowEqual
  );

  const dataNotLoaded = shallowEqual(initialState.form, store.form);
  useEffect(() => {
    /* eslint-disable-next-line eqeqeq */
    if (dataNotLoaded && useLoanId != loanIDToCheck && !isPurchasedLoansOnly())
      dispatch(getLoanAppraisalInformation(loanIDToCheck as string));
    useLoanId = loanIDToCheck;
    return () => {
      useLoanId = null;
    };
  }, [loanIDToCheck]);

  return store.form;
}

export function useConvertedAppraisalForm() {
  const dispatch = useDispatch();
  const { loanStage } = useParams<{ loanStage: any }>();
  const { convertedTo } = useSelector<RootState, any>(
    (state) => state.appraisalStore.form,
    shallowEqual
  );

  useEffect(() => {
    if (convertedTo && loanStage !== useLoanStage) {
      const isNotSameStage = loanStage !== convertedTo;
      // dispatch({
      //   type: UPDATE_PREVIOUS_STAGE,
      //   payload: {
      //     viewMode: isNotSameStage,
      //     availableLoanStage: [convertedTo, LoanStage.tv]
      //   }
      // });
      dispatch(
        updatePreviousStageRedux({
          payload: {
            viewMode: isNotSameStage,
            availableLoanStage: [convertedTo, LoanStage.tv]
          }
        })
      );
    }
    useLoanStage = loanStage;
    return () => {
      useLoanStage = null;
    };
  }, [loanStage, convertedTo]);
}
