/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
// import settingsSvg from "../images/exceptions/cog.svg";
import ArrowUp from "../images/arrow-upward.svg";
import viewIcon from "../images/guidelineOutput/view.svg";
import waiverSatisfied from "../images/exceptions/waiver-approved.svg";
import waiverRejected from "../images/exceptions/waiver-rejected.svg";
import waiverRequested from "../images/exceptions/waiver.svg";
import satisfied from "../images/exceptions/satisfied.svg";
import questionAsked from "../images/exceptions/question-asked.svg";
import sortingaz from "../images/sortingaz.svg";
// import downloadImg from "../images/createloan/download.svg";

// import CommentIconSVG from "../icons/CommentIcon.jsx";
// import redirect from "../images/exceptions/link.svg";
export const ContentCopyPaste = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h18v18H0z" />
        <g fillRule="nonzero">
          <path
            d="M3.474 4h1.473v2.25h7.369V4h1.473v4.5h1.474V4c0-.825-.663-1.5-1.474-1.5h-3.08C10.4 1.63 9.59 1 8.632 1c-.958 0-1.769.63-2.078 1.5h-3.08C2.664 2.5 2 3.175 2 4v10.5c0 .825.663 1.5 1.474 1.5h3.684v-1.5H3.474V4zm5.158-1.5c.405 0 .736.337.736.75S9.037 4 8.632 4a.746.746 0 0 1-.737-.75c0-.413.331-.75.737-.75z"
            fill="#32325D"
          />
          <path
            fill="#000"
            d="m13.06 10-1.046 1.057 1.164 1.185H8.632v1.5h4.546l-1.164 1.193 1.046 1.057 2.94-3z"
          />
        </g>
      </g>
    </svg>
  );
};

export const SettingsIcon = () => (
  <svg width="17" height="17" viewBox="0 0 17 17">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        fillRule="nonzero"
        stroke="#FFF"
        strokeWidth=".3"
        d="M14.191 10.119l-1.36-.967c.228-.84.227-1.727-.004-2.566l1.365-.956-1.09-1.885-1.518.694c-.614-.618-1.382-1.06-2.224-1.28L9.215 1.5h-2.18l-.144 1.66c-.843.22-1.611.662-2.224 1.28l-1.519-.695-1.089 1.886 1.365.955c-.231.84-.233 1.726-.004 2.566l-1.361.967 1.089 1.877 1.519-.696c.613.618 1.381 1.06 2.224 1.28l.144 1.66h2.18l.145-1.66c.842-.22 1.61-.662 2.223-1.28l1.519.695 1.09-1.875-.001-.001zM9.629.739c.2 0 .366.157.375.358l.133 1.526c.555.211 1.072.51 1.532.883l1.388-.647c.178-.092.397-.027.496.146l1.504 2.603c.1.173.047.395-.121.503l-1.256.879c.096.598.093 1.195.001 1.77l1.255.877c.169.108.222.33.121.504l-1.504 2.592c-.1.174-.318.238-.496.147l-1.388-.648c-.451.368-.966.668-1.532.885l-.133 1.526c-.01.2-.175.357-.375.357H6.62c-.2 0-.365-.157-.375-.357l-.133-1.526c-.555-.212-1.072-.51-1.533-.885l-1.387.648c-.178.092-.397.028-.497-.146L1.193 10.14c-.1-.173-.047-.395.121-.503l1.255-.879c-.094-.586-.094-1.183 0-1.769l-1.254-.878c-.168-.108-.221-.33-.121-.502l1.501-2.604c.1-.174.32-.238.497-.146l1.388.647c.46-.374.978-.672 1.532-.883l.133-1.527c.01-.2.175-.357.375-.357h3.008zM8.125 5.25c1.45 0 2.625 1.175 2.625 2.625S9.575 10.5 8.125 10.5 5.5 9.325 5.5 7.875 6.675 5.25 8.125 5.25zm0 .75C7.089 6 6.25 6.84 6.25 7.875c0 1.036.84 1.875 1.875 1.875C9.161 9.75 10 8.91 10 7.875 10 6.839 9.16 6 8.125 6h0z"
        transform="translate(.667 .667)"
      />
      <path d="M0 0H16V16H0z" transform="translate(.667 .667)" />
    </g>
  </svg>
);

export const SettingsIconDark = (props: { size?: string }) => {
  const { size = "16" } = props;
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#32325D"
          fillRule="nonzero"
          stroke="#32325D"
          strokeWidth=".3"
          d="M14.191 10.119l-1.36-.967c.228-.84.227-1.727-.004-2.566l1.365-.956-1.09-1.885-1.518.694c-.614-.618-1.382-1.06-2.224-1.28L9.215 1.5h-2.18l-.144 1.66c-.843.22-1.611.662-2.224 1.28l-1.519-.695-1.089 1.886 1.365.955c-.231.84-.233 1.726-.004 2.566l-1.361.967 1.089 1.877 1.519-.696c.613.618 1.381 1.06 2.224 1.28l.144 1.66h2.18l.145-1.66c.842-.22 1.61-.662 2.223-1.28l1.519.695 1.09-1.875-.001-.001zM9.629.739c.2 0 .366.157.375.358l.133 1.526c.555.211 1.072.51 1.532.883l1.388-.647c.178-.092.397-.027.496.146l1.504 2.603c.1.173.047.395-.121.503l-1.256.879c.096.598.093 1.195.001 1.77l1.255.877c.169.108.222.33.121.504l-1.504 2.592c-.1.174-.318.238-.496.147l-1.388-.648c-.451.368-.966.668-1.532.885l-.133 1.526c-.01.2-.175.357-.375.357H6.62c-.2 0-.365-.157-.375-.357l-.133-1.526c-.555-.212-1.072-.51-1.533-.885l-1.387.648c-.178.092-.397.028-.497-.146L1.193 10.14c-.1-.173-.047-.395.121-.503l1.255-.879c-.094-.586-.094-1.183 0-1.769l-1.254-.878c-.168-.108-.221-.33-.121-.502l1.501-2.604c.1-.174.32-.238.497-.146l1.388.647c.46-.374.978-.672 1.532-.883l.133-1.527c.01-.2.175-.357.375-.357h3.008zM8.125 5.25c1.45 0 2.625 1.175 2.625 2.625S9.575 10.5 8.125 10.5 5.5 9.325 5.5 7.875 6.675 5.25 8.125 5.25zm0 .75C7.089 6 6.25 6.84 6.25 7.875c0 1.036.84 1.875 1.875 1.875C9.161 9.75 10 8.91 10 7.875 10 6.839 9.16 6 8.125 6h0z"
          transform="translate(.667 .667)"
        />
        <path d="M0 0H16V16H0z" transform="translate(.667 .667)" />
      </g>
    </svg>
  );
};

export const SortingAZIcon = () => {
  return <img src={sortingaz} alt="Sorting" />;
};

export const ArrowUpIcon = () => {
  return <img src={ArrowUp} alt="dropdown-up-arrow" />;
};

export const ArrowNext = () => {
  return <img src={ArrowUp} alt="dropdown-next-option" />;
};

export const EyeIcon = () => {
  return <img src={viewIcon} alt="view-details" />;
};

export const WaiverSatisfiedIcon = () => {
  return <img src={waiverSatisfied} alt="waiver-satisfied" />;
};

export const WaiverRejectedIcon = () => {
  return <img src={waiverRejected} alt="waiver-rejected" />;
};
export const WaiverRequestedIcon = () => {
  return <img src={waiverRequested} alt="waiver-requested" />;
};
export const SatisfiedIcon = () => {
  return <img src={satisfied} alt="satisfied" />;
};
export const QuestionAskedIcon = () => {
  return <img src={questionAsked} alt="question-asked" />;
};
export const CommentIcon = () => (
  <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
    <g
      id="Icons-/-Dark-/-Comment"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M6.2,14 C5.86862915,14 5.6,13.7313708 5.6,13.4 L5.6,11.6 L3.2,11.6 C2.5372583,11.6 2,11.0627417 2,10.4 L2,3.2 C2,2.5372583 2.5372583,2 3.2,2 L12.8,2 C13.4627417,2 14,2.5372583 14,3.2 L14,10.4 C14,11.0627417 13.4627417,11.6 12.8,11.6 L9.14,11.6 L6.92,13.826 C6.8,13.94 6.65,14 6.5,14 L6.2,14 M6.8,10.4 L6.8,12.248 L8.648,10.4 L12.8,10.4 L12.8,3.2 L3.2,3.2 L3.2,10.4 L6.8,10.4 M4.4,5 L11.6,5 L11.6,6.2 L4.4,6.2 L4.4,5 M4.4,7.4 L9.8,7.4 L9.8,8.6 L4.4,8.6 L4.4,7.4 Z"
        id="Icon-Shape"
        fill="#32325D"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export const CommentBlueIcon = () => (
  <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
    <title>Group 31</title>
    <g
      id="Purchase-Settlement"
      stroke="none"
      stroke-width="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group-31">
        <path
          d="M6.2,15 C5.86862915,15 5.6,14.7313708 5.6,14.4 L5.6,12.6 L3.2,12.6 C2.5372583,12.6 2,12.0627417 2,11.4 L2,4.2 C2,3.5372583 2.5372583,3 3.2,3 L12.8,3 C13.4627417,3 14,3.5372583 14,4.2 L14,11.4 C14,12.0627417 13.4627417,12.6 12.8,12.6 L9.14,12.6 L6.92,14.826 C6.8,14.94 6.65,15 6.5,15 L6.2,15 M6.8,11.4 L6.8,13.248 L8.648,11.4 L12.8,11.4 L12.8,4.2 L3.2,4.2 L3.2,11.4 L6.8,11.4 M4.4,6 L11.6,6 L11.6,7.2 L4.4,7.2 L4.4,6 M4.4,8.4 L9.8,8.4 L9.8,9.6 L4.4,9.6 L4.4,8.4 Z"
          id="Icon-Shape"
          fill="#5566D6"
          fillRule="nonzero"
        ></path>
        <rect id="Rectangle-Copy" x="0" y="0" width="16" height="16"></rect>
      </g>
    </g>
  </svg>
);

export const RedirectIcon = (props: any) => {
  const { color = "#FFF", width = "16", height = "16" } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g fillRule="nonzero">
                <g fill={color}>
                  <path
                    d="M12.667 12.667H3.333V3.333H8V2H3.333C2.597 2 2 2.597 2 3.333v9.334C2 13.403 2.597 14 3.333 14h9.334C13.4 14 14 13.4 14 12.667V8h-1.333v4.667zM9.333 2v1.333h2.394L5.173 9.887l.94.94 6.554-6.554v2.394H14V2H9.333z"
                    transform="translate(-1015 -1151) translate(935 1146) translate(80 5)"
                  />
                </g>
                <path
                  d="M0 0H16V16H0z"
                  transform="translate(-1015 -1151) translate(935 1146) translate(80 5)"
                />
              </g>
              <path
                d="M0 0H16V16H0z"
                transform="translate(-1015 -1151) translate(935 1146) translate(80 5)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const RefreshIcon = (props: any) => {
  const { color = "#FFF", width = "16", height = "16" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path
              d="M0 0L16 0 16 16 0 16z"
              transform="translate(-389.000000, -106.000000) translate(389.000000, 106.000000)"
            />
            <path
              fill={color}
              fillRule="nonzero"
              d="M8 4v2l2.667-2.667L8 .667v2C5.053 2.667 2.667 5.053 2.667 8c0 1.047.306 2.02.826 2.84l.974-.973C4.167 9.313 4 8.673 4 8c0-2.207 1.793-4 4-4zm4.507 1.16l-.974.973C11.827 6.693 12 7.327 12 8c0 2.207-1.793 4-4 4v-2l-2.667 2.667L8 15.333v-2c2.947 0 5.333-2.386 5.333-5.333 0-1.047-.306-2.02-.826-2.84z"
              transform="translate(-389.000000, -106.000000) translate(389.000000, 106.000000)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const MoveRightIcon = (props: any) => {
  const { width, height } = props;
  return (
    <svg width={width || "24"} height={height || "24"} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                d="M0 0L24 0 24 24 0 24z"
                transform="translate(-1274 -505) translate(132 260) translate(1142 245)"
              />
              <path
                fill="#000"
                fillRule="nonzero"
                d="M6.23 20.23L8 22 18 12 8 2 6.23 3.77 14.46 12z"
                transform="translate(-1274 -505) translate(132 260) translate(1142 245)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const DeleteIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16">
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g fill="#32325D" fillRule="nonzero">
            <path
              d="M.667 10.667C.667 11.4 1.267 12 2 12h5.333c.734 0 1.334-.6 1.334-1.333v-8h-8v8zM2 4h5.333v6.667H2V4zM7 .667L6.333 0H3l-.667.667H0V2h9.333V.667H7z"
              transform="translate(-977 -1772) translate(977 1772) translate(3.333 2)"
            />
          </g>
          <path
            d="M0 0H16V16H0z"
            transform="translate(-977 -1772) translate(977 1772)"
          />
        </g>
      </g>
    </g>
  </svg>
);
export const AskAQuestion = () => (
  <svg
    width="15"
    height="15"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M1.5 0A1.5 1.5 0 0 0 0 1.5v9A1.5 1.5 0 0 0 1.5 12H5v2c0 .414.336.743.75.75.263.005.394-.084 1.06-.75l1.926-2H13.5a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 13.5 0h-12zm-.2 1.3h12.4v9.488H8.337l-2.336 2.435v-2.435H1.3V1.3z"
        id="a"
      />
      <path
        d="M0 3.532h1.333c0-.732.601-1.333 1.334-1.333C3.401 2.199 4 2.8 4 3.532 4 4.866 2 4.7 2 6.866h1.333c0-1.5 2-1.667 2-3.334A2.665 2.665 0 0 0 2.667.866 2.665 2.665 0 0 0 0 3.532z"
        id="c"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path fill="#32315C" mask="url(#b)" d="M-5 19.8h25V-5H-5z" />
      </g>
      <path fill="#32315C" d="M7 10.2h1.333V8.866H7z" />
      <g transform="translate(5 1)">
        <mask id="d" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <path fill="#32315C" mask="url(#d)" d="M-5 11.866h15.333v-16H-5z" />
      </g>
    </g>
  </svg>
);

export const SatisfyIcon = () => (
  <svg width="17" height="16" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <rect fill="#32325D" width="17" height="16" rx="4" />
      <text
        // fontFamily="PTSans-CaptionBold, PT Sans Caption"
        fontSize="12"
        fontWeight="bold"
        fill="#FFF"
      >
        <tspan x="5" y="12">
          S
        </tspan>
      </text>
    </g>
  </svg>
);

export const WaiverReRequestIcon = () => (
  <svg
    width="22px"
    height="22px"
    viewBox="0 0 22 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Group 29</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Communication-Full-screen-clear-filter"
        transform="translate(-1238.000000, -430.000000)"
      >
        <g id="Group-29" transform="translate(1238.000000, 431.000000)">
          <g id="Group-17">
            <rect
              id="Rectangle-Copy-3"
              fill="#32325D"
              x="0"
              y="4"
              width="17"
              height="16"
              rx="4"
            />
            <text
              id="W"
              // fontFamily="PTSans-CaptionBold, PT Sans Caption"
              fontSize="12"
              fontWeight="bold"
              fill="#FFFFFF"
            >
              <tspan x="3" y="17">
                W
              </tspan>
            </text>
            <g id="Group-16" transform="translate(11.000000, 0.000000)">
              <g id="Group-20">
                <rect
                  id="Rectangle"
                  stroke="#FFFFFF"
                  fill="#5E72E4"
                  x="0"
                  y="0"
                  width="10"
                  height="10"
                  rx="5"
                />
                <path
                  d="M5,2.46666667 L5,1 L3.125,2.83333333 L5,4.66666667 L5,3.2 C6.24125,3.2 7.25,4.18633333 7.25,5.4 C7.25,6.61366667 6.24125,7.6 5,7.6 C3.75875,7.6 2.75,6.61366667 2.75,5.4 L2,5.4 C2,7.02066667 3.3425,8.33333333 5,8.33333333 C6.6575,8.33333333 8,7.02066667 8,5.4 C8,3.77933333 6.6575,2.46666667 5,2.46666667"
                  id="Fill-1"
                  fill="#FFFFFF"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const WaiverReopenIcon = () => (
  <svg
    width="22px"
    height="22px"
    viewBox="0 0 22 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Group 26</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Communication-Full-screen-clear-filter"
        transform="translate(-1238.000000, -624.000000)"
      >
        <g id="Group-26" transform="translate(1238.000000, 625.000000)">
          <g id="Group-17">
            <rect
              id="Rectangle-Copy-3"
              fill="#32325D"
              x="0"
              y="4"
              width="17"
              height="16"
              rx="4"
            />
            <text
              id="W"
              // fontFamily="PTSans-CaptionBold, PT Sans Caption"
              fontSize="12"
              fontWeight="bold"
              fill="#FFFFFF"
            >
              <tspan x="3" y="17">
                W
              </tspan>
            </text>
            <g id="Group-16" transform="translate(11.000000, 0.000000)">
              <g id="Group-15">
                <rect
                  id="Rectangle"
                  stroke="#FFFFFF"
                  fill="#5E72E4"
                  x="0"
                  y="0"
                  width="10"
                  height="10"
                  rx="5"
                />
                <path
                  d="M5.0625,2.91477273 L5.0625,3.98863636 L6.46875,2.55681818 L5.0625,1.125 L5.0625,2.19886364 C3.50859375,2.19886364 2.25,3.48034091 2.25,5.0625 C2.25,5.62448864 2.41171875,6.14710227 2.6859375,6.58738636 L3.19921875,6.06477273 C3.04101562,5.76767045 2.953125,5.42403409 2.953125,5.0625 C2.953125,3.87767045 3.89882812,2.91477273 5.0625,2.91477273 Z M7.4390625,3.53761364 L6.92578125,4.06022727 C7.08046875,4.36090909 7.171875,4.70096591 7.171875,5.0625 C7.171875,6.24732955 6.22617188,7.21022727 5.0625,7.21022727 L5.0625,6.13636364 L3.65625,7.56818182 L5.0625,9 L5.0625,7.92613636 C6.61640625,7.92613636 7.875,6.64465909 7.875,5.0625 C7.875,4.50051136 7.71328125,3.97789773 7.4390625,3.53761364 L7.4390625,3.53761364 Z"
                  id="Shape"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const WaiverRebuttalIcon = () => (
  <svg
    width="22px"
    height="22px"
    viewBox="0 0 22 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>624408E1-52DF-42F2-BC47-EF26537FDEB4</title>
    <g
      id="Condition-&amp;-Inquiry-Tab"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="LAT_Condition-&amp;-Inq-All-Tab"
        transform="translate(-1209.000000, -572.000000)"
      >
        <g id="Group-26" transform="translate(1209.000000, 573.000000)">
          <g id="Group-17">
            <rect
              id="Rectangle-Copy-3"
              fill="#32325D"
              x="0"
              y="4"
              width="17"
              height="16"
              rx="4"
            />
            <text
              id="W"
              // fontFamily="PTSans-CaptionBold, PT Sans Caption"
              fontSize="12"
              fontWeight="bold"
              fill="#FFFFFF"
            >
              <tspan x="3" y="17">
                W
              </tspan>
            </text>
            <g id="Group-34" transform="translate(11.000000, 0.000000)">
              <g id="Group-16" fill="#F75676" stroke="#FFFFFF">
                <rect
                  id="Rectangle"
                  x="0"
                  y="0"
                  width="10"
                  height="10"
                  rx="5"
                />
              </g>
              <g id="Group-16" transform="translate(2.000000, 2.000000)">
                <g id="Group-20">
                  <g id="Group-28" stroke="#FFFFFF">
                    <rect
                      id="Rectangle"
                      x="0"
                      y="0"
                      width="6"
                      height="6"
                      rx="3"
                    />
                    <line
                      x1="1"
                      y1="1"
                      x2="4.99999982"
                      y2="4.99999982"
                      id="Path-5"
                      transform="translate(3.000000, 3.000000) scale(-1, 1) translate(-3.000000, -3.000000) "
                    />
                  </g>
                  <g id="Group-36" transform="translate(1.000000, 1.000000)">
                    <g id="Group-35" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const WaiverApprovedIcon = () => (
  <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 1)">
        <rect fill="#32325D" y="4" width="17" height="16" rx="4" />
        <text
          // fontFamily="PTSans-CaptionBold, PT Sans Caption"
          fontSize="12"
          fontWeight="500"
          fill="#FFF"
        >
          <tspan x="3" y="17">
            W
          </tspan>
        </text>
        <rect
          width="10"
          height="10"
          rx="5"
          transform="translate(11)"
          fill="#2ECE89"
          stroke="#FFF"
        />
      </g>
      <path stroke="#FFF" d="m13 6 2 2 4-4" />
    </g>
  </svg>
);

// export const WaiverRejectedIcon = () => (
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     width="22"
//     height="22"
//     viewBox="0 0 22 22"
//   >
//     <g fill="none" fillRule="evenodd">
//       <g>
//         <g>
//           <g>
//             <g transform="translate(-935 -1145) translate(935 1146)">
//               <rect width="17" height="16" y="4" fill="#32325D" rx="4" />
//               <text
//                 fill="#FFF"
//                 fontFamily="OpenSans-Bold, Open Sans"
//                 fontSize="12"
//                 fontWeight="bold"
//               >
//                 <tspan x="3" y="17">
//                   W
//                 </tspan>
//               </text>
//               <g fill="#F75676" stroke="#FFF" transform="translate(11)">
//                 <rect width="10" height="10" rx="5" />
//               </g>
//             </g>
//             <g stroke="#FFF">
//               <g>
//                 <path
//                   d="M0 0L4 4"
//                   transform="translate(-935 -1145) translate(935 1146) translate(14 3)"
//                 />
//                 <path
//                   d="M0 0L4 4"
//                   transform="translate(-935 -1145) translate(935 1146) translate(14 3) matrix(-1 0 0 1 4 0)"
//                 />
//               </g>
//             </g>
//           </g>
//         </g>
//       </g>
//     </g>
//   </svg>
// );

export const WaiverRequestIcon = () => (
  <svg width="17" height="16" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <rect fill="#32325D" width="17" height="16" rx="4" />
      <text
        // fontFamily="PTSans-CaptionBold, PT Sans Caption"
        fontSize="12"
        fontWeight="bold"
        fill="#FFF"
      >
        <tspan x="3" y="13">
          W
        </tspan>
      </text>
    </g>
  </svg>
);

export const BackIcon = () => (
  <svg width="17" height="16" viewBox="0 0 17 16">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#32325D"
        fillRule="nonzero"
        stroke="#333160"
        strokeWidth=".5"
        d="M5.277 0.834L4.477 0 0 4.667 4.477 9.333 5.277 8.499 1.601 4.667z"
        transform="translate(.8) translate(.66 3.333)"
      />
      <path d="M0 0H15.832V16H0z" transform="translate(.8)" />
    </g>
  </svg>
);

export const AnalyticsIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16">
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              d="M0 0H16V16H0z"
              transform="translate(-1037 -121) translate(1037 118) translate(0 3)"
            />
            <g fillRule="nonzero">
              <g fill="#000">
                <path
                  d="M12.667 2H3.333C2.6 2 2 2.6 2 3.333v9.334C2 13.4 2.6 14 3.333 14h9.334C13.4 14 14 13.4 14 12.667V3.333C14 2.6 13.4 2 12.667 2zm0 10.667H3.333V3.333h9.334v9.334z"
                  transform="translate(-1037 -121) translate(1037 118) translate(0 3)"
                />
                <path
                  d="M4.667 8H6v3.333H4.667V8zM10 4.667h1.333v6.666H10V4.667zM7.333 9.333h1.334v2H7.333v-2zm0-2.666h1.334V8H7.333V6.667z"
                  transform="translate(-1037 -121) translate(1037 118) translate(0 3)"
                />
              </g>
              <path
                d="M0 0H16V16H0z"
                transform="translate(-1037 -121) translate(1037 118) translate(0 3)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const ViewIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 17">
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF" fillRule="nonzero" stroke="#FFF" strokeWidth=".4">
        <path
          d="M14.194 4.975C13.054 2.073 10.269.117 7.11 0 3.953.117 1.168 2.073.028 4.975c-.037.102-.037.214 0 .317 1.14 2.902 3.925 4.858 7.083 4.975 3.158-.117 5.943-2.073 7.083-4.975.037-.103.037-.215 0-.317zM7.11 9.333c-2.513 0-5.167-1.834-6.13-4.2.963-2.366 3.617-4.2 6.13-4.2s5.167 1.834 6.13 4.2c-.963 2.366-3.617 4.2-6.13 4.2z"
          transform="translate(.884 .667) translate(1.016 3)"
        />
        <path
          d="M7.11 2.333c-1.57 0-2.844 1.254-2.844 2.8 0 1.547 1.274 2.8 2.845 2.8 1.57 0 2.844-1.253 2.844-2.8 0-1.546-1.273-2.8-2.844-2.8zM7.11 7c-1.046 0-1.895-.836-1.895-1.867 0-1.03.849-1.866 1.896-1.866s1.896.835 1.896 1.866c0 .495-.2.97-.555 1.32-.356.35-.838.547-1.341.547z"
          transform="translate(.884 .667) translate(1.016 3)"
        />
      </g>
      <path d="M0 0H16.254V16H0z" transform="translate(.884 .667)" />
    </g>
  </svg>
);

export const OriginatorIcon = () => (
  <svg width="14px" height="15px" viewBox="0 0 14 15" version="1.1">
    <title>Group 10</title>
    <defs>
      <linearGradient
        x1="3.77179089%"
        y1="0%"
        x2="89.9462386%"
        y2="100%"
        id="linearGradient-1"
      >
        <stop stopColor="#FF9E55" offset="0%" />
        <stop stopColor="#AE5F00" offset="100%" />
      </linearGradient>
    </defs>
    <g
      id="Pre-to-Post-Close-State"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Post-Close_-More-Actions_Dropdown"
        transform="translate(-506.000000, -549.000000)"
      >
        <g id="Group-10" transform="translate(506.000000, 549.000000)">
          <circle id="Oval" fill="url(#linearGradient-1)" cx="7" cy="8" r="7" />
          <text
            id="O"
            fontFamily="OpenSans-Bold, Open Sans"
            fontSize="11"
            fontWeight="bold"
            fill="#FFFFFF"
          >
            <tspan x="2.7" y="12">
              O
            </tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
);

export const ToorakIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14">
    <defs>
      <linearGradient id="prefix__b" x1="3.772%" x2="89.946%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#0FCBEF" />
        <stop offset="100%" stopColor="#1071EE" />
      </linearGradient>
      <circle id="prefix__a" cx="7" cy="7" r="7" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#D8D8D8" xlinkHref="#prefix__a" />
      <use fill="url(#prefix__b)" xlinkHref="#prefix__a" />
      <path stroke="#FFF" strokeWidth="1.5" d="M3 4.5L11 4.5M7 5L7 12" />
    </g>
  </svg>
);
export const ToorakIconWithDots = () => (
  <svg width="28px" height="14px">
    {/* <title>Group 10</title> */}
    <defs>
      <linearGradient
        x1="3.77179089%"
        y1="0%"
        x2="89.9462386%"
        y2="100%"
        id="linearGradient-toorak"
      >
        <stop stopColor="#0FCBEF" offset="0%" />
        <stop stopColor="#1071EE" offset="100%" />
      </linearGradient>
      <circle id="path-2" cx="14" cy="7" r="7" />
    </defs>
    <g
      id="Discussion"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Discussions-detailed-View"
        transform="translate(-462.000000, -110.000000)"
      >
        <g id="Group-10" transform="translate(462.000000, 110.000000)">
          <g id="Group-4">
            <path
              d="M2.07177734,8.31445312 C2.45133464,8.31445312 2.74405924,8.19897461 2.94995117,7.96801758 C3.1558431,7.73706055 3.25878906,7.40852865 3.25878906,6.98242188 C3.25878906,6.54199219 3.15494792,6.21077474 2.94726562,5.98876953 C2.73958333,5.76676432 2.44775391,5.65576172 2.07177734,5.65576172 C1.68505859,5.65576172 1.39054362,5.76855469 1.18823242,5.99414062 C0.985921224,6.21972656 0.884765625,6.54915365 0.884765625,6.98242188 C0.884765625,7.40136719 0.986816406,7.72810872 1.19091797,7.96264648 C1.39501953,8.19718424 1.68863932,8.31445312 2.07177734,8.31445312 Z"
              id="Path"
              fill="#8897AA"
              fillRule="nonzero"
            />
            <path
              d="M26.2094727,8.31445312 C26.5890299,8.31445312 26.8817546,8.19897461 27.0876465,7.96801758 C27.2935384,7.73706055 27.3964844,7.40852865 27.3964844,6.98242188 C27.3964844,6.54199219 27.2926432,6.21077474 27.0849609,5.98876953 C26.8772786,5.76676432 26.5854492,5.65576172 26.2094727,5.65576172 C25.8227539,5.65576172 25.5282389,5.76855469 25.3259277,5.99414062 C25.1236165,6.21972656 25.0224609,6.54915365 25.0224609,6.98242188 C25.0224609,7.40136719 25.1245117,7.72810872 25.3286133,7.96264648 C25.5327148,8.19718424 25.8263346,8.31445312 26.2094727,8.31445312 Z"
              id="Path"
              fill="#8897AA"
              fillRule="nonzero"
            />
            <g id="Oval">
              <use fill="#D8D8D8" xlinkHref="#path-2" />
              <use fill="url(#linearGradient-toorak)" xlinkHref="#path-2" />
            </g>
            <line
              x1="10"
              y1="4.5"
              x2="18"
              y2="4.5"
              id="Path-3"
              stroke="#FFFFFF"
              strokeWidth="1.5"
            />
            <line
              x1="14"
              y1="5"
              x2="14"
              y2="12"
              id="Path-4"
              stroke="#FFFFFF"
              strokeWidth="1.5"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const OriginatorIconWithDots = () => (
  <svg width="28px" height="14px" viewBox="0 0 28 14">
    {/* <title>Group 12</title> */}
    <defs>
      <linearGradient
        x1="3.77179089%"
        y1="0%"
        x2="89.9462386%"
        y2="100%"
        id="linearGradient-1"
      >
        <stop stopColor="#FF9E55" offset="0%" />
        <stop stopColor="#AE5F00" offset="100%" />
      </linearGradient>
    </defs>
    <g
      id="Loan-Inquiries-&amp;-Conditions-Tab"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group-12" transform="translate(0.744141, 0.000000)">
        <g id="Group-6">
          <g
            id="Group-10"
            transform="translate(0.000000, 5.000000)"
            fill="#8897AA"
            fillRule="nonzero"
          >
            <path
              d="M1.18701172,3.31445312 C1.56656901,3.31445312 1.85929362,3.19897461 2.06518555,2.96801758 C2.27107747,2.73706055 2.37402344,2.40852865 2.37402344,1.98242188 C2.37402344,1.54199219 2.27018229,1.21077474 2.0625,0.988769531 C1.85481771,0.766764323 1.56298828,0.655761719 1.18701172,0.655761719 C0.800292969,0.655761719 0.505777995,0.768554688 0.303466797,0.994140625 C0.101155599,1.21972656 0,1.54915365 0,1.98242188 C0,2.40136719 0.102050781,2.72810872 0.306152344,2.96264648 C0.510253906,3.19718424 0.803873698,3.31445312 1.18701172,3.31445312 Z"
              id="Path"
            />
            <path
              d="M25.324707,3.31445312 C25.7042643,3.31445312 25.9969889,3.19897461 26.2028809,2.96801758 C26.4087728,2.73706055 26.5117188,2.40852865 26.5117188,1.98242188 C26.5117188,1.54199219 26.4078776,1.21077474 26.2001953,0.988769531 C25.992513,0.766764323 25.7006836,0.655761719 25.324707,0.655761719 C24.9379883,0.655761719 24.6434733,0.768554688 24.4411621,0.994140625 C24.2388509,1.21972656 24.1376953,1.54915365 24.1376953,1.98242188 C24.1376953,2.40136719 24.2397461,2.72810872 24.4438477,2.96264648 C24.6479492,3.19718424 24.941569,3.31445312 25.324707,3.31445312 Z"
              id="Path"
            />
          </g>
          <g id="Group-2-Copy" transform="translate(6.255859, 0.000000)">
            <g id="Group">
              <g id="Group-7">
                <g id="Group-18" />
              </g>
            </g>
          </g>
        </g>
        <circle
          id="Oval"
          fill="url(#linearGradient-1)"
          cx="13.2558594"
          cy="7"
          r="7"
        />
        <path
          d="M13.3333008,11.1074219 C14.5364258,11.1074219 15.4602539,10.7583008 16.1047852,10.0600586 C16.7493164,9.36181641 17.071582,8.36279297 17.071582,7.06298828 C17.071582,5.75960286 16.752002,4.76057943 16.1128418,4.06591797 C15.4736816,3.37125651 14.5507487,3.02392578 13.344043,3.02392578 C12.1373372,3.02392578 11.2108236,3.36857096 10.564502,4.05786133 C9.91818034,4.74715169 9.59501953,5.74527995 9.59501953,7.05224609 C9.59501953,8.35921224 9.91728516,9.36181641 10.5618164,10.0600586 C11.2063477,10.7583008 12.1301758,11.1074219 13.3333008,11.1074219 Z M13.3333008,9.7109375 C12.6708659,9.7109375 12.1731445,9.48893229 11.8401367,9.04492188 C11.5071289,8.60091146 11.340625,7.94026693 11.340625,7.06298828 C11.340625,6.18570964 11.5089193,5.52416992 11.8455078,5.07836914 C12.1820964,4.63256836 12.6816081,4.40966797 13.344043,4.40966797 C14.665332,4.40966797 15.3259766,5.29410807 15.3259766,7.06298828 C15.3259766,8.82828776 14.6617513,9.7109375 13.3333008,9.7109375 Z"
          id="O"
          fill="#FFFFFF"
          fillRule="nonzero"
        />
      </g>
    </g>
  </svg>
);

export const BlueInfoIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16">
    <g fill="none" fillRule="evenodd">
      <g fill="#5F72E4" fillRule="nonzero">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="M6.75 3.75h1.5v1.5h-1.5v-1.5zm0 3h1.5v4.5h-1.5v-4.5zM7.5 0C3.36 0 0 3.36 0 7.5 0 11.64 3.36 15 7.5 15c4.14 0 7.5-3.36 7.5-7.5C15 3.36 11.64 0 7.5 0zm0 13.5c-3.308 0-6-2.693-6-6 0-3.308 2.692-6 6-6 3.307 0 6 2.692 6 6 0 3.307-2.693 6-6 6z"
                    transform="translate(-911 -399) translate(779 395) translate(132 4) translate(.5 .5)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const MoreIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16">
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g fill="#32325D" fillRule="nonzero">
                <g>
                  <path
                    d="M1.333 2.667c.734 0 1.334-.6 1.334-1.334C2.667.6 2.067 0 1.333 0 .6 0 0 .6 0 1.333c0 .734.6 1.334 1.333 1.334zm0 1.333C.6 4 0 4.6 0 5.333c0 .734.6 1.334 1.333 1.334.734 0 1.334-.6 1.334-1.334C2.667 4.6 2.067 4 1.333 4zm0 4C.6 8 0 8.6 0 9.333c0 .734.6 1.334 1.333 1.334.734 0 1.334-.6 1.334-1.334C2.667 8.6 2.067 8 1.333 8z"
                    transform="translate(-1337 -175) translate(55 91) translate(1276 78) translate(6 6) translate(6.667 2.667)"
                  />
                </g>
              </g>
              <path
                d="M0 0H16V16H0z"
                transform="translate(-1337 -175) translate(55 91) translate(1276 78) translate(6 6)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const CloseIcon = (props: { color?: string }) => {
  const { color } = props;
  return (
    <svg width="16" height="16" viewBox="0 0 16 16">
      <g fill={color || "#32325D"} fillRule="evenodd">
        <g fill={color || "#32325D"} fillRule="nonzero">
          <g>
            <g>
              <g>
                <path
                  d="M12.667 4.273L11.727 3.333 8 7.06 4.273 3.333 3.333 4.273 7.06 8 3.333 11.727 4.273 12.667 8 8.94 11.727 12.667 12.667 11.727 8.94 8z"
                  transform="translate(-1274.000000, -1702.000000) translate(946.000000, 514.000000) translate(5.000000, 950.000000) translate(323.000000, 238.000000)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const ArrowDownIcon = (props: { color?: string }) => {
  const { color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill={color || "#fff"}
          fillRule="nonzero"
          d="M10.273 4.94L9.333 4 5.333 8 9.333 12 10.273 11.06 7.22 8z"
          transform="translate(.667 .667) rotate(-90 7.803 8)"
        />
        <path d="M0 8H16V24H0z" transform="translate(.667 .667)" />
      </g>
    </svg>
  );
};

export const SingleViewIcon = (props: { color?: string }) => {
  const { color } = props;
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.667 0h-8.89C.8 0 0 .8 0 1.778v12.444C0 15.2.8 16 1.778 16h8.889c.977 0 1.777-.8 1.777-1.778V1.778C12.444.8 11.644 0 10.667 0zm0 14.222h-8.89V1.778h8.89v12.444z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};
export const SplitscreenViewIcon = (props: {
  color?: string;
  width?: string;
  height?: string;
}) => {
  const { color, width = "13", height = "16" } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2 1.6v4H1.6v-4h9.6m0-1.6H1.6C.72 0 0 .72 0 1.6v4c0 .88.72 1.6 1.6 1.6h9.6c.88 0 1.6-.72 1.6-1.6v-4c0-.88-.72-1.6-1.6-1.6zm0 10.4v4H1.6v-4h9.6m0-1.6H1.6c-.88 0-1.6.72-1.6 1.6v4c0 .88.72 1.6 1.6 1.6h9.6c.88 0 1.6-.72 1.6-1.6v-4c0-.88-.72-1.6-1.6-1.6z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};
export const DownloadIcon = (props: {
  color?: string;
  size?: string;
  viewBox?: string;
}) => {
  const { color, size = 17, viewBox = "0 0 17 17" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={viewBox}
    >
      <g fill="none" fillRule="evenodd">
        <g
          stroke={color || "#183238"}
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="1.333"
        >
          <path
            d="M10.605 8.778v2.37c0 .655-.528 1.185-1.179 1.185H1.178c-.65 0-1.178-.53-1.178-1.185v-2.37"
            transform="translate(.587 .667) translate(2.651 1)"
          />
          <g>
            <path
              d="M5.891 2.963L2.946 0 0 2.963"
              transform="translate(.587 .667) translate(2.651 1) matrix(1 0 0 -1 2.357 9.667)"
            />
            <path
              d="M2.946 1.185L2.946 8.296"
              transform="translate(.587 .667) translate(2.651 1) matrix(1 0 0 -1 2.357 9.667)"
            />
          </g>
        </g>
        <path d="M0 0H15.907V16H0z" transform="translate(.587 .667)" />
      </g>
    </svg>
  );
};

export const ZoomOutIcon = (props: { size?: string }) => {
  const { size = "16" } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m15.022 16-5.844-5.844a4.897 4.897 0 0 1-1.556.9 5.5 5.5 0 0 1-1.889.322c-1.6 0-2.955-.556-4.066-1.667C.556 8.6 0 7.26 0 5.69c0-1.57.556-2.911 1.667-4.022C2.777.556 4.126 0 5.71 0c1.57 0 2.908.556 4.011 1.667 1.104 1.11 1.656 2.452 1.656 4.022 0 .637-.104 1.252-.311 1.844a5.64 5.64 0 0 1-.934 1.667L16 15.022l-.978.978zm-9.31-5.956c1.2 0 2.221-.425 3.066-1.277.844-.852 1.266-1.878 1.266-3.078S9.622 3.463 8.778 2.61C7.933 1.76 6.91 1.333 5.71 1.333c-1.215 0-2.248.426-3.1 1.278-.852.852-1.278 1.878-1.278 3.078s.426 2.226 1.278 3.078c.852.852 1.885 1.277 3.1 1.277zm-2.268-3.71V5h4.467v1.333H3.444z"
        fill="#32325D"
        fillRule="nonzero"
      />
    </svg>
  );
};

export const ZoomInIcon = (props: { size?: string }) => {
  const { size = "16" } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m15.022 16-5.844-5.844a4.897 4.897 0 0 1-1.556.9 5.5 5.5 0 0 1-1.889.322c-1.6 0-2.955-.556-4.066-1.667C.556 8.6 0 7.26 0 5.69c0-1.57.556-2.911 1.667-4.022C2.777.556 4.126 0 5.71 0c1.57 0 2.908.556 4.011 1.667 1.104 1.11 1.656 2.452 1.656 4.022 0 .637-.104 1.252-.311 1.844a5.64 5.64 0 0 1-.934 1.667L16 15.022l-.978.978zm-9.31-5.956c1.2 0 2.221-.425 3.066-1.277.844-.852 1.266-1.878 1.266-3.078S9.622 3.463 8.778 2.61C7.933 1.76 6.91 1.333 5.71 1.333c-1.215 0-2.248.426-3.1 1.278-.852.852-1.278 1.878-1.278 3.078s.426 2.226 1.278 3.078c.852.852 1.885 1.277 3.1 1.277zm.644-1.888H5.022V6.333H3.2V5h1.822V3.2h1.334V5h1.8v1.333h-1.8v1.823z"
        fill="#32325D"
        fillRule="nonzero"
      />
    </svg>
  );
};

export const FirstPageIcon = (props: { size?: string }) => {
  const { size = "16" } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#32325D" fillRule="nonzero">
        <path d="M8 16 0 8l8-8 1.427 1.427L2.855 8l6.572 6.573zM18 16l-8-8 8-8 1.427 1.427L12.855 8l6.572 6.573z" />
      </g>
    </svg>
  );
};

export const PrevPageIcon = (props: { size?: string }) => {
  const { size = "16" } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#32325D" fillRule="nonzero">
        <path
          d="M8 16 0 8l8-8 1.427 1.427L2.855 8l6.572 6.573z"
          fill="#32325D"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export const NextPageIcon = (props: { size?: string }) => {
  const { size = "16" } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m1.427 16 8-8-8-8L0 1.427 6.573 8 0 14.573z"
        fill="#32325D"
        fillRule="nonzero"
      />
    </svg>
  );
};

export const LastPageIcon = (props: { size?: string }) => {
  const { size = "16" } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#32325D" fillRule="nonzero">
        <path d="m1.427 16 8-8-8-8L0 1.427 6.573 8 0 14.573zM11.427 16l8-8-8-8L10 1.427 16.573 8 10 14.573z" />
      </g>
    </svg>
  );
};

export const RotateClockwiseIcon = (props: {
  size?: string;
  viewBox?: string;
}) => {
  const { size = "16", viewBox = "0 0 16 16" } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.857 16c-1.892 0-3.508-.67-4.847-2.01C.67 12.65 0 11.035 0 9.143c0-.94.181-1.829.543-2.667a7.093 7.093 0 0 1 3.676-3.714 6.49 6.49 0 0 1 2.657-.552h.59L6.058.8l.8-.8 2.781 2.781-2.78 2.78-.8-.8 1.409-1.409h-.59c-1.563 0-2.909.572-4.039 1.715-1.13 1.143-1.695 2.501-1.695 4.076 0 1.562.562 2.905 1.686 4.028 1.123 1.124 2.466 1.686 4.028 1.686.597 0 1.156-.086 1.676-.257a6.262 6.262 0 0 0 1.543-.771l.857.857a7.932 7.932 0 0 1-1.952.98A6.673 6.673 0 0 1 6.857 16zm4.648-2.362L7.01 9.143l4.495-4.495L16 9.143l-4.495 4.495zm0-1.619 2.876-2.876-2.876-2.876-2.876 2.876 2.876 2.876z"
        fill="#32325D"
        fillRule="nonzero"
      />
    </svg>
  );
};

export const HighConfidenceIcon = (props: {
  color?: string;
  size?: string;
  viewBox?: string;
}) => {
  const { color = "green", size = 16, viewBox = "0 0 16 16" } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M7.4 10.96h1.2V7.32l1.48 1.48.84-.84L8 5.04 5.08 7.96l.84.84L7.4 7.32v3.64zM8 16a7.74 7.74 0 0 1-3.1-.63 8.114 8.114 0 0 1-2.55-1.72A8.114 8.114 0 0 1 .63 11.1 7.74 7.74 0 0 1 0 8c0-1.107.21-2.147.63-3.12.42-.973.993-1.82 1.72-2.54A8.2 8.2 0 0 1 4.9.63 7.74 7.74 0 0 1 8 0a7.79 7.79 0 0 1 3.12.63c.973.42 1.82.99 2.54 1.71s1.29 1.567 1.71 2.54c.42.973.63 2.013.63 3.12a7.74 7.74 0 0 1-.63 3.1 8.2 8.2 0 0 1-1.71 2.55 7.995 7.995 0 0 1-2.54 1.72A7.79 7.79 0 0 1 8 16z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export const LowConfidenceIcon = (props: {
  color?: string;
  size?: string;
  viewBox?: string;
}) => {
  const { color = "#F75676", size = 16, viewBox = "0 0 16 16" } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M7.4 5.04h1.2v3.64l1.48-1.48.84.84L8 10.96 5.08 8.04l.84-.84L7.4 8.68V5.04zM8 0a7.74 7.74 0 0 0-3.1.63 8.114 8.114 0 0 0-2.55 1.72A8.114 8.114 0 0 0 .63 4.9 7.74 7.74 0 0 0 0 8c0 1.107.21 2.147.63 3.12.42.973.993 1.82 1.72 2.54a8.2 8.2 0 0 0 2.55 1.71A7.74 7.74 0 0 0 8 16a7.79 7.79 0 0 0 3.12-.63c.973-.42 1.82-.99 2.54-1.71a8.079 8.079 0 0 0 1.71-2.54A7.79 7.79 0 0 0 16 8a7.74 7.74 0 0 0-.63-3.1 8.2 8.2 0 0 0-1.71-2.55A7.995 7.995 0 0 0 11.12.63 7.79 7.79 0 0 0 8 0z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};
