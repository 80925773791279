/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-array-index-key */
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import { Badge, Grid, IconButton, Typography, Button } from "@mui/material";

import { ConfirmationDialog } from "@toorak/tc-common-fe-sdk";
import { InfoOnlyTooltip } from "../../utils/Tooltip";
import { UserDetailsCard } from "../exceptions-and-enquiries/UserDetailsCard";
import { CloseIcon, CommentIcon, DeleteIcon, RedirectIcon } from "../Icons";
import { OpenInNewTab } from "../OpenInNewTab";
import { IconButtonDropdownStylesWithoutBG } from "../waiver-form/DocumentsForm.style";
import { StatusComponent } from "../waiver-form/StatusComponent";
import { useStyles } from "../exceptions-and-enquiries/ExceptionCardStyle";
import { ScenarioApprovalDropDown } from "./ScenarioApprovalDropDown";
import { isLATUser } from "../../utils/AccessManagement";
import { getDateInFormat } from "../../utils/utils";
import { RootState } from "../../stores/rootReducer";
import {
  // deleteLoanFromRedux,
  deleteLoanFromScenario,
  deleteScenario,
  getLoanIdAndOrgIdPopData,
  getModifiedLoanType
} from "../../stores/Scenario/ScenarioActions";
import {
  showLoader,
  hideLoader
  // clearLoader
} from "../../stores/loaderAndException/loaderAndException.action";
import { PopOverInfo } from "../../key-deal-metrics/PopOverInfo";
import { getConfig, getLoanType } from "../../config/config";
import { googleMapLink } from "../../utils/constants";
import { isNullOrUndefined, nameToInitials } from "../../utils/formatChecks";
import { propertyTypeObject } from "../constants/loanCreationDropDownValues";
import { deleteLoanFromRedux } from "../../stores/Scenario/ScenarioReducer";
import { clearLoader } from "../../stores/loaderAndException/loaderAndException.reducer";

export interface ScenarioListView {
  scenarioResults: any;
}
export const ScenarioListView = (props: any) => {
  const navigate = useNavigate();
  const [openConfirmBox, setOpenConfirmBox] = React.useState(false);
  const [loanTag, setloanTag] = React.useState("");
  const [openRemoveLoanDialog, setRemoveLoanDialog] = React.useState(false);
  const dispatch = useDispatch();
  const {
    nameInitials,
    userName,
    accountType,
    createdOnDate,
    personPartyId,
    scenarioDetails,
    scenarioViewDetail,
    scenarioID,
    finalSTATUS,
    subjecT,
    descriptioN,
    propertyChips,
    scenarioLoanType,
    waiverId,
    orgPartyId,
    attachedLoanArr,
    isDetailView
  } = props;
  const countData = useSelector<RootState, any>(
    (state) => state.dashboardStore.countData
  );
  const iconButtonDropdownStylesWithoutBG = IconButtonDropdownStylesWithoutBG();
  const classes = useStyles();
  const scenarioId = scenarioDetails?._source?.identifierValue || scenarioID;

  const subject = scenarioDetails?._source?.waiver?.title || subjecT;
  const description =
    scenarioDetails?._source?.waiver?.description || descriptioN;
  const status = scenarioDetails?._source?.waiver?.waiverInfo?.finalResponse;
  const loanType = getModifiedLoanType(
    scenarioDetails?._source?.loanType || scenarioLoanType,
    "name"
  );
  const finalStatus =
    status && status === "open" ? "SCENARIO REQUESTED" : status;
  const waiverIDFromList =
    scenarioDetails?._source?.waiver &&
    scenarioDetails?._source?.waiver?.waiverId;
  const date = scenarioDetails?._source?.waiver?.created_on
    ? getDateInFormat(scenarioDetails?._source?.waiver?.created_on)
    : "";
  const waiverMitigates =
    scenarioDetails?._source?.waiver?.waiverMitigates &&
    scenarioDetails?._source?.waiver?.waiverMitigates[0] &&
    scenarioDetails?._source?.waiver?.waiverMitigates[0]?.description;
  const propertyNameArr = waiverMitigates && waiverMitigates.split("@");
  const nameArr =
    propertyNameArr && propertyNameArr[1] && propertyNameArr[1]?.split(" ");
  const fullName = propertyNameArr && propertyNameArr[1];
  const NameInitials = nameArr && nameToInitials(`${nameArr[1]} ${nameArr[2]}`);
  const propertyType = useMemo(() => {
    const listViewPropertyDetails = scenarioDetails?._source?.properties
      ? scenarioDetails?._source?.properties[0]
      : null;
    const editViewPropertyDetails = scenarioViewDetail?.scenarioPropertyDetails
      ? scenarioViewDetail?.scenarioPropertyDetails[0]
      : null;
    const property = !isDetailView
      ? listViewPropertyDetails
      : editViewPropertyDetails;
    if (isNullOrUndefined(property)) return "";
    const firstPropertyType =
      propertyTypeObject[property.propertyinfo?.propertyType];
    if (!firstPropertyType) return "";
    return `- ${firstPropertyType}`;
  }, [isDetailView, scenarioDetails, scenarioViewDetail]);
  const confirmDelete = () => {
    setOpenConfirmBox(true);
  };
  const handleConfirmation = () => {
    setOpenConfirmBox(false);
  };
  const handleDialog = () => {
    setRemoveLoanDialog(false);
  };

  const handleDeleteScenario = () => {
    dispatch(showLoader());
    if (waiverId) {
      const promise1 = deleteScenario(waiverId);
      promise1
        .then((response: any) => {
          if (response) {
            navigate(`/loans/list?tab=4`);
          }
          dispatch(hideLoader());
        })
        .catch((e) => {
          dispatch(clearLoader());
          console.log(e);
        });
    }
  };
  let finalUnreadCount = 0;
  (function () {
    if (waiverIDFromList)
      finalUnreadCount =
        countData &&
        countData[waiverIDFromList] &&
        countData[waiverIDFromList].length > 0
          ? countData[waiverIDFromList].length
          : 0;
    return finalUnreadCount;
  })();
  const popOverData: any[] = [];
  (function () {
    if (attachedLoanArr && attachedLoanArr.length > 0) {
      attachedLoanArr.forEach((item: any) => {
        const popLoanTag = getLoanIdAndOrgIdPopData(
          item?.loanId,
          item?.originatorLoanId
        );
        popOverData.push(popLoanTag);
      });
    }
  })();

  const redirectToLoanDetails = (loanIndex: number) => {
    if (attachedLoanArr && attachedLoanArr?.length) {
      const { loanType, loanStage, loanId } = attachedLoanArr[loanIndex];
      const loanTypePath = loanType.includes("30")
        ? getLoanType[1].displayValue
        : loanType.replace(/\s/g, "");
      const url = `${
        getConfig().redirectUrl
      }loan/createLoan/${loanTypePath}/${loanStage}/${loanId}`;
      window.open(url);
    }
  };

  let fStatus = finalStatus || finalSTATUS;
  fStatus = fStatus && fStatus.toLowerCase() !== "seems ok";
  const enableDelete = isDetailView && !isLATUser() && fStatus;
  const handleDeleteLoanFromScenario = (loanIdTag: string) => {
    const loanId = loanIdTag.split("/")[0];
    dispatch(showLoader());
    const deletedLoanPromise = deleteLoanFromScenario(scenarioId, loanId);
    deletedLoanPromise
      .then((res) => {
        // const loanIdIndex = popOverData.indexOf(loanIdTag);
        // popOverData.splice(loanIdIndex, 1);
        // setIsLoanDeleted(true);
        dispatch(deleteLoanFromRedux({ scenarioId, loanId }));
        dispatch(hideLoader());
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.log(err);
      });
  };

  const removeLoanDialog = (loanTag: any) => {
    setRemoveLoanDialog(true);
    setloanTag(loanTag);
  };
  return (
    <>
      <Grid
        container
        className={
          personPartyId
            ? classes.scenarioContainer
            : classes.scenarionViewDetailsContainer
        }
      >
        {/* user details and actions section */}
        <Grid
          container
          item
          xs={12}
          data-testid="userCard-and-actionscard"
          style={{ marginBottom: "10px" }}
          className={
            personPartyId
              ? classes.scenarioListContainer
              : classes.scenarioUserContainer
          }
        >
          <Grid
            item
            xs={7}
            container
            alignItems="center"
            spacing={1}
            data-testid="user-card-scenario"
          >
            <UserDetailsCard
              alternateText="Scenario ID :"
              headingId={scenarioId}
              userImg="some img will come here"
              accountNameImg={nameInitials || NameInitials}
              userFullName={userName || fullName}
              accountType={accountType || "O"}
              date={createdOnDate || date}
            />
          </Grid>
          <Grid
            item
            xs={5}
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            data-testid="actions-card-scenario"
            style={{ paddingRight: 10 }}
          >
            {!finalStatus || !finalSTATUS ? (
              <Grid item>
                <StatusComponent
                  status={finalStatus || finalSTATUS}
                  value={finalStatus || finalSTATUS}
                />
              </Grid>
            ) : null}
            {finalUnreadCount && finalUnreadCount > 0 ? (
              <Grid item>
                <InfoOnlyTooltip
                  title="Notification Count"
                  arrow
                  placement="right"
                >
                  <Grid
                    item
                    style={{ paddingRight: "5px" }}
                    justifyContent="center"
                  >
                    <Badge
                      badgeContent={finalUnreadCount}
                      data-testid="notification-badge-scenario"
                      overlap="circular"
                      variant="standard"
                      className="notificationNumberExceptions"
                    >
                      <IconButton
                        style={{ padding: "7px 7px 7px 0" }}
                        aria-label="notification-count"
                        component="button"
                        classes={iconButtonDropdownStylesWithoutBG}
                        size="large"
                      >
                        <CommentIcon />
                      </IconButton>
                    </Badge>
                  </Grid>
                </InfoOnlyTooltip>
              </Grid>
            ) : null}
            {enableDelete ? (
              <Grid
                item
                justifyContent="center"
                alignItems="flex-end"
                data-testid="delete-scenario-btn"
              >
                <IconButton
                  aria-label="delete"
                  component="button"
                  classes={iconButtonDropdownStylesWithoutBG}
                  onClick={() => confirmDelete()}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            ) : null}
            {/* {!isLATUser() && ? ( */}
            <Grid item>
              <ScenarioApprovalDropDown
                waiverId={waiverId || waiverIDFromList}
                orgPartyId={orgPartyId}
                scenarioIdFromList={scenarioId}
                loanType={loanType}
                isDetailView={isDetailView}
                fStatus={fStatus}
                attachedLoanArr={attachedLoanArr}
              />
            </Grid>
            {/* ) : null} */}
            {personPartyId ? (
              <InfoOnlyTooltip title="Open in new tab" arrow placement="right">
                <Grid
                  item
                  justifyContent="center"
                  alignItems="center"
                  data-testid="redirect-div-sceanrio"
                >
                  <OpenInNewTab
                    path={`loan/scenario/${scenarioId}/view`}
                    attachedLoanArr={attachedLoanArr}
                    loanTag={loanTag}
                  />
                </Grid>
              </InfoOnlyTooltip>
            ) : null}
          </Grid>
        </Grid>
        {/* property section */}
        <Grid
          container
          item
          xs={12}
          data-testid="property-tags"
          spacing={1}
          className={
            personPartyId
              ? classes.scenarioSectionListContainer
              : classes.scenarioSectionsContainer
          }
        >
          <section className={classes.scenarioTagsWrapper}>
            <Grid container item xs={12}>
              {propertyChips && propertyChips.length > 1 && !isDetailView ? (
                // propertyChips.map((item: string, index: number) =>
                <div className={classes.tagsContainer}>
                  <Typography className={classes.tagsItem}>
                    Multiple Properties {propertyChips?.length}
                  </Typography>
                  <span style={{ cursor: "pointer", display: "flex" }}>
                    <PopOverInfo
                      popOverData={propertyChips}
                      redirectionType="googleMap"
                      fromScenario
                    />
                  </span>
                </div>
              ) : propertyChips && propertyChips.length ? (
                propertyChips.map((property: any) => (
                  <div className={classes.tagsContainer}>
                    <Typography className={classes.tagsItem}>
                      {property}
                    </Typography>
                    <Button
                      className={classes.launchIcon}
                      variant="outlined"
                      href={`${googleMapLink}${property}`}
                      target="_blank"
                    >
                      <RedirectIcon color="#000000" />
                    </Button>
                  </div>
                ))
              ) : null}
              {attachedLoanArr &&
              attachedLoanArr.length > 1 &&
              !isDetailView ? (
                <div className={classes.tagsContainer}>
                  <Typography
                    className={classes.tagsItem}
                    style={{ paddingRight: "3px" }}
                  >
                    Multiple Loans {attachedLoanArr.length}
                  </Typography>
                  <span style={{ cursor: "pointer", display: "flex" }}>
                    <PopOverInfo
                      popOverData={popOverData}
                      attachedLoanArr={attachedLoanArr}
                      fromScenario
                      redirectionType="loanDetails"
                      onClose={removeLoanDialog}
                    />
                  </span>
                </div>
              ) : popOverData.length && attachedLoanArr.length ? (
                popOverData.map((loanTag: string, index: any) => (
                  <div className={classes.tagsContainer} key={loanTag}>
                    <Typography
                      className={classes.tagsItem}
                      onClick={() => redirectToLoanDetails(index)}
                      style={{ paddingRight: "5px", cursor: "pointer" }}
                    >
                      LOAN ID - {loanTag}
                    </Typography>
                    <span
                      style={{ cursor: "pointer", display: "flex" }}
                      onClick={() => redirectToLoanDetails(index)}
                    >
                      <RedirectIcon
                        color="#000000"
                        style={{ cursor: "pointer", marginLeft: 5 }}
                      />
                    </span>
                    <span>
                      <IconButton
                        aria-label="close"
                        onClick={() => removeLoanDialog(loanTag)}
                        // onClick={() => handleDeleteLoanFromScenario(loanTag)}
                        className={classes.closeButton}
                        size="large"
                      >
                        <CloseIcon color="#000000" />
                      </IconButton>
                    </span>
                  </div>
                ))
              ) : null}
              {loanType ? (
                <div className={classes.tagsStageContainer}>
                  <Typography className={classes.tagsItemBlack}>
                    {loanType} {propertyType}
                  </Typography>
                </div>
              ) : null}
            </Grid>
          </section>
        </Grid>
        {/* Subject and description */}
        <Grid
          container
          item
          xs={12}
          className={
            personPartyId
              ? classes.scenarioDescriptionListContainer
              : classes.scenarioDescriptionContainer
          }
        >
          <Grid xs={12} item>
            <Typography className={classes.scenarioSubject}>
              {subject}
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Typography className={classes.scenarioDescription}>
              {description}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <ConfirmationDialog
          onCancel={handleConfirmation}
          head="Delete Scenario"
          onConfirm={handleDeleteScenario}
          open={openConfirmBox}
          msg="Are you sure you want to delete the Scenario ?"
          btnText="Delete"
        />
      </Grid>
      <Grid>
        <ConfirmationDialog
          onCancel={handleDialog}
          head="Remove Loan"
          onConfirm={() => handleDeleteLoanFromScenario(loanTag)}
          open={openRemoveLoanDialog}
          msg="Are you sure you want to remove the loan from scenario?"
          btnText="Remove"
        />
      </Grid>
    </>
  );
};
