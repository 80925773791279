/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  Grid,
  // Typography,
  Theme,
  Chip,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import CloseIcon from "@mui/icons-material/Close";
import { ExpandCollapse } from "@toorak/tc-common-fe-sdk";
import { useParams } from "react-router-dom";
import { waiverStatusLowerCase } from "@toorak/tc-common-fe-sdk";
import { StylesEntity } from "../models";
// import { CommentsList } from "./CommentsList";
import {
  getCommentThread,
  getComments,
  createCommentThread,
  GetComments,
  updateWaiverComments
} from "../../../../stores/commentsSection/comment.action";
import { RootState } from "../../../../stores/rootReducer";
import {
  getChatLinesByChatSequenceId,
  updateResponseByResponseId,
  getViewMoreDetails
} from "../../../../stores/ExceptionsResults/ExceptionActions";
// import { ShowDialog } from "../../../waiver-form/ShowDialog";
import {
  showLoader,
  hideLoader
} from "../../../../stores/loaderAndException/loaderAndException.action";
import { getCookie } from "../../../../utils/cookies";
import { isViewOnly } from "../../../../utils/formatChecks";
import { isLATUser, isILP, isRole } from "../../../../utils/AccessManagement";
import { UnreadCountTag } from "../../../dashboard/notification";
// import { CommentsTab } from "./CommentsTab";
import { getChatsAndResponse } from "../../../create-loan.action";
import { getDashboardViewMore } from "../../../../stores/dashboardSection/dashboard.action";
import { InternalRolesEnum } from "../../../../utils/constants";

const CommentsTab = React.lazy(() =>
  import("./CommentsTab").then((module) => ({
    default: module.CommentsTab
  }))
);

const CommentsList = React.lazy(() =>
  import("./CommentsList").then((module) => ({
    default: module.CommentsList
  }))
);

const ShowDialog = React.lazy(() =>
  import("../../../waiver-form/ShowDialog").then((module) => ({
    default: module.ShowDialog
  }))
);

const CommentForm = React.lazy(() =>
  import("./CommentForm").then((module) => ({
    default: module.default
  }))
);

const defaultStyles = {
  // btn: (base: any) => ({ ...base }),
  // comment: (base: any) => ({ ...base }),
  // textarea: (base: any) => ({ ...base }),
};

export const CBContext = React.createContext<StylesEntity>(defaultStyles);

export interface Props {
  selectedCompensatingFactors?: any;
  comments: Record<string, any>;
  signinUrl: string;
  isLoggedIn?: boolean;
  reactRouter?: boolean;
  styles?: StylesEntity;
  exceptionId?: string;
  exceptionIdActInt?: any;
  approvalComment?: any;
  summaryComment?: boolean;
  approvalType?: any;
  chatLines?: any;
  chatSequenceId?: any;
  chatDetails?: any;
  discussionChatId?: string;
  loanStageType?: string;
  loanStage?: string;
  exceptionResponseId?: any;
  isEditable?: boolean;
  responseType?: string;
  exceptionType?: string;
  waiverId?: any;
  statusProp?: any;
}

export interface State {
  styles: StylesEntity;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      borderRadius: "4px 0px",
      display: "flex",
      flexDirection: "column",
      borderTop: "solid 2px #f2f2f2",
      backgroundColor: "#ffffff"
    },
    sectionWrapper: {
      padding: `${theme.spacing(1)}px 0px 0px 0px`,
      display: "flex",
      margin: "0 !important",
      width: "100%"
    },
    headingTypography: {
      textAlign: "left",
      fontSize: "14px !important",
      fontWeight: 600,
      color: "#32325d",
      lineHeight: 1.57
    },
    inputLable: {
      textAlign: "left",
      fontSize: "12px !important",
      fontWeight: 600,
      color: "#8897aa",
      lineHeight: 1.5,
      paddingBottom: 5
    },
    responseTypography: {
      textAlign: "left",
      fontSize: "14px !important",
      fontWeight: 600,
      color: "#32325d"
      // marginBottom: "10px"
    },
    submitButton: {
      height: "36px"
    },
    uploadButton: {
      backgroundColor: "#ffffff",
      color: "#32325d",
      minWidth: "0px",
      minHeight: "0px",
      padding: "6px",
      height: "36px",
      margin: "0px"
    },
    red: {
      color: "#f75676"
    },
    headingImageContainer: {
      justifyContent: "flex-end",
      display: "flex"
    },
    summaryCommentStyle: {
      width: "85%"
    },
    headingImage: {
      width: 16,
      height: 16,
      objectFit: "contain",
      cursor: "pointer"
    },
    newCommentChip: {
      background: "#f75676",
      color: "#ffffff",
      height: "auto",
      width: "auto",
      marginLeft: "10px",
      borderRadius: "9px",
      "& .MuiChip-label": {
        fontWeight: "bold",
        fontSize: "10px",
        padding: "1px 8px !important"
      }
    },
    commentCard: {
      backgroundColor: "#F6F9FC"
    }
  })
);
export const CommentsBlock = (Props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { rescind } = waiverStatusLowerCase;
  const { exceptionId: exceptionIdFromURL } = useParams<{
    type: string;
    exceptionId: any;
  }>() as any;
  const currentURL = window.location.href;
  const notFromApprover = currentURL.includes("view");
  const [filesList, setFilesList] = useState<any>([]);
  const [dialogdetails, setDialogdetails] = useState<any>({});
  const [openDialog, setOpenDialog] = useState(false);
  const [styles] = React.useState(defaultStyles);
  const {
    selectedCompensatingFactors,
    exceptionId,
    hostType,
    hostId,
    exceptionIdProp,
    approvalComment,
    approvalType,
    summaryComment,
    OptionalRightHeader,
    onCloseHandler,
    customStyles,
    showSenderAvatar,
    chatLines,
    chatSequenceId,
    chatDetails,
    hideCollapse,
    customLable,
    optionalUnReadView,
    discussionChatId,
    loanStageType,
    customHeaderStyles,
    exceptionResponseId,
    loanStage,
    loanId,
    commentFormstyles,
    waiverResponseType,
    disableComment,
    isViewMode, // use this to disable all action after conversion
    isWithdrawnLoan,
    unreadCount,
    hideSpinner,
    visibility,
    isEditable,
    responseType,
    showCloseDiscussion,
    waiverChat,
    customerDetails,
    isCarryForwardedWaiver,
    keyId,
    TagComments,
    RequestAndTakeAction,
    // userTags,
    activeInteractionsModal,
    exceptionType,
    condtType,
    waiverId,
    cardType,
    currentLoanStage,
    statusProp,
    addCommentCB,
    exception
  } = Props;
  let isInquiry: boolean;
  if (exceptionIdFromURL || exceptionType) {
    isInquiry = !(
      window.location.pathname.includes("/Condition/") ||
      exceptionType === "Condition"
    );
  } else {
    isInquiry = false;
  }

  const [updateComments, setUpdateComments] = React.useState<boolean>(true);
  const [isMinimized, setIsMinimized] = useState<boolean>(false);
  const [threadList, setThreadList] = React.useState<any>({});
  const [updateCommentsThread, setUpdateCommentsThread] =
    React.useState<boolean>(true);
  const [selectedTags, setSelectedTags] = useState([]);
  const comments: any = useSelector<RootState>(
    (state) => state.commentStore.comments
  );
  const status: any = useSelector<RootState>(
    (state) =>
      state.exceptionsResultsStore?.exceptionsResult?.exceptions?.[0]?.status
  );
  const loading: any = useSelector<RootState>(
    (state) => state.commentStore.loading
  );

  const userTags = useSelector<RootState, any>(
    (state) => state.scenarioResultsStore.userLists
  );
  const personPartyId: string | any = getCookie("person_id");
  const commentsList = comments[hostId] || {};
  const threadIdentifier = chatSequenceId || hostId;
  let type = exceptionId ? "EXCEPTION" : "waiver";
  const newUnreadCount = !unreadCount ? comments[hostId]?.count : unreadCount;
  const chatUserDetails =
    activeInteractionsModal ||
    exceptionId ||
    (window.location.href.indexOf("view") === -1 && !summaryComment)
      ? chatDetails
      : commentsList;

  let responseLength: number = 0;
  responseLength = !exceptionId
    ? commentsList?.chatLines?.length
    : chatLines?.length - 1;
  if (discussionChatId && responseLength) {
    responseLength -= 1;
  }
  const isRescind: boolean = waiverResponseType?.toLowerCase() === rescind;
  if (isRescind) {
    responseLength = TagComments?.length;
  }
  useEffect(() => {
    if (waiverChat?.chatSequence) {
      setThreadList(waiverChat.chatSequence);
    }
  }, [waiverChat]);

  useEffect(() => {
    // eslint-disable-next-line
    if (hostType) type = hostType;
    if (
      type === "LOAN" &&
      loanStageType === "LOAN_STAGE" &&
      discussionChatId &&
      loanStage
    ) {
      dispatch(showLoader());
      getCommentThread(type, loanId, loanStageType, loanStage)
        .then((res: any) => {
          dispatch(hideLoader());
          setThreadList(res);
          // updateLastReadComment(discussionChatId, personPartyId);
        })
        .catch((error: any) => {
          dispatch(hideLoader());
        });
    }
    // type !== "waiver" ||
    //   hostType === "scenario" ||
    //   hostType === "BUNDLE" ||
    //   hostType === "loan"
    else if (hostType) {
      getCommentThread(type, threadIdentifier, "loan", loanId).then(
        (res: any) => {
          setThreadList(res);
          // if(chatSequenceId) {updateLastReadComment(chatSequenceId, personPartyId);}
          if (!res.length && !exceptionId) {
            const id = threadIdentifier || hostId;
            createCommentThread(type, id, "loan", loanId).then(() => {
              setUpdateCommentsThread(!updateCommentsThread);
            });
          }
        }
      );
    }
  }, [
    exceptionId,
    updateCommentsThread,
    threadIdentifier,
    loanId,
    discussionChatId
  ]);
  const onSubmitNewComment = (
    text: boolean,
    sequenceId?: any,
    _type?: any,
    files?: any,
    textNew?: any,
    hasDocuments?: boolean
  ) => {
    // dispatch(showLoader(true));
    // const chatType = _type && _type.type;
    if (text) {
      setUpdateCommentsThread(!updateCommentsThread);
    }
    if (text && exceptionId) {
      dispatch(
        updateResponseByResponseId(
          exceptionResponseId,
          exceptionId,
          loanStage,
          loanId
        )
      );
      dispatch(getChatLinesByChatSequenceId(sequenceId, personPartyId));
      // if (
      //   (responseType === "ASK_A_QUESTION" ||
      //     responseType === "SATISFY_EXCEPTION") &&
      //   (paramsType === "Condition" || exceptionType === "Condition") &&
      //   hasDocuments
      // ) {
      //   ;
      //   dispatch(uploadDocToBoxCom(loanId, exceptionId, responseType));
      // }
      dispatch(hideLoader());
    }
    if (
      activeInteractionsModal ||
      window.location.href.indexOf("tab=0") > -1 ||
      (window.location.href.indexOf("createLoan") > -1 && !summaryComment)
    ) {
      setTimeout(() => {
        if (activeInteractionsModal) {
          dispatch(
            getChatsAndResponse(
              loanId,
              loanStage,
              visibility,
              exceptionIdProp || hostId,
              cardType,
              condtType,
              waiverId
            )
          );
        }

        if (window.location.href.indexOf("tab=0") > -1) {
          const visibilityVal = visibility || "external";
          dispatch(
            getDashboardViewMore(
              loanId,
              loanStage,
              visibilityVal,
              exceptionIdProp || hostId,
              "Condition"
            )
          );
        }

        if (
          window.location.href.indexOf("createLoan") > -1 &&
          !summaryComment
        ) {
          dispatch(
            getViewMoreDetails(
              loanId,
              loanStage,
              visibility,
              exceptionIdProp || exceptionId || hostId,
              "Condition",
              true,
              true
            )
          );
        }
        setTimeout(() => {
          dispatch(hideLoader());
        }, 500);
      }, 2000);
    }
    if (addCommentCB) {
      addCommentCB(textNew);
    }

    setTimeout(() => {
      dispatch(hideLoader());
    }, 500);
    setUpdateComments(!updateComments);
    /**
     * the above set variable is not getting updated.. so had to call the function below for discussions only
     */
    window.location.pathname.includes("/discussion/") && getCommentsAPI();
    if (approvalComment) {
      Props.onApproval(textNew);
      // callBack(textNew);
    }
  };

  function getCommentsAPI() {
    if (threadList[0] && hostType) {
      const threadId = discussionChatId || threadList[0].id;
      const commentData: GetComments = {
        chatId: threadId,
        type,
        hideSpinner,
        hostId,
        partyId: personPartyId
      };
      getComments(commentData, dispatch);
    }
  }

  useEffect(() => {
    getCommentsAPI();
  }, [threadList, updateComments, dispatch, hostId, type, personPartyId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      type === "waiver" &&
      hostId &&
      personPartyId &&
      hostType === undefined &&
      window.location.href.indexOf("view") > -1
    ) {
      if (
        threadList[0] &&
        waiverChat &&
        customerDetails &&
        Object.keys(customerDetails).includes("accountMapping")
      ) {
        const threadId = threadList[0].id;
        const commentData: GetComments = {
          chatId: threadId,
          type,
          hideSpinner,
          hostId,
          partyId: personPartyId
        };
        dispatch(
          updateWaiverComments(
            commentData,
            waiverChat,
            customerDetails,
            isCarryForwardedWaiver
          )
        );
      }
    }
  }, [threadList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      chatSequenceId !== undefined &&
      window.location.href.indexOf("view") > -1 &&
      !isRole(InternalRolesEnum.LAT_APPROVER) &&
      !isRole(InternalRolesEnum.LAT_ADMIN) //for these users it is taken care in getResponsesByExceptionId function <ExceptionAction.ts>
    ) {
      const commentData: GetComments = {
        chatId: chatSequenceId,
        hideSpinner,
        partyId: personPartyId
      };
      getComments(commentData, dispatch);
    }
  }, [chatSequenceId, dispatch, personPartyId]); // eslint-disable-line react-hooks/exhaustive-deps

  const finalChatLine = isRescind
    ? TagComments
    : chatLines || commentsList.chatLines;

  const statusVal: any =
    window.location.href.indexOf("view") > -1 ? status : statusProp;
  const statusDisable = !!(
    statusVal &&
    (statusVal?.toLowerCase() === "approved" ||
      statusVal?.toLowerCase() === "approved-conditionally" ||
      statusVal?.toLowerCase() === "satisfied" ||
      statusVal?.toLowerCase() === "rejected" ||
      statusVal?.toLowerCase() === "rescinded" ||
      statusVal?.toLowerCase() === "rescind" ||
      statusVal?.toLowerCase() === "waived" ||
      (waiverResponseType &&
        waiverResponseType?.toLowerCase() === "approved") ||
      waiverResponseType?.toLowerCase() === "approved-conditionally" ||
      waiverResponseType?.toLowerCase() === "rejected")
  );
  return (
    <React.Fragment key={keyId ? `${keyId}-${chatSequenceId}` : chatSequenceId}>
      <CBContext.Provider value={styles}>
        {!approvalComment ? (
          <div
            className={
              summaryComment
                ? classes.summaryCommentStyle
                : isILP() && notFromApprover
                ? [classes.container, classes.commentCard].join(" ")
                : classes.container
            }
            style={customStyles ? customStyles.container : {}}
            data-testid="comment-block-container"
          >
            <Grid container className={classes.sectionWrapper}>
              <Grid item sm={12}>
                {visibility !== "internal" ? (
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    style={{
                      margin: !summaryComment ? "15px 0" : "0px",
                      ...customHeaderStyles
                    }}
                  >
                    <Grid item data-testid="response-div" xs={6}>
                      <>
                        <Grid container spacing={1}>
                          <Grid item className={classes.responseTypography}>
                            {visibility === "internal" || summaryComment
                              ? null
                              : isILP() || isInquiry
                              ? "Comments "
                              : customLable || "Responses "}
                            {!optionalUnReadView &&
                            !isLATUser() &&
                            responseLength !== undefined
                              ? `(${
                                  responseLength && responseLength > 0
                                    ? responseLength
                                    : 0
                                })`
                              : null}{" "}
                          </Grid>
                          {newUnreadCount && !optionalUnReadView ? (
                            <Grid
                              item
                              style={{
                                display: "flex",
                                alignItems: "center"
                                // padding: "3px 5px"
                              }}
                            >
                              {" "}
                              <UnreadCountTag
                                status="NEW"
                                unreadCount={
                                  newUnreadCount > 0 && newUnreadCount
                                }
                                background="#f75676"
                                color="#fff"
                                padding="0px 3px 1px"
                              />{" "}
                            </Grid>
                          ) : null}
                          {optionalUnReadView && !summaryComment ? (
                            <Grid item>
                              <Chip
                                label={optionalUnReadView}
                                className={classes.newCommentChip}
                              />
                            </Grid>
                          ) : null}
                        </Grid>
                      </>
                    </Grid>
                    <Grid item data-testid="right-header">
                      <Grid
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Grid item>{OptionalRightHeader}</Grid>
                        {onCloseHandler ? (
                          <Grid item style={{ display: "flex" }}>
                            <span
                              style={{ marginLeft: "20px", height: "16px" }}
                              onClick={onCloseHandler}
                            >
                              <CloseIcon
                                style={{ width: "auto", cursor: "pointer" }}
                              />
                            </span>
                          </Grid>
                        ) : null}
                      </Grid>
                    </Grid>
                    {!hideCollapse && visibility !== "internal" ? (
                      <Grid item data-testid="expand-collapse-div">
                        <ExpandCollapse
                          isMinimize={isMinimized}
                          clicked={() => {
                            setIsMinimized(!isMinimized);
                          }}
                          keyDown={() => {
                            setIsMinimized(!isMinimized);
                          }}
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                ) : null}
                {/* comment tab section starts */}
                {!isInquiry ? (
                  <Grid item>
                    {!summaryComment && isLATUser() && !isILP() ? (
                      <React.Suspense fallback={null}>
                        <Typography
                          component="span"
                          className={classes.responseTypography}
                        >
                          <CommentsTab
                            visibility={visibility === "internal"}
                            hostId={hostId}
                            activeInteractionsModal={activeInteractionsModal}
                            isMinimized={isMinimized}
                            commentsList={commentsList}
                            isCarryForwardedWaiver={isCarryForwardedWaiver}
                            chatSequenceId={chatSequenceId}
                            exceptionId={exceptionId}
                            isWithdrawnLoan={isWithdrawnLoan}
                            finalChatLine={finalChatLine}
                            summaryComment={summaryComment}
                            chatUserDetails={chatUserDetails}
                            discussionChatId={discussionChatId}
                            loanStage={loanStage}
                            statusDisable={statusDisable}
                            disableComment={disableComment}
                            threadList={threadList}
                            onSubmitNewComment={onSubmitNewComment}
                            approvalComment={approvalComment}
                            approvalType={approvalType}
                            showSenderAvatar={showSenderAvatar}
                            filesList={filesList}
                            setFilesList={setFilesList}
                            dispatch={dispatch}
                            setDialogdetails={setDialogdetails}
                            setOpenDialog={setOpenDialog}
                            loading={loading}
                            status={statusVal}
                            loanId={loanId}
                            exceptionResponseId={exceptionResponseId}
                            commentFormstyles={commentFormstyles}
                            hostType={hostType}
                            setMinimized={setIsMinimized}
                            setSelectedTags={setSelectedTags}
                            selectedTags={selectedTags}
                            userTags={userTags}
                            isRescind={isRescind}
                            currentLoanStage={currentLoanStage}
                            condtType={exceptionType}
                          />
                        </Typography>
                      </React.Suspense>
                    ) : (
                      ""
                    )}
                  </Grid>
                ) : (
                  ""
                )}
                {(summaryComment || !isLATUser() || isILP() || isInquiry) &&
                !isMinimized &&
                commentsList ? (
                  <React.Suspense fallback={null}>
                    <React.Fragment
                      key={
                        keyId
                          ? `${keyId}-${chatSequenceId}`
                          : chatSequenceId || hostId
                      }
                    >
                      <CommentsList
                        key={
                          keyId
                            ? `${keyId}-${chatSequenceId}`
                            : chatSequenceId || hostId
                        }
                        keyId={keyId || chatSequenceId || hostId}
                        chatSequenceId={chatSequenceId}
                        exceptionId={exceptionId}
                        comments={finalChatLine}
                        reactRouter={false}
                        summaryComment={summaryComment}
                        chatUserDetails={chatUserDetails}
                        discussionChatId={discussionChatId}
                        isEditable={isEditable}
                        threadList={threadList}
                        hostId={hostId}
                      />
                    </React.Fragment>
                  </React.Suspense>
                ) : null}
                {(summaryComment || !isLATUser() || isILP() || isInquiry) &&
                !isMinimized &&
                !isWithdrawnLoan &&
                !isViewOnly(loanStage, loanId, currentLoanStage) &&
                !statusDisable &&
                !isRescind &&
                !isWithdrawnLoan &&
                !isCarryForwardedWaiver ? (
                  !disableComment && !isViewMode ? (
                    <CommentForm
                      selectedCompensatingFactors={selectedCompensatingFactors}
                      thread={threadList}
                      commentList={commentsList}
                      onSubmitNewComment={onSubmitNewComment}
                      approval={approvalComment}
                      approvalType={approvalType}
                      showSenderAvatar={showSenderAvatar}
                      chatSequenceId={chatSequenceId}
                      filesList={filesList}
                      setFilesList={setFilesList}
                      summaryComment={summaryComment}
                      dispatch={dispatch}
                      showCloseDiscussion={showCloseDiscussion}
                      setDialogdetails={setDialogdetails}
                      setOpenDialog={setOpenDialog}
                      discussionChatId={discussionChatId}
                      loading={loading}
                      loanId={loanId}
                      loanStage={loanStage}
                      exceptionResponseId={exceptionResponseId}
                      status={statusVal}
                      commentFormstyles={commentFormstyles}
                      hostType={hostType}
                      visibility={visibility}
                      responseType={responseType}
                      setSelectedTags={setSelectedTags}
                      selectedTags={selectedTags}
                      userTags={userTags}
                      condtType={
                        cardType?.toLowerCase() === "condition"
                          ? exceptionType === "Inquiry"
                            ? exceptionType
                            : "condition"
                          : condtType || exceptionType
                      }
                      exception={exception}
                    />
                  ) : null
                ) : null}
              </Grid>
            </Grid>
          </div>
        ) : (
          <div>
            {/* //Only for LAT action pop-up */}
            <Grid className={classes.inputLable}>
              Add your comment<sup className={classes.red}>*</sup>
            </Grid>
            <CommentForm
              selectedCompensatingFactors={selectedCompensatingFactors}
              RequestAndTakeAction={RequestAndTakeAction}
              thread={threadList}
              setDialogdetails={() => {}}
              commentList={commentsList}
              onSubmitNewComment={onSubmitNewComment}
              approval={approvalComment}
              approvalType={approvalType}
              loanId={loanId}
              dispatch={dispatch}
              discussionChatId={discussionChatId}
              loading={loading}
              loanStage={loanStage}
              status={statusVal}
              filesList={filesList}
              setFilesList={setFilesList}
              showCloseDiscussion={showCloseDiscussion}
              visibility={visibility === "internal"}
              condtType={
                cardType?.toLowerCase() === "condition"
                  ? exceptionType === "Inquiry"
                    ? exceptionType
                    : "condition"
                  : condtType
              }
            />
          </div>
        )}
        <Grid>
          <ShowDialog
            dialogdetails={dialogdetails}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
          />
        </Grid>
      </CBContext.Provider>
    </React.Fragment>
  );
};
CommentsBlock.defaultProps = {
  styles: defaultStyles
};
