/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import {
//   CLEAR_CLOSING_WIRE_FEE_STORE,
//   CLOSING_WIRE_ADD_FEE_STORE,
//   CLOSING_WIRE_FEE_COMMENT_STORE,
//   CLOSING_WIRE_FEE_EDIT_STORE,
//   RM_CLOSING_WIRE_FEE_EDIT_STORE
// } from "./closingWire.action";

export interface CWStore {
  editedFees: { [key: string]: string };
  addFees: { [key: string]: string };
  cwCommnets: { [key: string]: string };
}

export const initialState: CWStore = {
  editedFees: {},
  addFees: {},
  cwCommnets: {}
};

const cwSlice = createSlice({
  name: "cwReducer",
  initialState,
  reducers: {
    closingWireAddFeeStore: (state, action: PayloadAction<any>) => {
      state.addFees = { ...state.addFees, ...action.payload };
    },
    closingWireFeeEditStore: (state, action: PayloadAction<any>) => {
      state.editedFees = { ...state.editedFees, ...action.payload };
    },
    rmClosingWireFeeEditStore: (state, action: PayloadAction<string>) => {
      const delKey = action.payload;
      delete state.editedFees[delKey];
      delete state.cwCommnets[delKey];
    },
    closingWireFeeCommentStore: (state, action: PayloadAction<any>) => {
      state.cwCommnets = { ...state.cwCommnets, ...action.payload };
    },
    clearClosingWireFeeStore: (state) => {
      state.editedFees = {};
      state.cwCommnets = {};
      state.addFees = {};
    }
  }
});

export const {
  closingWireAddFeeStore,
  closingWireFeeEditStore,
  rmClosingWireFeeEditStore,
  closingWireFeeCommentStore,
  clearClosingWireFeeStore
} = cwSlice.actions;

export const CWReducer = cwSlice.reducer;

// export const CWReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case CLOSING_WIRE_ADD_FEE_STORE: {
//       const nextState = produce(state, draftState => {
//         const oldData = state.addFees;
//         const newData = {
//           ...oldData,
//           ...action.payload
//         };
//         draftState.addFees = newData;
//       });
//       return nextState;
//     }
//     case CLOSING_WIRE_FEE_EDIT_STORE: {
//       const nextState = produce(state, draftState => {
//         const oldData = state.editedFees;
//         const newData = {
//           ...oldData,
//           ...action.payload
//         };
//         draftState.editedFees = newData;
//       });
//       return nextState;
//     }
//     case RM_CLOSING_WIRE_FEE_EDIT_STORE: {
//       const nextState = produce(state, draftState => {
//         const oldFeeStore = state.editedFees;
//         const oldCWStore = state.cwCommnets;
//         const delKey = action.payload;
//         const newCWData = {
//           ...oldCWStore
//         };
//         delete newCWData[delKey];
//         const newFeeData = {
//           ...oldFeeStore
//         };
//         delete newFeeData[delKey];
//         draftState.editedFees = newFeeData;
//         draftState.cwCommnets = newCWData;
//       });
//       return nextState;
//     }
//     case CLOSING_WIRE_FEE_COMMENT_STORE: {
//       const nextState = produce(state, draftState => {
//         const oldData = state.cwCommnets;
//         const newData = {
//           ...oldData,
//           ...action.payload
//         };
//         draftState.cwCommnets = newData;
//       });
//       return nextState;
//     }
//     case CLEAR_CLOSING_WIRE_FEE_STORE: {
//       const nextState = produce(state, draftState => {
//         draftState.editedFees = {};
//         draftState.cwCommnets = {};
//         draftState.addFees = {};
//       });
//       return nextState;
//     }
//     default:
//       return state;
//   }
// };
