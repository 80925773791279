import { makeStyles, createStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      input: {
        backgroundColor: "#ffffff !important"
      }
    },
    checkBoxContainer: {
      border: "1px solid rgb(233, 236, 239)",
      padding: "14px 16px 14px 24px",
      borderRadius: "4px"
    },
    container: {
      borderRadius: 4,
      display: "flex",
      flexDirection: "column",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      backgroundColor: "#ffffff",
      marginTop: theme.spacing(3)
      // fontFamily: "OpenSans"
    },
    sectionWrapper: {
      padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
      display: "flex"
    },
    subHeaderWrapper: {
      color: theme.palette.text.secondary,
      borderRadius: "1px",
      display: "flex",
      alignItems: "center"
    },
    paper: {
      maxWidth: "786px",
      height: "2478px",
      // margin: "52px 333px 147px 321px",
      // padding: "69px 16px 19px 15px",
      borderRadius: "4px",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      backgroundColor: "#ffffff"
    },

    allProperties: {
      fontSize: 12,
      fontWeight: 600,
      color: "#32325d"
    },
    headingTypography: {
      textAlign: "left",
      fontSize: "14px !important",
      fontWeight: 600,
      color: "#32325d",
      lineHeight: 1.57
    },
    scrollParentContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      overflow: "hidden",
      flexGrow: 1
    },
    propertyStepContainer: {
      position: "relative",
      flexShrink: 0,
      cursor: "pointer",
      borderRadius: 4,
      margin: "30px 24px 5px 24px",
      borderWidth: 1,
      borderStyle: "dashed",
      borderColor: "#8897aa",
      width: "20%"
    },
    addIconContainer: {
      color: "#5e72e4",
      borderRadius: 48,
      // width: 48,
      // height: 48,
      flexShrink: 0,
      backgroundColor: "#ffffff",
      margin: "6px auto",
      // margin: "12px 0px 0px 5px",
      padding: "22px 19px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        padding: "0px"
      }
    },
    addPropertyContainer: {
      // margin: "31px 30px 20px 15px",
      margin: "auto",
      fontSize: 12,
      fontWeight: 600,
      color: "#8897aa"
      // [theme.breakpoints.down("sm")]  : {
      //   padding: "0px"
      // }
    },
    headingImageContainer: {
      justifyContent: "flex-end",
      display: "flex"
    },
    modalDeleteButton: {
      marginRight: 12,
      color: "#ffffff",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      marginTop: 18,
      borderWidth: 0
    },
    modalButton: {
      marginRight: 12,
      color: "#ffffff",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      marginTop: 18,
      borderWidth: 0,
      "&:hover": {
        backgroundColor: "#5566d6 !important"
      }
    },
    modalCancelButton: {
      color: "#32325d",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      backgroundColor: "#ffffff",
      marginTop: 18,
      borderWidth: 1,
      borderColor: "#ececec"
    },
    modalDeleteButtonConatainer: {
      marginTop: 50
    },
    headingImage: {
      width: 16,
      height: 16,
      objectFit: "contain",
      cursor: "pointer"
    },
    editImage: {
      width: 16,
      height: 16,
      marginRight: 16,
      objectFit: "contain",
      cursor: "pointer"
    },
    editImageDisplay: {
      display: "none"
    },
    savebutton: {
      width: 70,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4"
    },
    cancelButton: {
      width: 69,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      marginLeft: 10,
      color: "#32325d",
      backgroundColor: "#fff",
      "&:hover": {
        background: "none"
      }
    },
    divider: {
      height: "1px",
      border: "solid 1px #f2f2f2",
      padding: "0px !important"
    },
    calculateCondoButton: {
      width: "194px",
      height: "36px",
      padding: "7px 21px 7px 22px",
      borderRadius: "4px",
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundcolor: "#5566d6"
    },
    downloadBtn: {
      width: "110px",
      height: "36px",
      padding: "7px 25px 7px 26px",
      borderRadius: "4px",
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      border: "solid 1px #ececec",
      backgroundColor: "#ffffff",
      color: "black",
      marginLeft: "5px"
    },
    cancelCondoBtn: {
      width: "90px",
      height: "36px",
      padding: "7px 25px 7px 26px",
      borderRadius: "4px",
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      border: "solid 1px #ececec",
      backgroundColor: "#ffffff",
      color: "black",
      marginLeft: "5px"
    },
    formSelectContainer: {
      justifyContent: "space-around",
      display: "flex",
      flexDirection: "row",
      width: "95%",
      padding: "8px 0px 0px 0px"
    },
    iconInfo: {
      width: "16px !important",
      height: "16px !important",
      color: "#32355d",
      "&:hover": {
        color: "#5e72e4"
      }
    },
    labelInformation: {
      fontSize: 12,
      fontWeight: 600,
      color: "#8897aa",
      height: "14px",
      "&:hover": {
        color: "#5e72e4"
      }
    }
  })
);
