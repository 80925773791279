import { Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { TextOnlyTooltip } from "../../utils/Tooltip";
import { useStyles } from "../../create-loan/common-style/CreateLoan.style";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  BudgetReviewStore,
  BudgetValidationStatus
} from "../../stores/budgetReview/budgetReview.interface";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores/rootReducer";
import { sanitizeCurrency } from "../../utils/formatChecks";
import { formatPropertyAddress } from "../../create-loan/property-details/PropertyDetailsCard";
import { initBudgetErrors,updateBudgetErrors } from "../../stores/budgetReview/budgetReview.reducer";


export function BudgetHeaderRightErrors() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { BulkDocsData, BulkBudgetSum } = useSelector<
    RootState,
    BudgetReviewStore
  >(state => state.budgetReviewStore);
  const { loanDetails } = useSelector<RootState, any>(
    state => state.createLoanStore
  );
  const propertyDetails = useSelector<RootState, any[]>(
    state => state.createLoanStore.loanDetails.propertyDetails
  );
  const [totalBudget, setTotalBudget] = useState<string>("");
  const [rehabBudget, setRehabBudget] = useState<string>("");
  const [budgetErrorsLocal, setBudgetErrorsLocal] = useState<
    BudgetValidationStatus
  >(initBudgetErrors);

  useEffect(() => {
   dispatch(updateBudgetErrors(budgetErrorsLocal));
  }, [dispatch, budgetErrorsLocal]);

  const getMappedDocsData = useCallback(
    (loanPropertyOrder: any) => {
      let selectedPropData = BulkDocsData.filter(docsData => {
        if (docsData.staticData.documentData?.propertyId) {
          return (
            // eslint-disable-next-line
            docsData.staticData.documentData.propertyId == loanPropertyOrder
          );
        }
        return false;
      });
      return selectedPropData.length
        ? selectedPropData[0].staticData?.documentData?.fileName
        : null;
    },
    [BulkDocsData]
  );

  useEffect(() => {
    let budgetAmount = loanDetails.bridgeLoanEconomics.payload.budgetAmount;
    let financedBudgetAmount =
      loanDetails.bridgeLoanEconomics.payload.financedBudgetAmount;
    if (!(budgetAmount && financedBudgetAmount)) return;

    setRehabBudget(budgetAmount);
  }, [loanDetails]);

  useEffect(() => {
    if (!BulkBudgetSum.grandRehabBudget) return;
    setTotalBudget(sanitizeCurrency(`${BulkBudgetSum.grandRehabBudget}`));
  }, [BulkBudgetSum.grandRehabBudget]);

  useEffect(() => {
    if (!rehabBudget) return;
    const budgetErrorsCopy = { ...budgetErrorsLocal };
    if (totalBudget !== rehabBudget) {
      budgetErrorsCopy.hasErrors = true;
      budgetErrorsCopy.budget = true;
    } else {
      budgetErrorsCopy.budget = false;
      if (!budgetErrorsCopy.property /*  && !budgetErrorsCopy.lineItem */)
        budgetErrorsCopy.hasErrors = false;
    }
    setBudgetErrorsLocal(budgetErrorsCopy);
    // eslint-disable-next-line
  }, [totalBudget, rehabBudget]);

  useEffect(() => {
    const budgetErrorsCopy = { ...budgetErrorsLocal };
    if (!BulkBudgetSum.grandFieldValidation) {
      // budgetErrorsCopy.hasErrors = true;
      // budgetErrorsCopy.lineItem = true;
    } else {
      if (!budgetErrorsCopy.property && !budgetErrorsCopy.budget)
        budgetErrorsCopy.hasErrors = false;
      // budgetErrorsCopy.lineItem = false;
    }
    setBudgetErrorsLocal(budgetErrorsCopy);
    // eslint-disable-next-line
  }, [BulkBudgetSum]);

  useEffect(() => {
    if (!BulkDocsData.length) return;
    const tempPropAddressUpdateOption = propertyDetails.map(propLocation => ({
      title: formatPropertyAddress(propLocation.propertyLocation.payload),
      numberOfUnits: propLocation.propertyInformation.payload.numberOfUnits,
      loanPropertyOrder: propLocation.globalPropertyId,
      propertyInfo: propLocation.propertyLocation.payload.address,
      mappedDocsData: getMappedDocsData(propLocation.loanPropertyOrder)
    }));
    checkIfPropAddressMapped(tempPropAddressUpdateOption);
    // eslint-disable-next-line
  }, [BulkDocsData, BulkBudgetSum]);

  const checkIfPropAddressMapped = (tempPropAddressUpdateOption: any[]) => {
    if (!tempPropAddressUpdateOption.length) return;
    const isAllPropMapped = tempPropAddressUpdateOption.find(
      item => !item.mappedDocsData
    );
    const budgetError = { ...budgetErrorsLocal };
    if (totalBudget === rehabBudget) {
      budgetError.budget = false;
    }
    if (isAllPropMapped && !isAllPropMapped.mappedDocsData) {
      budgetError.hasErrors = true;
      budgetError.property = true;
    } else {
      if (/* !budgetError.lineItem &&  */ !budgetError.budget) {
        budgetError.hasErrors = false;
      }
      budgetError.property = false;
    }
    if (budgetErrorsLocal.property !== budgetError.property) {
      setBudgetErrorsLocal(budgetError);
    }
  };

  return (
    <>
      {budgetErrorsLocal.hasErrors && (
        <div style={{ marginLeft: 15 }}>
          <Typography
            key={"errors-badge"}
            variant="h5"
            className={classes.itemTypography}
            style={{
              backgroundColor: "#fb623f",
              color: "#ffffff"
            }}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <span>Errors</span>
              <TextOnlyTooltip
                classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
                placement="right"
                arrow
                data-testid="error-tooltip"
                title={
                  <>
                    {budgetErrorsLocal.property && (
                      <li className={classes.pendingItem}>
                        {`Property missing Budget Information`}
                      </li>
                    )}
                    {budgetErrorsLocal.budget && (
                      <li className={classes.pendingItem}>
                        {`Total Budget Mismatch`}
                      </li>
                    )}
                    {/* {budgetErrorsLocal.lineItem && (
                      <li className={classes.pendingItem}>
                        {`Standardized Item missing`}
                      </li>
                    )} */}
                  </>
                }
              >
                <InfoOutlinedIcon
                  className={classes.icon}
                  htmlColor="#ffffff"
                  style={{ marginLeft: "2px" }}
                  data-testid={"info-icon"}
                />
              </TextOnlyTooltip>
            </span>
          </Typography>
        </div>
      )}
    </>
  );
}
