/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-duplicates */
import React, { useState, useEffect, useMemo } from "react";
import {
  Grid,
  // makeStyles,
  Theme,
  //createStyles,
  Typography,
  Button,
  Tooltip,
  TooltipProps,
  IconButton,
  Badge
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  IconTooltip,
  // WarningModal,
  // WarningModalProps,
  LoanStage,
  waiverStatusLowerCase
} from "@toorak/tc-common-fe-sdk";
import { push } from "connected-react-router";
import { useParams } from "react-router-dom";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import errorIcon from "../../images/guidelineOutput/browse-copy.svg";
import iconLink from "../../images/guidelineOutput/link.svg";
import helpIcon from "../../images/help@2x.png";
import { setActiveTabAndProperty } from "../create-loan.action";
import {
  checkCarryForwardWaiver,
  getPartyId,
  waiverDetails
} from "../../stores/WaiverRequest/WaiverRequest.action";
import { StatusComponent } from "../waiver-form/StatusComponent";
import { FailedPropertyAddress } from "../failed-property-address/FailedPropertyAddress";
import { UserDetailsCard } from "../exceptions-and-enquiries/UserDetailsCard";
import { RootState } from "../../stores/rootReducer";
import { genFormattedUserDetails } from "../../stores/EvaluationResults/EvaluationResults.action";
// import { setCookieWithIdentifier } from "../../utils/cookies";
import { loanStageToReadable } from "../createLoanHeaders/ActionDropDown";
import { getConfig, getLoanType } from "../../config/config";
import { CommentIcon, RedirectIcon } from "../Icons";
import {
  // IconButtonDropdownStyles,
  // IconButtonDropdownStyles,
  IconButtonDropdownStylesWithoutBG
} from "../waiver-form/DocumentsForm.style";
import { InfoOnlyTooltip } from "../../utils/Tooltip";
import { ExceptionCardRescindIcon } from "../exceptions-and-enquiries/ExceptionCardRescindIcon";
import { getLatestWaiverResponse } from "../../utils/utils";
import CompensatingFactors from "../../ui-components/CompensatingFactor";
import { waiverRequestConfig } from "../../utils/constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      border: "1px solid red",
      borderRadius: "4px"
      // marginTop: "5px"
    },
    approvedContainer: {
      border: "1px solid rgb(46, 206, 137)",
      borderRadius: "4px"
    },
    generalContainer: {
      border: "1px solid #e9ecef",
      borderRadius: "4px",
      marginTop: "5px"
    },
    headingContainer: {
      padding: "14px 0px 0px 14px",
      alignItems: "center"
    },
    userDetail: {
      padding: "10px 0px 10px 14px;"
    },
    userDetailButtonContainer: {
      padding: "5px 0px 10px 14px;"
    },
    headingTypography: {
      fontSize: "12px !important",
      fontWeight: "bold",
      color: "#32325d",
      overflowWrap: "break-word",
      paddingRight: "8px"
    },
    mandatoryBadge: {
      fontSize: "10px !important",
      fontWeight: "bold",
      color: "#092b53",
      borderRadius: 9,
      backgroundColor: "#e5fcff",
      overflowWrap: "break-word"
    },
    failedContainer: {
      borderRadius: "6px",
      backgroundColor: "#f75676",
      padding: "2px 5px",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      maxWidth: "75px",
      height: "20px"
    },
    warningIcon: {
      //   padding: "2px",
      height: "19px"
    },
    failedTypography: {
      fontSize: "10px",
      fontWeight: "bold",
      color: "#ffffff",
      height: "14px",
      marginLeft: "4px"
    },
    failReasonContainer: {
      padding: "17px 14px"
    },
    userDetailRescind: {
      padding: "0px 17px 10px 17px"
    },
    failReason: {
      fontsize: "12px",
      color: "#32325d",
      fontWeight: 500
    },
    failReasonDescription: {
      fontSize: "11px",
      color: "#32325d",
      fontWeight: 500,
      paddingTop: "2px"
    },
    buttonLinkConatiner: {
      padding: "0px 14px 19px 14px"
    },
    tooltip: {
      color: "#32325d",
      backgroundColor: "white",
      fontFamily: `"Open Sans", sans-serif !important`,
      fontSize: 12,
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      width: "170px",
      padding: "9px"
    },
    arrow: {
      color: "white"
    },
    buttonLink: {
      fontSize: 12,
      fontWeight: 600,
      color: "#32325d",
      padding: "6px 14px",
      borderRadius: 4,
      boxShadow: "0 3px 4px 0 rgba(0, 0, 0, 0.14)",
      border: "solid 1px #ececec",
      flexShrink: 1
    },
    divider: {
      height: "1px",
      border: "solid 1px #f2f2f2",
      padding: "0px !important",
      marginTop: 14,
      marginBottom: 14
    },
    requestedContainer: {
      padding: "0px 14px 0px 16px"
    },
    requestedDiv: {
      borderRadius: "6px",
      backgroundColor: "#fb623f",
      padding: "5px 8px",
      fontWeight: 600
    },
    requestedUserContainer: {
      padding: "16px 14px 16px 14px"
    },
    requestedUser: {
      fontSize: "11px",
      color: "#8897aa",
      fontWeight: 500
    },
    userIcon: {
      paddingRight: "9px",
      height: "28px"
    },
    viewDetailsConatiner: {
      padding: "0px 14px 14px 14px"
    },
    root: {
      "&.MuiIconButton-root": {
        padding: "7px",
        backgroundColor: "#5f72e4",
        borderRadius: "4px",
        "&:hover": {
          boxShadow:
            "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
          background: "#3d55df"
        },
        "&.Mui-disabled": {
          cursor: "no-drop",
          pointerEvents: "auto"
        }
      }
    },
    viewDetailsbutton: {
      borderRadius: "4px",
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4"
    },
    passLabel: {
      borderRadius: "6px",
      marginRight: "10px",
      backgroundColor: "#2ece89",
      padding: "2px 4px",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      maxWidth: "70px"
    },
    passIcon: {
      width: 16,
      height: 16,
      color: "white"
    },
    passText: {
      fontFamily: "OpenSans",
      fontSize: "10px",
      color: "#ffffff"
    },
    unReadCountCircle: {
      position: "relative"
    },
    unreadCount: {
      position: "absolute",
      backgroundColor: "#f75676",
      height: "16px",
      width: "16px",
      borderRadius: "100%",
      textAlign: "center",
      fontSize: "10px",
      top: "-9px",
      color: "#fff",
      border: "1px solid #fff",
      right: "-7px"
    }
  })
);

export const FailureResultsCard = (props: {
  key: string;
  representableTestName: string;
  testGroup: string;
  testName: string;
  isMandatory: boolean;
  numberOfWaiversCreated: number;
  failureMessage: string;
  dividerRequired?: boolean;
  redirectionPage?: string;
  propertyId?: string;
  waiverData?: any;
  redirectionRequired?: boolean;
  showIntermediateValues?: boolean;
  errorAddressList?: any;
  resultType?: string;
  infoContentResult?: any;
  evaluationPanel?: any;
  showWaiverRequestButton?: boolean;
}) => {
  const classes = useStyles();
  // const iconButtonOverrideClasses = IconButtonDropdownStyles();
  const {
    key,
    testGroup,
    testName,
    representableTestName,
    failureMessage,
    redirectionPage,
    waiverData,
    isMandatory,
    dividerRequired,
    propertyId,
    redirectionRequired,
    // numberOfWaiversCreated,
    errorAddressList,
    resultType,
    infoContentResult,
    evaluationPanel
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sidePanel = true;
  const { loanId, loanStage, loanType } = useParams() as any;
  
  const { isViewMode, isWithdrawnLoan, loanDetails } = useSelector<
    RootState,
    any
  >((state) => state.createLoanStore);

  const loanState = useSelector<RootState, LoanStatusEnum>(
    (state) => state.createLoanStore.loanDetails.loanState as LoanStatusEnum
  );

  const {
    fesLoanInformation,
    thirtyYearLoanInformation
  } = loanDetails;

  const takeoutPartnerId = useMemo(() => {
    if (loanStage === LoanStage.fes) {
      return fesLoanInformation?.takeoutPartnerId;
    }
    return thirtyYearLoanInformation?.payload?.takeoutPartnerId;
  }, [loanStage, fesLoanInformation, thirtyYearLoanInformation]);



  const customerDetails = useSelector<RootState, any>(
    (state) => state.evaluationResultsStore.customerInfo
  );
  const { unreadWaivers } = useSelector<RootState, any>(
    (state) => state.evaluationResultsStore
  );
  const { partyId } = getPartyId();
  const userUnreadWaivers = unreadWaivers[waiverData?.waiverId]?.filter(
    (notification: any) => notification.createdBy !== partyId
  );
  const originatorPartyId = useMemo(
    () => loanDetails?.loanDetailId?.originatorPartyId,
    [loanDetails]
  );
  const showWaiverRequestButton = useMemo(() => {
    if (!waiverRequestConfig?.[loanType]?.showForOrgs) return true;
    return waiverRequestConfig?.[loanType]?.showForOrgs.some(
      (id: string) => id === originatorPartyId
    );
  }, [loanType, originatorPartyId]);

  const compensatingFactors = waiverData?.waiverInfo?.finalCompensatingFactors;
  const loanTypeMapping =
    loanType === getLoanType[0].displayValue ? "bridge" : loanType;

  const useStylesBootstrap = makeStyles(() => ({
    tooltip: {
      backgroundColor: "#ffffff",
      width: "186px",
      height: "92px",
      borderRadius: "3px",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      border: "solid 1px #dde0e5",
      fontSize: "12px",
      lineHeight: "1.5",
      color: "#32325d",
      left: "-30px",
      padding: "10px"
    },
    arrow: {
      color: "#ffffff"
      // backgroundColor: "#ffffff",
    }
  }));

  // eslint-disable-next-line no-shadow
  function CustomTooltip(props: TooltipProps) {
    // eslint-disable-next-line no-shadow
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
  }
  const [isCarryForwardedWaiver, setIsCarryForwardedWaiver] =
    useState<boolean>(false);
  const [waiverState, setWaiverState] = useState<string | null>(null);
  // const [isRescindWaiver, setIsRescindWaiver] = useState<boolean>(false);
  const isMandatoryFun = () => {
    return isMandatory ? (
      <span className={classes.mandatoryBadge}>
        <span style={{ margin: 5 }}>MANDATORY</span>
      </span>
    ) : (
      ""
    );
  };

  const [waiverLastUpdatedInfo, setWaiverLastUpdatedInfo] = useState<any>({});
  const { rescind } = waiverStatusLowerCase;

  useEffect(() => {
    if (waiverData && waiverData?.refWaiverId) {
      setIsCarryForwardedWaiver(checkCarryForwardWaiver(waiverData));
    }
  }, [waiverData]);
  useEffect(() => {
    if (
      waiverData?.waiverResponses &&
      waiverData?.waiverResponses?.length > 0
    ) {
      const waiverResponseObject = getLatestWaiverResponse(
        waiverData.waiverResponses
      );
      // .slice()
      // .sort(
      //   (a: any, b: any) =>
      //     Date.parse(a.responseDate) - Date.parse(b.responseDate)
      // );
      const waiverResponseType = waiverResponseObject.length
        ? waiverResponseObject[waiverResponseObject.length - 1].responseType
        : "Requested";
      setWaiverState(waiverResponseType);
      // if(waiverResponseType.toLowerCase() === rescind){
      //   setIsRescindWaiver(true);
      // }
    } else if (waiverData) {
      setWaiverState("Requested");
    } else {
      setWaiverState(null);
    }
    // eslint-disable-next-line
  }, [waiverData]);
  const redirectToWaiverRequest = (isReRequest: boolean) => {
    dispatch(
      waiverDetails(
        testName,
        failureMessage,
        {
          tab: propertyId ? "PROPERTY" : "LOAN",
          id: propertyId,
          backLocation: window.location.pathname,
          testGroup,
          representableTestName,
          waiverId: waiverData?.waiverId
        },
        errorAddressList
      )
    );
    if (isReRequest) {
      navigate(
        `/loan/waiver/re-request/${loanStage}/${loanId}/${waiverData?.waiverId}?pId=${takeoutPartnerId}`
      );
    } else {
      navigate(
        `/loan/waiver/request/${loanStage}/${loanId}?pId=${takeoutPartnerId}`
      );
    }
  };

  useEffect(() => {
    if (waiverData) {
      // eslint-disable-next-line no-shadow
      const partyId = waiverData?.createdBy || waiverData?.created_by;
      // eslint-disable-next-line @typescript-eslint/camelcase
      const { createdOn, created_on } = waiverData;
      const waiverRequestedInformation = genFormattedUserDetails(
        customerDetails,
        partyId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        createdOn || created_on
      );
      if (waiverRequestedInformation) {
        setWaiverLastUpdatedInfo(waiverRequestedInformation);
      }
      if (waiverData?.waiverResponses?.length) {
        const length = waiverData?.waiverResponses.length;
        const waiverLastUpdatedInformation = genFormattedUserDetails(
          customerDetails,
          waiverData?.waiverResponses[length - 1].responseBy,
          waiverData?.waiverResponses[length - 1].responseDate
        );
        if (waiverLastUpdatedInformation) {
          setWaiverLastUpdatedInfo(waiverLastUpdatedInformation);
        }
      }
    }
    // eslint-disable-next-line
  }, [waiverData, customerDetails]);

  // eslint-disable-next-line no-shadow
  const isRescindWaiver = (waiverData: any) => {
    return (
      waiverData?.waiverInfo &&
      waiverData?.waiverInfo?.finalResponse?.toLowerCase() === rescind
    );
  };

  // const handleRequestWaiver = (isReRequest: boolean) => {
  //   if (numberOfWaiversCreated >= 5 && !isReRequest) {
  //     setOpenWarning({
  //       isOpen: true,
  //       handleClose: () => {
  //         setOpenWarning(null);
  //       },
  //       header: "Waivers Requested: 5.",
  //       body:
  //         "You have reached the limit of the maximum number of waiver requests allowed."
  //     });
  //   } else {
  //     redirectToWaiverRequest(isReRequest);
  //   }
  // };
  return (
    <Grid
      style={{
        backgroundColor: "#ffffff",
        padding: evaluationPanel ? "0px 0px 5px 0px" : "15px",
        margin: evaluationPanel ? "0px 5px" : "10px 0px 0px 10px"
      }}
      data-testId="failure-result-container"
    >
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={
          waiverData?.waiverType === "GeneralWaiver"
            ? classes.generalContainer
            : waiverState === "APPROVED" ||
              waiverState === "APPROVED-CONDITIONALLY"
            ? classes.approvedContainer
            : classes.container
        }
        key={key}
      >
        {/* {openWarning && (
          <WarningModal
            isOpen={openWarning.isOpen}
            handleClose={openWarning.handleClose}
            handleOptional={openWarning.handleOptional}
            primaryBtnName={openWarning.primaryBtnName}
            header={openWarning.header}
            body={openWarning.body}
          />
        )} */}
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.headingContainer}
        >
          <Grid item xs={6}>
            <Typography className={classes.headingTypography}>
              {representableTestName} {isMandatoryFun()}
            </Typography>
          </Grid>
          {/* {waiverData?.waiverType !== "GeneralWaiver" && ( */}
          <Grid container item xs={6}>
            <Grid
              container
              item
              xs={12}
              justifyContent="flex-end"
              alignItems="center"
            >
              {waiverData?.waiverType !== "GeneralWaiver" && (
                <>
                  {waiverState &&
                  (waiverState === "APPROVED" ||
                    waiverState === "APPROVED-CONDITIONALLY") ? (
                    <Grid item className={classes.passLabel}>
                      <CheckRoundedIcon
                        className={classes.passIcon}
                        htmlColor="#2ece89"
                      />
                      <Typography
                        className={classes.passText}
                        data-testId="test-pass-label"
                      >
                        PASS
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      className={classes.failedContainer}
                      style={{ marginRight: "10px" }}
                    >
                      <img
                        src={errorIcon}
                        alt="Test Failure"
                        className={classes.warningIcon}
                      />
                      <span className={classes.failedTypography}>FAILED</span>
                    </Grid>
                  )}
                </>
              )}
              {waiverData?.waiverType === "GeneralWaiver" &&
                isRescindWaiver(waiverData) && (
                  <>
                    <div style={{ paddingRight: "10px" }}>
                      <ExceptionCardRescindIcon
                        path={`${
                          getConfig().redirectUrl
                        }loan/waiver/request/${loanStage}/${loanId}/view/${
                          waiverData?.waiverId
                        }?pId=${takeoutPartnerId}`}
                        isGeneralWaiver
                      />
                    </div>
                  </>
                )}
              {waiverData && (
                <>
                  {userUnreadWaivers && userUnreadWaivers.length > 0 && (
                    <>
                      <InfoOnlyTooltip
                        title="Unread Count"
                        arrow
                        placement="right"
                      >
                        <Grid
                          item
                          style={{ marginRight: "10px" }}
                          justifyContent="flex-end"
                        >
                          <Badge
                            badgeContent={userUnreadWaivers.length}
                            data-testid="unread-count-badge"
                            overlap="circular"
                            variant="standard"
                            className="notificationNumberExceptions"
                          >
                            <IconButton
                              aria-label="unread-count"
                              component="button"
                              classes={IconButtonDropdownStylesWithoutBG}
                              size="large"
                            >
                              <CommentIcon />
                            </IconButton>
                          </Badge>
                        </Grid>
                      </InfoOnlyTooltip>
                    </>
                  )}
                </>
              )}
            </Grid>
            <Grid
              container
              item
              xs={3}
              justifyContent="flex-end"
              alignItems="center"
            >
              <IconTooltip
                clicked={false}
                keyDown={false}
                topic="Information"
                infoIconData={
                  infoContentResult &&
                  infoContentResult[
                    `fieldInfo.${loanTypeMapping}.${loanStage.toLowerCase()}.result.${resultType}.${testGroup}.${testName}`
                  ]
                }
              />
            </Grid>
          </Grid>
          {/* )} */}
        </Grid>
        {/* Reason for fail */}
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.failReasonContainer}
        >
          <Grid xs={12}>
            {waiverData?.waiverType !== "GeneralWaiver" ? (
              <Typography className={classes.failReason}>
                Reason for Fail
              </Typography>
            ) : (
              <Typography className={classes.failReason}>
                Mitigating Factor
              </Typography>
            )}
          </Grid>
          <Grid xs={12}>
            <Tooltip
              title={
                failureMessage && failureMessage.length > 250
                  ? failureMessage
                  : ""
              }
            >
              <Typography className={classes.failReasonDescription}>
                {failureMessage && failureMessage.length > 250
                  ? `${failureMessage.slice(0, 250)}...`
                  : failureMessage}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
        {errorAddressList &&
          !isRescindWaiver(waiverData) &&
          Object.keys(errorAddressList).length > 0 && (
            <FailedPropertyAddress
              errorAddressList={errorAddressList}
              testName={testName}
              section="failureResultCard"
            />
          )}
        {redirectionRequired && (
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={classes.buttonLinkConatiner}
          >
            <Button
              endIcon={<img src={iconLink} alt="Request Link" />}
              className={classes.buttonLink}
              data-testId="redirection-button"
              onClick={() => {
                if (redirectionPage === "Loan Details") {
                  dispatch(setActiveTabAndProperty("loanDetails"));
                } else if (propertyId) {
                  dispatch(
                    setActiveTabAndProperty(
                      "propertyDetails",
                      JSON.parse(propertyId)
                    )
                  );
                } else dispatch(setActiveTabAndProperty("loanDetails"));
              }}
              color="info"
            >
              {redirectionPage}
            </Button>
          </Grid>
        )}
        {showWaiverRequestButton && (
          <>
            {dividerRequired &&
              (waiverState || waiverData || isCarryForwardedWaiver) && (
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.divider}
                />
              )}
            {!waiverData &&
              testGroup !== "masterTest" &&
              testGroup !== "finalCalculation" && (
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.viewDetailsConatiner}
                >
                  <Button
                    variant="contained"
                    size="small"
                    color="info"
                    data-testid="request-waiver-btn"
                    className={classes.viewDetailsbutton}
                    // startIcon={<img src={iconWaiver} alt="Request Waiver" />}
                    // onClick={() => handleRequestWaiver(false)}
                    onClick={() => redirectToWaiverRequest(false)} //no restriction on number of waivers TA-4097
                    disabled={
                      isViewMode ||
                      isWithdrawnLoan ||
                      (loanStage !== LoanStage.fes &&
                        (loanState === LoanStatusEnum.DataEntry ||
                          loanState === LoanStatusEnum.Submit))
                    }
                  >
                    Request Waiver
                  </Button>
                  {(isViewMode ||
                    isWithdrawnLoan ||
                    (loanStage !== LoanStage.fes &&
                      (loanState === LoanStatusEnum.DataEntry ||
                        loanState === LoanStatusEnum.Submit))) && (
                    <CustomTooltip
                      title={
                        isViewMode || isWithdrawnLoan
                          ? `You are viewing “${loanStageToReadable[loanStage]}” stage of the loan.`
                          : "You need to tag at-least one document in the Documents tab before you can raise a waiver request."
                      }
                      placement="right"
                    >
                      <Button startIcon={<img src={helpIcon} alt="help" />} />
                    </CustomTooltip>
                  )}
                </Grid>
              )}
            {waiverState && !isRescindWaiver(waiverData) && (
              <Grid
                container
                item
                xs={12}
                className={classes.requestedContainer}
              >
                {/* <div className={classes.requestedDiv}>
<span className={classes.failedTypography}>Waiver Requested</span>
</div> */}

                <StatusComponent
                  status={waiverState.toUpperCase()}
                  value={`WAIVER ${waiverState}`}
                />
              </Grid>
            )}
            {waiverData && (
              <>
                <CompensatingFactors
                  compensatingFactors={compensatingFactors}
                />
                <Grid
                  container
                  alignItems="center"
                  spacing={0}
                  className={
                    isRescindWaiver(waiverData)
                      ? classes.userDetailRescind
                      : classes.userDetail
                  }
                >
                  {/* <span className={classes.requestedUser}>{waiverState} By</span> */}
                  {waiverState && (
                    <UserDetailsCard
                      alternateText={`${waiverState.toLowerCase()} by`}
                      userImg="some img will come here"
                      accountNameImg={waiverLastUpdatedInfo.NameInitials}
                      userFullName={waiverLastUpdatedInfo.userFullName}
                      accountType={waiverLastUpdatedInfo.accountTypeFirstLetter}
                      date={waiverLastUpdatedInfo.dateTime}
                      sidePanel={sidePanel}
                    />
                  )}
                </Grid>
                {!isRescindWaiver(waiverData) && (
                  <Grid
                    container
                    alignItems="flex-start"
                    className={classes.userDetailButtonContainer}
                  >
                    <Button
                      variant="contained"
                      size="small"
                      data-testId="view-details-btn"
                      className={classes.viewDetailsbutton}
                      component="button"
                      endIcon={<RedirectIcon />}
                      color="info"
                      onClick={() => {
                        window.open(
                          `${
                            getConfig().redirectUrl
                          }loan/waiver/request/${loanStage}/${loanId}/view/${
                            waiverData?.waiverId
                          }?pId=${takeoutPartnerId}`
                        );
                      }}
                    >
                      View Details &nbsp;
                    </Button>
                  </Grid>
                )}
              </>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};
