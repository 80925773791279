import { IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useStyles } from "../../create-loan/common-style/CreateLoan.style";
import { LightTooltip } from "../../create-loan/waiver-form/DocumentsForm.style";
import { InfoIcon } from "../../icons/info";
import { budgetReviewStatusEnum } from "../../ilp/list-view/BudgetTaskManagement";
import { BudgetReviewStore } from "../../stores/budgetReview/budgetReview.interface";
import { getListOfAssignee, setILPUserList } from "../../stores/ILP/ILP.action";
import { ILPStore } from "../../stores/ILP/ILP.reducer";
import { RootState } from "../../stores/rootReducer";
import { InternalRolesEnum } from "../../utils/constants";
import { BudgetHistory } from "./BudgetHistory";

export function BudgetHeaderLeft(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loanId } = useParams<{
    loanId: string;
  }>() as any;
  const { loanDetails } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );

  const { budgetStatusHistory, budgetApprovalHistory, budgetReviewHeader } =
    useSelector<RootState, BudgetReviewStore>(
      (state) => state.budgetReviewStore
    );
  const { irQCVMAdminMap } = useSelector<RootState, ILPStore>(
    (state) => state.ILPStore
  );

  const [borrowersNames, setBorrowersNames] = useState<string>("");
  const [originatorName, setOriginatorName] = useState<string>("");
  const [reviewerName, setReviewerName] = useState<string>("");
  const [approverName, setApproverName] = useState<string>("");

  useEffect(() => {
    if (!loanDetails) return;
    const BorrowerData = loanDetails.bridgeLoanBorrowerInformation;
    const BorrowerNameList = BorrowerData.filter(
      (item: { payload: { borrowingEntityName: any; lastName: any } }) => {
        const { borrowingEntityName } = item.payload;
        return borrowingEntityName; // Filter out undefined and empty string values for borrowingEntityName
      }
    ).map((item: { payload: { borrowingEntityName: any; lastName: any } }) => {
      const { borrowingEntityName, lastName } = item.payload;
      return `${borrowingEntityName} ${lastName || ""}`;
    });
    setBorrowersNames(BorrowerNameList.join(","));
    loanDetails.originatorInfo &&
      setOriginatorName(loanDetails.originatorInfo.accountName);
  }, [loanDetails]);

  useEffect(() => {
    if (Object.keys(irQCVMAdminMap).length) return;
    getListOfAssignee([
      InternalRolesEnum.LAT_IR,
      InternalRolesEnum.LAT_QC,
      InternalRolesEnum.LAT_VM,
      InternalRolesEnum.LAT_ADMIN,
      InternalRolesEnum.LAT_APPROVER,
      InternalRolesEnum.LAT_INQUIRY
    ]).then((res: any) => {
      dispatch(setILPUserList(res.data));
    });
  }, [irQCVMAdminMap, dispatch]);

  useEffect(() => {
    if (!Object.keys(irQCVMAdminMap).length) return;
    let tempReviewer =
      (budgetStatusHistory.length &&
        irQCVMAdminMap[budgetStatusHistory[0].assigneePartyId]) ||
      "--";
    let tempApprover =
      (budgetApprovalHistory.length &&
        irQCVMAdminMap[budgetApprovalHistory[0].assigneePartyId]) ||
      "--";
    setReviewerName(tempReviewer);
    setApproverName(tempApprover);
  }, [irQCVMAdminMap, budgetStatusHistory, budgetApprovalHistory]);

  const getReviewDisplay = (review: string) => {
    // if (review === "REVIEW_PENDING") {
    //   return "REVIEW PENDING"
    // } else {
    return review.replace(/_/g, " ");
    // }
  };

  const getChipColor = () => {
    if (
      budgetReviewHeader?.budgetReviewStatus === budgetReviewStatusEnum.Approved
    ) {
      return "limegreen";
    } else if (
      budgetReviewHeader?.budgetReviewStatus === budgetReviewStatusEnum.Rejected
    ) {
      return "orangered";
    } else return "#172b4d";
  };
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography key={"BudgetReview"} style={{ marginRight: 7 }}>
          Budget Review
        </Typography>
        <Typography
          key={"BudgetStatus"}
          variant="h5"
          className={classes.itemTypography}
          style={{ backgroundColor: getChipColor() }}
        >
          <span>
            {getReviewDisplay(budgetReviewHeader?.budgetReviewStatus)}
          </span>
        </Typography>
        <BudgetHistory />
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="h5"
          style={{
            fontSize: 12,
            marginTop: 5
          }}
        >
          Loan ID: {loanId}
        </Typography>
        <LightTooltip
          title={
            <Typography>
              <div>
                <span style={{ fontWeight: "normal" }}>Borrower: </span>
                <strong>{borrowersNames}</strong>
              </div>
              <div>
                <span style={{ fontWeight: "normal" }}>Originator: </span>
                <strong>{originatorName}</strong>
              </div>
              <div>
                <span style={{ fontWeight: "normal" }}>Reviewer: </span>
                <strong>{reviewerName}</strong>
              </div>
              <div>
                <span style={{ fontWeight: "normal" }}>Approver: </span>
                <strong>{approverName}</strong>
              </div>
            </Typography>
          }
          placement="right-start"
        >
          <IconButton
            aria-label="loan information"
            style={{ padding: 0, marginLeft: 7, marginTop: 5 }}
          >
            <InfoIcon />
          </IconButton>
        </LightTooltip>
      </div>
    </>
  );
}
