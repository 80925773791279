import React, { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { getConfig } from "../config/config";
import { getCookie, removeCookie, deleteAllCookies, setCookie } from "../utils/cookies";
import { ObjectType } from "../masterView/common";
import { logoutApp } from "../network/apiService";
export function forceLogout() {
  if (window.location.hostname !== "localhost") {
    window.location.replace(`https://${window.location.hostname}/logout`);
  } else {
    window.location.replace(
      `http://${window.location.hostname}:${Number(
        window.location.port
      )}/logout`
    );
  }
}
export const LogoutPage = () => {
  const refreshToken = getCookie("refresh_token");
  useEffect(() => {
    async function revokeCode() {
      const token = getCookie("id_token") || "";
      if (token) {
        deleteAllCookies();
        try {
          const formBody: string[] = [];
          const logoutObject: ObjectType = {
            refresh_token: refreshToken,
            client_id: 'login',
          };

          Object.keys(logoutObject).forEach((property) => {
            const encodedKey = encodeURIComponent(property);
            const encodedValue = encodeURIComponent(logoutObject[property]);
            formBody.push(`${encodedKey}=${encodedValue}`);
          });
          const formBodyString = formBody.join("&");
          const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
          };
          const url = `${getConfig().keyClockUrl}/logout`;

          await logoutApp(url, formBodyString, headers);
          setCookie("isUserLoggedIn", false);
        } catch (err) {
          console.error(err);
        }
      }
      if (
        getCookie("docReferrer") !== null &&
        getCookie("docReferrer") !== ""
      ) {
        sessionStorage.clear();
        if (token !== null && token !== undefined) {
          window.location.href = `${
            getConfig().keyClockUrl
          }/oidc/logout?id_token_hint=${token}&post_logout_redirect_uri=https://www.toorakcapital.com/`;
        } else {
          window.location.href = `${getConfig().logoutCallbackURL}`;
        }
        // const redirectUrl =getCookie("docReferrer");
        // window.location.href = `${getConfig().keyClockUrl}/oidc/logout?id_token_hint=${token}&post_logout_redirect_uri=${redirectUrl}`;
      } else {
        sessionStorage.clear();
        deleteAllCookies();
        if (token !== null && token !== undefined) {
          const stateUUID = uuidv4();
          const nonceUUID = uuidv4();
          window.location.replace(
            `${getConfig().keyClockUrl}/auth?client_id=login&redirect_uri=${getConfig()?.redirectUrl}callBack&state=${stateUUID}&nonce=${nonceUUID}&response_mode=query&response_type=code&scope=openid`
          );
        } else {
          window.location.href = `${getConfig().logoutCallbackURL}`;
        }
      }
      removeCookie("view");
    }
    localStorage.setItem("logoutFromAllTabs", `${Math.random() + 1}`);
    revokeCode();
  }, []);

  return (
    <>
      <div />
    </>
  );
};
