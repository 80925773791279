import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  banner: {
    backgroundColor: "#333",
    color: "white",
    overflow: "hidden",
    width: "100%",
    whiteSpace: "nowrap",
    position: "fixed",
    height: "20px",
    zIndex: 1101
  },
  scrollingBannerText: {
    animation: "$scrollLeft 30s linear infinite",
    position: "absolute",
    width: "100%",
    textAlign: "center"
  },
  "@keyframes scrollLeft": {
    "0%": { transform: "translateX(100%)" },
    "100%": { transform: "translateX(-100%)" }
  },
  bannerText: {
    position: "absolute",
    width: "100%",
    textAlign: "center"
  }
}));

interface ScrollingBannerProps {
  text: string;
  scroll?: boolean;
}

export const ScrollingBanner = (props: ScrollingBannerProps) => {
  const classes = useStyles();
  const { text = "", scroll = true } = props;
  return (
    <div className={classes.banner}>
      <div
        className={scroll ? classes.scrollingBannerText : classes.bannerText}
      >
        <span>{text}</span>
      </div>
    </div>
  );
};
