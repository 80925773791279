import { taskNames } from "../../stores/tasks/Tasks.action";
import { getKeyByValue } from "../../utils/utils";

export enum budgetReviewStatusEnum {
  LoanSubmitted = "Loan Submitted",
  ReadyForBudgetReview = "Ready For Budget Review",
  InProgress = "In-Progress",
  ReadyForBudgetApproval = "Ready For Budget Approval",
  Rejected = "Rejected",
  Approved = "Approved"
}

export const editableStatuses = [budgetReviewStatusEnum.ReadyForBudgetReview,budgetReviewStatusEnum.InProgress];

export const approvalStatuses = [budgetReviewStatusEnum.ReadyForBudgetApproval];

export const disableStatuses = [budgetReviewStatusEnum.Approved,budgetReviewStatusEnum.Rejected];

export enum budgetApproveStatusEnum {
  Pending = "Pending",
  ReadyForBudgetApproval = "Ready For Budget Review",
  Rejected = "Rejected",
  Approved = "Approved"
}

export function getBudgetAge(data: any = {}, getLapsedTime: Function): any {
  const taskStatus = data?.[taskNames.BUDGET_REVIEW]?.taskStatus;
  const taskLapsedTime = getLapsedTime(
    data?.[taskNames.BUDGET_REVIEW]?.firstAudit?.[
      getKeyByValue(budgetReviewStatusEnum, taskStatus) as string
    ] ?? data?.[taskNames.BUDGET_REVIEW]?.updatedOn
  );
  if (taskLapsedTime && taskStatus && taskStatus !== "Not Applicable") {
    return `${taskStatus} ${taskLapsedTime} ago`;
  }
  return "Not Available";
}
