import React, { useEffect, useState } from 'react'
import ListCard from '../list-card/list-card.component';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            marginLeft: 20,
            "& .MuiInputLabel-shrink": {
                transform: "translate(14px, -20px) scale(0.75) !important"
            },
            "& legend": {
              display: "none"
            }
        },
        selectEmpty: {
            marginTop: theme.spacing(1),
        },
        select: {
            height: 40
        }
    }),
);
type Props = {
    listData: any
};
type BridgeProps = {
    bridge: Boolean
};
function NavigationTabs<NavigationProps>({ listData }: Props, { bridge }: BridgeProps) {
    const classes = useStyles();
    const [value, setValue] = useState('');
    const [arrayData, setArrayData] = useState([]);
    const [uniqueStates, setUniqueStates] = useState([]);
    const [stateRespectiveFiles, setStateRespectiveFiles] = useState([]);
    const [currentState, setCurrentState] = useState('');
    const [currentSectionArray, setSectionArray] = useState([]);
    function onTab(value = currentSectionArray[0]) {
        setValue(value);
        const tempData = listData[value].files;
        setArrayData(tempData);
        let sortedStates: any = sortStates(tempData);
        let uniqueCurrentState = sortedStates.includes(currentState) ? currentState : sortedStates[0];
        setCurrentState(uniqueCurrentState)
        setUniqueStates(sortedStates);
        var filteredFiles = tempData.filter(function (event: any) {
            if (uniqueCurrentState === "All") {
                return event;
            }
            else if (event.state === uniqueCurrentState) {
                return event;
            } else if (!event.state && !sortedStates.includes(currentState)) {
                return event;
            }
            return console.log(event);

        });
        setStateRespectiveFiles(filteredFiles);
    }
    function onSelectState(event: any) {
        let currentState = event.target.value;
        var filteredFiles = arrayData.filter(function (event: any) {
            if (currentState === "All") {
                return event;
            } else if (event.state === currentState) {
                return event;
            }
            return console.log(event);

        });
        setCurrentState(currentState);
        setStateRespectiveFiles(filteredFiles);
    }
    function sortStates(data: any) {
        let sortedStates = data.map(function (a: any, key: any) {
            return a.state;
        }).sort();
        sortedStates = sortedStates.filter((value: any, index: any) => sortedStates.indexOf(value) === index);
        let filteredStates = sortedStates.filter(function (element: any) {
            if (element !== undefined || element !== "") {
                return element;
            }
            return console.log(element);

        });
        return ["All"].concat(filteredStates);
    }
    function createSectionArray(listData: any) {
        const sectionArray: any = [];
        for (let i = 0; i < Object.keys(listData).length; i++) {
            if (typeof (listData[Object.keys(listData)[i]]) === 'object')
                sectionArray.push(Object.keys(listData)[i]);
        }
        setValue(sectionArray[0]);
        setSectionArray(sectionArray);
        return sectionArray;
    }
    useEffect(() => {
        const sectionArray = createSectionArray(listData);
        const firstValue: any = sectionArray[0];
        let sortedStates: any = sortStates(listData[firstValue].files);
        let uniqueCurrentState = (currentState !== "" ? sortedStates.includes(currentState) ? currentState : sortedStates[0] : sortedStates[0]);
        setUniqueStates(sortedStates);
        var filteredFiles = listData[firstValue].files.filter(function (event: any) {
            if (uniqueCurrentState === "All" || uniqueCurrentState === "") {
                return event;
            }
            else if (event.state === uniqueCurrentState) {
                return event;
            } else if (!event.state && !sortedStates.includes(uniqueCurrentState)) {
                return event;
            }
            return console.log(event);
        });
        setArrayData(listData[firstValue].files ? listData[firstValue].files : []);
        setStateRespectiveFiles(filteredFiles);
        setCurrentState(uniqueCurrentState);
        // eslint-disable-next-line
    }, [listData]);
    return (
        <div>
            <ul className="tabs-list">
                {currentSectionArray ? currentSectionArray.map((itemValue, index) => {
                    if (listData[itemValue])
                        return <li key={index} className={`${itemValue === value ? 'active' : ''}`} onClick={e => onTab(itemValue)} aria-label={itemValue}>{listData[itemValue].name}</li>
                   return null;     
                }) : ''}
            </ul>
            {uniqueStates[1] ?
                <div>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label"
                            variant='standard'
                        >State</InputLabel>
                        <Select
                            className={classes.select}
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={e => onSelectState(e)}
                            value={currentState}
                            label="State"
                        >{uniqueStates.map((value, key) => <MenuItem key={key} value={value}>{value}</MenuItem>)}
                        </Select>
                    </FormControl></div> : ''}
            {stateRespectiveFiles?.map((item: any, key: any) => <div key={key} className="widget-wrapper"><ListCard listData={item} /></div>)}
        </div>
    )
}
export default NavigationTabs