import Cookies from "js-cookie";
import React from "react";
import { isRole } from "../utils/AccessManagement";
import { DrawRolesEnum } from "../utils/constants";

export const getUsername = () => {
  const firstName = Cookies.get("firstName");
  const lastName = Cookies.get("lastName");
  return `${firstName} ${lastName}`;
};

export const getDrawDateCreated = (date: any) => {
  // safari fix
  //
  if (!date) return null;
  const valueDTDate = date.replace("T", " ").replace("Z", "");
  const valueDTDateM = valueDTDate ? valueDTDate.slice(0, -7) : "";
  const valueDTDateZoneVal1 = valueDTDate
    ? `${valueDTDateM.replace(/ /g, "T")}Z`
    : "";
  const createdDate = new Date(valueDTDateZoneVal1);
  const dateArray = createdDate.toString().split(" ", 8);
  return (
    dateArray.length > 2 && (
      <>
        {dateArray[2]} {dateArray[1]} {dateArray[3]} &bull; {dateArray[4]}
      </>
    )
  );
};

export const sanitizeStringToNum = (abc: string) => {
  try {
    if (typeof abc !== "string") return abc;
    let num = abc?.replace(/,/g, "");
    return Number(num);
  } catch (e) {
    console.error(e);
    return abc;
  }
};

export const getRoles = (): DrawRolesEnum => {
  let role: DrawRolesEnum;
  if (isAMTView()) {
    if (isOnlyDrawMember()) {
      role = DrawRolesEnum.DRAW_MEMBER;
    } else {
      role = DrawRolesEnum.DRAW_MANAGER;
    }
  } else {
    role = DrawRolesEnum.ORIGINATOR_DRAW;
  }
  return role;
};

export const isAMTView = () => {
  return (
    isRole(DrawRolesEnum.DRAW_MEMBER) || isRole(DrawRolesEnum.DRAW_MANAGER)
  );
};
export const isOnlyDrawMember = () => {
  return (
    isRole(DrawRolesEnum.DRAW_MEMBER) && !isRole(DrawRolesEnum.DRAW_MANAGER)
  );
};

export const getInitials = (name: string) => {
  if (name) {
    const namesArray = name.toUpperCase().split(" ");
    if ([3, 2].includes(namesArray.length)) {
      return (
        namesArray[0].charAt(0) + namesArray[namesArray.length - 1].charAt(0)
      );
    }
    return namesArray[0].charAt(0) + namesArray[0].charAt(1);
  }
};

export const drawSubmittedDate = (drawStageHistory: any, drawStage: any) => {
  const tempObj = JSON.parse(JSON.stringify(drawStageHistory));
  let obj = tempObj.find((o: any) => o.stageName === drawStage);
  return obj.updatedTimeStamp;
};

export function validateBoxURL(url: string): boolean {
  const regex =
    /^(https:\/\/(?:toorakcapital\.box\.com|toorakcapital\.app\.box\.com)\/(?:folder|file)\/\d+)/;
  return regex.test(url);
}
