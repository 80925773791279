/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Grid, Button, Dialog } from "@mui/material";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import { RootState } from "../../../stores/rootReducer";
import {
  OriginalAppraisalValueTextField,
  PurchasePriceTextField,
  CostBasisTextField,
  AnnualPropertyTaxesTextField,
  AnnualHazardInsuranceTextField,
  AnnualFloodInsuranceTextField,
  AnnualHOAFeeTextField,
  ThirdPartyValuationTextField,
  DecliningMarketsDropDown
} from "../../form-components/PropertyEconomics";
import {
  previousLoanState,
  updatePropertyDetails,
  // valueEditing,
  validateForm as validateFormCheck,
  updatePreviousLoanStateAction
} from "../../create-loan.action";
import iconMinimize from "../../../images/icon_minimize.svg";
import iconMaximize from "../../../images/icon_maximize.svg";
import { useStyles } from "../../30-year-loan-details/ThirtyYearLoanStyles";
import editImage from "../../../images/Edit.svg";
// import { isLATUser } from "../../../utils/AccessManagement";
import {
  globalAutoSaveOnPropertyFieldChange,
  globalSaveThirtyYrOnFieldChangeProperty,
  prepareCommentObjectProperty
} from "./ThirtyYearLoanPropertyInformation";
import {
  checkFieldValidity,
  compareRuleVersion,
  editHistoryValue,
  getErrorMessage,
  getRuleVersion,
  isEmptyValue
} from "../../../utils/formatChecks";
import { thirtyYearPropertyfields } from "../../constants/loanFieldType";
import { isLATUser } from "../../../utils/AccessManagement";
import { requestWaiverMessage, ruleV02_22 } from "../../../utils/constants";
import { yesNoDropdown } from "../../constants/loanCreationDropDownValues";
import { UpdatedPropertyEconomics } from "../../../masterView/common";
import { valueEditing } from "../../create-loan.reducer";

const isValidCurrency = (text: string) => {
  return /^\${0,1}[0-9]\d*(((,\d{3}){1})*(\.\d{1,2})?)$/.test(text);
};

// const editableFlags: any = {};
let originalPropertyEconomics: any;

const cleanCurrency = (text: string) => {
  return (
    text
      .replace(/[^0-9.$]/g, "")
      // eslint-disable-next-line no-plusplus, no-param-reassign
      .replace(
        /[$]/g,
        (
          (i) => (m: string) =>
            !i++ ? m : ""
        )(0)
      )
      // eslint-disable-next-line no-plusplus, no-param-reassign
      .replace(
        /[.]/g,
        (
          (i) => (m: string) =>
            !i++ ? m : ""
        )(0)
      )
      .replace(/[$]/g, (c, i) => (i !== 0 ? "" : c))
      .replace(/^\./, "")
      .replace(/^\$\./, "$")
      .replace(/\.(\d\d)\d?$/, ".$1")
  );
};

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD"
});

const sanitizeCurrency = (text: string) => {
  const result = text.replace(/\.$/, "");
  return text !== "" && text !== "$"
    ? currencyFormatter.format(
        Number.parseFloat(result.replace(/[^0-9.]/g, ""))
      )
    : "";
};

function getProperty<T, K extends keyof T>(o: T, propertyName: K): T[K] {
  return o[propertyName] as any; // o[propertyName] is of type T[K]
}

export interface PropertyDetailsPropertyEconomicsProps {
  propertyEconomics?: UpdatedPropertyEconomics | null;
  propertyId: string;
  isEvaluationPanelVisible: boolean;
  loanType: string;
  data: any;
  isAllowedToEdit: boolean;
  index?: number;
  isMandatoryServicerField?: any;
  isEditable?: any;
  setCommentObj?: any;
  reasonCommentObj?: any;
}

interface FieldType {
  field: any;
  editedLabel: any;
  fieldLabel: string;
  fieldComponent: any;
  required?: any;
  errorChecks?: any;
  ChangeValueMethod?: any;
  FocusOutMethod?: any;
  fieldType: string;
  errorMsg?: any;
  testField?: any;
  hideCondition?: (data: any) => boolean;
}

const GridFieldsPropertyEconomics: FieldType[] = [
  {
    field: "originalAppraisalValue",
    editedLabel: "originalAsIsAppraisalValue",
    fieldLabel: 'Original "As Is" Appraisal Value ($)',
    fieldComponent: OriginalAppraisalValueTextField,
    errorChecks: isValidCurrency,
    required: "required",
    ChangeValueMethod: cleanCurrency,
    FocusOutMethod: sanitizeCurrency,
    fieldType: "currency",
    errorMsg: "Please enter value in proper currency format",
    testField: "current-loan-balance"
  },
  {
    field: "thirdPartyValuation",
    editedLabel: "thirdPartyValuation",
    fieldLabel: "Third Party Valuation ($)",
    fieldComponent: ThirdPartyValuationTextField,
    errorChecks: isValidCurrency,
    required: "required",
    ChangeValueMethod: cleanCurrency,
    FocusOutMethod: sanitizeCurrency,
    fieldType: "currency",
    errorMsg: "Please enter value in proper currency format",
    testField: "third-party-valuation"
  },
  {
    field: "purchasePrice",
    editedLabel: "purchasePrice",
    fieldLabel: "Purchase Price ($)",
    fieldComponent: PurchasePriceTextField,
    errorChecks: isValidCurrency,
    required: "required",
    ChangeValueMethod: cleanCurrency,
    FocusOutMethod: sanitizeCurrency,
    fieldType: "currency",
    errorMsg: "Please enter value in proper currency format",
    testField: "current-loan-balance"
  },
  {
    field: "costBasis",
    editedLabel: "costBasisAfterCostIncurred",
    fieldLabel: "Cost Basis (After Cost Incurred) ($)",
    fieldComponent: CostBasisTextField,
    errorChecks: isValidCurrency,
    required: "required",
    hideCondition: (data: any) => {
      const { loanRuleVersions } = data;
      const ruleVersion = getRuleVersion(loanRuleVersions);
      if (compareRuleVersion(ruleVersion, "<", ruleV02_22)) {
        return false;
      }
      return true;
    },
    ChangeValueMethod: cleanCurrency,
    FocusOutMethod: sanitizeCurrency,
    fieldType: "currency",
    errorMsg: "Please enter value in proper currency format",
    testField: "current-loan-balance"
  },
  {
    field: "annualPropertyTaxes",
    editedLabel: "annualPropertyTaxes",
    fieldLabel: "Annual Property Taxes ($)",
    fieldComponent: AnnualPropertyTaxesTextField,
    errorChecks: isValidCurrency,
    required: "required",
    ChangeValueMethod: cleanCurrency,
    FocusOutMethod: sanitizeCurrency,
    fieldType: "currency",
    errorMsg: "Please enter value in proper currency format",
    testField: "current-loan-balance"
  },
  {
    field: "annualHazardInsurance",
    editedLabel: "annualHazardInsurance",
    fieldLabel: "Annual Hazard Insurance ($)",
    fieldComponent: AnnualHazardInsuranceTextField,
    errorChecks: isValidCurrency,
    required: "required",
    ChangeValueMethod: cleanCurrency,
    FocusOutMethod: sanitizeCurrency,
    fieldType: "currency",
    errorMsg: "Please enter value in proper currency format",
    testField: "current-loan-balance"
  },
  {
    field: "annualFloodInsurance",
    editedLabel: "annualFloodInsurance",
    fieldLabel: "Annual Flood Insurance ($)",
    fieldComponent: AnnualFloodInsuranceTextField,
    errorChecks: isValidCurrency,
    required: "required",
    ChangeValueMethod: cleanCurrency,
    FocusOutMethod: sanitizeCurrency,
    fieldType: "currency",
    errorMsg: "Please enter value in proper currency format",
    testField: "current-loan-balance"
  },
  {
    field: "annualHoaFee",
    editedLabel: "annualHOAFee",
    fieldLabel: "Annual HOA Fee ($)",
    fieldComponent: AnnualHOAFeeTextField,
    errorChecks: isValidCurrency,
    required: "required",
    ChangeValueMethod: cleanCurrency,
    FocusOutMethod: sanitizeCurrency,
    fieldType: "currency",
    errorMsg: "Please enter value in proper currency format",
    testField: "current-loan-balance"
  },
  {
    field: "decliningMarkets",
    editedLabel: "decliningMarkets",
    fieldLabel: "Declining Market",
    fieldComponent: DecliningMarketsDropDown,
    fieldType: "dropDown",
    errorMsg: "Please select at least one option",
    testField: "declining-market-value"
  }
];

export function ThirtyYearLoanPropertyEconomics(
  props: PropertyDetailsPropertyEconomicsProps
) {
  const {
    propertyEconomics,
    propertyId,
    isEvaluationPanelVisible,
    isAllowedToEdit,
    index,
    isMandatoryServicerField,
    isEditable,
    setCommentObj,
    reasonCommentObj
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const validateForm = useSelector<RootState, { validate: boolean }>(
    (state) => state.createLoanStore.validateForm
  );
  const { activePropertyId, loanRuleVersions } = useSelector<RootState, any>(
    (state) => state.createLoanStore.loanDetails
  );
  const {
    // bridgeLoanGuarantorInformation,
    propertyDetails,
    loanState
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useSelector<RootState, any>((state) => state.createLoanStore.loanDetails);

  const [fieldErrors, setFieldErrors] = useState<{ [k: string]: any }>({});
  // const [isEditable, setEditable] = useState(false);
  const [resetData, setResetData] = useState(false);
  const [isMinimized, setMinimized] = useState(false);
  const [changedValueFields, setChangedValueFields] = useState<{
    [index: string]: boolean;
  }>({});
  const [editableFlags, setEditableFlags] = useState<any>({});

  const editHistory = useSelector<RootState, any>(
    (state) => state.createLoanStore.editHistory
  );
  let infoContent = useSelector<RootState, any>(
    (state) => state.createLoanStore.infoContentProperty
  );
  if (infoContent) {
    infoContent = JSON.parse(JSON.stringify(infoContent));
  }
  const [open, setOpen] = React.useState(false);
  const { loanType, loanStage } = useParams<any>() as any;
  const propertyEconomicsField = React.useMemo(() => {
    return GridFieldsPropertyEconomics.filter((ele) => {
      if (ele.hideCondition && ele.hideCondition({ loanRuleVersions })) {
        return false;
      }
      return true;
    });
  }, [loanRuleVersions]);
  let editHistory2: any;
  editHistory2 = editHistory;
  useEffect(() => {
    const errorCount = Object.values(fieldErrors).filter((item) => item).length;
    dispatch(
      updatePropertyDetails(propertyId, {
        propertyEconomics: {
          errors: errorCount
        }
      })
    );
  }, [fieldErrors]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setErrorField = (fieldName: any, msg: string | null | undefined) => {
    setFieldErrors({
      ...fieldErrors,
      [fieldName]: msg
    });
  };
  const { loanId } = useParams<any>() as any;
  const [callAutoSave, setCallAutoSave] = useState<{
    value: boolean;
    impactingFieldChange: boolean;
  }>({ value: false, impactingFieldChange: false });

  useEffect(() => {
    if (callAutoSave.value) {
      globalAutoSaveOnPropertyFieldChange(loanId, propertyDetails);
      setCallAutoSave({ value: false, impactingFieldChange: false });
    }
  }, [callAutoSave]);

  useEffect(() => {
    if (activePropertyId && resetData) {
      onCancel();
      updatePreviousLoanState();
      dispatch(
        previousLoanState(originalPropertyEconomics, "propertyEconomics", true)
      );
      setResetData(!resetData);
    }
  }, [activePropertyId]);

  useEffect(() => {
    if (isEditable) {
      const tempEditableFlags: any = { ...editableFlags };
      for (const i in tempEditableFlags) tempEditableFlags[i] = false;
      updatePreviousLoanState();
      dispatch(valueEditing(true));
      setEditableFlags({ ...tempEditableFlags, [`editProp${activePropertyId}`]: true });
    } else {
      setEditableFlags({ ...editableFlags, [`editProp${activePropertyId}`]: false });
      dispatch(valueEditing(false));
    }
  }, [isEditable, activePropertyId]);

  const onCancel = () => {
    const tempEditableFlags: any = { ...editableFlags };
    for (const i in tempEditableFlags) tempEditableFlags[i] = false;
    dispatch(valueEditing(false));
    // for (const i in editableFlags) editableFlags[i] = false;
    closeForm();
    setResetData(!resetData);
    setEditableFlags({ ...tempEditableFlags });
    prepareCommentObjectProperty("", "", "", true);
  };

  function closeForm() {
    // setEditable(!isEditable);
    setChangedValueFields({});
  }
  useEffect(() => {
    if (resetData && originalPropertyEconomics) {
      dispatch(
        previousLoanState(originalPropertyEconomics, "propertyEconomics", true)
      );
      setResetData(!resetData);
    }
  });

  useEffect(() => {
    if (loanState === LoanStatusEnum.DueDiligenceReview && isEditable) {
      const tempEditableFlags: any = { ...editableFlags };
      for (const i in editableFlags) tempEditableFlags[i] = false;
      dispatch(valueEditing(false));
      // for (const i in editableFlags) editableFlags[i] = false;
      // setEditable(!isEditable);
      setChangedValueFields({});
      setEditableFlags({ ...tempEditableFlags })
    }
  }, [loanState]);

  useEffect(() => {
    if (validateForm.validate) {
      const requiredErrors: { [k: string]: string } = {};
      propertyEconomicsField.forEach((item: any) => {
        const value = getProperty(
          propertyEconomics as { [k: string]: any },
          item.field
        );
        let errorMessage = "";
        if (!fieldErrors[item.field] && isEmptyValue(value)) {
          errorMessage = "This field is required";
        } else if (fieldErrors[item.field]) {
          errorMessage = fieldErrors[item.field];
        } else if (
          !checkFieldValidity(value, thirtyYearPropertyfields[item.field])
        ) {
          errorMessage = getErrorMessage(
            thirtyYearPropertyfields[item.field]?.fieldTpe,
            value
          );
        }
        if (
          (errorMessage === "This field is required" ||
            errorMessage === "Please enter value in proper currency format") &&
          thirtyYearPropertyfields[item.field]?.optionalIn?.includes(loanStage)
        ) {
          errorMessage = "";
        }
        requiredErrors[item.field] = errorMessage;
      });
      setFieldErrors({
        ...fieldErrors,
        ...requiredErrors
      });
    }
  }, [validateForm, propertyId, propertyEconomics]);

  const onChange =
    (item: any) => (event: ChangeEvent<HTMLInputElement>) => {
      const { fieldLabel, field } = item;
      const fieldObject = propertyEconomicsField.find((f) => f.field === field);
      const sanitizedValue = fieldObject?.ChangeValueMethod
        ? fieldObject?.ChangeValueMethod(event.target.value)
        : event.target.value;
      if (isEvaluationPanelVisible) {
        // if (!isLATUser()) {
        prepareCommentObjectProperty(
          field === "originalAppraisalValue"
            ? `data.properties[${activePropertyId}].propertyEconomics.originalAsIsAppraisalValue`
            : `data.properties[${activePropertyId}].propertyEconomics.${field}`,
          sanitizedValue,
          "",
          false,
          setCommentObj,
          true,
          reasonCommentObj,
          fieldLabel,
          true,
          "Property Economics"
        );
        // }
      }
      if (!(field in changedValueFields)) {
        if (isEvaluationPanelVisible) {
          switch (field) {
            case "annualHoaFee":
              setChangedValueFields({
                ...changedValueFields,
                ...{ annualHOAFee: true }
              });
              break;
            case "costBasis":
              setChangedValueFields({
                ...changedValueFields,
                ...{ costBasisAfterCostIncurred: true }
              });
              break;
            case "originalAppraisalValue":
              setChangedValueFields({
                ...changedValueFields,
                ...{ originalAsIsAppraisalValue: true }
              });
              break;
            default:
              setChangedValueFields({
                ...changedValueFields,
                ...{ [field]: true }
              });
          }
        }
      }
      if (fieldObject) {
        dispatch(
          updatePropertyDetails(propertyId, {
            propertyEconomics: {
              payload: {
                [`${field}`]: sanitizedValue
              }
            }
          })
        );

        if (fieldObject.errorChecks) {
          const validErrorCheck = fieldObject.errorChecks(event.target.value);
          if (!validErrorCheck) {
            setErrorField(field, fieldObject.errorMsg);
          } else {
            setErrorField(field, null);
          }
        }
      } else if (
        !thirtyYearPropertyfields[field]?.optionalIn?.includes(loanStage)
      ) {
        setErrorField(field, "This Field is required");
      }
    };

  const saveOnFieldChange = () => {
    dispatch(validateFormCheck(true));
    if (
      !Object.values(fieldErrors).filter(
        (value: any) => value !== null && value !== ""
      ).length
    ) {
      // if (
      //   globalSaveThirtyYrOnFieldChangeProperty(
      //     Object.keys(changedValueFields),
      //     propertyEconomics
      //   )
      // ) {
      //   return true;
      // }
      globalSaveThirtyYrOnFieldChangeProperty(
        Object.keys(changedValueFields),
        propertyEconomics
      ).then((res: any) => {
        if (res !== "false") {
          dispatch(valueEditing(false));
          for (const i in editableFlags) editableFlags[i] = false;
          closeForm();
          setChangedValueFields({});
          // updatePreviousLoanState();
        }
      });
      return false;
    }
  };

  const handleBlur =
    (field: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const propertyEconomicsCopy: any = propertyEconomics;
      if (
        event?.target.parentElement?.parentElement?.innerText.includes(
          "Reason For Editing"
        )
      ) {
        prepareCommentObjectProperty(
          field === "originalAppraisalValue"
            ? `data.properties[${activePropertyId}].propertyEconomics.originalAsIsAppraisalValue`
            : `data.properties[${activePropertyId}].propertyEconomics.${field}`,
          propertyEconomicsCopy[`${field}`],
          event.target.value
        );
        return;
      }
      const fieldObject = propertyEconomicsField.find((f) => f.field === field);
      if (fieldObject) {
        const sanitizeValue = fieldObject.FocusOutMethod
          ? fieldObject.FocusOutMethod(event.target.value)
          : event.target.value;
        const isValid = checkFieldValidity(sanitizeValue, {
          fieldTpe: fieldObject.fieldType
        });
        const formattedValue = isValid ? sanitizeValue : event.target.value;
        dispatch(
          updatePropertyDetails(propertyId, {
            propertyEconomics: {
              payload: {
                [`${field}`]: formattedValue
              }
            }
          })
        );
        if (sanitizeValue === "" || sanitizeValue === null) {
          if (
            thirtyYearPropertyfields[field]?.optionalIn?.includes(loanStage)
          ) {
            setErrorField(field, null);
          } else {
            setErrorField(field, "This field is required");
          }
        } else if (isValid) {
          setErrorField(field, null);
        }
      }
      setCallAutoSave({ value: true, impactingFieldChange: true });
    };

  const updatePreviousLoanState = () => {
    originalPropertyEconomics = updatePreviousLoanStateAction(
      propertyDetails,
      activePropertyId,
      "propertyEconomics"
    );
  };

  const handleServiceFlowRender = (field: string): boolean => {
    return (
      !isMandatoryServicerField ||
      (isMandatoryServicerField && isMandatoryServicerField(field))
    );
  };

  return (
    <div className={classes.container}>
      {/* Minimize Section start */}
      <Grid container spacing={3} className={classes.sectionWrapper}>
        <Grid item xs={12} container>
          <Grid item xs={6} sm={11}>
            <Typography className={classes.headingTypography}>
              Property {index} Economics
            </Typography>
          </Grid>
          <Grid item xs={6} sm={1} className={classes.headingImageContainer}>
            {isAllowedToEdit && (
              <img
                className={
                  editableFlags[`editProp${activePropertyId}`] ||
                  !isEvaluationPanelVisible
                    ? classes.editImageDisplay
                    : classes.editImage
                }
                src={editImage}
                alt="Edit Section"
                onClick={() => {
                  for (const i in editableFlags) editableFlags[i] = false;
                  updatePreviousLoanState();
                  dispatch(valueEditing(true));
                  // setEditable(!isEditable);
                  editableFlags[`editProp${activePropertyId}`] = true;
                }}
                onKeyDown={() => {
                  dispatch(valueEditing(true));
                  for (const i in editableFlags) editableFlags[i] = false;
                  updatePreviousLoanState();
                  // setEditable(!isEditable);
                  editableFlags[`editProp${activePropertyId}`] = true;
                }}
              />
            )}
            <img
              className={classes.headingImage}
              src={isMinimized ? iconMaximize : iconMinimize}
              alt={isMinimized ? "Maximize section" : "Minimize section"}
              onClick={() => {
                setMinimized(!isMinimized);
              }}
              onKeyDown={() => {
                setMinimized(!isMinimized);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* Minimize Section end */}

      {/* Divider Section start */}
      <Grid
        item
        xs={12}
        sm={12}
        style={{
          display: isMinimized ? "none" : ""
        }}
      >
        <div className={classes.divider} />
      </Grid>
      {/* Divider Section end */}

      <Grid
        container
        spacing={4}
        style={{
          padding: 24,
          paddingTop: isMandatoryServicerField ? 0 : 24,
          marginTop: isMandatoryServicerField ? "-26px" : "-16px",
          display: isMinimized ? "none" : ""
        }}
      >
        {propertyEconomicsField.map((item: any) => {
          const EconomicsFieldComponent = item.fieldComponent;
          return (
            handleServiceFlowRender(item.editedLabel) && (
              <Grid container item xs={12} sm={6} md={4} xl={3}>
                <EconomicsFieldComponent
                  data-testid={item.testField}
                  required={
                    !thirtyYearPropertyfields[item.field]?.optionalIn?.includes(
                      loanStage
                    )
                  }
                  value={getProperty(
                    propertyEconomics as { [k: string]: any },
                    item.field
                  )}
                  onChange={onChange(item)}
                  onBlur={handleBlur(item.field)}
                  error={fieldErrors[item.field]}
                  helperText={fieldErrors[item.field]}
                  isViewMode={
                    isEvaluationPanelVisible &&
                    !editableFlags[`editProp${activePropertyId}`]
                  }
                  editedLabel={false}
                  enableReason={false}
                  editedData={editHistoryValue(
                    editHistory2[
                      item.field === "originalAppraisalValue"
                        ? `data.properties[${activePropertyId}].propertyEconomics.originalAsIsAppraisalValue`
                        : `data.properties[${activePropertyId}].propertyEconomics.${item.field}`
                    ],
                    "currency"
                  )}
                  isMandatoryServicerField={
                    isMandatoryServicerField &&
                    isMandatoryServicerField(item.field)
                  }
                  infoIconData={
                    infoContent &&
                    (item.field === "originalAppraisalValue"
                      ? infoContent[
                          `fieldInfo.${loanType}.${loanStage.toLowerCase()}.properties.propertyEconomics.originalAsIsAppraisalValue`
                        ]
                      : infoContent[
                          `fieldInfo.${loanType}.${loanStage.toLowerCase()}.properties.propertyEconomics.${
                            item.field
                          }`
                        ])
                  }
                  selectionData={yesNoDropdown.map((ele) => {
                    return { label: ele, value: ele };
                  })}
                  isLATView={isLATUser()}
                />
              </Grid>
            )
          );
        })}
        {editableFlags[`editProp${activePropertyId}`] && (
          <Grid container item xs={12} sm={12} md={12} xl={12}>
            <Button
              variant="contained"
              color="info"
              // data-testid="save-button"
              className={
                editableFlags[`editProp${activePropertyId}`]
                  ? classes.savebutton
                  : classes.editImageDisplay
              }
              disabled={Object.keys(changedValueFields).length === 0}
              onClick={() => {
                handleOpen();
              }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="info"
              // data-testid="save-button"
              className={
                editableFlags[`editProp${activePropertyId}`]
                  ? classes.cancelButton
                  : classes.editImageDisplay
              }
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </Button>
          </Grid>
        )}
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="duplicate-property-modal"
        aria-describedby="duplicate-property-modal-description"
      >
        <div style={{ padding: 20, width: 500, maxWidth: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexGrow: 1,
              alignItems: "center"
            }}
          >
            <div style={{ fontSize: 14, fontWeight: 600, color: "#32325d" }}>
              Warning
            </div>
            <CloseIcon
              htmlColor="#414169"
              style={{ width: 16, height: 16, cursor: "pointer" }}
              onClick={() => handleClose()}
            />
          </div>
          <div
            style={{
              fontSize: 12,
              lineHeight: 1.5,
              color: "#32325d",
              marginTop: 40
            }}
          >
            {requestWaiverMessage}
          </div>
          <div className={classes.modalDeleteButtonConatainer}>
            <Button
              variant="contained"
              color="primary"
              data-testid="modal-delete-button"
              className={classes.modalButton}
              onClick={() => {
                if (
                  !Object.values(fieldErrors).filter(
                    (value: any) => value !== null && value !== ""
                  ).length
                ) {
                  saveOnFieldChange();
                  // if (saveOnFieldChange()) {
                  //   dispatch(valueEditing(false));
                  //   for (const i in editableFlags) editableFlags[i] = false;
                  //   closeForm();
                  //   setChangedValueFields({})
                  // }
                }
                handleClose();
              }}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              data-testid="modal-cancel-button"
              className={classes.modalCancelButton}
              onClick={() => {
                onCancel();
                handleClose();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
