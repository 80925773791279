import React from "react";
import {
  GridTextFieldForm,
  GridDatePickerForm as DatePickerForm,
  DropDownProps,
  DropDown
} from "@toorak/tc-common-fe-sdk";
import {
  GridTextFieldProps as TextFieldProps,
  DatePickerProps
} from "./FormComponentsProps";
import { CustomizedTextField } from "../../utils/customizedTextField";

export function GenericSummaryTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    isViewMode,
    onBlur,
    label,
    infoIconData,
    editedLabel,
    defaultValue,
    fromSummary,
    viewModeStyle,
    editedData,
    isServicerMandatory
  } = props;
  return (
    <GridTextFieldForm
      // required
      value={value}
      isViewMode={isViewMode}
      onChange={onChange}
      label={label}
      error={error}
      helperText={helperText}
      data-testid="borrowing-entity"
      valueTestId="borrower-entity-value"
      onBlur={onBlur}
      editedData={editedData}
      infoIconData={infoIconData}
      editedLabel={editedLabel}
      defaultValue={defaultValue}
      fromSummary={fromSummary}
      viewModeStyle={viewModeStyle}
      isServicerMandatory={isServicerMandatory}
    />
  );
}

export function GridSummaryDateField(props: DatePickerProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    isViewMode,
    onBlur,
    editedLabel,
    infoIconData,
    label
  } = props;

  return (
    <DatePickerForm
      value={(isViewMode ? `${value}` : value) as any}
      onChange={onChange as any}
      isViewMode={isViewMode}
      label={label}
      error={error}
      helperText={helperText}
      infoIconData={infoIconData}
      onBlur={onBlur}
      editedLabel={editedLabel}
      renderInput={(props: any) => (
        <CustomizedTextField
          props={props}
          data-testid={"original-credit-report-date"}
          value={value}
          error={error}
          helperText={helperText}
          isViewMode={isViewMode}
          valueTestId={"original-credit-report-date-value"}
        />
      )}
    />
  );
}

export function GenericSummaryDropDown(props: DropDownProps) {
  const {
    selectionValue,
    selectionOnChange,
    selectionData,
    helperText,
    isViewMode,
    editedLabel,
    formControlTestId,
    inputLabelId,
    inputLabelTestId,
    label,
    selectId,
    selectTestId,
    infoIconData,
    editedData,
    isLATView,
    onBlur,
    enableReason,
    notRequired = false,
    menuItemStyles,
    renderCustomInfoTag,
    showEditedTag,
    onHover
  } = props;
  return (
    <DropDown
      isViewMode={isViewMode}
      formControlTestId={formControlTestId}
      inputLabelId={inputLabelId}
      inputLabelTestId={inputLabelTestId}
      label={label}
      selectId={selectId}
      selectTestId={selectTestId}
      selectionValue={selectionValue}
      selectionOnChange={selectionOnChange}
      selectionData={selectionData}
      helperText={helperText}
      onBlur={onBlur}
      infoIconData={infoIconData}
      valueTestId="borrower-type-value"
      editedLabel={editedLabel}
      editedData={editedData}
      isLATView={isLATView}
      enableReason={enableReason}
      notRequired={notRequired}
      menuItemStyles={menuItemStyles}
      renderCustomInfoTag={renderCustomInfoTag}
      showEditedTag={showEditedTag}
      onHover={onHover}
    />
  );
}
