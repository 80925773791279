import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../stores/rootReducer";

export function useHoldLandMark(latest: boolean = false) {
  const [loanLandMarks, setLoanLandMarks] = useState([]);

  const loanLandmark = useSelector<RootState, any>(
    (state: any) => state.createLoanStore?.loanDetails?.loanLandmarks
  );

  const { availableLoanStages, isViewMode } = useSelector<RootState, any>(
    (state: any) => state.createLoanStore
  );

  const getPrefixLandMark = (loanLandMarkData: any) => {
    const returnLandMark = [];
    for (let i = 0; i <= loanLandMarkData.length - 1; i += 1) {
      if (loanLandMarkData[i].name === "update_hold_status") {
        returnLandMark.push(loanLandMarkData[i]);
      }
      if (loanLandMarkData[i].name === "loan_conversion") {
        break;
      }
    }
    return returnLandMark;
  };

  const getPostfixLandMark = (loanLandMarkData: any) => {
    const index = loanLandMarkData.findIndex(
      (item: any) => item.name === "loan_conversion"
    );
    const returnLandMark = [];
    for (let i = index; i <= loanLandMarkData.length - 1; i += 1) {
      if (loanLandMarkData[i].name === "update_hold_status") {
        returnLandMark.push(loanLandMarkData[i]);
      }
    }
    return returnLandMark;
  };

  useEffect(() => {
    if (loanLandmark) {
      const isLoanConverted = loanLandmark?.find(
        (item: any) => item.name === "loan_conversion"
      );
      let latestLandMark: any = [];
      if (isLoanConverted && availableLoanStages && !isViewMode) {
        const postfixOnHoldLandMark = getPostfixLandMark(loanLandmark);
        latestLandMark = postfixOnHoldLandMark;
      } else if (isLoanConverted && isViewMode) {
        const prefixOnHoldLandMark = getPrefixLandMark(loanLandmark);
        latestLandMark = prefixOnHoldLandMark;
      } else {
        latestLandMark = loanLandmark?.filter(
          (item: any) => item.name === "update_hold_status"
        );
      }

      if (latest) {
        setLoanLandMarks(latestLandMark[latestLandMark?.length - 1]);
      } else {
        setLoanLandMarks(latestLandMark);
      }
    }
  }, [loanLandmark, latest, availableLoanStages, isViewMode]);

  return loanLandMarks;
}
