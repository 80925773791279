import makeStyles from '@mui/styles/makeStyles';

export const dbMainStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  }
}));

export const dbHeaderStyles = makeStyles(theme => ({
  tab: {
    color: "#32325d",
    fontSize: "14px",
    opacity: 1,
    textTransform: "none"
  },
  tabSelected: {
    fontWeight: 600
  }
}));

export const dbSubHeaderStyles = makeStyles(theme => ({
  root: {
    alignItems: "center",
    justifyContent: "space-between",
    height: "48px",
    width: "100%",
    padding: "6px 20px",
    backgroundColor: "#f6f9fc"
  },
  rootFullSC: {
    minHeight: "48px",
    width: "100%",
    backgroundColor: "#FFF"
  },
  inputSearch: {
    marginLeft: theme.spacing(1),
    flex: 1,
    border: "1px solid #e9ecef",
    borderRadius: "20px",
    padding: "0px 10px",
    backgroundColor: "#ffffff",
    height: "36px !important",
    width: 140
  },
  exportHeaderText: {
    fontSize: "12px",
    fontWeight: 600,
    color: "#32325d"
  },
  inputBaseStyle: {
    "&:focus": {
      boxShadow: "none !important"
    }
  },
  filterButton: {
    color: "#32325d",
    "&:hover": {
      backgroundColor: "inherit !important",
      color: "#32325d !important"
    }
  },
  exportButton: {
    color: "#32325d",
    "&:hover": {
      backgroundColor: "inherit !important",
      color: "#32325d !important"
    },
    padding: "6px 0px",
    marginBottom: "4px"
  },
  filterButtonFullSC: {
    color: "#32325d",
    padding: "12px",
    "&:hover": {
      backgroundColor: "inherit !important",
      color: "#32325d !important"
    }
  },
  noWidth: {},
  fullWidth: {
    width: "100%"
  },
  clearButton: {
    marginLeft: "20px",
    color: "#5e72e4",
    "&:hover": {
      backgroundColor: "inherit !important",
      color: "#5e72e4 !important"
    }
  },
  clearFilterButton: {
    marginRight: "8px",
    color: "#5e72e4",
    "&:hover": {
      backgroundColor: "inherit !important",
      color: "#5e72e4 !important"
    }
  },
  clearButtonFullScreen: {
    marginRight: "20px",
    color: "#5e72e4",
    "&:hover": {
      backgroundColor: "inherit !important",
      color: "#5e72e4 !important"
    }
  },
  filterOutterContainer: {
    minWidth: "330px",
    // width: "330px",
    // maxWidth: "650px",
    backgroundColor: "#ffffff"
  },
  filterOutterContainerFullScreen: {
    //minWidth: "220px",
    // maxWidth: "650px",
    backgroundColor: "#ffffff"
  }
}));

export const dbContentBodyStyles = makeStyles(theme => ({
  body: {
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: "800px",
    width: "100%"
  },
  bannerContainer: {
    alignItems: "center",
    justifyContent: "space-between",
    height: "48px",
    width: "100%",
    padding: "6px 20px",
    backgroundColor: "#f6f9fc"
  }
}));
