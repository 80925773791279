/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from "react";
import { Avatar, Button, Grid, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { deepOrange, deepPurple } from "@mui/material/colors";
import { CheckboxesTags } from "./CheckBoxesTags";
import { TagProps, userInitials } from ".";

const commentPostStyles = makeStyles((theme: Theme) => ({
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500]
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500]
  },
  userName: {
    color: "#5b6880",
    fontSize: "12px"
  },
  commentText: {
    fontSize: "12px",
    color: "#32325d"
  },
  textAreaWrapper: {
    width: "100%",
    height: "64px",
    border: "1px solid #cad1d7",
    borderRadius: "4px"
  },
  textArea: {
    fontFamily: "sans-serif !important",
    width: "100%",
    height: "100%",
    padding: "10px 12px",
    resize: "none",
    border: "none",
    borderRadius: "4px",
    fontSize: "14px"
  }
}));

export const CommentPost = React.memo(
  (props: {
    avatarProps?: {
      style?: {};
      url?: string;
    };
    userProps: {
      firstName: string;
      lastName: string;
    };
    onSave: Function;
    tagProps: TagProps;
  }) => {
    const classes = commentPostStyles();
    const { avatarProps, userProps, onSave, tagProps } = props;
    const [comment, setComment] = useState<string>("");
    const [commentBlr, setCommentBlr] = useState<string>("");
    const [tagStore, setTagStore] = useState<any[]>([]);

    const onSaveTrigger = useCallback(() => {
      onSave(commentBlr, tagStore);
      onClear();
    }, [commentBlr, tagStore]);

    const onClear = () => {
      setCommentBlr("");
      setComment("");
      setTagStore([]);
    };

    return (
      <Grid container alignItems="flex-start">
        <Grid item xs={1} style={{ justifyContent: "center", display: "flex" }}>
          <Avatar
            className={classes.purple}
            style={avatarProps?.style}
            src={avatarProps?.url}
          >
            {userInitials(userProps.firstName || "", userProps.lastName || "")}
          </Avatar>
        </Grid>
        <Grid item xs={10}>
          <Grid container direction="column" spacing={1}>
            <Grid item xs={12}>
              <div className={classes.textAreaWrapper}>
                <textarea
                  className={classes.textArea}
                  value={comment}
                  onChange={(e: any) => setComment(e.target.value)}
                  placeholder="Your Comment"
                  onBlur={(e: any) => {
                    setCommentBlr(e.target.value);
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <CheckboxesTags
                tagProps={tagProps}
                tagsVal={tagStore}
                saveTags={setTagStore}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={onSaveTrigger}
                    disabled={!comment}
                    color="info"
                  >
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onClear}
                    disabled={!comment && !tagStore.length}
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);
