import { LoanStage, LoanTypes, publicClient } from "@toorak/tc-common-fe-sdk";
import { getConfig } from "../../config/config";
import {
  getLoanById,
  updateSummaryDetails,
  insertDocs
} from "../../create-loan/create-loan.action";
import {
  hideLoader,
  showLoader
} from "../loaderAndException/loaderAndException.action";
import {
  getCommentsForDocType,
  getCustodyDocsPerLoan,
  updateCustodyDocsFields
} from "./CustodyDocuments.reducer";

export const formatLoanType = (loanType: string) => {
  return loanType === "BridgeLoan"
    ? LoanTypes.BridgeLoan
    : loanType === "InvestorDSCR"
    ? LoanTypes.ThirtyYearLoan
    : LoanTypes.TwoOneLoan;
};
export const getCustodyDocsList = (
  loanId: any,
  loanStage: any,
  loanType: any
): any => {
  return async (dispatch: any) => {
    try {
      const formattedLoanType = formatLoanType(loanType);
      const url = `${
        getConfig().apiUrl
      }/custody-service/loan/${loanId}/${loanStage}/custody-docs-list?createIfMissing=true&&loanType=${formattedLoanType}`;
      const response = await publicClient.get(url);

      if (response) {
        dispatch(getCustodyDocsPerLoan(response.data.data));
      }
    } catch (err) {
      const e: any = err;
      console.error(e.message);
      // dispatch({
      //   type: GET_CUSTODY_DOCS_PER_LOAN_FAILED,
      //   error: {
      //     message: e.message
      //   }
      // });
    }
  };
};

export const patchCustodyDocs = async (
  updatedocList: any[],
  loanId: string,
  loanType: string,
  loanStage: LoanStage
) => {
  const formattedLoanType = formatLoanType(loanType);
  const url = `${
    getConfig().apiUrl
  }/custody-service/loan/${loanId}/${loanStage}/${formattedLoanType}/custody-docs-list`;
  const requestBody = {
    documentList: updatedocList
  };
  const response = await publicClient.patch(url, requestBody);
  return response;
};

export const updateCustodyFieldsById = (documentList: any[]): any => {
  return async (dispatch: any) => {
    dispatch(updateCustodyDocsFields({ documentList }));
  };
};

export const fetchCommentsForDocSak = (payloadObj: any, docIds: any) => {
  return async (dispatch: any) => {
    try {
      const url = `${
        getConfig().apiUrl
      }/aggregate/chatservice/fetch?includeChatLines=true&includeTotalLinesCount=true&unReadCount=false`;
      const response = await publicClient.post(url, payloadObj);
      if (response) {
        dispatch(getCommentsForDocType({ data: response.data, docIds }));
      }
    } catch (err) {
      const e: any = err;
      console.error(e.message);
      // dispatch({
      //   type: GET_COMMENTS_FOR_DOCTYPE_FAILED,
      //   error: {
      //     message: e.message
      //   }
      // });
    }
  };
};

export function createCommentThread(loanId: any, docId: any): Promise<string> {
  return new Promise((resolve, reject) => {
    const url = `${
      getConfig().apiUrl
    }/chatservice/LOAN/${loanId}/chats?secondaryIdentifierName=document&secondaryIdentifierValue=${docId}`;
    publicClient
      .post(url, {})
      .then((res: any) => resolve(res.data))
      .catch(reject);
  });
}

export const postComment = (
  chatId: any,
  docId: any,
  payload: any,
  payload2: any,
  filesList: any
): any => {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const url = `${getConfig().apiUrl}/chatservice/${chatId}/lines`;
      const response = await publicClient.post(url, payload);

      if (response) {
        if (filesList?.length) {
          let files = filesList.map((file: any) => {
            return file.body.length && file.body[0];
          });
          await insertDocs(
            files,
            chatId,
            "CHAT_SEQUENCE",
            response.data.chatLineSequenceId,
            "CHAT"
          );
        }
        dispatch(fetchCommentsForDocSak([payload2], [docId]));
        dispatch(hideLoader());
      }
    } catch (err) {
      dispatch(hideLoader());
      // const e: any = err;
      // dispatch({
      //   type: GET_COMMENTS_FOR_DOCTYPE_FAILED,
      //   error: {
      //     message: e.message
      //   }
      // });
    }
  };
};

export const refetchLoanAndSyncCustodyCheckbox = (
  loanId: string,
  loanStage: LoanStage
): any => {
  return async (dispatch: any) => {
    const loanData = await getLoanById(loanId, loanStage);
    if (loanData) {
      const custodyDocuments: string =
        loanData.data?.loanDetailId?.loanConfigId?.custodyDocuments || "No";

      dispatch(
        updateSummaryDetails("custodyDocuments", custodyDocuments, false)
      );
    }
  };
};
