import { LoanStage } from "@toorak/tc-common-fe-sdk";
import React from "react";
import {
  CreditEvalComponentType,
  InputValueSectionType,
  thirdPartyReportFields
} from "../../../credit-evaluation/helperAndConfigs/CreditEvalFields";
import { LoanServicerNames } from "../../../utils/constants";
import { LoanDetailsStatusResult } from "./LoanDetailsStatusResult";

const isEvaluationPanelVisible = true;
const ThirtyYearLoanDetailsForm = React.lazy(() =>
  import("../../../create-loan/30-year-loan-details/LoanDetailsForm").then(
    (module) => ({
      default: module.LoanDetailsForm
    })
  )
);
const ServicerDocsWrapper = React.lazy(() =>
  import("../DocsTab/ServicerDocsWrapper").then((module) => ({
    default: module.ServicerDocsWrapper
  }))
);
export enum LoanTypesValues {
  BridgeLoan = "BridgeLoan",
  ThirtyYearLoan = "InvestorDSCR",
  TwoOneLoan = "2 + 1 Loan"
}
const { pre, post } = LoanStage;
const { bsi, fci, situs, shellpoint, fay } = LoanServicerNames;
const { ThirtyYearLoan, BridgeLoan } = LoanTypesValues;

export const getServicerTabs = (servicerName: LoanServicerNames) => [
  {
    id: "servicerLoanDetails",
    title: "Loan Details",
    component: () => <ThirtyYearLoanDetailsForm />,
    requiresEvaluationPanel: false,
    showIn: [pre, post]
  },

  {
    id: "servicerDocuments",
    title: "Documents",
    component: () => <ServicerDocsWrapper />,
    requiresEvaluationPanel: false,
    showIn: [pre, post]
  }
];

export const tabsDocsPanel: any = (
  mandatoryFields: any[],
  loanTypeAllowed: string
) => [
  {
    title: "Loan Details Status",
    component: () => (
      <LoanDetailsStatusResult
        missingFieldList={mandatoryFields}
        loanTypeAllowed={loanTypeAllowed}
      />
    )
  }
];

export interface ServicerFieldType {
  label: string;
  mandatoryField: string;
  valueField: string;
  fieldType: string;
  forServicer: LoanServicerNames[];
  loanTypeAllowed: LoanTypesValues[];
  hideCondition?: (data: any) => boolean;
  readOnly?: boolean;
  isResultValue?: boolean;
  showFor?: string[];
  isFormula?: boolean;
  ruleField?: string;
  ruleValueType?: string;
  preSanitize?: Function;
}

export const finalThirdPartySection: InputValueSectionType = {
  sectionLabel: "Third Party Report",
  sectionId: "thirdPartyReport",
  sectionName: "loanCredit",
  componentType: CreditEvalComponentType.creditThirdParty,
  fields: thirdPartyReportFields
};

export const ServicerOnboardingLoanEconomicsFields: ServicerFieldType[] = [
  {
    label: "Financed Interest Reserve ($)",
    mandatoryField: "originalLoanAmount",
    valueField: "financialInterestReserve",
    fieldType: "currency",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan]
  },
  // added for am-1049
  {
    label: "Initial Loan Amount ($)",
    mandatoryField: "originalLoanAmount",
    valueField: "loanAmount",
    fieldType: "currency",
    forServicer: [bsi],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "Initial Loan Amount ($)",
    mandatoryField: "originalLoanAmount",
    valueField: "loanAmount",
    fieldType: "currency",
    forServicer: [shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan]
  },
  {
    label: "Initial Rate Adjustment Period",
    mandatoryField: "initialRateAdjustmentPeriod",
    valueField: "initialRateAdjustmentPeriod",
    fieldType: "number",
    forServicer: [situs],
    loanTypeAllowed: [ThirtyYearLoan]
  },
  {
    label: "Original Maximum Loan Amount ($)",
    mandatoryField: "originalMaximumLoanAmount",
    valueField: "maxLoanAmount",
    fieldType: "currency",
    forServicer: [bsi, situs],
    loanTypeAllowed: [BridgeLoan]
  },
  {
    label: "Original Maximum Loan Amount ($)",
    mandatoryField: "originalMaximumLoanAmount",
    valueField: "maxLoanAmount",
    fieldType: "currency",
    forServicer: [fci],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "Cut-off Date Loan Amount ($)",
    mandatoryField: "cutOffDateLoanAmount",
    valueField: "cutOffLoanAmount",
    fieldType: "currency",
    forServicer: [fci],
    loanTypeAllowed: [BridgeLoan]
  },
  {
    label: "Cut-off Date Loan Amount ($)",
    mandatoryField: "cutOffDateLoanAmount",
    valueField: "cutOffLoanAmount",
    fieldType: "currency",
    forServicer: [shellpoint, bsi, fay],
    loanTypeAllowed: [ThirtyYearLoan, BridgeLoan]
  },
  {
    label: "Interest Rate (As of Cut off Date) (%)",
    mandatoryField: "interestRateAsOfCutOffDate",
    valueField: "interestRate",
    fieldType: "percentage",
    forServicer: [bsi, fci, situs, shellpoint, fay],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "Financed Interest Reserve ($)",
    mandatoryField: "financedInterestReserve",
    valueField: "financialInterestReserve",
    fieldType: "currency",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [BridgeLoan]
  },
  {
    label: "Interest Only",
    mandatoryField: "interestOnly",
    valueField: "interestOnly",
    fieldType: "string",
    forServicer: [bsi, situs],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "Rate Type",
    mandatoryField: "rateType",
    valueField: "rateType",
    fieldType: "string",
    forServicer: [fci],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "Rate Type",
    mandatoryField: "rateType",
    valueField: "rateType",
    fieldType: "string",
    forServicer: [situs],
    loanTypeAllowed: [ThirtyYearLoan]
  },
  {
    label: "Business Purpose Occupancy",
    mandatoryField: "businessPurposeOccupancy",
    valueField: "businessPurposeOccupancy",
    fieldType: "number",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan]
  },
  {
    label: "Borrower Liquidity ($)",
    mandatoryField: "borrowerLiquidity",
    valueField: "borrowerLiquidity",
    fieldType: "currency",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan]
  },
  {
    label: "Lifetime Min Rate",
    mandatoryField: "lifetimeMinRate",
    valueField: "lifetimeMinRate",
    fieldType: "percentage",
    forServicer: [bsi, shellpoint, fay, situs],
    loanTypeAllowed: [ThirtyYearLoan]
  },
  {
    label: "Lifetime Max Rate",
    mandatoryField: "lifetimeMaxRate",
    valueField: "lifetimeMaxRate",
    fieldType: "percentage",
    forServicer: [situs],
    loanTypeAllowed: [ThirtyYearLoan]
  },
  {
    label: "Original Monthly Qualifying Payment ($)",
    mandatoryField: "monthlyQualifyingPayment",
    valueField: "monthlyQualifyingPayment",
    fieldType: "currency",
    forServicer: [bsi],
    loanTypeAllowed: [ThirtyYearLoan]
  },
  {
    label: "Original Monthly Qualifying Payment ($)",
    mandatoryField: "monthlyQualifyingPayment",
    valueField: "monthlyQualifyingPayment",
    fieldType: "currency",
    forServicer: [shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan, BridgeLoan]
  },
  {
    label: "Subordinate Financing",
    mandatoryField: "subordinateFinancing",
    valueField: "subordinateFinancing",
    fieldType: "string",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan]
  },
  {
    label: "Months of qualifying payment in reserves",
    mandatoryField: "qualifyingPaymentInReservesMonthly",
    valueField: "monthsOfQualifyingPaymentInReserves",
    fieldType: "string",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan],
    ruleField: "loanEconomics.monthsOfQualifyingPaymentInReserves",
    ruleValueType: "decimalFixedto4",
    isFormula: true
  },
  {
    label: "Financed Budget Amount ($)",
    mandatoryField: "financedBudgetAmount",
    valueField: "financedBudgetAmount",
    fieldType: "currency",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [BridgeLoan]
  },
  {
    label: "Prepayment Penalty Type",
    mandatoryField: "prepayPenaltyType",
    valueField: "prepayPenaltyType",
    fieldType: "string",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan]
  },
  {
    label: "Total Origination and Discount Points (%)",
    mandatoryField: "totalOriginationAndDiscountPoints",
    valueField: "discountPoints",
    fieldType: "percentage",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [BridgeLoan]
  },
  {
    label: "Late Charge ($)",
    mandatoryField: "lateCharge",
    valueField: "lateCharge",
    fieldType: "currency",
    forServicer: [bsi, fci, shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan, BridgeLoan]
  },
  {
    label: "ARM Round Flag",
    mandatoryField: "armRoundFlag",
    valueField: "armRoundFlag",
    fieldType: "string",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan, BridgeLoan]
  },
  {
    label: "Late Charge (%)",
    mandatoryField: "lateChargePercentage",
    valueField: "lateChargePercentage",
    fieldType: "percentage",
    forServicer: [bsi, fci, shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan, BridgeLoan]
  },
  // {
  //   label: "Original Monthly P&I Payment", // only dscr field
  //   mandatoryField: "originalMonthlyPiPayment",
  //   valueField: "originalMonthlyPiPayment",
  //   fieldType: "string",
  //   forServicer: [fci, shellpoint, fay],
  //   loanTypeAllowed: [ThirtyYearLoan, BridgeLoan]
  // },

  //for am-1054
  {
    label: "Interest Payment", // only dscr field
    mandatoryField: "originalMonthlyPiPayment",
    valueField: "originalMonthlyPiPayment",
    fieldType: "currency",
    forServicer: [bsi, shellpoint, fay, fci],
    loanTypeAllowed: [BridgeLoan]
  },
  {
    label: "P&I Payment", // only dscr field
    mandatoryField: "originalMonthlyPiPayment",
    valueField: "originalMonthlyPiPayment",
    fieldType: "currency",
    forServicer: [bsi, shellpoint, fay, fci],
    loanTypeAllowed: [ThirtyYearLoan]
  },
  {
    label: "ARM Index",
    mandatoryField: "armIndex",
    valueField: "armIndex",
    fieldType: "string",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan, BridgeLoan]
  },
  {
    label: "ARM Index",
    mandatoryField: "armIndex",
    valueField: "armIndex",
    fieldType: "string",
    forServicer: [situs],
    loanTypeAllowed: [ThirtyYearLoan]
  },
  {
    label: "Arm Rounding Factor",
    mandatoryField: "armRoundingFactor",
    valueField: "armRoundingFactor",
    fieldType: "string",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan, BridgeLoan]
  },
  {
    label: "ARM Look Back Days",
    mandatoryField: "armLookBackDays",
    valueField: "armLookBackDays",
    fieldType: "number",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan, BridgeLoan]
  },
  {
    label: "ARM Look Back Days",
    mandatoryField: "armLookBackDays",
    valueField: "armLookBackDays",
    fieldType: "number",
    forServicer: [situs],
    loanTypeAllowed: [ThirtyYearLoan]
  },
  {
    label: "Gross Margin (%)",
    mandatoryField: "grossArmMargin",
    valueField: "grossArmMargin",
    fieldType: "percentage",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan, BridgeLoan]
  },
  {
    label: "Gross Margin (%)",
    mandatoryField: "grossArmMargin",
    valueField: "grossArmMargin",
    fieldType: "percentage",
    forServicer: [situs],
    loanTypeAllowed: [ThirtyYearLoan]
  },
  {
    label: "Rate Adjustment Frequency",
    mandatoryField: "rateAdjustmentFrequency",
    valueField: "rateAdjustmentFrequency",
    fieldType: "number",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan, BridgeLoan]
  },
  {
    label: "Accrual Type",
    mandatoryField: "accrualType",
    valueField: "accrualType",
    fieldType: "string",
    forServicer: [bsi, situs],
    loanTypeAllowed: [ThirtyYearLoan, BridgeLoan]
  },
  {
    label: "Prepayment Penalty Months",
    mandatoryField: "prePaymentPenaltyMonths",
    valueField: "prepaymentPenaltyMonthsResults",
    fieldType: "string",
    forServicer: [shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan, BridgeLoan]
  },
  {
    label: "Include out of pocket budget in ARLTV calculation",
    mandatoryField: "includeOutOfPocketBudgetInARLTV",
    valueField: "includeOutOfPocketBudgetInARLTV",
    fieldType: "checkBox",
    forServicer: [shellpoint, fay], // need to check with Moukthik on the servicer
    loanTypeAllowed: [BridgeLoan]
  }
];

export const ServicerOnboardingBorrowerInfoFields: ServicerFieldType[] = [
  {
    label: "Borrower Type",
    showFor: ["Individual", "Entity"],
    mandatoryField: "borrowerType",
    valueField: "borrowerType",
    fieldType: "string",
    forServicer: [bsi, shellpoint, fay, fci],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "First Name",
    showFor: ["Individual"],
    mandatoryField: "firstName",
    valueField: "firstName",
    fieldType: "string",
    forServicer: [bsi, shellpoint, fay, fci],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "Last Name",
    showFor: ["Individual"],
    mandatoryField: "lastName",
    valueField: "lastName",
    fieldType: "string",
    forServicer: [bsi, shellpoint, fay, fci],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "Borrower Entity Name",
    showFor: ["Entity"],
    mandatoryField: "borrowingEntityName",
    valueField: "borrowingEntityName",
    fieldType: "string",
    forServicer: [bsi, shellpoint, fay, fci],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "EIN",
    showFor: ["Entity"],
    mandatoryField: "eiNumber",
    valueField: "eiNumber",
    fieldType: "string",
    forServicer: [bsi, shellpoint, fay, fci],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "SSN",
    showFor: ["Individual"],
    mandatoryField: "ssNumber",
    valueField: "ssNumber",
    fieldType: "string",
    forServicer: [bsi, shellpoint, fay, fci],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "Borrower Email",
    showFor: ["Individual", "Entity"],
    mandatoryField: "borrowerEmail",
    valueField: "borrowerEmail",
    fieldType: "string",
    forServicer: [bsi, shellpoint, fay, fci],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "Borrower Full Billing Address",
    showFor: ["Individual", "Entity"],
    mandatoryField: "billingAddress",
    valueField: "billingAddress",
    fieldType: "string",
    forServicer: [bsi, shellpoint, fay, fci],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "Borrower Billing Phone Number",
    showFor: ["Individual", "Entity"],
    mandatoryField: "billingPhoneNumber",
    valueField: "billingPhoneNumber",
    fieldType: "string",
    forServicer: [bsi, shellpoint, fay, fci],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  }
];

export const ServicerOnboardingGuarantorInfoFields: ServicerFieldType[] = [
  {
    label: "First Name",
    mandatoryField: "firstName",
    valueField: "firstName",
    fieldType: "string",
    forServicer: [bsi, shellpoint, fay, fci],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "Last Name",
    mandatoryField: "lastName",
    valueField: "lastName",
    fieldType: "string",
    forServicer: [bsi, shellpoint, fay, fci],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },

  {
    label: "Foreign National Flag",
    mandatoryField: "foreignNationalString",
    valueField: "foreignNationalString",
    fieldType: "string",
    forServicer: [bsi, shellpoint, fay, fci],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "Original Credit Score (Median)",
    mandatoryField: "originalCreditScoreMedian",
    valueField: "originalCreditScoreMedian",
    fieldType: "string",
    forServicer: [bsi, shellpoint, fay, fci],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "SSN",
    mandatoryField: "ssNumber",
    valueField: "ssNumber",
    fieldType: "string",
    forServicer: [bsi, shellpoint, fay, fci],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  }
];

export const ServicerOnboardingPropInformationFields: ServicerFieldType[] = [
  {
    label: "Property Type",
    mandatoryField: "propertyType",
    valueField: "propertyType",
    fieldType: "string",
    forServicer: [bsi, fci, situs, shellpoint, fay],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "Number Of Units",
    mandatoryField: "propertyUnits",
    valueField: "numberOfUnits",
    fieldType: "number",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "Most Recent Property Valuation Date",
    mandatoryField: "recentPropertyValuationDate",
    valueField: "propertyValuationDate",
    fieldType: "date",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  }
];

export const ServicerOnboardingLoanFeaturesFields: ServicerFieldType[] = [
  {
    label: "Original Term to Maturity (Months)",
    mandatoryField: "originalTermToMaturity",
    valueField: "originalTermToMaturity",
    fieldType: "number",
    forServicer: [bsi, situs, shellpoint, fay],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  }
];

export const ServicerOnboardingPropUnitFields: ServicerFieldType[] = [
  {
    label: `Total Monthly Gross Rent`,
    mandatoryField: "marketRentMonthly",
    valueField: "marketRentMonthly",
    fieldType: "currency",
    forServicer: [bsi],
    loanTypeAllowed: [ThirtyYearLoan]
  },
  {
    label: `Total Monthly Gross Rent`,
    mandatoryField: "marketRentMonthly",
    valueField: "marketRentMonthly",
    fieldType: "currency",
    forServicer: [shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan, BridgeLoan]
  }
];

export const ServicerOnboardingLoanSettlementFields: ServicerFieldType[] = [
  {
    label: `Closing Date`,
    mandatoryField: "closingDate",
    valueField: "closingDate",
    fieldType: "date",
    forServicer: [situs],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: `Current Principal Balance`,
    mandatoryField: "currentPrincipalBalance",
    valueField: "currentPrincipalBalance",
    fieldType: "currency",
    forServicer: [situs, fci, bsi, shellpoint, fay],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  }
];

export const ServicerOnboardingExtraGurrantorFields: ServicerFieldType[] = [
  {
    label: `SSN`,
    mandatoryField: "ssNumber",
    valueField: "ssNumber",
    fieldType: "string",
    forServicer: [shellpoint, fay],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  }
];

export const ServicerOnboardingPropEconomicsFields: ServicerFieldType[] = [
  {
    label: `Original "As Is" Appraisal Value($)`,
    mandatoryField: "originalAsIsAppraisalValue",
    valueField: "originalAppraisalValue",
    fieldType: "currency",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "Purchased Price ($)",
    mandatoryField: "purchasePrice",
    valueField: "purchasePrice",
    fieldType: "currency",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "Third Party Valuation ($)",
    mandatoryField: "thirdPartyValuation",
    valueField: "thirdPartyValuation",
    fieldType: "currency",
    forServicer: [bsi],
    loanTypeAllowed: [ThirtyYearLoan]
  },
  {
    label: "Third Party Valuation ($)",
    mandatoryField: "thirdPartyValuation",
    valueField: "thirdPartyValuation",
    fieldType: "currency",
    forServicer: [shellpoint, fay],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "Annual Flood Insurance ($)",
    mandatoryField: "annualFloodInsurance",
    valueField: "annualFloodInsurance",
    fieldType: "currency",
    forServicer: [shellpoint, fay],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "Annual Hazard Insurance ($)",
    mandatoryField: "annualHazardInsurance",
    valueField: "annualHazardInsurance",
    fieldType: "currency",
    forServicer: [shellpoint, fay],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  }
  // hidden for am-1121
  // {
  //   label: "Market Rent per month (if applicable) ($)",
  //   mandatoryField: "marketRentPerMonth",
  //   valueField: "marketRentPerMonth",
  //   fieldType: "currency",
  //   forServicer: [shellpoint, fay],
  //   loanTypeAllowed: [ThirtyYearLoan]
  // }
];

export const ServicerOnboardingLoanSummaryFields: ServicerFieldType[] = [
  {
    label: "Originator",
    mandatoryField: "accountName",
    valueField: "accountName",
    fieldType: "string",
    forServicer: [fci, bsi, shellpoint, fay, situs],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "Loan Type",
    mandatoryField: "loanType",
    valueField: "loanType",
    fieldType: "string",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "Loan Type",
    mandatoryField: "loanType",
    valueField: "loanType",
    fieldType: "string",
    forServicer: [fci],
    loanTypeAllowed: [ThirtyYearLoan]
  }
];

export const ServicerOnboardingPropLocationFields: ServicerFieldType[] = [
  {
    label: "Address",
    mandatoryField: "addressLine1",
    valueField: "address",
    fieldType: "string",
    forServicer: [fci, bsi, shellpoint, fay, situs],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "City",
    mandatoryField: "city",
    valueField: "city",
    fieldType: "string",
    forServicer: [fci, bsi, shellpoint, fay, situs],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "State",
    mandatoryField: "state",
    valueField: "state",
    fieldType: "string",
    forServicer: [fci, bsi, shellpoint, fay, situs],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "Postal Code",
    mandatoryField: "postalCode",
    valueField: "zipCode",
    fieldType: "string",
    forServicer: [fci, bsi, shellpoint, fay, situs],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  }
];

export const ServicerOnboardingCalculatedFields: ServicerFieldType[] = [
  {
    label: "Coborrower Name",
    mandatoryField: "coborrowerName",
    valueField: "coborrowerName",
    fieldType: "string",
    forServicer: [fci],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "Company Contact Name",
    mandatoryField: "companyContactName",
    valueField: "companyContactName",
    fieldType: "string",
    forServicer: [fci],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  // {
  //   label: "Escrow Balance ($)",
  //   mandatoryField: "escrowBalance",
  //   valueField: "escrowBalance",
  //   fieldType: "currency",
  //   forServicer: [bsi],
  //   loanTypeAllowed: [ThirtyYearLoan]
  // },
  // {
  //   label: "Escrow Balance ($)",
  //   mandatoryField: "escrowBalance",
  //   valueField: "escrowBalance",
  //   fieldType: "currency",
  //   forServicer: [fci],
  //   loanTypeAllowed: [ThirtyYearLoan, BridgeLoan]
  // },
  {
    label: "Trade Finance Buyer",
    mandatoryField: "tradeFinanceBuyer",
    valueField: "tradeFinanceBuyer",
    fieldType: "string",
    forServicer: [fci],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "Interest Reserve?",
    mandatoryField: "financedInterestReserve",
    valueField: "financedInterestReserve",
    fieldType: "string",
    forServicer: [bsi],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "Prepayment Penalty",
    mandatoryField: "prepayPenaltyType",
    valueField: "prepayPenaltyType",
    fieldType: "string",
    forServicer: [shellpoint, fay, fci],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "Prepayment Penalty",
    mandatoryField: "prepayPenaltyType",
    valueField: "prepayPenaltyType",
    fieldType: "string",
    forServicer: [bsi],
    loanTypeAllowed: [ThirtyYearLoan]
  },
  {
    label: "Total No Of Borrowers",
    mandatoryField: "totalNoOfBorrowers",
    valueField: "totalNoOfBorrowers",
    fieldType: "string",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  }
];
export const ServicerOnboardingDrawDetailsFields: ServicerFieldType[] = [
  {
    label: "Draw Date",
    mandatoryField: "drawDate",
    valueField: "drawDate",
    fieldType: "date",
    forServicer: [bsi],
    loanTypeAllowed: [BridgeLoan]
  },
  {
    label: "Draw Amount",
    mandatoryField: "drawAmount",
    valueField: "drawAmount",
    fieldType: "currency",
    forServicer: [bsi],
    loanTypeAllowed: [BridgeLoan]
  }
];

export const ServicerOnboardingLoanInformationFields: ServicerFieldType[] = [
  // {
  //   label: "Primary Loan Id",
  //   mandatoryField: "primaryLoanId",
  //   valueField: "primaryLoanID",
  //   fieldType: "string",
  //   forServicer: [fci, bsi],
  //   loanTypeAllowed: [BridgeLoan]
  // },
  {
    label: "Primary Loan Id",
    mandatoryField: "primaryLoanId",
    valueField: "primaryLoanID",
    fieldType: "string",
    forServicer: [fci, bsi, situs, shellpoint, fay],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  // {
  //   label: "Originator Loan Id",
  //   mandatoryField: "primaryLoanId",
  //   valueField: "primaryLoanID",
  //   fieldType: "string",
  //   forServicer: [fci, bsi],
  //   loanTypeAllowed: [ThirtyYearLoan]
  // },
  {
    label: "Loan Structure",
    mandatoryField: "loanStructure",
    valueField: "selectedLoanStructure",
    fieldType: "string",
    forServicer: [bsi, fci, situs],
    loanTypeAllowed: [BridgeLoan]
  },
  {
    label: "Grace Days",
    mandatoryField: "defaultRateCurable",
    valueField: "defaultRateCurable",
    fieldType: "string",
    forServicer: [bsi],
    loanTypeAllowed: [BridgeLoan]
  },
  {
    label: "Grace Days",
    mandatoryField: "defaultRateCurable",
    valueField: "defaultRateCurable",
    fieldType: "string",
    forServicer: [shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan, BridgeLoan]
  },
  {
    label: "Loan Purpose",
    mandatoryField: "loanPurpose",
    valueField: "loanPurpose",
    fieldType: "string",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan, BridgeLoan]
  },
  {
    label: "Origination Date",
    mandatoryField: "originationDate",
    valueField: "originationDate",
    fieldType: "date",
    forServicer: [fci, bsi, situs, shellpoint, fay],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "First Payment Date of Loan",
    mandatoryField: "firstPaymentDateOfLoan",
    valueField: "firstPaymentDate",
    fieldType: "date",
    forServicer: [fci, bsi, situs, shellpoint, fay],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "Maturity Date",
    mandatoryField: "originalMaturityDate",
    valueField: "maturityDate",
    fieldType: "date",
    forServicer: [fci, bsi, situs, shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan]
  },
  {
    label: "Original Maturity Date",
    mandatoryField: "originalMaturityDate",
    valueField: "maturityDate",
    fieldType: "date",
    forServicer: [fci, bsi, situs, shellpoint, fay],
    loanTypeAllowed: [BridgeLoan]
  },
  {
    label: "Cross Collaterlization (Y/N)",
    mandatoryField: "crossCollaterlization",
    valueField: "selectedCross",
    fieldType: "string",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [BridgeLoan]
  },
  {
    label: "Cross Collaterlization",
    mandatoryField: "crossCollaterlization",
    valueField: "selectedCross",
    fieldType: "string",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan]
  },
  {
    label: "Next Payment Due Date",
    mandatoryField: "nextPaymentDueDate",
    valueField: "nextPaymentDueDate",
    fieldType: "date",
    forServicer: [fci, situs, bsi, shellpoint, fay],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "Default Rate (%)",
    mandatoryField: "defaultRate",
    valueField: "defaultRate",
    fieldType: "percentage",
    forServicer: [fci, bsi, shellpoint, fay],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "Default Rate Curable (Grace Days)",
    mandatoryField: "defaultRateCurable",
    valueField: "defaultRateCurable",
    fieldType: "number",
    forServicer: [bsi, fci],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  },
  {
    label: "Condo Eligibility",
    mandatoryField: "condoEligibility",
    valueField: "condoEligibility",
    fieldType: "string",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan]
  },
  {
    label: "Updated Insurance Escrow Balance ($)",
    mandatoryField: "updatedInsuranceEscrowBalance",
    valueField: "updatedInsuranceEscrowBalance",
    fieldType: "currency",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan]
  },
  {
    label: "Updated Tax Escrow Balance ($)",
    mandatoryField: "updatedTaxEscrowBalance",
    valueField: "updatedTaxEscrowBalance",
    fieldType: "currency",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan]
  },
  // {
  //   label: "Escrow Taxes At Closing ($)",
  //   mandatoryField: "escrowTaxesAtClosing",
  //   valueField: "escrowTaxesAtClosing",
  //   fieldType: "currency",
  //   forServicer: [bsi, shellpoint, fay],
  //   loanTypeAllowed: [ThirtyYearLoan]
  // },
  {
    label:
      "T&I Collected at closing sufficient to cover due dates within 12 months?",
    mandatoryField: "taxesAndInsuranceCollected",
    valueField: "taxesAndInsuranceCollected",
    fieldType: "string",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan]
  },
  {
    label: "T&I due within 90 days (at least 60 days) paid at closing?",
    mandatoryField: "taxesandInsuranceDue",
    valueField: "taxesandInsuranceDue",
    fieldType: "string",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan]
  },
  // {
  //   label: "Updated Tax Escrow Balance ($)",
  //   mandatoryField: "updatedTaxEscrowBalance",
  //   valueField: "updatedTaxEscrowBalance",
  //   fieldType: "currency",
  //   forServicer: [bsi, shellpoint, fay],
  //   loanTypeAllowed: [ThirtyYearLoan]
  // },
  {
    label: "Months of Insurance escrowed at closing",
    mandatoryField: "monthsOfInsurance",
    valueField: "monthsOfInsurance",
    fieldType: "decimal",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan]
  },
  {
    label: "Months of Taxes escrowed at closing",
    mandatoryField: "monthsOfTaxes",
    valueField: "monthsOfTaxes",
    fieldType: "string",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan]
  },
  {
    label: "Escrowed Taxes at closing ($)",
    mandatoryField: "escrowTaxes",
    valueField: "escrowTaxes",
    fieldType: "currency",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan]
  },
  {
    label: "Escrowed Insurance at closing ($)",
    mandatoryField: "escrowInsurance",
    valueField: "escrowInsurance",
    fieldType: "currency",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan]
  },

  {
    label: "Monthly Insurance Amount ($)",
    mandatoryField: "MonthlyInsuranceAmount",
    valueField: "monthlyInsuranceAmount",
    fieldType: "currency",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan]
  },
  {
    label: "Monthly Tax Amount ($)",
    mandatoryField: "monthlyTaxAmount",
    valueField: "monthlyTaxAmount",
    fieldType: "currency",
    forServicer: [bsi, shellpoint, fay],
    loanTypeAllowed: [ThirtyYearLoan]
  }
];

export const ServicerOnboardingToorakPricingFields: ServicerFieldType[] = [
  {
    label: "Servicing Fee (per month)($)",
    mandatoryField: "servicingFeePerMonth",
    valueField: "servicingFeePerMonth",
    fieldType: "currency",
    forServicer: [fci, bsi],
    loanTypeAllowed: [BridgeLoan, ThirtyYearLoan]
  }
];
