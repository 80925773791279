/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import {
  DropDownProps,
  DropDown,
  GridTextFieldForm,
  GridDatePickerForm as DatePickerForm
} from "@toorak/tc-common-fe-sdk";
import {
  DatePickerProps,
  TextFieldProps
} from "../create-loan/form-components/FormComponentsProps";
import { CustomizedTextField } from "../utils/customizedTextField";
import { formatDate } from "../utils/utils";
import { formatValueByType } from "../utils/formatChecks";

export function DropDownComponent(props: DropDownProps) {
  const {
    selectionValue,
    selectionOnChange,
    selectionData,
    helperText,
    isViewMode,
    editedLabel,
    formControlTestId,
    inputLabelId,
    inputLabelTestId,
    label,
    isLATView,
    onBlur,
    selectId,
    selectTestId,
    editedData,
    modalLink,
    infoIconData,
    notRequired
  } = props;
  return (
    <DropDown
      isViewMode={isViewMode}
      formControlTestId={formControlTestId}
      inputLabelId={inputLabelId}
      inputLabelTestId={inputLabelTestId}
      label={label}
      isLATView={isLATView}
      selectId={selectId}
      selectTestId={selectTestId}
      selectionValue={
        isViewMode && selectionValue === "SFR"
          ? "Single Family Detached"
          : selectionValue
      }
      notRequired={notRequired}
      selectionOnChange={selectionOnChange}
      selectionData={selectionData}
      helperText={helperText}
      onBlur={onBlur}
      modalLink={modalLink}
      infoIconData={infoIconData}
      valueTestId="borrower-type-value"
      editedLabel={editedLabel}
      editedData={editedData}
    />
  );
}

export function AlphaNumericComponent(props: TextFieldProps) {
  const {
    value,
    required,
    onChange,
    error,
    label,
    helperText,
    isViewMode,
    infoIconData,
    onBlur,
    editedLabel,
    editedData
  } = props;
  return (
    <GridTextFieldForm
      required={required}
      value={value}
      isViewMode={isViewMode}
      onChange={onChange}
      label={label}
      infoIconData={infoIconData}
      error={error}
      helperText={helperText}
      data-testid="data-testid"
      valueTestId="value-test-id"
      onBlur={onBlur}
      editedData={editedData}
      editedLabel={editedLabel}
    />
  );
}

export function DatePickerComponent(props: DatePickerProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    label,
    required,
    isViewMode,
    editedLabel,
    editedData,
    infoIconData,
    onBlur,
    labelCss,
    enableReason,
    customStyle = {},
    isServicerMandatory,
    onReasonBlur,
    showEditedTag,
    onHover
  } = props;
  const formattedDate = formatDate(value);
  const labelStyles = labelCss || {};
  return (
    <DatePickerForm
      value={value as any}
      onChange={onChange as any}
      label={label}
      required={required}
      editedData={editedData}
      error={error}
      customStyle={customStyle}
      infoIconData={infoIconData}
      isViewMode={isViewMode}
      helperText={helperText}
      editedLabel={editedLabel}
      onBlur={onBlur}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
      onReasonBlur={onReasonBlur}
      renderInput={(props: any) => (
        <CustomizedTextField
          props={props}
          data-testid="cut-off-date"
          value={value}
          error={error}
          helperText={helperText}
          isViewMode={isViewMode}
          labelStyles={labelStyles}
          valueTestId={`${label}-date`}
        />
      )}
      showEditedTag={showEditedTag}
      onHover={onHover}
    />
  );
}
