/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
import { Dialog, Grid } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { CommentCard } from "./CommentCard";
import { CommentHeader } from "./CommentHeader";
import { CommentPost } from "./CommentPost";

export interface CommentsData {
  firstName: string;
  middleName: string;
  lastName: string;
  comment: string;
  commentedDate: string;
  tags?: string[];
  unread?: boolean;
}

export interface TagProps {
  hide: boolean;
  type?: "customer";
  props?: {
    roles?: string[];
    accId?: any;
    sellerId?: any;
    isLAT?: boolean;
  };
}

export const userInitials = (firstName: string, lastName: string) => {
  return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
};

export const CommentBox = (props: {
  open: boolean;
  commentsList: CommentsData[];
  onClose: Function;
  badgeText: string;
  userProps: {
    firstName: string;
    lastName: string;
  };
  avatarProps?: {
    style?: {};
    url?: string;
  };
  onSave: Function;
  chatCount: number;
  tagProps: TagProps;
}) => {
  const {
    open,
    commentsList,
    onClose,
    badgeText,
    userProps,
    onSave,
    chatCount,
    tagProps
  } = props;

  const commentListRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setTimeout(() => {
      if (commentListRef.current)
        commentListRef?.current?.scrollIntoView({
          behavior: "smooth"
        });
    }, 600);
  }, [commentsList]);

  return (
    <Dialog fullWidth open={open} maxWidth="md">
      <Grid
        container
        style={{ width: "100%", padding: "18px", overflow: "hidden" }}
      >
        <Grid item xs={12}>
          <CommentHeader
            chatCount={chatCount}
            badgeText={badgeText}
            onClose={onClose}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            padding: "15px 0px",
            maxHeight: "50vh",
            overflowY: "auto",
            marginBottom: "20px"
          }}
        >
          {commentsList.map((cObj: CommentsData) => {
            return <CommentCard {...cObj} />;
          })}
          <div ref={commentListRef} />
        </Grid>
        {!tagProps.hide ? (
          <Grid item xs={12}>
            <CommentPost
              userProps={userProps}
              onSave={onSave}
              tagProps={tagProps}
            />
          </Grid>
        ) : null}
      </Grid>
    </Dialog>
  );
};
