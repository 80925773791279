/* eslint-disable no-param-reassign */
// import { produce } from "immer";
// import {
//   RESET_STATE_FLAG,
//   SET_APPRAISER_RESULTS,
//   SET_PREFERRED_DISCLAIMER,
//   SET_REVIEW100_DISCLAIMER,
//   SET_STATE_KEYS,
//   SET_TOORAK_PREFERRED_APPRAISERS,
//   UPDATE_PAGE_NUMBER
// } from "./appraiser.action";
// import {
//   SET_APPRAISR_FAQ,
//   UPDATE_TOTAL_APPRAISER_COUNT
// } from "./appraiser.action";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface AppraiserInit {
  resultData: {
    searchedList: any[];
    searchedText: string;
  };
  FAQTemplate: string;
  preferredDisclaimer: string;
  review100Disclaimer: string;
  stateList: any[];
  toorakPreferredAppraisers: {
    searchList: any[];
    searchedText: string;
  };
  resetStateFlag: boolean;
  pageNumber: number;
  totalAppraiser: number;
}
export const initialState: AppraiserInit = {
  resultData: {
    searchedList: [],
    searchedText: ""
  },
  FAQTemplate: "",
  preferredDisclaimer: "",
  review100Disclaimer: "",
  stateList: [],
  toorakPreferredAppraisers: {
    searchList: [],
    searchedText: ""
  },
  resetStateFlag: false,
  pageNumber: 1,
  totalAppraiser: 0
};

const appraiserSlice = createSlice({
  name: "appraiser",
  initialState,
  reducers: {
    setAppraiserResults: (
      state,
      action: PayloadAction<{ searchedText: string; resList: any[] }>
    ) => {
      const { searchedText, resList } = action.payload;
      state.resultData = {
        searchedList: resList,
        searchedText
      };
    },
    setAppraiserFAQ: (state, action: PayloadAction<{ FAQTemplate: any }>) => {
      const { FAQTemplate } = action.payload;
      state.FAQTemplate = FAQTemplate;
    },
    setStateKeys: (state, action: PayloadAction<{ stateList: any[] }>) => {
      const { stateList } = action.payload;
      state.stateList = stateList.map((state) => ({ name: state.key }));
    },
    setToorakPreferredAppraisers: (
      state,
      action: PayloadAction<{ searchedText: string; resList: any[] }>
    ) => {
      const { searchedText, resList } = action.payload;
      state.toorakPreferredAppraisers = {
        searchList: resList,
        searchedText
      };
    },
    setPreferredDisclaimer: (
      state,
      action: PayloadAction<{ preferredDisclaimer: string }>
    ) => {
      const { preferredDisclaimer } = action.payload;
      state.preferredDisclaimer = preferredDisclaimer;
    },
    setReview100Disclaimer: (
      state,
      action: PayloadAction<{ review100Disclaimer: string }>
    ) => {
      const { review100Disclaimer } = action.payload;
      state.review100Disclaimer = review100Disclaimer;
    },
    resetStateFlag: (state, action: PayloadAction<boolean>) => {
      // const { resetStateFlag } = action.payload;
      state.resetStateFlag = action.payload;
    },
    updatePageNumber: (state, action: PayloadAction<number>) => {
      // const { pageNumber } = action.payload;
      state.pageNumber = action.payload;
    },
    updateTotalAppraiserCount: (state, action: PayloadAction<number>) => {
      state.totalAppraiser = action.payload;
    }
  }
});

export const {
  setAppraiserResults,
  setAppraiserFAQ,
  setStateKeys,
  setToorakPreferredAppraisers,
  setPreferredDisclaimer,
  setReview100Disclaimer,
  resetStateFlag,
  updatePageNumber,
  updateTotalAppraiserCount
} = appraiserSlice.actions;
export const AppraiserReducer = appraiserSlice.reducer;

// export const AppraiserReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case SET_APPRAISER_RESULTS: {
//       const nextState = produce(state, (draftState) => {
//         const { searchedText, resList } = action.payload;
//         draftState.resultData = {
//           searchedList: resList,
//           searchedText
//         };
//       });
//       return nextState;
//     }
//     case SET_APPRAISR_FAQ: {
//       const nextState = produce(state, (draftState) => {
//         const { FAQTemplate } = action.payload;
//         draftState.FAQTemplate = FAQTemplate;
//       });
//       return nextState;
//     }
//     case SET_STATE_KEYS: {
//       const { stateList } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         const states: any[] = [];
//         stateList.forEach((state: any) => {
//           states.push({ name: state.key });
//         });
//         draftState.stateList = states;
//       });
//       return nextState;
//     }
//     case SET_TOORAK_PREFERRED_APPRAISERS: {
//       const nextState = produce(state, (draftState) => {
//         const { searchedText, resList } = action.payload;
//         draftState.toorakPreferredAppraisers = {
//           searchList: resList,
//           searchedText
//         };
//       });
//       return nextState;
//     }
//     case SET_PREFERRED_DISCLAIMER: {
//       const nextState = produce(state, (draftState) => {
//         const { preferredDisclaimer } = action.payload;
//         draftState.preferredDisclaimer = preferredDisclaimer;
//       });
//       return nextState;
//     }
//     case SET_REVIEW100_DISCLAIMER: {
//       const nextState = produce(state, (draftState) => {
//         const { review100Disclaimer } = action.payload;
//         draftState.review100Disclaimer = review100Disclaimer;
//       });
//       return nextState;
//     }
//     case RESET_STATE_FLAG: {
//       const nextState = produce(state, (draftState) => {
//         const { resetStateFlag } = action.payload;
//         draftState.resetStateFlag = resetStateFlag;
//       });
//       return nextState;
//     }
//     case UPDATE_PAGE_NUMBER: {
//       const nextState = produce(state, (draftState) => {
//         const { pageNumber } = action.payload;
//         draftState.pageNumber = pageNumber;
//       });
//       return nextState;
//     }
//     case UPDATE_TOTAL_APPRAISER_COUNT: {
//       const nextState = produce(state, (draftState) => {
//         const { totalCount } = action.payload;
//         draftState.totalAppraiser = totalCount;
//       });
//       return nextState;
//     }
//     default:
//       return state;
//   }
// };
