/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
import { Dialog, Grid } from "@mui/material";
import React from "react";
import { LoanStage } from "@toorak/tc-common-fe-sdk";

import { TKOPModalConatiner } from "./TKOPModalContainer";
import { modalHeaderStyles } from "./styles";

export const TKOPBox = (props: {
  open: boolean;
  partnerNames: any;
  onClose: Function;
  loanId: string;
  loanStage: LoanStage;
  loanRuleVersions: any;
  takeoutPartnerId: string;
  originatorId: string;
}) => {
  const {
    open,
    onClose,
    loanId,
    partnerNames,
    loanStage,
    loanRuleVersions,
    takeoutPartnerId,
    originatorId
  } = props;
  const classes = modalHeaderStyles();

  return <>
    {open && (
      <Dialog fullWidth open={open} sx={{ "& .MuiDialog-paper": { maxWidth: "1280px", width: "calc(100% - 64px)", maxHeight: "calc(100% - 64px)" } }}>
        <Grid container className={classes.dialogConainer}>
          <Grid item xs={12}>
            <TKOPModalConatiner
              onClose={onClose}
              loanId={loanId}
              loanStage={loanStage}
              partners={partnerNames}
              loanRuleVersions={loanRuleVersions}
              takeoutPartnerId={takeoutPartnerId}
              originatorId={originatorId}
            />
          </Grid>
        </Grid>
      </Dialog>
    )}
  </>;
};
