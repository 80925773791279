import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import {
  InquiryStatusEnum,
  InternalDDStatusEnum,
  ResponseTypeEnum,
  ApproverActionsEnum,
  iLPGoLiveDate
} from "../../../utils/constants";

export const dataLimit = 20;
export const getDateInFormatForActionsTab = (dateVal: any) => {
  return new Date(dateVal).toLocaleDateString(undefined, {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    year: "numeric",
    month: "short",
    day: "2-digit"
  });
};
export const isNullOrUndefined = (value: any) => {
  return (
    value === null ||
    value === undefined ||
    value === "null" ||
    value === "undefined"
  );
};
export const forCheckbox = (event: any, item: any, selectedInquiries: any) => {
  const tempInquiries = [...selectedInquiries];
  if (event.target.checked) {
    tempInquiries.push(item);
  } else {
    const inquiryIndex = tempInquiries.findIndex(
      (inq: any) =>
        // eslint-disable-next-line no-underscore-dangle
        inq._source.exception.exceptionId === item._source.exception.exceptionId
    );
    tempInquiries.splice(inquiryIndex, 1);
  }
  return tempInquiries;
};

export const handleForAllCheckbox = (
  event: any,
  selectedInquiries: any,
  listExceptions: any,
  action: string
) => {
  const tempInquiries = [...selectedInquiries];
  const checkExceptions = [...listExceptions];
  if (event.target.checked) {
    checkExceptions.forEach((item: any) => {
      if (
        !isConditionAvailable(item, action) &&
        !tempInquiries.some(
          (inquiry: any) =>
            // eslint-disable-next-line no-underscore-dangle
            inquiry._source.exception.exceptionId ===
            // eslint-disable-next-line no-underscore-dangle
            item._source.exception.exceptionId
        )
      ) {
        tempInquiries.push(item);
      }
    });
  } else {
    checkExceptions.forEach((item: any) => {
      const inquiryIndex = tempInquiries.findIndex(
        (inq: any) =>
          // eslint-disable-next-line no-underscore-dangle
          inq._source.exception.exceptionId ===
          // eslint-disable-next-line no-underscore-dangle
          item._source.exception.exceptionId
      );
      if (inquiryIndex !== -1) {
        tempInquiries.splice(inquiryIndex, 1);
      }
    });
  }
  return tempInquiries;
};
export const isConditionAvailable = (item: any, action: string) => {
  if (action === "approval") {
    // eslint-disable-next-line no-underscore-dangle
    return item._source.exception.response.some((val: any) => {
      return (
        val.responseType === ResponseTypeEnum.Approver &&
        // eslint-disable-next-line no-underscore-dangle
        item._source.exception.workflowState === InquiryStatusEnum.InProgress
      );
    });
  }
  if (action === "inquiry") {
    // eslint-disable-next-line no-underscore-dangle
    return item._source.exception.workflowState === InquiryStatusEnum.Completed;
  }
  return false;
};

export const ifThereIsOneOfType = (inquiry: any, type: string) => {
  return inquiry.response.some((val: any) => val.responseType === type);
};

export const conditionForChecked = (item: any, selectedInquiries: any) => {
  const checkInquiries = [...selectedInquiries];
  const inquiryIndex = checkInquiries.findIndex(
    (inq: any) =>
      // eslint-disable-next-line no-underscore-dangle
      inq._source.exception.exceptionId === item._source.exception.exceptionId
  );
  return inquiryIndex !== -1;
};

const inquiryTerms = {
  "exception.workflowState.raw": Object.values(InquiryStatusEnum)
};

export const defaultQuery = (forInquiry?: boolean) => {
  let accountIds: any;
  accountIds = localStorage.getItem("account_ids");
  accountIds = JSON.parse(accountIds);
  const placeHolderQuery: any = {
    query: {
      bool: {
        must: [
          {
            range: {
              "dueDiligenceDate.raw": {
                from: iLPGoLiveDate
              }
            }
          },
          {
            terms: {
              "originatorId.raw": [...accountIds]
            }
          },
          {
            terms: {
              "loanWorkflowState.raw": [
                InternalDDStatusEnum.ReviewInProgress,
                InternalDDStatusEnum.ApproverPending
              ]
            }
          },
          {
            terms: {
              "exception.type.raw": ["Inquiry", "inquiry"]
            }
          }
        ],
        // eslint-disable-next-line @typescript-eslint/camelcase
        must_not: [
          {
            term: {
              "exception.hold": true
            }
          },
          {
            terms: {
              "loan.loanState.raw": [
                LoanStatusEnum.DataEntry,
                LoanStatusEnum.ExcelError,
                LoanStatusEnum.Rejected,
                "Withdrawn"
              ]
            }
          }
        ]
      }
    }
  };
  if (forInquiry) {
    placeHolderQuery.query.bool.must.push({
      terms: {
        "actionStatus.raw": [ApproverActionsEnum.MakeitVisibletoOriginator]
      }
    });
  }
  return placeHolderQuery.query;
};

export const mutateQuery = (
  forInquiry: boolean,
  initialLimit: number,
  excessFilter?: any,
  searchedVal?: string
) => {
  let extraFilter: any;
  if (excessFilter) {
    JSON.parse(JSON.stringify(excessFilter));
  }
  if (forInquiry) {
    extraFilter = { ...inquiryTerms };
  }
  const searchQuery: any = {
    query: defaultQuery(forInquiry),
    _source: ["*"],
    sort: [
      {
        ageing: "desc"
      },
      "_score"
    ],
    size: dataLimit,
    from: initialLimit
  };
  const appendingArray: any = [];
  if (extraFilter) {
    Object.keys(extraFilter).forEach(key => {
      if (
        Object.prototype.hasOwnProperty.call(extraFilter, key) &&
        extraFilter[key]?.length > 0
      ) {
        appendingArray.push({
          terms: {
            [key]: extraFilter[key]
          }
        });
      }
    });
  }
  if (searchedVal) {
    appendingArray.push({
      // eslint-disable-next-line @typescript-eslint/camelcase
      multi_match: {
        analyzer: "standard",
        fields: ["*"],
        query: searchedVal,
        type: "phrase_prefix"
      }
    });
  }
  const mustArray = [...searchQuery.query.bool.must, ...appendingArray];
  searchQuery.query.bool.must = mustArray;
  return searchQuery;
};

export const uniqueArrayOfObject = (array: any, keyToBeUnique: string) => {
  // Filter by looking at the next objects if the key is present a second time
  return array.filter(
    (x: any, xi: any) =>
      !array
        .slice(xi + 1)
        .some((y: any) => y[keyToBeUnique] === x[keyToBeUnique])
  );
};
