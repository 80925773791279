import { LoanStage, publicClient } from "@toorak/tc-common-fe-sdk";
import { getConfig } from "../config/config";

export const updatePropertyByloanId = async (
  loanId: string,
  Stage: LoanStage,
  toorvalId: string
) => {
  const copyPropsUrl = `${
    getConfig().apiUrl
  }/loan_property/${loanId}/properties?stage=${Stage}`;
  const reqBody = [
    {
      loanPropertyId: 1,
      propertyinfo: {
        toorvalId: toorvalId
      }
    }
  ];
  try {
    await publicClient.patch(copyPropsUrl, reqBody);
    return Promise.resolve();
  } catch (e) {
    console.log(e);
    return Promise.reject(e);
  }
};
