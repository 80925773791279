/* eslint-disable no-nested-ternary */
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import { getCookie } from "../../../utils/cookies";
import { isLATUser } from "../../../utils/AccessManagement";
import { ObjectType } from "../../../masterView/common";
import {
  LoanAppraisalStatusToReadable,
  getIsToorvalFilter
} from "../../appraisal/util";

export function commonStatusFormatter(item: any) {
  if (
    getCookie("GUIDE") === "selected" &&
    (item?.loanStageRaw || "").toUpperCase() === "FES"
  ) {
    return statusFormatterFes(item);
  }
  if ((item?.loanStageRaw || "").toUpperCase() === "TOORVAL")
    return statusFormatterAppraisal(item);
  if (!item?.status) return;
  return statusFormatterNormal(item);
}

export const statusFormatterNormal = (item: any) => {
  return item.status?.toUpperCase() === "SUBMIT" &&
    (item.loanStageRaw.toUpperCase() === "FES" ||
      getCookie("GUIDE") === "selected")
    ? "PRELIMINARY SIZING"
    : item.status.toUpperCase() === "EXCEL ERROR" ||
      item.status.toUpperCase() === "DATA ENTRY" ||
      item.status.toUpperCase() === "SUBMIT"
      ? "DATA ENTRY"
      : item.status.toUpperCase() === "PURCHASED" && !isLATUser()
        ? "FUNDED"
        : item.status.toUpperCase() === "DUE DILIGENCE REVIEW"
          ? "REVIEW IN PROGRESS"
          : item.status.toUpperCase();
};

export const statusFormatterAppraisal = (item: any) => {
  if (item.status === "submit") return "UNDER REVIEW";
  return statusFormatterNormal(item);
};
export const statusFormatterFes = (item: any) => {
  return item.status?.toUpperCase() === "EXCEL ERROR" ||
    item.status?.toUpperCase() === "DATA ENTRY"
    ? "DATA ENTRY"
    : item.status?.toUpperCase() === "INITIAL REVIEW" ||
      item.status?.toUpperCase() === "FINAL REVIEW" ||
      item.status?.toUpperCase() === "APPROVED" ||
      item.status?.toUpperCase() === "SUBMIT"
      ? "PRELIMINARY SIZING"
      : item.status?.toUpperCase() === "PURCHASED" && !isLATUser()
        ? "FUNDED"
        : item.status?.toUpperCase();
};

const order: { [key: string]: number } = {
  SFR: 0,
  "2-4 Unit": 1,
  "5+ Unit Multifamily": 2,
  Condo: 3,
  Townhome: 4,
  "Mixed Use": 5,
  MF: 6,
  Family: 7,
  "98": 8
};

export function reorderPropertyType(items: {
  [key: number]: { [key: string]: string };
}) {
  const newOrder: { [key: string]: string } = {};
  for (let i = 0; i < Object.keys(items).length; i++) {
    newOrder[i] = items[i].key;
  }
  const sortedObject = Object.entries(newOrder)
    .sort(([, a], [, b]) => order[a] - order[b])
    .reduce((r, [k, v], i) => ({ ...r, [i]: v }), {});
  return sortedObject;
}

export function reorderOriginator(items: {
  [key: number]: { [key: string]: string };
}) {
  const newOrder: { [key: string]: string } = {};
  for (let i = 0; i < Object.keys(items).length; i++) {
    newOrder[i] = items[i].key;
  }
  // check local
  // newOrder = {0:"CDB",1:"ABC"};
  const values = Object.values(newOrder);
  const sortedAscendingValues = values.sort();
  const sortedObject = { ...sortedAscendingValues };
  return sortedObject;
}

export function getLoanStatusFilterOptions(
  data: any[] = [],
  loanStagesSelected: string,
  customMapping?: ObjectType
) {
  let dropdownData: any[] = [];
  let loanStatusLabelMapping: ObjectType = {};

  if (loanStagesSelected === "Loans") {
    loanStatusLabelMapping = {
      submit: "Data Entry",
      "Due Diligence Review": "Review in Progress",
      ...customMapping
    };
  } else {
    loanStatusLabelMapping = {
      submit: "Preliminary Sizing",
      "Due Diligence Review": "Review in Progress",
      ...customMapping
    };
  }
  const isToorvalFilter = getIsToorvalFilter();
  if (isToorvalFilter || loanStagesSelected === "Toorval Appraisal") {
    loanStatusLabelMapping = {
      ...LoanAppraisalStatusToReadable,
      submit: "Under Review"
    };
  }

  data.forEach((itm: any) => {
    // if (
    //   // adding submit as an option if all types of loans are shown
    //   itm.key === "submit" &&
    //   (loanStagesSelected === "See both" ||
    //     loanStagesSelected === "On-Hold Loans")
    // ) {
    //   dropdownData.push({
    //     ...itm,
    //     key: "Submit",
    //     viewText: "Submit"
    //   });
    // }
    if (
      loanStagesSelected === "Loans" &&
      itm.key === LoanStatusEnum.ApprovedConditionally
    )
      return;
    if (isToorvalFilter && itm.key === "submit") return;
    if (isToorvalFilter && !LoanAppraisalStatusToReadable[itm.key]) return;
    dropdownData.push({
      ...itm,
      viewText: loanStatusLabelMapping?.[itm.key] ?? itm.key
    });
  });

  /* adding one entry of Data entry instead of Data Entry, Excel Error, Excel Success - Start */
  const excludedData = dropdownData.filter((it: any) => {
    return ["Data Entry", "Excel Error", "Excel Success"].includes(it.viewText);
  });
  dropdownData = dropdownData.filter((it: any) => {
    return !["Data Entry", "Excel Error", "Excel Success"].includes(
      it.viewText
    );
  });
  if (excludedData.length > 0) {
    dropdownData = [...dropdownData, { ...excludedData[0], key: "Data Entry" }];
  }
  /* adding one entry of Data entry instead of Data Entry, Excel Error, Excel Success - End */
  return dropdownData;
}

export const transformStatusFilterSelection = (
  isColFiltered: any[],
  filterselections: any[]
) => {
  const isDataEntryData = (element: string) => element === "Data Entry";
  const dataEntryFilterIndex = isColFiltered.findIndex(isDataEntryData);
  let localData = [...filterselections];
  if (dataEntryFilterIndex > -1) {
    localData.splice(
      dataEntryFilterIndex,
      1,
      "Data Entry",
      "Excel Error",
      "Excel Success",
      "Submit"
    );
  } else {
    localData = isColFiltered.filter(
      (it: string) =>
        it !== "Excel Error" && it !== "Excel Success" && it !== "Submit"
    );
  }
  return localData;
};
