import { InquiryStatusEnum, ResponseTypeEnum } from "../../utils/constants";
import { dataLimit } from "../../ilp/actionDashboard/common/utils";

import { getCookie, setCookie } from "../../utils/cookies";
import { ObjectType } from "../../masterView/common";

export const ilpColumnsTabMap = {
  all: 0,
  docReview: 1,
  sdd: 2,
  budget: 3
};

export interface ILPStore {
  loading: boolean;
  searchValue: string;
  internalUsers: any[];
  internalGroups: any[];
  grade: string;
  tagInfo: any;
  fetchedExceptions: any[];
  initialLimit: number;
  totalFetchedExceptions: number;
  filterOptions: any;
  selectedFilters: any;
  internalTags: ObjectType[];
  internalTagMap: ObjectType;
  viewType: any;
  csTableData: any;
  irQCVMAdminMap: any;
  qcVMAdminMap: any;
  roleObj: any;
  refreshed: boolean;
  ilpColumnTab: ilpColumnTabType;
}

const initialState: ILPStore = {
  loading: false,
  searchValue: "",
  internalUsers: [],
  internalGroups: [],
  grade: "",
  tagInfo: [],
  fetchedExceptions: [],
  initialLimit: 0,
  totalFetchedExceptions: 0,
  filterOptions: {},
  selectedFilters: {},
  internalTags: [],
  internalTagMap: {},
  viewType: "details",
  csTableData: {},
  irQCVMAdminMap: {},
  qcVMAdminMap: {},
  roleObj: {},
  refreshed: false,
  ilpColumnTab: (getCookie("ilpColumnTab") as ilpColumnTabType) || "all"
};

import { createSlice } from "@reduxjs/toolkit";
import { ilpColumnTabType } from "./ILP.action";

const ilpSlice = createSlice({
  name: "ilp",
  initialState,
  reducers: {
    setSearchValueRedux: (state, action) => {
      state.searchValue = action.payload;
    },
    internalUsersSuccess: (state, action) => {
      state.internalUsers = action.payload;
    },
    internalGroupsSuccess: (state, action) => {
      state.internalGroups = action.payload;
    },
    setGradeRedux: (state, action) => {
      state.grade = action.payload;
    },
    setTagsInfo: (state, action) => {
      state.tagInfo = action.payload;
    },
    fetchExceptionsLoading: (state) => {
      state.loading = true;
    },
    fetchExceptions: (state, action) => {
      state.loading = false;
      const payload = action.payload;
      payload.forEach((exception: { loading: boolean }) => {
        exception.loading = false;
      });
      state.fetchedExceptions = [...state.fetchedExceptions, ...payload];
      state.initialLimit = state.initialLimit + dataLimit;
    },
    fetchExceptionsTotalCount: (state, action) => {
      state.loading = false;
      state.totalFetchedExceptions = action.payload;
    },
    fetchExceptionsError: (state) => {
      state.loading = false;
    },
    filterOptionData: (state, action) => {
      const buckets = action.payload?.response?.aggregations;
      state.filterOptions = buckets;
    },
    clearExceptionData: (state) => {
      state.fetchedExceptions = [];
      state.totalFetchedExceptions = 0;
      state.initialLimit = 0;
    },
    filtersUpdateSuccess: (state, action) => {
      const selections = action.payload;
      state.selectedFilters = selections;
    },
    fetchUpdatedData: (state) => {
      state.selectedFilters = { ...state.selectedFilters };
    },
    updateActionLoadingStatus: (state, action) => {
      const { exceptionId, loadingStatus } = action.payload;
      const exceptionIndex = state.fetchedExceptions.findIndex(
        (item) => item._source.exception.exceptionId === exceptionId
      );
      state.fetchedExceptions[exceptionIndex].loading = loadingStatus;
    },
    deleteActionFromActionTab: (state, action) => {
      const { exceptionId } = action.payload;
      const exceptionIndex = state.fetchedExceptions.findIndex(
        (item) => item._source.exception.exceptionId === exceptionId
      );
      let response =
        state.fetchedExceptions[exceptionIndex]._source.exception.response;
      state.fetchedExceptions[exceptionIndex]._source.exception.response =
        response.filter(
          (resp: { responseType: ResponseTypeEnum }) =>
            resp.responseType !== ResponseTypeEnum.Approver
        );
    },
    setInternalTags: (state, action) => {
      const { tags } = action.payload;
      const tagMap: { [key: string]: any } = {};
      const internalTags = tags
        .map((tag: ObjectType) => {
          if (tag.tag_config?.property?.selectionMode) {
            tag.tag_config.property.selectionMode =
              typeof tag.tag_config.property.selectionMode === "string"
                ? JSON.parse(
                    tag.tag_config.property.selectionMode.replace(
                      /\w+/g,
                      '"$&"'
                    )
                  )
                : tag.tag_config.property.selectionMode;
          }
          if (tag.tag_config?.user?.selectionMode) {
            tag.tag_config.user.selectionMode =
              typeof tag.tag_config.user.selectionMode === "string"
                ? JSON.parse(
                    tag.tag_config.user.selectionMode.replace(/\w+/g, '"$&"')
                  )
                : tag.tag_config.user.selectionMode;
          }

          return {
            code: tag.tag_code,
            description: tag.tag_description,
            name: tag.tag_name,
            tagCategory: null,
            tagType: tag.tag_type,
            id: tag.tag_internal_id,
            isMandatory: "true",
            config: tag.tag_config
          };
        })
        .sort((a: { name: string }, b: { name: any }) => {
          return a?.name?.localeCompare(b?.name);
        });
      internalTags.forEach((tag: ObjectType) => {
        tagMap[tag.code] = tag;
      });
      state.internalTags = internalTags;
      state.internalTagMap = tagMap;
    },
    completeInquiryStatusActionstab: (state, action) => {
      const payload = action.payload;
      payload.forEach((obj: { exceptionId: any }) => {
        const exceptionIndex = state.fetchedExceptions.findIndex(
          (inq) => inq._source.exception.exceptionId === obj.exceptionId
        );
        if (exceptionIndex !== -1) {
          state.fetchedExceptions[
            exceptionIndex
          ]._source.exception.workflowState = InquiryStatusEnum.Completed;
        }
      });
    },
    setViewType: (state, action) => {
      state.viewType = action.payload;
    },
    setTableData: (state, action) => {
      const { tableData } = action.payload;
      if (tableData?.documents?.length) {
        const tempArray: any = [];
        tableData.documents.forEach((element: any) => {
          if (element.identifierName.toLowerCase().includes("loan")) {
            element.tags.forEach((ele: any) => {
              const obj = {
                loanId: element.identifierValue,
                docId: element.id,
                fileName: element.name,
                autoTagName: ele?.tag?.name || "",
                autoTagCode: ele?.tag?.code || "",
                prevTagCode: ele?.tag?.code || "",
                primaryIdentifierName: element.primaryIdentifierName,
                primaryIdentifierValue: element.primaryIdentifierValue,
                secondaryIdentifierName: element.secondaryIdentifierName,
                secondaryIdentifierValue: element.secondaryIdentifierValue,
                confidenceScore: ele.confidenceScore,
                manualTagName: "",
                manualTagCode: "",
                isOpen: false,
                disabled: false,
                loading: false
              };
              tempArray.push(obj);
            });
          }
        });
        const tempObj = {
          tableData: tempArray,
          totalDataCount: tableData.totalRecords,
          maxOffset: tableData.maxOffset
        };
        state.csTableData = tempObj;
      } else {
        state.csTableData = {};
      }
    },
    setIrQcVmAdminList: (state, action) => {
      state.irQCVMAdminMap = action.payload;
    },
    setQcList: (state, action) => {
      state.qcVMAdminMap = action.payload;
    },
    setRoleObjForSdd: (state, action) => {
      state.roleObj = action.payload;
    },
    updateIRQCVMAdminMap: (state, action) => {
      state.irQCVMAdminMap = JSON.parse(JSON.stringify(action.payload));
    },
    toggleRefresh: (state) => {
      state.refreshed = !state.refreshed;
    },
    updateILPColumnTab: (state, action) => {
      const key = Object.keys(ilpColumnsTabMap).find(
        (key) => ilpColumnsTabMap[key as ilpColumnTabType] === action.payload
      );
      state.ilpColumnTab = key as ilpColumnTabType;
      setCookie("ilpColumnTab", key);
    }
  }
});

export const {
  setSearchValueRedux,
  internalUsersSuccess,
  internalGroupsSuccess,
  setGradeRedux,
  setTagsInfo,
  fetchExceptionsLoading,
  fetchExceptions,
  fetchExceptionsTotalCount,
  fetchExceptionsError,
  filterOptionData,
  clearExceptionData,
  filtersUpdateSuccess,
  fetchUpdatedData,
  updateActionLoadingStatus,
  deleteActionFromActionTab,
  setInternalTags,
  completeInquiryStatusActionstab,
  setViewType,
  setTableData,
  setIrQcVmAdminList,
  setQcList,
  setRoleObjForSdd,
  updateIRQCVMAdminMap,
  toggleRefresh,
  updateILPColumnTab
} = ilpSlice.actions;

export const ILPReducer = ilpSlice.reducer;

// export const ILPReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case SET_SEARCH_VALUE: {
//       const nextState = produce(state, draftState => {
//         draftState.searchValue = action.payload;
//       });
//       return nextState;
//     }
//     case INTERNAL_USERS_SUCCESS: {
//       const nextState = produce(state, draftState => {
//         draftState.internalUsers = action.payload;
//       });
//       return nextState;
//     }

//     case INTERNAL_GROUPS_SUCCESS: {
//       const nextState = produce(state, draftState => {
//         draftState.internalGroups = action.payload;
//       });
//       return nextState;
//     }
//     case SET_GRADE: {
//       const nextState = produce(state, draftState => {
//         draftState.grade = action.payload;
//       });
//       return nextState;
//     }
//     case SET_TAGS_INFO: {
//       const nextState = produce(state, draftState => {
//         draftState.tagInfo = action.payload;
//       });
//       return nextState;
//     }

//     case FETCH_EXCEPTIONS_LOADING: {
//       const nextState = produce(state, draftState => {
//         draftState.loading = true;
//       });
//       return nextState;
//     }
//     case FETCH_EXCEPTIONS: {
//       const nextState = produce(state, draftState => {
//         draftState.loading = false;
//         const payload = action.payload;
//         payload.forEach((exception: any) => {
//           exception.loading = false;
//         });
//         draftState.fetchedExceptions = [...state.fetchedExceptions, ...payload];
//         draftState.initialLimit = state.initialLimit + dataLimit;
//       });
//       return nextState;
//     }
//     case FETCH_EXCEPTIONS_TOTAL_COUNT: {
//       const nextState = produce(state, draftState => {
//         draftState.loading = false;
//         draftState.totalFetchedExceptions = action.payload;
//       });
//       return nextState;
//     }
//     case FETCH_EXCEPTIONS_ERROR: {
//       const nextState = produce(state, draftState => {
//         draftState.loading = false;
//       });
//       return nextState;
//     }
//     case FILTER_OPTION_DATA: {
//       const buckets = action.payload?.response?.aggregations;
//       const nextState = produce(state, draftState => {
//         draftState.filterOptions = buckets;
//       });
//       return nextState;
//     }
//     case CLEAR_DATA: {
//       const nextState = produce(state, draftState => {
//         draftState.fetchedExceptions = [];
//         draftState.totalFetchedExceptions = 0;
//         draftState.initialLimit = 0;
//       });
//       return nextState;
//     }
//     case FILTERS_UPDATE_SUCCESS: {
//       const selections = action.payload;
//       const nextState = produce(state, draftState => {
//         draftState.selectedFilters = selections;
//       });
//       return nextState;
//     }
//     case FETCH_UPDATED_DATA: {
//       const nextState = produce(state, draftState => {
//         draftState.selectedFilters = { ...draftState.selectedFilters };
//       });
//       return nextState;
//     }
//     case UPDATE_ACTION_LOADING_STATUS: {
//       const { exceptionId, loadingStatus } = action.payload;
//       const nextState = produce(state, draftState => {
//         const exceptionIndex = draftState.fetchedExceptions.findIndex(
//           (item?: any) => item._source.exception.exceptionId === exceptionId
//         );
//         draftState.fetchedExceptions[exceptionIndex].loading = loadingStatus;
//       });
//       return nextState;
//     }
//     case DELETE_ACTION_FROM_ACTION_TAB: {
//       const { exceptionId } = action.payload;
//       const nextState = produce(state, draftState => {
//         const exceptionIndex = draftState.fetchedExceptions.findIndex(
//           (item?: any) => item._source.exception.exceptionId === exceptionId
//         );
//         let response =
//           draftState.fetchedExceptions[exceptionIndex]._source.exception
//             .response;
//         draftState.fetchedExceptions[
//           exceptionIndex
//         ]._source.exception.response = response.filter(
//           (resp: any) => resp.responseType !== ResponseTypeEnum.Approver
//         );
//       });
//       return nextState;
//     }
// case SET_INTERNAL_TAGS: {
//   const { tags } = action.payload;
//   const tagMap: ObjectType = {};
//   const internalTags: ObjectType[] = tags
//     .map((tag: ObjectType) => {
//       if (tag.tag_config?.property?.selectionMode) {
//         tag.tag_config.property.selectionMode =
//           typeof tag.tag_config.property.selectionMode === "string"
//             ? JSON.parse(
//               tag.tag_config.property.selectionMode.replace(/\w+/g, '"$&"')
//             )
//             : tag.tag_config.property.selectionMode;
//       }
//       if (tag.tag_config?.user?.selectionMode) {
//         tag.tag_config.user.selectionMode =
//           typeof tag.tag_config.user.selectionMode === "string"
//             ? JSON.parse(
//               tag.tag_config.user.selectionMode.replace(/\w+/g, '"$&"')
//             )
//             : tag.tag_config.user.selectionMode;
//       }

//       return {
//         code: tag.tag_code,
//         description: tag.tag_description,
//         name: tag.tag_name,
//         tagCategory: null,
//         tagType: tag.tag_type,
//         id: tag.tag_internal_id,
//         isMandatory: "true",
//         config: tag.tag_config
//       };
//     })
//     .sort((a: any, b: any) => {
//       return a?.name?.localeCompare(b?.name);
//     });
//   internalTags.forEach((tag: ObjectType) => {
//     tagMap[tag.code] = tag;
//   });
//   const nextState = produce(state, draftState => {
//     draftState.internalTags = internalTags;
//     draftState.internalTagMap = tagMap;
//   });
//   return nextState;
// }
//     case COMPLETE_INQUIRY_STATUS_ACTIONSTAB: {
//       const payload = action.payload;
//       const nextState = produce(state, draftState => {
//         payload.forEach((obj: any) => {
//           const exceptionIndex = draftState.fetchedExceptions.findIndex(
//             (inq?: any) => inq._source.exception.exceptionId === obj.exceptionId
//           );
//           if (exceptionIndex !== -1) {
//             draftState.fetchedExceptions[
//               exceptionIndex
//             ]._source.exception.workflowState = InquiryStatusEnum.Completed;
//           }
//         });
//       });
//       return nextState;
//     }

//     case SET_VIEW_TYPE: {
//       const nextState = produce(state, draftState => {
//         draftState.viewType = action.payload;
//       });
//       return nextState;
//     }

//     case SET_TABLE_DATA: {
//       const { tableData } = action.payload;
//       const nextState = produce(state, draftState => {
//         if (tableData?.documents?.length) {
//           const tempArray: any = [];
//           tableData.documents.forEach((element: any) => {
//             if (element.identifierName.toLowerCase().includes("loan")) {
//               element.tags.forEach((ele: any) => {
//                 const obj = {
//                   loanId: element.identifierValue,
//                   docId: element.id,
//                   fileName: element.name,
//                   autoTagName: ele?.tag?.name || "",
//                   autoTagCode: ele?.tag?.code || "",
//                   prevTagCode: ele?.tag?.code || "",
//                   primaryIdentifierName: element.primaryIdentifierName,
//                   primaryIdentifierValue: element.primaryIdentifierValue,
//                   secondaryIdentifierName: element.secondaryIdentifierName,
//                   secondaryIdentifierValue: element.secondaryIdentifierValue,
//                   confidenceScore: ele.confidenceScore,
//                   manualTagName: "",
//                   manualTagCode: "",
//                   isOpen: false,
//                   disabled: false,
//                   loading: false
//                 };
//                 tempArray.push(obj);
//               });
//             }
//           });
//           const tempObj = {
//             tableData: tempArray,
//             totalDataCount: tableData.totalRecords,
//             maxOffset: tableData.maxOffset
//           };
//           draftState.csTableData = tempObj;
//         } else {
//           draftState.csTableData = {};
//         }
//       });
//       return nextState;
//     }

//     case SET_IR_QC_VM_ADMIN_LIST: {
//       const nextState = produce(state, draftState => {
//         draftState.irQCVMAdminMap = action.payload;
//       });
//       return nextState;
//     }

//     case SET_QC_LIST: {
//       const nextState = produce(state, draftState => {
//         draftState.qcVMAdminMap = action.payload;
//       });
//       return nextState;
//     }

//     case SET_ROLE_OBJ_FOR_SDD: {
//       const nextState = produce(state, draftState => {
//         draftState.roleObj = action.payload;
//       });
//       return nextState;
//     }

//     case SYNC_IR_QC_VM_ADMIN_MAP: {
//       const nextState = produce(state, draftState => {
//         draftState.irQCVMAdminMap = JSON.parse(JSON.stringify(action.payload));
//       });
//       return nextState;
//     }

//     case TOGGLE_REFRESH: {
//       const nextState = produce(state, draftState => {
//         draftState.refreshed = !draftState.refreshed;
//       });
//       return nextState;
//     }

//     case UPDATE_ILP_COLUMN_TAB: {
//       const nextState = produce(state, draftState => {
//         const key = Object.keys(ilpColumnsTabMap).find(
//           (key: string) =>
//             ilpColumnsTabMap[key as ilpColumnTabType] ===
//             (action.payload as number)
//         );
//         draftState.ilpColumnTab = key as ilpColumnTabType;
//         setCookie("ilpColumnTab", key);
//       });
//       return nextState;
//     }

//     default:
//       return state;
//   }
// };
