/* eslint-disable @typescript-eslint/camelcase */
export enum fieldSections {
  loanConfig = "loanConfig",
  loanEconomics = "loanEconomics",
  firstLoss = "firstLoss",
  toorakProceedsTest = "toorakProceedsTest",
  loanPricing = "loanPricing",
  toorakInterests = "toorakInterests",
  settlementResults = "settlementResults"
}

export const ssNonRuleField: any = {
  warehouseLenderPartyId: true,
  custodianPartyId: true,
  toorakProduct: true,
  drawAmount: true,
  drawDate: true,
  miscFees: true
};

export const ssLoanFields: any = {
  servicerPartyId: fieldSections.loanConfig,
  sellerPartyId: fieldSections.loanConfig,
  cutOffDateLoanAmount: fieldSections.loanEconomics,
  interestRateAsOfCutOffDate: fieldSections.loanEconomics,
  financedInterestReserve: fieldSections.loanEconomics
};

export const ssRulesFields: any = {
  accrualDate: fieldSections.settlementResults,
  nextPaymentDueDate: fieldSections.settlementResults,
  lessPurchaserCostAllocation: fieldSections.toorakInterests,
  pricing: fieldSections.toorakInterests,
  servicingFeePerMonth: fieldSections.toorakInterests,
  locationAdjustment: fieldSections.toorakInterests,
  toorakYield: fieldSections.toorakInterests,
  finalToorakYield_SFR: fieldSections.toorakInterests,
  finalToorakYield: fieldSections.toorakInterests,
  regularToorakYield: fieldSections.toorakProceedsTest,
  standardFirstLossAdjustment: fieldSections.firstLoss,
  regularCreditReserveHoldback: fieldSections.firstLoss,
  additionalCreditReserveHoldback: fieldSections.firstLoss,
  finalToorakPrice: fieldSections.loanPricing
};

export const ssRulesFieldsMask: any = {
  regularCreditReserveHoldback: "sellerCreditReserveHoldback"
};

export const onlyLoanFields: any = {
  servicerPartyId: true,
  regularCreditReserveHoldback: true,
  additionalCreditReserveHoldback: true,
  sellerPartyId: true,
  finalToorakPrice: true,
  standardFirstLossAdjustment: true,
  locationAdjustment: true,
  interestRateAsOfCutOffDate: true,
  maxLoanProceedsBeforeFirstLoss: true,
  financedInterestReserve: true,
  pricing: true,
  accrualDate: true, // mapping in loanResult
  nextPaymentDueDate: true, // mapping in loanResult,
  finalToorakYield_SFR: true // mapping in loanResult
};

export interface BundleSigneeInfo {
  op: "add" | "replace";
  signeePartyId: string;
  status: "NOT_COMPLETED" | "COMPLETED";
}
export interface BundleSellerMappingReq {
  op: "add" | "replace";
  sellerPartyId: string;
  signingType: "ALL_SIGNEE" | "ANY_SIGNEE";
  bundleSellerSigneeMapping: BundleSigneeInfo[];
  termsAndConditions?: string;
}

export interface SignerDetails {
  email: string;
  name: string;
  title: string;
  signerPartyId: string;
  recipientId?: string;
  status?: string;
}
export interface SellerSignerData {
  sellerName: string;
  sellerPartyId: string;
  signingType: "ALL_SIGNEE" | "ANY_SIGNEE" | "";
  signerDetails: SignerDetails[];
  envelopeId?: string;
  resendRequest?: boolean;
}
export interface DocuSignReqBody {
  originatorName: string;
  bundleId: string;
  uniqueId: string;
  awsAccount: string;
  createdBy: string;
  closingDate: string;
  loanType: string;
  signingMethod: "REMOTE" | "EMBEDDED";
  envelopeId?: string;
  sellerName?: string;
  sellerPartyId?: string;
  embeddedSignerPartyId?: string;
  signerName?: string;
  signerEmail?: string;
  title?: string;
  sellerSignerData?: SellerSignerData[];
  embeddedSellerSignerData?: {
    sellerName: string;
    sellerPartyId: string;
    signingType: "ALL_SIGNEE" | "ANY_SIGNEE";
    signerDetails: SignerDetails[];
  };
}

export interface MLPAPayload {
  sellerPartyId: string;
  mlpaDate: string;
  comment: string;
}
