import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  TableContainer,
  TextField,
  Typography
} from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmFormModal, WarningModal } from "@toorak/tc-common-fe-sdk";
import { Header } from "../../../create-loan/home/Header";
import { MasterViewStyles } from "../../../masterView/styles";
import CustomTable from "../../../masterView/CustomTable";
import {
  addProvision,
  deleteProvision,
  getProvisionHistory,
  getProvisionsData,
  updateElasticSearchResponse,
  updateProvision
  // updateTableSearchOptions,
  // updateTableSelectedFilters
  // updateTableSortOptions
} from "../../../stores/drawRequest/drawRequest.action";
import { RootState } from "../../../stores/rootReducer";
import { setTabView } from "../../../stores/SettlementAndPurchase/SettlementAndPurchase.reducer";
import { useDrawsStyles } from "../Draws.styles";
import { Autocomplete } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  hideLoader,
  showLoader
} from "../../../stores/loaderAndException/loaderAndException.action";
import { DrawRequestStore } from "../../../stores/drawRequest/drawRequest.interface";
import { provisionColumns } from "./provisionColumns";
import { DrawsButtonGroup } from "../DrawsButtonGroup";
import { getCookie } from "../../../utils/cookies";
import { getStoredFilter, ListViewPagination } from "../../ListViewPagination";
import { isDrawAMRole } from "../../../utils/AccessManagement";
import { useNavigate } from "react-router-dom";
import {
  updateTableFilters,
  updateTableSearchOptions,
  updateTableSortOptions
} from "../../../stores/drawRequest/drawRequest.reducer";

const ProvisionView = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [tableData, setTableData] = useState<any>([]);
  const [page, setPage] = useState(1);
  // const [clearFilterClicked, setClearFilterClicked] = useState<number>(0);

  const [filtersOptions, setFiltersOptions] = useState<any>({});
  const classes = MasterViewStyles();
  const navigate = useNavigate();
  const drawClasses = useDrawsStyles();

  const {
    provisionData,
    totalProvisionsCount,
    tableSearchConfig,
    tableFilterConfig,
    tableSortConfig
  } = useSelector<RootState, DrawRequestStore>(
    (state) => state.drawRequestStore
  );

  const dispatch = useDispatch();
  const [options, setOptions] = useState<any[]>([]);
  const [openFormModel, setOpenFormModel] = useState<boolean>(false);
  const [loanId, setLoanId] = useState<string>("");
  const [popUpOpen, setPopUpOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState({
    header: "",
    primaryBtnName: ""
  });

  const [provisionObj, setProvisionObj] = useState({
    primaryLoanId: "",
    toorakLoanId: "",
    provisionId: ""
  });

  const [provisionValue, setProvisionValue] = useState<string>("");
  const userId = getCookie("person_id") || "userId";

  useEffect(() => {
    getPaginatedProvisionsData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let clonedArray = JSON.parse(JSON.stringify(provisionData));
    if (tableSearchConfig) {
      for (const key in tableSearchConfig) {
        if (
          tableSearchConfig.hasOwnProperty(key) &&
          tableSearchConfig[key] &&
          tableSearchConfig[key].trim() !== ""
        ) {
          clonedArray = clonedArray.filter((item: any) =>
            item[key]
              ?.toLowerCase()
              .includes(tableSearchConfig[key]?.toLowerCase())
          );
        }
      }
    }
    if (tableSortConfig) {
      const key = Object.keys(tableSortConfig)[0];
      const type = tableSortConfig[key];
      if (type === "" || type === "DESC") {
        clonedArray.sort(
          (a: any, b: any) => b[key] && b[key].localeCompare(a[key])
        );
      } else {
        clonedArray.sort(
          (a: any, b: any) => a[key] && a[key].localeCompare(b[key])
        );
      }
    }
    setTableData(clonedArray);
    //eslint-disable-next-line
  }, [tableFilterConfig, tableSearchConfig, provisionData, tableSortConfig]);

  function getPaginatedProvisionsData() {
    const defaultFilter = getDefaultFilter();
    const pageSize = defaultFilter?.pageLimit || 25;
    const pageNo = defaultFilter?.page || 1;
    setPage(pageNo);
    // setPageLimit(pageSize);
    dispatch(getProvisionsData(defaultFilter, false, pageNo, pageSize));
  }

  function saveFilters(
    updatedConfig: any | undefined,
    configType: string | undefined,
    drawProvisionFilterSetting?: any | undefined,
    getUpdatedData?: boolean
  ) {
    const currentFilters = JSON.parse(localStorage.getItem("filters") || "{}");
    if (!currentFilters[userId]) {
      currentFilters[userId] = {
        drawProvisionFilterSetting: {}
      };
    }
    const tempDrawFilterSetting =
      currentFilters?.[userId]?.drawProvisionFilterSetting ?? {};
    let updatedDrawFilterSetting = drawProvisionFilterSetting;
    if (!drawProvisionFilterSetting && configType) {
      updatedDrawFilterSetting = {
        ...tempDrawFilterSetting,
        [configType]: updatedConfig,
        page: 1
      };
    }

    currentFilters[userId] = {
      ...currentFilters[userId],
      drawProvisionFilterSetting: updatedDrawFilterSetting
    };
    localStorage.setItem("filters", JSON.stringify(currentFilters));
    if (getUpdatedData) {
      dispatch(
        getProvisionsData(
          currentFilters[userId].drawProvisionFilterSetting,
          false,
          updatedDrawFilterSetting.page,
          updatedDrawFilterSetting.pageLimit
        )
      );
    }
  }

  const getDefaultFilter = () => {
    // let tempDrawFilterSetting: any = getCookie("drawProvisionFilterSetting");

    let tempDrawFilterSetting = getStoredFilter(
      userId,
      "drawProvisionFilterSetting"
    );

    const searchConfig = tempDrawFilterSetting?.tableSearchConfig || {};
    const sortConfig = tempDrawFilterSetting?.tableSortConfig || {};
    const pageLimitTemp = tempDrawFilterSetting?.pageLimit || 25;
    const pageNo = tempDrawFilterSetting?.page || 1;
    tempDrawFilterSetting = {
      tableSearchConfig: searchConfig,
      tableSortConfig: sortConfig,
      pageLimit: pageLimitTemp,
      page: pageNo
    };

    // dispatch(
    //   updateTableSelectedFilters(tempDrawFilterSetting.tableFilterConfig)
    // );
    if (tempDrawFilterSetting.tableSearchConfig) {
      dispatch(
        updateTableSearchOptions(tempDrawFilterSetting.tableSearchConfig)
      );
    }
    if (tempDrawFilterSetting.tableSortConfig) {
      dispatch(updateTableSortOptions(tempDrawFilterSetting.tableSortConfig));
    }
    saveFilters(undefined, undefined, tempDrawFilterSetting);
    return tempDrawFilterSetting;
  };

  const callback = (data: any) => {
    setOptions(data);
  };

  const boxColumns = useMemo(() => {
    setTableData(provisionData);
    return provisionColumns();
    // eslint-disable-next-line
  }, [provisionData]);

  const { tableCols } = useMemo(() => {
    const cols = boxColumns.filter(
      (it: any) => !it.hidden || it.id === "checkboxId"
    );
    return { tableCols: cols };
  }, [boxColumns]);

  const handleSearchChange = useCallback(
    (config: { [key: string]: string }) => {
      const updatedSearchConfig = { ...tableSearchConfig, ...config };
      dispatch(updateTableSearchOptions(updatedSearchConfig));
      setPage(1);
      saveFilters(updatedSearchConfig, "tableSearchConfig", undefined, true);
    },
    // eslint-disable-next-line
    [tableSearchConfig, dispatch]
  );

  const onSort = useCallback(
    (config: { [key: string]: any[] }) => {
      dispatch(updateTableSortOptions(config));
      saveFilters(config, "tableSortConfig", undefined, true);
      setPage(1);
    },
    // eslint-disable-next-line
    [dispatch]
  );

  const handleFilterToggle = useCallback(
    (columnName: string) => {
      let clonedArray = JSON.parse(JSON.stringify(provisionData));
      let distinctValue: any = [];
      clonedArray.forEach((element: any) => {
        if (
          element[columnName] &&
          distinctValue.indexOf(element[columnName]) === -1
        ) {
          distinctValue.push(element[columnName]);
        }
      });
      let filterObj: any = [];
      distinctValue.forEach((element: any) => {
        filterObj.push({ key: element, doc_count: Math.random() });
      });
      setFiltersOptions({
        ...filtersOptions,
        [columnName]: filterObj
      });
    },
    // eslint-disable-next-line
    [filtersOptions]
  );

  //New Change
  useEffect(() => {
    let clonedArray = JSON.parse(JSON.stringify(provisionData));
    if (tableSearchConfig) {
      for (const key in tableSearchConfig) {
        if (
          tableSearchConfig.hasOwnProperty(key) &&
          tableSearchConfig[key] &&
          tableSearchConfig[key].trim() !== ""
        ) {
          clonedArray = clonedArray.filter((item: any) =>
            item[key]
              ?.toLowerCase()
              .includes(tableSearchConfig[key]?.toLowerCase())
          );
        }
      }
    }
    if (tableSortConfig) {
      const key = Object.keys(tableSortConfig)[0];
      if (key && clonedArray[key]) {
        const type = tableSortConfig[key];
        if (type === "" || type === "DESC") {
          clonedArray.sort((a: any, b: any) => b[key].localeCompare(a[key]));
        } else {
          clonedArray.sort((a: any, b: any) => a[key].localeCompare(b[key]));
        }
      }
    }
    setTableData(clonedArray);
    //eslint-disable-next-line
  }, [tableFilterConfig, tableSearchConfig]);
  //End of New Change

  function getOptionsList(option: any) {
    return `${option._source.loan.loanDetailId.toorakLoanId}/${option._source.loan.loanInfo.primaryLoanId}`;
  }

  const searchBody = () => {
    return (
      <>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          data-testid="provision"
          style={{
            padding: "0px 10px 20px 0px",
            width: "400px"
          }}
        >
          <Typography>Enter Toorak Loan Id/ Primary Loan Id</Typography>
        </Grid>
        <Autocomplete
          id="Search-Loan-Id"
          options={options}
          onInputChange={(event: any, val: any) => {
            dispatch(updateElasticSearchResponse(val, callback));
          }}
          onChange={(e: any, val: any) => {
            if (val != null && val._id) {
              setLoanId(val._source.loan.loanInfo.primaryLoanId);
            }
          }}
          getOptionLabel={(option) => getOptionsList(option)}
          className={drawClasses.dropDownClass}
          renderInput={(params) => (
            <TextField {...params} variant={"outlined"} />
          )}
        />
      </>
    );
  };

  const editBody = () => {
    return (
      <Grid
        item
        container
        xs={12}
        className={classes.itemHeaderText}
        data-testid="draw-view-itemHeaderText"
        style={{
          width: "600px",
          minHeight: "100px",
          display: "flex",
          color: "black"
        }}
      >
        <div style={{ width: "85%" }}>
          {" "}
          <TextField
            value={provisionValue}
            multiline
            rows={6}
            className={classes.textAreaClass}
            InputProps={{
              className: classes.editBody
            }}
            placeholder="Enter Provision"
            onChange={(e) => {
              setProvisionValue(() => e.target.value);
            }}
            style={{ width: "100%" }}
            onFocus={function (e) {
              var val = e.target.value;
              e.target.value = "";
              e.target.value = val;
            }}
            autoFocus
          />
        </div>
      </Grid>
    );
  };

  const deleteBody = () => {
    return (
      <div
        style={{
          width: "325px",
          minHeight: "55px",
          display: "flex"
        }}
      >
        <Typography style={{ fontSize: 14 }}>
          Do you want to delete the Provision?
        </Typography>
      </div>
    );
  };

  const getBody = useMemo(() => {
    return (
      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        data-testid="provision"
        style={{
          padding: "0px 17px 20px"
        }}
      >
        {modalData.primaryBtnName === "Next"
          ? searchBody()
          : modalData.primaryBtnName === "Edit"
          ? editBody()
          : modalData.primaryBtnName === "Delete" && deleteBody()}
      </Grid>
    );
    // eslint-disable-next-line
  }, [options, provisionValue, modalData]);

  const editCallback = () => {
    getPaginatedProvisionsData();
  };

  const deleteCallback = (primaryLoanId: string) => {
    getPaginatedProvisionsData();
    dispatch(getProvisionHistory(primaryLoanId, provisionHistorycallBack));
  };

  const provisionHistorycallBack = (data: any, toorakLoanId: string) => {
    let value: string = "No";
    if (0 !== data.length) {
      value = "Yes";
    }
    dispatch(updateProvision(toorakLoanId, value));
  };

  const successHandler = () => {
    if (modalData.primaryBtnName === "Next") {
      if (loanId === "") {
        setPopUpOpen(true);
        return;
      }
      navigate(`/drawDetails/addProvision/${loanId}`);
    } else if (modalData.primaryBtnName === "Edit") {
      dispatch(
        addProvision(
          provisionObj.primaryLoanId,
          provisionObj.toorakLoanId,
          provisionValue,
          provisionObj.provisionId,
          editCallback
        )
      );
    } else if (modalData.primaryBtnName === "Delete") {
      dispatch(
        deleteProvision(
          provisionObj.provisionId,
          deleteCallback,
          provisionObj.toorakLoanId
        )
      );
    }
    setOpenFormModel(false);
  };

  // const redirectToDrawListView = () => {
  //   navigate("/asset/home?tab=0");
  // };

  const clearFilter = () => {
    dispatch(showLoader());
    tableFilterConfig !== undefined && dispatch(updateTableFilters(undefined));
    tableSortConfig !== undefined &&
      dispatch(updateTableSortOptions(undefined));
    tableSearchConfig !== undefined &&
      dispatch(updateTableSearchOptions(undefined));
    saveFilters(
      undefined,
      undefined,
      {
        tableSearchConfig: {},
        tableSortConfig: {},
        pageLimit: 25,
        page: 1
      },
      true
    );
    setPage(1);
    dispatch(hideLoader());
  };

  // const isFilled = useMemo(() => {
  //   return (
  //     (tableFilterConfig !== undefined && !tableFilterConfig?.["stage"]) ||
  //     tableSortConfig !== undefined ||
  //     tableSearchConfig !== undefined
  //   );
  // }, [tableFilterConfig, tableSortConfig, tableSearchConfig]);

  const onLinkClick = (toorakLoanId: any) => {
    navigate(`/drawDetails/addProvision/${toorakLoanId}`);
  };

  const editClick = (data: any) => {
    setModalData({
      header: "Edit Provision",
      primaryBtnName: "Edit"
    });
    setProvisionObj({
      primaryLoanId: data.originatorLoanId,
      toorakLoanId: data.toorakLoanId,
      provisionId: data.provisionId
    });
    setProvisionValue(data.provision);
    setOpenFormModel(true);
  };

  const deleteClick = (data: any) => {
    setModalData({
      header: "Delete Provision",
      primaryBtnName: "Delete"
    });
    setProvisionObj({
      primaryLoanId: data.originatorLoanId,
      toorakLoanId: data.toorakLoanId,
      provisionId: data.provisionId
    });
    setProvisionValue(data.provision);
    setOpenFormModel(true);
  };

  return (
    <div>
      <Header
        value={3}
        setTabValue={setTabView}
        showTab={true}
        showNotificationIcon={false}
      />
      <section className={drawClasses.bodyWraper}>
        <Grid item xs={12}>
          <WarningModal
            isOpen={popUpOpen}
            handleClose={() => setPopUpOpen(false)}
            primaryBtnName="OK"
            header="Warning"
            body="Please choose a loan Id "
          />
          <ConfirmFormModal
            isOpen={openFormModel}
            handleClose={() => {
              setOpenFormModel(false);
            }}
            successHandler={successHandler}
            confirmCondition={false}
            containerStyle={{ overflow: "hidden" }}
            header={modalData.header}
            body={getBody}
            primaryBtnName={modalData.primaryBtnName}
            size="lg"
            //disableBackdropClick={true}
            headerStyles={{
              padding: 17,
              borderBottom: "solid 1px #dddddd"
            }}
            footerStyles={{
              borderTop: "solid 1px #dddddd",
              padding: "0px 0px 3px 17px",
              margin: "0 0px 14px 0px"
            }}
          />
        </Grid>
        <Grid item container xs={12}>
          <Grid item container xs={5} md={6}>
            <DrawsButtonGroup defaultValue={"Provisions"} />
            <Button
              className={drawClasses.clearButton}
              startIcon={<RefreshIcon />}
              disableRipple
              disableElevation
              onClick={clearFilter}
              style={{ marginTop: 15 }}
              data-testid="provision-view-clear-filter-btn"
            >
              Clear Filter
            </Button>
          </Grid>
          <Grid item container xs={5} md={4} />
          {isDrawAMRole() && (
            <Grid
              item
              xs={2}
              style={{
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              <Button
                variant="outlined"
                className={`${drawClasses.headerButton} ${drawClasses.addProvisionBtn}`}
                onClick={() => {
                  setModalData({
                    header: "Search Toorak Loan Id / Primary Loan ID",
                    primaryBtnName: "Next"
                  });
                  setOpenFormModel(true);
                }}
              >
                Add Provisions
              </Button>
            </Grid>
          )}

          <Grid style={{ display: "flex", justifyContent: "flex-end" }}></Grid>
        </Grid>
        <Box>
          <Container maxWidth={false}>
            <TableContainer
              ref={scrollRef}
              component={Paper}
              className={drawClasses.boxTableContainer}
            >
              <CustomTable
                columnsData={tableCols}
                rowsData={tableData}
                classes={classes}
                filtersOptions={filtersOptions}
                tableFilterConfig={tableFilterConfig}
                tableSortConfig={tableSortConfig}
                tableSearchConfig={tableSearchConfig}
                handleSearchChange={handleSearchChange}
                onSort={onSort}
                onRowSelect={() => {}}
                handleFilterToggle={handleFilterToggle}
                onLinkClick={onLinkClick}
                editClick={editClick}
                deleteClick={deleteClick}
                expandColumnIndex={1}
                from={"provision"}
                tableTextstyle={{ fontFamily: "system-ui" }}
                idKey={"provisionId"}
              />
            </TableContainer>
          </Container>
        </Box>
        <ListViewPagination
          filterName={"drawProvisionFilterSetting"}
          tableData={tableData}
          getDefaultFilter={getDefaultFilter}
          // getItemsRequestData={getProvisionsData}
          page={page}
          setPage={setPage}
          totalItemCount={totalProvisionsCount}
        />
      </section>
    </div>
  );
};

export default ProvisionView;
