/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Theme, Container, Grid, Typography, Chip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import WarningIcon from "@mui/icons-material/Warning";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { useDispatch } from "react-redux";
import { goBack, push } from "connected-react-router";

const FailedPropertyAddress = React.lazy(() =>
  import("../failed-property-address/FailedPropertyAddress").then((module) => ({
    default: module.FailedPropertyAddress
  }))
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subHeaderContainer: {
      backgroundColor: "#ffffff",
      boxShadow: "0 2px 2px 0 rgba(50, 50, 93, 0.21)",
      padding: "24px 0 21px 0"
      // marginTop: "60px"
    },
    subHeaderContainerModal: {
      paddingBottom: "5px !important"
    },
    subHeaderWrapper: {
      color: theme.palette.text.secondary,
      borderRadius: "1px",
      display: "flex",
      alignItems: "center"
    },
    waiverSubject: {
      color: "#32325d",
      fontSize: "16px",
      fontWeight: 600
    },
    failedPill: {
      color: "#ffffff",
      backgroundColor: "#f75676",
      fontSize: "10px",
      borderRadius: "6px",
      fontWeight: "bold"
    },
    failedPillIcon: {
      color: "#ffffff !important",
      height: "16px",
      width: "16px"
    },
    passPill: {
      color: "#ffffff",
      backgroundColor: "#2ece89",
      fontSize: "10px",
      borderRadius: "6px",
      fontWeight: "bold"
    },
    waiverReason: {
      fontSize: "12px",
      lineHeight: 1.67,
      fontWeight: "normal"
    }
  })
);

interface Props {
  waiverDetails: {
    subject: string;
    reason: string;
    details: any;
    errorAddressList?: any;
  };
  isSelectProgramView?: boolean;
  showLeftHeader?: boolean;
  exceptionWaiverId?: string;
}

export const WaiverSubheader = (props: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    waiverDetails,
    exceptionWaiverId,
    isSelectProgramView,
    showLeftHeader
  } = props;
  const waiverResponses = waiverDetails?.details?.waiverData?.waiverResponses;
  const testResult = waiverResponses?.length
    ? waiverResponses[waiverResponses.length - 1].isApproved
    : false;

  useEffect(() => {
    if (
      !window.location.pathname.includes("view") &&
      !waiverDetails.subject &&
      !exceptionWaiverId
    ) {
      dispatch(
        waiverDetails.details.backLocation
          ? push(waiverDetails.details.backLocation)
          : goBack()
      );
    }
  }, []);

  return (
    <div
      className={classes.subHeaderContainer}
      style={{
        paddingBottom: isSelectProgramView ? "5px" : "inherit"
      }}
    >
      <Container
        style={{
          display: "flex",
          maxWidth: 1280,
          justifyContent:
            isSelectProgramView || showLeftHeader ? "flex-start" : "center"
        }}
      >
        <Grid item xs={10} md={10} lg={10}>
          <Grid container direction="column" spacing={1}>
            {/* Line 1 */}
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Typography className={classes.waiverSubject}>
                    {waiverDetails?.details?.representableTestName || "Subject"}
                  </Typography>
                </Grid>
                {waiverDetails?.details?.tab !== "AdjustmentWaiver" ? (
                  <Grid item>
                    {!testResult && (
                      <Chip
                        size="small"
                        label="FAIL"
                        className={classes.failedPill}
                        icon={
                          <WarningIcon className={classes.failedPillIcon} />
                        }
                      />
                    )}
                    {testResult && (
                      <Chip
                        size="small"
                        label="PASS"
                        className={classes.passPill}
                        icon={
                          <CheckRoundedIcon
                            className={classes.failedPillIcon}
                          />
                        }
                      />
                    )}
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            {/* Line 2 */}
            <Grid item xs={12}>
              {waiverDetails?.details?.tab !== "AdjustmentWaiver" ? (
                <>
                  <Typography className={classes.waiverReason}>
                    <span
                      style={{
                        fontWeight: 600
                      }}
                    >
                      Reason for Fail :{" "}
                    </span>
                    {waiverDetails.reason || "Unavailable"}
                  </Typography>
                  {isSelectProgramView && (
                    <Typography className={classes.waiverReason}>
                      <span
                        style={{
                          fontWeight: 600
                        }}
                      >
                        Current Value :{" "}
                      </span>
                      {waiverDetails.details?.currentValue || "Unavailable"}
                    </Typography>
                  )}
                </>
              ) : (
                <Typography className={classes.waiverReason}>
                  {waiverDetails?.reason}
                </Typography>
              )}
            </Grid>
            <FailedPropertyAddress
              errorAddressList={waiverDetails?.errorAddressList}
              testName={waiverDetails?.subject}
              section="waiverSubHeader"
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
