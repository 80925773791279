import { Grid, IconButton, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getDrawDateCreated, getInitials } from "../../AssetManagement.utils";
import { useDrawsStyles } from "../Draws.styles";

import editImage from "../../../images/Edit.svg";
import deleteImage from "../../../images/Delete.svg";
import { ConfirmFormModal } from "@toorak/tc-common-fe-sdk";
import { useDispatch } from "react-redux";
import {
  addProvision,
  deleteProvision,
  getProvisionHistory
} from "../../../stores/drawRequest/drawRequest.action";
import { isRole } from "../../../utils/AccessManagement";
import { DrawRolesEnum } from "../../../utils/constants";

export const ProvisionHistoryDetail = (props: any) => {
  const { historyData } = props;
  const classes = useDrawsStyles();
  const [openFormModel, setOpenFormModel] = useState<boolean>(false);
  const [provisionHistory, setProvisionHistory] = useState<[]>([]);

  useEffect(() => {
    setProvisionHistory(historyData);
  }, [historyData]);

  const provisionHistorycallBack = (data: any, _toorakLoanId: string) => {
    setProvisionHistory(data);
  };

  const [modalData, setModalData] = useState({
    header: "",
    primaryBtnName: "",
    provisionData: {},
    originatorLoanId: "",
    toorakLoanId: "",
    provisionId: ""
  });

  const dispatch = useDispatch();
  const [provisionVal, setProvisionVal] = useState<string>("");

  const editAndDeleteCallback = (primaryLoanId: string) => {
    dispatch(getProvisionHistory(primaryLoanId, provisionHistorycallBack));
  };

  const successHandler = () => {
    if (modalData.primaryBtnName === "Edit") {
      dispatch(
        addProvision(
          modalData.originatorLoanId,
          modalData.toorakLoanId,
          provisionVal,
          modalData.provisionId,
          editAndDeleteCallback
        )
      );
    } else if (modalData.primaryBtnName === "Delete") {
      dispatch(
        deleteProvision(
          modalData.provisionId,
          editAndDeleteCallback,
          modalData.originatorLoanId
        )
      );
    }
    setOpenFormModel(false);
  };
  const getBody = () => {
    if (modalData.primaryBtnName === "Edit") {
      return editBody();
    } else if (modalData.primaryBtnName === "Delete") {
      return deleteBody();
    }
  };

  const editBody = () => {
    return (
      <Grid
        item
        container
        xs={12}
        data-testid="draw-view-itemHeaderText"
        className={classes.editTextAreastyles}
      >
        <div style={{ width: "85%" }}>
          {" "}
          <TextField
            value={provisionVal}
            InputProps={{
              className: classes.editBody
            }}
            multiline
            className={classes.textAreaClass}
            rows={6}
            placeholder="Enter Provision"
            onChange={(e) => {
              setProvisionVal(e.target.value);
            }}
            onFocus={function (e) {
              var val = e.target.value;
              e.target.value = "";
              e.target.value = val;
            }}
            style={{ width: "100%" }}
            autoFocus
          />
        </div>
      </Grid>
    );
  };
  const editIcon = (provisionItem: any) => {
    setOpenFormModel(true);
    setModalData({
      primaryBtnName: "Edit",
      header: "Edit Provision",
      provisionData: provisionItem,
      originatorLoanId: provisionItem.primaryLoanId,
      toorakLoanId: provisionItem.toorakLoanId,
      provisionId: provisionItem.provisionId
    });

    setProvisionVal(provisionItem.provision);
  };
  const deleteIcon = (provisionItem: any) => {
    setOpenFormModel(true);

    setModalData({
      primaryBtnName: "Delete",
      header: "Delete Provision",
      provisionData: provisionItem,
      originatorLoanId: provisionItem.primaryLoanId,
      toorakLoanId: provisionItem.toorakLoanId,
      provisionId: provisionItem.provisionId
    });
  };
  const deleteBody = () => {
    return (
      <div className={classes.deleteTextAreaStyles}>
        <Typography style={{ fontSize: 14 }}>
          Do you want to delete the Provision?
        </Typography>
      </div>
    );
  };
  if (provisionHistory.length > 0) {
    return (
      <>
        <ConfirmFormModal
          isOpen={openFormModel}
          handleClose={() => {
            setOpenFormModel(false);
          }}
          successHandler={successHandler}
          confirmCondition={false}
          containerStyle={{ overflow: "hidden" }}
          header={modalData.header}
          body={getBody()}
          primaryBtnName={modalData.primaryBtnName}
          size="lg"
          // disableBackdropClick={true}
          headerStyles={{
            padding: 17,
            borderBottom: "solid 1px #dddddd"
          }}
          footerStyles={{
            borderTop: "solid 1px #dddddd",
            padding: "0px 0px 3px 17px",
            margin: "0 0px 14px 0px"
          }}
        />
        <div className={classes.provisionDetailBody}>
          {provisionHistory?.map((provisionItem: any) => (
            <Grid
              item
              container
              xs={12}
              key={provisionItem.id}
              className={classes.discussionsDisplay}
            >
              <div
                className={classes.userInfo}
                data-testid="discussion-user-info"
              >
                <span className={classes.avatar}>
                  {getInitials(provisionItem.addedBy)}
                </span>
                <span className={classes.personName}>
                  {provisionItem.addedBy}
                </span>
                <span className={classes.personName}>
                  {getDrawDateCreated(provisionItem.createdAt)}
                </span>
              </div>
              <Grid
                item
                container
                xs={12}
                className={classes.comment}
                // style={{ marginLeft: "7%" }}
                data-testid="discussion-chat"
              >
                <div style={{ width: "730px", wordWrap: "break-word" }}>
                  {provisionItem.provision}
                </div>
                <div>
                  {isRole(DrawRolesEnum.DRAW_MANAGER) && (
                    <>
                      <IconButton
                        onClick={() => {
                          editIcon(provisionItem);
                        }}
                        size="large"
                      >
                        <img
                          className={classes.editIcon}
                          src={editImage}
                          alt={"Edit icon"}
                        />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          deleteIcon(provisionItem);
                        }}
                        size="large"
                      >
                        <img
                          className={classes.deleteIcon}
                          src={deleteImage}
                          alt={"Delete icon"}
                        />
                      </IconButton>
                    </>
                  )}
                </div>
              </Grid>
            </Grid>
          ))}
        </div>
      </>
    );
  } else {
    return <Grid className={classes.modalContainer}>No Data Available</Grid>;
  }
};
