/* eslint-disable prefer-destructuring */
import { AuditCardProps } from "@toorak/tc-common-fe-sdk";
import moment from "moment";
import {
  formatType,
  sanitizeValue
} from "../create-loan/purchase-and-settlements/constants";

export const transformRawAuditDataPerField = (
  accessor: string,
  auditPath: string,
  audit: any,
  customers: any[],
  fieldType: formatType,
  partyIdsObj: any = {}
) => {
  const customerObj: any = customers.reduce(
    (a: any, k: any) => ({ ...a, [k.partyId]: k }),
    {}
  );
  const latConfig = {
    style: {
      backgroundImage:
        "linear-gradient(139deg, #0fcbef 2%, #1071ee 95%), linear-gradient(to bottom, #d8d8d8, #d8d8d8)"
    },
    label: "T"
  };
  // to be implemented
  // const orgConfig = {
  //   style: {
  //     backgroundImage: "linear-gradient(139deg, #ff9e55 2%, #ae5f00 95%)"
  //   },
  //   label: "O"
  // };

  if (auditPath in audit) {
    const auditList: AuditCardProps[] = audit[auditPath]
      .filter((item: any) => item.action !== "create")
      .slice(0, 3)
      .map((itm: any) => {
        let firstName: string = customerObj[itm.updatedBy]?.firstName;
        let lastName: string = customerObj[itm.updatedBy]?.lastName;
        if (itm.comments === "value recalculated") {
          firstName = "System";
          lastName = "Re-Calculated";
        }
        let preValue: string = itm.preValue;
        let value: string = itm.value;
        if (
          accessor === "warehouseLender" ||
          accessor === "servicer" ||
          accessor === "sellerName"
        ) {
          preValue = partyIdsObj[preValue];
          value = partyIdsObj[value];
        }
        return {
          preValue: sanitizeValue(fieldType, preValue),
          currValue: sanitizeValue(fieldType, value),
          firstName,
          lastName,
          reason: itm.comments,
          updatedDate: moment(itm.updatedOn).format("DD-MMM-YYYY, HH:mm"),
          createdByConfig: latConfig
        };
      });
    return auditList;
  }
  return [];
};

export const formatLockOptions = (
  options: any,
  orgPartyId: string,
  labelFormatter?: Function
) => {
  let rateLockDropDownOptions: any = [];
  let lockOptions = options;
  const orgSpecificOptions = options?.filter((it: any) => {
    return it?.originatorId === orgPartyId;
  });
  lockOptions = orgSpecificOptions?.length ? orgSpecificOptions : options;
  lockOptions?.forEach((item: any) => {
    const days = item?.pricingFilter?.[0]?.value;
    let charges = item?.value;
    const hasNoCharges = !parseFloat(charges);
    charges = charges.includes("-")
      ? `${charges.replace("-", "-$")}`
      : `${charges}`;
    const chargesText = `${days} days at ${
      hasNoCharges ? `` : `${charges} of `
    }current rate`;
    rateLockDropDownOptions = [
      ...rateLockDropDownOptions,
      {
        value: days,
        label: labelFormatter?.(days, charges) ?? chargesText,
        charge: item?.value
      }
    ];
  });
  return rateLockDropDownOptions;
};

export const rateExtensionLabelFormatter = (days: number | string) => {
  return `${days} days`;
};
