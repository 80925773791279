/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useRef, useMemo } from "react";
import "../../App.css";
import { useSelector, useDispatch } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { Container, Theme, Grid, Hidden } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useParams } from "react-router-dom";
import {
  LoanStage,
  WarningModal,
  WarningModalProps
} from "@toorak/tc-common-fe-sdk";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import { PropertyDetailsPropertyLocation } from "./PropertyDetailsPropertyLocation";
import { PropertyDetailsPropertyInformation } from "./PropertyDetailsPropertyInformation";
import { PropertyDetailsPropertyEconomics } from "./PropertyDetailsPropertyEconomics";
import { PropertyDetailsCard } from "./PropertyDetailsCard";
import { PropertyDetailsActionBar } from "./PropertyDetailsActionBar";
import { RootState } from "../../stores/rootReducer";
import {
  updatePropertyDetails,
  // setActivePropertyId,
  deletePropertyDetails,
  postPropertyData,
  // updateDeletePropertyDetails,
  prepareObjectForTemplateMapping,
  checkDuplicateLoan
} from "../create-loan.action";
import {
  CreateLocationStore,
  emptyPropertyBasedOnLoan
} from "../create-loan.reducerHelper";

import {
  setActivePropertyId,
  updateDeletePropertyDetails
} from "../create-loan.reducer";
import leftDoubleArrowDisabled from "../../images/left_double_arrow_disabled.svg";
import rightDoubleArrowEnabled from "../../images/right_double_arrow_enabled.svg";
import { PropertyDetailsLoanFeatures } from "./PropertyDetailsLoanFeatures";
import { EvaluationPanel } from "./EvaluationPanel";
import { ThirtyYearLoanPropertyInformation as ThirtyYearPropertyDetailsPropertyInformation } from "./30-year-loan-property-details/ThirtyYearLoanPropertyInformation";
import { ThirtyYearLoanPropertyEconomics as ThirtyYearPropertyDetailsPropertyEconomics } from "./30-year-loan-property-details/ThirtyYearLoanPropertyEconomics";
import { ThirtyYearPropertyDetailsLoanFeatures } from "./30-year-loan-property-details/ThirtyYearLoanPropertyLoanFeatures";
import { MultipleUnitsWrapper } from "./30-year-loan-property-details/MultipleUnitsWrapper";
import { EvaluationResultStore } from "../../stores/EvaluationResults/EvaluationResults.reducer";
import { FrontendSizerProperty } from "../../frontend-sizer/FrontendSizerProperty";
import {
  getFesRulesRequestObject,
  postFesPropertyData
} from "../../stores/FrontEndSizer/fesLoanCreation.action";
import {
  rentDescription,
  rentDescriptionOld,
  LeaseStatusEnum,
  ValuationSource
} from "../constants/loanCreationDropDownValues";
import {
  isNullOrUndefined,
  getIsAllowedToEdit,
  formatValueByType
} from "../../utils/formatChecks";
import { getLoanType } from "../../config/config";
import {
  hideLoader,
  showLoader
} from "../../stores/loaderAndException/loaderAndException.action";
import { MarketData } from "./MarketData";
import { LoanSizerEnum } from "../../frontend-sizer/FrontendSizerTemplate";
import { isLATUser } from "../../utils/AccessManagement";
import {
  evaluateLoan,
  getOverriddenOutputs,
  updateOverriddenInredux
} from "../../stores/EvaluationResults/EvaluationResults.action";
import {
  allPropertyFields,
  SubmitToDD,
  SubmitToFR,
  URLParamsType
} from "../../utils/constants";
import {
  getEvaluationRequestBody,
  percentageOccupiedFormula
} from "../evaluation-results/helpers/ruleRequestBody";
import { BridgeLoanData } from "../form-components/FormComponentsProps";
import { getAffectedWaivers } from "../../stores/WaiverRequest/WaiverRequest.action";
import { AggregatePropertyForm } from "./aggregate-property-tab/AggregatePropertyForm";
import { getDuplicateCheckBody } from "../handle-duplicate-loans/DuplicateLoansFunctionality";
import { arePropertyAddressAmongstThemselvesSame } from "./PropertyDetailsHelper";
import { duplicatePropertyMessage } from "../ttf-review/document-review/document-ttf-review/AddressSuggestAndValidate";

export const getRequiredLeaseStatus = (fieldId: string) => {
  switch (fieldId) {
    case "currentLeaseTerm":
      return [
        rentDescriptionOld[0],
        rentDescriptionOld[1],
        rentDescriptionOld[2],
        rentDescriptionOld[3],
        LeaseStatusEnum.occupiedLongTerm,
        LeaseStatusEnum.unOccupiedShortTerm
      ];
    case "inPlaceLeaseRentMonthly":
      return [
        rentDescriptionOld[0],
        rentDescriptionOld[1],
        rentDescriptionOld[2],
        LeaseStatusEnum.occupiedLongTerm,
        LeaseStatusEnum.unOccupiedShortTerm
      ];
    case "recentLeaseRent":
      return [
        rentDescriptionOld[1],
        rentDescriptionOld[2],
        LeaseStatusEnum.occupiedShortTerm
      ];
    default:
      return [];
  }
};
export interface PropertyDetailsFormInterface {
  isEditEnabled: any;
  commentObj?: any;
  setCommentObj?: any;
}
export const unitInfoFields = [
  {
    fieldId: "leaseStatus",
    editedLabel: "leaseStatus",
    reduxFieldName: "leaseStatus",
    fieldLabel: "Rental Characterisation",
    fieldType: "dropDown",
    dropDownData: rentDescription.map((item: any) => ({
      value: item,
      label: item
    })),
    editHistoryName: "leaseStatus"
  },
  {
    fieldId: "currentLeaseTerm",
    editedLabel: "currentLeaseTermMonths",
    reduxFieldName: "currentLeaseTerm",
    fieldLabel: "Current Lease Term(Months)",
    fieldType: "decimal",
    requiredLeaseStatus: getRequiredLeaseStatus("currentLeaseTerm"),
    optionalCondition: (data: any) => {
      if (data) {
        if (
          data.unitInformation?.leaseStatus ===
          LeaseStatusEnum.unOccupiedShortTerm
        ) {
          return true;
        }
      }
      return false;
    },
    editHistoryName: "currentLeaseTermMonthly"
  },
  {
    fieldId: "marketRentMonthly",
    editedLabel: "marketRentMonthly",
    reduxFieldName: "marketRentMonthly",
    fieldLabel: "Market Rent Monthly ($)",
    fieldType: "currency",
    editHistoryName: "marketRentMonthly",
    optionalCondition: (data: any) => {
      if (data) {
        if (
          data.unitInformation?.leaseStatus ===
          LeaseStatusEnum.occupiedShortTerm
        ) {
          return true;
        }
      }
      return false;
    }
  },
  {
    fieldId: "inPlaceLeaseRent",
    editedLabel: "inPlaceLeaseRentMonthly",
    reduxFieldName: "inPlaceLeaseRent",
    fieldLabel: "In Place Lease Rent Monthly ($)",
    fieldType: "currency",
    editHistoryName: "inPlaceLeaseRentMonthly",
    optionalCondition: (data: any) => {
      if (data) {
        if (
          data.unitInformation?.leaseStatus ===
          LeaseStatusEnum.unOccupiedShortTerm
        ) {
          return true;
        }
      }
      return false;
    },
    requiredLeaseStatus: getRequiredLeaseStatus("inPlaceLeaseRentMonthly")
  },
  {
    fieldId: "recentLeaseRent",
    editedLabel: "mostRecentLeaseRentMonthly",
    reduxFieldName: "recentLeaseRent",
    fieldLabel: "Net Monthly Operating Income ($)",
    fieldType: "currency",
    editHistoryName: "mostRecentLeaseRentMonthly",
    requiredLeaseStatus: getRequiredLeaseStatus("recentLeaseRent")
  },
  {
    fieldName: "monthlyGrossRent",
    fieldId: "monthlyGrossRent",
    reduxFieldName: "monthlyGrossRent",
    fieldLabel: "Monthly Unit Rent ($)",
    fieldType: "ruleOutput",
    getValues: (inputs: any) => {
      const { unitInformation, loanEvaluationResult, activePropertyId } =
        inputs;
      try {
        const { unitId } = unitInformation;
        const { propertiesResults } = loanEvaluationResult;
        let propertyResult = null;
        if (propertiesResults) {
          propertyResult = propertiesResults[activePropertyId];
        }
        if (propertyResult) {
          const unitResult: any = propertyResult[`unit${unitId}`];
          return formatValueByType(
            `${unitResult?.monthlyGrossRent}`,
            "currency"
          );
        }
      } catch (error) {
        console.log(error);
        return null;
      }
      return null;
    }
  },
  {
    fieldName: "percentageOccupied",
    fieldId: "percentageOccupied",
    reduxFieldName: "percentageOccupied",
    fieldLabel: "% Unit Occupied",
    fieldType: "formula",
    rules: (inputs: any) => {
      return percentageOccupiedFormula(inputs);
    }
  }
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    propertyStepContainer: {
      position: "relative",
      flexShrink: 0,
      cursor: "pointer",
      borderRadius: 4,
      margin: "30px 24px 5px 24px",
      borderWidth: 1,
      borderStyle: "dashed",
      borderColor: "#8897aa",
      width: "20%"
    },
    wrapper: {
      display: "flex",
      flexDirection: "column",
      flex: 0.725,
      width: "100%",
      flexGrow: 1
    },
    scrollParentContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      overflow: "hidden",
      flexGrow: 1
    },
    addIconContainer: {
      color: "#5e72e4",
      borderRadius: 48,
      // width: 48,
      // height: 48,
      flexShrink: 0,
      backgroundColor: "#ffffff",
      margin: "6px auto",
      // margin: "12px 0px 0px 5px",
      padding: "22px 19px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "0px"
      }
    },
    addPropertyContainer: {
      // margin: "31px 30px 20px 15px",
      margin: "auto",
      fontSize: 12,
      fontWeight: 600,
      color: "#8897aa",
      textAlign: "center"
      // [theme.breakpoints.down("sm")]  : {
      //   padding: "0px"
      // }
    },
    propertySectionwrapper: {
      padding: "4px",
      [theme.breakpoints.only("sm")]: {
        padding: "18px 0px"
      }
    },
    cardContainer: {
      padding: "10px 20px",
      height: "80px"
    },
    activeIndexWrapper: {
      display: "flex",
      flexBasis: "31%",
      fontSize: 20,
      fontWeight: 800,
      borderRadius: "50%",
      textAlign: "center",
      lineHeight: "normal",
      height: "50px",
      maxWidth: "50px",
      justifyContent: "center",
      alignItems: "center",
      width: "50px",
      flexShrink: 0,
      [theme.breakpoints.only("sm")]: {
        padding: "6px",
        lineHeight: "41px"
      },
      [theme.breakpoints.down("sm")]: {
        padding: "7px",
        lineHeight: "0px",
        color: "black !important",
        backgroundColor: "transparent !important"
      }
    },
    propertyAddressWrapper: {
      maxLines: 2,
      display: "inline-block",
      paddingLeft: "10px",
      wordWrap: "break-word",
      lineHeight: 1.67,
      marginTop: "5px",
      maxHeight: "3.34em",
      fontSize: 12,
      fontWeight: 600,
      [theme.breakpoints.only("sm")]: {
        display: "none"
      },
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    mobileViewHideEvaluation: {
      [theme.breakpoints.only("xs")]: {
        display: "none"
      }
    }
  })
);

export const handlePropUpdateAfterSubmit = async (
  loanId: string,
  loanStage: LoanStage,
  id?: string,
  propertyLocation?: any,
  rBody?: any
): Promise<any> => {
  let updatedProp;
  let checkDupliBody;
  try {
    const requestBody = rBody
      ? rBody
      : await getDuplicateCheckBody(loanId, loanStage);
    if (!propertyLocation) {
      // for delete property adress after submit
      updatedProp = requestBody[0].propertiesArray.filter(
        (prop: { loanPropertyId: string }) => prop.loanPropertyId !== id
      );
    } else {
      updatedProp = requestBody[0].propertiesArray.map((prop: any) => {
        if (prop.loanPropertyId === id) {
          prop.propertyLocation.addressLine1 =
            propertyLocation?.address?.street_line;
          prop.propertyLocation.city = propertyLocation.city;
          prop.propertyLocation.state = propertyLocation.state;
          prop.propertyLocation.postalCode = propertyLocation.zipCode;
          if (propertyLocation.locationValidationStatus) {
            prop.propertyLocation.locationValidationStatus =
              propertyLocation.locationValidationStatus;
          }
        }
        return prop;
      });
    }
    if (rBody) {
      // checking if they themselves are duplicate. only used in ttf
      const areThereDuplicatesAmongThemselves =
        arePropertyAddressAmongstThemselvesSame(updatedProp);
      if (areThereDuplicatesAmongThemselves) {
        return Promise.resolve({
          duplicateAmongstThemselves: true
        });
      }
    }
    checkDupliBody = [
      {
        loanObject: requestBody[0].loanObject,
        propertiesArray: updatedProp
      }
    ];
    const response = (await checkDuplicateLoan(checkDupliBody, loanStage)).data;
    const { duplResultsDetails } = response;
    const { andArray, orArray, convertArray } = duplResultsDetails;
    if (!(andArray?.length || convertArray?.length || orArray?.length))
      return Promise.resolve({
        isDupli: false,
        propString: ""
      });
    const dupliData = andArray.length
      ? andArray
      : orArray.length
      ? orArray
      : convertArray;
    const { properties } = dupliData.length && dupliData[0];
    let propString: string = "";
    if (properties?.length) {
      const { addressLine1, city, state, postalCode } =
        properties[0]?.propertyLocation || {};
      propString = `${addressLine1}, ${city}, ${state}, ${postalCode}`;
    }
    if (dupliData?.length) {
      return Promise.resolve({
        isDupli: true,
        propString,
        dupliData: dupliData[0]
      });
    }
    return Promise.resolve({
      isDupli: false,
      propString: ""
    });
  } catch (e) {
    console.log(e, "error in update after submit");
    return Promise.reject("failed");
  }
};
export const AGGREGATE_PROPERTY_ID = "-1";
export const aggregateProperty = {
  propertyId: AGGREGATE_PROPERTY_ID, // hard coded here and in reducer for active tab.
  propertyLocation: {
    errors: null,
    payload: {
      address: null,
      city: "",
      state: "",
      zipCode: "",
      duplicateLoanIds: [],
      duplicatePropertyInfo: null,
      invalidAddress: false
    }
  },
  propertyDefaultName: "Property Aggregator"
};
export function PropertyDetailsForm(props: PropertyDetailsFormInterface) {
  const {
    bridgeLoanBorrowerInformation,
    bridgeLoanInformation,
    bridgeLoanEconomics,
    loanState,
    loanSizerType,
    bridgeLoanGuarantorInformation,
    exceptionGrade,
    loanRuleVersions,
    loanSummary
  } = useSelector<RootState, any>((state) => state.createLoanStore.loanDetails);
  const { loanId, loanStage } = useParams<URLParamsType>() as any;
  const { thirtyYearLoanInformation, fesLoanInformation } = useSelector<
    RootState,
    any
  >((state) => state.createLoanStore?.loanDetails);

  const takeoutPartnerId = useMemo(() => {
    if (loanStage === LoanStage.fes) {
      return fesLoanInformation?.takeoutPartnerId;
    } else {
      return thirtyYearLoanInformation?.payload?.takeoutPartnerId;
    }
  }, [loanStage, fesLoanInformation, thirtyYearLoanInformation]);

  const { thirtyYearLoanEconomics, loanType } = useSelector<RootState, any>(
    (state) => state.createLoanStore.loanDetails
  );
  const { loanDetails } = useSelector<RootState, CreateLocationStore>(
    (state) => state.createLoanStore
  );
  const submitForm = useSelector<
    RootState,
    {
      loanErrors: number;
      propertyErrors: number;
      // docsErrors: number;
      clicked: boolean;
    }
  >((state) => state.createLoanStore.submitForm);
  const { loanEvaluationResult, overriddenValues, waiversCreated, isEvaluationPanelVisible } =
    useSelector<RootState, EvaluationResultStore>(
      (state) => state.evaluationResultsStore
    );
  const dispatch = useDispatch();
  const {
    isEditEnabled,
    commentObj,
    setCommentObj
  } = props;

  const classes = useStyles();
  const [openWarning, setOpenWarning] = useState<WarningModalProps | null>(
    null
  );
  const isBridgeLoan: boolean = loanType === getLoanType[0].displayValue;

  const showMarketData = () => {
    return (
      loanStage === LoanStage.fes &&
      loanSizerType === LoanSizerEnum.multifamilyMixedUse &&
      isBridgeLoan
    );
  };
  const onAddClicked = async () => {
    const valuationSource =
      loanDetails?.propertyDetails[0]?.fesPropertyInformation?.payload?.valuationSource;
    if (valuationSource === ValuationSource.Toorval)
      // eslint-disable-next-line no-alert
      return alert("One Property allowed for Appraisal Lite process.");
    if (
      (loanState === LoanStatusEnum.DataEntry ||
        loanState === LoanStatusEnum.ExcelError ||
        loanState === LoanStatusEnum.ExcelSuccess) &&
      !submitForm.clicked
    ) {
      dispatch(showLoader(true));
      try {
        const postPropResp = await postPropertyData(
          loanId,
          loanStage,
          [emptyPropertyBasedOnLoan(loanType, loanStage)],
          propertyDetails.length + 1
        );
        if (postPropResp.status === 200) {
          const propertyId = postPropResp.data[0].loanPropertyId;
          dispatch(
            updatePropertyDetails(
              propertyId,
              emptyPropertyBasedOnLoan(loanType, loanStage)
            )
          );
          if (propertyDetails.length === 0) {
            prepareObjectForTemplateMapping(
              takeoutPartnerId,
              postPropResp.data[0].propertyId,
              dispatch,
              "property",
              true,
              loanStage.toLowerCase(),
              loanId,
              loanType
            );
          }
        }
        dispatch(hideLoader(true));
      } catch (error) {
        console.log(error);
        dispatch(hideLoader(true));
      }
    }
  };

  /**
   * This is used to run eval & check waiver changes after update loaction post submit
   * @param id loanId
   * @param overriddenValuesToUse
   */
  const runEvalAfterPropDelete = async (
    id: string = "",
    overriddenValuesToUse: any = {},
    isDeleted: boolean,
    takeoutPartnerId?: string,
    newPops?: any,
    updatedCreatedWaivers?: any
  ) => {
    let data: BridgeLoanData;
    const initializer = { reRequestWaiverObject: {}, openWaivers: [] };
    let { reRequestWaiverObject, openWaivers }: any = initializer;
    let refreshWaiver: boolean = false;
    if (isEvaluationPanelVisible) {
      const changedValueFields: any[] = allPropertyFields;
      try {
        overriddenValuesToUse = overriddenValues;
        ({ reRequestWaiverObject, openWaivers } = await getAffectedWaivers(
          changedValueFields,
          updatedCreatedWaivers ?? waiversCreated,
          loanId,
          loanStage,
          loanType
        ));
        refreshWaiver =
          (typeof reRequestWaiverObject === "object" &&
            Object.keys(reRequestWaiverObject)?.length) ||
          openWaivers.length;
        // skipping when no partner and loan is of dscr
        // https://toorakcapital.atlassian.net/browse/TA-4313
        if (
          refreshWaiver &&
          (loanType === getLoanType[0].displayValue ||
            (loanType === getLoanType[1].displayValue && takeoutPartnerId))
        ) {
          const resp = await getOverriddenOutputs(
            loanId,
            loanStage,
            takeoutPartnerId
          );
          overriddenValuesToUse = resp?.data;
          dispatch(updateOverriddenInredux(resp));
        }
      } catch (e) {
        console.log(e);
        return;
      }
    }
    // "Bridge Loan
    if (isBridgeLoan) {
      data = {
        borrowerInformation: bridgeLoanBorrowerInformation,
        guarantorInformation: bridgeLoanGuarantorInformation,
        loanInformation: bridgeLoanInformation.payload,
        loanEconomics: bridgeLoanEconomics.payload,
        loanRuleVersions,
        loanSummary
      };
    } else {
      data = {
        borrowerInformation: bridgeLoanBorrowerInformation,
        guarantorInformation: bridgeLoanGuarantorInformation,
        loanInformation: thirtyYearLoanInformation.payload,
        loanEconomics: thirtyYearLoanEconomics.payload,
        loanRuleVersions,
        loanSummary
      };
    }

    const updatedProps = isDeleted
      ? propertyDetails.filter((prop: any) => prop.propertyId !== id)
      : newPops || propertyDetails;
    console.log(
      "NEW function..for update propr post submit... manual flow",
      updatedProps
    );
    if (loanStage === LoanStage.fes) {
      const newLoanDetails = { ...loanDetails };
      newLoanDetails.propertyDetails = updatedProps;
      const loanEvaluationRequestFES = getFesRulesRequestObject(
        newLoanDetails,
        loanId,
        loanType,
        loanStage,
        true
      );
      loanEvaluationRequestFES.ruleAttributs = {
        ruleGroup: [],
        ruleField: [],
        ruleResult: []
      };
      loanEvaluationRequestFES.resultObject = overriddenValuesToUse;
      dispatch(
        evaluateLoan(
          loanEvaluationRequestFES,
          loanId,
          loanStage,
          true,
          false,
          propertyDetails
        )
      );
    } else {
      getEvaluationRequestBody(
        loanId,
        loanStage,
        data,
        loanEvaluationResult,
        updatedProps,
        loanType
      ).then(async ({ evaluate, loanEvaluationRequest }) => {
        if (evaluate && loanEvaluationRequest) {
          if (isEvaluationPanelVisible) {
            loanEvaluationRequest.ruleAttributs = {
              ruleGroup: [],
              ruleField: [],
              ruleResult: []
            };
            loanEvaluationRequest.resultObject = overriddenValuesToUse;
          }
          dispatch(
            evaluateLoan(
              loanEvaluationRequest,
              loanId,
              loanStage,
              isEvaluationPanelVisible,
              false,
              updatedProps,
              refreshWaiver
            )
          );
        }
      });
    }
  };

  const onDeleteClicked = async (id: string) => {
    dispatch(showLoader(true));
    const isPropDel: boolean = isVisibleActionBar();
    // check for loanstage, user, duplicate loan
    // let updatedProp:any[]=[];
    const { pre, post } = LoanStage;
    if (isPropDel && [pre, post].includes(loanStage)) {
      try {
        const isDupliLoans = await handlePropUpdateAfterSubmit(
          loanId,
          loanStage,
          id
        );

        if (isDupliLoans.isDupli) {
          duplicatePropertyMessage(dispatch, setOpenWarning, isDupliLoans);
          return;
        }
      } catch (e) {
        console.log("error while deleting property");
        dispatch(hideLoader(true));
        return;
      }
    }
    try {
      const delPropResp = await deletePropertyDetails(loanId, loanStage, id);
      if (delPropResp.status === 204) {
        dispatch(updateDeletePropertyDetails(id));
        if (isPropDel) runEvalAfterPropDelete(id, {}, true, takeoutPartnerId);
      }
      dispatch(hideLoader(true));
    } catch (error) {
      console.log("error while updating deleted property");
      dispatch(hideLoader(true));
    }
  };

  const onPropertyClicked = (id: string) => {
    dispatch(setActivePropertyId(id));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const propertyDetails = useSelector<RootState, any[]>(
    (state) => state.createLoanStore.loanDetails.propertyDetails
  );
  // const { loanDetails } = useSelector<RootState, any[]>(
  //   state => state.createLoanStore
  // );
  const activePropertyId = useSelector<RootState, string | null>(
    (state) => state.createLoanStore.loanDetails.activePropertyId
  );
  const { activeTab, isViewMode } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );
  const [activePropertyDetail, setActivePropertyDetail] = useState(null);

  const scrollParentRef = useRef<HTMLDivElement>(null);

  const [scrollLeft, setScrollLeft] = useState(0);
  const [leftEnabled, setLeftEnabled] = useState(false);
  const [rightEnabled, setRightEnabled] = useState(false);

  function scrollListener() {
    const element = scrollParentRef.current;
    if (!element) return;
    setScrollLeft(element.scrollLeft);
  }
  useEffect(() => {
    // dispatch(getLoanHistory(loanId));
    const element = scrollParentRef.current;
    setLeftEnabled(scrollLeft !== 0);
    if (element)
      setRightEnabled(element.offsetWidth + scrollLeft < element.scrollWidth);
    else setRightEnabled(false);
  }, [
    scrollLeft,
    propertyDetails.length,
    activePropertyId,
    activePropertyDetail,
    dispatch,
    loanId
  ]);

  function onLeftClicked() {
    const element = scrollParentRef.current;
    if (!element) return;
    element.scrollLeft = Math.max(0, element.scrollLeft - element.offsetWidth);
  }

  function onRightClicked() {
    const element = scrollParentRef.current;
    if (!element) return;
    element.scrollLeft = Math.min(
      element.scrollLeft + element.offsetWidth,
      element.scrollWidth - element.offsetWidth
    );
  }

  const propertyLengthCheck = propertyDetails.length === 0;
  useEffect(() => {
    const element = scrollParentRef.current;
    if (element) element.addEventListener("scroll", scrollListener);
    return () => {
      if (element) element.removeEventListener("scroll", scrollListener);
    };
  }, [propertyLengthCheck, activePropertyId, activePropertyDetail]);

  useEffect(() => {
    if (propertyDetails.length === 0) {
      onAddClicked();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isAllowedToEdit, setIsAllowedToEdit] = useState(true);

  useEffect(() => {
    setIsAllowedToEdit(getIsAllowedToEdit(loanStage, loanState, isViewMode));
  }, [loanState, loanStage, isViewMode, isEvaluationPanelVisible]);

  const onDuplicateClicked = async () => {
    const currActivePropertyIndex = propertyDetails.findIndex(
      (element) => element.propertyId === activePropertyId
    );
    if (currActivePropertyIndex !== -1) {
      const duplicatedProperty = JSON.parse(
        JSON.stringify(propertyDetails[currActivePropertyIndex])
      );
      duplicatedProperty.propertyLocation.payload = {
        address: null,
        city: "",
        state: "",
        zipCode: ""
      };
      const currActivePropertyAddress =
        propertyDetails[currActivePropertyIndex].propertyLocation.payload;
      if (currActivePropertyAddress.address) {
        const { address, city, state, zipCode } = currActivePropertyAddress;
        duplicatedProperty.propertyDefaultName = address?.street_line
          ? `Copy of ${address?.street_line}, ${city}, ${state} ${zipCode}`
          : "";
      } else if (propertyDetails[currActivePropertyIndex].propertyDefaultName) {
        duplicatedProperty.propertyDefaultName = `Copy of ${propertyDetails[currActivePropertyIndex].propertyDefaultName}`;
      } else {
        duplicatedProperty.propertyDefaultName = "";
      }
      delete duplicatedProperty.propertyId;
      const tempArr = JSON.parse(JSON.stringify(propertyDetails));
      tempArr.splice(currActivePropertyIndex + 1, 0, duplicatedProperty);
      try {
        dispatch(showLoader(true));
        const postPropResp =
          loanStage === LoanStage.fes
            ? await postFesPropertyData(loanId, tempArr, loanSizerType)
            : await postPropertyData(loanId, loanStage, tempArr);
        if (postPropResp.status === 200) {
          const oldPropertyIds = propertyDetails.map((item) => item.propertyId);
          const propertyArray = JSON.parse(JSON.stringify(postPropResp.data));
          const newProperty = propertyArray.find(
            (item: any) => !oldPropertyIds.includes(item.loanPropertyId)
          );
          dispatch(
            updatePropertyDetails(
              newProperty?.loanPropertyId,
              duplicatedProperty,
              currActivePropertyIndex + 1
            )
          );
        }
        dispatch(hideLoader(true));
      } catch (error) {
        console.log(error);
        dispatch(hideLoader(true));
      }
    }
  };

  useEffect(() => {
    const activePropertyDetails = propertyDetails.find(
      (element) => element.propertyId === activePropertyId
    );
    if (activePropertyDetails) {
      setActivePropertyDetail(activePropertyDetails);
    } else if (
      propertyDetails.length &&
      activePropertyId !== AGGREGATE_PROPERTY_ID
    ) {
      dispatch(setActivePropertyId(propertyDetails[0].propertyId));
    }
    // eslint-disable-next-line
  }, [activePropertyId, propertyDetails, activeTab]);
  const scrollToLastPropertyBar = () => {
    const elem = document.getElementById(`${activePropertyId}ref`);
    if (elem) {
      elem.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start"
      });
    }
  };
  useEffect(() => {
    scrollToLastPropertyBar();
    // eslint-disable-next-line
  }, [activePropertyId, propertyDetails.length]);
  useEffect(() => {
    setTimeout(() => {
      scrollToLastPropertyBar();
    }, 500);
    // eslint-disable-next-line
  }, []);
  if (!activePropertyId && activePropertyId !== AGGREGATE_PROPERTY_ID) {
    dispatch(
      setActivePropertyId(propertyDetails[0] && propertyDetails[0].propertyId)
    );
    return <></>;
  }

  if (!activePropertyDetail && activePropertyId !== AGGREGATE_PROPERTY_ID) {
    return <></>;
  }

  const isVisibleActionBar = (): boolean => {
    if (
      loanDetails?.sizerRateLockExpiryDate &&
      loanStage === LoanStage.fes &&
      !isBridgeLoan
    )
      return false;
    if (isViewMode) return false;
    if (isEvaluationPanelVisible) {
      if (isLATUser()) {
        // LAT should be allowed to edit the property details till the time loan is approved or rejected.
        return SubmitToFR.includes(loanState);
      }
      // Originator should be allowed to edit the property details only till the time loan is in Due Diligence Review and without a grade(A,B,C,D,E).Originator should NOT be allowed edit the property location post the DD review status(Final Review and states after that) or if the loan is in DD review but with a grade.
      return SubmitToDD.includes(loanState) && !exceptionGrade;
    }
    return false;
  };

  return (
    <Container
      style={{ display: "flex", paddingBottom: "60px", maxWidth: 1280 }}
      key={`${loanId}-property-form`}
    >
      {openWarning && (
        <WarningModal
          isOpen={openWarning.isOpen}
          handleClose={openWarning.handleClose}
          handleOptional={openWarning.handleOptional}
          primaryBtnName={openWarning.primaryBtnName}
          header={openWarning.header}
          body={openWarning.body}
        />
      )}
      <Grid lg={12} md={12} sm={12}>
        <div>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 0.725,
                  // justifyContent: "space-between",
                  flexGrow: 1,
                  flexBasis: "72.5%"
                }}
                key={`${activePropertyId}`}
              />
            </Grid>
            {/* Left scroll image */}
            <Grid item xs={1} sm={1} md={1} lg={1} style={{ marginTop: "3%" }}>
              <img
                src={
                  leftEnabled
                    ? rightDoubleArrowEnabled
                    : leftDoubleArrowDisabled
                }
                alt="Scroll Left"
                style={{
                  width: 24,
                  height: 24,
                  objectFit: "contain",
                  transform: leftEnabled ? "rotate(180deg)" : "rotate(0deg)"
                }}
                onClick={onLeftClicked}
                onKeyDown={onLeftClicked}
              />
            </Grid>
            {/* Add property section */}
            <Grid item xs={10} sm={10} md={10} lg={10}>
              <div
                className={classes.scrollParentContainer}
                ref={scrollParentRef}
              >
                {propertyDetails.length > 1 && isEvaluationPanelVisible && (
                  <PropertyDetailsCard
                    onPropertyClicked={onPropertyClicked}
                    activePropertyId={activePropertyId}
                    propertyDetails={aggregateProperty}
                    key={aggregateProperty.propertyId}
                    index={0}
                    aggregateTab
                  />
                )}
                {propertyDetails.map((element, index) => {
                  if (element.propertyLocation) {
                    return (
                      <PropertyDetailsCard
                        onPropertyClicked={onPropertyClicked}
                        activePropertyId={activePropertyId}
                        propertyDetails={element}
                        key={element.propertyId}
                        index={index}
                      />
                    );
                  }
                  return null;
                })}
                {!isEvaluationPanelVisible && (
                  <div
                    className={classes.propertyStepContainer}
                    onClick={() => onAddClicked()}
                    onKeyDown={() => onAddClicked()}
                    role="button"
                    tabIndex={0}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      className={classes.cardContainer}
                    >
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        lg={3}
                        className={classes.activeIndexWrapper}
                        style={{
                          color: "#5e72e4",
                          backgroundColor: "#ffffff"
                        }}
                      >
                        <AddIcon htmlColor="#8897aa" />
                      </Grid>
                      <Grid item xs={9} sm={9} md={8} lg={8}>
                        <div
                          className={classes.propertyAddressWrapper}
                          style={{
                            color: "#8897aa"
                          }}
                        >
                          {/* @ts-ignore */}
                          <Hidden smDown>Add property</Hidden>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </div>
            </Grid>
            {/* right scroll img */}
            <Grid
              container
              item
              xs={1}
              sm={1}
              md={1}
              lg={1}
              justifyContent="flex-end"
              alignItems="flex-end"
              style={{ marginTop: "3%" }}
            >
              <img
                src={
                  rightEnabled
                    ? rightDoubleArrowEnabled
                    : leftDoubleArrowDisabled
                }
                alt="Scroll Left"
                style={{
                  width: 24,
                  height: 24,
                  objectFit: "contain",
                  transform: !rightEnabled ? "rotate(180deg)" : "rotate(0deg)"
                }}
                onClick={onRightClicked}
                onKeyDown={onRightClicked}
              />
            </Grid>
            {/* property details components section */}
            {activePropertyId === AGGREGATE_PROPERTY_ID &&
            isEvaluationPanelVisible ? (
              <Grid item xs={12} style={{ marginTop: 24 }}>
                <AggregatePropertyForm isAllowedToEdit={isAllowedToEdit} />
              </Grid>
            ) : (
              <>
                <Grid item xs={12}>
                  <div key={`${activePropertyId}`}>
                    {(isVisibleActionBar() || !isEvaluationPanelVisible) && (
                      <PropertyDetailsActionBar
                        onAddClicked={onAddClicked}
                        loanState={loanState}
                        onDeleteClicked={onDeleteClicked}
                        onDuplicateClicked={onDuplicateClicked}
                        activePropertyId={activePropertyId}
                        isDeleteable={propertyDetails.length === 1}
                        isEvaluationPanelVisible={isEvaluationPanelVisible}
                      />
                    )}
                  </div>
                </Grid>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%"
                    // justifyContent: "space-between"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 0.725,
                      // justifyContent: "space-between",
                      flexGrow: 1,
                      flexBasis: "72.5%"
                    }}
                    key={`${activePropertyId}`}
                  >
                    {/* {(isVisibleActionBar() || !isEvaluationPanelVisible) && (
                  <PropertyDetailsActionBar
                    onAddClicked={onAddClicked}
                    loanState={loanState}
                    onDeleteClicked={onDeleteClicked}
                    onDuplicateClicked={onDuplicateClicked}
                    activePropertyId={activePropertyId}
                    isDeleteable={propertyDetails.length === 1}
                  />
                )} */}
                    <PropertyDetailsPropertyLocation
                      propertyId={activePropertyId}
                      isAllowedToEdit={isVisibleActionBar()}
                      data={{
                        borrowerInformation: bridgeLoanBorrowerInformation,
                        guarantorInformation: bridgeLoanGuarantorInformation,
                        loanInformation: isBridgeLoan
                          ? bridgeLoanInformation.payload
                          : thirtyYearLoanInformation.payload,
                        loanEconomics: isBridgeLoan
                          ? bridgeLoanEconomics.payload
                          : thirtyYearLoanEconomics.payload,
                        loanRuleVersions,
                        loanSummary
                      }}
                      runEvalAfterUpdatePostSubmit={runEvalAfterPropDelete}
                      propertyLocation={
                        activePropertyDetail &&
                        (activePropertyDetail as any).propertyLocation.payload
                      }
                      isEvaluationPanelVisible={isEvaluationPanelVisible}
                      isEditable={isEditEnabled["propertyDetails"]}
                      setCommentObj={setCommentObj}
                      reasonCommentObj={commentObj}
                    />
                    {loanStage !== LoanStage.fes && (
                      <>
                        {isBridgeLoan ? (
                          <PropertyDetailsPropertyInformation
                            propertyId={activePropertyId}
                            isAllowedToEdit={isAllowedToEdit}
                            data={{
                              borrowerInformation:
                                bridgeLoanBorrowerInformation,
                              guarantorInformation:
                                bridgeLoanGuarantorInformation,
                              loanInformation: bridgeLoanInformation.payload,
                              loanEconomics: bridgeLoanEconomics.payload,
                              loanRuleVersions,
                              loanSummary
                            }}
                            propertyInformation={
                              activePropertyDetail &&
                              (activePropertyDetail as any).propertyInformation
                                .payload
                            }
                            isEvaluationPanelVisible={isEvaluationPanelVisible}
                          />
                        ) : (
                          <ThirtyYearPropertyDetailsPropertyInformation
                            propertyId={activePropertyId}
                            isAllowedToEdit={isAllowedToEdit}
                            loanType={loanType}
                            data={{
                              borrowerInformation:
                                bridgeLoanBorrowerInformation,
                              guarantorInformation:
                                bridgeLoanGuarantorInformation,
                              loanInformation:
                                thirtyYearLoanInformation.payload,
                              loanEconomics: thirtyYearLoanEconomics.payload,
                              loanRuleVersions,
                              loanSummary
                            }}
                            propertyInformation={
                              activePropertyDetail &&
                              (activePropertyDetail as any).propertyInformation
                                .payload
                            }
                            isEvaluationPanelVisible={isEvaluationPanelVisible}
                            isEditable={isEditEnabled["propertyDetails"]}
                            setCommentObj={setCommentObj}
                            reasonCommentObj={commentObj}
                          />
                        )}
                      </>
                    )}
                    {isBridgeLoan || loanStage === LoanStage.fes ? null : (
                      <MultipleUnitsWrapper
                        unitInformation={
                          activePropertyDetail &&
                          (activePropertyDetail as any).unitsInformation
                        }
                        isAllowedToEdit={isAllowedToEdit}
                        isEvaluationPanelVisible={isEvaluationPanelVisible}
                        unitInfoFields={unitInfoFields}
                      />
                    )}
                    {loanStage !== LoanStage.fes && (
                      <>
                        {isBridgeLoan ? (
                          <PropertyDetailsPropertyEconomics
                            propertyId={activePropertyId}
                            isAllowedToEdit={isAllowedToEdit}
                            data={{
                              borrowerInformation:
                                bridgeLoanBorrowerInformation,
                              guarantorInformation:
                                bridgeLoanGuarantorInformation,
                              loanInformation: bridgeLoanInformation.payload,
                              loanEconomics: bridgeLoanEconomics.payload,
                              loanRuleVersions,
                              loanSummary
                            }}
                            propertyEconomics={
                              activePropertyDetail &&
                              (activePropertyDetail as any).propertyEconomics
                                ?.payload
                            }
                            isEvaluationPanelVisible={isEvaluationPanelVisible}
                          />
                        ) : (
                          <ThirtyYearPropertyDetailsPropertyEconomics
                            propertyId={activePropertyId}
                            isAllowedToEdit={isAllowedToEdit}
                            loanType={loanType}
                            data={{
                              borrowerInformation:
                                bridgeLoanBorrowerInformation,
                              guarantorInformation:
                                bridgeLoanGuarantorInformation,
                              loanInformation:
                                thirtyYearLoanInformation.payload,
                              loanEconomics: thirtyYearLoanEconomics.payload
                            }}
                            propertyEconomics={
                              activePropertyDetail &&
                              (activePropertyDetail as any).propertyEconomics
                                ?.payload
                            }
                            isEvaluationPanelVisible={isEvaluationPanelVisible}
                            isEditable={isEditEnabled["propertyDetails"]}
                            setCommentObj={setCommentObj}
                            reasonCommentObj={commentObj}
                          />
                        )}
                      </>
                    )}
                    {loanStage === LoanStage.fes && (
                      <FrontendSizerProperty
                        isEvaluationPanelVisible={isEvaluationPanelVisible}
                      />
                    )}
                    {isBridgeLoan ? (
                      <PropertyDetailsLoanFeatures
                        isAllowedToEdit={isAllowedToEdit}
                        loanFeatures={
                          loanEvaluationResult?.propertiesResults
                            ? {
                                cbsa: loanEvaluationResult?.propertiesResults[
                                  `${activePropertyId}`
                                ]?.loanFeatures.cbsa,
                                daysOnMarket:
                                  loanEvaluationResult?.propertiesResults[
                                    `${activePropertyId}`
                                  ]?.loanFeatures.daysOnMarket,
                                ltmHpa: isNullOrUndefined(
                                  loanEvaluationResult?.propertiesResults[
                                    `${activePropertyId}`
                                  ]?.loanFeatures.ltmHpa
                                )
                                  ? loanEvaluationResult?.propertiesResults[
                                      `${activePropertyId}`
                                    ]?.loanFeatures.medianHomeHpa
                                  : loanEvaluationResult?.propertiesResults[
                                      `${activePropertyId}`
                                    ]?.loanFeatures.ltmHpa,
                                medianValue: isNullOrUndefined(
                                  loanEvaluationResult?.propertiesResults[
                                    `${activePropertyId}`
                                  ]?.loanFeatures.medianValue
                                )
                                  ? loanEvaluationResult?.propertiesResults[
                                      `${activePropertyId}`
                                    ]?.loanFeatures.medianHomePriceValue
                                  : loanEvaluationResult?.propertiesResults[
                                      `${activePropertyId}`
                                    ]?.loanFeatures.medianValue,
                                toorakLoanTypeRehab:
                                  loanEvaluationResult?.propertiesResults[
                                    `${activePropertyId}`
                                  ]?.loanFeatures.toorakLoanTypeRehab || "",
                              esriPopulationDensity:
                                loanEvaluationResult?.propertiesResults[
                                  `${activePropertyId}`
                                ]?.loanFeatures.esriPopulationDensity || ""
                              }
                            : {
                                cbsa: "",
                                daysOnMarket: "",
                                ltmHpa: "",
                                medianValue: "",
                                toorakLoanTypeRehab: "",
                                esriPopulationDensity: ""
                              }
                        }
                        propertyId={activePropertyId}
                        isEvaluationPanelVisible={isEvaluationPanelVisible}
                      />
                    ) : (
                      <ThirtyYearPropertyDetailsLoanFeatures
                        loanFeatures={
                          loanEvaluationResult?.propertiesResults
                            ? {
                                esriPopulationDensity: isNullOrUndefined(
                                  loanEvaluationResult?.propertiesResults[
                                    `${activePropertyId}`
                                  ]?.loanFeatures?.esriPopulationDensity
                                )
                                  ? ""
                                  : loanEvaluationResult?.propertiesResults[
                                      `${activePropertyId}`
                                    ]?.loanFeatures?.esriPopulationDensity,
                                zillowCbsa:
                                  loanEvaluationResult?.propertiesResults[
                                    `${activePropertyId}`
                                  ]?.loanFeatures?.zillowCbsa || "",
                                adjustedAsIsValue: isNullOrUndefined(
                                  loanEvaluationResult?.propertiesResults[
                                    `${activePropertyId}`
                                  ]?.loanFeatures?.adjustedAsIsValue
                                )
                                  ? ""
                                  : loanEvaluationResult?.propertiesResults[
                                      `${activePropertyId}`
                                    ]?.loanFeatures?.adjustedAsIsValue,
                                cityAndState:
                                  loanEvaluationResult?.propertiesResults[
                                    `${activePropertyId}`
                                  ]?.loanFeatures?.cityAndState || ""
                              }
                            : {
                                esriPopulationDensity: "",
                                zillowCbsa: "",
                                adjustedAsIsValue: "",
                                cityAndState: ""
                              }
                        }
                        isEvaluationPanelVisible={isEvaluationPanelVisible}
                      />
                    )}
                    {showMarketData() && (
                      <MarketData
                        data={
                          loanEvaluationResult?.propertiesResults
                            ? loanEvaluationResult?.propertiesResults[
                                `${activePropertyId}`
                              ]
                            : {}
                        }
                        isEvaluationPanelVisible={isEvaluationPanelVisible}
                        sectionId="marketData" // same key as in rule response
                        activePropertyId={activePropertyId}
                        sectionLabel="Market Data"
                      />
                    )}
                    {showMarketData() && (
                      <MarketData
                        data={
                          loanEvaluationResult?.propertiesResults
                            ? loanEvaluationResult?.propertiesResults[
                                `${activePropertyId}`
                              ]
                            : {}
                        }
                        isEvaluationPanelVisible={isEvaluationPanelVisible}
                        sectionId="subMarketData" // same key as in rule response
                        activePropertyId={activePropertyId}
                        sectionLabel="Submarket Data"
                      />
                    )}
                  </div>
                  {isEvaluationPanelVisible && (
                    <div
                      style={{
                        flex: 0.275,
                        height: "auto",
                        marginLeft: 24,
                        flexShrink: 0,
                        flexBasis: "33.2%"
                      }}
                      className={classes.mobileViewHideEvaluation}
                    >
                      <EvaluationPanel />
                    </div>
                  )}
                </div>
              </>
            )}
          </Grid>
        </div>
      </Grid>
    </Container>
  );
}
