import { Theme, Switch } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
// import styled from "styled-components";
import { styled } from "@mui/system";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      fontWeight: 600,
      backgroundColor: theme.palette.background.default,
      "&:selected": {
        backgroundColor: "#d2e3ef !important"
      },
      textTransform: "none",
      [theme.breakpoints.down("sm")]: {
        width: "102%"
      }
    },
    snackBarRoot: {
      background: "#fff",
      "& .MuiSnackbarContent-root": {
        background: "#fff",
        color: "#32325d"
      }
    },
    moreField: {
      display: "flex",
      alignItems: "center",
      color: "#32325d",
      cursor: "pointer",
      borderRadius: "4px",
      padding: "5px 5px 5px 10px",
      height: "32px",
      width: "32px",
      boxShadow: "0 0 3px 0 rgb(0 0 0 / 25%)"
    },
    size: {
      fontSize: "12px !important",
      fontWeight: 600
    },
    chip: {
      "& .MuiChip-label": {
        paddingRight: "5px"
      }
    },
    chipColor: {
      "& .MuiChip-label": {
        paddingRight: "5px",
        color: "#5f72e4",
        fontWeight: 600,
        fontSize: "10px"
      }
    },
    subHeaderContainer: {
      padding: "0px 24px 11px 24px",
      borderBottom: "solid 1px #e2e4f1",
      background: "#ffffff",
      [theme.breakpoints.down("sm")]: {
        padding: "10px 12px",
        minHeight: "57px !important"
      },
      [theme.breakpoints.down("md")]: {
        padding: "10px 12px",
        position: "relative",
        minHeight: "132px",
        alignItems: "flex-start"
      }
    },
    resetContainer: {
      // paddingLeft: "10px",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("md")]: {}
      // [theme.breakpoints.down("xs")]: {
      //   display: "none"
      // }
    },
    subHeaderWrapper: {
      color: theme.palette.text.secondary,
      borderRadius: "1px",
      display: "flex",
      alignItems: "center"
    },
    btnCreateloan: {
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    headingImage: {
      width: "16px",
      height: "16px",
      objectFit: "contain",
      cursor: "pointer",
      margin: "1px 2px 1px 0"
    },
    buttonGroup: {
      // border: "1px solid #51e5ff",
      backgroundColor: "#f7fafc !important",
      fontSize: "12px !important",
      fontWeight: 400,
      height: "35px",
      border: "0 !important",
      letterSpacing: "0.5px",
      color: theme.palette.text.secondary,
      // boxShadow:
      //   "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2)",
      "&:selected": {
        backgroundColor: "#f7fafc !important"
      },
      "&:disabled": {
        backgroundColor: "#f7fafc !important",
        color: "#32325d !important",
        fontWeight: 600,
        cursor: "no-drop"
      },
      textTransform: "none"
    },
    btnGroupicon: {
      marginRight: "5px",
      height: 16,
      width: 16
    },
    btnPurchase: {
      color: "#32325d !important",
      fontSize: "12px",
      fontWeight: 600,
      display: "flex",
      cursor: "no-drop",
      justifyContent: "center",
      paddingRight: "10px !important",
      "&:disabled": {
        backgroundColor: "#32325d",
        cursor: "no-drop"
      },
      [theme.breakpoints.up("xs")]: {
        justifyContent: "center"
      }
    },
    iconPurchase: {
      color: "#32325d"
    },
    searchContainer: {
      alignSelf: "flex-end",
      flex: 1,
      display: "flex",
      alignItems: "center",
      borderRadius: "20px",
      backgroundColor: "#fff !important",
      padding: "0px 16px 0 4px !important",
      // maxWidth: "171px !important",
      height: "36px !important",
      border: "solid 1px #E9ECEF !important",
      fontSize: "12px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100% !important",
        marginBottom: "10px"
      },
      "&:hover": {
        boxShadow: "0 0 0 2px #5e72e4d9 !important"
      }
    },
    input: {
      fontSize: "14px !important",
      color: theme.palette.text.primary,
      with: "100%"
    },
    searchButton: {
      padding: "10px 7px 7px 7px",
      width: "16px",
      height: "16px",
      color: theme.palette.secondary.light
    },
    searchIcon: {
      width: "16px",
      height: "16px",
      color: "#32325D",
      position: "relative",
      top: "1px",
      left: "3px",
      marginRight: "5px"
    },
    clearSearchIcon: {
      width: "16px",
      height: "16px",
      cursor: "pointer",
      backgroundColor: "#f7fafc !important",
      position: "relative",
      zIndex: 1
    },
    btnExport: {
      //   fontSize: "12px",
      //   cursor: "no-drop",
      //   opacity: "0.5",
      //   padding: theme.spacing(1),
      //   "&:hover": {
      //     cursor: "no-drop"
      //   }
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    exportButtonStyle: {
      width: "83px",
      color: "#32325d",
      height: "28px",
      padding: "6px 13px 6px 7px",
      borderRadius: "4px",
      boxShadow: "0 3px 4px 0 rgba(0, 0, 0, 0.14)",
      border: "solid 1px #ececec",
      backgroundColor: "var(--white)"
    },
    loanHeading: {
      color: "#32325d",
      paddingLeft: "1px",
      fontFamily: "OpenSans",
      fontSize: "24px !important",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    marginClose: {
      paddingRight: "0 !important",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        textAlign: "center",
        margin: "10px auto 0"
      }
    },
    compactandlistView: {
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    xsPadding: {
      padding: "4px 20px",
      fontWeight: 600
    },
    labelText: {
      fontFamily: "'Open Sans', sans-serif !important",
      color: "#8897aa",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      fontSize: "12px",
      paddingBottom: 7
    },
    textAreaWrapper: {
      width: "100%",
      height: "64px",
      border: "1px solid #cad1d7",
      borderRadius: "4px"
    },
    textArea: {
      fontFamily: "sans-serif !important",
      width: "100%",
      height: "100%",
      padding: "14px 12px 14px 12px",
      resize: "none",
      border: "none",
      borderRadius: "4px",
      fontSize: "14px"
    },
    modalDeleteButtonConatainer: {
      marginTop: 50
    },
    filterButton: {
      display: "none",
      border: "1px solid #ececec",
      borderRadius: "4px",
      boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.3)",
      height: "36px",
      minWidth: "auto",
      width: "36px",
      [theme.breakpoints.down("sm")]: {
        display: "inline-flex"
      },
      [theme.breakpoints.down("md")]: {
        display: "inline-flex",
        marginLeft: "15px"
      },
      "&:disabled": {
        display: "none"
      }
    },
    modalButton: {
      marginRight: 12,
      color: "#ffffff",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      marginTop: 18,
      borderWidth: 0,
      "&:hover": {
        backgroundColor: "#5566d6 !important"
      }
    },
    modalShowContainer: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block"
      },
      [theme.breakpoints.down("md")]: {
        display: "block"
      }
    },
    modalPopup: {
      display: "none",
      background: "#ffffff",
      borderRadius: "6px",
      position: "absolute",
      padding: "15px 0 20px",
      top: "10%",
      minHeight: "210px",
      left: "calc(50% - 150px)",
      width: "300px",
      [theme.breakpoints.down("sm")]: {
        display: "block",
        left: "calc(50% - 150px) !important",
        width: "300px !important"
      },
      [theme.breakpoints.down("md")]: {
        display: "block",
        left: "calc(50% - 225px)",
        width: "450px"
      }
    },
    modalButtonWrapper: {
      padding: "10px 18px 5px"
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    applyButton: {
      // marginRight: 12,
      color: "#ffffff",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      // marginTop: 10,
      // marginLeft: 16,
      // marginBottom: 5,
      borderWidth: 0,
      "&:hover": {
        backgroundColor: "#5566d6 !important"
      },
      "&:disabled": {
        opacity: "0.5"
      }
    },
    cancelButton: {
      color: "#32325d",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "0 3px 5px 0 rgba(0, 0, 0, 0.14)",
      border: "solid 1px #ececec",
      backgroundColor: "var(--white)",
      // marginTop: 10,
      // marginLeft: 20,
      // marginRight: 16,
      // marginBottom: 5,
      borderWidth: 0,
      "&:hover": {
        backgroundColor: "none"
      }
    },
    clearFilter: {
      width: "110px",
      height: "28px",
      padding: "6px 13px 6px 7px",
      borderRadius: "4px",
      boxShadow: "0 3px 4px 0 rgba(0, 0, 0, 0.14)",
      border: "solid 1px #ececec",
      backgroundColor: "var(--white)",
      color: "#32325d",
      marginLeft: 15,
      [theme.breakpoints.down("sm")]: {
        padding: "6px 8px 6px 8px",
        border: "1px solid #ececec",
        boxShadow: "0 0 3px 0 rgb(0 0 0 / 30%)",
        height: "36px",
        width: "36px",
        minWidth: "36px"
      },
      [theme.breakpoints.down("md")]: {
        border: "1px solid #ececec",
        boxShadow: "0 0 3px 0 rgb(0 0 0 / 30%)",
        padding: "6px 8px 6px 8px",
        height: "36px",
        width: "36px",
        minWidth: "36px"
      }
    },
    filterSelect: {
      width: 190,
      padding: "5px 0px 4px 7px",
      borderRadius: "4px",
      boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.22)",
      border: "solid 1px #ececec",
      backgroundColor: "var(--white)",
      fontSize: 12
    },
    selectItem: {
      fontSize: 12,
      paddingTop: 9,
      paddingBottom: 9
    },
    toggleItem: {
      paddingLeft: 16,
      display: "flex",
      justifyContent: "space-between"
    },
    toggleItemTypography: {
      fontWeight: 100,
      fontSize: 12,
      display: "flex",
      justifyContent: "flex-start"
    },
    hideForMobileView: {
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    subHeaderHideForMobileView: {
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    subHeaderShowForMobileView: {
      display: "none",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%"
      }
    },
    mobileSearchIcon: {
      display: "inline-flex",
      border: "1px solid #ececec",
      borderRadius: "4px",
      boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.3)",
      minWidth: "auto",
      marginLeft: "10px",
      height: "36px",
      width: "36px"
    },
    mobileViewDropdown: {
      margin: "0"
    },
    ipadViewDropdown: {
      margin: "0"
    },
    mobileViewItemSelect: {
      padding: "4px 0 4px 4px",
      height: "36px",
      minWidth: "165px",
      width: "auto"
    },
    ipadViewItemSelect: {
      padding: "4px 0 4px 4px",
      height: "36px",
      minWidth: "165px",
      width: "auto"
    },
    searchInputContainer: {
      display: "none"
    },
    searchInputEnable: {
      display: "block !important"
    },
    mobileSearchInputEnable: {
      position: "absolute",
      left: "0",
      height: "36px",
      top: "0",
      width: "100%"
    },
    IpadSearchInputEnable: {
      [theme.breakpoints.down("sm")]: {
        display: "none"
      },
      [theme.breakpoints.down("md")]: {
        position: "absolute",
        left: "0",
        height: "36px",
        top: "80px",
        margin: "0 15px",
        width: "calc(100% - 30px)"
      },
      [theme.breakpoints.up("md")]: {
        display: "none"
      }
    },
    inputSearchIpad: {
      maxWidth: "100% !important"
    },
    closeSearchInput: {
      position: "absolute",
      cursor: "pointer",
      height: "30px",
      top: "3px",
      padding: "7px",
      right: "10px",
      width: "30px",
      zIndex: 0
    },
    viewToggleBtnContainer: {
      [theme.breakpoints.down("md")]: {
        maxWidth: "118px"
      }
    },
    searchDesktopView: {
      [theme.breakpoints.up("sm")]: {
        display: "flex",
        // paddingLeft: "15px",
        alignItems: "center"
      }
    },
    searchIpadView: {
      [theme.breakpoints.down("md")]: {
        display: "flex"
      }
    },
    clearFilterDesktopView: {},
    chipRoot: {
      fontSize: 12,
      fontWeight: 700,
      backgroundColor: "#e9fafd",
      borderRadius: 6,
      color: "#32325d",
      marginLeft: "1rem",
      height: "24px",
      marginTop: "0.4rem"
    },
    statusContainer: {
      width: "100%",
      height: "100%",
      textTransform: "uppercase",
      textAlign: "center",
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      fontWeight: "bold"
    },
    statusText: {
      color: "#ffffff",
      margin: 0,
      padding: 5,
      fontSize: 10,
      borderRadius: 6,
      backgroundColor: "#172b4d"
    },
    tooltip: {
      color: "white",
      boxShadow: "0 2px 12px 0 rgb(50 50 93 / 21%)",
      margin: "0px 10px",
      padding: "0.5rem",
      fontSize: "12px",
      backgroundColor: "#5565d6",
      width: "200px",
      paddingLeft: "15px"
    },
    arrow: {
      color: "#5565d6"
    },
    intiatePurchaseCheckBoxWrap: {
      marginLeft: "0px",
      color: "rgb(94 94 117)",
      "&:after": {
        content: "'*'",
        color: "red",
        marginTop: "-1rem"
      }
    },
    initatePurchaseCheckBoxGroup: {
      marginBottom: "1rem"
    }
  })
);

export const CustomToggle = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16
    }
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2
  },
  display: "flex",
  justifyContent: "flex-end"
}));
