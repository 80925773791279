import { IconButton } from "@mui/material";
import React from "react";

import editImage from "../../../images/Edit.svg";

import deleteImage from "../../../images/Delete.svg";
import { DateFormatter } from "../../../masterView/formatter";
import { isRole } from "../../../utils/AccessManagement";
export const provisionColumns = () => {
  return [
    {
      id: "primaryLoanId",
      title: "Primary Loan ID",
      width: 150,
      boxProps: { ml: "auto", lineHeight: "0px", display: "flex" },
      rowClass: "text-truncate",
      frozen: true,
      alwaysFrozen: true,
      showSearchFilter: true,
      showSort: true,
      tbField: "primaryLoanId",
      renderCustom: (
        data: any,
        styles: any,
        handleDropdownSelection: any,
        onLinkClick: any
      ) => {
        return (
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => onLinkClick(data.primaryLoanId)}
          >
            {data.primaryLoanId}
          </span>
        );
      }
    },
    {
      id: "toorakLoanId",
      title: "Toorak Loan ID",
      width: 150,
      showSearchFilter: true,
      showSort: true,
      boxProps: { ml: "auto", lineHeight: "0px", display: "flex" },
      rowClass: "text-truncate",
      frozen: true,
      alwaysFrozen: true,
      tbField: "toorakLoanId"
    },
    {
      id: "provision",
      title: "Provisions",
      width: 450,
      boxProps: { ml: "auto", lineHeight: "0px", display: "flex" },
      rowClass: "text-truncate",
      frozen: true,
      alwaysFrozen: true,
      // showSearchFilter: true,
      tbField: "provision"
    },
    {
      id: "addedBy",
      title: "Added By",
      width: 150,
      boxProps: { ml: "auto", lineHeight: "0px", display: "flex" },
      rowClass: "text-truncate",
      frozen: true,
      alwaysFrozen: true,
      // showSearchFilter: true,
      // showSort: true,
      tbField: "addedBy"
    },
    {
      id: "createdAt",
      title: "Created At",
      width: 200,
      boxProps: { ml: "auto", lineHeight: "0px", display: "flex" },
      rowClass: "text-truncate",
      // showSearchFilter: true,
      tbField: "createdAt",
      renderCustom: (data: any) => {
        return (
          <DateFormatter
            data={data.createdAt}
            timeReq={true}
            isLocalTime={true}
          />
        );
      }
    },
    {
      id: "action",
      title: "Actions",
      width: 200,
      boxProps: { ml: "auto", lineHeight: "0px", display: "flex" },
      rowClass: "text-truncate",
      renderCustom: (
        data: any,
        styles: any,
        handleDropdownSelection: any,
        onButtonClick: any,
        editClick: any,
        deleteClick: any
      ) => {
        return (
          <>
            <IconButton
              onClick={() => editClick(data)}
              hidden={!isRole("AM_DRAW_MANAGER")}
              size="large"
            >
              <img
                className={styles.editIcon}
                src={editImage}
                alt={"Edit icon"}
              />
            </IconButton>
            <IconButton
              onClick={() => deleteClick(data)}
              hidden={!isRole("AM_DRAW_MANAGER")}
              size="large"
            >
              <img
                className={styles.deleteIcon}
                src={deleteImage}
                alt={"Delete icon"}
              />
            </IconButton>
          </>
        );
      }
    }
  ];
};
