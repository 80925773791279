/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-default-export */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import React, { useMemo } from "react";
import { ObjectType } from "../masterView/common";
import filter from "../images/filter-outline-2.svg";
import { CheckBoxList } from "../ui-components/CheckBoxList";
import { tkopStyles } from "./tkop.style";

const filterTypeMap: ObjectType = {
  multiCheckBox: (props: any) => <CheckBoxList {...props} />
};

export interface FilterWrapperProps {
  filters: ObjectType[];
  onApply: React.MouseEventHandler;
  onCancel: React.MouseEventHandler;
  disableOnApply: boolean;
  disableOnCancel: boolean;
  appliedFilterCount: number;
}

export default function FilterWrapper(props: FilterWrapperProps) {
  const {
    filters,
    onApply,
    onCancel,
    disableOnApply,
    disableOnCancel,
    appliedFilterCount
  } = props;
  const classes = tkopStyles();

  const defaultExpandedState = useMemo(() => {
    const data: ObjectType = {};
    filters.forEach((filterData: ObjectType) => {
      data[filterData.id] = false;
    });
    return data;
  }, [filters]);

  const [expanded, setExpanded] =
    React.useState<ObjectType>(defaultExpandedState);

  const handleChange =
    (value: boolean, key: string) =>
    (event: React.ChangeEvent<{}>, currentExpanded: boolean) => {
      setExpanded({ ...expanded, [key]: currentExpanded ? value : false });
    };
  return (
    <div className={classes.filterWrapper}>
      <div
        style={{
          display: "flex",
          padding: "10px",
          position: "relative",
          fontSize: 12
        }}
      >
        <img src={filter} alt="Apply Filter" />
        {appliedFilterCount > 0 && <span className={classes.ovalDot} />}
        <p
          style={{
            margin: appliedFilterCount > 0 ? "0px 0px 0px 5px" : "0px",
            fontWeight: 600
          }}
        >
          {`Filters (${appliedFilterCount})`}
        </p>
      </div>
      <div className={classes.filtersContainer}>
        {filters.map((filterData: ObjectType) => {
          return (
            <Accordion
              key={filterData.id}
              elevation={0}
              onChange={handleChange(!expanded[filterData.id], filterData.id)}
              classes={{
                root: classes.accordion,
                expanded: classes.accordionExpanded
              }}
              expanded={expanded[filterData.id]}
            >
              <AccordionSummary
                expandIcon={
                  expanded[filterData.id] === true ? (
                    <RemoveIcon
                      fontSize="small"
                      className={classes.smallAccordionSvg}
                      color="primary"
                    />
                  ) : (
                    <AddIcon
                      fontSize="small"
                      className={classes.smallAccordionSvg}
                      color="primary"
                    />
                  )
                }
                classes={{ root: classes.accordionSummary }}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                {filterData.label}
              </AccordionSummary>
              <AccordionDetails
                classes={{
                  root: classes.accordionDetails
                }}
              >
                {filterData?.type &&
                  filterTypeMap[filterData.type](filterData.config)}
                {filterData?.custom && filterData?.custom()}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
      <div className={classes.actionButtonsWrapper}>
        <Button
          variant="contained"
          onClick={onApply}
          disabled={disableOnApply}
          color="info"
        >
          Apply
        </Button>
        <Button
          variant="outlined"
          onClick={onCancel}
          disabled={disableOnCancel}
          className={classes.cancelButton}
        >
          Clear
        </Button>
      </div>
    </div>
  );
}
