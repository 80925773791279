import React from "react";
import { WaiverSubheader } from "../../waiver-form/WaiverSubheader";
import { WaiverContainer } from "../../waiver-form/WaiverContainer";
import { useParams } from "react-router-dom";
import { URLParamsType } from "../../../utils/constants";

interface WaiverWrapperProps {
  waiverDetails: any;
  selectedProgramDetails: any;
  selectedLoan: any;
  getDetails?: () => void;
  callback?: () => void;
}

export function WaiverWrapper({
  waiverDetails,
  selectedProgramDetails,
  getDetails,
  selectedLoan,
  callback
}: WaiverWrapperProps) {
  const { loanStage, loanId } = useParams<URLParamsType>() as any;
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  const isLoanding = (val: boolean) => {
    console.log(val);
  };

  return (
    <>
      <WaiverSubheader
        waiverDetails={waiverDetails}
        isSelectProgramView={true}
      />
      <WaiverContainer
        loanStage={loanStage}
        loanId={selectedLoan.value ?? loanId}
        waiverDetails={waiverDetails}
        callbacks={{ isLoading: isLoanding }}
        waiverRequestedInfo={{ isModal: true }}
        getDetails={getDetails}
        takeoutPartnerId={selectedProgramDetails.key}
        callback={callback}
      />
    </>
  );
}
