import { Grid } from "@mui/material";
import { EditFieldHistoryComponent } from "@toorak/tc-common-fe-sdk";
import React from "react";
import { sanitizeCurrency } from "../../utils/formatChecks";
import { useDrawsStyles } from "./Draws.styles";

export const DrawViewModeTextField = ({
  label,
  value,
  type,
  editedData,
  isEditMode
}: any) => {
  const classes = useDrawsStyles();
  let valLoc =
    type === "currency" ? sanitizeCurrency(value?.toString()) : value;

  return (
    <Grid item container xs={12} className={classes.fieldRow}>
      <div className={!isEditMode ? classes.itemBox : ""}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <p className={classes.itemSubHeaderText}>{label}</p>

          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center"
            }}
          >
            {editedData && !isEditMode && (
              <EditFieldHistoryComponent
                auditList={editedData}
                fromSettelement={false}
              />
            )}
          </div>
        </div>
        {!isEditMode ? (
          <p
            className={classes.itemValueText}
            data-testid="toorak-prePurchaseDrawAmount-value"
          >
            {valLoc ? valLoc : "__ __"}
          </p>
        ) : null}
      </div>
    </Grid>
  );
};
