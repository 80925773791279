/* eslint-disable no-shadow */
/* eslint-disable no-console */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useState, ChangeEvent, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import {
  Typography,
  Grid,
  Button,
  Theme,
  Tooltip,
  Dialog
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import {
  GridDatePickerForm as DatePickerForm,
  DropDown,
  LoanStage,
  PropertyInformation,
  GridTextFieldForm,
  CreateLoanParamsBridgeLoan
} from "@toorak/tc-common-fe-sdk";
import { useParams } from "react-router-dom";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import {
  globalSaveOnFieldChange,
  cancelForm,
  prepareCommentObject
} from "./LoanDetailsBorrowerInformation";
import {
  updateBridgeLoanDetails,
  postLoan,
  // POST_LOAN_SUCCESS,
  // POST_LOAN_FAILED,
  previousLoanState
  // valueEditing,
} from "../create-loan.action";
import {
  loanTypeFailed,
  postLoanSuccess,
  valueEditing,
  setAutoSaveCall
} from "../create-loan.reducer";
import { RootState } from "../../stores/rootReducer";

import {
  CutOffDateLoanAmountField,
  FinancedInterestReserveTextField,
  LoanAmountField,
  MaxLoanAmountField,
  CutOffDateMaxLoanAmountField,
  InterestRateTextField,
  CashOutAmountField,
  OriginationAndDiscountPointsField,
  TotalBudgetAmountField
} from "../form-components/LoanEconomics";
import iconMinimize from "../../images/icon_minimize.svg";
import iconMaximize from "../../images/icon_maximize.svg";
import editImage from "../../images/Edit.svg";
import { BridgeLoanData } from "../form-components/FormComponentsProps";
import { getBridgeLoanGuarantorInformationToPass } from "../CreateLoanHeader";
import { evaluateLoan } from "../../stores/EvaluationResults/EvaluationResults.action";
import {
  accuralTypeDropDown,
  armIndexTypes,
  rateType,
  ToorakProductEnum,
  yesNoDropdown
} from "../constants/loanCreationDropDownValues";
import {
  cleanPercentage,
  sanitizePercentage,
  isValidPercentage,
  cleanCurrency,
  isValidCurrency,
  sanitizeCurrency,
  isEmptyValue,
  enableReasonField,
  editHistoryValue,
  isValidNumber,
  sanitizeNumericInput,
  isValidDecimal,
  cleanDecimal
} from "../../utils/formatChecks";
import { getEvaluationRequestBody } from "../evaluation-results/helpers/ruleRequestBody";
import { isLATUser } from "../../utils/AccessManagement";
import { FinancedBudgetAmountTextField } from "../form-components/PropertyEconomics";
import { requestWaiverMessage } from "../../utils/constants";
import { messages } from "../../utils/messages";
import { ObjectType } from "../../masterView/common";
import { useFormFields } from "../../custom-hooks/useFormFields";
import { CustomizedTextField } from "../../utils/customizedTextField";
import { CheckBox } from "../../ui-components/CheckBox";

// let changedValueFields: any = [];
let originalEconomicsObject: any;

const accrualTypeValues = accuralTypeDropDown.map((item) => ({
  value: item,
  label: item
}));

export const rateTypeValues = rateType.map((item) => ({
  value: item,
  label: item
}));

export const armIndexValues = armIndexTypes.map((item) => ({
  value: item,
  label: item
}));

const yesNoDropdownValues = yesNoDropdown.map((item) => ({
  value: item,
  label: item
}));

const validatorsMap: ObjectType = {
  currency: isValidCurrency,
  percentage: isValidPercentage,
  number: isValidNumber,
  decimal: isValidDecimal,
  string: () => true
};

const formattersMap: ObjectType = {
  currency: cleanCurrency,
  percentage: cleanPercentage,
  decimal: cleanDecimal
};

export function isRateAdjustable(params: ObjectType) {
  return params?.rateType === "Adjustable";
}

function isPostClose(params: ObjectType) {
  return params?.loanStage === LoanStage.post;
}

function isNotTwoPlusOneLoan(params: ObjectType) {
  return params?.toorakProduct !== ToorakProductEnum.Rental;
}

const sanitizersMap: ObjectType = {
  currency: sanitizeCurrency,
  percentage: sanitizePercentage,
  number: sanitizeNumericInput
};

// const armFields = [
//   "armIndex",
//   "grossArmMargin",
//   "initialRateAdjustmentPeriod",
//   "initialPayAdjustmentPeriod",
//   "initialInterestRateDown",
//   "initialInterestRateUp",
//   "lifetimeMaxRate",
//   "lifetimeMinRate",
//   "subsequentInterestRateDown",
//   "subsequentInterestRateUp",
//   "paymentAdjustmentFrequency",
//   "rateAdjustmentFrequency",
//   "armLookBackDays",
//   "armRoundFlag",
//   "armRoundingFactor"
// ];

const waiverKeys = {
  maxLoanAmount: "originalMaximumLoanAmount",
  loanAmount: "originalLoanAmount"
};

const fields = [
  {
    field: "loanAmount",
    label: "Initial Loan Amount ($)",
    fieldType: "",
    fieldComponent: LoanAmountField,
    showIn: [LoanStage.pre, LoanStage.post],
    formattertype: "currency",
    errorText: messages.properCurrencyFormat,
    required: () => true
  },
  {
    field: "maxLoanAmount",
    label: "Original Maximum Loan Amount ($)",
    fieldType: "",
    fieldComponent: MaxLoanAmountField,
    showIn: [LoanStage.pre, LoanStage.post],
    formattertype: "currency",
    errorText: messages.properCurrencyFormat,
    required: () => true,
    showOnly: isNotTwoPlusOneLoan
  },
  {
    field: "financedBudgetAmount",
    label: "Financed Budget Amount ($)",
    fieldType: "",
    fieldComponent: FinancedBudgetAmountTextField,
    showIn: [LoanStage.pre, LoanStage.post],
    formattertype: "currency",
    errorText: messages.properCurrencyFormat,
    required: () => true,
    showOnly: isNotTwoPlusOneLoan
  },
  {
    field: "cutOffLoanAmount",
    label: "Cut-off Date Loan Amount ($)",
    fieldType: "",
    fieldComponent: CutOffDateLoanAmountField,
    showIn: [LoanStage.pre, LoanStage.post],
    formattertype: "currency",
    errorText: messages.properCurrencyFormat,
    required: () => true
  },
  {
    field: "cutOffMaxLoanAmount",
    label: "Cut-off Date Maximum Loan Amount ($)",
    fieldType: "",
    fieldComponent: CutOffDateMaxLoanAmountField,
    showIn: [LoanStage.pre, LoanStage.post],
    formattertype: "currency",
    errorText: messages.properCurrencyFormat,
    required: () => true,
    showOnly: isNotTwoPlusOneLoan
  },
  {
    field: "budgetAmount",
    label: "Total Rehab Budget ($)",
    fieldType: "",
    fieldComponent: TotalBudgetAmountField,
    showIn: [LoanStage.pre, LoanStage.post],
    formattertype: "currency",
    errorText: messages.properCurrencyFormat,
    required: () => true,
    showOnly: isNotTwoPlusOneLoan
  },
  {
    field: "interestRate",
    label: "Interest Rate (%)",
    fieldType: "",
    fieldComponent: InterestRateTextField,
    showIn: [LoanStage.pre, LoanStage.post],
    formattertype: "percentage",
    errorText: messages.enterDecimalValue,
    required: () => true
  },
  {
    field: "rateType",
    label: "Rate Type",
    fieldType: "dropdown",
    showIn: [LoanStage.pre, LoanStage.post],
    formattertype: "string",
    errorText: "Please select a Rate type",
    options: rateTypeValues,
    required: () => true
  },
  {
    field: "accrualType",
    label: "Accrual Type",
    fieldType: "dropdown",
    showIn: [LoanStage.pre, LoanStage.post],
    formattertype: "string",
    errorText: "",
    options: accrualTypeValues,
    required: () => isPostClose
  },
  {
    field: "financialInterestReserve",
    label: "Financed Interest Reserve ($)",
    fieldType: "",
    fieldComponent: FinancedInterestReserveTextField,
    showIn: [LoanStage.pre, LoanStage.post],
    formattertype: "currency",
    errorText: messages.properCurrencyFormat,
    required: () => isPostClose
  },
  {
    field: "cashOutAmount",
    label: "Cash Out Amount ($)",
    fieldType: "",
    fieldComponent: CashOutAmountField,
    showIn: [LoanStage.pre, LoanStage.post],
    formattertype: "currency",
    errorText: messages.properCurrencyFormat,
    required: () => isPostClose
  },
  {
    field: "discountPoints",
    label: "Total Origination and Discount Points (%)",
    fieldType: "",
    fieldComponent: OriginationAndDiscountPointsField,
    showIn: [LoanStage.pre, LoanStage.post],
    formattertype: "percentage",
    errorText: messages.enterDecimalValue,
    required: () => isPostClose
  },
  {
    field: "armIndex",
    label: "Arm Index",
    fieldType: "dropdown",
    showIn: [LoanStage.pre, LoanStage.post],
    formattertype: "string",
    errorText: "Please Add Arm Index",
    required: () => isRateAdjustable,
    options: armIndexValues,
    showOnly: isRateAdjustable
  },
  {
    field: "grossArmMargin",
    label: "Gross Margin (For ARMs)",
    fieldType: "",
    showIn: [LoanStage.pre, LoanStage.post],
    formattertype: "percentage",
    errorText: "Please Add Arm Gross Margin",
    required: () => isRateAdjustable,
    showOnly: isRateAdjustable
  },
  {
    field: "initialRateAdjustmentPeriod",
    label: "Initial Rate Adjustment Period",
    fieldType: "",
    showIn: [LoanStage.pre, LoanStage.post],
    formattertype: "number",
    errorText: "Please enter rate period in number",
    required: () => isRateAdjustable,
    showOnly: isRateAdjustable
  },
  {
    field: "initialPayAdjustmentPeriod",
    label: "Initial Pay Adjustment Period",
    fieldType: "",
    showIn: [LoanStage.pre, LoanStage.post],
    formattertype: "number",
    errorText: "Please enter pay period in number",
    required: () => isRateAdjustable,
    showOnly: isRateAdjustable
  },
  {
    field: "initialInterestRateDown",
    label: "Initial Interest Rate Cap (Change Down)",
    fieldType: "",
    showIn: [LoanStage.pre, LoanStage.post],
    formattertype: "percentage",
    errorText: "Please enter valid periodic cap",
    required: () => isRateAdjustable,
    showOnly: isRateAdjustable
  },
  {
    field: "initialInterestRateUp",
    label: "Initial Interest Rate Cap (Change Up)",
    fieldType: "",
    showIn: [LoanStage.pre, LoanStage.post],
    formattertype: "percentage",
    errorText: "Please enter valid periodic cap",
    required: () => isRateAdjustable,
    showOnly: isRateAdjustable
  },
  {
    field: "lifetimeMaxRate",
    label: "Lifetime Max Rate",
    fieldType: "",
    showIn: [LoanStage.pre, LoanStage.post],
    formattertype: "percentage",
    errorText: "Please enter valid Lifetime Max Rate",
    required: () => isRateAdjustable,
    showOnly: isRateAdjustable
  },
  {
    field: "lifetimeMinRate",
    label: "Lifetime Min Rate",
    fieldType: "",
    showIn: [LoanStage.pre, LoanStage.post],
    formattertype: "percentage",
    errorText: "Please enter valid Lifetime Min Rate",
    required: () => isRateAdjustable,
    showOnly: isRateAdjustable
  },
  {
    field: "subsequentInterestRateDown",
    label: "Subsequent Interest Rate Cap (Change Down)",
    fieldType: "",
    showIn: [LoanStage.pre, LoanStage.post],
    formattertype: "percentage",
    errorText: messages.enterDecimalValue,
    required: () => isRateAdjustable,
    showOnly: isRateAdjustable
  },
  {
    field: "subsequentInterestRateUp",
    label: "Subsequent Interest Rate Cap (Change Up)",
    fieldType: "",
    showIn: [LoanStage.pre, LoanStage.post],
    formattertype: "percentage",
    errorText: messages.enterDecimalValue,
    required: () => isRateAdjustable,
    showOnly: isRateAdjustable
  },
  {
    field: "paymentAdjustmentFrequency",
    label: "Payment Adjustment Frequency",
    fieldType: "",
    showIn: [LoanStage.pre, LoanStage.post],
    formattertype: "number",
    errorText: "Please enter payment Adjustment Frequency",
    required: () => isRateAdjustable,
    showOnly: isRateAdjustable
  },
  {
    field: "rateAdjustmentFrequency",
    label: "Rate Adjustment Frequency",
    fieldType: "",
    showIn: [LoanStage.pre, LoanStage.post],
    formattertype: "number",
    errorText: "Please enter rate Adjustment Frequency",
    required: () => isRateAdjustable,
    showOnly: isRateAdjustable
  },
  {
    field: "armLookBackDays",
    label: "ARM Look Back Days",
    fieldType: "",
    showIn: [LoanStage.pre, LoanStage.post],
    formattertype: "number",
    errorText: "Please enter ARM Look Back Days",
    required: () => isRateAdjustable,
    showOnly: isRateAdjustable
  },
  {
    field: "armRoundFlag",
    label: "ARM Round Flag",
    fieldType: "dropdown",
    showIn: [LoanStage.pre, LoanStage.post],
    formattertype: "string",
    errorText: "Please select Arm Round Flag",
    required: () => isRateAdjustable,
    options: yesNoDropdownValues,
    showOnly: isRateAdjustable
  },
  {
    field: "armRoundingFactor",
    label: "Arm Rounding Factor",
    fieldType: "",
    showIn: [LoanStage.pre, LoanStage.post],
    formattertype: "percentage",
    errorText: "Please enter Arm Rounding Factor",
    required: () => isRateAdjustable,
    showOnly: isRateAdjustable
  },
  {
    id: "includeOutOfPocketBudgetInARLTV",
    field: "includeOutOfPocketBudgetInARLTV",
    label: "Include out of pocket budget in ARLTV calculation",
    fieldType: "checkBox",
    showIn: [LoanStage.pre, LoanStage.post],
    formattertype: "boolean",
    required: () => true,
    showOnly: isLATUser
  }
];

export interface LoanDetailsLoanEconomicsProps {
  loanEconomics: any;
  isEvaluationPanelVisible: boolean;
  data: BridgeLoanData;
  propertyInformation?: PropertyInformation | null;
  isAllowedToEdit: boolean;
  isSelectedSection?: any;
  isMandatoryServicerField?: (field: string) => boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      input: {
        backgroundColor: "#ffffff !important"
      }
    },
    container: {
      borderRadius: 4,
      display: "flex",
      flexDirection: "column",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      backgroundColor: "#ffffff",
      marginTop: theme.spacing(3)
      // fontFamily: "OpenSans"
    },
    sectionWrapper: {
      padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
      display: "flex"
    },
    primaryGuarantorWrapper: {
      padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(
        1
      )} ${theme.spacing(3)}`,
      display: "flex",
      color: "#32325d"
    },
    sectionButtonWrapper: {
      padding: `${theme.spacing(0)} ${theme.spacing(3)} ${theme.spacing(
        3
      )} ${theme.spacing(3)}`,
      display: "flex"
    },
    guarantorHeadingTypography: {
      textAlign: "left",
      fontSize: "14px !important",
      fontWeight: 600,
      color: "#32325d",
      lineHeight: 1.57
    },
    headingImageContainer: {
      justifyContent: "flex-end",
      display: "flex"
    },
    headingImage: {
      width: 16,
      height: 16,
      objectFit: "contain",
      cursor: "pointer",
      verticalAlign: "top"
    },
    editImage: {
      width: 16,
      height: 16,
      marginRight: 16,
      objectFit: "contain",
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        display: "none"
      }
    },
    editImageDisplay: {
      display: "none"
    },
    savebutton: {
      width: 70,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4"
    },
    modalButton: {
      marginRight: 12,
      color: "#ffffff",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      marginTop: 18,
      borderWidth: 0,
      "&:hover": {
        backgroundColor: "#5566d6 !important"
      }
    },
    cancelButton: {
      width: 69,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      color: "#32325d",
      backgroundColor: "#fff",
      marginLeft: 10,
      "&:hover": {
        background: "none"
      }
    },
    divider: {
      height: "1px",
      border: "solid 1px #f2f2f2",
      padding: "0px !important"
    },
    formSelectContainer: {
      justifyContent: "space-around",
      display: "flex",
      flexDirection: "row",
      width: "95%",
      padding: "8px 0px 0px 0px"
    },
    modalDeleteButton: {
      marginRight: 12,
      color: "#ffffff",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      marginTop: 18,
      borderWidth: 0
    },
    modalCancelButton: {
      color: "#32325d",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      backgroundColor: "#ffffff",
      marginTop: 18,
      borderWidth: 1,
      borderColor: "#ececec"
    },
    modalDeleteButtonConatainer: {
      marginTop: 50
    },
    iconInfo: {
      width: "16px !important",
      height: "16px !important",
      color: "#32355d",
      "&:hover": {
        color: "#5e72e4"
      }
    },
    labelInformation: {
      fontSize: 12,
      fontWeight: 600,
      color: "#8897aa",
      height: "14px",
      "&:hover": {
        color: "#5e72e4"
      }
    },
    checkBoxContainer: {
      border: "1px solid rgb(233, 236, 239)",
      padding: "14px 16px 14px 24px",
      borderRadius: "4px"
    },
    checkBoxLabel: {
      fontWeight: 600,
      fontSize: "14px",
      marginLeft: "2px"
    }
  })
);
const colSpan = 4;

export function LoanDetailsLoanEconomics(props: LoanDetailsLoanEconomicsProps) {
  const {
    loanEconomics,
    isEvaluationPanelVisible,
    data,
    isAllowedToEdit,
    isSelectedSection,
    isMandatoryServicerField
  } = props;

  // const colSpan = 4;

  const [errors, setErrors] = useState(0);
  // const [interestRateError, setInterestError] = useState("");
  // const [accrualTypeError, setAccrualTypeError] = useState("");
  // const [
  //   financialInterestReserveError,
  //   setFinancialInterestReserveError
  // ] = useState("");
  const { loanId, loanType, loanStage }: any = useParams() as any;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isEditable, setEditable] = useState(isSelectedSection);
  const [callAutoSave, setCallAutoSave] = useState<{
    value: boolean;
    impactingFieldChange: boolean;
  }>({ value: false, impactingFieldChange: false });

  const [isMinimized, setMinimized] = useState(false);
  const [resetData, setResetData] = useState(false);
  const [open, setOpen] = React.useState(false);
  const { formFields, defaultFieldsEditStatus, defaultFieldErrorStatus } =
    useFormFields(fields, loanStage, {
      rateType: loanEconomics.rateType,
      toorakProduct: data.loanInformation.selectedToorakProduct
    });
  const [fieldsErrors, setFieldsErrors] = useState<Map<string, string>>(
    defaultFieldErrorStatus
  );
  const [fieldEditStatus, setFieldEditStatus] = useState<Map<string, boolean>>(
    defaultFieldsEditStatus
  );

  const { propertyDetails, loanState, loanConfig } =
    useSelector<RootState, any>((state) => state.createLoanStore.loanDetails) ||
    {};

  const editHistory = useSelector<RootState, any>(
    (state) => state.createLoanStore.editHistory
  );

  const infoContent = useSelector<RootState, any>(
    (state) => state.createLoanStore.infoContentLoan
  );

  const validateForm = useSelector<RootState, { validate: boolean }>(
    (state) => state.createLoanStore.validateForm
  );
  const loanEvaluationResult = useSelector<
    RootState,
    { loanResult: any; propertiesResults: any }
  >((state) => state.evaluationResultsStore.loanEvaluationResult);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loanInfoContent = useMemo(
    () => JSON.parse(JSON.stringify(infoContent || {})),
    [infoContent]
  );

  useEffect(() => {
    if (callAutoSave.value) {
      postLoanAutoSave(callAutoSave.impactingFieldChange);
      setCallAutoSave({ value: false, impactingFieldChange: false });
    }
  }, [loanEconomics]);

  useEffect(() => {
    if (resetData) {
      dispatch(
        previousLoanState(originalEconomicsObject, "bridgeLoanEconomics", false)
      );
      setResetData(!resetData);
    }
  });

  const validateData = () => {
    formFields.forEach((fieldData: ObjectType) => {
      const { field, formattertype, errorText, required } = fieldData;
      const validator = validatorsMap?.[formattertype];
      checkForError(
        loanEconomics?.[field],
        errorText,
        field,
        validator,
        required
      );
    });
  };

  const changedValueFields = useMemo(() => {
    const changedFields: string[] = [];
    setFieldsErrors(defaultFieldErrorStatus);
    fieldEditStatus.forEach((value, key) => {
      if (value) {
        /* @ts-ignore */
        changedFields.push(waiverKeys?.[key] || key);
      }
    });
    return changedFields;
  }, [fieldEditStatus]);

  useEffect(() => {
    const errorCount = Array.from(fieldsErrors.values()).filter(
      (item) => item
    ).length;
    setErrors(errorCount);
    dispatch(
      updateBridgeLoanDetails({
        bridgeLoanEconomics: {
          errors: errorCount
        }
      })
    );
  }, [fieldsErrors]);

  useEffect(() => {
    if (validateForm.validate) {
      validateData();
      dispatch(
        updateBridgeLoanDetails({
          bridgeLoanEconomics: {
            errors
          }
        })
      );
    }
  }, [validateForm]);

  useEffect(() => {
    if (
      loanState === LoanStatusEnum.DueDiligenceReview &&
      isEditable === true
    ) {
      dispatch(valueEditing(false));
      setEditable(!isEditable);
      // setResetData(!resetData);
      setFieldEditStatus(defaultFieldsEditStatus);
      cancelForm("economics", "delete");
    }
  }, [loanState]);

  const postLoanAutoSave = (
    impactingFieldChanged: boolean = false,
    extraData?: any
  ) => {
    if (!isEvaluationPanelVisible) {
      dispatch(setAutoSaveCall(true));
      const extraneousData = extraData || {};
      postLoan({
        loanId,
        loanType,
        loanConfig,
        guarantorInfo: getBridgeLoanGuarantorInformationToPass(
          data.guarantorInformation
        ),
        borrowerInformation: data.borrowerInformation,
        ...data.loanEconomics,
        ...data.loanInformation,
        ...extraneousData
      })
        .then((response: CreateLoanParamsBridgeLoan) => {
          // dispatch({
          //   type: POST_LOAN_SUCCESS,
          //   payload: {
          //     reservation: response
          //   }
          // });
          dispatch(
            postLoanSuccess({
              reservation: response
            })
          );
          if (impactingFieldChanged) {
            getEvaluationRequestBody(
              loanId,
              loanStage,
              data,
              loanEvaluationResult,
              propertyDetails
            )
              .then(({ evaluate, loanEvaluationRequest }) => {
                if (evaluate && loanEvaluationRequest) {
                  dispatch(
                    evaluateLoan(loanEvaluationRequest, loanId, loanStage)
                  );
                }
              })
              .catch((e) => {
                dispatch(setAutoSaveCall(false));
                console.log("error while sending evaluation request", e);
              });
          }else{
            dispatch(setAutoSaveCall(false));
          }
        })
        .catch((error) => {
          dispatch(setAutoSaveCall(false));
          dispatch(
            loanTypeFailed({
              error
            })
          );
        });
    }
  };

  const checkForError = (
    value: string,
    errorMessage: string,
    fieldId: string,
    validator?: Function,
    required?: Function
  ) => {
    let errorText = "";
    if (
      isEmptyValue(value) &&
      required?.({ rateType: loanEconomics.rateType, loanStage })
    ) {
      errorText = messages.fieldRequired;
    } else if (!validator?.(value)) {
      errorText = errorMessage;
    }

    setFieldsErrors((fieldsErrors) => {
      const updatedFieldsErrors = new Map(fieldsErrors);
      return updatedFieldsErrors.set(fieldId, errorText);
    });
  };

  const onFieldChange = (event: any, fieldData: ObjectType) => {
    const { field, formattertype, errorText, required } = fieldData;
    const validator = validatorsMap?.[formattertype];
    const formatter = formattersMap?.[formattertype];
    let formattedValue = null;
    if (fieldData.field === "includeOutOfPocketBudgetInARLTV") {
      formattedValue = event.target.checked;
    } else {
      formattedValue = formatter?.(event.target.value) ?? event.target.value;
    }

    if (isEvaluationPanelVisible) {
      prepareCommentObject(
        messages?.[`${field}AuditPath`],
        formattedValue,
        "",
        false,
        true
      );
      const updatedfieldEditStatus = new Map(fieldEditStatus);
      setFieldEditStatus(updatedfieldEditStatus.set(field, true));
    }
    dispatch(
      updateBridgeLoanDetails({
        bridgeLoanEconomics: {
          payload: {
            [field]: formattedValue
          }
        }
      })
    );
    checkForError(event.target.value, errorText, field, validator, required);
  };

  const onFieldFocusOut = (
    event: ChangeEvent<HTMLInputElement>,
    fieldData: ObjectType
  ) => {
    const { field, formattertype, errorText, required } = fieldData;
    const validator = validatorsMap?.[formattertype];
    const formatter = formattersMap?.[formattertype];
    const sanitizer = sanitizersMap?.[formattertype];
    const formattedValue =
      formatter?.(event.target.value) ?? event.target.value;
    const valid = validator?.(formattedValue) ?? true;
    const valueToPass =
      valid === true
        ? sanitizer?.(formattedValue) ?? formattedValue
        : event.target.value;
    if (
      event?.target.parentElement?.parentElement?.innerText.includes(
        messages.reasonForEditing
      )
    ) {
      prepareCommentObject(
        messages?.[`${field}AuditPath`],
        data.loanEconomics?.[field],
        field === "includeOutOfPocketBudgetInARLTV"
          ? formattedValue
          : event.target.value
      );
      return;
    }
    dispatch(
      updateBridgeLoanDetails({
        bridgeLoanEconomics: {
          payload: {
            [field]: valueToPass
          }
        }
      })
    );
    setCallAutoSave({ value: true, impactingFieldChange: true });
    checkForError(formattedValue, errorText, field, validator, required);
  };

  const clearEdits = () => {
    setEditable(!isEditable);
    dispatch(valueEditing(false));
    setFieldEditStatus(defaultFieldsEditStatus);
    cancelForm("economics", "delete");
  };

  // const checkRateType = () => { // don't delete this function
  //   if (
  //     loanEconomics.rateType !== originalEconomicsObject.rateType &&
  //     loanEconomics.rateType === "Fixed"
  //   ) {
  //     const armData: ObjectType = {};
  //     armFields.forEach((key: string) => {
  //       armData[key] = null;
  //       prepareCommentObject(
  //         Messages?.[`${key}Path`],
  //         null,
  //         "Reset by system as Rate Type was Updated"
  //       );
  //     });
  //     dispatch(
  //       updateBridgeLoanDetails({
  //         bridgeLoanEconomics: {
  //           payload: armData
  //         }
  //       })
  //     );
  //   }
  // };

  return (
    <div className={classes.container}>
      <Grid container spacing={3} className={classes.sectionWrapper}>
        <Grid item xs={12} container>
          <Grid item xs={9} sm={11}>
            <Typography className={classes.guarantorHeadingTypography}>
              Loan Economics
            </Typography>
          </Grid>
          <Grid item xs={3} sm={1} className={classes.headingImageContainer}>
            {isAllowedToEdit && (
              <img
                className={
                  isEditable || !isEvaluationPanelVisible
                    ? classes.editImageDisplay
                    : classes.editImage
                }
                src={editImage}
                alt="Edit Section"
                onClick={() => {
                  dispatch(valueEditing(true));
                  originalEconomicsObject = JSON.parse(
                    JSON.stringify(data.loanEconomics)
                  );
                  setEditable(!isEditable);
                  cancelForm("economics", "add");
                }}
                onKeyDown={() => {
                  dispatch(valueEditing(true));
                  originalEconomicsObject = JSON.parse(
                    JSON.stringify(data.loanEconomics)
                  );
                  setEditable(!isEditable);
                  cancelForm("economics", "add");
                }}
              />
            )}
            <Tooltip title={isMinimized ? "Expand" : "Collapse"}>
              <img
                className={classes.headingImage}
                src={isMinimized ? iconMaximize : iconMinimize}
                alt={isMinimized ? "Maximize section" : "Minimize section"}
                onClick={() => {
                  setMinimized(!isMinimized);
                }}
                onKeyDown={() => {
                  setMinimized(!isMinimized);
                }}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      {isMandatoryServicerField ? (
        <div className={classes.divider} />
      ) : (
        <Grid
          item
          xs={12}
          sm={12}
          style={{
            display: isMinimized ? "none" : ""
          }}
        >
          <div className={classes.divider} />
        </Grid>
      )}
      <Grid
        container
        spacing={4}
        className={classes.sectionWrapper}
        style={{
          display: isMinimized ? "none" : ""
        }}
      >
        {formFields.map((fieldData: ObjectType) => {
          const { fieldComponent: FieldComponent, field } = fieldData;
          if (FieldComponent) {
            return (
              <Grid
                container
                key={field}
                item
                xs={12}
                md={colSpan}
                sm={6}
                xl={colSpan}
              >
                <FieldComponent
                  required={fieldData.required({
                    ratetype: loanEconomics.rateType,
                    loanStage
                  })}
                  value={loanEconomics?.[field]}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onFieldChange(e, fieldData);
                  }}
                  checked={
                    loanEconomics?.includeOutOfPocketBudgetInARLTV || null
                  }
                  error={fieldsErrors.get(field) !== ""}
                  helperText={fieldsErrors.get(field)}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onFieldFocusOut(e, fieldData);
                  }}
                  isViewMode={isEvaluationPanelVisible && !isEditable}
                  editedLabel={fieldEditStatus.get(field)}
                  enableReason={enableReasonField(
                    changedValueFields,
                    field,
                    loanState
                  )}
                  editedData={editHistoryValue(
                    editHistory[messages?.[`${field}AuditPath`]],
                    fieldData.formatterType
                  )}
                  isServicerMandatory={isMandatoryServicerField?.(field)}
                  infoIconData={
                    loanInfoContent?.[
                      `fieldInfo.bridge.${loanStage.toLowerCase()}.loan.loanEconomics.${field}`
                    ]
                  }
                  className={
                    field === "includeOutOfPocketBudgetInARLTV"
                      ? classes.checkBoxLabel
                      : null
                  }
                />
              </Grid>
            );
          }
          switch (fieldData.fieldType) {
            case "dropdown":
              return (
                <Grid
                  container
                  key={field}
                  item
                  xs={12}
                  md={colSpan}
                  sm={6}
                  xl={colSpan}
                >
                  <DropDown
                    selectionValue={loanEconomics?.[field]}
                    selectionOnChange={(e: any) => {
                      onFieldChange(e, fieldData);
                    }}
                    selectionData={fieldData?.options ?? []}
                    onBlur={(e: any) => {
                      onFieldFocusOut(e, fieldData);
                    }}
                    helperText={fieldsErrors.get(field) || ""}
                    isViewMode={isEvaluationPanelVisible && !isEditable}
                    editedLabel={fieldEditStatus.get(field)}
                    editedData={editHistory[messages?.[`${field}AuditPath`]]}
                    isServicerMandatory={isMandatoryServicerField?.(field)}
                    isLATView={isLATUser()}
                    enableReason={enableReasonField(
                      changedValueFields,
                      field,
                      loanState
                    )}
                    notRequired={
                      !fieldData.required({
                        ratetype: loanEconomics.rateType,
                        loanStage
                      })
                    }
                    infoIconData={
                      loanInfoContent?.[
                        `fieldInfo.bridge.${loanStage.toLowerCase()}.loan.loanEconomics.${field}`
                      ]
                    }
                    formControlTestId={`${field}-form-control`}
                    inputLabelId={`${field}-input-label`}
                    inputLabelTestId={`${field}-input-label-test-id`}
                    label={fieldData.label}
                    selectId={`${field}-input-select-id`}
                    selectTestId={`${field}-input-select-test-id`}
                    valueTestId={`${field}-value`}
                    data-testid={`${field}-value`}
                  />
                </Grid>
              );
            case "datePicker":
              return (
                <Grid
                  container
                  key={field}
                  item
                  xs={12}
                  md={colSpan}
                  sm={6}
                  xl={colSpan}
                >
                  <DatePickerForm
                    value={loanEconomics?.[field]}
                    onChange={(e: any) => {
                      onFieldChange({ target: { value: e } }, fieldData);
                    }}
                    required={fieldData.required({
                      ratetype: loanEconomics.rateType,
                      loanStage
                    })}
                    label={fieldData.label}
                    error={
                      validateForm.validate && fieldsErrors.get(field) !== ""
                    }
                    helperText={
                      validateForm.validate ? fieldsErrors.get(field) : ""
                    }
                    onBlur={(e: any) => {
                      onFieldFocusOut(e, fieldData);
                    }}
                    infoIconData={
                      loanInfoContent?.[
                        `fieldInfo.bridge.${loanStage.toLowerCase()}.loan.loanEconomics.${field}`
                      ]
                    }
                    isViewMode={isEvaluationPanelVisible && !isEditable}
                    editedLabel={fieldEditStatus.get(field)}
                    editedData={editHistory[messages?.[`${field}AuditPath`]]}
                    isServicerMandatory={isMandatoryServicerField?.(field)}
                    enableReason={enableReasonField(
                      changedValueFields,
                      field,
                      loanState
                    )}
                    renderInput={(props: any) => (
                      <CustomizedTextField
                        props={props}
                        data-testid={field}
                        value={loanEconomics?.[field]}
                        error={fieldsErrors.get(field) !== ""}
                        helperText={fieldsErrors.get(field)}
                        isViewMode={isEvaluationPanelVisible && !isEditable}
                        valueTestId={`${field}-value`}
                      />
                    )}
                    valueTestId={`${field}-value`}
                    data-testid={field}
                  />
                </Grid>
              );
            case "checkBox":
              return isLATUser() ? (
                <Grid
                  container
                  key={field}
                  item
                  xs={12}
                  md={colSpan}
                  sm={6}
                  xl={colSpan}
                >
                  <CheckBox
                    label={fieldData.label}
                    value={loanEconomics?.includeOutOfPocketBudgetInARLTV}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onFieldChange(e, fieldData);
                    }}
                    isViewMode={isEvaluationPanelVisible && !isEditable}
                    editedLabel={fieldEditStatus.get(field)}
                    editedData={editHistoryValue(
                      editHistory[messages?.[`${field}AuditPath`]],
                      fieldData.formattertype
                    )}
                    isLATView={isLATUser()}
                    enableReason={enableReasonField(
                      changedValueFields,
                      field,
                      loanState
                    )}
                    onBlur={(e: any) => {
                      onFieldFocusOut(e, fieldData);
                    }}
                    gridClassName={classes.checkBoxContainer}
                  />
                </Grid>
              ) : null;
            default:
              return (
                <Grid
                  container
                  key={field}
                  item
                  xs={12}
                  md={colSpan}
                  sm={6}
                  xl={colSpan}
                >
                  <GridTextFieldForm
                    required={fieldData.required({
                      ratetype: loanEconomics.rateType,
                      loanStage
                    })}
                    value={loanEconomics?.[field]}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onFieldChange(e, fieldData);
                    }}
                    error={fieldsErrors.get(field) !== ""}
                    helperText={fieldsErrors.get(field)}
                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                      onFieldFocusOut(e, fieldData);
                    }}
                    isViewMode={isEvaluationPanelVisible && !isEditable}
                    editedLabel={fieldEditStatus.get(field)}
                    enableReason={enableReasonField(
                      changedValueFields,
                      field,
                      loanState
                    )}
                    editedData={editHistoryValue(
                      editHistory[messages?.[`${field}AuditPath`]],
                      fieldData.formatterType
                    )}
                    isServicerMandatory={isMandatoryServicerField?.(field)}
                    infoIconData={
                      loanInfoContent?.[
                        `fieldInfo.bridge.${loanStage.toLowerCase()}.loan.loanEconomics.${field}`
                      ]
                    }
                    label={fieldData.label}
                    data-testid={field}
                    valueTestId={`${field}-value`}
                  />
                </Grid>
              );
          }
        })}

        {isEditable && (
          <Grid container item xs={12} sm={12} md={12} xl={12}>
            <Button
              variant="contained"
              // data-testid="save-button"
              className={
                isEditable ? classes.savebutton : classes.editImageDisplay
              }
              // startIcon={<SaveOutlinedIcon />}
              disabled={changedValueFields.length === 0}
              onClick={() => {
                handleOpen();
                validateData();
                // checkRateType();
                // saveOnFieldChange();
                // setEditable(!isEditable);
                // changedValueFields = [];
              }}
              color="info"
            >
              Save
            </Button>
            <Button
              variant="contained"
              // data-testid="save-button"
              className={
                isEditable ? classes.cancelButton : classes.editImageDisplay
              }
              // startIcon={<SaveOutlinedIcon />}
              // onClick={handleSave}
              onClick={() => {
                setResetData(!resetData);
                clearEdits();
                prepareCommentObject("", "", "", true);
              }}
              color="info"
            >
              Cancel
            </Button>
          </Grid>
        )}
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="duplicate-property-modal"
        aria-describedby="duplicate-property-modal-description"
      >
        <div style={{ padding: 20, width: 500, maxWidth: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexGrow: 1,
              alignItems: "center"
            }}
          >
            <div style={{ fontSize: 14, fontWeight: 600, color: "#32325d" }}>
              Warning
            </div>
            <CloseIcon
              htmlColor="#414169"
              style={{ width: 16, height: 16, cursor: "pointer" }}
              onClick={() => handleClose()}
            />
          </div>
          <div
            style={{
              fontSize: 12,
              lineHeight: 1.5,
              color: "#32325d",
              marginTop: 40
            }}
          >
            {requestWaiverMessage}
          </div>
          <div className={classes.modalDeleteButtonConatainer}>
            <Button
              variant="contained"
              color="primary"
              data-testid="modal-delete-button"
              className={classes.modalButton}
              onClick={() => {
                handleClose();
                if (errors === 0) {
                  globalSaveOnFieldChange(
                    changedValueFields,
                    false,
                    false,
                    "loanEconomics"
                  ).then((res: any) => {
                    if (res !== "false") {
                      dispatch(valueEditing(false));
                      clearEdits();
                    }
                  });
                }
              }}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              data-testid="modal-cancel-button"
              className={classes.modalCancelButton}
              onClick={() => {
                handleClose();

                setResetData(!resetData);
                clearEdits();
                prepareCommentObject("", "", "", true);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
