import React from "react";
import { Grid, Theme, Container } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import NoLoanImg from "../../../images/loanlistheader/group-9.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      height: "calc(100vh - 380px)",
      width: "calc(100vw - 60px)",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "120px"
    },
    notCreatedLoan: {
      margin: "auto",
      fontSize: "16px",
      textAlign: "center",
      color: "#32325d"
    }
  })
);
export interface ZeroStateProps {
  textToShow: string;
  secondTextToShow: string;
  customClasses?: any;
}
export const ZeroStateLoan = (props: ZeroStateProps) => {
  const { textToShow, secondTextToShow, customClasses } = props;
  let classes = useStyles();
  classes = customClasses || classes;

  return (
    <>
      <Grid container className={classes.root}>
        {/* No table content */}
        <Grid item xs={12} sm={12} style={{ position: "absolute" }}>
          <Container
            style={{
              maxWidth: "428px",
              fontSize: "14px",
              color: "#32325d",
              lineHeight: 1.3
            }}
          >
            <div className={classes.notCreatedLoan}>
              <img src={NoLoanImg} alt="no-loan-content" />
              <p style={{ marginTop: "15px" }}>{textToShow}</p>
              <p>{secondTextToShow}</p>
            </div>
          </Container>
        </Grid>
      </Grid>
    </>
  );
};
