/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable object-shorthand */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */

import React from "react";
import { AppBar, Grid, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import logo from "../images/loanlistheader/group-3.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loanCreationTypography: {
      alignSelf: "center",
      color: "#FFF",
      [theme.breakpoints.only("lg")]: {
        marginLeft: "20px"
      }
    },
    headerButtonWrapper: {
      alignItems: "center",
      [theme.breakpoints.up("sm")]: {
        display: "flex",
        justifyContent: "flex-end"
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "0",
        display: "flex"
      },
    },
    fixedHeader: {
      //position: "fixed",
      height: "60px",
      zIndex: 2,
      [theme.breakpoints.down("sm")]: {
        padding: "10px 12px 8px 12px"
      },
      width: '100%',
    },
    headerPrimaryButton: {
      marginRight: "10px !important",
      [theme.breakpoints.down("md")]: {
        width: "36px",
        height: "36px",
        minWidth: "auto",
        padding: "5px",
        justifyContent: "center"
      }
    },
    headerSecondaryButton: {
      [theme.breakpoints.down("sm")]: {
        width: "36px",
        height: "36px",
        minWidth: "auto",
        padding: "5px",
        justifyContent: "center"
      }
    },
    btnLabel: {
      [theme.breakpoints.down("md")]: {
        display: "none"
      }
    },
    btnLabelEvaluate: {
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    imgLogo: {
        width: "212px"
      },    
  })
);

export function ExportHeader() {
    const classes = useStyles();
  return (
    <>
      <AppBar color="default" position="static" className={classes.fixedHeader}>
        <Grid container>
          <Grid
            container
            item
            xs={7}
            sm={6}
            md={5}
            lg={8}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
                        <img src={logo} alt="toorak_logo" className={classes.imgLogo} />
          </Grid>
        </Grid>
      </AppBar>
    </>
  );
}