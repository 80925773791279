import { isLATUser } from "../../utils/AccessManagement";
import { TABLE_FUNDING, ADVANCE_FUNDING } from "../../utils/constants";

export const loanStageMapping: any = {
  PRE: "Pre-Close",
  POST: "Post-Close",
  FES: "Guideline Evaluation",
  TOORVAL: "Toorval"
};

export const orgLoanStatusLabelMapping: any = {
  Purchased: "Funded"
};

export const tableFilterMapping: any = {
  "30 Year Loan": "Investor DSCR",
  "30 Year Rental": "Investor DSCR Rental",
  submit: "Preliminary Sizing",
  FES: "Guideline Evaluation",
  Purchased: isLATUser() ? "Purchased" : "Funded",
  "Due Diligence Review": "Review in Progress",
  [TABLE_FUNDING]: "Table Fund",
  [ADVANCE_FUNDING]: "Advance Fund",
  true: "Yes",
  false: "No"
};
