/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import {
  PropertyLocation,
  PropertyInformation,
  LocationSuggestion,
  LoanStage
} from "@toorak/tc-common-fe-sdk";
import { useSelector } from "react-redux";
import { Theme, Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useParams } from "react-router-dom";
import DomainRoundedIcon from "@mui/icons-material/DomainRounded";
import errorIcon from "../../images/icon_error_white.svg";
import {
  computePropertyError,
  computePropertyLocationError
} from "./PropertyDetailsHelper";
import { RootState } from "../../stores/rootReducer";
import { calculateFesPropertyError } from "../ErrorCounter";
import { UpdatedPropertyEconomics } from "../../masterView/common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardWrapper: {
      position: "relative",
      flexShrink: 0,
      cursor: "pointer",
      borderRadius: 4,
      margin: "30px 20px 5px 20px",
      width: "25%",
      minWidth: "250px"
    },
    cardContainer: {
      padding: "10px 20px",
      height: "80px"
    },
    activeIndexWrapper: {
      display: "flex",
      fontSize: 20,
      fontWeight: 800,
      borderRadius: "50%",
      textAlign: "center",
      lineHeight: "normal",
      height: "50px",
      maxWidth: "50px",
      justifyContent: "center",
      alignItems: "center",
      width: "50px",
      flexShrink: 0,
      // margin: "16px 0px 16px 20px",
      [theme.breakpoints.only("sm")]: {
        padding: "6px",
        lineHeight: "41px"
      },
      [theme.breakpoints.down('sm')]: {
        padding: "7px",
        lineHeight: "0px",
        color: "black !important",
        backgroundColor: "transparent !important"
      }
    },
    activeEllipsis: {
      background: "#5e72e4"
    },
    propertyAddressWrapper: {
      // overflow: "hidden",
      // textOverflow: "ellipsis",
      // maxLines: 2,
      // paddingLeft: "10px",
      // wordWrap: "break-word",
      // lineHeight: 1.67,
      // marginTop: "5px",
      // maxHeight: "3.34em",
      display: "inline-block",
      padding: "0 5px 0 10px",
      fontSize: 12,
      fontWeight: 600
    }
  })
);

export function formatPropertyAddress(
  propertyLocation: any,
  onlyStreetAddress?: boolean
) {
  if (propertyLocation) {
    const { address, city, state, zipCode } = propertyLocation;
    if (onlyStreetAddress) {
      if (address && typeof address === "object") {
        if (address.street_line) return `${address.street_line}`;
        return "";
      }
      if (address && address !== "") {
        return `${address}`;
      }
    }
    if (address && typeof address === "object") {
      if (address.street_line)
        return `${address.street_line}, ${city}, ${state}, ${zipCode}`;
      return "";
    }
    if (address && address !== "") {
      return `${address}, ${city}, ${state}, ${zipCode}`;
    }
  }
  return "";
}

export interface PropertyDetailsCardProps {
  onPropertyClicked: (id: string) => void;
  activePropertyId: string;
  propertyDetails: {
    propertyId: string;
    propertyLocation: { errors: number | null; payload: PropertyLocation };
    propertyInformation?: {
      errors: number | null;
      payload: PropertyInformation;
    };
    propertyEconomics?: {
      errors: number | null;
      payload: UpdatedPropertyEconomics;
    };
    propertyDefaultName?: string | null;
  };
  index: number;
  aggregateTab?: boolean;
}

export function PropertyDetailsCard(props: PropertyDetailsCardProps) {
  const validateForm = useSelector<RootState, { validate: boolean }>(
    state => state.createLoanStore.validateForm
  );
  const { loanDetails } = useSelector<RootState, any>(
    state => state.createLoanStore
  );
  const { loanSizerType, loanRuleVersions, bridgeIsGroundUpLoan } = loanDetails;
  const classes = useStyles();

  const {
    onPropertyClicked,
    activePropertyId,
    propertyDetails,
    index,
    aggregateTab
  } = props;
  const location = propertyDetails?.propertyLocation?.payload;
  const otherAddresses = useSelector<RootState, LocationSuggestion[]>(state =>
    state.createLoanStore.loanDetails.propertyDetails.map((item: any) => {
      if (item.propertyId === propertyDetails.propertyId) {
        return null;
      }
      if (typeof item?.propertyLocation?.payload?.address === "string") {
        return {
          city: item?.propertyLocation?.payload?.city,
          state: item?.propertyLocation?.payload?.state,
          street_line: item?.propertyLocation?.payload?.address,
          zipcode: item?.propertyLocation?.payload?.zipCode
        };
      }
      return item?.propertyLocation?.payload?.address;
    })
  );
  const { loanStage, loanType } = useParams<any>() as any;
  let errors = 0;
  if (!aggregateTab) {
    if (loanStage !== LoanStage.fes) {
      const propertyLocationError = Object.values(
        computePropertyLocationError(
          propertyDetails?.propertyLocation?.payload,
          otherAddresses
        )
      ).filter(item => item !== "").length;
      const propertyInfoAndEconomicsError = computePropertyError(
        { ...propertyDetails, loanRuleVersions },
        loanType,
        loanStage,
        bridgeIsGroundUpLoan,
        loanDetails
      );
      errors = propertyInfoAndEconomicsError + propertyLocationError;
    } else {
      const propertyLocationError = Object.values(
        computePropertyLocationError(
          propertyDetails?.propertyLocation?.payload,
          otherAddresses
        )
      ).filter(item => item !== "").length;
      const propertyInfoAndEconomicsError = calculateFesPropertyError(
        propertyDetails,
        loanType,
        loanSizerType
      );
      errors = propertyInfoAndEconomicsError + propertyLocationError;
    }
  }

  return (
    <div
      className={classes.cardWrapper}
      id={`${propertyDetails.propertyId}ref`}
      style={{
        backgroundColor:
          activePropertyId === propertyDetails.propertyId
            ? "#5e72e4"
            : "#ffffff",
        boxShadow:
          activePropertyId === propertyDetails.propertyId
            ? "unset"
            : "0 2px 12px 0 rgba(50, 50, 93, 0.21)"
      }}
      onClick={() => onPropertyClicked(propertyDetails.propertyId)}
      onKeyDown={() => onPropertyClicked(propertyDetails.propertyId)}
      role="button"
      tabIndex={0}
    >
      {validateForm.validate && errors !== 0 ? (
        <img
          alt="Property Details Error"
          src={errorIcon}
          height={24}
          style={{
            position: "absolute",
            top: 3,
            right: 3,
            width: 20,
            height: 20,
            borderRadius: 16,
            padding: 2,
            backgroundColor:
              activePropertyId === propertyDetails.propertyId
                ? "#ffffff"
                : "#e9fafd"
          }}
        />
      ) : (
        <></>
      )}
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.cardContainer}
      >
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          lg={3}
          className={classes.activeIndexWrapper}
          style={{
            color:
              activePropertyId === propertyDetails.propertyId
                ? "#5e72e4"
                : "#32325d",
            backgroundColor:
              activePropertyId === propertyDetails.propertyId
                ? "#ffffff"
                : "#e9fafd"
          }}
        >
          {aggregateTab ? (
            <DomainRoundedIcon style={{ height: 24, width: 24 }} />
          ) : (
            index + 1
          )}
        </Grid>
        <Grid
          item
          xs={9}
          sm={9}
          md={8}
          lg={8}
          className="property-ellipsis-container"
        >
          <div
            className={`property-ellipsis-content ${classes.propertyAddressWrapper}`}
            style={{
              color:
                activePropertyId === propertyDetails.propertyId
                  ? "#ffffff"
                  : "#32325d"
            }}
          >
            {propertyDetails.propertyDefaultName
              ? propertyDetails.propertyDefaultName
              : formatPropertyAddress(location)}
            <div
              className={`property-ellipsis ${
                activePropertyId === propertyDetails.propertyId
                  ? classes.activeEllipsis
                  : ""
              }`}
            >
              ...
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
