import { Theme } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

export const creditEvalStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridOne: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      width: "100%",
      flexGrow: 1,
      // boxShadow: "0 2px 12px 0 rgb(50 50 93 / 21%)",
      borderRadius: 4
      // marginTop:"20px"
    },
    inputDesc: {
      padding: 0,
      color: "#32325d",
      fontWeight: "normal",
      wordBreak: "break-all"
    },
    readLessView: {
      height: "2em",
      lineHeight: "1em",
      overflow: "hidden"
    },
    readMoreView: {
      lineHeight: "1em"
    },
    expandText: {
      paddingTop: 2,
      color: "#5e72e4",
      cursor: "pointer",
      textDecoration: "underline"
    },
    addTagText: {
      fontSize: "12px",
      marginTop: "9px",
      marginBottom: "15px",
      lineHeight: 1.5,
      color: "#32325d",
      wordBreak: "break-all"
    },
    borrowerHeaderStyle: {
      display: "flex",
      alignItems: "center"
    },
    savebutton: {
      width: 148,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4"
    },
    container: {
      borderRadius: 4,
      display: "flex",
      flexDirection: "column",
      // boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      backgroundColor: "#ffffff"
    },
    sectionWrapper: {
      padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
      display: "flex"
    },
    borrowerDropdown: {
      padding: `${theme.spacing(0)} ${theme.spacing(3)}`,
      display: "flex",
      marginTop: "0px !important"
    },
    viewDetailsButton: {
      borderRadius: "4px",
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4",
      marginRight: "15px"
    },
    viewEditableButton: {
      borderRadius: "4px",
      backgroundColor: "#f7fafc",
      padding: "9px 15px 9px 23px",
      height: "36px",
      boxShadow: "none",
      color: "#32325d",
      fontSize: "12px",
      marginRight: "0px",
      "&:hover": {
        // background: "#a1c4de"
        background: "none",
        boxShadow: "none"
      }
    },
    buttonContainer: {
      [theme.breakpoints.up("sm")]: {
        textAlign: "right"
      }
    },
    headingImageContainer: {
      justifyContent: "flex-end",
      display: "flex"
    },
    scenarioHeading: {
      textAlign: "left",
      fontSize: "12px",
      fontWeight: 600,
      color: "#32325d",
      lineHeight: "18px"
      // paddingTop:"20px",
      // paddingBottom:"30px"
    },
    headingTypography: {
      textAlign: "left",
      fontSize: "14px !important",
      fontWeight: 600,
      color: "#32325d",
      lineHeight: 1.57
    },
    divider: {
      height: "1px",
      border: "solid 1px #f2f2f2",
      padding: "0px !important"
    },
    textAreaStyle: {
      height: "11.7rem",
      border: "1px solid #cad1d7 !important",
      borderRadius: "4px !important",
      fontFamily: "'Open Sans', sans-serif !important",
      width: "100%",
      padding: "14px 12px 14px 12px",
      fontSize: "14px",
      background: "transparent",
      "&:disabled": {
        cursor: "no-drop"
      }
    },
    inputLabel: {
      fontFamily: "'Open Sans', sans-serif !important",
      color: "#32325d",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      fontSize: "14px",
      // paddingBottom: 7
      marginLeft: "0",
      marginBottom: "15px !important"
    },
    uploadContainer: {
      minHeight: "150px",
      maxHeight: "170px",
      overflow: "auto",
      border: "1px solid #cad1d7",
      borderRadius: "4px"
    },
    uploadListContainer: {
      padding: "8px 8px 0 8px",
      maxHeight: "135px",
      overflowY: "auto"
    },
    uploadItem: {
      display: "flex",
      backgroundColor: "#f7fafc",
      borderRadius: "3px",
      fontSize: "12px",
      padding: "6px 10px",
      alignItems: "center",
      marginBottom: "2px",
      justifyContent: "space-between",
      width: "100%"
    },
    uploadItemDetail: {
      fontWeight: "normal"
    },
    subSection: {
      paddingTop: 24,
      paddingLeft: 24
    },
    subSectionLabel: {
      fontSize: 12,
      color: "#32325d",
      fontWeight: "bold"
    },
    passedTestWrapper: {
      display: "flex",
      flexDirection: "row",
      borderRadius: 4,
      height: "100%",
      border: "1px solid rgb(233, 236, 239)",
      width: "100%",
      padding: "14px 16px 14px 12px",
      fontFamily: "OpenSans"
    },
    testNameText: {
      fontSize: 12,
      color: "#32325d"
    },
    valueText: {
      fontSize: "14px",
      lineHeight: 1.57,
      color: "#32325d",
      fontFamily: "OpenSans",
      fontWeight: "normal",
      paddingTop: "10px"
    },
    passLabel: {
      borderRadius: "6px",
      marginRight: "10px",
      backgroundColor: "#2ece89",
      padding: "2px 4px",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      maxWidth: "70px"
    },
    failedLabel: {
      borderRadius: "6px",
      backgroundColor: "#f75676",
      padding: "2px 4px",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      maxWidth: "70px"
    },
    otherLabel: {
      //used for NA
      borderRadius: "6px",
      backgroundColor: "#fa9d00",
      padding: "2px 4px",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      maxWidth: "70px"
    },
    tooltip: {
      color: "#32325d",
      backgroundColor: "white",
      fontFamily: `"Open Sans", sans-serif !important`,
      fontSize: 12,
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      width: "170px",
      padding: "9px"
    },
    arrow: {
      color: "white"
    },
    passIcon: {
      width: 16,
      height: 16,
      color: "white"
    },
    passText: {
      fontFamily: "OpenSans",
      fontSize: "10px",
      color: "#ffffff"
    },
    errorIcon: {
      width: 16,
      height: 16
    },
    commentsTextContainer: {
      display: "block",
      height: "calc(32px + 5px)",
      lineHeight: "18px",
      overflow: "hidden",
      position: "relative",
      whiteSpace: "normal"
    },
    commentsText: {
      display: "block",
      position: "relative",
      maxHeight: "32px",
      wordBreak: "break-word",
      fontSize: "13px",
      fontWeight: "normal",
      color: "#32325d"
    },
    ellipsis: {
      position: "absolute",
      right: "0",
      top: "calc(48px + 2px - 100%)",
      textAlign: "left",
      background: "white",
      width: "15px"
    },
    commentsExpands: {
      height: "100%",
      maxHeight: "200px",
      overflowY: "auto"
    },
    readMoreText: {
      color: "#5e72e4",
      cursor: "pointer",
      fontSize: "12px",
      textDecoration: "underline"
    },
    calculatedOutput: {
      marginLeft: 6,
      // textAlign: "left",
      fontSize: 10,
      fontWeight: "bold",
      color: "#172b4d",
      // lineHeight: 1.8,
      borderRadius: 9,
      backgroundColor: "#d9efff",
      padding: "0 6px",
      height: 16,
      marginTop: 2,
      [theme.breakpoints.down("md")]: {
        marginLeft: "0px",
        marginTop: 10
      },
      [theme.breakpoints.only("sm")]: {
        marginTop: 4,
        marginLeft: "5px"
      }
    },
    gridTwo: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      width: "100%",
      flexGrow: 1,
      borderRadius: 4,
      "& > *": {
        marginBottom: "24px",
        boxShadow: "0 2px 12px 0 rgb(50 50 93 / 21%)"
      },
      marginTop: "20px"
    }
  })
);

export const useFormLabelStyles = makeStyles((theme: Theme) => ({
  root: {
    "&.MuiFormControlLabel-root": {
      padding: "12px 0px 12px 33px",
      alignItems: "center",
      width: "100%",
      marginBottom: "1.5%"
    }
  },
  label: {
    fontSize: 12,
    color: "#8897aa",
    fontWeight: 600
  }
}));
