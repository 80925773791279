/* eslint-disable array-callback-return */
import * as React from "react";
import { useSelector } from "react-redux";

import { Grid, Chip } from "@mui/material";
import { Theme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { RootState } from "../../../../stores/rootReducer";
import { getCookie } from "../../../../utils/cookies";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chipItem: {
      backgroundColor: "#11ceef",
      borderRadius: 6,
      color: "#ffffff",
      marginRight: 4
    }
  })
);

export const UserTagList = (props: any) => {
  const classes = useStyles();
  const { userList } = props;
  const userTags = useSelector<RootState, any>(
    state => state.scenarioResultsStore.userLists
  );

  const getPartIdName = (id: any) => {
    let name: any = "";
    const currentPersonId = getCookie("person_id");
    const currentUserName = getCookie("displayUserName");
    userTags.filter((value: any) => {
      if (value.id === id) {
        name = value.name;
      } else if (currentPersonId === id) {
        name = currentUserName;
      }
    });
    return name;
  };

  return (
    <Grid>
      {userList.map((item: any) => (
        <Chip
          key={item.id}
          size="small"
          label={getPartIdName(item.partyId)}
          classes={{ root: classes.chipItem }}
        />
      ))}
    </Grid>
  );
};
