import React from "react";
import { Checkbox, TableCell, TableRow } from "@mui/material";
import { ObjectType } from "./common";

interface TableRowProps {
  gridTemplate: string;
  classes: any;
  row: ObjectType;
  columns: ObjectType[];
  isCollapsed?: boolean;
  showCollapseButton?: boolean;
  collapsColumnIndex?: number;
  expandColumnIndex?: number;
  onSelectedColor?: string;
  onRowSelect: Function;
  showCheckBox: boolean;
  idKey: string;
  selected: boolean;
  disableRowSelection: boolean;
  from?: string;
  rowWithSerialNumber: ObjectType;
  lastSelectedRowSerialNumber: any;
  updateLastSelectedRowSerialNumber: Function;
  setSequenceRows: any;
  handleDropdownSelection?: any;
  onLinkClick?: any;
  editClick?: any;
  deleteClick?: any;
  tableTextstyle?: any;
  customTableRowStyle?: any;
}

const TableRowCell = React.memo((props: any) => {
  const {
    columns,
    row,
    classes,
    isCollapsed,
    showCollapseButton,
    collapsColumnIndex,
    expandColumnIndex,
    from,
    selected,
    showCheckBox,
    handleDropdownSelection,
    onLinkClick,
    editClick,
    deleteClick,
    tableTextstyle
  } = props;
  let cumulativeWidth = 0;
  return columns.map((col: ObjectType, index: number) => {
    if (col?.frozen) {
      if (index === 0) {
        cumulativeWidth = showCheckBox ? 50 : 0;
      } else {
        cumulativeWidth += columns[index - 1].width;
      }
    }
    return (
      <TableCell
        key={col.id}
        component="div"
        className={`${col?.rowClass ?? ""} ${col?.frozen ? classes.stickyRow : ""
      }`}
        style={{
          ...(col?.style ? col?.style : {}),
          ...(col?.frozen && {
            left: cumulativeWidth,
            boxShadow:
              (col?.expandBtn && isCollapsed) ||
                (showCollapseButton && collapsColumnIndex === index) ||
                ((isCollapsed || !showCollapseButton) &&
                  expandColumnIndex === index)
                ? "5px 0px 5px -1px #dedede"
                : undefined
          }),
          backgroundColor:
            selected && from === "masterView" ? "#fff9d9" : undefined,
          width: col.width || "auto"
        }}
      >
        {col?.renderCustom ? (
          col.renderCustom(
            row,
            classes,
            handleDropdownSelection,
            onLinkClick,
            editClick,
            deleteClick
          )
        ) : (
          <span
            className="text-truncate"
            title={row?.[col?.id]}
            style={tableTextstyle}
          >
            {row?.[col?.id]}
          </span>
        )}
      </TableCell>
    );
  });
});

const TableSelectCell = React.memo((props: any) => {
  const {
    classes,
    row,
    idKey,
    selected,
    onRowSelect,
    disableRowSelection,
    from,
    rowWithSerialNumber,
    lastSelectedRowSerialNumber,
    updateLastSelectedRowSerialNumber,
    setSequenceRows
  } = props;

  const handleCheckBox = (e: any) => {
    if (lastSelectedRowSerialNumber.length === 0) {
      updateLastSelectedRowSerialNumber(
        rowWithSerialNumber.serialNumber,
        "push"
      );
    } else {
      updateLastSelectedRowSerialNumber(null, "pop");
    }
    const sequenceGenerator = (start: number, end: number) => {
      const sequenceArray = [];
      for (let i = end - 1; i > start; i -= 1) {
        sequenceArray.push(i);
      }
      setSequenceRows(sequenceArray);
    };

    if (e.nativeEvent.shiftKey) {
      if (rowWithSerialNumber.serialNumber > lastSelectedRowSerialNumber) {
        sequenceGenerator(
          lastSelectedRowSerialNumber,
          rowWithSerialNumber.serialNumber
        );
      }
      if (lastSelectedRowSerialNumber > rowWithSerialNumber.serialNumber) {
        sequenceGenerator(
          rowWithSerialNumber.serialNumber,
          lastSelectedRowSerialNumber
        );
      }
    }
    updateLastSelectedRowSerialNumber(rowWithSerialNumber.serialNumber, "push");

    onRowSelect(e, [row?.[idKey]], [row]);
  };

  return (
    <TableCell
      className={classes.stickyRow}
      style={{
        backgroundColor:
          selected && from === "masterView" ? "#fff9d9" : undefined
      }}
    >
      <Checkbox
        disableRipple
        id={row?.[idKey]}
        checked={selected}
        onChange={handleCheckBox}
        classes={{
          checked: classes.resolvedCheckboxColor,
          root: classes.checkboxBase
        }}
        className={classes.stickyRow}
        disabled={disableRowSelection}
      />
    </TableCell>
  );
});

export const TableCustomRow = React.memo((props: TableRowProps) => {
  const {
    gridTemplate,
    classes,
    row,
    onSelectedColor,
    showCheckBox,
    idKey,
    from,
    selected,
    columns,
    isCollapsed,
    showCollapseButton,
    collapsColumnIndex,
    expandColumnIndex,
    onRowSelect,
    disableRowSelection,
    rowWithSerialNumber,
    lastSelectedRowSerialNumber,
    updateLastSelectedRowSerialNumber,
    setSequenceRows,
    handleDropdownSelection,
    onLinkClick,
    editClick,
    deleteClick,
    customTableRowStyle = {}
  } = props;
  
  return (
    <TableRow
      key={row?.[idKey]}
      component="div"
      className={`${classes?.tableRowGrid} ${classes?.rowHover}`}
      style={{
        gridTemplateColumns: `${gridTemplate}`,
        backgroundColor: selected ? onSelectedColor : "#ffffff",
        ...customTableRowStyle
      }}
    >
      {showCheckBox && (
        <TableSelectCell
          classes={classes}
          row={row}
          idKey={idKey}
          selected={selected}
          onRowSelect={onRowSelect}
          disableRowSelection={disableRowSelection}
          from={from}
          rowWithSerialNumber={rowWithSerialNumber}
          lastSelectedRowSerialNumber={lastSelectedRowSerialNumber}
          updateLastSelectedRowSerialNumber={updateLastSelectedRowSerialNumber}
          setSequenceRows={setSequenceRows}
        />
      )}
      <TableRowCell
        columns={columns}
        row={row}
        classes={classes}
        isCollapsed={isCollapsed}
        showCollapseButton={showCollapseButton}
        collapsColumnIndex={collapsColumnIndex}
        expandColumnIndex={expandColumnIndex}
        from={from}
        selected={selected}
        showCheckBox={showCheckBox}
        handleDropdownSelection={handleDropdownSelection}
        onLinkClick={onLinkClick}
        editClick={editClick}
        deleteClick={deleteClick}
      />
    </TableRow>
  );
});
