import { Theme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const LoanHistoryStyle = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
        "& .MuiDialogContent-root": {
          padding: "0 !important",
          overflowY: "hidden",
          height: "450px"
        },
        height: "90% !important",
        top: 0,
        bottom: 0,
        margin: "auto"
      },
      tableWrapper: {
        borderRadius: "5px",
        position: "relative"
      },
      loaderModal: {
        backgroundColor: "#ffffff",
        // width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        overflow: "hidden",
        overflowY: "auto"
      },
      closeIcon:{
        cursor: "pointer"
      }
   }))