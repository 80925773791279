import { AppBar, Grid, Theme, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import toorakLogo from "../../../images/loanlistheader/group-3.svg";
import successDocusign from "../../../images/successDocusign.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "70vh"
    },
    paperHeader: {
      backgroundImage: "linear-gradient(93deg, #0fcbef 4%, #1071ee 90%)",
      boxShadow: "none",
      paddingRight: 20,
      paddingLeft: 20
    }
  })
);

export const DocusignSuccess = () => {
  const classes = useStyles();
  return <>
    <AppBar position="static" color="default" className={classes.paperHeader}>
      <Grid container>
        <Grid item>
          <img src={toorakLogo} alt="toorak" />
        </Grid>
      </Grid>
    </AppBar>
    <div className={classes.body}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <img src={successDocusign} alt="success" />
        </Grid>
        <Grid item>
          <Typography component="h5" style={{ textAlign: "center" }}>
            You have successfully signed the Purchase Advice document.<br/>
            Please go back to the Toorak platform.
          </Typography>
        </Grid>
      </Grid>
    </div>
  </>;
};
