import { ObjectType } from "../masterView/common";
import {
  LoanSubmissionDateFilter,
  RateLockExpiryDateFilter
} from "./LoanSubmissionAndRateLockFilter";

export const getFilters = (
  filtersData: ObjectType,
  selectedFiltersData: ObjectType,
  selectedView: number,
  handleCheckBox?: Function,
  handleSelectAll?: Function,
  setRange?: Function,
  handleSearch?: Function
) => {
  const loanSubmissionDateFilter = {
    id: "loanSubmissionDate",
    label: "Loan Submition Date",
    custom: () =>
      LoanSubmissionDateFilter({
        range: {
          loanSubmissionDate: selectedFiltersData.loanSubmissionDate
        },
        setRange
      })
  };
  const rateLockExpiryDateFilter = {
    id: "rateLockExpiryDate",
    label: "Rate Lock Expiry Date",
    custom: () =>
      RateLockExpiryDateFilter({
        range: {
          rateLockExpiryDate: selectedFiltersData.rateLockExpiryDate
        },
        setRange
      })
  };
  const originatorFilter = {
    id: "originator.accountName.raw",
    label: "Originators",
    config: {
      filterKey: "originator.accountName.raw",
      showSelectAll: true,
      showSearch: true,
      handleCheckBox,
      handleSelectAll,
      optionsList: filtersData,
      checkedOptions: selectedFiltersData?.["originator.accountName.raw"] ?? []
    },
    type: "multiCheckBox"
  };
  const loanStatusFilter = {
    id: "loan.loanState.raw",
    label: "Loan Status",
    config: {
      optionsList: [
        {
          id: "Initial Review - Approved",
          label: "Initial Review - Approved"
        },
        {
          id: "Purchase Initiated",
          label: "Purchase Initiated"
        },
        {
          id: "Purchased",
          label: "Purchased"
        }
      ],
      checkedOptions: selectedFiltersData?.["loan.loanState.raw"] ?? [],
      filterKey: "loan.loanState.raw",
      showSelectAll: true,
      handleCheckBox,
      handleSelectAll
    },
    type: "multiCheckBox"
  };
  const loanStageFilter = {
    id: "loan.loanStage.raw",
    label: "Loan Stage",
    config: {
      optionsList: [
        {
          id: "PRE",
          label: "Pre-Close"
        },
        {
          id: "POST",
          label: "Post-Close"
        },
        {
          id: "FES",
          label: "Guideline Evaluation"
        }
      ],
      checkedOptions: selectedFiltersData?.["loan.loanStage.raw"] ?? [],
      filterKey: "loan.loanStage.raw",
      showSelectAll: true,
      handleCheckBox,
      handleSelectAll
    },
    type: "multiCheckBox"
  };

  return [
    loanStatusFilter,
    loanStageFilter,
    loanSubmissionDateFilter,
    rateLockExpiryDateFilter,
    originatorFilter
  ];
};
