/* eslint-disable no-nested-ternary */
import React, { useState, useRef, useMemo } from "react";
import { useParams } from "react-router-dom";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import {
  Typography,
  Grid,
  // makeStyles,
  // createStyles,
  // IconButton,
  // InputBase,
  Button
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import {
  ConfirmFormModal,
  LoanStage,
  WarningModal
} from "@toorak/tc-common-fe-sdk";
import ReactToPrint from "react-to-print";
// import SearchIcon from "@mui/icons-material/Search";
import { useSelector, useDispatch } from "react-redux";
import exportStartIcon from "../../images/loanlistheader/uil-file-export-2.svg";
// import { AllExceptionsAndEnquiries } from "./AllExceptionsAndEnquiries";
// import { AllExceptions } from "./AllExceptions";
// import { AllInquiriesTC } from "./AllInquiriesTC";
import { RootState } from "../../stores/rootReducer";
import { ExportHeader } from "../exportHeader";
import { isILP, isLATUser } from "../../utils/AccessManagement";
import { ExportConditionsAndWaivers } from "../dashboard/dashboardFilter/ExportConditionsAndWaivers";
import { openRescindSuccessDialog } from "../create-loan.action";
import { isViewOnly } from "../../utils/formatChecks";
import { LoanEnquiryCard } from "./LoanEnquiryCard";
import { getConfig, getLoanType } from "../../config/config";
import { ObjectType } from "../../masterView/common";

const AllExceptions = React.lazy(() =>
  import("./AllExceptions").then((module) => ({
    default: module.AllExceptions
  }))
);

const AllExceptionsAndEnquiries = React.lazy(() =>
  import("./AllExceptionsAndEnquiries").then((module) => ({
    default: module.AllExceptionsAndEnquiries
  }))
);

const AllInquiriesTC = React.lazy(() =>
  import("./AllInquiriesTC").then((module) => ({
    default: module.AllInquiriesTC
  }))
);
const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: "24px 20px 0px 24px"
    },
    containerSearch: {
      padding: "15px 24px 18px"
    },
    wrapper: {
      display: "flex",
      backgroundColor: "#FFF",
      borderBottom: "solid 1px #f2f2f2",
      // marginBottom: "10px"
      boxShadow: "0 2px 12px 0 rgb(50 50 93 / 21%)"
    },
    searchButton: {
      padding: "10px 7px 7px 7px",
      width: "16px",
      height: "16px",
      color: theme.palette.secondary.light
    },
    searchIcon: {
      width: "16px",
      height: "16px"
    },
    input: {
      fontSize: "14px !important",
      color: theme.palette.text.primary
    },
    searchContainer: {
      alignSelf: "flex-end",
      flex: 1,
      display: "flex",
      alignItems: "center",
      // borderRadius: "20px",
      // backgroundColor: theme.palette.background.default,
      padding: "0px 16px !important",
      maxWidth: "290px !important",
      height: "36px !important"
      // border: "1px solid #E9ECEF"
    },
    viewDetailsConatiner: {
      padding: "0px 14px 14px 14px"
    },
    viewDetailsbutton: {
      borderRadius: "4px",
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4",
      width: "max-content",
      marginRight: 10
    },
    tabMobileView: {
      maxWidth: "132px",
      [theme.breakpoints.down("sm")]: {
        marginRight: "20px !important",
        "&:last-child": {
          marginRight: "0 !important"
        }
      },
      [theme.breakpoints.down("lg")]: {
        marginRight: "20px !important"
      }
    },
    conditionsTabContainer: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        padding: "24px 10px 0px 12px !important",
        maxWidth: "100%",
        flexBasis: "100%"
      }
    },
    headerButtonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    exportButton: {
      color: "#32325d !important",
      width: "83px",
      height: "28px",
      padding: "6px 13px 6px 7px",
      borderRadius: "4px",
      boxShadow: "0 3px 4px 0 rgba(0, 0, 0, 0.14)",
      border: "solid 1px #ececec",
      backgroundColor: "#fff !important"
    }
  })
);
export const ExportStartIcon = () => {
  return (
    <>
      <img src={exportStartIcon} alt="export-start-icon" />
    </>
  );
};
export function ExceptionsTabsPanel() {
  const [currentlySelectedTab, setCurrentlySelectedTab] = useState<number>(0);
  const classes = useStyles();
  const ref3 = useRef(null);
  const dispatch = useDispatch();
  const { rescindDialog, isWithdrawnLoan } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );
  const { takeoutPartnerId } = useSelector<RootState, any>(
    (state) =>
      state.createLoanStore?.loanDetails.thirtyYearLoanInformation.payload
  );
  const componentRef: any = useRef<HTMLButtonElement>(null);
  // const exceptionsResult = useSelector<RootState, { exceptions: any[] | any }>(
  //   state => state.exceptionsResultsStore?.exceptionsResult?.exceptions
  // );

  const { exceptions } = useSelector<RootState, any>(
    (state) => state.exceptionsResultsStore.filteredExceptionsArray
  );
  const { loanId, loanStage, loanType } = useParams<{
    loanId: string;
    loanType: string;
    loanStage: LoanStage;
  }>() as any;
  const exportFromConditionsAndInquiriesTab =
    window.location.href.indexOf("createLoan") !== -1;
  const isDSCRLoan = loanType === getLoanType[1].displayValue;
  const sortedExceptionResultsOrg = useMemo(() => {
    if (!(exceptions instanceof Array)) return exceptions;
    return !isLATUser()
      ? exceptions?.filter((item: any) => item.visibility !== "internal")
      : exceptions;
  }, [exceptions]);

  const filterOnPartner = (exceptionArr: ObjectType[], tp: string) =>
    exceptionArr.filter((item: ObjectType) => item.takeoutPartnerId === tp);

  let countAll =
    sortedExceptionResultsOrg instanceof Array
      ? sortedExceptionResultsOrg.length
      : 0;
  if (isDSCRLoan && countAll !== 0) {
    countAll = filterOnPartner(
      sortedExceptionResultsOrg,
      takeoutPartnerId
    ).length;
  }
  const filterExternal = (item: any) =>
    item.type.toLowerCase() === "exception" ||
    item.type.toLowerCase() === "exceptions" ||
    (item.type.toLowerCase() === "inquiry" &&
      item.visibility.toLowerCase() === "external");

  const countAllExceptions =
    sortedExceptionResultsOrg instanceof Array
      ? isDSCRLoan
        ? filterOnPartner(sortedExceptionResultsOrg, takeoutPartnerId).filter(
            filterExternal
          ).length
        : sortedExceptionResultsOrg.filter(filterExternal).length
      : 0;
  // if (isDSCRLoan && countAllExceptions !== 0) {
  //   countAllExceptions = filterOnPartner(
  //     sortedExceptionResultsOrg,
  //     takeoutPartnerId
  //   ).length;
  // }
  const filterInternalAndInquiry = (item: any) =>
    item.type.toLowerCase() === "inquiry" &&
    item.visibility.toLowerCase() === "internal";
  const countAllEnquiries =
    sortedExceptionResultsOrg instanceof Array
      ? isDSCRLoan
        ? filterOnPartner(sortedExceptionResultsOrg, takeoutPartnerId).filter(
            filterInternalAndInquiry
          ).length
        : sortedExceptionResultsOrg.filter(filterInternalAndInquiry).length
      : 0;
  // if (loanType === getLoanType[1].displayValue && countAllEnquiries !== 0) {
  //   countAllEnquiries = filterOnPartner(
  //     sortedExceptionResultsOrg,
  //     takeoutPartnerId
  //   ).length;
  // }

  const exportTabs = [
    {
      title: "Export Conditions",
      category: "conditions",
      tab: "all"
    },
    {
      title: "Export Inquiries",
      category: "inquiries",
      tab: "all"
    }
  ];
  const tabs = !isLATUser()
    ? [
        {
          title: `Conditions (${countAll})`,
          component: () => (
            <React.Suspense fallback={null}>
              <AllExceptionsAndEnquiries />
            </React.Suspense>
          )
        }
      ]
    : [
        {
          title: `All (${countAll})`,
          component: () => (
            <React.Suspense fallback={null}>
              <AllExceptionsAndEnquiries />
            </React.Suspense>
          )
        },
        {
          title: `Conditions (${countAllExceptions})`,
          component: () => (
            <React.Suspense fallback={null}>
              <AllExceptions />
            </React.Suspense>
          )
        },
        {
          title: `Inquiries (${countAllEnquiries})`,
          component: () => (
            <React.Suspense fallback={null}>
              <AllInquiriesTC />
            </React.Suspense>
          )
        }
      ];

  const getCurrentTab = (tab: any) => {
    if (tab === 0) return "all";
    if (tab === 1) return "exceptions";
    return "inquiries";
  };
  const defaultMsg: string =
    "The information provided in this system is for guidance purposes only and should not be considered as a commitment to buy any loan. All loan acquisitions, including the related pricing and terms, are subject to mutual execution of the related purchase advice following Toorak’s satisfactory completion of due diligence. All loan acquisitions are made pursuant to the applicable master loan purchase agreement.";
  const getPageMargins = () => {
    return `@page { 
      margin: ${10} ${10} ${10} ${10} !important;
      size: 210mm 297mm;
    }`;
  };

  const [openFormModel, setOpenFormModel] = useState<boolean>(false);

  const handleClick = () => {
    // dispatch(
    //   push(`/loan/create/${loanType}/${loanStage}/${loanId}/create/inquiry`)
    // );
    setOpenFormModel(true);
  };

  const closeModal = () => {
    setOpenFormModel(false);
  };

  return (
    <>
      <ConfirmFormModal
        isOpen={openFormModel}
        handleClose={(event: any, reason: string) => {
          if (reason === "backdropClick") return;
          closeModal();
        }}
        confirmCondition={false}
        header="Create LBT Condition"
        body={
          <LoanEnquiryCard
            label="Condition Type"
            descriptionLabel="Condition Details"
            closeModal={closeModal}
            type="EXCEPTION"
            status="OPEN"
          />
        }
        hidePrimaryBtn
        hideSecondaryBtn
        size="sm"
        disableBackdropClick
        footerStyles={{
          marginTop: "0px",
          padding: "0px"
        }}
        headerStyles={{
          paddingBottom: "0px"
        }}
      />
      <WarningModal
        isOpen={rescindDialog}
        handleClose={() => {
          dispatch(openRescindSuccessDialog(false));
        }}
        primaryBtnName="OK"
        header="Waiver Rescinded Successfully"
        body="This waiver has been rescinded successfully."
      />
      <div
        data-testid="loanlist-container"
        ref={componentRef}
        style={{ width: "100%", marginLeft: "20px", zIndex: 999 }}
      >
        {/* <div ref={ref3} style={{ position: "relative" }}>
            <section > */}
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          //  ref={componentRef}
          style={{ position: "relative" }}
        >
          <div
            ref={ref3}
            style={{ position: "relative" }}
            className="print-sourced"
          >
            <ExportHeader />
          </div>
          <style>{getPageMargins()}</style>
          <div className="page-break" />

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={`${classes.wrapper} conditionTabPrint`}
          >
            <Grid
              item
              className={`${classes.container} ${classes.conditionsTabContainer} disable-tab-print`}
              xs={8}
              sm={9}
              // md={5}
              // lg={5}
              // xl={5}
              data-testid="wrapper-tabs-panel"
            >
              {tabs.map((item, index) => (
                <Grid
                  key={item.title}
                  style={{
                    marginRight: 35
                  }}
                  className={classes.tabMobileView}
                >
                  <Grid style={{ display: "flex", justifyContent: "center" }}>
                    <Typography
                      onClick={() => {
                        setCurrentlySelectedTab(index);
                      }}
                      variant="h5"
                      style={{
                        cursor: "pointer",
                        color: "#32325d",
                        fontSize: 14,
                        fontWeight: 600
                      }}
                    >
                      {item.title}
                    </Typography>
                  </Grid>
                  {isLATUser() && (
                    <Grid
                      style={{
                        height: 0,
                        borderRadius: 6,
                        borderStyle: "solid",
                        borderColor: "#5e72e4",
                        margin: "13px 8px 0px 8px",
                        borderWidth: 2,
                        opacity: index === currentlySelectedTab ? 1 : 0,
                        padding: "0px 16px",
                        background: "#5e72e4"
                      }}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
            <Grid
              item
              xs={4}
              sm={3}
              // md={7}
              // lg={7}
              // xl={7}
              // container
              justifyContent="flex-end"
              className={`${classes.containerSearch} ${classes.headerButtonContainer}`}
            >
              {isLATUser() && !isWithdrawnLoan && (
                <>
                  <Grid item>
                    <Button
                      variant="contained"
                      size="small"
                      data-testid="create-manual-inquiry-tc-btn"
                      className={classes.viewDetailsbutton}
                      onClick={handleClick}
                      disabled={isViewOnly(loanStage, loanId)}
                      color="info"
                    >
                      Create LBT Condition
                    </Button>
                  </Grid>
                  <Grid item>
                    {!isILP() && (
                      <Button
                        variant="contained"
                        size="small"
                        data-testid="create-manual-inquiry-tc-btn"
                        className={classes.viewDetailsbutton}
                        color="info"
                        onClick={() => {
                          if (loanType && loanStage && loanId) {
                            window.open(
                              `${
                                getConfig().redirectUrl
                              }internal/loan/createLoan/${loanType}/${loanStage}/${loanId}`
                            );
                          }
                        }}
                      >
                        Go to ILP
                      </Button>
                    )}
                  </Grid>
                </>
              )}
              {currentlySelectedTab === 0 && window.innerWidth >= 960 && (
                <span style={{ margin: "0px 0px" }}>
                  <ExportConditionsAndWaivers
                    tabs={exportTabs}
                    selectedTab={getCurrentTab(currentlySelectedTab)}
                    exportFromConditionsAndInquiriesTab={
                      exportFromConditionsAndInquiriesTab
                    }
                    loanId={loanId}
                    loanStage={loanStage}
                    countOfExceptions={countAllExceptions}
                    countOfEnquiries={countAllEnquiries}
                  />
                </span>
              )}
              {/* <IconButton
              type="submit"
              className={classes.searchButton}
              aria-label="search"
            >
              <SearchIcon className={classes.searchIcon} />
            </IconButton>
            <InputBase
              className={classes.input}
              type="search"
              placeholder="Type Keyword"
              inputProps={{ "aria-label": "Type Keyword" }}
            /> */}
              <ReactToPrint
                documentTitle={`Toorak Capital Partners | Conditions & Inquiries Report | ${loanId}`}
                trigger={() => (
                  <Button
                    className="export-button"
                    variant="contained"
                    size="small"
                    style={{ marginLeft: "10px", display: "flex" }}
                    startIcon={<PrintOutlinedIcon />}
                    color="info"
                  >
                    Print
                  </Button>
                )}
                content={() => componentRef.current}
                copyStyles
              />
            </Grid>
          </Grid>
          <Grid className="disable-border-print" />
          <div className="content-wrapper">
            {tabs[currentlySelectedTab].component()}
          </div>
        </Grid>
        {/* </section>
          </div> */}
        <div className="page-break" />
        <div
          className="print-sourced"
          ref={ref3}
          style={{ position: "relative", bottom: "0", width: "100%" }}
        >
          <Typography
            style={{
              top: 0,
              fontSize: 12,
              color: "#ffffff",
              lineHeight: "16px",
              fontWeight: "normal",
              width: "100%",
              padding: "6px 20px 6px 20px",
              backgroundColor: "#6A7490"
            }}
          >
            {defaultMsg}
          </Typography>
        </div>
      </div>
    </>
  );
}
