/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { ErrorModalStyle } from "../ErrorModalStyle";
import { clearErrors } from "../../stores/loaderAndException/loaderAndException.action";

export enum FlashMessageType {
  error = "error",
  success = "success"
}
export const FlashMessages = (props: {
  id: string;
  type: FlashMessageType;
  errorMessage: any;
}) => {
  const classes = ErrorModalStyle();
  const { errorMessage, type, id } = props;
  const dispatch = useDispatch();
  const getIcon = () => {
    switch (type) {
      case FlashMessageType.error:
        return <WarningIcon style={{ color: "#f75676" }} />;
      case FlashMessageType.success:
        return <CheckCircleIcon style={{ color: "#2ece89" }} />;
      default:
        return null;
    }
  };
  useEffect(() => {
    setTimeout(() => {
      dispatch(clearErrors({ errorId: id, autoClose: true }));
    }, 5000);
    // eslint-disable-next-line
  }, [id]);
  return (
    <div className={classes.inlineMessageDiv} data-testid="flashMessageWrapper">
      <Grid container direction="row" key={id}>
        <Grid item xs={1}>
          {getIcon()}
        </Grid>
        <Grid item xs={11} className={classes.paddingLeft5}>
          <span>{errorMessage} </span>
        </Grid>
      </Grid>
    </div>
  );
};
