import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

export const viewlabelStyles: React.CSSProperties = { fontWeight: 600 };
export const style: React.CSSProperties = { maxWidth: 180 };
export const viewtextStyles: React.CSSProperties = {
  fontSize: "12px",
  lineHeight: 1.5,
  color: "#32325d",
  marginTop: "10px"
};

export const useDrawsStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropDownClass: {
      width: "85%",
      "& .MuiFormControl-fullWidth": {
        width: "100%",
        background: "#ffffff",
        color: "#000000",
        height: "36px"
      },
      boxShadow: "none",
      "& .Mui-focused": {
        boxShadow: "none",
        "& .MuiAutocomplete-inputRoot": {
          boxShadow: "none"
        }
      }
    },
    drawHeaderSelect: {
      "& .MuiTypography-body1": {
        fontSize: 12
      }
    },
    assignToDD: {
      height: 30,
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      borderTop: "1px solid lightgrey"
    },
    clearButton: {
      marginLeft: "20px",
      color: "#5e72e4",
      "&:hover": {
        backgroundColor: "inherit !important",
        color: "#5e72e4 !important"
      }
    },
    textAreaClass: {
      "& textarea": {
        padding: "0px !important"
      },
      "& .Mui-focused textarea": {
        boxShadow: "none !important"
      }
    },
    editBody: {
      color: "black"
    },
    docsImportBtn: {
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4",
      color: "#fff"
    },
    topInsightContainer: {
      marginTop: "10px"
    },
    editTextAreastyles: {
      width: "600px",
      minHeight: "100px",
      display: "flex",
      marginLeft: "20px"
    },

    deleteTextAreaStyles: {
      width: "325px",
      minHeight: "55px",
      display: "flex",
      marginLeft: "20px"
    },
    textarea: {
      width: "600px",
      minHeight: "100px",
      display: "flex",
      color: "black"
    },
    cell: {
      border: "1px ridge #DCDCDC",
      width: "50px",
      padding: "15px",
      fontSize: 13,
      fontWeight: 600
    },
    tooltipTableHead: { fontSize: 13, fontWeight: 600 },
    tooltipTableCell: { padding: "10px 0px 10px 8px" },
    editedLabel: {
      width: "75px",
      height: "30px",
      alignItems: "center",
      borderRadius: "6px",
      backgroundColor: "#fb623f",
      marginRight: "10px",
      justifyContent: "space-between",
      display: "flex"
    },
    editIcon: {
      height: "16px",
      width: "16px"
    },
    deleteIcon: {
      height: "16px",
      width: "16px"
    },
    tagTitle: {
      fontSize: "10px",
      padding: "3px",
      paddingLeft: "5px",
      fontWeight: 600,
      color: "#ffffff"
    },
    headerButton: {
      marginLeft: 24,
      marginTop: 10
      // border: "1px solid lightgrey"
    },
    addProvisionBtn: {
      marginRight: 20,
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      color: "inherit"
    },
    dropdownListContainer: {
      display: "flex",
      minWidth: "150px"
    },
    drawMoreActionIcon: {
      borderRadius: 4,
      boxShadow: "1px 2px 8px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: "#ffffff",
      padding: 0,
      width: 200,
      paddingTop: 8,
      paddingBottom: 6,
      marginTop: 32,
      position: "absolute",
      zIndex: 1,
      left: "auto"
    },
    modalContainer: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      minWidth: "400px",
      minHeight: "100px",
      justifyContent: "center"
    },
    currentRateVersion: {
      border: "solid 1px #f2f2f2",
      marginLeft: "15px",
      fontSize: "11px",
      padding: "8px 15px",
      [theme.breakpoints.down("sm")]: {
        margin: "10px 0 0 0 !important"
      }
    },
    boxTableContainer: {
      margin: "10px 0px 0px 0px",
      height: "calc(100vh - 200px)",
      boxShadow: "0 2px 12px 0 rgb(50 50 93 / 21%)",
      border: "1px solid #ddd",
      "&::-webkit-scrollbar": {
        width: 15,
        height: 15
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#c1c1d9",
        borderRadius: "10px"
      }
    },
    buttonGroup: {
      backgroundColor: "#f7fafc !important",
      fontSize: "12px !important",
      fontWeight: 400,
      height: "35px",
      borderWidth: "2px",
      borderColor: "#E5E4E2",
      padding: "10px 20px",

      letterSpacing: "0.5px",
      color: theme.palette.text.secondary,
      "&:selected": {
        backgroundColor: "#f7fafc !important"
      },
      "&:disabled": {
        backgroundColor: "#f7fafc !important",
        color: "#32325d !important",
        fontWeight: 600,
        cursor: "no-drop"
      },
      textTransform: "none"
    },
    openModalAddButton: {
      padding: "7px 15px",
      borderRadius: 4,
      color: "#fff",
      margin: "10px 30px 10px 10px",
      border: "solid 1px #ececec",
      backgroundColor: "#5e72e4",
      "&:hover": {
        backgroundColor: "#5e72e4"
      }
    },
    insightContainer: {
      borderRadius: "4px",
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      border: "solid 1px #e9ecef"
    },

    insightContainer1: {
      borderRadius: "4px",
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#FFF",
      width: "calc(100% - 80px)",
      margin: "20px",
      border: "solid 1px #e9ecef",
      height: "460px",
      marginTop: "2%"
    },
    excelDraw: {
      marginTop: "3.5%",
      height: "fit-content"
    },
    drawView: {
      height: "fit-content"
    },
    drawInfo: {
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "16px",
      color: "#0E0D0D",
      textTransform: "capitalize",
      background: "#E7E7E7",
      border: "1px solid #241D1D",
      width: "100%",
      padding: "10px 15px",
      borderRadius: "10px",
      marginBottom: "5px",
      cursor: "pointer",
      "& p": {
        marginBottom: "1px"
      }
    },
    drawInfoSelected: {
      background: "#ffffff",
      border: 0,
      display: "inline-block"
    },
    selected: {
      width: 7,
      height: 7,
      borderRadius: "50%",
      position: "relative",
      left: "100%",
      display: "flex",
      top: "-20%",
      backgroundImage:
        "linear-gradient(139deg, #0fcbef 2%, #1071ee 95%), linear-gradient(to bottom, #d8d8d8, #d8d8d8)"
    },
    drawTitle: {
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "16px",
      color: "#0E0D0D",
      textTransform: "capitalize",
      padding: "5px",
      margin: "0"
    },
    drawCount: {
      fontSize: "40px",
      fontWeight: 500,
      lineHeight: "48px",
      marginRight: "7px",
      position: "relative",
      top: "6px"
    },
    draftContainer: {
      background: "#FBFBFB",
      padding: "10px 15px",
      margin: "10px 0",
      borderRadius: "10px"
    },
    submittedContainer: {
      background: "#31BDE9",
      border: "1px solid #F8EDED",
      padding: "10px 15px",
      margin: "10px 0",
      borderRadius: "10px"
    },
    individualCount: {
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "16px",
      color: "#0E0D0D",
      textTransform: "capitalize",
      width: "100%"
    },
    excelContainer: {
      borderRadius: "4px",
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      border: "solid 1px #e9ecef",
      background: "#E4E3E3"
    },
    countContainer: {
      width: "100%",
      padding: "30px 15px 15px 15px",
      borderBottom: "1px solid #796C6C"
    },
    insightContainer2: {
      borderRadius: "4px",
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#FFF",
      width: "calc(100% - 80px)",
      margin: "20px",
      border: "solid 1px #e9ecef",
      height: "250px",
      marginTop: "2%"
    },
    bankformContainer: {
      display: "inlineblock",
      verticalAlign: "middle",
      borderRadius: "4px",
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#FFF",
      border: "solid 1px #e9ecef"
    },
    textfieldAccnum: {
      marginLeft: "15px",
      width: "300px"
      // marginTop:"20px",
    },
    addbanklabels: {
      marginLeft: "15px",
      marginTop: "10px"
    },
    asterick: {
      color: "red"
    },
    hiddenAsterick: {
      color: " #ffcccb"
    },
    secondDetailsContainer: {
      marginTop: "2%"
    },
    label: {
      marginTop: "5px",
      marginLeft: "2%"
    },
    netfundslabel: {
      marginLeft: "2%",
      color: "red"
    },
    secondContainer: {
      marginTop: "2%"
    },
    textfield: {
      width: "180px"
    },
    thirdContainer: {
      marginTop: "0%"
    },
    headers: {
      marginLeft: "2%",
      marginTop: "1%",
      fontWeight: 600
    },
    temp: {
      flexDirection: "column",
      marginLeft: "2%",
      marginTop: "1%",
      fontWeight: 600,
      width: "10px",
      height: "40px",
      border: "1px solid black"
    },
    headerLoanDetail: {
      // marginLeft: "2%",
      marginTop: "2%",
      paddingLeft: "2%",
      // marginBottom: "2%",
      fontWeight: "bold",
      borderBottom: "solid 1px #e9ecef"
    },
    headernetfund: {
      marginLeft: "2%",
      marginTop: "2%",
      fontWeight: 600
    },
    or: {
      marginTop: "3%",
      marginLeft: "2%"
    },
    disabledExportButton: {
      //  display:"none"
      // margin: "15px 40px",
      border: 0,
      backgroundColor: "white",
      fontWeight: "bold",
      color: "black",
      opacity: 0.3,
      cursor: "default !important"
    },
    disabledAssignedTo: {
      margin: "40px 40px",
      border: 0,
      backgroundColor: "white",
      fontWeight: "bold",
      color: "black",
      opacity: 0.3,
      cursor: "default !important",
      width: "100px",
      position: "relative",
      right: "150px"
    },
    expandCollapse: {
      justifyContent: "flex-end"
    },
    addnewbtn: {
      marginLeft: "3%",
      marginTop: "0.5%",
      borderRadius: "4",
      width: "90px",
      height: "36px",
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      color: "black"
    },
    SelectBankDetails: {
      width: "180px",
      height: "40px",
      marginTop: "2%",
      border: "solid 1px",
      borderColor: "black",
      borderRadius: "3px",
      marginLeft: "2%"
    },
    SelectLoanID: {
      width: "180px",
      height: "40px",
      // marginTop:"1%",
      // paddingLeft:"2%",
      // border:"solid 1px",
      borderColor: "black",
      borderRadius: "3px"
      // marginLeft:"2%"
    },
    headerDrawsDiv: {
      padding: "4px 0px 8px 10px",
      fontWeight: 600,
      color: "#32325d",
      fontSize: "14px"
    },
    saveButton: {
      width: "91px",
      height: "36px",
      borderRadius: "4",
      fontFamily: "ProximaNova",
      color: "#32325d",
      lineHeight: "1.83",
      marginRight: "8px",
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      border: "solid 1px #ececec",
      backgroundColor: "#fff"
    },
    cancelButton: {
      width: "91px",
      height: "36px",
      marginRight: "8px",
      fontFamily: "OpenSans",
      fontWeight: 600,
      color: "#32325d",
      backgroundColor: "#fff",
      "&:hover": {
        background: "none"
      },
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      border: "solid 1px #ececec"
    },
    submitButton: {
      width: "91px",
      height: "36px",
      borderRadius: "4",
      // opacity: "0.5",
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      backgroundColor: "#5f72e4"
    },

    searchIcon: {
      width: "16px",
      height: "16px",
      color: "#32325D",
      position: "relative",
      top: "1px",
      left: "3px",
      marginRight: "5px"
    },
    exportBtn: {
      margin: 12,
      height: 30,
      color: "#32325d",
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      border: "1px solid  rgba(0, 0, 0, 0.23)"
    },
    editButton: {
      width: "91px",
      height: "36px",
      fontFamily: "OpenSans",
      fontWeight: 600,
      color: "#5e72e4",
      // boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      border: "#fff"
    },
    clearSearchIcon: {
      width: "16px",
      height: "16px",
      cursor: "pointer",
      position: "relative",
      zIndex: 1
    },
    stageDD: {
      backgroundColor: "#d3d3d363",
      width: 150,
      color: "#000",
      display: "flex",
      justifyContent: "space-between",
      padding: "12px 6px"
    },
    commonDrawsListDD: {
      width: 150,
      color: "#000",
      display: "flex",
      justifyContent: "space-between",
      padding: "12px 6px"
    },
    commonListDDText: {
      marginLeft: 5,
      fontWeight: 400,
      fontFamily: "system-ui"
    },
    clearDrawButton: {
      color: "#5e72e4d9",
      marginLeft: 24,
      "&:hover": {
        backgroundColor: "inherit !important",
        color: "#5e72e4 !important"
      }
    },
    searchBox: {
      alignSelf: "flex-end",
      flex: 1,
      display: "flex",
      alignItems: "center",
      borderRadius: "20px",
      backgroundColor: "#f7fafc !important",
      padding: "0px 12px 0 4px !important",
      maxWidth: "300px !important",
      height: "36px !important",
      border: "solid 1px #E9ECEF !important",
      fontSize: "12px",
      marginBottom: "10px",
      marginLeft: "24px",
      marginTop: "10px"
    },
    createLoanHeader: {
      top: "0",
      height: "60px"
    },
    itemContainer: {
      padding: "10px 14px",
      backgroundColor: "#FFF",
      borderTop: "solid 2px #f2f2f2",
      borderBottom: "solid 2px #f2f2f2",
      margin: "10px 0px"
    },
    tabSelected: { fontWeight: 600 },
    discussionDetail: {
      height: "72% !important",
      overflowY: "scroll"
    },
    provisionDetailBody: { height: "100%" },
    discussionDetailsContainer: { maxHeight: 990, paddingRight: 0 },
    externalDiscussionHeader: {
      padding: "15px 10px",
      height: 58,
      borderBottom: "1px solid #f2f2f2"
    },
    headerContainer: {
      marginTop: "3.5%",
      padding: "20px 24px",
      backgroundColor: "#FFF"
    },
    drawHeaderDetails: {
      marginTop: "1.5%"
    },
    itemHeaderText: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "1.57",
      color: "#32325d"
    },
    itemSubHeaderText: {
      fontSize: "12px",
      lineHeight: 1.5,
      color: "#32325d",
      fontWeight: 600,
      marginLeft: 5,
      marginBottom: 5

      // marginTop: "8px"
    },
    itemValueText: {
      fontSize: "12px",
      lineHeight: 1.5,
      color: "#32325d",
      marginLeft: "5px",
      marginTop: "1px",
      marginBottom: 5
    },
    itemBox: {
      paddingTop: 6,
      borderRadius: "4px",
      border: "solid 1px #e9ecef",
      backgroundColor: "#fff",
      maxWidth: "180px",
      overflow: "hidden"
      // height: "78px",
      // margin: "20px 46px 30px 45px",
      // padding: "0 0.5px 0 0"
    },
    headerLoanID: {
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: 1.5,
      color: "#32325d"
    },
    fieldContainer: {
      borderTop: "solid 1px #f2f2f2",
      height: "85%"
    },
    fieldRow: {
      display: "block",
      marginTop: "10px"
    },
    disabledHeaderText: {
      // visibility: "hidden"
      opacity: 0.5
    },
    errorMgs: {
      color: "red",
      fontSize: "10px",
      paddingTop: "2%"
    },
    inputValue: {
      border: "solid 1px #cad1d7",
      borderRadius: "4px",
      background: "#ffffff",
      color: "#000000",
      padding: "2px 5px",
      height: "36px",
      width: "85%"
    },
    formControlOption: {
      maxWidth: "180px",
      width: "100%",
      height: "57px",
      "& .MuiInputBase-root": {
        fontSize: "11px",
        padding: "7px 0"
      }
    },
    addOption: {
      marginLeft: "20px"
    },
    bankOption: {
      width: "100%"
    },
    headerText: {
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: 1.5,
      color: "#32325d",
      marginBottom: "5px"
    },
    BankInputValue: {
      border: "solid 1px #cad1d7",
      borderRadius: "4px",
      background: "#ffffff",
      color: "#000000",
      padding: "2px 5px",
      height: "36px",
      width: "100%",
      marginBottom: "20px"
    },

    BankInputWithError: {
      border: "solid 1px #cad1d7",
      borderRadius: "4px",
      background: "#ffffff",
      color: "#000000",
      padding: "2px 5px",
      height: "36px",
      width: "100%"
    },
    accountNumber: {
      fontSize: "12px",
      color: "#09090e"
    },
    otherInfo: {
      fontSize: "12px",
      color: "#5b5b7d"
    },
    accountFormControl: {
      width: "350px",
      height: "65px",
      "& .MuiInputBase-root": {
        fontSize: "11px",
        padding: "7px 0"
      }
    },
    assignToContainer: {
      margin: "15px 10px",
      border: 0,
      backgroundColor: "white",
      fontWeight: "bold",
      color: "black",
      cursor: "pointer",
      // width: "fit-content",
      // paddingRight: "40px"
      Position: "relative",
      right: "5px",
      width: "110px"
    },
    exportContainer: {
      // margin: "15px 40px",
      border: 0,
      backgroundColor: "white",
      fontWeight: "bold",
      color: "black",
      cursor: "pointer"
    },
    image: {
      width: 16,
      height: 16,
      objectFit: "contain",
      cursor: "pointer",
      marginRight: 2
    },
    labelText: {
      fontSize: "12px",
      color: "#32325d",
      fontWeight: 600,
      lineHeight: 1.23,
      marginRight: 8
    },
    hideButton: {
      visibility: "hidden"
    },
    deleteDrawButton: {
      marginLeft: "0px"
    },
    stageDisplay: {
      width: "60px",
      height: "30px",
      marginLeft: "20px",
      padding: "2px 7px 3px",
      borderRadius: "6px",
      // backgroundColor: "#172b4d",
      fontSize: "10px",
      color: "#fff",
      fontWeight: "bold"
    },
    netFunds: {
      // color: "#f75676",
      fontWeight: "bold"
    },
    selectRoot: {
      "& .MuiSelect-select": {
        marginTop: "0px",
        minHeight: "0.1876em",
        paddingBottom: "7px !important"
      },
      "& .MuiSelect-select:focus": {
        backgroundColor: "#ffffff !important",
        margin: "0px",
        marginTop: "1px !important"
      },
      "& legend": {
        display: "none"
      },
      "& .MuiListItemText-root": {
        marginTop: "-8px"
      },
      "& .MuiTypography-body1": {
        fontSize: "14px",
        top: "2px",
        position: "relative",
        fontWeight: "normal"
      },
      "& fieldset": {
        top: "0"
      }
    },
    userInfo: {
      display: "flex",
      flexDirection: "row"
    },
    personName: {
      fontSize: "11px",
      lineHeight: "1.64",
      color: "#8897aa",
      marginLeft: "10px",
      paddingTop: 5
    },
    createdOn: {
      fontSize: "10px",
      lineHeight: "1.8",
      marginLeft: "15px",
      color: "#8897aa"
    },
    comment: {
      fontSize: "13px",
      lineHeight: "1.54",
      color: "var(--dark-grey-blue)",
      marginLeft: 38
    },
    // textAreaDiscussionWrapper: {
    //   width: "92%",
    //   marginLeft: 36,
    //   height: "89px",
    //   border: "1px solid #cad1d7",
    //   borderRadius: "4px",
    //   marginTop: "1.5%",
    //   "&:disabled": {
    //     cursor: "no-drop"
    //   }
    // },
    sendButton: {
      marginLeft: "3%"
    },
    failReasonDescription: {
      fontSize: "14px",
      color: "#32325d",
      fontWeight: 500,
      paddingTop: "2px"
    },
    failReason: {
      fontsize: "12px !important",
      color: "#32325d",
      fontWeight: 600
    },
    failReasonContainer: {
      padding: "17px 14px"
    },
    headingContainer: {
      padding: "14px 0px 0px 14px",
      alignItems: "center"
    },
    container: {
      border: "1px solid red",
      borderRadius: "4px"
      // marginTop: "5px"
    },
    headingTypography: {
      fontSize: "12px !important",
      fontWeight: "bold",
      color: "#32325d",
      overflowWrap: "break-word",
      paddingRight: "8px"
    },
    failedContainer: {
      borderRadius: "6px",
      backgroundColor: "#f75676",
      padding: "2px 5px",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      maxWidth: "75px",
      height: "20px"
    },
    warningIcon: {
      //   padding: "2px",
      height: "19px"
    },
    failedTypography: {
      fontSize: "10px",
      fontWeight: "bold",
      color: "#ffffff",
      height: "14px",
      marginLeft: "4px"
    },
    avatar: {
      display: "inline-flex",
      width: "28px",
      height: "28px",
      background: "#9d5ce6",
      borderRadius: "50%",
      color: "white",
      justifyContent: "center",
      alignItems: "center"
    },
    auditTrailavatar: {
      display: "inline-flex",
      width: "28px",
      height: "28px",
      background: "#9d5ce6",
      borderRadius: "50%",
      color: "white",
      justifyContent: "center",
      alignItems: "center"
    },
    discussionsDisplay: {
      marginTop: "2%",
      marginBottom: "2%"
    },
    CssTextarea: {
      fontFamily: "'Open Sans', sans-serif !important",
      border: "none",
      width: "100%",
      reSize: "both",
      padding: "14px 12px 14px 12px",
      "border-radius": "4px",
      outline: "none",
      boxSizing: "border-box",
      fontSize: "14px",
      height: "100%",
      background: "transparent",
      "&:disabled": {
        cursor: "no-drop"
      }
    },
    verticalLinenew: {
      borderLeft: "solid 4px #fff",
      marginLeft: "20px"
    },
    verticalLine: {
      borderLeft: "solid 4px #5c70ec",
      marginLeft: "20px"
      // height: "70px"
    },
    evaluationResultBox: {
      height: 1125,
      borderTop: "2px solid #eef",
      overflowX: "scroll",
      padding: 10,
      backgroundColor: "#fff"
    },
    evaluationPassedSection: {
      border: "1px solid #e9ecef",
      margin: "5px",
      borderRadius: 4
    },
    failedDrawBox: {
      backgroundColor: "#fff",
      padding: "0px 0px 5px 0px",
      margin: "0px 5px"
    },
    auditTrailParentDiv: {
      // Width: "400px",
      width: "340px",
      height: "auto",
      whiteSpace: "initial",
      position: "fixed",
      padding: "20px",
      border: "black solid 0px",
      boxShadow: "2px 2px 2px 2px #888888",
      backgroundColor: "white",
      zIndex: 999
    },
    auditTrailOrigName: {
      marginLeft: "5px"
    },
    auditCommentBlock: {
      marginLeft: "5%",
      marginBottom: "2%"
    },
    noAuditData: {
      display: "flex",
      justifyContent: "center"
    },
    origDetails: {
      marginLeft: "5%",
      paddingBottom: "5%",
      display: "flex",
      flexFlow: "row"
    },
    bullet: {
      height: "16px",
      width: "16px",
      marginLeft: "-10px",
      backgroundColor: "#5c70ec",
      borderRadius: "50%",
      display: "inline-block"
    },
    auditTrailStage: {
      width: "250px",
      height: "20px",
      marginLeft: "15px",
      marginTop: "-20px",
      // marginBottom: "8px",
      fontSize: "13px",
      fontWeight: 600,
      color: "var(--dark-grey-blue)"
    },
    bodyWraper: {
      paddingTop: "60px",
      [theme.breakpoints.down("md")]: {
        paddingTop: "46px"
      },
      [theme.breakpoints.down("lg")]: {
        paddingTop: "46px"
      }
    },
    provisionHeader: {
      backgroundColor: "#FFF",
      paddingTop: "60px",
      [theme.breakpoints.down("md")]: {
        paddingTop: "46px"
      },
      [theme.breakpoints.down("lg")]: {
        paddingTop: "46px"
      }
    },
    provisionButton: {
      height: "30px",
      background: "#5566d6",
      color: "white",
      position: "absolute",
      display: "inline-block",
      marginTop: "5px"
    },
    auditTrailComment: {
      width: "142px",
      height: "20px",
      // marginLeft: "15px",
      wordBreak: "break-word",
      marginTop: "5px",
      marginRight: "5px",
      // marginBottom: "8px",
      fontSize: "12px",
      fontWeight: 600,
      color: "var(--dark-grey-blue)"
    },
    // auditTrailUpdateTime: {
    //   width: "157px",
    //   paddingTop: 5,
    //   height: "21px",
    //   marginLeft: "5px",
    //   // marginTop: "5px",
    //   // marginBottom: "8px"
    //   fontSize: "10px",
    //   color: "#8897aa"
    // },
    auditTrailUser: {
      marginLeft: "15px",
      marginTop: "5px",
      marginBottom: "8px",
      fontSize: "10px",
      color: "#8897aa"
    },
    pageText: {
      marginTop: "17px",
      fontWeight: "normal",
      lineHeight: 1.54,
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal"
    },
    pageDropdown: {
      marginLeft: "5px",
      marginTop: "-21px",
      flexGrow: 0,
      maxWidth: "18%",
      flexBasis: "18%"
    },
    listData: {
      textDecoration: "underline",
      color: "#5e72e4",
      cursor: "pointer",
      padding: "17px"
    },
    paginate: {
      marginTop: "-7px",
      lineHeight: 1.54,
      "& .PaginationItem-page": {
        color: "red"
      }
    },
    bulkPaginate: {
      marginTop: "-7px",
      lineHeight: 1.54,
      "& .MuiPaginationItem-root": {
        fontSize: "14px", // Set your desired font size
        color: "#000000",
        fontWeight: 500 // Set your desired color
      },
      "& .Mui-selected": {
        fontWeight: "500",
        backgroundColor: "#0f4c81",
        borderRadius: 3,
        color: "#FFFFFF !important" // For selected page style
      }
    },
    warningModal: {
      fontSize: "13px",
      padding: "0px 10px"
    },
    paramInfo: {
      fontSize: 11,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    },
    tooltip: {
      color: "#32325d",
      backgroundColor: "white",
      fontFamily: `"Open Sans", sans-serif !important`,
      fontSize: 12,
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      minWidth: "185px",
      maxWidth: "400px",
      minHeight: "40px",
      padding: "9px"
    },
    arrow: {
      color: "white"
    },
    superScriptElement: {
      backgroundColor: "#c8d0fb",
      borderRadius: "60%",
      padding: "2px 4px",
      fontWeight: "bold",
      cursor: "pointer"
    },
    addressHeader: {
      width: "100%",
      marginBottom: "1px",
      marginTop: 3,
      fontSize: 12,
      color: "#8897aa"
    },
    actionBtn: {
      borderRadius: 4,
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      borderWidth: 1,
      marginLeft: "7px",
      borderColor: "#ececec",
      height: "36px"
    },
    root: {
      flexGrow: 1,
      width: "100%",
      backgroundColor: "#F8F9FF",
      overflow: "hidden"
    },
    subHeaderContainer: {
      alignItems: "center",
      zIndex: 1,
      marginTop: 60,
      backgroundColor: "#ffffff"
    },
    closeButton: {
      display: "flex",
      justifyContent: "center",
      width: 60,
      top: 1
    },
    clearIcon: {
      width: 30,
      height: 30,
      marginLeft: "15px"
    },
    tabDataContainer: {
      width: "100%"
    },
    confirmFormModalClass: {
      "& .MuiDialog-container": {
        height: "auto"
      },
      "& .MuiDialog-paperWidthSm": {
        maxWidth: "unset"
      }
    },
    zeroState: {
      justifyContent: "center",
      padding: "15px 20px",
      minHeight: 75
    }
  })
);
