import React from "react";

import { Grid } from "@mui/material";
import {
  getKeyDealMetricsFieldMap,
  KeyMetricFieldType
} from "./configs/KeyMetricsConfig";
import { KeyDealMetricStyle } from "./keyDealMetricStyle";
import { formatValueByType } from "../utils/formatChecks";
import { KeyMetricsTextField } from "./KeyMetricsTextField";

export const KeyDealSectionMap = (props: any) => {
  const { data } = props;
  const classes = KeyDealMetricStyle();
  const { loanType, loanStage } = data;
  return (
    <Grid>
      {getKeyDealMetricsFieldMap(loanType).map(ele => {
        const { sectionLabel, sectionId, fields } = ele;
        return (
          <Grid key={sectionId}>
            <Grid
              xs={12}
              className={`${classes.sectionHeader} keydeal-section-header`}
              style={{ display: "flex" }}
            >
              <span
                className={classes.sectionHeading}
                style={{ minWidth: 130, maxWidth: 250 }}
              >
                {sectionLabel}
              </span>
            </Grid>
            <Grid xs={12} container style={{ paddingLeft: "13px" }}>
              {fields
                .filter(
                  (fieldItem: any) =>
                    !fieldItem.showInStage ||
                    fieldItem.showInStage.includes(loanStage)
                )
                .map((item: KeyMetricFieldType) => {
                  const {
                    fieldId,
                    fieldLabel,
                    getValue,
                    fieldType,
                    popOverData,
                    redirectionType
                  } = item;
                  const value = getValue(data);
                  return (
                    <Grid
                      item
                      style={{ minWidth: 130, maxWidth: 250 }}
                      key={fieldId}
                    >
                      <KeyMetricsTextField
                        label={fieldLabel}
                        redirectionType={redirectionType}
                        value={formatValueByType(value, fieldType)}
                        key={`${fieldId}-field`}
                        popOverData={popOverData ? popOverData(data) : null}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};
