import React, { useState } from "react";
import { exportDiscussions } from "../../../stores/drawRequest/drawRequest.action";
import { DrawDiscussionChat } from "../DiscussionChats.Html";
import { RootState } from "../../../stores/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { DrawRolesEnum } from "../../../utils/constants";
import { isOrigUser } from "../../../utils/AccessManagement";
import { DrawRequestStore } from "../../../stores/drawRequest/drawRequest.interface";
import { DrawStage } from "@toorak/tc-common-fe-sdk";
import {
  getUsername,
  isAMTView,
  isOnlyDrawMember
} from "../../AssetManagement.utils";
import { Grid } from "@mui/material";

export const ExternalDiscussions = ({ drawId }: { drawId: string }) => {
  const dispatch = useDispatch();
  const isOrig = isOrigUser();
  const firstName = Cookies.get("firstName");
  const lastName = Cookies.get("lastName");
  const [discussionChat, setDiscussionChat] = useState<any>({
    userName: "",
    text: "",
    role: ""
  });

  let { drawDetails } = useSelector<RootState, DrawRequestStore>(
    (state) => state.drawRequestStore
  );
  let drawDiscussionsHistory: any[] = useSelector<RootState, any>(
    (state) => state.drawRequestStore.drawDiscussions
  );

  let role: DrawRolesEnum;
  if (isAMTView()) {
    if (isOnlyDrawMember()) {
      role = DrawRolesEnum.DRAW_MEMBER;
    } else {
      role = DrawRolesEnum.DRAW_MANAGER;
    }
  }

  const handleDiscussionsSave = () => {
    let tempDiscussionHistory = JSON.parse(
      JSON.stringify(drawDiscussionsHistory)
    );
    dispatch(
      exportDiscussions(
        discussionChat,
        drawId,
        isOrig,
        tempDiscussionHistory,
        drawDetails.originatorId
      )
    );
    let tempObj = {
      userName: firstName + " " + lastName,
      text: "",
      role
    };
    setDiscussionChat(tempObj);
  };
  const handleDiscussionText = (val: any) => {
    let tempObj = JSON.parse(JSON.stringify(discussionChat));
    tempObj["text"] = val;
    tempObj["userName"] = getUsername();
    tempObj["role"] = role;
    setDiscussionChat(tempObj);
  };
  return (
    <Grid container>
      <DrawDiscussionChat
        drawInternalChats={[]}
        drawDiscussionsHistory={drawDiscussionsHistory}
        handleTextChange={handleDiscussionText}
        handleChatsPost={handleDiscussionsSave}
        chatItem={discussionChat}
        disableChat={drawDetails.stage === DrawStage.draft}
        btnLabel={"Send"}
        styleProps={{ height: 1130 }}
      />
    </Grid>
  );
};
