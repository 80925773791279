import { Typography, Grid } from "@mui/material";
import { ExpandCollapse } from "@toorak/tc-common-fe-sdk";
import React, { useState } from "react";
import { BorroweGuaranHeader } from "./borrowerGuaranHeader";
import { creditEvalStyles } from "./styles/CreditEvalStyles";

export const InputSectionWrapper = (props: {
  sectionLabel: string;
  children: any;
  sectionId: string;
}) => {
  const classes = creditEvalStyles();
  const [isMinimized, setMinimized] = useState(false);
  const { sectionLabel, sectionId, children } = props;
  return (
    <Grid container item md={12} className={classes.gridOne} id={sectionId}>
      <Grid className={classes.container}>
        <Grid container spacing={3} className={classes.sectionWrapper}>
          <Grid item xs={12} container style={{ alignItems: "center" }}>
            <Grid
              item
              xs={6}
              sm={11}
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <Typography
                style={{ fontSize: "12px" }}
                className={
                  sectionId === "input-borrowerGuarantor" ||
                  sectionId === "output-borrowerGuarantor"
                    ? classes.borrowerHeaderStyle
                    : sectionId === "input-scenario-id"
                    ? classes.scenarioHeading
                    : classes.headingTypography
                }
              >
                {sectionLabel}
              </Typography>
              {sectionId === "input-borrowerGuarantor" ||
              sectionId === "output-borrowerGuarantor" ? (
                <BorroweGuaranHeader />
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={6} sm={1} className={classes.headingImageContainer}>
              <ExpandCollapse
                isMinimize={isMinimized}
                clicked={() => {
                  setMinimized(!isMinimized);
                }}
                keyDown={() => {
                  setMinimized(!isMinimized);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid
          item
          xs={12}
          sm={12}
          // style={{
          //   display: isMinimized ? "none" : ""
          // }}
        >
          <div className={classes.divider} />
        </Grid> */}
        {isMinimized ? null : children}
      </Grid>
    </Grid>
  );
};
