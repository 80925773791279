import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    compactRoot: {
      textTransform: "none",
      color: "#32325d",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(14),
      marginRight: theme.spacing(1),
      paddingLeft: "6px",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    searchContainer: {
      alignSelf: "flex-end",
      flex: 1,
      display: "flex",
      alignItems: "center",
      borderRadius: "20px",
      backgroundColor: "#f7fafc !important",
      padding: "0px 16px 0 4px !important",
      maxWidth: "171px !important",
      height: "36px !important",
      border: "solid 1px #E9ECEF !important",
      fontSize: "12px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100% !important",
        marginBottom: "10px"
      }
    },
    searchIcon: {
      width: "16px",
      height: "16px",
      color: "#32325D",
      position: "relative",
      top: "1px",
      left: "3px",
      marginRight: "5px"
    },
    clearSearchIcon: {
      width: "16px",
      height: "16px",
      cursor: "pointer",
      backgroundColor: "#f7fafc !important",
      position: "relative",
      zIndex: 1
    },
    // clearIcon: {
    //   backgroundColor: "transparent",
    //   border: "none"
    // },
    filterTerms: {
      fontSize: "12px",
      fontWeight: "normal",
      color: "#32325d",
      padding: "0px"
    },
    // searchIcon: {
    //   left: 5,
    //   position: "relative"
    // },
    checkListItem: {
      padding: "12px 20px",
      alignItems: "flex-start",
      borderBottom: "1px solid #e9ecef",
      backgroundColor: "#f6f9fc",
      cursor: "pointer"
    },
    search: {
      position: "relative",
      width: "290px",

      "& .svg-icon": {
        fontSize: "16px"
      }
    },
    // searchIcon: {
    //   padding: theme.spacing(0, 2),
    //   height: "100%",
    //   position: "absolute",
    //   pointerEvents: "none",
    //   display: "flex",
    //   alignItems: "center",
    //   justifyContent: "center",
    //   zIndex: 1
    // },
    inputRoot: {
      color: "inherit",
      width: "100%"
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: "36px",
      width: "100%",
      backgroundColor: "#f7fafc",
      border: "1px solid #e9ecef",
      borderRadius: "20px",
      fontSize: "12px"
    },
    categoryBlock: {
      // maxHeight: "150px",
      overflowY: "auto",
      overflowX: "hidden",
      transition: "max-height 0.5s"
    },
    indicatorCompactHeader: {
      height: "4px",
      borderRadius: "30px",
      backgroundColor: "#5e72e4"
    },
    tabHeading: {
      marginRight: "5px",
      padding: "23px 14px 0 14px"
    },
    filterOptions: {
      color: "#32325d",
      padding: `${theme.spacing(2)} ${theme.spacing(2)}`
    },
    resetContainer: {
      justifyContent: "flex-end",
      display: "flex",
      padding: "14px 7px 14px 0"
    },
    filters: {
      background: "#f6f9fc",
      padding: `${theme.spacing(2)} ${theme.spacing(3)} !important`,
      borderTop: "solid 1px #e9ecef"
    },
    headingTypography: {
      textAlign: "left",
      fontSize: "12px !important",
      fontWeight: 600,
      color: "#32325d",
      lineHeight: 1.57,
      padding: "14px"
    },
    headingImageContainer: {
      justifyContent: "flex-end",
      display: "flex",
      padding: "14px 10px 14px 0"
    },
    headingImage: {
      width: "18px",
      height: "18px",
      objectFit: "contain",
      cursor: "pointer"
    },
    ageingImage: {
      width: "18px",
      height: "18px",
      objectFit: "contain",
      cursor: "pointer"
    },
    divider: {
      height: "1px",
      border: "solid 1px #f2f2f2",
      padding: "0px !important"
    },
    filterContainer: {
      borderBottom: "solid 1px #f2f2f2"
    },
    sectionWrapper: {
      padding: `${theme.spacing(2)} ${theme.spacing(2)}`
    },
    filterWrapper: {
      borderRadius: "4px",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
      background: "#ffffff",
      height: "fit-content",
      [theme.breakpoints.down("lg")]: {
        display: "flex"
      },
      [theme.breakpoints.down("md")]: {
        display: "none"
      },
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    defaultTextProperty: {
      color: "#424242",
      fontSize: 12
      // textDecoration: "underline #5f72e4"
    },
    tableWrapper: {
      background: "#ffffff",
      maxWidth: "calc(83.3333% - 16px)",
      marginLeft: "16px",
      borderRadius: "4px",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      height: "fit-content",
      // maxHeight: "70vh",
      // overflowY: "scroll"
      //[theme.breakpoints.down("md")]: {
      //maxWidth: "calc(75% - 6px)",
      //  marginLeft: "6px",
      //},
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
        marginLeft: "0"
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        marginLeft: "0"
      }
    },
    selectAllWrapper: {
      padding: "14px 0 14px 20px",
      borderTop: "solid 1px #f2f2f2",
      background: "#f6f9fc",
      borderBottom: "solid 1px #f2f2f2",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    loanTabWrapper: {
      borderBottom: "solid 1px #e9ecef",
      padding: "14px 20px",
      minHeight: "90px"
    },
    loanListWrapper: {
      maxHeight: "75vh",
      overflowY: "auto"
      // [theme.breakpoints.down("xs")]: {
      //   maxHeight: "57vh",
      // },
    },
    filterHeading: {
      fontSize: "12px"
    },
    filterButton: {
      fontSize: "12px",
      width: "110px",
      display: "none",
      [theme.breakpoints.down("md")]: {
        display: "inline-block"
      }
    },
    loanInfo: {
      fontSize: "14px",
      color: "#5e71e4",
      cursor: "pointer",
      fontWeight: 600,
      [theme.breakpoints.down("md")]: {
        display: "inline-block",
        textAlign: "left"
      }
    },
    subTitle: {
      paddingLeft: "20px",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px"
      }
    },
    selectLoanCheckBox: {
      paddingTop: "5px",
      paddingBottom: "4px",
      alignItems: "normal",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    statusContainer: {
      fontFamily: `"Open Sans", sans-serif !important`,
      position: "relative",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-end",
      flexWrap: "wrap",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start"
      }
    },
    statusText: {
      color: "#ffffff",
      fontSize: 10,
      display: "flex",
      alignItems: "center",
      // margin: "auto 10px",
      backgroundColor: "#172b4d",
      borderRadius: 6,
      padding: 5,
      fontWeight: "bold",
      lineHeight: "initial",
      maxHeight: 25,
      margin: 0,
      [theme.breakpoints.down("lg")]: {
        // position: "absolute",
        // right: "-73px",
        // margin: "0",
      },
      [theme.breakpoints.down("md")]: {
        // position: "relative",
        // margin: "10px 0 0",
        // right: "auto",
      }
    },
    countText: {
      display: "flex",
      position: "relative",
      alignItems: "center",
      justifyContent: "flex-start"
    },
    bundleStatusText: {
      fontSize: "10px",
      borderRadius: "5px",
      padding: "2px 4px",
      background: "#d2e3ef",
      maxWidth: "153px",
      lineHeight: "initial",
      whiteSpace: "break-spaces",
      fontWeight: "bold",
      color: "#32325d",
      textAlign: "center",
      marginTop: "3px",
      textTransform: "uppercase"
    },
    filterTitle: {
      padding: "0px"
    },
    ageingContainer: {
      display: "flex",
      // alignItems: "center",
      justifyContent: "flex-end",
      position: "relative",
      [theme.breakpoints.down("lg")]: {
        // justifyContent: "center"
      },
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start"
      }
    },
    ageingSubContainer: {
      display: "flex",
      justifyContent: "flex-end",
      maxWidth: "fit-content",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start"
      }
    },
    filterKeys: {
      color: "#32325d",
      fontWeight: "normal",
      fontSize: 12
    },
    selectAll: {
      fontWeight: "bold",
      fontSize: 12,
      color: "#32325d"
    },
    sortWrapper: {
      padding: "14px 20px 14px 0",
      background: "#f6f9fc",
      fontWeight: "bold",
      borderTop: "solid 1px #f2f2f2",
      textAlign: "right",
      borderBottom: "solid 1px #f2f2f2",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    countTag: {
      display: "flex",
      justifyContent: "flex-end",
      margin: "0.2rem 0",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start"
      }
    },
    loanContainer: {
      display: "flex",
      justifyContent: "flex-end",
      position: "relative",
      margin: "0.2rem 0",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px",
        display: "flex",
        justifyContent: "flex-start"
      }
    },
    loanAmount: {
      margin: "auto 1rem",
      fontWeight: 600,
      position: "relative",
      [theme.breakpoints.down("lg")]: {
        // margin: "0",
        // position: "absolute",
        // right: "-125px"
      },
      [theme.breakpoints.down("md")]: {
        // margin: "10px 0 0 0px",
        // position: "relative",
        // right: "auto"
      },
      [theme.breakpoints.down("sm")]: {
        margin: "0 1rem 0 0"
        //  position: "absolute",
        //  right: "-125px",
      }
    },
    ZeroStateContainer: {
      padding: "20px",
      fontSize: "14px",
      borderTop: "solid 1px #f2f2f2"
    },
    loanId: {
      cursor: "pointer"
    },
    subTitles: {
      paddingLeft: "20px",
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px"
      }
    },
    col1: {
      lineHeight: 1.57
    },
    filtersAll: {
      background: "#f6f9fc",
      padding: `${theme.spacing(2)} ${theme.spacing(3)} !important`,
      borderTop: "solid 1px #e9ecef"
    },
    clear: {
      color: "#5e72e4",
      lineHeight: 1.5,
      fontSize: "12px",
      fontWeight: 600,
      cursor: "pointer",
      marginLeft: "1px"
    },
    filterCount: {
      color: "#5e72e4",
      fontSize: "12px",
      fontWeight: 600,
      marginLeft: "2px"
    },
    filterkey: {
      textAlign: "left",
      fontSize: "12px !important",
      fontWeight: 600,
      color: "#32325d",
      lineHeight: 1.57,
      padding: "14px 14px 14px 12px"
    },
    disableResetContainer: {
      justifyContent: "flex-end",
      padding: "14px 7px 14px 0",
      pointerEvents: "none",
      opacity: "0.5",
      display: "none"
    },
    fundingTag: {
      backgroundColor: "#3d55df",
      borderRadius: "1px",
      color: "white",
      padding: "0 0.2rem",
      fontSize: "12px",
      marginLeft: "0.5rem",
      lineHeight: "1.2"
    },
    expressTag: {
      backgroundColor: "#1071ee",
      borderRadius: "1px",
      color: "white",
      padding: "0 0.2rem",
      fontSize: "12px",
      lineHeight: "1.2",
      marginLeft: "0.5rem"
    },
    premierTag: {
      backgroundColor: "#b87300",
      borderRadius: "1px",
      color: "white",
      padding: "0 0.2rem",
      fontSize: "12px",
      lineHeight: "1.2",
      marginLeft: "0.5rem"
    },
    tooltip: {
      backgroundColor: "#fff",
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: "0 2px 12px 0 rgb(50 50 93 / 21%)",
      margin: "0px 10px",
      padding: "0.5rem",
      fontSize: "12px"
    },
    arrow: {
      color: "#fff"
    }
  })
);
