import {
  Button,
  Popover,
  Typography,
  MenuItem,
  CircularProgress,
  Grid
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
// import { ExportIcon } from "../../../icons/exportIcon";
import { dbSubHeaderStyles } from "./styles";
import {
  exportFromCommunication,
  exportInquiresOrConditions
} from "../../../stores/dashboardSection/dashboard.action";
import { clickAndDownloadFile } from "../../create-loan.action";
import { exportFromLoansList } from "../../../stores/ExceptionsResults/ExceptionActions";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/rootReducer";
import { ExportIcon } from "../../../icons/exportIcon";
import { isViewOnly } from "../../../utils/formatChecks";

interface ExportConditionsAndWaivers {
  tabs: any;
  loanId?: any;
  exportFromConditionsAndInquiriesTab?: any;
  selectedTab?: any;
  countOfEnquiries?: any;
  countOfExceptions?: any;
  loanStage?: any;
}

export const ExportConditionsAndWaivers = (
  props: ExportConditionsAndWaivers
) => {
  const classes = dbSubHeaderStyles();
  const exportButtonRef = useRef(null);
  const {
    tabs,
    selectedTab,
    loanId,
    exportFromConditionsAndInquiriesTab,
    countOfEnquiries,
    countOfExceptions,
    loanStage
  } = props;
  const [exportOpen, setExportOpen] = useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const oldLoanId = useSelector<RootState, any>(
    state => state.createLoanStore.loanDetails?.loanDetailId?.oldLoanId
  );
  const [selectedConditions, setSelectedConditions] = useState<any>([]);
  const [selectedInquiry, setSelectedInquiry] = useState<any>([]);
  const selectedConditionArray = useSelector<RootState, any>(
    state => state.exceptionsResultsStore?.selectedCondition
  );
  const { commListData } = useSelector<RootState, any>(
    state => state.dashboardStore
  );

  useEffect(() => {
    if (selectedConditionArray?.length && commListData?.length) {
      const tempArray1: any = [];
      const tempArray2: any = [];
      selectedConditionArray.forEach((element: any) => {
        const exceptionIndex = commListData.findIndex(
          (item?: any) => item?._source?.exception?.exceptionId === element
        );
        if (exceptionIndex > -1) {
          const selectedException = commListData[exceptionIndex];
          if (
            selectedException?._source?.resource === "exception" &&
            (selectedException?._source?.exception?.type.toLowerCase() ===
              "exception" ||
              selectedException?._source?.exception?.type === "")
          ) {
            tempArray1.push(element);
          } else if (
            selectedException?._source?.resource === "exception" &&
            selectedException?._source?.exception?.type.toLowerCase() ===
              "inquiry"
          ) {
            tempArray2.push(element);
          }
        }
      });
      setSelectedInquiry(tempArray2);
      setSelectedConditions(tempArray1);
    } else {
      setSelectedInquiry([]);
      setSelectedConditions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConditionArray]);

  const toggleExport = (_event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setExportOpen((preVal: boolean) => {
      const newVal: boolean = !preVal;
      // if (!newVal) removeUnAppliedFilters();
      return newVal;
    });
  };

  const closeExportPopover = () => {
    setExportOpen(false);
  };

  const exportCategory = (category: any) => {
    if (exportFromConditionsAndInquiriesTab) {
      exportFromLoansList(category, loanId, loanStage, oldLoanId).then(res => {
        if (res) {
          clickAndDownloadFile(res, `${category}.xlsx`);
        }
      });
    } else {
      if (category === "inquiries" || category === "conditions") {
        setIsExporting(true);
        setExportOpen(false);
        if (category === "inquiries") {
          exportInquiresOrConditions(category, selectedInquiry).then(res => {
            if (res) {
              setIsExporting(false);
              clickAndDownloadFile(res, `${category}.xlsx`);
            }
          });
        } else {
          exportInquiresOrConditions(category, selectedConditions).then(res => {
            if (res) {
              setIsExporting(false);
              clickAndDownloadFile(res, `${category}.xlsx`);
            }
          });
        }
      } else {
        exportFromCommunication(category).then(res => {
          if (res) {
            clickAndDownloadFile(res, `${category}.xlsx`);
          }
        });
      }
    }
  };

  const isDisable = (title: string) => {
    if (exportFromConditionsAndInquiriesTab) {
      if (title === "Export Conditions") {
        return countOfExceptions === 0;
      } else if (title === "Export Inquiries") {
        return countOfEnquiries === 0;
      }
    } else {
      if (title === "Export Conditions") {
        return selectedConditions.length === 0;
      } else if (title === "Export Inquiries") {
        return selectedInquiry.length === 0;
      }
    }
  };

  return (
    <>
      {isExporting ? (
        <CircularProgress
          size={12}
          style={{
            margin: "5px"
          }}
        />
      ) : (
        <Grid
          style={
            exportFromConditionsAndInquiriesTab
              ? { width: 95, display: "flex", alignItems: "center" }
              : { display: "flex", alignItems: "center" }
          }
        >
          {exportFromConditionsAndInquiriesTab && (
            <span
              style={
                exportFromConditionsAndInquiriesTab
                  ? { marginLeft: "15px", position: "relative", top: 2 }
                  : { marginLeft: "15px" }
              }
            >
              <ExportIcon />
            </span>
          )}
          <Button
            ref={exportButtonRef}
            onClick={toggleExport}
            className={classes.exportButton}
            style={{ marginBottom: 0 }}
            endIcon={exportOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            data-testid="db-export-toggle-button"
            disabled={
              !!(
                exportFromConditionsAndInquiriesTab &&
                isViewOnly(loanStage, loanId)
              )
            }
          >
            <span style={{ fontSize: "12px", fontWeight: 600 }}>
              {exportFromConditionsAndInquiriesTab ? "Export" : "More"}
            </span>
          </Button>
          <Popover
            data-testid="db-export-popover"
            open={exportOpen}
            onClose={closeExportPopover}
            anchorEl={exportButtonRef.current}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            disableRestoreFocus
            PaperProps={{
              style: {
                marginTop: "5px",
                marginLeft: "-8px"
              }
            }}
          >
            {/* <List component="nav"> */}
            {tabs &&
              tabs.map((item: any) => {
                if (item.tab === selectedTab)
                  return (
                    <MenuItem
                      onClick={() => exportCategory(item.category)}
                      style={{ cursor: "pointer", margin: "5px 0px" }}
                      disabled={isDisable(item.title)}
                    >
                      <Typography className={classes.exportHeaderText}>
                        {item.title}
                      </Typography>
                    </MenuItem>
                  );
                return null;
              })}
            {/* </List> */}
          </Popover>
        </Grid>
      )}
    </>
  );
};
