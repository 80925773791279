/* eslint-disable no-param-reassign */
import { tabIds, ttfAction } from "../constants";
import { ttfActionType, DocumentReviewStateType } from "../types";

export const documentReviewInitialState = {
  [tabIds.DOCUMENT_TAGGING]: {
    activePageNumber: 0,
    totalPages: 0,
    showAllDocs: false,
    selectedDocument: null,
    nextActiveTab: null,
    isAllRequiredTagsAreTagged: false,
    status: null,
    highlightCoordinates: []
  },
  [tabIds.DOCUMENTS_SPLIT]: {
    activePageNumber: 0,
    totalPages: 0,
    showAllDocs: true,
    selectedClosingPackage: null,
    selectedDocumentInfoId: null,
    selectedDocument: null,
    checkedSplitViewData: new Map(),
    closingPackagesList: new Map(),
    baseClosingPackageList: new Map(),
    showWarningModal: false,
    warningModalValue: {},
    isTTFStageEnabled: false,
    status: null,
    highlightCoordinates: []
  },
  [tabIds.DOCUMENT_REVIEW]: {
    activePageNumber: 0,
    totalPages: 0,
    status: null,
    selectedCategories: [],
    selectedDocument: null,
    isTTFDBDataAvailable: false,
    reqDocsAvailable: false,
    highlightCoordinates: []
  },
  activeTab: tabIds.DOCUMENT_TAGGING,
  showConfirmationDialog: false,
  confirmationModalMessage: null,
  showToastMessage: false,
  toastMessage: null,
  initialized: false
};

export const documentReviewReducer = (
  state: DocumentReviewStateType,
  action: ttfActionType
) => {
  switch (action.type) {
    case ttfAction.UPDATE_PAGE_OR_HIGHLIGHT_SET: {
      if (!action.payload.tabToUpdate) return state;
      return {
        ...state,
        [action.payload.tabToUpdate]: {
          ...state[action.payload.tabToUpdate],
          ...action.payload.value
        }
      };
    }
    case ttfAction.UPDATE_PDF_CONTROLS:
    case ttfAction.UPDATE_SPLIT_PARAMS:
    case ttfAction.UPDATE_STATUS:
    case ttfAction.UPDATE_TAGGING_TAB_VALUES:
    case ttfAction.UPDATE_TTF_PARAMS: {
      if (!action.payload.tabToUpdate) return state;
      return {
        ...state,
        [action.payload.tabToUpdate]: {
          ...state[action.payload.tabToUpdate],
          ...action.payload.value
        }
      };
    }
    case ttfAction.UPDATE_TAB:
    case ttfAction.SET_INITIALIZED:
    case ttfAction.UPDATE_DOCUMENT_REVIEW:
      return {
        ...state,
        ...action.payload.value
      };

    case ttfAction.UPDATE_DOCUMENT_SPLIT_VALUES: {
      return {
        ...state,
        [tabIds.DOCUMENTS_SPLIT]: {
          ...state[tabIds.DOCUMENTS_SPLIT],
          ...action.payload.value
        }
      };
    }

    default:
      return state;
  }
};
