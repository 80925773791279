/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography, Box } from "@mui/material";
import queryString from "query-string";
import { push } from "connected-react-router";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { LoanStage } from "@toorak/tc-common-fe-sdk";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// import { resetExcelUploadReducer } from "../../stores/ExcelUpload/excel-upload.action";
import {
  getExcelDataInSession,
  isILP,
  isLoanOnHold,
  isPurchasedLoansOnly,
  isServicerOnboardingOnly
} from "../../utils/AccessManagement";
import { resetAllStores } from "../create-loan.action";
import { useStyles } from "../common-style/CreateLoan.style";
import { loanStageToReadable } from "./ActionDropDown";
import { removeCookie } from "../../utils/cookies";
import { RootState } from "../../stores/rootReducer";
import { useParams } from "react-router-dom";
import { getLoanType } from "../../config/config";
import { isEmptyValue } from "../../utils/formatChecks";
import { OnHoldInfoTag } from "../on-hold-loan/OnHoldInfoTag";
import { useAppraisalForm } from "../appraisal/shared/useAppraisalForm";
import { clearPurchasedLoansData } from "../../stores/servicerOnboarding/servicerOnboarding.action";
import { resetRetryRequestReducer } from "../../stores/RetryRequest/retry-request.reducer";
import { resetExcelUploadReducer } from "../../stores/ExcelUpload/excel-upload.reducer";

export const getOriginatorId = (
  excelData: any,
  loanId: string,
  loanType: any,
  loanDetails: any,
  appraisalOriginatorLoanId?: any
) => {
  if (appraisalOriginatorLoanId) return appraisalOriginatorLoanId;
  if (excelData?.maintainExcelBundle) {
    let ret: any;
    Object.keys(excelData?.loanIdList).forEach((id, val) => {
      if (loanId === id) {
        const primaryId = excelData?.loanIdList[id]?.primaryLoanId;
        ret = primaryId;
      }
    });
    return ret;
  } else {
    if (loanType && loanType === getLoanType[0].displayValue) {
      return loanDetails?.bridgeLoanInformation?.payload?.primaryLoanID;
    } else if (loanType && loanType === getLoanType[1].displayValue) {
      return loanDetails?.thirtyYearLoanInformation?.payload?.primaryLoanID;
    }
  }
};

/** This component displays loan id and contains the link to go back to loan list page */
export const DisplayLoanId = (props: {
  loanId: string;
  loanStage: LoanStage;
  isViewMode?: boolean;
  primaryLoanIdProps?: string;
  showServierHistory?: () => any;
}) => {
  const {
    loanId,
    isViewMode,
    loanStage,
    showServierHistory,
    primaryLoanIdProps
  } = props;
  const { loanType } = useParams<{
    loanId: string;
    loanStage: LoanStage;
    loanType: string;
  }>() as any;
  const [primaryLoanId, setPrimaryLoanId] = useState<any>("");
  const { loanID: appraisalOriginatorLoanId } = useAppraisalForm();
  const { loanDetails } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );
  const tradeId = loanDetails?.loanDetailId?.tradeId;

  const loanOnHold = isLoanOnHold(loanDetails?.onHoldBy);

  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const excelData = getExcelDataInSession();

  const urlParams: any = queryString.parse(
    new URL(window.location.href).search
  );

  useEffect(() => {
    if (isServicerOnboardingOnly() && loanDetails.loanType) {
      if (loanDetails.loanType === getLoanType[0].loanCreationName) {
        setPrimaryLoanId(
          loanDetails?.bridgeLoanInformation?.payload?.primaryLoanID
        );
      } else if (loanDetails.loanType === getLoanType[1].loanCreationName) {
        setPrimaryLoanId(
          loanDetails?.thirtyYearLoanInformation?.payload?.primaryLoanID
        );
      }
    } else if (isPurchasedLoansOnly()) {
      setPrimaryLoanId(primaryLoanIdProps);
    } else {
      setPrimaryLoanId(() =>
        getOriginatorId(
          excelData,
          loanId,
          loanType,
          loanDetails,
          appraisalOriginatorLoanId
        )
      );
    }
  }, [
    loanId,
    loanStage,
    primaryLoanIdProps,
    primaryLoanId,
    loanDetails?.loanType,
    loanDetails?.thirtyYearLoanInformation?.payload?.primaryLoanID,
    loanDetails?.bridgeLoanInformation?.payload?.primaryLoanID,
    excelData,
    appraisalOriginatorLoanId
  ]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingTop: isViewMode ? 10 : 20
        }}
      >
        <Typography
          variant="h5"
          style={{
            color: "#5e72e4",
            fontSize: 12,
            fontWeight: "bold",
            cursor: "pointer"
          }}
          onClick={() => {
            const excelData = getExcelDataInSession();
            if (excelData) {
              const newData = {
                ...excelData,
                ...{ maintainExcelBundle: false }
              };
              sessionStorage.setItem("excelUpload", JSON.stringify(newData));
            }
            removeCookie("loanConverted");
            dispatch(resetAllStores());
            dispatch(resetExcelUploadReducer());
            dispatch(resetRetryRequestReducer());
            const subTab = urlParams.from ? `&subTab=${urlParams.from}` : "";
            if (isILP()) {
              navigate(`/internal/loans/list?tab=0${subTab}`);
            } else if (isPurchasedLoansOnly()) {
              navigate(`/asset/home?tab=2`);
            } else if (isServicerOnboardingOnly()) {
              dispatch(clearPurchasedLoansData());
              navigate(`/asset/home?tab=1`);
            } else {
              navigate(`/loans/list?tab=1${subTab}`); // change route to loan list later
            }
          }}
        >
          Loans
        </Typography>
        <KeyboardArrowLeftIcon
          fontSize="large"
          htmlColor="#5e72e4"
          style={{
            width: 11,
            height: 11,
            margin: 2,
            marginBottom: 2,
            transform: "rotate(180deg)",
            objectFit: "contain"
          }}
        />
        <Typography
          variant="h5"
          style={{
            color: "#5e72e4",
            fontSize: 12,
            fontWeight: "bold"
          }}
        >
          Loan ID: {loanId}
        </Typography>
      </div>
      <Box display="flex" alignItems="center" className={classes.loanIdLabel}>
        Loan ID: Toorak
        {!isEmptyValue(primaryLoanId) && <span>/Originator</span>}
        {loanOnHold && <OnHoldInfoTag />}
      </Box>
      <Grid xs={12} className={classes.loanIdContainer}>
        <Grid
          xs={isViewMode ? 6 : 12}
          style={{
            minWidth: 200,
            // maxWidth: 290,
            display: "flex"
          }}
        >
          <Typography
            style={{ fontWeight: 550, wordBreak: "break-word" }}
            className={`${classes.loanIdTypoGraphy} ${
              loanOnHold ? classes.holdLoan : null
            }`}
          >
            <span>{loanId}</span>
            {!isEmptyValue(primaryLoanId) && (
              <>
                <span className={classes.loanIdSeparator}>/</span>
                <span className={classes.originatorIdLabel}>
                  {primaryLoanId}
                </span>
              </>
            )}
            {!isEmptyValue(tradeId) && (
              <span
                className={classes.originatorIdLabel}
                style={{
                  fontWeight: "normal",
                  fontSize: 12,
                  color: " #32325d"
                }}
              >
                {" "}
                Trade ID: {tradeId}
              </span>
            )}
          </Typography>
          {showServierHistory && showServierHistory()}
          {/* {!isEmptyValue(primaryLoanId) && (
            <span
              style={{
                color: "#32325d",
                borderRadius: "5px",
                backgroundColor: "#e9ecfe",
                marginTop: "5px",
                padding: "4px 8px 0px 8px",
                display: "flex",
                maxWidth: "calc(100% - 250px)"
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  paddingRight: "3px",
                  width: "115px"
                }}
              >
                Originator Loan ID:{" "}
              </span>
              <span className={classes.loanOriginatorId}> {primaryLoanId}</span>
            </span>
          )} */}
        </Grid>
        {isViewMode && (
          <Grid xs={9}>
            <div className={classes.readOnlyContainer}>
              <InfoOutlinedIcon
                style={{ width: 12, height: 12 }}
                htmlColor="#32325d"
              />
              <Typography variant="h5" className={classes.readOnlyMessage}>
                <span>{`You are viewing “${loanStageToReadable[loanStage]}” stage of the loan.`}</span>
              </Typography>
            </div>
          </Grid>
        )}
      </Grid>
    </>
  );
};
