/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import { Grid, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { ExpandCollapse } from "@toorak/tc-common-fe-sdk";
import { useSelector } from "react-redux";
import iconFailure from "../../images/icon_test_fail.svg";
import { isLATUser } from "../../utils/AccessManagement";
import { RootState } from "../../stores/rootReducer";
import { FailureResultsCard } from "../evaluation-results/FailureResultsCard";
import {
  RepresentableEvaluationResults,
  EvaluationResultsFailureData,
  GridSize
} from "../evaluation-results/EvaluationResults";

export interface FailedSectionWrapperProps {
  item: RepresentableEvaluationResults;
  failureCardSize?: {
    xs?: GridSize["size"];
    md?: GridSize["size"];
    sm?: GridSize["size"];
    xl?: GridSize["size"];
  };
  numberOfWaiversCreated: number;
  isMinimizable?: boolean;
  dividerRequired?: boolean;
  redirectionPage?: string;
  waiverCreated?: boolean;
  waiverRequested?: boolean;
  redirectionRequired?: boolean;
  showIntermediateValues?: boolean;
  errorAddressList?: any;
  isWaiverPending?: any;
}
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headingImageContainer: {
      justifyContent: "flex-end",
      display: "flex"
    },
    headingImage: {
      width: 16,
      height: 16,
      objectFit: "contain",
      cursor: "pointer"
    },
    sectionWrapper: {
      paddingRight: `${theme.spacing(2)}`,
      paddingLeft: `${theme.spacing(2)}`,
      paddingTop: `${theme.spacing(2)}`,
      display: "flex"
    },
    sectionWrapperMinimized: {
      padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
      display: "flex"
    }
  })
);
export function WaiverPendingWrapper(props: FailedSectionWrapperProps) {
  const {
    item,
    failureCardSize,
    isMinimizable,
    dividerRequired,
    redirectionRequired,
    showIntermediateValues,
    numberOfWaiversCreated,
    errorAddressList,
    isWaiverPending
  } = props;
  const [isMinimized, setIsMinimized] = useState<boolean>(false);
  const classes = useStyles();
  useEffect(() => {
    setIsMinimized(false);
  }, [item.failingTests.length]);

  const { mandatoryResult } = useSelector<RootState, any>(
    (state) => state.createLoanStore.loanDetails
  );

  const isMandatoryTest = (name: string, group: string) => {
    if (!isLATUser()) return false;
    const foo = mandatoryResult.filter((result: any) => {
      const resultList = result.split(".");
      return !!(group === resultList[1] && name === resultList[2]);
    });
    return !!foo.length;
  };
  // function onlyUnique(value: any, index: any, self: any) {
  //   return self.indexOf(value) === index;
  // }
  // const unique = Array.isArray(item) ? item.filter(onlyUnique) : null;
  const pendingWaiver = item.failingTests.find(
    (faileditem) =>
      (faileditem?.waiverData?.waiverType === "EvaluationResult" ||
        faileditem?.waiverData?.waiverType === "GeneralWaiver") &&
      (!faileditem?.waiverData?.waiverResponses ||
        faileditem?.waiverData?.waiverResponses?.length === 0 ||
        faileditem?.waiverData?.waiverResponses[
          faileditem?.waiverData?.waiverResponses.length - 1
        ].responseType === "REQUESTED" ||
        faileditem?.waiverData?.waiverResponses[
          faileditem?.waiverData?.waiverResponses.length - 1
        ].responseType === "RE-REQUESTED")
  );
  const failedTestItems = item.failingTests.filter(
    (testItem) =>
      (testItem?.waiverData?.waiverType === "EvaluationResult" ||
        testItem?.waiverData?.waiverType === "GeneralWaiver") &&
      (!testItem?.waiverData?.waiverResponses ||
        testItem?.waiverData?.waiverResponses?.length === 0 ||
        testItem?.waiverData?.waiverResponses[
          testItem?.waiverData?.waiverResponses.length - 1
        ].responseType === "REQUESTED" ||
        testItem?.waiverData?.waiverResponses[
          testItem?.waiverData?.waiverResponses.length - 1
        ].responseType === "RE-REQUESTED")
  );
  return (
    <>
      {isWaiverPending ? (
        <Grid key={item.testGroupName} data-testid="failed-section-wrapper">
          {item.overallFailingTests === "FAIL" &&
            pendingWaiver !== undefined && (
              <Grid
                container
                className={
                  isMinimized
                    ? classes.sectionWrapperMinimized
                    : classes.sectionWrapper
                }
                key={item.testGroupName}
              >
                <Grid item xs={12} container>
                  <Grid
                    xs={6}
                    sm={11}
                    style={{
                      fontSize: 12,
                      fontWeight: 600,
                      lineHeight: 1.5,
                      color: "#32325d",
                      display: "flex",
                      alignItems: "center"
                      // paddingLeft: 10,
                    }}
                  >
                    {item.representableTestGroupName}
                    {item.overallFailingTests === "FAIL" && (
                      <img
                        src={iconFailure}
                        alt="Test Failure"
                        style={{ marginLeft: 6, width: 16, height: 16 }}
                      />
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={1}
                    className={classes.headingImageContainer}
                  >
                    {isMinimizable && (
                      <ExpandCollapse
                        isMinimize={isMinimized}
                        clicked={() => {
                          setIsMinimized(!isMinimized);
                        }}
                        keyDown={() => {
                          setIsMinimized(!isMinimized);
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
          <Grid
            container
            xs={12}
            style={{
              display: isMinimized ? "none" : "",
              padding: isMinimized ? "10px" : "0"
            }}
          >
            {failedTestItems.map(
              (failureItem: EvaluationResultsFailureData) => {
                return (
                  <Grid
                    xs={failureCardSize?.xs ? failureCardSize.xs : 12}
                    sm={failureCardSize?.sm ? failureCardSize.sm : 12}
                    md={failureCardSize?.md ? failureCardSize.md : 12}
                    xl={failureCardSize?.xl ? failureCardSize.xl : 12}
                  >
                    <FailureResultsCard
                      key={failureItem.testGroup}
                      testGroup={failureItem.testGroup}
                      testName={failureItem.testName}
                      representableTestName={failureItem.representableTestName}
                      isMandatory={isMandatoryTest(
                        failureItem.testName,
                        item.testGroupName
                      )}
                      errorAddressList={errorAddressList}
                      failureMessage={failureItem.reasonForFailure}
                      dividerRequired={dividerRequired}
                      redirectionRequired={redirectionRequired}
                      showIntermediateValues={showIntermediateValues}
                      redirectionPage={failureItem.redirectionButtonName}
                      propertyId={failureItem.propertyId}
                      waiverData={failureItem.waiverData}
                      numberOfWaiversCreated={numberOfWaiversCreated}
                    />
                  </Grid>
                );
              }
            )}
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}
