import React from "react";
import { Grid, IconButton, Typography, Button } from "@mui/material";
import { Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { OpenInNewTab } from "../OpenInNewTab";
import { StatusComponent } from "../waiver-form/StatusComponent";
import { UserDetailsCard } from "../exceptions-and-enquiries/UserDetailsCard";
import { getDateInFormat } from "../../utils/utils";
import { InfoOnlyTooltip } from "../../utils/Tooltip";
import { ConfirmationDialog } from "@toorak/tc-common-fe-sdk";
import { CloseIcon, RedirectIcon } from "../Icons";
import {
  deleteScenarioAttachedToLoan,
  getScenarioListFromElasticSearch,
  updateScenarioDetailsListInRedux
} from "../../stores/Scenario/ScenarioActions";
import { getAllDiscussions } from "../../stores/Discussions/Discussions.action";
import {
  showLoader,
  hideLoader
  // clearLoader
} from "../../stores/loaderAndException/loaderAndException.action";
import { RootState } from "../../stores/rootReducer";
import { getConfig } from "../../config/config";
import { clearLoader } from "../../stores/loaderAndException/loaderAndException.reducer";

const useStyles = makeStyles((theme: Theme) => ({
  scenarioCardWrap: {
    margin: 5,
    border: "1px solid #e9ecef",
    borderRadius: 5,
    backgroundColor: "#ffffff"
  },
  scenarioCardPadding: {
    padding: 15
  },
  scenarioIDHeading: {
    fontSize: 12,
    color: "#32325d",
    fontWeight: 600
  },
  scenarioIDText: {
    fontSize: 12,
    color: "#32325d",
    fontWeight: 700
  },
  scenarioSubject: {
    fontSize: 12,
    color: "#32325d",
    fontWeight: 600,
    margin: "8px 0"
  },
  scenarioDesc: {
    fontSize: 12,
    color: "#32325d",
    fontWeight: "normal"
  },
  viewDetailsbutton: {
    borderRadius: "4px",
    boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
    backgroundColor: "#5e72e4",
    width: "fit-content",
    marginTop: "15px"
  }
}));

export const ScenarioCardDetails = (props: any) => {
  const { userName, scenarioDetails, nameInitials } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const isViewMode = useSelector<RootState, boolean | undefined>(
    (state) => state.createLoanStore.isViewMode
  );
  const [openModal, setOpenModal] = React.useState(false);
  const { loanId, loanStage } = useParams<any>() as any;
  const handleRemoveScenarioFromLoan = () => {
    dispatch(showLoader());
    deleteScenarioAttachedToLoan(loanId, scenarioId)
      .then(async (resp: any) => {
        // await removeScenarioDocs(loanId,scenarioId,loanStage);
        const listView = getScenarioListFromElasticSearch(
          false,
          "",
          scenarioId
        );
        listView
          .then((res: any) => {
            dispatch(
              updateScenarioDetailsListInRedux(
                res.data?.response?.hits?.hits,
                resp.data?.response?.hits?.total?.value
              )
            );
            dispatch(getAllDiscussions(loanId, loanStage, 0, 0, {}, "", true));
            dispatch(hideLoader());
          })
          .catch((err: any) => {
            dispatch(clearLoader());
            console.log("err", err);
          });
      })
      .catch((err: any) => {
        dispatch(clearLoader());
        console.log("err", err);
      });
  };

  const showModal = () => {
    setOpenModal(true);
  };

  const onCancelModal = () => {
    setOpenModal(false);
  };

  const onConfirmModal = () => {
    setOpenModal(false);
    handleRemoveScenarioFromLoan();
  };

  const date = scenarioDetails?._source?.waiver?.created_on
    ? getDateInFormat(scenarioDetails?._source?.waiver?.created_on)
    : "";
  const description = scenarioDetails?._source?.waiver?.description;
  const subject = scenarioDetails?._source?.waiver?.title;
  const status = scenarioDetails?._source?.waiver?.waiverInfo?.finalResponse;
  const finalStatus =
    status && status === "open" ? "SCENARIO REQUESTED" : status;
  const scenarioId = scenarioDetails?._source?.identifierValue;
  const { isWithdrawnLoan } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );
  return (
    <div className={classes.scenarioCardWrap}>
      <Grid
        container
        direction="column"
        className={classes.scenarioCardPadding}
      >
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Typography className={classes.scenarioIDHeading}>
              Scenario ID:
            </Typography>
            <Typography className={classes.scenarioIDText}>
              {scenarioId}
            </Typography>
          </Grid>
          <Grid item justifyContent="center" alignItems="center">
            {!isWithdrawnLoan && (
              <InfoOnlyTooltip
                title="Remove the Scenario"
                arrow
                placement="left"
              >
                <IconButton
                  component="button"
                  style={{ marginLeft: 8, padding: 0 }}
                  onClick={() => showModal()}
                  disabled={isViewMode || isWithdrawnLoan}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </InfoOnlyTooltip>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Typography className={classes.scenarioSubject}>{subject}</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.scenarioDesc} gutterBottom>
            {description}
          </Typography>
        </Grid>
        <Grid item style={{ margin: "8px 0" }}>
          {finalStatus ? (
            <Grid item>
              <StatusComponent status={finalStatus} value={finalStatus} />
            </Grid>
          ) : null}
        </Grid>
        <Grid container alignItems="center">
          <UserDetailsCard
            alternateText="Requested by"
            userImg="some img will come here"
            accountNameImg={nameInitials}
            userFullName={userName}
            accountType="O"
            date={date}
            sidePanel
          />
          {/* <OpenInNewTab
              path={`loan/scenario/${scenarioId}/view`}
              secondaryParams={{ exceptionId: "888", personPartyId: "888" }}
            /> */}
        </Grid>
        <Button
          variant="contained"
          size="small"
          color="info"
          data-testId="view-details-btn"
          className={classes.viewDetailsbutton}
          component="button"
          onClick={() => {
            window.open(
              `${getConfig().redirectUrl}loan/scenario/${scenarioId}/view`
            );
          }}
        >
          View Details &nbsp;
          <RedirectIcon />
        </Button>
      </Grid>
      <ConfirmationDialog
        onCancel={onCancelModal}
        head="Remove Scenario"
        onConfirm={onConfirmModal}
        open={openModal}
        msg="Are you sure you want to remove the scenario from loan?"
        btnText="Remove"
      />
    </div>
  );
};
