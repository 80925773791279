import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Container, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { RootState } from "../../../stores/rootReducer";
import { ActionsCard } from "../../exceptions-and-enquiries/ActionsCard";
import { isLATUser, isILP } from "../../../utils/AccessManagement";
import { ResponseTypeEnum } from "../../../utils/constants";
import { WaiverView } from "../../waiver-form/WaiverView";
import {
  hideLoader,
  showLoader
} from "../../../stores/loaderAndException/loaderAndException.action";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      input: {
        backgroundColor: "#ffffff !important"
      }
    },
    containerStyle: {
      padding: "15px 20px 15px 0px",
      // borderBottom: "solid 1px #f2f2f2",
      //   borderTop: "solid 1px #f2f2f2",
      // border: "solid 1px #f2f2f2",
      backgroundColor: "#ffffff"
    },
    containerShadow: {
      // boxShadow: "2px 2px 6px 2px rgba(0, 0, 0, 0.1)",
      // margin: "24px",
      // borderRadius: "4px",
      // border: "solid 1px #e1e1e1",
      backgroundColor: "#ffffff"
    }
  })
);

export const ViewMoreExceptions = (props: any) => {
  const {
    exception,
    status,
    loanId,
    addCommentCB,
    // loanType,
    loanStage,
    resourceId,
    visibility,
    type,
    exceptionId,
    exceptionType,
    loanDetails,
    activeInteractionsModal,
    cardType,
    waiverId,
    getDetails,
    currentLoanStage,
    isWithdrawnLoan,
    from,
    bulkDetails,
    isBulk
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const { exceptionsResult } = useSelector<RootState, any>(
    (state) => state.exceptionsResultsStore
  );

  const { activeInteractionsModalArray } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );
  const { commListData } = useSelector<RootState, any>(
    (state) => state.dashboardStore
  );
  const [responses, setResponses] = useState<any>([]);
  const [chatDetails, setChatDetails] = useState<any>([]);
  const [waiversResult, setWaiversResult] = useState<any>([]);
  const [filteredWaivers, setFilteredWaivers] = useState<any>([]);
  useEffect(() => {
    const arr = activeInteractionsModal
      ? activeInteractionsModalArray
      : window.location.href.indexOf("tab=0") > -1
      ? commListData
      : bulkDetails && isBulk
      ? bulkDetails?.exceptions.exceptions
      : exceptionsResult?.exceptions;
    if (activeInteractionsModal || window.location.href.indexOf("tab=0") > -1) {
      const exceptionFilter = arr.filter((item: any) => {
        return item?._source?.resourceId === resourceId;
      });

      const res = exceptionFilter?.[0]?._source?.responses;
      const waivers = exceptionFilter?.[0]?._source?.waiverResult;
      const filteredWaiver =
        !isLATUser() && waivers?.waivers instanceof Array
          ? waivers?.waivers?.filter(
              (item: any) => item.waiverInfo.isInternal === false
            )
          : waivers?.waivers;
      setResponses(res);

      setFilteredWaivers(filteredWaiver);
      setWaiversResult(waivers);
    } else {
      const exceptionFilter = arr.filter((item: any) => {
        return item?.exceptionId === resourceId;
      });
      const res = exceptionFilter?.[0]?.responses;
      const waivers = exceptionFilter?.[0]?.waiverResult;
      const filteredWaiver =
        !isLATUser() && waivers?.waivers instanceof Array
          ? waivers?.waivers?.filter(
              (item: any) => item.waiverInfo.isInternal === false
            )
          : waivers?.waivers;
      setResponses(res);

      setFilteredWaivers(filteredWaiver);
      setWaiversResult(waivers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeInteractionsModalArray,
    commListData,
    bulkDetails,
    exceptionsResult
  ]);

  // eslint-disable-next-line
  const [files, setFiles] = useState<any[]>([]);

  const {
    Approver,
    Recommendation,
    Inquiry,
    SendtoOriginator,
    OriginatorQuery,
    Query
  } = ResponseTypeEnum;

  let filteredResponse: any =
    responses instanceof Array
      ? responses.filter(
          (item: any) =>
            ![
              Approver,
              Recommendation,
              Inquiry,
              SendtoOriginator,
              Query
            ].includes(item.responseType)
        )
      : [];
  filteredResponse = !isLATUser()
    ? filteredResponse.filter(
        (item: any) =>
          item.visibility === visibility &&
          item.responseType !== OriginatorQuery
      )
    : filteredResponse;

  useEffect(() => {
    if (exception && Object.keys(exception).length) {
      dispatch(hideLoader());
    } else {
      dispatch(showLoader());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exception]);

  // const loanExceptionType =
  //   exception && type && type?.toLowerCase() === "inquiry"
  //     ? "Inquiry"
  //     : "Condition";
  // const heading = `Loan ${loanExceptionType}  •  Loan ID: ${loanId}`;
  const gridRef: any = React.createRef();

  useEffect(() => {
    if (gridRef.current && waiversResult) {
      gridRef.current.scrollIntoView({
        behavior: "auto",
        block: "end"
      });
    } else {
      gridRef?.current?.scrollIntoView(false);
    }

    const getchatDetails = {
      accountMapping: waiversResult?.accountMapping,
      accounts: waiversResult?.accounts,
      customers: waiversResult?.customers,
      documents: waiversResult?.documents
    };
    setChatDetails(getchatDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waiversResult]);

  return (
    <>
      <Grid container style={isILP() ? { marginTop: "20px" } : {}}>
        <Grid container>
          {exception ? (
            <Container
              maxWidth={!isILP() ? "lg" : "xl"}
              style={
                !isILP()
                  ? { paddingTop: "40px", paddingLeft: 0, paddingRight: 0 }
                  : { padding: "0px" }
              }
            >
              <Grid
                className={classes.containerShadow}
                style={
                  isILP() ? { margin: "0px", border: 0, boxShadow: "none" } : {}
                }
              >
                {filteredResponse instanceof Array &&
                filteredResponse.length > 0 &&
                exception ? (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    // className={classes.wrapperActionsCard}
                  >
                    {filteredResponse instanceof Array &&
                      filteredResponse.map((item: any) => (
                        <ActionsCard
                          chatDetails={item}
                          key={item.chatSequenceId}
                          status={status}
                          exceptionTypeProp={exceptionType}
                          cardType={cardType}
                          excepId={resourceId}
                          loanIdComm={loanId}
                          loanStageComm={loanStage}
                          condtType={type}
                          exceptionId={exceptionId}
                          activeInteractionsModal={activeInteractionsModal}
                          viewMore
                          waiverId={waiverId}
                          getDetails={getDetails}
                          currentLoanStage={currentLoanStage}
                          from={from}
                          addCommentCB={addCommentCB}
                          exception={exception}
                        />
                      ))}
                  </Grid>
                ) : null}
                {filteredWaivers instanceof Array &&
                exception &&
                filteredWaivers?.length > 0 ? (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    ref={gridRef}
                  >
                    {filteredWaivers instanceof Array &&
                    filteredWaivers?.length > 0
                      ? filteredWaivers.map((item: any) =>
                          item.waiverKeys &&
                          item.waiverKeys[0] === exceptionId &&
                          item.waiverInfo?.finalResponse !== "RESCIND" ? (
                            // RESCIND waiver is allowed only in rescind view
                            <span key={item.waiverId}>
                              <React.Suspense
                                fallback={<span>Loading...</span>}
                              >
                                <WaiverView
                                  exceptionWaiverId={item.waiverId}
                                  documents={waiversResult.documents}
                                  status={status}
                                  visibility={visibility}
                                  waiversCreatedProp={filteredWaivers}
                                  exceptionIdProp={item.waiverKeys[0]}
                                  waiverReqInfoProp={{}}
                                  chatDetails={chatDetails}
                                  waiverChatProp={item.chat}
                                  viewModeProp={false}
                                  customerDetailsProp={waiversResult}
                                  waiverHistory={waiversResult.waiverHistory}
                                  waiverTypeProp="Exception"
                                  loanDetails={loanDetails}
                                  waiverMitigatesProp={item.waiverMitigates}
                                  viewMore
                                  getDetails={getDetails}
                                  currentLoanStage={currentLoanStage}
                                  type={type}
                                  exceptionType={exceptionType}
                                  isWithdrawnLoan={isWithdrawnLoan}
                                  from={from}
                                  addCommentCB={addCommentCB}
                                />
                              </React.Suspense>
                            </span>
                          ) : null
                        )
                      : null}
                  </Grid>
                ) : null}
              </Grid>
            </Container>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};
