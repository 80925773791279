/* eslint-disable react/jsx-indent */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-default-export */
import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { LoanStage } from "@toorak/tc-common-fe-sdk";

import { rejectLoan } from "../stores/TKOP/takeoutPartner.action";

// images
import iconsDarkCross from "../images/createloan/tkop/icons-dark-cross.png";
import pricing from "../images/createloan/tkop/pricing.png";
import iconPass from "../images/createloan/tkop/icons-pass-green.png";
import iconFail from "../images/createloan/tkop/icons-dark-fail.png";
import iconBellWarningLight from "../images/createloan/tkop/icons-bell-warning.png";

import { TKOPBox } from "./TKOPModal";
import { loanCardStyles, useStylesBootstrap } from "./styles";
import { TakeOutPartnerContext } from "./takeoutPartner";
import { ObjectType } from "../masterView/common";
import {
  showLoader,
  hideLoader
} from "../stores/loaderAndException/loaderAndException.action";

function BootstrapTooltip(props: any) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} placement="left" />;
}

export interface PartnerDetailsProps {
  loanId: string;
  originatorId: string;
  loanStage: LoanStage;
  loanState: string;
  loanType: string;
  eligiblePartners: [];
  activeServicerTab: string;
  loanRuleVersions: [];
  takeoutPartnerId: string;
}

export default function PartnerDetails(props: PartnerDetailsProps) {
  const {
    loanId,
    loanStage,
    loanState,
    eligiblePartners,
    activeServicerTab,
    loanRuleVersions,
    takeoutPartnerId,
    originatorId
  } = props;
  const [open, setOpen] = useState<boolean>(false);
  const { creditLines, setCurrentlySelectedTab } = useContext(
    TakeOutPartnerContext
  );
  const dispatch = useDispatch();
  const classes = loanCardStyles();

  const refreshPageContent = () => setCurrentlySelectedTab(activeServicerTab);

  const rejectSingleLoan = async (loans: any) => {
    const loan = loans.map((item: any) => {
      return {
        loanId: item.loanId,
        loanStage: item.loanStage,
        loanState: "Rejected"
      };
    });
    try {
      dispatch(showLoader());
      await rejectLoan(loan[0], refreshPageContent);
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      console.log("error is ", error);
    }
  };
  const onClose = () => {
    setOpen(false);
  };

  const getNameFromId = (partnerId: any) => {
    return creditLines.find((item: ObjectType) => item.id === partnerId)
      .shortName;
  };

  return (
    <Grid style={{ paddingLeft: "20px" }}>
      <Grid>
        <TKOPBox
          partnerNames={creditLines}
          loanId={loanId}
          onClose={onClose}
          open={open}
          loanStage={loanStage}
          loanRuleVersions={loanRuleVersions}
          takeoutPartnerId={takeoutPartnerId}
          originatorId={originatorId}
        />
        <Grid container spacing={1}>
          {activeServicerTab === "UnassignedLoans"
            ? creditLines
                .map((item: ObjectType) => {
                  const currentCreditLineEconomics = item.creditLineEconomics.filter(
                    (cdlEcoItem: any) => cdlEcoItem.isCurrentCycle
                  )[0];
                  return {
                    ...item,
                    isBalanceOver:
                      currentCreditLineEconomics.totalLoanAmount -
                        currentCreditLineEconomics.purchasedLoanAmount -
                        currentCreditLineEconomics.pipelineLoanAmount <
                      0
                  };
                })
                .map((item: ObjectType, index: number) => {
                  return (
                    <Grid item key={`button_${index.toString()}`}>
                      <BootstrapTooltip
                        title={
                          item.isBalanceOver
                            ? "Eligible partners balance limit is over to take this loan."
                            : eligiblePartners &&
                              eligiblePartners.length > 0 &&
                              eligiblePartners.some(
                                (item: any) => item.takeoutPartnerPartyId === item.id
                              )
                            ? `Loan is eligible
                          for this partner`
                            : `Loan is not eligible
                          for this partner`
                        }
                      >
                        <Button
                          variant="outlined"
                          className={classes.partnerButton}
                        >
                          {item.shortName}{" "}
                          {item.isBalanceOver ? (
                            <img
                              src={iconBellWarningLight}
                              alt="bell-warning-icon"
                            />
                          ) : eligiblePartners &&
                            eligiblePartners.length > 0 &&
                            eligiblePartners.some(
                              (item: any) =>
                                item.takeoutPartnerPartyId === item.id
                            ) ? (
                            <img src={iconPass} alt="icon pass" />
                          ) : (
                            <img src={iconFail} alt="icon fail" />
                          )}
                        </Button>
                      </BootstrapTooltip>
                    </Grid>
                  );
                })
            : eligiblePartners &&
              eligiblePartners.map((item: ObjectType, index: number) => {
                return (
                  <Grid item key={`button_${index.toString()}`}>
                    <BootstrapTooltip
                      title={`Loan is eligible
                          for this partner`}
                    >
                      <Button
                        variant="outlined"
                        className={classes.partnerButton}
                      >
                        {getNameFromId(item.takeoutPartnerPartyId)}{" "}
                        {item.takeoutPartnerPartyId === activeServicerTab && (
                          <img src={iconPass} alt="icon pass" />
                        )}
                      </Button>
                    </BootstrapTooltip>
                  </Grid>
                );
              })}
          <Grid item>
            {activeServicerTab !== "UnassignedLoans" &&
            eligiblePartners === undefined ? (
              "No eligible partner"
            ) : (
              <BootstrapTooltip title="Compare Test Results and Pricing">
                <IconButton
                  onClick={() => {
                    setOpen(true);
                  }}
                  className={classes.partnerIconButton}
                  color="primary"
                  aria-label="comapre test and pricing"
                  size="large">
                  <img src={pricing} alt="pricing" />
                </IconButton>
              </BootstrapTooltip>
            )}
          </Grid>
          {activeServicerTab === "UnassignedLoans" && (
            <Grid item>
              <BootstrapTooltip title="Reject the Loan">
                <IconButton
                  className={classes.partnerIconButton}
                  color="primary"
                  aria-label="reject loan"
                  onClick={() =>
                    rejectSingleLoan([{ loanId, loanStage, loanState }])
                  }
                  data-testid="raject-signle-loan"
                  size="large">
                  <img style={{}} src={iconsDarkCross} alt="icons-Dark-Unpin" />
                </IconButton>
              </BootstrapTooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
