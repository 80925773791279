/**
 * CRITICAL: blocks user to close the error popup. Only refresh button is available that refreshes the page.
 * MEDIUM: shows error popup and gives option for user to close it.
 * LOW: shows inline error message that disappears after 5seconds.
 * In case array of errors present in ErrorContext contains multiple errors with different ErrorPriority, one with highest ErrorPriority will be considered.
 *
 * NOTE: BY DEFAULT EVERY ERROR IS CONSIDERED MEDIUM PRIORITY.
 */
export enum ErrorPriority {
  critical = "CRITICAL", // To be used only when page refresh is the only option.
  medium = "MEDIUM",
  low = "LOW"
}

/**
 * This function creates and returns a errorConfig custom object to be passed in axios config.
 * Main goal of this function is to make the errorConfig consistent across and avoid change at multiple places if we add a new common parameter in future.
 * @param data.skipForStatus can be used to skip error handling in case a particular error is thrown. ex: if i want to skip error handling for a api when 400 and 404 is thrown, skipForStatus=[400,404].
 * @param data.skipErrorHandling if true, skips error handling for that particular api.
 * @param data.errorPriority used to show particular error component. if not specified defaults to MEDIUM.
 * @param data.customErrorMessage can be used to specify specific error message and title. If not specified, generic error message is shown to user. ErrorMessageJason.ts must be used for defining custom message so that all message can be sent to BA for reviewing.
 * @param data.customErrorComponent (Not developed yet) can be used to display custom error component.
 * @param data.dontSkipIfAlreadyPresent if this is not sent, before storing error object in reducer we check for api end point and at one given point, only 1 error per api is stored.
 */
export const getErrorConfigObject = (data: {
  skipForStatus?: any[];
  skipErrorHandling?: boolean;
  errorPriority?: ErrorPriority;
  customErrorMessage?: { heading?: string; body?: string };
  customErrorComponent?: string;
  dontSkipIfAlreadyPresent?: boolean;
}) => {
  const {
    skipErrorHandling,
    errorPriority,
    customErrorMessage,
    customErrorComponent,
    skipForStatus
  } = data;
  return {
    skipErrorHandling,
    errorPriority,
    customErrorMessage,
    customErrorComponent,
    skipForStatus
  };
};

export interface ErrorComponentProps {
  errorData: {
    id: string;
    status: any;
    errorMessageBody: string;
    errorHeader: string;
    errorObject: any;
    autoClose?: boolean;
  };
  hasCloseOption?: boolean;
}
export const toorakSupportMessage =
  "If issue continues, contact us at tooraksupport@triconinfotech.com";
export const headerTextForCode = (status: any) => {
  switch (status) {
    case 400:
      return "Bad Request";
    case 404:
      return "Resource Not Found";
    case 500:
      return "Server Down";
    case 503:
      return "Server Down";
    default:
      return "Error";
  }
};
export const errorTextForCode = (status: any) => {
  let errorMessage = "";
  switch (status) {
    case 400:
      errorMessage = "There is some issue with the provided data.";
      break;
    case 404:
      errorMessage = "We are not able to find the requested information.";
      break;
    case 500:
      errorMessage = "We are facing issue connecting to server.";
      break;
    case 503:
      errorMessage = "We are facing issue connecting to server.";
      break;
    default:
      errorMessage = "Something went wrong!";
  }
  return `${errorMessage} Please try again. ${toorakSupportMessage}`;
};
export const getErrorMessageAndHeader = (
  errorObj: any
): {
  status: any;
  errorHeader: string;
  errorMessageBody: string;
  requestInfo: any;
} => {
  try {
    const { config, response } = errorObj.errorData;
    const { status } = response || {};
    const { errorConfig } = config;
    const requestInfo = {
      method: config?.method,
      url: config?.url,
      requestBody: config?.data,
      headers: config?.headers
    };
    return {
      status,
      errorHeader:
        errorConfig?.customErrorMessage?.heading || headerTextForCode(status),
      errorMessageBody:
        errorConfig?.customErrorMessage?.body || errorTextForCode(status),
      requestInfo
    };
  } catch (error) {
    console.log(error);
    return {
      status: "",
      errorHeader: headerTextForCode("default"),
      errorMessageBody: errorTextForCode("default"),
      requestInfo: {}
    };
  }
};
