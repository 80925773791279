/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, Chip, Grid, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Autocomplete } from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { WhiteArrowTooltip } from "@toorak/tc-common-fe-sdk";
import EmailIcon from "@mui/icons-material/Email";
import {
  customersListPrep,
  getCustomersList
} from "../../stores/commentsSection/comment.action";
import { TagProps } from ".";

const SignedIcon = (
  <WhiteArrowTooltip title="PA Signed by User">
    <CheckCircleIcon
      style={{ height: "16px", width: "16px", color: "#2ece89" }}
    />
  </WhiteArrowTooltip>
);

const EmailSentIcon = (
  <WhiteArrowTooltip title="Mail sent to user for signing">
    <EmailIcon style={{ height: "16px", width: "16px", color: "#5566d6" }} />
  </WhiteArrowTooltip>
);

const icon = <CheckBoxOutlineBlankIcon fontSize="large" />;
const checkedIcon = <CheckBoxIcon fontSize="large" />;

const checkedTagStyles = makeStyles((theme) => ({
  chipRoot: {
    color: "#172b4d",
    fontSize: "10px",
    fontWeight: "bold",
    margin: "0px",
    borderRadius: "6px",
    border: "solid 1px #c4e1f6",
    backgroundColor: "#d9efff",
    height: "auto",
    marginRight: "4px",
    marginBottom: "2px"
  },
  deleteIcon: {
    width: "14px",
    height: "14px",
    color: "#172b4d !important",
    marginLeft: "0px"
  },
  chipLabel: {
    padding: "4px"
  },
  inputStyles: {
    padding: "0px !important",
    fontSize: "12px",
    color: "#32325d"
  },
  options: {
    padding: "0px",
    margin: "0px"
  },
  tag: {
    cursor: "pointer"
  },
  hide: {
    display: "none"
  },
  hideBorder: {
    border: "none"
  },
  chipAvatar: {
    marginRight: "0px !important"
  },
  customSignee: {
    border: "1px solid #e7b78e",
    backgroundColor: "#ffc10754"
  }
}));

export const CheckboxesTags = (props: {
  from?: "multiSignee" | "comments";
  tagsVal: any[];
  saveTags: Function;
  tagProps: TagProps;
  disabled?: boolean;
  dropDownOptions?: any[];
}) => {
  const { from, tagsVal, saveTags, tagProps, disabled, dropDownOptions } =
    props;
  const classes = checkedTagStyles();
  const [tags, setTags] = useState<{ title: string; key: string }[]>(
    dropDownOptions || []
  );

  const initCall = () => {
    if (tagProps?.type === "customer") {
      getCustomersList(tagProps?.props?.roles || [])
        .then((res: any) => {
          setTags(customersListPrep(res?.data?.response?.hits?.hits || []));
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (dropDownOptions?.length) setTags(dropDownOptions);
    else initCall();
  }, [dropDownOptions]);

  const optionsGenerator = (
    props: any,
    option: {
      key: string;
      title: string;
      subTitle?: string;
    },
    { selected }: any
  ) => {
    const tagsValObj: any = tagsVal.reduce(
      (a, v) => ({ ...a, [v.key]: v }),
      {}
    );
    if (option.key in tagsValObj && from === "multiSignee") return null;
    return (
      <div style={{ padding: "5px 10px" }} {...props}>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={selected}
        />
        <span style={{ fontSize: "12px", fontWeight: "normal" }}>
          <b>{option.title}</b>
          {option.subTitle ? `, ${option.subTitle}` : ""}
        </span>
      </div>
    );
  };

  const tagGenerator = (tagValue: any[], getTagProps: any) => {
    return tagValue.map((option, index) => {
      return (
        <Chip
          label={option.title}
          {...getTagProps({ index })}
          classes={{
            root: classes.chipRoot,
            deleteIcon: classes.deleteIcon,
            label: classes.chipLabel
          }}
          deleteIcon={<CloseIcon />}
        />
      );
    });
  };

  const signeeStatusIcon = (status: string) => {
    if (status === "COMPLETED") return SignedIcon;
    if (status === "EMAIL_SENT" || status === "EMAIL_TRIGGERED")
      return EmailSentIcon;
  };

  const tagGeneratorMultiSignee = (tagValue: any[], getTagProps: any) => {
    return tagValue
      .filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.key === value.key)
      )
      .map((option, index) => {
        return (
          <Chip
            avatar={signeeStatusIcon(option.status)}
            label={
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <span style={{ fontSize: "10px", fontWeight: "normal" }}>
                    <b>{option.title}</b>, {option.subTitle}
                  </span>
                </Grid>
                {option.newSignee && (
                  <Grid item>
                    <WhiteArrowTooltip title="New Signee">
                      <span
                        style={{
                          fontSize: "10px",
                          fontWeight: "normal",
                          color: "#ffffff",
                          background: "#11ceef",
                          borderRadius: "9px",
                          padding: "1px 5px"
                        }}
                      >
                        <b>GUEST</b>
                      </span>
                    </WhiteArrowTooltip>
                  </Grid>
                )}
              </Grid>
            }
            {...getTagProps({ index })}
            classes={{
              root: classes.chipRoot,
              deleteIcon:
                option.newSignee && !tagProps.props?.isLAT
                  ? classes.hide
                  : classes.deleteIcon,
              label: classes.chipLabel,
              avatar: classes.chipAvatar
            }}
            deleteIcon={<CloseIcon />}
          />
        );
      });
  };

  return (
    <Autocomplete
      classes={{
        option: classes.options,
        tag: classes.tag,
        endAdornment: disabled ? classes.hide : undefined
      }}
      disabled={disabled}
      value={tagsVal}
      limitTags={!disabled ? 5 : undefined}
      onChange={(event: any, newValue: any) => {
        saveTags(
          newValue.filter(
            (value: any, index: number, self: any[]) =>
              index === self.findIndex((t) => t.key === value.key)
          )
        );
      }}
      className="restricted-from-global-styles"
      multiple
      id="checkboxes-tags-demo"
      popupIcon={<KeyboardArrowDownIcon />}
      options={tags}
      disableCloseOnSelect
      disableClearable
      getOptionLabel={(option) => option?.title ?? ""}
      defaultChecked
      renderOption={optionsGenerator as any}
      style={{ width: "100%" }}
      renderTags={
        from === "multiSignee" ? tagGeneratorMultiSignee : tagGenerator
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            classes: {
              input: disabled ? classes.hide : undefined,
              notchedOutline: disabled ? classes.hideBorder : undefined
            }
          }}
          placeholder={disabled ? "" : "Tag To"}
        />
      )}
    />
  );
};
