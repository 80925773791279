/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { CacheSizeProvider } from "./CacheSizeProvider";

export const calculateIndexedDBSize = () => {
  const cacheProvider = CacheSizeProvider();
  const DBNames = ["TTFREVIEW"];

  const getTableSize = (openedDBResult: any, tableName: string) => {
    return new Promise((resolve, reject) => {
      if (openedDBResult == null) {
        return reject();
      }
      let DBSize = 0;

      const transaction = openedDBResult
        .transaction([tableName])
        .objectStore(tableName)
        .openCursor();

      transaction.onsuccess = (event: any) => {
        const cursor = event.target.result;
        if (cursor) {
          const storedObject = cursor.value;
          const json = JSON.stringify(storedObject);
          DBSize += json.length;

          const keys: any = Object.keys(storedObject);
          const values: any = Object.values(storedObject);
          for (let i = 0; i < keys.length; i += 1) {
            if (values[i] instanceof Blob) {
              DBSize += values[i].size;
            }
          }
          cursor.continue();
        } else {
          resolve(DBSize);
        }
      };
      transaction.onerror = function(err: any) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject(`error in ${tableName}: ${err}`);
      };
    });
  };

  const humanReadableSize = function(bytes: number) {
    const thresh = 1024;
    let copyBytes = bytes;
    if (Math.abs(bytes) < thresh) {
      return `${bytes} B`;
    }
    const units = ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let u = -1;
    do {
      copyBytes /= thresh;
      u += 1;
    } while (Math.abs(copyBytes) >= thresh && u < units.length - 1);
    return `${copyBytes.toFixed(1)} ${units[u]}`;
  };

  DBNames.forEach((DBName: string) => {
    const request: IDBOpenDBRequest = indexedDB.open(DBName);
    request.onerror = (event: any) => {
      console.log("Why didn't you allow my web app to use IndexedDB");
    };

    request.onsuccess = (event: any) => {
      const openedDBResult = event?.target?.result;
      const tableNames: DOMStringList = openedDBResult?.objectStoreNames;
      const tableNamesArray: string[] = Array.from(tableNames);

      const tableSizeGetters = tableNamesArray.reduce(
        (acc: Promise<any>[], tableName: string) => {
          const promise: Promise<any> = getTableSize(openedDBResult, tableName);
          acc.push(promise);
          return acc;
        },
        []
      );

      Promise.all(tableSizeGetters).then((sizes: number[]) => {
        const total = sizes.reduce(function(acc, val) {
          return acc + val;
        }, 0);

        tableNamesArray.forEach((tableName: any, i: any) => {
          const payload = {
            size: total,
            humanReadableFormat: humanReadableSize(total)
          };
          cacheProvider.setItem(DBName, payload);
        });
      });
    };
  });
};
