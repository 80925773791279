import React, { useEffect, useState, RefObject, useMemo } from "react";
import { useDebounce } from "../../custom-hooks/useDebounce";
import { uniqueArrayOfObject } from "../../ilp/actionDashboard/common/utils";

const INITIALSCROLLINDEX = 0;
const LISTSIZE = 20;

interface SearchConfigType {
  columnName: string;
  source: string;
}

export const useAction = (
  anchorRef: RefObject<Element>,
  columnName: string,
  columnLabel: unknown,
  handleAutoCompleteSearch: Function,
  handleUpdateSelectedCheckboxes: Function,
  searchConfig: SearchConfigType,
  filterSelections: any,
  options: any,
  KEY: string,
  isFrozen: boolean
) => {
  const [searchValue, setSearchValue] = useState<any>("");
  const [scrollIndex, setScrollIndex] = useState<any>(INITIALSCROLLINDEX);
  const [selectedTags, setSelectedTags] = useState<any>([]);
  const [isVisible, setIsVisible] = useState<any>(false);
  const [open, setOpen] = useState(false);

  const debounceSearchValue: string = useDebounce<string>(searchValue, 500);

  const searchBytext = (searchText: string, index: number) => {
    handleAutoCompleteSearch(
      searchText,
      [columnName],
      columnName,
      searchConfig,
      index,
      LISTSIZE,
      columnLabel
    );
  };

  const changeZIndex = (ele: any, override?: boolean) => {
    const state = override ?? open;
    let headerEle = ele?.closest('.MuiTableCell-root');
    if (headerEle) {
      headerEle.style.zIndex = state ? isFrozen ? 1 : 0 : 2;
    }
  };

  const onOpenFunction = (e: any) => {
    changeZIndex(e?.target);
    setOpen(isOpen => !isOpen);
    // handleFilterToggle(columnName);
  };

  const handleClose = (
    event: React.MouseEvent<EventTarget>,
    isCancel?: boolean
  ) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) return;
    setOpen(false);
    changeZIndex(anchorRef.current);
    setSearchValue("");
    if (isCancel) setSelectedTags([]); // for AM-1288
  };

  const handleSave = () => {
    const tags = selectedTags.map((tag: any) => tag.key);
    handleUpdateSelectedCheckboxes(columnName, tags, columnLabel);
    setOpen(false);
    changeZIndex(anchorRef.current);
  };

  const uniqueOptions = useMemo(() => {
    return uniqueArrayOfObject(options, KEY);
  }, [options, KEY]);

  useEffect(() => {
    if (debounceSearchValue.length <= 2) return;
    setScrollIndex(INITIALSCROLLINDEX);
    searchBytext(debounceSearchValue, INITIALSCROLLINDEX);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearchValue]);

  useEffect(() => {
    if (scrollIndex === INITIALSCROLLINDEX) return;
    searchBytext(debounceSearchValue, scrollIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollIndex]);

  useEffect(() => {
    if (!isVisible) return;
    setScrollIndex((prevIndex: any) => prevIndex + LISTSIZE);
    setIsVisible(false);
  }, [isVisible]);

  useEffect(() => {
    if (searchValue !== "") return;
    searchBytext(searchValue, INITIALSCROLLINDEX);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const isFilterApplied = useMemo(() => {
    if (!filterSelections || !filterSelections[columnName]) return false;
    return filterSelections[columnName].length !== 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSelections]);

  useEffect(() => {
    if (isFilterApplied) return;
    setSearchValue("");
    setSelectedTags([]);
  }, [isFilterApplied]);

  return {
    searchValue,
    scrollIndex,
    selectedTags,
    isVisible,
    open,
    setSearchValue,
    setScrollIndex,
    setSelectedTags,
    onOpenFunction,
    setIsVisible,
    handleSave,
    handleClose,
    uniqueOptions,
    isFilterApplied
  };
};
