import { getCookie } from "../../utils/cookies";
import {getConfig} from '../../config/config';

export async function getFileContentInJson(fileId:Number) {
  const url = `${getConfig().apiUrl}/extract/json/${fileId}`;
  return fetch(url, {
    method: 'GET',
    headers :{
      "Authorization" :`Bearer ${getCookie("id_token")}`
    }
  })
    .then(x => x.json());
}

export async function getDownloadUrl(fileId:Number) {
  const url = `${getConfig().apiUrl}/extract/download/${fileId}`;
  return fetch(url, {
    method: 'GET',
    headers :{
      "Authorization" :`Bearer ${getCookie("id_token")}`
    }
  })
    .then(x => x.json());
}