/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  Typography,
  CircularProgress,
  Box,
  CircularProgressProps
} from "@mui/material";

export const CircularProgressWithLabel = (
  props: CircularProgressProps & { value: number }
) => {
  return (
    <Box
      position="relative"
      display="inline-flex"
      style={{
        marginTop: "100px"
      }}
    >
      <CircularProgress
        color="primary"
        style={{
          transform: "rotate(-90deg)",
          color: "#0f97ee"
        }}
        variant="determinate"
        size={200}
        {...props}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          {`${Math.round(props.value)}% \n\nDownloading PDF file...`}
        </Typography>
      </Box>
    </Box>
  );
};
