import { LoanStage } from "@toorak/tc-common-fe-sdk";
import { ObjectType } from "../../masterView/common";

export const getLoanPricingFields = (
  version: string,
  additionalData?: ObjectType
) => {
  const versionToFieldsmap: ObjectType = {
    RATE: [
      {
        label: "Toorak Interest Rate",
        field: "toorakInterestRate",
        fieldType: "percentage",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.toorakInterestRate`,
        hideCondition: (data: any) => !data?.loanPricing?.toorakInterestRate
      },
      {
        label: "Base Rate",
        field: "baseRate",
        fieldType: "percentage",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.baseRate`,
        hideCondition: (data: any) => !data?.loanPricing?.baseRate
      },
      {
        label: "Base Price",
        field: "basePrice",
        fieldType: "numeric",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.basePrice`,
        hideCondition: (data: any) => !data?.loanPricing?.basePrice
      },
      {
        label: "Actual Interest Rate",
        field: "actualInterestRate",
        fieldType: "percentage",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        overRideinfoContent: `data.loan.loanEconomics.interestRateAsOfCutOffDate`
      },
      {
        label: "Variance From TRK Int. Rate",
        field: "interestRateVariance",
        fieldType: "percentage",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.interestRateVariance`,
        hideCondition: (data: any) => !data?.loanPricing?.interestRateVariance
      },
      // {
      //   label: "Rate Adj. - Lock Period",
      //   field: "rateLockAdjustment",
      //
      //   fieldType: "",
      //   loanStage: [pre, post],
      //   infoContent: `fieldInfo.bridge​​​​​.${loanStage.toLowerCase()}​​​​​.result.loanResult.${loanId}​​​​​.loanPricing.rateLockAdjustment`
      // },
      {
        label: "Price Adjustment",
        field: "priceAdjustment",
        fieldType: "",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.priceAdjustment`,
        hideCondition: (data: any) => !data?.loanPricing?.priceAdjustment
      },
      {
        label: "Prepayment Penalty - Max Price",
        field: "pppMaxPrice",
        fieldType: "numeric",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.pppMaxPrice`,
        hideCondition: (data: any) => !data?.loanPricing?.pppMaxPrice
      },
      {
        label: "State PPP Restriction - Max Price",
        field: "statepppRestrictionMaxPrice",
        fieldType: "numeric",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.statepppRestrictionMaxPrice`,
        hideCondition: (data: any) =>
          !data?.loanPricing?.statepppRestrictionMaxPrice
      },
      {
        label: "Final Toorak Price",
        field: "finalToorakPrice",
        fieldType: "numeric",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.finalToorakPrice`,
        hideCondition: (data: any) => !data?.loanPricing?.finalToorakPrice
      },
      {
        label: "Morgan Stanley Lease Status",
        field: "leaseStatus",
        fieldType: "dropdown",
        loanStage: [LoanStage.pre, LoanStage.post],
        infoContent: `loanPricing.leaseStatus`,
        dropDownValues: additionalData?.msLeaseStatusDropdownValue,
        hideCondition: (data: any) =>
          !data?.loanPricing?.leaseStatus &&
          !!additionalData?.msLeaseStatusDropdownValue
      }
    ],
    "": [
      {
        label: "Final Toorak Price",
        field: "finalToorakPrice",
        fieldType: "numeric",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.finalToorakPrice`,
        hideCondition: (data: any) => !data?.loanPricing?.finalToorakPrice
      },
      {
        label: "Price Adj. - Total",
        field: "totalPriceAdjustment",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.totalPriceAdjustment`
      },
      {
        label: "Base Price",
        field: "basePrice",
        fieldType: "numeric",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.basePrice`,
        hideCondition: (data: any) => !data?.loanPricing?.basePrice
      },
      {
        label: "Prepayment Penalty - Max Price",
        field: "pppMaxPrice",
        fieldType: "numeric",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.pppMaxPrice`,
        hideCondition: (data: any) => !data?.loanPricing?.pppMaxPrice
      },
      {
        label: "State PPP Restriction - Max Price",
        field: "statepppRestrictionMaxPrice",
        fieldType: "numeric",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.statepppRestrictionMaxPrice`
      },
      {
        label: "Max Toorak Price",
        field: "maxAllowedToorakPrice",
        fieldType: "numeric",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.maxAllowedToorakPrice`,
        hideCondition: (data: any) => data?.takeoutPartnerId === "c096ff03-b4c3-4691-bad9-aa448c328e7c" || !data
      },
      {
        label: "Morgan Stanley Lease Status",
        field: "leaseStatus",
        fieldType: "dropdown",
        loanStage: [LoanStage.pre, LoanStage.post],
        infoContent: `loanPricing.leaseStatus`,
        dropDownValues: additionalData?.msLeaseStatusDropdownValue,
        hideCondition: (data: any) =>
          !data?.loanPricing?.leaseStatus &&
          !!additionalData?.msLeaseStatusDropdownValue
      }
    ]
  };
  return versionToFieldsmap?.[version] ?? versionToFieldsmap[""];
};
