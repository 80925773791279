import React from "react";
import { publicClient } from "@toorak/tc-common-fe-sdk";
import { useDispatch } from "react-redux";
import {
  fetchRefreshToken,
} from "../login/authentication";
import { getCookie } from "../utils/cookies";
import { uuidV4 } from "../utils/utils";
import { ErrorComponentWrapper } from "../ErrorHandler/ErrorComponentWrapper";
import { storeAPIError } from "../stores/loaderAndException/loaderAndException.action";
import { forceLogout } from "../login/logout";


const exceptionUrls = [
  {
      url: "logout",
  },
  {
      url: "smartystreets.com",
  },
  {
      url: "token"
  },
  {
      url: "storage.googleapis.com"
  
  }
];

export const Interceptor = () => {
  const dispatch = useDispatch();
  publicClient.interceptors.request.use(async (config: any) => {
    let accessToken: string = getCookie("id_token") ?? "";
    if (!accessToken) {
        accessToken = getCookie("id_token") ?? "{}";
    }
    const noCustomHeaders = exceptionUrls.some(({ url }) => config.url.includes(url));
    if (!noCustomHeaders) {
        if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  });

  /**
   * If you are trying to avoid generic error pop up pass errorConfig.skipErrorHandling as true to in the axios config (From the place where you are making api call).
   *
   */
  publicClient.interceptors.response.use(
    (config: any) => {
      return config;
    },
    (err: any) => {
      const originalReq = err?.config;
      const { errorConfig } = originalReq || {};
      const { skipErrorHandling, skipForStatus } = errorConfig || {};
      const errorStatus = err?.response?.status;
      if (
        originalReq.url &&
        originalReq.url.includes("smartystreets.com")
      ) {
        return err;
      }
      return new Promise((resolve, reject) => {
        /**
         * we can skip catching error if skipErrorHandling is true.
         * we can skip catching error if skipForStatus includes current error status.
         *  (!err?.response?.status ||
            [500, 400, 503, 404].includes(err?.response?.status))
            this is to catch errors with status code 500, 400, 503, 404 and network error in which err?.response?.status is undefined.
            https://github.com/axios/axios/issues/383#issuecomment-234079506---> status code for network error discussion.
         */
        if ( originalReq.url.includes("check-submit") && errorStatus === 400) {
          reject(err);
        }
        else if (
          err.message !== "Request cancelled" &&
          !skipErrorHandling &&
          !(skipForStatus && skipForStatus.includes(errorStatus)) &&
          (!errorStatus || [500, 400, 503, 404, 502].includes(errorStatus))
        ) {
          dispatch(
            storeAPIError({
              id: uuidV4(),
              errorData: err
            })
          );
        }
        if (
          (errorStatus === 401 || errorStatus === 403) &&
          originalReq.url &&
          originalReq.url.includes("smartystreets.com")
        ) {
          reject(err);
        }
        if (
          (errorStatus === 401 || errorStatus === 403) &&
          err?.config &&
          !err.config?._retry
        ) {
          originalReq._retry = true;
          const res = fetchRefreshToken()
            .then(res => {
              originalReq.headers.Authorization = `Bearer ${getCookie(
                "id_token"
              )}`;
              return publicClient(originalReq);
            })
            .catch(e => {
              originalReq.headers.Authorization = `Bearer ${getCookie(
                "id_token"
              )}`;
              return publicClient(originalReq);
            });
          resolve(res);
        } else if (
          (errorStatus === 401 || errorStatus === 403) &&
          err.config?._retry
        ) {
          sessionStorage.clear();
          sessionStorage.removeItem("excelUpload"); // remove uploaded excel data used to create the loan from session
          forceLogout();
        } else {
          reject(err);
        }
      });
    }
  );

  if (window.location.hostname === "www.toorakcapital.info") {
    return null;
  }
  return <ErrorComponentWrapper />;
};
