import React, { useEffect } from 'react';
import { LogLevel } from '@grafana/faro-web-sdk';

const debounce = (func: any, delay: number) => {
  let timeoutId: any;
  return (...args: any) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

// Helper function to find relevant text in elements
const findText = (el: any) => (
  el?.innerText || el?.textContent || el?.label || el?.ariaLabel || el?.getAttribute("data-testid") || ''
);

const logQueue: string[] = [];
const batchInterval = 5000; // Send logs every 5 seconds
const maxQueueSize = 10; // Send logs immediately if queue exceeds 10 logs

const sendLogs = () => {
  const faro = (window as any).faro;
  if (faro && logQueue.length > 0) {
    const logsToSend = logQueue.splice(0, logQueue.length);
    console.log('Sending batched logs...', logsToSend);
    faro.api.pushLog(logsToSend, {
      level: LogLevel.INFO,
    });
  }
};

const pushToFaro = (logMessage: string) => {
  logQueue.push(logMessage);

  // Check if we need to send logs immediately
  if (logQueue?.length >= maxQueueSize) {
    sendLogs();
  }
};

// Function to immediately flush logs when the page is about to unload
const flushLogsImmediately = () => {
  if (logQueue?.length > 0) {
    sendLogs();
  }
};

const handleClick = (event: any) => {
  let element = event?.target;

  // Traverse up the DOM if the clicked element is not an interactive element
  while (element) {
    const tagName = element?.tagName?.toLowerCase();

    // Check if the element is a relevant element
    if (tagName?.match(/^(button|a|input|select|textarea|span|li|h1|h2|h3|h4|h5)$/i)) {
      break;
    }

    // If the element is an SVG, check if its parent is a button
    if (tagName === 'svg') {
      const parent = element?.parentElement;
      if (parent?.tagName?.toLowerCase() !== 'button') {
        break;
      }
    }

    // Move to the parent element
    element = element?.parentElement;
  }

  if (element) {
    const elementType = element?.tagName?.toLowerCase();
    const elementId = element?.id ? `#${element?.id}` : '';

    // Check inside the current element and up to two levels of children and parents for non-empty text
    let elementText = findText(element);

    if (!elementText) {
      // Check up to two levels inside the current element
      for (let i = 0; i < Math.min(2, element?.children?.length) && !elementText; i++) {
        elementText = findText(element.children[i]);
      }

      // If still no text, traverse up the DOM to find non-empty text from up to two parent elements
      let parentElement = element?.parentElement;
      for (let i = 0; i < 2 && !elementText && parentElement; i++) {
        elementText = findText(parentElement);
        parentElement = parentElement?.parentElement;
      }
    }

    const logMessage = `User clicked on ${elementType}${elementId ? `: ${elementId}` : ''}${elementText ? `: ${elementText}` : ''}`;
    console.log(logMessage);
    pushToFaro(logMessage);
  } else {
    console.log('No valid element found for logging.');
  }
};

const handleInput = debounce((event: any) => {
  const input = event?.target;
  const logMessage = `Input changed: ${input?.name || input?.id} to ${input?.value}`;
  console.log(logMessage);
  pushToFaro(logMessage);
}, 300);

const handleVisibilityChange = () => {
  if (document?.hidden) {
    const logMessage = 'User switched to another tab or minimized the browser';
    console.log(logMessage);
    pushToFaro(logMessage);
    flushLogsImmediately(); // Ensure logs are flushed if the user is leaving the page
  } else {
    const logMessage = 'User returned to the tab';
    console.log(logMessage);
    pushToFaro(logMessage);
  }
};

// Handle page unload
const handleBeforeUnload = (event: any) => {
  const logMessage = 'User is leaving the page';
  console.log(logMessage);
  pushToFaro(logMessage);
  flushLogsImmediately(); // Immediately send all logs before unloading
};

// HOC to track user interactions
const withUserInteractionTracking = (WrappedComponent: any) => {
  return (props: any) => {
    useEffect(() => {
      // Start interval to send batched logs
      const intervalId = setInterval(sendLogs, batchInterval);
      document.addEventListener('click', handleClick);
      document.addEventListener('input', handleInput);
      document.addEventListener('visibilitychange', handleVisibilityChange);
      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        clearInterval(intervalId);
        document.removeEventListener('click', handleClick);
        document.removeEventListener('input', handleInput);
        document.removeEventListener('visibilitychange', handleVisibilityChange);
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, []);

    return <WrappedComponent {...props} />;
  };
};

export default withUserInteractionTracking;
