/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import {
  formatValueByType,
  isEmptyValue,
  sanitizeValueByType
} from "../../../utils/formatChecks";
import { getPropertyAggregateFieldValue } from "./configs/PropertyAggregateFields";
import { MoreVerticalIcon } from "./ui-components/MoreVerticalIcon";
import { NewTextFieldForm } from "./ui-components/NewTextFieldForm";
import {
  handleAggregateDivide
  // handleExpandCollapseRow,
  // resetFieldValue
} from "../../../stores/loanAndPropertyDetails/aggregatePropertyTabActions";
// import {
// updatePropertyDataInRedux,
// updateUnitInfoDataInRedux
// } from "../../../stores/loanAndPropertyDetails/loanAndPropertyDetails.action";
import {
  expandCollapseRowClick,
  resetFieldValues,
  updatePropertyFieldsLoanAndProp,
  updateUnitInfoFields
} from "../../../stores/loanAndPropertyDetails/loanAndPropertyDetails.reducer";

enum MoreActionBtnEnums {
  divide = "divide",
  allocate = "allocate",
  reset = "reset"
}
const actionBtn = [
  {
    title: "Divide",
    id: MoreActionBtnEnums.divide
  },
  {
    title: "Allocate",
    id: MoreActionBtnEnums.allocate
  },
  {
    title: "Reset",
    id: MoreActionBtnEnums.reset
  }
];
function getValueFromRowProps(data: any, noFormatting: boolean) {
  try {
    const {
      fieldConfig = {},
      tableData = {},
      rowData = {},
      isAggregate,
      unitObj,
      perUnitValue
    } = data || {};
    const { getValue, fieldType } = fieldConfig;
    return getPropertyAggregateFieldValue(
      getValue,
      fieldType,
      {
        ...tableData,
        singlePropertyDetails: rowData,
        fieldType,
        isAggregate,
        unitObj,
        perUnitValue
      },
      noFormatting
    );
  } catch (error) {
    console.log(error);
  }
  return null;
}
export const ExpandCollapseComponent = {
  customComponent: (data: any) => {
    const { rowId } = data;
    switch (rowId) {
      case "firstRowForLabel":
      case "aggregateValueRow":
      case "aggregateDistributeRow":
        return <></>;
      default:
        return <ExpandCollapse data={data} />;
    }
  }
};
export const getIsExpanded = (data: any) => {
  const expandedRows = data.tableData?.aggregatePropertyDetails?.expandedRows;
  const loanPropertyId = data.rowData?.loanPropertyId;
  return expandedRows && expandedRows.has(loanPropertyId);
};
export const ExpandCollapse = (props: any) => {
  const { data } = props;
  const dispatch = useDispatch();
  const isExpanded = getIsExpanded(data);
  return (
    <div
      style={{
        cursor: "pointer",
        height: 20,
        width: 20,
        paddingTop: isExpanded ? 15 : 0
      }}
      onClick={() => {
        dispatch(
          expandCollapseRowClick({ rowId: data.rowData?.loanPropertyId })
        );
      }}
    >
      {isExpanded ? <RemoveIcon /> : <AddIcon />}
    </div>
  );
};
export const PropertyAddressComponent = {
  customComponent: (data: any) => {
    let propertyAddress = "Address";
    try {
      const { rowData, rowIndex } = data;
      if (rowData?.propertyLocation) {
        const { addressLine1, city, state, postalCode } =
          rowData?.propertyLocation;
        propertyAddress = `${rowIndex + 1}.  ${[
          addressLine1,
          city,
          state,
          postalCode
        ]
          .filter((ele) => !isEmptyValue(ele))
          .join(", ")}`;
      }
    } catch (error) {
      console.log(error);
    }

    return (
      <span
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          display: "block",
          textOverflow: "ellipsis"
        }}
        title={propertyAddress}
      >
        {propertyAddress}
      </span>
    );
  }
};
export const customComponent = {
  customComponent: (data: any) => {
    const { rowId } = data;
    switch (rowId) {
      case "firstRowForLabel":
        return <FieldLabelRenderer data={data} />;
      case "aggregateValueRow":
        return <AggregateValueRenderer data={{ ...data, isAggregate: true }} />;
      case "aggregateDistributeRow":
        return (
          <DivideAndAllocateField data={{ ...data }} showMoreVerticalIcon />
        );
      default:
        return <AggregateInputFields data={data} />;
    }
  }
};
export const AggregateValueRenderer = (props: any) => {
  const value = getValueFromRowProps(props?.data, false);
  return <span>{value}</span>;
};
export const FieldLabelRenderer = (props: any) => {
  const { fieldLabel } = props?.data?.fieldConfig || {};
  return <span>{fieldLabel}</span>;
};
export const AggregateInputFields = (props: any) => {
  const { fieldType, sectionId, fieldId, enableClear } =
    props?.data?.fieldConfig || {};
  const { rowData } = props?.data || {};
  const value = getValueFromRowProps(props?.data, true);
  const dispatch = useDispatch();
  const handleOnBlur = (val: any) => {
    if (sectionId && fieldId && rowData?.loanPropertyId) {
      dispatch(
        updatePropertyFieldsLoanAndProp({
          sectionName: sectionId,
          fieldName: fieldId,
          fieldValue: val,
          propertyId: rowData.loanPropertyId
        })
      );
    }
  };
  return (
    <InputField
      onBlur={handleOnBlur}
      value={value}
      enableClear={enableClear}
      fieldType={fieldType}
      showMoreVerticalIcon={false}
    />
  );
};
export const UnitInfoComponents = {
  customComponent: (data: any) => {
    const { rowId } = data;
    switch (rowId) {
      case "firstRowForLabel":
        return <FieldLabelRenderer data={data} />;
      case "aggregateValueRow":
        return <AggregateValueRenderer data={{ ...data, isAggregate: true }} />;
      case "aggregateDistributeRow":
        return (
          <DivideAndAllocateField data={{ ...data }} showMoreVerticalIcon />
        );
      default:
        return <UnitInfoFields data={data} />;
    }
  }
};

const UnitInfoFields = (props: any) => {
  const { data } = props;
  const { fieldType, fieldId, sectionId, isDisabled, enableClear } =
    data?.fieldConfig || {};
  const { rowData } = data || {};
  const value = getValueFromRowProps(data, true);
  const isExpanded = getIsExpanded(data);
  const units = rowData?.propertyUnit || [];
  const dispatch = useDispatch();
  const handleOnBlur = (val: any) => {
    if (Number(val.toString().replace(/,/g, "")) !== Number(value)) {
      handleActionBtnClick({ id: MoreActionBtnEnums.divide }, val);
    }
  };
  const handleActionBtnClick = (actionItem: any, val?: any) => {
    const valueToUse = val || value;
    let valuePerField = valueToUse;
    if (actionItem.id === MoreActionBtnEnums.divide) {
      const noOfFieldToUpdate = getEnabledUnitCount(
        rowData.propertyUnit,
        isDisabled
      );
      valuePerField = getValueAfterDivision(
        valueToUse,
        fieldType,
        noOfFieldToUpdate
      );
    } else if (valueToUse) {
      valuePerField = formatValueByType(valueToUse.toString(), fieldType)
        .toString()
        .replace(/%/, "")
        .replace("$", "");
    }
    if (valuePerField)
      dispatch(
        handleAggregateDivide({
          sectionId,
          fieldId,
          value: valuePerField,
          perProperty: true,
          loanPropertyId: rowData.loanPropertyId,
          checkIsDisabledUnit: (data: any) => {
            return typeof isDisabled === "function" ? isDisabled(data) : false;
          }
        })
      );
  };
  return (
    <>
      <InputField
        onBlur={handleOnBlur}
        value={value}
        fieldType={fieldType}
        enableClear={enableClear}
        showMoreVerticalIcon
        handleActionBtnClick={handleActionBtnClick}
        actionBtnOtions={actionBtn.filter((ele) => ele.id !== "reset")}
      />
      {isExpanded && (
        <>
          {units.map((item: any, index: number) => {
            return (
              <SingleUnitLevelField
                data={data}
                unitData={item}
                unitNumber={index + 1}
              />
            );
          })}
        </>
      )}
    </>
  );
};
const SingleUnitLevelField = (props: any) => {
  const { data, unitNumber, unitData } = props;
  const { fieldType, fieldId, isDisabled, enableClear } =
    data?.fieldConfig || {};
  const value = getValueFromRowProps(
    { ...data, unitObj: unitData, perUnitValue: true },
    true
  );
  const { rowData } = data || {};

  const dispatch = useDispatch();
  const disabled =
    typeof isDisabled === "function"
      ? isDisabled({ unitObj: unitData })
      : false;

  const handleOnBlur = (val: any) => {
    if (fieldId && val && rowData?.loanPropertyId && unitData?.propertyUnitId) {
      updateUnitInfoFields({
        fieldName: fieldId,
        fieldValue: val,
        propertyId: rowData.loanPropertyId,
        unitId: unitData?.propertyUnitId
      });
      // dispatch(
      //   updateUnitInfoDataInRedux(
      //     fieldId,
      //     val,
      //     rowData.loanPropertyId,
      //     unitData?.propertyUnitId
      //   )
      // );
    }
  };
  return (
    <Grid
      style={{
        marginTop: unitNumber === 1 ? 5 : 0
      }}
    >
      <span
        style={{
          padding: 5,
          visibility: fieldId === "marketRentMonthly" ? "visible" : "hidden"
        }}
      >
        Unit {unitNumber}
      </span>
      <Grid style={{ marginTop: 5, marginBottom: 5 }}>
        <InputField
          onBlur={handleOnBlur}
          value={value}
          fieldType={fieldType}
          enableClear={enableClear}
          showMoreVerticalIcon={false}
          disabled={disabled}
          title={
            disabled
              ? "This field is not applicable to this unit because of its lease status."
              : ""
          }
        />
      </Grid>
    </Grid>
  );
};
const getValueAfterDivision = (
  value: string,
  fieldType: string,
  numberOfProperties: number
) => {
  const sanitizedVal = Number(sanitizeValueByType(value, fieldType));
  if (numberOfProperties && !isNaN(sanitizedVal)) {
    const valAfterDiv = sanitizedVal / numberOfProperties;
    return formatValueByType(valAfterDiv.toString(), fieldType)
      .toString()
      .replace(/%/, "")
      .replace("$", "");
  }
  return null;
};
const getEnabledUnitCount = (unitArr: any[], isDisabled: any) => {
  return unitArr.reduce((acc: number, cur: any) => {
    const disabled =
      typeof isDisabled === "function" ? isDisabled({ unitObj: cur }) : false;
    return !disabled ? acc + 1 : acc;
  }, 0);
};
export const DivideAndAllocateField = (props: any) => {
  const { showMoreVerticalIcon, data = {} } = props;
  const { tableData = {}, fieldConfig = {} } = data;
  const { propertyDetails = [] } = tableData;
  const { fieldType, fieldId, sectionId, isDisabled } = fieldConfig;
  const [value, setValue] = useState<any>();
  const dispatch = useDispatch();
  const handleOnBlur = (val: any) => {
    setValue(val);
  };
  const handleActionBtnClick = (actionItem: any) => {
    if (actionItem.id === MoreActionBtnEnums.reset) {
      dispatch(resetFieldValues({ sectionId, fieldId }));
      return;
    }
    let valuePerField = value;
    if (actionItem.id === MoreActionBtnEnums.divide) {
      let noOfFieldToUpdate = propertyDetails.length;
      if (sectionId === "propertyUnit") {
        noOfFieldToUpdate = propertyDetails.reduce((acc: number, cur: any) => {
          return acc + getEnabledUnitCount(cur.propertyUnit, isDisabled);
        }, 0);
      }
      valuePerField = getValueAfterDivision(
        value,
        fieldType,
        noOfFieldToUpdate
      );
    }
    if (valuePerField)
      dispatch(
        handleAggregateDivide({
          sectionId,
          fieldId,
          value: valuePerField,
          checkIsDisabledUnit: (data: any) => {
            return typeof isDisabled === "function" ? isDisabled(data) : false;
          }
        })
      );
  };
  return (
    <NewTextFieldForm
      onBlur={handleOnBlur}
      value={value}
      fieldType={fieldType}
      endIcon={
        showMoreVerticalIcon ? (
          <MoreVerticalIcon
            actionButton={actionBtn}
            handleActionBtnClick={handleActionBtnClick}
          />
        ) : null
      }
    />
  );
};

const InputField = React.memo(function input(props: any) {
  const {
    value,
    fieldType,
    showMoreVerticalIcon,
    onBlur,
    disabled,
    title,
    actionBtnOtions,
    handleActionBtnClick,
    enableClear
  } = props;
  return (
    <NewTextFieldForm
      disableClear={!enableClear}
      onBlur={onBlur}
      value={value}
      fieldType={fieldType}
      endIcon={
        showMoreVerticalIcon ? (
          <MoreVerticalIcon
            actionButton={actionBtnOtions}
            handleActionBtnClick={handleActionBtnClick}
          />
        ) : null
      }
      disabled={disabled}
      title={title}
    />
  );
});
