import { Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useDrawsStyles } from "../../assetManagement/DrawRequest/Draws.styles";
import arrowUpDark from "../../images/arrow-up.svg";
import arrowDownDark from "../../images/arrow-down.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import iconSettings from "../../images/icon_settings.svg";
import { useBudgetStyles } from "../BudgetReview.style";
import { HtmlWhiteTooltip } from "../../utils/Tooltip";
import { availableOptionsInterface } from "../../utils/DropDownCommon";

export interface DropdownWithTooltipInterface {
  MenuItemOptions: availableOptionsInterface[];
  tooltipText: string;
  BttnText: string;
  rowData: any;
  onMenuItemClick: (value: any, id: string, rowData?: any) => void;
  styleMenuCard?: any;
  displayIcon?: string;
  MenuStyle?: any;
  isDisabled?: boolean;
  disabledList?: string[];
  renderOnlyCustomStyle?: any;
  customAutocompleteStyle?: any;
  popperCustomStyle?: any;
}
export const getDisplayIcon = (type: string | undefined) => {
  switch (type) {
    case "iconSettings":
      return (
        <img
          style={{ width: 16, height: 16 }}
          src={iconSettings}
          alt="settings icon"
        />
      );
    case "MoreVert": {
      return <MoreVertIcon />;
    }
    default:
      return null;
  }
};

export function DropdownWithTooltip(props: DropdownWithTooltipInterface) {
  const {
    MenuItemOptions,
    tooltipText,
    rowData,
    BttnText,
    onMenuItemClick,
    styleMenuCard,
    displayIcon,
    MenuStyle,
    isDisabled,
    disabledList
  } = props;
  const drawClasses = useDrawsStyles();
  const classes = useBudgetStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Button
        id={`basic-button-${rowData.id}`}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className={`more-action-iconBtn ${drawClasses.commonDrawsListDD}`}
        style={styleMenuCard ? styleMenuCard : null}
        disabled={isDisabled}
      >
        {displayIcon ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span className={drawClasses.commonListDDText}>
              {getDisplayIcon(displayIcon)}
            </span>
            {rowData.commentId && (
              <HtmlWhiteTooltip arrow placement="left" title="Comment">
                <span className={classes.withComments}>
                  <sup>*</sup>
                </span>
              </HtmlWhiteTooltip>
            )}
          </div>
        ) : (
          <>
            <HtmlWhiteTooltip title={tooltipText} placement="right" arrow>
              <span
                style={{ fontWeight: 400, color: "#32325d" }}
                className={drawClasses.commonListDDText}
              >
                {BttnText}
              </span>
            </HtmlWhiteTooltip>
            {anchorEl ? (
              <img src={arrowUpDark} alt="arrow-Up-Dark" />
            ) : (
              <img src={arrowDownDark} alt="arrow-Down-Dark" />
            )}
          </>
        )}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          style: MenuStyle
            ? MenuStyle
            : {
                maxHeight: 200,
                width: "160px"
              }
        }}
        style={{ marginTop: 5 }}
       // getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        {MenuItemOptions.map((item: any) => {
          return (
            <MenuItem
              onClick={() => {
                handleClose();
                onMenuItemClick(item, rowData.id, rowData);
              }}
              disabled={disabledList && disabledList.includes(item.title)}
              data-testid="status-update-list"
              role="button"
              onKeyDown={() => {
                handleClose();
              }}
              tabIndex={0}
              key={item.standardizedItemId}
              // className={classes.menuItem}
            >
              {item.title}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
