import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
// import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";
import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";

const ProgressBar = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 5,
      position: "absolute",
      zIndex: 20
    },
    colorPrimary: {
      backgroundColor: "#3571f2"
    },
    bar: {
      backgroundColor: "#00c7ff"
    }
  })
)(LinearProgress);

export function LinearProgressBar() {
  // const classes = useStyles();

  return (
    <div>
      <ProgressBar />
    </div>
  );
}

const ProgressBarNoWidth = withStyles((theme: Theme) =>
  createStyles({
    colorPrimary: {
      backgroundColor: "#3571f2"
    },
    bar: {
      backgroundColor: "#00c7ff"
    }
  })
)(LinearProgress);

export function LinearProgressBarNoWidth() {
  // const classes = useStyles();

  return (
    <div>
      <ProgressBarNoWidth />
    </div>
  );
}
