import React, { useContext, useEffect, useMemo, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { WarningModal } from "@toorak/tc-common-fe-sdk";
import { ObjectType } from "../../masterView/common";
import { ResultsSummaryCard } from "./ResultsSummaryCard";
import { ResultsListWithFailMessages } from "./ResultsListWithFailMessages";
import { GuidlinesComparisionContext } from "./PricingAndGuidlinesSection";
import { ZeroStateLoan } from "../loan-lists/DetailsView/ZeroStateLoan";
import { GuidlinesComparisionContextForDashboard } from "../../take-out-partners/TKOPModalContainer";
import { isLATUser } from "../../utils/AccessManagement";

export const accordianStyles = makeStyles((theme: Theme) => ({
  smallAccordionSvg: {
    fontSize: 16
  },
  accordion: {
    "&:before": {
      backgroundColor: "transparent"
    },
    "& .Mui-expanded": {
      minHeight: "0px"
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
      padding: 12,
      marginRight: -12
    }
  },
  accordionExpanded: {
    margin: "0 !important"
  },
  accordionSummary: {
    borderBottom: "1px solid #e9ecef",
    padding: "0.5rem 1rem",
    minHeight: "0px",
    fontSize: "12px",
    fontWeight: 600,
    "& .MuiAccordionSummary-content": {
      margin: "0px"
    }
  },
  accordionDetails: {
    padding: 0,
    display: "flex"
  }
}));

export const resultsTabs = [
  { label: "Fail", value: "fail" },
  { label: "Waived", value: "waived" },
  { label: "Pass", value: "pass" }
];

const partnersPerPage = 3;

export const GuidelinesComparision = (props: {
  programSelectConfig?: any;
  showSummary?: boolean;
}) => {
  const { programSelectConfig } = props;
  const {
    isProgramSelectView,
    setGlobalSelected,
    setGlobalFilteredPartners,
    selectedProgramDetails,
    setCurrentSelectedPartner,
    eligibleTakeOutPartnerResult,
  } = programSelectConfig;
  const context1 = useContext(GuidlinesComparisionContext);
  const context2 = useContext(GuidlinesComparisionContextForDashboard);
  const partnersData = context1?.partnersData ?? context2?.partnersData;
  const [range, setRange] = useState({ start: 0, end: partnersPerPage });
  const [expanded, setExpanded] = useState<boolean>(true);
  const [showNoLimit, setShowNoLimit] = useState<boolean>(false);
  const classes = accordianStyles();

  const filteredPartnersList = useMemo(() => {
    return partnersData?.slice(range.start, range.end);
  }, [range.start, range.end, partnersData]);
  // const [selectedProgram, setSelectedProgram] = useState<string>("");

  const handleProgramChange = (program: any) => {
    // setSelectedProgram(program.label);
    const programCredit = partnersData.find((it: any) => it.key === program.key)
      .creditLineEconomics;
    if (eligibleTakeOutPartnerResult?.dailyCreditLimit?.[programCredit.id]?.isWithinLimit === false) {
      setShowNoLimit(true);
    } else {
      setGlobalSelected(program);
      setCurrentSelectedPartner({
        assignedPartner: "",
        partnerId: program?.id
      })
    }
  };

  useEffect(() => {
    if (isProgramSelectView) {
      setExpanded(true);
      setGlobalFilteredPartners(filteredPartnersList);
    }
    // eslint-disable-next-line
  }, [isProgramSelectView, filteredPartnersList]);

  const handleNavigation = (val: number) => {
    if (val === -1) {
      if (range.start === 0) return;
      setRange({ start: range.start - 1, end: range.end - 1 });
    } else {
      if (range.end === partnersData.length) return;
      setRange({ start: range.start + 1, end: range.end + 1 });
    }
  };
  return (
    <>
      {partnersData?.length !== 0 && (
        <>
          {showNoLimit && (
            <WarningModal
              isOpen={showNoLimit}
              handleClose={() => {
                setShowNoLimit(false);
              }}
              header="Warning"
              body={
                isLATUser()
                  ? "No limit available for eligible partner"
                  : "Please contact LAT for support"
              }
              primaryBtnName="OK"
            />
          )}
          <Grid
            style={{
              display: "flex",
              flexDirection: "row",
              position: "relative",
              flexWrap: "nowrap",
              height:"100px",
              borderRight: isProgramSelectView ? "1px solid lightgrey" : ""
            }}
          >
            {filteredPartnersList?.map(
              (partnerData: ObjectType, index: number) => {
                return (
                  <ResultsSummaryCard
                    partnerData={partnerData}
                    range={range}
                    index={index}
                    programmeSelectConfig={{
                      isProgramSelectView,
                      selectedProgram: selectedProgramDetails
                        ? selectedProgramDetails?.key === partnerData.key
                        : false,
                      handleProgramChange,
                    }}
                    rangeEnd={partnersData.length}
                    handleNavigation={handleNavigation}
                    showNavigator={
                      index === filteredPartnersList.length - 1 &&
                      filteredPartnersList.length > partnersPerPage
                    }
                    passPercent={Math.round(
                      (partnerData?.pass?.length /
                        (partnerData?.pass?.length +
                          partnerData?.fail?.length)) *
                      100
                    )}
                    width={100 / filteredPartnersList.length}
                    showSummary={false}
                  />
                );
              }
            )}
          </Grid>
          <Grid style={{ width: "inherit" }}>
            <Accordion
              elevation={0}
              onChange={() => setExpanded(!expanded)}
              classes={{
                root: classes.accordion,
                expanded: classes.accordionExpanded
              }}
              expanded={expanded}
            >
              <AccordionSummary
                expandIcon={
                  expanded === true ? (
                    <RemoveIcon
                      fontSize="small"
                      className={classes.smallAccordionSvg}
                      color="primary"
                    />
                  ) : (
                    <AddIcon
                      fontSize="small"
                      className={classes.smallAccordionSvg}
                      color="primary"
                    />
                  )
                }
                classes={{ root: classes.accordionSummary }}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                Guideline Results & Reason for Fail
              </AccordionSummary>
              <AccordionDetails
                style={{ borderRight: "1px solid lightgrey" }}
                classes={{
                  root: classes.accordionDetails
                }}
              >
                {filteredPartnersList?.map((partnerData: ObjectType) => {
                  return (
                    <ResultsListWithFailMessages
                      key={partnerData.value}
                      partnerData={partnerData}
                      width={100 / filteredPartnersList.length}
                      showWaiver
                      programSelectConfig={{
                        selectedProgram: selectedProgramDetails
                          ? selectedProgramDetails?.key === partnerData.key
                          : false,
                        handleProgramChange,
                        isProgramSelectView,
                        onRequestWaiverClick:
                          programSelectConfig.onRequestWaiverClick
                      }}
                    />
                  );
                })}
              </AccordionDetails>
            </Accordion>
          </Grid>
        </>
      )}
      {!partnersData?.length && (
        <ZeroStateLoan
          textToShow="There are no results to show for the this loan."
          secondTextToShow=""
        />
      )}
    </>
  );
};
