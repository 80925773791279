import React, { useRef, useState, Suspense, useEffect, useMemo } from "react";
import { Autocomplete } from "@mui/material";
import {
  TextField,
  Checkbox,
  ListItemText,
  ListItem,
  Grid
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { LoanStage } from "@toorak/tc-common-fe-sdk";
import { useAutoCorrectStyles } from "@toorak/tc-common-fe-sdk/dist/components/input/autocomplete/AutoCompleteForm";
import { useDispatch } from "react-redux";
import { useAutoCorrectDropdownStyles } from "@toorak/tc-common-fe-sdk/dist/components/input/autocomplete/AutoCompleteForm";
import { useStyles } from "./DocumentsForm.style";
import closeWhiteImg from "../../images/closeWhite@3x.png";
import downloadImg from "../../images/createloan/download.svg";
import deleteImg from "../../images/exceptions/delete.svg";
import arrowDown from "../../images/Arrow_down.svg";
import StarsIcon from "@mui/icons-material/Stars";
import {
  hideLoader,
  showLoader
} from "../../stores/loaderAndException/loaderAndException.action";

import { downloadDocsType } from "../../utils/constants";
import { DocumentsTrackingId } from "./DocumentsTrackingId";
import {
  isLATUser,
  isILP,
  isPurchasedLoansOnly,
  isOrigUser
} from "../../utils/AccessManagement";
import { DocumentTooltip } from "../../utils/Tooltip";
import { LOW_CONFIDENCE_THRESHOLD } from "../ttf-review/constants";
import { getCookie } from "../../utils/cookies";

export function DocsProgressCard(props: any) {
  const {
    value,
    i,
    docsTags,
    filesList,
    handleTagRemove,
    internalTagMap,
    // handleFileDelete,
    // categoryTag,
    // handleUploadRetry,
    deleteBtnHandler,
    isAllowedToEdit,
    handleDownload,
    exceptionId,
    handleDropdownClose,
    handleChangeTag,
    isAllowedToDelete,
    isDocTab,
    confidenceScoreMap,
    currentTab,
    loanStage,
    loanId,
    secondaryIden,
    drawId,
    disableDeleteOnly,
    atSplitOrTTF = false,
    ttfStarted = false,
    ttfVersionId,
    customStyle = {}
  } = props;
  const DocumentViewer = React.lazy(() => import("./DocumentViewer"));

  const [preview, setPreview] = useState<boolean>(false);
  const [previewData, setPreviewData] = useState<any>({});
  const classes = useStyles();
  const autoClasses = useAutoCorrectStyles();
  const autoCorrectDropdownStyles = useAutoCorrectDropdownStyles();
  const icon = <CheckBoxOutlineBlankIcon fontSize="large" />;
  const checkedIcon = <CheckBoxIcon fontSize="large" />;

  const uploading = {
    backgroundColor: "#2ece89",
    height: "8px",
    borderRadius: "4px"
  };
  const groupLabelStyle = {
    fontSize: "12px",
    lineHeight: 1.5,
    fontWeight: 600
  };
  const {
    tagText,
    fss_normal,
    mt_10,
    fileTime,
    fwss_normal,
    rowFlex,
    c_pointer,
    wh_16,
    initProgress,
    tagBadge,
    progressContainer,
    tagContainerExceptions,
    fileName,
    fw_wrap,
    addTagText,
    ml_auto
  } = classes;
  const dispatch = useDispatch();
  const isILPDocumentView =
    secondaryIden &&
    !secondaryIden.includes("prePurchase") &&
    (isILP() || isLATUser());

  const showAutoTag = () => {
    const csScore = !Number.isNaN(
      Number(value.TagsInternal?.[0]?.confidence_score)
    )
      ? parseFloat(
          (value.TagsInternal?.[0]?.confidence_score)
            .toFixed(2)
            .replace(/[.,]00$/, "")
        )
      : 0;
    return (
      <div
        key={value?.docsId}
        className={`${tagBadge} ${rowFlex}`}
        style={
          csScore < LOW_CONFIDENCE_THRESHOLD ? { backgroundColor: "red" } : {}
        }
      >
        <span className={`${fss_normal} ${tagText}`}>
          {value.TagsInternal?.[0]?.tag.name?.toUpperCase()}
        </span>
      </div>
    );
  };
  const showtagBadge = (file: any, i: number) => {
    // cn
    // TODO, store in file.tags only and follow same api structure
    const tags = file?.tag || file?.tags || [];

    if (tags?.length) {
      return tags?.map((tag: any, index: number) => {
        if (typeof tag === "object") tag = tag?.tag?.name;
        return (
          <div
            key={tag}
            className={`${tagBadge} ${rowFlex}`}
            style={
              currentTab !== "prePurchase" &&
              isILPDocumentView &&
              file?.docsId &&
              confidenceScoreMap?.[file.docsId] !== null &&
              confidenceScoreMap?.[file.docsId] !== undefined &&
              Number(confidenceScoreMap?.[file.docsId]) ===
                confidenceScoreMap?.[file.docsId] &&
              confidenceScoreMap?.[file.docsId] < LOW_CONFIDENCE_THRESHOLD
                ? { backgroundColor: "red" }
                : currentTab === "prePurchase"
                ? { backgroundColor: "grey" }
                : {}
            }
          >
            <span className={`${fss_normal} ${tagText}`}>
              {tag.toUpperCase()}
            </span>
            {canEditTag && !file.boxDocumentPath && (
              <img
                src={closeWhiteImg}
                alt="close"
                //  srcSet={closeSet}
                onClick={(e) => handleTagRemove(file, tag, i)}
                className={`${wh_16} ${c_pointer}`}
              />
            )}
          </div>
        );
      });
    }
  };

  const [isTrackingIdAllowed, setIsTrackingIdAllowed] =
    React.useState<boolean>(false);

  useEffect(() => {
    if (
      ((loanStage === LoanStage.post && currentTab === "prePurchase") ||
        currentTab === "postClose") &&
      !drawId
    )
      setIsTrackingIdAllowed(true);
  }, [loanStage, currentTab, drawId]);

  const docTagRef = useRef<HTMLDivElement>(null);
  const showfeatProgressBar = (file: any, count: number) => {
    if (!file.uploading && !file?.ErrorMsg) {
      return (
        <div className={rowFlex}>
          <img
            src={downloadImg}
            onClick={() => handleDownload(file, downloadDocsType.download)}
            title="Download"
            alt="Download"
            style={{
              cursor: "pointer",
              paddingRight: "18px"
            }}
          />
          {canDeleteDoc && (
            <img
              src={deleteImg}
              title="Delete"
              alt="Delete"
              style={{
                cursor: "pointer"
              }}
              onClick={() => {
                deleteBtnHandler(file, i, "delete");
              }}
            />
          )}
        </div>
      );
    }
    return "";
  };
  const getValues = () => {
    const tempArray: any[] = [];
    docsTags.tags?.filter((item: any) => {
      if (filesList[i]?.tag?.includes(item.name)) {
        tempArray.push(item);
      }
    });

    return tempArray;
  };
  function showPrimaryPurchased(value: any) {
    return value?.isPurchasePrimary
      ? ttfStarted &&
        internalTagMap?.[value.TagsInternal?.[0]?.tag.code]?.config
          ?.isPurchasePrimary
        ? true
        : isPurchasedLoansOnly() || drawId
      : false;
  }
  const showUploadMsg = (file: any) => {
    if (file?.tag && file?.tag?.length) {
      return "";
    }
    // if (file.inQueue) {
    //   return (
    //     <div className={`${fwss_normal} ${QueueMsg} ${Msg}`}>In queue</div>
    //   );
    // }
    if (file?.ErrorMsg) {
      return "";
    }
    // if (!file.uploading && !file.uploaded) {
    //   return (
    //     <div className={`${fwss_normal} ${ErrorMsg} ${Msg}`}>Upload Failed</div>
    //   );
    // }
  };

  const getDateCreated = (valueDT: {
    lastModifiedDate: any;
    createdOn: any;
    percentCompleted: number;
  }) => {
    if (valueDT.percentCompleted !== 100) return null;
    const lastModeDate = valueDT && valueDT.lastModifiedDate;
    // safari fix
    const valueDTDate = valueDT && valueDT.createdOn;
    const valueDTDateM = valueDTDate ? valueDTDate.slice(0, -7) : "";
    const valueDTDateZoneVal1 = valueDTDate
      ? `${valueDTDateM.replace(/ /g, "T")}Z`
      : "";
    const createdDate =
      valueDTDateZoneVal1 !== ""
        ? new Date(valueDTDateZoneVal1)
        : new Date(lastModeDate);
    const dateArray = createdDate.toString().split(" ", 8);
    return dateArray.length > 2 ? (
      <span>
        {dateArray[2]} {dateArray[1]} {dateArray[3]} &bull; {dateArray[4]}
      </span>
    ) : (
      ""
    );
  };
  const showLatestIcon = () => {
    return (isPurchasedLoansOnly() || drawId) && value.isLatest
      ? true
      : ttfStarted &&
          internalTagMap?.[value.TagsInternal?.[0]?.tag.code]?.config
            ?.isLatest &&
          value.isLatest;
  };
  const previewFile = async (file: File) => {
    dispatch(showLoader());
    // previewDownload(file)
    try {
      const preResp = await handleDownload(file, downloadDocsType.preview);
      setPreviewData({
        type: file.name.split(".").pop() || "",
        url: preResp.data.url,
        name: file.name
      });
      setPreview(true);
      dispatch(hideLoader());
    } catch (e) {
      console.log(e);

      dispatch(hideLoader());
    }
  };

  const renderGroup = (params: any) => {
    return [
      <ListItem button key={params.key} id={`${params.group}_doctag_id`}>
        <ListItemText
          primary={<span style={groupLabelStyle}>{params.group}</span>}
        />
      </ListItem>,
      params.children
    ];
  };
  const splitSourceFiles = useMemo(() => {
    return filesList.find((doc: any) => doc.docsId === value?.split_source_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesList]);

  const canDeleteDoc = useMemo(() => {
    return (
      getCookie("superUser") === "Yes" ||
      (isAllowedToEdit &&
        isAllowedToDelete &&
        !disableDeleteOnly &&
        !atSplitOrTTF)
    );
  }, [isAllowedToEdit, isAllowedToDelete, disableDeleteOnly, atSplitOrTTF]);

  const canEditTag = useMemo(() => {
    return (
      isAllowedToEdit &&
      !drawId &&
      !isPurchasedLoansOnly() &&
      // intentionally == only. Do not change
      ((ttfVersionId != null && !isLATUser()) || ttfVersionId == null)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllowedToEdit]);

  return (
    <div>
      <div
        className={!exceptionId ? progressContainer : tagContainerExceptions}
      >
        {!exceptionId ? (
          <div className={`${rowFlex}`}>
            {value?.ErrorMsg ? (
              ""
            ) : (
              <span
                className={fileName}
                data-testid="file-name"
                onContextMenu={(event: any) => {
                  event.preventDefault();
                }}
                style={{ cursor: "pointer" }}
              >
                <DocumentTooltip
                  value={value}
                  loanId={loanId}
                  secondaryIden={secondaryIden}
                  previewFile={previewFile}
                  drawId={drawId}
                  handleDownload={handleDownload}
                />
              </span>
            )}
            {preview && (
              <Suspense fallback={null}>
                <DocumentViewer
                  setPreview={setPreview}
                  file={value}
                  previewData={previewData}
                  setPreviewData={setPreviewData}
                />
              </Suspense>
            )}
            <div className={`${ml_auto}`}>
              {/* //Intentionally left blank */}
            </div>
            {showfeatProgressBar(value, i)}
          </div>
        ) : null}

        {/* 
        To show date & time */}
        {!exceptionId ? (
          <div className={`${rowFlex} ${fileTime} ${fwss_normal}`}>
            {getDateCreated(value)}
            {isTrackingIdAllowed ? (
              <span style={{ paddingRight: 5, paddingLeft: 5 }}>|</span>
            ) : null}
            {!drawId && (
              <DocumentsTrackingId
                value={value}
                isTrackingIdAllowed={isTrackingIdAllowed}
                filesList={filesList}
                currentTab={currentTab}
                isAllowedToEdit={isAllowedToEdit}
              />
            )}
          </div>
        ) : null}
        
        <div className={`${rowFlex} ${fw_wrap} ${mt_10}`}>
          {(currentTab === "prePurchase" || drawId) &&
          isLATUser() &&
          value.TagsInternal?.length ? (
            showAutoTag()
          ) : (
            <></>
          )}
          {showtagBadge(value, i)}
          {value?.split_source_id !== null &&
            isLATUser() &&
            splitSourceFiles && (
              <span
                style={{
                  marginLeft: 8,
                  padding: "2px 7px",
                  borderRadius: 5,
                  backgroundColor: "#e9ecfe",
                  marginTop: 2
                }}
              >
                {splitSourceFiles?.name}
              </span>
            )}
          {showPrimaryPurchased(value) && !isOrigUser() && (
            <span
              title="Purchase Primary"
              style={{
                margin: "0px 6px 0px 7px",
                textAlign: "center"
              }}
            >
              <StarsIcon
                style={{
                  color: "black",
                  fontSize: 18,
                  height: 18,
                  display: "flex"
                }}
              />
            </span>
          )}
          {showLatestIcon() && (
            <span
              className={`${fss_normal} ${tagText}`}
              title="Latest"
              style={{
                backgroundColor: "black",
                padding: 5,
                paddingTop: 4,
                borderRadius: 10,
                width: 15,
                height: 15,
                marginTop: 4,
                display: "flex",
                alignItems: "center"
              }}
            >
              L
            </span>
          )}
        </div>
        {value?.tag &&
        !value?.tag?.length &&
        !exceptionId &&
        !value?.ErrorMsg &&
        value?.percentCompleted !== 100 ? (
          <div className={initProgress}>
            {!value?.ErrorMsg && value?.percentCompleted !== 100 ? (
              <div
                style={{
                  ...uploading,
                  width: `${value?.percentCompleted}%`
                }}
              />
            ) : null}
          </div>
        ) : null}
        {/* {showUploadMsg(value)} */}
        {!exceptionId ? showUploadMsg(value) : null}
        {!isDocTab &&
        value?.percentCompleted === 100 &&
        !value?.ErrorMsg &&
        isAllowedToEdit ? (
          <div>
            <div className={addTagText} style={{ textAlign: "left" }}>
              Add Tags to this Document
            </div>
            <div>
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                data-testid="auto-complete-input"
                options={docsTags.tags}
                classes={autoClasses}
                groupBy={
                  exceptionId
                    ? (option: any) => {
                        return option.tagCategory === null
                          ? "Others"
                          : option.tagCategory;
                      }
                    : undefined
                }
                onChange={(e, key) => {
                  handleChangeTag(value, key, i);
                }}
                onClose={handleDropdownClose}
                value={getValues()}
                disableCloseOnSelect
                getOptionLabel={(option: any) => option?.name ?? ""}
                popupIcon={
                  <img src={arrowDown} alt="Expand" className={`${wh_16}`} />
                }
                clearIcon={<i />}
                renderGroup={exceptionId ? renderGroup : undefined}
                renderOption={(props, option, selected) => {
                  return (
                    <li {...props}>
                      <Grid
                        container
                        style={{ margin: "6px 0px 6px 6px" }}
                        ref={docTagRef}
                      >
                        {
                          /*@ts-ignore*/
                          option.name !== "" ? (
                            <>
                              <Grid
                                item
                                xs={1}
                                style={{
                                  position: "relative",
                                  right: 5,
                                  bottom: 1
                                }}
                              >
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  classes={autoCorrectDropdownStyles}
                                  checked={filesList[i]?.tag?.includes(
                                    /*@ts-ignore*/
                                    option.name
                                  )}
                                />
                              </Grid>
                              <Grid item xs={11} style={{ ...customStyle }}>
                                <div
                                  className={classes.optionMenu}
                                  /*@ts-ignore*/
                                  title={/*@ts-ignore*/ option.name}
                                >
                                  {/*@ts-ignore*/}
                                  {option.name}
                                </div>
                              </Grid>
                            </>
                          ) : (
                            <div
                              style={{
                                background: "black",
                                height: "0px",
                                width: "100%"
                              }}
                              onClick={(event) => event.stopPropagation()}
                            />
                          )
                        }
                      </Grid>
                    </li>
                  );
                }}
                style={{ maxWidth: 356 }}
                renderInput={(params) => {
                  params.InputProps.startAdornment = undefined;
                  return (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select"
                    />
                  );
                }}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
