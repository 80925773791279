/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Button, DialogActions, DialogContent, Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { DialogTitle } from "@toorak/tc-common-fe-sdk";
import { CheckboxAutoCompleteTag } from "./CheckboxAutoCompleteTag";
import { useStyles as useStyle } from "../documents/DocumentsForm.style";

export interface AttachScenarioDialogProps {
  dialogDetails: any;
  setOpenDialog: any;
  openDialog: any;
  name: any;
  tagData: any;
  setSelectedTags: any;
  selectedTags: any;
  handleSave: any;
  label: string;
  fromPreSubmit?: any;
  searchedLoanId?: any;
  setSearchedLoanId?: any;
  handleChange?: any;
}
export const AttachScenarioDialog = ({
  dialogDetails,
  setOpenDialog,
  openDialog,
  tagData,
  setSelectedTags,
  selectedTags,
  handleSave,
  name,
  label,
  fromPreSubmit,
  searchedLoanId,
  setSearchedLoanId,
  handleChange
}: AttachScenarioDialogProps) => {
  let isClicked = false;
  const dailogClasses = useStyle();

  const { head } = dialogDetails;
  const {
    dialogDeleteButton,
    dialogCancelBtn,
    dialogActionArea
  } = dailogClasses;

  return (
    <Dialog
      onClose={() => setOpenDialog(false)}
      aria-labelledby="customized-dialog-title"
      open={openDialog}
      test-dataid="dialog"
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={() => setOpenDialog(false)}
        style={{ paddingTop: "20px" }}
      >
        {head}
      </DialogTitle>
      <DialogContent>
        <Grid container item xs={12}>
          <CheckboxAutoCompleteTag
            tagData={tagData}
            setSelectedTags={setSelectedTags}
            selectedTags={selectedTags}
            name={name}
            label={label}
            setSearchedLoanId={setSearchedLoanId}
            searchedLoanId={searchedLoanId}
            getSearched={handleChange}
          />
        </Grid>
      </DialogContent>
      <DialogActions
        className={dialogActionArea}
        style={{ paddingTop: "50px" }}
      >
        <Button
          variant="contained"
          color="primary"
          className={dialogDeleteButton}
          onClick={() => {
            if (!isClicked) {
              handleSave();
            }
            isClicked = true;
          }}
          data-testid="delete-confirm-btn"
        >
          Save
        </Button>
        <Button
          autoFocus
          onClick={() => {
            if (!fromPreSubmit) {
              setSelectedTags([]);
            }
            setOpenDialog(false);
          }}
          color="primary"
          className={`${dialogCancelBtn} ${dialogDeleteButton}`}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
