import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import { ObjectType } from "../../masterView/common";

export const MessageBody = (rateLockOptions?: ObjectType[]) => {
  return (
    <div>
      <span style={{ fontSize: 14 }}>
      The Toorak purchase price of the loan is determined by the most up-to-date
      rate sheet posted to Toorak Connect at the time the loan is locked. 
      <br /> 
      <br />
      The lock date will be the date the loan tape or loan data and minimum documentation files are initially uploaded to Toorak Connect (this includes pre-close loan submissions).
      <br /> 
      <br />
      If the minimum documentation files are not uploaded to Toorak Connect at the time the loan is locked, the loan may be placed on “hold” however, lock period will continue to be engaged. Toorak must close/buy the loan within the lock period. The minimum document checklist can be found in our document repository.
      </span>
    </div>
  );
};

export const MessageHeader = () => {
  return (
    <div style={{ display: "flex", height: 35 }}>
      <span>
        <InfoIcon
          style={{ color: "#5f72e4", fontSize: 16, height: "100%", width: 26 }}
        />
      </span>
      <span style={{ fontSize: 16, alignSelf: "center", paddingLeft: 8 }}>
        TC Pricing Info
      </span>
    </div>
  );
};

export const FESRateLockPoupBody = () => {
  return (
    <div>
      <h6>
        <b>Sizer Submission Lock Selection </b>
      </h6>

      <span style={{ fontSize: 14 }}>
        In the event a Sizer Submission Lock is selected and confirmed, the
        Toorak purchase price of the loan is determined by the most up-to-date
        rate sheet posted to Toorak Connect at the time of such confirmation. A
        Sizer Submission Lock will be effective on the date all of the following
        has occurred: the lender requests the Sizer Submission Lock in Toorak
        Connect, the loan tape and fully executed termsheet signed by the
        borrower and lender are uploaded to Toorak Connect, the Sizer Submission
        Lock deposit is received by Toorak and the Sizer Submission Lock is
        confirmed by Toorak. At the time of conversion to Pre/Post Close loan
        status, the Seller will be required to follow the “Pre/Post Close Lock”
        policy, as stated on Toorak's published rate sheet, except that if the
        conversation is completed within the Sizer Submission Lock period, then
        the rate sheet at the time of the Sizer Submission Lock date will be
        utilized in the “Pre/Post Close Lock” period.
      </span>

      <h6 style={{ marginTop: "1rem" }}>
        <b>Sizer Submission Lock Options</b>
      </h6>
      <span style={{ fontSize: 14 }}>
        Toorak allows for the following Sizer Submission Lock period options: 30
        days, 45 days and 60 days at a downward price adjustment from the locked
        rate sheet of $0.25, $0.50 and $0.75, respectively. Toorak requires a
        Sizer Submission Lock deposit of $0.25, $0.50 and $0.75 of original
        principal balance for 30-day, 45-day and 60-day locks. This deposit is
        refundable if the loan is purchased by Toorak.
      </span>

      <h6 style={{ marginTop: "1rem" }}>
        <b>Sizer Submission Lock Expiration</b>
      </h6>
      <span style={{ fontSize: 14 }}>
        In the event the sizer submission lock expires for any reason, including
        but not limited to purchase delays as a result of the loan failing to
        meet Toorak's then current underwriting guidelines or as a result of
        delays by any third parties, Toorak may reprice the loan at Toorak's
        then current rate sheet and retain all or a portion of the rate lock
        deposit to the extent needed to offset any interest rate hedging or
        other costs or losses incurred by Toorak, as determined by Toorak in its
        sole and absolute discretion.
      </span>
    </div>
  );
};

export const FESRateLockMessageHeader = () => {
  return (
    <div style={{ display: "flex", height: 35 }}>
      <span>
        <InfoIcon
          style={{ color: "#5f72e4", fontSize: 16, height: "100%", width: 26 }}
        />
      </span>
      <span style={{ fontSize: 16, alignSelf: "center", paddingLeft: 8 }}>
        Sizer Submission Lock
      </span>
    </div>
  );
};
