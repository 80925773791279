/* eslint-disable no-shadow */
import { Grid, Typography } from "@mui/material";
import { LoanStage } from "@toorak/tc-common-fe-sdk";
import { ExportHeader } from "../create-loan/exportHeader";
import React, { useEffect } from "react";
import { KeyDealMetricStyle } from "./keyDealMetricStyle";
import {
  getLoanById,
  getPropertyOriginalValuesByLoanId
} from "../create-loan/create-loan.action";
import {
  getRuleResult
  // getBorrowerUsedForCalculation
} from "../stores/EvaluationResults/EvaluationResults.action";
import { KeyDealSectionMap } from "./KeyDealSectionMap";

export interface KeyDealMetricsProps {
  loanId: string;
  loanStage: LoanStage;
  loanType: string;
  componentRef: any;
  takeoutPartnerId?: string | null;
}
/** This is a KeyDealMetrics wrapper component that contains all state variables and logic */
export const KeyDealMetricsExport = (props: KeyDealMetricsProps) => {
  // const componentRef:any = useRef<HTMLButtonElement>(null);
  const { loanId, loanType, loanStage, componentRef, takeoutPartnerId } = props;
  const classes = KeyDealMetricStyle();
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [data, setData] = React.useState<{
    loanDetails: any;
    propertyDetails: any;
    ruleResult: any;
    loanStage: string;
    loanType: string;
  } | null>(null);
  const getData = async (
    loanId: string,
    loanStage: LoanStage,
    loanType: string,
    takeoutPartnerId?: string | null
  ) => {
    try {
      const [loanDetails, propertyDetails, ruleResult]: any[] =
        await Promise.all([
          getLoanById(loanId, loanStage),
          getPropertyOriginalValuesByLoanId(loanId, loanStage),
          getRuleResult(loanId, loanStage, takeoutPartnerId)
        ]);
      // handle corrupted loan
      if (!ruleResult) {
        setErrorMessage("Failed to load data. Please try again.");
      }
      const ruleData = ruleResult;
      // if (!ruleResult?.loanResult[loanId].borrowerInfo) {
      //   const borrowerInfo = await getBorrowerUsedForCalculation(
      //     loanType,
      //     loanDetails.data.loanUserMap
      //   );
      //   if (borrowerInfo) {
      //     ruleData.loanResult[loanId].borrowerInfo = borrowerInfo;
      //   }
      // }
      setData({
        loanDetails: loanDetails.data,
        propertyDetails: propertyDetails.data,
        ruleResult: ruleData,
        loanStage,
        loanType
      });
    } catch (error) {
      console.log(error);
      setErrorMessage("Failed to load data. Please try again.");
    }
  };

  useEffect(() => {
    getData(loanId, loanStage, loanType, takeoutPartnerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanId, loanStage, loanType]);

  const getPageMargins = () => {
    return `@page { 
        margin: ${10} ${10} ${10} ${10} !important;
        size: 210mm 297mm;
      }`;
  };
  const defaultMsg: string =
    "The information provided in this system is for guidance purposes only and should not be considered as a commitment to buy any loan. All loan acquisitions, including the related pricing and terms, are subject to mutual execution of the related purchase advice following Toorak’s satisfactory completion of due diligence. All loan acquisitions are made pursuant to the applicable master loan purchase agreement.";
  return (
    <>
      <div
        className="print-source"
        data-testid="loanlist-container"
        ref={componentRef}
        style={{ width: "100%", zIndex: 999 }}
      >
        <ExportHeader />
        <style>{getPageMargins()}</style>

        {/* <Grid className={classes.containerHeader}> */}

        <Grid className={classes.containerHeader} container>
          <Grid item xs={12}>
            <span className={classes.heading}>Key Deal Metrics</span>
          </Grid>
        </Grid>
        <div>
          {!errorMessage && data && <KeyDealSectionMap data={data} />}
          {errorMessage && (
            <Grid
              xs={12}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid style={{ marginTop: "15vh", marginBottom: "15vh" }}>
                <Typography>{errorMessage}</Typography>
              </Grid>
            </Grid>
          )}
        </div>
        {/* </Grid> */}

        <div className="page-break" />
        <div style={{ position: "relative", bottom: "0", width: "100%" }}>
          <section>
            <Typography
              style={{
                top: 0,
                fontSize: 12,
                color: "#ffffff",
                lineHeight: 1,
                fontWeight: "normal",
                maxWidth: "100%",
                padding: "6px 20px 6px 20px",
                backgroundColor: "#6A7490"
              }}
            >
              {defaultMsg}
            </Typography>
          </section>
        </div>
      </div>
    </>
  );
};
