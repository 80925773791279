/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
// import { produce } from "immer";
// import {
//   GET_SCENARIO_RESULTS,
//   UPDATE_ATTACHED_SCENARIO_IN_REDUX,
//   UPDATE_LOAN_LIST_IN_REDUX,
//   UPDATE_SCENARIO_IN_VIEW_DETAILS,
//   SET_USER_LIST,
//   UPDATE_SCENARIO_STATUS,
//   DELETE_LOAN_FROM_REDUX,
//   SET_SCENARIO_FILTERS
// } from "./ScenarioActions";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ScenarioStore {
  scenarioListResult: any[];
  scenarioViewDetails: any;
  loansListDetail: any[];
  scenarioId: string;
  attachedLoanDetails: any[];
  attachedScenarioDetails: any[];
  total: number;
  userLists?: any[];
  loanTypeSelected: any[];
  selectedScenarioStatusType: any[];
  selectedDays: any[];
  selectedOriginators: any[];
  scenarioOrgPartyIdSelected: any[];
  propertyTypeSelected: any[];
}

const initialState: ScenarioStore = {
  scenarioListResult: [],
  scenarioViewDetails: [],
  loansListDetail: [],
  scenarioId: "",
  attachedLoanDetails: [],
  attachedScenarioDetails: [],
  userLists: [],
  total: 0,
  loanTypeSelected: [],
  selectedScenarioStatusType: [],
  selectedDays: [],
  selectedOriginators: [],
  scenarioOrgPartyIdSelected: [],
  propertyTypeSelected: []
};

const scenarioResultsSlice = createSlice({
  name: "scenarioResults",
  initialState,
  reducers: {
    getScenarioResults: (
      state,
      action: PayloadAction<{ scenarioListDetails: any[]; total: number }>
    ) => {
      const { scenarioListDetails, total } = action.payload;
      state.scenarioListResult = scenarioListDetails;
      state.total = total;
    },
    updateScenarioInViewDetails: (
      state,
      action: PayloadAction<{ scenarioDetails: any; attachedLoanDetails: any }>
    ) => {
      const { scenarioDetails, attachedLoanDetails } = action.payload;
      state.scenarioViewDetails = scenarioDetails?.data;
      state.attachedLoanDetails = attachedLoanDetails;
    },
    updateLoanListInRedux: (
      state,
      action: PayloadAction<{ loansListDetail: any }>
    ) => {
      const { loansListDetail } = action.payload;
      state.loansListDetail = loansListDetail;
    },
    updateAttachedScenarioInRedux: (
      state,
      action: PayloadAction<{ attachedScenarioDetails: any }>
    ) => {
      const { attachedScenarioDetails } = action.payload;
      state.attachedScenarioDetails = attachedScenarioDetails;
    },
    updateUserList: (state, action: PayloadAction<any[]>) => {
      // const { userLists } = action.payload;
      state.userLists = action.payload;
    },
    updateScenarioStatus: (
      state,
      action: PayloadAction<{ waiverId: any; responseType: any }>
    ) => {
      const { waiverId, responseType } = action.payload;
      const scenarioIndex = state.scenarioListResult.findIndex(
        (list) => list._source.waiver.waiverId === waiverId?.waiverId
      );
      const scenarioClone = JSON.parse(
        JSON.stringify(state.scenarioListResult)
      );
      scenarioClone[scenarioIndex]._source.waiver.waiverInfo.finalResponse =
        responseType;
      state.scenarioListResult = scenarioClone;
    },
    deleteLoanFromRedux: (
      state,
      action: PayloadAction<{ loanId: any; scenarioId: any }>
    ) => {
      const { loanId, scenarioId } = action.payload;
      const scenarioIndex = state.scenarioListResult.findIndex(
        (list: { _source: { scenarioId: any } }) =>
          list._source.scenarioId === scenarioId
      );
      const scenarioDetailsClone = JSON.parse(
        JSON.stringify(state.scenarioViewDetails)
      );
      if (scenarioIndex !== -1) {
        state.scenarioListResult[scenarioIndex]._source.waiver.attachedLoans =
          state.scenarioListResult[
            scenarioIndex
          ]?._source?.waiver?.attachedLoans?.filter(
            (item: any) => item.loanId !== loanId
          );
        state.scenarioListResult = [...state.scenarioListResult];
      } else {
        const attachedLoanArr: any = [];
        const tempLoanDetails = scenarioDetailsClone.loanDetails.filter(
          (item: any) => item.loanDetailId.loanId !== loanId
        );
        tempLoanDetails.forEach((item: any) => {
          const { loanStage, loanType } = item;
          const { loanId } = item?.loanDetailId;
          const { primaryLoanId } = item?.loanInfo;
          const obj = {
            loanStage,
            loanType,
            loanId: `${loanId}`,
            originatorLoanId: primaryLoanId
          };
          attachedLoanArr.push(obj);
        });
        scenarioDetailsClone.loanDetails = [...tempLoanDetails];
        state.scenarioViewDetails = scenarioDetailsClone;
        state.attachedLoanDetails = [...attachedLoanArr];
      }
    },
    setScenarioFilters: (
      state,
      action: PayloadAction<{
        loanTypeSelected: string[];
        selectedScenarioStatusType: string[];
        selectedDaysFilter: string[];
        selectedOrgFilter: string[];
        propertyTypeFilter: string[];
      }>
    ) => {
      const {
        loanTypeSelected,
        selectedScenarioStatusType,
        selectedDaysFilter,
        selectedOrgFilter,
        propertyTypeFilter
      } = action.payload;
      state.loanTypeSelected = loanTypeSelected;
      state.selectedScenarioStatusType = selectedScenarioStatusType;
      state.selectedDays = selectedDaysFilter;
      state.selectedOriginators = selectedOrgFilter;
      state.propertyTypeSelected = propertyTypeFilter;
    }
  }
});

export const {
  getScenarioResults,
  updateScenarioInViewDetails,
  updateLoanListInRedux,
  updateAttachedScenarioInRedux,
  updateUserList,
  updateScenarioStatus,
  deleteLoanFromRedux,
  setScenarioFilters
} = scenarioResultsSlice.actions;

export const ScenarioResultsReducer = scenarioResultsSlice.reducer;

// export const ScenarioResultsReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case GET_SCENARIO_RESULTS: {
//       const { scenarioListDetails, total } = action.payload;
//       const nextState = produce(state, draftState => {
//         draftState.scenarioListResult = scenarioListDetails;
//         draftState.total = total;
//       });
//       return nextState;
//     }
//     case UPDATE_SCENARIO_IN_VIEW_DETAILS: {
//       const { scenarioDetails, attachedLoanDetails } = action.payload;
//       const nextState = produce(state, draftState => {
//         draftState.scenarioViewDetails = scenarioDetails?.data;
//         draftState.attachedLoanDetails = attachedLoanDetails;
//       });
//       return nextState;
//     }
//     case UPDATE_LOAN_LIST_IN_REDUX: {
//       const { loansListDetail } = action.payload;
//       const nextState = produce(state, draftState => {
//         draftState.loansListDetail = loansListDetail;
//       });
//       return nextState;
//     }
//     case UPDATE_ATTACHED_SCENARIO_IN_REDUX: {
//       const { attachedScenarioDetails } = action.payload;
//       const nextState = produce(state, draftState => {
//         draftState.attachedScenarioDetails = attachedScenarioDetails;
//       });
//       return nextState;
//     }
//     case SET_USER_LIST: {
//       const nextState = produce(state, draftState => {
//         draftState.userLists = action.payload;
//       });
//       return nextState;
//     }
//     case UPDATE_SCENARIO_STATUS: {
//       const nextState = produce(state, draftState => {
//         const scenarioIndex = state.scenarioListResult.findIndex(
//           list =>
//             list._source.waiver.waiverId === action.payload.waiverId?.waiverId
//         );
//         const scenarioClone = JSON.parse(
//           JSON.stringify(state.scenarioListResult)
//         );
//         scenarioClone[scenarioIndex]._source.waiver.waiverInfo.finalResponse =
//           action.payload.responseType;
//         draftState.scenarioListResult = scenarioClone;
//       });
//       return nextState;
//     }
//     case DELETE_LOAN_FROM_REDUX: {
//       const { loanId, scenarioId } = action.payload;
//       const nextState = produce(state, draftState => {
//         const scenarioIndex = state.scenarioListResult.findIndex(
//           (list: { _source: { scenarioId: any } }) =>
//             list._source.scenarioId === scenarioId
//         );
//         const scenarioDetailsClone = JSON.parse(
//           JSON.stringify(state.scenarioViewDetails)
//         );
//         if (scenarioIndex !== -1) {
//           draftState.scenarioListResult[
//             scenarioIndex
//           ]._source.waiver.attachedLoans = state.scenarioListResult[
//             scenarioIndex
//           ]?._source?.waiver?.attachedLoans?.filter(
//             (item: any) => item.loanId !== loanId
//           );
//           draftState.scenarioListResult = [...draftState.scenarioListResult];
//         } else {
//           const attachedLoanArr: any = [];
//           const tempLoanDetails = state.scenarioViewDetails.loanDetails.filter(
//             (item: any) => item.loanDetailId.loanId !== loanId
//           );
//           tempLoanDetails.forEach((item: any) => {
//             const { loanStage, loanType } = item;
//             const { loanId } = item?.loanDetailId;
//             const { primaryLoanId } = item?.loanInfo;
//             const obj = {
//               loanStage,
//               loanType,
//               loanId: `${loanId}`,
//               originatorLoanId: primaryLoanId
//             };
//             attachedLoanArr.push(obj);
//           });
//           scenarioDetailsClone.loanDetails = [...tempLoanDetails];
//           draftState.scenarioViewDetails = scenarioDetailsClone;
//           draftState.attachedLoanDetails = [...attachedLoanArr];
//         }
//       });
//       return nextState;
//     }

//     case SET_SCENARIO_FILTERS: {
//       const nextState = produce(state, draftState => {
//         draftState.loanTypeSelected = action.payload.loanTypeSelected;
//         draftState.selectedScenarioStatusType =
//           action.payload.selectedScenarioStatusType;
//         draftState.selectedDays = action.payload.selectedDaysFilter;
//         draftState.selectedOriginators = action.payload.selectedOrgFilter;
//         draftState.propertyTypeSelected = action.payload.propertyTypeFilter;
//       });
//       return nextState;
//     }
//     default: {
//       return state;
//     }
//   }
// };
