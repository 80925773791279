import { useSelector } from "react-redux";
import { RootState } from "../../../stores/rootReducer";
let stages = new Map();

export function usePriorStages() {
  const {
    loanLandMarks
  } = useSelector<RootState, any>(state => state.createLoanStore.loanDetails);
  stages = new Map(
    loanLandMarks?.map(
      ({ stage, ...rest }: { stage: string; rest: any }) => {
        return [stage, rest];
      }
    )
  );
  return stages;
}
