import { ViewModeTextField, IconTooltip } from '@toorak/tc-common-fe-sdk';
import { Autocomplete, TextField, Grid, Tooltip, Theme } from "@mui/material";
import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    commentBox: {
      marginTop: "20px",
      "& label": {
        width: "100%",
        "& div": {
          "& input": {
            marginBottom: "20px"
          }
        }
      }
    },
    labelContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      // marginTop: "1px",
      width: "100%"
    },
    editedLabelContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: "1px",
      width: "100%"
    },
    labelInformation: {
      fontSize: 12,
      fontWeight: 600,
      color: "#8897aa",
      lineHeight: "16px",
      // height: "14px",
      "&:hover": {
        color: "#5e72e4"
      },
      display: "inline-block",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: "95%",
      textOverflow: "ellipsis",
      pointerEvents: "all"
    },
    labelRequired: {
      color: "#f75676",
      marginLeft: "4px",
      fontSize: "12px",
      // marginBottom: "4px",
      paddingTop: 1,
      display: "inline-block",
      verticalAlign: "top"
    },
    labelRequiredComments: {
      color: "#f75676",
      marginLeft: "4px",
      fontSize: "12px",
      marginBottom: "4px",
      marginTop: "5px"
    },
    iconInfoContainer: {
      height: "16px",
      textAlign: "end"
    },
    iconInfo: {
      width: "16px !important",
      height: "16px !important",
      color: "#32325d",
      "&:hover": {
        color: "#5e72e4"
      }
    },
    tooltip: {
      color: "#32325d",
      backgroundColor: "white",
      fontFamily: `"Open Sans", sans-serif !important`,
      fontSize: 12,
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      padding: "5px"
    },
    arrow: {
      color: "white"
    }
  })
);

const inputStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: "5px"
      }
    },
    input: {
      borderRadius: 4,
      position: "relative",
      border: "1px solid #cad1d7",
      fontSize: 14,
      padding: "13px 12px",
      color: "#32325d",
      alignSelf: "flex-end",
      transition: theme.transitions.create(["box-shadow"]),
      "&:focus": {
        "text-decoration-line": "none"
        // borderRadius: "4px",
        // border: "solid 1px #5e72e4 !important",
      }
    }
  })
);

interface GridAutoCompleteFieldProps {
  label: string;
  infoIconData?: any;
  value?: string;
  defaultValue?: string;
  editedLabel?: any;
  isNotInfo?: boolean;
  isLATView?: boolean;
  editedData?: any;
  isServicerMandatory?: boolean;
  isViewMode?: boolean;
  autoCompleteFieldProps: any;
  style?: any;
  error?: boolean;
  required?: boolean;
  showBorder?: boolean;
  disabled?: boolean;
  showEditedTag?: boolean;
  onHover?: any;
  helperText?: string;
};

const GridAutoCompleteAddressField = (props: GridAutoCompleteFieldProps) => {
  const { autoCompleteFieldProps, isViewMode, editedLabel, infoIconData, label, required, showBorder = true, disabled, showEditedTag, onHover, error, helperText } = props;
  const classes = useStyles();
  const inputClasses = inputStyles();

  const updatedAutoCompleteFieldProps = {
    ...autoCompleteFieldProps,
    renderInput: (params: any) => (
      <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...params}
        InputProps={{
          ...params.InputProps,
          classes: inputClasses
        }}
        label={
          <Grid
            container
            item
            xs={12}
            sm={12}
            className={
              !editedLabel
                ? classes.labelContainer
                : classes.editedLabelContainer
            }
          >
            <Grid item xs={12} md={12} sm={12} container>
              <Grid item xs={10} sm={10} md={infoIconData ? 11 : 12}>
                <Tooltip
                  title={`${label}`}
                  placement="right"
                  arrow
                  classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.arrow
                  }}
                >
                  <span className={classes.labelInformation}>{label}</span>
                </Tooltip>
                {required ? (
                  <span className={classes.labelRequired}>
                    {/* <sup
                          style={{ top: 0 }}>*</sup> */}
                    <sup>*</sup>
                  </span>
                ) : (
                  ""
                )}
              </Grid>
              {infoIconData && (
                <Grid
                  item
                  xs={2}
                  sm={2}
                  md={1}
                  className={classes.iconInfoContainer}
                >
                  <IconTooltip
                    topic="Information"
                    infoIconData={infoIconData}
                  // htmlColor="#8897aa"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        }
        variant="outlined"
        style={{
          flex: 1,
          width: "100%",
          borderColor: "#cad1d7",
          fontSize: 14,
          color: "black !imporrtant",
          padding: "0px"
        }}
        InputLabelProps={{
          shrink: false,
          style: {
            position: "relative",
            fontWeight: 600,
            fontSize: 12,
            color: "#8897aa",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }
        }}
        disabled={disabled}
        error={error}
        helperText={helperText} 
      />
    ),
    value: props?.value ?? "",
  };

  return (
    <>
      {isViewMode && (
        <ViewModeTextField
          label={props?.label}
          style={{ ...props?.style, borderWidth: showBorder ? "1px" : "0px" }}
          infoIconData={props?.infoIconData}
          value={props?.value ?? ""}
          editedLabel={props?.editedLabel}
          isNotInfo={props?.isNotInfo}
          editedData={props?.editedData}
          showEditedTag={showEditedTag}
          onHover={onHover}
        />
      )}
      {!isViewMode &&
        <div style={{
          display: "flex",
          width: "100%"
        }}>
          <Autocomplete {...updatedAutoCompleteFieldProps} />
        </div>
      }
    </>
  );
};

export default GridAutoCompleteAddressField;