import { Grid } from "@mui/material";
import React from "react";
import { BudgetHeaderLeft } from "./BudgetHeaderLeft";
import { BudgetHeaderRight } from "./BudgetHeaderRight";

interface BudgetEntryHeaderWrapperInterface {
  handleClose: any;
}

export function BudgetEntryHeaderWrapper() {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      style={{ height: "100%" }}
    >
      <Grid item xs={5} style={{ textAlign: "left" }}>
        <BudgetHeaderLeft />
      </Grid>
      <Grid item xs={7} style={{ textAlign: "left" }}>
        <BudgetHeaderRight />
      </Grid>
    </Grid>
  );
}
