import { Theme, Tooltip } from "@mui/material";

import withStyles from '@mui/styles/withStyles';

export const CustomTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "white",
    color: "#32325d",
    fontSize: 12,
    fontFamily: `"Open Sans", sans-serif !important`,
    boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
    minWidth: "200px",
    // minHeight: "200px",
    zIndex: 20
  },
  arrow: {
    color: "white"
  }
}))(Tooltip);
