/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ToorakStatusEnum } from "../../create-loan/documents/CustodyDocuments/enumsAndConstants";

export interface CustodyDocumentsStore {
  custodyDocsPerLoan: any;
  refetchCustodyfundingStatus: boolean;
  hasTrailingDocument: boolean;
}
const initialState: CustodyDocumentsStore = {
  custodyDocsPerLoan: [],
  refetchCustodyfundingStatus: false,
  hasTrailingDocument: false
};

const custodyDocumentsSlice = createSlice({
  name: "custodyDocuments",
  initialState,
  reducers: {
    getCustodyDocsPerLoan: (
      state,
      action: PayloadAction<{ documentList: any[] }>
    ) => {
      const { documentList } = action.payload;
      state.custodyDocsPerLoan = documentList;
      state.hasTrailingDocument = state.custodyDocsPerLoan.some(
        (ele: { toorakStatus: ToorakStatusEnum; isTrailingDocument: any }) =>
          ele.toorakStatus === ToorakStatusEnum.ignoreForFunding &&
          ele.isTrailingDocument
      );
    },
    updateCustodyDocsFields: (
      state,
      action: PayloadAction<{ documentList: any[] }>
    ) => {
      const { documentList } = action.payload;
      const custodyDocsPerLoan = JSON.parse(
        JSON.stringify(state.custodyDocsPerLoan)
      );
      state.custodyDocsPerLoan = custodyDocsPerLoan.map((item: { id: any }) => {
        const updatedObj = documentList.find((ele) => ele.id === item.id);
        if (updatedObj) {
          return { ...item, ...updatedObj };
        }
        return item;
      });
      const refetchCustodyfundingStatus = state.custodyDocsPerLoan.every(
        (ele: { isMandatory: any; toorakStatus: ToorakStatusEnum }) => {
          return !ele.isMandatory || ele.toorakStatus !== ToorakStatusEnum.open;
        }
      );
      state.hasTrailingDocument = state.custodyDocsPerLoan.some(
        (ele: { toorakStatus: ToorakStatusEnum; isTrailingDocument: any }) =>
          ele.toorakStatus === ToorakStatusEnum.ignoreForFunding &&
          ele.isTrailingDocument
      );
      state.refetchCustodyfundingStatus = !!refetchCustodyfundingStatus;
    },
    getCommentsForDocType: (
      state,
      action: PayloadAction<{ data: any; docIds: any[] }>
    ) => {
      const { data, docIds } = action.payload;
      const listCopy = JSON.parse(JSON.stringify(state.custodyDocsPerLoan));
      let updatedDoc;

      if (!data?.chats?.length) {
        updatedDoc = listCopy.map(
          (doc: {
            documentSourceId: any;
            chat: {};
            accounts: {};
            accountMapping: {};
            customers: {};
            documents: {};
          }) => {
            docIds.forEach((item) => {
              if (item === doc.documentSourceId) {
                doc.chat = {};
                doc.accounts = {};
                doc.accountMapping = {};
                doc.customers = {};
                doc.documents = {};
              }
            });
            return doc;
          }
        );
      } else {
        updatedDoc = listCopy.map(
          (doc: {
            documentSourceId: any;
            chat: any;
            accounts: any;
            accountMapping: any;
            customers: any;
            documents: any;
          }) => {
            data.chats?.forEach((item: { secondaryIdentifierValue: any }) => {
              if (item.secondaryIdentifierValue === doc.documentSourceId) {
                doc.chat = item;
                doc.accounts = data.accounts;
                doc.accountMapping = data.accountMapping;
                doc.customers = data.customers;
                doc.documents = data.documents;
              }
            });
            return doc;
          }
        );
      }
      state.custodyDocsPerLoan = updatedDoc;
    }
  }
});

export const {
  getCustodyDocsPerLoan,
  updateCustodyDocsFields,
  getCommentsForDocType
} = custodyDocumentsSlice.actions;

export const CustodyDocumentsReducer = custodyDocumentsSlice.reducer;

// export const CustodyDocumentsReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case GET_CUSTODY_DOCS_PER_LOAN: {
//       const { documentList } = action.payload;
//       const nextState = produce(state, draftState => {
//         draftState.custodyDocsPerLoan = documentList;
//         draftState.hasTrailingDocument = draftState.custodyDocsPerLoan.some(
//           (ele: any) =>
//             ele.toorakStatus === ToorakStatusEnum.ignoreForFunding &&
//             ele.isTrailingDocument
//         );
//       });
//       return nextState;
//     }
//     case UPDATE_CUSTODY_DOCS_FIELDS: {
//       const { documentList } = action.payload;
//       const nextState = produce(state, draftState => {
//         const custodyDocsPerLoan = JSON.parse(
//           JSON.stringify(draftState.custodyDocsPerLoan)
//         );
//         draftState.custodyDocsPerLoan = custodyDocsPerLoan.map((item: any) => {
//           const updatedObj = documentList.find(
//             (ele: any) => ele.id === item.id
//           );
//           if (updatedObj) {
//             return { ...item, ...updatedObj };
//           }
//           return item;
//         });
//         const refetchCustodyfundingStatus = draftState.custodyDocsPerLoan.every(
//           (ele: any) => {
//             return (
//               !ele.isMandatory || ele.toorakStatus !== ToorakStatusEnum.open
//             );
//           }
//         );
//         draftState.hasTrailingDocument = draftState.custodyDocsPerLoan.some(
//           (ele: any) =>
//             ele.toorakStatus === ToorakStatusEnum.ignoreForFunding &&
//             ele.isTrailingDocument
//         );
//         draftState.refetchCustodyfundingStatus = !!refetchCustodyfundingStatus;
//       });
//       return nextState;
//     }
//     case GET_COMMENTS_FOR_DOCTYPE: {
//       const { data, docIds } = action.payload;
//       const nextState = produce(state, draftState => {
//         const listCopy = JSON.parse(JSON.stringify(state.custodyDocsPerLoan));
//         let updatedDoc;

//         if (!data?.chats?.length) {
//           updatedDoc = listCopy.map((doc: any) => {
//             docIds.forEach((item: any) => {
//               if (item === doc.documentSourceId) {
//                 doc.chat = {};
//                 doc.accounts = {};
//                 doc.accountMapping = {};
//                 doc.customers = {};
//                 doc.documents = {};
//               }
//             });
//             return doc;
//           });
//         } else {
//           updatedDoc = listCopy.map((doc: any) => {
//             data.chats?.forEach((item: any) => {
//               if (item.secondaryIdentifierValue === doc.documentSourceId) {
//                 doc.chat = item;
//                 doc.accounts = data.accounts;
//                 doc.accountMapping = data.accountMapping;
//                 doc.customers = data.customers;
//                 doc.documents = data.documents;
//               }
//             });
//             return doc;
//           });
//         }
//         draftState.custodyDocsPerLoan = updatedDoc;
//       });
//       return nextState;
//     }
//     default: {
//       return state;
//     }
//   }
// };
