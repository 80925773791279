/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { TextField } from "@mui/material";

export const CustomizedTextFieldSettlements = (props: any) => {
  const {
    value,
    helperText,
    error,
    inputStyle,
    labelStyles,
    noLabel,
    inputClassName,
    propss,
    textFieldClassName,
    valueTestId
  } = props;
  return (
    <TextField
      className={textFieldClassName}
      {...propss}
      onKeyDown={(evt) => {
        const charCode = evt.which ? evt.which : evt.keyCode;
        if (charCode !== 9) {
          evt.preventDefault();
          return false;
        }
        return true;
      }}
      variant="outlined"
      placeholder=""
      helperText={helperText}
      error={error}
      style={{
        borderColor: error ? "#f75676" : "#cad1d7",
        width: "100%",
        pointerEvents: "none",
        margin: 0
      }}
      value={value}
      // inputProps={{
      //   "data-testid": valueTestId,
      //   className: inputClassName,
      //   style: { borderColor: "#cad1d7", ...inputStyle }
      // }}
      InputProps={{
        ...propss.InputProps,
        "data-testid": valueTestId,
        style: { borderColor: "#cad1d7", ...inputStyle },
        className: inputClassName
      }}
      InputLabelProps={
        !noLabel
          ? {
              shrink: false,
              style: {
                position: "relative",
                fontSize: 12,
                color: "#8897aa",
                fontWeight: 600,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                marginTop: "5px",
                lineHeight: 1.25,
                ...labelStyles
              }
            }
          : { shrink: false }
      }
    />
  );
};
