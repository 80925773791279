/* eslint-disable no-console */
import localForage from "localforage";
import { calculateIndexedDBSize } from "./CachedDBSize";
import { CacheSizeProvider, DBMAXSIZE } from "./CacheSizeProvider";

interface CachedDataType {
  data: Blob;
  expiresOn: Date;
}

interface SuccessCallbackType {
  (err: Error, value: Blob): void;
}

interface CacheSizeType {
  size: number;
  humanReadableFormat: string;
}

const TTFREVIEWCACHEIDENTIFIER = "TTFREVIEW";
const VALIDFORDAYS = 2;

const getExpiryDate = (date: Date = new Date(), noOfDays?: number) => {
  const cloneDate = new Date(date);
  const validForDays = noOfDays || VALIDFORDAYS;
  cloneDate.setDate(date.getDate() + validForDays);
  return cloneDate;
};

export const TTFReviewCacheProvider = () => {
  const DBCacheSize = CacheSizeProvider();
  const cacheProvider = localForage.createInstance({
    name: TTFREVIEWCACHEIDENTIFIER
  });

  const getItem = async (
    key: string,
    successCallback?: SuccessCallbackType
  ) => {
    try {
      const cachedValue: CachedDataType | null = await cacheProvider.getItem(
        key
      );
      return cachedValue && cachedValue.data;
    } catch (error) {
      console.log(
        `Encountered an error while retreiving value for key: ${key} and Error: {error}`
      );
      return null;
    }
  };

  const setItem = async (key: string, data: Blob) => {
    try {
      await cleanUpExpiredItems();
      await calculateIndexedDBSize();
      const cacheSize: CacheSizeType | null = await DBCacheSize.getItem(
        "TTFREVIEW"
      );
      if (cacheSize && cacheSize?.size > DBMAXSIZE) return;
      const payload: CachedDataType = {
        data,
        expiresOn: getExpiryDate()
      };
      await cacheProvider.setItem(key, payload);
    } catch (error) {
      console.log(
        `Encountered an error while setting value for key: ${key} and Error: ${error}`
      );
    }
  };

  const removeItem = async (key: string) => {
    try {
      await cacheProvider.removeItem(key);
    } catch (error) {
      console.log(
        `Encountered an error while removing item for key: ${key} and Error: ${error}`
      );
    }
  };

  const cleanUpExpiredItems = () => {
    return new Promise((resolve, reject) => {
      const currentDate = new Date();
      cacheProvider
        .iterate((value: CachedDataType, localforageKey: string) => {
          if (!value) return;
          const { expiresOn } = value;
          if (currentDate < new Date(expiresOn)) return;
          removeItem(localforageKey);
        })
        .then(function() {
          resolve("success");
        })
        .catch(function(err) {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(`error: ${err}`);
        });
    });
  };

  return {
    getItem,
    setItem,
    removeItem
  };
};
