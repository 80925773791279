const defaultSource = [
  "loan.loanDetailId.originatorPartyId",
  "loan.loanType",
  "loan.actualLoanType",
  "loan.loanInfo.toorakProduct",
  "loan.loanDetailId.isWarehouseFunded",
  "loan.loanDetailId.fundingType",
  "loan.loanProcessType",
  "loan.loanTypeId.loanTypeName",
  "loan.onHoldBy",
  "loan.loanCredit.phase1ClosingDate",
  "loan.loanInfo.primaryLoanId",
  "loan.loanInfo.takeoutPartner",
  "onHoldDetails",
  "loan.loanDetailId.loanLandmarks",
  "loan.loanStage"
];
export const columnSourceMap: { [key: string]: string | string[] } = {
  toorakLoanId: ["loan.loanDetailId.loanId", ...defaultSource], // mandatory field
  loanTypeName: [
    "loan.loanType",
    "loan.actualLoanType",
    "loan.loanInfo.toorakProduct",
    "loan.loanDetailId.isWarehouseFunded",
    "loan.loanDetailId.fundingType",
    "loan.loanProcessType",
    "loan.loanTypeId.loanTypeName"
  ],
  riskBucket: "result.loanResult.riskScores.riskBucket",
  needEnhancedReview:
    "result.loanResult.loanCharacterisations.needEnhancedReview",
  address: "properties",
  status: ["loan.loanState", "bundle.bundleStatus"],
  originalLoanId: "loan.loanInfo.primaryLoanId",
  activeInteractions: [
    "pendingAllWaiverCount",
    "pendingExternalWaiverCount",
    "pendingAllExceptionCount",
    "pendingExternalExceptionCount",
    "pendingAllEnquiryCount",
    "pendingExternalEnquiryCount",
    "currentPendingAllWaiverCount",
    "currentPendingExternalWaiverCount",
    "currentPendingAllExceptionCount",
    "currentPendingExternalExceptionCount",
    "currentPendingAllEnquiryCount",
    "currentPendingExternalEnquiryCount"
  ],
  toorakTradeID: "loan.loanDetailId.tradeId",
  loanStage: "loan.loanStage",
  firstPostedOpenCondt: "firstOpenConditionDate",
  propertyType: "properties",
  toorakLoanTypeRehab:
    "result.loanResult.loanCharacterisations.loanCharacterisation",
  loanCreateDate: "loan.loanCreationDate",
  loanPurpose: "loan.loanInfo.loanPurpose",
  firstName: ["guarantors", "loan.loanUserMap"],
  lastName: ["guarantors", "loan.loanUserMap"],
  loanSubmissionDate: "loan.loanSubmissionDate",
  initialLoanSubmissionDate: "loan.initialLoanSubmissionDate",
  effectiveStartDate: "loan.loanDetailId.loanConfigId.rateLockedRateSheet",
  rateLockExpiryDate: [
    "loan.rateLockExpiryDate",
    "loan.sizerRateLockExpiryDate"
  ],
  updatedOn: "updatedOn",
  originator: "originator.accountName",
  loanCreatedBy: "loan.loanCreatedBy",
  loanAmount: "loan.loanEconomics.originalLoanAmount",
  interestRate: "loan.loanEconomics.interestRateAsOfCutOffDate",
  numberOfProperties: "loan.loanInfo.noOfProperties",
  originationDate: "loan.loanInfo.originationDate",
  foreignNationalString: "borrowerDetails.customer.foreignNationalString",
  experiance: ["borrowerDetails.loanUser.experience", "borrowerDetails.loanUser.borrowerGUCExperience", "borrowerDetails.loanUser.heavyRehabExperience"  ],
  originalCreditScoreMedian:
    "result.loanResult.borrowerInfo.originalCreditScoreMedian",
  originalCreditScoreReportDate:
    "result.loanResult.borrowerInfo.originalCreditScoreReportDate",
  primaryEmail: "borrowers",
  borrowerBillingPhone: "borrowers",
  numberOfGuarantors: "numberOfGuarantors",
  loanStructure: "loan.loanInfo.loanStructure",
  additionalGuarantorName: "guarantors",
  cutOffDate: "loan.loanInfo.cutOffDate",
  firstPaymentDateOfLoan: "loan.loanInfo.firstPaymentDateOfLoan",
  originalMaturityDate: "loan.loanInfo.originalMaturityDate",
  recourseString: "loan.loanInfo.recourseString",
  crossCollaterlization: "loan.loanInfo.crossCollaterlization",
  extensionOptions: "loan.loanInfo.extensionOptions",
  financedInterestReserve: "loan.loanEconomics.financedInterestReserve",
  originalMaximumLoanAmount: "loan.loanEconomics.originalMaximumLoanAmount",
  takeoutPartner: ["loan.loanInfo.takeoutPartner", "loan.loanInfo.takeoutPartnerStatus"],
  finalToorakYield: "result.loanResult.toorakInterests.finalToorakYield",
  pricingDiscount: "result.loanResult.toorakInterests.pricingDiscount",
  cutOffDateLoanAmount: "loan.loanEconomics.cutOffDateLoanAmount",
  cutOffDateMaximumLoanAmount: "loan.loanEconomics.cutOffDateMaximumLoanAmount",
  accrualType: "loan.loanEconomics.accrualType",
  totalOriginationAndDiscountPoints:
    "loan.loanEconomics.totalOriginationAndDiscountPoints",
  totalBudgetAmount: "loan.loanEconomics.totalBudgetAmount",
  cashOutAmount: "loan.loanEconomics.cashOutAmount",
  financedBudgetAmount: "loan.loanEconomics.financedBudgetAmount",
  appraisalReportedNoiNcf: "properties",
  lifeRateCap: "loan.loanEconomics.lifeRateCap",
  purchasePrice: "properties",
  dateUploaded: "loan.loanDetailId.loanConfigId.dateUploaded",
  dueDiligencePartyId: "loan.loanDetailId.loanConfigId.dueDiligencePartyId",
  ddReportStatus: "loan.ddReportStatus",
  teamIndiaTapetoFile: "loan.loanDetailId.loanSummaryId.teamIndiaTapetoFile",
  tapeToFile: "loan.loanDetailId.loanConfigId.tapeToFile",
  truliaCrime: "loan.loanDetailId.loanConfigId.truliaCrime",
  zillow: "loan.loanDetailId.loanConfigId.zillow",
  emailSentToOriginator: "loan.loanDetailId.loanConfigId.emailSentToOriginator",
  streetView: "loan.loanDetailId.loanConfigId.streetView",
  appraisal: "loan.loanDetailId.loanConfigId.appraisal",
  closingDate: "bundle.closingDate",
  postRehabSquareFootage: "properties",
  preRehabSquareFootage: "properties",
  originalAsRepairedAppraisedValue: "properties",
  insurance: "properties",
  grossPotentialRent: "properties",
  changeInUseCase: "properties",
  propertyAcquisitionDate: "properties",
  originalAsIsAppraisalValue: "properties",
  propertyUnits: "properties",
  recentPropertyValuationDate: "properties",
  costBasis: "properties",
  annualPropertyTaxes: "properties",
  occupiedPercentage: "properties",
  subsequentPeriodicCap: "loan.loanEconomics.subsequentPeriodicCap",
  squareFootage: "properties",
  rentDescription: "properties",
  rateType: "loan.loanEconomics.rateType",
  prepaymentPenaltyMonths: "loan.loanEconomics.prepaymentPenaltyMonths",
  prepayPenaltyType: "loan.loanEconomics.prepayPenaltyType",
  rateAdjustmentFrequency: "loan.loanEconomics.rateAdjustmentFrequency",
  paymentAdjustmentFrequency: "loan.loanEconomics.paymentAdjustmentFrequency",
  originalMonthlyPiPayment: "loan.loanEconomics.originalMonthlyPiPayment",
  interestOnlyPeriod: "loan.loanEconomics.interestOnlyPeriod",
  initialRateAdjustmentPeriod: "loan.loanEconomics.initialRateAdjustmentPeriod",
  initialPeriodicCap: "loan.loanEconomics.initialPeriodicCap",
  initialPayAdjustmentPeriod: "loan.loanEconomics.initialPayAdjustmentPeriod",
  grossArmMargin: "loan.loanEconomics.grossArmMargin",
  debtServiceCoverageRatio: "result.loanResult.loanEconomics.dscr",
  currentLoanBalance: "loan.loanEconomics.currentLoanBalance",
  costToLoanRatio: "loan.loanEconomics.costToLoanRatio",
  originalLtc: "result.loanResult.loanFeatures.originalLtc",
  originalAsIsLtv: "result.loanResult.loanFeatures.originalAsIsLtv",
  originalAsRepairedLtv: "result.loanResult.loanFeatures.originalAsRepairedLtv",
  finalToorakPrice: "result.loanResult.loanPricing.finalToorakPrice",
  annualHazardInsurance: "properties",
  ARMIndex: "loan.loanEconomics.armIndex",
  annualMarketRent: "properties",
  annualHoaFee: "properties",
  annualFloodInsurance: "properties",
  ownershipOfEntity: "guarantors",
  borrowerType: "borrowers",
  borrowerBillingAddress: "borrowers",
  borrowingEntityName: "borrowers",
  lifetimeMaxRate: "loan.loanEconomics.lifetimeMaxRate",
  monthlyTaxAmount: "loan.loanDetailId.loanSummaryId.monthlyTaxAmount",
  monthlyInsuranceAmount:
    "loan.loanDetailId.loanSummaryId.monthlyInsuranceAmount",
  taxes: "loan.loanDetailId.loanSummaryId.taxes",
  taxesandInsuranceDue: "loan.loanDetailId.loanSummaryId.taxesandInsuranceDue",
  escrowFund: "loan.loanDetailId.loanSummaryId.escrowFund",
  lastPaymentDate: "loan.loanDetailId.loanSummaryId.lastPaymentDate",
  nextPaymentDue: "loan.loanDetailId.loanSummaryId.nextPaymentDue",
  updatedEscrowBalance: "loan.loanDetailId.loanSummaryId.updatedEscrowBalance",
  seasoned: "loan.loanDetailId.loanSummaryId.seasoned",
  paymentHistoryandUPBChecked:
    "loan.loanDetailId.loanSummaryId.paymentHistoryandUPBChecked",
  firstLoss: "result.loanResult.firstLoss.firstLossPercentage",
  holdback: "result.loanResult.firstLoss.totalCreditReserveHoldback",
  riskScore: ["result.loanResult.riskScores.totalAggregateRisk", "result.loanResult.riskScores.riskScoresVersion" ],
  inStateLocRisk: ["result.loanResult.riskScores.inStateLocRisk", "result.loanResult.riskScoreCalculations.inState", "result.loanResult.riskScores.riskScoresVersion" ],
  leaseStatus: "properties",
  lendingAmount: "lendingAmount",
  grade: "grades",
  master: "loan.loanDetailId.loanConfigId",
  fico: "result.loanResult.warehouseLine.fico",
  rateLocked: "loan.rateLockSyncStatus",
  rateLockedDate: "loan.loanDetailId.loanConfigId.rateLockedDate",
};
