/* eslint-disable react/jsx-indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
// import { css } from 'emotion';
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Grid, Typography, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { WarningModal } from "@toorak/tc-common-fe-sdk";
import { CommentEntity } from "../models";
import { CBContext } from "./CommentsBlock";
import { StatusComponent } from "../../../waiver-form/StatusComponent";
import OIcon from "../../../../images/oval.svg";
import {
  formatDatetoUi,
  isEmptyValue,
  isNullOrUndefined,
  nameToInitials
} from "../../../../utils/formatChecks";
import { useStyles } from "../../../exceptions-and-enquiries/ExceptionCardStyle";
import { getAccountTypeFirstLetter } from "../../../exceptions-and-enquiries/ExceptionsCard";
import { handleDownload } from "../../../exceptions-and-enquiries/DocumentUploadApis";
import { FileBar } from "../../../waiver-form/FileBar";
import oIcon from "../../../../images/exceptions/o.svg";
import tIcon from "../../../../images/exceptions/t.svg";
import dIcon from "../../../../images/exceptions/d.svg";
import editImage from "../../../../images/Edit.svg";
import { waiverFormStyles } from "../../../waiver-form/Common.style";
import {
  updateComment,
  updateEditedCommentStore
} from "../../../../stores/commentsSection/comment.action";
import {
  showLoader,
  hideLoader
} from "../../../../stores/loaderAndException/loaderAndException.action";

import { UserTagList } from "./UserTagList";
import { constructName } from "../../../../utils/utils";

export interface Props {
  comment?: CommentEntity;
  reactRouter?: boolean;
  exceptionComment?: any;
  accounts?: any;
  summaryComment?: boolean;
  customers?: any;
  chatSequenceId?: string;
  accountMapping?: any;
  accountNameImg?: any;
  orgType?: any;
  documents?: any;
  fullName?: any;
  isEditable?: boolean;
  threadList?: any;
  hostId?: string;
  keyId?: string;
}

const CssComment = {
  "&": {
    display: "flex",
    margin: "8px 0px 16px 0px"
  },
  "&__avatar": {
    display: "inline-block",
    "margin-right": "10px",
    "background-size": "contain"
  },
  "&__col-right": {
    display: "flex",
    "flex-wrap": "wrap",
    width: "calc(100% - 40px)",
    maxWidth: "calc(100% - 40px)"
    // "align-items": "center"
  },

  "&__col-right > a": {
    "text-decoration": "none"
  },

  // '&__name': {
  //   "font-size": '18px',
  //   "color": '#0088ff',
  //   "margin-right": '10px',
  // },

  "&__name": {
    color: "#8897aa",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    fontSize: "11px",
    paddingTop: 3,
    display: "inline-block"
  },

  "&__time": {
    "font-size": "10px",
    color: "#8897aa",
    paddingTop: "5px"
  },

  "&__content": {
    "font-size": "12px",
    flex: "1 100%",
    paddingBottom: 5,
    "word-break": "break-all",
    fontSeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.67,
    letterSpacing: "normal",
    color: "#32325d"
  },
  oBackground: {
    backgroundImage: `url(${OIcon})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "15px",
    height: "15px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    color: "#ffffff",
    margin: "3px"
  },
  // lBackground: {
  //   backgroundImage: `url(${latIcon})`,
  //   backgroundPosition: "center",
  //   backgroundSize: "cover",
  //   backgroundRepeat: "no-repeat",
  //   width: "15px",
  //   height: "15px",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   display: "flex",
  //   color: "#ffffff",
  //   margin: "3px"
  // },
  oStyle: {
    fontSize: "11px",
    fontWeight: 600
  },
  exceptionCommentContainer: {
    display: "flex"
  },
  commentTextContainer: {
    marginTop: "5px"
    // marginLeft: "11px",
  }
};

export const Comment: React.FunctionComponent<Props> = (props: Props) => {
  const {
    customers,
    comment,
    exceptionComment,
    accounts,
    accountMapping,
    summaryComment,
    accountNameImg,
    orgType,
    fullName,
    chatSequenceId,
    documents = [],
    isEditable,
    threadList,
    hostId,
    keyId
  } = props;
  const classes = useStyles();
  const fileClasses = waiverFormStyles();
  const dispatch = useDispatch();
  const [editComment, setEditComment] = useState(false);
  const [enableSaveBtn, setEnableSaveBtn] = useState(true);
  const [currentCommentText, setCurrentCommentText] = useState(
    comment?.lineText
  );
  const [openWarning, setOpenWarning] = useState<boolean>(false);

  const partyId =
    comment?.partyId ||
    exceptionComment?.partyId ||
    exceptionComment?.createdBy;
  const customerData = customers?.find((item: any) => item.partyId === partyId);
  const accountMappingItem = accountMapping?.find(
    (item: any) => item.personId === partyId
  );
  const accountData = accounts?.find(
    (item: any) => item.partyId === accountMappingItem?.accountId
  );
  const accountType = accountData?.accountType || orgType;
  const firstName = !isNullOrUndefined(customerData?.firstName)
    ? customerData?.firstName
    : "";

  const lastName = !isNullOrUndefined(customerData?.lastName)
    ? customerData?.lastName
    : "";

  const NameInitials = nameToInitials(`${firstName} ${lastName}`);
  const flName = constructName(firstName, "", lastName);
  const userFullName = flName || fullName;
  const accountTypeFirstLetter = !accountNameImg
    ? accountType
      ? getAccountTypeFirstLetter(accountType)[0]
      : ""
    : orgType;

  // To get the comment create date if the comment is created and get update date if the comment is edited
  const getCommentDate = (commentObj: any) => {
    let dateToUpdate = null;
    if (commentObj.updatedOn) {
      dateToUpdate = commentObj.updatedOn;
    } else {
      dateToUpdate = commentObj.createdOn;
    }
    return new Date(dateToUpdate).toLocaleDateString(undefined, {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      year: "numeric",
      month: "short",
      day: "2-digit"
    });
  };

  const textHtml = (
    <>
      {comment !== undefined && comment.lineText !== undefined
        ? comment.lineText.split("\n").map((chunk, inx, arr) =>
            inx !== arr.length - 1 ? (
              <React.Fragment key={chunk + inx + keyId}>
                {chunk}
                <br />
              </React.Fragment>
            ) : (
              chunk
            )
          )
        : exceptionComment && exceptionComment.description
        ? exceptionComment.description
        : exceptionComment?.comments}
    </>
  );
  const approvalTag = comment ? comment.tags?.[0] : "";
  const getApprovalTag = (approvalTypeTag: string) => {
    let modeTag = "";
    if (!isNullOrUndefined(approvalTypeTag))
      switch (approvalTypeTag.toLowerCase()) {
        case "approve":
          modeTag = "WAIVER APPROVAL COMMENT";
          break;
        case "approve-conditionally":
          modeTag = "WAIVER CONDITIONAL-APPROVAL COMMENT";
          break;
        case "reject":
          modeTag = "WAIVER REJECTION COMMENT";
          break;
        case "re-open":
          modeTag = "WAIVER REOPENED COMMENT";
          break;
        case "seems ok":
          modeTag = "SCENARIO APPROVAL COMMENT";
          break;
        case "doesn't fit toorak guidelines":
          modeTag = "SCENARIO REJECTION COMMENT";
          break;
        case "waiver rebuttal":
          modeTag = "WAIVER REBUTTAL COMMENT";
          break;
        case "rescind waiver comment":
          modeTag = "RESCIND WAIVER COMMENT";
          break;
        case "closing comment":
          modeTag = "Closing Comment";
          break;
        case "reopening comment":
          modeTag = "Re-Opening Comment";
          break;
        default:
          modeTag = "";
      }
    return modeTag;
  };
  // approvalTag === "Approve"
  //     ? "WAIVER APPROVAL COMMENT"
  //     : approvalTag === "Approve-Conditionally"
  //     ? "WAIVER CONDITIONAL-APPROVAL COMMENT"
  //     : approvalTag === "Reject"
  //     ? "WAIVER REJECTION COMMENT"
  //     : approvalTag === "Re-Open"
  //     ? "WAIVER REOPENED COMMENT"
  //     : approvalTag?.toLowerCase() === "seems ok"
  //     ? "Scenario Approval Comment".toUpperCase()
  //     : approvalTag?.toLowerCase() === "doesn't fit toorak guidelines"
  //     ? "Scenario Rejection Comment".toUpperCase()

  const commentTag = !exceptionComment
    ? getApprovalTag(approvalTag)
    : exceptionComment && exceptionComment.initialComment
    ? exceptionComment?.initialComment
    : `${exceptionComment?.status} LOAN INQUIRY COMMENT`;
  const date =
    comment !== undefined && comment.createdOn
      ? getCommentDate(comment)
      : exceptionComment && exceptionComment.createdOn
      ? formatDatetoUi(exceptionComment?.createdOn)
      : null;
  if (!comment && !exceptionComment) return null;
  const file =
    documents.length &&
    documents.find((item: any) => {
      let chatIdCheck = true;
      if (comment?.chatId) {
        chatIdCheck = comment?.chatId === item.primaryIdentifierValue;
      }
      return (
        chatIdCheck &&
        parseInt(item.secondaryIdentifierValue, 10) ===
          comment?.chatLineSequenceId
      );
    });
  // console.log("documents", documents);
  // console.log("chat", comment?.chatLineSequenceId);
  // save the edited comment
  const saveEditedComment = (commentData: any) => {
    const chatLineSequenceId = commentData?.chatLineSequenceId;
    const chatId = threadList[0].id || "";
    const lineText = currentCommentText || "";
    const loanId = hostId || "";

    if (lineText !== "") {
      dispatch(showLoader());
      updateComment(chatId, chatLineSequenceId, lineText)
        .then((data: any) => {
          setEditComment(false);
          dispatch(updateEditedCommentStore(data, loanId, commentData.id));
          dispatch(hideLoader());
          setEnableSaveBtn(true);
        })
        .catch((error: any) => {
          dispatch(hideLoader());
          setOpenWarning(true);
          setEnableSaveBtn(true);
        });
    }
  };

  const setCommentText = (value: string) => {
    if (value !== "") {
      setEnableSaveBtn(false);
    } else {
      setEnableSaveBtn(true);
    }
    setCurrentCommentText(value);
  };
  const isComment =
    comment &&
    comment?.tags?.length &&
    !isEmptyValue(approvalTag) &&
    !isEmptyValue(commentTag);
  return (
    <CBContext.Consumer>
      {() => {
        return (
          <>
            <WarningModal
              isOpen={openWarning}
              handleClose={() => {
                setOpenWarning(false);
              }}
              header="Warning"
              body="Something went wrong"
            />
            <Grid
              container
              className="cn"
              style={
                !exceptionComment
                  ? CssComment["&"]
                  : CssComment.exceptionCommentContainer
              }
              data-testid="comment-container"
            >
              {NameInitials !== "" || accountNameImg !== "" ? (
                <Grid item className={classes.userAvatarCircle}>
                  <div
                    // style={CssComment["&__avatar"]}
                    className={classes.avatar}
                  >
                    <div
                      className={
                        summaryComment ? classes.commentCircle : classes.circle
                      }
                    >
                      <span className={classes.nameInitials}>
                        {!isNullOrUndefined(NameInitials) && !accountNameImg
                          ? NameInitials
                          : accountNameImg || null}
                      </span>
                    </div>
                  </div>
                </Grid>
              ) : null}
              {!editComment ? (
                <>
                  <Grid
                    item
                    container
                    xs={11}
                    sm={11}
                    md={11}
                    lg={11}
                    alignItems="center"
                    // style={CssComment["&__col-right"]}
                    className={classes.commentColRight}
                  >
                    <Grid
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                    >
                      <Grid item>
                        <Typography
                          component="span"
                          style={CssComment["&__name"]}
                        >
                          {!isNullOrUndefined(partyId) && userFullName}
                        </Typography>
                      </Grid>
                      <Grid item>
                        {/* className={classes.accountPadding} */}
                        <span>
                          {accountTypeFirstLetter[0] === "L" ||
                          (accountTypeFirstLetter &&
                            accountTypeFirstLetter === "T") ? (
                            <img src={tIcon} alt="Originator" />
                          ) : accountTypeFirstLetter[0] === "D" ? (
                            <img src={dIcon} alt="Originator" />
                          ) : (
                            <img src={oIcon} alt="Originator" />
                          )}
                        </span>
                      </Grid>
                      {/* xs={6} sm={6} md={6} lg={6} */}
                      {isEditable && (
                        <Grid item>
                          <IconButton
                            aria-label="edit"
                            onClick={() => setEditComment(true)}
                            size="small"
                          >
                            <img
                              src={editImage}
                              alt="edit"
                              className={classes.commentEditBtn}
                            />
                          </IconButton>
                        </Grid>
                      )}
                      <Grid item>
                        {isComment ? (
                          <Grid item>
                            {summaryComment !== true ? (
                              <StatusComponent
                                status={`${approvalTag}`}
                                value={`${commentTag}`}
                              />
                            ) : (
                              ""
                            )}
                          </Grid>
                        ) : exceptionComment ? (
                          <Grid item>
                            {/* xs={10} sm={10} md={10} lg={10} */}
                            <StatusComponent value={`${commentTag}`} />
                          </Grid>
                        ) : null}
                      </Grid>
                    </Grid>
                    <div
                      // style={
                      //   !exceptionComment
                      //     ? CssComment["&__content"]
                      //     : CssComment.commentTextContainer
                      // }
                      className={
                        !exceptionComment
                          ? classes.commentContent
                          : classes.commentTextContainer
                      }
                    >
                      {textHtml}
                    </div>
                    {comment?.users && comment?.users.length > 0 && (
                      <UserTagList userList={comment?.users} />
                    )}

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      // style={CssComment["&__time"]}
                      className={classes.commentTime}
                    >
                      {date ||
                        (exceptionComment && exceptionComment.ddLastUpdate) ||
                        exceptionComment?.createdOn}
                    </Grid>
                  </Grid>
                  {file && file.docs && file.docs.length > 0
                    ? file.docs.map((item: any) => (
                        <Grid
                          item
                          xs={12}
                          className={fileClasses.fileBarWrapper}
                        >
                          <Grid container className={fileClasses.fileContainer}>
                            <FileBar
                              downloadHandler={() =>
                                handleDownload(
                                  item,
                                  chatSequenceId ||
                                    props?.documents[0]
                                      ?.primaryIdentifierValue ||
                                    "",
                                  "chat"
                                )
                              }
                              // // type={type}
                              value={item}
                            />
                          </Grid>
                        </Grid>
                      ))
                    : null}
                </>
              ) : (
                <Grid item xs={11}>
                  <Grid item className={classes.commentFullWidth}>
                    <textarea
                      className={classes.commentEditTextArea}
                      name="comment-text"
                      value={currentCommentText}
                      onChange={(event) => setCommentText(event.target.value)}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      type="submit"
                      color="info"
                      className={classes.commentBtn}
                      disabled={enableSaveBtn}
                      onClick={() => {
                        saveEditedComment(comment);
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      color="info"
                      onClick={() => {
                        setEditComment(false);
                        setCurrentCommentText(comment?.lineText);
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </>
        );
      }}
    </CBContext.Consumer>
  );
};
