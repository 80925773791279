/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
// import produce from "immer";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
// import {
//   ACTIVE_BUNDLE,
//   GET_FILTER_STATUS_LIST,
//   SET_TOTAL_BUNDLE_COUNT,
//   SET_BUNDLE_LIST,
//   SET_PURCHASE_LOANS,
//   SET_TAB_VIEW,
//   SET_ORG_WARN_ALERT,
//   SET_BUNDLE_SELECTED_LOANS,
//   SET_IS_LOADING,
//   SET_SEARCHED_LOANID,
//   SET_SELECTED_BUNLDE_LOANS,
//   TRIGGER_BUNDLE_LISTING_ONCE,
//   RESET_PAGE_NUMBER,
//   RESET_LOANS_SELECTIONS,
//   SET_LOAN_TABLE_TO_EXPORT,
//   CALCULATED_BUNDLE_FIELDS,
//   STORE_UPDATED_FIELDS_REGENERATE,
//   RECALL_CALC_FIELDS,
//   SET_LOAN_BUNDLE_DATA,
//   SET_BUNDLE_CHAT_COUNT,
//   SET_PARTY_ID_OBJ,
//   SET_VIEW_BUNDLE,
//   SET_BUNDLE_CHAT_UREAD_COUNT,
//   SET_CLEAR_P_AND_S_SORTING,
//   SET_P_AND_S_FILTER,
//   UPDATE_POLLING_DATA,
//   SET_REFRESH,
//   CLEAR_REFRESH,
//   SET_THIRD_PARTY_SERVICED_VALUE,
//   SET_THIRD_PARTY_SERVICERS,
//   SELECT_ALL_SERVICERS,
//   RESET_THIRD_PARTY_VALUES,
//   EMPTY_SEARCH_LOANID,
//   SET_EDIT_HISTORY,
//   GET_FILTER_ORG_LIST,
//   SET_MIN_BUNDLE_CREATED_ON_DATE,
//   SET_BUNDLE_DATE_RANGE,
//   GET_FILTER_LOAN_TYPE_LIST,
//   GET_TAKEOUTPARTNER_LIST,
//   CLEAR_BUNDLE_DATA,
//   REFRESH_BUNDLE_VIEW,
//   OPEN_EDIT_LOAN_MODAL,
//   OPEN_VIEW_LOAN_MODAL,
//   OPEN_DRAW_EDIT_LOAN_MODAL,
//   SET_SAVED_BUNDLE_FILTERS,
//   SAVE_EDITED_LOANS_DATA,
//   REMOVE_EDITED_LOANS_DATA,
//   CLEAR_EDITED_LOANS_DATA,
//   SAVE_EDITED_LOANS_DATA_COL_LEVEL,
//   REMOVE_EDITED_LOANS_DATA_COL_LEVEL,
//   SELECT_ALL_THIRD_PARTY_SERVICERS,
//   SAVE_SELECTED_LOANS_BT,
//   SET_AUDIT_STORE,
//   UPDATE_NEW_SIGNEE_ACTIVE_BUNDLE
// } from "./SettlementAndPurchase.action";

export const defaultQueryDateRange: any = {
  range: {
    closingDate: {
      gte: moment().subtract(15, "days").format("YYYY-MM-DD"),
      lte: moment().add(15, "days").format("YYYY-MM-DD"),
      format: "yyyy-MM-dd"
    }
  }
};
export interface SPStore {
  loading: boolean;
  pollingData?: {
    startPolling: boolean;
    timeInMs: number;
    showRefreshButton: boolean;
    refreshClicked?: boolean;
    informDataUpdate?: boolean;
  };
  bundleInfo: {
    bundleId: string;
    bundleStatus: string;
    closingDate: string;
    netWire?: string;
    totalLoanProceeds?: string;
    servicingTransferDate?: string;
    cutOffDate?: string;
    loanType: string;
    originatorPartyId: string;
    loanData: any[];
    partyIdsObj: any;
    auditData: any;
    loanAudits: any;
    wareHouseLenderList: any[];
    servicerList: any[];
    fundingType: string;
    loanStage: string;
    isWarehouseFunded: boolean;
    bundleSellerMapping: any[];
    staticCustomerData: any;
    originatorInfo: { [key: string]: string };
    whlToSellerMap: { [key: string]: string };
    takeoutPartner: string;
  };
  bundleStatusList: any;
  originatorsList: any;
  loanTypeFList: any[];
  takeoutPartnerList: any[];
  totalBundleCount: number;
  // totalBundleCount: number;
  bundleList: any;
  purchaseLoans: any;
  tabView: number;
  alertWarnOrg: boolean;
  selectedLonas: any;
  isLoading: boolean;
  searchedLoanID: string;
  triggerBundleListing: any;
  selectedLoansFromBundle: {
    reset: boolean;
    loans: any[];
  };
  resetPage: boolean;
  resetSelection: boolean;
  exportDetails: {
    bundleId: string;
    bundleStatus: string;
    loanTable: any;
  };
  calculatedFields: {
    loanResults: any;
    bundleResult: any;
  };
  updateGenFields: {
    loans: any;
  };
  recallGen: boolean;
  loanBundleDetails: {
    loanbundleData: any;
    calcFields: any;
    bundleStatus: any;
  };
  bundleChatCounts: any;
  bundleChatUnreadCounts: any;
  partyIdsObj: any;
  viewBundle: {
    loanId: string;
  };
  clearPandSsorting: boolean;
  filterObj: any;
  isThirdPartyServiced: boolean;
  thirdPartyServicers: Array<string>;
  emptySearch: boolean;
  bundleEditHistory: {
    audit: any;
    customers: any[];
  };
  minBundleCreatedOnDate: string;
  bundleDateRange: any;
  refreshBundleView: boolean;
  editModalProps: {
    open: boolean;
    loanId: string;
  };
  drawEditProps: {
    open: boolean;
    loanId: string;
  };
  viewModalProps: {
    open: boolean;
    loanId: string;
    fieldList: any[];
  };
  savedFilters: any;
  saveEditedLoans: any;
  selectedLoansInBT: any;
  auditStore: any;
}

export const initialState: SPStore = {
  loading: false,
  bundleInfo: {
    bundleId: "",
    bundleStatus: "",
    closingDate: "",
    netWire: "",
    totalLoanProceeds: "",
    servicingTransferDate: "",
    cutOffDate: "",
    loanType: "",
    originatorPartyId: "",
    loanData: [],
    partyIdsObj: {},
    auditData: {},
    loanAudits: {},
    wareHouseLenderList: [],
    servicerList: [],
    fundingType: "",
    loanStage: "",
    isWarehouseFunded: false,
    bundleSellerMapping: [],
    staticCustomerData: {},
    originatorInfo: {},
    whlToSellerMap: {},
    takeoutPartner: ""
  },
  bundleStatusList: [],
  originatorsList: [],
  loanTypeFList: [
    {
      label: "Select All",
      value: "selectAll",
      keyValue: "selectAll",
      key: "Select All",
      checked: false
    },
    {
      label: "Bridge Loan",
      value: "Bridge Loan",
      keyValue: "Bridge",
      key: "Bridge Loan",
      checked: false
    },
    {
      label: "Investor DSCR",
      value: "30 Year Loan",
      keyValue: "DSCR",
      key: "30 Year Loan",
      checked: false
    },
    {
      label: "Table Fund Loan",
      value: "table_funding",
      keyValue: "funding_type",
      key: "table_funding",
      checked: false
    },
    {
      label: "Warehouse funded Loan",
      value: "isWarehouseFunded",
      keyValue: "isWarehouseFunded",
      key: "isWarehouseFunded",
      checked: false
    },
    {
      label: "Advance Fund Loan",
      value: "advance_funding",
      keyValue: "funding_type",
      key: "advance_funding",
      checked: false
    },
    {
      label: "Non Table Fund Loan",
      value: "non_table_funding",
      keyValue: "funding_type",
      key: "normal",
      checked: false
    },
    {
      label: "Non Warehouse Fund Loan",
      value: "non_warehouse_funding",
      keyValue: "isWarehouseFunded",
      key: "isWarehouseFunded",
      checked: false
    }
  ],
  takeoutPartnerList: [],
  totalBundleCount: 0,
  bundleList: [],
  purchaseLoans: [],
  tabView: 0,
  // tabView: 2,
  alertWarnOrg: false,
  selectedLonas: [],
  isLoading: false,
  searchedLoanID: "",
  triggerBundleListing: null,
  selectedLoansFromBundle: {
    reset: false,
    loans: []
  },
  resetPage: false,
  resetSelection: false,
  exportDetails: {
    bundleId: "",
    bundleStatus: "",
    loanTable: []
  },
  calculatedFields: {
    loanResults: {},
    bundleResult: {}
  },
  updateGenFields: {
    loans: {}
  },
  recallGen: false,
  loanBundleDetails: {
    loanbundleData: {},
    calcFields: {},
    bundleStatus: ""
  },
  bundleChatCounts: {},
  bundleChatUnreadCounts: {},
  partyIdsObj: {},
  viewBundle: {
    loanId: ""
  },
  clearPandSsorting: false,
  filterObj: {},
  isThirdPartyServiced: false,
  thirdPartyServicers: [],
  emptySearch: false,
  bundleEditHistory: {
    audit: {},
    customers: []
  },
  minBundleCreatedOnDate: moment().subtract(14, "days").format("YYYY-MM-DD"),
  bundleDateRange: defaultQueryDateRange,
  refreshBundleView: false,
  editModalProps: {
    open: false,
    loanId: ""
  },
  drawEditProps: {
    open: false,
    loanId: ""
  },
  viewModalProps: {
    open: false,
    loanId: "",
    fieldList: []
  },
  savedFilters: {},
  saveEditedLoans: {},
  selectedLoansInBT: new Set(),
  auditStore: {}
};

const spSlice = createSlice({
  name: "sp",
  initialState,
  reducers: {
    setAuditDataBIview(state, action: PayloadAction<any>) {
      state.auditStore = action.payload;
    },
    saveSelectedLoansBT(state, action: PayloadAction<any>) {
      state.selectedLoansInBT = action.payload;
    },
    setSaveEditedLoansData(state, action: PayloadAction<any>) {
      const { loan, colKey, value } = action.payload;
      const oldLoanData = state.saveEditedLoans[loan] || {};
      const newData = {
        ...state.saveEditedLoans,
        [loan]: {
          ...oldLoanData,
          [colKey]: value
        }
      };
      state.saveEditedLoans = newData;
    },
    rmColLvlSaveEditedLoansData(state, action: PayloadAction<any>) {
      const newData = JSON.parse(JSON.stringify(state.saveEditedLoans));
      const { rowKey, cellKey } = action.payload;
      if (rowKey in newData && cellKey in newData[rowKey]) {
        delete newData[rowKey][cellKey];
        if (!Object.keys(newData[rowKey]).length) delete newData[rowKey];
      }
      state.saveEditedLoans = newData;
    },
    colLvlSaveEditedLoansData(state, action: PayloadAction<any>) {
      const newData: any = {};
      const { cellKey, value, loanIds } = action.payload;
      const oldData: any = state.saveEditedLoans;
      loanIds.forEach((itm: any, index: number) => {
        const loanData = oldData[itm.loanId] || {};
        newData[itm.loanId] = {
          ...loanData,
          [cellKey]: value
        };
        if (index + 1 === loanIds.length) {
          state.saveEditedLoans = newData;
        }
      });
    },
    removeSaveEditedLoansData(state, action: PayloadAction<any>) {
      const newData: any = {};
      const { cellKey } = action.payload;
      const oldData: any = JSON.parse(JSON.stringify(state.saveEditedLoans));
      const loanIds: any[] = Object.keys(oldData);
      loanIds.forEach((loanId: any, index: number) => {
        const loanData = oldData[loanId];
        delete loanData[cellKey];
        if (Object.keys(loanData).length) newData[loanId] = loanData;
        if (index + 1 === loanIds.length) {
          state.saveEditedLoans = newData;
        }
      });
    },
    clearEditedLoansData(state) {
      state.saveEditedLoans = {};
    },
    setSavedBundleFilters(state, action: PayloadAction<any>) {
      state.savedFilters = action.payload;
    },
    setOpenViewModal(state, action: PayloadAction<any>) {
      state.viewModalProps = action.payload;
    },
    setOpenEditModal(state, action: PayloadAction<any>) {
      state.editModalProps = action.payload;
    },
    setOpenDrawEditModal(state, action: PayloadAction<any>) {
      state.drawEditProps = action.payload;
    },
    setBundleRefresh(state, action: PayloadAction<boolean>) {
      state.refreshBundleView = action.payload;
    },
    resetPageNumber(state, action: PayloadAction<any>) {
      state.resetPage = action.payload;
    },
    setBundleInfo(state, action: PayloadAction<any>) {
      state.bundleInfo = action.payload;
    },
    updateNewSigneeActiveBundle(state, action: PayloadAction<any>) {
      const { newSellerObj, sellerId } = action.payload;
      state.bundleInfo = {
        ...state.bundleInfo,
        bundleSellerMapping: state.bundleInfo.bundleSellerMapping?.map(
          (sInfo: any) => {
            if (sInfo.sellerPartyId === sellerId) {
              return {
                ...sInfo,
                bundleSellerSigneeMapping: [
                  ...sInfo.bundleSellerSigneeMapping,
                  {
                    signeePartyId: newSellerObj.partyId
                  }
                ]
              };
            }
            return sInfo;
          }
        ),
        staticCustomerData: {
          ...state.bundleInfo.staticCustomerData,
          [newSellerObj.partyId]: newSellerObj
        }
      };
    },
    setFilterStatusList(state, action: PayloadAction<any>) {
      state.bundleStatusList = action.payload;
    },
    setOrgFilter(state, action: PayloadAction<any>) {
      state.originatorsList = action.payload;
    },
    setMinBundleCreatedOnDate(state, action: PayloadAction<any>) {
      state.minBundleCreatedOnDate = action.payload;
    },
    setBundleDateRange(state, action: PayloadAction<any>) {
      state.bundleDateRange = action.payload;
    },
    setLoanTypeFilter(state, action: PayloadAction<any>) {
      state.loanTypeFList = action.payload;
    },
    setTakeoutPartnerFilter(state, action: PayloadAction<any>) {
      state.takeoutPartnerList = action.payload;
    },
    setTotalBundleCount(state, action: PayloadAction<any>) {
      state.totalBundleCount = action.payload;
    },
    setBundleList(state, action: PayloadAction<any>) {
      state.bundleList = action.payload;
    },
    setPurchaseLoans(state, action: PayloadAction<any>) {
      state.purchaseLoans = action.payload;
    },
    setTabView(state, action: PayloadAction<any>) {
      state.tabView = action.payload;
    },
    setOrgWarnAlert(state, action: PayloadAction<any>) {
      state.alertWarnOrg = action.payload;
    },
    setBundleSelectedLoans(state, action: PayloadAction<any>) {
      state.selectedLonas = action.payload;
    },
    // setIsLoading(state, action: PayloadAction<any>) {
    //   state.isLoading = action.payload;
    // },
    setSearchedLoanID(state, action: PayloadAction<any>) {
      state.searchedLoanID = action.payload;
    },
    setSelectedBundleLoans(state, action: PayloadAction<any>) {
      const { reset, loans } = action.payload;
      state.selectedLoansFromBundle = {
        reset,
        loans: loans || []
      };
    },
    triggerBundleListingOnce(state, action: PayloadAction<any>) {
      state.triggerBundleListing = action.payload;
    },
    resetLoanSelections(state, action: PayloadAction<any>) {
      state.resetSelection = action.payload;
    },
    // setLoanTableToExport(state, action: PayloadAction<any>) {
    //   state.exportDetails = action.payload;
    // },
    calculatedBundleFields(state, action: PayloadAction<any>) {
      state.calculatedFields = action.payload;
    },
    storeUpdatedFieldsToRegenerate(state, action: PayloadAction<any>) {
      state.updateGenFields = action.payload;
    },
    recallCalcFields(state, action: PayloadAction<any>) {
      state.recallGen = action.payload;
    },
    setBundleLoanData(state, action: PayloadAction<any>) {
      const { loanbundleData, calcFields, bundleStatus } = action.payload;
      state.loanBundleDetails = {
        loanbundleData,
        calcFields,
        bundleStatus
      };
    },
    setBundleChatCount(state, action: PayloadAction<any>) {
      state.bundleChatCounts = action.payload;
    },
    setBundleChatUnreadCount(state, action: PayloadAction<any>) {
      state.bundleChatUnreadCounts = action.payload;
    },
    // setPartyIdObj(state, action: PayloadAction<any>) {
    //   state.partyIdsObj = action.payload;
    // },
    setViewBundle(state, action: PayloadAction<any>) {
      state.viewBundle = action.payload;
    },
    setClearPAndSSorting(state, action: PayloadAction<any>) {
      state.clearPandSsorting = action.payload;
    },
    setFiltersPandS(state, action: PayloadAction<any>) {
      state.filterObj = action.payload;
    },
    updatePollingData(state, action: PayloadAction<any>) {
      const { startPolling, timeInMs } = action.payload;
      const showRefreshButton = state.pollingData?.startPolling
        ? !startPolling
        : false;
      state.pollingData = {
        startPolling,
        timeInMs,
        showRefreshButton,
        informDataUpdate: startPolling && !showRefreshButton
      };
    },
    setRefresh(state) {
      state.pollingData = {
        refreshClicked: true,
        showRefreshButton: true,
        startPolling: false,
        timeInMs: 15
      };
    },
    clearPolling(state) {
      state.pollingData = undefined;
    },
    setThirdPartyServicedValue(state, action: PayloadAction<any>) {
      state.isThirdPartyServiced = action.payload.isThirdPartyServiced;
    },
    SelectDeselectAllThirdParty(state, action: PayloadAction<any>) {
      state.thirdPartyServicers = [...action.payload];
    },
    updateThirdPartyServicers(state, action: PayloadAction<any>) {
      if (action.payload.isChecked) {
        state.thirdPartyServicers.push(action.payload.servicer);
      } else if (state.thirdPartyServicers.includes(action.payload.servicer)) {
        const index = state.thirdPartyServicers.indexOf(
          action.payload.servicer
        );
        state.thirdPartyServicers.splice(index, 1);
      }
    },
    selectAllServicers(state, action: PayloadAction<any>) {
      if (action.payload.isChecked) {
        state.thirdPartyServicers = action.payload.servicersArr;
      } else {
        state.thirdPartyServicers = [];
      }
    },
    resetThirdPartyServicerValue(state) {
      state.isThirdPartyServiced = false;
      state.thirdPartyServicers = [];
    },
    emptySearchLoanID(state, action: PayloadAction<any>) {
      state.emptySearch = action.payload;
    },
    setEditHistory(state, action: PayloadAction<any>) {
      state.bundleEditHistory = action.payload;
    },
    clearBundleData() {
      return initialState;
    }
  }
});

export const {
  setAuditDataBIview,
  saveSelectedLoansBT,
  setSaveEditedLoansData,
  rmColLvlSaveEditedLoansData,
  colLvlSaveEditedLoansData,
  removeSaveEditedLoansData,
  clearEditedLoansData,
  setSavedBundleFilters,
  setOpenViewModal,
  setOpenEditModal,
  setOpenDrawEditModal,
  setBundleRefresh,
  resetPageNumber,
  setBundleInfo,
  updateNewSigneeActiveBundle,
  setFilterStatusList,
  setOrgFilter,
  setMinBundleCreatedOnDate,
  setBundleDateRange,
  setLoanTypeFilter,
  setTakeoutPartnerFilter,
  setTotalBundleCount,
  setBundleList,
  setPurchaseLoans,
  setTabView,
  setOrgWarnAlert,
  setBundleSelectedLoans,
  // setIsLoading,
  setSearchedLoanID,
  setSelectedBundleLoans,
  triggerBundleListingOnce,
  resetLoanSelections,
  // setLoanTableToExport,
  calculatedBundleFields,
  storeUpdatedFieldsToRegenerate,
  recallCalcFields,
  setBundleLoanData,
  setBundleChatCount,
  setBundleChatUnreadCount,
  // setPartyIdObj,
  setViewBundle,
  setClearPAndSSorting,
  setFiltersPandS,
  updatePollingData,
  setRefresh,
  clearPolling,
  setThirdPartyServicedValue,
  SelectDeselectAllThirdParty,
  updateThirdPartyServicers,
  selectAllServicers,
  resetThirdPartyServicerValue,
  emptySearchLoanID,
  setEditHistory,
  clearBundleData
} = spSlice.actions;

export const SPReducer = spSlice.reducer;

// export const SPReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case SET_AUDIT_STORE: {
//       return produce(state, draftState => {
//         draftState.auditStore = action.payload;
//       });
//     }
//     case SAVE_SELECTED_LOANS_BT: {
//       return produce(state, draftState => {
//         draftState.selectedLoansInBT = action.payload;
//       });
//     }
//     case SAVE_EDITED_LOANS_DATA: {
//       return produce(state, draftState => {
//         const { loan, colKey, value } = action.payload;
//         const oldLoanData = state.saveEditedLoans[loan] || {};
//         const newData = {
//           ...state.saveEditedLoans,
//           [loan]: {
//             ...oldLoanData,
//             [colKey]: value
//           }
//         };
//         console.log(newData, "saveEditedLoans loan lvl");
//         draftState.saveEditedLoans = newData;
//       });
//     }
//     case REMOVE_EDITED_LOANS_DATA: {
//       return produce(state, draftState => {
//         const newData = JSON.parse(JSON.stringify(state.saveEditedLoans));
//         const { rowKey, cellKey } = action.payload;
//         if (rowKey in newData && cellKey in newData[rowKey]) {
//           delete newData[rowKey][cellKey];
//           if (!Object.keys(newData[rowKey]).length) delete newData[rowKey];
//           console.log(newData, "removeEditedLoans loan lvl");
//           draftState.saveEditedLoans = newData;
//         }
//       });
//     }
//     case SAVE_EDITED_LOANS_DATA_COL_LEVEL: {
//       return produce(state, draftState => {
//         const newData: any = {};
//         const { cellKey, value, loanIds } = action.payload;
//         const oldData: any = state.saveEditedLoans;
//         loanIds.forEach((itm: any, index: number) => {
//           const loanData = oldData[itm.loanId] || {};
//           newData[itm.loanId] = {
//             ...loanData,
//             [cellKey]: value
//           };
//           if (index + 1 === loanIds.length) {
//             console.log(newData, "saveEditedLoans bundle lvl");
//             draftState.saveEditedLoans = newData;
//           }
//         });
//       });
//     }
//     case REMOVE_EDITED_LOANS_DATA_COL_LEVEL: {
//       return produce(state, draftState => {
//         const newData: any = {};
//         const { cellKey } = action.payload;
//         const oldData: any = JSON.parse(JSON.stringify(state.saveEditedLoans));
//         const loanIds: any[] = Object.keys(oldData);
//         loanIds.forEach((loanId: any, index: number) => {
//           const loanData = oldData[loanId];
//           delete loanData[cellKey];
//           if (Object.keys(loanData).length) newData[loanId] = loanData;
//           if (index + 1 === loanIds.length) {
//             console.log(newData, "removeEditedLoans bundle lvl");
//             draftState.saveEditedLoans = newData;
//           }
//         });
//       });
//     }
//     case CLEAR_EDITED_LOANS_DATA: {
//       return produce(state, draftState => {
//         draftState.saveEditedLoans = {};
//       });
//     }
//     case SET_SAVED_BUNDLE_FILTERS: {
//       return produce(state, draftState => {
//         draftState.savedFilters = action.payload;
//       });
//     }
//     case OPEN_VIEW_LOAN_MODAL: {
//       return produce(state, draftState => {
//         draftState.viewModalProps = action.payload;
//       });
//     }
//     case OPEN_EDIT_LOAN_MODAL: {
//       return produce(state, draftState => {
//         draftState.editModalProps = action.payload;
//       });
//     }
//     case OPEN_DRAW_EDIT_LOAN_MODAL: {
//       return produce(state, draftState => {
//         draftState.drawEditProps = action.payload;
//       });
//     }
//     case REFRESH_BUNDLE_VIEW: {
//       return produce(state, draftState => {
//         draftState.refreshBundleView = action.payload;
//       });
//     }
//     case RESET_PAGE_NUMBER: {
//       return produce(state, draftState => {
//         draftState.resetPage = action.payload;
//       });
//     }
//     case ACTIVE_BUNDLE: {
//       return produce(state, draftState => {
//         draftState.bundleInfo = action.payload;
//       });
//     }
//     case UPDATE_NEW_SIGNEE_ACTIVE_BUNDLE: {
//       return produce(state, draftState => {
//         const { newSellerObj, sellerId } = action.payload;
//         draftState.bundleInfo = {
//           ...draftState.bundleInfo,
//           bundleSellerMapping: draftState?.bundleInfo.bundleSellerMapping?.map(
//             (sInfo: any) => {
//               if (sInfo.sellerPartyId === sellerId) {
//                 return {
//                   ...sInfo,
//                   bundleSellerSigneeMapping: [
//                     ...sInfo.bundleSellerSigneeMapping,
//                     {
//                       signeePartyId: newSellerObj.partyId
//                     }
//                   ]
//                 };
//               }
//               return sInfo;
//             }
//           ),
//           staticCustomerData: {
//             ...draftState.bundleInfo.staticCustomerData,
//             [newSellerObj.partyId]: newSellerObj
//           }
//         };
//       });
//     }
//     case GET_FILTER_STATUS_LIST: {
//       return produce(state, draftState => {
//         draftState.bundleStatusList = action.payload;
//       });
//     }
//     case GET_FILTER_ORG_LIST: {
//       return produce(state, draftState => {
//         draftState.originatorsList = action.payload;
//       });
//     }
//     case SET_MIN_BUNDLE_CREATED_ON_DATE: {
//       return produce(state, draftState => {
//         draftState.minBundleCreatedOnDate = action.payload;
//       });
//     }
//     case SET_BUNDLE_DATE_RANGE: {
//       return produce(state, draftState => {
//         draftState.bundleDateRange = action.payload;
//       });
//     }
//     case GET_FILTER_LOAN_TYPE_LIST: {
//       return produce(state, draftState => {
//         draftState.loanTypeFList = action.payload;
//       });
//     }
//     case GET_TAKEOUTPARTNER_LIST: {
//       return produce(state, draftState => {
//         draftState.takeoutPartnerList = action.payload;
//       });
//     }
//     // case GET_BUNDLE_COUNT: {
//     //   const nextState = produce(state, draftState => {
//     //     draftState.totalBundleCount = action.payload;
//     //   });
//     //   return nextState;
//     // }
//     case SET_TOTAL_BUNDLE_COUNT: {
//       return produce(state, draftState => {
//         draftState.totalBundleCount = action.payload;
//       });
//     }
//     case SET_BUNDLE_LIST: {
//       return produce(state, draftState => {
//         draftState.bundleList = action.payload;
//       });
//     }
//     case SET_PURCHASE_LOANS: {
//       return produce(state, draftState => {
//         draftState.purchaseLoans = action.payload;
//       });
//     }
//     case SET_TAB_VIEW: {
//       return produce(state, draftState => {
//         draftState.tabView = action.payload;
//       });
//     }
//     case SET_ORG_WARN_ALERT: {
//       return produce(state, draftState => {
//         draftState.alertWarnOrg = action.payload;
//       });
//     }
//     case SET_BUNDLE_SELECTED_LOANS: {
//       return produce(state, draftState => {
//         draftState.selectedLonas = action.payload;
//       });
//     }
//     case SET_IS_LOADING: {
//       return produce(state, draftState => {
//         draftState.isLoading = action.payload;
//       });
//     }
//     case SET_SEARCHED_LOANID: {
//       return produce(state, draftState => {
//         draftState.searchedLoanID = action.payload;
//       });
//     }
//     case SET_SELECTED_BUNLDE_LOANS: {
//       return produce(state, draftState => {
//         const { reset, loans } = action.payload;
//         draftState.selectedLoansFromBundle = {
//           reset,
//           loans: loans || []
//         };
//       });
//     }
//     case TRIGGER_BUNDLE_LISTING_ONCE: {
//       return produce(state, draftState => {
//         draftState.triggerBundleListing = action.payload;
//       });
//     }
//     case RESET_LOANS_SELECTIONS: {
//       return produce(state, draftState => {
//         draftState.resetSelection = action.payload;
//       });
//     }
//     case SET_LOAN_TABLE_TO_EXPORT: {
//       return produce(state, draftState => {
//         draftState.exportDetails = action.payload;
//       });
//     }
//     case CALCULATED_BUNDLE_FIELDS: {
//       return produce(state, draftState => {
//         draftState.calculatedFields = action.payload;
//       });
//     }
//     case STORE_UPDATED_FIELDS_REGENERATE: {
//       return produce(state, draftState => {
//         draftState.updateGenFields = action.payload;
//       });
//     }
//     case RECALL_CALC_FIELDS: {
//       return produce(state, draftState => {
//         draftState.recallGen = action.payload;
//       });
//     }
//     case SET_LOAN_BUNDLE_DATA: {
//       return produce(state, draftState => {
//         const { loanbundleData, calcFields, bundleStatus } = action.payload;
//         draftState.loanBundleDetails = {
//           loanbundleData,
//           calcFields,
//           bundleStatus
//         };
//       });
//     }
//     case SET_BUNDLE_CHAT_COUNT: {
//       return produce(state, draftState => {
//         draftState.bundleChatCounts = action.payload;
//       });
//     }
//     case SET_BUNDLE_CHAT_UREAD_COUNT: {
//       return produce(state, draftState => {
//         draftState.bundleChatUnreadCounts = action.payload;
//       });
//     }
//     case SET_PARTY_ID_OBJ: {
//       return produce(state, draftState => {
//         draftState.partyIdsObj = action.payload;
//       });
//     }
//     case SET_VIEW_BUNDLE: {
//       return produce(state, draftState => {
//         draftState.viewBundle = action.payload;
//       });
//     }
//     case SET_CLEAR_P_AND_S_SORTING: {
//       return produce(state, draftState => {
//         draftState.clearPandSsorting = action.payload;
//       });
//     }
//     case SET_P_AND_S_FILTER: {
//       return produce(state, draftState => {
//         draftState.filterObj = action.payload;
//       });
//     }
//     case UPDATE_POLLING_DATA: {
//       const { startPolling, timeInMs } = action.payload;
//       return produce(state, draftState => {
//         const showRefreshButton = state.pollingData?.startPolling
//           ? !startPolling
//           : false;
//         draftState.pollingData = {
//           startPolling,
//           timeInMs,
//           showRefreshButton,
//           informDataUpdate: startPolling && !showRefreshButton
//         };
//       });
//     }
//     case SET_REFRESH: {
//       return produce(state, draftState => {
//         draftState.pollingData = {
//           refreshClicked: true,
//           showRefreshButton: true,
//           startPolling: false,
//           timeInMs: 15
//         };
//       });
//     }
//     case CLEAR_REFRESH: {
//       return produce(state, draftState => {
//         draftState.pollingData = undefined;
//       });
//     }
//     case SET_THIRD_PARTY_SERVICED_VALUE: {
//       return produce(state, draftState => {
//         draftState.isThirdPartyServiced = action.isThirdPartyServiced;
//       });
//     }
//     case SELECT_ALL_THIRD_PARTY_SERVICERS: {
//       return produce(state, draftState => {
//         draftState.thirdPartyServicers = [...action.servicers];
//       });
//     }
//     case SET_THIRD_PARTY_SERVICERS: {
//       return produce(state, draftState => {
//         if (action.isChecked) {
//           draftState.thirdPartyServicers.push(action.servicer);
//         } else if (draftState.thirdPartyServicers.includes(action.servicer)) {
//           const index = draftState.thirdPartyServicers.indexOf(action.servicer);
//           draftState.thirdPartyServicers.splice(index, 1);
//         }
//       });
//     }
//     case SELECT_ALL_SERVICERS: {
//       return produce(state, draftState => {
//         if (action.isChecked) {
//           draftState.thirdPartyServicers = action.servicersArr;
//         } else {
//           draftState.thirdPartyServicers = [];
//         }
//       });
//     }
//     case RESET_THIRD_PARTY_VALUES: {
//       return produce(state, draftState => {
//         draftState.isThirdPartyServiced = false;
//         draftState.thirdPartyServicers = [];
//       });
//     }
//     case EMPTY_SEARCH_LOANID: {
//       return produce(state, draftState => {
//         draftState.emptySearch = action.payload;
//       });
//     }
//     case SET_EDIT_HISTORY: {
//       return produce(state, draftState => {
//         draftState.bundleEditHistory = action.payload;
//       });
//     }
//     case CLEAR_BUNDLE_DATA: {
//       return initialState;
//     }
//     default:
//       return state;
//   }
// };
