import React, { useState } from "react";

import { Box, Chip } from "@mui/material";
import { Alert } from '@mui/material';
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import { onHoldStyles } from "./Common.style";

import { ViewOnHoldDialog } from "./ViewOnHoldDialog";
import { useHoldLandMark } from "./useHoldLandMark";
import { generateAgeingLabel } from "../../utils/utils";

export const OnHoldInfoTag = () => {
  const classes = onHoldStyles();
  const [isOpenHoldDialog, setIsOpenHoldDialog] = useState<boolean>(false);
  const loanLandmark: any = useHoldLandMark(true);

  const handleHoldDialogOpen = () => {
    setIsOpenHoldDialog(true);
  };

  const handleHoldDialogClose = () => {
    setIsOpenHoldDialog(false);
  };

  const generateHoldChipLabel = () => {
    if (loanLandmark) {
      const timeText = generateAgeingLabel(loanLandmark?.date);
      return `ON HOLD - ${timeText}`;
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center" className={classes.chipWrap}>
        <Chip
          label={generateHoldChipLabel()}
          size="small"
          deleteIcon={<HelpOutlineIcon />}
          onClick={handleHoldDialogOpen}
          onDelete={handleHoldDialogOpen}
          classes={{
            root: classes.chipRoot,
            label: classes.label,
            deleteIcon: classes.deleteIcon,
            clickable: classes.clickable
          }}
        />
        <Alert
          severity="info"
          classes={{
            root: classes.alertRoot,
            icon: classes.infoIcon,
            message: classes.alertMessage
          }}
        >
          Loan is temporarily on hold and is not present in current pipeline of
          loans
        </Alert>
      </Box>
      {isOpenHoldDialog && (
        <ViewOnHoldDialog
          action="VIEW"
          isOpen={isOpenHoldDialog}
          handleClose={handleHoldDialogClose}
        />
      )}
    </>
  );
};
