import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { Grid } from "@mui/material";
import { ZeroStateLoan } from "../create-loan/loan-lists/DetailsView/ZeroStateLoan";
import { ObjectType } from "../masterView/common";
import LoanCard from "./LoanCard";
import { zeroStateStyles } from "./styles";
import { useMultiSelection } from "./Utils";
import { TakeOutPartnerContext } from "./takeoutPartner";
import { ListHeader } from "./ListHeader";
import useLazyScroll from "../custom-hooks/useLazyScroll";

export interface LoanListHeaderProps {
  list: Map<string, ObjectType>;
  handleCommentClick: Function;
  warehouseLines: ObjectType[];
  showTags: boolean;
  showOriginatorTag: boolean;
  showHeader: boolean;
}

export const TKOPListView = (props: any) => {
  const { appliedFiltersData, fetchData } = useContext(TakeOutPartnerContext);
  const { list, showHeader = true } = props;
  const classes = zeroStateStyles();
  const [updatedLoansData, setUpdatedLoansData] = useState({});
  const {
    selectedItems,
    setSelectedItems,
    updateSelectedItems
  } = useMultiSelection({ key: "loanId" });

  useEffect(() => {
    setSelectedItems(new Map());
  }, [appliedFiltersData, setSelectedItems]);

  const onBulkSuccessCallBack = (data: ObjectType) => {
    setUpdatedLoansData({ ...updatedLoansData, ...data });
  };

  // const onIndividualSuccessCallBack = (data: ObjectType) =>
  //   setUpdatedLoansData({ ...updatedLoansData, ...data });

  const scrollRef = useRef<HTMLDivElement>(null);
  const onScroll = useCallback(() => {
    if (list.length < 20) return;
    fetchData("", list.length, appliedFiltersData, false);
  }, [list, appliedFiltersData, fetchData]);

  useLazyScroll(scrollRef, onScroll, true);

  return (
    <>
      {showHeader && (
        <ListHeader
          onSelectAll={setSelectedItems}
          selectedItems={selectedItems}
          itemsList={list}
          listHeaderText={`Initial Review Loans (${list.length})`}
          onSuccessCallBack={onBulkSuccessCallBack}
          idKey="loanId"
          partners={[]}
          isDropDownNeeded={false}
        />
      )}
      <Grid style={{ maxHeight: "80vh", overflowY: "auto" }} ref={scrollRef}>
        {list &&
          list.map((loan: ObjectType, index: number) => {
            return (
              <LoanCard
                key={`Loan_${index.toString()}`}
                isSelected={selectedItems.has(loan.loanId)}
                loanDetails={loan}
                updateSelectedLoans={updateSelectedItems}
              />
            );
          })}
        {list.length === 0 && (
          <ZeroStateLoan
            textToShow="There are no loans available!"
            secondTextToShow=""
            customClasses={classes}
          />
        )}
      </Grid>
    </>
  );
};
