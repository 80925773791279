/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Container, Grid, Theme, Button, Paper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  LoanStage,
  CheckListGroupFilter,
  CheckListItemProps
} from "@toorak/tc-common-fe-sdk";
import RefreshIcon from "@mui/icons-material/Refresh";

import { ExceptionsTabsPanel } from "./ExceptionsTabsPanel";
import filterIcon from "../../images/filter-outline-2.svg";
import {
  getExceptionsAndEnquiriesByLoanId
  // resetExceptionStore,
  // updateConditionsFilters,
  // setFilteredExceptionsArr,
  // disableCondtResetFilter
} from "../../stores/ExceptionsResults/ExceptionActions";
import { RootState } from "../../stores/rootReducer";
import {
  defaultVisiblityState,
  defaultWaiverState,
  defaultSatisfiedState,
  CondtFilterGroupType
} from "./constants";
import { isLATUser } from "../../utils/AccessManagement";
import {
  disableCondtResetFilter,
  resetExceptionStore,
  setFilteredException,
  updateConditionsFilters
} from "../../stores/ExceptionsResults/ExceptionsReducer";
// import { updateConditionStatusFilter } from "../../stores/dashboardSection/dashboard.action";

// import { updateConditionStatusFilter } from "../../stores/dashboardSection/dashboard.action";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      input: {
        backgroundColor: "#ffffff !important"
      }
    },
    wrapper: {
      display: "flex"
    },
    conditionInquiryMobileView: {
      [theme.breakpoints.down("sm")]: {
        padding: "24px 10px 60px !important"
      }
    },
    filterButtonFullSC: {
      color: "#32325d",
      padding: "12px",
      "&:hover": {
        backgroundColor: "inherit !important",
        color: "#32325d !important"
      }
    },
    clearButtonFullScreen: {
      marginRight: "20px",
      color: "#5e72e4",
      "&:hover": {
        backgroundColor: "inherit !important",
        color: "#5e72e4 !important"
      }
    },
    filterOutterContainer: {
      minWidth: "330px",
      // width: "330px",
      // maxWidth: "650px",
      backgroundColor: "#ffffff"
    },
    filterOutterContainerFullScreen: {
      // minWidth: "220px",
      // maxWidth: "650px",
      backgroundColor: "#ffffff"
    }
  })
);

const VisiblityHeader: string = "Visible To";
const WaiverHeader: string = "Waiver";
const SatisfiedHeader: string = "Satisfied";

interface CondFtilterSection {
  visiblity: boolean;
  waiver: boolean;
  satisfied: boolean;
}

export interface FilterOptionProps {
  visiblity: CheckListItemProps[];
  waiver: CheckListItemProps[];
  satisfied: CheckListItemProps[];
}

export const getErrorCount = (data: any) => {
  let countException = 0;
  let countEnquiry = 0;
  if (!data || data?.length <= 0) return null;
  Array.isArray(data) &&
    data.forEach((item: any) => {
      if (
        item.status.toLowerCase() !== "satisfied" &&
        item.status.toLowerCase() !== "approved" &&
        item.status.toLowerCase() !== "approved-conditionally" &&
        item.status.toLowerCase() !== "rejected" &&
        item.status.toLowerCase() !== "rescinded" &&
        item.status.toLowerCase() !== "rescind" &&
        item.type.toLowerCase() === "inquiry"
      ) {
        countEnquiry += 1;
      }
      if (
        (item.status.toLowerCase() !== "satisfied" &&
          item.status.toLowerCase() !== "approved" &&
          item.status.toLowerCase() !== "waived" &&
          item.status.toLowerCase() !== "rejected" &&
          item.status.toLowerCase() !== "rescinded" &&
          item.status.toLowerCase() !== "rescind" &&
          item.type.toLowerCase() === "exception") ||
        item.type.toLowerCase() === "exceptions"
      ) {
        countException += 1;
      }
    });
  return [countEnquiry, countException];
};

export const mapWaiverToExceptions = (exceptions: any, waiversResult: any) => {
  const duplicateWaivers =
    waiversResult && JSON.parse(JSON.stringify(waiversResult));

  const result =
    duplicateWaivers &&
    duplicateWaivers.reduce(function (r: any, a: any) {
      r[a.waiverKeys[0]] = r[a.waiverKeys[0]] || [];

      r[a.waiverKeys[0]].push(a);
      return r;
    }, Object.create(null));
  const orgVal =
    exceptions &&
    exceptions instanceof Array &&
    exceptions.map((item: any) => {
      const o = { ...item };
      if (result && result[item.exceptionId]) {
        o.waivers = result[item.exceptionId];
      }
      return o;
    });
  return orgVal || [];
};

export function ExceptionsAndEnquiries() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filterButtonRef = useRef(null);
  const isLatUser = isLATUser();

  const { loanId, loanStage } = useParams<{
    loanId: string;
    loanStage: LoanStage;
  }>() as any;

  const {
    disableCondtReset,
    visiblitySelected,
    satisfiedSelected,
    waiverSelected
  } = useSelector<RootState, any>((state) => state.exceptionsResultsStore);

  const { exceptions } = useSelector<RootState, any>(
    (state) => state.exceptionsResultsStore.exceptionsResult
  );
  const waiversResult = useSelector<RootState, any>(
    (state) => state.exceptionsResultsStore?.waiverResult?.waivers
  );

  const [disableApplyFilter, setDisableApplyFilter] = useState<boolean>(true);

  const defaultCondttoggleState: CondFtilterSection = {
    visiblity: false,
    waiver: false,
    satisfied: false
  };
  const [filterSectionToggle, setFilterSectionToggle] =
    useState<CondFtilterSection>(defaultCondttoggleState);

  const [filterOptions, setFilterOptions] = useState<FilterOptionProps>({
    visiblity: defaultVisiblityState,
    waiver: defaultWaiverState,
    satisfied: defaultSatisfiedState
  });

  useEffect(() => {
    if (exceptions) {
      const org = mapWaiverToExceptions(exceptions, waiversResult);
      let filteredArr = org;
      if (waiverSelected.length) {
        let filteredExceptionss = filteredArr.map((item: any) => {
          let condtState = false;
          item?.waivers?.some((waiver: any) => {
            if (waiverSelected.includes(waiver.waiverInfo.finalResponse)) {
              condtState = true;
            }
          });
          if (condtState) return item.exceptionId;
          return null;
        });

        filteredExceptionss =
          filteredExceptionss?.filter((item: any) => {
            return item !== undefined || item !== null;
          }) ?? [];
        if (filteredExceptionss.length) {
          const arr = filteredArr.filter((item: any) => {
            return filteredExceptionss.includes(item.exceptionId);
          });
          filteredArr = arr;
        } else filteredArr = [];
      }
      if (visiblitySelected.length) {
        filteredArr =
          filteredArr?.filter((item: any) => {
            return visiblitySelected.includes(item.visibility);
          }) ?? [];
      }
      if (satisfiedSelected.length) {
        if (
          satisfiedSelected.includes("Satisfied Condition") &&
          !satisfiedSelected.includes("Satisfied Inquiry")
        ) {
          filteredArr =
            filteredArr?.filter((item: any) => {
              return (
                item.type.toLowerCase() === "exception" &&
                item.status.toLowerCase() === "satisfied"
              );
            }) ?? [];
        } else if (
          !satisfiedSelected.includes("Satisfied Condition") &&
          satisfiedSelected.includes("Satisfied Inquiry")
        ) {
          filteredArr =
            filteredArr?.filter((item: any) => {
              return (
                item.type.toLowerCase() === "inquiry" &&
                item.status.toLowerCase() === "satisfied"
              );
            }) ?? [];
        } else if (
          satisfiedSelected.includes("Satisfied Condition") &&
          satisfiedSelected.includes("Satisfied Inquiry")
        ) {
          filteredArr =
            filteredArr?.filter((item: any) => {
              return (
                (item.type.toLowerCase() === "inquiry" ||
                  item.type.toLowerCase() === "exception") &&
                item.status.toLowerCase() === "satisfied"
              );
            }) ?? [];
        }
      }
      dispatch(setFilteredException(filteredArr));
    }
  }, [
    waiverSelected,
    visiblitySelected,
    satisfiedSelected,
    exceptions,
    waiversResult
  ]);

  useEffect(() => {
    dispatch(getExceptionsAndEnquiriesByLoanId(loanId, loanStage));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visiblitySelected, satisfiedSelected]);

  useEffect(() => {
    if (
      waiverSelected.length ||
      satisfiedSelected.length ||
      visiblitySelected.length
    ) {
      dispatch(disableCondtResetFilter(false));
    } else {
      dispatch(disableCondtResetFilter(true));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visiblitySelected, satisfiedSelected, waiverSelected]);

  useEffect(() => {
    dispatch(resetExceptionStore());
  }, [dispatch]);

  const onCheckItem = (
    checkBoxEle: HTMLInputElement,
    checked: boolean,
    grpType: string
  ) => {
    if (disableApplyFilter) setDisableApplyFilter(false);
    const { value } = checkBoxEle;
    if (value === "Select All") {
      setFilterOptions((preVal: any) => {
        return {
          ...preVal,
          [grpType]: preVal[grpType].map((item: any) => {
            return {
              ...item,
              checked
            };
          })
        };
      });
    } else {
      setFilterOptions((preVal: any) => {
        const newArray = preVal[grpType].filter(
          (item: any) => item.label !== "Select All" && item.checked
        );
        const len = newArray.length + 1;

        const checkedAll =
          checked &&
          preVal[grpType].filter((item: any) => item.label !== "Select All")
            .length === len;

        return {
          ...preVal,
          [grpType]: preVal[grpType].map((item: any) => {
            if (checkedAll && item.label === "Select All") {
              return {
                ...item,
                checked: true
              };
            }
            if (!checkedAll && item.label === "Select All") {
              return {
                ...item,
                checked: false
              };
            }

            if (item.value === value) {
              return {
                ...item,
                checked: !item.checked
              };
            }
            return item;
          })
        };
      });
    }
  };

  const checkedReducerUpdate = () => {
    const visiblitySelected: string[] = filterOptions.visiblity
      .filter((itm: any) => itm.value && itm.checked)
      .map((itm: any) => itm.value);
    const waiverSelected: string[] = filterOptions.waiver
      .filter((itm: any) => (itm.value || itm.value === "") && itm.checked)
      .map((itm: any) => itm.value);
    const satisfiedSelected: string[] = filterOptions.satisfied
      .filter((itm: any) => itm.value && itm.checked)
      .map((itm: any) => itm.value);

    dispatch(
      updateConditionsFilters({
        visiblitySelected,
        waiverSelected,
        satisfiedSelected
      })
    );
  };

  const applyDBFilter = () => {
    setDisableApplyFilter(true);
    checkedReducerUpdate();
  };

  const clearAppliedFilter = () => {
    setFilterOptions({
      visiblity: defaultVisiblityState,
      waiver: defaultWaiverState,
      satisfied: defaultSatisfiedState
    });

    dispatch(
      updateConditionsFilters({
        visiblitySelected: [],
        waiverSelected: [],
        satisfiedSelected: []
      })
    );
  };

  const toggleSectionClick = (sectionHeader: string) => {
    if (sectionHeader === VisiblityHeader) {
      setFilterSectionToggle((preVal: CondFtilterSection) => {
        return {
          ...preVal,
          visiblity: !preVal.visiblity
        };
      });
    } else if (sectionHeader === WaiverHeader) {
      setFilterSectionToggle((preVal: CondFtilterSection) => {
        return {
          ...preVal,
          waiver: !preVal.waiver
        };
      });
    } else if (sectionHeader === SatisfiedHeader) {
      setFilterSectionToggle((preVal: CondFtilterSection) => {
        return {
          ...preVal,
          satisfied: !preVal.satisfied
        };
      });
    }
  };

  const filterGroupItems = [
    ...(isLatUser
      ? [
          {
            sectionHeader: VisiblityHeader,
            toggleSection: toggleSectionClick,
            open: filterSectionToggle.visiblity,
            checklistItems: filterOptions.visiblity,
            onCheck: onCheckItem,
            onCheckArgs: [CondtFilterGroupType.visiblity],
            toggelBtnDataTestId: "loan-type-fltr-toggle-btn",
            itemListDataTestId: "loan-type-fltr-items-bolck"
          }
        ]
      : []),
    {
      sectionHeader: WaiverHeader,
      toggleSection: toggleSectionClick,
      open: filterSectionToggle.waiver,
      checklistItems: filterOptions.waiver,
      onCheck: onCheckItem,
      onCheckArgs: [CondtFilterGroupType.waiver],
      toggelBtnDataTestId: "org-fltr-toggle-btn",
      itemListDataTestId: "org-fltr-items-bolck"
    },
    {
      sectionHeader: SatisfiedHeader,
      toggleSection: toggleSectionClick,
      open: filterSectionToggle.satisfied,
      checklistItems: filterOptions.satisfied,
      onCheck: onCheckItem,
      onCheckArgs: [CondtFilterGroupType.satisfied],
      toggelBtnDataTestId: "loan-stage-fltr-toggle-btn",
      itemListDataTestId: "loan-stage-fltr-items-bolck"
    }
  ];

  return (
    <div
      style={{ padding: "24px 24px 60px 24px", borderRadius: "4px" }}
      data-testid="exceptions-enquiries-tabs-panel"
      className={classes.conditionInquiryMobileView}
    >
      <Container style={{ maxWidth: 1280 }}>
        <Grid container className={classes.wrapper} sm={12}>
          <Grid sm={2}>
            <Paper
              style={{
                boxShadow: "0 2px 8px rgb(0 0 0 / 15%)",
                overflowY: "auto",
                overflowX: "hidden"
              }}
            >
              <span
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  ref={filterButtonRef}
                  startIcon={<img src={filterIcon} alt="filter" />}
                  className={classes.filterButtonFullSC}
                  data-testid="condt-filter-toggle-button"
                >
                  Filters
                </Button>
                {disableCondtReset ? null : (
                  <Button
                    className={classes.clearButtonFullScreen}
                    startIcon={<RefreshIcon />}
                    disableRipple
                    disableElevation
                    onClick={clearAppliedFilter}
                    data-testid="db-clear-filter-btn"
                  >
                    Clear Filter
                  </Button>
                )}
              </span>
              <CheckListGroupFilter
                filterGroupItems={filterGroupItems}
                filterBlockDataTestId="db-filter-block"
                filterOutterContainer={classes.filterOutterContainerFullScreen}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  padding: "20px"
                }}
              >
                <Button
                  variant="contained"
                  color="info"
                  data-testid="apply-db-filter-button"
                  disabled={disableApplyFilter}
                  onClick={applyDBFilter}
                >
                  Apply
                </Button>
              </div>
            </Paper>
          </Grid>
          <Grid sm={10}>
            <ExceptionsTabsPanel />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
