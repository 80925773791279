import React from "react";
import {
  DropDown,
  TextFieldForm,
  GridDatePickerForm
} from "@toorak/tc-common-fe-sdk";
import {
  TextFieldProps,
  DropDownProps,
  DatePickerProps
} from "./FormComponentsProps";
import { CustomizedTextField } from "../../utils/customizedTextField";

export function PropertyTypeDropDown(props: DropDownProps) {
  const {
    selectionValue,
    selectionOnChange,
    selectionData,
    helperText,
    isViewMode,
    onBlur,
    editedLabel,
    editedData,
    isLATView,
    infoIconData,
    enableReason,
    isServicerMandatory
  } = props;
  return (
    <DropDown
      formControlTestId="property-type"
      inputLabelId="property-type-label"
      inputLabelTestId="property-type-label-testid"
      label="Property Type"
      selectId="property-type-selectid"
      selectTestId="property-type-select-testid"
      selectionValue={
        isViewMode && selectionValue === "SFR"
          ? "Single Family Detached"
          : selectionValue
      }
      editedData={editedData}
      selectionOnChange={selectionOnChange}
      selectionData={selectionData}
      isViewMode={isViewMode}
      helperText={helperText}
      valueTestId="property-type-value"
      editedLabel={editedLabel}
      onBlur={onBlur}
      isLATView={isLATView}
      infoIconData={infoIconData}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
}

export function ChangeInUseCaseDropDown(props: DropDownProps) {
  const {
    selectionValue,
    selectionOnChange,
    selectionData,
    helperText,
    editedData,
    isViewMode,
    onBlur,
    infoIconData,
    editedLabel,
    notRequired,
    isLATView,
    enableReason,
    isServicerMandatory
  } = props;
  return (
    <DropDown
      formControlTestId="change-in-use-case"
      inputLabelId="change-in-use-case-label"
      inputLabelTestId="change-in-use-case-label-testid"
      label="Expansion (>20%/+750 sq.ft) or Change Of Use Case"
      selectId="change-in-use-case-selectid"
      selectTestId="change-in-use-case-select-testid"
      selectionValue={selectionValue}
      selectionOnChange={selectionOnChange}
      editedData={editedData}
      selectionData={selectionData}
      isViewMode={isViewMode}
      helperText={helperText}
      valueTestId="change-in-use-case-value"
      editedLabel={editedLabel}
      onBlur={onBlur}
      infoIconData={infoIconData}
      notRequired={notRequired}
      isLATView={isLATView}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
}

export function NoOfUnitsTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    infoIconData,
    isViewMode,
    editedData,
    editedLabel,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      value={value}
      isViewMode={isViewMode}
      onChange={onChange}
      label="Number Of Units"
      onBlur={onBlur}
      infoIconData={infoIconData}
      editedData={editedData}
      error={error}
      helperText={helperText}
      data-testid="no-of-units-id"
      valueTestId="no-of-units-id-value"
      editedLabel={editedLabel}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
}

export function PreRehabSquareFootageTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    infoIconData,
    onBlur,
    isViewMode,
    editedData,
    editedLabel,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      value={value}
      isViewMode={isViewMode}
      onChange={onChange}
      label="Pre-Rehab Square Footage"
      error={error}
      onBlur={onBlur}
      infoIconData={infoIconData}
      editedData={editedData}
      helperText={helperText}
      data-testid="pre-rehab-sq-footage"
      valueTestId="pre-rehab-sq-footage-value"
      editedLabel={editedLabel}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
}

export function PostRehabSquareFootageTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    infoIconData,
    onBlur,
    isViewMode,
    editedData,
    editedLabel,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      value={value}
      isViewMode={isViewMode}
      onChange={onChange}
      label="Post-Rehab Square Footage"
      error={error}
      infoIconData={infoIconData}
      editedData={editedData}
      helperText={helperText}
      onBlur={onBlur}
      data-testid="post-rehab-sq-footage"
      valueTestId="post-rehab-sq-footage-value"
      editedLabel={editedLabel}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
}

export function SquareFootageTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    infoIconData,
    onBlur,
    editedLabel,
    editedData,
    isViewMode,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      value={value}
      isViewMode={isViewMode}
      onChange={onChange}
      editedLabel={editedLabel}
      label="Square Footage"
      infoIconData={infoIconData}
      editedData={editedData}
      error={error}
      onBlur={onBlur}
      helperText={helperText}
      data-testid="sq-footage"
      valueTestId="sq-footage-value"
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
}

export function AppraisalReportedTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    isViewMode,
    infoIconData,
    editedData,
    onBlur,
    editedLabel,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      value={value}
      isViewMode={isViewMode}
      onChange={onChange}
      label="Appraisal Reported NOI/NCF ($)"
      error={error}
      editedData={editedData}
      onBlur={onBlur}
      infoIconData={infoIconData}
      helperText={helperText}
      data-testid="appraisal-reported"
      valueTestId="appraisal-reported-value"
      editedLabel={editedLabel}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
}

export function PercentageOccupiedField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    editedData,
    onBlur,
    isViewMode,
    infoIconData,
    editedLabel,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      value={value}
      isViewMode={isViewMode}
      onChange={onChange}
      label="% Occupied"
      error={error}
      editedData={editedData}
      helperText={helperText}
      data-testid="percentage-occupied"
      valueTestId="percentage-occupied-value"
      onBlur={onBlur}
      infoIconData={infoIconData}
      editedLabel={editedLabel}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
}

export function PropertyValuationDateTextField(props: DatePickerProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    editedData,
    infoIconData,
    isViewMode,
    editedLabel,
    enableReason,
    isServicerMandatory
  } = props;
  return (
    <GridDatePickerForm
      value={value as any}
      onChange={onChange as any}
      isViewMode={isViewMode}
      required
      label="Most Recent Property Valuation Date"
      error={error}
      onBlur={onBlur}
      infoIconData={infoIconData}
      editedData={editedData}
      helperText={helperText}
      // valueTestId="property-valuation-date-value"
      // data-testid="property-valuation-date"
      editedLabel={editedLabel}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
      renderInput={(props: any) => (
        <CustomizedTextField
          props={props}
          data-testid="property-valuation-date"
          value={value}
          error={error}
          helperText={helperText}
          isViewMode={isViewMode}
          valueTestId="property-valuation-date-value"
        />
      )}
    />
  );
}

// export function PropertyAcquisitionDateTextField(props: DatePickerProps) {
//   const {
//     value,
//     onChange,
//     error,
//     helperText,
//     isViewMode,
//     editedLabel,
//     required,
//   } = props;
//   return (
//     <DatePickerForm
//       value={isViewMode ? `${formattedDate}` : value}
//       isViewMode={isViewMode}
//       onChange={onChange}
//       required={required}
//       label="Property Acquisition Date"
//       error={error}
//       helperText={helperText}
//       valueTestId="property-acquisition-date-value"
//       data-testid="property-acquisition-date"
//       editedLabel={editedLabel}
//     />
//   );
// }

export function PropertyAcquisitionDateTextField(props: DatePickerProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    infoIconData,
    editedData,
    isViewMode,
    editedLabel,
    enableReason,
    isServicerMandatory
  } = props;
  return (
    <GridDatePickerForm
      value={value as any}
      onChange={onChange as any}
      onBlur={onBlur}
      editedData={editedData}
      label="Original Date of Acquisition"
      error={error}
      infoIconData={infoIconData}
      required
      isViewMode={isViewMode}
      helperText={helperText}
      isServicerMandatory={isServicerMandatory}
      // valueTestId="property-acquisition-date-value"
      // data-testid="property-acquisition-date"
      editedLabel={editedLabel}
      enableReason={enableReason}
      renderInput={(props: any) => (
        <CustomizedTextField
          props={props}
          data-testid="property-acquisition-date"
          value={value}
          error={error}
          helperText={helperText}
          isViewMode={isViewMode}
          valueTestId="property-acquisition-date-value"
        />
      )}
    />
  );
}

export function RentalDescriptionDropDown(props: DropDownProps) {
  const {
    selectionValue,
    selectionOnChange,
    selectionData,
    helperText,
    isViewMode
  } = props;
  return (
    <DropDown
      formControlTestId="rental-description"
      inputLabelId="rental-description-label"
      inputLabelTestId="rental-description-label-testid"
      label="Rent Description"
      selectId="rental-description-selectid"
      selectTestId="rental-description-select-testid"
      selectionValue={selectionValue}
      selectionOnChange={selectionOnChange}
      selectionData={selectionData}
      isViewMode={isViewMode}
      helperText={helperText}
      valueTestId="rental-description-value"
      // className={className}
    />
  );
}
export function MarketRentField(props: TextFieldProps) {
  const { value, onChange, error, helperText, isViewMode, onBlur } = props;
  return (
    <TextFieldForm
      value={value}
      onChange={onChange}
      isViewMode={isViewMode}
      label="Annual Market Rent ($)"
      error={error}
      helperText={helperText}
      data-testid="market-rent"
      valueTestId="market-rent-value"
      onBlur={onBlur}
    />
  );
}
export function PropertyUnit3rdFiled(
  props: TextFieldProps & { label: string }
) {
  const { value, onChange, error, helperText, isViewMode, onBlur, label } =
    props;

  return (
    <TextFieldForm
      value={value}
      onChange={onChange}
      isViewMode={isViewMode}
      label={label}
      error={error}
      helperText={helperText}
      data-testid="PropertyUnit3rdFiled"
      valueTestId="PropertyUnit3rdFiled-value"
      onBlur={onBlur}
    />
  );
}
