/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Checkbox, Chip, Grid, InputAdornment, TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { autoCompleteSearchBoxFilterStyles } from "./filterStyles";
import { RootState } from "../../../../stores/rootReducer";
import { setFiltersPandS } from "../../../../stores/SettlementAndPurchase/SettlementAndPurchase.reducer";

const icon = <CheckBoxOutlineBlankIcon fontSize="large" />;
const checkedIcon = <CheckBoxIcon fontSize="large" />;

export const AutoCompleteSearchBoxFilter = (props: any) => {
  const { data, filterOptions } = props;
  const { value, fieldKey } = data;
  const filterOptionsVals: any = Object.values(filterOptions || {});
  let options: any[] = [];
  if (filterOptionsVals.length && filterOptionsVals[0].buckets)
    options = filterOptionsVals[0].buckets.map((itm: any) => itm.key);

  const classes = autoCompleteSearchBoxFilterStyles();
  const [selectedValue, setValue] = React.useState<any[]>([]);
  const [filterInitiated, setFilterInitiated] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const filterObj = useSelector<RootState, any>(
    (state) => state.spStore.filterObj
  );

  useEffect(() => {
    if (!filterInitiated) return;
    const fKey: string = String(fieldKey).replace(/\?/g, "");
    if (selectedValue.length) {
      dispatch(
        setFiltersPandS({
          ...filterObj,
          filterInitiated: true,
          [fKey]: selectedValue
        })
      );
    } else {
      const newFobj: any = {};
      Object.keys(filterObj).forEach((fObjKeys: string) => {
        if (fObjKeys !== fKey) newFobj[fObjKeys] = filterObj[fObjKeys];
      });
      newFobj.filterInitiated = true;
      dispatch(setFiltersPandS(newFobj));
    }
    setFilterInitiated(false);
  }, [filterInitiated, selectedValue]);

  const onDeleteChip = (index: number) => {
    setValue(selectedValue.filter((_x: any, indx: number) => indx !== index));
    setTimeout(() => {
      setFilterInitiated(true);
    }, 0);
  };

  return (
    <div className={classes.root}>
      {selectedValue.length ? (
        <>
          <div className={`${classes.header} ${classes.borderPadding}`}>
            {`Selected ${value}`}
          </div>
          <div
            className={`${classes.selectedOptions} ${classes.borderPadding}`}
          >
            <Grid container>
              {selectedValue.map((item: any, index: number) => {
                return (
                  <Grid item style={{ margin: "0px 2px 2px 0px" }}>
                    <Chip
                      label={item}
                      onDelete={() => {
                        onDeleteChip(index);
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </>
      ) : null}
      <div className={classes.inputbody}>
        <Autocomplete
          multiple
          id="checkboxes-tags-demo"
          value={selectedValue}
          onChange={(event: any, newValue: any[]) => {
            setValue(newValue);
            setTimeout(() => {
              setFilterInitiated(true);
            }, 0);
          }}
          className={classes.autoComplete}
          options={options}
          disableCloseOnSelect
          disableClearable
          getOptionLabel={(option: any) => option ?? ""}
          forcePopupIcon={false}
          renderOption={(props, option, { selected }) => {
            return (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            );
          }}
          style={{ width: "100%" }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                variant="outlined"
                // variant="standard"
                placeholder="Type Here"
                InputProps={{
                  ...params.InputProps,
                  className: `${classes.onHoverInputStyles}`,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                  )
                }}
              />
            );
          }}
        />
      </div>
    </div>
  );
};
