/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import { LoanStage, publicClient } from "@toorak/tc-common-fe-sdk";
// import { push } from "connected-react-router";
import {
  ExcelUploadStoreProps,
  excelUploadLoading,
  getExcelLoanSuccess,
  resetExcelUploadReducer,
  saveLoanWhileChanging,
  toggleRerenderLoanIdList,
  updateLoanError
} from "./excel-upload.reducer";
import { RootState } from "../rootReducer";
import { LoanIdListInSession } from "../../create-loan/excel-create-loan/LoanIdList";
import { getConfig } from "../../config/config";
import {
  hideLoader,
  showLoader
} from "../loaderAndException/loaderAndException.action";
import { getExcelDataInSession } from "../../utils/AccessManagement";
import { setCookie } from "../../utils/cookies";
import { PreSubmitStates } from "../../utils/constants";
// import { showPopUpMessages } from "../loanAndPropertyDetails/loanAndPropertyDetails.action";
// import {
//   MessageBody,
//   MessageHeader
// } from "../../create-loan/createLoanHeaders/InfoPopUp";
import { resetAllStores } from "../../create-loan/create-loan.action";
// import { ObjectType } from "../../masterView/common";
// import { getTasks, taskNames } from "../tasks/Tasks.action";

// export const EXCEL_UPLOAD_LOADING = "EXCEL_UPLOAD_LOADING";
// export const GET_EXCEL_LOAN_SUCCESS = "GET_EXCEL_LOAN_SUCCESS";
// export const SAVE_LOAN_WHILE_CHANGING = "SAVE_LOAN_WHILE_CHANGING";
// export const UPDATE_LOAN_ERROR = "UPDATE_LOAN_ERROR";
// export const UPDATE_SUBMIT_STATUS = "UPDATE_SUBMIT_STATUS";
// export const CHANGE_SUBMIT_STATUS = "CHANGE_SUBMIT_STATUS";
// export const RESET_EXCEL_STORE = "RESET_EXCEL_STORE";
// export const TOGGLE_DELETE_LOAN = "TOGGLE_DELETE_LOAN";
// export const SET_EXCEL_DATA = "SET_EXCEL_DATA";
// export const TOGGLE_RERENDER_LOAN_ID_LIST = "TOGGLE_RERENDER_LOAN_ID_LIST";
/**
 * this function is used to convert the loan response we get in excel upload flow.
 * pass the array of loan objects to this function and it checks for field error and converts the data to required redux format.
 * store this in the excel-upload store by dispatching GET_EXCEL_LOAN_SUCCESS
 *
 * @param loanId optional parameter to be passed if the data is for particular loan
 */
export function getLoanDetailsForAllLoans(loanId?: any[]) {
  return async (dispatch: any) => {
    // dispatch({
    //   type: EXCEL_UPLOAD_LOADING,
    //   payload: {
    //     showLoader: true
    //   }
    // });
    dispatch(excelUploadLoading({ showLoader: true }));
    const dataToStore: ExcelUploadStoreProps = {
      loading: false,
      data: {}
    };
    dispatch(getExcelLoanSuccess({ data: dataToStore }));
    // dispatch({
    //   type: GET_EXCEL_LOAN_SUCCESS,
    //   payload: {
    //     data: dataToStore
    //   }
    // });
  };
}
/**
 * This function will update all the data of a particular loan to excel-upload-reducer
 * createLoanstore,waiver store, evaluation store etc are all copied into excel-upload-reducer under a loanId:{data} format
 * @param loanId the loan id which was displayed when user tried to change loans
 * @param storeValue values available in the root reducer
 */
export function updateLoanValues(loanId: string, storeValue: RootState): any {
  return async (dispatch: any) => {
    const storeData = {
      createLoanStore: storeValue.createLoanStore,
      evaluationResultsStore: storeValue.evaluationResultsStore,
      exceptionsResultsStore: storeValue.exceptionsResultsStore,
      wrStore: storeValue.wrStore
    };
    dispatch(
      saveLoanWhileChanging({
        loanId,
        storeData
      })
    );
  };
}
export function updateLoanErrors(
  loanId: string,
  errors: {
    loanLevelErrors: number;
    propertyLevelErrors: number;
    // documentLevelErrors: number;
  }
): any {
  return async (dispatch: any) => {
    dispatch(
      updateLoanError({
        loanId,
        errors
      })
    );
  };
}

export function submitMultipleLoans(
  loanData: LoanIdListInSession,
  loanType: string,
  loanStage: LoanStage,
  originatorId: string | null | any,
  callback: (firstLoanId: string) => void
): any {
  return async (dispatch: any) => {
    dispatch(showLoader(true));
    const requestBody = Object.keys(loanData.loanIdList).map((loanId) => {
      return {
        loanId,
        loanState: LoanStatusEnum.DataEntry,
        loanStage,
        originatorId,
        loanType
      };
    });
    const exceldata = getExcelDataInSession();
    const url = `${getConfig().apiUrl}/aggregate/v2/loans/status`;
    publicClient
      .put(url, requestBody)
      .then((response: any) => {
        let updatedSessionObj = {};
        const stateToLoanId: any = [[], [], []];
        dispatch(resetAllStores());
        dispatch(resetExcelUploadReducer());
        response.data.forEach((item: any) => {
          const { currentState, loanId, cause } = item;
          const hasMissingDocs = cause.includes("No documents uploaded");
          const hasFailingTests = cause.includes("Has failed tests");

          if (
            [...PreSubmitStates, LoanStatusEnum.Submit].includes(currentState)
          ) {
            stateToLoanId[0].push(loanId);
          } else if (currentState === LoanStatusEnum.InitialReview) {
            stateToLoanId[1].push(loanId);
          } else {
            stateToLoanId[2].push(loanId);
          }
          updatedSessionObj = {
            ...updatedSessionObj,
            ...{
              [loanId]: {
                loanId,
                excelError: false,
                loanStage: currentState,
                rulesExecuted: true,
                missingDocs: hasMissingDocs || false,
                failedTests: hasFailingTests || false,
                primaryLoanId: exceldata?.loanIdList[loanId]?.primaryLoanId
              }
            }
          };
          // getTasks(
          //   dispatch,
          //   {
          //     resourceTypes: ["LOAN"],
          //     resourceIds: [loanId],
          //     taskNames: [
          //       taskNames.TAG,
          //       taskNames.SPLIT,
          //       taskNames.REVIEW,
          //       taskNames.RECONCILE,
          //       taskNames.BUDGET_REVIEW,
          //       taskNames.BUDGET_APPROVE
          //     ]
          //   }
          // );
        });
        sessionStorage.setItem(
          "excelUpload",
          JSON.stringify({
            ...exceldata,
            ...{ loanIdList: updatedSessionObj },
            ...{ isSubmitted: true, submitClicked: true }
          })
        );
        setCookie("excelSubmitPressed", true);
        const firstLoanId =
          stateToLoanId[0][0] || stateToLoanId[1][0] || stateToLoanId[2][0];
        callback(firstLoanId);
        // dispatch({
        //   type: UPDATE_SUBMIT_STATUS
        // });
        // // Show info message during submission as per LPP-7161
        // if (loanType === getLoanType[1].displayValue) {
        //   dispatch(
        //     showMessage({
        //       message: MessageBody(lockinValues),
        //       header: MessageHeader()
        //     })
        //   );
        // }
        dispatch(hideLoader(true));
      })
      .catch((e) => {
        dispatch(hideLoader(true));
        console.log(e);
      });
  };
}

// export function changeSubmitStatusInReducer(): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: CHANGE_SUBMIT_STATUS
//     });
//   };
// }

// export function setDeleteLoan(): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: TOGGLE_DELETE_LOAN
//     });
//   };
// }
// export function setRerenderLoanIdList(value: boolean): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: TOGGLE_RERENDER_LOAN_ID_LIST,
//       payload: { value }
//     });
//   };
// }
// export function resetExcelUploadReducer(): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: RESET_EXCEL_STORE
//     });
//   };
// }

// export function setExcelUploadData(data: any): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: SET_EXCEL_DATA,
//       payload: data
//     });
//   };
// }

export const updatePrimaryLoanIdInSession = (
  loanId: string,
  primaryLoanId: string
): any => {
  return async (dispatch: any) => {
    const exceldata = getExcelDataInSession();
    if (exceldata?.maintainExcelBundle) {
      const obj = {
        ...exceldata.loanIdList[loanId],
        ...{
          primaryLoanId:
            typeof primaryLoanId === "string"
              ? primaryLoanId.trim()
              : primaryLoanId
        }
      };
      const newLoanIdListObj = {
        ...exceldata.loanIdList,
        ...{ [loanId]: obj }
      };
      const updatedExcelData = {
        ...exceldata,
        ...{ loanIdList: newLoanIdListObj }
      };
      sessionStorage.setItem("excelUpload", JSON.stringify(updatedExcelData));
      dispatch(toggleRerenderLoanIdList(true));
    }
  };
};
