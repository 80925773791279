import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    badgeFilter: {
      height: "6px !important",
      width: "6px !important",
      backgroundColor: "#5566d6",
      zIndex: "auto",
      minWidth: "6px"
    },
    menuRoot: {
      width: 200,
      boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.16)"
    },
    menuList: {
      padding: 0,
      overflow: "auto",
      maxHeight: "250px"
    },
    dialogDeleteButton: {
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: 1.83,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#ffffff",
      // width: "89px",
      textTransform: "none",
      height: "36px",
      borderRadius: "4px",
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      "&:hover": {
        backgroundColor: "#5566d6 !important"
      }
    },
    dialogCancelBtn: {
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      backgroundColor: "#ffffff",
      border: "solid 1px #ececec",
      color: "#32325d",
      "&:hover": {
        backgroundColor: "#ffffff !important"
      }
    },
    divButtonPlacement: {
      padding: "0px 20px 20px 20px"
    },
    root: {
      justifyContent: "space-around",
      alignItems: "center",
      marginBottom: 14,
      marginTop: 20,
      boxShadow: "0 2px 8px 0 rgb(0 0 0 / 16%)"
    }
  })
);
