/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { getLoanType } from "../../config/config";
import { LoanAndPropertyReducerType } from "../../stores/loanAndPropertyDetails/loanAndPropertyDetails.reducer";
import {
  formatValueByType,
  formatValueForLoanAndPropertyApi,
  isEmptyValue,
  sanitizePercentage,
  sanitizeCurrency,
  cleanCurrency,
  sanitizeCurrencyWithMinus
} from "../../utils/formatChecks";
import { formatDate, getTwoDecimalPercentageValue } from "../../utils/utils";

export interface ConditionalHideData {
  loanType?: string;
  reduxData?: LoanAndPropertyReducerType;
}

export enum CreditEvalComponentType {
  creditEvalNormal = "creditEvalNormal", // used for most of the generic sections like loan info, dscr centric fields etc
  creditEvalProperty = "creditEvalProperty",
  creditThirdParty = "creditThirdParty",
  borrowerGuarantorSec = "borrowerGuarantorSec",
  dscrCentricFields = "dscrCentricFields"
}
export interface InputValueSectionType {
  sectionLabel: string;
  sectionId: string;
  sectionName: string; // make sure this is same as the back end section name
  componentType: CreditEvalComponentType;
  fields: CreditEvalFieldType[];
  isHidden?: any;
}

export interface CreditEvalFieldType {
  fieldLabel: string;
  fieldId: string; // should be same as reducer and loan service key
  fieldType: string;
  dropDownValues?: any;
  userType?: string;
  allowedLoanType?: string[]; // include the loan type here only when there is conditional display based on loan type
  getValueFuntion?: any;
  getTagValue?: any;
  selectionData?: any;
  isUnitLevel?: boolean;
}

export const propertyInformationFields: CreditEvalFieldType[] = [
  {
    fieldLabel: "Description",
    fieldId: "description",
    fieldType: "description"
  }
];
export const propertyInformationFinalValueFields: CreditEvalFieldType[] = [
  {
    fieldLabel: "Description",
    fieldId: "description",
    fieldType: "description"
  },
  {
    fieldLabel: "Property Type",
    fieldId: "propertyType",
    fieldType: "dropDown"
  },
  {
    fieldLabel: "Units",
    fieldId: "propertyUnits",
    fieldType: "number"
  },
  {
    fieldLabel: "Square Feet",
    fieldId: "preRehabSquareFootage",
    fieldType: "number",
    allowedLoanType: [getLoanType[0].displayValue]
  },
  {
    fieldLabel: "Square Feet",
    fieldId: "squareFootage",
    fieldType: "number",
    allowedLoanType: [getLoanType[1].displayValue]
  },
  {
    fieldLabel: "SF/Unit",
    fieldId: "sfUnit",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      const { evaluationResults, propertyId } = data;
      try {
        if (
          evaluationResults?.propertiesResults &&
          evaluationResults.propertiesResults[propertyId]
        ) {
          return evaluationResults.propertiesResults[propertyId]
            .creditEvaluation?.sfPerUnit;
        }
        return null;
      } catch (error) {
        return null;
      }
    }
  }
];

export const leverageGuidelinesFinalValue: CreditEvalFieldType[] = [
  {
    fieldLabel: "Toorak",
    fieldId: "toorakSubSection",
    fieldType: "subSectionLabel"
  },
  {
    fieldLabel: "ARV LTV",
    fieldId: "arvLtv",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      const { evaluationResults, loanId, loanType } = data;
      try {
        const val =
          loanType === getLoanType[0].displayValue
            ? evaluationResults?.loanResult &&
              evaluationResults?.loanResult[loanId].maxLeverageTest?.maxLTVARV
            : evaluationResults?.loanResult &&
              evaluationResults?.loanResult[loanId].maxLeverage?.maxLTVARV;
        const formattedValue = formatValueByType(val, "percentage");
        return formattedValue;
      } catch (error) {
        return null;
      }
    }
  },
  {
    fieldLabel: "As-Is LTV",
    fieldId: "asIsLtv",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      const { evaluationResults, loanId, loanType } = data;
      try {
        const val =
          loanType === getLoanType[0].displayValue
            ? evaluationResults?.loanResult &&
              evaluationResults?.loanResult[loanId].maxLeverageTest?.maxLTVAsIs
            : evaluationResults?.loanResult &&
              evaluationResults?.loanResult[loanId].maxLeverage?.maxAsIsLTV;
        const formattedValue = formatValueByType(val, "percentage");
        return formattedValue;
      } catch (error) {
        return null;
      }
    }
  },
  {
    fieldLabel: "LTC",
    fieldId: "ltc",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      const { evaluationResults, loanId, loanType } = data;
      try {
        const val =
          loanType === getLoanType[0].displayValue
            ? evaluationResults?.loanResult &&
              evaluationResults?.loanResult[loanId].maxLeverageTest?.maxLTCOne
            : evaluationResults?.loanResult &&
              evaluationResults?.loanResult[loanId].maxLeverage?.maxLTC;
        const formattedValue = formatValueByType(val, "percentage");
        return formattedValue;
      } catch (error) {
        return null;
      }
    }
  },
  {
    fieldLabel: "Profitability",
    fieldId: "profitability",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      const { evaluationResults, loanId } = data;
      try {
        const val =
          evaluationResults?.loanResult &&
          evaluationResults?.loanResult[loanId].creditEvaluation
            ?.profitabilityPercentage;
        const formattedValue = formatValueByType(val, "percentage");
        return formattedValue;
      } catch (error) {
        return null;
      }
    }
  },
  {
    fieldLabel: "Rental Yield",
    fieldId: "rentalYield",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      const { evaluationResults, loanId } = data;
      try {
        const val =
          evaluationResults?.loanResult &&
          evaluationResults?.loanResult[loanId].creditEvaluation?.rentalYield;
        const formattedValue = formatValueByType(val, "percentage"); // doubt
        return formattedValue;
      } catch (error) {
        return null;
      }
    }
  },
  {
    fieldLabel: "Toorak DY (As Stabilized)",
    fieldId: "toorakDYAsStabilized",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      const { evaluationResults, loanId } = data;
      try {
        const val =
          evaluationResults?.loanResult &&
          evaluationResults?.loanResult[loanId].creditEvaluation
            ?.minDebtYeildRequirement;
        const formattedValue = formatValueByType(val, "percentage");
        return formattedValue;
      } catch (error) {
        return null;
      }
    }
  },
  {
    fieldLabel: "Borrower",
    fieldId: "borrowerSubSection",
    fieldType: "subSectionLabel"
  },
  {
    fieldLabel: "ARV LTV",
    fieldId: "arvLtv",
    fieldType: "formulaWithTag",
    getValueFuntion: (data: any) => {
      const { evaluationResults, loanId } = data;
      try {
        const val =
          evaluationResults?.loanResult &&
          evaluationResults?.loanResult[loanId].loanFeatures
            ?.originalAsRepairedLtv;
        const formattedValue = getTwoDecimalPercentageValue(
          formatValueByType(val, "percentage")
        );
        return formattedValue;
      } catch (error) {
        return null;
      }
    },
    getTagValue: (data: any) => {
      const { evaluationResults, loanId, loanType } = data;
      try {
        const original =
          evaluationResults?.loanResult &&
          evaluationResults?.loanResult[loanId].loanFeatures
            ?.originalAsRepairedLtv;
        const net =
          loanType === getLoanType[0].displayValue
            ? evaluationResults?.loanResult &&
              evaluationResults?.loanResult[loanId].maxLeverageTest?.maxLTVARV
            : evaluationResults?.loanResult &&
              evaluationResults?.loanResult[loanId].maxLeverage?.maxLTVARV;
        console.log(original, net);
        return compareOriginalVsAdjustedValues(original, net);
      } catch (error) {
        return null;
      }
    }
  },
  {
    fieldLabel: "As-Is LTV",
    fieldId: "asIsLtv",
    fieldType: "formulaWithTag",
    getValueFuntion: (data: any) => {
      const { evaluationResults, loanId } = data;
      try {
        const val =
          evaluationResults?.loanResult &&
          evaluationResults?.loanResult[loanId].loanFeatures?.originalAsIsLtv;
        const formattedValue = getTwoDecimalPercentageValue(
          formatValueByType(val, "percentage")
        );
        return formattedValue;
      } catch (error) {
        return null;
      }
    },
    getTagValue: (data: any) => {
      const { evaluationResults, loanId, loanType } = data;
      try {
        const original =
          evaluationResults?.loanResult &&
          evaluationResults?.loanResult[loanId].loanFeatures?.originalAsIsLtv;
        const net =
          loanType === getLoanType[0].displayValue
            ? evaluationResults?.loanResult &&
              evaluationResults?.loanResult[loanId].maxLeverageTest?.maxLTVAsIs
            : evaluationResults?.loanResult &&
              evaluationResults?.loanResult[loanId].maxLeverage?.maxAsIsLTV;
        return compareOriginalVsAdjustedValues(original, net);
      } catch (error) {
        return null;
      }
    }
  },
  {
    fieldLabel: "LTC",
    fieldId: "ltc",
    fieldType: "formulaWithTag",
    getValueFuntion: (data: any) => {
      const { evaluationResults, loanId } = data;
      try {
        const val =
          evaluationResults?.loanResult &&
          evaluationResults?.loanResult[loanId].loanFeatures?.originalLtc;
        const formattedValue = getTwoDecimalPercentageValue(
          formatValueByType(val, "percentage")
        );
        return formattedValue;
      } catch (error) {
        return null;
      }
    },
    getTagValue: (data: any) => {
      const { evaluationResults, loanId, loanType } = data;
      try {
        const original =
          evaluationResults?.loanResult &&
          evaluationResults?.loanResult[loanId].loanFeatures?.originalLtc;
        const net =
          loanType === getLoanType[0].displayValue
            ? evaluationResults?.loanResult &&
              evaluationResults?.loanResult[loanId].maxLeverageTest?.maxLTCOne
            : evaluationResults?.loanResult &&
              evaluationResults?.loanResult[loanId].maxLeverage?.maxLTC;
        return compareOriginalVsAdjustedValues(original, net);
      } catch (error) {
        return null;
      }
    }
  },
  {
    fieldLabel: "Profitability",
    fieldId: "profitability",
    fieldType: "formulaWithTag",
    getValueFuntion: (data: any) => {
      const { evaluationResults, loanId } = data;
      try {
        const val =
          evaluationResults?.loanResult &&
          evaluationResults?.loanResult[loanId].loanEconomics
            ?.profitabilityCalculation;
        const formattedValue = formatValueByType(val, "percentage");
        return formattedValue;
      } catch (error) {
        return null;
      }
    },
    getTagValue: (data: any) => {
      const { evaluationResults, loanId } = data;
      try {
        const constValue =
          evaluationResults?.loanResult &&
          evaluationResults?.loanResult[loanId].creditEvaluation
            ?.profitabilityPercentage;
        const calculated =
          evaluationResults?.loanResult &&
          evaluationResults?.loanResult[loanId].loanEconomics
            ?.profitabilityCalculation;
        return profitabilityRule(constValue, calculated);
      } catch (error) {
        return null;
      }
    }
  },
  {
    fieldLabel: "Rental Yield",
    fieldId: "rentalYield",
    fieldType: "formulaWithTag",
    getValueFuntion: (data: any) => {
      const { evaluationResults, loanId } = data;
      try {
        const val =
          evaluationResults?.loanResult &&
          evaluationResults?.loanResult[loanId].loanEconomics
            ?.rentYieldCalculation;
        const formattedValue = formatValueByType(val, "percentage"); // doubt
        return formattedValue;
      } catch (error) {
        return null;
      }
    },
    getTagValue: (data: any) => {
      const { evaluationResults, loanId } = data;
      try {
        const constValue =
          evaluationResults?.loanResult &&
          evaluationResults?.loanResult[loanId].creditEvaluation?.rentalYield;
        const calculated =
          evaluationResults?.loanResult &&
          evaluationResults?.loanResult[loanId].loanEconomics
            ?.rentYieldCalculation;
        return rentYieldRule(constValue, calculated);
      } catch (error) {
        return null;
      }
    }
  },
  {
    fieldLabel: "DY (As Complete)",
    fieldId: "dyAsComplete",
    fieldType: "formulaWithTag",
    getValueFuntion: (data: any) => {
      const { evaluationResults, loanId } = data;
      try {
        let calculated: any = null;
        const propertyKeys = Object.keys(evaluationResults.propertiesResults);
        if (propertyKeys.length) {
          propertyKeys.forEach((propertyId: any) => {
            if (
              evaluationResults?.propertiesResults &&
              evaluationResults?.propertiesResults[propertyId]
                .multiFamilyMixedUse?.actualDebtYield
            ) {
              calculated = calculated
                ? calculated + evaluationResults?.propertiesResults &&
                  evaluationResults?.propertiesResults[propertyId]
                    .multiFamilyMixedUse?.actualDebtYield
                : evaluationResults?.propertiesResults &&
                  evaluationResults?.propertiesResults[propertyId]
                    .multiFamilyMixedUse?.actualDebtYield;
            }
          });
        }
        if (calculated) {
          calculated /= propertyKeys.length;
        }
        const formattedValue = formatValueByType(calculated, "percentage"); // doubt
        return formattedValue;
      } catch (error) {
        return null;
      }
    },
    getTagValue: (data: any) => {
      const { evaluationResults, loanId } = data;
      try {
        const constValue =
          evaluationResults?.loanResult &&
          evaluationResults?.loanResult[loanId].creditEvaluation
            ?.actualDebtYield; // doubt
        let calculated: any;
        const propertyKeys = Object.keys(evaluationResults.propertiesResults);

        if (propertyKeys.length) {
          propertyKeys.forEach((propertyId: any) => {
            if (
              evaluationResults?.propertiesResults &&
              evaluationResults?.propertiesResults[propertyId]
                .multiFamilyMixedUse?.actualDebtYield
            ) {
              calculated = calculated
                ? calculated + evaluationResults?.propertiesResults &&
                  evaluationResults?.propertiesResults[propertyId]
                    .multiFamilyMixedUse?.actualDebtYield
                : evaluationResults?.propertiesResults &&
                  evaluationResults?.propertiesResults[propertyId]
                    .multiFamilyMixedUse?.actualDebtYield;
            }
          });
          if (calculated) {
            calculated /= propertyKeys.length;
          }
        }
        return actualDebtYieldRule(constValue, calculated);
      } catch (error) {
        return null;
      }
    }
  }
];

export const DSCRCentricFields: CreditEvalFieldType[] = [
  {
    fieldLabel: "Lease in place",
    fieldId: "leaseInPlace",
    fieldType: "formula",
    isUnitLevel: true,
    getValueFuntion: (data: any) => {
      const { item } = data;
      try {
        return item?.inPlaceLeaseRentMonthly
          ? sanitizeCurrency(item?.inPlaceLeaseRentMonthly)
          : sanitizeCurrency("0");
      } catch (e) {
        console.log("error", e);
      }
    }
  },
  {
    fieldLabel: "Lease Status",
    fieldId: "leaseStatus",
    fieldType: "formula",
    isUnitLevel: true,
    getValueFuntion: (data: any) => {
      const { item } = data;
      try {
        return item?.leaseStatus;
      } catch (e) {
        console.log("error", e);
      }
    }
  },
  {
    fieldLabel: "Lease Term",
    fieldId: "currentLeaseTerm",
    fieldType: "formula",
    isUnitLevel: true,
    getValueFuntion: (data: any) => {
      const { item } = data;
      return item?.currentLeaseTermMonthly || "";
    }
  },
  {
    fieldLabel: "Total Monthly Gross Rent",
    fieldId: "marketRentMonthly",
    fieldType: "formula",
    isUnitLevel: true,
    getValueFuntion: (data: any) => {
      try {
        const { item } = data;
        return item?.marketRentMonthly;
      } catch (e) {
        console.log("error", e);
      }
    }
  },
  {
    fieldLabel: "Percentage Occupied",
    fieldId: "percentOccupied",
    fieldType: "formula",
    isUnitLevel: false,
    getValueFuntion: (data: any) => {
      try {
        const { evaluationResults } = data;
        const totalSum: any = Object.values(
          evaluationResults.propertiesResults
        ).reduce((acc: any, ele: any) => {
          return (
            acc +
            parseFloat(
              ele?.loanEconomics?.percentOccupied
                ? ele?.loanEconomics?.percentOccupied
                : 0
            )
          );
        }, 0);
        const averagePercentageOccupied = totalSum
          ? totalSum / Object.values(evaluationResults.propertiesResults).length
          : 0;
        return sanitizePercentage(
          JSON.stringify(averagePercentageOccupied),
          true
        );
      } catch (e) {
        console.log("error", e);
      }
    }
  },
  {
    fieldLabel: "Total Monthly Taxes",
    fieldId: "totalMonthlyTaxes",
    fieldType: "formula",
    isUnitLevel: false,
    getValueFuntion: (data: any) => {
      const { evaluationResults, loanId } = data;
      const sanitizedValue = evaluationResults.loanResult?.[loanId]
        ?.creditEvaluation?.totalMonthlyTaxes
        ? sanitizeCurrency(
            evaluationResults.loanResult?.[loanId]?.creditEvaluation
              ?.totalMonthlyTaxes
          )
        : null;
      return sanitizedValue;
    }
  },
  {
    fieldLabel: "Total Monthly Insurance",
    fieldId: "totalMonthlyInsurance",
    fieldType: "formula",
    isUnitLevel: false,
    getValueFuntion: (data: any) => {
      const { evaluationResults, loanId } = data;
      const sanitizedValue = evaluationResults?.loanResult?.[loanId]
        ?.creditEvaluation?.totalMonthlyInsurance
        ? sanitizeCurrency(
            evaluationResults?.loanResult?.[loanId]?.creditEvaluation
              ?.totalMonthlyInsurance
          )
        : null;
      return sanitizedValue;
    }
  },
  {
    fieldLabel: "Total Monthly HOA/Flood",
    fieldId: "totalMonthlyHoaAndFlood",
    fieldType: "formula",
    isUnitLevel: false,
    getValueFuntion: (data: any) => {
      const { evaluationResults, loanId } = data;
      const sanitizedValue = evaluationResults?.loanResult?.[loanId]
        ?.creditEvaluation?.totalMonthlyHoaAndFlood
        ? sanitizeCurrency(
            evaluationResults?.loanResult?.[loanId]?.creditEvaluation
              ?.totalMonthlyHoaAndFlood
          )
        : null;
      return sanitizedValue;
    }
  },
  {
    fieldLabel: "Monthly Qualifying Payment",
    fieldId: "monthlyQualifyingPayment",
    fieldType: "formula",
    isUnitLevel: false,
    getValueFuntion: (data: any) => {
      const { evaluationResults, loanId } = data;
      const sanitizedValue = evaluationResults?.loanResult?.[loanId]
        ?.loanEconomics?.monthlyQualifyingPayment
        ? sanitizeCurrency(
            evaluationResults?.loanResult?.[loanId]?.loanEconomics
              ?.monthlyQualifyingPayment
          )
        : null;
      return sanitizedValue;
    }
  },
  {
    fieldLabel: "DSCR",
    fieldId: "debtServiceCoverageRatio",
    fieldType: "formula",
    isUnitLevel: false,
    getValueFuntion: (data: any) => {
      const { evaluationResults, loanId } = data;
      return evaluationResults?.loanResult?.[loanId]?.loanEconomics?.dscr;
    }
  }
];

export const loanInformationFields: CreditEvalFieldType[] = [
  {
    fieldLabel: "Existing Debt ($)",
    fieldId: "existingDebtCurrency",
    fieldType: "currency"
  },
  {
    fieldLabel: "Closing Costs ($)",
    fieldId: "closingCostsCurrency",
    fieldType: "currency"
  },
  {
    fieldLabel: "Appraiser DY (As Stabilized) (%)",
    fieldId: "appraiserDYAsStabilizedPercentage",
    fieldType: "percentageWithout100x"
  },
  {
    fieldLabel: "Exceptions",
    fieldId: "exceptions",
    fieldType: "string"
  },
  {
    fieldLabel: "Approved",
    fieldId: "approved",
    fieldType: "string"
  },
  {
    fieldLabel: "Comments",
    fieldId: "comments",
    fieldType: "string"
  },
  {
    fieldLabel: "Excess First Loss ($)",
    fieldId: "excessFirstLossCurrency",
    fieldType: "currency"
  },
  {
    fieldLabel: "Interest Reserve (IR) ($)",
    fieldId: "interestReserveIRCurrency",
    fieldType: "currency"
  },
  {
    fieldLabel: "12 Month IR ($)",
    fieldId: "twelveMonthIRCurrency",
    fieldType: "currency"
  },
  {
    fieldLabel: "Annual DS (Initial Loan) ($)",
    fieldId: "annualDSInitialLoanCurrency",
    fieldType: "currency"
  },
  {
    fieldLabel: "Annual DS (Max Loan) ($)",
    fieldId: "annualDSMaxLoanCurrency",
    fieldType: "currency"
  }
];

export const loanInformationFieldsForOutput: CreditEvalFieldType[] = [
  {
    fieldLabel: "Existing Debt ($)",
    fieldId: "existingDebtCurrency",
    fieldType: "currency"
  },
  {
    fieldLabel: "Closing Costs ($)",
    fieldId: "closingCostsCurrency",
    fieldType: "currency"
  },
  {
    fieldLabel: "Appraiser DY (As Stabilized) (%)",
    fieldId: "appraiserDYAsStabilizedPercentage",
    fieldType: "percentageWithout100x"
  },
  {
    fieldLabel: "Exceptions",
    fieldId: "exceptions",
    fieldType: "string"
  },
  {
    fieldLabel: "Approved",
    fieldId: "approved",
    fieldType: "string"
  },
  {
    fieldLabel: "Comments",
    fieldId: "comments",
    fieldType: "string"
  },
  {
    fieldLabel: "Excess First Loss ($)",
    fieldId: "excessFirstLossCurrency",
    fieldType: "currency"
  },
  {
    fieldLabel: "Interest Reserve (IR) ($)",
    fieldId: "interestReserveIRCurrency",
    fieldType: "currency"
  },
  {
    fieldLabel: "12 Month IR ($)",
    fieldId: "twelveMonthIRCurrency",
    fieldType: "currency"
  },
  {
    fieldLabel: "Annual DS (Initial Loan) ($)",
    fieldId: "annualDSInitialLoanCurrency",
    fieldType: "currency"
  },
  {
    fieldLabel: "Annual DS (Max Loan) ($)",
    fieldId: "annualDSMaxLoanCurrency",
    fieldType: "currency"
  },
  {
    fieldLabel: "Seller",
    fieldId: "sellerName",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.originatorInfo?.sellerName;
    }
  },
  {
    fieldLabel: "Loan Purpose",
    fieldId: "loanPurpose",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      const { loanDetails, loanType } = data;
      if (loanType === getLoanType[0].displayValue) {
        return loanDetails?.bridgeLoanInformation?.payload?.loanPurpose;
      }
      if (loanType === getLoanType[1].displayValue) {
        return loanDetails?.thirtyYearLoanInformation?.payload?.loanPurpose;
      }
    }
  },
  {
    fieldLabel: "Borrower Experience",
    fieldId: "annualDSMaxLoanCurrency",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      try {
        const { evaluationResults, loanId } = data;
        return evaluationResults.loanResult?.[loanId]?.creditEvaluation
          ?.borrowerExperienceExist;
      } catch (e) {
        console.log("error", e);
      }
    }
  },
  {
    fieldLabel: "Loan Characterisation",
    fieldId: "loanCharacterisation",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      try {
        const { evaluationResults, loanId } = data;
        return evaluationResults.loanResult?.[loanId]?.loanCharacterisations
          ?.loanCharacterisation;
      } catch (e) {
        console.log("error", e);
      }
    }
  },
  {
    fieldLabel: "Loan Structure",
    fieldId: "selectedLoanStructure",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      try {
        const { loanDetails, loanType } = data;
        if (loanType === getLoanType[0].displayValue) {
          return loanDetails?.bridgeLoanInformation?.payload
            ?.selectedLoanStructure;
        }
        if (loanType === getLoanType[1].displayValue) {
          return loanDetails?.thirtyYearLoanInformation?.payload
            ?.selectedLoanStructure;
        }
      } catch (e) {
        console.log("error", e);
      }
    }
  },
  {
    fieldLabel: "Original Term to Maturity(Months)",
    fieldId: "originalTermToMaturity",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      const { evaluationResults, loanId } = data;
      return evaluationResults.loanResult?.[loanId]?.loanFeatures
        ?.originalTermToMaturity;
    }
  },
  {
    fieldLabel: "Interest Rate",
    fieldId: "interestRate",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      try {
        const { loanDetails, loanType } = data;
        if (loanType === getLoanType[0].displayValue) {
          return loanDetails?.bridgeLoanEconomics?.payload?.interestRate;
        }
        if (loanType === getLoanType[1].displayValue) {
          return loanDetails?.thirtyYearLoanEconomics?.payload?.interestRate;
        }
      } catch (e) {
        console.log("error", e);
      }
    }
  },
  {
    fieldLabel: "Recourse",
    fieldId: "selectedRecourse",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      try {
        const { loanDetails, loanType } = data;
        if (loanType === getLoanType[0].displayValue) {
          return loanDetails?.bridgeLoanInformation?.payload?.selectedRecourse;
        }
        if (loanType === getLoanType[1].displayValue) {
          return loanDetails?.thirtyYearLoanInformation?.payload
            ?.selectedRecourse;
        }
      } catch (e) {
        console.log("error", e);
      }
    }
  },
  {
    fieldLabel: "Original Max Loan Amount",
    fieldId: "maxLoanAmount",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      const { loanDetails, loanType } = data;
      if (loanType === getLoanType[0].displayValue) {
        return loanDetails?.bridgeLoanEconomics?.payload?.maxLoanAmount;
      }
      if (loanType === getLoanType[1].displayValue) {
        return loanDetails?.thirtyYearLoanEconomics?.payload?.loanAmount;
      }
    }
  },
  {
    fieldLabel: "Initial Loan Funding",
    fieldId: "initialLoanFunding",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      const { evaluationResults, loanId } = data;
      return evaluationResults.loanResult?.[loanId]?.toorakProceedsTest
        ?.initialLoanFunding
        ? sanitizeCurrency(
            evaluationResults.loanResult?.[loanId]?.toorakProceedsTest
              ?.initialLoanFunding
          )
        : null;
    }
  },
  {
    fieldLabel: "Financed Budget Amount",
    fieldId: "financedBudgetAmount",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      const { loanDetails, loanType } = data;
      if (loanType === getLoanType[0].displayValue) {
        return loanDetails?.bridgeLoanEconomics?.payload?.financedBudgetAmount;
      }
      if (loanType === getLoanType[1].displayValue) {
        return loanDetails?.thirtyYearLoanEconomics?.payload
          ?.financedBudgetAmount;
      }
    }
  },
  {
    fieldLabel: "Cash Out Amount",
    fieldId: "cashOutAmount",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      const { loanDetails, loanType } = data;
      if (loanType === getLoanType[0].displayValue) {
        return loanDetails?.bridgeLoanEconomics?.payload?.cashOutAmount;
      }
      if (loanType === getLoanType[1].displayValue) {
        return loanDetails?.thirtyYearLoanEconomics?.payload?.cashOutAmount;
      }
    }
  },
  {
    fieldLabel: "Original 'As Repaired' Appraised Value",
    fieldId: "originalRepairedAppraisalValue",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      try {
        const { propertyDetails } = data;
        const propertyResults = propertyDetails.reduce((acc: any, ele: any) => {
          const sanitizedValue = formatValueForLoanAndPropertyApi(
            ele?.propertyEconomics?.payload?.originalRepairedAppraisalValue,
            "currency"
          );
          return acc + parseFloat(cleanCurrency(sanitizedValue));
        }, 0);
        return sanitizeCurrency(JSON.stringify(propertyResults));
      } catch (e) {
        console.log("error", e);
      }
    }
  },
  {
    fieldLabel: "Original 'As Is' Appraised Value",
    fieldId: "originalAppraisalValue",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      try {
        const { propertyDetails } = data;
        console.log("propertyDetails--", propertyDetails);
        const propertyResults = propertyDetails.reduce((acc: any, ele: any) => {
          const sanitizedValue = formatValueForLoanAndPropertyApi(
            ele?.propertyEconomics?.payload?.originalAppraisalValue,
            "currency"
          );
          return acc + parseFloat(cleanCurrency(sanitizedValue));
        }, 0);
        return sanitizeCurrency(JSON.stringify(propertyResults));
      } catch (e) {
        console.log("error", e);
      }
    }
  },
  {
    fieldLabel: "Rehab Amount",
    fieldId: "rehabAmount",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      try {
        const { evaluationResults, loanId } = data;
        return evaluationResults.loanResult?.[loanId]?.toorakProceedsTest
          ?.rehabAmount
          ? sanitizeCurrency(
              evaluationResults.loanResult?.[loanId]?.toorakProceedsTest
                ?.rehabAmount
            )
          : null;
      } catch (e) {
        console.log("error", e);
      }
    }
  },
  {
    fieldLabel: "Purchase Price",
    fieldId: "purchasePrice",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      try {
        const { propertyDetails } = data;
        const propertyResults = propertyDetails.reduce((acc: any, ele: any) => {
          const sanitizedValue = formatValueForLoanAndPropertyApi(
            ele?.propertyEconomics?.payload?.purchasePrice,
            "currency"
          );
          return acc + parseFloat(cleanCurrency(sanitizedValue));
        }, 0);
        return sanitizeCurrency(JSON.stringify(propertyResults));
      } catch (e) {
        console.log("error", e);
      }
    }
  },
  {
    fieldLabel: "Cost Basis",
    fieldId: "costBasis",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      try {
        const { propertyDetails } = data;
        const propertyResults = propertyDetails.reduce((acc: any, ele: any) => {
          const sanitizedValue = formatValueForLoanAndPropertyApi(
            ele?.propertyEconomics?.payload?.costBasis,
            "currency"
          );
          return acc + parseFloat(cleanCurrency(sanitizedValue));
        }, 0);
        return sanitizeCurrency(JSON.stringify(propertyResults));
      } catch (e) {
        console.log("error", e);
      }
    }
  },
  {
    fieldLabel: "Median Value",
    fieldId: "medianValue",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      try {
        const { evaluationResults, loanId } = data;
        const sanitizedValue = evaluationResults.loanResult?.[loanId]
          ?.creditEvaluation?.medianValue
          ? sanitizeCurrency(
              evaluationResults.loanResult?.[loanId]?.creditEvaluation
                ?.medianValue
            )
          : null;
        return sanitizedValue;
      } catch (e) {
        console.log("error", e);
      }
    }
  },
  {
    fieldLabel: "Days on Market",
    fieldId: "daysOnMarket",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      try {
        const { evaluationResults, loanId } = data;
        return evaluationResults.loanResult?.[loanId]?.creditEvaluation
          ?.daysOnMarket;
      } catch (e) {
        console.log("error", e);
      }
    }
  },
  {
    fieldLabel: "LTM HPA",
    fieldId: "ltmHpa",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      try {
        const { evaluationResults, loanId } = data;
        return evaluationResults.loanResult?.[loanId]?.creditEvaluation?.ltmHpa
          ? sanitizePercentage(
              evaluationResults.loanResult?.[loanId]?.creditEvaluation?.ltmHpa,
              true
            )
          : null;
      } catch (e) {
        console.log("error", e);
      }
    }
  },
  {
    fieldLabel: "Extension",
    fieldId: "extensionOption",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      const { loanDetails, loanType } = data;
      if (loanType === getLoanType[0].displayValue) {
        return loanDetails?.bridgeLoanInformation?.payload?.extensionOption;
      }
      if (loanType === getLoanType[1].displayValue) {
        return loanDetails?.thirtyYearLoanInformation?.payload?.extensionOption;
      }
    }
  },
  {
    fieldLabel: "Origination Date",
    fieldId: "originationDate",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      const { loanDetails, loanType } = data;
      if (loanType === getLoanType[0].displayValue) {
        return loanDetails?.bridgeLoanInformation?.payload?.originationDate
          ? formatDate(
              loanDetails?.bridgeLoanInformation?.payload?.originationDate
            )
          : null;
      }
      if (loanType === getLoanType[1].displayValue) {
        return loanDetails?.thirtyYearLoanInformation?.payload?.originationDate
          ? formatDate(
              loanDetails?.thirtyYearLoanInformation?.payload?.originationDate
            )
          : null;
      }
    }
  },
  {
    fieldLabel: "Original Maturity Date",
    fieldId: "maturityDate",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      try {
        const { loanDetails, loanType } = data;
        let maturityDate = "";
        if (loanType === getLoanType[0].displayValue) {
          maturityDate =
            loanDetails?.bridgeLoanInformation?.payload?.maturityDate;
          return formatDate(maturityDate);
        }
        if (loanType === getLoanType[1].displayValue) {
          maturityDate =
            loanDetails?.thirtyYearLoanInformation?.payload?.maturityDate;
          return formatDate(maturityDate);
        }
      } catch (e) {
        console.log("error", e);
      }
    }
  }
];

export const borrowerGuarantorFields: CreditEvalFieldType[] = [
  {
    fieldLabel: "Borrower Liquidity",
    fieldId: "liquidityCurrency",
    fieldType: "currency",
    userType: "Borrower-Guarantor"
  },
  {
    fieldLabel: "Net Worth",
    fieldId: "netWorthCurrency",
    fieldType: "currency",
    userType: "Borrower-Guarantor"
  }
];

export const borrowerGuarantorOutputFields: CreditEvalFieldType[] = [
  {
    fieldLabel: "Borrower Liquidity",
    fieldId: "liquidityCurrency",
    fieldType: "currency",
    userType: "Borrower-Guarantor"
  },
  {
    fieldLabel: "Net Worth",
    fieldId: "netWorthCurrency",
    fieldType: "currency",
    userType: "Borrower-Guarantor"
  },
  {
    fieldLabel: "Borrower Type",
    fieldId: "borrowerType",
    fieldType: "string",
    userType: "Borrower"
  },
  {
    fieldLabel: "Borrower Entity Name",
    fieldId: "accountName",
    fieldType: "string",
    userType: "Borrower"
  },
  {
    fieldLabel: "Guarantor Name",
    fieldId: "guarantorName",
    fieldType: "string",
    userType: "Guarantor"
  },
  {
    fieldLabel: "% of Ownership of Entity",
    fieldId: "ownershipOfEntity",
    fieldType: "percentage",
    userType: "Guarantor"
  },
  {
    fieldLabel: "Original Credit Score (Median)",
    fieldId: "originalCreditScoreMedian",
    fieldType: "string",
    userType: "Borrower-Guarantor"
  },
  {
    fieldLabel: "Experience",
    fieldId: "experience",
    fieldType: "number",
    userType: "Borrower-Guarantor"
  },
  {
    fieldLabel: "Foreign National",
    fieldId: "foreignNationalString",
    fieldType: "string",
    userType: "Borrower-Guarantor"
  }
];
export const cashFlowFields: CreditEvalFieldType[] = [
  {
    fieldLabel: "In Place Occupancy",
    fieldId: "inPlaceOccupancyNumeric",
    fieldType: "number"
  },
  {
    fieldLabel: "In Place NOI",
    fieldId: "inPlaceNOINumeric",
    fieldType: "number"
  },
  {
    fieldLabel: "In Place DSCR",
    fieldId: "inPlaceDSCRNumeric",
    fieldType: "number"
  },
  {
    fieldLabel: "Stabilized DSCR",
    fieldId: "stabilizedDSCRNumeric",
    fieldType: "number"
  },
  {
    fieldLabel: "Takeout@4.5% DSCR",
    fieldId: "takeoutDSCRNumeric",
    fieldType: "number"
  },
  {
    fieldLabel: "Covenants",
    fieldId: "covenants",
    fieldType: "string"
  }
];

export const cashFlowFieldsForOutput: CreditEvalFieldType[] = [
  {
    fieldLabel: "In Place Occupancy",
    fieldId: "inPlaceOccupancyNumeric",
    fieldType: "number"
  },
  {
    fieldLabel: "In Place NOI",
    fieldId: "inPlaceNOINumeric",
    fieldType: "number"
  },
  {
    fieldLabel: "In Place DSCR",
    fieldId: "inPlaceDSCRNumeric",
    fieldType: "number"
  },
  {
    fieldLabel: "Stabilized DSCR",
    fieldId: "stabilizedDSCRNumeric",
    fieldType: "number"
  },
  {
    fieldLabel: "Takeout@4.5% DSCR",
    fieldId: "takeoutDSCRNumeric",
    fieldType: "number"
  },
  {
    fieldLabel: "Covenants",
    fieldId: "covenants",
    fieldType: "string"
  },
  {
    fieldLabel: "Gross Potential Rent (Yearly)($)",
    fieldId: "grossPotentialRent",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      try {
        const { propertyDetails } = data;
        const propertyResults = propertyDetails.reduce((acc: any, ele: any) => {
          const sanitizedValue = formatValueForLoanAndPropertyApi(
            ele?.propertyEconomics?.payload?.grossPotentialRent,
            "currency"
          );
          return acc + parseFloat(cleanCurrency(sanitizedValue));
        }, 0);
        return propertyResults !== null && propertyResults !== undefined
          ? sanitizeCurrency(JSON.stringify(propertyResults))
          : null;
      } catch (e) {
        console.log("error", e);
      }
    }
  },
  {
    fieldLabel: "Annual Property Taxes",
    fieldId: "annualPropertyTaxes",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      try {
        const { propertyDetails } = data;
        const propertyResults = propertyDetails.reduce((acc: any, ele: any) => {
          const sanitizedValue = formatValueForLoanAndPropertyApi(
            ele?.propertyEconomics?.payload?.annualPropertyTaxes,
            "currency"
          );
          return acc + parseFloat(cleanCurrency(sanitizedValue));
        }, 0);
        return propertyResults !== null && propertyResults !== undefined
          ? sanitizeCurrency(JSON.stringify(propertyResults))
          : null;
      } catch (e) {
        console.log("error", e);
      }
    }
  },
  {
    fieldLabel: "Insurance",
    fieldId: "insurance",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      try {
        const { propertyDetails } = data;
        const propertyResults = propertyDetails.reduce((acc: any, ele: any) => {
          const sanitizedValue = formatValueForLoanAndPropertyApi(
            ele?.propertyEconomics?.payload?.insurance,
            "currency"
          );
          return acc + parseFloat(cleanCurrency(sanitizedValue));
        }, 0);
        return propertyResults !== null && propertyResults !== undefined
          ? sanitizeCurrency(JSON.stringify(propertyResults))
          : null;
      } catch (e) {
        console.log("error", e);
      }
    }
  },
  {
    fieldLabel: "Operating Expense & Vacancy",
    fieldId: "operatingExpenseandVacancy",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      try {
        const { evaluationResults, loanId } = data;
        return evaluationResults.loanResult?.[loanId]?.creditEvaluation
          ?.operatingExpenseandVacancy;
      } catch (e) {
        console.log("error", e);
      }
    }
  },
  {
    fieldLabel: "Toorak NOI/NCF",
    fieldId: "toorakNoiorNof",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      try {
        const { evaluationResults, loanId } = data;
        const value = evaluationResults.loanResult?.[loanId]?.creditEvaluation
          ?.toorakNoiorNof
          ? JSON.stringify(
              JSON.parse(
                evaluationResults.loanResult?.[loanId]?.creditEvaluation
                  ?.toorakNoiorNof
              )
            )
          : null;
        return value ? sanitizeCurrencyWithMinus(value) : null;
      } catch (e) {
        console.log("error", e);
      }
    }
  },
  {
    fieldLabel: "Appraisal NOI/NCF",
    fieldId: "appraisalNoiorNof",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      try {
        const { evaluationResults, loanId } = data;
        const appraisalValue =
          evaluationResults.loanResult?.[loanId]?.creditEvaluation
            ?.appraisalNoiorNof;
        const value =
          appraisalValue !== undefined && appraisalValue !== null
            ? JSON.stringify(JSON.parse(appraisalValue))
            : null;
        return value ? sanitizeCurrency(value) : null;
      } catch (e) {
        console.log("error", e);
      }
    }
  },
  {
    fieldLabel: "Underwritten NOI/NCF",
    fieldId: "underwrittenNoiorNof",
    fieldType: "formula",
    getValueFuntion: (data: any) => {
      try {
        const { evaluationResults, loanId } = data;
        const value = evaluationResults.loanResult?.[loanId]?.creditEvaluation
          ?.underwrittenNoiorNof
          ? JSON.stringify(
              JSON.parse(
                evaluationResults.loanResult?.[loanId]?.creditEvaluation
                  ?.underwrittenNoiorNof
              )
            )
          : null;
        return value ? sanitizeCurrencyWithMinus(value) : null;
      } catch (e) {
        console.log("error", e);
      }
    }
  }
];

export const reviewFields: CreditEvalFieldType[] = [
  {
    fieldLabel: "Ready for Purchase",
    fieldId: "readyforPurchase",
    fieldType: "string"
  },
  {
    fieldLabel: "Summary Output Sent",
    fieldId: "summaryOutputSent",
    fieldType: "string"
  },
  {
    fieldLabel: "Purchase Advice Requirement",
    fieldId: "purchaseAdviceRequirement",
    fieldType: "string"
  },
  {
    fieldLabel: "Recommendation",
    fieldId: "recommendation",
    fieldType: "string"
  },
  {
    fieldLabel: "Additional Notes",
    fieldId: "additionalNotes",
    fieldType: "string"
  },
  {
    fieldLabel: "Purchase Date",
    fieldId: "purchaseDate",
    fieldType: "date"
  }
];
const appraisalFormTypes = [
  {
    value: "FNMA 1004/FH 70 (1 Family)",
    label: "FNMA 1004/FH 70 (1 Family)"
  },
  {
    value: "FNMA 1025/ FH 72 (2-4 Family)",
    label: "FNMA 1025/ FH 72 (2-4 Family)"
  },
  {
    value: "FNMA 1073/ FH 465 (Individual Condo)",
    label: "FNMA 1073/ FH 465 (Individual Condo)"
  },
  {
    value: "Hybrid - ClearVal 2.0 (Clear Capital)",
    label: "Hybrid - ClearVal 2.0 (Clear Capital)"
  },
  {
    value: "Hybrid – ANX (Appraisal Nation)",
    label: "Hybrid – ANX (Appraisal Nation)"
  },
  {
    value: "Hybrid – DVII (ServiceLink)",
    label: "Hybrid – DVII (ServiceLink)"
  },
  {
    value: "Hybrid - IValueNet55 (Accurate Group)",
    label: "Hybrid - IValueNet55 (Accurate Group)"
  },
  {
    value: "MAI With Narrative (5+ Units or Mixed-Use)",
    label: "MAI With Narrative (5+ Units or Mixed-Use)"
  }
];
export const thirdPartyReportFields: CreditEvalFieldType[] = [
  {
    fieldLabel: "Appraiser Name",
    fieldId: "appraiserName",
    fieldType: "string"
  },
  {
    fieldLabel: "Appraisal company",
    fieldId: "appraisalCompany",
    fieldType: "string"
  },
  {
    fieldLabel: "Appraisal management company",
    fieldId: "appraisalManagementCompany",
    fieldType: "string"
  },
  {
    fieldLabel: "Appraisal Form (type)",
    fieldId: "appraisalFormType",
    fieldType: "dropdown",
    selectionData: appraisalFormTypes
  },
  {
    fieldLabel: "License number",
    fieldId: "licenseNumber",
    fieldType: "string"
  },
  {
    fieldLabel: "Appraisal Date",
    fieldId: "appraisalDate",
    fieldType: "date"
  },
  {
    fieldLabel: "Appraisal Comments",
    fieldId: "appraisalComments",
    fieldType: "description"
  },
  {
    fieldLabel: "Restricted",
    fieldId: "restricted",
    fieldType: "string"
  },
  {
    fieldLabel: "Appraisal Review",
    fieldId: "appraisalReview",
    fieldType: "string"
  },
  {
    fieldLabel: "Review Date",
    fieldId: "appraisalReviewDate",
    fieldType: "date"
  },
  {
    fieldLabel: "Review Comments",
    fieldId: "appraisalReviewComments",
    fieldType: "description"
  },
  {
    fieldLabel: "Engineering Review",
    fieldId: "engineeringReview",
    fieldType: "string"
  },
  {
    fieldLabel: "Review Date",
    fieldId: "engineeringReviewDate",
    fieldType: "date"
  },
  {
    fieldLabel: "Review Comments",
    fieldId: "engineeringReviewComments",
    fieldType: "description"
  },
  {
    fieldLabel: "Environmental Review",
    fieldId: "environmentalReview",
    fieldType: "string"
  },
  {
    fieldLabel: "Review Date",
    fieldId: "environmentalReviewDate",
    fieldType: "date"
  },
  {
    fieldLabel: "Review Comments",
    fieldId: "environmentalReviewComments",
    fieldType: "description"
  },
  {
    fieldLabel: "Seismic Review",
    fieldId: "seismicReview",
    fieldType: "string"
  },
  {
    fieldLabel: "Review Date",
    fieldId: "seismicReviewDate",
    fieldType: "date"
  },
  {
    fieldLabel: "Review Comments",
    fieldId: "seismicReviewComments",
    fieldType: "description"
  },
  {
    fieldLabel: "Trulia Crime Check",
    fieldId: "truliaCrimeCheck",
    fieldType: "checkbox"
  }
];
export const finalValueSection: InputValueSectionType[] = [
  {
    sectionLabel: "Property Information",
    sectionId: "propertyInfo",
    sectionName: "propertyInfo",
    componentType: CreditEvalComponentType.creditEvalProperty,
    fields: propertyInformationFinalValueFields
  },
  {
    sectionLabel: "Borrower / Guarantor",
    sectionId: "borrowerGuarantor",
    sectionName: "borrowerGuarantor",
    componentType: CreditEvalComponentType.borrowerGuarantorSec,
    fields: borrowerGuarantorOutputFields
  },
  {
    sectionLabel: "Loan Information",
    sectionId: "loanInfo",
    sectionName: "loanInfo",
    componentType: CreditEvalComponentType.creditEvalNormal,
    fields: loanInformationFieldsForOutput
  },
  {
    sectionLabel: "Cash Flow Information",
    sectionId: "cashFlowInformation",
    sectionName: "loanCredit",
    componentType: CreditEvalComponentType.creditEvalNormal,
    isHidden: (data: ConditionalHideData) => {
      // this needs more info..need to discuss with BA on how to handle it incase of multiple properties
      const { loanType, reduxData } = data;
      if (reduxData) {
        const { loanDetails, propertyDetails } = reduxData;
        if (propertyDetails?.length) {
          return !propertyDetails.some(
            ele =>
              ele.propertyInfo.propertyType === "5+ Unit Multifamily" ||
              ele.propertyInfo.propertyType === "Mixed Use"
          );
        }
      }
      return true;
    },
    fields: cashFlowFieldsForOutput
  },
  {
    sectionLabel: "Leverage Guidelines",
    sectionId: "leverageGuidelines",
    sectionName: "loanCredit",
    componentType: CreditEvalComponentType.creditEvalNormal,
    fields: leverageGuidelinesFinalValue
  },
  {
    sectionLabel: "DSCR Centric Fields",
    sectionId: "DSCRCentricFields",
    sectionName: "loanCredit",
    componentType: CreditEvalComponentType.dscrCentricFields,
    isHidden: (data: ConditionalHideData) => {
      const { loanType } = data;
      if (loanType && loanType === getLoanType[1].displayValue) return false;
      return true;
    },
    fields: DSCRCentricFields
  },
  {
    sectionLabel: "Third Party Report",
    sectionId: "thirdPartyReport",
    sectionName: "loanCredit",
    componentType: CreditEvalComponentType.creditThirdParty,
    fields: thirdPartyReportFields
  },
  {
    sectionLabel: "Review",
    sectionId: "review",
    sectionName: "loanCredit",
    componentType: CreditEvalComponentType.creditEvalNormal,
    fields: reviewFields // it is same as input for now. if it changes in future, create a new mapping
  }
];
export const inputValueSection: InputValueSectionType[] = [
  {
    sectionLabel: "Property Information",
    sectionId: "propertyInfo",
    sectionName: "propertyInfo",
    componentType: CreditEvalComponentType.creditEvalProperty,
    fields: propertyInformationFields
  },
  {
    sectionLabel: "Borrower / Guarantor",
    sectionId: "borrowerGuarantor",
    sectionName: "borrowerGuarantor",
    componentType: CreditEvalComponentType.borrowerGuarantorSec,
    fields: borrowerGuarantorFields
  },
  {
    sectionLabel: "Loan Information",
    sectionId: "loanInfo",
    sectionName: "loanInfo",
    componentType: CreditEvalComponentType.creditEvalNormal,
    fields: loanInformationFields
  },
  {
    sectionLabel: "Cash Flow Information",
    sectionId: "cashFlowInformation",
    sectionName: "loanCredit",
    componentType: CreditEvalComponentType.creditEvalNormal,
    isHidden: (data: ConditionalHideData) => {
      // this needs more info..need to discuss with BA on how to handle it incase of multiple properties
      const { loanType, reduxData } = data;
      if (reduxData) {
        const { loanDetails, propertyDetails } = reduxData;
        if (propertyDetails?.length) {
          return !propertyDetails.some(
            ele =>
              ele.propertyInfo.propertyType === "5+ Unit Multifamily" ||
              ele.propertyInfo.propertyType === "Mixed Use"
          );
        }
      }
      return true;
    },
    fields: cashFlowFields
  },
  {
    sectionLabel: "Third Party Report",
    sectionId: "thirdPartyReport",
    sectionName: "loanCredit",
    componentType: CreditEvalComponentType.creditThirdParty,
    fields: thirdPartyReportFields
  },
  {
    sectionLabel: "Review",
    sectionId: "review",
    sectionName: "loanCredit",
    componentType: CreditEvalComponentType.creditEvalNormal,
    fields: reviewFields
  }
];

/** Rules section starts here */

/** Below function can be considered as a rule which checks original and adjusted values and returns PASS if original values are less than or equal to adjusted values */
export const compareOriginalVsAdjustedValues = (original: any, net: any) => {
  if (isEmptyValue(net) || net === "Not Permitted") {
    return "FAIL";
  }
  if (net === "N/A") {
    return "NA";
  }
  if (isEmptyValue(original)) {
    return "NA";
  }
  if (original <= net) {
    return "PASS";
  }
  if (original > net) {
    return "FAIL";
  }
  return null;
};

export const profitabilityRule = (constValue: any, calculated: any) => {
  if (isEmptyValue(calculated)) {
    return "NA";
  }
  if (calculated === "N/A" || calculated === "NA") {
    return "PASS";
  }
  if (calculated < constValue) {
    return "FAIL";
  }
  if (calculated >= constValue) {
    return "PASS";
  }
  return null;
};
export const rentYieldRule = (constValue: any, calculated: any) => {
  if (isEmptyValue(calculated)) {
    return "NA";
  }
  if (calculated === "N/A" || calculated === "NA") {
    return null;
  }
  if (calculated < constValue) {
    return "FAIL";
  }
  if (calculated >= constValue) {
    return "PASS";
  }
  return null;
};
export const actualDebtYieldRule = (original: any, net: any) => {
  if (isEmptyValue(net) || net === "Not Permitted" || net === "N/A") {
    return "FAIL";
  }
  if (isEmptyValue(original)) {
    return "NA";
  }
  if (original === "N/A") {
    return "PASS";
  }
  if (original < net) {
    return "FAIL";
  }
  if (original >= net) {
    return "PASS";
  }
  return null;
};
/** Rules section ends here */
