import React from "react";
import {
  GridTextFieldForm as TextFieldForm,
  DropDown
} from "@toorak/tc-common-fe-sdk";
import { TextFieldProps } from "./FormComponentsProps";

export function OriginalAppraisalValueTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    isViewMode,
    infoIconData,
    editedData,
    editedLabel,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      required
      value={value}
      isViewMode={isViewMode}
      onChange={onChange}
      editedData={editedData}
      label='Original "As Is" Appraisal Value ($)'
      error={error}
      infoIconData={infoIconData}
      helperText={helperText}
      data-testid="original-appraisal-value"
      valueTestId="original-appraisal-value-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
}

export function ThirdPartyValuationTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    isViewMode,
    onBlur,
    editedData,
    editedLabel,
    required,
    enableReason
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      editedData={editedData}
      label="Third Party Valuation ($)"
      error={error}
      helperText={helperText}
      isViewMode={isViewMode}
      onBlur={onBlur}
      data-testid="third-party-valuation-penalty"
      valueTestId="third-party-valuation-penalty-value"
      editedLabel={editedLabel}
      enableReason={enableReason}
    />
  );
}

export function OriginalAppraisalRepairedValueTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    editedData,
    infoIconData,
    helperText,
    onBlur,
    isViewMode,
    editedLabel,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      required
      value={value}
      isViewMode={isViewMode}
      onChange={onChange}
      infoIconData={infoIconData}
      editedData={editedData}
      label="As Repaired Value (ARV) ($)"
      error={error}
      helperText={helperText}
      data-testid="original-appraisal-repaired"
      valueTestId="original-appraisal-repaired-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
}

export function PurchasePriceTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    editedData,
    helperText,
    infoIconData,
    onBlur,
    isViewMode,
    editedLabel,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      required
      value={value}
      isViewMode={isViewMode}
      onChange={onChange}
      editedData={editedData}
      infoIconData={infoIconData}
      label="Purchase Price ($)"
      error={error}
      helperText={helperText}
      data-testid="purchase-price ($)"
      valueTestId="purchase-price-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
}

export function CostBasisTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    editedData,
    helperText,
    infoIconData,
    onBlur,
    isViewMode,
    editedLabel,
    required,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      value={value}
      isViewMode={isViewMode}
      onChange={onChange}
      label="Cost Basis (After Cost Incurred) ($)"
      error={error}
      editedData={editedData}
      helperText={helperText}
      infoIconData={infoIconData}
      data-testid="cost-basis"
      valueTestId="cost-basis-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      required={required}
      isServicerMandatory={isServicerMandatory}
      enableReason={enableReason}
    />
  );
}

export function GrossPotentialRentTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    editedData,
    infoIconData,
    onBlur,
    isViewMode,
    editedLabel,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      required
      value={value}
      isViewMode={isViewMode}
      onChange={onChange}
      editedData={editedData}
      label="Gross Potential Rent (Yearly) ($)"
      error={error}
      infoIconData={infoIconData}
      helperText={helperText}
      data-testid="gross-potential-rent"
      valueTestId="gross-potential-rent-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
}

export function AssignmentFeesTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    editedData,
    infoIconData,
    onBlur,
    isViewMode,
    editedLabel,
    enableReason
  } = props;

  return (
    <TextFieldForm
      required
      value={value}
      isViewMode={isViewMode}
      onChange={onChange}
      editedData={editedData}
      label="Assignment Fees"
      error={error}
      infoIconData={infoIconData}
      helperText={helperText}
      data-testid="assignment-fees"
      valueTestId="assignment-fees-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
    />
  );
}
export function AnnualPropertyTaxesTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    infoIconData,
    isViewMode,
    editedLabel,
    editedData,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      required
      value={value}
      isViewMode={isViewMode}
      onChange={onChange}
      label="Annual Property Taxes ($)"
      error={error}
      infoIconData={infoIconData}
      editedData={editedData}
      helperText={helperText}
      data-testid="annual-property-taxes"
      valueTestId="annual-property-taxes-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
}

export function AnnualHazardInsuranceTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    editedLabel,
    helperText,
    onBlur,
    editedData,
    isViewMode,
    enableReason
  } = props;

  return (
    <TextFieldForm
      required
      value={value}
      isViewMode={isViewMode}
      editedData={editedData}
      onChange={onChange}
      label="Annual Hazard Insurance ($)"
      error={error}
      editedLabel={editedLabel}
      helperText={helperText}
      data-testid="annual-hazard-insurance"
      valueTestId="insurance-value"
      onBlur={onBlur}
      enableReason={enableReason}
    />
  );
}

export function AnnualFloodInsuranceTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    editedLabel,
    helperText,
    editedData,
    onBlur,
    isViewMode,
    enableReason
  } = props;

  return (
    <TextFieldForm
      required
      value={value}
      editedData={editedData}
      editedLabel={editedLabel}
      isViewMode={isViewMode}
      onChange={onChange}
      label="Annual Flood Insurance ($)"
      error={error}
      helperText={helperText}
      data-testid="annual-flood-insurance"
      valueTestId="insurance-value"
      onBlur={onBlur}
      enableReason={enableReason}
    />
  );
}

export function AnnualHOAFeeTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    editedLabel,
    helperText,
    onBlur,
    editedData,
    isViewMode,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      required
      value={value}
      isViewMode={isViewMode}
      editedData={editedData}
      onChange={onChange}
      label="Annual HOA Fee ($)"
      error={error}
      editedLabel={editedLabel}
      helperText={helperText}
      data-testid="annual-hoa-fee"
      valueTestId="annual-hoa-fee-value"
      onBlur={onBlur}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
}

export function InsuranceTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    editedData,
    infoIconData,
    onBlur,
    isViewMode,
    editedLabel,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      required
      value={value}
      isViewMode={isViewMode}
      onChange={onChange}
      editedData={editedData}
      label="Insurance  ($)"
      error={error}
      infoIconData={infoIconData}
      helperText={helperText}
      data-testid="insurance"
      valueTestId="insurance-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
}

export function FinancedBudgetAmountTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    infoIconData,
    editedData,
    isViewMode,
    editedLabel,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      required
      value={value}
      isServicerMandatory={isServicerMandatory}
      isViewMode={isViewMode}
      onChange={onChange}
      editedData={editedData}
      label="Financed Budget Amount ($)"
      error={error}
      infoIconData={infoIconData}
      helperText={helperText}
      data-testid="financed-budget-amount"
      valueTestId="financed-budget-amount-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
    />
  );
}

export function RentalCashflowRatioTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    isViewMode,
    editedLabel,
    editedData,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      required
      value={value}
      isViewMode={isViewMode}
      onChange={onChange}
      label="Rental Cashflow Ratio (%)"
      error={error}
      helperText={helperText}
      data-testid="rental-cashflow-ratio"
      valueTestId="rental-cashflow-ratio-value"
      onBlur={onBlur}
      editedData={editedData}
      editedLabel={editedLabel}
      isServicerMandatory={isServicerMandatory}
    />
  );
}

export function DecliningMarketsDropDown(props: any) {
  const {
    value,
    onChange,
    onBlur,
    helperText,
    isViewMode,
    editedLabel,
    enableReason,
    editedData,
    infoIconData,
    selectionData,
    required,
    isLATView
  } = props;

  return (
    <DropDown
      formControlTestId="declining-market-bucket-form-control"
      inputLabelId="declining-market-bucket-label"
      inputLabelTestId="declining-market-bucket-label-test-id"
      label="Declining Market"
      selectId="declining-market-bucket-select-id"
      selectTestId="declining-market-bucket-select-test-id"
      selectionValue={value}
      isViewMode={isViewMode}
      selectionOnChange={onChange}
      selectionData={selectionData}
      helperText={helperText}
      editedLabel={editedLabel}
      onBlur={onBlur}
      notRequired={required}
      infoIconData={infoIconData}
      enableReason={enableReason}
      valueTestId="declining-market-value"
      data-testid="declining-market-value"
      editedData={editedData}
      isLATView={isLATView}
    />
  );
}
