/* eslint-disable import/no-default-export */
import { Grid, Typography } from "@mui/material";
import React from "react";
// import { useStyles } from "../common-style/CreateLoan.style";
import { OriginatorIcon } from "../create-loan/Icons";
import { MMMDDYYYY } from "../utils/formatChecks";
import { loanDetailsStyles } from "./styles";
import { getConfig, getLoanType } from "../config/config";
import {
  sanitizeValue,
  formatType
} from "../create-loan/purchase-and-settlements/constants";
import { convertToMillions } from "../utils/utils";
import { LoanStage } from "@toorak/tc-common-fe-sdk";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import { loanStageMapping } from "../create-loan/constants/loanListConstants";

export interface WarehouseLoanDetailsProps {
  loanId: string;
  originatorLoanId: string;
  loanSubmissionDate: string;
  rateLockPeriod: string;
  originatorName: string;
  finalToorakPrice: string;
  originatorInterestRate: string;
  loanStage: string;
  loanType: string;
  loanState: string;
  maxLoanAmount: string;
  rateLockExpiryDate: string;
}

export default function LoanDetails(props: WarehouseLoanDetailsProps) {
  const loanDetailsClasses = loanDetailsStyles();
  const {
    loanId = "",
    originatorLoanId = "",
    originatorName = "",
    loanSubmissionDate,
    finalToorakPrice,
    originatorInterestRate,
    loanStage,
    loanState,
    maxLoanAmount,
    rateLockExpiryDate,
    rateLockPeriod,
    loanType
  } = props;

  const loanIdRedirect = () => {
    const tempLoanType =
      getLoanType[0].loanCreationName === loanType
        ? getLoanType[0].displayValue
        : getLoanType[1].displayValue;
    window.open(
      `${
        getConfig().redirectUrl
      }loan/createLoan/${tempLoanType}/${loanStage}/${loanId}`
    );
  };

  return (
    <Grid style={{ paddingLeft: "20px" }}>
      <Grid>
        <Grid className={loanDetailsClasses.wrapper}>
          <div
            style={{
              marginRight: "5px",
              display: "flex",
              alignItems: "center"
            }}
          >
            <Typography
              className={loanDetailsClasses.loanId}
              component="a"
              onClick={loanIdRedirect}
            >
              {`${loanId} / ${originatorLoanId}`}{" "}
              <div className={loanDetailsClasses.originatorDataWrapper}>
                <OriginatorIcon />
                <span className={loanDetailsClasses.originatorName}>
                  {originatorName}
                </span>
              </div>
            </Typography>
          </div>
          {/* {isLoanOnHold && <Tag tagData={onHoldTagData} classes={classes} />} */}
        </Grid>
        {loanStage && (
          <Grid>
            <div>
              Status:{" "}
              <span className={loanDetailsClasses.closingDateText}>
                {loanState !== LoanStatusEnum.DataEntry &&
                  loanStage === LoanStage.fes
                  ? "Preliminary Sizing"
                  : loanState}
              </span>{" "}
              | Loan Stage:{" "}
              <span className={loanDetailsClasses.purchaseDateText}>
                {loanStageMapping[loanStage]}
              </span>
            </div>
            <div>
              Max Loan Amount:{" "}
              <span className={loanDetailsClasses.closingDateText}>
                {maxLoanAmount ? convertToMillions(maxLoanAmount) : "NA"}
              </span>{" "}
              | Final Toorak Price:{" "}
              <span className={loanDetailsClasses.purchaseDateText}>
                {finalToorakPrice
                  ? `${convertToMillions(finalToorakPrice)}`
                  : "NA"}
              </span>{" "}
              | Originator Interest Rate:{" "}
              <span className={loanDetailsClasses.purchaseDateText}>
                {originatorInterestRate
                  ? `${sanitizeValue(
                      formatType.percentage,
                      originatorInterestRate
                    )}`
                  : "NA"}
              </span>
            </div>
            <div>
              Loan Submission Date:{" "}
              <span className={loanDetailsClasses.closingDateText}>
                {MMMDDYYYY(loanSubmissionDate) || "NA"}
              </span>{" "}
              | Rate Lock Expiry:{" "}
              <span className={loanDetailsClasses.purchaseDateText}>
                {rateLockExpiryDate
                  ? `${MMMDDYYYY(rateLockExpiryDate)} (
                ${rateLockPeriod} days)`
                  : "NA"}
              </span>
            </div>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
