/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  Typography,
  AppBar,
  Box,
  CircularProgress,
  Container,
  Grid,
  Tooltip
} from "@mui/material";
import React, { useMemo, useReducer, useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useStyles } from "../../create-loan/common-style/CreateLoanType.style";
// import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import {
  createDrawFromExcel,
  generateDrawId
  // updateDrawExcelData
} from "../../stores/drawRequest/drawRequest.action";
import {
  BrowseFileButton,
  WarningModal,
  WarningModalProps
} from "@toorak/tc-common-fe-sdk";
import { UploadExcelIcon } from "../../create-loan/CreateLoanType";
import { DragAndDrop } from "../../create-loan/DragAndDrop";
import excelIcon from "../../images/excel-doc.svg";
import remove from "../../images/icons-dark-remove.svg";
import proceed from "../../images/icons-dark-proceed.svg";
import download from "../../images/download-white.svg";
import { isPrivilege } from "../../utils/AccessManagement";
import {
  downloadFile,
  getPreAssignedURL,
  uploadAWSFile
} from "../../create-loan/create-loan.action";
import {
  hideLoader,
  showLoader
} from "../../stores/loaderAndException/loaderAndException.action";
import { getCookie } from "../../utils/cookies";
import { bucketName } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { updateDrawExcelData } from "../../stores/drawRequest/drawRequest.reducer";

export const CreateDraw = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [docUploaded, setDocUploaded] = useState<boolean>(false);
  const [uploadedDocName, setUploadedDocName] = useState<string | null>(null);
  const [cloudFilePathBucket, setcloudFilePathBucket] = useState<{
    BucketName: string;
    Bucketkey: string;
  } | null>(null);
  const [openWarning, setOpenWarning] = useState<WarningModalProps | null>(
    null
  );

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case "SET_DROP_DEPTH":
        return { ...state, dropDepth: action.dropDepth };
      case "SET_IN_DROP_ZONE":
        return { ...state, inDropZone: action.inDropZone };
      case "ADD_FILE_TO_LIST":
        handleUpload(action.files);
        return { ...state, fileList: state.fileList.concat(action.files) };
      default:
        return state;
    }
  };

  const [data, dispatchh] = useReducer(reducer, {
    dropDepth: 0,
    inDropZone: false,
    fileList: []
  });

  const goBackToDrawListPage = () => {
    navigate("/loans/list?tab=8");
  };

  const isLoading = useMemo(
    () => loading || uploading || docUploaded,
    [loading, uploading, docUploaded]
  );

  const isBulkUplodDisabled = useMemo(() => {
    return uploading || loading ? true : false;
  }, [uploading, loading]);

  const isManualCreateDisabled = useMemo(() => {
    return isLoading ? true : false;
  }, [isLoading]);

  const createDrawManually = () => {
    sessionStorage.setItem("bulkDrawView", "false");
    sessionStorage.setItem("drawExcelUpload", "");
    generateDrawId().then((res: any) => {
      sessionStorage.setItem("newDrawRequestHeader", "true");
      sessionStorage.setItem("newDrawCreated", "true");
      navigate(`/drawDetails/${res.data.drawId}`);
    });
  };

  const validateSelections = () => {
    return isPrivilege("create_draw");
  };

  function handleUpload(files: any) {
    setUploading(true);
    const fileChosen = files[0];
    const isValidFile = fileChosen.name.split(".").pop() === "xlsx";
    if (isValidFile) {
      const body = {
        BucketName: bucketName,
        Bucketkey: `S3_Bucket/${getCookie(
          "org_id"
        )}/excelUpload/${new Date().getTime()}/${files[0].name}`
      };
      getPreAssignedURL(body, fileChosen.type)
        .then(async (awsUrl: any) => {
          uploadAWSFile(fileChosen, {}, awsUrl)
            .then((response) => {
              setUploadedDocName(fileChosen.name);
              setDocUploaded(true);
              setcloudFilePathBucket(body);
              setUploading(false);
            })
            .catch((e) => {
              console.error(e);
              setUploading(false);
            });
        })
        .catch((e) => {
          console.error(e);
          setUploading(false);
        });
    } else {
      setUploading(false);
      setOpenWarning({
        isOpen: true,
        header: "Invalid file",
        body:
          fileChosen.name.split(".").pop().toLowerCase() === "xls"
            ? "xls file format is not supported. Please save the file in .xlsx format and try again"
            : "Invalid file format. Please select file of type xlsx.",
        primaryBtnName: "Okay",
        handleClose: () => {
          setOpenWarning(null);
        }
      });
    }
  }

  function getFileType(fileName: any) {
    let fileType = "XLS";
    if (typeof fileName === "string") {
      const type = fileName.split(".").pop();
      if (type) {
        fileType = type.toUpperCase();
      }
    }
    return fileType;
  }

  const handleDownload = () => {
    dispatch(showLoader());
    const body = {
      Bucketkey: `drawCreation/template/Draw Requests`
    };

    downloadFile(body, `draw.xlsx`)
      .then((data) => {
        dispatch(hideLoader());
      })
      .catch((error) => console.error(error));
  };

  const handleDocRemove = () => {
    setOpenWarning({
      isOpen: true,
      header: "Remove uploaded Excel",
      handleOptional: () => {
        setOpenWarning(null);
      },
      body: "Are you sure you want to remove this excel?",
      primaryBtnName: "Okay",
      handleClose: () => {
        removeUploadedExcel();
        setOpenWarning(null);
      }
    });
  };
  function removeUploadedExcel() {
    setDocUploaded(false);
    setUploadedDocName(null);
  }
  function handleProceedClick() {
    dispatch(showLoader(true));
    createDrawFromExcel({
      cloudFilePathBucket
    })
      .then((resp) => {
        const excelData = resp.data;
        if (
          excelData?.data.length &&
          excelData.errorCount === 0 &&
          excelData.totalCount
        ) {
          excelData.data.forEach((element: any) => {
            element.selected = false;
          });
          excelData.data[0].selected = true;
          sessionStorage.setItem(
            "drawExcelUpload",
            JSON.stringify(excelData.data)
          );
          sessionStorage.setItem("bulkDrawView", "true");
          dispatch(updateDrawExcelData(excelData.data));
          setTimeout(() => {
            dispatch(hideLoader(true));
            navigate(`/drawDetails/${excelData.data[0].drawId}`);
          }, 100);
        } else {
          dispatch(hideLoader(true));
          sessionStorage.setItem("bulkDrawView", "false");
          sessionStorage.setItem("drawExcelUpload", "");
          let warnMgs = `${excelData.errorCount}/${excelData.totalCount} Draw Request details are invalid, Please review to proceed further.`;
          if (excelData.errorCount === 0 && excelData.totalCount === 0) {
            warnMgs = `No Draw Request details are available, Please review to proceed further.`;
          }
          setOpenWarning({
            isOpen: true,
            header: "Invalid Excel",
            body: warnMgs,
            primaryBtnName: "Okay",
            handleClose: () => {
              removeUploadedExcel();
              setOpenWarning(null);
            }
          });
        }
      })
      .catch((e) => {
        dispatch(hideLoader(true));
        sessionStorage.setItem("bulkDrawView", "false");
        sessionStorage.setItem("drawExcelUpload", "");
      });
  }

  return (
    <div className={classes.rectangle}>
      <AppBar
        color="default"
        position="static"
        className={classes.createLoanHeader}
      >
        <Box
          className="button-typography-wrapper-left"
          style={{ padding: "4px 0px" }}
        >
          <Button
            variant="contained"
            data-testid="back-button"
            color="primary"
            startIcon={<KeyboardArrowLeftIcon />}
            onClick={goBackToDrawListPage}
          >
            Back
          </Button>
          <Typography>Draw Request Creation</Typography>
        </Box>
      </AppBar>
      <Container maxWidth="sm" className={classes.createLoanBodyWrapper}>
        <Box component="div" className={classes.formContainer}>
          <Grid container className={classes.headerContainer}>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              className={classes.loanInfoContainer}
            >
              <Typography className={classes.loanInfo}>
                Draw Request Information
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              style={{
                cursor: "no-drop",
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              <Button
                variant="contained"
                data-testid="download-draw-excel-button"
                startIcon={<img src={download} alt="download_draw_template" />}
                onClick={handleDownload}
                disabled={false}
                color="info"
              >
                Download Template
              </Button>
            </Grid>
          </Grid>
          <Grid container style={{ display: "flex", justifyContent: "center" }}>
            {docUploaded ? (
              <div className={classes.uploadContainer}>
                <Container maxWidth="xs">
                  <div>
                    <div className={classes.fileIconContainer}>
                      <img src={excelIcon} alt="excel file" />
                      <Typography
                        data-testid="format"
                        className={classes.centered}
                      >
                        {getFileType(uploadedDocName)}
                      </Typography>
                    </div>
                    <Tooltip
                      title={
                        uploadedDocName && uploadedDocName.length > 50
                          ? uploadedDocName
                          : ""
                      }
                    >
                      <Typography
                        data-testid="uploaded-doc-name"
                        className={classes.fileName}
                      >
                        {uploadedDocName && uploadedDocName.length > 50
                          ? `${uploadedDocName.slice(0, 50)}...`
                          : uploadedDocName}
                      </Typography>
                    </Tooltip>
                  </div>
                </Container>
              </div>
            ) : (
              <>
                {uploading ? (
                  <div className={classes.uploadContainer}>
                    <Container maxWidth="xs">
                      <div className={classes.uploadExcelLoading}>
                        <CircularProgress size={50} />
                      </div>
                    </Container>
                  </div>
                ) : (
                  <DragAndDrop
                    data={data}
                    dispatch={dispatchh}
                    useDefaultExcelConditionCheck={false}
                    validateSelections={validateSelections}
                  >
                    <div className={classes.dragDropContainer}>
                      <Container maxWidth="xs">
                        <Typography
                          data-testid="upload-text"
                          className={classes.uploadText}
                        >
                          Drag and drop the draw file here if you already have
                          the draw data in an excel format.
                          <br />
                          <br /> Else, click the button below to upload.
                        </Typography>
                      </Container>
                    </div>
                  </DragAndDrop>
                )}
              </>
            )}
          </Grid>
          <Box
            style={{
              fontSize: "12px",
              color: "#7a8aa0",
              margin: "20px 0 0",
              textAlign: "center"
            }}
          >
            {!docUploaded && (
              <BrowseFileButton
                text="Upload Excel"
                startIcon={<UploadExcelIcon />}
                disabled={isBulkUplodDisabled}
                testId="upload-button"
                onChange={(e: any) => {
                  if (validateSelections()) {
                    handleUpload(e.target.files);
                  }
                }}
                dontAllowMultipleSelect
              />
            )}
            {docUploaded && (
              <Grid>
                <Button
                  variant="contained"
                  data-testid="create-loan-excel-button"
                  color="primary"
                  startIcon={<img src={remove} alt="manual_remove_draw" />}
                  onClick={handleDocRemove}
                  disabled={uploading}
                >
                  Remove
                </Button>
                <Button
                  variant="contained"
                  data-testid="create-loan-excel-button"
                  color="info"
                  startIcon={<img src={proceed} alt="manual_create_loan" />}
                  onClick={handleProceedClick}
                  disabled={uploading}
                >
                  {uploading && <CircularProgress size={14} />}
                  Proceed
                </Button>
              </Grid>
            )}
            <Box my={2}>
              <Typography
                style={{
                  fontSize: "12px",
                  color: "#7a8aa0"
                }}
              >
                or
              </Typography>
            </Box>
          </Box>
          <Grid
            style={{
              marginBottom: "30px"
            }}
          >
            <Button
              variant="contained"
              data-testid="create-manually-button"
              color="primary"
              onClick={createDrawManually}
              disabled={isManualCreateDisabled}
            >
              {loading && <CircularProgress size={14} />}
              Manually Create the Draw
            </Button>
          </Grid>
        </Box>
      </Container>
      {openWarning && (
        <WarningModal
          isOpen={openWarning.isOpen}
          handleClose={openWarning.handleClose}
          header={openWarning.header}
          handleOptional={openWarning.handleOptional}
          body={openWarning.body}
          primaryBtnName={openWarning.primaryBtnName}
        />
      )}
    </div>
  );
};
