// import { UPDATE_TOAST } from "../../../../stores/documentReview/DocumentReview.action";
import { updateToast } from "../../../../stores/documentReview/DocumentReview.reducer";
import { delay } from "../../../../utils/utils";
import { ttfAction } from "../../constants";

export enum snackMessageType {
  ERROR = "error",
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning"
}

export const splitViewEmptyValueMessage = {
  message: "Please enter a valid page number.",
  messageType: snackMessageType.ERROR
};

export const splitViewSmallValueMessage = {
  message: "Please enter a number greater than 0.",
  messageType: snackMessageType.ERROR
};

export const splitViewLastGreaterMessage = {
  message: "Last page number cannot be greater than or equal to first page.",
  messageType: snackMessageType.ERROR
};

export const splitViewGreaterValueMessage = {
  message: "Please enter a number less than the total page of the pdf.",
  messageType: snackMessageType.ERROR
};

export const documentSplitFailedMessage = (pdfFile: any) => {
  return {
    message: `Split failed for ${pdfFile}. Please try again.`,
    messageType: snackMessageType.ERROR
  };
};

export const splitViewStartGreaterMessage = {
  message: "First page number cannot be greater than or equal to last page.",
  messageType: snackMessageType.ERROR
};

export const splitSuccessMessage = {
  message: "Split successfully",
  messageType: snackMessageType.SUCCESS
};

export const savedSuccessMessage = {
  message: "Saved successfully",
  messageType: snackMessageType.SUCCESS
};

export const saveFailedMessage = {
  message: "Save failed. Please check the input or try again later.",
  messageType: snackMessageType.SUCCESS
};

export const submittedSuccessMessage = {
  message: "Updated to TC successfully",
  messageType: snackMessageType.SUCCESS
};

export const submissionFailedMessage = {
  message: "Updation to TC failed. Please check the input or try again later.",
  messageType: snackMessageType.ERROR
};

export const somethingFailedMessage = {
  message: "Something went wrong. Please check the input or try again later.",
  messageType: snackMessageType.ERROR
};

export const ttfReviewErrorInputMessage = {
  message: "Enter valid input.",
  messageType: snackMessageType.ERROR
};

export const invalidBoundaryMessage = {
  message: "Highlighted boundaries are invalid.",
  messageType: snackMessageType.ERROR
};

export const overlappingBoundaryMessage = {
  message: "Highlighted boundaries are overlapping.",
  messageType: snackMessageType.ERROR
};

export const emptyTagCodeMessage = {
  message: "Please select tag.",
  messageType: snackMessageType.ERROR
};

export const allPagesCoverMessage = {
  message: "Check for incomplete data.",
  messageType: snackMessageType.ERROR
};

export const closingPackageTypeError = {
  message: "Cannot tag a non PDF document as a Closing Package",
  messageType: snackMessageType.ERROR
};

export const snackBarOpenAndClose = async (
  documentReviewDispatch: any,
  configuration: any
) => {
  documentReviewDispatch({
    type: ttfAction.UPDATE_TAB,
    payload: {
      value: {
        showToastMessage: true,
        toastMessage: configuration
      }
    }
  });
  await delay(4000);
  documentReviewDispatch({
    type: ttfAction.UPDATE_TAB,
    payload: {
      value: {
        showToastMessage: false
      }
    }
  });
};

export const snackBarOpenAndCloseReconcileView = async (
  dispatch: any,
  configuration: any
) => {
  dispatch(
    updateToast({
      showToastMessage: true,
      ...configuration
    })
  );
  await delay(4000);
  dispatch(
    updateToast({
      showToastMessage: false
    })
  );
  // dispatch({
  //   type: UPDATE_TOAST,
  //   payload: {
  //     showToastMessage: false
  //   }
  // });
};
