import {
  accuralTypeDropDown,
  decimalType,
  numberType,
  prepaymentPenalty,
  prepaymentPenaltyTypeDropDown,
  rateType,
  textType
} from "../../constants";

import {
  formatValueByType,
  sanitizeCurrency,
  sanitizeNumericInput,
  sanitizePercentage
} from "../../../utils/formatChecks";
import {
  isValidCurrency,
  isValidDecimal,
  isValidNumber,
  isValidPercentage,
  cleanCurrency,
  cleanDecimal,
  cleanPercentage,
} from "@toorak/tc-common-util-sdk/dist/errorCountService/fieldMissingCount";
import { isRateAdjustable } from "../../../create-loan/bridge-loan-details/LoanDetailsLoanEconomics";
import {
  armIndexTypes,
  closingCostValues,
  yesNoDropdown
} from "../../../create-loan/constants/loanCreationDropDownValues";
import { isLATUser } from "../../../utils/AccessManagement";
import { LoanSizerEnum, totalRehabBudgeFormula } from "../../../frontend-sizer/FrontendSizerTemplate";
import { ToorakStatusEnum } from "../../../create-loan/documents/CustodyDocuments/enumsAndConstants";
import { getTwoDecimalPercentageValue } from "../../../utils/utils";
import { getBooleanDropDownValue } from "../../views/common/util";

export const armIndexValues = armIndexTypes.map((item: string) => ({
  value: item,
  label: item
}));

const originalLoanAmount = {
  fieldName: "originalLoanAmount", // used in requestObject
  fieldLabel: "Borrower Requested ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  fieldType: () => "currency",
  isVisible: () => true,
  required: () => false,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const interestRate = {
  fieldName: "interestRate",
  backendKey: "interestRateAsOfCutOffDate",
  fieldLabel: "Original Interest Rate (%)",
  ChangeValueMethod: cleanPercentage,
  FocusOutMethod: sanitizePercentage,
  errorChecks: isValidNumber,
  fieldType: () => "percentageNoWithout100x",
  isVisible: () => true,
  required: () => false,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const dscrInterestRate = {
  fieldName: "interestRate",
  backendKey: "interestRateAsOfCutOffDate",
  ChangeValueMethod: cleanPercentage,
  FocusOutMethod: sanitizePercentage,
  errorChecks: isValidPercentage,
  fieldLabel: "Interest Rate (%)",
  fieldType: () => "percentageNoWithout100x",
  isVisible: () => true,
  required: () => true,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const prepaymentPenaltyonths: any = {
  fieldName: "prepaymentPenalty",
  backendKey: "prepaymentPenaltyMonths",
  fieldLabel: "Prepayment Penalty (Months)",
  fieldType: () => "dropDown",
  ruleFieldName: "prepaymentPenaltyMonths",
  dropDownData: prepaymentPenalty.map((item: any) => ({
    value: item,
    label: item
  })),
  isVisible: () => true,
  required: () => false,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const prepayPenaltyType = {
  fieldName: "prepayPenaltyType",
  backendKey: "prepayPenaltyType",
  fieldLabel: "Prepay Penalties Type",
  fieldType: () => "dropDown",
  dropDownData: prepaymentPenaltyTypeDropDown.map((item: any) => ({
    value: item,
    label: item
  })),
  isVisible: () => true,
  required: () => false,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const rateTypeDropDown = {
  fieldName: "rateType",
  backendKey: "rateType",
  fieldLabel: "Rate Type",
  fieldId: "fes-rateType",
  fieldType: () => "dropDown",
  dropDownData: rateType
    ?.filter((it: any) => it !== "Adjustable")
    ?.map((item: any) => ({
      value: item,
      label: item
    })),
  isVisible: () => true,
  required: () => false,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const interestOnlyPeriod = {
  fieldName: "interestOnlyPeriod",
  backendKey: "interestOnlyPeriod",
  fieldLabel: "Interest Only Period (Months)",
  fieldId: "fes-interestOnlyPeriod",
  fieldType: () => "number",
  isVisible: () => true,
  required: () => false,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const LoanLevelOccupancy = {
  fieldName: "loanLevelOccupancy",
  fieldLabel: "Loan Level Occupancy Percentage",
  fieldType: () => "percentage",
  // rules: (inputs: any) => {
  //   const { loanEvaluationResult, loanId } = inputs;
  //   return formatValueByType(
  //     loanEvaluationResult?.loanResult &&
  //       loanEvaluationResult.loanResult[loanId]?.loanEconomics
  //         ?.loanLevelOccupancy,
  //     "percentage"
  //   );
  // },
  fieldId: "loanLevelOccupancy-fes",
  ruleFieldName: "loanLevelOccupancy",
  isVisible: () => true,
  required: () => false,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    ToorakStatusEnum,
  getValue: (loanEconomics: any) =>
    getTwoDecimalPercentageValue(
      formatValueByType(loanEconomics.loanLevelOccupancy, "percentage")
    )
};

const financialInterestReserve = {
  fieldName: "financialInterestReserve",
  backendKey: "financedInterestReserve",
  editedLabel: "financedInterestReserve",
  fieldLabel: "Financed Interest Reserve ($)",
  required: () => true,
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  fieldType: () => "currency",
  errorMsg: "Please enter value in proper currency format",
  testField: "financed-interest-reserve",
  type: numberType,
  isVisible: () => true,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const interestReserve = {
  fieldName: "interestReserve",
  backendKey: "interestReserve",
  editedLabel: "interestReserve",
  fieldLabel: "Interest Reserve ($)",
  required: () => true,
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  fieldType: () => "currency",
  errorMsg: "Please enter value in proper currency format",
  testField: "interest-reserve",
  type: numberType,
  isVisible: () => true,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const loanAmount = {
  fieldName: "loanAmount",
  backendKey: "originalLoanAmount",
  editedLabel: "originalLoanAmount",
  fieldLabel: "Initial Loan Amount ($)",
  errorChecks: isValidCurrency,
  required: () => true,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  fieldType: () => "currency",
  errorMsg: "Please enter value in proper currency format",
  testField: "loan-amount",
  type: numberType,
  isVisible: () => true,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const maxLoanAmount = {
  fieldName: "maxLoanAmount",
  backendKey: "originalMaximumLoanAmount",
  editedLabel: "maxLoanAmount",
  fieldLabel: "Original Maximum Loan Amount ($)",
  errorChecks: isValidCurrency,
  required: () => true,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  fieldType: () => "currency",
  errorMsg: "Please enter value in proper currency format",
  testField: "loan-amount",
  type: numberType,
  isVisible: (loanEconomics: any, loanType: any) => loanType === "BridgeLoan",
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const financedBudgetAmount = {
  fieldName: "financedBudgetAmount",
  backendKey: "financedBudgetAmount",
  editedLabel: "financedBudgetAmount",
  fieldLabel: "Financed Budget Amount ($)",
  errorChecks: isValidCurrency,
  required: () => true,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  fieldType: () => "currency",
  errorMsg: "Please enter value in proper currency format",
  testField: "loan-amount",
  type: numberType,
  isVisible: (loanEconomics: any, loanType: any) => loanType === "BridgeLoan",
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const cutOffLoanAmount = {
  fieldName: "cutOffLoanAmount",
  backendKey: "cutOffDateLoanAmount",
  editedLabel: "cutOffLoanAmount",
  fieldLabel: "Cut-off Date Loan Amount ($)",
  errorChecks: isValidCurrency,
  required: () => true,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  fieldType: () => "currency",
  errorMsg: "Please enter value in proper currency format",
  testField: "loan-amount",
  type: numberType,
  isVisible: (loanEconomics: any, loanType: any) => loanType === "BridgeLoan",
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const cutOffMaxLoanAmount = {
  fieldName: "cutOffMaxLoanAmount",
  backendKey: "cutOffDateMaximumLoanAmount",
  editedLabel: "cutOffMaxLoanAmount",
  fieldLabel: "Cut-off Date Maximum Loan Amount ($)",
  errorChecks: isValidCurrency,
  required: () => true,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  fieldType: () => "currency",
  errorMsg: "Please enter value in proper currency format",
  testField: "loan-amount",
  type: numberType,
  isVisible: (loanEconomics: any, loanType: any) => loanType === "BridgeLoan",
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const budgetAmount = {
  fieldName: "budgetAmount",
  backendKey: "totalBudgetAmount",
  editedLabel: "budgetAmount",
  fieldLabel: "Total Rehab Budget ($)",
  errorChecks: isValidCurrency,
  required: () => true,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  fieldType: () => "currency",
  errorMsg: "Please enter value in proper currency format",
  testField: "loan-amount",
  type: numberType,
  isVisible: (loanEconomics: any, loanType: any) => loanType === "BridgeLoan",
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const currentLoanBalance = {
  fieldName: "currentLoanBalance",
  backendKey: "currentLoanBalance",
  editedLabel: "currentLoanBalance",
  fieldLabel: "Current Loan Balance($)",
  errorChecks: isValidCurrency,
  required: () => true,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  fieldType: () => "currency",
  errorMsg: "Please enter value in proper currency format",
  testField: "current-loan-balance",
  type: numberType,
  isVisible: () => true,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const bridgeInterestRate = {
  fieldName: "interestRate",
  backendKey: "interestRateAsOfCutOffDate",
  editedLabel: "interestRate",
  fieldLabel: "Interest Rate (%)",
  required: () => true,
  ChangeValueMethod: cleanPercentage,
  FocusOutMethod: sanitizePercentage,
  errorChecks: isValidPercentage,
  fieldType: () => "percentageNoWithout100x",
  errorMsg: "Please enter value in Decimal",
  testField: "interest-rate",
  type: numberType,
  isVisible: () => true,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const rateTypeField = {
  fieldName: "rateType",
  editedLabel: "rateType",
  fieldLabel: "Rate Type",
  dropDownData: rateType.map((item) => ({
    value: item,
    label: item
  })),
  fieldType: () => "dropDown",
  required: () => true,
  errorMsg: "Please add rate type",
  testField: "accrual-type-form-control",
  type: textType,
  isVisible: () => true,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const dscrRateTypeField = {
  fieldName: "rateType",
  editedLabel: "rateType",
  fieldLabel: "Rate Type",
  dropDownData: rateType
    ?.filter((it: any) => it !== "Adjustable")
    ?.map((item: any) => ({
      value: item,
      label: item
    })),
  fieldType: () => "dropDown",
  required: () => true,
  errorMsg: "Please add rate type",
  testField: "accrual-type-form-control",
  type: textType,
  isVisible: () => true,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const accrualType = {
  fieldName: "accrualType",
  backendKey: "accrualType",
  editedLabel: "accrualType",
  fieldLabel: "Accrual Type",
  required: () => true,
  errorMsg: "Please add accural type",
  dropDownData:
    accuralTypeDropDown.map((item: any) => ({
      value: item,
      label: item
    })) || [],
  testField: "accrual-type-form-control",
  type: textType,
  isVisible: () => true,
  fieldType: () => "dropDown",
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const cashOutAmount = {
  fieldName: "cashOutAmount",
  backendKey: "cashOutAmount",
  editedLabel: "cashOutAmount",
  fieldLabel: "Cash Out Amount ($)",
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  errorChecks: isValidCurrency,
  fieldType: () => "currency",
  errorMsg: "Please enter value in Decimal",
  testField: "cash-out-amount",
  type: numberType,
  isVisible: () => true,
  required: () => true,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const includeOutOfPocketBudgetInARLTV = {
  fieldName: "includeOutOfPocketBudgetInARLTV",
  backendKey: "includeOutOfPocketBudgetInARLTV",
  editedLabel: "includeOutOfPocketBudgetInARLTV",
  fieldLabel: "Include Out Of Pocket Budget In ARLTV",
  fieldType: () => "checkBox",
  isVisible: () => isLATUser(),
  required: () => false,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const borrowerLiquidity = {
  fieldName: "borrowerLiquidity",
  backendKey: "borrowerLiquidity",
  editedLabel: "borrowerLiquidity",
  fieldLabel: "Borrower Liquidity ($)",
  required: () => true,
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  fieldType: () => "currency",
  errorMsg: "Please enter value in proper currency format",
  testField: "borrower-liquidity-reserve",
  type: numberType,
  isVisible: () => true,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const discountPoints = {
  fieldName: "discountPoints",
  backendKey: "totalOriginationAndDiscountPoints",
  editedLabel: "totalOriginationAndDiscountPoints",
  fieldLabel: "Total Origination and Discount Points (%)",
  ChangeValueMethod: cleanPercentage,
  FocusOutMethod: sanitizePercentage,
  fieldType: () => "percentageWithout100x",
  errorChecks: isValidPercentage,
  errorMsg: "Please enter value in Decimal",
  testField: "origination-and-discount-points",
  type: numberType,
  isVisible: () => true,
  required: () => true,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const armIndex = {
  fieldName: "armIndex",
  editedLabel: "armIndex",
  fieldLabel: "ARM Index",
  ruleField: "loanEconomics.armIndex",
  isFormula: true,
  errorMsg: "Please Add Arm Index",
  testField: "arm-index",
  fieldType: () => "dropDown",
  dropDownData: armIndexValues,
  type: textType,
  required: (params: any) => isRateAdjustable(params),
  isVisible: (params: any) => isRateAdjustable(params),
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const grossArmMargin = {
  fieldName: "grossArmMargin",
  editedLabel: "grossArmMargin",
  fieldLabel: "Gross Margin (For ARMs)",
  errorMsg: "Please Add Arm Gross Margin",
  testField: "arm-gross-margin",
  ChangeValueMethod: cleanPercentage,
  FocusOutMethod: sanitizePercentage,
  fieldType: () => "percentageWithout100x",
  errorChecks: isValidPercentage,
  type: numberType,
  required: (params: any) => isRateAdjustable(params),
  isVisible: (params: any) => isRateAdjustable(params),
  // errorChecks: isValidDecimal,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const initialRateAdjustmentPeriod = {
  fieldName: "initialRateAdjustmentPeriod",
  backendKey: "initialRateAdjustmentPeriod",
  editedLabel: "initialRateAdjustmentPeriod",
  fieldLabel: "Initial Rate Adjustment Period",
  errorMsg: "Please enter rate period in number",
  testField: "initial-rate-adjustment-period",
  ChangeValueMethod: sanitizeNumericInput,
  errorChecks: isValidNumber,
  fieldType: () => "number",
  type: numberType,
  required: (params: any) => isRateAdjustable(params),
  isVisible: (params: any) => isRateAdjustable(params),
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const initialPayAdjustmentPeriod = {
  fieldName: "initialPayAdjustmentPeriod",
  editedLabel: "initialPayAdjustmentPeriod",
  fieldLabel: "Initial Pay Adjustment Period",
  ruleField: "loanEconomics.initialPayAdjustmentPeriod",
  errorMsg: "Please enter pay period in number",
  testField: "initial-pay-adjustment-period",
  ChangeValueMethod: sanitizeNumericInput,
  errorChecks: isValidNumber,
  fieldType: () => "number",
  type: numberType,
  required: (params: any) => isRateAdjustable(params),
  isVisible: (params: any) => isRateAdjustable(params),
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const initialInterestRateDown = {
  fieldName: "initialInterestRateDown",
  editedLabel: "initialInterestRateDown",
  fieldLabel: "Initial Interest Rate Cap (Change Down)",
  ruleField: "loanEconomics.initialInterestRateDown",
  isFormula: true,
  errorMsg: "Please enter Loan level occupancy",
  testField: "loanLevelOccupancy",
  ChangeValueMethod: sanitizeNumericInput,
  fieldType: () => "percentageWithout100x",
  errorChecks: isValidNumber,
  type: numberType,
  required: (params: any) => isRateAdjustable(params),
  isVisible: (params: any) => isRateAdjustable(params),
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};
const initialInterestRateUp = {
  fieldName: "initialInterestRateUp",
  editedLabel: "initialInterestRateUp",
  fieldLabel: "Initial Interest Rate Cap (Change Up)",
  ruleField: "loanEconomics.initialInterestRateUp",
  isFormula: true,
  errorMsg: "Please enter Loan level occupancy",
  testField: "loanLevelOccupancy",
  ChangeValueMethod: sanitizeNumericInput,
  fieldType: () => "percentageWithout100x",
  errorChecks: isValidNumber,
  type: numberType,
  required: (params: any) => isRateAdjustable(params),
  isVisible: (params: any) => isRateAdjustable(params),
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const lifetimeMaxRate = {
  fieldName: "lifetimeMaxRate",
  backendKey: "lifetimeMaxRate",
  editedLabel: "lifetimeMaxRate",
  fieldLabel: "Lifetime Max Rate",
  ruleField: "loanEconomics.lifetimeMaxRate",
  errorMsg: "Please enter value in Decimal",
  testField: "life-time-max-rate",
  ChangeValueMethod: cleanPercentage,
  FocusOutMethod: sanitizePercentage,
  fieldType: () => "percentageWithout100x",
  errorChecks: isValidPercentage,
  type: numberType,
  required: (params: any) => isRateAdjustable(params),
  isVisible: (params: any) => isRateAdjustable(params),
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const lifetimeMinRate = {
  fieldName: "lifetimeMinRate",
  backendKey: "lifetimeMinRate",
  editedLabel: "lifetimeMinRate",
  fieldLabel: "Lifetime Min Rate",
  ruleField: "loanEconomics.lifetimeMinRate",
  ChangeValueMethod: cleanPercentage,
  FocusOutMethod: sanitizePercentage,
  fieldType: () => "percentageWithout100x",
  errorChecks: isValidPercentage,
  errorMsg: "Please enter value in Decimal",
  testField: "life-time-min-rate",
  type: numberType,
  required: (params: any) => isRateAdjustable(params),
  isVisible: (params: any) => isRateAdjustable(params),
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const subsequentInterestRateDown =
{
  fieldName: "subsequentInterestRateDown",
  editedLabel: "subsequentInterestRateDown",
  fieldLabel: "Subsequent Interest Rate Cap (Change Down)",
  ruleField: "loanEconomics.subsequentInterestRateDown",
  isFormula: true,
  errorMsg: "Please enter Loan level occupancy",
  testField: "loanLevelOccupancy",
  ChangeValueMethod: sanitizeNumericInput,
  fieldType: () => "percentageWithout100x",
  errorChecks: isValidNumber,
  type: numberType,
  required: (params: any) => isRateAdjustable(params),
  isVisible: (params: any) => isRateAdjustable(params),
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};
const subsequentInterestRateUp =
{
  fieldName: "subsequentInterestRateUp",
  editedLabel: "subsequentInterestRateUp",
  fieldLabel: "Subsequent Interest Rate Cap (Change Up)",
  ruleField: "loanEconomics.subsequentInterestRateUp",
  isFormula: true,
  errorMsg: "Please enter Loan level occupancy",
  testField: "loanLevelOccupancy",
  ChangeValueMethod: sanitizeNumericInput,
  fieldType: () => "percentageWithout100x",
  errorChecks: isValidNumber,
  type: numberType,
  required: (params: any) => isRateAdjustable(params),
  isVisible: (params: any) => isRateAdjustable(params),
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const paymentAdjustmentFrequency = {
  fieldName: "paymentAdjustmentFrequency",
  editedLabel: "paymentAdjustmentFrequency",
  fieldLabel: "Payment Adjustment Frequency",
  ruleField: "loanEconomics.paymentAdjustmentFrequency",
  errorMsg: "Please enter payment Adjustment Frequency",
  testField: "payment-adjustment-frequency",
  fieldType: () => "numberString",
  ChangeValueMethod: sanitizeNumericInput,
  errorChecks: isValidNumber,
  type: numberType,
  required: (params: any) => isRateAdjustable(params),
  isVisible: (params: any) => isRateAdjustable(params),
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const rateAdjustmentFrequency = {
  fieldName: "rateAdjustmentFrequency",
  editedLabel: "rateAdjustmentFrequency",
  fieldLabel: "Rate Adjustment Frequency",
  ruleField: "loanEconomics.rateAdjustmentFrequency",
  errorMsg: "Please enter rate Adjustment Frequency",
  testField: "rate-adjustment-frequency",
  fieldType: () => "numberString",
  ChangeValueMethod: sanitizeNumericInput,
  errorChecks: isValidNumber,
  type: numberType,
  required: (params: any) => isRateAdjustable(params),
  isVisible: (params: any) => isRateAdjustable(params),
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const armLookBackDays =
{
  fieldName: "armLookBackDays",
  editedLabel: "armLookBackDays",
  fieldLabel: "ARM Look Back Days",
  ruleField: "loanEconomics.armLookBackDays",
  isFormula: true,
  ruleValueType: "number",
  errorMsg: "Please enter Loan level occupancy",
  testField: "loanLevelOccupancy",
  ChangeValueMethod: sanitizeNumericInput,
  fieldType: () => "number",
  errorChecks: isValidNumber,
  type: numberType,
  required: (params: any) => isRateAdjustable(params),
  isVisible: (params: any) => isRateAdjustable(params),
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const armRoundFlag = {
  fieldName: "armRoundFlag",
  editedLabel: "armRoundFlag",
  fieldLabel: "ARM Round Flag",
  required: (params: any) => isRateAdjustable(params),
  errorMsg: "Please enter Loan level occupancy",
  testField: "loanLevelOccupancy",
  fieldType: () => "dropDown",
  dropDownData:
    yesNoDropdown.map((item: any) => ({
      value: item,
      label: item
    })) || [],
  errorChecks: textType,
  isVisible: (params: any) => isRateAdjustable(params),
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const armRoundingFactor =
{
  fieldName: "armRoundingFactor",
  editedLabel: "armRoundingFactor",
  fieldLabel: "Arm Rounding Factor",
  ruleField: "loanEconomics.armRoundingFactor",
  isFormula: true,
  errorMsg: "Please enter Loan level occupancy",
  testField: "loanLevelOccupancy",
  ChangeValueMethod: sanitizeNumericInput,
  fieldType: () => "percentageWithout100x",
  errorChecks: isValidNumber,
  type: numberType,
  required: (params: any) => isRateAdjustable(params),
  isVisible: (params: any) => isRateAdjustable(params),
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const subsequentPeriodicCap = {
  fieldName: "subsequentPeriodicCap",
  editedLabel: "subsequentPeriodicCap",
  fieldLabel: "Subsequent Periodic Cap",
  ruleField: "loanEconomics.subsequentPeriodicCap",
  errorMsg: "Please enter subsequent period",
  testField: "subsequent-periodic-cap",
  ChangeValueMethod: cleanDecimal,
  FocusOutMethod: formatValueByType,
  fieldType: () => "number",
  errorChecks: isValidDecimal,
  type: decimalType,
  required: (params: any) => isRateAdjustable(params),
  isVisible: (params: any) => isRateAdjustable(params),
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};


const costToLoanRatio = {
  fieldName: "costToLoanRatio",
  backendKey: "costToLoanRatio",
  editedLabel: "costToLoanRatio",
  fieldLabel: "LTV(Loan to Value)",
  ChangeValueMethod: cleanPercentage,
  FocusOutMethod: sanitizePercentage,
  fieldType: () => "percentageNoWithout100x",
  errorChecks: isValidPercentage,
  errorMsg: "Please enter value in Decimal i.e, 12%",
  testField: "cltr-value",
  type: numberType,
  isVisible: () => true,
  required: () => true,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const businessPurposeOccupancy = {
  fieldName: "businessPurposeOccupancy",
  backendKey: "businessPurposeOccupancy",
  editedLabel: "businessPurposeOccupancy",
  fieldLabel: "Business Purpose Occupancy",
  required: () => true,
  errorMsg: "Please add business Purpose",
  fieldType: () => "booleanDropDown",
  dropDownData:
    yesNoDropdown.map((item: any) => ({
      value: item,
      label: item
    })) || [],
  testField: "business-purpose-occupancy-form-control",
  getValue: (loanEconomics: any) => {
    return getBooleanDropDownValue(loanEconomics?.businessPurposeOccupancy, true);
  },
  type: textType,
  isVisible: () => true,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const subOrdinateFinancing = {
  fieldName: "subordinateFinancing",
  backendKey: "subordinateFinancing",
  editedLabel: "subOrdinateFinancing",
  fieldLabel: "Subordinate Financing",
  required: () => true,
  errorMsg: "Please add subordinate Financing",
  testField: "subordinate-financing-form-control",
  fieldType: () => "booleanDropDown",
  getValue: (loanEconomics: any) => {
    return getBooleanDropDownValue(loanEconomics?.subordinateFinancing, true);
  },
  dropDownData:
    yesNoDropdown.map((item: any) => ({
      value: item,
      label: item
    })) || [],
  type: textType,
  isVisible: () => true,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const dscrPrepayPenaltyType = {
  fieldName: "prepayPenaltyType",
  backendKey: "prepayPenaltyType",
  editedLabel: "prepayPenaltyType",
  fieldLabel: "Prepayment Penalty Type",
  required: () => true,
  errorMsg: "Please add Prepay Penalty Type",
  fieldType: () => "dropDown",
  dropDownData:
    prepaymentPenaltyTypeDropDown.map((item: any) => ({
      value: item,
      label: item
    })) || [],
  testField: "prepayPenaltyType-form-control",
  type: textType,
  isVisible: () => true,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const debtServiceCoverageRatio = {
  fieldName: "debtServiceCoverageRatio",
  backendKey: "debtServiceCoverageRatio",
  isFormula: true,
  ruleField: "loanEconomics.dscr",
  ruleValueType: "decimal",
  editedLabel: "dscr",
  fieldLabel: "DSCR",
  errorMsg: "Please enter value in Decimal i.e, 1.4",
  testField: "dscr-value",
  ChangeValueMethod: cleanDecimal,
  FocusOutMethod: formatValueByType,
  fieldType: () => "number",
  errorChecks: isValidDecimal,
  type: decimalType,
  isVisible: () => true,
  required: () => false,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) => true
};

const qualifyingPaymentInReservesMonthly = {
  fieldName: "qualifyingPaymentInReservesMonthly",
  backendKey: "qualifyingPaymentInReservesMonthly",
  editedLabel: "qualifyingPaymentInReservesMonthly",
  fieldLabel: "Months of qualifying payment in reserves",
  isFormula: true,
  ruleField: "loanEconomics.monthsOfQualifyingPaymentInReserves",
  ruleValueType: "decimal",
  FocusOutMethod: formatValueByType,
  fieldType: () => "number",
  errorMsg: "Please enter valid Decimal value",
  testField: "months-Of-qualifying-payment-cap",
  ChangeValueMethod: cleanDecimal,
  errorChecks: isValidDecimal,
  type: decimalType,
  isVisible: () => true,
  required: () => false,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) => true
};

const prepaymentPenaltyMonths = {
  fieldName: "prepaymentPenaltyMonths",
  backendKey: "prepaymentPenaltyMonths",
  editedLabel: "prepaymentPenaltyMonths",
  fieldLabel: "Prepayment Penalty Months",
  ruleField: "loanEconomics.prePaymentPenaltyMonths",
  isFormula: true,
  ruleValueType: "number",
  errorMsg: "Please enter Prepayment Penalty Months",
  testField: "prepayment-penalty-months",
  ChangeValueMethod: sanitizeNumericInput,
  fieldType: () => "number",
  errorChecks: isValidNumber,
  type: numberType,
  isVisible: () => true,
  required: () => false,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) => true
};

const dscrLoanLevelOccupancy = {
  fieldName: "loanLevelOccupancy",
  editedLabel: "loanLevelOccupancy",
  fieldLabel: "Loan Level Occupancy Percentage",
  ruleField: "loanEconomics.loanLevelOccupancy",
  isFormula: true,
  errorMsg: "Please enter Loan level occupancy",
  testField: "loanLevelOccupancy",
  ChangeValueMethod: sanitizeNumericInput,
  fieldType: () => "percentage",
  errorChecks: isValidNumber,
  type: numberType,
  isVisible: () => true,
  required: () => false,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) => true
};

const dscrInterestOnlyPeriod = {
  fieldName: "interestOnlyPeriod",
  editedLabel: "interestOnlyPeriod",
  fieldLabel: "Interest Only Period",
  errorMsg: "Please Add Interest Period in number",
  testField: "interest-only-period",
  ChangeValueMethod: sanitizeNumericInput,
  errorChecks: isValidNumber,
  fieldType: () => "number",
  type: numberType,
  isVisible: () => true,
  required: () => false,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};


const initialPeriodicCap = {
  fieldName: "initialPeriodicCap",
  editedLabel: "initialPeriodicCap",
  fieldLabel: "Initial Periodic Cap",
  errorMsg: "Please enter valid periodic cap",
  testField: "initial-periodic-cap",
  ChangeValueMethod: cleanDecimal,
  errorChecks: isValidDecimal,
  FocusOutMethod: formatValueByType,
  fieldType: () => "number",
  type: decimalType,
  required: (params: any) => isRateAdjustable(params),
  isVisible: (params: any) => isRateAdjustable(params),
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const existDebt = {
  fieldName: "existDebt",
  editedLabel: "existDebt",
  fieldLabel: "Exist Debt ($)",
  testField: "exist-debt-fes",
  fieldType: () => "currency",
  type: decimalType,
  required: (params: any) => true,
  isVisible: (params: any) => true,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const hardCost = {
  fieldName: "hardCost",
  editedLabel: "hardCost",
  fieldLabel: "Hard Costs ($)",
  testField: "hard-cost-fes",
  fieldType: () => "currency",
  type: decimalType,
  required: (params: any) => true,
  isVisible: (params: any) => true,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const softCost = {
  fieldName: "softCost",
  editedLabel: "softCost",
  fieldLabel: "Soft Cost (cap at 15% Rehab)($)",
  testField: "soft-cost-fes",
  fieldType: () => "currency",
  type: decimalType,
  required: (params: any) => true,
  isVisible: (params: any) => true,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const rehabAmount = {
  fieldName: "rehabAmount",
  backendKey: "rehabAmount",
  editedLabel: "rehabAmount",
  fieldLabel: "Rehab Loan Amount ($)",
  errorChecks: isValidCurrency,
  required: () => false,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  fieldType: () => "currency",
  errorMsg: "Please enter value in proper currency format",
  testField: "rehab-amount",
  type: numberType,
  isVisible: (loanEconomics: any, loanType: any) => true,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) => true,
  getValue: (loanEconomics: any) => {
    return totalRehabBudgeFormula({ loanDetails: { fesLoanEconomics: loanEconomics } });
  }

};

const bridgeFesGrUpTotalOriginationAndDiscountPoints = {
  fieldName: "totalOriginationAndDiscountPoints",
  backendKey: "totalOriginationAndDiscountPoints",
  editedLabel: "totalOriginationAndDiscountPoints",
  fieldLabel: "Origination & Broker Fees (%)",
  fieldType: () => "dropDown",
  dropDownData: closingCostValues,
  testField: "origination-and-discount-points",
  type: numberType,
  isVisible: () => true,
  required: () => false,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const fesLoanEconomicsFields: any = [
  { ...originalLoanAmount, required: () => true },
  { ...interestRate, required: () => true },
  prepaymentPenaltyonths,
  prepayPenaltyType,
  rateTypeDropDown,
  { ...interestOnlyPeriod, required: () => true },
  LoanLevelOccupancy
];

const fesBridge14Fields = [
  { ...budgetAmount, fieldName: "rehabBudget" },
  { ...loanAmount, fieldName: "originalLoanAmount" },
  { ...interestRate, required:() => true },
  {
    ...discountPoints,
    fieldName: "totalOriginationAndDiscountPoints",
    backendKey: "totalOriginationAndDiscountPoints"
  },
  includeOutOfPocketBudgetInARLTV
];

const postDSCRFields: any = [
  financialInterestReserve,
  loanAmount,
  currentLoanBalance,
  dscrInterestRate,
  dscrRateTypeField,
  accrualType,
  cashOutAmount,
  { ...borrowerLiquidity, required: () => false },
  { ...discountPoints, required: () => false },
  // armIndex,
  // grossArmMargin,
  dscrInterestOnlyPeriod,
  // initialRateAdjustmentPeriod,
  // initialPayAdjustmentPeriod,
  // initialPeriodicCap,
  // lifetimeMaxRate,
  // lifetimeMinRate,
  // subsequentPeriodicCap,
  // paymentAdjustmentFrequency,
  // rateAdjustmentFrequency,
  costToLoanRatio,
  businessPurposeOccupancy,
  subOrdinateFinancing,
  dscrPrepayPenaltyType,
  debtServiceCoverageRatio,
  qualifyingPaymentInReservesMonthly,
  prepaymentPenaltyMonths,
  dscrLoanLevelOccupancy
];

const preDSCRFields: any = [
  financialInterestReserve,
  loanAmount,
  currentLoanBalance,
  { ...dscrInterestRate, required: () => true },
  dscrRateTypeField,
  accrualType,
  cashOutAmount,
  { ...borrowerLiquidity, required: () => false },
  { ...discountPoints, required: () => false },
  // armIndex,
  // grossArmMargin,
  dscrInterestOnlyPeriod,
  // initialRateAdjustmentPeriod,
  // initialPayAdjustmentPeriod,
  // initialPeriodicCap,
  // lifetimeMaxRate,
  // lifetimeMinRate,
  // subsequentPeriodicCap,
  // paymentAdjustmentFrequency,
  // rateAdjustmentFrequency,
  costToLoanRatio,
  businessPurposeOccupancy,
  subOrdinateFinancing,
  dscrPrepayPenaltyType,
  debtServiceCoverageRatio,
  qualifyingPaymentInReservesMonthly,
  prepaymentPenaltyMonths,
  dscrLoanLevelOccupancy
];

const prePostBridgeFields: any = [
  loanAmount,
  maxLoanAmount,
  financedBudgetAmount,
  cutOffLoanAmount,
  cutOffMaxLoanAmount,
  budgetAmount,
  bridgeInterestRate,
  rateTypeField,
  accrualType,
  financialInterestReserve,
  cashOutAmount,
  { ...discountPoints, fieldType: () => "percentageWithout100x" },
  armIndex,
  grossArmMargin,
  initialRateAdjustmentPeriod,
  initialPayAdjustmentPeriod,
  initialInterestRateDown,
  initialInterestRateUp,
  lifetimeMaxRate,
  lifetimeMinRate,
  subsequentInterestRateDown,
  subsequentInterestRateUp,
  paymentAdjustmentFrequency,
  rateAdjustmentFrequency,
  armLookBackDays,
  { ...armRoundFlag, required: () => true },
  armRoundingFactor,
  includeOutOfPocketBudgetInARLTV
];

const getBridgeFESFields = (toorakProduct: any) => {
  if (toorakProduct === LoanSizerEnum.oneFourFamily) {
    return [...fesBridge14Fields];
  }
  if (toorakProduct === LoanSizerEnum.multifamilyMixedUse) {
    return [
      existDebt,
      hardCost,
      softCost,
      { ...budgetAmount, 
        isViewMode: () => true, 
        fieldName: "rehabBudget",
        required: () => false,
        getValue: (loanEconomics: any) => {
          return totalRehabBudgeFormula({ loanDetails: { fesLoanEconomics: loanEconomics } });
        }
       },
      { ...loanAmount, fieldName: "originalLoanAmount" },
      {
        ...discountPoints,
        fieldName: "totalOriginationAndDiscountPoints",
        backendKey: "totalOriginationAndDiscountPoints"
      },
      bridgeInterestRate,
      includeOutOfPocketBudgetInARLTV
    ];
  }
  return [
    hardCost,
    { ...softCost, fieldLabel: "Soft Costs ($)" },
    { ...loanAmount, fieldName: "originalLoanAmount" },
    rehabAmount,
    interestReserve,
    bridgeFesGrUpTotalOriginationAndDiscountPoints,
    bridgeInterestRate,
    includeOutOfPocketBudgetInARLTV
  ];
};


export const getLoanEconomicsFields = (loanStage: string, loanType: any, loanSizerType?: any) => {
  switch (loanStage) {
    case "fes":
      return loanType === "InvestorDSCR"
        ? fesLoanEconomicsFields
        : getBridgeFESFields(loanSizerType);
    case "pre":
      return loanType === "InvestorDSCR" ? preDSCRFields : prePostBridgeFields;
    case "post":
      return loanType === "InvestorDSCR"
        ? postDSCRFields
        : prePostBridgeFields;
    default:
      break;
  }
};
