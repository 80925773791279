import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: "flex",
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      overflow: "hidden"
    },
    unReadNotDot: {
      backgroundColor: "#f75676",
      height: "6px",
      width: "6px",
      borderRadius: "50%",
      position: "absolute",
      left: 10,
      top: "25px"
    },
    backLine: {
      transform: "translate(0%, -100%)",
      backgroundColor: "#b69dff",
      height: "5px",
      width: "100%",
      position: "absolute",
      zIndex: 1
    },
    evaluateLoanWrapper: {
      padding: 20
    },
    evaluateLoanInner: {
      padding: "20px 24px",
      display: "flex",
      flexWrap: "wrap"
    },
    proceedBtn: {
      backgroundColor: "#5f72e4",
      float: "right",
      padding: "6px 12px",
      textTransform: "none",
      fontSize: 12
    },
    infoIcon: {
      fontSize: 16,
      padding: 4
    },
    loanPipeline: {
      height: "440px",
      backgroundColor: "white",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      margin: "10px"
    },
    evaluateLoanWrap: {
      height: "440px",
      backgroundColor: "white",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      margin: "10px",
      padding: "20px"
    },
    communicationWrap: {
      height: "540px",
      // maxHeight: "950px",
      backgroundColor: "white",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      margin: "10px"
    },
    graphArea: {
      height: "440px",
      margin: "10px",
      marginTop: "0px"
    },
    loanPurchased: {
      width: "100%",
      height: "215px",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      margin: "10px",
      marginLeft: "0px",
      backgroundColor: "white"
    },
    loanType: {
      width: "100%",
      height: "215px",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      margin: "10px",
      marginLeft: "0px",
      backgroundColor: "white"
    },
    chartHeader: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: 1.57,
      color: "#32325d",
      padding: "15px",
      borderBottom: "solid 1px #f2f2f2",
      margin: "0px",
      display: "flex"
    },
    loanPipelineContainer: {
      height: "inherit"
    },
    loanPipelineInnerContainer: {
      borderRadius: "0 0 5px 5px",
      height: "378px !important",
      boxShadow: "none !important"
    },
    chartVessel: {
      height: "100% !important"
    },
    chartHeaderLoan: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: 1.57,
      color: "#32325d",
      margin: "0px",
      height: "65px",
      padding: "20px",
      borderBottom: "solid 1px #f2f2f2",
      display: "flex",
      position: "relative"
    },
    subHeader: {
      display: "none",
      height: "60px",
      width: "100%",
      borderBottom: "solid 1px #e2e4f1",
      background: "white",
      padding: "12px 20px"
    },
    LoanPipeChart: {
      [theme.breakpoints.down("xs")]: {
        display: "none"
      }
    },
    removeFixedHeight: {
      [theme.breakpoints.up("sm")]: {
        minHeight: "revert !important"
      }
    },
    increaseHeight: {
      [theme.breakpoints.up("md")]: {
        height: "960px !important"
      }
    },
    setHeightCommunication: {
      [theme.breakpoints.up("md")]: {
        height: "748px !important"
      }
    },
    expandCollapse: {
      position: "absolute",
      right: "25px",
      top: "20px"
    },
    containerSpace: {
      [theme.breakpoints.down("xs")]: {
        padding: "10px !important"
      }
    },
    noMarginY: {
      [theme.breakpoints.down("xs")]: {
        marginTop: "0 !important",
        marginBottom: "0 !important"
      }
    },
    noPaddingY: {
      [theme.breakpoints.down("xs")]: {
        paddingTop: "0 !important",
        paddingBottom: "0 !important"
      }
    },
    notificationWrapper: {
      height: "540px",
      // maxHeight: "950px",
      backgroundColor: "white",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      margin: "10px",
      flexBasis: "calc(25% - 14px)"
    },
    evaluateLoan: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: 1.57,
      color: "#32325d",
      padding: "0px",
      margin: "0px"
    },
    evaluateHeading: {
      paddingBottom: "10px"
    },
    description: {
      fontSize: "12px",
      color: "#32325d",
      fontWeight: 500,
      textAlign: "left",
      marginBottom: "10px"
    },
    btnGroupicon: {
      //marginRight: "5px",
      height: 14,
      width: 13
      //marginLeft: "5px"
    },
    variationLoanHeader: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: 1.57,
      color: "#32325d",
      margin: "0px",
      display: "flex",
      position: "relative",
      borderBottom: "solid 1px #f2f2f2",
      padding: "20px"
    },
    variationLoan: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: 1.57,
      color: "#32325d",
      margin: "0px",
      display: "flex",
      padding: "15px"
    },
    btnGroupZoomIcon: {
      paddingRight: "5px",
      height: 17,
      width: 20,
      position: "absolute",
      right: "20px",
      cursor: "pointer"
    },
    typeOfLoan: {
      fontSize: "12px",
      color: "#32325d",
      fontWeight: 600
    },
    loanTypeImg: {
      height: "50px",
      width: "50px"
    },
    imgWrapper: {
      borderRadius: "4px",
      textAlign: "center",
      boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.23)",
      backgroundColor: "#ffffff",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      padding: "10px 7px",
      cursor: "pointer",
      margin: "0 5px",
      maxWidth: "140px",
      width: "46%"
    },
    typeWrapper: {
      display: "flex",
      justifyContent: "space-around",
      height: "100px",
      width: "100%"
    },
    loanHeading: {
      color: "#32325d",
      fontSize: "12px",
      width: "100%"
    },
    midHeader: {
      width: "100%",
      textAlign: "center",
      padding: "2px 7px"
    },
    noteWrapper: {
      borderRadius: "4px",
      backgroundColor: "#e9fafd",
      padding: "10px 10px",
      textAlign: "left",
      height: "fit-content"
    },
    noteHeader: {
      margin: "0px",
      display: "inline-block"
    },
    wrapper: {
      display: "flex"
    },
    indicatorHeader: {
      height: "4px",
      borderRadius: "30px",
      backgroundColor: "#5e72e4"
    },
    tabRoot: {
      textTransform: "none",
      color: "#000",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(14),
      marginRight: theme.spacing(1),
      "&:focus": {
        opacity: 1
      },
      "&:disabled": {
        color: "#000"
      }
    },
    tabStyle: {
      fontSize: "14px",
      fontWeight: 600,
      textTransform: "capitalize",
      position: "relative"
      // marginLeft: "20px"
    },
    noteDesc: {
      fontSize: "12px",
      fontWeight: 500
    },
    loanLink: {
      color: "#5566d6",
      fontSize: 11,
      fontWeight: "bold",
      textAlign: "center",
      "&:hover": {
        cursor: "pointer"
      },
      zIndex: 1
    },
    loanLinkWrapper: {
      //paddingLeft: "25px",
      display: "flex",
      //justifyContent: "space-around"
      padding: "0 130px 0 82px",
      marginTop: "-14px",
      alignItems: "center",
      justifyContent: "space-around"
    },
    backToDefault: {
      height: "40px",
      backgroundColor: "#f6f9fc",
      padding: "10px",
      fontSize: "12px",
      fontWeight: 600,
      color: "#32325d",
      cursor: "pointer",
      textAlign: "left"
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    },
    circleCreation: {
      borderRadius: "50%",
      // height: "100px",
      // width: "100px",
      position: "relative",
      textAlign: "center",
      backgroundImage:
        "linear-gradient(149deg, #d782d9 3%, #8f6ed5 33%, #281483 100%)"
    },
    circleWrapper: {
      justifyContent: "space-evenly",
      height: "calc(100% - 155px)",
      display: "flex",
      width: "100%",
      alignItems: "center",
      position: "relative"
    },
    keyName: {
      fontWeight: 600,
      display: "inline"
    },
    buttonGroup: {
      backgroundColor: "#f7fafc !important",
      fontSize: "14px !important",
      fontWeight: "normal",
      height: "36px !important",
      letterSpacing: "0.5px",
      color: "#32325d !important", //theme.palette.text.secondary,
      textTransform: "none",
      border: "none !important"
    },
    communicationHeader: {
      display: "flex",
      padding: "15px 20px",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottom: "solid 1px #f2f2f2",
      position: "relative"
    },
    toggleBtnGroup: {
      [theme.breakpoints.down("xs")]: {
        display: "none !important"
      }
    },
    selectEmpty: {
      height: "2.25rem",
      borderRadius: "3px",
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      paddingLeft: "3px",
      fontSize: "0.75rem",
      fontWeight: 600,
      background: "#ffffff !important",
      "&:selected": {
        backgroundColor: "#ffffff !important"
      }
    },
    tabLinks: {
      display: "flex",
      borderBottom: "solid 1px #f2f2f2",
      height: "50px",
      padding: "10px 20px 0px",
      justifyContent: "space-between"
    },
    insideLoanLink: {
      color: "#32325d",
      fontSize: "12px",
      fontWeight: "normal",
      marginRight: "20px",
      cursor: "pointer"
    },
    selected: {
      borderBottom: "solid 4px #5e72e4",
      fontSize: "12px",
      fontWeight: "bold"
    },
    insideLoanLinkCount: {
      color: "#32325d",
      fontSize: "10px",
      fontWeight: "inherit",
      cursor: "pointer",
      letterSpacing: "normal",
      lineHeight: "normal",
      textAlign: "center"
    },
    circleText: {
      transform: "translate(-50%, -50%)",
      margin: "0",
      position: "absolute",
      top: "50%",
      left: "50%",
      color: "white",
      fontSize: "14px",
      fontWeight: 600
    },
    cardContainer: {
      padding: "25px 20px",
      border: "solid 1px #f2f2f2",
      backgroundColor: "#ffffff"
    },
    requestedUser: {
      fontSize: "14px",
      color: "#32325d",
      fontWeight: 600,
      padding: "0px 10px 0px 0px",
      wordBreak: "break-all"
    },
    accountName: {
      fontSize: "14px",
      margin: "0px 7px 0px 0px",
      padding: "5px 7px 6px 6px",
      color: "#FFF",
      fontWeight: "bold",
      textAlign: "center",
      borderRadius: "21px",
      backgroundImage:
        "linear-gradient(139deg, #0fcbef 2%, #1071ee 95%), linear-gradient(to bottom, #d8d8d8, #d8d8d8)"
    },
    viewDetailsConatiner: {
      display: "flex",
      justifyContent: "flex-end"
    },
    viewDetailsbutton: {
      borderRadius: "4px",
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4"
    },
    sectionContainer: {
      padding: "5px 20px 24px 20px",
      borderBottom: "solid 1px #f2f2f2",
      backgroundColor: "#ffffff"
    },
    allCommWrapper: {
      overflowY: "auto",
      overflowX: "hidden",
      height: "348px",
      marginBottom: "15px"
      // maxHeight: "700px"
    },
    allCommWrapperList: {
      height: "420px",
      // minHeight: "320px",
      // maxHeight: "820px",
      overflowY: "auto"
    },
    statusText: {
      display: "inline-block",
      color: "#ffffff",
      margin: "0px 5px",
      padding: "5px",
      fontSize: "10px",
      fontWeight: "bold",
      borderRadius: "6px",
      backgroundColor: "#172b4d"
    },
    variationGraph: {
      height: "500px",
      background: "white",
      padding: "10px",
      position: "relative",
      borderRadius: "4px"
    },
    lightColor: {
      color: "#8897aa",
      fontSize: 11,
      padding: "0px 3px",
      marginBottom: "0px"
    },
    msg: {
      fontSize: "12px",
      color: "#32325d",
      marginBottom: "5px",
      wordBreak: "break-word"
    },
    notWrapper: {
      borderBottom: "solid 1px #e9ecef",
      padding: "20px",
      width: "100%",
      cursor: "pointer",
      textAlign: "left",
      paddingBottom: "0px"
    },
    notWrapperBell: {
      borderBottom: "solid 1px #e9ecef",
      padding: "20px",
      width: "100%",
      cursor: "pointer",
      textAlign: "left",
      display: "flex"
    },
    notWrapperContent: {
      borderBottom: "solid 1px #e9ecef",
      padding: "20px",
      width: "100%",
      cursor: "pointer",
      textAlign: "left",
      height: "fit-content",
      position: "relative"
    },
    formControl: {
      minWidth: 120,
      width: "15%",
      fontSize: "0.75rem",
      "&: selected": {
        fontWeight: "bold !important"
      }
    },
    notificationScroll: {
      height: "calc(100% - 70px)",
      overflowY: "auto",
      overflowX: "hidden",
      display: "block"
    },
    fromBellNot: {
      height: "435px",
      overflowY: "auto",
      overflowX: "hidden"
    },
    closeIcon: {
      right: "20px",
      top: "20px",
      width: "18px",
      height: "18px",
      cursor: "pointer",
      position: "absolute"
    },
    paperDashboard: {
      padding: "0px",
      textAlign: "center",
      color: theme.palette.text.secondary,
      height: "100%",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)"
    },
    hideLoan: {
      display: "none"
    },
    notificationHeightLAT: {
      height: "1299px"
    },
    notificationHeightOrg: {
      height: "1299px"
    },
    evaluatedLoanContainer: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "0"
      },
      [theme.breakpoints.down("xs")]: {
        display: "none"
      }
    },
    notificationContainer: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "0 !important"
      },
      [theme.breakpoints.down("xs")]: {
        display: "none !important"
      }
    },
    MobileViewEvaluatedWrapper: {
      [theme.breakpoints.down("xs")]: {
        display: "flex !important"
      },
      [theme.breakpoints.up("sm")]: {
        display: "none !important"
      }
    },
    evaluatedPapper: {
      padding: "0px",
      textAlign: "center",
      color: theme.palette.text.secondary,
      height: "100%",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)"
    },
    hideContainer: {
      display: "none"
    },
    showContainer: {
      dsiplay: "flex"
    },
    onHoldTag: {
      fontSize: 8,
      fontWeight: "bold",
      color: "#fff",
      backgroundColor: "#fb623f",
      borderRadius: "8px",
      padding: "2px 5px",
      marginLeft: "3px",
      wordBreak: "keep-all",
      whiteSpace: "nowrap"
    }
  })
);
