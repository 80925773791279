/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Grid, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    textAreaWrapper: {
      width: "100%",
      minHeight: "89px",
      border: "1px solid #cad1d7",
      borderRadius: "4px",
      padding: "14px 12px 14px 12px"
    },
    textArea: {
      fontFamily: "sans-serif !important",
      width: "100%",
      height: "100%",
      resize: "none",
      border: "none",
      borderRadius: "4px",
      fontSize: "14px"
    },
    headingTypography: {
      textAlign: "left",
      fontSize: "12px !important",
      fontWeight: 600,
      color: "#32325d",
      lineHeight: 1.57,
      padding: "0px 24px 20px 0px"
    },
    divider: {
      height: "1px",
      border: "solid 1px #f2f2f2",
      padding: "0px !important"
    },
    PdBottom: {
      paddingBottom: 24
    }
  })
);

interface Props {
  fieldKey?: number;
  required?: boolean;
  deleteCallback?: any;
  index?: number;
  formData?: any;
  setFormData?: any;
  isSubmitted?: boolean;
  setIsSubmitDisabled?: any;
  actionType?: string;
  actionId?: string;
  handleChange?: any;
  value?: string;
  handleBlur?: any;
  placeholder?: any;
}

export const DiscussionField = (props: Props) => {
  const { required, actionId, actionType, handleChange, value } = props;
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography className={classes.headingTypography}>
          {actionType}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classes.PdBottom}
      >
        <div className={classes.textAreaWrapper}>
          <textarea
            className={classes.textArea}
            required={required}
            value={value}
            name={actionId}
            onChange={handleChange}
            // onBlur={handleBlur}
            placeholder="Write Here"
          />
        </div>
      </Grid>
    </>
  );
};
