/* eslint-disable @typescript-eslint/no-unused-vars */
import { LoanStage, publicClient } from "@toorak/tc-common-fe-sdk";
import { getConfig, getLoanType } from "../../config/config";
// import { updateBridgeLoanDetails } from "../../create-loan/create-loan.action";
import { getCookie } from "../../utils/cookies";
import { getWaiverDetails } from "./WaiverRequest.reducer";
// export const GET_WAIVER_DETAILS = "GET_WAIVER_DETAILS";
// export const SET_ERROR_ADDRESSLIST = "SET_ERROR_ADDRESSLIST";
export function waiverDetails(
  subject: string,
  reason: string,
  details: any,
  errorAddressList?: any
) {
  return getWaiverDetails({
    subject,
    reason,
    details,
    errorAddressList
  });
  // return {
  //   type: GET_WAIVER_DETAILS,
  //   payload: {
  //     subject,
  //     reason,
  //     details,
  //     errorAddressList
  //   }
  // };
}

export function submitWaiverCall(body: any, props: any) {
  const header = {
    headers: { "Content-Type": "application/json" }
  };
  const url = `${getConfig().apiUrl}/waiverservice/LOAN/${
    props.identifierValue
  }/waiver?${props.secondIdentifier}`;
  // if (props.secondIdentifier && props.secondIdentifier.tab === "PROPERTY")
  //   url += `?secondaryIdentifierName=${props.secondIdentifier.tab}&secondaryIdentifierValue=${props.secondIdentifier.id}`;
  // else if (props.secondIdentifierForException)
  //   url += `?${props.secondIdentifierForException}`;
  // else
  //   url += `?secondaryIdentifierName=LOAN&secondaryIdentifierValue=${props.identifierValue}`;
  return new Promise((resolve, reject) => {
    publicClient
      .post(url, body, header)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}
export function getCompensatingFactor(body: any) {
  const header = {
    headers: { "Content-Type": "application/json" }
  };
  const url = `${
    getConfig().apiUrl
  }/custom/WAIVER_COMPENSATING_FACTORS/values/fetch`;
  // if (props.secondIdentifier && props.secondIdentifier.tab === "PROPERTY")
  //   url += `?secondaryIdentifierName=${props.secondIdentifier.tab}&secondaryIdentifierValue=${props.secondIdentifier.id}`;
  // else if (props.secondIdentifierForException)
  //   url += `?${props.secondIdentifierForException}`;
  // else
  //   url += `?secondaryIdentifierName=LOAN&secondaryIdentifierValue=${props.identifierValue}`;
  return new Promise((resolve, reject) => {
    publicClient
      .post(url, body, header)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}

export function getPartyId() {
  const token = getCookie("id_token") || "";
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join("")
  );
  const tokenData = JSON.parse(jsonPayload);
  return tokenData;
  // tokenData.partyId;
}

export function waiverApproval(props: any): Promise<string> {
  const { responseType, isApproved, waiverId, compensatingFactors } = props;
  return new Promise((resolve, reject) => {
    const url = `${
      getConfig().apiUrl
    }/aggregate/waiverservice/waiver/${waiverId}/response`;
    // const responseBy = getPartyId();
    const { partyId } = getPartyId();
    console.log("responseType", responseType);
    const body = {
      responseType,
      responseBy: partyId,
      responseDate: new Date(),
      isApproved,
      compensatingFactors
    };
    publicClient
      .post(url, body)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}

/**
 * * Api to update response of existing waiver.
 * * Currently Used to move waiver back to requested state if effected by edit of any field.
 * * Take LAT action on RE-REQUEST of waiver.
 */
export function reRequestWaiver(
  body: any,
  loanId: string,
  loanStage: string
): Promise<string> {
  return new Promise((resolve, reject) => {
    const url = `${
      getConfig().apiUrl
    }/aggregate/waiverservice/LOAN/${loanId}/waiver/response/bulk?secondaryIdentifierName=LOAN_STAGE&secondaryIdentifierValue=${loanStage}`;
    publicClient
      .post(url, body)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}

export function updateMitigates(body: any, waiverId: any) {
  return new Promise((resolve, reject) => {
    const url = `${
      getConfig().apiUrl
    }/waiverservice/waiver/${waiverId}/mitigates/bulk`;
    publicClient
      .post(url, body)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}

export function insertTypeDocs(
  body: any,
  type?: string,
  typeId?: string
): Promise<string> {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl}/docs/${type}/${typeId}/documents`;
    const header = {
      headers: { "Content-Type": "text/plain" }
    };
    publicClient
      .post(url, body, header)
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}
export function getWaiverResponse(waiverId: string) {
  return new Promise((resolve, reject) => {
    const url = `${
      getConfig().apiUrl
    }/waiverservice/waiver/${waiverId}/response`;
    publicClient
      .get(url)
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}

// function getMitigates(waiverId: string) {
//   return new Promise((resolve, reject) => {
//     const url = `${getConfig().apiUrl
//       }/waiverservice/waiver/${waiverId}/mitigates`;
//     publicClient
//       .get(url)
//       .then((res: any) => resolve(res))
//       .catch(reject);
//   });
// }

export function deleteWaiverRequest(
  waiverId: string,
  loanId: string,
  loanStage: LoanStage,
  skipRuleExecution: boolean = true
) {
  return new Promise((resolve, reject) => {
    const url = `${
      getConfig().apiUrl
    }/aggregate/waiverservice/LOAN/${loanId}/waiver?secondaryIdentifierName=LOAN_STAGE&secondaryIdentifierValue=${loanStage}&waiverIds=${waiverId}&skipRuleExecution=${skipRuleExecution}`;
    publicClient
      .delete(url)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}

/**
 * * Get all the effected test by edited value. Calculate all the effected Waivers.
 * * Move all the effected Waivers back to Requested State if not already.
 * * In case of any ReRequested waivers or Open waives. Refresh the waivers list after reevaluation of rules.
 * @param changedFieldsArr
 * @param waiverCreated
 * @param loanId
 * @param loanStage
 * @param loantype
 * @returns { reRequestWaiverObject, openWaivers } Waivers ReRequested and OpenWaivers(REQUESTED/RE-REQUESTED/REBUTTALED).
 */
export function getAffectedWaivers(
  changedFieldsArr: any,
  waiverCreated?: any,
  loanId?: any,
  loanStage?: any,
  loantype?: string,
  isGroundUp?: boolean
): Promise<any> {
  return new Promise((resolve, reject) => {
    let url = "";
    switch (loanStage) {
      case LoanStage.fes:
        url =
          loantype === getLoanType[1].displayValue
            ? `${getConfig().apiUrl}/ruleevaluation/attribute/fes30year`
            : `${getConfig().apiUrl}/ruleevaluation/attribute/${
                isGroundUp ? "fesGroundUp" : "fesBridge"
              }`;
        break;
      default:
        url =
          loantype === getLoanType[1].displayValue
            ? `${getConfig().apiUrl}/ruleevaluation/attribute/30year`
            : `${getConfig().apiUrl}/ruleevaluation/attribute/bridge`;
    }
    publicClient
      .post(url, changedFieldsArr, {
        headers: {
          "Content-Type": "application/json"
          // "ignore-auth": true
        }
      })
      .then((res: any) => {
        const { reRequestWaiverObject, openWaivers } = getWaiverKeysToDelete(
          res.data,
          waiverCreated
        );
        if (Object?.keys(reRequestWaiverObject).length > 0) {
          reRequestWaiversOnEdit(reRequestWaiverObject, loanId, loanStage)
            .then((response) => resolve({ reRequestWaiverObject, openWaivers }))
            .catch(() => resolve({ reRequestWaiverObject, openWaivers })); //resolving value here so that edit flow in not stuck when this api fails.
        } else resolve({ reRequestWaiverObject, openWaivers });
      })
      .catch(reject);
  });
}

export function onFirstPaymentDateOfLoanSave(loanId: any) {
  const url = `${
    getConfig().apiUrl
  }/aggregate/loans/updatePricingPaymentDueDate`;
  const payload = {
    loanIds: [loanId]
  };
  const firstPayment = publicClient.post(url, payload, {
    headers: {
      "Content-Type": "application/json"
      // "ignore-auth": true
    }
  });
  return new Promise((resolve, reject) => {
    firstPayment
      .then((res) => {
        resolve(res);
      })
      .catch(reject);
  });
}

//this function is moved to common-utils,
// common-utils/src/createLoanService/loanWaiversService.ts
//TO-DO: remove getWaiverKeysToDelete() & import from common-utils
export function getWaiverKeysToDelete(
  waiverIds: Array<[]>,
  waiverCreated: any
) {
  const waiverKeyIds: any = [];
  const openWaivers: any = [];
  // eslint-disable-next-line
  waiverIds.forEach((item: any) => {
    if (!waiverCreated.length) return false;
    // eslint-disable-next-line
    waiverCreated.forEach((waiverItem: any) => {
      if (waiverItem.waiverType !== "EvaluationResult") {
        return false;
      }
      const isRebuttal = waiverItem.waiverInfo.finalResponse === "REBUTTAL";
      waiverItem?.waiverKeys.forEach((waiverKey: any) => {
        if (
          waiverKey.includes(item) &&
          !waiverKeyIds.includes(waiverItem.waiverId) &&
          waiverItem.waiverResponses?.length !== 0
        ) {
          if (!isRebuttal) {
            waiverKeyIds.push(waiverItem.waiverId);
          }
        }
        if (
          waiverKey.includes(item) &&
          !openWaivers.includes(waiverItem.waiverId) &&
          (!waiverItem?.waiverResponses ||
            !waiverItem?.waiverResponses?.length ||
            waiverItem?.waiverResponses?.filter((item: any) =>
              item.responseType.includes("REQUESTED")
            ).length ||
            isRebuttal)
        ) {
          openWaivers.push(waiverItem.waiverId);
        }
      });
    });
  });
  const { partyId } = getPartyId();
  let reRequestWaiverObject: any = waiverCreated?.length
    ? waiverCreated.reduce((accumulator: any, currentValue: any) => {
        Object.assign(
          accumulator,
          waiverKeyIds.includes(currentValue["waiverId"])
            ? {
                [currentValue["waiverId"]]: {
                  responseType: "REQUESTED",
                  responseBy: partyId,
                  responseDate: new Date(),
                  isApproved: false
                }
              }
            : ""
        );
        return accumulator;
      }, {})
    : {};
  return { reRequestWaiverObject, openWaivers };
}

export function deleteWaiverOnEdit(
  changedFieldsArr: Array<[]>,
  loanId?: any,
  loanStage?: any
): Promise<any> {
  return new Promise((resolve, reject) => {
    const url = `${
      getConfig().apiUrl
    }/aggregate/waiverservice/LOAN/${loanId}/waiver?secondaryIdentifierName=LOAN_STAGE&secondaryIdentifierValue=${loanStage}&waiverIds=${changedFieldsArr.join()}`;
    publicClient
      .delete(url, {
        headers: {
          "Content-Type": "application/json"
          // "ignore-auth": true
        }
      })
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}

export function reRequestWaiversOnEdit(
  changedWaivers?: any,
  loanId?: any,
  loanStage?: any
): Promise<any> {
  return new Promise((resolve, reject) => {
    const url = `${
      getConfig().apiUrl
    }/aggregate/waiverservice/LOAN/${loanId}/waiver/response/bulk?secondaryIdentifierName=LOAN_STAGE&secondaryIdentifierValue=${loanStage}`;
    publicClient
      .post(url, changedWaivers, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}

/**
 * TODO: Change logic as per requiremet. LPP-6294
 * * Return value is used to show RE-REQUEST button. Currently, showing for all carryforwarded waivers but as per discussion only needs to be shown for open and rejected waivers in forwarded stage.
 */
export const checkCarryForwardWaiver = (currentWaiver: any) => {
  let isForwardedWaiver: boolean = false;
  const stageVal = currentWaiver?.secondaryIdentifierValue;
  if (currentWaiver?.waiverResponses && currentWaiver?.refWaiverId) {
    const refIdLength = currentWaiver?.refWaiverId.length;
    if (refIdLength) {
      let reRequestedResponse = currentWaiver?.waiverResponses?.filter(
        (res: any) => res.responseType === "RE-REQUESTED"
      );
      if (
        (stageVal === LoanStage.pre || stageVal === LoanStage.post) &&
        !reRequestedResponse.length
      )
        isForwardedWaiver = true;
      if (stageVal === LoanStage.post && reRequestedResponse.length) {
        isForwardedWaiver = true;
        reRequestedResponse = reRequestedResponse.sort(
          (a: any, b: any) =>
            Date.parse(a.responseDate) - Date.parse(b.responseDate)
        );
        reRequestedResponse.forEach((res: any) => {
          if (
            new Date(res.responseDate).getTime() >
            new Date(currentWaiver.createdOn).getTime()
          ) {
            isForwardedWaiver = false;
          }
        });
      }
    }
  }

  return isForwardedWaiver;
};
