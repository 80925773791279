/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/no-explicit-any */
// import { combineReducers, createStore, applyMiddleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { connectRouter } from "connected-react-router";
// import thunk from "redux-thunk";
// import { composeWithDevTools } from "redux-devtools-extension";
// import { History } from "history";
// import { Domain1Store, domain1Reducer } from "./domain1/domain1.reducer";
import { createLoanReducer } from "../create-loan/create-loan.reducer";
import { CreateLocationStore } from "../create-loan/create-loan.reducerHelper";

import {
  EvaluationResultStore,
  EvaluationResultsReducer
} from "./EvaluationResults/EvaluationResults.reducer";
import {
  SPStore,
  SPReducer
} from "./SettlementAndPurchase/SettlementAndPurchase.reducer";
import {
  ExceptionsStore,
  ExceptionsResultsReducer
} from "./ExceptionsResults/ExceptionsReducer";
import {
  ScenarioStore,
  ScenarioResultsReducer
} from "./Scenario/ScenarioReducer";
import {
  ExcelUploadStoreProps,
  ExcelUploadReducer
} from "./ExcelUpload/excel-upload.reducer";
import { WRStore, WRReducer } from "./WaiverRequest/WaiverRequest.reducer";
import { commentReducer } from "./commentsSection/comment.reducer";
import {
  LoaderAndExceptionsStore,
  LoaderAndExceptionReducer
} from "./loaderAndException/loaderAndException.reducer";
import {
  RetryRequestReducer,
  RetryRequestStore
} from "./RetryRequest/retry-request.reducer";
import {
  FesLoanCreationReducer,
  FesLoanCreationStore
} from "./FrontEndSizer/fesLoanCreation.reducer";

import {
  DashboardResultStore,
  DashboardResultReducer
} from "./dashboardSection/dashboard.reducer";
import {
  DiscussionStore,
  DiscussionReducer
} from "./Discussions/Discussions.reducer";
import { ILPStore, ILPReducer } from "./ILP/ILP.reducer";
import {
  TapeToFileStore,
  TapeToFileReducer
} from "./TapeToFile/TapeToFile.reducer";
import {
  NotificationStore,
  NotificationReducer
} from "./Notification/Notification.reducer";
import {
  HelpMenuReducerType,
  HelpMenuReducer
} from "./helpMenu/helpMenu.reducer";
import {
  LoanAndPropertyReducerType,
  loanAndPropertyStore
} from "./loanAndPropertyDetails/loanAndPropertyDetails.reducer";

import {
  AppraiserInit,
  AppraiserReducer
} from "./appraiserSearch/appraiser.reducer";
import {
  MasterViewReducer,
  MasterViewStore
} from "./masterView/masterView.reducer";
import {
  AssetManagementStore,
  AssetManagementReducer
} from "./assetManagement/assetManagement.reducer";
import {
  PricingGridStore,
  PricingGridReducer
} from "../pricing-grid/pricing-grid.reducer";
import {
  CustodyDocumentsStore,
  CustodyDocumentsReducer
} from "./CustodyDocuments/CustodyDocuments.reducer";

import DrawRequestReducer from "./drawRequest/drawRequest.reducer";

import ServicerOnboardingReducer from "./servicerOnboarding/servicerOnboarding.reducer";
import BudgetReviewReducer from "./budgetReview/budgetReview.reducer";

import { enableMapSet } from 'immer'

enableMapSet()
import {
  EscrowCalculatorReducer,
  EscrowCalculatorStore
} from "./escrowCalculator/EscrowCalculator.reducer";

import {
  SupportUIReducer,
  SupportUIReducerType
} from "./supportUI/supportUi.reducer";

import {
  DocumentReviewReducer,
  DocumentReviewStore
} from "./documentReview/DocumentReview.reducer";

import { TasksReducer, TasksStore } from "./tasks/Tasks.reducer";

import { CWReducer, CWStore } from "./closingWireSection/closingWire.reducer";

import {
  AppraisalReducer,
  AppraisalState
} from "./appraisal/appraisal.reducer";
import { DrawRequestStore } from "./drawRequest/drawRequest.interface";
import { ServicerOnboardingStore } from "./servicerOnboarding/servicerOnboarding.interface";
import { NoteReducer, NoteStore } from "./Notes/Notes.reducer";
import { BudgetReviewStore } from "./budgetReview/budgetReview.interface";
import {
  TakeOutPartnerReducer,
  TakeOutPartnerStore
} from "./TKOP/takeoutPartner.reducer";
// import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  ApproverDashboardReducer,
  ApproverDashboardStore
} from "./ApproverDashboard/ApproverDashboard.reducer";
import {
  purchasedLoansDocReviewReducer,
  PLDocumentReviewStore
} from "./purchasedLoansDocReview/purchasedLoansDocReview.reducer";

import {
  LoanDetailsStore,
  loanDetailReducer
} from "../v2/loanDetailStore.reducer";
// import { TestReducer } from "./drawRequest/drawRequest_ReduxToolkit";

// https://github.com/codeBelt/react-redux-architecture

export interface RootState {
  // testStore: any;
  // domain1Store: Domain1Store;
  fesLoanStore: FesLoanCreationStore; //not getting used; have to check

  // router: any;
  scenarioResultsStore: ScenarioStore;
  spStore: SPStore;
  custodyDocumentStore: CustodyDocumentsStore;
  cwStore: CWStore;
  helpMenuStore: HelpMenuReducerType;
  wrStore: WRStore;
  drawRequestStore: DrawRequestStore;
  tkopStore: TakeOutPartnerStore;
  servicerOnboardingStore: ServicerOnboardingStore;
  budgetReviewStore: BudgetReviewStore;
  pricingGridStore: PricingGridStore;
  TasksStore: TasksStore;
  LoaderAndExceptionsStore: LoaderAndExceptionsStore;
  commentStore: any;
  aprStore: AppraiserInit;
  noteStore: NoteStore;
  retryRequestStore: RetryRequestStore;
  excelUploadStore: ExcelUploadStoreProps;
  dashboardStore: DashboardResultStore;
  ILPStore: ILPStore;
  tapeToFileStore: TapeToFileStore;
  supportUIStore: SupportUIReducerType;
  notificationStore: NotificationStore;
  exceptionsResultsStore: ExceptionsStore;
  discussionStore: DiscussionStore;
  masterViewStore: MasterViewStore;
  createLoanStore: CreateLocationStore;
  PurchasedLoansDocReviewStore: PLDocumentReviewStore;
  appraisalStore: AppraisalState;
  assetManagementStore: AssetManagementStore;
  evaluationResultsStore: EvaluationResultStore;
  loanAndPropertyStore: LoanAndPropertyReducerType;
  EscrowCalculatorStore: EscrowCalculatorStore;
  DocumentReviewStore: DocumentReviewStore;
  approverDashboardStore: ApproverDashboardStore;
  loanDetailsStore: LoanDetailsStore;
}

const rootReducer = {
  // domain1Store: domain1Reducer,
  createLoanStore: createLoanReducer,
  commentStore: commentReducer,
  custodyDocumentStore: CustodyDocumentsReducer,
  evaluationResultsStore: EvaluationResultsReducer,
  spStore: SPReducer,
  cwStore: CWReducer,
  PurchasedLoansDocReviewStore: purchasedLoansDocReviewReducer,
  exceptionsResultsStore: ExceptionsResultsReducer,
  wrStore: WRReducer,
  LoaderAndExceptionsStore: LoaderAndExceptionReducer,
  excelUploadStore: ExcelUploadReducer,
  retryRequestStore: RetryRequestReducer,
  fesLoanStore: FesLoanCreationReducer,
  dashboardStore: DashboardResultReducer,
  // router: connectRouter(history),
  discussionStore: DiscussionReducer,
  ILPStore: ILPReducer,
  tapeToFileStore: TapeToFileReducer,
  notificationStore: NotificationReducer,
  noteStore: NoteReducer,
  tkopStore: TakeOutPartnerReducer,
  helpMenuStore: HelpMenuReducer,
  loanAndPropertyStore: loanAndPropertyStore,
  scenarioResultsStore: ScenarioResultsReducer,
  aprStore: AppraiserReducer,
  masterViewStore: MasterViewReducer,
  assetManagementStore: AssetManagementReducer,
  pricingGridStore: PricingGridReducer,
  drawRequestStore: DrawRequestReducer,
  servicerOnboardingStore: ServicerOnboardingReducer,
  budgetReviewStore: BudgetReviewReducer,
  EscrowCalculatorStore: EscrowCalculatorReducer,
  supportUIStore: SupportUIReducer,
  DocumentReviewStore: DocumentReviewReducer,
  TasksStore: TasksReducer,
  appraisalStore: AppraisalReducer,
  approverDashboardStore: ApproverDashboardReducer,
  loanDetailsStore: loanDetailReducer
  // testStore: TestReducer
};
export const rootStore = () => {
  return configureStore<RootState>({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false
      })
  });
};
// export const rootStore = configureStore<RootState>({
//   reducer: rootReducer
// });

// export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof rootStore.dispatch;
// export const useAppDispatch: () => AppDispatch = useDispatch;
// export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
// export const rootStore = (history: History) => {
//   enableMapSet();
//   const middleware = [thunk, routerMiddleware(history)];
//   return createStore(
//     rootReducer(history),
//     {},
//     composeWithDevTools(applyMiddleware(...middleware))
//   );
// };
