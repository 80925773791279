import { Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
// import { getDrawHistory } from "../../stores/drawRequest/drawRequest.action";
import { DrawRequestStore } from "../../stores/drawRequest/drawRequest.interface";
import { RootState } from "../../stores/rootReducer";
import { formatValueByType, sanitizeCurrency } from "../../utils/formatChecks";
import { DrawHistoryComponent } from "./DrawHistory";
import { useDrawsStyles } from "./Draws.styles";

const DrawBalanceToFinish = ({ fieldWidth }: { fieldWidth: 2 | 3 }) => {
  let { drawDetails, drawHistory } = useSelector<RootState, DrawRequestStore>(
    state => state.drawRequestStore
  );
  const classes = useDrawsStyles();

  const { balanceToFinish, financedBudgetAmount } = drawDetails || {};

  const getUtilizedPercentage = () => {
    const percentageVal =
      ((financedBudgetAmount - balanceToFinish) * 100) / financedBudgetAmount;
    return formatValueByType(percentageVal, "decimalFixedto4");
  };

  return (
    // <Grid item container xs={12} sm={12} md={12} xl={12}>
    <>
      <Grid item container xs={fieldWidth} className={classes.fieldRow}>
        <div className={classes.itemBox}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
            data-testid="balance-to-finish"
          >
            {/*  */}
            <Typography
              className={classes.itemSubHeaderText}
              style={{ marginBottom: "4px" }}
            >
              Balance To Finish ($){" "}
            </Typography>
            {drawHistory?.length ? (
              <DrawHistoryComponent historyList={drawHistory} />
            ) : null}
          </div>

          {balanceToFinish ? (
            <>
              <p className={classes.itemValueText}>
                {sanitizeCurrency(balanceToFinish.toString())}
              </p>
            </>
          ) : (
            <p className={classes.itemValueText}>__ __</p>
          )}
        </div>
      </Grid>
      <Grid item container xs={fieldWidth} className={classes.fieldRow}>
        <div className={classes.itemBox}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
            data-testid="financed-budget-amount"
          >
            {/*  */}
            <Typography
              className={classes.itemSubHeaderText}
              style={{ marginBottom: "4px" }}
            >
              Budget Utilized (%){" "}
            </Typography>
            {/* {drawHistory?.length ? (
              <DrawHistoryComponent historyList={drawHistory} />
            ) : null} */}
          </div>

          {balanceToFinish && financedBudgetAmount ? (
            <>
              <p className={classes.itemValueText}>
                {getUtilizedPercentage()}%
              </p>
            </>
          ) : (
            <p className={classes.itemValueText}>__ __</p>
          )}
        </div>
      </Grid>
    </>
    // </Grid>
  );
};

export default React.memo(DrawBalanceToFinish);
