/* eslint-disable */

import { Theme, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";

export const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 12
  }
}))(Tooltip);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tagListBox: {
      //   marginRight: "132px",
      // minHeight: "841px",  // commenting it to give a vertical scroll bar
      height: "841px",
      overflowY: "auto",
      borderRadius: "4px",
      // boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      backgroundColor: "#ffffff"
    },
    m_tb: {
      marginTop: "24px",
      marginBottom: "98px"
    },
    noIcon: {
      paddingRight: "15px"
    },
    componentCointainer: {
      display: "flex",
      flexDirection: "row",
      backgroundColor: "#f8f9ff"
    },
    browseIcon: {
      width: "15px",
      height: "15px",
      marginRight: "7px"
    },
    container: {
      borderRadius: 4,
      display: "flex",
      flexDirection: "column",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      backgroundColor: "#ffffff",
      marginTop: theme.spacing(3),
      marginLeft: "2%",
      marginRight: "10%"
    },
    docsImportBtn: {
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4"
    },
    headingTypography: {
      textAlign: "left",
      fontSize: "14px !important",
      fontWeight: 600,
      color: "#32325d",
      lineHeight: 1.57
    },
    uploadExpand: {
      marginLeft: 15,
      marginTop: 4
    },
    tabContainer: {
      borderRadius: 4,
      display: "flex",
      flexDirection: "column",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      backgroundColor: "#ffffff",
      marginTop: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    sectionWrapper: {
      padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
      display: "flex",
      width: "inherit"
    },
    activeTab: {
      border: "none",
      width: "205px",
      height: "45px",
      color: "#32325d",
      fontWeight: "bold",
      cursor: "pointer",
      backgroundColor: "#EDEEF6"
    },
    inactiveTab: {
      border: "none",
      width: "205px",
      height: "45px",
      color: "#32325d",
      cursor: "pointer",
      backgroundColor: "#F6F9FC"
    },
    browseButtton: {
      width: "124px",
      height: "36px",
      borderRadius: "4px",
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      border: "solid 1px #ececec",
      backgroundColor: "#ffffff",
      textTransform: "none",
      fontSize: "12px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.83,
      letterSpacing: "normal",
      color: " #32325d"
    },
    documentsContainer: {
      margin: "0px 0px 14px 0px"
    },
    documentHeader: {
      //height: "22px",
      fontSize: "14px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.57",
      letterSpacing: "normal",
      color: "#32325d",
      width: "78px",
      padding: "24px 24px"
    },
    docsActionIconBtn: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      height: "30px",
      backgroundColor: "#ffffff",
      justifyContent: "center",
      width: "30px"
    },
    infoSymbol: {
      width: "15px",
      height: "13px",
      marginTop: "2px"
    },
    mt_3: {
      marginTop: "3px"
    },
    infoMargin: {
      margin: "0px 3px"
    },

    c_pointer: {
      cursor: "pointer"
    },
    infoHead: {
      width: "27px",
      height: "22px",
      marginLeft: "6px",
      fontSize: "14px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.57,
      letterSpacing: "normal",
      color: "#32325d",
      fontWeight: 600
    },
    p10: {
      padding: "10px"
    },
    hrTagList: {
      // height: "1px",
      // border: "solid 1px #f2f2f2",
      marginTop: "0px"
    },
    infoBox: {
      height: "85px",
      borderRadius: "4px",
      backgroundColor: "#e9fafd",
      margin: "12px 15px 12px 15px"
    },
    h_28: {
      height: "28px"
    },
    rowFlex: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center"
    },
    m10: {
      margin: "10px"
    },
    fw_wrap: {
      flexWrap: "wrap"
    },
    docsListBox: {
      // width: 308px;
      borderRadius: "1px",
      borderBottom: "solid 1px #e1e8ee",
      backgrounColor: "#fefefe",
      "&:first-child": { borderTop: "solid 1px #e1e8ee" }
    },
    bg_red: {
      backgroundColor: "#f75676 !important"
    },
    infoClose: {
      marginLeft: "auto",
      width: "15px",
      height: "15px"
    },
    infoMsg: {
      height: "34px",
      fontSize: "13px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#32325d",
      marginLeft: "10px"
    },
    preCloseDocs: {
      //   width: "157px",
      height: "18px",
      fontSize: "12px",
      fontWeight: 700,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.5,
      letterSpacing: "normal",
      color: "#32325d",
      textAlign: "center"
    },
    tickBlueChange: {
      filter:
        "invert(84%) sepia(35%) saturate(5603%) hue-rotate(145deg) brightness(94%) contrast(100%)"
    },
    docsListContainer: {
      margin: "15px 24px"
      //   width: "302px"
    },
    docsNameList: {
      padding: "12px 18px",
      alignItems: "flex-start !important"
    },
    hrStyle: {
      borderColor: "#e1e8ee",
      marginBottom: "0px",
      marginTop: "0px"
    },
    tagDocsList: {
      // width: "308px",
      // height: "595px",
      // opacity: 0.14,
      backgroundColor: "#f6f9fc"
    },
    taglistBorder: {
      borderTop: "solid 1px #e1e8ee"
    },
    docsList: {
      //   width: "244px",
      //height: "28px",
      fontSize: "13px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 2.33,
      letterSpacing: "normal",
      color: "#32325d"
      //   font-size: 12px;
    },
    d_none: {
      display: "none"
    },
    docsListIcon: {
      //   border: "solid 2px #bbbbbb"
      paddingTop: "7px",
      margin: "0px 14px 0px 3px"
    },
    tagListInfoIcon: {
      marginTop: "2px",
      paddingTop: "6px",
      height: "22px !important",
      width: "16px !important"
    },
    tickGreyChange: {
      filter:
        "invert(100%) sepia(0%) saturate(2%) hue-rotate(197deg) brightness(101%) contrast(101%)"
    },
    ml_4: { marginLeft: "4px" },
    ml_auto: {
      marginLeft: "auto"
    },
    mr_6: {
      marginRight: "6px"
    },
    mr_10: {
      marginRight: "10px"
    },
    leftBoxesMargin: {
      margin: "24px 0 0 0"
    },
    leftBoxesMargin2: {
      margin: "0px 24px 0px 0px"
    },
    browseBoxWithNoGrid: {
      borderRadius: "4px 4px 0 0",
      paddingBottom: "15px",
      backgroundColor: "#ffffff",
      width: "inherit"
    },
    browseBox: {
      // height: "343px",
      borderRadius: "4px 4px 0 0",
      boxShadow: "0 0 12px 0 rgba(50, 50, 93, 0.21)",
      /*border: "solid 1px #deebf3",*/
      paddingBottom: "15px",
      backgroundColor: "#ffffff",
      width: "inherit"

      //   marginRight: "",
      //   marginLeft: "8%"
      //   width: "790px",
    },
    browseBox2: {
      borderRadius: "4px",
      boxShadow: "0 -2px 12px 0 rgba(50, 50, 93, 0.21)",
      border: "solid 1px #deebf3",
      borderBottom: "0px",
      backgroundColor: "#ffffff",
      width: "inherit"
    },
    uploadViewContainer: {
      borderRadius: "4px",
      border: "solid 1px #deebf3",
      backgroundColor: "#ffffff",
      width: "inherit"
    },
    browseContainer: {
      //   width: "770px",
      // height: "252px",
      borderRadius: "6px !important",
      border: "dotted 2px #8897aa",
      backgroundColor: "#ffffff",
      margin: "10px 10px 0 10px",
      //   textAlign: "center",
      display: "flex",
      //   alignItems: "center",
      justifyContent: "center"
    },
    browseContainer2: {
      borderRadius: "6px",
      border: "dotted 2px #8897aa",
      backgroundColor: "#ffffff",
      margin: "10px",
      marginBottom: "0px",
      display: "flex",
      justifyContent: "center"
    },
    browseButton: {
      width: "124px",
      height: "36px",
      borderRadius: "4px",
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      border: "solid 1px #ececec",
      backgroundColor: "#ffffff",
      textTransform: "none",
      fontSize: "12px",
      fontHeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.83,
      letterSpacing: "normal",
      color: " #32325d"
    },
    fwss_normal: {
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal"
    },
    BrowseInfo: {
      lineHeight: 1.25,
      letterSpacing: "0.09px",
      color: "#7a8aa0",
      height: "16px",
      fontSize: "12px"
    },
    BrowseText: {
      //   width: "390px",
      // height: "66px",
      fontSize: "14px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.07,
      letterSpacing: "0.1px",
      textAlign: "center",
      color: "#7a8aa0",
      margin: "20px 0"
    },
    Browse: {
      width: "67px",
      height: "22px",
      fontSize: "12px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.83,
      letterSpacing: "normal",
      color: "#32325d"
    },
    iconShape: {
      width: "22px",
      height: "26px",
      //   backgroundColor: "#02669f",
      objectFit: "contain"
    },
    // ic-outline-file-copy {
    //     width: 22px;
    //     height: 28px;
    //   }
    w_100: {
      width: "100%",
      paddingLeft: "15px"
    },
    w_95: { width: "95%" },
    uploadDocsText: { margin: "24px 10px 24px 36px" },

    uploadDocs: {
      //   width: "186px",
      height: "22px",
      //   fontFamily: "NotoSansJP",
      fontSize: "18px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#022d45"
    },
    uploadDocsCount: {
      marginTop: "24px",
      width: "16px",
      height: "16px",
      fontFamily: "NotoSansJP",
      fontSize: "12px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.5,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#ffffff",
      background: "blue",
      borderRadius: "50%"
    },
    progressBarBox: {
      // width: 1192px;
      display: "flex",
      justifyContent: "center",
      minHeight: "1164px",
      borderRadius: "16px",
      boxShadow: "0 2px 4px 0 #d5e1e9",
      backgroundColor: "#f5f6f9"
    },
    Group10: {
      marginTop: "250px",
      marginBottom: "10px",
      width: "430px",
      height: "256px",
      objectFit: "contain"
    },

    progressCard: {
      margin: "14px 0px 0px 0px",
      minHeight: "164px",
      borderRadius: "4px",
      boxShadow: "0 6px 9px 0 rgba(144, 164, 183, 0.22)",
      backgroundColor: " #ffffff"
    },
    progressContainer: {
      padding: "20px 24px 10px 24px",
      [theme.breakpoints.down("md")]: {
        padding: "20px 5px 10px 14px"
      },
      [theme.breakpoints.down("sm")]: {
        padding: "20px 6px 10px 0"
      }
    },
    tagContainerExceptions: {
      padding: "0px 24px 5px 24px"
    },
    errorCountIcon: {
      marginLeft: "6px",
      marginTop: "3px"
    },
    mt_10: {
      marginTop: "10px"
    },
    fileName: {
      //height: "18px",
      fontSize: "12px",
      lineHeight: 1.5,
      letterSpacing: "normal",
      // color: "#454b60",
      color: "#5e72e4",
      paddingRight: "15px",
      wordBreak: "break-word",
      width: "calc(100% - 25px)",
      display: "inline-block"
    },
    confirmModal: {
      fontSize: 12,
      lineHeight: 1.5,
      color: "#32325d",
      marginTop: 30,
      padding: 20,
      paddingBlock: 0,
      wordBreak: "break-word"
    },
    initProgress: {
      height: "8px",
      backgroundColor: "#e9ecef",
      //   opacity: 0.39,
      borderRadius: "4px",
      boxShadow: "inset 0 1px 3px 0 rgba(92, 85, 113, 0.5)"
    },
    docUpload: {
      height: "calc(100% - 287px)",
      marginTop: 24
    },
    fileListBoxWithNoGrid: {
      borderRadius: "0 0 4px 4px",
      backgroundColor: "#ffffff",
      width: "inherit",
      marginTop: "0",
      height: "580px",
      overflowY: "auto"
    },
    fileListBox: {
      // minHeight: "581px",
      borderRadius: "0 0 4px 4px",
      //boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      boxShadow: "0 8px 10px 0px rgba(50, 50, 93, 0.21)",
      backgroundColor: "#ffffff",
      width: "inherit",
      // height: "calc(100% - 30px)",
      // marginBottom: "30px",
      marginTop: "0"
      // height: "580px",
      // overflowY: "auto"
    },
    fileListBox2: {
      borderRadius: "4px",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      backgroundColor: "#ffffff",
      width: "inherit",
      marginBottom: "30px"
    },

    activeProgress: {
      marginTop: "9px",

      height: "4px",
      backgroundColor: "#009e55;"
    },
    fss_normal: {
      fontStretch: "normal",
      fontStyle: "normal"
    },
    addTagText: {
      width: "279px",
      height: "14px",
      fontSize: "12px",
      marginTop: "9px",
      marginBottom: "15px",
      fontWeight: 600,
      lineHeight: 1.5,
      letterSpacing: "normal",
      color: "#8897aa"
    },
    tagRequired: {
      fontSize: "12px",
      color: " #f75676",
      lineHeight: "normal"
    },
    mt_0: {
      marginTop: "0px !important"
    },
    formControl: {
      marginTop: "8px",

      width: "279px",
      height: "40px",
      borderRadius: "3px",
      border: "solid 1px #8c939b"
    },
    tagBadge: {
      //   minWidth: "135px",
      margin: "2px 2px",
      padding: "2px",
      backgroundColor: "#11ceef",
      // minHeight: "21px",
      borderRadius: "6px"
    },
    errorBadge: {
      backgroundColor: "#f75676",
      // marginLeft: "13px"
      justifyContent: "center"
    },
    mt_20: {
      marginTop: "20px"
    },
    tagText: {
      //   width: "120px",
      margin: "3px 6px 3px 7px",
      // height: "14px",
      fontSize: "10px",
      fontWeight: "bold",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#ffffff"
    },
    mr_4: {
      marginRight: "4px"
      //   backgroundColor: "#041d3e"
    },
    retrytext: {
      //   width: "39px",
      height: "22px",
      fontFamily: "NotoSansJP",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.57,
      letterSpacing: "0.53px",
      color: "#0067a4"
    },
    ErrorMsg: {
      color: "#f75676"
    },
    QueueMsg: {
      color: "#fb633f"
    },
    SuccessMsg: {
      color: "#2ece89"
    },

    Msg: {
      //   width: "324px",
      height: "14px",
      fontSize: "12px",
      lineHeight: 1.5,
      letterSpacing: "normal",
      marginTop: "3px"
    },
    uploadDocsHead: {
      // height: "22px",
      fontSize: "14px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.57,
      letterSpacing: "normal",
      color: "#32325d"
    },
    uploadDocsHeadMargin: {
      /*marginTop: "22px",
      marginLeft: "24px"*/
      padding: "15px 18px 0"
    },
    uploadDocsHeadMargin2: {
      marginTop: "22px",
      marginLeft: "24px",
      marginBottom: "20px"
    },
    minimizeImg: {
      marginTop: "22px",
      marginBottom: "20px"
    },
    uploadDocsHeader: {
      color: "#32325d",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: 1.57,
      borderTop: "1px solid #e5e5e5",
      zIndex: 1,
      position: "sticky",
      top: 0,
      backgroundColor: "white",
      left: 0,
      [theme.breakpoints.down("md")]: {
        position: "relative"
      }
    },
    fileListScroll: {
      height: "520px",
      overflowY: "auto"
    },
    headerLeftSideContainer: {
      display: "flex",
      flexWrap: "wrap",
      padding: "12px 0 12px 16px",
      [theme.breakpoints.down("md")]: {
        padding: "12px 0 12px 10px"
      },
      [theme.breakpoints.down("sm")]: {
        padding: "12px 0 12px 16px"
      }
    },
    headerRightSideContainer: {
      padding: "12px 10px 12px 0 !important",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end"
    },
    checkBoxWrapper: {
      display: "flex",
      alignItems: "center",
      width: "100%"
    },
    checkBoxContainer: {
      display: "flex",
      paddingRight: "15px",
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
        whiteSpace: "nowrap"
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "11px",
        whiteSpace: "nowrap"
      }
    },
    errorMessageWrapper: {
      display: "flex",
      paddingLeft: "20px",
      width: "100%"
    },
    sortingContainer: {},
    uploadDocsHeadPadding: {
      // paddingTop: "22px",
      paddingLeft: "24px"
    },
    h_44: {
      height: "44px !important"
    },
    noDocs: {
      height: "26px",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 26,
      letterSpacing: "0.31px",
      textAlign: "center",
      color: "#32325d"
    },
    docsUpload: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 510,
      // height: "calc(90% - 80px)",
      flexWrap: "wrap",
      lineHeight: "normal"
    },
    // docsUploadContainer: {
    //   display: "block",
    //   width: "100%",
    //   marginBottom: "70%",
    //   marginTop: "15%"
    // },
    docsUploadContainer2: {
      display: "block",
      width: "100%",
      marginBottom: "20%",
      marginTop: "20%"
    },
    uploadOutcome: {
      marginRight: "17px",
      width: "94px",
      height: "41px"
    },
    arrow: {
      border: "solid black",
      borderWidth: "0 3px 3px 0",
      display: "inline-block",
      padding: "3px"
    },
    up: {
      transform: "rotate(-135deg)"
    },
    down: {
      transform: "rotate(45deg)"
    },
    wh_16: {
      width: "16px !important",
      height: "16px !important"
    },
    dialogActionArea: {
      display: "block",
      paddingLeft: "20px",
      marginBottom: "20px"
    },
    dialogDeleteButton: {
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: 1.83,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#ffffff",
      // width: "89px",
      textTransform: "none",
      height: "36px",
      borderRadius: "4px",
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      "&:hover": {
        backgroundColor: "#5566d6 !important"
      }
    },
    dialogCancelBtn: {
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      backgroundColor: "#ffffff",
      border: "solid 1px #ececec",
      color: "#32325d",
      "&:hover": {
        backgroundColor: "#ffffff !important"
      }
    },

    dialogText: {
      height: "18px",
      marginTop: "16px",
      marginBottom: "40px",
      fontSize: "12px",
      lineHeight: 1.5,
      letterSpacing: "normal"
    },
    docsErrorMsg: {
      height: 14,
      fontSize: 12,
      lineHeight: 1.5
    },
    fileTime: {
      fontSize: 12,
      lineHeight: 1.6,
      letterSpacing: "normal",
      color: "#8897aa",
      height: 14
      // marginTop: -7,
      // paddingLeft: 23
    },
    editImageIcon: {
      width: 12,
      height: 12,
      marginLeft: 5,
      color: "#8897aa",
      objectFit: "contain",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    editImageDisplay: {
      display: "none"
    },
    dialogHead: {
      //height: "18px",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: 1.29,
      letterSpacing: "normal",
      color: "#32325d"
    },
    optionMenu: {
      textOverflow: "ellipsis !important",
      whiteSpace: "nowrap",
      overflow: "hidden"
    },
    searchIcon: {
      width: "16px",
      height: "16px",
      color: "#32325D",
      position: "relative",
      top: "1px",
      left: "3px"
      // marginRight: "5px"
    },
    clearSearchIcon: {
      width: "16px",
      height: "16px",
      cursor: "pointer",
      position: "relative",
      zIndex: 1
    },
    groupLabelStyle: {
      fontSize: "12px",
      lineHeight: 1.5,
      fontWeight: 600
    },
    mainWrapper: {
      width: "235px",
      height: "360px",
      margin: "8px 0 23px 0",
      padding: "0 0 3px",
      borderRadius: "4px",
      boxShadow: "0 2px 8px 0 rgb(0 0 0 / 16%)",
      backgroundColor: "var(--pale-grey)"
    },
    applyButtonWrapper: {
      padding: "10px",
      height: "50px",
      backgroundColor: "#f6f9fc",
      width: "235px"
    },
    searchContainer: {
      alignSelf: "flex-end",
      flex: 1,
      display: "flex",
      alignItems: "center",
      borderRadius: "20px",
      backgroundColor: "#f7fafc !important",
      padding: "0px 12px 0 4px !important",
      maxWidth: "171px !important",
      height: "36px !important",
      border: "solid 1px #E9ECEF !important",
      fontSize: "12px",
      [theme.breakpoints.down("md")]: {
        maxWidth: "100% !important"
        //marginBottom: "10px",
      }
    },
    searchInputBox: {
      alignSelf: "flex-end",
      flex: 1,
      display: "flex",
      alignItems: "center",
      borderRadius: "20px",
      backgroundColor: "#f7fafc !important",
      padding: "0px 12px 0 4px !important",
      height: "36px !important",
      border: "solid 1px #E9ECEF !important",
      fontSize: "12px",
      [theme.breakpoints.down("md")]: {
        maxWidth: "100% !important"
        //marginBottom: "10px",
      },
      "&.Mui-focused input, .Mui-focused textarea": {
        border: "none !important",
        boxShadow: "none !important"
      }
    },
    setMaxWidth: {
      maxWidth: "171px !important"
    },
    buttonGrid: {
      marginTop: "9%"
    },
    createTagMainWrapper: {
      marginBottom: "7%"
    },
    modalHeading: {
      fontSize: "15px",
      fontWeight: 600
    },
    textFieldSearchContainer: {
      alignSelf: "flex-end",
      flex: 1,
      display: "flex",
      alignItems: "center",
      borderRadius: "20px",
      backgroundColor: "#f7fafc !important",
      maxWidth: "100%",
      border: "solid 1px #E9ECEF !important",
      fontSize: "12px"
    },
    textFieldSearchContainerILP: {
      alignSelf: "flex-end",
      flex: 1,
      display: "flex",
      alignItems: "center",
      backgroundColor: "#f7fafc !important",
      maxWidth: "100%",
      border: "none !important",
      fontSize: "12px"
    },
    "& .Mui-focused .MuiAutocomplete-inputRoot": {
      boxShadow: "none !important"
    },
    failedListHeader: {
      marginTop: "15px",
      alignContent: "center",
      color: "#32325d",
      fontSize: "13px",
      maxHeight: "120px",
      lineHeight: "2.3",
      overflowY: "auto"
    },
    failedList: {
      fontSize: "12px",
      fontWeight: "normal",
      display: "flex",
      alignItems: "center"
    },
    mt_40: {
      marginTop: "40px"
    },
    successText: {
      fontSize: 14,
      lineHeight: 1.5,
      color: "#32325d",
      paddingRight: "70px",
      fontWeight: "normal",
      verticalAlign: "middle"
    },
    failureText: {
      fontSize: 14,
      lineHeight: 1.5,
      color: "#32325d",
      fontWeight: "normal",
      verticalAlign: "middle"
    },
    mr_15: {
      marginRight: "15px"
    },
    modalContainer: {
      padding: 20,
      minWidth: 450,
      maxWidth: "100%"
    },
    closeIcon: {
      display: "flex",
      justifyContent: "space-between",
      flexGrow: 1,
      alignItems: "center",
      float: "right",
      width: 16,
      height: 16,
      cursor: "pointer"
    },
    modalHeader: {
      fontSize: 15,
      fontWeight: 600,
      color: "#32325d",
      width: "100%"
    },
    mt_30: {
      marginTop: "30px"
    },
    mb_0: {
      marginBottom: "0px !important"
    },
    itemContainer: {
      position: "relative"
    },
    documentsMobileView: {
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        flexBasis: "100%",
        marginTop: "15px"
      }
    },
    hideUploadDocsMobileView: {
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    docSearchDesktopView: {
      [theme.breakpoints.down("md")]: {
        display: "none"
      }
    },
    hideMobileBtn: {
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    mobileSearchIcon: {
      display: "none",
      minWidth: "auto",
      marginLeft: "10px",
      height: "36px",
      width: "36px",
      [theme.breakpoints.down("md")]: {
        display: "inline-flex"
      }
    },
    docSearchIpadView: {
      display: "none",
      [theme.breakpoints.down("md")]: {
        display: "flex",
        position: "absolute",
        width: "100%",
        height: "64px",
        padding: "10px 10px 13px 10px",
        alignItems: "center",
        top: "0",
        backgroundColor: "#fff"
      }
    },
    closeSearchInput: {
      position: "absolute",
      cursor: "pointer",
      height: "30px",
      top: "18px",
      padding: "7px",
      right: "20px",
      width: "30px",
      zIndex: 0
    },
    hideHeaderCheckBoxMobileView: {
      [theme.breakpoints.down("sm")]: {
        display: "none !important"
      }
    },
    hideCheckBoxMobileView: {
      [theme.breakpoints.down("md")]: {
        left: "10px"
      },
      [theme.breakpoints.down("sm")]: {
        display: "none !important"
      }
    },
    loanTypeLabel: {
      width: "100%",
      paddingLeft: 25
    },
    formSelectContainer: {
      justifyContent: "space-between",
      display: "flex",
      flexDirection: "row",
      width: "95%",
      padding: "15px 0px 0px 0px"
    },
    radioWrap: {
      paddingLeft: "20px"
    },
    documentVisibilityWrapper: {
      boxShadow: "0 0 12px 0 rgb(50 50 93 / 21%)",
      paddingBottom: "15px",
      paddingTop: "15px",
      backgroundColor: "#ffffff",
      margin: "10px 0px"
    },
    confirmFormModalClass: {
      "& .MuiDialog-container": {
        height: "auto"
      }
    },
    warningModal: {
      fontSize: " 13px",
      padding: "0px 10px"
    }
  })
);
