import React, { useEffect } from "react";
import { Grid, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useSelector, useDispatch } from "react-redux";
import { AllExceptionsAndEnquiries } from "../exceptions-and-enquiries/AllExceptionsAndEnquiries";
import { RootState } from "../../stores/rootReducer";
import NoExceptionImg from "../../images/exceptions/octicon-comment-discussion.svg";
import { useParams } from "react-router";
import { getExceptionsAndEnquiriesByLoanId } from "../../stores/ExceptionsResults/ExceptionActions";
import { LoanStage } from "@toorak/tc-common-fe-sdk";
import { getWaiverList } from "../../stores/EvaluationResults/EvaluationResults.action";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      border: "1px solid red"
    },
    headingContainer: {
      padding: "14px 14px 0px 14px"
    },
    headingTypography: {
      fontSize: "12px !important",
      fontWeight: "bold",
      color: "#32325d",
      overflowWrap: "break-word"
    },
    mandatoryBadge: {
      fontSize: "10px !important",
      fontWeight: "bold",
      color: "#092b53",
      borderRadius: 9,
      backgroundColor: "#e5fcff",
      overflowWrap: "break-word"
    },
    failedContainer: {
      borderRadius: "6px",
      backgroundColor: "#f75676",
      padding: "2px 4px",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      maxWidth: "75px"
    },
    warningIcon: {
      //   padding: "2px",
      height: "19px"
    },
    failedTypography: {
      fontSize: "10px",
      fontWeight: "bold",
      color: "#ffffff",
      height: "14px"
    },
    failReasonContainer: {
      padding: "17px 14px"
    },
    failReason: {
      fontsize: "12px",
      color: "#32325d",
      fontWeight: 500
    },
    failReasonDescription: {
      fontSize: "11px",
      color: "#32325d",
      fontWeight: 500,
      paddingTop: "2px"
    },
    buttonLinkConatiner: {
      padding: "0px 14px 19px 14px"
    },
    buttonLink: {
      fontSize: 12,
      fontWeight: 600,
      color: "#32325d",
      padding: "6px 14px",
      borderRadius: 4,
      boxShadow: "0 3px 4px 0 rgba(0, 0, 0, 0.14)",
      border: "solid 1px #ececec",
      flexShrink: 1
    },
    divider: {
      height: "1px",
      border: "solid 1px #f2f2f2",
      padding: "0px !important",
      marginTop: 14,
      marginBottom: 14
    },
    requestedContainer: {
      padding: "16px 14px 0px 16px"
    },
    requestedDiv: {
      borderRadius: "6px",
      backgroundColor: "#fb623f",
      padding: "5px 8px",
      fontWeight: 600
    },
    requestedUserContainer: {
      padding: "16px 14px 16px 14px"
    },
    requestedUser: {
      fontSize: "11px",
      color: "#8897aa",
      fontWeight: 500,
      padding: "0px 6px 0px 0px",
      wordBreak: "break-all"
    },
    userIcon: {
      paddingRight: "9px",
      height: "28px"
    },
    viewDetailsConatiner: {
      padding: "0px 14px 14px 14px"
    },
    viewDetailsbutton: {
      borderRadius: "4px",
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4"
    },
    passLabel: {
      borderRadius: "6px",
      backgroundColor: "#2ece89",
      padding: "2px 4px",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      maxWidth: "70px"
    },
    passIcon: {
      width: 16,
      height: 16,
      color: "white"
    },
    passText: {
      fontFamily: "OpenSans",
      fontSize: "10px",
      color: "#ffffff"
    },
    noExceptionContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      maxWidth: "428px",
      fontSize: "14px",
      color: "#32325d",
      lineHeight: 0.9
    },
    notCreatedExceptions: {
      margin: "auto",
      fontSize: "16px",
      textAlign: "center",
      color: "#32325d",
      // height: "200px",
      display: "flex",
      alignItems: "center",
      position: "relative",
      marginTop: "20px"
    },
    oval: {
      width: "59px",
      height: "59px",
      backgroundColor: "#f6f6f6",
      borderRadius: 70,
      lineHeight: 70,
      position: "absolute",
      left: "33%"
    },
    noExceptionText: {
      zIndex: 0,
      fontSize: 14,
      fontWeight: 500,
      color: "#32325d"
    },
    exceptionsContainer: {
      textAlign: "center",
      padding: "25px 0"
    }
  })
);

export interface ExceptionAndQueriesProps {
  infoRequired?: boolean;
}
export function ExceptionAndQueries(props: ExceptionAndQueriesProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loanId, loanStage } = useParams<{
    loanId: string;
    loanStage: LoanStage;
  }>() as any;
  useEffect(() => {
    dispatch(getExceptionsAndEnquiriesByLoanId(loanId, loanStage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dispatch(getWaiverList(loanId, loanStage, false, false));
  }, [dispatch, loanId, loanStage]);
  const { infoRequired } = props;
  const exceptionsResult = useSelector<RootState, { exceptions: any[] | any }>(
    (state) => state.exceptionsResultsStore?.exceptionsResult?.exceptions?.[0]
  );
  // const handleClick = () => {
  //   dispatch(
  //     push(`/loan/create/${loanType}/${loanStage}/${loanId}/create/inquiry`)
  //   );
  // };

  return (
    <>
      <Grid
        style={{
          padding: 15
        }}
      >
        {infoRequired ? (
          <Grid
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 9,
              backgroundColor: "#e9fafd",
              borderRadius: 4
            }}
          >
            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <InfoOutlinedIcon
                style={{ width: 15, height: 15 }}
                htmlColor="#32325d"
              />
              <Typography
                variant="h4"
                style={{
                  fontSize: 14,
                  color: "#32325d",
                  fontWeight: 600,
                  marginLeft: 9
                }}
              >
                Info
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="h4"
                style={{ fontSize: 12, color: "#32325d", marginTop: 9 }}
              >
                Conditions would be raised by due diligence team. As a Toorak
                user, you can create a loan Inquiry.
              </Typography>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      {/* add create loan enquiry button here */}
      {/* {isLATUser() ? (
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.viewDetailsConatiner}
        >
          <Button
            variant="contained"
            size="small"
            data-testid="request-waiver-btn"
            className={classes.viewDetailsbutton}
            startIcon={<img src={enquiryIcon} alt="View Details" />}
            onClick={handleClick}
            disabled={isViewOnly(loanStage,loanId)}
          >
            Create a Loan Inquiry
          </Button>
        </Grid>
      ) : null} */}
      {exceptionsResult && Object.keys(exceptionsResult).length > 0 ? (
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ borderTop: "1px solid #f2f2f2" }}
        >
          <AllExceptionsAndEnquiries
            isViewDetailsVisible
            spanValue={12}
            sidePanel
          />
        </Grid>
      ) : (
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          justifyContent="center"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={classes.divider}
          />
          <div className={classes.exceptionsContainer}>
            <img src={NoExceptionImg} alt="No exceptions" />
            <p
              data-testid="no-exceptions-text"
              className={classes.notCreatedExceptions}
            >
              <span className={classes.noExceptionText}>Nothing yet</span>
            </p>
          </div>
        </Grid>
      )}
    </>
  );
}
