import React from "react";
import { Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useStyles } from "../create-loan/common-style/CreateLoan.style";
import { TextOnlyTooltip } from "../create-loan/createLoanHeaders/DisplayLoanStageAndType";

export const Tag = (props: any) => {
  const { data } = props;
  const classes = useStyles();
  return (
    <Typography
      key={data?.label}
      variant="button"
      className={classes.itemTypography}
      style={data?.style}
      onClick={data?.onClick}
    >
      <span
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <span>{data.label}</span>
        {data?.tooltipLabel && (
          <TextOnlyTooltip
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
            placement="right"
            arrow
            title={data.tooltipLabel}
          >
            <InfoOutlinedIcon className={classes.icon} htmlColor="#ffffff" />
          </TextOnlyTooltip>
        )}
      </span>
    </Typography>
  );
};
