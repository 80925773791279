// import { produce } from "immer";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { notToBeCountedDocs } from "../../create-loan/ttf-review/constants";
import { checkIfStringStartsWith } from "../../utils/utils";
// import {
//   // SET_DOC_SPLIT_INITIAL_DATA,
//   UPDATE_DOCS_SPLIT_PARAMS,
//   SET_AUTO_TAGGED_DOCUMENTS,
//   UPDATE_TTF_DATA,
//   UPDATE_FIELDSET,
//   UPDATE_SAVE_AND_SUBMIT_DATA,
//   UPDATE_AUTO_TAGGED_DOCUMENTS,
//   ADD_TO_AUTO_TAGGED_DOCS,
//   UPDATE_LOAN_FIELDS,
//   UPDATE_TTF_FIELDS,
//   UPDATE_PROPERTY_FIELDS,
//   UPDATE_RECONCILIATION_DATA,
//   UPDATE_LOAN_FIELDS_RECONCILED_VIEW,
//   UPDATE_PROPERTY_FIELDS_RECONCILED_VIEW,
//   UPDATE_TOAST,
//   UPDATE_CATEGORY_STATUS,
//   SET_ADDRESS,
//   SET_EXTRACTION_RESPONSE
// } from "./DocumentReview.action";
import { userFieldsInfo } from "../../create-loan/ttf-review/ttfHelperFunctions";
import { ObjectType } from "../../masterView/common";

export interface DocumentReviewStore {
  splitDocsState?: any;
  autoTaggedDocuments: { isLoading: boolean; data: any };
  loanFields: any[];
  userFields: any[];
  propertyFields: any[];
  address: any;
  borrowers: any;
  guarantors: any;
  savedDocumentInfo: any;
  savedReconciledInfo: any;
  submittedDocumentInfo: any;
  submittedReconciledInfo: any;
  extractedInfo: any;
  diligenceInfo: any;
  originalLoanInfo: any;
  categories: any[];
  dataPoints: any[];
  reqDocs: string[];
  escrowPropertyInfo: any;
  escrowloanInfo: any;
  extractionResponse: any;
  toastMessage: {
    showToastMessage: boolean;
    message: string;
    messageType: string;
  };
}

const initialState: DocumentReviewStore = {
  splitDocsState: {},
  autoTaggedDocuments: { isLoading: false, data: [] },
  loanFields: [],
  userFields: [],
  propertyFields: [],
  address: [],
  borrowers: [],
  guarantors: [],
  savedDocumentInfo: {},
  savedReconciledInfo: {},
  submittedDocumentInfo: {},
  submittedReconciledInfo: {},
  extractedInfo: {},
  diligenceInfo: [],
  originalLoanInfo: {},
  categories: [],
  dataPoints: [],
  reqDocs: [],
  escrowPropertyInfo: [],
  escrowloanInfo: {},
  extractionResponse: [],
  toastMessage: {
    showToastMessage: false,
    message: "",
    messageType: ""
  }
};

// Function to merge arrays and overwrite objects in a with those from b if they have the same id
export const mergeArrays = (arr1: ObjectType[], arr2: ObjectType[]) => {
  return arr2.reduce((acc: ObjectType[], obj: ObjectType) => {
    const existingIndex = acc.findIndex(
      (item: ObjectType) => item.id === obj.id
    );
    if (existingIndex !== -1) {
      acc[existingIndex] = obj; // Overwrite existing object with same id
    } else {
      acc.push(obj); // Add new object to the array
    }
    return acc;
  }, arr1.slice()); // Copy of arr1 to avoid mutating the original array
};

const postProcessDocs = (draftState: any) => {
  draftState.autoTaggedDocuments.data =
    draftState.autoTaggedDocuments.data.filter(
      (val: any) => !checkIfStringStartsWith(notToBeCountedDocs, val.name)
    );
};

const DocumentReviewSlice = createSlice({
  name: "documentReview",
  initialState: initialState,
  reducers: {
    setInitialDocSplitData: (state, action: PayloadAction<any>) => {
      const documentDetails = action.payload;
      const obj: any = {};
      obj.files =
        documentDetails?.files &&
        documentDetails?.files.filter(
          (file: any) => file?.tag?.[0]?.toLowerCase() === "closing package"
        );
      obj.closingPackage = 0;
      obj.cacheKey = obj?.files?.[0]?.docsId;
      obj.url = "";
      state.splitDocsState = obj;
    },
    setAddress: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      const { address, borrowers, guarantors } = payload;
      state.address = address;
      state.borrowers = borrowers;
      state.guarantors = guarantors;
    },
    updateDocsSplit: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.splitDocsState = payload;
    },
    getAutomaticTaggedDoc: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      const tempObj: any = payload;
      tempObj.data.forEach((item: any) => {
        item.isOpenState = false;
      });
      if (payload?.fromTaggingScreen) {
        state.autoTaggedDocuments = tempObj;
      } else if (tempObj?.clear) {
        state.autoTaggedDocuments.data = [];
      } else {
        state.autoTaggedDocuments.data = mergeArrays(
          state.autoTaggedDocuments.data,
          tempObj.data
        );
      }
      state.autoTaggedDocuments.isLoading = tempObj.isLoading;
      postProcessDocs(state);
    },
    updateAutoTaggedDoc: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      const tempObj = {
        data: payload,
        isLoading: false
      };
      state.autoTaggedDocuments = tempObj;
      postProcessDocs(state);
    },
    addToAutoTaggedDoc: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      const tempObj = {
        data: payload,
        isLoading: false
      };
      state.autoTaggedDocuments.data = [
        ...state.autoTaggedDocuments.data,
        tempObj.data[tempObj.data.length - 1]
      ];
      postProcessDocs(state);
    },
    updateTtfData: (state, action: PayloadAction<any>) => {
      const {
        loanFields,
        userFields,
        propertyFields,
        savedDocumentInfo,
        submittedDocumentInfo,
        extractedInfo,
        extractionResponse,
        categories,
        dataPoints,
        reqDocs,
        address,
        borrowers,
        guarantors,
        escrowInfoObj,
        escrowPropObj
      } = action.payload;
      state.savedDocumentInfo = savedDocumentInfo;
      state.submittedDocumentInfo = submittedDocumentInfo;
      state.extractedInfo = extractedInfo;
      state.extractionResponse = extractionResponse;
      state.categories = categories;
      state.dataPoints = dataPoints;
      state.loanFields = loanFields;
      state.userFields = userFields;
      state.propertyFields = propertyFields;
      state.reqDocs = reqDocs;
      state.address = address;
      state.borrowers = borrowers;
      state.guarantors = guarantors;
      state.escrowloanInfo = escrowInfoObj;
      state.escrowPropertyInfo = escrowPropObj;
    },
    updateCategoryStatus: (state, action: PayloadAction<any>) => {
      state.categories = action.payload;
    },
    updateReconciliationData: (state, action: PayloadAction<any>) => {
      const {
        loanFields,
        userFields,
        propertyFields,
        savedReconciledInfo,
        submittedReconciledInfo,
        submittedDocumentInfo,
        diligenceInfo,
        originalLoanInfo,
        categories,
        dataPoints
      } = action.payload;
      state.savedReconciledInfo = savedReconciledInfo;
      state.submittedReconciledInfo = submittedReconciledInfo;
      state.diligenceInfo = diligenceInfo;
      state.originalLoanInfo = originalLoanInfo;
      state.loanFields = loanFields;
      state.userFields = userFields;
      state.propertyFields = propertyFields;
      state.categories = categories;
      state.dataPoints = dataPoints;
      state.submittedDocumentInfo = submittedDocumentInfo;
    },
    updateLoanFields: (state, action: PayloadAction<any>) => {
      const { loanFields, userFields, escrowInfoObj } = action.payload;
      const borrowers = userFieldsInfo(userFields, "Borrower");
      const guarantors = userFieldsInfo(userFields, "Guarantor");
      state.loanFields = loanFields;
      state.userFields = userFields;
      state.borrowers = borrowers;
      state.guarantors = guarantors;
      state.escrowloanInfo = escrowInfoObj;
    },
    updateTtfFields: (state, action: PayloadAction<any>) => {
      const { loanFieldsPayload, userFieldsPayload, propertyFieldsPayload } =
        action.payload;
      state.loanFields = loanFieldsPayload;
      state.userFields = userFieldsPayload;
      state.propertyFields = propertyFieldsPayload;
      if (action.payload.extractedInfo) {
        state.extractedInfo = action.payload.extractedInfo;
      }
    },
    updateLoanFieldsReconciledView: (state, action: PayloadAction<any>) => {
      const { loanFields, userFields } = action.payload;
      state.loanFields = loanFields;
      state.userFields = userFields;
    },
    updatePropertyFields: (state, action: PayloadAction<any>) => {
      const { propertyFields, address, escrowPropObj } = action.payload;
      state.propertyFields = propertyFields;
      state.escrowPropertyInfo = escrowPropObj;
      state.address = address;
    },
    updatePropertyFieldsReconciledView: (state, action: PayloadAction<any>) => {
      const { propertyFields } = action.payload;
      state.propertyFields = propertyFields;
    },
    updateFieldSet: (state, action: PayloadAction<any>) => {
      const { updatedFieldSet, tab, index } = action.payload;
      if (tab === "loan") {
        state.loanFields = updatedFieldSet;
      } else if (tab === "users") {
        state.userFields = updatedFieldSet;
      } else {
        state.propertyFields[index] = updatedFieldSet;
      }
    },
    updateSaveSubmit: (state, action: PayloadAction<any>) => {
      state.savedDocumentInfo = action.payload.documentInfo;
      if (action.payload.submittedDocumentInfo) {
        state.submittedDocumentInfo = action.payload.submittedDocumentInfo;
      }
      if (action.payload.extractedInfo) {
        state.extractedInfo = action.payload.extractedInfo;
      }
    },
    updateToast: (state, action: PayloadAction<any>) => {
      state.toastMessage = {
        ...state.toastMessage,
        ...action.payload
      };
    },
    setExtractionResponse: (state, action: PayloadAction<any>) => {
      state.extractionResponse = action.payload;
    }
  }
});

export const {
  setInitialDocSplitData,
  setAddress,
  updateDocsSplit,
  getAutomaticTaggedDoc,
  // updateAutoTaggedDocuments,
  // addToAutoTaggedDocuments,
  updateAutoTaggedDoc,
  addToAutoTaggedDoc,
  updateTtfData,
  updateCategoryStatus,
  updateReconciliationData,
  updateLoanFields,
  updateTtfFields,
  updateLoanFieldsReconciledView,
  updatePropertyFields,
  updatePropertyFieldsReconciledView,
  updateFieldSet,
  updateSaveSubmit,
  updateToast,
  setExtractionResponse
} = DocumentReviewSlice.actions;

export const DocumentReviewReducer = DocumentReviewSlice.reducer;

// export const DocumentReviewReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case SET_DOC_SPLIT_INITIAL_DATA: {
//       const nextState = produce(state, (draftState) => {
//         const documentDetails = action?.payload;
//         const obj: any = {};
//         obj.files =
//           documentDetails?.files &&
//           documentDetails?.files.filter(
//             (file: any) => file?.tag?.[0]?.toLowerCase() === "closing package"
//           );
//         obj.closingPackage = 0;
//         obj.cacheKey = obj?.files?.[0]?.docsId;
//         obj.url = "";
//         draftState.splitDocsState = obj;
//       });
//       return nextState;
//     }

//     case SET_ADDRESS: {
//       const { payload } = action;
//       const { address, borrowers, guarantors } = payload;
//       const nextState = produce(state, (draftState) => {
//         draftState.address = address;
//         draftState.borrowers = borrowers;
//         draftState.guarantors = guarantors;
//       });
//       return nextState;
//     }

//     case UPDATE_DOCS_SPLIT_PARAMS: {
//       const { payload } = action;
//       const nextState = produce(state, (draftState) => {
//         draftState.splitDocsState = payload;
//       });
//       return nextState;
//     }

//     case SET_AUTO_TAGGED_DOCUMENTS: {
//       const { payload } = action;
//       const nextState = produce(state, (draftState) => {
//         const tempObj: any = payload;
//         tempObj.data.forEach((item: any) => {
//           item.isOpenState = false;
//         });
//         if (payload?.fromTaggingScreen) {
//           draftState.autoTaggedDocuments = tempObj;
//         } else if (tempObj?.clear) {
//           draftState.autoTaggedDocuments.data = [];
//         } else {
//           draftState.autoTaggedDocuments.data = [
//             ...draftState.autoTaggedDocuments.data,
//             ...tempObj.data
//           ];
//         }
//         draftState.autoTaggedDocuments.isLoading = tempObj.isLoading;
//         postProcessDocs(draftState);
//       });
//       return nextState;
//     }
//     case UPDATE_AUTO_TAGGED_DOCUMENTS: {
//       const { payload } = action;
//       const tempObj = {
//         data: payload,
//         isLoading: false
//       };
//       const nextState = produce(state, (draftState) => {
//         draftState.autoTaggedDocuments = tempObj;
//         postProcessDocs(draftState);
//       });
//       return nextState;
//     }
//     case ADD_TO_AUTO_TAGGED_DOCS: {
//       const { payload } = action;
//       const tempObj = {
//         data: payload,
//         isLoading: false
//       };
//       const nextState = produce(state, (draftState) => {
//         draftState.autoTaggedDocuments.data = [
//           ...draftState.autoTaggedDocuments.data,
//           tempObj.data[tempObj.data.length - 1]
//         ];
//         postProcessDocs(draftState);
//       });
//       return nextState;
//     }

//     case UPDATE_TTF_DATA: {
//       const {
//         loanFields,
//         userFields,
//         propertyFields,
//         savedDocumentInfo,
//         submittedDocumentInfo,
//         extractedInfo,
//         extractionResponse,
//         categories,
//         dataPoints,
//         reqDocs,
//         address,
//         borrowers,
//         guarantors,
//         escrowInfoObj,
//         escrowPropObj
//       } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         draftState.savedDocumentInfo = savedDocumentInfo;
//         draftState.submittedDocumentInfo = submittedDocumentInfo;
//         draftState.extractedInfo = extractedInfo;
//         draftState.extractionResponse = extractionResponse;
//         draftState.categories = categories;
//         draftState.dataPoints = dataPoints;
//         draftState.loanFields = loanFields;
//         draftState.userFields = userFields;
//         draftState.propertyFields = propertyFields;
//         draftState.reqDocs = reqDocs;
//         draftState.address = address;
//         draftState.borrowers = borrowers;
//         draftState.guarantors = guarantors;
//         draftState.escrowloanInfo = escrowInfoObj;
//         draftState.escrowPropertyInfo = escrowPropObj;
//       });
//       return nextState;
//     }

//     case UPDATE_CATEGORY_STATUS: {
//       const nextState = produce(state, (draftState) => {
//         draftState.categories = action.payload;
//       });
//       return nextState;
//     }

//     case UPDATE_RECONCILIATION_DATA: {
//       const {
//         loanFields,
//         userFields,
//         propertyFields,
//         savedReconciledInfo,
//         submittedReconciledInfo,
//         submittedDocumentInfo,
//         diligenceInfo,
//         originalLoanInfo,
//         categories,
//         dataPoints
//       } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         draftState.savedReconciledInfo = savedReconciledInfo;
//         draftState.submittedReconciledInfo = submittedReconciledInfo;
//         draftState.diligenceInfo = diligenceInfo;
//         draftState.originalLoanInfo = originalLoanInfo;
//         draftState.loanFields = loanFields;
//         draftState.userFields = userFields;
//         draftState.propertyFields = propertyFields;
//         draftState.categories = categories;
//         draftState.dataPoints = dataPoints;
//         draftState.submittedDocumentInfo = submittedDocumentInfo;
//       });
//       return nextState;
//     }

//     case UPDATE_LOAN_FIELDS: {
//       const { loanFields, userFields, escrowInfoObj } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         const borrowers = userFieldsInfo(userFields, "Borrower");
//         const guarantors = userFieldsInfo(userFields, "Guarantor");
//         draftState.loanFields = loanFields;
//         draftState.userFields = userFields;
//         draftState.borrowers = borrowers;
//         draftState.guarantors = guarantors;
//         draftState.escrowloanInfo = escrowInfoObj;
//       });
//       return nextState;
//     }

//     case UPDATE_TTF_FIELDS: {
//       const { loanFieldsPayload, userFieldsPayload, propertyFieldsPayload } =
//         action.payload;
//       const nextState = produce(state, (draftState) => {
//         draftState.loanFields = loanFieldsPayload;
//         draftState.userFields = userFieldsPayload;
//         draftState.propertyFields = propertyFieldsPayload;
//         if (action.payload.extractedInfo) {
//           draftState.extractedInfo = action.payload.extractedInfo;
//         }
//       });
//       return nextState;
//     }

//     case UPDATE_LOAN_FIELDS_RECONCILED_VIEW: {
//       const { loanFields, userFields } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         draftState.loanFields = loanFields;
//         draftState.userFields = userFields;
//       });
//       return nextState;
//     }

//     case UPDATE_PROPERTY_FIELDS: {
//       const { propertyFields, address, escrowPropObj } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         draftState.propertyFields = propertyFields;
//         draftState.escrowPropertyInfo = escrowPropObj;
//         draftState.address = address;
//       });
//       return nextState;
//     }

//     case UPDATE_PROPERTY_FIELDS_RECONCILED_VIEW: {
//       const { propertyFields } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         draftState.propertyFields = propertyFields;
//       });
//       return nextState;
//     }
//     case UPDATE_FIELDSET: {
//       const { updatedFieldSet, tab, index } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         if (tab === "loan") {
//           draftState.loanFields = updatedFieldSet;
//         } else if (tab === "users") {
//           draftState.userFields = updatedFieldSet;
//         } else {
//           draftState.propertyFields[index] = updatedFieldSet;
//         }
//       });
//       return nextState;
//     }
//     case UPDATE_SAVE_AND_SUBMIT_DATA: {
//       const nextState = produce(state, (draftState) => {
//         draftState.savedDocumentInfo = action.payload.documentInfo;
//         if (action.payload.submittedDocumentInfo) {
//           draftState.submittedDocumentInfo =
//             action.payload.submittedDocumentInfo;
//         }
//         if (action.payload.extractedInfo) {
//           draftState.extractedInfo = action.payload.extractedInfo;
//         }
//       });
//       return nextState;
//     }
//     case UPDATE_TOAST: {
//       const nextState = produce(state, (draftState) => {
//         draftState.toastMessage = {
//           ...draftState.toastMessage,
//           ...action.payload
//         };
//       });
//       return nextState;
//     }
//     case SET_EXTRACTION_RESPONSE: {
//       const nextState = produce(state, (draftState) => {
//         draftState.extractionResponse = action.payload;
//       });
//       return nextState;
//     }
//     default:
//       return state;
//   }
// };
