import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
export const MasterViewStyles = makeStyles((theme: Theme) => {
  return createStyles({
    detailsBtn: {
      minWidth: "auto",
      justifyContent: "start",
      padding: "0px"
    },
    bulkHeading: {
      fontSize: "15px",
      lineHeight: 1.69,
      color: "#32325d",
      margin: "20px"
    },
    textAreaClass: {
      "& textarea": {
        padding: "0px !important"
      },
      "& .Mui-focused textarea": {
        boxShadow: "none !important"
      }
    },
    editBody: {
      color: "black"
    },
    assignstyles: {
      display: "flex",
      alignItems: "center",
      position: "relative",
      right: "5px",
      lineHeight: 0
    },
    detailsLabel: {
      width: 70,
      position: "relative"
    },
    detailsEndIcon: {
      position: "absolute",
      right: 0
    },
    editIcon: {
      height: "16px",
      width: "16px"
    },
    deleteIcon: {
      height: "16px",
      width: "16px"
    },
    itemHeaderText: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "1.57",
      color: "#32325d",
      paddingBottom: "1.5%"
    },
    tooltip: {
      color: "#32325d",
      backgroundColor: "white",
      fontFamily: `"Open Sans", sans-serif !important`,
      fontSize: 12,
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      minWidth: "185px",
      maxWidth: "400px",
      minHeight: "40px",
      padding: "9px"
    },
    arrow: {
      color: "white"
    },
    selectDropdown: {
      "& .MuiListItemText-root": {
        transform: "translateY(3px)",
        paddingRight: "7px"
      }
    },
    selectRoot: {
      "& .MuiSelect-select": {
        marginTop: "0px",
        minHeight: "1.6876em",
        paddingBottom: "7px !important",
        background: "white"
      },
      "& .MuiSelect-select:focus": {
        backgroundColor: "#ffffff !important",
        margin: "0px",
        marginTop: "1px !important"
      },
      "& legend": {
        display: "none"
      },
      "& .MuiListItemText-root": {
        marginTop: "-8px",
        position: "relative",
        right: 7,
        top: 3
      },
      "& .MuiTypography-body1": {
        overflow: "hidden",
        fontSize: "12px",
        textOverflow: "ellipsis"
      },
      "& fieldset": {
        top: "0"
      },
      "& svg": {
        right: -1,
        top: 9
      }
    },
    selectRootWhiteText: {
      height: 28,
      borderRadius: 6,
      "& .MuiTypography-body1": {
        overflow: "hidden",
        fontSize: 10,
        textOverflow: "ellipsis",
        maxWidth: "19ch"
      },
      "& .MuiSelect-select": {
        color: "#fff"
      }
    },
    selectRootBlackText: {
      height: 28,
      borderRadius: 6,
      "& .MuiTypography-body1": {
        overflow: "hidden",
        fontSize: 10,
        textOverflow: "ellipsis",
        maxWidth: "19ch"
      },
      "& .MuiSelect-select": {
        color: "black"
      }
    },
    inputCommentField: {},
    input: {
      fontSize: "12px",
      color: "#424242",
      fontWeight: 100
    },
    subHeader: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #e2e4f1"
    },
    searchInput: {
      backgroundColor: "#f7fafc",
      fontSize: 12,
      fontWeight: 600,
      color: "#8897aa",
      border: "1px solid #e9ecef",
      borderRadius: 30,
      width: 290
    },
    totalCountWrap: {
      display: "flex",
      marginTop: 8
    },
    divider: {
      height: 16,
      margin: "0 6px",
      width: 2,
      backgroundColor: "#979797"
    },
    clearIcon: {
      backgroundColor: "transparent",
      border: "none"
    },
    table: {
      minWidth: 650
    },
    boxTableContainer: {
      margin: "16px 0",
      maxHeight: "calc(100vh - 110px)",
      boxShadow: "0 2px 12px 0 rgb(50 50 93 / 21%)",
      border: "1px solid #ddd"
    },
    tableRowGrid: {
      display: "grid",

      "& .MuiTableCell-root.MuiTableCell-head": {
        display: "flex",
        alignItems: "center",
        padding: "10px 12px",
        color: "#32325d",
        fontWeight: "600",
        lineHeight: 1.5,
        border: "1px solid #e9ecef"
      },
      "& .MuiTableCell-root": {
        padding: "16px 12px 16px 12px",
        border: "1px solid #e9ecef"
      }
    },
    tableBody: {
      minHeight: "250px",
      display: "block"
    },
    resolvedCheckboxColor: {
      color: "#2ece89 !important"
    },
    checkboxBase: {
      padding: "0px",
      paddingBottom: 4,
      "& .MuiSvgIcon-root": {
        fontSize: "1.2rem"
      }
    },
    provisionButton: {
      height: "30px"
    },
    resolveButton: {
      marginRight: "1rem",
      background: "#5566d6",
      color: "white"
    },
    headClass: {
      justifyContent: "center"
    },
    rowClass: {
      textAlign: "center"
    },
    stickyTopZero: {
      position: "sticky",
      top: 0,
      zIndex: 3
    },
    stickyHeader: {
      top: 0,
      left: 0,
      position: "sticky",
      backgroundColor: "#eef6ff",
      zIndex: 1
    },
    stickyRow: {
      top: 0,
      left: 0,
      position: "sticky !important" as "sticky",
      backgroundColor: "white",
      zIndex: 2
    },
    arrowLeft1: {
      position: "absolute",
      marginTop: "10px",
      height: "32px",
      cursor: "pointer",
      right: "-14px",
      "&:hover": {
        cursor: "pointer !important"
      }
    },
    arrowLeft: {
      //position: "absolute",
      height: "29px",
      cursor: "pointer",
      right: "-16px",
      "&:hover": {
        cursor: "pointer !important"
      }
    },
    arrowLeft2: {
      position: "absolute",
      height: "29px",
      cursor: "pointer",
      "&:hover": {
        cursor: "pointer !important"
      }
    },
    root: {
      border: "none !important",
      "& .MuiSelect-select.MuiSelect-select": {
        fontSize: "12px",
        fontWeight: "100",
        padding: "3px"
      }
    },
    statusText: {
      color: "#ffffff",
      margin: 0,
      padding: 5,
      fontSize: 10,
      borderRadius: 6,
      backgroundColor: "#172b4d"
    },
    subStatusText: {
      color: "#32325d",
      padding: "2px 4px",
      fontSize: "8px",
      background: "#d2e3ef",
      marginTop: 3,
      whiteSpace: "break-spaces",
      borderRadius: 5
    },
    statusContainer: {
      width: "100%",
      height: "100%",
      textTransform: "uppercase",
      textAlign: "center",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      fontWeight: "bold"
    },
    statusClass: {
      padding: "5px 12px !important"
    },
    modalContainer: {
      flexDirection: "column",
      width: "500px",
      height: "250px"
    },
    checkLineWrapper: {
      display: "flex",
      alignItems: "center",
      padding: "0px 20px",
      "& .MuiOutlinedInput-input": {
        height: "169px !important"
      }
    },
    textArea: {
      padding: 0
    },
    commentsField: {
      border: "1px solid #cad1d7",
      borderRadius: "3px",
      position: "relative",
      top: -5,
      height: 30,
      paddingLeft: 10,
      paddingTop: 3,
      cursor: "pointer"
    },
    addText: {
      padding: "5px 0px 5px 17px",
      position: "absolute",
      bottom: 0,
      margin: 0
    },
    sectionPadding: {
      paddingTop: "35px",
      paddingBottom: "10px"
    },
    modalCancelButton: {
      padding: "6px 16px",
      borderRadius: 4,
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      marginRight: 15,
      borderWidth: 1,
      borderColor: "#ececec"
    },
    defaulttextAmount: {
      color: "#32325d",
      fontSize: 12,
      float: "right"
    },
    dateContainer: {
      position: "relative",
      paddingTop: "10px",
      "& input": {
        visibility: "hidden"
      },
      "& fieldset": {
        height: 41
      },
      "& label": {
        color: "#000000",
        top: "2px",
        padding: "2px 8px !important"
      },
      "& label + div": {
        marginTop: "0px !important",
        height: 36,
        background: "#ffffff"
      }
    },
    headerRowBackground: {},
    loanTypeTags: {
      lineHeight: "normal",
      marginTop: "0.2rem",
      display: "flex",
      flexDirection: "row"
    },
    fundingTag: {
      backgroundColor: "#3d55df",
      borderRadius: "1px",
      color: "white",
      padding: "0 0.2rem",
      fontSize: "12px",
      lineHeight: "1.2",
      marginRight: "0.5rem"
    },
    expressTag: {
      backgroundColor: "#1071ee",
      borderRadius: "1px",
      color: "white",
      padding: "0 0.2rem",
      fontSize: "12px",
      lineHeight: "1.2"
    },
    premierTag: {
      backgroundColor: "#b87300",
      borderRadius: "1px",
      color: "white",
      padding: "0 0.2rem",
      fontSize: "12px",
      lineHeight: "1.2"
    },
    formControlDropdown: {
      width: "95%",
      height: "28px",
      position: "relative",
      marginBottom: "10px",
      fontSize: 12,
      "& .MuiInputBase-root": {
        fontSize: "4px"
      },
      "& .MuiSelect-select:focus": {
        backgroundColor: "inherit !important"
      }
    },
    kdmButtonClass: {
      minWidth: "unset",
      paddingTop: 2,
      "& .MuiButton-startIcon": {
        marginLeft: 0,
        marginRight: 0
      }
    }
  });
});
