import {
  accuralTypeDropDown,
  condoEligibilityDropDown,
  loanPurposeDropDown,
  loanStructureDropDown,
  prepaymentPenaltyTypeDropDown,
  rateType,
  thirtyYearLoanPurpose,
  yOrNDropDown,
  thirtyYearPropertyTypeDropDown,
  foreignNationalOptions,
  armIndexTypes
} from "../constants/loanCreationDropDownValues";

export const loanInfoParamsForBridge = [
  {
    label: "Primary Loan ID",
    path: "loanInfo.primaryLoanId",
    field: "primaryLoanId",
    aliasKey: "primaryLoanID",
    type: "alphanumeric",
    tags: ["Data Tape"]
  },
  {
    label: "Loan Structure",
    path: "loanInfo.loanStructure",
    field: "loanStructure",
    aliasKey: "selectedLoanStructure",
    rulesKey: "loanStructure",
    type: "dropdown",
    values: loanStructureDropDown,
    tags: ["HUD-1"]
  },
  {
    label: "Loan Purpose",
    path: "loanInfo.loanPurpose",
    field: "loanPurpose",
    type: "dropdown",
    values: loanPurposeDropDown,
    tags: ["HUD-1"]
  },
  {
    label: "Financed Budget Amount ($)",
    path: "loanEconomics.financedBudgetAmount",
    field: "financedBudgetAmount",
    type: "currency",
    tags: ["HUD-1"]
  },
  {
    label: "Total Budget Amount ($)",
    path: "loanEconomics.totalBudgetAmount",
    field: "totalBudgetAmount",
    aliasKey: "budgetAmount",
    rulesKey: "totalBudgetAmount",
    type: "currency",
    tags: ["Appraisal", "Rehab Budget", "Project Feasibility Review", "BPO/BOV"]
  },
  {
    label: "Interest Reserve ($)",
    path: "loanEconomics.financedInterestReserve",
    field: "financedInterestReserve",
    aliasKey: "financialInterestReserve",
    rulesKey: "financedInterestReserve",
    type: "currency",
    tags: ["HUD-1"]
  },
  {
    label: "Original Loan Amount ($)",
    path: "loanEconomics.originalLoanAmount",
    field: "originalLoanAmount",
    aliasKey: "loanAmount",
    rulesKey: "originalLoanAmount",
    type: "currency",
    tags: ["Promissory Note", "HUD-1"]
  },
  {
    label: "Original Maximum Loan Amount ($)",
    path: "loanEconomics.originalMaximumLoanAmount",
    field: "originalMaximumLoanAmount",
    aliasKey: "maxLoanAmount",
    rulesKey: "originalMaximumLoanAmount",
    type: "currency",
    tags: ["Promissory Note"]
  },
  {
    label: "Interest Rate (As of Cut off Date) (%)",
    path: "loanEconomics.interestRateAsOfCutOffDate",
    field: "interestRateAsOfCutOffDate",
    aliasKey: "interestRate",
    type: "percentage",
    tags: ["Promissory Note"]
  },
  {
    label: "Accrual Type",
    path: "loanEconomics.accrualType",
    field: "accrualType",
    type: "dropdown",
    values: accuralTypeDropDown,
    tags: ["Promissory Note"]
  },
  {
    label: "Origination Date",
    path: "loanInfo.originationDate",
    field: "originationDate",
    type: "date",
    tags: ["Promissory Note"]
  },
  {
    label: "First Payment Date of Loan",
    path: "loanInfo.firstPaymentDateOfLoan",
    field: "firstPaymentDateOfLoan",
    aliasKey: "firstPaymentDate",
    rulesKey: "firstPaymentDateOfLoan",
    type: "date",
    tags: ["Promissory Note"]
  },
  {
    label: "Original Maturity Date",
    path: "loanInfo.originalMaturityDate",
    field: "originalMaturityDate",
    aliasKey: "maturityDate",
    rulesKey: "originalMaturityDate",
    type: "date",
    tags: ["Promissory Note"]
  },
  {
    label: "Total Origination and Discount Points (%)",
    path: "loanEconomics.totalOriginationAndDiscountPoints",
    field: "totalOriginationAndDiscountPoints",
    aliasKey: "discountPoints",
    rulesKey: "totalOriginationAndDiscountPoints",
    type: "percentage",
    tags: ["HUD-1"]
  },
  {
    label: "Cash Out Amount ($)",
    path: "loanEconomics.cashOutAmount",
    field: "cashOutAmount",
    type: "currency",
    tags: ["HUD-1"]
  },
  {
    label: "Extension Option (Months)",
    path: "loanInfo.extensionOptions",
    field: "extensionOptions",
    aliasKey: "extensionOption",
    rulesKey: "extensionOptions",
    type: "number",
    tags: ["Promissory Note"]
  },
  {
    label: "Default Rate (%)",
    path: "loanInfo.defaultRate",
    field: "defaultRate",
    type: "percentage",
    skipRules: true,
    tags: ["Promissory Note"]
  },
  {
    label: "Default Rate Curable (Grace Days)",
    path: "loanInfo.defaultRateCurable",
    field: "defaultRateCurable",
    type: "number",
    skipRules: true,
    tags: ["Promissory Note"]
  }
];

export const loanInfoParamsForDSCR = [
  {
    label: "Toorak Loan ID",
    path: "loanInfo.primaryLoanId",
    field: "primaryLoanId",
    aliasKey: "primaryLoanID",
    type: "alphanumeric",
    tags: ["Data Tape"]
  },
  {
    label: "Number Of Properties",
    path: "loanInfo.noOfProperties",
    field: "noOfProperties",
    aliasKey: "numberOfProperties",
    type: "number",
    tags: ["Data Tape"]
  },
  {
    label: "Cross Collaterlization (Y/N)",
    path: "loanInfo.crossCollaterlization",
    field: "crossCollaterlization",
    aliasKey: "selectedCross",
    rulesKey: "crossCollaterlization",
    type: "dropdown",
    values: yOrNDropDown,
    tags: ["Data Tape"]
  },
  {
    label: "Loan Purpose",
    path: "loanInfo.loanPurpose",
    field: "loanPurpose",
    type: "dropdown",
    values: thirtyYearLoanPurpose,
    tags: ["HUD-1"]
  },
  {
    label: "Condo Eligibility",
    path: "loanInfo.condoEligibility",
    field: "condoEligibility",
    type: "dropdown",
    values: condoEligibilityDropDown,
    tags: ["Condo Questionnaire"]
  },
  {
    label: "Interest Reserve ($)",
    path: "loanEconomics.financedInterestReserve",
    field: "financedInterestReserve",
    aliasKey: "financialInterestReserve",
    rulesKey: "financedInterestReserve",
    type: "currency",
    tags: ["HUD-1"]
  },
  {
    label: "Original Loan Amount ($)",
    path: "loanEconomics.originalLoanAmount",
    field: "originalLoanAmount",
    aliasKey: "loanAmount",
    rulesKey: "originalLoanAmount",
    type: "currency",
    tags: ["Promissory Note"]
  },
  {
    label: "Interest Only Period",
    path: "loanEconomics.interestOnlyPeriod",
    field: "interestOnlyPeriod",
    type: "number",
    tags: ["Promissory Note"]
  },
  {
    label: "Prepay Penalties Type",
    path: "loanEconomics.prepayPenaltyType",
    field: "prepayPenaltyType",
    type: "dropdown",
    values: prepaymentPenaltyTypeDropDown,
    tags: ["Promissory Note", "Pre-Payment Rider"]
  },
  {
    label: "Rate Type",
    path: "loanEconomics.rateType",
    field: "rateType",
    type: "dropdown",
    values: rateType,
    tags: ["Promissory Note"]
  },
  {
    label: "Interest Rate (As of Cut off Date) (%)",
    path: "loanEconomics.interestRateAsOfCutOffDate",
    field: "interestRateAsOfCutOffDate",
    aliasKey: "interestRate",
    type: "percentage",
    tags: ["Promissory Note"]
  },
  {
    label: "Origination Date",
    path: "loanInfo.originationDate",
    field: "originationDate",
    type: "date",
    tags: ["Promissory Note"]
  },
  {
    label: "First Payment Date of Loan",
    path: "loanInfo.firstPaymentDateOfLoan",
    field: "firstPaymentDateOfLoan",
    aliasKey: "firstPaymentDate",
    rulesKey: "firstPaymentDateOfLoan",
    type: "date",
    tags: ["Promissory Note"]
  },
  {
    label: "Original Maturity Date",
    path: "loanInfo.originalMaturityDate",
    field: "originalMaturityDate",
    aliasKey: "maturityDate",
    rulesKey: "originalMaturityDate",
    type: "date",
    tags: ["Promissory Note"]
  },
  {
    label: "Total Origination and Discount Points (%)",
    path: "loanEconomics.totalOriginationAndDiscountPoints",
    field: "totalOriginationAndDiscountPoints",
    aliasKey: "discountPoints",
    rulesKey: "totalOriginationAndDiscountPoints",
    type: "percentage",
    tags: ["HUD-1"]
  },
  {
    label: "Cash Out Amount ($)",
    path: "loanEconomics.cashOutAmount",
    field: "cashOutAmount",
    type: "currency",
    tags: ["HUD-1"]
  },
  {
    label: "Default Rate (%)",
    path: "loanInfo.defaultRate",
    field: "defaultRate",
    type: "percentage",
    skipRules: true,
    tags: ["Promissory Note"]
  },
  {
    label: "Default Rate Curable (Grace Days)",
    path: "loanInfo.defaultRateCurable",
    field: "defaultRateCurable",
    type: "number",
    skipRules: true,
    tags: ["Promissory Note"]
  },
  {
    label: "Index Type",
    path: "loanEconomics.armIndex",
    field: "armIndex",
    type: "dropdown",
    values: armIndexTypes,
    tags: ["Promissory Note"]
  },
  {
    label: "Gross Margin",
    path: "loanEconomics.grossArmMargin",
    field: "grossArmMargin",
    type: "percentage",
    tags: ["Promissory Note"]
  },
  {
    label: "Arm Rounding Factor",
    path: "loanEconomics.armRoundingFactor",
    field: "armRoundingFactor",
    type: "text",
    skipRules: true,
    tags: ["Promissory Note"]
  },
  {
    label: "Initial Fixed Rate Period",
    path: "loanEconomics.initialPeriodicCap",
    field: "initialPeriodicCap",
    type: "number",
    skipRules: true,
    tags: ["Promissory Note"]
  },
  {
    label: "Initial Interest Rate Cap (Change Up)",
    path: "loanEconomics.initialInterestRateUp",
    field: "initialInterestRateUp",
    type: "text",
    skipRules: true,
    tags: ["Promissory Note"]
  },
  {
    label: "Initial Interest Rate Cap (Change Down)",
    path: "loanEconomics.initialInterestRateDown",
    field: "initialInterestRateDown",
    type: "text",
    skipRules: true,
    tags: ["Promissory Note"]
  },
  {
    label: "Subsequent Interest Rate Reset Period",
    path: "loanEconomics.subsequentPeriodicCap",
    field: "subsequentPeriodicCap",
    type: "number",
    skipRules: true,
    tags: ["Promissory Note"]
  },
  {
    label: "Subsequent Interest Rate Cap (Change Up)",
    path: "loanEconomics.subsequentInterestRateUp",
    field: "subsequentInterestRateUp",
    type: "text",
    skipRules: true,
    tags: ["Promissory Note"]
  },
  {
    label: "Subsequent Interest Rate Cap (Change Down)",
    path: "loanEconomics.subsequentInterestRateDown",
    field: "subsequentInterestRateDown",
    type: "text",
    skipRules: true,
    tags: ["Promissory Note"]
  },
  {
    label: "Prepayment Penalty Total Term (Months)",
    path: "loanEconomics.prepaymentPenaltyMonths",
    field: "prepaymentPenaltyMonths",
    type: "number",
    tags: ["Promissory Note", "Pre-Payment Rider"]
  },
  {
    label: "Borrower is LLC",
    path: "loanEconomics.borrowerIsLLC",
    field: "borrowerIsLLC",
    type: "text",
    skipRules: true,
    tags: ["Data Tape"]
  },
  {
    label: "HOA Payment Frequency",
    path: "loanEconomics.hoaPaymentFrequency",
    field: "hoaPaymentFrequency",
    type: "text",
    skipRules: true,
    tags: ["Appraisal", "BPO/BOV"]
  },
  {
    label: "Borrower Liquidity ($)",
    path: "loanEconomics.borrowerLiquidity",
    field: "borrowerLiquidity",
    type: "currency",
    skipRules: true,
    tags: ["Proof of Funds/Liquidity"]
  },
  {
    label: "Escrow Taxes at Closing ($)",
    path: "loanDetailId.loanSummaryId.escrowTaxes",
    field: "escrowTaxes",
    type: "readOnly",
    skipRules: true,
    commentsDisabled: true,
    tags: []
  },
  {
    label: "Escrow Insurance at Closing ($)",
    path: "loanDetailId.loanSummaryId.escrowInsurance",
    field: "escrowInsurance",
    type: "readOnly",
    skipRules: true,
    commentsDisabled: true,
    tags: []
  }
];

export const guarantorParams = [
  {
    label: "Borrowing Entity Name",
    path: "customer.accountName",
    borrower: "borrowingEntityName",
    guarantor: "borrowingEntityName",
    rulesKey: "partyType",
    type: "text",
    tags: ["Promissory Note"],
    partyType: "account"
  },
  {
    label: "First Name",
    path: "customer.firstName",
    borrower: "firstName",
    guarantor: "guarantorFirstName",
    rulesKey: "firstName",
    type: "text",
    tags: ["Guaranty"],
    partyType: "person"
  },
  {
    label: "Last Name",
    path: "customer.lastName",
    borrower: "lastName",
    guarantor: "guarantorLastName",
    rulesKey: "lastName",
    type: "text",
    tags: ["Guaranty"],
    partyType: "person"
  },
  {
    label: "Foreign National",
    path: "customer.foreignNationalString",
    borrower: "foreignNationalString",
    guarantor: "foreignNationalString",
    borrowerIdentifier: "foreignNationalString",
    rulesKey: "foreignNationalString",
    type: "dropdown",
    values: foreignNationalOptions,
    tags: [
      "Driver License",
      "SSN",
      "Passport",
      "Permanent Resident Identification"
    ]
  },
  {
    label: "Original Credit Report Date",
    path: "originalCreditScoreReportDate",
    borrower: "originalCreditReportDate",
    guarantor: "originalCreditReportDate",
    borrowerIdentifier: "originalCreditScoreReportDate",
    rulesKey: "originalCreditScoreReportDate",
    type: "date",
    tags: ["Credit Report"],
    partyType: "person"
  },
  {
    label: "Original Credit Score (Median)",
    path: "originalCreditScoreMedian",
    borrower: "originalCreditScoreMedian",
    guarantor: "creditScore",
    rulesKey: "originalCreditScoreMedian",
    type: "creditscore",
    tags: ["Credit Report"],
    partyType: "person"
  },
  {
    label: "EIN",
    path: "customer.eiNumber",
    borrower: "eiNumber",
    guarantor: "eiNumber",
    type: "ein",
    skipRules: true,
    tags: ["EIN/TIN"],
    partyType: "account"
  },
  {
    label: "SSN",
    path: "customer.ssNumber",
    borrower: "ssNumber",
    guarantor: "ssNumber",
    type: "ssn",
    skipRules: true,
    tags: ["SSN"],
    partyType: "person"
  }
];

export const propertyParamsForBridge = [
  {
    label: `Original "As Is" Appraisal Value ($)`,
    path: "propertyEconomics.originalAsIsAppraisalValue",
    field: "originalAsIsAppraisalValue",
    aliasKey: "originalAppraisalValue",
    rulesKey: "originalAsIsAppraisalValue",
    type: "currency",
    tags: ["Appraisal", "BPO/BOV"]
  },
  {
    label: "Property Acquisition Date",
    path: "propertyinfo.propertyAcquisitionDate",
    field: "propertyAcquisitionDate",
    aliasKey: "propertyAcquisitionDate",
    rulesKey: "propertyAcquisitionDate",
    type: "dateOnly",
    tags: ["Appraisal", "BPO/BOV"]
  },
  {
    label: `Original "As Repaired" Appraisal Value ($)`,
    path: "propertyEconomics.originalAsRepairedAppraisedValue",
    field: "originalAsRepairedAppraisedValue",
    aliasKey: "originalRepairedAppraisalValue",
    rulesKey: "originalRepairedAppraisalValue",
    type: "currency",
    tags: ["Appraisal", "BPO/BOV"]
  },
  {
    label: "Most Recent Property Valuation Date",
    path: "propertyinfo.recentPropertyValuationDate",
    field: "recentPropertyValuationDate",
    aliasKey: "propertyValuationDate",
    rulesKey: "mostRecentPropertyValuationDate",
    type: "dateOnly",
    tags: ["Appraisal", "BPO/BOV"]
  },
  {
    label: "Purchase Price ($)",
    path: "propertyEconomics.purchasePrice",
    field: "purchasePrice",
    aliasKey: "purchasePrice",
    rulesKey: "purchasePrice",
    type: "currency",
    tags: ["HUD-1", "Purchase Contract"]
  },
  {
    label: "Cost Basis ($)",
    path: "propertyEconomics.costBasis",
    field: "costBasis",
    aliasKey: "costBasis",
    rulesKey: "costBasisAfterCostIncurred",
    type: "currency",
    tags: ["HUD-1"]
  },
  {
    label: "Pre-Rehab Square Footage",
    path: "propertyinfo.preRehabSquareFootage",
    field: "preRehabSquareFootage",
    aliasKey: "preRehabSquareFootage",
    rulesKey: "preRehabSquareFootage",
    type: "Number",
    tags: ["Appraisal", "BPO/BOV"]
  },
  {
    label: "Post-Rehab Square Footage",
    path: "propertyinfo.postRehabSquareFootage",
    field: "postRehabSquareFootage",
    aliasKey: "postRehabSquareFootage",
    rulesKey: "postRehabSquareFootage",
    type: "Number",
    tags: ["Appraisal", "BPO/BOV"]
  }
];

export const propertyParamsForDSCR = [
  {
    label: "Property Type",
    path: "propertyinfo.propertyType",
    field: "propertyType",
    aliasKey: "propertyType",
    rulesKey: "propertyType",
    type: "dropdown",
    values: thirtyYearPropertyTypeDropDown.map(val =>
      val === "SFR" ? "Single Family Detached" : val
    ),
    tags: ["Appraisal", "BPO/BOV"]
  },
  {
    label: `Original "As Is" Appraisal Value ($)`,
    path: "propertyEconomics.originalAsIsAppraisalValue",
    field: "originalAsIsAppraisalValue",
    aliasKey: "originalAppraisalValue",
    rulesKey: "originalAsIsAppraisalValue",
    type: "currency",
    tags: ["Appraisal", "BPO/BOV"]
  },
  {
    label: "Property Acquisition Date",
    path: "propertyinfo.propertyAcquisitionDate",
    field: "propertyAcquisitionDate",
    aliasKey: "propertyAcquisitionDate",
    rulesKey: "propertyAcquisitionDate",
    type: "dateOnly",
    tags: ["Appraisal", "BPO/BOV"]
  },
  {
    label: "Most Recent Property Valuation Date",
    path: "propertyinfo.recentPropertyValuationDate",
    field: "recentPropertyValuationDate",
    aliasKey: "propertyValuationDate",
    rulesKey: "mostRecentPropertyValuationDate",
    type: "dateOnly",
    tags: ["Appraisal", "BPO/BOV"]
  },
  {
    label: "Third Party Valuation ($)",
    path: "propertyEconomics.thirdPartyValuation",
    field: "thirdPartyValuation",
    aliasKey: "thirdPartyValuation",
    rulesKey: "thirdPartyValuation",
    type: "currency",
    tags: ["Desktop Review"]
  },
  {
    label: "Purchase Price ($)",
    path: "propertyEconomics.purchasePrice",
    field: "purchasePrice",
    aliasKey: "purchasePrice",
    rulesKey: "purchasePrice",
    type: "currency",
    tags: ["HUD-1", "Purchase Contract"]
  },
  {
    label: "Cost Basis ($)",
    path: "propertyEconomics.costBasis",
    field: "costBasis",
    aliasKey: "costBasis",
    rulesKey: "costBasisAfterCostIncurred",
    type: "currency",
    tags: ["HUD-1"]
  },
  {
    label: [
      "Rental Characterisation",
      "Current Lease Term",
      "Market Rent Monthly",
      "In Place Lease Rent Monthly",
      "Most Recent Lease Rent Monthly"
    ],
    path: "propertyUnit",
    key: [
      "leaseStatus",
      "currentLeaseTermMonthly",
      "marketRentMonthly",
      "inPlaceLeaseRentMonthly",
      "mostRecentLeaseRentMonthly"
    ],
    field: [
      "leaseStatus",
      "currentLeaseTermMonthly",
      "marketRentMonthly",
      "inPlaceLeaseRentMonthly",
      "mostRecentLeaseRentMonthly"
    ],
    aliasKey: [
      "leaseStatus",
      "currentLeaseTerm",
      "marketRentMonthly",
      "inPlaceLeaseRent",
      "recentLeaseRent"
    ],
    rulesKey: [
      "leaseStatus",
      "currentLeaseTermMonths",
      "marketRentMonthly",
      "inPlaceLeaseRentMonthly",
      "mostRecentLeaseRentMonthly"
    ],
    type: ["dropdown", "Number", "currency", "currency", "currency"],
    values: [],
    tags: [
      ["Appraisal", "BPO/BOV"],
      ["Lease/Rental Agreement"],
      ["Appraisal", "BPO/BOV"],
      ["Lease/Rental Agreement"],
      ["Lease/Rental Agreement"]
    ]
  },
  {
    label: "Annual Property Taxes ($)",
    path: "propertyEconomics.annualPropertyTaxes",
    field: "annualPropertyTaxes",
    aliasKey: "annualPropertyTaxes",
    rulesKey: "annualPropertyTaxes",
    type: "currency",
    tags: ["Real Estate Taxes"]
  },
  {
    label: "Annual Hazard Insurance ($)",
    path: "propertyEconomics.annualHazardInsurance",
    field: "annualHazardInsurance",
    aliasKey: "annualHazardInsurance",
    rulesKey: "annualHazardInsurance",
    type: "currency",
    tags: ["Proof of Casualty Insurance"]
  },
  {
    label: "Annual Flood Insurance ($)",
    path: "propertyEconomics.annualFloodInsurance",
    field: "annualFloodInsurance",
    aliasKey: "annualFloodInsurance",
    rulesKey: "annualFloodInsurance",
    type: "currency",
    tags: ["Proof of Flood Insurance"]
  },
  {
    label: "Annual HOA Fee ($)",
    path: "propertyEconomics.annualHoaFee",
    field: "annualHoaFee",
    aliasKey: "annualHoaFee",
    rulesKey: "annualHOAFee",
    type: "currency",
    tags: ["Appraisal", "BPO/BOV"]
  },
  {
    label: "Escrow Taxes at Closing ($)",
    path: "propertySummary.escrowTaxes",
    field: "escrowTaxes",
    type: "readOnly",
    skipRules: true,
    commentsDisabled: true,
    tags: []
  },
  {
    label: "Escrow Insurance at Closing ($)",
    path: "propertySummary.escrowInsurance",
    field: "escrowInsurance",
    type: "readOnly",
    skipRules: true,
    commentsDisabled: true,
    tags: []
  },
  {
    label: "Original Property Valuation Type",
    path: "propertyinfo.propertyValuationType",
    field: "propertyValuationType",
    type: "dropdown",
    values: ["As-Is", "ARV", "Subject To"],
    skipRules: true,
    tags: ["Appraisal", "BPO/BOV"]
  }
];
