import React, { useEffect } from "react";
import { Grid, Button, Typography } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { HtmlTooltip, LoanStage } from "@toorak/tc-common-fe-sdk";
import SubmitIcon from "../../images/submit_icon.svg";
import { waiverFormStyles } from "../waiver-form/Common.style";
import { RootState } from "../../stores/rootReducer";
import {
  postChatByIdAndUpdateResponseType,
  // cancelAction,
  // updateDocumentInException,
  setAskAQuestionClicked,
  setSatisfyExceptionsClicked,
  uploadDocToBoxCom,
  getResponsesByExceptionId,
  // setWaiverRequestedClicked,
  getViewMoreDetails
  // deleteFromBulkSatisfyPayload,
  // UPDATE_EXCEPTION,
  // resetExceptionStore
} from "../../stores/ExceptionsResults/ExceptionActions";
import { handleFileUpload, uploadFileToDocuments } from "./DocumentUploadApis";
import {
  // clearLoader,
  hideLoader,
  showLoader
} from "../../stores/loaderAndException/loaderAndException.action";
import { getCookie } from "../../utils/cookies";
import { CloseIcon } from "../Icons";
import { extensionList } from "../../utils/constants";
import {
  insertDocs,
  getChatsAndResponse,
  setActiveInteractionsModal
} from "../create-loan.action";
import { getDashboardViewMore } from "../../stores/dashboardSection/dashboard.action";
import { updateExceptionStatus } from "../../network/apiService";
import { ObjectType } from "../../masterView/common";
import { clearLoader } from "../../stores/loaderAndException/loaderAndException.reducer";
import {
  cancelActionByActionId,
  deleteFromBulkSatisfyPayload,
  resetExceptionStore,
  updateDocumentInException,
  updateException
} from "../../stores/ExceptionsResults/ExceptionsReducer";

export interface SatisfyExceptionProps {
  actionId?: any;
  actionType?: any;
  filesList?: any;
  setFilesList?: any;
  setOpenDialog?: any;
  setDialogdetails?: any;
  startUpload?: any;
  isSubmitDisabled?: boolean;
  valid?: any;
  handleSubmit?: any;
  handleEnquirySubmit?: any;
  type?: any;
  submitDisable?: any;
  loanDetail?: any;
  passedFile?: any;
  conditionType?: any;
  getDetails?: any;
  showViewDetails?: any;
  waiverId?: any;
  clearUploadedFile?: any;
  fileInfoChangedFlag?: any;
  setFileInfoChangedFlag?: any;
  isDashboard?: boolean;
  exceptionData?: ObjectType;
}

const LATActionTypes = [
  "Waive Condition",
  "Close Condition",
  "Rescind Condition"
];

export const SubmitException = (props: SatisfyExceptionProps) => {
  const classes = waiverFormStyles();
  const {
    actionId,
    actionType,
    filesList,
    setFilesList,
    valid,
    setDialogdetails,
    setOpenDialog,
    handleEnquirySubmit,
    type,
    loanDetail,
    passedFile,
    conditionType,
    getDetails,
    waiverId,
    clearUploadedFile,
    fileInfoChangedFlag,
    setFileInfoChangedFlag,
    isDashboard,
    exceptionData
  } = props;
  const [submitDisable, setSubmitDisable] = React.useState<string[]>([]);
  const { activeInteractionsModal } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );
  const dispatch = useDispatch();
  let chatSequenceId = "";
  let chatLineSequenceId = "";
  const actionClicks: any = useSelector<any>(
    (state) => state.exceptionsResultsStore?.actionClicks
  );
  const isConditionsAndInquiriesTab =
    window.location.href.indexOf("createLoan") !== -1;
  let { loanId, exceptionId, loanStage, visibility }: any = useParams<{
    loanId: string;
      exceptionId: string;
      loanStage: LoanStage;
      visibility: string;
  }>();
  if (loanDetail) {
    ({ loanId, exceptionId, loanStage, visibility } = loanDetail);
  }

  const userPartyId: string | any = getCookie("person_id");
  const { lineText } = (actionClicks instanceof Array &&
    actionClicks.find((item) => item.actionId === actionId)) || {
    lineText: "",
    personPartyId: ""
  };
  const actionFiles =
    actionClicks instanceof Array &&
    actionClicks.find((item) => item.actionId === actionId);
  const disableDocUpload = LATActionTypes.includes(actionType);

  const doesBelongToCondition = (): boolean => {
    return (
      conditionType.toLowerCase() === "condition" &&
      (window.location.pathname.includes("/Condition/") ||
        activeInteractionsModal ||
        exceptionData?.visibility === "external" ||
        window.location.href.indexOf("tab=0") > -1 ||
        window.location.href.indexOf("createLoan") > -1)
    );
  };

  const handleCancel = async () => {
    dispatch(cancelActionByActionId({ exceptionId, actionId, actionType }));
    dispatch(resetExceptionStore());
    if (isConditionsAndInquiriesTab) {
      if (clearUploadedFile) {
        clearUploadedFile();
      }
    } else {
      if (clearUploadedFile) {
        clearUploadedFile(null);
      }
      if (isDashboard && actionType === "Satisfy Condition") {
        dispatch(deleteFromBulkSatisfyPayload(exceptionId));
      }
    }
  };

  const refetchData = () => {
    if (activeInteractionsModal)
      dispatch(
        getChatsAndResponse(
          loanId,
          loanStage,
          visibility,
          exceptionId,
          "Condition",
          conditionType
        )
      );
    if (window.location.href.indexOf("tab=0") > -1) {
      dispatch(
        getDashboardViewMore(
          loanId,
          loanStage,
          visibility,
          exceptionId,
          "Condition"
        )
      );
    }
    if (window.location.href.indexOf("createLoan") > -1) {
      dispatch(
        getViewMoreDetails(
          loanId,
          loanStage,
          visibility,
          exceptionId,
          "Condition"
        )
      );
    }
    setTimeout(() => {
      dispatch(setActiveInteractionsModal(false, loanId, loanStage));
      /*@ts-ignore*/
    }, [1000]);
  };

  const fileUploadCallback = async (
    files: any,
    bodyFiles: any,
    error?: any
  ) => {
    const secondaryIden = `${loanStage}-postClose`;
    const body: any[] = [];
    const tagExist =
      files &&
      files[0]?.data?.filter(
        (item: { tags: string | any[] }) => item?.tags?.length > 0
      );
    handleCancel();
    dispatch(setSatisfyExceptionsClicked(false, exceptionId));
    dispatch(setAskAQuestionClicked(false, exceptionId));
    if (error) {
      dispatch(hideLoader(true));
      // eslint-disable-next-line no-alert
      alert("Sorry, Failed to upload documents. Please try again.");
      return;
    }

    if (actionType.toLowerCase() === "ask a question") {
      dispatch(
        getResponsesByExceptionId(
          loanId,
          loanStage,
          exceptionId,
          userPartyId,
          true
        )
      );
      //dispatch(uploadDocToBoxCom(loanId, exceptionId, "ASK_A_QUESTION"));
    }
    if (
      actionType.toLowerCase() === "satisfy condition" ||
      actionType.toLowerCase() === "satisfy inquiry"
    ) {
      dispatch(
        getResponsesByExceptionId(
          loanId,
          loanStage,
          exceptionId,
          userPartyId,
          true
        )
      );

      for (let i = 0; i < tagExist?.length; i++) {
        if (
          tagExist[i] !== null &&
          !Array.isArray(tagExist[i]) &&
          tagExist[i] !== undefined
        ) {
          body.push(tagExist[i]);
        }
      }
      //AM-261; inqiry files shoudl nt be uploaded to docs tab/box
      if (body.length && conditionType.toLowerCase() === "condition") {
        uploadFileToDocuments(
          body,
          "LOAN",
          loanId,
          secondaryIden,
          secondaryIden
        );
      }
      dispatch(hideLoader(true));
    }

    if (
      // actionType.toLowerCase() === "satisfy condition" ||
      actionType.toLowerCase() === "satisfy exception" &&
      tagExist?.length
    ) {
      dispatch(uploadDocToBoxCom(loanId, exceptionId, "SATISFY_EXCEPTION"));
    }
    // AM-261; inqiry files shoudl nt be uploaded to docs tab/box

    const hasCondition: boolean = doesBelongToCondition();

    if (hasCondition && bodyFiles.length) {
      const condition = true;
      await insertDocs(
        bodyFiles?.map((item: any) => ({...item, shouldUploadToBox:false})),
        loanId,
        `${loanStage}-postClose`,
        `${loanStage}-postClose`,
        "LOAN",
        condition
      );
    }
    refetchData();
  };

  const chatUploadCallBack = async (
    chatId: any,
    lineSequenceId: string,
    chatIdObject: any
  ) => {
    chatSequenceId = chatId;
    chatLineSequenceId = lineSequenceId;
    startUpload(chatSequenceId, chatLineSequenceId, chatIdObject);
  };

  const startUpload = async (
    chatLineId: string,
    chatId: string,
    responseType: string
  ) => {
    const primaryIdentifier = "CHAT";
    const secondaryIdentifier = "CHAT_SEQUENCE";
    const body: any[] = [];
    for (
      let i = 0;
      i < filesList.length || i < Object.keys(filesList).length;
      i++
    ) {
      if (filesList[i]?.body !== null) {
        body.push({...filesList[i]?.body[0], shouldUploadToBox: true});
      }
    }

    const cb = (files: any, error?: any) => {
      fileUploadCallback(files, body, error);
    };
    uploadFileToDocuments(
      body,
      primaryIdentifier,
      chatSequenceId,
      secondaryIdentifier,
      chatLineSequenceId,
      cb
    );
  };

  const handleSubmit = () => {
    // Update exception status of LAT condition starts
    if (disableDocUpload) {
      const actionToStatusMap: ObjectType = {
        "Waive Condition": "WAIVED",
        "Close Condition": "SATISFIED",
        "Rescind Condition": "RESCIND"
      };
      dispatch(showLoader(true));
      const exceptionDataClone: ObjectType = { ...exceptionData };
      delete exceptionDataClone.exceptionId;
      delete exceptionDataClone.responses;
      updateExceptionStatus(loanId, loanStage, {
        exceptions: [
          {
            ...exceptionDataClone,
            ExceptionId: exceptionId,
            conclusionComment: lineText,
            response: [],
            status: actionToStatusMap[actionType]
          }
        ]
      })
        .then(async (resp: any) => {
          dispatch(hideLoader(true));
          if (resp.status === 200) {
            // dispatch({
            //   type: UPDATE_EXCEPTION,
            //   payload: {
            //     exceptionId,
            //     updateObj: {
            //       status: actionToStatusMap[actionType],
            //       conclusionComment: lineText
            //     }
            //   }
            // });
            dispatch(
              updateException({
                exceptionId,
                updateObj: {
                  status: actionToStatusMap[actionType],
                  conclusionComment: lineText
                }
              })
            );
            dispatch(resetExceptionStore());
            refetchData();
          }
        })
        .catch((e: any) => {
          dispatch(hideLoader(true));
          console.log(e);
        });
      return;
    }
    // take actions on non lat condition related actions
    const type = "LOAN";
    const hasDocuments = !!actionFiles.files;
    const actionTypeTosubmit =
      actionType && actionType.toLowerCase() === "satisfy condition"
        ? "Satisfy Exception"
        : actionType;
    dispatch(showLoader(true));
    dispatch(
      postChatByIdAndUpdateResponseType(
        loanStage,
        exceptionId,
        type,
        loanId,
        visibility,
        actionId,
        actionTypeTosubmit,
        hasDocuments,
        lineText,
        chatUploadCallBack,
        userPartyId,
        activeInteractionsModal,
        waiverId,
        conditionType
      )
    );
    if (!hasDocuments) {
      handleCancel();
      dispatch(setSatisfyExceptionsClicked(false, exceptionId));
      dispatch(setAskAQuestionClicked(false, exceptionId));
    }
    if (getDetails) {
      getDetails();
    }
  };
  const setSubmitDisableWrapper = (
    id: string,
    addNewToArray: boolean,
    error?: any
  ) => {
    if (addNewToArray) {
      setSubmitDisable((state) => [...state, id]);
    } else {
      setSubmitDisable((state) => state.filter((ele) => ele !== id));
    }
    if (error) {
      alert("Network Error, Please upload documents again");
      dispatch(clearLoader());
    }
  };
  const handleFileUploadWrapper = (
    e: any,
    id: any,
    dragFiles: boolean,
    filesList?: any
  ) => {
    const callBack = (length: number, updatedFileList: any) => {
      if (type !== "inquiry") {
        dispatch(
          updateDocumentInException({
            filesList: updatedFileList,
            actionId: id
          })
        );
      }
    };
    handleFileUpload(e, {
      setOpenDialog,
      filesList,
      setDialogdetails,
      setFilesList,
      startUpload,
      callBack,
      id,
      loanId,
      setSubmitDisable: setSubmitDisableWrapper,
      dragFiles
    });
  };
  let sendBtnText = "Submit";
  const isFileUploadInProgress = submitDisable.length > 0;
  if (isFileUploadInProgress) {
    sendBtnText = "Files upload in progress";
  }

  useEffect(() => {
    if (isFileUploadInProgress) {
      dispatch(showLoader());
    } else {
      if (isDashboard && setFileInfoChangedFlag) {
        setFileInfoChangedFlag(!fileInfoChangedFlag);
      }
      dispatch(hideLoader());
    }
    // eslint-disable-next-line
  }, [dispatch, isFileUploadInProgress]);

  useEffect(() => {
    if (passedFile) {
      handleFileUploadWrapper([passedFile], actionId, true, filesList);
    }
    // eslint-disable-next-line
  }, [passedFile]);

  return (
    <>
      <Grid item xs={12}>
        <Grid container direction="row" alignItems="stretch" spacing={1}>
          <Grid item>
            <Button
              variant="contained"
              startIcon={
                isFileUploadInProgress ? (
                  <CircularProgress size={14} style={{ color: "white" }} />
                ) : (
                  <img alt="submit" src={SubmitIcon} />
                )
              }
              className={classes.submitButton}
              onClick={type !== "inquiry" ? handleSubmit : handleEnquirySubmit}
              type="submit"
              disabled={
                isFileUploadInProgress || (!valid && type !== "inquiry")
              }
              color="info"
            >
              {sendBtnText}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              startIcon={<CloseIcon color="#ffffff" />}
              className={classes.submitButton}
              onClick={handleCancel}
              type="submit"
              color="info"
            >
              Cancel
            </Button>
          </Grid>
          {!disableDocUpload && (
            <>
              <Grid item>
                <div>
                  <input
                    style={{
                      display: "none"
                    }}
                    id={`raised-button-file-${actionId}`}
                    multiple
                    type="file"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleFileUploadWrapper(event, actionId, false, filesList)
                    }
                    onClick={(
                      event: React.MouseEvent<HTMLInputElement, MouseEvent>
                    ) => {
                      const element = event.target as HTMLInputElement;
                      element.value = "";
                    }}
                  />
                  <label htmlFor={`raised-button-file-${actionId}`}>
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      className={classes.uploadButton}
                      disabled={filesList?.length && filesList.length === 5}
                    >
                      <AttachFileIcon style={{ transform: "rotate(30deg)" }} />
                    </Button>
                  </label>
                </div>
              </Grid>
              <Grid item alignItems="center" className={classes.contentDisplay}>
                <HtmlTooltip
                  placement="right-end"
                  title={
                    <>
                      <Typography>Upload Restrictions</Typography>
                      <ul>
                        <li>Max size: 20MB</li>
                        <li>Max number of docs: 50</li>
                        <li>
                          File type:
                          <ul>
                            {extensionList.map((item) => {
                              return <li>{item}</li>;
                            })}
                          </ul>
                        </li>
                      </ul>
                    </>
                  }
                >
                  <InfoOutlinedIcon />
                </HtmlTooltip>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};
