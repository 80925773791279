/* eslint-disable no-shadow */
import { useMemo } from "react";
import { ObjectType } from "../masterView/common";

const alwayTrue = () => true;

export const useFormFields = (
  fields: ObjectType[],
  loanStage: string,
  additionalParams?: ObjectType
) => {
  const { filteredFields, defaultFieldErrorStatus, defaultFieldsEditStatus } =
    useMemo(() => {
      const filteredFields: ObjectType[] = [];
      const defaultFieldErrorStatus = new Map();
      const defaultFieldsEditStatus = new Map();
      fields.forEach((fieldData: ObjectType) => {
        const showOnly = fieldData.showOnly || alwayTrue;
        if (
          (fieldData?.showIn?.includes(loanStage) || !fieldData?.showIn) &&
          showOnly(additionalParams)
        ) {
          filteredFields.push(fieldData);
        }
        defaultFieldErrorStatus.set(fieldData.field, "");
        defaultFieldsEditStatus.set(fieldData.field, false);
      });
      return {
        filteredFields,
        defaultFieldErrorStatus,
        defaultFieldsEditStatus
      };
    }, [fields, loanStage, additionalParams]);
  return {
    formFields: filteredFields,
    defaultFieldErrorStatus,
    defaultFieldsEditStatus
  };
};
