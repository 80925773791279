/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable no-var */
/* eslint-disable prefer-const */
/* eslint-disable one-var */
/* eslint-disable vars-on-top */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { waiverStatusLowerCase, WaiverType } from "@toorak/tc-common-fe-sdk";
import {
  getLoanTypeFromLoanId,
  LoanStatusEnum,
  getUserTags
} from "@toorak/tc-common-util-sdk";
import { RootState } from "../../stores/rootReducer";
import {
  checkCarryForwardWaiver
  // GET_WAIVER_DETAILS,
  // SET_ERROR_ADDRESSLIST
} from "../../stores/WaiverRequest/WaiverRequest.action";
import { formStyles } from "./Common.style";
import {
  getPropertiesWithFailedTests,
  genFormattedUserDetails,
  getWaiverList,
  markNotificationAsRead
} from "../../stores/EvaluationResults/EvaluationResults.action";
import { getLoanType } from "../../config/config";
import {
  getLoanById,
  getLoanDetails,
  updatePreviousStage
  // UPDATE_IS_WITHDRAWN_STATE
} from "../create-loan.action";
import { isEmptyObject } from "../../utils/utils";
import {
  showLoader,
  hideLoader
  // clearLoader
} from "../../stores/loaderAndException/loaderAndException.action";
import {
  getUserLists
  // updateUserListInRedux
} from "../../stores/Scenario/ScenarioActions";
import { getCookie } from "../../utils/cookies";
import {
  getWaiverDetails,
  setErrorAddressList
} from "../../stores/WaiverRequest/WaiverRequest.reducer";
import { updateUserList } from "../../stores/Scenario/ScenarioReducer";
import { clearLoader } from "../../stores/loaderAndException/loaderAndException.reducer";
import { updateIsWithdrawnState } from "../create-loan.reducer";

const PageNotFound = React.lazy(() =>
  import("../../ui-components/pageNotFound").then((module) => ({
    default: module.PageNotFound
  }))
);

const WaiverHeader = React.lazy(() =>
  import("./WaiverHeader").then((module) => ({
    default: module.WaiverHeader
  }))
);

const WaiverSubheader = React.lazy(() =>
  import("./WaiverSubheader").then((module) => ({
    default: module.WaiverSubheader
  }))
);

const WaiverViewForm = React.lazy(() =>
  import("./WaiverViewForm").then((module) => ({
    default: module.WaiverViewForm
  }))
);

const WaiverRescindHistoryExceptRequest = React.lazy(() =>
  import("./WaiverRescindHistoryExceptRequest").then((module) => ({
    default: module.WaiverRescindHistoryExceptRequest
  }))
);

export interface WaiverViewProps {
  exceptionWaiverId?: string;
  exceptionId?: string;
  documents?: any;
  status?: any;
  visibility?: any;
  loanDetails?: any;
  waivers?: any;
  waiversCreatedProp?: any;
  waiverReqInfoProp?: any;
  waiverChatProp?: any;
  viewModeProp?: any;
  customerDetailsProp?: any;
  waiverTypeProp?: any;
  exceptionIdProp?: any;
  waiverMitigatesProp?: any;
  viewMore?: boolean;
  waiverHistory?: any;
  getDetails?: any;
  chatDetails?: any;
  currentLoanStage?: any;
  type?: any;
  isWithdrawnLoan?: any;
  exceptionType?: any;
  from?: string;
  addCommentCB?: any;
}

export interface waiverPayload {
  subject: string;
  reason: string;
  details: any;
}

export const WaiverView = (props: WaiverViewProps) => {
  const classes = formStyles();
  const {
    exceptionWaiverId,
    exceptionId,
    exceptionIdProp,
    documents,
    status,
    visibility,
    // waivers,
    waiversCreatedProp,
    waiverChatProp,
    waiverReqInfoProp,
    waiverTypeProp,
    viewModeProp,
    customerDetailsProp,
    chatDetails,
    // exceptionIdProp,
    loanDetails,
    waiverMitigatesProp,
    viewMore,
    waiverHistory,
    getDetails,
    currentLoanStage,
    type,
    isWithdrawnLoan,
    exceptionType,
    from,
    addCommentCB
  } = props;
  // let { loanId, waiverId, loanStage }: any = "";
  // if (activeInteractionsModal) {
  //   ({ loanId, loanStage } = loanDetails);
  //   waiverId = exceptionWaiverId;
  // } else {
  const params = useParams<any>() as any;

  let loanId: any = params.loanId;
  let waiverId: any = params.waiverId || exceptionWaiverId;
  let loanStage: any = params.loanStage;
  
  if (!Object.keys(params)?.length) {
    ({ loanId, loanStage } = loanDetails);
    waiverId = exceptionWaiverId;
  }    
  // }

  const waiverIdForSinglewaiver = waiverId || exceptionWaiverId;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [waiverRequestedInfo, setWaiverRequestedInfo] = useState<any>({});
  const [isViewMode, setIsViewMode] = useState(false);
  const [loanState, setLoanState] = useState<any>("");
  const [waiverChat, setWaiverChat] = useState<any>({});
  const [isCarryForwardedWaiver, setIsCarryForwardedWaiver] =
    useState<boolean>(false);
  const waiverDetails = useSelector<
    RootState,
    {
      subject: string;
      reason: string;
      details: any;
      errorAddressList?: any;
    }
  >((state) => state.wrStore.waiverDetails);
  const { waiversCreated, documentsUploaded } = useSelector<RootState, any>(
    (state) => state.evaluationResultsStore
  );
  const customerDetails = useSelector<RootState, any>(
    (state) => state.evaluationResultsStore.customerInfo
  );
  const { unreadWaivers } = useSelector<RootState, any>(
    (state) => state.evaluationResultsStore
  );
  // Pass waiverLists and return the matched single waiver by waiver id.
  const getMatchedSingleWaiver = (waiverLists: any) => {
    let waiverObj = {};
    if (waiverLists && !isEmptyObject(waiverLists)) {
      waiverLists.forEach((waiver: { waiverId?: any }) => {
        if (waiver.waiverId === waiverIdForSinglewaiver) {
          waiverObj = waiver;
        }
      });
    }
    return waiverObj;
  };
  const currentWaiver: any = getMatchedSingleWaiver(
    waiversCreated || waiversCreatedProp
  );
  const { waiverType } = currentWaiver;
  useEffect(() => {
    if (unreadWaivers && unreadWaivers[waiverId]) {
      dispatch(markNotificationAsRead(unreadWaivers[waiverId], waiverId));
    }
  }, [unreadWaivers, waiverId]);
  const { waiverResponses } = waiverDetails?.details?.waiverData || "";
  const waiverResponseType =
    waiverResponses?.length > 0
      ? waiverResponses[waiverResponses?.length - 1].responseType
      : "REQUESTED";
  const { rescind } = waiverStatusLowerCase;
  const isRescind: boolean = waiverResponseType?.toLowerCase() === rescind;

  const TagComments: any[] = [];
  let bar: any[] = [];
  const isGeneralWaiver =
    waiverDetails?.subject === "GeneralWaiver" ||
    waiverDetails?.subject === "General Waiver Request";
  if (isRescind) {
    const { chatSequence, internalChatlines } = waiverChat || undefined;
    waiverChat.chatLines?.map((item: any) => {
      if (
        !item?.tags?.length ||
        item?.tags[0] === "false" ||
        item?.tags[0] === "true"
      ) {
        bar.push(item);
      } else {
        TagComments.push({ chatLines: bar, chatSequence, internalChatlines });
        bar = [item];
      }
    });
    TagComments.push({ chatLines: bar, chatSequence, internalChatlines });
  }

  useEffect(() => {
    if (exceptionWaiverId && window.location.href.indexOf("view") > -1) {
      dispatch(getWaiverList(loanId, loanStage, false, true));
    } else if (
      waiverIdForSinglewaiver &&
      window.location.href.indexOf("view") > -1
    ) {
      getLoanDetails(loanId).then((loanDetails: any) => {
        setLoanState(loanDetails.state);
        if (loanDetails.stage !== loanStage) {
          setIsViewMode(true);
        }
      });
      dispatch(
        getWaiverList(loanId, loanStage, true, true, waiverIdForSinglewaiver)
      );
    }
  }, [waiverIdForSinglewaiver]);

  useEffect(() => {
    if (from === "approver") return;
    const loanInfo = getLoanById(loanId, loanStage);
    loanInfo.then((resp: any) => {
      dispatch(updatePreviousStage(resp.data, loanStage, loanId));
      // dispatch({
      //   type: UPDATE_IS_WITHDRAWN_STATE,
      //   payload: resp.data.loanState === LoanStatusEnum.Withdrawn
      // });
      dispatch(
        updateIsWithdrawnState(resp.data.loanState === LoanStatusEnum.Withdrawn)
      );
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(showLoader());
    const userList = getUserLists();
    userList
      .then((resp: any) => {
        const hits: any[] = resp.data?.response?.hits?.hits
          ? resp.data.response.hits.hits
          : [];
        const currentPersonId = getCookie("person_id");
        const userSortListArray = getUserTags(hits, currentPersonId);
        dispatch(updateUserList(userSortListArray));
        dispatch(hideLoader());
      })
      .catch((err: any) => {
        dispatch(clearLoader());
        console.log("err", err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      (waiversCreated?.length || waiversCreatedProp?.length) &&
      (documentsUploaded?.length || documents?.length) &&
      (customerDetails?.properties?.length ||
        customerDetailsProp?.properties?.length)
    ) {
      let payload: waiverPayload = { subject: "", reason: "", details: null };
      const currentWaiver: any =
        window.location.href.indexOf("view") > -1
          ? getMatchedSingleWaiver(waiversCreated)
          : getMatchedSingleWaiver(waiversCreatedProp);
      if (currentWaiver && !isEmptyObject(currentWaiver)) {
        setIsCarryForwardedWaiver(checkCarryForwardWaiver(currentWaiver));
      }
      const crrentwaiverDocs: any = getDocumentForCurrentWaiver(
        documentsUploaded || documents,
        currentWaiver?.refWaiverId,
        isCarryForwardedWaiver
      );
      if (currentWaiver && !isEmptyObject(currentWaiver)) {
        payload.subject = currentWaiver?.title;
        payload.reason = currentWaiver?.description;
        payload.details = {
          backLocation: generateBackLocationPath(),
          representableTestName: currentWaiver?.title
        };
        updateWaiverChat(
          customerDetails,
          currentWaiver.chat,
          currentWaiver.refWaiverId,
          isCarryForwardedWaiver,
          waiverHistory
        );
      }
      if (!isEmptyObject(payload)) {
        payload.details = !exceptionWaiverId
          ? {
              waiverData: {
                waiverId,
                waiverResponses: currentWaiver.waiverResponses,
                waiverMitigates: currentWaiver.waiverMitigates,
                waiverDocs: crrentwaiverDocs
              },
              backLocation: payload.details?.backLocation,
              representableTestName: payload.details?.representableTestName
            }
          : {
              waiverData: {
                exceptionWaiverId,
                waiverResponses: currentWaiver.waiverResponses,
                waiverMitigates: currentWaiver.waiverMitigates,
                waiverDocs: crrentwaiverDocs
              },
              backLocation: null,
              representableTestName: null
            };

        dispatch(getWaiverDetails(payload));

        // dispatch({
        //   type: GET_WAIVER_DETAILS,
        //   payload
        // });
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else if (
      !exceptionWaiverId &&
      customerDetails &&
      customerDetails.properties &&
      !customerDetails?.properties?.length
    ) {
      setLoading(false);
    }
  }, [
    waiversCreated,
    documentsUploaded,
    customerDetails,
    waiverHistory,
    waiversCreatedProp
  ]);
  useEffect(() => {
    if (!exceptionWaiverId) {
      const currentWaiver: any = getMatchedSingleWaiver(waiversCreated);
      updateUserInformation(currentWaiver, customerDetails);
    }
  }, [customerDetails, waiverDetails]);

  const updateUserInformation = (currentWaiver: any, customerDetails: any) => {
    const partyId = currentWaiver?.createdBy;
    const { createdOn } = currentWaiver;
    const waiverRequestedInformation = genFormattedUserDetails(
      customerDetails,
      partyId,
      createdOn
    );
    if (waiverRequestedInformation) {
      setWaiverRequestedInfo(waiverRequestedInformation);
    }
    if (
      !isEmptyObject(currentWaiver) &&
      !waiverDetails?.errorAddressList &&
      currentWaiver.waiverType !== WaiverType.generalWaiver &&
      customerDetails.properties
    ) {
      const errorAddress = getPropertiesWithFailedTests(
        currentWaiver?.waiverKeys,
        customerDetails.properties,
        currentWaiver?.title
      );

      dispatch(setErrorAddressList(errorAddress));
      // dispatch({
      //   type: SET_ERROR_ADDRESSLIST,
      //   payload: errorAddress
      // });
    }
  };

  interface WaiverChat {
    chatLines: any[];
    chatSequence: any[];
    internalChatlines: any[];
  }

  /**
   * * Show all chats in current waiver and linked waiver(chats linked to waiver in previous stage).
   * @param customerDetails
   * @param waiverChat
   * @param refWaiverId : Reference waiver Id. Waiver Id to which current Waiver linked in previous stage.
   * @param isCarryForwardedWaiver
   */
  const updateWaiverChat = (
    customerDetails: any,
    waiverChat: any,
    refWaiverId: any[],
    isCarryForwardedWaiver: boolean = false,
    waiverHistory: any
  ) => {
    let chatData: WaiverChat = {
      chatLines: [],
      chatSequence: [],
      internalChatlines: []
    };
    if (customerDetails?.waiverHistory || waiverHistory) {
      let waiverHist = customerDetails?.waiverHistory || waiverHistory;
      waiverHist.forEach((history: any) => {
        refWaiverId.forEach((refId: string) => {
          if (history.waiverId === refId) {
            chatData.chatLines = chatData.chatLines.concat(
              history.chat.chatLines
            );
            // chatData.chatSequence = chatData.chatSequence.concat(history.chat.chatSequence);
            chatData.internalChatlines = chatData.internalChatlines.concat(
              history.chat.internalChatlines
            );
          }
        });
      });
    }
    chatData.chatLines = chatData.chatLines.concat(waiverChat.chatLines);
    chatData.chatSequence = chatData.chatSequence.concat(
      waiverChat.chatSequence
    );
    chatData.internalChatlines = chatData.internalChatlines.concat(
      waiverChat.internalChatlines
    );
    setWaiverChat(chatData);
  };

  /**
   * * Show all documents attached to current waiver and linked waiver(documents attached to waiver in previous stage).
   * @param documentList
   * @param refWaiverId : Reference waiver Id. Waiver Id to which current Waiver linked in previous stage.
   * @param isCarryForwardedWaiver
   * @returns
   */
  const getDocumentForCurrentWaiver = (
    documentList: any,
    refWaiverId: any,
    isCarryForwardedWaiver: boolean
  ) => {
    let documents: any[] = [];
    if (documentList?.length) {
      if (isCarryForwardedWaiver) {
        documentList.forEach((docs: any) => {
          refWaiverId.forEach((refId: any) => {
            if (
              docs.primaryIdentifierName === "WAIVER" &&
              docs.primaryIdentifierValue === refId
            ) {
              documents = documents.concat(docs.docs);
            }
          });
        });
      } else {
        documentList.forEach((docs: any) => {
          if (
            docs.primaryIdentifierName === "WAIVER" &&
            docs.primaryIdentifierValue === waiverIdForSinglewaiver
          ) {
            documents = docs.docs;
          }
        });
      }
    }
    return documents;
  };
  // since from email link we did not get the loanType. so we generating the back location path using the first two char of loanID
  const generateBackLocationPath = () => {
    const loanStageChar = getLoanTypeFromLoanId(loanId);
    const loanType =
      loanStageChar === getLoanType[0].loanCreationName
        ? getLoanType[0].displayValue
        : getLoanType[1].displayValue;
    return `/loan/createLoan/${loanType}/${loanStage}/${loanId}`;
  };
  let filteredDoc = documents?.filter((item: any) => {
    return item.primaryIdentifierValue === waiverId;
  });
  // const isGeneralWaiver =
  //   waiverDetails?.subject === "GeneralWaiver" ||
  //   waiverDetails?.subject === "General Waiver Request";
  // console.log(exceptionWaiverId,"exceptionWaiverIdexceptionWaiverId");

  return (
    <>
      {/* Header section */}
      {!exceptionWaiverId && (
        <section className={classes.generalWaiverContainer}>
          <React.Suspense fallback={null}>
            <WaiverHeader
              loanId={loanId}
              loading={loading}
              waiverDetails={waiverDetails}
              loanStage={loanStage}
              takeoutPartnerId={
                waiversCreated.length
                  ? waiversCreated[0].takeoutPartnerId
                  : null
              }
            />
          </React.Suspense>
        </section>
      )}
      {!loading ? (
        <>
          {(waiverDetails?.details?.waiverData !== undefined &&
            waiverDetails?.details?.waiverData?.waiverMitigates?.length > 0) ||
          waiverMitigatesProp?.length ? (
            <>
              {/* Subheader Section */}
              {!exceptionWaiverId && !isGeneralWaiver && (
                <React.Suspense fallback={null}>
                  <section className={classes.sectionContainer}>
                    <WaiverSubheader
                      waiverDetails={waiverDetails}
                      exceptionWaiverId={exceptionWaiverId}
                      showLeftHeader={true}
                    />
                  </section>
                </React.Suspense>
              )}
              {/* Form Container */}
              <React.Suspense fallback={null}>
                <section
                  key="waiver-key"
                  className={isGeneralWaiver ? classes.generalContainer : ""}
                >
                  <WaiverViewForm
                    loanId={loanId}
                    chatDetails={chatDetails}
                    loanStage={loanStage}
                    loanState={loanState}
                    waiverDetails={waiverDetails}
                    waiversCreated={
                      window.location.href.indexOf("view") > -1
                        ? waiversCreated
                        : waiversCreatedProp
                    }
                    type={type}
                    isWithdrawnLoanProp={isWithdrawnLoan}
                    // reloadWaiver={setUpdateWaiver}
                    filteredDoc={filteredDoc}
                    exceptionWaiverId={exceptionWaiverId}
                    displayStyle={"flow"}
                    exceptionType={exceptionType}
                    exceptionId={exceptionId}
                    exceptionIdProp={exceptionIdProp}
                    waiverRequestedInfo={
                      waiverRequestedInfo || waiverReqInfoProp
                    }
                    TagComments={
                      isRescind ? TagComments[0] : waiverChat || waiverChatProp
                    }
                    isViewMode={isViewMode || viewModeProp || false}
                    status={status}
                    visibility={visibility}
                    waiverChat={waiverChat || waiverChatProp}
                    customerDetails={customerDetails || customerDetailsProp}
                    isCarryForwardedWaiver={isCarryForwardedWaiver}
                    waiverType={waiverType || waiverTypeProp}
                    documents={documents}
                    waivers={customerDetailsProp || {}}
                    viewMore={viewMore}
                    getDetails={getDetails}
                    currentLoanStage={currentLoanStage}
                    from={from}
                    addCommentCB={addCommentCB}
                  />
                </section>
              </React.Suspense>
              {TagComments?.length > 1 && isRescind && isGeneralWaiver
                ? TagComments.map(
                    (sectionObj: any, index: number) =>
                      index !== 0 && (
                        <React.Suspense fallback={null}>
                          <section
                            key={`rescind-key-${index}`}
                            className={
                              isGeneralWaiver ? classes.generalContainer : ""
                            }
                          >
                            <WaiverRescindHistoryExceptRequest
                              loanId={loanId}
                              loanStage={loanStage}
                              loanState={loanState}
                              waiverDetails={waiverDetails}
                              waiversCreated={waiversCreated}
                              // reloadWaiver={setUpdateWaiver}
                              exceptionWaiverId={exceptionWaiverId}
                              exceptionId={exceptionId}
                              waiverRequestedInfo={waiverRequestedInfo}
                              // documents={documents}
                              isViewMode={isViewMode || false}
                              status={status}
                              visibility={visibility}
                              waiverChat={sectionObj}
                              TagComments={
                                sectionObj ? sectionObj.chatLines : ""
                              }
                              customerDetails={customerDetails}
                              isCarryForwardedWaiver={isCarryForwardedWaiver}
                              waiverType={waiverType}
                            />
                          </section>
                        </React.Suspense>
                      )
                  )
                : null}
            </>
          ) : (
            <>
              {!exceptionWaiverId && (
                <React.Suspense fallback={null}>
                  <PageNotFound
                    header="Invalid Waiver"
                    errorMsg="This Waiver is no longer valid. Please click on following button to see the current status of the loan."
                    generateBackLocationPath={generateBackLocationPath}
                  />
                </React.Suspense>
              )}{" "}
            </>
          )}
        </>
      ) : null}
    </>
  );
};
