import React from "react";
import { useDispatch } from "react-redux";
import { useStyles } from "./DocumentsForm.style";
import editIcon from "../../images/Edit.svg";
import { DocsTrackingIdModal } from "./DocsTrackingIdModal";
import {
  updateDocsTrackingbyDocsId,
  updateFilesInDocs
} from "../create-loan.action";
import {
  hideLoader,
  showLoader
} from "../../stores/loaderAndException/loaderAndException.action";

export const DocumentsTrackingId = ({
  isTrackingIdAllowed,
  value,
  filesList,
  currentTab,
  isAllowedToEdit
}: {
  value: any;
  isTrackingIdAllowed: boolean;
  filesList: any[];
  currentTab: any;
  isAllowedToEdit: boolean;
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showAddTracking, setShowAddTracking] = React.useState<boolean>(false);
  if (!isTrackingIdAllowed || value.percentCompleted !== 100) return null;
  const { fileName, editImageIcon } = classes;

  const updateDocsTracking = async (_trackingId: string) => {
    const trackingIdObj = {
      trackingId: _trackingId
    };
    const filesListCopy = JSON.parse(JSON.stringify(filesList));
    const index = filesListCopy.findIndex(
      (x: any) => x.docsId === value.docsId
    );
    const reqBody = [
      {
        documentId: value.docsId,
        trackingId: _trackingId
      }
    ];
    // console.log(reqBody);
    try {
      dispatch(showLoader());
      await updateDocsTrackingbyDocsId(reqBody);
      dispatch(
        updateFilesInDocs(
          filesListCopy.map((item: any, i: number) => {
            return i !== index ? item : Object.assign(item, trackingIdObj);
          }),
          // "prePurchase"
          currentTab // currentTab
        )
      );
      dispatch(hideLoader());
    } catch (e) {
      console.log(e);
    }

    setShowAddTracking(false);
  };

  return (
    <span>
      <DocsTrackingIdModal
        handleSave={updateDocsTracking}
        showAddTracking={showAddTracking}
        setShowAddTracking={setShowAddTracking}
        oldTrackingId={value.trackingId || ""}
      />
      {!value.trackingId ? (
        <span
          className={fileName}
          data-testid="add-trackingId"
          onClick={(e) => setShowAddTracking(true)}
          style={{ cursor: "pointer", width: "100%", fontSize: 11 }}
        >
          {isAllowedToEdit && "+ Add Tracking Id"}
        </span>
      ) : (
        <span>
          Tracking ID :{" "}
          <span style={{ fontWeight: 600 }}>{value.trackingId}</span>{" "}
          {isAllowedToEdit && (
            <img
              className={editImageIcon}
              src={editIcon}
              alt="Edit TrackingID"
              onClick={(e) => setShowAddTracking(true)}
              onKeyDown={(e) => setShowAddTracking(true)}
            />
          )}
        </span>
      )}
    </span>
  );
};
