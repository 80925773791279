import { Grid } from "@mui/material";
import React from "react";
import { BudgetMetaData } from "./BudgetMetaData";
import { BudgetFieldsArea } from "./BudgetFieldsArea";

export function FieldsPanelWrapper() {
  return (
    <Grid container direction="column" style={{ height: "100%" }}>
      <Grid item style={{ height: 50, paddingLeft: 15 }}>
        <BudgetMetaData />
      </Grid>
      <Grid item>
        <BudgetFieldsArea />
      </Grid>
    </Grid>
  );
}
