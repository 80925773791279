import React, { useState } from "react";
import {
  ClickAwayListener,
  IconButton,
  Popper,
  Paper,
  Tooltip
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import { CodeBlock, a11yLight } from "react-code-blocks";
import { ObjectType } from "../masterView/common";
import { copyToClipBoard } from "../utils/utils";

const useStyles = makeStyles(() =>
  createStyles({
    menuRoot: {
      width: "auto",
      maxHeight: "200px",
      overflowY: "scroll",
      boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.16)"
    },
    codeBlock: {
      "& code": {
        color: "#32325d"
      }
    },
    tooltip: {
      backgroundColor: "#fff",
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: "0 2px 12px 0 rgb(50 50 93 / 21%)",
      margin: "0px 10px",
      padding: "0.5rem",
      fontSize: "12px"
    },
    arrow: {
      color: "#fff"
    }
  })
);

export const CodeSnippetModal = (props: any) => {
  const { formulaText, formulaVariables, formulaLable, data } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [boundingClientRect, setBoundingClientRect] = useState<any>({});
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const classes = useStyles();
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setIsOpen(false);
  };

  React.useEffect(() => {
    if (isOpen) {
        const boundingRect = anchorRef.current?.getBoundingClientRect();
        setBoundingClientRect(boundingRect);
    }
  }, [isOpen]);

  return (
    <>
      <IconButton onClick={handleOpen} ref={anchorRef} size="large">
        <MoreHorizIcon style={{ color: "#32325d" }} />
      </IconButton>

      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-start"
        modifiers={[
          {
            name: "flip",
            enabled: false
          },
          {
            name: "preventOverflow",
            enabled: true
          }
        ]}
        style={{
          position: "absolute",
          willChange: "transform",
          top: boundingClientRect?.bottom + window.scrollY,
          left: boundingClientRect?.right + window.scrollX
        }}
      >
        <Paper
          classes={{
            root: classes.menuRoot
          }}
        >
          <ClickAwayListener onClickAway={handleClose as any}>
            <div>
              <div className={classes.codeBlock}>
                <Tooltip
                  classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.arrow
                  }}
                  placement="right"
                  arrow
                  title={"Copy"}
                >
                  <IconButton
                    style={{ float: "right" }}
                    onClick={(e) => copyToClipBoard(e, formulaText)}
                    size="large"
                  >
                    <FilterNoneIcon style={{ color: "#32325d" }} />
                  </IconButton>
                </Tooltip>
                <CodeBlock
                  language="text"
                  text={`${formulaLable} ${formulaText}`}
                  codeBlock
                  theme={a11yLight}
                  showLineNumbers={false}
                />
              </div>
              <div
                style={{
                  backgroundColor: "#e9fafd",
                  padding: "1rem",
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                {formulaVariables?.map((variableObj: ObjectType) => {
                  return (
                    <span>{`${variableObj.key} : ${
                      variableObj.formatter?.(
                        data?.[variableObj.fieldPath] ?? ""
                      ) ??
                      data?.[variableObj.fieldPath] ??
                      ""
                    }`}</span>
                  );
                })}
              </div>
            </div>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};
