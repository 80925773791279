import React, { useEffect } from "react";
import useAutocomplete from "@mui/material/useAutocomplete";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Popper from "@mui/material/Popper";
import NoSsr from "@mui/material/NoSsr";
import { Checkbox } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
//import styled from "styled-components";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import { RootState } from "../../stores/rootReducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listWrap: {
      listStyle: "none",
      backgroundColor: "#fff",
      overflow: "auto",
      maxHeight: "250px",
      borderRadius: "4px",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
      width: "100%",
      margin: "6px 0",
      zIndex: 1,
      padding: "10px 20px",
      "& li[aria-selected='true']": {
        fontWeight: "600"
      },
      "& li[data-focus='true']": {
        cursor: "pointer"
      }
    },
    listHeading: {
      fontWeight: 600,
      padding: "10px 0",
      fontSize: "12px"
    },
    innerList: {
      listStyle: "none",
      padding: "0",
      margin: "0",
      "& li": {
        padding: "5px 0",
        fontWeight: "normal",
        fontSize: "12px"
      }
    }
  })
);

const InputWrapper = styled("div")`
  width: 100%;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 4px;
  padding: 3px;
  display: flex;
  flex-wrap: wrap;
  color: aliceblue;
  &:hover {
    border-color: #40a9ff;
  }

  &.focused {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    font-size: 10px;
    height: 30px;
    background-color: "#11ceef";
    color: "#FFF";
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`;
/*@ts-ignore*/
const Tag = styled(({ label, reset, onDelete, ...props }) => {
  if (reset) {
    onDelete();
  }
  return (
    <div {...props}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
})`
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: #11ceef;
  color: "#FFF";
  border-radius: 6px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 14px;
    cursor: pointer;
    padding: 4px;
    font-weight: 600;
  }
`;

export interface Props {
  tags: any;
  setSelectedTags: any;
  key?: any;
  defaultTags?: any;
  tagPeople?: any;
  showTagGroupHeading?: boolean;
  areAllPreferred?: boolean;
}
export function CustomizedHook(props: Props) {
  const classes = useStyles();
  const {
    tags,
    setSelectedTags,
    tagPeople,
    key,
    showTagGroupHeading,
    areAllPreferred
  } = props;
  const defaultTags: any[] = props.defaultTags?.length
    ? tags?.filter((ele: any) => props.defaultTags.includes(ele.name))
    : [];
  const { resetStateFlag } = useSelector<RootState, any>(
    (state) => state.aprStore
  );

  let {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl
  } = useAutocomplete({
    id: "customized-hook-demo",
    multiple: true,
    disableCloseOnSelect: true,
    options: tags || [],
    defaultValue: defaultTags || [],
    groupBy: (option: any) => option.tagType,
    getOptionLabel: (option: any) => option.name
    // getOptionSelected: (option: any, value: any) => option.id === value.id
  });
  const placeholder = areAllPreferred
    ? !value.length
      ? "Search by State"
      : undefined
    : undefined;
  const [inputAnchorEl, setInputAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  useEffect(() => {
    if (setSelectedTags instanceof Object) {
      setSelectedTags(value);
    }
  });
  useEffect(() => {
    if (key) {
      setSelectedTags([]);
    }
    // eslint-disable-next-line
  }, [key]);
  const getCheckedValue = (optionValue: any) => {
    if (value.length && value.includes(optionValue)) {
      return true;
    }
    return false;
  };
  const getGroupItem = (option: any, index: any) => {
    return (
      <li {...getOptionProps({ option, index })}>
        <Checkbox disableRipple checked={getCheckedValue(option)} />
        <span>{option.name}</span>
      </li>
    );
  };
  const onInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
    setInputAnchorEl(event.currentTarget);
  };

  const openPopper = Boolean(inputAnchorEl);

  if (tags === undefined) return null;

  return (
    <NoSsr>
      <div
        className="abc"
        style={{
          width: areAllPreferred ? "30%" : "100%",
          position: "relative",
          marginRight: areAllPreferred ? "10px" : "",
          marginBottom: "10px"
        }}
      >
        <div className="def" {...getRootProps()}>
          <InputWrapper
            ref={setAnchorEl}
            className={focused ? "focused" : ""}
            onClick={onInputClick}
          >
            {tagPeople && (
              <span
                style={{ color: "grey", alignItems: "center", display: "flex" }}
              >
                Tag To:{" "}
              </span>
            )}
            {value.map((option: any, index: number) => (
              <Tag
                /*@ts-ignore*/
                label={option?.name}
                reset={areAllPreferred ? resetStateFlag : false}
                {...getTagProps({ index })}
              />
            ))}
            <input
              style={{ fontSize: "14px", color: "#000000" }}
              placeholder={placeholder}
              {...getInputProps()}
            />
          </InputWrapper>
        </div>
        {groupedOptions.length > 0 ? (
          <Popper
            anchorEl={inputAnchorEl}
            open={openPopper}
            disablePortal
            placement="bottom-start"
            modifiers={{
              /*@ts-ignore*/
              flip: {
                enabled: true
              },
              preventOverflow: {
                enabled: true,
                boundariesElement: "scrollParent"
              }
            }}
            {...getListboxProps()}
            className={classes.listWrap}
          >
            {groupedOptions.map((data: any) => (
              <>
                <li>
                  <ul className={classes.innerList}>
                    {showTagGroupHeading && (
                      <div className={classes.listHeading}>{data?.group}</div>
                    )}
                    {data?.options.map((option: any, index: number) =>
                      getGroupItem(option, data.index + index)
                    )}
                  </ul>
                </li>
              </>
            ))}
          </Popper>
        ) : null}
      </div>
    </NoSsr>
  );
}
