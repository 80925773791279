/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { DateRange } from "react-date-range";
import { Button } from "@mui/material";

interface DatePickerRangeProps {
  title: string;
  onChange: (arg0: any) => void;
  dateRange: any[];
  onApply?: () => void;
  onCancel?: () => void;
  minDate?: any;
  maxDate?: any;
  applyButtonText?: string;
  cancelButtonText?: string;
  disableApply?: boolean;
  disableCancel?: boolean;
  dateInputFormat?: string;
  nullDateValue?: boolean;
  placeholder?: any;
}

export const DatePickerRange = (props: DatePickerRangeProps) => {
  const {
    title,
    onChange,
    dateRange,
    onApply,
    onCancel,
    applyButtonText,
    cancelButtonText,
    minDate,
    maxDate,
    disableApply,
    disableCancel,
    dateInputFormat,
    nullDateValue,
    placeholder
  } = props;
  return (
    <div data-testid="date-range-picker-component">
      <div className="dateRangeTitle">{title}</div>
      <div className="startEndDate">
        <div>Start</div>
        <div>End</div>
      </div>
      <div style={{ pointerEvents: nullDateValue ? "none" : "auto" }}>
        <DateRange
          color="#5e72e4"
          startDatePlaceholder={placeholder}
          endDatePlaceholder={placeholder}
          // showMonthAndYearPickers={false}
          // showMonthArrow={false}
          dateDisplayFormat={dateInputFormat || "dd MMM yyyy"}
          onChange={onChange}
          // moveRangeOnFirstSelection={false}
          {...{ retainEndDateOnFirstSelection: true }}
          editableDateInputs
          ranges={dateRange}
          minDate={minDate}
          maxDate={maxDate}
        />
      </div>
      <div className="dateRangeBottomOptions">
        {onApply ? (
          <Button
            data-testid="apply-date-range"
            variant="contained"
            color="info"
            disabled={disableApply}
            onClick={onApply}
          >
            {applyButtonText || "Apply"}
          </Button>
        ) : null}
        {onCancel ? (
          <Button
            data-testid="cancel-date-range"
            variant="contained"
            color="primary"
            disabled={disableCancel}
            onClick={onCancel}
          >
            {cancelButtonText || "Cancel"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};
