/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import { Grid, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { ExpandCollapse } from "@toorak/tc-common-fe-sdk";
import { useSelector } from "react-redux";
import iconSuccess from "../../images/icon_test_pass.svg";
import iconFailure from "../../images/icon_test_fail.svg";
import { FailureResultsCard } from "./FailureResultsCard";
import {
  RepresentableEvaluationResults,
  GridSize,
  EvaluationResultsFailureData
} from "./EvaluationResults";
import { PassedResultCard } from "./PassedResultCard";
import { isLATUser } from "../../utils/AccessManagement";
import { RootState } from "../../stores/rootReducer";

export interface FailedSectionWrapperProps {
  item: RepresentableEvaluationResults;
  failureCardSize?: {
    xs?: GridSize["size"];
    md?: GridSize["size"];
    sm?: GridSize["size"];
    xl?: GridSize["size"];
  };
  numberOfWaiversCreated: number;
  isMinimizable?: boolean;
  dividerRequired?: boolean;
  redirectionPage?: string;
  waiverCreated?: boolean;
  waiverRequested?: boolean;
  redirectionRequired?: boolean;
  showIntermediateValues?: boolean;
  errorAddressList?: any;
  isWaiverPending?: any;
  resultType?: string;
  infoContentResult?: any;
  isFailedTestTab?: boolean;
  evaluationPanel?: any;
}
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headingImageContainer: {
      justifyContent: "flex-end",
      display: "flex"
    },
    headingImage: {
      width: 16,
      height: 16,
      objectFit: "contain",
      cursor: "pointer"
    },
    sectionWrapper: {
      paddingRight: `${theme.spacing(2)}`,
      paddingLeft: `${theme.spacing(2)}`,
      paddingTop: `${theme.spacing(2)}`,
      display: "flex"
    },
    sectionWrapperMinimized: {
      padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
      display: "flex"
    }
  })
);
export function FailedSectionWrapper(props: FailedSectionWrapperProps) {
  const {
    item,
    failureCardSize,
    isMinimizable,
    dividerRequired,
    redirectionRequired,
    showIntermediateValues,
    numberOfWaiversCreated,
    errorAddressList,
    isWaiverPending,
    resultType,
    infoContentResult,
    isFailedTestTab,
    evaluationPanel
  } = props;
  const [isMinimized, setIsMinimized] = useState<boolean>(false);
  const classes = useStyles();
  useEffect(() => {
    setIsMinimized(false);
    // eslint-disable-next-line
  }, [item?.failingTests?.length]);

  const { mandatoryResult } = useSelector<RootState, any>(
    (state) => state.createLoanStore.loanDetails
  );
  const isMandatoryTest = (name: string, group: string) => {
    if (!isLATUser()) return false;
    const foo = mandatoryResult.filter((result: any) => {
      const resultList = result.split(".");
      return !!(group === resultList[1] && name === resultList[2]);
    });
    return !!foo.length;
  };

  const getFailedTests = (failureItem: any) => {
    const waiverResponseObject = failureItem?.waiverData?.waiverResponses
      ?.slice()
      .sort(
        (a: any, b: any) =>
          Date.parse(a.responseDate) - Date.parse(b.responseDate)
      );
    const approvedWaiverLength = waiverResponseObject?.length - 1;
    if (
      !failureItem?.waiverData ||
      !failureItem?.waiverData?.waiverResponses ||
      failureItem?.waiverData?.waiverResponses?.length === 0 ||
      (failureItem?.waiverData?.waiverResponses?.length > 0 &&
        waiverResponseObject[approvedWaiverLength]?.isApproved === false)
    ) {
      return (
        <Grid
          xs={failureCardSize?.xs ? failureCardSize.xs : 12}
          sm={failureCardSize?.sm ? failureCardSize.sm : 12}
          md={failureCardSize?.md ? failureCardSize.md : 12}
          xl={failureCardSize?.xl ? failureCardSize.xl : 12}
        >
          {!failureItem?.waiverData ||
            !failureItem?.waiverData?.waiverResponses ||
            failureItem?.waiverData?.waiverResponses?.length === 0}
          <FailureResultsCard
            key={failureItem.testGroup}
            testGroup={failureItem.testGroup}
            testName={failureItem.testName}
            representableTestName={failureItem.representableTestName}
            isMandatory={isMandatoryTest(
              failureItem.testName,
              item?.testGroupName
            )}
            errorAddressList={errorAddressList}
            failureMessage={failureItem.reasonForFailure}
            dividerRequired={dividerRequired}
            redirectionRequired={redirectionRequired}
            showIntermediateValues={showIntermediateValues}
            redirectionPage={failureItem.redirectionButtonName}
            propertyId={failureItem.propertyId}
            waiverData={failureItem.waiverData}
            numberOfWaiversCreated={numberOfWaiversCreated}
            resultType={resultType}
            infoContentResult={infoContentResult}
            evaluationPanel={evaluationPanel}
          />
        </Grid>
      );
    }
    return null;
  };
  return (
    <>
      {/* <div style={{ backgroundColor: "#ffffff" }}>
        <div
          style={{
            backgroundColor: "#ffffff",
            //border: "1px solid #e9ecef",
            margin: "7px",
            borderRadius: 4
          }}
        > */}

      {!isWaiverPending && !isFailedTestTab && item ? (
        <Grid
          key={item?.testGroupName}
          data-testid="failed-section-wrapper"
          style={{ backgroundColor: evaluationPanel ? "#f8f9ff" : "#ffffff" }}
        >
          {item?.testGroupName !== "General Waiver Request" && (
            <Grid
              container
              className={
                isMinimized
                  ? classes.sectionWrapperMinimized
                  : classes.sectionWrapper
              }
              key={item?.testGroupName}
              style={{ marginBottom: "1rem" }}
            >
              <Grid item xs={12} container>
                <Grid
                  xs={6}
                  sm={11}
                  style={{
                    fontSize: 12,
                    fontWeight: 600,
                    lineHeight: 1.5,
                    color: "#32325d",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 10
                  }}
                >
                  {item.representableTestGroupName}
                  {item.overallFailingTests === "FAIL" ? (
                    <img
                      src={iconFailure}
                      alt="Test Failure"
                      style={{ marginLeft: 6, width: 16, height: 16 }}
                    />
                  ) : (
                    <img
                      src={iconSuccess}
                      alt="Test Success"
                      style={{ marginLeft: 6, width: 16, height: 16 }}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={1}
                  style={{ paddingRight: "5px" }}
                  className={classes.headingImageContainer}
                >
                  {isMinimizable &&
                    item.overallFailingTests &&
                    item.overallFailingTests === "FAIL" && (
                      <ExpandCollapse
                        isMinimize={isMinimized}
                        clicked={() => {
                          setIsMinimized(!isMinimized);
                        }}
                        keyDown={() => {
                          setIsMinimized(!isMinimized);
                        }}
                      />
                    )}
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid
            container
            xs={12}
            style={{
              display: isMinimized ? "none" : ""
            }}
          >
            {item?.failingTests.length > 0 &&
              item.failingTests.map(
                (failureItem: EvaluationResultsFailureData, index: any) => {
                  return (
                    <Grid
                      xs={failureCardSize?.xs ? failureCardSize.xs : 12}
                      sm={failureCardSize?.sm ? failureCardSize.sm : 12}
                      md={failureCardSize?.md ? failureCardSize.md : 12}
                      xl={failureCardSize?.xl ? failureCardSize.xl : 12}
                    >
                      <FailureResultsCard
                        key={failureItem.testGroup}
                        testGroup={failureItem.testGroup}
                        testName={failureItem.testName}
                        representableTestName={
                          failureItem.representableTestName
                        }
                        isMandatory={isMandatoryTest(
                          failureItem.testName,
                          item?.testGroupName
                        )}
                        errorAddressList={errorAddressList}
                        failureMessage={failureItem.reasonForFailure}
                        dividerRequired={dividerRequired}
                        redirectionRequired={redirectionRequired}
                        showIntermediateValues={showIntermediateValues}
                        redirectionPage={failureItem.redirectionButtonName}
                        propertyId={failureItem.propertyId}
                        waiverData={failureItem.waiverData}
                        numberOfWaiversCreated={numberOfWaiversCreated}
                        resultType={resultType}
                        infoContentResult={infoContentResult}
                        evaluationPanel={evaluationPanel}
                      />
                    </Grid>
                    //  </div>
                  );
                }
              )}
          </Grid>

          {showIntermediateValues && (
            <Grid
              container
              xs={12}
              style={{
                display: isMinimized ? "none" : "",
                padding: 10
              }}
            >
              {item.intermediateValues
                ?.filter((ele: any) => ele.fieldValue)
                .map((intermediateItem: any) => {
                  return (
                    // <Grid xs={12} sm={6} style={{ padding: 15 }}>
                    <div
                      style={{
                        backgroundColor: evaluationPanel
                          ? "#f8f9ff"
                          : "#ffffff",
                        margin: "10px"
                      }}
                    >
                      {/* <div
                      style={{
                        border: "1px solid #e9ecef",
                        margin: "5px",
                        borderRadius: 4
                      }}
                    > */}
                      <PassedResultCard
                        fieldName={intermediateItem.fieldName}
                        fieldType={intermediateItem.fieldType}
                        isTest={intermediateItem.isTest}
                        fieldValue={intermediateItem.fieldValue}
                        propertyId={intermediateItem.propertyId}
                      />
                      {/* </div> */}
                    </div>
                    // </Grid>
                  );
                })}
            </Grid>
          )}
        </Grid>
      ) : null}
      {/* waiver pending section */}
      {isWaiverPending && item && !isFailedTestTab ? (
        <Grid key={item?.testGroupName} data-testid="failed-section-wrapper">
          {item.overallFailingTests === "FAIL" &&
            item?.testGroupName !== "General Waiver Request" && (
              <Grid
                container
                className={
                  isMinimized
                    ? classes.sectionWrapperMinimized
                    : classes.sectionWrapper
                }
                key={item?.testGroupName}
              >
                <Grid item xs={12} container>
                  <Grid
                    xs={6}
                    sm={11}
                    style={{
                      fontSize: 12,
                      fontWeight: 600,
                      lineHeight: 1.5,
                      color: "#32325d",
                      display: "flex",
                      alignItems: "center"
                      //paddingLeft: 10
                    }}
                  >
                    {item.representableTestGroupName}
                    {item.overallFailingTests === "FAIL" && (
                      <img
                        src={iconFailure}
                        alt="Test Failure"
                        style={{ marginLeft: 6, width: 16, height: 16 }}
                      />
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={1}
                    className={classes.headingImageContainer}
                  >
                    {isMinimizable && (
                      <ExpandCollapse
                        isMinimize={isMinimized}
                        clicked={() => {
                          setIsMinimized(!isMinimized);
                        }}
                        keyDown={() => {
                          setIsMinimized(!isMinimized);
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
          <Grid
            container
            xs={12}
            style={{
              display: isMinimized ? "none" : "",
              padding: 0
            }}
          >
            {item?.failingTests.length > 0 &&
              item.failingTests.map(
                (failureItem: EvaluationResultsFailureData) => {
                  return (
                    <Grid
                      xs={failureCardSize?.xs ? failureCardSize.xs : 12}
                      sm={failureCardSize?.sm ? failureCardSize.sm : 12}
                      md={failureCardSize?.md ? failureCardSize.md : 12}
                      xl={failureCardSize?.xl ? failureCardSize.xl : 12}
                    >
                      {failureItem?.waiverData?.waiverResponses?.length ===
                      0 ? (
                        <FailureResultsCard
                          key={failureItem.testGroup}
                          testGroup={failureItem.testGroup}
                          testName={failureItem.testName}
                          representableTestName={
                            failureItem.representableTestName
                          }
                          isMandatory={isMandatoryTest(
                            failureItem.testName,
                            item?.testGroupName
                          )}
                          errorAddressList={errorAddressList}
                          failureMessage={failureItem.reasonForFailure}
                          dividerRequired={dividerRequired}
                          redirectionRequired={redirectionRequired}
                          showIntermediateValues={showIntermediateValues}
                          redirectionPage={failureItem.redirectionButtonName}
                          propertyId={failureItem.propertyId}
                          waiverData={failureItem.waiverData}
                          numberOfWaiversCreated={numberOfWaiversCreated}
                          evaluationPanel={evaluationPanel}
                        />
                      ) : null}
                    </Grid>
                  );
                }
              )}
          </Grid>
        </Grid>
      ) : null}
      {/* failed test tab */}
      {!isWaiverPending && isFailedTestTab && item ? (
        <Grid key={item?.testGroupName} data-testid="failed-section-wrapper">
          {item?.testGroupName !== "General Waiver Request" && (
            <Grid
              container
              className={
                isMinimized
                  ? classes.sectionWrapperMinimized
                  : classes.sectionWrapper
              }
              key={item?.testGroupName}
            >
              <Grid item xs={12} container>
                <Grid
                  xs={6}
                  sm={11}
                  style={{
                    fontSize: 12,
                    fontWeight: 600,
                    lineHeight: 1.5,
                    color: "#32325d",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 10
                  }}
                >
                  {item.representableTestGroupName}
                  {item.overallFailingTests === "FAIL" ? (
                    <img
                      src={iconFailure}
                      alt="Test Failure"
                      style={{ marginLeft: 6, width: 16, height: 16 }}
                    />
                  ) : (
                    <img
                      src={iconSuccess}
                      alt="Test Success"
                      style={{ marginLeft: 6, width: 16, height: 16 }}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={1}
                  className={classes.headingImageContainer}
                >
                  {isMinimizable &&
                    item.overallFailingTests &&
                    item.overallFailingTests === "FAIL" && (
                      <ExpandCollapse
                        isMinimize={isMinimized}
                        clicked={() => {
                          setIsMinimized(!isMinimized);
                        }}
                        keyDown={() => {
                          setIsMinimized(!isMinimized);
                        }}
                      />
                    )}
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid
            container
            xs={12}
            style={{
              display: isMinimized ? "none" : "",
              padding: 0
            }}
          >
            {item?.failingTests.length > 0 &&
              item.failingTests.map(
                (failureItem: EvaluationResultsFailureData) => {
                  return getFailedTests(failureItem);
                }
              )}
          </Grid>
          {showIntermediateValues && (
            <Grid
              container
              xs={12}
              style={{
                display: isMinimized ? "none" : "",
                padding: 10
              }}
            >
              {item.intermediateValues
                ?.filter((ele: any) => ele.fieldValue)
                .map((intermediateItem: any) => {
                  return (
                    // <Grid xs={12} sm={6} style={{ padding: 15 }}>
                    <div
                      style={{
                        backgroundColor: evaluationPanel ? "#f8f9ff" : "#ffffff"
                      }}
                    >
                      <div
                        style={{
                          border: "1px solid #e9ecef",
                          margin: "5px",
                          borderRadius: 4
                        }}
                      >
                        <PassedResultCard
                          fieldName={intermediateItem.fieldName}
                          fieldType={intermediateItem.fieldType}
                          isTest={intermediateItem.isTest}
                          fieldValue={intermediateItem.fieldValue}
                          propertyId={intermediateItem.propertyId}
                        />
                      </div>
                    </div>
                    // </Grid>
                  );
                })}
            </Grid>
          )}
        </Grid>
      ) : null}
    </>
  );
}
