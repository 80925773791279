import { UserRole, publicClient } from "@toorak/tc-common-fe-sdk";
import { getConfig, getLoanType } from "../../config/config";
import { isLATUser, isRole } from "../../utils/AccessManagement";
import { getCookie } from "../../utils/cookies";
import { isEmptyValue, isNullOrUndefined } from "../../utils/formatChecks";
import { getTermBlock, getRangeBlock } from "../Discussions/Discussions.action";
import { getPartyId } from "../WaiverRequest/WaiverRequest.action";
import {
  getScenarioResults,
  updateAttachedScenarioInRedux,
  updateLoanListInRedux,
  updateScenarioInViewDetails
} from "./ScenarioReducer";
import { ObjectType } from "../../masterView/common";

// export const GET_SCENARIO_RESULTS = "GET_SCENARIO_RESULTS";
// export const UPDATE_SCENARIO_IN_VIEW_DETAILS =
//   "UPDATE_SCENARIO_IN_VIEW_DETAILS";
// export const UPDATE_LOAN_LIST_IN_REDUX = "UPDATE_LOAN_LIST_IN_REDUX";
// export const UPDATE_ATTACHED_SCENARIO_IN_REDUX =
//   "UPDATE_ATTACHED_SCENARIO_IN_REDUX";
// export const SET_USER_LIST = "SET_USER_LIST";
// export const UPDATE_SCENARIO_STATUS = "UPDATE_SCENARIO_STATUS";
// export const DELETE_LOAN_FROM_REDUX = "DELETE_LOAN_FROM_REDUX";
// export const SET_LOAN_TYPE_FILTERS_SCENARIO = "SET_LOAN_TYPE_FILTERS_SCENARIO";
// export const SET_SCENARIO_STATUS_FILTERS = "SET_SCENARIO_STATUS_FILTERS";
// export const SET_DAYS_FILTERS = "SET_DAYS_FILTERS";
// export const SET_ORG_FILTERS = "SET_ORG_FILTERS";
// export const SET_SCENARIO_FILTERS = "SET_SCENARIO_FILTERS";

export const getModifiedLoanType = (loanType: any, type: string) => {
  const loanTypeOne =
    type !== "name" ? getLoanType[1].loanCreationName : getLoanType[1].name;
  const loanTypeZero = loanType?.toLowerCase().includes("bridge")
    ? getLoanType[0].loanCreationName
    : "";
  const editedLoanType =
    loanType &&
    (loanType?.toLowerCase().includes("investor") ||
      loanType?.toLowerCase().includes("30"))
      ? loanTypeOne
      : loanTypeZero;
  return editedLoanType;
};

export function CreateScenarioId(originatorPartyId: string, loanType?: string) {
  // const url = `${getConfig().apiUrl}/loan_property/properties`;
  const url = `${getConfig().apiUrl}/aggregate/scenarios`;
  const requestBody = {
    originatorPartyId
  };
  return new Promise((resolve, reject) => {
    publicClient
      .post(url, requestBody)
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}

export function PostScenarioDetails(
  personPartyId: string,
  fullName: string,
  scenarioId: string,
  loanType: string,
  title: string,
  description: string,
  propertyLocationObj: any,
  hasDocuments: boolean,
  propertyType: string
) {
  const url = `${getConfig().apiUrl}/aggregate/scenario/${scenarioId}`;
  const scenarioArr: {
    originatorPartyId: string;
    loanType: string;
    propertyEconomics: {};
    propertyinfo: {};
    propertyLocation: any;
    propertySummary: {};
    propertyUnit: never[];
  }[] = [];
  if (propertyLocationObj && propertyLocationObj.length) {
    propertyLocationObj.forEach((property: any) => {
      const obj = {
        originatorPartyId: personPartyId,
        loanType,
        propertyEconomics: {},
        propertyinfo: {
          propertyType
        },
        propertyLocation: property,
        propertySummary: {},
        propertyUnit: []
      };
      scenarioArr.push(obj);
    });
  }
  const requestBody = {
    scenarios:
      scenarioArr && scenarioArr.length
        ? scenarioArr
        : [
            {
              originatorPartyId: personPartyId,
              loanType,
              propertyEconomics: {},
              propertyinfo: {
                propertyType
              },
              propertyLocation: propertyLocationObj,
              propertySummary: {},
              propertyUnit: []
            }
          ],
    waivers: {
      add: [
        {
          waiverType: "Scenario",
          waiverKeys: [],
          title,
          description,
          waiverMitigates: [],
          waiverInfo: {
            hasDocuments,
            hasDiscussions: false,
            finalResponse: "open",
            isInternal: false
          }
        }
      ]
    }
  };
  return new Promise((resolve, reject) => {
    publicClient
      .post(url, requestBody)
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}

export function getScenarioListFromElasticSearch(
  fromPreSubmitLoan?: boolean,
  loanType?: string,
  scenarioId?: string,
  page?: number,
  attachedScenarioIds?: any[] | any,
  value?: any,
  loanTypeSelected?: any,
  selectedScenarioStatusType?: any,
  selectedDays?: any,
  selectedOriginators?: any,
  propertyTypeSelected?: any
) {
  // const url = `${getConfig().apiUrl}/search/dev_dashboard/_search`;
  // const url = `${getConfig().apiUrl}/search/${
  //   getConfig().dashboardUrl
  // }/_search`;
  const url = `${getConfig().apiUrl}/aggregate/scenarios/search`;
  const boolMustArray: any = [];
  const boolMustNotArray: any[] = [];
  boolMustArray.push(getTermBlock("waiver.identifierName.raw", ["SCENARIO"]));
  if (getCookie("loginFlow") === "originator") {
    let accountIds: any;
    accountIds = getCookie("org_id");
    boolMustArray.push(getTermBlock("originatorId.raw", [accountIds]));
  } else if (!isRole(UserRole.LAT_TREASURER)) {
    let accountIds: any;
    accountIds = localStorage.getItem("account_ids");
    accountIds = JSON.parse(accountIds);
    boolMustArray.push(getTermBlock("originatorId.raw", accountIds));
  }
  if (fromPreSubmitLoan) {
    boolMustArray.push(getTermBlock("status.raw", ["Seems OK"]));
  }

  if (!isEmptyValue(loanType)) {
    const editedLoanType = getModifiedLoanType(loanType, "loanCreationName");
    boolMustArray.push(getTermBlock("loanType.raw", [editedLoanType]));
  }
  if (attachedScenarioIds instanceof Array && attachedScenarioIds?.length) {
    boolMustNotArray.push(getTermBlock("scenarioId.raw", attachedScenarioIds));
  }

  if (loanTypeSelected && loanTypeSelected.length) {
    const selectedLoanType = loanTypeSelected.filter(
      (item: any) => item !== undefined
    );

    boolMustArray.push(getTermBlock("loanType.raw", selectedLoanType));
  }

  if (propertyTypeSelected && propertyTypeSelected.length) {
    const selectedPropertyType = propertyTypeSelected.filter(
      (item: any) => item !== undefined
    );

    boolMustArray.push(
      getTermBlock(
        "properties.propertyinfo.propertyType.raw",
        selectedPropertyType
      )
    );
  }

  if (selectedScenarioStatusType && selectedScenarioStatusType.length) {
    let selectedStatus = selectedScenarioStatusType.filter(
      (item: any) => item !== undefined
    );

    boolMustArray.push(getTermBlock("status.raw", selectedStatus));
  }

  if (value?.length) {
    let obj = {
      multi_match: {
        query: value,
        fields: ["identifierValue"],
        analyzer: "standard",
        type: "phrase_prefix"
      }
    };
    boolMustArray.push(obj);
  }
  if (selectedOriginators && selectedOriginators.length) {
    let selectedOrg = selectedOriginators.filter(
      (item: any) => item !== undefined
    );

    boolMustArray.push(getTermBlock("originatorId.raw", selectedOrg));
  }
  if (selectedDays && selectedDays.length) {
    let selectedDaysScenario = selectedDays.filter(
      (item: any) => item !== undefined
    );
    let selectedDay =
      selectedDaysScenario.length === 1 &&
      selectedDaysScenario.includes("now-7d/d")
        ? { gte: "now-7d/d" }
        : { gte: "now-30d/d" };
    boolMustArray.push(getRangeBlock("waiver.created_on", selectedDay));
  }
  let requestBody = {
    query: {
      bool: {
        must: boolMustArray,
        must_not: boolMustNotArray
      }
    },
    _source: ["*"],
    sort: [{ "waiver.created_on": "desc" }],
    size: page,
    from: 0
  };
  if (!isEmptyValue(scenarioId)) {
    const scenarioIDObj = {
      scenarioIds: [scenarioId]
    };
    requestBody = {
      ...scenarioIDObj,
      query: {
        bool: {
          must: boolMustArray,
          must_not: boolMustNotArray
        }
      },
      _source: ["*"],
      sort: [{ "waiver.created_on": "desc" }],
      size: 200,
      from: 0
    };
  }
  return new Promise((resolve, reject) => {
    publicClient
      .post(url, requestBody)
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}

export function updateScenarioDetailsListInRedux(
  data: any,
  total: number
): any {
  return async (dispatch: any) => {
    dispatch(
      getScenarioResults({
        scenarioListDetails: data,
        total
      })
    );
    // dispatch({
    //   type: GET_SCENARIO_RESULTS,
    //   payload: {
    //     scenarioListDetails: data,
    //     total
    //   }
    // });
  };
}

// export function updateScenarioStatusInRedux(data: any): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: UPDATE_SCENARIO_STATUS,
//       payload: data
//     });
//   };
// }

export function getScenarioDetailFromAggregate(scenarioId: string) {
  const url = `${getConfig().apiUrl}/aggregate/scenario/${scenarioId}`;
  return new Promise((resolve, reject) => {
    publicClient
      .get(url)
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}

export function updateScenarioViewDetailsInRedux(data: any): any {
  const { loanDetails } = data.data;
  const attachedLoanArr: any[] = [];
  if (loanDetails && loanDetails instanceof Array)
    loanDetails.forEach((item: any) => {
      const { loanStage, loanType } = item;
      const { loanId } = item?.loanDetailId;
      const { primaryLoanId } = item?.loanInfo;
      const obj = {
        loanStage,
        loanType,
        loanId: `${loanId}`,
        originatorLoanId: primaryLoanId
      };
      attachedLoanArr.push(obj);
    });
  return async (dispatch: any) => {
    dispatch(
      updateScenarioInViewDetails({
        scenarioDetails: data,
        attachedLoanDetails: attachedLoanArr
      })
    );

    // dispatch({
    //   type: UPDATE_SCENARIO_IN_VIEW_DETAILS,
    //   payload: {
    //     scenarioDetails: data,
    //     attachedLoanDetails: attachedLoanArr
    //   }
    // });
  };
}

export function deleteScenario(waiverId: any) {
  const url = `${getConfig().apiUrl}/waiverservice/waiver/${waiverId}`;
  return new Promise((resolve, reject) => {
    publicClient
      .delete(url)
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}

export function scenarioApproval(
  responseType: string,
  isApproved: boolean,
  waiverId: string
): Promise<string> {
  return new Promise((resolve, reject) => {
    const url = `${
      getConfig().apiUrl
    }/waiverservice/waiver/${waiverId}/response`;
    // const responseBy = getPartyId();
    const { partyId } = getPartyId();
    const body = {
      responseType,
      responseBy: partyId,
      responseDate: new Date(),
      isApproved
    };
    publicClient
      .post(url, body)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}

export function attachScenarioToLoans(
  loanId: string,
  scenarioIds: any
): Promise<string> {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl}/loans/${loanId}/scenarios`;
    const body: any = scenarioIds?.length
      ? scenarioIds.map((id: any) => {
          if (!isEmptyValue(id.scenarioId)) {
            const scenarioId = id.scenarioId.split(" - ")[0];
            return { scenarioId };
          }
          return null;
        })
      : [];
    publicClient
      .post(url, body)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}

export function getScenarioAttachedToLoan(loanId: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl}/loans/${loanId}/scenarios`;
    publicClient
      .get(url)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}

export function getLoansListToAttachToScenario(
  loanType: string,
  loanId?: string,
  attachedLoanArr?: any[]
) {
  const url = `${getConfig().apiUrl}/search/${
    getConfig().aggregateUrl
  }/_search`;
  const boolMustArray: any[] = [];
  const boolMustNotArray: any[] = [];
  const editedLoanType = getModifiedLoanType(loanType, "loanCreationName");
  const multiMatcher = {
    multi_match: {
      query: loanId,
      fields: ["loan.loanInfo.primaryLoanId", "loan.loanDetailId.loanId"],
      type: "phrase_prefix",
      analyzer: "standard"
    }
  };
  boolMustNotArray.push(getTermBlock("loan.loanState.raw", ["Purchased"]));
  if (attachedLoanArr?.length) {
    boolMustNotArray.push(
      getTermBlock("loan.loanDetailId.loanId.raw", attachedLoanArr)
    );
  }
  if (!isNullOrUndefined(editedLoanType) && editedLoanType !== "") {
    boolMustArray.push(getTermBlock("loan.loanType.raw", [editedLoanType]));
  }
  if (getCookie("loginFlow") === "originator") {
    let accountIds: any;
    accountIds = getCookie("org_id");
    boolMustArray.push(
      getTermBlock("loan.loanDetailId.originatorPartyId.raw", [accountIds])
    );
  } else if (!isRole(UserRole.LAT_TREASURER)) {
    let accountIds: any;
    accountIds = localStorage.getItem("account_ids");
    accountIds = JSON.parse(accountIds);
    boolMustArray.push(
      getTermBlock("loan.loanDetailId.originatorPartyId.raw", accountIds)
    );
  }
  if (loanId) {
    boolMustArray.push(multiMatcher);
  }
  const requestBody = {
    query: {
      bool: {
        must: boolMustArray,
        must_not: boolMustNotArray
      }
    },
    _source: [
      "loan.loanInfo.primaryLoanId",
      "loan.loanDetailId.loanId",
      "loan.loanDetailId.originatorPartyId",
      "loan.loanType"
    ],
    sort: [
      {
        "loan.loanCreationDate": "desc"
      },
      "_score"
    ],
    size: 200,
    from: 0
  };
  return new Promise((resolve, reject) => {
    publicClient
      .post(url, requestBody)
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}
export function getLoanIdAndOrgIdPopData(
  loanId: string,
  primaryLoanId: string
) {
  const loanIdAndOrgLoanId =
    !isNullOrUndefined(loanId) &&
    !isNullOrUndefined(primaryLoanId) &&
    primaryLoanId !== "" &&
    loanId !== ""
      ? `${loanId}/${primaryLoanId}`
      : loanId;
  return loanIdAndOrgLoanId;
}
export function updateLoansInRedux(data: any): any {
  const loanDetailIdArr: any[] = [];
  data.forEach((item: any) => {
    const popData = {
      loanId: getLoanIdAndOrgIdPopData(
        item?._source?.loan?.loanDetailId?.loanId,
        item?._source?.loan?.loanInfo?.primaryLoanId
      )
    };
    loanDetailIdArr.push(popData);
  });
  return async (dispatch: any) => {
    if (loanDetailIdArr.length > 0)
      // dispatch({
      //   type: UPDATE_LOAN_LIST_IN_REDUX,
      //   payload: {
      //     loansListDetail: loanDetailIdArr
      //   }
      // });
      dispatch(
        updateLoanListInRedux({
          loansListDetail: loanDetailIdArr
        })
      );
  };
}

// export function removeScenarioDocs(
//   loanId: string,
//   scenarioId: string,
//   loanStage: LoanStage
// ){
//   const url = `${getConfig().apiUrl}/aggregate/documentsRemove/scenario/${scenarioId}/loan/${loanId}/${loanStage}`
//   return new Promise((resolve, reject) => {
//     publicClient
//       .post(url)
//       .then((res: any) => {
//         resolve(res);
//       })
//       .catch(reject);
//   });
// }

export function deleteScenarioAttachedToLoan(
  loanId: string,
  scenarioId: string
) {
  const url = `${getConfig().apiUrl}/loans/${loanId}/scenarios/${scenarioId}`;
  return new Promise((resolve, reject) => {
    publicClient
      .delete(url)
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}

export function attachLoansToScenario(
  scenarioID: string,
  loanIds: any
): Promise<string> {
  const body: any[] = [];
  loanIds.forEach((item: any) => {
    const loanIdObj = {
      loanId: item.loanId?.split("/")[0]
    };
    body.push(loanIdObj);
  });
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl}/aggregate/scenario/${scenarioID}/loans`;
    publicClient
      .post(url, body)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}

export const getScenariosByLoanIds = (loanId: string, loanStage: any): any => {
  return async (dispatch: any) => {
    const url = `${getConfig().apiUrl}/search/${
      getConfig().dashboardUrl
    }/_search`;

    const orgId = getCookie("org_id");
    let mustArray: any = [
      {
        terms: {
          "resource.raw": ["waiver"]
        }
      },
      {
        terms: {
          "identifierName.raw": ["SCENARIO"]
        }
      },
      {
        term: {
          "waiver.attachedLoans.loanId.raw": loanId
        }
      },
      {
        term: {
          "waiver.attachedLoans.loanStage.raw": loanStage
        }
      }
    ];
    const orgArr = {
      terms: {
        "originatorId.raw": [orgId]
      }
    };
    if (!isLATUser()) mustArray.unshift(orgArr);
    const queryBoolObject: { [key: string]: any } = {
      query: {
        bool: {
          must: mustArray
        }
      },
      size: 10,
      from: 0,
      sort: [
        {
          createdOn: {
            order: "desc"
          }
        }
      ]
    };

    const resp = await publicClient.post(url, queryBoolObject, {
      headers: {
        "Content-Type": "application/json"
      }
    });

    const hitsDataForScenario = resp.data.response?.hits?.hits;
    if (
      !isNullOrUndefined(hitsDataForScenario)
      // hitsDataForScenario.length
    ) {
      const filteredList = hitsDataForScenario.filter((item: any) =>
        item?._id?.includes("waiver")
      );
      dispatch(
        updateAttachedScenarioInRedux({
          attachedScenarioDetails: filteredList
        })
      );
    }
  };
};

// export function updateScenarioAttachedToLoanInRedux(data: any) {
//   return async (dispatch: any) => {
//     dispatch({
//       type: UPDATE_ATTACHED_SCENARIO_IN_REDUX,
//       payload: {
//         attachedScenarioDetails: data
//       }
//     });
//   };
// }

export function getUserLists() {
  const url = `${getConfig().apiUrl}/aggregate/v2/customer/roles`;
  const requestBody: ObjectType = {
    "roleNames": [
        "ORIGINATOR_MEMBER", "LAT_MEMBER"
    ]
  };

  if (getCookie("loginFlow") === "originator") {
    let accountIds = getCookie("org_id");
    requestBody["accountIds"] = [accountIds];
  }
  return new Promise((resolve, reject) => {
    publicClient
      .post(url, requestBody)
      .then((res: any) => {
        const { data } = res;
        let elasticSearchHits: any  =[]
        for (let item of data["access"]) {
            const customerArr = data["customers"]
            const customer = customerArr.find((citem: any)=> citem.partyId === item.personId);
            elasticSearchHits.push({"_source":{access: item, customer}})
        }
        resolve({data: {response: {hits: {hits: elasticSearchHits}}}});
      })
      .catch(reject);
  });
}

// export function updateUserListInRedux(userSortListArray: any): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: SET_USER_LIST,
//       payload: userSortListArray
//     });
//   };
// }
export function deleteLoanFromScenario(scenarioId: string, loanId: string) {
  const url = `${
    getConfig().apiUrl
  }/aggregate/scenario/${scenarioId}/loan/${loanId}`;
  return new Promise((resolve, reject) => {
    publicClient
      .delete(url)
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}

// export function deleteLoanFromRedux(scenarioId: string, loanId: string): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: DELETE_LOAN_FROM_REDUX,
//       payload: {
//         scenarioId,
//         loanId
//       }
//     });
//   };
// }

// export function updateScenarioFilter(
//   loanTypeSelected: string[],
//   selectedScenarioStatusType: string[],
//   selectedDaysFilter: string[],
//   selectedOrgFilter: string[],
//   propertyTypeFilter: string[]
// ): any {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_SCENARIO_FILTERS,
//       payload: {
//         loanTypeSelected,
//         selectedScenarioStatusType,
//         selectedDaysFilter,
//         selectedOrgFilter,
//         propertyTypeFilter
//       }
//     });
//   };
// }
