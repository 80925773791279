import React from "react";
import { Grid } from "@mui/material";
// import { EditOriginatorServicerMappingWrapper } from "./editOriginatorServicerMappingWrapper";
import { ServicerHistoryWrapper } from "../servicerHistoryWrapper";
// import { isRole } from "../../utils/AccessManagement";
// import { DateFormatter } from "../../masterView/formatter";

interface columnsInputs {
  classes?: any;
  onFieldChange?: any;
  changeModalState?: Function;
}

export const servicerMappingColumns = (config: columnsInputs) => {
  // eslint-disable-next-line
  const ShowEditAndHistory = (props: any, classes: any) => {
    return (
      <Grid item container xs={12} sm={12} md={12} xl={12}>
        {<ServicerHistoryWrapper historyObject={props.row} />}
      </Grid>
    );
  };

  return [
    {
      id: "originatorName",
      title: "Originator",
      width: 400,
      boxProps: { ml: "auto", lineHeight: 0, display: "flex" },
      showDropDownFilter: true,
      rowClass: "text-truncate",
      showSort: true,
      frozen: false,
      alwaysFrozen: false,
      tbField: "originatorName"
    },
    {
      id: "bridgeServicer",
      title: "Bridge Servicer",
      width: 400,
      boxProps: { ml: "auto", lineHeight: 0, display: "flex" },
      showDropDownFilter: true,
      rowClass: "text-truncate",
      showSort: true,
      frozen: false,
      alwaysFrozen: false,
      tbField: "bridgeServicer"
    },
    {
      id: "dscrServicer",
      title: "DSCR Servicer",
      width: 400,
      boxProps: { ml: "auto", lineHeight: 0, display: "flex" },
      showDropDownFilter: true,
      rowClass: "text-truncate",
      showSort: true,
      frozen: false,
      alwaysFrozen: false,
      tbField: "dscrServicer"
    }
    /**
     * below column removed bcz of AM-448
     */
    // {
    //   id: "comment",
    //   title: "Comment",
    //   width: 250,
    //   boxProps: { ml: "auto", lineHeight: 0, display: "flex" },
    //   showDropDownFilter: false,
    //   rowClass: "text-truncate",
    //   showSort: false,
    //   frozen: false,
    //   alwaysFrozen: false,
    //   tbField: "comment"
    // },
    // {
    //   id: "updatedDate",
    //   title: "Updated Date",
    //   boxProps: { ml: "auto", lineHeight: 0, minWidth: "20px" },
    //   rowClass: "text-truncate",
    //   width: 200,
    //   showSort: true,
    //   showDateRangeFilter: true,
    //   renderCustom: (data: any) => {
    //     return <DateFormatter data={data.updatedDate} />;
    //   },
    //   tbField: "updatedDate"
    // },
    // {
    //   id: "editAndHistory",
    //   title: "",
    //   boxProps: { ml: "auto", lineHeight: 0, display: "flex" },
    //   showSort: false,
    //   width: 80,
    //   renderCustom: (row: any, styles: any) => {
    //     return ShowEditAndHistory({ row, key: "ageing" }, styles);
    //   },
    //   tbField: "editAndHistory"
    // }
  ];
};
