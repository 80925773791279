import React, { useCallback, useEffect, useMemo, useState } from "react";
// @ts-ignore
import { BudgetReviewWrapperSDK } from "@toorak/budget-review-module";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import {
  hideLoader,
  showLoader
} from "../stores/loaderAndException/loaderAndException.action";
import { getDocuments } from "../create-loan/create-loan.action";
import { RootState } from "../stores/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  DrawRolesEnum,
  InternalRolesEnum,
  URLParamsType
} from "../utils/constants";
import { filterObjectsByTag } from "./InternalBudgetReview";

import { ILPStore } from "../stores/ILP/ILP.reducer";
import { getListOfAssignee, setILPUserList } from "../stores/ILP/ILP.action";
import { BudgetReviewStore } from "../stores/budgetReview/budgetReview.interface";
import {
  approvalStatuses,
  budgetApproveStatusEnum,
  budgetReviewStatusEnum,
  disableStatuses,
  editableStatuses
} from "../ilp/list-view/BudgetTaskManagement";
import { convertUSDToNumber } from "../utils/formatChecks";
import {
  setSelectedBudgetDoc,
  updateBudgetButtonInfo,
  updateBudgetChatsData,
  updateBudgetPropData,
  updateLineItemList,
  updateSelectedPropDetails
} from "../stores/budgetReview/budgetReview.reducer";
import {
  BudgetApproverStatus,
  ExtractionInProgressState,
  ExtractionProcessState,
  isLoginPartyId
} from "./BudgetReview.constant";
import {
  deleteLineItems,
  getBudgetHistory,
  getBudgetData,
  getBudgetReviewDataV2,
  getCommentsList,
  redoBudgetReview,
  updateBudgetStatusV2,
  // updateLineReduxData,
  updatePredictionStatus,
  updateStatusOnTask,
  updateBudgetLineItems,
  getUserDetails,
  updateSelectionMode,
  getRedoValidationItems,
  getPreviousVersionData,
  lineItemResponse,
  mergeChatDetails
} from "../stores/budgetReview/budgetReview.action";
import { taskNames } from "../stores/tasks/Tasks.action";
import { DocumentViewer } from "../create-loan/ttf-review/document-review/document-viewer/DocumentViewer";
import { getCookie } from "../utils/cookies";
import { ConfirmFormModal } from "@toorak/tc-common-fe-sdk";
// import { ConfigItems } from "./ReviewConfig";
import { usePolling } from "../custom-hooks/usePolling";
import { getConfig } from "../config/config";
import { addEmptyLineItems } from "./FieldsPanel/TotalBudgetArea";
import {
  buildTaskObject,
  getAssignedBy,
  getDataByLoanID,
  getPropDataByLoanID,
  handleAddCommentProceed,
  updateFieldName
} from "./LineItemsComment.util";
import { isRole } from "../utils/AccessManagement";

export const budgetReviewStatusConst = {
  green: budgetReviewStatusEnum.Approved,
  orange: budgetReviewStatusEnum.InProgress,
  black: budgetReviewStatusEnum.LoanSubmitted
};

export const InternalBudgetReviewWrapper = () => {
  const dispatch = useDispatch();
  const [budgetDocs, setbudgetDocs] = useState<any[]>([]);
  const [budgetExtractionGetResponse, setBudgetExtractionGetResponse] =
    useState<any[]>([]);
  const { loanId, loanStage, loanType } = useParams<URLParamsType>() as any;
  const [budgetDocsSelected, setBudgetDocsSelected] = useState<any>("");
  const [extractiotnStatus, setExtractionStatus] = useState<
    ExtractionProcessState | "Data Loading"
  >("Data Loading");
  const [budgetDocsOption, setbudgetDocsOption] = useState<any[]>([]);
  const { loanDetails } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );

  const { irQCVMAdminMap } = useSelector<RootState, ILPStore>(
    (state) => state.ILPStore
  );
  const propertyDetails = useSelector<RootState, any[]>(
    (state) => state.createLoanStore.loanDetails.propertyDetails
  );
  const [isApprovalView, setIsApprovalView] = useState<boolean>(false);
  const [showReadyForReview, setShowReadyForReview] = useState<boolean>(false);
  const [userIdMapping, setUserIdMapping] = useState<any>({});
  const {
    budgetStatusHistory,
    BulkBudgetSum,
    budgetReviewHeader,
    budgetApprovalHistory,
    budgetButtonInfo,
    budgetReviewResponse,
    selectedBudgetDoc,
    lineItemsList,
    isLineItemsEditDisable,
    budgetChatsData,
    budgetPropData,
    redoValidationItems
  } = useSelector<RootState, BudgetReviewStore>(
    (state) => state.budgetReviewStore
  );
  const { startPolling, stopPolling } = usePolling();
  const { loanState } = loanDetails;
  const { tasks } = useSelector<RootState, any>((state) => state.TasksStore);
  const [reviewerApprover, setReviewerApprover] = useState<{
    reviewerName: string;
    approverName: string;
  }>({
    reviewerName: "",
    approverName: ""
  });
  const [borrowerOriginator, setBorrowerOriginator] = useState<{
    borrowersNames: string;
    originatorName: string;
  }>({
    borrowersNames: "",
    originatorName: ""
  });
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isFieldsDisabled, setIsFieldsDisabled] = useState<boolean>(true);
  const [isApprovalDisabled, setIsApprovalDisabled] = useState<boolean>(true);
  const [isRejectDisabled, setIsRejectDisabled] = useState<boolean>(true);
  const [isRedoDisabled, setIsRedoDisabled] = useState<boolean>(true);

  useEffect(() => {
    let budgetAmount = loanDetails.bridgeLoanEconomics.payload.budgetAmount;
    let financedBudgetAmount =
      loanDetails.bridgeLoanEconomics.payload.financedBudgetAmount;
    if (!(budgetAmount && financedBudgetAmount)) return;
    let FinancedPercent =
      (convertUSDToNumber(financedBudgetAmount) /
        convertUSDToNumber(budgetAmount)) *
      100;
    let budgetButtonInfo = {
      rehabBudget: budgetAmount,
      financedBudgetAmount,
      financedBudgetPercentage: FinancedPercent
    };
    dispatch(updateBudgetButtonInfo(budgetButtonInfo));
  }, [loanDetails, dispatch]);

  async function getCommentsDataAndBudgetDataV2(
    selectedBudgetDocId: number,
    selectedBudgetData: any
  ) {
    try {
      dispatch(showLoader());
      let resp = await getCommentsList(`${selectedBudgetDocId}`);
      dispatch(hideLoader());
      dispatch(
        getBudgetReviewDataV2(
          resp,
          selectedBudgetData.budgetLineItemList,
          budgetButtonInfo.financedBudgetPercentage,
          propertyDetails,
          budgetExtractionGetResponse,
          budgetChatsData,
          selectedBudgetData.isRedoInProcess ? getRedoValidationCB : undefined
        )
      );
    } catch (e) {
      console.error(e);
      dispatch(hideLoader());
    }
  }
  useEffect(() => {
    if (Object.keys(irQCVMAdminMap).length) return;
    getListOfAssignee([
      InternalRolesEnum.LAT_IR,
      InternalRolesEnum.LAT_QC,
      InternalRolesEnum.LAT_VM,
      InternalRolesEnum.LAT_ADMIN,
      InternalRolesEnum.LAT_APPROVER,
      InternalRolesEnum.LAT_INQUIRY
    ]).then((res: any) => {
      dispatch(setILPUserList(res.data));
    });
  }, [irQCVMAdminMap, dispatch]);

  useEffect(() => {
    let history = [...budgetApprovalHistory, ...budgetStatusHistory];
    if (!history.length) return;
    async function fetchDetails() {
      let uniqueUser = [...new Set(history.map((item) => item.assignedBy))];
      uniqueUser = uniqueUser.filter((item) => item !== "None");
      const data = await getUserDetails({ partyIds: uniqueUser });
      let userData: any = {};
      data.forEach((item: any) => {
        userData = {
          ...userData,
          ...{
            [item.partyId]: {
              firstName: item.firstName,
              lastName: item.lastName
            }
          }
        };
      });
      setUserIdMapping(userData);
    }
    fetchDetails();
  }, [budgetStatusHistory, budgetApprovalHistory]);

  useEffect(() => {
    if (!propertyDetails.length) return;
    const { street_line, state, city, zipcode } =
      propertyDetails[0].propertyLocation.payload.address;
    const selectedPropInfo = {
      addressLine1: street_line,
      state,
      city,
      postalCode: zipcode
    };
    dispatch(
      updateSelectedPropDetails({
        selectedPropInfo,
        selectedPropId: propertyDetails[0].loanPropertyOrder
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyDetails]);

  useEffect(() => {
    if (!loanId) return;
    let reqBody = {
      resourceIds: [loanId],
      resourceTypes: ["LOAN"],
      taskNames: [
        taskNames.TAG,
        taskNames.SPLIT,
        taskNames.REVIEW,
        taskNames.RECONCILE,
        taskNames.BUDGET_REVIEW,
        taskNames.BUDGET_APPROVE
      ]
    };
    dispatch(getBudgetHistory(reqBody, loanId));
  }, [loanId, dispatch]);

  useEffect(() => {
    //updatePropertyDetails
    if (budgetDocs.length === 0) return;
    let tempOptions = [];
    if (
      budgetStatusHistory.length &&
      isLoginPartyId(budgetStatusHistory[0].assigneePartyId) &&
      [
        budgetReviewStatusEnum.InProgress,
        budgetReviewStatusEnum.ReadyForBudgetReview
      ].includes(budgetReviewHeader.budgetReviewStatus)
    ) {
      tempOptions = budgetDocs;
    }
    if (!budgetReviewHeader?.selectedPropId) return;
    const selectDocs = budgetDocs.find((pr: any) =>
      pr.assets.find(
        // eslint-disable-next-line
        (it: any) => it.assetExternalId == budgetReviewHeader?.selectedPropId
      )
    );
    dispatch(setSelectedBudgetDoc(selectDocs));
    // eslint-disable-next-line
  }, [budgetDocs, budgetApprovalHistory, dispatch]);

  useEffect(() => {
    if (!budgetDocsSelected) return;
    let pageSource = sessionStorage.getItem("budgetReviewSource");
    if (!pageSource) return;
    if (pageSource === "ILP Page") {
      return loanDetails.loanState === LoanStatusEnum.Purchased
        ? setIsRedoDisabled(true)
        : setIsRedoDisabled(false);
    }
    if (pageSource === "PLD Page") {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const isRedoDisabled = params.get("isRedoDisabled") === "true";
      return setIsRedoDisabled(isRedoDisabled);
    }
    //eslint-disable-next-line
  }, [budgetDocsSelected]);

  useEffect(() => {
    if (!Object.keys(irQCVMAdminMap).length) return;
    let tempReviewer =
      (budgetStatusHistory.length &&
        irQCVMAdminMap[budgetStatusHistory[0].assigneePartyId]) ||
      "--";
    let tempApprover =
      (budgetApprovalHistory.length &&
        irQCVMAdminMap[budgetApprovalHistory[0].assigneePartyId]) ||
      "--";
    setReviewerApprover({
      reviewerName: tempReviewer,
      approverName: tempApprover
    });
    // setApproverName(tempApprover);
  }, [irQCVMAdminMap, budgetStatusHistory, budgetApprovalHistory]);

  useEffect(() => {
    if (!loanDetails) return;
    const BorrowerData = loanDetails.bridgeLoanBorrowerInformation;
    const BorrowerNameList = BorrowerData.filter(
      (item: { payload: { borrowingEntityName: any; lastName: any } }) => {
        const { borrowingEntityName } = item.payload;
        return borrowingEntityName; // Filter out undefined and empty string values for borrowingEntityName
      }
    ).map((item: { payload: { borrowingEntityName: any; lastName: any } }) => {
      const { borrowingEntityName, lastName } = item.payload;
      return `${borrowingEntityName} ${lastName || ""}`;
    });
    loanDetails.originatorInfo &&
      setBorrowerOriginator({
        borrowersNames: BorrowerNameList.join(","),
        originatorName: loanDetails.originatorInfo?.accountName
      });
    // setBorrowersNames(BorrowerNameList.join(","));
    // loanDetails.originatorInfo &&
    //   setOriginatorName(loanDetails.originatorInfo.accountName);
  }, [loanDetails]);

  useEffect(() => {
    if (!(loanId && loanStage)) return;
    getDataByLoanID(loanId, loanStage, dispatch);
    getPropDataByLoanID(loanId, loanStage, loanType, dispatch);
    // dispatch(getBulkBudgetReviewDatav2(loanId, ()=>{}));
    // eslint-disable-next-line
  }, [loanId, loanStage]);

  useEffect(() => {
    //updatePropertyDetails
    if (budgetDocs.length === 0) return;
    // let tempOptions = [];
    // if (
    //   budgetStatusHistory.length &&
    //   // isLoginPartyId(budgetStatusHistory[0].assigneePartyId) &&
    //   [
    //     budgetReviewStatusEnum.InProgress,
    //     budgetReviewStatusEnum.ReadyForBudgetReview,
    //     budgetReviewStatusEnum.ReadyForBudgetApproval
    //   ].includes(budgetReviewHeader.budgetReviewStatus)
    // ) {
    // }
    // tempOptions = budgetDocs;
    setbudgetDocsOption(budgetDocs);
    if (!budgetReviewHeader?.selectedPropId) return;

    const selectDocs = budgetDocs.find((pr: any) =>
      pr.assets.find(
        // eslint-disable-next-line
        (it: any) => it.assetExternalId == budgetReviewHeader?.selectedPropId
      )
    );
    setBudgetDocsSelected(selectDocs); //default value
    dispatch(setSelectedBudgetDoc(selectDocs));
    // eslint-disable-next-line
  }, [budgetDocs, budgetApprovalHistory, dispatch, isLineItemsEditDisable]);

  useEffect(() => {
    if (!selectedBudgetDoc || !budgetDocsSelected) return;
    if (budgetDocsSelected.id === selectedBudgetDoc.id) return;
    setBudgetDocsSelected(selectedBudgetDoc);
    dispatch(setSelectedBudgetDoc(selectedBudgetDoc));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBudgetDoc, budgetDocsSelected]);

  const getBudgetDocs = async () => {
    try {
      const [prePurchaseList, postCloseList] = (await Promise.all([
        getDocuments(
          loanId,
          `${loanStage}-prePurchase`,
          `${loanStage}-prePurchase`,
          loanState,
          true
        ) as any,
        getDocuments(
          loanId,
          `${loanStage}-postClose`,
          `${loanStage}-postClose`,
          loanState
        )
      ])) as any;

      const budgetDocs = filterObjectsByTag([
        ...postCloseList.response.data,
        ...prePurchaseList.response.data
      ]);
      if (budgetDocs.length) {
        const tempBudgetFilesList = budgetDocs.map(
          (fileData: { name: string; id: string }) => {
            const fileType = fileData.name.split(".").pop()?.toLowerCase();
            return {
              ...fileData,
              fileType,
              ...{
                title: fileData.name
              }
            };
          }
        );
        setbudgetDocs(tempBudgetFilesList);
        // const selectDoc = tempBudgetFilesList.find((pr: any) =>
        //   pr.assets.find(
        //     // eslint-disable-next-line
        //     (it: any) =>
        //       (it.assetName === "PROPERTY" &&
        //         it.assetExternalId == budgetReviewHeader?.selectedPropId) ||
        //       propertyDetails[0].loanPropertyOrder
        //   )
        // );
        // setBudgetDocsSelected(selectDoc); //default value
        // console.log(selectDoc?.id, "4");

        // dispatch(setSelectedBudgetDoc(selectDoc));
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!selectedBudgetDoc?.id || extractiotnStatus === "Data Loading") return;
    if (ExtractionInProgressState?.includes(extractiotnStatus)) return;
    dispatch(
      getBudgetData(
        loanId,
        selectedBudgetDoc.id,
        getCommentsDataAndBudgetDataV2
      )
    );
  }, [loanId, selectedBudgetDoc, extractiotnStatus]);

  async function handleItemDelete(payload: any[]) {
    try {
      dispatch(showLoader());
      const resp = await deleteLineItems(payload, loanId);
      dispatch(hideLoader());
      return Promise.resolve(resp);
    } catch (err) {
      console.error(err);
      dispatch(hideLoader());
      return Promise.reject(err);
    }
  }

  // useEffect(() => {
  //   if (!budgetStatusHistory.length) return;
  //   const isApproval =
  //     budgetApprovalHistory.length > 0 &&
  //     isLoginPartyId(budgetApprovalHistory[0].assigneePartyId);
  //   setIsApprovalView(isApproval);
  //   const isBudgetReadyForApproval =
  //     budgetReviewStatusEnum.ReadyForBudgetApproval ===
  //     budgetReviewHeader.budgetReviewStatus;

  //   const isAssigneeLoggedIn = isLoginPartyId(
  //     budgetStatusHistory[0].assigneePartyId
  //   );
  //   const propertyObj = budgetPropData?.find(
  //     (item) => item.externalDocumentId === selectedBudgetDoc?.id
  //   );

  //   const isBudgetApproverOrInProcess: boolean = BudgetApproverStatus.includes(
  //     budgetReviewHeader.budgetReviewStatus
  //   )
  //     ? propertyObj?.isRedoInProcess
  //     : false;

  //   const isDisabledTemp = isApproval
  //     ? !isBudgetReadyForApproval
  //     : isAssigneeLoggedIn
  //     ? isBudgetApproverOrInProcess
  //     : true;

  //   setIsFieldsDisabled(isDisabledTemp);
  //   dispatch(updateLineItemDisable(isDisabledTemp));
  // }, [
  //   dispatch,
  //   budgetReviewResponse,
  //   budgetApprovalHistory,
  //   budgetStatusHistory,
  //   budgetPropData,
  //   budgetReviewHeader.budgetReviewStatus
  // ]);

  /**
   * showReadyForReview: [
            ReadyForBudgetReview,
            InProgress,
            LoanSubmitted
          ].includes(budgetReviewHeader.budgetReviewStatus),
          isReadyForReviewDisabled: isDisabled,
          isApproveDisabled: isApprovalDisabled,
          isRejectDisabled,
          showApproveBtn: showApproveBttn,
          isFieldsDisabled,
          isApprovalView
   */

  useEffect(() => {
    const isApprover =
      budgetApprovalHistory?.length > 0 &&
      isLoginPartyId(budgetApprovalHistory[0].assigneePartyId);
    const isReviewer =
      budgetStatusHistory?.length > 0 &&
      isLoginPartyId(budgetStatusHistory[0].assigneePartyId);
    let pageSource = sessionStorage.getItem("budgetReviewSource");
    if (
      pageSource &&
      pageSource === "PLD Page" &&
      !isRole(DrawRolesEnum.DRAW_MANAGER)
    ) {
      //only AM_DRAW_MANAGER role can edit budget review, if coming from PLD
      setIsApprovalDisabled(true);
      setIsRejectDisabled(true);
      setIsFieldsDisabled(true);
      setShowReadyForReview(false);
      setIsApprovalView(false);
      return;
    }
    if (
      editableStatuses?.includes(budgetReviewHeader.budgetReviewStatus) &&
      isReviewer
    ) {
      setShowReadyForReview(true);
      setIsApprovalDisabled(false);
      setIsRejectDisabled(true);
      setShowApproveBttn(false);
      setIsFieldsDisabled(false);
      setIsApprovalView(false);
    } else if (
      approvalStatuses?.includes(budgetReviewHeader.budgetReviewStatus) &&
      isApprover
    ) {
      setShowReadyForReview(false);
      setIsApprovalDisabled(false);
      setIsRejectDisabled(false);
      setShowApproveBttn(true);
      setIsFieldsDisabled(false);
      setIsApprovalView(true);
    } else if (
      disableStatuses?.includes(budgetReviewHeader.budgetReviewStatus) &&
      isApprover
    ) {
      setShowReadyForReview(false);
      setIsApprovalDisabled(true);
      setIsRejectDisabled(true);
      setShowApproveBttn(true);
      setIsFieldsDisabled(true);
      setIsApprovalView(true);
    } else {
      if (isReviewer) {
        setShowReadyForReview(true);
        setShowApproveBttn(false);
      } else if (isApprover) {
        setShowApproveBttn(true);
        setShowReadyForReview(false);
      } else {
        setShowReadyForReview(false);
        setShowApproveBttn(false);
      }
      setIsApprovalDisabled(true);
      setIsRejectDisabled(true);
      setIsFieldsDisabled(true);
      setIsApprovalView(false);
    }
  }, [
    dispatch,
    budgetReviewResponse,
    budgetApprovalHistory,
    budgetStatusHistory,
    budgetPropData,
    budgetReviewHeader.budgetReviewStatus
  ]);

  const [showApproveBttn, setShowApproveBttn] = useState<boolean>(false);
  const {
    Approved,
    Rejected,
    InProgress,
    LoanSubmitted,
    ReadyForBudgetApproval,
    ReadyForBudgetReview
  } = budgetReviewStatusEnum;

  useEffect(() => {
    if (
      budgetApprovalHistory.length &&
      isLoginPartyId(budgetApprovalHistory[0].assigneePartyId)
    ) {
      setShowApproveBttn(true);
    } else setShowApproveBttn(false);
  }, [budgetApprovalHistory, budgetReviewHeader.budgetReviewStatus]);

  useEffect(() => {
    const isFirstApprovalAssigneePartyId = budgetApprovalHistory.length
      ? isLoginPartyId(budgetApprovalHistory[0].assigneePartyId)
      : false;
    setIsRejectDisabled(
      isFirstApprovalAssigneePartyId
        ? ![ReadyForBudgetApproval].includes(
            budgetReviewHeader.budgetReviewStatus
          )
        : true
    );

    // eslint-disable-next-line
  }, [budgetReviewHeader.budgetReviewStatus, budgetApprovalHistory]);

  useEffect(
    () => {
      const isFirstAssigneePartyId = budgetStatusHistory.length
        ? isLoginPartyId(budgetStatusHistory[0].assigneePartyId) &&
          [ReadyForBudgetReview, InProgress].includes(
            budgetReviewHeader.budgetReviewStatus
          )
        : false;
      setIsDisabled(!isFirstAssigneePartyId);

      const isFirstApprovalAssigneePartyId = budgetApprovalHistory.length
        ? isLoginPartyId(budgetApprovalHistory[0].assigneePartyId)
        : false;
      setIsApprovalDisabled(
        isFirstApprovalAssigneePartyId
          ? ![ReadyForBudgetApproval].includes(
              budgetReviewHeader.budgetReviewStatus
            )
          : true
      );
    },
    // eslint-disable-next-line
    [
      BulkBudgetSum,
      budgetReviewHeader.budgetReviewStatus,
      showApproveBttn,
      budgetStatusHistory,
      budgetApprovalHistory
    ]
  );

  const updateWithEmptyLineItems = useCallback(
    (response?: any) => {
      // function cb(resp: any) {
      //   let formattedLineItems = JSON.parse(
      //     JSON.stringify([...resp.extractedInfoProcessed.lineItems])
      //   );
      //   for (const item of formattedLineItems) {
      //     item.budget = `$${item.budget}.00`;
      //   }
      //   setFieldsData(formattedLineItems);
      //   // setIsManualEntry(true);
      // }
      const newLineItems = addEmptyLineItems({ val: 10 }, [], true);
      updatePredictionStatus(
        response ? response : budgetReviewResponse,
        response ? response.processState : budgetReviewResponse.processState,
        newLineItems,
        dispatch
        // cb
      );
    },
    [budgetReviewResponse, dispatch]
  );

  const onPollSuccess = (response: any, isManualEntry: boolean) => {
    setExtractionStatus(response[0]?.processState);
    setBudgetExtractionGetResponse(response[0]);
    if (
      ExtractionInProgressState.includes(response[0]?.processState) &&
      isManualEntry
    ) {
      stopPolling();
    }
    if (ExtractionInProgressState?.includes(response[0]?.processState)) {
      return true; // Continue polling
    }
    //when status moved to prediction completed
    stopPolling();
    if (isManualEntry) {
      updateWithEmptyLineItems();
    } else {
      getBudgetDocs();
    }
    // if (
    //   response.budgetDocumentInfoList &&
    //   response.budgetDocumentInfoList[0]?.budgetLineItems?.length
    // ) {
    //   // updateLineReduxData(
    //   //   response,
    //   //   dispatch,
    //   //   budgetButtonInfo.financedBudgetPercentage,
    //   //   // commentsList,
    //   //   true
    //   // );
    //   // dispatch(setBudgetGetResponse(response));
    // } else {
    //   updateWithEmptyLineItems();
    // }
  };

  const onPollTimeExceed = () => {
    console.error("Polling time exceed");
  };

  const startPollingExcecution = (isManualEntry: boolean) => {
    const url = `${
      getConfig().apiUrl
    }/ocr/extraction/${loanId}/documents?tags=REBD,SCOW`;
    startPolling({
      url,
      method: "GET",
      interval: 3000,
      onSuccess: (res) => {
        return onPollSuccess(res.data, isManualEntry);
      },
      onExceedingPollingWaitTime: () => {
        onPollTimeExceed();
      },
      pollingWaitTime: 300000 // allow 5 minutes polling
    });
  };

  useEffect(() => {
    if (!loanId) return;
    if (!propertyDetails.length) return;
    startPollingExcecution(false);
  }, [loanId, propertyDetails]);

  function handlePropTypeClickCB(
    title: any,
    fieldsData: any,
    lineItemObj: any
  ) {
    let payload = {
      budgetDocumentInfoId: lineItemObj.budgetDocumentInfoId,
      selectionMode: lineItemObj.selectionMode.toUpperCase()
    };
    updateSelectionMode(lineItemObj.DocumentId, payload);
  }

  function handleCommonTaskUpdate(
    tasks: any[],
    budgetStatusHistory: any[],
    budgetApprovalHistory: any[],
    header: string,
    showCommentPopup: any,
    isFirstApprovalAssigneePartyId?: boolean
  ) {
    const userId = getCookie("person_id") || "";
    const updatedTasks = JSON.parse(JSON.stringify(tasks || []));

    const reviewAssignedBy = getAssignedBy(
      updatedTasks,
      taskNames.BUDGET_REVIEW,
      userId
    );
    const approveAssignedBy = getAssignedBy(
      updatedTasks,
      taskNames.BUDGET_APPROVE,
      userId
    );

    let assignedReviewTasksObj = buildTaskObject(
      "budgetReview",
      reviewAssignedBy,
      budgetStatusHistory[0].assigneePartyId,
      header === "Budget Review Comment" || isFirstApprovalAssigneePartyId
        ? ReadyForBudgetApproval
        : header === "Redo Budget Review"
        ? InProgress
        : Approved,
      budgetStatusHistory[0].taskId,
      showCommentPopup.comment
    );

    let assignedApproveTasksObj = buildTaskObject(
      "budgetApprove",
      approveAssignedBy,
      budgetApprovalHistory[0].assigneePartyId,
      header === "Budget Review Comment"
        ? budgetApproveStatusEnum.ReadyForBudgetApproval
        : header === "Redo Budget Review"
        ? budgetApproveStatusEnum.Pending
        : budgetApproveStatusEnum.Approved,
      budgetApprovalHistory[0].taskId,
      showCommentPopup.comment
    );
    if (header === "Rejection Comment") {
      assignedReviewTasksObj = {
        ...assignedReviewTasksObj,
        ...{ taskStatus: Rejected }
      };
      assignedApproveTasksObj = {
        ...assignedApproveTasksObj,
        ...{ taskStatus: budgetApproveStatusEnum.Rejected }
      };
    }
    return {
      assignedReviewTasksObj,
      assignedApproveTasksObj
    };
  }
  async function handleStatusUpdate(header: string, showCommentPopup: any) {
    const { assignedReviewTasksObj, assignedApproveTasksObj } =
      handleCommonTaskUpdate(
        tasks,
        budgetStatusHistory,
        budgetApprovalHistory,
        header,
        showCommentPopup
      );

    let propertyData = loanDetails.propertyDetails?.map((prop: any) => {
      return {
        propertyId: prop.propertyId,
        zipcode: prop.propertyLocation?.payload?.zipCode,
        addressLine1: prop.propertyLocation?.payload?.address?.street_line,
        ...prop.propertyLocation?.payload
      };
    });
    const payload = {
      budgetApprovalStatus: assignedReviewTasksObj.taskStatus,
      approvedBy: assignedApproveTasksObj.assignedBy,
      reviewedBy: assignedReviewTasksObj.assignedBy,
      propertyData,
      primaryLoanId: loanDetails?.bridgeLoanInformation?.payload?.primaryLoanID
    };
    try {
      await updateBudgetStatusV2(loanId, payload, dispatch);

      dispatch(
        updateStatusOnTask(
          loanId,
          [assignedReviewTasksObj, assignedApproveTasksObj],
          budgetStatusHistory,
          budgetApprovalHistory
        )
      );
      return Promise.resolve();
    } catch (e) {
      return Promise.reject();
    }
  }

  async function addExistingCommentsAndRedoValidation(
    lineObject: any[],
    isRedoValidationReq: boolean,
    newBudgetChatsData: any,
    newPropertyId?: string
  ) {
    let lineItemsWithoutChats: any[] = [];
    let updatedWithComments: any[] = [];
    lineObject.forEach((item) => {
      if (item.commentThreadId && newBudgetChatsData[item.commentThreadId]) {
        updatedWithComments.push({
          ...item,
          comments: newBudgetChatsData[item.commentThreadId]
        });
      } else if (
        item.commentThreadId &&
        !newBudgetChatsData[item.commentThreadId]
      ) {
        lineItemsWithoutChats.push(item);
      } else {
        updatedWithComments.push(item);
      }
      // return item;
    });
    if (lineItemsWithoutChats?.length) {
      const missingComments = await mergeChatDetails(
        lineItemsWithoutChats,
        newBudgetChatsData,
        dispatch
      );
      updatedWithComments = [...missingComments, ...updatedWithComments];
    }
    if (isRedoValidationReq) {
      getRedoValidationCB(updatedWithComments, undefined, newPropertyId);
    } else {
      dispatch(updateLineItemList(updatedWithComments));
    }
  }

  async function getPreviousVersionDataCB(
    version: string,
    isLatestVersion: boolean,
    budgetDocSelected: any
  ) {
    const lineObject = await getPreviousVersionData(
      budgetDocSelected.id,
      version,
      budgetButtonInfo.financedBudgetPercentage,
      dispatch
    );
    addExistingCommentsAndRedoValidation(
      lineObject,
      isLatestVersion,
      budgetChatsData
    );
  }

  // handleRedoBudgetCB using the helper
  async function handleRedoBudgetCB(
    isFirstApprovalAssigneePartyId: boolean,
    showCommentPopup: any,
    budgetDocSelected: any
  ) {
    const { assignedReviewTasksObj, assignedApproveTasksObj } =
      handleCommonTaskUpdate(
        tasks,
        budgetStatusHistory,
        budgetApprovalHistory,
        showCommentPopup.headerText,
        showCommentPopup,
        isFirstApprovalAssigneePartyId
      );
    try {
      if (!budgetDocSelected?.id) {
        console.error("Budget Doc id not found..", {
          budgetDocSelected,
          budgetDocsSelected
        });
        return Promise.reject("Budget Doc id not found..");
      }
      let selectedPropId = "";
      let { lineItemsReq } = lineItemResponse(lineItemsList);
      budgetDocSelected?.assets &&
        budgetDocSelected?.assets.filter((item: any) => {
          if (item.assetName === "PROPERTY") {
            selectedPropId = item.assetExternalId;
          }
        });
      let payload = {
        propertyIds: showCommentPopup.extraInfo,
        budgetLineItemMap: { [budgetDocSelected.id]: lineItemsReq }
      };
      await redoBudgetReview(
        loanId,
        payload,
        selectedPropId,
        dispatch,
        getRedoValidationCB
      );
      dispatch(
        updateStatusOnTask(
          loanId,
          [assignedReviewTasksObj, assignedApproveTasksObj],
          budgetStatusHistory,
          budgetApprovalHistory
        )
      );
      return Promise.resolve();
    } catch (e) {
      console.error(e);
      return Promise.reject();
    }
  }

  const getRedoValidationCB = async (
    updatedLineItems: any[],
    propertyViewData?: any,
    newPropertyId?: string
  ) => {
    let redoItems: any[] = [];
    if (!updatedLineItems?.length) {
      selectedBudgetDoc &&
        dispatch(
          getBudgetData(
            loanId,
            selectedBudgetDoc.id,
            getCommentsDataAndBudgetDataV2
          )
        );
      return;
    }
    if (propertyViewData) {
      //update available versions dropdown
      let selectedBudgetPropData = {
        ...budgetPropData.find(
          (data: any) => data.externalDocumentId == budgetDocsSelected?.id
        )
      };
      const availableVersions = [
        ...(selectedBudgetPropData?.availableVersions || []),
        propertyViewData.version.toString()
      ];
      propertyViewData = {
        ...propertyViewData,
        ...{
          availableVersions
        }
      };
      let newBudgetPropData = budgetPropData.map((obj: any) =>
        obj.externalDocumentId == budgetDocsSelected?.id
          ? propertyViewData
          : obj
      );
      dispatch(updateBudgetPropData(newBudgetPropData));
    }
    let selectedPropId = "";
    if (newPropertyId) {
      selectedPropId = newPropertyId;
    } else {
      selectedBudgetDoc?.assets &&
        selectedBudgetDoc?.assets.filter((item: any) => {
          if (item.assetName === "PROPERTY") {
            selectedPropId = item.assetExternalId;
          }
        });
    }
    if (!selectedPropId) return;
    if (redoValidationItems[selectedPropId]) {
      redoItems = redoValidationItems[selectedPropId];
    } else {
      redoItems = await getRedoValidationItems(
        loanId,
        selectedPropId,
        dispatch
      );
    }
    // if (redoItems.length === 0) {
    //   dispatch(updateLineItemList(updatedLineItems));
    // } else {
    const formatttedData = updatedLineItems?.map((item) => {
      const prevDrawData = redoItems.filter(
        (temp: any) => temp.item === item.item
      )[0];
      const tempItem = {
        ...item,
        isDeletionAllowed: !(
          prevDrawData?.latestCompletion == 0 ||
          prevDrawData?.latestCompletion === null
        ),
        minBudgetAllowed:
          (prevDrawData?.financedBudget * prevDrawData?.latestCompletion) / 100
      };
      return tempItem;
    });
    dispatch(updateLineItemList(formatttedData));
    // }
  };

  async function createAndUpdateCommentsCB(
    comments: any[],
    newLineItemsList: any[],
    docsId: string,
    comment: string,
    row: any,
    cb: any
  ) {
    const { newList, newChatData } = await handleAddCommentProceed(
      newLineItemsList,
      docsId,
      comment,
      row,
      comments
    );
    let newbudgetChatsData = { ...budgetChatsData, ...newChatData };
    dispatch(updateBudgetChatsData(newbudgetChatsData));
    !row.commentThreadId && dispatch(updateBudgetLineItems(newList, docsId));

    dispatch(updateLineItemList(newList));
    let newBudgetPropData = budgetPropData.map((obj: any) =>
      obj.externalDocumentId == docsId
        ? {
            ...obj,
            ...{
              budgetLineItemList: newList
            }
          }
        : obj
    );
    dispatch(updateBudgetPropData(newBudgetPropData));
    cb && cb();
  }

  async function handleLineItemOnBlur(
    fieldsData: any,
    cb: any,
    details: any,
    totalCalculatedData: any,
    lineItemObj: any,
    docId: string
    // budgetTotalInfo: BudgetTotalInfo,
  ) {
    // if (!isPropertyMapped) {
    //   console.error("Property not mapped properly");
    //   setPopUpOpen(true);
    //   return;
    // }
    let lineItems = fieldsData;
    // let tempBudgetInfo = budgetTotalInfo
    //   ? budgetTotalInfo
    //   : {
    //       rehabBudget: sanitizeCurrency(`${totalBudget}`),
    //       financedBudgetAmount: sanitizeCurrency(`${totalfinancedBudget}`)
    //     };
    // if (updatedData) {
    //   lineItems = updatedData;
    // }
    const userId = getCookie("person_id") || "";

    if (
      [
        budgetReviewStatusEnum.LoanSubmitted,
        budgetReviewStatusEnum.ReadyForBudgetReview
      ].includes(budgetReviewHeader.budgetReviewStatus)
    ) {
      //update status to in progress
      const updatedTasks: any = JSON.parse(JSON.stringify(tasks));
      let assignedBy = updatedTasks?.[0]?.assignedTasks.find((task: any) =>
        [taskNames.BUDGET_REVIEW].includes(task.taskName)
      )?.assignedBy;
      if (!assignedBy || ["UnAssigned", "None"].includes(assignedBy)) {
        assignedBy = userId;
      }
      let assignedTasksObj = [
        {
          op: "replace",
          taskName: "budgetReview",
          assignedBy,
          assigneePartyId: budgetStatusHistory[0].assigneePartyId,
          taskStatus: budgetReviewStatusEnum.InProgress,
          taskId: budgetStatusHistory[0].taskId,
          comment: "Status Updated by System"
        }
      ];
      // await updateStatusOnDocument(
      //   loanId,
      //   budgetReviewStatusEnum.InProgress,
      //   dispatch
      // );
      const payload = {
        budgetApprovalStatus: budgetReviewStatusEnum.InProgress
      };
      await updateBudgetStatusV2(loanId, payload, dispatch);
      dispatch(
        updateStatusOnTask(loanId, assignedTasksObj, budgetStatusHistory)
      );
    }
    // const cb = () =>
    //   setShowToast({ isShown: true, message: "Saved Successfully" });
    // dispatch(
    //   updateBudgetReview(
    //     lineItems,
    //     budgetReviewResponse,
    //     tempBudgetInfo,
    //     budgetReviewResponse?.status ?? "In-Progress",
    //     budgetReviewHeader,
    //     budgetButtonInfo.financedBudgetPercentage,
    //     cb
    //   )
    // );
    // }
    dispatch(updateBudgetLineItems(lineItems, docId, true, cb));
  }
  return (
    <BudgetReviewWrapperSDK
      hideOldBudget={true}
      budgetData={{
        id: loanId,
        budgetDocs,
        handlePropTypeClickCB,
        budgetChatsData,
        userIdMapping,
        reviewerApprover,
        borrowerOriginator,
        loanDetails,
        budgetReviewHeader,
        BulkBudgetSum,
        budgetButtonInfo,
        budgetApprovalHistory,
        budgetStatusHistory,
        lineItemsList,
        selectedBudgetDoc,
        reviewHistory: [...budgetStatusHistory],
        budgetDocumentInfoList: budgetPropData
          ? updateFieldName(budgetPropData)
          : [],
        budgetReviewResponse: {
          processState: extractiotnStatus
        },
        actionButtonAccess: {
          showReadyForReview,
          isReadyForReviewDisabled: isDisabled,
          isApproveDisabled: isApprovalDisabled,
          isRejectDisabled,
          isRedoDisabled,
          showApproveBtn: showApproveBttn,
          isFieldsDisabled,
          isApprovalView
        },
        budgetDocsOption
      }}
      DocumentViewer={{
        DocumentViewerCompo: DocumentViewer,
        budgetDocsSelected,
        DocumentViewerCB: {
          documentReviewDispatch: () => {},
          onChangeHighlightSelect: () => {}
        }
      }}
      budgetCB={{
        isLoginPartyId,
        handleItemDelete,
        createAndUpdateCommentsCB,
        handleRedoBudgetCB,
        getPreviousVersionDataCB,
        addExistingCommentsAndRedoValidationCB:
          addExistingCommentsAndRedoValidation,
        handleStatusChangeCB: handleStatusUpdate,
        handleLineItemOnBlurCB: handleLineItemOnBlur,
        startPollingCB: startPollingExcecution
      }}
      budgetConstants={{
        budgetReviewStatusConst,
        BudgetApproverStatus,
        ExtractionInProgressState,
        ExtractionProcessState,
        budgetReviewStatusEnum
      }}
      // configList={{
      //   configItems: ConfigItems
      // }}
      budgetModals={{ ConfirmFormModal }}
    />
  );
};
