/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable consistent-return */
/* eslint-disable prefer-const */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
// import { produce } from "immer";
import {
  LoanType,
  LoanList,
  PropertyInformation,
  PropertyLocation,
  BridgeLoanLoanInformation,
  BridgeLoanBorrowerInformation as BridgeBorrowerInformation,
  BridgeLoanLoanEconomics,
  BridgeLoanGuarantorInformation,
  ThirtyYearLoanInformation as DSCRLoanInformation,
  ThirtyYearBorrowerInformation,
  ThirtyYearGuarantorInformation,
  ThirtyYearLoanEconomics,
  ThirtyYearLoanPropertyLocation,
  ThirtyYearLoanPropertyInformation,
  ThirtyYearLoanPropertyEconomics,
  LoanStage
} from "@toorak/tc-common-fe-sdk";

import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
// import {
//   GET_CREDIT_SCORE_BUCKETS_FAILED,
//   GET_CREDIT_SCORE_BUCKETS_LOADING,
//   GET_CREDIT_SCORE_BUCKETS_SUCCESS,
//   GET_APPRAISAL_VENDORS_LOADING,
//   GET_LOAN_PURPOSES_LOADING,
//   GET_LOAN_PURPOSES_FAILED,
//   GET_APPRAISAL_VENDORS_FAILED,
//   GET_LOAN_PURPOSES_SUCCESS,
//   GET_APPRAISAL_VENDORS_SUCCESS,
//   SAVE_LOAN_DETAILS_FORM,
//   VALIDATE_FORM,
//   MISSING_EDIT_COMMENTS,
//   FETCH_PARTYID,
//   POST_LOAN_LOADING,
//   POST_LOAN_SUCCESS,
//   POST_PROPERTY_SUCCESS,
//   CONDO_QUEST_OPENED,
//   POST_LOAN_FAILED,
//   LOAN_TYPE_LOADING,
//   LOAN_TYPE_FAILED,
//   LOAN_TYPE_SUCCESS,
//   LOAN_LIST_SUCCESS,
//   LOAN_LIST_COUNT,
//   SORT_LOAN_LIST_SUCCESS,
//   DROPDOWN_DATA_SUCCESS,
//   FILTER_DATA_SUCCESS,
//   FILTERS_UPDATE_SUCCESS,
//   HEADER_FILTERS_UPDATE_SUCCESS,
//   LOAN_LIST_LOADING,
//   LOAN_LIST_FAILED,
//   UPDATE_PROPERTY_DETAILS,
//   UPDATE_LOAN_STATUS,
//   UPDATE_ILP_STATE,
//   UPDATE_TTF_STATE,
//   GET_PROPERTY_DETAILS,
//   UPDATE_BRIDGE_LOAN_GUARANTOR,
//   SET_ACTIVE_PROPERTY_ID,
//   SET_ACTIVE_EDIT_LOAN_SECTION,
//   DELETE_PROPERTY_DETAILS,
//   DELETE_BRIDGE_LOAN_GUARANTOR,
//   UPDATE_BRIDGE_LOAN_DETAILS,
//   UPDATE_PRIMARY_GUARANTOR,
//   UPDATE_THIRTY_YEAR_LOAN_PRIMARY_GUARANTOR,
//   TAGS_LOADING,
//   TAGS_FAILED,
//   FETCH_EDIT_HISTORY,
//   TAGS_SUCCESS,
//   DASHBOARD_TAGS_SUCCESS,
//   VALUE_BEING_EDITED,
//   UPDATE_30_YEARS_LOAN_DETAILS,
//   RESET_LOAN_DETAILS,
//   GET_LOAN_BY_ID_LOADING,
//   GET_LOAN_BY_ID_SUCCESS,
//   SET_LOAN_PREVIOUS_STATE,
//   UPDATE_SUMMARY_DETAILS,
//   UPDATE_LOAN_CONFIG,
//   UPDATE_SUMMARY_DETAILS_PROPERTY,
//   ADD_EXCEPTION_GRADE,
//   ADD_INTERNAL_GRADE,
//   GET_LOAN_BY_ID_FAILED,
//   UPDATE_FILES_IN_DOCS,
//   UPDATE_TAGS_IN_DOCS,
//   UPDATE_FAILURE_COUNT_IN_DOCS,
//   UPDATE_SUCCESS_COUNT_IN_DOCS,
//   GET_DOCS_BY_ID_SUCCESS,
//   GET_TERMSHEET_BY_ID_SUCCESS,
//   DOCS_CURRENT_UPLOAD_SUCCEESS,
//   UPDATE_PERCENTAGE_IN_DOCS,
//   GET_PROPERTY_FAILED,
//   GET_PROPERTY_LOADING,
//   VALIDATE_FORM_FOR_SUBMIT,
//   POST_PROPERTY_LOADING,
//   POST_PROPERTY_FAILED,
//   UPDATE_THIRTY_YEAR_LOAN_GUARANTOR,
//   DELETE_THIRTY_YEAR_LOAN_GUARANTOR,
//   DELETE_PROPERTY_UNITS,
//   UPDATE_PROPERTY_UNITS,
//   GURRANTOR_FIELD_CHANGED,
//   BORROWER_FIELD_CHANGED,
//   SET_ACTIVE_TAB_AND_PROPERTY,
//   SET_SELECTED_LOAN_LIST,
//   GET_MANDATORY_RESULTS_LOADING,
//   GET_MANDATORY_DOCUMENTS_LOADING,
//   GET_MANDATORY_RESULTS_SUCCESS,
//   GET_MANDATORY_DOCUMENTS_SUCCESS,
//   GET_MANDATORY_DOCUMENTS_FAILED,
//   GET_MANDATORY_RESULTS_FAILED,
//   UPLOAD_EXCEL_LOAN_CHANGE,
//   RESET_LOAN_STORE,
//   UPDATE_BORROWER_DETAILS,
//   DELETE_BORROWER_DETAILS,
//   UPDATE_PRIMARY_BORROWER,
//   MISSING_BORROWER_OR_GUARANTOR,
//   SET_AUTO_SAVE_CALL,
//   FETCH_INFO_ICON_CONTENT,
//   UPDATE_PREVIOUS_STAGE,
//   UPDATE_IS_WITHDRAWN_STATE,
//   LOAN_USERMAP_SUCCESS,
//   UNIT_FIELD_CHANGED,
//   UPDATE_LOAN_STAGE,
//   UPDATE_TIME_INTERVAL,
//   UPDATE_COMPACT_TAB,
//   UPDATE_LOAN_LIST_TAB_COUNT,
//   UPDATE_LOAN_LIST_VIEW,
//   UPDATE_COMPACT_TAB_INDEX,
//   UPDATE_SELECTED_LOANS,
//   UPDATE_LOAN_LIST_SEARCH,
//   SET_SEARCH_TYPE,
//   UPDATE_LOADING_FLAG,
//   SET_CREDIT_SYNC_FLAG,
//   HIDE_SUMMARY_CHECK_LIST_MODAL,
//   SHOW_SUMMARY_CHECK_LIST_MODAL,
//   SINGLE_LOAN_ROW,
//   SET_RATE_SHEET_DATA,
//   SET_VERSION_DATE,
//   LOAN_TO_DELETE,
//   LOAN_TO_CONVERT,
//   LOAN_TO_CHECK_UPLOAD,
//   SET_RATE_SHEET_VERSIONS,
//   SET_OVERLAYS_VERSIONS,
//   UPDATE_SELECTED_VERSION,
//   UPDATE_LOAN_RULE_VERSIONS,
//   SET_DUE_DILIGENCE_INFO,
//   UPDATE_COMPLETE_DATE,
//   UPDATE_TOGGLE_SELECTIONS,
//   UPDATE_ON_HOLD_INFO,
//   UPDATE_LOAN_LANDMARK,
//   UPDATE_SELECTED_LOAN_RULE_VERSIONS,
//   UPDATE_CONFIDENCE_SCORE,
//   OPEN_COLUMN_REORDER,
//   CUSTOMER_INFO,
//   CUSTOM_COLUMN_LIST,
//   UPDATE_SORT_CONFIG,
//   UPDATE_TTF_SUBMIT,
//   UPDATE_LOAN_DATA,
//   COMPACT_SEARCH_UPDATE,
//   ACTIVE_INTERACTIONS_MODAL,
//   CLOSE_ACTIVE_INTERACTIONS_MODAL,
//   UPDATE_ACTIVE_INTERACTIONS_ARRAY,
//   UPDATE_ACT_INT_LOANLIST,
//   ADD_RESPONSE_TO_ACTIVEINT_ARRAY,
//   ACTIVE_INT_FAILED,
//   DELETE_WAIVER,
//   IS_GROUNDUP_BRIDGE,
//   RESCIND_SUCCESS_DIALOG,
//   DELETE_ACT_INT_ASKQUEST,
//   SET_LOAN_TABLE_FUNDING,
//   SET_PAGE_BLOCK,
//   SET_RAW_LOAN_LIST_DATA,
//   SELECT_ALL_LOANS,
//   SET_TABLE_FUNDING_FLAG,
//   UPDATE_SELECTED_LIST,
//   SET_WAREHOUSE_FUNDING_FLAG,
//   SET_WAREHOUSE_FUNDING,
//   SET_PARTYID,
//   LOAN_LIST_MULTI_DISPATCH
//   // SET_ACTIVE_INTERACTIONS_ACTIONS
// } from "./create-loan.action";
import { uuidV4 } from "../utils/utils";
// import {
//   GET_FES_LOAN_BY_ID_SUCCESS,
//   GET_FES_PROPERTY_BY_ID_SUCCESS,
//   SET_SECTION_PREVIOUS_STATE,
//   SWITCH_LOAN_SIZER_TYPE,
//   UPDATE_FES_LOAN_DETAILS,
//   UPDATE_FES_PROPERTY_DETAILS
// } from "../stores/FrontEndSizer/fesLoanCreation.action";
import { rentDescription } from "./constants/loanCreationDropDownValues";
import { getLoanType } from "../config/config";
import { getCookie } from "../utils/cookies";
import { LoanSizerEnum } from "../frontend-sizer/FrontendSizerTemplate";

// import { isRole, isILP } from "../utils/AccessManagement";

import { ObjectType, UpdatedPropertyEconomics } from "../masterView/common";

export interface ThirtyYearLoanInformation extends DSCRLoanInformation {
  takeOutPartnerId?: string;
  eligibleTakeoutPartners?: string[];
  takeoutPartnerStatus?: string;
  creditEvent?: string;
  rateLockExtensions?: ObjectType[];
  selectedFundingChannel?: string;
  inState?: string;
  rateLockedRateSheet?: string;
}

export interface BridgeLoanBorrowerInformation extends BridgeBorrowerInformation {
  inState?: string;
}

export const emptyLoanDetails = () => ({
  propertyDetails: [],
  documentDetails: {
    docsLoaded: false,
    files: [],
    taggedArray: [],
    failedUploads: 0,
    successfulUploads: 0,
    confidenceScoreMap: {},
    currentFilesUploaded: 0
  },
  prePurchaseDocDetails: {
    files: [],
    taggedArray: [],
    failedUploads: 0,
    successfulUploads: 0,
    currentFilesUploaded: 0
  },
  termSheetDetails: {
    files: [],
    taggedArray: [],
    failedUploads: 0,
    successfulUploads: 0,
    currentFilesUploaded: 0
  }
});

export const emptyPropertyBasedOnLoan = (
  loanType: string,
  loanStage: LoanStage
) => {
  let propertyObj = emptyPropertyDetails();
  switch (loanType) {
    case `${getLoanType[0].displayValue}`:
      if (loanStage === LoanStage.fes) break;
      propertyObj.propertyInformation.payload.percentageOccupied = "0.0000%";
      propertyObj.propertyEconomics.payload.costBasis = "$0.00";
      propertyObj.propertyEconomics.payload.grossPotentialRent = "$0.00";
      propertyObj.propertyEconomics.payload.assignmentFees = "$0.00";
      propertyObj.propertyEconomics.payload.annualPropertyTaxes = "$0.00";
      propertyObj.propertyEconomics.payload.insurance = "$0.00";
      propertyObj.propertyEconomics.payload.appraisalReported = "$0.00";
      break;
    case `${getLoanType[1].displayValue}`:
      break;
    default:
      break;
  }
  return propertyObj;
};

export const getFilteredTags = (tags: any) => {
  if (!tags && !Array.isArray(tags?.tags)) return;
  let filteredTags = tags.tags.filter((ele: any) => ele.tagType === "DOCUMENT");
  filteredTags.forEach((element: any) => {
    if (element.tagCategory === null) {
      element.tagCategory = "Others";
    }
  });
  tags.tags = filteredTags;
  return tags;
};

export const emptyPropertyDetails = () => ({
  propertyLocation: {
    errors: null,
    payload: {
      address: null,
      city: "",
      state: "",
      zipCode: "",
      duplicateLoanIds: [],
      duplicatePropertyInfo: null,
      invalidAddress: false
    }
  },
  propertyInformation: {
    errors: null,
    payload: {
      propertyType: "",
      numberOfUnits: "",
      propertyValuationDate: null,
      propertyAcquisitionDate: null,
      toorvalId: "",
      squareFootage: "",
      changeInUseCase: null,
      preRehabSquareFootage: "",
      postRehabSquareFootage: "",
      percentageOccupied: "",
      isPropertyPurchasedLast2Years: ""
    }
  },
  propertyEconomics: {
    errors: null,
    payload: {
      originalAppraisalValue: "",
      originalRepairedAppraisalValue: "",
      purchasePrice: "",
      costBasis: "",
      grossPotentialRent: "",
      assignmentFees: "",
      annualPropertyTaxes: "",
      insurance: "",
      rentalCashflowRatio: "",
      annualHazardInsurance: "",
      annualFloodInsurance: "",
      annualHoaFee: "",
      thirdPartyValuation: "",
      appraisalReported: "",
      decliningMarkets: "No"
    }
  },
  unitsInformation: [
    {
      errors: null,
      unitId: 1,
      payload: {
        leaseEndDate: null,
        leaseStartDate: null,
        leaseStatus: rentDescription[0],
        currentLeaseTerm: "",
        currentLeaseTermMonthly: "",
        marketRentMonthly: "",
        inPlaceLeaseRent: "",
        recentLeaseRent: "",
        monthlyLease: "",
        vacancyStatus: "",
        unitCategory: "",
        leaseDurationGreaterThan12M: "",
        leaseDurationLessThan12M: ""
      }
    }
  ],
  fesPropertyInformation: {
    errors: null,
    payload: {
      propertyType: "",
      numberOfUnits: "",
      changeInUseCase: null,
      valuationSource: "",
      appraisalVendor: "",
      propertyCondition: "",
      squareFootage: "",
      isPropertyTypeTwoFourUnit: "",
      isPropertyTypeCondo: "",
      propertyOriginallyAcquired: null,
      isPropertyOnDebt: "",
      propertyValuationDate: null
    }
  },
  fesPropertyEconomics: {
    errors: null,
    payload: {
      originalAsIsAppraisalValue: "",
      purchasePrice: "",
      grossCondoSelloutValue: "",
      borrowerPaidCosts: "",
      originalAsRepairedAppraisedValue: "",
      costBasis: "",
      additionalEligibleCost: "",
      grossPotentialRent: "",
      assignmentFees: "",
      appraisalReportedNoiNcf: "",
      annualPropertyTaxes: "",
      insurance: "",
      thirdPartyValuationStatus: "",
      thirdPartyValuation: "",
      acquisitionPrice: "",
      closingCost: "",
      annualHazardInsurance: "",
      annualFloodInsurance: "",
      annualHOAFee: "",
      decliningMarkets: "No"
    }
  }
});
export const emptyBorrower = {
  errors: null,
  borrowerId: uuidV4(),
  payload: {
    isPrimaryBorrower: true,
    borrowerEmail: "",
    borrowerType: "Individual",
    borrowingEntityName: "",
    firstName: "",
    lastName: "",
    foreignNationalString: "",
    originalCreditScoreMedian: "",
    originalCreditReportDate: null,
    borrowerExperience: "",
    billingAddress: "",
    billingPhoneNumber: "",
    borrowerLoanUserSequence: "",
    inState: ""
  }
};
export const emptyEntityBorrower = {
  errors: null,
  borrowerId: uuidV4(),
  payload: {
    isPrimaryBorrower: false,
    borrowerEmail: "",
    borrowerType: "Entity",
    borrowingEntityName: "",
    eiNumber: "",
    ssNumber: "",
    passportExpirationDate: "",
    firstName: "",
    lastName: "",
    foreignNationalString: "",
    originalCreditScoreMedian: "",
    originalCreditReportDate: null,
    borrowerExperience: "",
    billingAddress: "",
    billingPhoneNumber: "",
    borrowerLoanUserSequence: "",
    inState: ""
  }
};
export const getEmptyBorrower = (loanType: string) => {
  if (getLoanType[0].displayValue === loanType) {
    return emptyEntityBorrower;
  }
  return emptyBorrower;
};
export const emptyBridgeLoanDetails = () => ({
  bridgeLoanBorrowerInformation: [emptyBorrower],
  bridgeLoanGuarantorInformation: [],
  bridgeLoanInformation: {
    errors: null,
    payload: {
      primaryLoanID: "",
      numberOfProperties: "",
      extensionOption: "",
      loanPurpose: "",
      selectedLoanStructure: "",
      selectedRecourse: "",
      selectedCross: "",
      selectedToorakProduct: "",
      selectedFundingChannel: "",
      cutOffDate: null,
      originationDate: null,
      submissionDate: null,
      firstPaymentDate: null,
      maturityDate: null,
      pledgeOfEquity: "",
      servicerName: "",
      servicerPartyId: "",
      sellerPartyId: ""
    }
  },
  bridgeLoanEconomics: {
    errors: null,
    payload: {
      interestRate: "",
      accrualType: "",
      financialInterestReserve: "",
      loanAmount: "",
      maxLoanAmount: "",
      cutOffLoanAmount: "",
      cutOffMaxLoanAmount: "",
      budgetAmount: "",
      cashOutAmount: "",
      discountPoints: "",
      insuranceAndAmortization: "",
      financedBudgetAmount: "",
      rateType: "",
      armIndex: "",
      grossArmMargin: "",
      initialRateAdjustmentPeriod: "",
      initialPayAdjustmentPeriod: "",
      initialInterestRateDown: "",
      initialInterestRateUp: "",
      lifetimeMaxRate: "",
      lifetimeMinRate: "",
      subsequentInterestRateDown: "",
      subsequentInterestRateUp: "",
      paymentAdjustmentFrequency: "",
      rateAdjustmentFrequency: "",
      armLookBackDays: null,
      armRoundFlag: "",
      armRoundingFactor: "",
      originalMonthlyPiPayment: "",
      includeOutOfPocketBudgetInARLTV: true
    }
  },
  fesBorrowerInformation: {
    borrowerExperience: "",
    foreignNationalString: "",
    borrowerType: "",
    loanUserMapId: 0,
    borrowerCreditScore: null,
    borrowerLoanUserSequence: "",
    inState: "",
    billingAddress: ""
  },
  fesLoanInformation: {
    loanStructure: "",
    loanPurpose: "",
    exitStrategy: "",
    cashoutFlag: "",
    recourse: "",
    expectedClosingDate: null,
    originationDate: null,
    submissionDate: null,
    borrowerProceeds: "",
    numberOfProperties: null,
    fundingType: "",
    isWarehouseFunded: null,
    pledgeOfEquity: "",
    predominantPropertyType: null,
    condoEligibility: null,
    loanTerm: null,
    calculatedCondoEligibility: null,
    primaryLoanId: null,
    creditEvent: "No"
  },
  fesLoanCondo: {
    monthlyHOAFee: null,
    hoaDuesAboveFifteen: null,
    hoaDuesAboveTwenty: null,
    reserveFundsForOneYear: null,
    reserveFundsForTwoYears: null,
    isProjectCompleted: null,
    subjectToAdditionalPhasing: null,
    soldPercentage: null,
    hoaUnderOwnerControl: null,
    simpleEstateOwnership: null,
    ownerOccupiedUnits: null,
    renterOccupiedUnits: null,
    renterOccupiedPercentage: null,
    individualOwnershipAboveTwentyFive: null,
    masterAssurancePolicy: null,
    projectHasMobileHomes: null,
    otherSourcesIncomeMoreThanTwenty: null,
    commercialPurposesAreaMoreThanForty: null,
    hoawithLitOrBankruptcy: null,
    documentsWithSEC: null,
    restrictOwnerToRent: null,
    ownerControlStartDate: null,
    indivdualOwnedUnits: null,
    hoawithLitOrBankruptcyDesc: null,
    restrictOwnerToRentOutDesc: null
  },
  fesLoanEconomics: {
    rehabBudget: "",
    assignmentFees: "",
    hardCost: "",
    softCost: "",
    existDebt: "",
    originalLoanAmount: "",
    rehabAmount: "",
    totalOriginationAndDiscountPoints: "",
    interestRate: "",
    prepaymentPenalty: null,
    prepayPenaltyType: null,
    interestOnlyPeriod: null,
    rateType: ""
  }
});

export const empty30yearLoanDetails = () => ({
  thirtyYearBorrowerInformation: {
    errors: null,
    payload: {
      borrowerType: "",
      borrowerEmail: "",
      borrowingEntityName: "",
      billingAddress: "",
      billingPhoneNumber: ""
    }
  },
  thirtyYearLoanInformation: {
    errors: null,
    payload: {
      primaryLoanID: "",
      loanPurpose: "",
      selectedToorakProduct: "",
      selectedFundingChannel: "",
      cutOffDate: null,
      originationDate: null,
      submissionDate: null,
      firstPaymentDate: null,
      maturityDate: null,
      selectedRecourse: "",
      selectedCross: "",
      numberOfProperties: "",
      condoEligibility: "",
      totalOriginationAndDiscountPoints: "",
      calculatedCondoEligibility: "",
      sellerPartyId: "",
      takeOutPartnerId: "",
      takeoutPartnerStatus: "",
      eligibleTakeoutPartners: [],
      creditEvent: "No",
      rateLockExtensions: []
    }
  },
  thirtyYearLoanEconomics: {
    errors: null,
    payload: {
      financialInterestReserve: "",
      loanAmount: "",
      currentLoanBalance: "",
      interestRate: "",
      rateType: "", // hard coded as a requirement of lpp-9134
      accrualType: "",
      discountPoints: "",
      cashOutAmount: "",
      armProductType: "",
      armIndex: "",
      grossArmMargin: "",
      interestOnlyPeriod: "",
      originalMonthlyPiPayment: "",
      initialRateAdjustmentPeriod: "",
      initialPayAdjustmentPeriod: "",
      initialPeriodicCap: "",
      lifeRateCap: "",
      subsequentPeriodicCap: "",
      paymentAdjustmentFrequency: "",
      rateAdjustmentFrequency: "",
      prepaymentPenaltyMonths: "",
      prepayPenaltyType: "",
      costToLoanRatio: "",
      debtServiceCoverageRatio: "",
      borrowerLiquidity: "",
      originalQualifyingPaymentMonthly: "",
      qualifyingPaymentInReservesMonthly: "",
      lifetimeMinRate: "",
      subordinateFinancing: "",
      businessPurposeOccupancy: "",
      lifetimeMaxRate: "",
      subsequentInterestRateDown: "",
      subsequentInterestRateUp: "",
      initialInterestRateDown: "",
      initialInterestRateUp: "",
      armRoundFlag: "",
      armRoundingFactor: "",
      armLookBackDays: null
    }
  },
  thirtyYearGuarantorInformation: [
    {
      guarantorId: uuidV4(),
      errors: null,
      payload: {
        guarantorLastName: "",
        guarantorMiddleName: "",
        ssNumber: "",
        passportExpirationDate: "",
        guarantorFirstName: "",
        foreignNationalString: "",
        creditScore: "",
        originalCreditReportDate: null,
        guarantorEmail: "",
        pOEntity: "",
        isPrimaryGuarantor: true
      }
    }
  ]
});

export type PropertyDetailsRepresentable = {
  propertyId?: string | null;
  propertyDefaultName?: string | null;
  propertyLocation: Partial<{
    errors: number | null;
    payload: Partial<PropertyLocation>;
  }>;
  propertyInformation: Partial<{
    errors: number | null;
    payload: Partial<PropertyInformation>;
  }>;
  propertyEconomics: Partial<{
    errors: number | null;
    payload: Partial<UpdatedPropertyEconomics>;
  }>;
  propertySummary?: any;
};
export interface MultiplUnitInfo {
  leaseStatus: string;
  currentLeaseTerm: string;
  currentLeaseTermMonthly: string;
  marketRentMonthly: string;
  inPlaceLeaseRent: string;
  recentLeaseRent: string;
  leaseStartDate: Date | null;
  leaseEndDate: Date | null;
  monthlyLease: string | null;
  leaseDurationGreaterThan12M: string;
  leaseDurationLessThan12M: string;
  vacancyStatus: string;
  unitCategory: string;
  percentageOccupied: string;
}
export type ThirtyYearPropertyDetailsRepresentable = {
  propertyId?: string | null;
  propertyDefaultName?: string | null;
  propertyLocation: Partial<{
    errors: number | null;
    payload: Partial<ThirtyYearLoanPropertyLocation>;
  }>;
  propertyInformation: Partial<{
    errors: number | null;
    payload: Partial<ThirtyYearLoanPropertyInformation>;
  }>;
  propertyEconomics: Partial<{
    errors: number | null;
    payload: Partial<ThirtyYearLoanPropertyEconomics>;
  }>;
  propertySummary?: any;
};

export type DocumnetDetailsRepresentable = {
  docsLoaded?: boolean;
  files: any[];
  taggedArray: any[];
  failedUploads: number;
  currentFilesUploaded: number;
  successfulUploads: number;
  confidenceScoreMap?: any;
};

export type BridgeLoanDetailsRepresentable = {
  bridgeLoanBorrowerInformation: Partial<{
    errors: number | null;
    borrowerId: string;
    payload: Partial<BridgeLoanBorrowerInformation>;
  }>[];
  bridgeLoanGuarantorInformation: Partial<{
    guarantorId: string;
    errors: number | null;
    payload: Partial<BridgeLoanGuarantorInformation>;
  }>[];
  bridgeLoanInformation: Partial<{
    errors: number | null;
    payload: Partial<BridgeLoanLoanInformation>;
  }>;
  bridgeLoanEconomics: Partial<{
    errors: number | null;
    payload: Partial<BridgeLoanLoanEconomics>;
  }>;
};
export interface BorrowerInformation {
  errors: number | null;
  borrowerId: string;
  payload: BridgeLoanBorrowerInformation;
}
export type ThirtyYearLoanDetailsRepresentable = {
  thirtyYearBorrowerInformation: Partial<{
    errors: number | null;
    payload: Partial<ThirtyYearBorrowerInformation>;
  }>;
  thirtyYearGuarantorInformation: Partial<{
    errors: number | null;
    payload: Partial<ThirtyYearGuarantorInformation>;
  }>[];
  thirtyYearLoanInformation: Partial<{
    errors: number | null;
    payload: Partial<ThirtyYearLoanInformation>;
  }>;
  thirtyYearLoanEconomics: Partial<{
    errors: number | null;
    payload: Partial<ThirtyYearLoanEconomics>;
  }>;
};
export interface FesBorrower {
  borrowerExperience: string;
  foreignNationalString: string;
  borrowerType: string;
  loanUserMapId: number | null;
  borrowerCreditScore: number | null;
  borrowerGUCExperience?: number;
  heavyRehabExperience?: number;
  inState?: string;
}

export interface FesLoanInfo {
  loanStructure: string;
  loanPurpose: string;
  exitStrategy: string;
  cashoutFlag: string;
  fundingType: string;
  recourse: string;
  pledgeOfEquity: string;
  expectedClosingDate: Date | null;
  originationDate: Date | null;
  submissionDate: Date | null;
  borrowerProceeds: string;
  numberOfProperties: number | null;
  predominantState: string;
  predominantPropertyType: string | null;
  condoEligibility: string | null;
  loanTerm: number | null;
  isPermitApprovalsAndFullPlansInPlace?: string;
  irFundingSource?: string;
  toorakProduct?: string;
  sizerRateLockPeriod?: number;
  rateLockedRateSheet?: string;
  primaryLoanId: string;
  takeoutPartner: string;
  crossCollaterlization?: string;
  takeoutPartnerId?: string;
  takeoutPartnerStatus?: string;
  eligibleTakeoutPartners?: string[];
  creditEvent?: string;
}

export interface FesLoanEconomics {
  rehabBudget: string;
  assignmentFees: string;
  hardCost: string;
  softCost: string;
  existDebt: string;
  originalLoanAmount: string;
  totalOriginationAndDiscountPoints: string;
  rehabAmount: string;
  interestRate: string;
  prepaymentPenalty: number | null;
  prepayPenaltyType: string | null;
  interestOnlyPeriod: number | null;
  rateType: string | null;
  interestReserve?: string;
  armIndex?: string;
  grossArmMargin?: string;
  includeOutOfPocketBudgetInARLTV?: boolean;
}

export interface FesPropertyInfo {
  propertyType: string;
  numberOfUnits: string;
  changeInUseCase: string | null;
  valuationSource: string;
  appraisalVendor: string;
  propertyCondition: string;
  squareFootage: string;
  isPropertyTypeTwoFourUnit: string;
  isPropertyTypeCondo: string;
  propertyOriginallyAcquired: Date | null;
  isPropertyOnDebt: string;
}
export interface FesLoanCondo {
  monthlyHOAFee: string | null;
  hoaDuesAboveFifteen: string | null;
  hoaDuesAboveTwenty: string | null;
  reserveFundsForOneYear: string | null;
  reserveFundsForTwoYears: string | null;
  isProjectCompleted: string | null;
  subjectToAdditionalPhasing: string | null;
  soldPercentage: any | null;
  hoaUnderOwnerControl: string | null;
  simpleEstateOwnership: string | null;
  ownerOccupiedUnits: string | null;
  renterOccupiedUnits: string | null;
  renterOccupiedPercentage: string | null;
  individualOwnershipAboveTwentyFive: string | null;
  masterAssurancePolicy: string | null;
  projectHasMobileHomes: string | null;
  otherSourcesIncomeMoreThanTwenty: string | null;
  commercialPurposesAreaMoreThanForty: string | null;
  hoawithLitOrBankruptcy: string | null;
  documentsWithSEC: string | null;
  restrictOwnerToRent: string | null;
  ownerControlStartDate: string | null;
  indivdualOwnedUnits: string | null;
  hoawithLitOrBankruptcyDesc: string | null;
  restrictOwnerToRentOutDesc: string | null;
}
export interface FesPropertyEconomics {
  originalAsIsAppraisalValue: string;
  purchasePrice: string;
  grossCondoSelloutValue: string;
  borrowerPaidCosts: string;
  originalAsRepairedAppraisedValue: string;
  costBasis: string;
  // asStabilizedMarketRentalValue: string;
  grossPotentialRent: string;
  assignmentFees?: string;
  appraisalReportedNoiNcf: string;
  annualPropertyTaxes: string;
  insurance: string;
  thirdPartyValuationStatus: string;
  thirdPartyValuation: number | null;
  acquisitionPrice: number | null;
  closingCost: string;
  annualHazardInsurance: string;
  annualFloodInsurance: string;
  annualHOAFee: string;
  decliningMarkets?: string | null;
}
export interface PropertyDetails {
  propertyId: string;
  propertyDefaultName: string;
  propertyLocation: { errors: number | null; payload: PropertyLocation };
  propertyInformation: {
    errors: number | null;
    payload: PropertyInformation;
  };
  propertyEconomics: {
    errors: number | null;
    payload: UpdatedPropertyEconomics;
  };
  unitsInformation: {
    errors: number | null;
    unitId: number;
    payload: MultiplUnitInfo;
  }[];
  propertySummary: any;
  fesPropertyInformation: {errors: number | null; payload: FesPropertyInfo}
  fesPropertyEconomics: {errors: number | null; payload: FesPropertyEconomics};
}

export interface LoanDetails {
  loanId?: string | null;
  loanConfig?: any;
  loanStage?: string;
  loanSizerType: LoanSizerEnum;
  loanType: LoanType | null;
  [key: string]: any;
  loanDetailId: any;
  propertyDetails: PropertyDetails[];
  documentDetails: DocumnetDetailsRepresentable;
  prePurchaseDocDetails: DocumnetDetailsRepresentable;
  termSheetDetails: DocumnetDetailsRepresentable;
  activePropertyId: string;
  bridgeLoanBorrowerInformation: BorrowerInformation[];
  bridgeIsGroundUpLoan?: boolean;
  bridgeLoanGuarantorInformation: {
    guarantorId: string;
    errors: number | null;
    payload: BridgeLoanGuarantorInformation;
  }[];
  bridgeLoanInformation: {
    errors: number | null;
    payload: BridgeLoanLoanInformation;
  };
  bridgeLoanEconomics: {
    errors: number | null;
    payload: BridgeLoanLoanEconomics;
  };
  thirtyYearBorrowerInformation: {
    errors: number | null;
    payload: ThirtyYearBorrowerInformation;
  };
  thirtyYearGuarantorInformation: {
    guarantorId: string;
    errors: number | null;
    payload: ThirtyYearGuarantorInformation;
  }[];
  thirtyYearLoanInformation: {
    errors: number | null;
    payload: ThirtyYearLoanInformation;
  };
  thirtyYearLoanEconomics: {
    errors: number | null;
    payload: ThirtyYearLoanEconomics;
  };
  fesBorrowerInformation: FesBorrower;
  fesLoanInformation: FesLoanInfo;
  fesLoanEconomics: FesLoanEconomics;
  fesLoanCondo: FesLoanCondo;
  loanState: LoanStatusEnum | string;
  loanRuleVersions?: any[];
  selectedLoanRuleVersions?: any[];
  workflowState?: string | null;
  tapeToFileStatus?: any;
  dueDilligenceDate?: string | null;
  loanProcessType?: string | null;
  loanLandmarks?: any;
  assignedTo?: string | null;
  roleOfAssignee?: string | null;
  qcRequired?: boolean | null;
  isTapeToFileSubmitted?: boolean | null;
  ddReportStatus?: any;
  onHoldBy?: string | null;
  creditEvent?: string;
  rateLockExtensions?: ObjectType[]
}
export interface CreateLocationStore {
  // waiverActionTaken?: boolean;
  openColumnReorder?: boolean;
  isViewMode?: boolean;
  isWithdrawnLoan?: boolean;
  availableLoanStages: LoanStage[];
  autoSave?: boolean;
  autoSaveCount: number;
  startCreditEvalSync?: boolean;
  loading: boolean;
  missingPrimaryGuarantorOrBorrower?: boolean;
  infoContentLoan?: any;
  infoContentProperty?: any;
  infoContentResult?: any;
  missingEditedComments?: boolean;
  nameWithPartyId?: any;
  loanAmount: any;
  loanCount?: any;
  error: any;
  gurrantorFieldChange: string[];
  secondaryGurrantorEdited: boolean;
  borrowerFieldChange: string[];
  totalBorrowerFieldChange: string[];
  secondaryBorrowerEdited: boolean;

  unitFieldChange: string[];
  totalUnitFieldChange: string[];
  secondaryUnitEdited: boolean;

  emptyValueFieldCount: number;
  creditScoreBuckets: string[];
  loanPurposes: string[];
  activeTab: string;
  loanToDelete: string;
  loanToConvert: string;
  loanTocheckUpload: string;
  condoQuestOpen: boolean;
  sectionBeingEdited: boolean;
  appraisalVendors: string[];
  loanTypes: LoanType[];
  loanLists: LoanList[];
  cachedLoanListData: LoanList[];
  // rawLoanList: any[];
  dropDownData: {};
  filterData: {};
  selectedLoanListFilters: {};
  compactViewSelectedFiltersList: {};
  compactViewSearchValue: {};
  activeInteractionsModal?: boolean;
  rescindDialog?: boolean;
  activeInteractionsModalCount?: any;
  activeInteractionsModalArray?: any;
  activeIntError?: boolean;
  detailedViewSelectedFiltersList: {};
  detailedViewSelectedFilterTags: {};
  sortConfig?: any;
  tags: any[];
  dashboardTags: any;
  prePurchaseTags: any[];
  docsCategory: any[];
  submitForm: {
    loanErrors: number;
    propertyErrors: number;
    // docsErrors: number;
    clicked: boolean;
  };
  validateForm: {
    isSubmitValidation: boolean;
    validate: boolean;
  };
  loanDetails: LoanDetails;
  selectedEditSection: string;
  getLoan: {
    loading: boolean;
    error: any;
    loadingPostSubmit: boolean;
  };
  selectedLoans: any;
  selectedItems: any;
  editHistory: any;
  loanUserMap: any;
  loanStage: any;
  timeInterval?: any;
  compactTabSelected: any;
  viewtype: string;
  compactTabIndex: number;
  loanIds: any;
  selectedLoanDetails: any[];
  advanceSearch?: boolean;
  searchValue?: any;
  summaryCheckListModal?: boolean;
  singleLoanDetails?: any;
  rateSheetData?: any;
  rateSheetVersions?: any;
  overlaysVersions?: any;
  versionDate?: any;
  ruleVersionDate?: any;
  selectedOverlayVersion?: string;
  selectedRatesVersion?: string;
  dueDiligenceInformation?: any[];
  toggleSelections?: any[];
  customerInfo?: any;
  customColumnList?: any[];
  isPageBlocked?: boolean;
  selectedAllLoans: boolean;
  rawLoanListData: LoanList[];
  isLoanTableFunded: boolean;
  isWarehouseFunded: boolean;
  isAdvanceFunded: boolean;
  editedFields: string[];
}

export const initialState: CreateLocationStore = {
  openColumnReorder: false,
  startCreditEvalSync: false,
  autoSave: false,
  autoSaveCount: 0,
  isViewMode: false,
  isWithdrawnLoan: false,
  availableLoanStages: [],
  getLoan: {
    loading: false,
    error: null,
    loadingPostSubmit: false
  },
  gurrantorFieldChange: [],
  borrowerFieldChange: [],
  advanceSearch: false,
  unitFieldChange: [],
  totalUnitFieldChange: [],
  secondaryUnitEdited: false,

  totalBorrowerFieldChange: [],
  missingEditedComments: false,
  secondaryGurrantorEdited: false,
  nameWithPartyId: {},
  loanAmount: {
    waiversPending: 0,
    errorsPending: 0,
    // "docsPending":0,
    fieldsPending: 0,
    exceptionsPending: 0,
    enquiriesPending: 0,
    agedLoans: 0,
    allTab: 0
  },
  loanCount: {
    waiversPending: 0,
    errorsPending: 0,
    // "docsPending":0,
    fieldsPending: 0,
    exceptionsPending: 0,
    enquiriesPending: 0,
    agedLoans: 0,
    allTab: 0
  },
  secondaryBorrowerEdited: false,
  emptyValueFieldCount: 0,
  selectedEditSection: "",
  loading: false,
  sectionBeingEdited: false,
  error: null,
  creditScoreBuckets: [],
  loanPurposes: [],
  appraisalVendors: [],
  loanTypes: [],
  tags: [],
  dashboardTags: {},
  prePurchaseTags: [],
  docsCategory: [],
  loanLists: [],
  cachedLoanListData: [],
  // rawLoanList: [],
  dropDownData: {},
  filterData: {},
  selectedLoanListFilters: {},
  detailedViewSelectedFiltersList: {},
  detailedViewSelectedFilterTags: {},
  compactViewSelectedFiltersList: {},
  compactViewSearchValue: {},
  rescindDialog: false,
  activeInteractionsModal: false,
  activeInteractionsModalCount: 0,
  isPageBlocked: false,
  activeInteractionsModalArray: [],
  activeIntError: false,
  sortConfig: {},
  submitForm: {
    loanErrors: 1,
    propertyErrors: 1,
    // docsErrors: 0,
    clicked: false
  },
  validateForm: { isSubmitValidation: false, validate: false },
  activeTab: "loanDetails",
  loanToDelete: "",
  loanToConvert: "",
  loanTocheckUpload: "",
  condoQuestOpen: false,
  loanDetails: {
    loanId: null,
    loanType: null,
    loanSizerType: LoanSizerEnum.oneFourFamily,
    mandatoryResult: [],
    mandatoryDocs: [],
    prePurchaseMandatoryResult: [],
    prePurchaseMandatoryDocs: [],
    loanDetailId: {},
    workflowState: null,
    tapeToFileStatus: null,
    dueDilligenceDate: null,
    isTapeToFileSubmitted: false,
    bridgeIsGroundUpLoan: false,
    ddReportStatus: null,
    loanProcessType: null,
    loanLandmarks: [],
    assignedTo: null,
    roleOfAssignee: null,
    qcRequired: null,
    loanState: "",
    activePropertyId: "",
    loanRuleVersions: [],
    selectedLoanRuleVersions: [],
    onHoldBy: null,
    ...emptyLoanDetails(),
    ...emptyBridgeLoanDetails(),
    ...empty30yearLoanDetails(),
    creditEvent: "No"
  } as any,
  selectedLoans: [],
  selectedItems: [],
  editHistory: {},
  editedFields: [],
  loanUserMap: [],
  loanStage: "Loans",
  timeInterval: getCookie("timeInterval") || "Daily",
  compactTabSelected: "allTab",
  viewtype: "",
  compactTabIndex: 0,
  loanIds: [],
  selectedLoanDetails: [],
  searchValue: "",
  summaryCheckListModal: false,
  singleLoanDetails: {},
  rateSheetData: [],
  rateSheetVersions: [],
  overlaysVersions: [],
  versionDate: null,
  ruleVersionDate: null,
  selectedOverlayVersion: "",
  selectedRatesVersion: "",
  dueDiligenceInformation: [],
  toggleSelections: [],
  customerInfo: {},
  customColumnList: [],
  selectedAllLoans: false,
  rawLoanListData: [],
  isLoanTableFunded: false,
  isWarehouseFunded: false,
  isAdvanceFunded: false
};

export const updateTagCategory = (file: any[], taggedArray: any) => {
  const myFileArray: any[] = [];

  if (file.length) {
    file.forEach((item: any) => {
      let categoryArray: any[] = [];
      taggedArray.forEach((tag: any) => {
        if (
          item.tag.includes(tag.name) &&
          !categoryArray.includes(tag.tagCategory)
        ) {
          categoryArray.push(tag.tagCategory);
        }
      });
      const tempItem = JSON.stringify(item);
      const listItem = JSON.parse(tempItem);
      categoryArray.push(item.categoryTag);
      listItem.tagCategory = categoryArray;
      myFileArray.push(listItem);
    });
  }
  return myFileArray;
};
export const copyFileList = (file: any[]) => {
  const myFileArray: any[] = [];
  if (file.length) {
    file.forEach((item: any) => {
      const percentCompleted =
        item.uploaded === true ? 100 : item.percentCompleted;
      const fille = item.ErrorMsg
        ? {
          ...item,
          name: item.name,
          split_source_id: item?.split_source_id || null,
          visibility: item.visibility || "",
          assets: item.assets,
          isPurchasePrimary: item.isPurchasePrimary,
          isLatest: item.isLatest
        }
        : {
          lastMod: item.lastModified,
          lastModDate: item.lastModifiedDate || item.createdOn,
          name: item.name,
          createdOn: item.createdOn,
          size: item.size,
          type: item.type,
          inQueue: item.inQueue,
          tag: item.tag,
          tagCategory: item.tagCategory,
          uploading: item.uploading,
          uploaded: item.uploaded,
          percentCompleted,
          path: item.path,
          docsId: item.docsId,
          uniqueId: item.uniqueId,
          categoryTag: item.categoryTag,
          trackingId: item.trackingId,
          split_source_id: item?.split_source_id || null,
          visibility: item.visibility || "",
          assets: item.assets,
          isPurchasePrimary: item.isPurchasePrimary,
          isLatest: item.isLatest
        };
      // add the fille obj to your array
      myFileArray.push(fille);
    });
  }
  return JSON.parse(JSON.stringify(myFileArray));
};

export const mergeArrayOfObjects = (
  original: any[],
  newdata: any[],
  selector = "key"
) => {
  newdata.forEach((dat: any) => {
    const foundIndex = original.findIndex(
      (ori) => ori[selector] === dat[selector]
    );
    if (foundIndex >= 0) original.splice(foundIndex, 1, dat);
    else original.push(dat);
  });

  return original;
};
