/* eslint-disable react-hooks/exhaustive-deps */
import { InputBase, InputAdornment, Grid, Switch } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { WhiteArrowTooltip } from "@toorak/tc-common-fe-sdk";
import { RootState } from "../../../stores/rootReducer";
import { updateSearchValue } from "../../create-loan.action";
import { loanStages } from "../utils/constants";
import { useStyles } from "./style";

const styleOverrides = makeStyles({
  root: {
    "& .MuiInputBase-input": {
      "&:focus": {
        boxShadow: "none !important"
      }
    }
  }
});

export function SearchBarForMobile(props: any) {
  const {
    setInputValue,
    inputValue,
    searchInput,
    handleSearch,
    clearSearch,
    customClassName,
    showSearchToggle = false,
    fullWidth = true,
    placeHolder = 'Type Here',
    handleKeyPress,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const customClasses = styleOverrides();
  const [advanceSearch, setAdvanceSearch] = useState<boolean>(false);

  const loanStageSelected: any = useSelector<RootState, any>(
    (state) => state.createLoanStore.loanStage
  );

  const onChange = (ev: any) => {
    setInputValue(ev.target.value);
  };

  const onKeyPress = (event: any) => {
    if (event.key === "Enter") {
      handleSearch(event, advanceSearch);
      handleKeyPress ? handleKeyPress() : dispatch(updateSearchValue(event.target.value));
    }
  };

  return (
    <Grid container alignItems="center" style={{ 'width': '100%' }}>
      <Grid item style={{ 'width': '100%' }}>
        <InputBase
          type="search"
          className={`${classes.searchContainer} ${customClassName} ${customClasses.root} `}
          fullWidth
          placeholder={placeHolder}
          inputProps={{ "aria-label": "Type Keyword" }}
          inputRef={searchInput}
          value={inputValue}
          onClick={(event) => event.stopPropagation()}
          onKeyPress={onKeyPress}
          onChange={onChange}
          // margin="dense"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon className={classes.searchIcon} />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              {inputValue !== "" && (
                <ClearIcon
                  className={classes.clearSearchIcon}
                  onClick={(event) =>
                    clearSearch(event, loanStages[loanStageSelected])
                  }
                />
              )}
            </InputAdornment>
          }
        />
      </Grid>
      {showSearchToggle && (
        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              <Switch
                checked={advanceSearch}
                onChange={() => {
                  setAdvanceSearch(!advanceSearch);
                }}
                name="advanceSearch"
              />
            </Grid>
            <Grid item style={{ lineHeight: 1, fontSize: "small" }}>
              <WhiteArrowTooltip
                title={
                  <div>
                    <p>
                      <b>Basic Search</b>- Searches across Toorak Loan Id and
                      Originator Loan Id.
                    </p>
                    <p>
                      <b>Advanced Search</b>- Searches across all the columns.
                    </p>
                  </div>
                }
              >
                <InfoOutlinedIcon />
              </WhiteArrowTooltip>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
