/* eslint-disable no-plusplus */
/* eslint-disable guard-for-in */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  InputLabel,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch } from "react-redux";
import { LoanStage, WaiverType } from "@toorak/tc-common-fe-sdk";
import {
  getPartyId,
  reRequestWaiver,
  submitWaiverCall,
  updateMitigates,
  waiverApproval
} from "../../stores/WaiverRequest/WaiverRequest.action";
import { MitigatingFactorField } from "./MitigatingFactorField";
import {
  getRuleResult,
  // requestedWaierUserName,
  // upadteRuleResults,
  updateWaiverApprovalStatus
  // updateWaiverList
} from "../../stores/EvaluationResults/EvaluationResults.action";
import { formStyles, waiverFormStyles, mitigatingClass } from "./Common.style";
import { sendFileExtra } from "./FileUploadAPIs";
import { SubmitWaiver } from "./SubmitWaiver";
import {
  getWaiverByLoanId
  // resetExceptionStore
} from "../../stores/ExceptionsResults/ExceptionActions";
import {
  // getWaiverByLoanId,
  resetExceptionStore
} from "../../stores/ExceptionsResults/ExceptionsReducer";
import {
  hideLoader,
  showLoader
} from "../../stores/loaderAndException/loaderAndException.action";
import { uploadFileToDocuments } from "../exceptions-and-enquiries/DocumentUploadApis";
import {
  createComment,
  createCommentThread
} from "../../stores/commentsSection/comment.action";
// import { UPDATE_POLLING_DATA } from "../../stores/SettlementAndPurchase/SettlementAndPurchase.action";
import { getCookie } from "../../utils/cookies";
import { isLATUser } from "../../utils/AccessManagement";
import {
  insertDocs,
  setActiveInteractionsModal
  // updateActIntLoanList
} from "../create-loan.action";
import { WaiverResponseState } from "../../utils/constants";
import { updateActIntLoanList } from "../create-loan.reducer";
import {
  requestedWaierUserName,
  updateRuleResults,
  updateWaiverList
} from "../../stores/EvaluationResults/EvaluationResults.reducer";
import { updatePollingData } from "../../stores/SettlementAndPurchase/SettlementAndPurchase.reducer";

declare module "@mui/styles" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const formLabelsTheme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "#db3131"
        }
      }
    }
    // notchedOutline: {
    //   borderWidth: "1px",
    //   borderColor: "black !important"
    // }
  }
});

const FileBar = React.lazy(() =>
  import("./FileBar").then((module) => ({
    default: module.FileBar
  }))
);

const ShowDialog = React.lazy(() =>
  import("./ShowDialog").then((module) => ({
    default: module.ShowDialog
  }))
);

const getWaiverResponse = (responseType: any) => {
  let responseValue =
    responseType === "APPROVE"
      ? WaiverResponseState.APPROVED
      : responseType === "APPROVE-CONDITIONALLY"
      ? WaiverResponseState.CONDITIONALLYAPPROVED
      : responseType === "REJECT"
      ? WaiverResponseState.REJECTED
      : "";
  return responseValue;
};

const isApprovedWaiverResponse = (response: any) => {
  let isApproved =
    response === "Approve" || response === "Approve-Conditionally";
  return isApproved;
};

export interface WaiverDetailsProps {
  isViewMode?: boolean;
  loanStage: any;
  loanId: string;
  actionId?: string;
  visibility?: any;
  actionType?: string;
  type?: any;
  waiverId?: any;
  // eslint-disable-next-line
  reloadWaiver?: any;
  waiverDetails?: {
    subject?: string;
    reason?: string;
    // eslint-disable-next-line
    details?: any;
    errorAddressList?: any;
  };
  waiverRequestedInfo?: any;
  exceptionId?: string;
  // eslint-disable-next-line
  callbacks?: any;
  exceptionWaiverId?: string;
  documents?: any;
  status?: any;
  setFiles?: any;
  waiverChat?: any;
  customerDetails?: any;
  isCarryForwardedWaiver?: boolean;
  currentWaiver?: any;
  currentWaiverDocs?: any;
  waiversCreated?: any;
  loanState?: any;
  waiverType?: WaiverType | "";
  activeInteractionsModal?: any;
  showViewDetails?: any;
  getDetails?: any;
  setReRequestContainer?: any;
  hasConditionProp?: boolean;
  takeoutPartnerId?: string | null;
  isModal?: boolean;
  callback?: () => void;
}

export const WaiverContainer = (props: WaiverDetailsProps) => {
  const formClass = formStyles();
  const navigate = useNavigate();
  const classes = waiverFormStyles();
  const dispatch = useDispatch();
  const {
    exceptionId,
    loanId,
    loanStage,
    visibility,
    currentWaiver,
    currentWaiverDocs,
    activeInteractionsModal,
    showViewDetails,
    getDetails,
    setReRequestContainer,
    hasConditionProp,
    takeoutPartnerId,
    isModal,
    callback
  } = props;
  let waiverMitigates: WaiverMitigate[] = [];
  const initialList = [
    {
      index: 1,
      fieldKey: 1,
      required: true
    }
    // {
    //   index: 2,
    //   fieldKey: 2,
    //   required: true
    // }
  ];

  const [mitigatingFactorList, setMitigatingFactorList] = useState(initialList);
  // eslint-disable-next-line
  const [filesList, setFilesList] = useState<any[]>([]);
  const [selectedCompensatingFactors, setselectedCompensatingFactors] =
    React.useState([]);
  const [waiverResponseType, setWaiverResponseType] = React.useState("");

  const [openDialog, setOpenDialog] = useState(false);

  const [generalWaiverSub, setGeneralWaiverSub] = useState<any>("");
  // eslint-disable-next-line
  const [dialogdetails, setDialogdetails] = useState<any>({});
  // const [openWarnDialog, setOpenWarnDialog] = useState<boolean>(false);
  // eslint-disable-next-line
  const [waiverData, setWaiverData] = useState<any>({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);

  const commonClass = mitigatingClass();

  useEffect(() => {
    if (currentWaiver?.waiverMitigates?.length) {
      const length = currentWaiver?.waiverMitigates?.length;
      if (length > 1 && mitigatingFactorList.length < length) {
        addMitigatingFactor(length - 1);
        let formData: any = {};
        for (let i = 1; i <= length; i++) {
          const value = currentWaiver.waiverMitigates[i - 1].description;
          let data = {
            ...formData,
            [i]: {
              value,
              valid: true
            }
          };
          formData = data;
        }
        setWaiverData(formData);
      }
    }
  }, [currentWaiver]);

  useEffect(() => {
    if (currentWaiverDocs?.length) {
      setFilesList(currentWaiverDocs);
    }
  }, [currentWaiverDocs]);

  useEffect(() => {
    if (currentWaiver?.waiverMitigates?.length) {
      setIsSubmitDisabled(false);
    } else {
      const keys = Object.keys(waiverData);
      var next = true;
      if (keys.length >= 1) {
        for (const key of keys) {
          if (!waiverData[key].valid) {
            next = false;
            break;
          }
        }
        if (next) {
          setIsSubmitDisabled(false);
        } else {
          setIsSubmitDisabled(true);
        }
      } else {
        setIsSubmitDisabled(true);
      }
    }
  }, [waiverData, currentWaiver]);

  // ----------- Handle submit waiver request--------------------
  interface WaiverMitigate {
    waiverMitigateId: string;
    description: string;
  }

  // ----------- Handle request and take LAT action on waiver starts --------------------
  const InitialState = { isRequested: false, waiverId: "", chatId: "" };
  const [requestAndActionObject, setRequestAndActionObject] = useState({
    actionComment: "",
    actionType: ""
  });
  const [requestComplete, setRequestComplete] = useState(InitialState);

  // * For submit and approve/conditionally-approve/reject.
  useEffect(() => {
    const { actionComment, actionType } = requestAndActionObject;
    const { waiverId, chatId } = requestComplete;
    if (actionComment && actionType && requestComplete.isRequested) {
      const isApproved =
        actionType === "Approve" || actionType === "Approve-Conditionally";
      const responseType =
        actionType === "Approve"
          ? "APPROVED"
          : actionType === "Approve-Conditionally"
          ? "APPROVED-CONDITIONALLY"
          : actionType === "Re-Open"
          ? "REOPENED"
          : "REJECTED";
      if (!currentWaiver) {
        takeActionOnWaiver(
          waiverId,
          isApproved,
          responseType,
          chatId,
          actionComment,
          actionType,
          takeoutPartnerId
        );
      } else {
        // * Submit and ReRequest flow as waiver already exits use bulk api to just update waiver response.
        const { partyId } = getPartyId();
        const actionObj: any = {
          responseType,
          responseBy: partyId,
          responseDate: new Date(),
          isApproved,
          compensatingFactors: selectedCompensatingFactors
        };
        const actionRequestBody = {
          [waiverId]: actionObj
        };
        takeActionOnRerequest(actionRequestBody);
      }
    } else if (!actionComment && !actionType && requestComplete.isRequested) {
      if (!exceptionId) {
        props?.callbacks?.isLoading(false);
        navigate(
          props?.waiverDetails && props?.waiverDetails?.details?.backLocation
        );
        callback && callback();
        dispatch(hideLoader());
      }
      if (exceptionId) {
        if (
          !filesList.length &&
          waiverId &&
          window.location.href.indexOf("createLoan") === -1
        ) {
          dispatch(getWaiverByLoanId(loanId, loanStage, exceptionId));
        }
        dispatch(hideLoader());
        //* Used to unmount request waiver component from condition flow. Unmount when waiver has been successfully requested.
        dispatch(resetExceptionStore());
      }
    }
  }, [requestAndActionObject, requestComplete, takeoutPartnerId]);

  const takeActionOnWaiver = (
    waiverId: string,
    isApproved: boolean,
    responseType: string,
    chatId: string,
    actionComment: string,
    actionType: string,
    takeoutPartnerId?: string | null
  ) => {
    const personPartyId: string | any = getCookie("person_id");
    dispatch(resetExceptionStore());
    createComment(chatId, personPartyId, actionComment, false, actionType);
    waiverApproval({
      waiverId,
      loanId,
      isApproved,
      responseType,
      compensatingFactors: selectedCompensatingFactors
    }).then((resp: any) => {
      const waiver = {
        isApproved: resp.data.isApproved,
        responseBy: resp.data.responseBy,
        responseDate: resp.data.responseDate,
        responseType: resp.data.responseType
      };
      dispatch(updateWaiverApprovalStatus(waiverId, waiver));
      dispatch(requestedWaierUserName(props?.waiverRequestedInfo));
      if (!exceptionId) {
        if (loanStage === LoanStage.post) {
          // dispatch({
          //   type: UPDATE_POLLING_DATA,
          //   payload: {
          //     startPolling: true,
          //     timeInMs: 0
          //   }
          // });
          dispatch(
            updatePollingData({
              startPolling: true,
              timeInMs: 0
            })
          );
        }
        getRuleResult(loanId, loanStage, takeoutPartnerId).then(
          (ruleResult: any) => dispatch(updateRuleResults({ruleResults : ruleResult}))
        );
        props.callbacks.isLoading(false);
        callback && callback();
        navigate(
          props.waiverDetails && props.waiverDetails.details.backLocation
        );
      } else if (
        !filesList.length &&
        waiverId &&
        window.location.href.indexOf("createLoan") === -1
      ) {
        dispatch(getWaiverByLoanId(loanId, loanStage, exceptionId));
      }
      dispatch(hideLoader());
    });
  };

  const takeActionOnRerequest = (actionRequestBody: any) => {
    reRequestWaiver(actionRequestBody, loanId, loanStage).then(
      (response: any) => {
        if (response.status === 200) {
          const waiver = {
            isApproved: response.data.isApproved,
            responseBy: response.data.responseBy,
            responseDate: response.data.responseDate,
            responseType: response.data.responseType
          };
          dispatch(
            updateWaiverApprovalStatus(
              currentWaiver.waiverId,
              waiver,
              undefined
            )
          );
          dispatch(requestedWaierUserName(props?.waiverRequestedInfo));
          if (loanStage === LoanStage.post) {
            // dispatch({
            //   type: UPDATE_POLLING_DATA,
            //   payload: {
            //     startPolling: true,
            //     timeInMs: 0
            //   }
            // });
            dispatch(
              updatePollingData({
                startPolling: true,
                timeInMs: 0
              })
            );
          }
          if (props?.waiverDetails?.details?.backLocation) {
            const { backLocation } = props.waiverDetails.details;
            navigate(backLocation);
          }
          callback && callback();
        }
      }
    );
  };

  const RequestAndTakeAction = (actionComment: any, actionType: any) => {
    setRequestAndActionObject({
      ...requestAndActionObject,
      actionComment,
      actionType
    });
  };

  // ----------- Handle request and take LAT action on waiver ends --------------------

  // eslint-disable-next-line
  const submitRequest = (event: any) => {
    event.preventDefault();
    setIsSubmitDisabled(true);
    // eslint-disable-next-line guard-for-in
    // const { actionType } = requestAndActionObject;
    for (let item in waiverData) {
      let value: string = waiverData[item].value.trim();
      const waiverMitigate = {
        waiverMitigateId: "",
        description: value
      };
      waiverMitigates.push(waiverMitigate);
    }
    // Wrapping request body
    let waiverRequest: any = {
      waiverType: exceptionId
        ? "Exception"
        : props?.waiverDetails?.details?.tab === "LOAN" ||
          props?.waiverDetails?.details?.tab === "PROPERTY"
        ? "EvaluationResult"
        : props?.waiverDetails?.details?.tab || "NA",
      waiverKeys: getWaiverKeys(),
      title: props?.waiverDetails?.details?.representableTestName,
      description:
        props?.waiverDetails?.details?.representableTestName ===
        "General Waiver Request"
          ? generalWaiverSub
          : props?.waiverDetails?.reason,
      waiverMitigates,
      waiverInfo: {
        hasDocuments: !!filesList.length,
        hasDiscussions: false,
        finalResponse: getWaiverResponse(waiverResponseType.toUpperCase()),
        isInternal: visibility === "internal"
      },
      takeoutPartnerId: takeoutPartnerId
    };
    if (
      isApprovedWaiverResponse(waiverResponseType) ||
      waiverResponseType === "Reject"
    ) {
      waiverRequest = {
        ...waiverRequest,
        waiverResponses: [
          {
            isApproved: isApprovedWaiverResponse(waiverResponseType),
            responseBy: getCookie("person_id"),
            responseDate: new Date().toISOString(),
            responseType: getWaiverResponse(waiverResponseType.toUpperCase()),
            compensatingFactors:
              waiverResponseType === "Reject" ? [] : selectedCompensatingFactors
          }
        ]
      };
    }
    // set loading status
    props.callbacks.isLoading(true);
    dispatch(showLoader());
    submitWaiverCall(waiverRequest, {
      identifierValue: props.loanId,
      secondIdentifier: `secondaryIdentifierName=LOAN_STAGE&secondaryIdentifierValue=${loanStage}`
    })
      .then((res: any) => {
        if (res.status === 200 && !exceptionId) {
          // * After submit waiver call createCommentThread to update chatSequenceId. No comments or LAT action can be performed without chasequenceId. Respective Apis will throw error.
          // * For carry forwarded waivers chatsequenceId will be created at backend.
          // ! If chatsequenceId already present No need to call this. i.e. in Re-Request, State update flows.
          createCommentThread(
            "waiver",
            res.data.waiverId,
            "loan",
            props.loanId
          ).then((resp: any) => {
            setRequestComplete({
              isRequested: true,
              waiverId: res.data.waiverId,
              chatId: resp.id
            });
          });
          dispatch(updateWaiverList({ waiver: res.data }));
          if (props?.waiverRequestedInfo?.customers) {
            dispatch(requestedWaierUserName(props?.waiverRequestedInfo));
          }
          if (filesList.length && res.data.waiverId) {
            startUpload(res.data.waiverId);
          }
        }
        if (res.status === 200 && exceptionId) {
          // if (!filesList.length && res.data.waiverId) {
          //   dispatch(getWaiverByLoanId(loanId, loanStage, exceptionId));
          // }
          // * After submit waiver call createCommentThread to update chatSequenceId. No comments or LAT action can be performed without chasequenceId. Respective Apis will throw error.
          createCommentThread(
            "waiver",
            res.data.waiverId,
            "loan",
            props.loanId
          ).then((resp: any) => {
            setRequestComplete({
              isRequested: true,
              waiverId: res.data.waiverId,
              chatId: resp.id
            });
            if (activeInteractionsModal) {
              dispatch(updateActIntLoanList(loanId));
              dispatch(
                setActiveInteractionsModal(
                  activeInteractionsModal,
                  loanId,
                  loanStage
                )
              );
            }
          });
          if (filesList.length && res.data.waiverId) {
            startUpload(res.data.waiverId);
          }
          //   dispatch(hideLoader());
        }
        if (
          getDetails &&
          (window.location.href.indexOf("createLoan") > -1 || !showViewDetails)
        ) {
          getDetails();
        }
        setTimeout(() => {
          dispatch(setActiveInteractionsModal(false, loanId, loanStage));
        }, 1000);
        dispatch(hideLoader());
      })
      .catch(() => {
        // set loading status
        setRequestComplete(InitialState);
        props.callbacks.isLoading(false);
        dispatch(hideLoader());
      });
  };

  const submitReRequestWaiver = (event: any) => {
    event.preventDefault();
    setIsSubmitDisabled(true);
    for (let item in waiverData) {
      let value: string = waiverData[item].value.trim();
      const waiverMitigate = {
        waiverMitigateId: "",
        description: value
      };
      waiverMitigates.push(waiverMitigate);
    }
    const key = currentWaiver.waiverId;
    const { partyId } = getPartyId();
    const requestObj: any = {
      responseType: "RE-REQUESTED",
      responseBy: partyId,
      responseDate: new Date(),
      compensatingFactors: selectedCompensatingFactors,
      isApproved: false
    };

    const waiverReRequestBody = {
      [key]: requestObj
    };
    const updateMitigatesRequestbody = {
      delete: currentWaiver.waiverMitigates,
      add: waiverMitigates,
      update: []
    };
    // updateMitigates(updateMitigatesRequestbody, currentWaiver.waiverId);
    reRequestWaiver(waiverReRequestBody, loanId, loanStage)
      .then((response: any) => {
        if (response.status === 200) {
          updateMitigates(updateMitigatesRequestbody, currentWaiver.waiverId)
            .then(() => {
              //! use bulk api to rerequest and approve both
              setRequestComplete({
                isRequested: true,
                waiverId: currentWaiver.waiverId,
                chatId: currentWaiver.chat.chatSequence.id
              });
              if (filesList.length && currentWaiver?.waiverId) {
                startUpload(currentWaiver?.waiverId, "re-request");
              }
              const waiver = {
                isApproved: response.data.isApproved,
                responseBy: response.data.responseBy,
                responseDate: response.data.responseDate,
                responseType: response.data.responseType
              };
              if (activeInteractionsModal) {
                setReRequestContainer(false);
                // dispatch(updateActIntLoanList(loanId));
                dispatch(
                  setActiveInteractionsModal(
                    activeInteractionsModal,
                    loanId,
                    loanStage
                  )
                );
              }
              if (!activeInteractionsModal) {
                dispatch(
                  updateWaiverApprovalStatus(
                    currentWaiver.waiverId,
                    waiver,
                    undefined
                  )
                );
                dispatch(requestedWaierUserName(props?.waiverRequestedInfo));
              }
            })
            .catch(() => {});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function getWaiverKeys() {
    let waiverKeys: any = [];
    if (
      props?.waiverDetails?.details?.representableTestName !==
      "General Waiver Request"
    ) {
      waiverKeys.push(
        exceptionId ||
          `loanResult.${props?.loanId}.${props?.waiverDetails?.details?.testGroup}.${props?.waiverDetails?.subject}`
      );
      if (!exceptionId) {
        props?.waiverDetails?.errorAddressList &&
          Object.keys(props?.waiverDetails?.errorAddressList).forEach(
            (key: any) => {
              // eslint-disable-next-line eqeqeq
              if (key == props?.waiverDetails?.subject) {
                props?.waiverDetails?.errorAddressList[key].forEach(
                  (address: any) => {
                    waiverKeys.push(
                      `propertiesResults.${address?.propertyId}.${props?.waiverDetails?.details?.testGroup}.${props?.waiverDetails?.subject}`
                    );
                    return null;
                  }
                );
              }
              return true;
            }
          );
      }
    } else {
      waiverKeys.push(`${props?.waiverDetails?.subject}`);
    }
    return waiverKeys;
  }
  // --------------------------------------------------------------
  // -------------------Migigating Fator field functions------------
  const addMitigatingFactor = (Mnum?: number) => {
    if (!Mnum) {
      const len = mitigatingFactorList.length;
      // setMitigatingFactorList([
      //   ...mitigatingFactorList,
      //   {
      //     index: len,
      //     fieldKey: len + 1,
      //     required: false
      //   }
      // ]);
      let Mitigate: any = {};
      let MitigateList: any[] = [];
      for (let i = 0; i < len + 1; i++) {
        if (i === 0) {
          Mitigate.index = 1;
          Mitigate.fieldKey = 1;
          Mitigate.required = true;
        } else {
          Mitigate.index = i;
          Mitigate.fieldKey = i + 1;
          Mitigate.required = false;
        }
        MitigateList.push({ ...Mitigate });
      }
      setMitigatingFactorList(MitigateList);
    } else {
      let Mitigate: any = {};
      let MitigateList: any[] = [...mitigatingFactorList];
      for (let i = 0; i < Mnum; i++) {
        Mitigate.index = i + 1;
        Mitigate.fieldKey = i + 1 + 1;
        Mitigate.required = false;
        MitigateList.push({ ...Mitigate });
      }
      setMitigatingFactorList(MitigateList);
    }
  };
  const removeMitigatingFactor = (fieldKey: number) => {
    const newList = mitigatingFactorList.filter(
      (item) => item.fieldKey !== fieldKey
    );
    setMitigatingFactorList(newList);
  };
  // ----------------------------------------------------------------

  // ----------------File upload related block-----------------------

  const [docUploadCount, setDocUploadCount] = useState<any>();
  const [docInxList, setDocInxList] = useState<any[]>([]);
  useEffect(() => {
    if (typeof docUploadCount === "number") {
      const newListSet: any = new Set([docUploadCount, ...docInxList]);
      setDocInxList([...newListSet]);
    }
  }, [docUploadCount]);

  useEffect(() => {
    if (filesList.length && docInxList.length === filesList.length) {
      if (exceptionId) {
        dispatch(getWaiverByLoanId(loanId, loanStage, exceptionId, filesList));
      }
    }
  }, [docInxList]);

  const fileUploadCallBack = (files: any) => {
    dispatch(getWaiverByLoanId(loanId, loanStage, exceptionId, files));
    if (activeInteractionsModal)
      dispatch(setActiveInteractionsModal(true, loanId, loanStage));
    // dispatch(hideLoader());
  };

  const startUpload = async (waiverId: string, responseType?: any) => {
    function delay(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
    // eslint-disable-next-line no-plusplus
    if (
      // eslint-disable-next-line
      props?.waiverDetails?.details?.backLocation === null ||
      (props?.waiverDetails?.details?.backLocation === undefined &&
        filesList?.length &&
        filesList[0]?.body?.length &&
        !activeInteractionsModal)
    ) {
      const body: any[] = [];
      for (let i = 0; i < filesList.length; i++) {
        if (Array.isArray(filesList[i]?.body) && filesList[i]?.body?.length) {
          body.push(filesList[i]?.body[0]);
        }
      }
      uploadFileToDocuments(
        body,
        "WAIVER",
        "",
        "",
        "",
        fileUploadCallBack,
        waiverId,
        loanId,
        loanStage,
        true
      );
      const hasCondition: boolean = window.location.pathname.includes(
        "/Condition/"
      );
      if (hasConditionProp && !hasCondition) {
        await insertDocs(
          body,
          loanId,
          `${loanStage}-postClose`,
          `${loanStage}-postClose`,
          "LOAN",
          true
        );
      }
      // dispatch(getWaiverByLoanId(loanId, loanStage, exceptionId, filesList));
    } else {
      for (let i = 0; i < filesList.length; i++) {
        sendFileExtra(
          i,
          waiverId,
          filesList,
          setFilesList,
          loanId,
          loanStage,
          async (inx: number, body: any[]) => {
            setDocUploadCount(inx);
            if (hasConditionProp) {
              await insertDocs(
                body,
                loanId,
                `${loanStage}-postClose`,
                `${loanStage}-postClose`,
                "LOAN",
                true
              );
            }
          }
        );
        // eslint-disable-next-line no-await-in-loop
        await delay(100);
        if (activeInteractionsModal) {
          setReRequestContainer && setReRequestContainer(false);
          if (!responseType) {
            dispatch(updateActIntLoanList(loanId));
          }
          dispatch(
            setActiveInteractionsModal(
              activeInteractionsModal,
              loanId,
              loanStage
            )
          );
        }
      }
    }
    return null;
  };

  // eslint-disable-next-line
  const handleFileDelete = async (file: any, index: number) => {
    let temp = [...filesList];
    temp.splice(index, 1);
    setFilesList([...temp]);
    setOpenDialog(false);
  };

  // eslint-disable-next-line
  const deleteBtnHandler = (file: any, i: number) => {
    let dialogData = {
      topic: "delete",
      head: "Delete Document",
      btnText: "Delete",
      file,
      contentText: "Are you sure you want to delete the document?",
      btnFunction: () => handleFileDelete(file, i)
    };
    setDialogdetails(dialogData);
    setOpenDialog(true);
  };

  // -------------------------------------------------------------
  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {/*
          Waiver Form Lable 
        */}
        <div
          className={
            !exceptionId
              ? !isModal
                ? formClass.containerModal
                : formClass.container
              : formClass.exceptionWaiverContainer
          }
        >
          <Grid
            container
            spacing={3}
            className={
              !exceptionId
                ? formClass.sectionWrapper
                : formClass.sectionWrapperExceptions
            }
            style={exceptionId ? { width: "calc(100% + 12px)" } : {}}
          >
            <Grid item xs={12} container>
              <Grid item xs={6} sm={11}>
                <React.Suspense fallback={null}>
                  <ShowDialog
                    dialogdetails={dialogdetails}
                    openDialog={openDialog}
                    setOpenDialog={setOpenDialog}
                  />
                </React.Suspense>
                {!setReRequestContainer && (
                  <>
                    {props?.waiverDetails?.details?.representableTestName !==
                    "General Waiver Request" ? (
                      <Typography
                        className={formClass.headingTypography}
                        data-testid="waiver Request"
                      >
                        Waiver Request
                      </Typography>
                    ) : (
                      <Typography
                        className={formClass.headingTypography}
                        data-testid="waiver Request"
                      >
                        General Waiver Request
                      </Typography>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          {isModal && !exceptionId ? (
            <Grid item xs={12}>
              <div className={formClass.divider} />
            </Grid>
          ) : null}

          {/* 
          Waiver Form Fields Container
        */}
          <form
            onSubmit={currentWaiver ? submitReRequestWaiver : submitRequest}
          >
            <Grid
              container
              className={formClass.waiverFormContainer}
              spacing={4}
              direction={exceptionId ? "row" : "column"}
              style={
                isModal && window.location.href.indexOf("waiver") === -1
                  ? { paddingLeft: 0, paddingRight: 0 }
                  : {}
              }
            >
              <Grid item xs={12}>
                {/* 
                  Waiver form input fields
                */}

                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={formLabelsTheme}>
                    {props?.waiverDetails?.details?.representableTestName ===
                      "General Waiver Request" && (
                      <>
                        <Grid item>
                          <InputLabel
                            required
                            className={commonClass.labelText}
                          >
                            {`Waiver Subject `}
                          </InputLabel>
                          <TextField
                            fullWidth
                            style={{
                              marginBottom: "14px"
                            }}
                            placeholder="Write here"
                            InputProps={{
                              classes: { input: classes.multilineColor }
                            }}
                            id="fullWidth"
                            variant="outlined"
                            onChange={(e) =>
                              setGeneralWaiverSub(e.target.value)
                            }
                          />
                        </Grid>
                      </>
                    )}
                    <Grid container spacing={3}>
                      {mitigatingFactorList.map((item, index) => (
                        <MitigatingFactorField
                          key={item.fieldKey}
                          index={
                            mitigatingFactorList.length > 1 ? index + 1 : 0
                          }
                          fieldKey={item.fieldKey}
                          required={item.required}
                          waiverMitigates={currentWaiver?.waiverMitigates}
                          deleteCallback={() => {
                            removeMitigatingFactor(item.fieldKey);
                            const newWaiverData: any = { ...waiverData };
                            delete newWaiverData[item.fieldKey];
                            setWaiverData(newWaiverData);
                          }}
                          formData={waiverData}
                          setFormData={setWaiverData}
                          isSubmitDisabled={isSubmitDisabled}
                          setIsSubmitDisabled={setIsSubmitDisabled}
                          exceptionId={exceptionId}
                        />
                      ))}
                    </Grid>
                  </ThemeProvider>
                </StyledEngineProvider>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="info"
                  startIcon={<AddIcon />}
                  style={{
                    backgroundColor: "#ffffff",
                    color: "#32325d"
                  }}
                  onClick={() => {
                    addMitigatingFactor();
                  }}
                >
                  Add Mitigating Factor
                </Button>
              </Grid>
              {/* File list display */}
              {filesList.length ? (
                <Grid item xs={12} style={{ padding: "0px 16px" }}>
                  {Object.entries(filesList).map(([key, value], i) => (
                    <div
                      key={value.uniqueId}
                      className={classes.fileBarWrapper}
                    >
                      <React.Suspense fallback={null}>
                        <FileBar
                          value={value}
                          deleteHandler={() => deleteBtnHandler(value, i)}
                        />
                      </React.Suspense>
                    </div>
                  ))}
                </Grid>
              ) : //   Zero State
              null}
              <SubmitWaiver
                exceptionId={exceptionId}
                setReRequestContainer={setReRequestContainer}
                isSubmitDisabled={isSubmitDisabled}
                setOpenDialog={setOpenDialog}
                filesList={filesList}
                setDialogdetails={setDialogdetails}
                setFilesList={setFilesList}
                RequestAndTakeAction={
                  isLATUser() ? RequestAndTakeAction : undefined
                }
                selectedCompensatingFactors={selectedCompensatingFactors}
                setWaiverResponseType={setWaiverResponseType}
                setselectedCompensatingFactors={setselectedCompensatingFactors}
                waiverSubject={
                  !generalWaiverSub.length &&
                  props?.waiverDetails?.details?.representableTestName ===
                    "General Waiver Request"
                }
              />
            </Grid>
          </form>
        </div>
      </Grid>
    </>
  );
};
