import React from "react";
import { Typography } from "@mui/material";
import { useStyles } from "./ExceptionCardStyle";
import { getAccountTypeFirstLetter } from "./ExceptionsCard";
import { OriginatorIconWithDots, ToorakIconWithDots } from "../Icons";
import { isLATUser } from "../../utils/AccessManagement";

export interface UserDetailsCardProps {
  heading?: any;
  headingId?: any;
  userImg?: any;
  accountNameImg?: any;
  userFullName?: any;
  accountType?: any;
  date?: any;
  alternateText?: any;
  sidePanel?: any;
  exceptionType?: string;
  exceptionSource?: string;
}
export const UserDetailsCard = (props: UserDetailsCardProps) => {
  const {
    heading,
    accountNameImg,
    userFullName,
    accountType,
    date,
    alternateText,
    headingId,
    sidePanel,
    exceptionType,
    exceptionSource
  } = props;
  const accountTypeFirstLetter =
    accountType !== undefined && accountType
      ? getAccountTypeFirstLetter(accountType)
      : "";
  const classes = useStyles();
  return (
    <>
      {heading !== undefined && heading ? (
        <span
          className={`${classes.mobileViewRequestedUser} ${classes.requestedUser}`}
        >
          {heading}
        </span>
      ) : null}

      {alternateText !== "" && alternateText ? (
        <span
          className={
            headingId ? classes.lightColorHeading : classes.lightColorAlternate
          }
          // style={{ textTransform: "capitalize" }}
        >
          {alternateText}
        </span>
      ) : null}
      {headingId !== undefined && headingId ? (
        <span
          className={`${classes.mobileViewRequestedUser} ${classes.requestedUser}`}
          style={{ display: "inline" }}
        >
          {headingId}
        </span>
      ) : null}
      {!sidePanel && accountNameImg && accountNameImg !== "" ? (
        <div
          className={`${classes.circle} user-name-circle`}
          style={{ display: "inline" }}
        >
          <span className={classes.nameInitials}>{accountNameImg}</span>
        </div>
      ) : (
        ""
      )}
      {userFullName || accountTypeFirstLetter !== "" ? (
        <span
          className={
            alternateText ? classes.lightColorCompactUser : classes.lightColor
          }
          style={{ display: "inline" }}
        >
          {userFullName}
        </span>
      ) : null}
      {accountTypeFirstLetter !== "" ? (
        <>
          <span
            className={classes.accountPadding}
            style={{ display: "inline" }}
          >
            {accountTypeFirstLetter[0] !== "O" ? (
              <ToorakIconWithDots />
            ) : (
              <OriginatorIconWithDots />
            )}
          </span>
        </>
      ) : (
        ""
      )}
      {date && date !== "" && date !== "Invalid Date" ? (
        <>
          <span
            className={
              userFullName?.length > 15 && alternateText
                ? classes.lightColorDateBigText
                : alternateText
                ? classes.lightColorDate
                : classes.lightColor
            }
            style={{ display: "inline" }}
          >
            {userFullName?.length > 15 && alternateText ? `${date} ` : date}
          </span>
        </>
      ) : null}
      {exceptionType?.toLowerCase() === "condition" && (
        <div className={classes.tagsStageContainer}>
          <Typography className={classes.tagsStageItem}>
            {exceptionSource === "CONNECT" ||
            (exceptionSource === "WORKFLOW" && !isLATUser())
              ? "LBT"
              : exceptionSource === "WORKFLOW"
              ? "SDD"
              : "DD"}
          </Typography>
        </div>
      )}
    </>
  );
};
