import { Button, Grid, TextField } from "@mui/material";
import { ConfirmFormModal } from "@toorak/tc-common-fe-sdk";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  addProvision,
  getProvisionHistory
} from "../../../stores/drawRequest/drawRequest.action";
import { DrawRequestStore } from "../../../stores/drawRequest/drawRequest.interface";
import { RootState } from "../../../stores/rootReducer";
import { useDrawsStyles } from "../Draws.styles";
import { ProvisionHistory } from "./ProvisionHistory";
import { isAMTView } from "../../AssetManagement.utils";
import { isOrigUser } from "../../../utils/AccessManagement";

export const ProvisionHistoryWrapper = ({ provisionHistory }: any) => {
  const classes = useDrawsStyles();
  const [openFormModel, setOpenFormModel] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [provisionVal, setProvisionVal] = useState<string>("");
  const [provisionObj, setProvisionObj] = useState({
    originatorLoanId: "",
    toorakLoanId: "",
    provisionId: ""
  });

  const [historyData, setHistoryData] = useState<[]>([]);

  useEffect(() => {
    setHistoryData(provisionHistory);
  }, [provisionHistory]);

  let { drawDetails } = useSelector<RootState, DrawRequestStore>(
    (state) => state.drawRequestStore
  );

  const provisionHistorycallBack = (data: any, _primaryLoanId: string) => {
    setHistoryData(data);
  };

  const addCallback = (primaryLoanId: string) => {
    dispatch(getProvisionHistory(primaryLoanId, provisionHistorycallBack));
  };

  const successHandler = () => {
    dispatch(
      addProvision(
        provisionObj.originatorLoanId,
        provisionObj.toorakLoanId,
        provisionVal,
        provisionObj.provisionId,
        addCallback
      )
    );

    setProvisionVal("");
    setOpenFormModel(false);
  };

  useEffect(() => {
    if (drawDetails) {
      setProvisionObj({
        originatorLoanId: drawDetails.primaryLoanId,
        toorakLoanId: drawDetails.toorakLoanId,
        provisionId: ""
      });
    }

    // eslint-disable-next-line
  }, [drawDetails.primaryLoanId]);

  const getBody = () => {
    return (
      <Grid
        item
        container
        xs={12}
        className={classes.textarea}
        data-testid="draw-view-itemHeaderText"
      >
        <div style={{ width: "95%" }}>
          {" "}
          <TextField
            autoFocus
            value={provisionVal}
            multiline
            className={classes.textAreaClass}
            rows={6}
            InputProps={{
              className: classes.editBody
            }}
            placeholder="Enter Provision"
            onChange={(e) => {
              setProvisionVal(e.target.value);
            }}
            style={{ width: "100%", paddingLeft: "30px" }}
          />
        </div>
      </Grid>
    );
  };
  return (
    <Grid item container xs={12} className={`${classes.itemContainer}`}>
      <Grid
        item
        container
        xs={2}
        md={2}
        sm={2}
        xl={2}
        className={classes.itemHeaderText}
        style={{ paddingTop: 5, paddingBottom: 10 }}
      >
        Provision History
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={12}
        sm={12}
        xl={12}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "17px"
        }}
      >
        {(isAMTView() || isOrigUser()) && (
          <Button
            variant="contained"
            color="primary"
            classes={{ root: classes.provisionButton }}
            style={{
              position: "absolute",

              display: "inline-block",
              marginTop: "-40px"
            }}
            onClick={() => {
              setOpenFormModel(true);
            }}
          >
            Add Provisions
          </Button>
        )}
        <ConfirmFormModal
          isOpen={openFormModel}
          handleClose={() => {
            setOpenFormModel(false);
            setProvisionVal("");
          }}
          successHandler={successHandler}
          confirmCondition={provisionVal === ""}
          containerStyle={{ overflow: "hidden" }}
          header={"Add provision"}
          body={getBody()}
          primaryBtnName={"Add"}
          size="lg"
          // disableBackdropClick={true}
          headerStyles={{
            padding: 17,
            borderBottom: "solid 1px #dddddd"
          }}
          footerStyles={{
            borderTop: "solid 1px #dddddd",
            padding: "0px 0px 3px 17px",
            margin: "0 0px 14px 0px"
          }}
        />
      </Grid>

      <Grid
        item
        container
        xs={12}
        sm={12}
        md={12}
        xl={12}
        data-testid="draw-view-fieldContainer"
        className={`${classes.fieldContainer}`}
      >
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={12}
          xl={12}
          data-testid="draw-view-primary-loan"
          className={classes.fieldRow}
        >
          <ProvisionHistory historyData={historyData} actionReq={false} />
        </Grid>
      </Grid>
    </Grid>
  );
};
