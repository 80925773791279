import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    approvedTestWrapper: {
      display: "flex",
      flexDirection: "row",
      borderRadius: 4,
      boxShadow: "1px 1px 5px #D3D3D3",
      width: "100%",
      padding: "15px 12px 10px 12px"
      // fontFamily: "OpenSans"
    },
    approvedLabel: {
      borderRadius: "6px",
      fontWeight: 600,
      padding: "1px 9px",
      display: "flex",
      justifyContent: "space-around",
      whiteSpace: "nowrap"
    },
    testNameText: {
      fontSize: "12px !important",
      color: "#32325d",
      fontWeight: 700
    },
    approvedIcon: {
      width: 16,
      height: 16,
      color: "white"
    },
    approvedText: {
      fontFamily: "OpenSans",
      fontSize: "10px",
      color: "#ffffff",
      padding: "2px"
    },
    valueText: {
      fontSize: "11px",
      color: "#7a8aa0",
      // fontFamily: "OpenSans",
      fontWeight: "normal"
    },
    profileImage: {
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      background: "#512DA8",
      fontSize: "12px",
      color: "#fff",
      textAlign: "center",
      lineHeight: "30px"
    },
    infoContainer: {
      paddingTop: "30px",
      alignItems: "center"
    },
    avatarClass: {
      padding: "0px 3px 5px 3px"
    }
  })
);
