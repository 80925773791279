import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    controlsBox: {
      display: "flex",
      height: "70px",
      alignItems: "center",
      backgroundColor: "#ffffff",
      width: "100%",
      margin: "0",
      padding: "5px 20px 5px 20px",
      maxHeight: 60,
      borderTop: "solid 1px #e9ecef"
    },
    warningModal: {
      fontSize: " 13px",
      padding: "10px"
    },
    confirmFormModalClass: {
      "&.MuiDialog-paperWidthSm": {
        marginBottom: "600px"
      },
      marginBottom: "600px"
    },
    menuStatus: {
      color: "#10010090",
      fontSize: "9px",
      fontWeight: "normal",
      width: "30%",
      justifyContent: "flex-end",
      display: "flex"
    },
    menuFileName: {
      fontSize: "12px",
      width: "70%",
      fontWeight: "normal",
      justifyContent: "flex-start",
      display: "flex",
      marginRight: "20px"
    },
    deleteDocCol: {
      display: "block",
      width: "3%"
    },
    backdrop: {
      zIndex: 1,
      width: "calc(100% - 500px)",
      maxWidth: "1100px",
      height: "82.5%",
      backgroundColor: "#ffffff",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      overflowY: "hidden",
      opacity: 0.6,
      "&.MuiBackdrop-root": {
        top: "inherit",
        bottom: "inherit",
        left: "inherit",
        right: "inherit",
        position: "absolute"
      }
    },
    splittingProgressBar: {
      backgroundColor: "#e9fafd",
      padding: "10px",
      maxWidth: "67%",
      borderRadius: "4px",
      fontSize: "12px",
      fontWeight: 200
    },

    dropdown: {
      backgroundColor: "white",
      "& .MuiSelect-select": {
        color: "#32325d",
        fontSize: "13px",
        fontWeight: "normal",
        "&:focus": {
          margin: "2px 0 0 0 !important",
          backgroundColor: "white !important",
          padding: "10px 14px !important"
        }
      }
    },
    saveBtn: {
      padding: "7px 24px 7px 24px",
      borderRadius: 4,
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      borderWidth: 1,
      color: "#333",
      borderColor: "#ececec",
      width: "90px",
      height: "36px"
    },
    splitBtn: {
      padding: "7px 24px 7px 24px",
      borderRadius: 4,
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      borderWidth: 1,
      marginLeft: "7px",
      borderColor: "#ececec",
      height: "36px"
    },
    rightBoxContainer: {
      width: "calc(100% - 500px)",
      maxWidth: "1100px",
      height: "89%",
      backgroundColor: "#ffffff",
      overflowY: "auto",
      borderTop: "solid 1px #e9ecef"
    },
    header: {
      padding: "16px 10px 16px 10px",
      color: "#32325d",
      fontSize: "14px",
      fontWeight: 600,
      backgroundColor: "#f6f9fc"
    },
    headerText: {
      fontSize: "11px",
      color: "#32325d"
    },
    formControlGroup: {
      display: "flex",
      "& .MuiFormControlLabel-root": {
        display: "flex",
        margin: "0px"
      }
    },
    tableContent: {
      display: "flex",
      padding: "8px 0",
      alignItems: "center",
      borderBottom: "1px solid #dddddd",
      "&:hover": {
        backgroundColor: "#f8f9ff",
        border: "1px solid #b9c1f0",
        borderRadius: "4px"
      }
    },
    tableContentNew: {
      display: "flex",
      padding: "5px 0",
      alignItems: "center",
      borderBottom: "1px solid #dddddd",
      backgroundColor: "white"
    },
    highlightedBorder: {
      display: "flex",
      padding: "8px 0",
      alignItems: "center",
      backgroundColor: "#eff1fd",
      border: "1px solid #5f72e4",
      borderRadius: "4px"
    },
    highlightedBorderNew: {
      display: "flex",
      padding: "5px 0",
      alignItems: "center",
      backgroundColor: "#eff1fd !important",
      border: "1px solid #5f72e4 !important",
      borderRadius: "4px !important"
    },
    manualTag: {
      flexGrow: 0,
      maxWidth: "45%",
      flexBasis: "45%",
      height: 33
    },
    dataContent: {
      fontSize: "13px",
      color: "transparent",
      fontWeight: "normal",
      width: "85%"
    },
    dataText: {
      fontSize: "12px",
      color: "#32325d",
      fontWeight: "normal",
      padding: "10px 0px",
      paddingRight: "20px",
      wordBreak: "break-word"
    },
    showAll: {
      margin: 0,
      marginRight: 10,
      "& .MuiTypography-body1": {
        fontSize: 10,
        fontWeight: "normal"
      }
    },
    buttonDropdown: {
      backgroundColor: "#ffffff",
      color: "#000000",
      height: 33,
      width: "100%",
      justifyContent: "left",
      fontWeight: "normal",
      "&:disabled": {
        color: "#000000 !important",
        opacity: "0.7 !important",
        backgroundColor: "#ffffff !important"
      },
      "&:hover": {
        backgroundColor: "#ffffff"
      }
    },
    mainWrapper: {
      width: "235px",
      height: "360px",
      margin: "8px 0 23px 0",
      padding: "0 0 3px",
      borderRadius: "4px",
      boxShadow: "0 2px 8px 0 rgb(0 0 0 / 16%)",
      backgroundColor: "var(--pale-grey)"
    },
    textFieldContainer: {
      width: "85%",
      alignSelf: "center",
      position: "relative",
      backgroundColor: "white",
      borderRadius: "4px",
      "& .MuiInputBase-root": {
        fontSize: "12px"
      },
      "&:hover": {
        border: "none",
        backgroundColor: "#ffffff"
      }
    },
    documentData: {
      color: "#5e72e4",
      cursor: "pointer"
    },
    leftBoxContainer: {
      alignItems: "center",
      justifyContent: "center",
      border: "solid 1px #e9ecef",
      height: "89%",
      backgroundColor: "#ffffff",
      minWidth: "500px",
      [theme.breakpoints.up(1600)]: {
        width: "calc(100% - 1100px)"
      },
      position: "relative"
    },
    deleteDisabled: {
      pointer: "none",
      opacity: 0.5
    },
    loadingDataContainer: {
      display: "flex",
      flexDirection: "column",
      borderRadius: 4,
      justifyContent: "start",
      paddingTop: "200px",
      overflow: "auto",
      alignItems: "center",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      width: "100%",
      height: "100%"
    },
    loadingDataHeading: {
      margin: "31px 0 14px 0",
      color: "#32325d",
      fontSize: "14px"
    },
    emptyTagCode: {
      border: "1px solid red"
    },
    radioCol: {
      width: 40
    },
    fileNameCol: {
      width: "calc(33% - 40px)"
    },
    tagCol: {
      width: "17%"
    },
    mappingCol: {
      width: "25%"
    },
    primaryCol: {
      display: "block",
      width: "11%",
      maxWidth: 90,
      minWidth: 64
    },
    latestCol: {
      display: "block",
      width: "11%",
      maxWidth: 90
    },
    lowOpacity: {
      opacity: 0.7
    }
  })
);
