/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
// import {
//   SET_SORT_VALUE,
//   SET_SEARCH_VALUE,
//   SET_SELECTED_LIST,
//   SET_LIST,
//   SET_PRICING_FILTERS,
//   UPDATE_DYNAMIC_PRICING,
//   UPDATE_SELECTED_STAGE,
//   RESET_DYNAMIC_PRICING,
//   SET_FILTER_DROPDOWN_DATA,
//   SET_LIST_LENGTH,
//   SET_UPDATED_LOANS,
//   GET_EDIT_HISTORY,
//   SET_CUSTOMERS_DATA,
//   SET_GLOBAL_SEARCH_VALUE,
//   SET_HAS_UPDATES
// } from "./pricing-grid.action";
import { ObjectType } from "../masterView/common";

// interface DynamicPricingFields {}
export interface PricingGridStore {
  list: any[];
  selectedLoanDetails: ObjectType[];
  dynamicPricingFields: any;
  selectedLoanType: string;
  sortConfig: ObjectType;
  filtersConfig: ObjectType;
  searchConfig: ObjectType;
  globalSearchValue: string;
  dropDownData: any[];
  listLength: number;
  updatedLoansMap: ObjectType;
  editHistory: ObjectType;
  customersData: ObjectType;
  hasLoanUpdates: boolean;
}

export const initialState: PricingGridStore = {
  list: [],
  selectedLoanDetails: [],
  dynamicPricingFields: {},
  selectedLoanType: "",
  sortConfig: {},
  filtersConfig: {},
  searchConfig: {},
  globalSearchValue: "",
  dropDownData: [],
  listLength: 0,
  updatedLoansMap: {},
  editHistory: {},
  customersData: {},
  hasLoanUpdates: false
};

const pricingGridSlice = createSlice({
  name: "pricingGrid",
  initialState,
  reducers: {
    setList: (state, action) => {
      state.list = action.payload;
    },
    setSelectedList: (state, action) => {
      state.selectedLoanDetails = action.payload;
    },
    updateDynamicPricing: (state, action) => {
      const { value, field, resultType } = action.payload;
      if (state.dynamicPricingFields[resultType]) {
        state.dynamicPricingFields[resultType] = {
          ...state.dynamicPricingFields[resultType],
          [field]: value
        };
      } else {
        state.dynamicPricingFields[resultType] = { [field]: value };
      }

      if (!value) {
        if (state.dynamicPricingFields[resultType])
          delete state.dynamicPricingFields[resultType][field];
      }
    },
    updateSelectedStage: (state, action) => {
      state.selectedLoanType = action.payload.loanType;
    },
    resetDynamicPricing: (state) => {
      state.dynamicPricingFields = initialState.dynamicPricingFields;
    },
    updateSortConfig: (state, action) => {
      state.sortConfig = action.payload;
    },
    setSearchValuePricingGrid: (state, action) => {
      state.searchConfig = action.payload;
    },
    setGlobalSearchValuePGrid: (state, action) => {
      state.globalSearchValue = action.payload;
    },
    setPricingFilters: (state, action) => {
      state.filtersConfig = action.payload;
    },
    setFilterDropdownData: (state, action) => {
      state.dropDownData = action.payload;
    },
    setListLength: (state, action) => {
      state.listLength = action.payload;
    },
    setUpdatedPricingListMap: (state, action) => {
      state.updatedLoansMap = action.payload;
    },
    setEditPricingHistory: (state, action) => {
      state.editHistory = action.payload;
    },
    setCustomersData: (state, action) => {
      state.customersData = action.payload;
    },
    setHasUpdates: (state, action) => {
      state.hasLoanUpdates = action.payload;
    }
  }
});

export const {
  setList,
  setSelectedList,
  updateDynamicPricing,
  updateSelectedStage,
  resetDynamicPricing,
  updateSortConfig,
  setSearchValuePricingGrid,
  setGlobalSearchValuePGrid,
  setPricingFilters,
  setFilterDropdownData,
  setListLength,
  setUpdatedPricingListMap,
  setEditPricingHistory,
  setCustomersData,
  setHasUpdates
} = pricingGridSlice.actions;

export const PricingGridReducer = pricingGridSlice.reducer;

// export const PricingGridReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case SET_LIST: {
//       const nextState = produce(state, draftState => {
//         draftState.list = action.payload;
//       });
//       return nextState;
//     }
//     case SET_SELECTED_LIST: {
//       const nextState = produce(state, draftState => {
//         draftState.selectedLoanDetails = action.payload;
//       });
//       return nextState;
//     }
//     case UPDATE_DYNAMIC_PRICING: {
//       const { value, field, resultType } = action.payload;
//       const nextState = produce(state, draftState => {
//         if (draftState.dynamicPricingFields[resultType]) {
//           draftState.dynamicPricingFields[resultType] = {
//             ...draftState.dynamicPricingFields[resultType],
//             [field]: value
//           };
//         } else {
//           draftState.dynamicPricingFields[resultType] = { [field]: value };
//         }

//         if (!value) {
//           if (draftState.dynamicPricingFields[resultType])
//             delete draftState.dynamicPricingFields[resultType][field];
//         }
//       });
//       return nextState;
//     }
//     case UPDATE_SELECTED_STAGE: {
//       const { loanType } = action.payload;
//       const nextState = produce(state, draftState => {
//         draftState.selectedLoanType = loanType;
//       });
//       return nextState;
//     }
//     case RESET_DYNAMIC_PRICING: {
//       const nextState = produce(state, draftState => {
//         draftState.dynamicPricingFields = initialState.dynamicPricingFields;
//       });
//       return nextState;
//     }
//     case SET_SORT_VALUE: {
//       const nextState = produce(state, draftState => {
//         draftState.sortConfig = action.payload;
//       });
//       return nextState;
//     }
//     case SET_SEARCH_VALUE: {
//       const nextState = produce(state, draftState => {
//         draftState.searchConfig = action.payload;
//       });
//       return nextState;
//     }
//     case SET_GLOBAL_SEARCH_VALUE: {
//       const nextState = produce(state, draftState => {
//         draftState.globalSearchValue = action.payload;
//       });
//       return nextState;
//     }
//     case SET_PRICING_FILTERS: {
//       const nextState = produce(state, draftState => {
//         draftState.filtersConfig = action.payload;
//       });
//       return nextState;
//     }
//     case SET_FILTER_DROPDOWN_DATA: {
//       const nextState = produce(state, draftState => {
//         draftState.dropDownData = action.payload;
//       });
//       return nextState;
//     }
//     case SET_LIST_LENGTH: {
//       const nextState = produce(state, draftState => {
//         draftState.listLength = action.payload;
//       });
//       return nextState;
//     }
//     case SET_UPDATED_LOANS: {
//       const nextState = produce(state, draftState => {
//         draftState.updatedLoansMap = action.payload;
//       });
//       return nextState;
//     }
//     case GET_EDIT_HISTORY: {
//       const nextState = produce(state, draftState => {
//         draftState.editHistory = action.payload;
//       });
//       return nextState;
//     }
//     case SET_CUSTOMERS_DATA: {
//       const nextState = produce(state, draftState => {
//         draftState.customersData = action.payload;
//       });
//       return nextState;
//     }
//     case SET_HAS_UPDATES: {
//       const nextState = produce(state, draftState => {
//         draftState.hasLoanUpdates = action.payload;
//       });
//       return nextState;
//     }
//     default:
//       return state;
//   }
// };
