/** This file will be moved to common utils. Dont put any other code here except for field of laon and property */

export interface FieldInfoForValidation {
  fieldType: string;
}
export interface FieldInfoForValidationObjects {
  [index: string]: FieldInfoForValidation;
}
export const loanInformationFields: FieldInfoForValidationObjects = {
  existingDebtCurrency: {
    fieldType: "currency"
  },
  closingCostsCurrency: {
    fieldType: "currency"
  },
  appraiserDYAsStabilizedPercentage: {
    fieldType: "percentageWithout100x"
  },
  exceptions: {
    fieldType: "string"
  },
  approved: {
    fieldType: "string"
  },
  comments: {
    fieldType: "string"
  },
  excessFirstLossCurrency: {
    fieldType: "currency"
  },
  interestReserveIRCurrency: {
    fieldType: "currency"
  },
  twelveMonthIRCurrency: {
    fieldType: "currency"
  },
  annualDSInitialLoanCurrency: {
    fieldType: "currency"
  },
  annualDSMaxLoanCurrency: {
    fieldType: "currency"
  }
};

export const loanCreditFields: FieldInfoForValidationObjects = {
  inPlaceOccupancyNumeric: {
    fieldType: "number"
  },
  inPlaceNOINumeric: {
    fieldType: "number"
  },
  inPlaceDSCRNumeric: {
    fieldType: "number"
  },
  stabilizedDSCRNumeric: {
    fieldType: "number"
  },
  takeoutDSCRNumeric: {
    fieldType: "number"
  },
  covenants: {
    fieldType: "string"
  },
  readyforPurchase: {
    fieldType: "string"
  },
  summaryOutputSent: {
    fieldType: "string"
  },
  purchaseAdviceRequirement: {
    fieldType: "string"
  },
  recommendation: {
    fieldType: "string"
  },
  additionalNotes: {
    fieldType: "string"
  },
  purchaseDate: {
    fieldType: "date"
  },
  appraiserName: {
    fieldType: "string"
  },
  appraisalCompany: {
    fieldType: "string"
  },
  appraisalManagementCompany: {
    fieldType: "string"
  },
  appraisalFormType: {
    fieldType: "string"
  },
  licenseNumber: {
    fieldType: "string"
  },
  appraisalDate: {
    fieldType: "date"
  },
  appraisalComments: {
    fieldType: "string"
  },
  restricted: {
    fieldType: "string"
  },
  appraisalReview: {
    fieldType: "string"
  },
  appraisalReviewDate: {
    fieldType: "date"
  },
  appraisalReviewComments: {
    fieldType: "string"
  },
  engineeringReview: {
    fieldType: "string"
  },
  engineeringReviewDate: {
    fieldType: "date"
  },
  engineeringReviewComments: {
    fieldType: "string"
  },
  environmentalReview: {
    fieldType: "string"
  },
  environmentalReviewDate: {
    fieldType: "date"
  },
  environmentalReviewComments: {
    fieldType: "string"
  },
  seismicReview: {
    fieldType: "string"
  },
  seismicReviewDate: {
    fieldType: "date"
  },
  seismicReviewComments: {
    fieldType: "string"
  },
  truliaCrimeCheck: {
    fieldType: "string"
  },
  phase1HoldbackPercentage: {
    fieldType: "percentage"
  },
  phase1HoldbackAmount: {
    fieldType: "currency"
  },
  phase1ClosingDate: {
    fieldType: "date"
  },
  phase1ClosingDatePayment: {
    fieldType: "currency"
  }
};

export const propertyInformationFields: FieldInfoForValidationObjects = {
  description: {
    fieldType: "description"
  },
  propertyType: {
    fieldType: "dropDown"
  },
  propertyUnits: {
    fieldType: "number"
  },
  preRehabSquareFootage: {
    fieldType: "number"
  },
  postRehabSquareFootage: {
    fieldType: "number"
  },
  squareFootage: {
    fieldType: "number"
  },
  occupiedPercentage: {
    fieldType: "number"
  }
};

export const propertyEconomicsFields: FieldInfoForValidationObjects = {
  annualHoaFee: {
    fieldType: "currency"
  },
  closingCost: {
    fieldType: "number"
  }
};

export const unitInformationFields: FieldInfoForValidationObjects = {
  leaseInPlace: {
    fieldType: "currency"
  },
  leaseStatus: {
    fieldType: "string"
  },
  currentLeaseTerm: {
    fieldType: "number"
  },
  marketRentMonthly: {
    fieldType: "currency"
  },
  currentLeaseTermMonthly: {
    fieldType: "number"
  }
};
