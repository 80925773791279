import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import { LoanServicerNames } from "../../utils/constants";
import { ServicerOnboardingStore } from "./servicerOnboarding.interface";

export const initialState: ServicerOnboardingStore = {
  onboardingResult: [],
  onboardingCustomColumnList: {},
  servicerCalculatedMandatoryFields: {},
  servicerMandatoryFields: {
    loanEconomics: [],
    drawDetails: [],
    loanInfo: [],
    loanUserMap: [],
    propertyEconomics: [],
    propertyInformation: [],
    propertyUnit: [],
    propertyLocation: [],
    loanSummary: [],
    loanFeature: [],
    loanSettlement: [],
    resultsToorakInterests: []
  },
  lineFieldOptions: {
    blockCode: [],
    investorCode: [],
    investorDefaultValue: "",
    blockDefaultCode: ""
  },
  onboardingLoanResults: {},
  onboardingLoanSettlement: {},
  onboardingLoanDetails: {
    loanBorrowerInformation: [],
    loanType: undefined,
    originatorInfo: undefined,
    servicerName: LoanServicerNames.bsi,
    drawDetails: {
      drawDate: "",
      drawAmount: ""
    },
    loanInformation: {
      primaryLoanID: "",
      numberOfProperties: "",
      extensionOption: "",
      loanPurpose: "",
      selectedLoanStructure: "",
      selectedRecourse: "",
      selectedCross: "",
      selectedToorakProduct: "",
      cutOffDate: null,
      originationDate: null,
      firstPaymentDate: null,
      maturityDate: null,
      pledgeOfEquity: "",
      sellerPartyId: ""
    },
    loanEconomics: {
      interestRate: "",
      accrualType: "",
      financialInterestReserve: "",
      loanAmount: "",
      maxLoanAmount: "",
      cutOffLoanAmount: "",
      cutOffMaxLoanAmount: "",
      budgetAmount: "",
      cashOutAmount: "",
      discountPoints: "",
      insuranceAndAmortization: "",
      financedBudgetAmount: "",
      lateChargePercentage: "",
      lateCharge: "",
      armRoundFlag: "",
      armRoundingFactor: "",
      armLookBackDays: "",
      monthlyQualifyingPayment: "",
      originalMonthlyPiPayment: "",
      includeOutOfPocketBudgetInARLTV: true
    },
    loanSummary: {},
    loanDetailId: {},
    loanState: LoanStatusEnum.Purchased,
    loanGuarantorInformation: []
  },
  onboardingResults: {},
  onboardingPropertyDetails: [],
  servicerDocsDetails: [],
  onboardingData: {},
  onboardingStatusHistory: [],
  activeServicerTab: "servicerLoanDetails",
  onboardingTableFilterConfig: undefined,
  onboardingTableSortConfig: undefined,
  onboardingTableSearchConfig: undefined,
  onboardingSelectedLoans: [],
  onboardingSelectedRowsData: [],
  onboardingSearchValue: "",
  onboardingSearchMappingValue: "",
  selectedMappingDetails: [],
  servicerHistory: [],
  servicerHistoryById: [],
  servicerMandatoryDocTags: [],
  servicerMissingDocsName: [],
  servicerMappingResult: [],
  onboardingSelectedMappings: [],
  lineObj: {},
  servicerMappingObj: {},
  servicerCount: 0,
  servicerInternalNotes: [],
  assignToDDList: [],
  selectedLoans: undefined
};

const servicerOnboardingSlice = createSlice({
  name: "servicerOnboarding",
  initialState,
  reducers: {
    updateOnboardingData: (state, action: PayloadAction<any>) => {
      state.onboardingResult = action.payload;
    },
    updateTableSelectedFilters: (state, action: PayloadAction<any>) => {
      state.onboardingTableFilterConfig = action.payload;
    },
    updateAssigneeList: (state, action: PayloadAction<any>) => {
      state.assignToDDList = action.payload;
    },
    updateTableSearchOptions: (state, action: PayloadAction<any>) => {
      state.onboardingTableSearchConfig = action.payload;
    },
    updateTableSortOptions: (state, action: PayloadAction<any>) => {
      state.onboardingTableSortConfig = action.payload;
    },
    setSelectedLoans: (state, action: PayloadAction<any>) => {
      state.onboardingSelectedLoans = action.payload;
    },
    setSelectedRowsData: (state, action: PayloadAction<any>) => {
      state.onboardingSelectedRowsData = action.payload;
    },
    setPurchasedLoanData: (state, action: PayloadAction<any>) => {
      state.onboardingLoanDetails = action.payload;
    },
    setPurchasedLoanResult: (state, action: PayloadAction<any>) => {
      state.onboardingLoanResults = action.payload;
    },
    setPurchasedLoanSettlement: (state, action: PayloadAction<any>) => {
      state.onboardingLoanSettlement = action.payload;
    },
    setPurchasedPropertyData: (state, action: PayloadAction<any>) => {
      state.onboardingPropertyDetails = action.payload;
    },
    setPurchasedDocsDetails: (state, action: PayloadAction<any>) => {
      state.servicerDocsDetails = action.payload;
    },
    updateGlobalSearchValue: (state, action: PayloadAction<any>) => {
      state.onboardingSearchValue = action.payload;
    },
    updateGlobalSearchMappingValue: (state, action: PayloadAction<any>) => {
      state.onboardingSearchMappingValue = action.payload;
    },
    updateServicerMappingDetails: (state, action: PayloadAction<any>) => {
      state.selectedMappingDetails = action.payload;
    },
    updateServicerMappingData: (state, action: PayloadAction<any>) => {
      state.servicerMappingResult = action.payload;
    },
    updateAllOriginatorServicerMappingHistory: (
      state,
      action: PayloadAction<any>
    ) => {
      state.servicerHistory = action.payload;
    },
    updateOriginatorServicerMappingHistoryById: (
      state,
      action: PayloadAction<any>
    ) => {
      state.servicerHistoryById = action.payload;
    },
    setServicerMandatoryDocs: (state, action: PayloadAction<any>) => {
      state.servicerMandatoryDocTags = action.payload;
    },
    setServicerMandatoryFields: (state, action: PayloadAction<any>) => {
      state.servicerMandatoryFields = action.payload;
    },
    getCalculatedMandatoryFields: (state, action: PayloadAction<any>) => {
      state.servicerCalculatedMandatoryFields = action.payload;
    },
    setServicerMissingDocsName: (state, action: PayloadAction<any>) => {
      state.servicerMissingDocsName = action.payload;
    },
    setSelectedMappings: (state, action: PayloadAction<any>) => {
      state.onboardingSelectedMappings = action.payload;
    },
    setOnboardingData: (state, action: PayloadAction<any>) => {
      state.onboardingData = action.payload;
      state.lineObj = {
        warehouseLine: action.payload.investorWarehouseLine,
        investorCode: action.payload.investorCode,
        blockCode: action.payload.blockCode
      };
    },
    setOnboardingStatusHistory: (state, action: PayloadAction<any>) => {
      state.onboardingStatusHistory = action.payload;
    },
    updateOnboardingDataStatus: (state, action: PayloadAction<any>) => {
      let result = {
        ...state.onboardingData,
        onboardStatus: action.payload
      };
      state.onboardingData = result;
    },
    setActiveServicerTab: (state, action) => {
      state.activeServicerTab = action.payload;
    },
    updateLineObj: (state, action: PayloadAction<any>) => {
      state.lineObj = action.payload;
    },
    setLineFieldOptions: (state, action: PayloadAction<any>) => {
      state.lineFieldOptions = action.payload;
    },
    updateServicerCount: (state, action: PayloadAction<any>) => {
      state.servicerCount = action.payload;
    },
    updateServicerDetails: (state, action: PayloadAction<any>) => {
      state.servicerMappingObj = action.payload;
    },
    updateInternalNotes: (state, action: PayloadAction<any>) => {
      state.servicerInternalNotes = action.payload;
    }
  }
});

export const {
  updateOnboardingData,
  updateTableSelectedFilters,
  updateAssigneeList,
  updateTableSearchOptions,
  updateTableSortOptions,
  setSelectedLoans,
  setSelectedRowsData,
  setPurchasedLoanData,
  setPurchasedLoanResult,
  setPurchasedLoanSettlement,
  setPurchasedPropertyData,
  setPurchasedDocsDetails,
  updateGlobalSearchValue,
  updateGlobalSearchMappingValue,
  updateServicerMappingDetails,
  updateServicerMappingData,
  updateAllOriginatorServicerMappingHistory,
  updateOriginatorServicerMappingHistoryById,
  setServicerMandatoryDocs,
  setServicerMandatoryFields,
  getCalculatedMandatoryFields,
  setServicerMissingDocsName,
  setSelectedMappings,
  setOnboardingData,
  setOnboardingStatusHistory,
  updateOnboardingDataStatus,
  setActiveServicerTab,
  updateLineObj,
  setLineFieldOptions,
  updateServicerCount,
  updateServicerDetails,
  updateInternalNotes
} = servicerOnboardingSlice.actions;

export default servicerOnboardingSlice.reducer;
