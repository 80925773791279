/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { PDFViewer } from "../pdf-viewer/PDFViewer";
import { DocumentViewerType } from "../../types";
import {
  getPresignedURL,
  getPresignedURLForDrawDocs
} from "../../../../network/apiService";
import { NoPreview } from "./no-preview/NoPreview";
import {
  EXCEL_FILE_TYPE,
  IMAGE_FILE_TYPE,
  PDF_FILE_TYPE,
  VIEW_OFFICE_APP_LINK
} from "../../constants";
import { URLParamsType } from "../../../../utils/constants";
//
import { PDFViewerBudgetReview } from "../pdf-viewer/PDFViewerBudgetReview";

export const DocumentViewer = (props: DocumentViewerType) => {
  const {
    state,
    documentReviewDispatch,
    document,
    highlightChecked,
    onChangeHighlightSelect,
    highlightDisabled,
    top = 48
  } = props;
  const { loanId, drawId } = useParams<URLParamsType>();
  const [presignedURL, setPresignedURL] = useState<string>("");

  const fetchPresignedURL = async () => {
    let responseData: any;
    let url: any;
    if (drawId) {
      responseData = await getPresignedURLForDrawDocs(drawId, document.id);
      url = responseData?.data?.downloadLinks?.[0].url;
    } else if (loanId) {
      responseData = await getPresignedURL("LOAN", loanId, document?.id);
      url = responseData?.data?.url;
    }
    if (!url) return null;
    setPresignedURL(url);
  };

  const encodedURL: string = useMemo(() => {
    if (!document) return "";
    if (!EXCEL_FILE_TYPE.includes(document?.fileType?.toLowerCase())) return "";
    const eUrl = encodeURIComponent(presignedURL);
    const urlPath = `${VIEW_OFFICE_APP_LINK}?src=${eUrl}`;
    return urlPath;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presignedURL]);

  useEffect(() => {
    if (!document || document?.name === "" || !document?.id) {
      setPresignedURL("");
      return;
    }
    if (PDF_FILE_TYPE.includes(document?.fileType?.toLowerCase())) {
      return;
    }
    fetchPresignedURL();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  const renderFile = () => {
    if (!document) {
      return (
        <div
          style={{
            textAlign: "center",
            position: "relative",
            top: "50%",
            fontSize: 16,
            fontWeight: "bold"
          }}
        >
          Select a Document to Preview
        </div>
      );
    }
    if (IMAGE_FILE_TYPE?.includes(document?.fileType?.toLowerCase())) {
      return (
        <img
          src={presignedURL}
          alt={document?.name}
          style={{
            width: "100%",
            position: "absolute",
            top
          }}
        />
      );
    }
    if (EXCEL_FILE_TYPE.includes(document?.fileType?.toLowerCase())) {
      return (
        <iframe
          id="xlsIframe"
          title="unique"
          src={encodedURL}
          width="100%"
          height="100%"
        />
      );
    }
    if (PDF_FILE_TYPE.includes(document?.fileType?.toLowerCase())) {
      if (window.location.href.includes("budgetReview")) {
        return (
          <PDFViewerBudgetReview
            // state={state}
            documentReviewDispatch={documentReviewDispatch}
            cacheKey={document?.id?.toString()}
            name={document?.name as string}
            highlightChecked={highlightChecked}
            onChangeHighlightSelect={onChangeHighlightSelect}
            highlightDisabled={highlightDisabled}
          />
        );
      }
      return (
        <PDFViewer
          state={state}
          documentReviewDispatch={documentReviewDispatch}
          cacheKey={document?.id}
          name={document?.name as string}
          highlightChecked={highlightChecked}
          onChangeHighlightSelect={onChangeHighlightSelect}
          highlightDisabled={highlightDisabled}
        />
      );
    }
    return <NoPreview url={presignedURL} name={document?.name} />;
  };

  return <>{renderFile()}</>;
};
