import { Grid } from "@mui/material";
import { ViewModeTextField } from "@toorak/tc-common-fe-sdk";
import React from "react";
import { formatValueByType } from "../../utils/formatChecks";
import { MinimizableSection } from "../form-components/MinimizableSection";

export const subMarketDataFields = [
  {
    fieldLabel: "Submarket Name",
    fieldId: "subMarketName",
    fieldType: "string"
  },
  {
    fieldLabel: "Cap Rate",
    fieldId: "capRate",
    fieldType: "percentage"
  },
  {
    fieldLabel: "12 Month Rental Growth",
    fieldId: "twelveMonthRentalGrowth",
    fieldType: "percentage"
  },
  {
    fieldLabel: "Rent Per SQFT",
    fieldId: "rentPerSqft",
    fieldType: "currency"
  },
  {
    fieldLabel: "Rent Per Unit",
    fieldId: "rentPerUnit",
    fieldType: "currency"
  },
  {
    fieldLabel: "Sale Price Growth",
    fieldId: "salePerGrowth",
    fieldType: "percentage"
  },
  {
    fieldLabel: "Sale Price Per Unit",
    fieldId: "salePerPriceUnit",
    fieldType: "currency"
  },
  {
    fieldLabel: "Vacancy Rate",
    fieldId: "vacancyRate",
    fieldType: "percentage"
  }
];

export const marketDataFields = [
  {
    fieldLabel: "Market Name",
    fieldId: "marketName",
    fieldType: "string"
  },
  {
    fieldLabel: "Cap Rate",
    fieldId: "capRate",
    fieldType: "percentage"
  },
  {
    fieldLabel: "12 Month Rental Growth",
    fieldId: "twelveMonthRentalGrowth",
    fieldType: "percentage"
  },
  {
    fieldLabel: "Rent Per SQFT",
    fieldId: "rentPerSqft",
    fieldType: "currency"
  },
  {
    fieldLabel: "Rent Per Unit",
    fieldId: "rentPerUnit",
    fieldType: "currency"
  },
  {
    fieldLabel: "Sale Price Growth",
    fieldId: "salePerGrowth",
    fieldType: "percentage"
  },
  {
    fieldLabel: "Sale Price Per Unit",
    fieldId: "salePerPriceUnit",
    fieldType: "currency"
  },
  {
    fieldLabel: "Population",
    fieldId: "population",
    fieldType: "number"
  },
  {
    fieldLabel: "Vacancy Rate",
    fieldId: "vacancyRate",
    fieldType: "percentage"
  }
];
export const MarketData = (props: {
  data: any;
  isEvaluationPanelVisible: any;
  sectionId: string;
  activePropertyId: any;
  sectionLabel: string;
}) => {
  const {
    data,
    sectionId,
    activePropertyId,
    sectionLabel
  } = props;
  const colSpan= 4;

  const fields = sectionId === "subMarketData" ? subMarketDataFields : marketDataFields;

  return (
    <MinimizableSection
      sectionLabel={sectionLabel}
      sectionId={`${sectionId}-${activePropertyId}`}
      showCalculatedOutputTag
    >
      <Grid
        container
        spacing={4}
        style={{
          padding: 24
        }}
      >
        {fields.map(ele => {
          const { fieldLabel, fieldId, fieldType } = ele;
          const value =
            data && data[sectionId] ? data[sectionId][fieldId] : null;
          return (
            <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
              <ViewModeTextField
                label={fieldLabel}
                value={formatValueByType(value, fieldType)}
              />
            </Grid>
          );
        })}
      </Grid>
    </MinimizableSection>
  );
};
