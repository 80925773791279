/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import {
  GridTextFieldForm as TextFieldForm,
  DropDown
} from "@toorak/tc-common-fe-sdk";
import {
  GridTextFieldProps as TextFieldProps,
  DropDownProps
} from "./FormComponentsProps";
import { sanitizePercentage } from '../../utils/formatChecks';
// import { isServicerOnboardingOnly } from "../../utils/AccessManagement";

// const isServicerMandatory: boolean = isServicerOnboardingOnly();

export function FinancedInterestReserveTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    isViewMode,
    required,
    infoIconData,
    editedLabel,
    editedData,
    enableReason,
    isServicerMandatory
  } = props;
  return (
    <TextFieldForm
      value={value}
      required={required}
      isServicerMandatory={isServicerMandatory}
      onChange={onChange}
      label="Financed Interest Reserve ($)"
      error={error}
      infoIconData={infoIconData}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="financed-interest-reserve-value"
      valueTestId="financed-interest-reserve-value"
      onBlur={onBlur}
      editedData={editedData}
      editedLabel={editedLabel}
      enableReason={enableReason}
    />
  );
}

export function LoanAmountField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    editedData,
    infoIconData,
    isViewMode,
    required,
    editedLabel,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      isServicerMandatory={isServicerMandatory}
      onChange={onChange}
      label="Initial Loan Amount ($)"
      error={error}
      infoIconData={infoIconData}
      editedData={editedData}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="loan-amount"
      valueTestId="loan-amount-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
    />
  );
}

export function CurrentLoanBalance(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    infoIconData,
    onBlur,
    editedLabel,
    editedData,
    isViewMode,
    required,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      isServicerMandatory={isServicerMandatory}
      onChange={onChange}
      label="Current Loan Balance($)"
      error={error}
      infoIconData={infoIconData}
      editedData={editedData}
      editedLabel={editedLabel}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="current-loan-balance"
      valueTestId="loan-balance-value"
      onBlur={onBlur}
      enableReason={enableReason}
    />
  );
}

export function InterestRateTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    editedData,
    infoIconData,
    helperText,
    onBlur,
    isViewMode,
    required,
    editedLabel,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      isServicerMandatory={isServicerMandatory}
      onChange={onChange}
      label="Interest Rate (%)"
      error={error}
      infoIconData={infoIconData}
      editedData={editedData}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="interest-rate"
      valueTestId="interest-rate-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
    />
  );
}

export function RateTypeTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    infoIconData,
    editedData,
    onBlur,
    isViewMode,
    required,
    editedLabel,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      isServicerMandatory={isServicerMandatory}
      onChange={onChange}
      label="Rate Type"
      infoIconData={infoIconData}
      editedData={editedData}
      error={error}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="rate-type"
      valueTestId="rate-type-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
    />
  );
}

export function AccrualTypeTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    editedData,
    infoIconData,
    helperText,
    onBlur,
    isViewMode,
    required,
    editedLabel,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      isServicerMandatory={isServicerMandatory}
      onChange={onChange}
      label="Accrual Type"
      error={error}
      infoIconData={infoIconData}
      editedData={editedData}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="accrual-type"
      valueTestId="accrual-type-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
    />
  );
}

export function DiscountPointsTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    infoIconData,
    editedData,
    onBlur,
    isViewMode,
    required,
    editedLabel,
    enableReason
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      label="Total Origination and Discount Points (%)"
      error={error}
      infoIconData={infoIconData}
      editedData={editedData}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="origination-and-discount-points"
      valueTestId="origination-and-discount-points-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
    />
  );
}

export function CashOutAmountField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    editedData,
    helperText,
    onBlur,
    infoIconData,
    isViewMode,
    required,
    editedLabel,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      isServicerMandatory={isServicerMandatory}
      label="Cash Out Amount ($)"
      error={error}
      infoIconData={infoIconData}
      editedData={editedData}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="cash-out-amount"
      valueTestId="cash-out-amount-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
    />
  );
}

export function CutOffDateLoanAmountField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    infoIconData,
    editedData,
    helperText,
    onBlur,
    isViewMode,
    required,
    editedLabel,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      isServicerMandatory={isServicerMandatory}
      label="Cut-off Date Loan Amount ($)"
      error={error}
      infoIconData={infoIconData}
      editedData={editedData}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="cut-off-date-loan-amount"
      valueTestId="cut-off-date-loan-amount-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
    />
  );
}

export function ARMProductTypeTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    editedData,
    helperText,
    onBlur,
    isViewMode,
    editedLabel,
    required,
    enableReason
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      label="ARM Product Type"
      error={error}
      editedLabel={editedLabel}
      editedData={editedData}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="arm-product-type"
      valueTestId="arm-product-type-value"
      onBlur={onBlur}
      enableReason={enableReason}
    />
  );
}

export function ARMIndexTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    isViewMode,
    editedData,
    editedLabel,
    required,
    enableReason
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      editedData={editedData}
      label="ARM Index"
      editedLabel={editedLabel}
      error={error}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="arm-index"
      valueTestId="arm-index-value"
      onBlur={onBlur}
      enableReason={enableReason}
    />
  );
}

export function ARMGrossMarginTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    isViewMode,
    editedData,
    required,
    editedLabel,
    enableReason
  } = props;
  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      editedData={editedData}
      editedLabel={editedLabel}
      label="Gross Margin (For ARMs)"
      error={error}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="arm-gross-margin"
      valueTestId="arm-gross-margin-value"
      onBlur={onBlur}
      enableReason={enableReason}
    />
  );
}

export function InterestOnlyPeriodTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    isViewMode,
    editedData,
    editedLabel,
    required,
    enableReason
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      editedData={editedData}
      label="Interest Only Period"
      editedLabel={editedLabel}
      error={error}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="interest-only-period"
      valueTestId="interest-only-period-value"
      onBlur={onBlur}
      enableReason={enableReason}
    />
  );
}

export function OriginalMonthlyPaymentTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    isViewMode,
    editedData,
    editedLabel,
    required,
    enableReason
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      editedData={editedData}
      label="Original Monthly P&I Payment ($)"
      error={error}
      editedLabel={editedLabel}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="original-monthly-pi-payment"
      valueTestId="monthly-payment-value"
      onBlur={onBlur}
      enableReason={enableReason}
    />
  );
}

export function InitialRateAdjustmentPeriodTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    isViewMode,
    required,
    editedData,
    editedLabel,
    enableReason
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      editedData={editedData}
      label="Initial Rate Adjustment Period"
      error={error}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="initial-rate-adjustment-period"
      valueTestId="rate-adjustment-period-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
    />
  );
}

export function InitialPayAdjustmentPeriodTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    isViewMode,
    editedData,
    required,
    editedLabel,
    enableReason
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      editedData={editedData}
      label="Initial Pay Adjustment Period"
      error={error}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="initial-pay-adjustment-period"
      valueTestId="pay-adjustment-period-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
    />
  );
}

export function InitialPeriodicCapTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    isViewMode,
    editedData,
    required,
    editedLabel,
    enableReason
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      editedData={editedData}
      label="Initial Periodic Cap"
      error={error}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="initial-periodic-cap"
      valueTestId="periodic-cap-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
    />
  );
}
export function InsuranceAndAmmortization(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    editedData,
    isViewMode,
    required,
    editedLabel,
    enableReason
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      label="Insurance And Ammortization ($)"
      error={error}
      editedData={editedData}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="insuranceAndAmmortization"
      valueTestId="insuranceAndAmmortization-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
    />
  );
}
export function LifeRateCapTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    isViewMode,
    required,
    editedLabel,
    enableReason
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      label="Life Rate Cap"
      error={error}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="life-rate-cap"
      valueTestId="rate-cap-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
    />
  );
}

export function SubsequentPeriodicCapTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    isViewMode,
    required,
    editedData,
    editedLabel,
    enableReason
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      editedData={editedData}
      label="Subsequent Periodic Cap"
      error={error}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="subsequent-periodic-cap"
      valueTestId="subsequent-periodic-cap-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
    />
  );
}

export function PaymentAdjustmentFrequencyTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    isViewMode,
    required,
    editedData,
    editedLabel,
    enableReason
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      editedData={editedData}
      label="Payment Adjustment Frequency"
      error={error}
      helperText={helperText}
      editedLabel={editedLabel}
      isViewMode={isViewMode}
      data-testid="payment-adjustment-frequency"
      valueTestId="payment-frequency-value"
      onBlur={onBlur}
      enableReason={enableReason}
    />
  );
}

export function RateAdjustmentFrequencyTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    editedData,
    onBlur,
    isViewMode,
    required,
    editedLabel,
    enableReason
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      editedData={editedData}
      editedLabel={editedLabel}
      label="Rate Adjustment Frequency"
      error={error}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="rate-adjustment-frequency"
      valueTestId="rate-adjust-frequency-value"
      onBlur={onBlur}
      enableReason={enableReason}
    />
  );
}

export function PrepaymentPenaltyMonthsTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    isViewMode,
    editedData,
    required,
    editedLabel,
    enableReason
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      editedLabel={editedLabel}
      onChange={onChange}
      label="Prepayment Penalty Months"
      error={error}
      editedData={editedData}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="prepayment-penalty-months"
      valueTestId="prepayment-penalty-months-value"
      onBlur={onBlur}
      enableReason={enableReason}
    />
  );
}

export function PrepayPenaltiesTypeTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    isViewMode,
    editedData,
    editedLabel,
    required,
    enableReason
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      editedData={editedData}
      editedLabel={editedLabel}
      onChange={onChange}
      label="Prepay Penalties Type"
      error={error}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="prepay-penalty-type"
      valueTestId="prepay-penalty-type-value"
      onBlur={onBlur}
      enableReason={enableReason}
    />
  );
}

export function PrepayPenaltiesDropDown(props: DropDownProps) {
  const {
    selectionValue,
    selectionOnChange,
    selectionData,
    helperText,
    isViewMode,
    editedData,
    editedLabel,
    notRequired,
    isLATView,
    onBlur,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <DropDown
      formControlTestId="prepaymentPenaltyType"
      inputLabelId="prepaymentPenaltyType-label"
      inputLabelTestId="prepaymentPenaltyType-label-test-id"
      label="Prepayment Penalty Type"
      selectId="prepaymentPenaltyType-input-select-id"
      selectTestId="prepaymentPenaltyType-select-test-id"
      selectionValue={selectionValue}
      selectionOnChange={selectionOnChange}
      selectionData={selectionData}
      helperText={helperText}
      isViewMode={isViewMode}
      editedLabel={editedLabel}
      isLATView={isLATView}
      onBlur={onBlur}
      editedData={editedData}
      notRequired={notRequired}
      valueTestId="prepaymentPenaltyType-value"
      data-testid="prepaymentPenaltyType-value"
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
}

export function CLTVTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    isViewMode,
    editedData,
    required,
    editedLabel,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      editedLabel={editedLabel}
      onChange={onChange}
      editedData={editedData}
      label="LTV(Loan to Value)"
      error={error}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="CLTV-type"
      valueTestId="CLTV-type-value"
      onBlur={onBlur}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
}

export function DSCRTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    isViewMode,
    editedData,
    editedLabel,
    required,
    isServicerMandatory,
    enableReason
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      editedLabel={editedLabel}
      onChange={onChange}
      editedData={editedData}
      label="DSCR"
      error={error}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="dscr-value"
      valueTestId="dscr-type-value"
      style={{ marginBottom: 10 }}
      onBlur={onBlur}
      enableReason={enableReason}
      isServicerMandatory={isServicerMandatory}
    />
  );
}

export function CutOffDateMaxLoanAmountField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    editedData,
    infoIconData,
    isViewMode,
    required,
    editedLabel,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      editedData={editedData}
      isServicerMandatory={isServicerMandatory}
      label="Cut-off Date Maximum Loan Amount ($)"
      error={error}
      infoIconData={infoIconData}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="cut-off-date-max-loan-amount"
      valueTestId="cut-off-date-max-loan-amount-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
    />
  );
}

export function AccrualTypeDropDown(props: DropDownProps) {
  const {
    selectionValue,
    selectionOnChange,
    selectionData,
    onBlur,
    editedData,
    helperText,
    isViewMode,
    editedLabel,
    infoIconData,
    notRequired,
    isLATView,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <DropDown
      formControlTestId="accrual-type-form-control"
      inputLabelId="accrual-type-input-label"
      inputLabelTestId="accrual-type-input-label-test-id"
      label="Accrual Type"
      selectId="accrual-type-input-select-id"
      selectTestId="accrual-type-input-select-test-id"
      selectionValue={selectionValue}
      editedData={editedData}
      isServicerMandatory={isServicerMandatory}
      selectionOnChange={selectionOnChange}
      selectionData={selectionData}
      infoIconData={infoIconData}
      helperText={helperText}
      onBlur={onBlur}
      isViewMode={isViewMode}
      isLATView={isLATView}
      editedLabel={editedLabel}
      valueTestId="accrual-type-value"
      data-testid="accrual-type-value"
      notRequired={notRequired}
      enableReason={enableReason}
    />
  );
}

export function OriginationAndDiscountPointsField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    editedData,
    infoIconData,
    isViewMode,
    required,
    editedLabel,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      isServicerMandatory={isServicerMandatory}
      label="Total Origination and Discount Points (%)"
      error={error}
      helperText={helperText}
      editedData={editedData}
      infoIconData={infoIconData}
      isViewMode={isViewMode}
      data-testid="origination-and-discount-points"
      valueTestId="origination-and-discount-points-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
    />
  );
}

export function TotalBudgetAmountField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    editedData,
    infoIconData,
    isViewMode,
    required,
    editedLabel,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      editedData={editedData}
      isServicerMandatory={isServicerMandatory}
      label="Total Rehab Budget ($)"
      error={error}
      helperText={helperText}
      infoIconData={infoIconData}
      isViewMode={isViewMode}
      data-testid="total-budget-amount"
      valueTestId="total-budget-amount-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
    />
  );
}

export function ClosingCostField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    isViewMode,
    required,
    editedLabel,
    enableReason
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      label="Closing cost"
      error={error}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="closing-cost"
      valueTestId="closing-cost-value"
      editedLabel={editedLabel}
      enableReason={enableReason}
    />
  );
}

export function LoanTermField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    isViewMode,
    required,
    editedLabel,
    enableReason
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      label="Loan Term"
      error={error}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="loan-term"
      valueTestId="loan-term-value"
      editedLabel={editedLabel}
      enableReason={enableReason}
    />
  );
}

export function InterestRateField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    isViewMode,
    required,
    editedLabel
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      label="Interest rate"
      error={error}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="interest-rate"
      valueTestId="interest-rate-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
    />
  );
}

export function PrepaymentPenaltyField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    isViewMode,
    required,
    editedLabel,
    enableReason
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      label="Prepayment Penalty (Months)"
      error={error}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="prepayment-penalty"
      valueTestId="prepayment-penalty-value"
      editedLabel={editedLabel}
      enableReason={enableReason}
    />
  );
}

export function MaxLoanAmountField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    isViewMode,
    editedData,
    infoIconData,
    required,
    editedLabel,
    enableReason,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      isServicerMandatory={isServicerMandatory}
      label="Original Maximum Loan Amount ($)"
      error={error}
      infoIconData={infoIconData}
      editedData={editedData}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="max-loan-amount"
      valueTestId="max-loan-amount-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
    />
  );
}

export function BorrowerLiquidityTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    isViewMode,
    required,
    editedLabel,
    editedData,
    enableReason
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      editedData={editedData}
      label="Borrower Liquidity ($)"
      error={error}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="borrower-liquidity-amount"
      valueTestId="borrower-liquidity-amount-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
    />
  );
}

export function OriginalMonthlyQualifyingPaymentField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    isViewMode,
    required,
    editedData,
    editedLabel,
    enableReason
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      editedData={editedData}
      label="Original Monthly Qualifying Payment ($)"
      error={error}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="original-monthly-qualifying-payment-amount"
      valueTestId="original-monthly-qualifying-payment-amount-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
    />
  );
}

export function LifeTimeMaxRateTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    isViewMode,
    editedData,
    required,
    editedLabel,
    enableReason
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      editedData={editedData}
      label="Lifetime Max Rate"
      error={error}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="life-time-max-rate-cap"
      valueTestId="life-time-max-rate-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
    />
  );
}

export function LifeTimeMinRateTextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    isViewMode,
    required,
    editedData,
    editedLabel,
    enableReason
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      editedData={editedData}
      label="Lifetime Min Rate"
      error={error}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="life-time-min-rate-cap"
      valueTestId="life-time-min-rate-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
    />
  );
}

export function MonthsOfQualifyingPaymentInReserves(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    isViewMode,
    required,
    editedData,
    editedLabel,
    enableReason
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      label="Months of qualifying payment in reserves"
      error={error}
      editedData={editedData}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="months-Of-qualifying-payment-in-reserves"
      valueTestId="months-Of-qualifying-payment-in-reserves-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
    />
  );
}

export function LoanLevelOccupancy(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    onBlur,
    isViewMode,
    required,
    editedData,
    editedLabel,
    enableReason
  } = props;

  return (
    <TextFieldForm
      required={required}
      value={value}
      onChange={onChange}
      label="Loan Level Occupancy Percentage"
      error={error}
      editedData={editedData}
      helperText={helperText}
      isViewMode={isViewMode}
      data-testid="loanLevelOccupancy"
      valueTestId="loanLevelOccupancy-value"
      onBlur={onBlur}
      editedLabel={editedLabel}
      enableReason={enableReason}
    />
  );
}
export function SubordinateFinancingDropDown(props: DropDownProps) {
  const {
    selectionValue,
    selectionOnChange,
    selectionData,
    helperText,
    isViewMode,
    editedData,
    editedLabel,
    notRequired,
    isLATView,
    onBlur,
    enableReason
  } = props;

  return (
    <DropDown
      formControlTestId="subordinate-financing"
      inputLabelId="subordinate-financing-label"
      inputLabelTestId="subordinate-financing-label-test-id"
      label="Subordinate Financing"
      selectId="subordinate-financing-input-select-id"
      selectTestId="subordinate-financing-select-test-id"
      selectionValue={selectionValue}
      selectionOnChange={selectionOnChange}
      selectionData={selectionData}
      helperText={helperText}
      isViewMode={isViewMode}
      editedLabel={editedLabel}
      isLATView={isLATView}
      onBlur={onBlur}
      editedData={editedData}
      notRequired={notRequired}
      valueTestId="subordinate-financing-value"
      data-testid="subordinate-financing-value"
      enableReason={enableReason}
    />
  );
}

export function RateTypeDropDown(props: DropDownProps) {
  const {
    selectionValue,
    selectionOnChange,
    selectionData,
    helperText,
    isViewMode,
    editedData,
    editedLabel,
    notRequired,
    isLATView,
    onBlur,
    enableReason
  } = props;

  return (
    <DropDown
      formControlTestId="rateType"
      inputLabelId="rateType-label"
      inputLabelTestId="rateType-label-test-id"
      label="Rate Type"
      selectId="rateType-input-select-id"
      selectTestId="rateType-select-test-id"
      selectionValue={selectionValue}
      selectionOnChange={selectionOnChange}
      selectionData={selectionData}
      helperText={helperText}
      isLATView={isLATView}
      isViewMode={isViewMode}
      onBlur={onBlur}
      editedLabel={editedLabel}
      editedData={editedData}
      notRequired={notRequired}
      valueTestId="rateType-value"
      data-testid="rateType-value"
      enableReason={enableReason}
    />
  );
}
export function BusinessPurposeOccupancyDropDown(props: DropDownProps) {
  const {
    selectionValue,
    selectionOnChange,
    selectionData,
    helperText,
    editedData,
    isViewMode,
    editedLabel,
    notRequired,
    isLATView,
    onBlur,
    enableReason
  } = props;

  return (
    <DropDown
      formControlTestId="business-purpose-occupancy-form-control"
      inputLabelId="business-purpose-occupancy-input-label"
      inputLabelTestId="business-purpose-occupancy-input-label-test-id"
      label="Business Purpose Occupancy"
      selectId="business-purpose-occupancy-input-select-id"
      selectTestId="business-purpose-occupancy-select-test-id"
      selectionValue={selectionValue}
      selectionOnChange={selectionOnChange}
      selectionData={selectionData}
      helperText={helperText}
      isLATView={isLATView}
      isViewMode={isViewMode}
      onBlur={onBlur}
      editedData={editedData}
      editedLabel={editedLabel}
      notRequired={notRequired}
      valueTestId="business-purpose-occupancy-value"
      data-testid="business-purpose-occupancy-value"
      enableReason={enableReason}
    />
  );
}
