/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import { LocationSuggestion, publicClient } from "@toorak/tc-common-fe-sdk";
import { useAutoCorrectStyles } from "@toorak/tc-common-fe-sdk/dist/components/input/autocomplete/AutoCompleteForm";
import { Grid, TextField, Theme, Tooltip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import React, { useEffect, useMemo, useState } from "react";
import { getConfig } from "../config/config";
import { throttle } from "../utils/utils";
import { getZillowURL, getFullAddress } from "../create-loan/appraisal/util";
import GridAutoCompleteAddressField from "./GridAutoCompleteAddressField";

export interface LocationSuggestionWithURL extends LocationSuggestion {
  zillowURL?: string | null;
  fullAddress?: string | null;
}

const inputStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: "5px"
      }
    },
    input: {
      borderRadius: 4,
      position: "relative",
      border: "1px solid #cad1d7",
      fontSize: 14,
      padding: "13px 12px",
      color: "#32325d",
      alignSelf: "flex-end",
      transition: theme.transitions.create(["box-shadow"]),
      "&:focus": {
        "text-decoration-line": "none"
        // borderRadius: "4px",
        // border: "solid 1px #5e72e4 !important",
      }
    }
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    commentBox: {
      marginTop: "20px",
      "& label": {
        width: "100%",
        "& div": {
          "& input": {
            marginBottom: "20px"
          }
        }
      }
    },
    labelContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      // marginTop: "1px",
      width: "100%"
    },
    editedLabelContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: "1px",
      width: "100%"
    },
    labelInformation: {
      fontSize: 12,
      fontWeight: 600,
      color: "#8897aa",
      lineHeight: "16px",
      // height: "14px",
      "&:hover": {
        color: "#5e72e4"
      },
      display: "inline-block",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: "95%",
      textOverflow: "ellipsis",
      pointerEvents: "all"
    },
    labelRequired: {
      color: "#f75676",
      marginLeft: "4px",
      fontSize: "12px",
      // marginBottom: "4px",
      paddingTop: 1,
      display: "inline-block",
      verticalAlign: "top"
    },
    labelRequiredComments: {
      color: "#f75676",
      marginLeft: "4px",
      fontSize: "12px",
      marginBottom: "4px",
      marginTop: "5px"
    },
    iconInfoContainer: {
      height: "16px",
      textAlign: "end"
    },
    iconInfo: {
      width: "16px !important",
      height: "16px !important",
      color: "#32325d",
      "&:hover": {
        color: "#5e72e4"
      }
    },
    tooltip: {
      color: "#32325d",
      backgroundColor: "white",
      fontFamily: `"Open Sans", sans-serif !important`,
      fontSize: 12,
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      padding: "5px"
    },
    arrow: {
      color: "white"
    }
  })
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function AutocompleteAddressField(props: any) {
  const { disabled, isViewMode, onChange, onBlur, showEditedTag, onHover, required, error, helperText } = props;
  const classes = useStyles();
  const inputClasses = inputStyles();
  const [addressInputValue, setAddressInputValue] = useState("");
  const [addressOptions, setAddressOptions] = useState<
    LocationSuggestionWithURL[]
  >([]);
  const autoClasses = useAutoCorrectStyles();
  const fetcLocationSuggestions = useMemo(() => {
    // @ts-ignore
    return throttle(
      (
        request: { input: string },
        callback: (results?: LocationSuggestionWithURL[]) => void
      ) => {
        publicClient
          .get(
            `https://us-autocomplete-pro.api.smartystreets.com/lookup?key=${
              getConfig().smartyStreetsApiKey
            }&search=${request.input}`
          )
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((results: any) =>
            callback(
              results?.data?.suggestions?.map(
                ({
                  street_line,
                  secondary,
                  city,
                  state,
                  zipcode
                }: LocationSuggestion) => ({
                  street_line,
                  city,
                  state,
                  pincode: zipcode,
                  secondary,
                  zipcode,
                  fullAddress: getFullAddress({
                    address: street_line,
                    city,
                    state,
                    postalCode: zipcode
                  }),
                  zillowLink: getZillowURL({
                    address: street_line,
                    city,
                    state,
                    postalCode: zipcode
                  })
                })
              ) as LocationSuggestionWithURL[]
            )
          )
          .catch(() => {});
      },
      200
    );
  }, []);

  useEffect(() => {
    if (addressInputValue === "") return;
    fetcLocationSuggestions(
      { input: addressInputValue },
      (results?: LocationSuggestionWithURL[]) => {
        let newOptions = [] as LocationSuggestionWithURL[];

        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setAddressOptions(newOptions);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressInputValue]);

  const autoCompleteAddressFieldProps = {
    getOptionLabel: (option: LocationSuggestionWithURL) =>
      // eslint-disable-next-line no-nested-ternary
      typeof option === "string"
        ? option
        : option.street_line
        ? `${option.street_line} ${option.city} ${option.state} ${option.zipcode}`
        : "",
    options: addressOptions,
    classes: autoClasses,
    autoComplete: true,
    disableClearable: true,
    onChange: async (
      _event: any,
      newValue: LocationSuggestionWithURL | null
    ) => {
      setAddressOptions(
        newValue ? [newValue, ...addressOptions] : addressOptions
      );
      onChange(_event, newValue);
    },
    onBlur: (event: any) => {
      onBlur(event, addressOptions.length !== 0 ? addressOptions[0] : null);
    },
    onInputChange: (_event: any, newInputValue: string) => {
      setAddressInputValue(newInputValue);
    },
    error: props?.error,
    disabled,
    helperText: props?.helperText,
    value: props?.value ?? "",
    fullWidth: true
  };

  return (
    <div style={{ width: "100%" }}>
      <GridAutoCompleteAddressField
        label={props?.label}
        style={props?.viewModeStyle}
        infoIconData={props?.infoIconData}
        value={
          props?.value
            ? (props?.value as string ?? "")
            : (props?.defaultValue as string ?? "")
        }
        editedLabel={props?.editedLabel}
        isNotInfo={props?.isNotInfo}
        isLATView={props?.isLATView}
        editedData={props?.editedData}
        autoCompleteFieldProps={autoCompleteAddressFieldProps}
        isViewMode={isViewMode}
        showEditedTag={showEditedTag}
        onHover={onHover}
        required={required}
        error={error}
        helperText={helperText}
      />
      {props?.enableReason && (
        <TextField
          InputProps={{ classes: inputClasses }}
          className={classes?.commentBox}
          InputLabelProps={{
            style: {
              position: "relative",
              fontWeight: 600,
              fontSize: 12,
              color: "#32325d",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              // border: "1px solid #cad1d7",
              backgroundColor: "white"
            }
          }}
          onBlur={props?.onReasonInputBlur}
          label={
            <Grid
              container
              item
              xs={12}
              sm={12}
              className={classes?.labelContainer}
            >
              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                container
                style={{ display: "block" }}
              >
                <Tooltip
                  title={`${props?.label}`}
                  placement="right"
                  arrow
                  classes={{
                    tooltip: classes?.tooltip,
                    arrow: classes?.arrow
                  }}
                >
                  <span className={classes?.labelInformation}>
                    Reason For Editing {props?.label}
                  </span>
                </Tooltip>
                <span className={classes?.labelRequiredComments}>
                  <sup>*</sup>
                </span>
              </Grid>
            </Grid>
          }
          style={{
            flex: 1,
            width: "100%",
            marginTop: "20px !important"
          }}
        />
      )}
    </div>
  );
}