import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import HistoryIcon from "@mui/icons-material/HistoryOutlined";
import { useDispatch, useSelector } from "react-redux";
import { BudgetReviewStore } from "../../stores/budgetReview/budgetReview.interface";
import { RootState } from "../../stores/rootReducer";
import { getTimeFromDate } from "../../utils/utils";
import { LoanHistoryStyle } from "../../create-loan/loan-lists/utils/LoanHistoryStyles";
import { useBudgetStyles } from "../BudgetReview.style";
import { InternalRolesEnum } from "../../utils/constants";
import { getListOfAssignee, setILPUserList } from "../../stores/ILP/ILP.action";
import { ILPStore } from "../../stores/ILP/ILP.reducer";
import { HtmlWhiteTooltip } from "../../utils/Tooltip";

export const getDate = (date: string) => {
  const formattedDate = getTimeFromDate(date, "MMM DD, YYYY, HH:mm");
  return formattedDate;
};

export function BudgetHistory(props: any) {
  const loanClasses = LoanHistoryStyle();
  const dispatch = useDispatch();
  const classes = useBudgetStyles();
  const [history, setHistory] = useState<any>([]);
  const { budgetStatusHistory } = useSelector<RootState, BudgetReviewStore>(
    state => state.budgetReviewStore
  );
  const { irQCVMAdminMap } = useSelector<RootState, ILPStore>(
    state => state.ILPStore
  );
  const [showHistory, setShowHistory] = useState(false);

  useEffect(() => {
    if (!budgetStatusHistory.length) return;
    setHistory(budgetStatusHistory);
  }, [budgetStatusHistory]);

  useEffect(() => {
    if (!showHistory) return;
    if (Object.keys(irQCVMAdminMap).length) return;
    getListOfAssignee([
      InternalRolesEnum.LAT_IR,
      InternalRolesEnum.LAT_QC,
      InternalRolesEnum.LAT_VM,
      InternalRolesEnum.LAT_ADMIN,
      InternalRolesEnum.LAT_APPROVER,
      InternalRolesEnum.LAT_INQUIRY
    ]).then((res: any) => {
      dispatch(setILPUserList(res.data));
    });
  }, [showHistory, irQCVMAdminMap, dispatch]);

  const loader = () => {
    return (
      <>
        <Grid className={loanClasses.loaderModal}>
          <Grid
            xs={12}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid>
              <CircularProgress size={35} style={{ marginLeft: 15 }} />
              <Typography>Loading...!</Typography>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };
  return (
    <>
      {showHistory && (
        <Dialog
          fullWidth
          maxWidth="md"
          open
          className={loanClasses.dialog}
          style={{ height: "90%" }}
          onClose={() => setShowHistory(false)}
          aria-labelledby="budget-history-dialog"
          aria-describedby="budget-status-history"
        >
          <DialogContent>
            <Grid
              container
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                height: "calc(100% - 75px)"
              }}
            >
              {/* main modal body */}
              <Grid item xs={12} sm={12}>
                {/* modal header */}
                <Grid container style={{ padding: "20px 28px" }}>
                  <Grid item xs={11}>
                    <b>Budget History</b>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end"
                    }}
                  >
                    <CloseIcon
                      htmlColor="#414169"
                      className={loanClasses.closeIcon}
                      onClick={() => setShowHistory(false)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* {!landMarks && !isAppraisalHistoryLoading ? ( */}
              {history.length === 0 ? (
                loader()
              ) : (
                <Grid container style={{ height: "330px", overflowY: "auto" }}>
                  <TableContainer className={loanClasses.tableWrapper}>
                    <Table stickyHeader id="budgetHistoryTable">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className={classes.budgetHistoryTableHeader}
                          >
                            Status Update
                          </TableCell>
                          <TableCell
                            className={classes.budgetHistoryTableHeader}
                          >
                            Updated At
                          </TableCell>
                          <TableCell
                            className={classes.budgetHistoryTableHeader}
                          >
                            Updated By
                          </TableCell>
                          <TableCell
                            className={classes.budgetHistoryTableHeader}
                          >
                            Status Update Comments
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {history.map((row: any, index: number) => (
                          <TableRow key={index}>
                            <TableCell
                              className={classes.budgetHistoryTableRow}
                            >
                              {row.taskStatus}
                            </TableCell>
                            <TableCell
                              className={classes.budgetHistoryTableRow}
                            >
                              {getDate(row.updatedOn)}
                            </TableCell>
                            <TableCell
                              className={classes.budgetHistoryTableRow}
                            >
                              {irQCVMAdminMap[row.assignedBy] || "--"}
                            </TableCell>
                            <TableCell
                              className={classes.budgetHistoryTableRow}
                            >
                              {row.comment || "--"}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}
            </Grid>
          </DialogContent>
        </Dialog>
      )}
      <HtmlWhiteTooltip title={"History"} placement="right" arrow>
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <Button
            variant="outlined"
            startIcon={
              <HistoryIcon fontSize="large" style={{ fontWeight: "bold" }} />
            }
            onClick={() => {
              setShowHistory(true);
            }}
            style={{ border: "0px solid black", color: " #32325d" }}
          ></Button>
        </div>
      </HtmlWhiteTooltip>
    </>
  );
}
