import { LoanStage } from "@toorak/tc-common-fe-sdk";

export enum filterGroupType {
  loanType = "loanType",
  originators = "originators",
  loanStages = "loanStages",
  status = "status",
  createdBy = "createdBy",
  waiverType = "waiverType",
  days = "days",
  loanId = "loanId",
  tradeId = "tradeId",
  spocPartyInfo = "spocPartyInfo"
}

export enum tabsType {
  all = "",
  waiver = "waiver",
  exception = "exception",
  discussion = "discussion"
}

export enum statusFilterType {
  waiver = "waiver",
  exception = "exception",
  inquiry = "inquiry"
}

export const PreserveDBfiltersKey = "PRESERVE_DB_FILTERS";

export const defaultStatusItems: any = {
  waivers: [
    {
      tab: tabsType.waiver,
      type: statusFilterType.waiver,
      label: "Waiver Requested",
      value: "OPEN",
      checked: false,
      checkBoxDataTestId: "w-requested-fltr-chx"
    },
    {
      tab: tabsType.waiver,
      type: statusFilterType.waiver,
      label: "Waiver Approved",
      value: "APPROVED",
      checked: false,
      checkBoxDataTestId: "w-approved-fltr-chx"
    },
    {
      tab: tabsType.waiver,
      type: statusFilterType.waiver,
      label: "Waiver Conditionally Approved",
      value: "APPROVED-CONDITIONALLY",
      checked: false,
      checkBoxDataTestId: "w-condt-approved-fltr-chx"
    },
    {
      tab: tabsType.waiver,
      type: statusFilterType.waiver,
      label: "Waiver Rejected",
      value: "REJECTED",
      checked: false,
      checkBoxDataTestId: "w-rejected-fltr-chx"
    },
    {
      tab: tabsType.waiver,
      type: statusFilterType.waiver,
      label: "Waiver Rebuttal",
      value: "REBUTTAL",
      checked: false,
      checkBoxDataTestId: "w-rebuttal-fltr-chx"
    },
    {
      tab: tabsType.waiver,
      type: statusFilterType.waiver,
      label: "Waiver Rescinded",
      value: "RESCIND",
      checked: false,
      checkBoxDataTestId: "w-rescind-fltr-chx"
    },
    {
      tab: tabsType.waiver,
      type: statusFilterType.waiver,
      label: "Scenario Requested",
      value: "open",
      checked: false,
      checkBoxDataTestId: "s-requested-fltr-chx"
    },
    {
      tab: tabsType.waiver,
      type: statusFilterType.waiver,
      label: "Seems OK",
      value: "Seems OK",
      checked: false,
      checkBoxDataTestId: "seems-ok-fltr-chx"
    },
    {
      tab: tabsType.waiver,
      type: statusFilterType.waiver,
      label: "Doesn't fit Toorak Guidelines",
      value: "Doesn't fit Toorak Guidelines",
      checked: false,
      checkBoxDataTestId: "dosent-fit-fltr-chx"
    }
  ],
  exception: [
    {
      tab: tabsType.exception,
      type: statusFilterType.inquiry,
      label: "Open",
      value: "Open",
      checked: false,
      checkBoxDataTestId: "i-open-fltr-chx"
    },
    {
      tab: tabsType.exception,
      type: statusFilterType.inquiry,
      label: "Close",
      value: "Close",
      checked: false,
      checkBoxDataTestId: "i-closed-fltr-chx"
    },
    {
      tab: tabsType.exception,
      type: statusFilterType.exception,
      label: "Condition Waived",
      value: "WAIVED",
      checked: false,
      checkBoxDataTestId: "c-waived-fltr-chx"
    },
    {
      tab: tabsType.exception,
      type: statusFilterType.exception,
      label: "Condition Satisfied",
      value: "SATISFIED",
      checked: false,
      checkBoxDataTestId: "c-satisfied-fltr-chx"
    },
    {
      tab: tabsType.exception,
      type: statusFilterType.exception,
      label: "Condition Open",
      value: "OPEN",
      checked: false,
      checkBoxDataTestId: "c-open-fltr-chx"
    },
    {
      tab: tabsType.exception,
      type: statusFilterType.exception,
      label: "Condition Rescinded",
      value: "RESCIND",
      checked: false,
      checkBoxDataTestId: "c-rescinded-fltr-chx"
    },
    {
      tab: tabsType.exception,
      type: statusFilterType.inquiry,
      label: "Inquiry Raised",
      value: "Inquiry Raised",
      checked: false,
      checkBoxDataTestId: "i-raised-fltr-chx"
    },
    {
      tab: tabsType.exception,
      type: statusFilterType.inquiry,
      label: "Inquiry Approved",
      value: "APPROVED",
      checked: false,
      checkBoxDataTestId: "i-approved-fltr-chx"
    },
    {
      tab: tabsType.exception,
      type: statusFilterType.inquiry,
      label: "Inquiry Conditionally Approved",
      value: "APPROVED-CONDITIONALLY",
      checked: false,
      checkBoxDataTestId: "i-app-condt-fltr-chx"
    },
    {
      tab: tabsType.exception,
      type: statusFilterType.inquiry,
      label: "Inquiry Rejected",
      value: "REJECTED",
      checked: false,
      checkBoxDataTestId: "i-rejected-fltr-chx"
    },
    {
      tab: tabsType.exception,
      type: statusFilterType.inquiry,
      label: "Inquiry Re-Opened",
      value: "REOPENED",
      checked: false,
      checkBoxDataTestId: "i-reopened-fltr-chx"
    }
  ],
  discussion: []
};

export const defaultLoanStageItems: any = [
  {
    value: "Select All",
    label: "Select All",
    checked: false,
    checkBoxDataTestId: "pre-fltr-slt-chx"
  },
  {
    value: LoanStage.pre,
    label: "Pre-Close",
    checked: false,
    checkBoxDataTestId: "pre-fltr-chx"
  },
  {
    value: LoanStage.post,
    label: "Post-Close",
    checked: false,
    checkBoxDataTestId: "post-fltr-chx"
  },
  {
    value: LoanStage.fes,
    label: "Guideline Evaluation",
    checked: false,
    checkBoxDataTestId: "fes-fltr-chx"
  }
];

export const defaultLoanTypeItems: any = [
  {
    value: "Select All",
    label: "Select All",
    checked: false,
    checkBoxDataTestId: "loantype-slt-chx"
  },
  {
    value: "Bridge Loan",
    label: "Bridge Loan",
    checked: false,
    checkBoxDataTestId: "bridge-fltr-chx"
  },
  {
    value: "30 Year Loan",
    label: "Investor DSCR",
    checked: false,
    checkBoxDataTestId: "dscr-fltr-chx"
  }
];

// export const defaultLoanTypeScenarioItems: any = [
//   {
//     value: "Bridge Loan",
//     label: "Bridge Loan • Mixed Use",
//     checked: false,
//     checkBoxDataTestId: "bridge-fltr-chx"
//   },
//   {
//     value: "Bridge Loan2",
//     label: "Bridge Loan • 5+ Unit",
//     checked: false,
//     checkBoxDataTestId: "bridge-fltr-chx"
//   },
//   {
//     value: "Bridge Loan3",
//     label: "Bridge Loan • 1-4 Family",
//     checked: false,
//     checkBoxDataTestId: "bridge-fltr-chx"
//   },
//   {
//     value: "Bridge Loan4",
//     label: "Bridge Loan • Ground Up",
//     checked: false,
//     checkBoxDataTestId: "bridge-fltr-chx"
//   },
//   {
//     value: "30 Year Loan",
//     label: "Investor DSCR",
//     checked: false,
//     checkBoxDataTestId: "dscr-fltr-chx"
//   }
// ];

export const defaultDays: any = [
  {
    value: "Select All",
    label: "Select All",
    checked: false,
    checkBoxDataTestId: "days-slt-chx"
  },
  {
    value: "now-7d/d",
    label: "Last 7 Days",
    checked: false,
    checkBoxDataTestId: "7-days-chx"
  },
  {
    value: "now-30d/d",
    label: "Last 30 Days",
    checked: false,
    checkBoxDataTestId: "30-days-chx"
  }
];

export const defaultWaiverTypeItems: any = [
  {
    value: "Select All",
    label: "Select All",
    checked: false,
    checkBoxDataTestId: "waiver-fltr-slt-chx"
  },
  {
    value: "EvaluationResult",
    label: "Tests",
    checked: false,
    checkBoxDataTestId: "tests-fltr-chx"
  },
  {
    value: "Exception",
    label: "Condition",
    checked: false,
    checkBoxDataTestId: "condition-fltr-chx"
  },
  {
    //change the value when updated
    value: "Inquiry",
    label: "Inquiry",
    checked: false,
    checkBoxDataTestId: "inquiry-fltr-chx"
  },
  {
    value: "GeneralWaiver",
    label: "General Waiver",
    checked: false,
    checkBoxDataTestId: "condition-fltr-chx"
  },
  {
    value: "AdjustmentWaiver",
    label: "Waiver on Adjustment",
    checked: false,
    checkBoxDataTestId: "condition-fltr-chx"
  }
];

export const scenarioStatusFilterType: any = [
  {
    value: "Select All",
    label: "Select All",
    checked: false,
    checkBoxDataTestId: "scenario-fltr-slt-chx"
  },
  {
    type: statusFilterType.waiver,
    label: "Scenario Requested",
    value: "open",
    checked: false,
    checkBoxDataTestId: "c-waived-fltr-chx"
  },
  {
    type: statusFilterType.waiver,
    label: "Seems OK",
    value: "Seems OK",
    checked: false,
    checkBoxDataTestId: "c-waived-fltr-chx"
  },
  {
    type: statusFilterType.waiver,
    label: "Doesn't fit Toorak Guidelines",
    value: "Doesn't fit Toorak Guidelines",
    checked: false,
    checkBoxDataTestId: "c-waived-fltr-chx"
  }
];
