import React, { useEffect, useRef, useState } from "react";
import { RenderChats } from "../RenderChats";
import { DrawInternalNotesInterface } from "../../stores/drawRequest/drawRequest.interface";
import iconMinimize from "../../images/icon_minimize.svg";
import iconMaximize from "../../images/icon_maximize.svg";

import { Grid, Tooltip } from "@mui/material";
import { ChatTextArea, useNotesStyles } from "../ChatTextArea";
import { useParams } from "react-router-dom";
import { getConfig } from "../../config/config";
import { getStageBgColor } from "./Constant";
import { MMMDDYYYYDateFormat } from "../../utils/utils";
import { isAMTView } from "../AssetManagement.utils";
import { isOrigUser } from "../../utils/AccessManagement";

export const DrawDiscussionChat = ({
  drawInternalChats,
  drawDiscussionsHistory,
  chatItem,
  handleTextChange,
  handleChatsPost,
  disableChat,
  btnLabel,
  styleProps
}: {
  drawInternalChats: DrawInternalNotesInterface[];
  drawDiscussionsHistory?: any;
  chatItem: any;
  handleTextChange: (e: any) => void;
  handleChatsPost: () => void;
  disableChat?: boolean;
  btnLabel?: string;
  styleProps?: any;
}) => {
  const { drawId } = useParams<any>() as any;
  const classesNotes = useNotesStyles();
  const addChatsRef = useRef<HTMLDivElement>(null);
  const [currentChatId, setCurrentChatId] = useState<string>("");
  const [chantsToRender, setChantsToRender] = useState<any>({});
  const [ordereddraws, setOrderedDraws] = useState<any>([]);
  useEffect(() => {
    if (drawDiscussionsHistory) {
      let updateDiscussionHistory = drawDiscussionsHistory;
      if (
        !isAMTView() &&
        Object.keys(updateDiscussionHistory || {}).length > 0
      ) {
        updateDiscussionHistory = {
          [drawId]: updateDiscussionHistory[drawId]
        };
      }
      const keys = Object.keys(updateDiscussionHistory) || {};
      setChantsToRender(updateDiscussionHistory);
      keys.reverse();
      setOrderedDraws(keys);
    } else {
      setChantsToRender({ [drawId]: drawInternalChats });
    }
  }, [drawDiscussionsHistory, drawInternalChats, drawId]);

  function isMinimized(drawIdHistory: string) {
    return drawIdHistory === currentChatId;
  }

  useEffect(() => {
    setCurrentChatId(drawId);
  }, [drawId]);

  function openInNewTab(id: string) {
    if (!drawDiscussionsHistory) return;
    const urlPath = `drawDetails/${id}`;
    window.open(`${getConfig().redirectUrl}${urlPath}`);
  }

  return (
    <>
      <div id="myForm" style={{ minWidth: "100%" }}>
        <div className={classesNotes.internalNotesTab} style={styleProps}>
          {(drawDiscussionsHistory
            ? ordereddraws
            : Object.keys(chantsToRender)
          ).map(
            (drawIdHistory: string) =>
              chantsToRender[drawIdHistory]?.drawDetails?.stage !== "Draft" && (
                <React.Fragment key={drawIdHistory}>
                  <Grid
                    item
                    xs={12}
                    container
                    data-testid="discussion-draw-header"
                    className={
                      isMinimized(drawIdHistory)
                        ? classesNotes.discussionDrawHeaderMin
                        : classesNotes.discussionDrawHeaderMax
                    }
                  >
                    {drawDiscussionsHistory && isAMTView() && (
                      <>
                        <Grid item xs={9} sm={11}>
                          <span
                            className={classesNotes.drawIdHistory}
                            onClick={() => openInNewTab(drawIdHistory)}
                          >
                            {drawIdHistory}
                          </span>
                          {drawIdHistory === drawId && (
                            <span
                              className={classesNotes.currentDrawTag}
                              data-testid="current-draw-tag"
                              style={{ backgroundColor: "rgb(63 214 142)" }}
                            >
                              {"CURRENT DRAW"}
                            </span>
                          )}
                          {drawIdHistory !== drawId && (
                            <span
                              className={classesNotes.currentDrawTag}
                              data-testid="current-draw-tag"
                              style={{
                                backgroundColor: getStageBgColor(
                                  drawDiscussionsHistory[drawIdHistory]
                                    ?.drawDetails?.stage
                                )
                              }}
                            >
                              {
                                drawDiscussionsHistory[drawIdHistory]
                                  ?.drawDetails?.stage
                              }
                            </span>
                          )}
                          {drawDiscussionsHistory[drawIdHistory]?.drawDetails
                            ?.requestedDate && (
                            <span
                              className={classesNotes.currentDrawTag}
                              data-testid="current-draw-tag"
                              style={{
                                color: "black",
                                backgroundColor: "white"
                              }}
                            >
                              {MMMDDYYYYDateFormat(
                                drawDiscussionsHistory[drawIdHistory]
                                  ?.drawDetails?.requestedDate
                              )}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={3} sm={1}>
                          <Tooltip
                            title={
                              isMinimized(drawIdHistory) ? "Expand" : "Collapse"
                            }
                          >
                            <img
                              // className={classes.headingImage}
                              src={
                                isMinimized(drawIdHistory)
                                  ? iconMinimize
                                  : iconMaximize
                              }
                              alt={
                                isMinimized(drawIdHistory)
                                  ? "Minimize section"
                                  : "Maximize section"
                              }
                              onClick={() => {
                                isMinimized(drawIdHistory)
                                  ? setCurrentChatId("")
                                  : setCurrentChatId(drawIdHistory);
                              }}
                              onKeyDown={() => {
                                isMinimized(drawIdHistory)
                                  ? setCurrentChatId("")
                                  : setCurrentChatId(drawIdHistory);
                              }}
                            />
                          </Tooltip>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  {(isMinimized(drawIdHistory) || !isAMTView()) && (
                    <Grid
                      style={{
                        padding: drawDiscussionsHistory ? "2px 5px 10px" : "0px"
                      }}
                    >
                      <RenderChats
                        Chats={
                          drawDiscussionsHistory
                            ? chantsToRender[drawIdHistory]?.discussions || []
                            : chantsToRender[drawIdHistory]
                        }
                      />
                      {drawIdHistory === drawId &&
                        (isAMTView() || isOrigUser()) && (
                          <div ref={addChatsRef}>
                            <ChatTextArea
                              textValue={chatItem.text}
                              handleTextChange={handleTextChange}
                              handleSubmitClicked={handleChatsPost}
                              btnLabel={btnLabel}
                              disableChat={disableChat}
                            />
                          </div>
                        )}
                    </Grid>
                  )}
                </React.Fragment>
              )
          )}
        </div>
      </div>
    </>
  );
};
