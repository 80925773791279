/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  Suspense,
  useState,
  useEffect,
  useCallback,
  useMemo,
  createContext
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Paper, Container, Box, AppBar, Tabs } from "@mui/material";

import { ObjectType } from "../masterView/common";
import { RootState } from "../stores/rootReducer";
import { tkopStyles } from "./tkop.style";
import FilterWrapper from "./FilterWrapper";
import { TKOPListBlock } from "./TKOPListBlock";
import { TabPanelProps } from "../utils/constants";
//
import {
  getAlloriginatorNames,
  getDDReviewInitiatedLoan,
  getDDReviewNonInitiatedLoan,
  getUnAssignedLoan,
  // updateAppliedFilterStatus,
  setActiveTabServicer,
  getErrorMessages,
  getAllCreditLines
} from "../stores/TKOP/takeoutPartner.action";
import { getServicerTabs } from "./takeoutPartnerTabs";
import { getFilters } from "./TKOPFilter";
import { getBundleList, getLoanListQuery } from "../financibility/Utils";
import { templateKeys } from "./TemplateKeys";

// images
import {
  getLatestAggregateRuleVersion,
  getRateLockOptions
} from "../network/apiService";
import { buyBoxTipsConfig } from "./Utils";
import { formatValueByType } from "../utils/formatChecks";
import { PartnerTabCard, UnAssignedLoansTabCard } from "./TKOPTabCard";
import { setAppliedFilterUpdated } from "../stores/TKOP/takeoutPartner.reducer";

export const TakeOutPartnerContext = createContext<any>(undefined);

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export const TakeoutPartner = () => {
  const classes = tkopStyles();
  const dispatch = useDispatch();

  const {
    activeServicerTab,
    initialReviewLoans,
    DDInitiatedLoan,
    DDNonInitiatedLoan,
    errorMessages,
    creditLines
  } = useSelector<RootState, any>((state) => state.tkopStore);
  const { unAssignedLaonAmountAndCount } = useSelector<RootState, any>(
    (state) => state.tkopStore
  );
  const { originatorNames } = useSelector<RootState, any>(
    (state) => state.tkopStore
  );
  const [servicerTabs, setServicerTabs] = useState<any[]>([]);
  const [filtersData, setFiltersData] = useState<ObjectType>([]);
  const [hasAppliedFilters, setHasAppliedFilters] = useState<boolean>(false);
  const [selectedFiltersData, setSelectedFiltersData] = useState<ObjectType>(
    {}
  );
  const [appliedFiltersData, setAppliedFiltersData] =
    useState<ObjectType | null>(null);

  const [inputValue, setInputValue] = useState<string>("");
  const [dDReviewInputValue, setDDReviewInputValue] = useState<string>("");
  const [dDNonReviewInputValue, setDDNonReviewInputValue] =
    useState<string>("");
  const [aggregateRuleVersions, setAggregateRuleVersions] = useState<any>();

  const getTabValue = (tab: string) => {
    const indexOfActiveTab = servicerTabs.findIndex(
      (item: any) => item.id === tab
    );

    if (indexOfActiveTab === -1 && servicerTabs.length > 0)
      dispatch(setActiveTabServicer(servicerTabs[0].id));
    return indexOfActiveTab || 0;
  };

  const setCurrentlySelectedTab = (tabId: string) => {
    dispatch(setActiveTabServicer(tabId));
    setAppliedFiltersData(null);
    setSelectedFiltersData({});
    dispatch(setAppliedFilterUpdated(true));
    if (tabId !== "UnassignedLoans") {
      const DDReviewNonInitiatedESQuery = getLoanListQuery(
        appliedFiltersData,
        "",
        {},
        0,
        false,
        20,
        false,
        false,
        true,
        false,
        tabId
      );
      dispatch(getDDReviewNonInitiatedLoan(DDReviewNonInitiatedESQuery));
      const DDReviewInitiatedESQuery = getLoanListQuery(
        appliedFiltersData,
        "",
        {},
        0,
        false,
        20,
        false,
        true,
        false,
        false,
        tabId
      );
      dispatch(getDDReviewInitiatedLoan(DDReviewInitiatedESQuery));
    } else {
      const ESQuery = getLoanListQuery(
        appliedFiltersData,
        "",
        {},
        0,
        true,
        20,
        true,
        false,
        false,
        true
      );
      dispatch(getUnAssignedLoan(ESQuery));
    }
  };

  const fetchLoans = (
    globalSearchKey: string = "",
    index: number = 0,
    appliedFilters: ObjectType | null,
    withAggs: boolean = false
  ) => {
    if (activeServicerTab !== "UnassignedLoans") {
      const DDReviewNonInitiatedESQuery = getLoanListQuery(
        appliedFilters,
        globalSearchKey,
        {},
        index,
        false,
        20,
        false,
        false,
        true,
        false,
        activeServicerTab
      );
      dispatch(getDDReviewNonInitiatedLoan(DDReviewNonInitiatedESQuery));
      const DDReviewInitiatedESQuery = getLoanListQuery(
        appliedFilters,
        globalSearchKey,
        {},
        index,
        false,
        20,
        false,
        true,
        false,
        false,
        activeServicerTab
      );
      dispatch(getDDReviewInitiatedLoan(DDReviewInitiatedESQuery));
    } else {
      const ESQuery = getLoanListQuery(
        appliedFiltersData,
        globalSearchKey,
        {},
        index,
        true,
        20,
        true,
        false,
        false,
        true
      );
      dispatch(getUnAssignedLoan(ESQuery));
    }
  };

  const fetchData = (
    globalSearchKey: string = "",
    index: number = 0,
    appliedFilters: ObjectType | null,
    isScrolled: boolean = false
  ) => {
    dispatch(setAppliedFilterUpdated(isScrolled));
    fetchLoans(globalSearchKey, index, appliedFilters, isScrolled);
  };

  const fetchOnlyDDReviewInitaitedData = (
    globalSearchKey: string = "",
    index: number = 0,
    appliedFilters: ObjectType | null,
    isScrolled: boolean = false
  ) => {
    dispatch(setAppliedFilterUpdated(isScrolled));
    if (activeServicerTab !== "UnassignedLoans") {
      const DDReviewInitiatedESQuery = getLoanListQuery(
        appliedFilters,
        globalSearchKey,
        {},
        index,
        false,
        20,
        false,
        true,
        false,
        false,
        activeServicerTab
      );
      dispatch(getDDReviewInitiatedLoan(DDReviewInitiatedESQuery));
    }
  };

  const fetchOnlyDDReviewNotInitaitedData = (
    globalSearchKey: string = "",
    index: number = 0,
    appliedFilters: ObjectType | null,
    isScrolled: boolean = false
  ) => {
    dispatch(setAppliedFilterUpdated(isScrolled));
    if (activeServicerTab !== "UnassignedLoans") {
      const DDReviewNonInitiatedESQuery = getLoanListQuery(
        appliedFilters,
        globalSearchKey,
        {},
        index,
        false,
        20,
        false,
        false,
        true,
        false,
        activeServicerTab
      );
      dispatch(getDDReviewNonInitiatedLoan(DDReviewNonInitiatedESQuery));
    }
  };

  // Below useEffect is to get the aggregate rule versions for all takeoutpartner and store it in redux

  useEffect(() => {
    getLatestAggregateRuleVersion("30year")
      .then((aggRes: any) => {
        const aggregateOverlays: ObjectType[] = [];
        const overlayToTpMap: ObjectType = {};
        aggRes.data.forEach((ruleData: ObjectType) => {
          const overlayData = ruleData.ruleVersionData.individualVersions?.find(
            (item: ObjectType) => item.name === "OVERLAYS"
          );
          if (overlayData) {
            overlayToTpMap[overlayData.version] =
              ruleData?.ruleVersionData?.tpName;
            aggregateOverlays.push(overlayData);
          }
        });
        getRateLockOptions("", aggregateOverlays)
          .then((res: any) => {
            const partnerOverlays: ObjectType = {};
            res.data.data.forEach((overlayData: ObjectType) => {
              const requiredData: ObjectType[] = [];
              overlayData.lookupData.forEach((it: ObjectType) => {
                if (Object.keys(buyBoxTipsConfig).includes(it.pricingType)) {
                  requiredData.push({
                    label: buyBoxTipsConfig?.[it?.pricingType].label,
                    value: formatValueByType(
                      it?.value,
                      buyBoxTipsConfig?.[it?.pricingType]?.type ?? ""
                    )
                  });
                }
              });
              partnerOverlays[overlayToTpMap[overlayData?.version]] =
                requiredData;
            });
            setAggregateRuleVersions(partnerOverlays);
          })
          .catch((err: any) => {
            console.log(err);
          });
      })
      .catch((err: any) => {
        console.error("error is ", err);
      });
  }, []);

  useEffect(() => {
    if (creditLines.length > 0) {
      setServicerTabs(getServicerTabs(creditLines));
    }
  }, [creditLines]);

  const getAllErrorMessages = useCallback(
    (templateKeys: ObjectType) => {
      dispatch(getErrorMessages(templateKeys));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(getAllCreditLines());
    dispatch(getAlloriginatorNames());
    getAllErrorMessages(templateKeys);
  }, [dispatch, getAllErrorMessages]);

  useEffect(() => {
    fetchData(inputValue, 0, appliedFiltersData, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFiltersData, initialReviewLoans.length]);

  const handleCheckBox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, filterKey: string) => {
      const appliedFiltersClone = { ...selectedFiltersData };
      let selectedFilterClone = [...(appliedFiltersClone?.[filterKey] ?? [])];
      if (event.target.checked) {
        selectedFilterClone.push(event.target.value);
      } else {
        selectedFilterClone = selectedFilterClone.filter(
          (option) => option !== event.target.value
        );
      }
      if (selectedFilterClone.length === 0) {
        delete appliedFiltersClone[filterKey];
      } else {
        appliedFiltersClone[filterKey] = selectedFilterClone;
      }
      setHasAppliedFilters(true);
      setSelectedFiltersData({
        ...appliedFiltersClone
      });
    },
    [selectedFiltersData]
  );

  const handleSelectAll = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement>,
      filterKey: string,
      filterOptions: ObjectType[]
    ) => {
      const appliedFiltersClone = { ...selectedFiltersData };
      let selectedFilterClone = [...(appliedFiltersClone?.[filterKey] ?? [])];
      if (event.target.checked) {
        selectedFilterClone = filterOptions.map(
          (option: ObjectType) => option.id
        );
        appliedFiltersClone[filterKey] = selectedFilterClone;
      } else {
        selectedFilterClone = [];
        delete appliedFiltersClone[filterKey];
      }
      setHasAppliedFilters(true);
      setSelectedFiltersData({
        ...appliedFiltersClone
      });
    },
    [selectedFiltersData]
  );

  const setRange = useCallback(
    (val: ObjectType, key: string) => {
      const selected = val?.[key];
      setHasAppliedFilters(true);
      setSelectedFiltersData({
        ...selectedFiltersData,
        [key]: [selected]
      });
    },
    [selectedFiltersData]
  );

  const handleFilterSearch = useCallback((val: string, key: string) => {
    if (key === "bundle.bundleId.raw") {
      getBundleList(null, val, {}, 0, false, 1000).then((resp: any) => {
        const options = resp.data.response.hits.hits.map(
          (bundleData: ObjectType) => ({
            label: bundleData._source.bundleId,
            id: bundleData._source.bundleId
          })
        );
        setFiltersData((filtersData: ObjectType) => ({
          ...filtersData,
          [key]: options
        }));
      });
    }
  }, []);

  const onApply: React.MouseEventHandler = () => {
    setAppliedFiltersData(selectedFiltersData);
    setHasAppliedFilters(false);
    dispatch(setAppliedFilterUpdated(true));
  };

  const onCancel: React.MouseEventHandler = () => {
    setAppliedFiltersData(null);
    setSelectedFiltersData({});
    setHasAppliedFilters(false);
    dispatch(setAppliedFilterUpdated(true));
  };

  const clearSearch = () => {
    setInputValue("");
    fetchData("", 0, appliedFiltersData, true);
  };
  const clearDDNonReviewSearch = () => {
    setDDNonReviewInputValue("");
    fetchOnlyDDReviewNotInitaitedData("", 0, appliedFiltersData, true);
  };
  const clearDDReviewSearch = () => {
    setDDReviewInputValue("");
    fetchOnlyDDReviewInitaitedData("", 0, appliedFiltersData, true);
  };
  const handleSearch = () => {
    dispatch(setAppliedFilterUpdated(true));
    fetchData(inputValue, 0, appliedFiltersData, true);
  };

  const handleDDReviewSearch = () => {
    fetchOnlyDDReviewInitaitedData(
      dDReviewInputValue,
      0,
      appliedFiltersData,
      true
    );
  };

  const handleDDNonReviewSearch = () => {
    fetchOnlyDDReviewNotInitaitedData(
      dDNonReviewInputValue,
      0,
      appliedFiltersData,
      true
    );
  };

  const setViewSearchText = (text: string) => {
    setInputValue(text);
  };
  const setDDReviewViewSearchText = (text: string) => {
    setDDReviewInputValue(text);
  };
  const setDDNonReviewViewSearchText = (text: string) => {
    setDDNonReviewInputValue(text);
  };

  const updatedFiltersConfig = useMemo(() => {
    setFiltersData(originatorNames);
    let tempFilters: any = getFilters(
      filtersData,
      selectedFiltersData,
      originatorNames,
      handleCheckBox,
      handleSelectAll,
      setRange,
      handleFilterSearch
    );

    if (activeServicerTab === "UnassignedLoans") {
      tempFilters = tempFilters.map((filtItem: ObjectType) => {
        if (filtItem.id === "loan.loanState.raw") {
          // eslint-disable-next-line no-param-reassign
          filtItem.config.optionsList = [
            {
              id: "Initial Review - Approved",
              label: "Initial Review - Approved"
            }
          ];
          return {
            ...filtItem
          };
        }
        return filtItem;
      });
    }
    return tempFilters;
  }, [
    filtersData,
    selectedFiltersData,
    originatorNames,
    handleCheckBox,
    handleSelectAll,
    setRange,
    handleFilterSearch,
    activeServicerTab
  ]);

  return (
    <>
      <Grid>
        <Container>
          <div className={classes.tkopContainer}>
            <AppBar position="static">
              <Tabs
                value={getTabValue(activeServicerTab)}
                indicatorColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                className="loans-tab-container"
              >
                {servicerTabs
                  .map((item) => {
                    const purchasedLoanAmountPercent = (
                      (item.purchasedLoanAmount / item.totalLoanAmount) *
                      100
                    ).toFixed(3);
                    const pipelineLoanAmountPercent = (
                      (item.pipelineLoanAmount / item.totalLoanAmount) *
                      100
                    ).toFixed(3);
                    return {
                      ...item,
                      purchasedLoanAmountPercent,
                      pipelineLoanAmountPercent
                    };
                  })
                  .map((item: any, index: number) => (
                    <div className="tab-menu" key={index.toString()}>
                      {index === 0 && (
                        <UnAssignedLoansTabCard
                          tabData={item}
                          setSelectedTab={setCurrentlySelectedTab}
                          selectedTab={activeServicerTab}
                          unAssignedLaonAmountAndCount={
                            unAssignedLaonAmountAndCount
                          }
                        />
                      )}
                      {index !== 0 && (
                        <PartnerTabCard
                          tabData={{
                            ...item,
                            toolTipData: aggregateRuleVersions?.[item.id]
                          }}
                          selectedTab={activeServicerTab}
                          setSelectedTab={setCurrentlySelectedTab}
                        />
                      )}
                    </div>
                  ))}
              </Tabs>
            </AppBar>
          </div>
        </Container>
        <Container maxWidth={false} style={{ padding: "0px 15px" }}>
          <Grid style={{ flexDirection: "row", display: "flex" }}>
            <TakeOutPartnerContext.Provider
              value={{
                appliedFiltersData,
                clearSearch,
                clearDDNonReviewSearch,
                clearDDReviewSearch,
                handleSearch,
                handleDDReviewSearch,
                handleDDNonReviewSearch,
                inputValue,
                dDReviewInputValue,
                dDNonReviewInputValue,
                fetchData,
                initialReviewLoans,
                activeServicerTab,
                DDInitiatedLoan,
                DDNonInitiatedLoan,
                fetchOnlyDDReviewInitaitedData,
                fetchOnlyDDReviewNotInitaitedData,
                creditLines,
                setDDReviewInputValue: setDDReviewViewSearchText,
                setDDNonReviewInputValue: setDDNonReviewViewSearchText,
                setInputValue: setViewSearchText,
                setCurrentlySelectedTab,
                errorMessages
              }}
            >
              <Grid style={{ width: "20%" }}>
                <FilterWrapper
                  filters={updatedFiltersConfig}
                  onApply={onApply}
                  onCancel={onCancel}
                  disableOnApply={!hasAppliedFilters}
                  disableOnCancel={!hasAppliedFilters && !appliedFiltersData}
                  appliedFilterCount={
                    Object.keys(appliedFiltersData ?? {}).length
                  }
                />
              </Grid>
              <Grid
                style={{
                  width: "80%",
                  padding: "0px 0px 0px 10px"
                }}
              >
                <Paper elevation={0}>
                  {servicerTabs.map((item: any, index: number) => (
                    <TabPanel value={1} index={index} key={index.toString()}>
                      <Suspense fallback={<span>Loading...</span>}>
                        <TKOPListBlock
                          unAssignedLoans={initialReviewLoans}
                          activeServicerTab={activeServicerTab}
                          ddReviewLoans={DDInitiatedLoan}
                          ddNonReviewLoans={DDNonInitiatedLoan}
                          originatorNames={originatorNames}
                          // selectedView={selectedView}
                          // setSelectedView={setSelectedView}
                        />
                      </Suspense>
                    </TabPanel>
                  ))}
                </Paper>
              </Grid>
            </TakeOutPartnerContext.Provider>
          </Grid>
        </Container>
      </Grid>
    </>
  );
};
