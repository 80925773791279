import React from "react";
import { Grid, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { IconTooltip } from "@toorak/tc-common-fe-sdk";
import { useParams } from "react-router-dom";
import { formatValueByType, isNullOrUndefined  } from "../../utils/formatChecks";

import { getLoanType } from "../../config/config";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    passedTestWrapper: {
      display: "flex",
      flexDirection: "row",
      borderRadius: 4,
      border: "1px solid rgb(233, 236, 239)",
      width: "100%",
      height: "100%",
      minWidth: "13rem",
      padding: "14px 16px 14px 12px",
      fontFamily: "OpenSans"
    },
    testNameText: {
      fontSize: 12,
      color: "#32325d"
    },
    valueText: {
      fontSize: "14px",
      lineHeight: 1.57,
      color: "#32325d",
      fontFamily: "OpenSans",
      fontWeight: "normal",
      paddingTop: "10px"
    },
    passLabel: {
      borderRadius: "6px",
      backgroundColor: "#2ece89",
      padding: "2px 4px",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      maxWidth: "70px"
    },
    tooltip: {
      color: "#32325d",
      backgroundColor: "white",
      fontFamily: `"Open Sans", sans-serif !important`,
      fontSize: 12,
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      width: "170px",
      padding: "9px"
    },
    arrow: {
      color: "white"
    },
    passIcon: {
      width: 16,
      height: 16,
      color: "white"
    },
    passText: {
      fontFamily: "OpenSans",
      fontSize: "10px",
      color: "#ffffff"
    }
  })
);
export interface PassedResultCardProps {
  fieldName?: string;
  fieldType?: string;
  isTest?: boolean;
  fieldValue?: any;
  propertyId?: any;
  resultType?: string;
  infoContentResult?: any;
}
export const PassedResultCard = (props: PassedResultCardProps) => {
  const {
    fieldName,
    fieldType,
    isTest,
    fieldValue,
    resultType,
    infoContentResult
  } = props;
  const classes = useStyles();
  const { loanStage, loanType } = useParams() as any;
  const loanTypeMapping =
    loanType === getLoanType[0].displayValue ? "bridge" : loanType;

  return (
    <Grid container className={classes.passedTestWrapper}>
      <Grid xs={8} item>
        <Typography
          variant="h5"
          className={classes.testNameText}
          data-testId="test-name"
        >
          {fieldName}
        </Typography>
      </Grid>
      <Grid xs={4} style={{ maxWidth: "fit-content" }} item>
        {isTest && fieldValue === "PASS" && (
          <div className={classes.passLabel}>
            <CheckRoundedIcon
              className={classes.passIcon}
              htmlColor="#2ece89"
            />
            <Typography
              className={classes.passText}
              data-testId="test-pass-label"
            >
              PASS
            </Typography>
          </div>
        )}
      </Grid>
      <Grid container item xs={1} justifyContent="flex-end" alignItems="center">
        <IconTooltip
          clicked={false}
          keyDown={false}
          topic="Information"
          infoIconData={
            infoContentResult &&
            infoContentResult[
              `fieldInfo.${loanTypeMapping}.${loanStage.toLowerCase()}.result.${resultType}.${fieldType}.${fieldName}`
            ]
          }
        />
      </Grid>
      {(!isTest || fieldValue !== "PASS") && (
        <Typography className={classes.valueText} data-testId="test-value">
          {isNullOrUndefined(fieldValue) ? (
            <span>&#8213;</span>
          ) : (
            formatValueByType(fieldValue, fieldType)
          )}
        </Typography>
      )}
    </Grid>
  );
};
