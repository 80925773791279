import { Button, Grid } from "@mui/material";
import { DropDown } from "@toorak/tc-common-fe-sdk";
import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateDataInRedux } from "../stores/loanAndPropertyDetails/loanAndPropertyDetails.action";
import { RootState } from "../stores/rootReducer";
import { sanitizeValueByType } from "../utils/formatChecks";
import { creditEvalStyles } from "./styles/CreditEvalStyles";

export const BorroweGuaranHeader = () => {
  const dataInRedux = useSelector<RootState, any>((state) => {
    return state.loanAndPropertyStore.loanDetails;
  });
  const classes = creditEvalStyles();

  const dispatch = useDispatch();
  const handleChange = (
    e: any,
    fieldId: string,
    sectnName: string,
    fieldType: string
  ) => {
    const sanitizedValue = sanitizeValueByType(e.target.value, fieldType);
    dispatch(updateDataInRedux(sectnName, fieldId, sanitizedValue));
  };
  const openSearch = () => {
    let name =
      dataInRedux?.borrowerGuarantor?.borrowerGuarantorDrop.split("(")[0];
    let searchName =
      name +
      ` AND litigation OR foreclosure OR lawsuit OR default OR judgement OR court OR discrimination OR complaints`;
    window.open(`https://www.google.com/search?q=${searchName} `, "_blank");
  };
  let borrowerGuarantorDrop: any = [];
  const getDropDownValue = () => {
    borrowerGuarantorDrop.push("All");
    dataInRedux?.loanUserMap.map((item: any) => {
      let fieldName = "";
      if (item?.loanUserType !== "Guarantor") {
        fieldName =
          item.customer.partyType === "account"
            ? item.customer.accountName + " (Entity)"
            : item?.isPrimary
            ? item.customer.firstName +
              " " +
              item.customer.lastName +
              " (Primary Borrower)"
            : item.customer.firstName + " " + item.customer.lastName;
        borrowerGuarantorDrop.push(fieldName);
        if (!dataInRedux?.borrowerGuarantor?.borrowerGuarantorDrop) {
          if (item?.isPrimary) {
            dispatch(
              updateDataInRedux(
                "borrowerGuarantor",
                "borrowerGuarantorDrop",
                fieldName
              )
            );
          }
        }
      } else {
        fieldName = item?.isPrimary
          ? item.customer.firstName +
            " " +
            item.customer.lastName +
            " (Primary Guarantor)"
          : item.customer.firstName + " " + item.customer.lastName;
        borrowerGuarantorDrop.push(fieldName);
        if (!dataInRedux?.borrowerGuarantor?.borrowerGuarantorDrop) {
          if (item?.isPrimary) {
            dispatch(
              updateDataInRedux(
                "borrowerGuarantor",
                "borrowerGuarantorDrop",
                fieldName
              )
            );
          }
        }
      }
      if (!dataInRedux?.borrowerGuarantor) {
        dispatch(
          updateDataInRedux(
            "borrowerGuarantor",
            `${fieldName}.liquidityCurrency`,
            item?.liquidityCurrency
          )
        );
        dispatch(
          updateDataInRedux(
            "borrowerGuarantor",
            `${fieldName}.netWorthCurrency`,
            item?.netWorthCurrency
          )
        );
        dispatch(
          updateDataInRedux(
            "borrowerGuarantor",
            `${fieldName}.experience`,
            item?.experience
          )
        );
        dispatch(
          updateDataInRedux(
            "borrowerGuarantor",
            `${fieldName}.foreignNationalString`,
            item?.customer?.foreignNationalString
          )
        );
        dispatch(
          updateDataInRedux(
            "borrowerGuarantor",
            `${fieldName}.originalCreditScoreMedian`,
            item?.originalCreditScoreMedian
          )
        );
        if (item?.loanUserType !== "Guarantor") {
          dispatch(
            updateDataInRedux(
              "borrowerGuarantor",
              `${fieldName}.borrowerType`,
              item?.customer?.partyType === "account" ? "Entity" : "Individual"
            )
          ); // not in obj
          dispatch(
            updateDataInRedux(
              "borrowerGuarantor",
              `${fieldName}.accountName`,
              item?.customer?.partyType === "account"
                ? item?.customer?.accountName
                : item?.customer?.firstName + " " + item?.customer?.lastName
            )
          ); //
        } else {
          dispatch(
            updateDataInRedux(
              "borrowerGuarantor",
              `${fieldName}.guarantorName`,
              item?.customer?.firstName + " " + item?.customer?.lastName
            )
          );
          dispatch(
            updateDataInRedux(
              "borrowerGuarantor",
              `${fieldName}.ownershipOfEntity`,
              item?.ownershipOfEntity
            )
          );
        }
      }
    });
    return borrowerGuarantorDrop.map((item: any) => ({
      value: item,
      label: item
    }));
  };
  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        className={classes.borrowerDropdown}
        alignItems="center"
      >
        <DropDown
          selectionValue={
            dataInRedux?.borrowerGuarantor
              ? dataInRedux["borrowerGuarantor"]["borrowerGuarantorDrop"]
              : ""
          }
          selectionOnChange={(e: any) => {
            handleChange(
              e,
              "borrowerGuarantorDrop",
              "borrowerGuarantor",
              "string"
            );
          }}
          formControlTestId="dd"
          inputLabelId="creditBorrowerDrop"
          selectId=""
          selectTestId=""
          inputLabelTestId=""
          helperText=""
          data-testid="data-testid"
          valueTestId="value-test-id"
          selectionData={getDropDownValue()}
          isViewMode={false}
          label={""}
          className={classes.borrowerDropdown}
          style={{
            width: "100%",
            marginTop: "0 !important"
          }}
          notRequired
        />

        {dataInRedux?.borrowerGuarantor?.borrowerGuarantorDrop !== "All" && (
          <Button
            variant="contained"
            className={classes.savebutton}
            onClick={() => {
              openSearch();
            }}
            color="info"
          >
            Google Search
          </Button>
        )}
      </Grid>
    </>
  );
};
