/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { EvaluationResults } from "../evaluation-results/EvaluationResults";
import { ExceptionAndQueries } from "../evaluation-results/ExceptionAndQueries";
import { RootState } from "../../stores/rootReducer";
import { alterPropertyEvaluationResult } from "../evaluation-results/EvaluationHelper";
import { useParams } from "react-router-dom";
import { LoanStage } from "@toorak/tc-common-fe-sdk";
import { isLATUser } from "../../utils/AccessManagement";

export function EvaluationPanel() {
  const [currentlySelectedTab, setCurrentlySelectedTab] = useState<number>(0);
  const { loanStage } = useParams<any>() as any;
  const evaluationResults = useSelector<RootState, any>(
    state => state.evaluationResultsStore.loanEvaluationResult
  );
  const activePropertyId = useSelector<RootState, string | null>(
    state => state.createLoanStore.loanDetails.activePropertyId
  );
  const loanDetails = useSelector<RootState, any>(
    state => state.createLoanStore.loanDetails
  );
  const propertyDetails = useSelector<RootState, any | null>(
    state => state.createLoanStore.loanDetails.propertyDetails
  );
  let infoContentResult = useSelector<RootState, any>(
    state => state.createLoanStore.infoContentResult
  );
  if (infoContentResult) {
    infoContentResult = JSON.parse(JSON.stringify(infoContentResult));
  }
  const [evalutionTestResults, setEvalutionTestResults] = useState<any>();
  useEffect(() => {
    const evalObj: any = {
      propertiesResults: {}
    };
    const validKeys =
      evaluationResults &&
      evaluationResults?.propertiesResults &&
      Object.keys(evaluationResults?.propertiesResults).filter(
        key =>
          typeof evaluationResults?.propertiesResults[key] === "object" &&
          evaluationResults.propertiesResults[key]
      );
    const key = `${activePropertyId}`;
    if (validKeys && validKeys.includes(key)) {
      const property = propertyDetails.find(
        (x: any) => x.propertyId === activePropertyId
      );
      if (evaluationResults?.propertiesResults[key]) {
        evalObj.propertiesResults = {
          ...{
            [key]: alterPropertyEvaluationResult(
              evaluationResults?.propertiesResults[key],
              key,
              property?.propertyLocation?.payload.address
            )
          }
        };
      }
    }
    setEvalutionTestResults(evalObj);
  }, [activePropertyId, evaluationResults]);

  let tabs: any = [
    {
      title: "Evaluation Results",
      component: () => (
        <EvaluationResults
          evaluationResults={evalutionTestResults}
          showInfo
          propertyInputs={propertyDetails}
          resultType="propertyResults"
          loanInputs={loanDetails}
          evalTemplateRules={evaluationResults.reasonForFail}
          infoContentResult={infoContentResult}
          evaluationPanel
        />
      )
    }
  ];
  const conditionTab = {
    title: isLATUser() ? "Conditions & Inquiries" : "Conditions",
    component: () => <ExceptionAndQueries infoRequired />
  };

  if (loanStage && loanStage !== "FES") tabs.push(conditionTab);

  return (
    <div
      style={{
        borderRadius: 4,
        boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
        backgroundColor: "#ffffff",
        marginTop: 24,
        height: "calc(100% - 24px)"
        // paddingBottom: 16
        // paddingBottom: 6
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "20px 24px 0"
        }}
      >
        {tabs.map((item: any, index: any) => (
          <div key={item.title}>
            <div
              className={
                loanStage && loanStage === LoanStage.fes ? "defaultCursor" : ""
              }
              style={{ display: "flex" }}
            >
              <Typography
                onClick={() => setCurrentlySelectedTab(index)}
                variant="h5"
                style={{
                  cursor: "pointer",
                  color: "#32325d",
                  fontSize: 14,
                  fontWeight: index === currentlySelectedTab ? 600 : "normal"
                }}
              >
                {item.title}
              </Typography>
            </div>
            <div
              style={{
                height: 0,
                borderRadius: 6,
                borderStyle: "solid",
                borderColor: "#5e72e4",
                background: "#5e72e4",
                margin: "22px 8px 0px 8px",
                borderWidth: 2,
                opacity: index === currentlySelectedTab ? 1 : 0,
                padding: "0px 16px"
              }}
              className={
                loanStage && loanStage === LoanStage.fes
                  ? "noHeaderSelection"
                  : ""
              }
            />
          </div>
        ))}
      </div>
      <div style={{ border: "solid 1px #f2f2f2", height: 1 }} />
      {tabs[currentlySelectedTab].component()}
    </div>
  );
}
