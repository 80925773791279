import { useState } from "react";
import { ObjectType } from "../masterView/common";


export const useMultiSelection = (data: ObjectType) => {
  const [selectedItems, setSelectedItems] = useState<Map<string, ObjectType>>(
    new Map()
  );
  const { key = "id" } = data;

  const updateSelectedItems = (checked: boolean, details: ObjectType) => {
    setSelectedItems(selectedItems => {
      const updatedSelectedItems = new Map(selectedItems);
      if (checked) {
        updatedSelectedItems.set(details?.[key], details);
      } else {
        updatedSelectedItems.delete(details?.[key]);
      }
      return updatedSelectedItems;
    });
  };

  return { selectedItems, setSelectedItems, updateSelectedItems };
};


export const buyBoxTipsConfig: ObjectType = {
  "MINIMUM_FICO": {
    label: "Min FICO"
  },
  "MAXIMUM_LTV": {
    label: "Max LTV",
    type: "percentage"
  },
  "MINIMUM_DSCR": {
    label: "Min DSCR",
    type: "percentage"
  },
  "MINIMUM_originalLoanAmount": {
    label: "Min Original Loan Amount"
  },
  "MAXIMUM_originalLoanAmount": {
    label: "Max Original Loan Amount"
  }
}