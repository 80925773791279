/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Button
  // Tooltip,
  // Theme,
  // withStyles
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { waiverFormStyles } from "./Common.style";
// import { handleFileUpload } from "./FileUploadAPIs";
import SubmitIcon from "../../images/submit_icon.svg";
// import { resetExceptionStore } from "../../stores/ExceptionsResults/ExceptionActions";
import { handleFileUpload } from "../exceptions-and-enquiries/DocumentUploadApis";
import {
  // clearLoader,
  hideLoader,
  showLoader
} from "../../stores/loaderAndException/loaderAndException.action";
import { CloseIcon } from "../Icons";
import { isLATUser } from "../../utils/AccessManagement";
import { WaiverActionsButton } from "./WaiverActionsButton";
import { extensionList } from "../../utils/constants";
import { HtmlTooltip } from "@toorak/tc-common-fe-sdk";
import { clearLoader } from "../../stores/loaderAndException/loaderAndException.reducer";
import { resetExceptionStore } from "../../stores/ExceptionsResults/ExceptionsReducer";

interface SubmitWaiverProps {
  exceptionId?: any;
  isSubmitDisabled: boolean;
  setOpenDialog?: any;
  filesList?: any;
  setDialogdetails?: any;
  setFilesList?: any;
  RequestAndTakeAction?: any;
  waiverSubject?: boolean;
  setReRequestContainer?: any;
  selectedCompensatingFactors: any;
  setWaiverResponseType?: any;
  setselectedCompensatingFactors: any;
}

export const SubmitWaiver = (props: SubmitWaiverProps) => {
  const { loanId } = useParams<any>() as any;
  const classes = waiverFormStyles();
  const dispatch = useDispatch();
  const handleCancel = () => {
    if (setReRequestContainer) {
      setReRequestContainer(false);
    }
    dispatch(resetExceptionStore());
  };
  const {
    exceptionId,
    isSubmitDisabled,
    setOpenDialog,
    filesList,
    setDialogdetails,
    setFilesList,
    RequestAndTakeAction,
    waiverSubject,
    setReRequestContainer,
    selectedCompensatingFactors,
    setWaiverResponseType,
    setselectedCompensatingFactors
  } = props;
  const [submitDisable, setSubmitDisable] = React.useState<string[]>([]);
  const setSubmitDisableWrapper = (
    id: string,
    addNewToArray: boolean,
    error?: any
  ) => {
    if (addNewToArray) {
      setSubmitDisable((state) => [...state, id]);
    } else {
      setSubmitDisable((state) => state.filter((ele) => ele !== id));
    }
    if (error) {
      alert("Network Error, Please upload documents again");
      dispatch(clearLoader());
    }
  };

  let sendBtnText = "Submit";
  const isFileUploadInProgress = exceptionId ? submitDisable.length > 0 : false;
  if (isFileUploadInProgress && exceptionId) {
    sendBtnText = "Files upload in progress";
  }

  useEffect(() => {
    if (isFileUploadInProgress) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [dispatch, isFileUploadInProgress]);
  return (
    <>
      <Grid item xs={12} key={exceptionId}>
        <Grid container direction="row" alignItems="stretch" spacing={1}>
          <Grid
            item
            className={
              RequestAndTakeAction ? classes.submitandActionContainer : ""
            }
          >
            <Button
              variant="contained"
              startIcon={<img alt="submit" src={SubmitIcon} />}
              color="info"
              className={
                RequestAndTakeAction
                  ? classes.submitandAction
                  : classes.submitButton
              }
              type="submit"
              disabled={
                isSubmitDisabled || isFileUploadInProgress || waiverSubject
              }
            >
              {sendBtnText}
            </Button>
          </Grid>
          {isLATUser() && (
            <WaiverActionsButton
              loanId={loanId}
              // loanStage={loanStage}
              requestAndAction
              RequestAndTakeAction={RequestAndTakeAction}
              statusDisable={isSubmitDisabled || isFileUploadInProgress}
              selectedCompensatingFactors={selectedCompensatingFactors}
              setWaiverResponseType={
                setWaiverResponseType ? setWaiverResponseType : ""
              }
              setselectedCompensatingFactors={setselectedCompensatingFactors}
            />
          )}
          {exceptionId ? (
            <Grid item>
              <Button
                variant="contained"
                color="info"
                startIcon={<CloseIcon color="#FFF" />}
                className={classes.submitButton}
                onClick={handleCancel}
                type="submit"
                // disabled={isSubmitted}
              >
                Cancel
              </Button>
            </Grid>
          ) : null}

          <Grid item>
            <div>
              <input
                style={{
                  display: "none"
                }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={(e) =>
                  handleFileUpload(e, {
                    setOpenDialog,
                    filesList,
                    setDialogdetails,
                    setFilesList,
                    loanId,
                    setSubmitDisable: setSubmitDisableWrapper,
                    isWaiverType: !exceptionId
                  })
                }
                onClick={(
                  event: React.MouseEvent<HTMLInputElement, MouseEvent>
                ) => {
                  const element = event.target as HTMLInputElement;
                  element.value = "";
                }}
              />
              <label htmlFor="raised-button-file">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.uploadButton}
                >
                  <AttachFileIcon style={{ transform: "rotate(30deg)" }} />
                </Button>
              </label>
            </div>
          </Grid>
          <Grid item>
            <HtmlTooltip
              placement="right-end"
              title={
                <>
                  <Typography>Upload Restrictions</Typography>
                  <ul>
                    <li>Max size: 20MB</li>
                    <li>Max number of docs: 50</li>
                    <li>
                      File type:
                      <ul>
                        {extensionList.map((item) => {
                          return <li key={item}>{item}</li>;
                        })}
                      </ul>
                    </li>
                  </ul>
                </>
              }
            >
              <InfoOutlinedIcon />
            </HtmlTooltip>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
