/* eslint-disable react/jsx-indent */
/* eslint-disable consistent-return */
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { InputSectionWrapper } from "../../credit-evaluation/InputSectionWrapper";
import { RootState } from "../../stores/rootReducer";
import { isNullOrUndefined, nameToInitials } from "../../utils/formatChecks";
import { ScenarioCardDetails } from "./ScenarioCardDetails";
import { generatePropertyChip } from "./ScenarioViewDetails";

export const ScenarioCompactView = () => {
  const scenarioListArr = useSelector<
    RootState,
    { attachedScenarioDetails: any[] | any }
  >(
    (state: { scenarioResultsStore: { attachedScenarioDetails: any } }) =>
      state.scenarioResultsStore?.attachedScenarioDetails
  );
  const [scenarioCount, setScenarioCount] = useState(0);

  const getUserName = (details: any, isUserName: boolean) => {
    if (!isNullOrUndefined(details)) {
      const { firstName, lastName, middleName } = details;
      const userFirstName = !isNullOrUndefined(firstName) ? firstName : "";
      const userLastName = !isNullOrUndefined(lastName) ? lastName : "";
      const userMiddleName = !isNullOrUndefined(middleName) ? middleName : "";
      const userFullName = `${userFirstName} ${userMiddleName} ${userLastName}`;
      const nameInitials = nameToInitials(`${firstName} ${lastName}`);
      return isUserName ? userFullName : nameInitials;
    }
  };
  useEffect(() => {
    const scenario: any = scenarioListArr || null;
    const count = scenario && scenario.length;
    setScenarioCount(count);
  }, [scenarioListArr]);

  return (
    <>
      <Grid>
        {scenarioListArr instanceof Array && scenarioCount > 0 ? (
          <InputSectionWrapper
            sectionLabel={`SCENARIO (${scenarioCount})`}
            sectionId="input-scenario-id"
          >
            <div style={{ backgroundColor: "#f8f9ff" }}>
              {scenarioListArr.map((scenarioDetails: any) => (
                <ScenarioCardDetails
                  scenarioDetails={scenarioDetails}
                  nameInitials={getUserName(
                    scenarioDetails?._source?.createdByUser,
                    false
                  )}
                  userName={getUserName(
                    scenarioDetails?._source?.createdByUser,
                    true
                  )}
                  attachedLoanArr={
                    scenarioDetails?._source?.waiver?.attachedLoans
                  }
                  propertyChips={generatePropertyChip(
                    scenarioDetails?._source?.properties
                  )}
                />
              ))}
            </div>
          </InputSectionWrapper>
        ) : null}
      </Grid>
    </>
  );
};
