/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { produce } from "immer";

// import {
//   GET_UNASSIGNED_LOANS_AMOUNT,
//   GET_ALL_ORGINATOR_NAMES,
//   RESET_TKOP_STORE,
//   SET_ACTIVE_SERVICER_TAB,
//   GET_UNASSIGNED_LOANS_INFO,
//   GET_INITIATED_LOANS,
//   GET_NON_INITIATED_LOANS,
//   SET_APPLIED_FILTER_UPDATED,
//   GET_ERRROR_MESSAGE,
//   GET_ALL_CREDIT_LINES
// } from "./takeoutPartner.constant";

export interface TakeOutPartnerStore {
  activeServicerTab: string;
  unAssignedLaonAmountAndCount: {};
  originatorNames: [];
  initialReviewLoans: [];
  DDNonInitiatedLoan: [];
  DDInitiatedLoan: [];
  isAppliedFilterUpdated: boolean;
  // evalutionResultsForPartners: [];
  errorMessages: {};
  creditLines: [];
}

const initialState: any = {
  activeServicerTab: "UnassignedLoans",
  unAssignedLaonAmountAndCount: {},
  initialReviewLoans: [],
  originatorNames: [],
  DDNonInitiatedLoan: [],
  DDInitiatedLoan: [],
  isAppliedFilterUpdated: false,
  errorMessages: {},
  creditLines: []
};

const takeOutPartnerSlice = createSlice({
  name: "takeOutPartner",
  initialState: initialState,
  reducers: {
    setActiveServicerTab: (
      state,
      action: PayloadAction<{ activeServicerTab: string }>
    ) => {
      state.activeServicerTab = action.payload.activeServicerTab;
    },
    getErrorMessage: (
      state,
      action: PayloadAction<{ errorMessages: string }>
    ) => {
      state.errorMessages = action.payload.errorMessages;
    },
    setAppliedFilterUpdated: (state, action: PayloadAction<boolean>) => {
      state.isAppliedFilterUpdated = action.payload;
    },
    getUnassignedLoansAmount: (
      state,
      action: PayloadAction<{ unAssignedLaonAmountAndCount: any }>
    ) => {
      state.unAssignedLaonAmountAndCount =
        action.payload.unAssignedLaonAmountAndCount;
    },
    updateAllCreditLines: (
      state,
      action: PayloadAction<{ creditLines: any }>
    ) => {
      state.creditLines = action.payload.creditLines;
    },
    getUnassignedLoansInfo: (
      state,
      action: PayloadAction<{ initialReviewLoans: any }>
    ) => {
      if (state.isAppliedFilterUpdated) {
        state.initialReviewLoans = action.payload.initialReviewLoans;
      } else {
        state.initialReviewLoans.push(...action.payload.initialReviewLoans);
      }
    },
    getNonInitiatedLoans: (
      state,
      action: PayloadAction<{ DDNonInitiatedLoan: any }>
    ) => {
      if (state.isAppliedFilterUpdated) {
        state.DDNonInitiatedLoan = action.payload.DDNonInitiatedLoan;
      } else {
        state.DDNonInitiatedLoan.push(...action.payload.DDNonInitiatedLoan);
      }
    },
    getInitiatedLoans: (
      state,
      action: PayloadAction<{ DDInitiatedLoan: any }>
    ) => {
      if (state.isAppliedFilterUpdated) {
        state.DDInitiatedLoan = action.payload.DDInitiatedLoan;
      } else {
        state.DDInitiatedLoan.push(...action.payload.DDInitiatedLoan);
      }
    },
    getAllOriginatorNames: (
      state,
      action: PayloadAction<{ originatorNames: any }>
    ) => {
      state.originatorNames = action.payload.originatorNames;
    },
    resetTKOPStore: () => initialState
  }
});

export const {
  setActiveServicerTab,
  getErrorMessage,
  setAppliedFilterUpdated,
  getUnassignedLoansAmount,
  updateAllCreditLines,
  getUnassignedLoansInfo,
  getNonInitiatedLoans,
  getInitiatedLoans,
  getAllOriginatorNames,
  resetTKOPStore
} = takeOutPartnerSlice.actions;

export const TakeOutPartnerReducer = takeOutPartnerSlice.reducer;

// export const TakeOutPartnerReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case SET_ACTIVE_SERVICER_TAB: {
//       const { activeServicerTab } = action.payload;
//       return produce(state, (draftState: any) => {
//         draftState.activeServicerTab = activeServicerTab;
//       });
//     }
//     case GET_ERRROR_MESSAGE: {
//       const { errorMessages } = action.payload;
//       return produce(state, (draftState: any) => {
//         draftState.errorMessages = errorMessages;
//       });
//     }
//     case SET_APPLIED_FILTER_UPDATED: {
//       const { status } = action.payload;
//       return produce(state, (draftState: any) => {
//         draftState.isAppliedFilterUpdated = status;
//       });
//     }
//     case GET_UNASSIGNED_LOANS_AMOUNT: {
//       const { unAssignedLaonAmountAndCount } = action.payload;
//       return produce(state, (draftState: any) => {
//         draftState.unAssignedLaonAmountAndCount = unAssignedLaonAmountAndCount;
//       });
//     }
//     // case GET_EVALUATION_RESULT_FOR_PARTNERS: {
//     //   const { evalutionResultsForPartners } = action.payload;
//     //   return produce(state, (draftState: any) => {
//     //     draftState.evalutionResultsForPartners = evalutionResultsForPartners;
//     //   });
//     // }
//     case GET_ALL_CREDIT_LINES: {
//       const { creditLines } = action.payload;
//       return produce(state, (draftState: any) => {
//         draftState.creditLines = creditLines;
//       });
//     }
//     case GET_UNASSIGNED_LOANS_INFO: {
//       const { initialReviewLoans } = action.payload;
//       return produce(state, (draftState: any) => {
//         if (draftState.isAppliedFilterUpdated) {
//           draftState.initialReviewLoans = initialReviewLoans;
//         } else draftState.initialReviewLoans.push(...initialReviewLoans);
//       });
//     }
//     case GET_NON_INITIATED_LOANS: {
//       const { DDNonInitiatedLoan } = action.payload;
//       return produce(state, (draftState: any) => {
//         if (draftState.isAppliedFilterUpdated) {
//           draftState.DDNonInitiatedLoan = DDNonInitiatedLoan;
//         } else draftState.DDNonInitiatedLoan.push(...DDNonInitiatedLoan);
//       });
//     }
//     case GET_INITIATED_LOANS: {
//       const { DDInitiatedLoan } = action.payload;
//       return produce(state, (draftState: any) => {
//         if (draftState.isAppliedFilterUpdated) {
//           draftState.DDInitiatedLoan = DDInitiatedLoan;
//         } else draftState.DDInitiatedLoan.push(...DDInitiatedLoan);
//       });
//     }
//     case GET_ALL_ORGINATOR_NAMES: {
//       const { originatorNames } = action.payload;
//       return produce(state, (draftState: any) => {
//         draftState.originatorNames = originatorNames;
//       });
//     }
//     case RESET_TKOP_STORE: {
//       return initialState;
//     }
//     default:
//       return state;
//   }
// };
