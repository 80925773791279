import React, { useEffect } from 'react';

import { useDispatch, useSelector } from "react-redux";

import { LoanStatusEnum } from '@toorak/tc-common-util-sdk';

import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import BorderColorIcon from '@mui/icons-material/BorderColor';

import { valueEditing } from '../create-loan.reducer';

import { RootState } from '../../stores/rootReducer';

import { updateFormEditStatus, updateOpenCommentDialog } from '../../v2/loanDetailStore.reducer';

export default function SaveEditActions(props: any) {
  // useSelector hooks
  const { loanState }: { loanState: any} = useSelector<RootState, any>(
    (state) => state.createLoanStore.loanDetails
  );
  const { isFormEditing, isDataEntry, fieldEditCount } = useSelector<RootState, any>((state) => state.loanDetailsStore);

  const dispatch = useDispatch();

  let {
    currentTab,
  } = props;

  useEffect(() => {
    if (
      loanState === LoanStatusEnum.DueDiligenceReview && isFormEditing && !isDataEntry
    ) {
      dispatch(valueEditing(true));
    }
  }, [loanState, currentTab]);

  return (
    <div style={{ textAlign: "right" }}>
      {
        !isFormEditing && !isDataEntry &&
        <Button
          variant="contained"
          color="info"
          startIcon={<BorderColorIcon />}
          onClick={() => {
            dispatch(updateFormEditStatus({ value: true, editStatus: "editing" }));
          }}
        >
          Edit
        </Button>
      }
      {
        isFormEditing && !isDataEntry &&
        <Grid container spacing={2} xs={12} style={{ display: "flex", justifyContent: "end" }}>
          <Grid item>
              <Button disabled={fieldEditCount === 0} variant="contained" color="info" onClick={() => dispatch(updateOpenCommentDialog({ value: true }))}>Save</Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={() => {
              dispatch(updateFormEditStatus({ value: false, editStatus: "notSaved" }));
              dispatch(updateOpenCommentDialog({ value: false }))
            }}>Cancel</Button>
          </Grid>
        </Grid>
      }
    </div>
  )
}
