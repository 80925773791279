/* eslint-disable prefer-destructuring */
import { InputAdornment, InputBase } from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { searchFilterStyles } from "./filterStyles";
import { RootState } from "../../../../stores/rootReducer";
import { setFiltersPandS } from "../../../../stores/SettlementAndPurchase/SettlementAndPurchase.reducer";

export const SearchFilter = (props: any) => {
  const classes = searchFilterStyles();
  const { fieldKey } = props;
  const filterObj = useSelector<RootState, any>(
    (state) => state.spStore.filterObj
  );
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = React.useState("");
  const handleSearch = (event: any) => {
    // console.log(event.target.name, event.target.value);
    let field: string = event.target.name;
    let value: any = event.target.value;
    if (field === "loan.loanDetailId.loanId") {
      field = `${field}.raw`;
      value = value.split(",");
    } else {
      value = {
        analyzer: "simple",
        query: value
      };
    }
    dispatch(
      setFiltersPandS({
        ...filterObj,
        [fieldKey]: value,
        filterInitiated: true
      })
    );
  };

  return (
    <InputBase
      autoComplete="off"
      className={`${classes.root} inputSearch`}
      onKeyPress={(event: any) => {
        if (event.key === "Enter") {
          handleSearch(event);
        }
      }}
      onChange={(event: any) => {
        setInputValue(event.target.value);
      }}
      value={inputValue}
      name={fieldKey}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon className={classes.searchIcon} />
        </InputAdornment>
      }
      placeholder="Type Keyword"
      inputProps={{ "aria-label": "Type Keyword" }}
      style={{ color: "#373761" }}
    />
  );
};
