import React, { useMemo } from "react";
import { disableExcel } from "./CreateLoanType";

export const DragAndDrop = (props: any) => {
  // remove loanStage check once upload story done for FES..
  const {
    data,
    dispatch,
    children,
    loanStage,
    loanType,
    validateSelections,
    useDefaultExcelConditionCheck
  } = props;

  const isExcelDisabled = useMemo(() => {
    if (!useDefaultExcelConditionCheck) {
      return false;
    }
    return disableExcel(loanStage, loanType?.loanTypeName);
  }, [loanStage, loanType, useDefaultExcelConditionCheck]);

  const handleDragEnter = (e: any) => {
    if (isExcelDisabled) {
      return true;
    }
    e.preventDefault();
    e.stopPropagation();
    dispatch({ type: "SET_DROP_DEPTH", dropDepth: data.dropDepth + 1 });
  };

  const handleDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (isExcelDisabled) {
      return true;
    }

    dispatch({ type: "SET_DROP_DEPTH", dropDepth: data.dropDepth - 1 });
    if (data.dropDepth > 0) return;
    dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: false });
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (isExcelDisabled) {
      return true;
    }
    e.dataTransfer.dropEffect = "copy";
    dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: true });
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (!validateSelections() || isExcelDisabled) {
      return true;
    }

    const files = [...e.dataTransfer.files];

    if (files && files.length > 0) {
      // const existingFiles = data.fileList.map((f: any) => f.name);
      // files = files.filter(f => !existingFiles.includes(f.name));

      dispatch({ type: "ADD_FILE_TO_LIST", files });
      e.dataTransfer.clearData();
      dispatch({ type: "SET_DROP_DEPTH", dropDepth: 0 });
      dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: false });
    }
  };

  return (
    <div
      onDrop={e => handleDrop(e)}
      onDragOver={e => handleDragOver(e)}
      onDragEnter={e => handleDragEnter(e)}
      onDragLeave={e => handleDragLeave(e)}
    >
      {children}
    </div>
  );
};

// eslint-disable-next-line no-lone-blocks
{
  /* {data.fileList.length ? (
        data.fileList.map((file: any) => (
          <div>
            <div className={classes.fileIconContainer}>
              <img src={excelIcon} alt="excel file" />
              <Typography data-testid="format" className={classes.centered}>
                {getFileType(file.name)}
              </Typography>
            </div>
            <Typography
              data-testid="uploaded-doc-name"
              className={classes.fileName}
            >
              {file.name}
            </Typography>
          </div>
        ))
      ) : ( */
}
