/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  Divider
} from "@mui/material";

import { DialogTitle, LoanStage } from "@toorak/tc-common-fe-sdk";

import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";

import { RootState } from "../../stores/rootReducer";

import { onHoldStyles } from "./Common.style";

import {
  onHoldLoan,
  setOnHoldLoan,
  getLoanLandmarks,
  setLoanLandMark,
  // UPDATE_LOAN_STATUS,
  setActiveTabAndProperty
} from "../create-loan.action";
import {
  showLoader,
  hideLoader
} from "../../stores/loaderAndException/loaderAndException.action";
import { OptionalComment } from "../purchase-and-settlements/components/ConstantComponents";
import // clearBundleData,
// updateLoanStatus
"../../stores/SettlementAndPurchase/SettlementAndPurchase.action";
import { clearBundleData } from "../../stores/SettlementAndPurchase/SettlementAndPurchase.reducer";
import { updateLoanStatusLoan } from "../create-loan.reducer";
import { delay } from "../../utils/utils";

export enum onHoldEnumValue {
  systemValue = "SYSTEM",
  systemValueToBeShown = "System"
};

interface AddOnHoldDialogProps {
  isOpen: boolean;
  handleClose: (arg0: boolean) => void;
  bundleId: string;
  loanState: string;
}

export const AddOnHoldDialog = (props: AddOnHoldDialogProps) => {
  const classes = onHoldStyles();
  const { isOpen, handleClose, bundleId, loanState } = props;
  const dispatch = useDispatch();
  const [comment, setComment] = useState<string>("");
  const { loanId, loanStage } = useParams<{
    loanId: string;
    loanStage: LoanStage;
  }>() as any;
  const [disableBtn, setDisableBtn] = useState(false);

  const activeTab = useSelector<RootState, string>(
    (state) => state.createLoanStore.activeTab
  );

  const addLoanToHold = () => {
    dispatch(showLoader(true));
    const loanToHoldPromise = onHoldLoan("ADD", loanId, comment);
    loanToHoldPromise
      .then((res: any) => {
        if (res) {
          getHoldLandMark();
        }
      })
      .catch((err: any) => {
        dispatch(hideLoader(true));
        console.log("err", err);
      });
  };

  const getHoldLandMark = () => {
    const holdLandMarkPromise = getLoanLandmarks(loanId, loanStage);
    holdLandMarkPromise
      .then((res: any) => {
        if (res) {
          dispatch(setOnHoldLoan(res.data?.onHoldBy));
          dispatch(setLoanLandMark(res.data?.loanDetailId?.loanLandmarks));
          setComment("");
          //! Loan status will be updated by BE once it is removed from bundle. So, just updating status value to reflect in UI without refresh.
          if (bundleId && loanState === LoanStatusEnum.PurchaseInitiated) {
            // dispatch({
            //   type: UPDATE_LOAN_STATUS,
            //   payload: LoanStatusEnum.Approved
            // });
            delay(1000);
            dispatch(updateLoanStatusLoan(LoanStatusEnum.Approved));

            dispatch(clearBundleData());
            const setActiveTab =
              activeTab === "settlement" ? "summary" : activeTab;
            dispatch(setActiveTabAndProperty(setActiveTab));
          }
          dispatch(hideLoader(true));
          handleClose(true);
        }
      })
      .catch((err: any) => {
        dispatch(hideLoader(true));
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (comment.trim().length > 0) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [comment]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="hold-dialog-title"
      open={isOpen}
      test-dataid="dialog"
      classes={{
        paper: classes.paper,
        paperWidthSm: classes.paperWidthSm
      }}
    >
      <DialogTitle id="hold-dialog-title" onClose={handleClose}>
        Put Loan On Hold
      </DialogTitle>
      <Divider classes={{ root: classes.dividerRoot }} />
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <OptionalComment
          comment={comment}
          setComment={setComment}
          label="Add your comment"
          customStyles={{
            body: {
              width: "100%",
              padding: "0px"
            },
            textAreaWrapper: {
              height: "89px"
            },
            lable: {
              marginBottom: "8px"
            }
          }}
          required
        />
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionsRoot }}>
        <>
          <Button
            variant="contained"
            color="info"
            disabled={disableBtn}
            onClick={() => {
              addLoanToHold();
            }}
          >
            Done
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleClose(true);
            }}
          >
            Cancel
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};
