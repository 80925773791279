/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useCallback, useEffect, useState } from "react";
import { Checkbox, TextField, Grid, CircularProgress } from "@mui/material";
import { Autocomplete } from '@mui/material';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { InfoOnlyTooltip } from "../../utils/Tooltip";
import { isNullOrUndefined } from "../../utils/formatChecks";
import { useStyles } from "./useStyles";

const icon = <CheckBoxOutlineBlankIcon fontSize="large" />;
const checkedIcon = <CheckBoxIcon fontSize="large" />;

const ALLOWEDONCLOSEREASONS: string[] = [
  "toggleInput",
  "blur",
  "select-option"
];

const ALLOWEDCLEARINPUTREASONS: string[] = ["input", "clear"];

export const AutoCompleteAsync = (props: any) => {
  const {
    options = [],
    isLoading,
    selectedOptions = [],
    dataKey,
    inputLabel,
    searchValue,
    setIsLastNodeVisible = () => {
      /* defaultValue */
    },
    setSelectedOptions = () => {
      /* defaultValue */
    },
    setSearchValue = () => {
      /* defaultValue */
    }
  } = props;
  const styles = useStyles();
  const observer: any = useRef();
  const [allowOpenPopup, setAllowPopupOpen] = useState<any>(false);
  const clearObserver = () => {
    if (observer.current) {
      observer.current.disconnect();
    }
  };
  const observerNode = useCallback(
    (node: any) => {
      clearObserver();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          setIsLastNodeVisible(true);
        }
      });
      if (node) {
        observer.current.observe(node);
      }
      return () => clearObserver();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [observer]
  );

  const handleOptionSelected = (option: any, value: any) => {
    const selectedOption = selectedOptions.find(
      (item: any) => item[dataKey] === option[dataKey]
    );
    return selectedOption && true;
  };

  const handleRenderOptions = (props: any, option: any, state: any) => {
    const selectedOption = selectedOptions.find(
      (item: any) => item[dataKey] === option[dataKey]
    );
    // need only for scenario id subject visibility check dataKey specifically for scenarioId
    const subject = !isNullOrUndefined(option[dataKey])
      ? option[dataKey].split(" - ")
      : dataKey;
    const isLastElement =
      option[dataKey] === options[options.length - 1][dataKey];
    return (
      <InfoOnlyTooltip
        title={dataKey === "scenarioId" ? subject[1] : ""}
        arrow
        placement="right"
        {...props}
      >
        {isLastElement ? (
          <div ref={observerNode}>
            <span
              data-testid="options-wrapper"
              className={styles.optionWrapper}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selectedOption ? true : state.selected}
              />
              <span className={styles.optionName} data-tesid="options-dataKey">
                {option[dataKey]}
              </span>
            </span>
          </div>
        ) : (
          <div>
            <span
              data-testid="options-wrapper"
              className={styles.optionWrapper}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selectedOption ? true : state.selected}
              />
              <span className={styles.optionName} data-tesid="options-dataKey">
                {option[dataKey]}
              </span>
            </span>
          </div>
        )}
      </InfoOnlyTooltip>
    );
  };

  const handleOptionChange = (option: any, event: any) => {
    setSelectedOptions(option);
    setSearchValue("");
  };

  const handleOnInputChange = (event: any, value: any, reason: string) => {
    if (!ALLOWEDCLEARINPUTREASONS.includes(reason)) return;
    setSearchValue(value);
  };

  const handleRenderInput = (params: any) => {
    return (
      <TextField
        variant="outlined"
        value={selectedOptions}
        className={`${styles.commentBox} autoCompleteTagWrap`}
        {...params}
        InputLabelProps={{
          style: {
            position: "relative",
            fontWeight: 600,
            fontSize: 12,
            color: "#32325d",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }
        }}
        label={
          <Grid item xs={12} sm={12} className={styles.labelContainer}>
            <Grid item xs={12} md={12} sm={12} container>
              <span className={styles.labelInformation}>{inputLabel}</span>
            </Grid>
          </Grid>
        }
        style={{
          flex: 1,
          width: "100%",
          marginTop: "20px !important"
        }}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {isLoading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                params.InputProps.endAdornment
              )}
            </>
          )
        }}
      />
    );
  };

  useEffect(() => {
    const open =
      searchValue.length > 2 && (options?.length !== 0 || !!isLoading);
    setAllowPopupOpen(open);
  }, [searchValue, options, isLoading]);

  return <>
    <Autocomplete
      multiple
      limitTags={4}
      autoComplete={false}
      open={allowOpenPopup}
      id="checkboxes-tags"
      clearOnBlur={false}
      clearOnEscape
      inputValue={searchValue}
      defaultValue={selectedOptions}
      className={styles.autoComplete}
      options={options}
      filterSelectedOptions={false}
      disableCloseOnSelect
      getOptionLabel={(option: any) => option?.[dataKey] ?? ""}
      isOptionEqualToValue={(option: any, value: any) =>
        handleOptionSelected(option, value)
      }
      renderOption={(props, option, state) => handleRenderOptions(props, option, state)}
      onChange={(event: any, selectedOption: any) => {
        handleOptionChange(selectedOption, event);
      }}
      onInputChange={(event: any, value: any, reason: string) =>
        handleOnInputChange(event, value, reason)
      }
      style={{ width: 380, padding: "20px 20px" }}
      renderInput={params => handleRenderInput(params)}
      loading={isLoading}
      onOpen={(event: object) => {
        if (options.length === 0) return;
        setAllowPopupOpen(true);
      }}
      onClose={(event: object, reason: string) => {
        if (!ALLOWEDONCLOSEREASONS.includes(reason)) return;
        setAllowPopupOpen(false);
      }}
    />
  </>;
};
