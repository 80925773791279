import React from "react";
import { Button, Grid, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

interface ChatTextAreaInterface {
  textValue: string;
  handleTextChange: (e: any) => any;
  handleSubmitClicked: (e: any) => any;
  btnLabel?: string;
  disableChat?: boolean;
}

export const useNotesStyles = makeStyles((theme: Theme) =>
  createStyles({
    textAreaDiscussionWrapper: {
      height: "89px",
      border: "1px solid #cad1d7",
      borderRadius: "4px",
      marginLeft: 36,
      marginTop: "1.5%",
      "&:disabled": {
        cursor: "no-drop"
      }
    },
    CssTextarea: {
      fontFamily: "'Open Sans', sans-serif !important",
      border: "none",
      width: "100%",
      reSize: "both",
      padding: "14px 12px 14px 12px",
      "border-radius": "4px",
      outline: "none",
      boxSizing: "border-box",
      fontSize: "14px",
      height: "100%",
      background: "transparent",
      "&:disabled": {
        cursor: "no-drop"
      }
    },
    sendButton: {
      marginLeft: 36
    },
    // chatPopup: {
    //   position: "fixed",
    //   bottom: "12%",
    //   right: 15,
    //   border: "3px solid #f1f1f1",
    //   zIndex: 999,
    //   backgroundColor: "#fafafa",
    //   height: "75%",
    //   width: "30%",
    //   boxShadow: "0 2px 12px 0 rgb(50 50 93 / 21%)"
    // },
    // notesButton: {
    //   backgroundColor: "#5e72e4",
    //   color: "white",
    //   padding: 20,
    //   border: "none",
    //   cursor: "pointer",
    //   position: "fixed",
    //   bottom: 5,
    //   right: 60,
    //   width: 60,
    //   height: 60,
    //   zIndex: 999,
    //   borderRadius: "50%"
    // },
    // popupHeader: {
    //   height: "7%",
    //   padding: 7,
    //   fontSize: 15,
    //   fontWeight: 600,
    //   borderBottom: "solid 1px #f2f2f2",
    //   color: "#fff",
    //   backgroundColor: "#5e72e4"
    // },
    // widgetBody: { overflowY: "scroll", height: "85%", padding: 10 },
    internalNotesTab: {
      height: 950,
      overflowX: "scroll",
      padding: 10,
      backgroundColor: "#fff"
    },
    drawIdHistory: {
      fontSize: 12,
      paddingLeft: 5,
      color: "blue",
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline"
      }
    },
    currentDrawTag: {
      marginLeft: "10px",
      padding: "1px 5px 2px",
      borderRadius: "10px",
      backgroundColor: "#5e72e4",
      fontSize: "9px",
      color: "#fff"
    },
    discussionDrawHeaderMin: {
      padding: "8px 3px"
    },
    discussionDrawHeaderMax: {
      backgroundColor: "#eafcff",
      padding: "8px 3px",
      border: "1px solid lightgray",
      borderRadius: 5
    },
    charRemaining: {
      justifyItems: "flex-end",
      justifyContent: "flex-end",
      fontWeight: 500,
      fontSize: 12
    }
  })
);

export const ChatTextArea = ({
  textValue,
  handleTextChange,
  handleSubmitClicked,
  btnLabel,
  disableChat
}: ChatTextAreaInterface) => {
  const classesNotes = useNotesStyles();
  return (
    <>
      <Grid
        item
        className={classesNotes.textAreaDiscussionWrapper}
        style={{ marginTop: "0%" }}
      >
        <textarea
          className={classesNotes.CssTextarea}
          name="comment-text"
          value={textValue}
          data-testid="chat-field"
          maxLength={1000}
          onChange={(e: any) => {
            handleTextChange(e.target.value);
          }}
        />
      </Grid>
      <Grid item container xs={12} className={classesNotes.charRemaining}>
        <p>Characters remaining : {1000 - textValue.trim().length}</p>
      </Grid>
      <Button
        variant="contained"
        disabled={disableChat || textValue.trim() === ""}
        className={classesNotes.sendButton}
        onClick={handleSubmitClicked}
        data-testid="send-button"
        color="info"
      >
        {btnLabel || "Submit"}
      </Button>
    </>
  );
};
