import { publicClient } from "@toorak/tc-common-fe-sdk";
// import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import { getConfig } from "../../config/config";
import {
  showLoader,
  hideLoader
  // clearLoader
} from "../loaderAndException/loaderAndException.action";
import { filterSize } from "../../ilp/actionDashboard/common/actionDashBoardConstant";
import {
  // dispatchAndUpdate,
  // UPDATE_ILP_STATE,
  // UPDATE_TTF_STATE,
  // UPDATE_COMPLETE_DATE,
  updateSummaryDetails,
  updateEditComments
  // SET_PARTYID
} from "../../create-loan/create-loan.action";
import { getCookie, setCookie } from "../../utils/cookies";
// import { COMPLETE_INQUIRY_STATUS } from "../ExceptionsResults/ExceptionActions";
import { defaultQuery } from "../../ilp/actionDashboard/common/utils";
import { isRole } from "../../utils/AccessManagement";
import {
  LoanProcessTypeEnum,
  InternalDDStatusEnum,
  InternalRolesEnum,
  ResponseTypeEnum,
  tapeToFileStatusEnum
} from "../../utils/constants";
import { getErrorConfigObject } from "../../ErrorHandler/ErrorConfigData";
import { constructName } from "../../utils/utils";
import { clearLoader } from "../loaderAndException/loaderAndException.reducer";
import {
  completeInquiryStatusActionstab,
  fetchExceptionsError,
  fetchExceptionsLoading,
  fetchExceptionsTotalCount,
  filterOptionData,
  filtersUpdateSuccess,
  internalGroupsSuccess,
  internalUsersSuccess,
  setInternalTags,
  setIrQcVmAdminList,
  setQcList,
  setRoleObjForSdd,
  setSearchValueRedux,
  setTableData,
  setTagsInfo
  // updateCompleteDate
} from "./ILP.reducer";
import { completeInquiryStatus } from "../ExceptionsResults/ExceptionsReducer";
import {
  setPartyId,
  updateILPStateRedux,
  updateAssignedTo,
  updateTTFStateRedux
} from "../../create-loan/create-loan.reducer";
// export const SET_ASSIGN_OBJ = "SET_ASSIGN_OBJ";
// export const INTERNAL_USERS_SUCCESS = "INTERNAL_USERS_SUCCESS";
// export const INTERNAL_GROUPS_SUCCESS = "INTERNAL_GROUPS_SUCCESS";
// export const SET_GRADE = "SET_GRADE";
// export const SET_TAGS_INFO = "SET_TAGS_INFO";
// export const FETCH_EXCEPTIONS = "FETCH_EXCEPTIONS";
// export const FETCH_EXCEPTIONS_LOADING = "FETCH_EXCEPTIONS_LOADING";
// export const FETCH_EXCEPTIONS_ERROR = "FETCH_EXCEPTIONS_ERROR";
// export const FETCH_EXCEPTIONS_TOTAL_COUNT = "FETCH_EXCEPTIONS_TOTAL_COUNT";
// export const FILTER_OPTION_DATA = "FILTER_OPTION_DATA";
// export const CLEAR_DATA = "CLEAR_DATA";
// export const FILTERS_UPDATE_SUCCESS = "FILTERS_UPDATE_SUCCESS";
// export const FETCH_UPDATED_DATA = "FETCH_UPDATED_DATA";
// export const SET_INTERNAL_TAGS = "SET_INTERNAL_TAGS";
// export const DELETE_ACTION_FROM_ACTION_TAB = "DELETE_ACTION_FROM_ACTION_TAB";
// export const COMPLETE_INQUIRY_STATUS_ACTIONSTAB =
//   "COMPLETE_INQUIRY_STATUS_ACTIONSTAB";
// export const SET_VIEW_TYPE = "SET_VIEW_TYPE";
// export const SET_TABLE_DATA = "SET_TABLE_DATA";
// export const UPDATE_ACTION_LOADING_STATUS = "UPDATE_ACTION_LOADING_STATUS";
// export const SET_IR_QC_VM_ADMIN_LIST = "SET_IR_QC_VM_ADMIN_LIST";
// export const SET_QC_LIST = "SET_QC_LIST";
// export const SET_ROLE_OBJ_FOR_SDD = "SET_ROLE_OBJ_FOR_SDD";
// export const SYNC_IR_QC_VM_ADMIN_MAP = "SYNC_IR_QC_VM_ADMIN_MAP";
// export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE";
// export const TOGGLE_REFRESH = "TOGGLE_REFRESH";
// export const UPDATE_ILP_COLUMN_TAB = "UPDATE_ILP_COLUMN_TAB";

export enum ilpColumnTabsEnum {
  all = "all",
  sdd = "sdd",
  docReview = "docReview",
  budget = "budget"
}

export type ilpColumnTabType =
  | ilpColumnTabsEnum.all
  | ilpColumnTabsEnum.sdd
  | ilpColumnTabsEnum.docReview
  | ilpColumnTabsEnum.budget;

export function getListOfAssignee(role: string[]) {
  let queryParam = "";
  role.forEach((val: string, index: number) => {
    if (index === 0) {
      queryParam += `role=${val}`;
    } else {
      queryParam += `&role=${val}`;
    }
  });
  const url = `${
    getConfig().apiUrl
  }/aggregate/get/users?size=300&${queryParam}`;
  return publicClient.get(url);
}

export function getAllUsersInILP() {
  const url: string = `${
    getConfig().apiUrl
  }/aggregate/get/users?size=300&role=${InternalRolesEnum.LAT_IR}&role=${
    InternalRolesEnum.LAT_QC
  }&role=${InternalRolesEnum.LAT_VM}&role=${InternalRolesEnum.LAT_ADMIN}&role=${
    InternalRolesEnum.LAT_APPROVER
  }&role=${InternalRolesEnum.LAT_INQUIRY}`;
  return publicClient.get(url);
}

export function getAllGroupInfo() {
  const url: string = `${getConfig().apiUrl}/chatservice/groups`;
  return publicClient.get(url);
}

export function assignBulkLoans(
  assignObj: any,
  assignToType?: any,
  callback?: any
): any {
  return async (dispatch: any) => {
    try {
      if (
        assignToType === "unassigned" ||
        assignToType === "irCompletedWithQC"
      ) {
        const camundAsyncOperations: any[] = [];
        assignObj.forEach((loan: any) => {
          camundAsyncOperations.push(
            completeStateThroughCamunda(
              loan,
              loan.workflowState.includes("IR")
                ? InternalDDStatusEnum.UnAssignedIR
                : InternalDDStatusEnum.UnAssignedQC,
              true
            )
          );
        });
        Promise.all(camundAsyncOperations)
          .then(async (res: any) => {
            if (
              res.status === 200 ||
              (res instanceof Array && res.some((obj) => obj.status === 200))
            ) {
              if (res instanceof Array) {
                for (let i = 0; i < res.length; i++) {
                  if (res[i].status !== 200) {
                    assignObj.splice(i, 1);
                  }
                }
              }
              const response = await changeWorkflowStateThroughDB(assignObj);
              if (response) {
                callback(response);
              }
            } else {
              console.error(res);
              callback();
            }
          })
          .catch((e) => {
            console.error(e);
            callback();
          });
      } else {
        const response = await changeWorkflowStateThroughDB(assignObj);
        callback(response);
      }
    } catch (e) {
      callback(e);
    }
  };
}

export function assignBulkTTFLoans(
  assignObj: any,
  assignToType?: any,
  callback?: any
): any {
  return async (dispatch: any) => {
    try {
      if (assignToType === "todo") {
        const camundAsyncOperations: any[] = [];
        assignObj.forEach((loan: any) => {
          camundAsyncOperations.push(
            completeStateThroughCamundaTTF(loan, tapeToFileStatusEnum.ToDo)
          );
        });
        Promise.all(camundAsyncOperations)
          .then(async (res: any) => {
            if (
              res.status === 200 ||
              (res instanceof Array && res.some((obj) => obj.status === 200))
            ) {
              if (res instanceof Array) {
                for (let i = 0; i < res.length; i++) {
                  if (res[i].status !== 200) {
                    assignObj.splice(i, 1);
                  }
                }
              }
              const response = await changeTTFStateThroughDB(assignObj);
              if (response) {
                callback(response);
              }
            } else {
              console.error(res);
              callback();
            }
          })
          .catch((e) => {
            console.error(e);
            callback();
          });
      } else {
        const response = await changeTTFStateThroughDB(assignObj);
        callback(response);
      }
    } catch (e) {
      callback(e);
    }
  };
}

export function changeTTFStateThroughDB(assignObj: any) {
  const url = `${getConfig().apiUrl}/loans/tapeToFile`;
  return publicClient.patch(url, assignObj);
}

export function changeWorkflowStateThroughDB(assignObj: any) {
  const url = `${getConfig().apiUrl}/loans/bulkAssign`;
  return publicClient.patch(url, assignObj);
}

export function createOrUpdateGroup(groupObj: any) {
  const url = `${getConfig().apiUrl}/aggregate/groups`;
  return publicClient.post(url, groupObj);
}

export function setSearchValue(newVal: string): any {
  return (dispatch: any) => {
    // dispatch({
    //   type: SET_SEARCH_VALUE,
    //   payload: newVal
    // });
    dispatch(setSearchValueRedux(newVal));
    setCookie("globalSearchValue", newVal);
  };
}

// export function setViewType(viewType: string): any {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_VIEW_TYPE,
//       payload: viewType
//     });
//   };
// }

export function completeStateThroughCamunda(
  loanObj: any,
  workflowState: any,
  updateQC: boolean
) {
  const url = `${getConfig().apiUrl}/camunda-aggregate/complete-state`;
  return publicClient.post(
    url,
    {
      taskName: workflowState,
      loanId: loanObj.loanId,
      variables: {
        nextTask: {
          value: loanObj.workflowState
        },
        ...(updateQC && {
          qcRequired: {
            value:
              typeof loanObj.qcRequired === "boolean"
                ? loanObj.qcRequired
                : true
          }
        })
      }
    },
    {
      errorConfig: getErrorConfigObject({ skipForStatus: [404] })
    } as any
  );
}

export function completeStateThroughCamundaTTF(loanObj: any, ttfState: any) {
  const url = `${getConfig().apiUrl}/camunda-aggregate/complete-state`;
  return publicClient.post(
    url,
    {
      taskName: ttfState,
      loanId: loanObj.loanId,
      variables: {
        nextTask: {
          value: loanObj.tapeToFileStatus
        }
      }
    },
    {
      errorConfig: getErrorConfigObject({ skipForStatus: [404, 400] })
    } as any
  );
}

export function getNextActionsFromCamunda(
  loanId: string,
  workflowState: any,
  callback: any
): any {
  return async (dispatch: any) => {
    const url = `${
      getConfig().apiUrl
    }/camunda-aggregate/next-state?loanId=${loanId}&taskName=${workflowState}`;
    // const url = `${
    //   getConfig().apiUrl
    // }/camunda-aggregate/next-state?loanId=${loanId}`;
    try {
      const response = await publicClient.get(url, {
        errorConfig: getErrorConfigObject({ skipForStatus: [404, 400] })
      } as any);
      if (response) {
        callback(response);
      }
    } catch (e) {
      callback(e);
    }
  };
}

export const updateILPState: any = (
  data: any,
  workflowState: any,
  cb?: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const response = await completeStateThroughCamunda(
        data[0],
        workflowState,
        true
      );
      if (response?.status === 200) {
        if (workflowState === InternalDDStatusEnum.ApproverPending) {
          const modData = { ...data[0] };
          modData.roleOfAssignee = isRole(InternalRolesEnum.LAT_ADMIN)
            ? InternalRolesEnum.LAT_ADMIN
            : InternalRolesEnum.LAT_APPROVER;
          modData.qcRequired = modData.qcRequired ?? true;
          modData.assignedTo = getCookie("person_id");
          await changeWorkflowStateThroughDB([modData]);
          dispatch(updateAssignedTo(modData.assignedTo));
        } /*else if (workflowState === InternalDDStatusEnum.ReviewInProgress) {
          if (
            loanState === LoanStatusEnum.DueDiligenceReview &&
            (loanProcessType === LoanProcessTypeEnum.Fantasy ||
              loanProcessType === LoanProcessTypeEnum.Express) &&
            (grade === "A" || grade === "B")
          ) {
            dispatch(
              dispatchAndUpdate(
                data[0].loanId,
                LoanStatusEnum.FinalReview,
                loanStage
              )
            );
          }
          // dispatch({
          //   type: UPDATE_COMPLETE_DATE
          // });
          dispatch(updateCompleteDate());
        }
        // dispatch({
        //   type: UPDATE_ILP_STATE,
        //   payload: data[0].workflowState
        // });*/
        dispatch(updateILPStateRedux(data[0].workflowState));
      }
      cb && cb();
      dispatch(hideLoader());
    } catch (e) {
      console.log(e);
      dispatch(hideLoader());
    }
  };
};

export const patchUpdateLoanDetails = (data: any[]) => {
  const newFormat = data.map((obj: any) => {
    let { loanId, loanStage, ...others } = obj;
    return {
      loanId,
      loanDetails: {
        ...others
      }
    };
  });
  return publicClient.patch(`${getConfig().apiUrl}/loans/bulk`, newFormat);
};

const pushTTFAudit = (
  dispatch: any,
  loanId: any,
  loanStage: any,
  field: string,
  comment: string
) => {
  const commentList = [
    {
      value: "Yes",
      comment,
      updatedBy: getCookie("person_id"),
      field
    }
  ];
  dispatch(updateEditComments(`${loanId}_${loanStage}`, commentList));
};

export const updateTTFStatus = async (
  data: any,
  dispatch: any,
  loanStage: any,
  loanDetails: any
) => {
  // intentionally == only. Do not change
  const oldTTF = loanDetails?.loanConfig?.ttfVersionId == null;
  const comment = oldTTF
    ? "tape to file activity complete"
    : "Final Doc Review Complete";
  const tapeToFileConfig = {
    value: loanDetails?.loanConfig?.tapeToFile,
    auditPoint: "data.loan.loanDetailId.loanConfigId.tapeToFile",
    patchObj: {
      loanConfig: {
        tapeToFile: "Yes"
      }
    },
    key: "tapeToFile"
  };
  if ([true, "true", "Yes"].includes(tapeToFileConfig.value)) {
    pushTTFAudit(
      dispatch,
      data.loanId,
      loanStage,
      tapeToFileConfig.auditPoint,
      comment
    );
  } else {
    const res = await patchUpdateLoanDetails([
      {
        loanId: data.loanId,
        ...tapeToFileConfig.patchObj
      }
    ]);
    if (res.status === 200) {
      pushTTFAudit(
        dispatch,
        data.loanId,
        loanStage,
        tapeToFileConfig.auditPoint,
        comment
      );
      dispatch(updateSummaryDetails(tapeToFileConfig.key, "Yes", false));
    }
  }
};

export const updateTapeToFileInTC = async (
  data: any,
  dispatch: any,
  loanStage: any,
  loanDetails: any
) => {
  if (data.tapeToFileStatus === tapeToFileStatusEnum.Completed) {
    updateTTFStatus(data, dispatch, loanStage, loanDetails);
  }
};

export const updateTTFState = async (
  data: any,
  loanStage: any,
  loanDetails: any,
  dispatch: any
) => {
  try {
    dispatch(showLoader());
    const response = await completeStateThroughCamundaTTF(
      data,
      data.ttfCurrentState
    );
    if (response?.status === 200) {
      const assignObj: any = [
        {
          loanId: data.loanId,
          tapeToFileStatus: data.tapeToFileStatus
        }
      ];
      if (data.tapeToFileStatus === tapeToFileStatusEnum.Pending) {
        assignObj[0].ttfAssignedTo = getCookie("person_id");
      }
      updateTapeToFileInTC(data, dispatch, loanStage, loanDetails);
      await changeTTFStateThroughDB(assignObj);
      // dispatch({
      //   type: UPDATE_TTF_STATE,
      //   payload: data.tapeToFileStatus
      // });
      dispatch(updateTTFStateRedux(data.tapeToFileStatus));

      dispatch(hideLoader());
      // temp code to support old ttf workflow . later we will remove it
      // const assignObj: any = [
      //   {
      //     loanId: data.loanId,
      //     tapeToFileStatus: data.tapeToFileStatus
      //   }
      // ];
      // updateTapeToFileInTC(data, dispatch, loanStage, tapeToFileConfig);
      // changeTTFStateThroughDB(assignObj);
      // dispatch({
      //   type: UPDATE_TTF_STATE,
      //   payload: data.tapeToFileStatus
      // });
      // dispatch(hideLoader());
    }
  } catch (e) {
    console.error(e);
    dispatch(hideLoader());
    // temp code to support old ttf workflow . later we will remove it
    // const assignObj: any = [
    //   {
    //     loanId: data.loanId,
    //     tapeToFileStatus: data.tapeToFileStatus
    //   }
    // ];
    // updateTapeToFileInTC(data, dispatch, loanStage, tapeToFileConfig);
    // changeTTFStateThroughDB(assignObj);
    // dispatch({
    //   type: UPDATE_TTF_STATE,
    //   payload: data.tapeToFileStatus
    // });
    // dispatch(hideLoader());
  }
};

export function getInternalUsers(returnBack?: boolean): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const url = `${getConfig().apiUrl}/aggregate/get/users?size=300&role=${
        InternalRolesEnum.LAT_IR
      }&role=${InternalRolesEnum.LAT_QC}&role=${
        InternalRolesEnum.LAT_VM
      }&role=${InternalRolesEnum.LAT_ADMIN}&role=${
        InternalRolesEnum.LAT_APPROVER
      }&role=${InternalRolesEnum.LAT_INQUIRY}`;
      const response = await publicClient.get(url);
      // dispatch({
      //   type: INTERNAL_USERS_SUCCESS,
      //   payload:
      //     response.data.access.length > 0
      //       ? [
      //           {
      //             access: response.data.access,
      //             customers: response.data.customers
      //           }
      //         ]
      //       : []
      // });
      dispatch(
        internalUsersSuccess(
          response.data.access.length > 0
            ? [
                {
                  access: response.data.access,
                  customers: response.data.customers
                }
              ]
            : []
        )
      );
      if (response) {
        dispatch(hideLoader());
      }
    } catch (err) {
      dispatch(hideLoader());
    }
  };
}

export function getInternalGroups(returnBack?: boolean): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const url = `${getConfig().apiUrl}/chatservice/groups`;
      const response = await publicClient.get(url);
      // dispatch({
      //   type: INTERNAL_GROUPS_SUCCESS,
      //   payload: response?.data?.length ? response.data : []
      // });
      dispatch(
        internalGroupsSuccess(response?.data?.length ? response.data : [])
      );
      if (response) {
        dispatch(hideLoader());
      }
    } catch (err) {
      dispatch(hideLoader());
    }
  };
}

export function getLoanDetails(
  loanId: string,
  exceptionId: any,
  callBack?: any
): any {
  return async (dispatch: any) => {
    const url = `${getConfig().apiUrl}/loans/${loanId}/details`;
    try {
      dispatch(showLoader());
      const response: any = await publicClient.get(url);
      if (response) {
        callBack(loanId, exceptionId, response.data);
      }
      dispatch(hideLoader());
    } catch (e) {
      console.error(e);
      dispatch(hideLoader());
    }
  };
}

export function getTagsData(loanStage: string, loanType: string): any {
  return async (dispatch: any) => {
    const url = `${
      getConfig().apiUrl
    }/aggregate/tagdetails/loanType/${loanType}/loanStage/${loanStage}/loanState/REVIEW`;
    try {
      const response: any = await publicClient.get(url);
      // dispatch({
      //   type: SET_TAGS_INFO,
      //   payload: response.data
      // });
      dispatch(setTagsInfo(response.data));
    } catch (e) {
      console.error(e);
    }
  };
}

export function bulkUpdateILPState(
  loanId: string,
  loanStage: string,
  payload: any,
  comments: any,
  tagsToSend: any,
  recommendation: string,
  callback: any
): any {
  return async (dispatch: any) => {
    dispatch(showLoader());
    const url = `${
      getConfig().apiUrl
    }/exceptionservice/${loanId}/status?loanStage=${loanStage}`;
    const response = await publicClient.put(url, payload);
    if (response) {
      // dispatch({
      //   type: COMPLETE_INQUIRY_STATUS,
      //   payload
      // });
      dispatch(completeInquiryStatus(payload));

      callback(payload, comments, tagsToSend, recommendation);
    } else {
      dispatch(hideLoader());
    }
  };
}

export function bulkUpdateILPStateAcrossLoans(
  payload: any,
  comments: any,
  tagsToSend: any,
  recommendation: string,
  callback: any
): any {
  return async (dispatch: any) => {
    dispatch(showLoader());
    const url = `${getConfig().apiUrl}/aggregate/exceptionservice/status`;
    const response = await publicClient.put(url, payload);
    if (response) {
      // dispatch({
      //   type: COMPLETE_INQUIRY_STATUS_ACTIONSTAB,
      //   payload
      // });
      dispatch(completeInquiryStatusActionstab(payload));
      callback(payload, comments, tagsToSend, recommendation);
    } else {
      dispatch(hideLoader());
    }
  };
}

export function fetchExceptions(searchQuery: any): any {
  return async (dispatch: any) => {
    dispatch(showLoader());
    try {
      dispatch(fetchExceptionsLoading());

      const url = `${getConfig().apiUrl}/search/${
        getConfig().dashboardUrl
      }/_search`;
      const response = await publicClient.post(url, searchQuery);

      dispatch(
        fetchExceptionsTotalCount(response.data.response.hits.total.value)
      );

      dispatch(fetchExceptions(response.data.response.hits.hits));

      dispatch(clearLoader());
    } catch (e) {
      dispatch(clearLoader());
      dispatch(fetchExceptionsError());
    }
    // dispatch({ type: FETCH_EXCEPTIONS_LOADING });
    // const url = `${getConfig().apiUrl}/search/${
    //   getConfig().dashboardUrl
    // }/_search`;
    // try {
    //   const response: any = await publicClient.post(url, searchQuery);
    //   dispatch({
    //     type: FETCH_EXCEPTIONS_TOTAL_COUNT,
    //     payload: response.data.response.hits.total.value
    //   });
    //   dispatch({
    //     type: FETCH_EXCEPTIONS,
    //     payload: response.data.response.hits.hits
    //   });
    //   dispatch(clearLoader());
    // } catch (e) {
    //   dispatch(clearLoader());
    //   dispatch({ type: FETCH_EXCEPTIONS_ERROR });
  };
}

export function getFilterData(parameters: any, forInquiry: boolean): any {
  let query: any = {
    aggs: {},
    query: defaultQuery(forInquiry)
  };
  for (const key in parameters) {
    if (parameters.hasOwnProperty(key)) {
      query.aggs[key] = {
        terms: {
          field: parameters[key],
          size: filterSize
        }
      };
    }
  }
  return async (dispatch: any) => {
    dispatch(showLoader());
    const url = `${getConfig().apiUrl}/search/${
      getConfig().dashboardUrl
    }/_search`;
    try {
      const response = await publicClient.post(url, query);
      if (response) {
        dispatch(filterOptionData(response.data));
      }
      dispatch(hideLoader());
    } catch (e) {
      dispatch(hideLoader());
    }
  };
}

// export function clearExceptionData(): any {
//   return (dispatch: any) => {
//     dispatch({
//       type: CLEAR_DATA
//     });
//   };
// }

export function updateSelectedFilters(value: any): any {
  return async (dispatch: any) => {
    try {
      const updatedSelection = JSON.parse(JSON.stringify(value));
      // dispatch({
      //   type: FILTERS_UPDATE_SUCCESS,
      //   payload: {
      //     filterSelections: updatedSelection
      //   }
      // });
      dispatch(
        filtersUpdateSuccess({
          filterSelections: updatedSelection
        })
      );
    } catch (err) {
      console.log(err);
    }
  };
}

// export function toggleRefresh(): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: TOGGLE_REFRESH
//     });
//   };
// }

export const updateWorkflowState = async (payload: any) => {
  try {
    const url = `${getConfig().apiUrl}/aggregate/exceptionservice/status`;

    const resp = await publicClient.put(url, payload);
    return Promise.resolve(resp);
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export function convertInquiryToQueriesActionsTab(
  payload: any,
  callback?: any
): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      let url = `${getConfig().apiUrl}/aggregate/exceptionservice/responses`;
      const response = await publicClient.post(url, payload);
      if (response && callback) {
        callback(response?.data, payload);
      }
      dispatch(hideLoader());
    } catch (err) {
      console.log(err);
    }
  };
}

export function postBulkActionAtActionsTab(
  pendingLoans: any,
  payload: any,
  callback: any
): any {
  return async (dispatch: any) => {
    dispatch(showLoader());
    if (pendingLoans.length) {
      try {
        const camundAsyncOperations: any[] = [];
        pendingLoans.forEach((loan: any) => {
          camundAsyncOperations.push(
            completeStateThroughCamunda(
              loan,
              InternalDDStatusEnum.ApproverPending,
              false
            )
          );
        });
        Promise.all(camundAsyncOperations)
          .then(async (res: any) => {
            if (
              res.status === 200 ||
              (res instanceof Array && res.some((obj) => obj.status === 200))
            ) {
              if (res instanceof Array) {
                for (let i = 0; i < res.length; i++) {
                  if (res[i].status !== 200) {
                    pendingLoans.splice(i, 1);
                  }
                }
              }
              const response = await changeWorkflowStateThroughDB(pendingLoans);
              if (response.status === 200) {
                dispatch(postAction(callback, payload));
              } else {
                dispatch(hideLoader());
              }
            } else {
              console.error(res);
              dispatch(hideLoader());
            }
          })
          .catch((e) => {
            console.error(e);
            dispatch(hideLoader());
          });
      } catch (e) {
        console.error(e);
        dispatch(hideLoader());
      }
    } else {
      dispatch(postAction(callback, payload));
    }
  };
}

export function postAction(callback: any, payload: any): any {
  return async (dispatch: any) => {
    const url = `${
      getConfig().apiUrl
    }/aggregate/exceptionservice/responses?responseType=${
      ResponseTypeEnum.Approver
    }`;
    const response = await publicClient.post(url, { add: payload });
    if (response) {
      callback(response);
    } else {
      dispatch(hideLoader());
    }
  };
}

export function sendQueriesToOriginatorActionsTab(reqBody: any): any {
  const url = `${
    getConfig().apiUrl
  }/aggregate/exceptionservice/responses?&responseType=${
    ResponseTypeEnum.SendtoOriginator
  }`;
  return new Promise((resolve, reject) => {
    publicClient
      .post(url, reqBody)
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}

export function getInternalTags(): any {
  return async (dispatch: any) => {
    try {
      const url = `${getConfig().apiUrl}/getAllInternalTags`;
      const response = await publicClient.get(url);
      // dispatch({
      //   type: SET_INTERNAL_TAGS,
      //   payload: {
      //     tags: response.data.length > 0 ? response.data : []
      //   }
      // });
      dispatch(
        setInternalTags({
          tags: response.data.length > 0 ? response.data : []
        })
      );
    } catch (err) {
      console.log("error");
    }
  };
}

export function getLowCsData(reqObj: any): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      let queryParam = `${reqObj.perPageData}&offset=${reqObj.pageNo}&confidence=${reqObj.confidenceScore}`;
      if (reqObj.createdInLast) {
        queryParam += `&createdInLast=${reqObj.createdInLast}`;
      }
      if (reqObj.loanId) {
        queryParam += `&loanId=${reqObj.loanId}`;
      }
      const url = `${
        getConfig().apiUrl
      }/documentdetails/review?limit=${queryParam}`;
      const response = await publicClient.get(url);
      if (response) {
        // dispatch({
        //   type: SET_TABLE_DATA,
        //   payload: {
        //     tableData:
        //       response?.data?.documents?.length > 0 ? response.data : []
        //   }
        // });
        dispatch(
          setTableData({
            tableData:
              response?.data?.documents?.length > 0 ? response.data : []
          })
        );
        dispatch(hideLoader());
      }
    } catch (err) {
      console.log("get low cs error", err);
    }
  };
}

// export function updateActionLoadingStatus(
//   exceptionId: any,
//   loadingStatus: boolean
// ): any {
//   return (dispatch: any) => {
//     dispatch({
//       type: UPDATE_ACTION_LOADING_STATUS,
//       payload: {
//         exceptionId,
//         loadingStatus
//       }
//     });
//   };
// }

export function setILPUserList(payload: any): any {
  return (dispatch: any) => {
    const userObj: any = {};
    const qcVMAdminObj: any = {};
    const irQCVMAdminObj: any = {};
    const roleObj: any = {};
    payload.customers.forEach((item: any) => {
      if (!userObj[item?.partyId]) {
        const firstName = item?.firstName ?? "";
        const middleName = item?.middleName ?? "";
        const lastName = item?.lastName ?? "";
        const fullName = constructName(firstName, middleName, lastName);
        userObj[item.partyId] = fullName;
        const accessArrayIndex = payload.access.findIndex(
          (obj: any) => obj.personId === item.partyId
        );
        if (accessArrayIndex > -1) {
          if (
            payload.access?.[accessArrayIndex]?.roles.some((item: any) =>
              [
                InternalRolesEnum.LAT_QC,
                InternalRolesEnum.LAT_VM,
                InternalRolesEnum.LAT_ADMIN
              ].includes(item.roleName)
            )
          ) {
            qcVMAdminObj[item.partyId] = fullName;
            irQCVMAdminObj[item.partyId] = fullName;
          }
          if (
            payload.access?.[accessArrayIndex]?.roles.some(
              (item: any) => item.roleName === InternalRolesEnum.LAT_IR
            )
          ) {
            irQCVMAdminObj[item.partyId] = fullName;
          }
          if (
            payload.access?.[accessArrayIndex]?.roles.some(
              (item: any) => item.roleName === InternalRolesEnum.LAT_ADMIN
            )
          ) {
            roleObj[item.partyId] = InternalRolesEnum.LAT_ADMIN;
          } else if (
            payload.access?.[accessArrayIndex]?.roles.some(
              (item: any) => item.roleName === InternalRolesEnum.LAT_VM
            )
          ) {
            roleObj[item.partyId] = InternalRolesEnum.LAT_VM;
          } else if (
            payload.access?.[accessArrayIndex]?.roles.some(
              (item: any) => item.roleName === InternalRolesEnum.LAT_APPROVER
            )
          ) {
            roleObj[item.partyId] = InternalRolesEnum.LAT_APPROVER;
          } else if (
            payload.access?.[accessArrayIndex]?.roles.some(
              (item: any) => item.roleName === InternalRolesEnum.LAT_INQUIRY
            )
          ) {
            roleObj[item.partyId] = InternalRolesEnum.LAT_INQUIRY;
          } else if (
            payload.access?.[accessArrayIndex]?.roles.some(
              (item: any) => item.roleName === InternalRolesEnum.LAT_QC
            )
          ) {
            roleObj[item.partyId] = InternalRolesEnum.LAT_QC;
          } else if (
            payload.access?.[accessArrayIndex]?.roles.some(
              (item: any) => item.roleName === InternalRolesEnum.LAT_IR
            )
          ) {
            roleObj[item.partyId] = InternalRolesEnum.LAT_IR;
          } else {
            roleObj[item.partyId] =
              payload.access?.[accessArrayIndex]?.roles?.[0]?.roleName;
          }
        } else {
          roleObj[item.partyId] = `UnIdentified User Role`;
        }
      }
    });
    payload.access.forEach((item: any, i: number) => {
      if (!userObj[item?.personId]) {
        const fullName = `UnIdentified User`;
        userObj[item.personId] = fullName;
        if (
          payload.access?.[i]?.roles.some(
            (item: any) => item.roleName === InternalRolesEnum.LAT_ADMIN
          )
        ) {
          roleObj[item.personId] = InternalRolesEnum.LAT_ADMIN;
        } else if (
          payload.access?.[i]?.roles.some(
            (item: any) => item.roleName === InternalRolesEnum.LAT_VM
          )
        ) {
          roleObj[item.personId] = InternalRolesEnum.LAT_VM;
        } else if (
          payload.access?.[i]?.roles.some(
            (item: any) => item.roleName === InternalRolesEnum.LAT_APPROVER
          )
        ) {
          roleObj[item.personId] = InternalRolesEnum.LAT_APPROVER;
        } else if (
          payload.access?.[i]?.roles.some(
            (item: any) => item.roleName === InternalRolesEnum.LAT_INQUIRY
          )
        ) {
          roleObj[item.personId] = InternalRolesEnum.LAT_INQUIRY;
        } else if (
          payload.access?.[i]?.roles.some(
            (item: any) => item.roleName === InternalRolesEnum.LAT_QC
          )
        ) {
          roleObj[item.personId] = InternalRolesEnum.LAT_QC;
        } else if (
          payload.access?.[i]?.roles.some(
            (item: any) => item.roleName === InternalRolesEnum.LAT_IR
          )
        ) {
          roleObj[item.personId] = InternalRolesEnum.LAT_IR;
        } else {
          roleObj[item.personId] = payload.access?.[i]?.roles?.[0]?.roleName;
        }
      }
    });
    // dispatch({
    //   type: SET_QC_LIST,
    //   payload: qcVMAdminObj
    // });
    // dispatch({
    //   type: SET_ROLE_OBJ_FOR_SDD,
    //   payload: roleObj
    // });
    dispatch(setQcList(qcVMAdminObj));
    dispatch(setRoleObjForSdd(roleObj));
    // dispatch({
    //   type: SET_PARTYID,
    //   payload: userObj
    // });
    dispatch(setPartyId(userObj));

    // dispatch({
    //   type: SET_IR_QC_VM_ADMIN_LIST,
    //   payload: irQCVMAdminObj
    // });
    dispatch(setIrQcVmAdminList(irQCVMAdminObj));
  };
}

// export function updateIRQCVMAdminMap(payload: any): any {
//   return (dispatch: any) => {
//     dispatch({
//       type: SYNC_IR_QC_VM_ADMIN_MAP,
//       payload
//     });
//   };
// }

// export function setILPColumnTab(payload: number): any {
//   return (dispatch: any) => {
//     dispatch({
//       type: UPDATE_ILP_COLUMN_TAB,
//       payload
//     });
//   };
// }
