import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      input: {
        backgroundColor: "#ffffff !important"
      }
    },
    container: {
      borderRadius: 4,
      display: "flex",
      flexDirection: "column",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      backgroundColor: "#ffffff",
      marginTop: theme.spacing(3)
    },
    sectionWrapper: {
      padding: "12px",
      display: "flex",
      width: "100%",
      margin: "0"
    },
    headingTypography: {
      textAlign: "left",
      fontSize: 14,
      fontWeight: 600,
      color: "#32325d",
      lineHeight: 1.57
    },
    headingImageContainer: {
      justifyContent: "flex-end",
      display: "flex"
    },
    checkBoxContainer: {
      border: "1px solid rgb(233, 236, 239)",
      padding: "14px 16px 14px 24px",
      borderRadius: "4px"
    },
    headingImage: {
      width: 16,
      height: 16,
      objectFit: "contain",
      cursor: "pointer",
      verticalAlign: "top"
    },
    divider: {
      height: "1px",
      border: "solid 1px #f2f2f2",
      padding: "0px !important"
    },
    formSelectContainer: {
      justifyContent: "space-around",
      display: "flex",
      flexDirection: "row",
      width: "95%",
      padding: "8px 0px 0px 0px"
    },
    iconInfo: {
      width: "16px !important",
      height: "16px !important",
      color: "#32355d",
      "&:hover": {
        color: "#5e72e4"
      }
    },
    labelInformation: {
      fontSize: 12,
      fontWeight: 600,
      color: "#8897aa",
      height: "14px",
      "&:hover": {
        color: "#5e72e4"
      }
    },
    primaryGuarantorWrapper: {
      padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(
        1
      )} ${theme.spacing(3)}`,
      display: "flex",
      color: "#32325d"
    },
    sectionButtonWrapper: {
      padding: `${theme.spacing(0)} ${theme.spacing(3)} ${theme.spacing(
        3
      )} ${theme.spacing(3)}`,
      display: "flex",
      width: "100%",
      margin: "0"
    },
    guarantorHeadingTypography: {
      textAlign: "left",
      fontSize: "14px !important",
      fontWeight: 600,
      color: "#32325d",
      lineHeight: 1.57
    },
    modalDeleteButton: {
      marginRight: 12,
      color: "#ffffff",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      marginTop: 18,
      borderWidth: 0
    },
    modalCancelButton: {
      color: "#32325d",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      backgroundColor: "#ffffff",
      marginTop: 18,
      borderWidth: 1,
      borderColor: "#ececec"
    },
    modalButton: {
      marginRight: 12,
      color: "#ffffff",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      marginTop: 18,
      borderWidth: 0,
      "&:hover": {
        backgroundColor: "#5566d6 !important"
      }
    },
    modalDeleteButtonConatainer: {
      marginTop: 50
    },
    editImage: {
      width: 16,
      height: 16,
      marginRight: 16,
      objectFit: "contain",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    editImageDisplay: {
      display: "none"
    },
    savebutton: {
      width: 70,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4"
    },
    cancelButton: {
      width: 69,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      color: "#32325d",
      backgroundColor: "#FFF",
      marginLeft: 10,
      "&:hover": {
        background: "none"
      }
    },
    addGuarantorBtn: {
      width: "100%",
      [theme.breakpoints.up("md")]: {
        marginRight: "20px"
      }
    },
  })
);
