import { Theme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

export const onHoldStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: "calc(100% - 64px)"
  },
  paperWidthSm: {
    maxWidth: "786px"
  },
  dividerRoot: {
    backgroundColor: "#f2f2f2"
  },
  dialogContentRoot: {
    padding: 20,
    paddingBottom: 0
  },
  dialogActionsRoot: {
    padding: 20,
    justifyContent: "flex-start"
  },
  buttonRoot: {
    backgroundColor: "#5566d6",
    "&:hover": {
      backgroundColor: "#3d55df"
    },
    "&:focus": {
      backgroundColor: "#3d55df"
    }
  },
  buttonLabel: {
    color: "#fff"
  },
  startIcon: {
    marginRight: 4
  },
  uploadLabel: {
    margin: "0 !important"
  },
  uploadBtn: {
    width: 33,
    height: 33,
    minWidth: "auto"
  },
  fileListWrap: {
    marginTop: "10px"
  },
  fileListItem: {
    padding: "10px 0"
  },
  chipWrap: {
    gap: "15px"
  },
  chipRoot: {
    borderRadius: "6px",
    marginLeft: "5px",
    backgroundColor: "#fb623f",
    color: "#fff",
    padding: "2px 4px",
    height: "auto"
  },
  label: {
    padding: 0,
    fontSize: "10px",
    fontWeight: "bold"
  },
  deleteIcon: {
    margin: 0,
    color: "#fff",
    marginLeft: "3px"
  },
  clickable: {
    "&:hover": {
      backgroundColor: "#fb623f"
    },
    "&:focus": {
      backgroundColor: "#fb623f"
    }
  },
  alertRoot: {
    padding: "6px 10px",
    backgroundColor: "#e9fafd",
    color: "#32325d",
    fontWeight: 600,
    alignItems: "center"
  },
  infoIcon: {
    color: "inherit !important",
    padding: 0,
    marginRight: 5,
    "& .MuiSvgIcon-root": {
      width: 16,
      height: 16
    }
  },
  alertMessage: {
    padding: 0
  },
  removeBtn: {
    position: "absolute",
    right: 50,
    top: 10
  },
  noteText: {
    fontSize: 12,
    color: "#454b60",
    fontWeight: "normal",
    padding: "5px 0"
  }
}));
