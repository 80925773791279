import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { ZeroStateLoan } from "../create-loan/loan-lists/DetailsView/ZeroStateLoan";
import { ObjectType } from "../masterView/common";
import LoanCard from "./LoanCard";
import { zeroStateStyles } from "./styles";
import { tkopStyles } from "./tkop.style";
import { useMultiSelection } from "./Utils";
import { TakeOutPartnerContext } from "./takeoutPartner";
import { ListHeader } from "./ListHeader";
import useLazyScroll from "../custom-hooks/useLazyScroll";

export interface LoanListHeaderProps {
  list: Map<string, ObjectType>;
  isDDreviewLoanAccordianStateSet: boolean;
  setDDreviewLoanState: Function;
}

export const TKOPDDReviewListView = (props: any) => {
  const { appliedFiltersData, fetchOnlyDDReviewInitaitedData } = useContext(
    TakeOutPartnerContext
  );
  const { list, isDDreviewLoanAccordianStateSet, setDDreviewLoanState } = props;

  const classes = zeroStateStyles();
  const classesTKOP = tkopStyles();

  const [updatedLoansData, setUpdatedLoansData] = useState({});
  const {
    selectedItems,
    setSelectedItems,
    updateSelectedItems
  } = useMultiSelection({ key: "loanId" });

  useEffect(() => {
    setSelectedItems(new Map());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFiltersData]);

  const onBulkSuccessCallBack = (data: ObjectType) => {
    setUpdatedLoansData({ ...updatedLoansData, ...data });
  };

  const loanList: ObjectType[] = list;

  const scrollRefNDD = useRef<HTMLDivElement>(null);
  const onScroll = useCallback(() => {
    if (loanList.length < 20) return;
    fetchOnlyDDReviewInitaitedData(
      "",
      loanList.length,
      appliedFiltersData,
      false
    );
  }, [loanList, appliedFiltersData, fetchOnlyDDReviewInitaitedData]);

  useLazyScroll(scrollRefNDD, onScroll, true);

  return (
    <>
      <Accordion
        elevation={0}
        onChange={() => setDDreviewLoanState(!isDDreviewLoanAccordianStateSet)}
        classes={{
          root: classesTKOP.accordion,
          expanded: classesTKOP.accordionExpanded
        }}
        expanded={isDDreviewLoanAccordianStateSet}
      >
        <AccordionSummary
          expandIcon={
            isDDreviewLoanAccordianStateSet ? (
              <RemoveIcon
                fontSize="small"
                className={classesTKOP.smallAccordionSvg}
                color="primary"
              />
            ) : (
              <AddIcon
                fontSize="small"
                className={classesTKOP.smallAccordionSvg}
                color="primary"
              />
            )
          }
          classes={{ root: classesTKOP.accordionSummaryList }}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <ListHeader
            onSelectAll={setSelectedItems}
            selectedItems={selectedItems}
            itemsList={loanList}
            isDropDownNeeded={false}
            listHeaderText={`DD Review Initiated Loans (${loanList.length})`}
            onSuccessCallBack={onBulkSuccessCallBack}
            idKey="loanId"
            partners={[]}
          />
        </AccordionSummary>

        <AccordionDetails
          classes={{
            root: classesTKOP.accordionDetails
          }}
        >
          <Grid
            style={{ maxHeight: "80vh", overflowY: "auto" }}
            ref={scrollRefNDD}
          >
            {loanList.map((loan: ObjectType) => {
              return (
                <LoanCard
                  key={loan.loanId}
                  isDropDownNeeded={false}
                  isSelected={selectedItems.has(loan.loanId)}
                  loanDetails={loan}
                  updateSelectedLoans={updateSelectedItems}
                />
              );
            })}
            {loanList.length === 0 && (
              <ZeroStateLoan
                textToShow="There are no loans available!"
                secondTextToShow=""
                customClasses={classes}
              />
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
