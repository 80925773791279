//We are not using this reducer anymore. this file has some logics that we need to complete fes flow.Once those are done this fine will be deleted.

import { LocationSuggestion } from "@toorak/tc-common-fe-sdk";
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */

// import produce from "immer";

// import {
//   EDIT_FES_SECTION,
//   GET_FES_LOAN_BY_ID_SUCCESS,
//   GET_FES_PROPERTY_BY_ID_SUCCESS,
//   SET_SECTION_PREVIOUS_STATE,
//   UPDATE_FES_LOAN_DETAILS,
//   UPDATE_FES_PROPERTY_DETAILS,
//   VALIDATE_FES_FORM
// } from "./fesLoanCreation.action";

export interface FesBorrower {
  borrowerExperience: string;
  foreignNationalString: string;
  borrowerType: string;
  borrowerCreditScore: number | null;
  inState?: string;
}

export interface FesLoanInfo {
  loanStructure: string;
  loanPurpose: string;
  cashoutFlag: string;
  recourse: string;
  // exitStrategy: string;
  pledgeOfEquity: string;
  totalOriginationAndDiscountPoints: string;
  expectedClosingDate: Date | null;
  closingMoreThanTwoPercent: string;
  numberOfProperties: number | null;
  predominantState: string;
  predominantPropertyType: string;
  crossCollaterlization?: string;
  includeOutOfPocketBudgetInARLTV?: boolean;
  takeoutPartnerId?: string;
  takeoutPartnerStatus?: string;
  eligibleTakeoutPartners?: string[];
}

export interface FesLoanEconomics {
  rehabBudget: string;
  originalLoanAmount: string;
  rehabAmount: string;
  interestRate: string;
  prepaymentPenalty: number | null;
  interestOnlyPeriod: number | null;
  includeOutOfPocketBudgetInARLTV?: boolean;
}
export interface FesLoanCondo {
  monthlyHOAFee: string;
  hoaDuesAboveFifteen: string;
  hoaDuesAboveTwenty: string;
  reserveFundsForOneYear: string;
  reserveFundsForTwoYears: string;
  isProjectCompleted: string;
  subjectToAdditionalPhasing: string;
  soldPercentage: any;
  hoaUnderOwnerControl: string;
  simpleEstateOwnership: string;
  ownerOccupiedUnits: string;
  renterOccupiedUnits: string;
  renterOccupiedPercentage: string;
  individualOwnershipAboveTwentyFive: string;
  masterAssurancePolicy: string;
  projectHasMobileHomes: string;
  otherSourcesIncomeMoreThanTwenty: string;
  commercialPurposesAreaMoreThanForty: string;
  restrictOwnerToRent: string;
  documentsWithSEC: string;
  hoawithLitOrBankruptcy: string;
}
export interface FesPropertyInfo {
  propertyType: string;
  numberOfUnits: string;
  changeInUseCase: string | null;
  valuationSource: string;
  appraisalVendor: string;
  propertyCondition: string;
  squareFootage: string;
  isPropertyTypeTwoFourUnit: string;
  isPropertyTypeCondo: string;
  propertyOriginallyAcquired: Date | null;
  propertyOriginallyPurchased: string;
}
export interface FesPropertyLocation {
  address: LocationSuggestion | null;
  zipCode: string;
  state: string;
  city: string;
}
export interface FesPropertyEconomics {
  originalAsIsAppraisalValue: string;
  purchasePrice: string;
  originalAsRepairedAppraisedValue: string;
  costBasis: string;
  assignmentFees: string;
  // asStabilizedMarketRentalValue: string;
  grossPotentialRent: string;
  appraisalReportedNoiNcf: string;
  annualPropertyTaxes: string;
  insurance: string;
  thirdPartyValuationStatus: string;
  thirdPartyValuation: number | null;
  acquisitionPrice: number | null;
  closingCosts: string;
  annualHazardInsurance: string;
  annualFloodInsurance: string;
  annualHOAFee: string;
  decliningMarkets?: string | null;
}

export interface FesUnitInformation {
  recentLeaseStartDate: Date | null;
  leaseEndDate: Date | null;
  monthToMonthLease: string;
  leaseForTwelveMonths: string;
  unitVacateForTwoMonths: string;
  leaseLessThanTermOfTwoMonth: string;
  unitCategorizedAbove: string;
  rentalCharacterization: string;
  percentageOccupied: string;
  marketRentMonthly: string;
  inPlaceRentMonthly: string;
  mostRecentLeaseRentMonthly: string;
  currentLeaseTermMonthly: string;
}

export interface FesLoanDetails {
  [index: string]: any;
  borrowerInformation: FesBorrower;
  loanInformation: FesLoanInfo;
  loanEconomics: FesLoanEconomics;
}
export interface FesPropertyDetails {
  [index: string]: any;
  propertyId: string;
  propertyInformation: FesPropertyInfo;
  propertyEconomics: FesPropertyEconomics;
  unitInformation: FesUnitInformation;
  propertyLocation: FesPropertyLocation | undefined;
}

export interface FesLoanCreationStore {
  [index: string]: any;
  editSection: {
    editSectionName: string;
    sectionBeingEdited: boolean;
    error: string;
  };
  submitForm: {
    loanErrors: number | null;
    propertyErrors: number | null;
    clicked: boolean;
  };
  validateForm: {
    isSubmitValidation: boolean;
    validate: boolean;
  };
  loanDetails: FesLoanDetails;
  propertyDetails: FesPropertyDetails[];
}
const initialState: FesLoanCreationStore = {
  editSection: {
    editSectionName: "",
    sectionBeingEdited: false,
    error: ""
  },
  submitForm: {
    loanErrors: 0,
    propertyErrors: 0,
    clicked: false
  },
  validateForm: {
    isSubmitValidation: false,
    validate: false
  },
  loanDetails: {
    borrowerInformation: {
      borrowerExperience: "",
      foreignNationalString: "",
      borrowerType: "",
      borrowerCreditScore: null
    },
    loanInformation: {
      loanStructure: "",
      loanPurpose: "",
      cashoutFlag: "",
      recourse: "",
      totalOriginationAndDiscountPoints: "",
      expectedClosingDate: null,
      closingMoreThanTwoPercent: "",
      numberOfProperties: null,
      predominantState: "",
      pledgeOfEquity: "",
      predominantPropertyType: "",
      crossCollaterlization: "N",
      includeOutOfPocketBudgetInARLTV: true
    },
    loanEconomics: {
      rehabBudget: "",
      originalLoanAmount: "",
      rehabAmount: "",
      interestRate: "",
      prepaymentPenalty: null,
      interestOnlyPeriod: null
    }
  },
  propertyDetails: [
    {
      propertyId: "1",
      propertyInformation: {
        propertyType: "",
        numberOfUnits: "",
        changeInUseCase: null,
        valuationSource: "",
        appraisalVendor: "",
        propertyCondition: "",
        squareFootage: "",
        isPropertyTypeTwoFourUnit: "",
        isPropertyTypeCondo: "",
        propertyOriginallyAcquired: null,
        propertyOriginallyPurchased: ""
      },
      propertyEconomics: {
        originalAsIsAppraisalValue: "",
        purchasePrice: "",
        assignmentFees: "",
        originalAsRepairedAppraisedValue: "",
        costBasis: "",
        grossPotentialRent: "",
        appraisalReportedNoiNcf: "",
        annualPropertyTaxes: "",
        insurance: "",
        thirdPartyValuationStatus: "",
        thirdPartyValuation: null,
        acquisitionPrice: null,
        closingCosts: "",
        annualHazardInsurance: "",
        annualFloodInsurance: "",
        annualHOAFee: "",
        decliningMarkets: "No"
      },
      unitInformation: {
        recentLeaseStartDate: null,
        leaseEndDate: null,
        monthToMonthLease: "",
        leaseForTwelveMonths: "",
        unitVacateForTwoMonths: "",
        leaseLessThanTermOfTwoMonth: "",
        unitCategorizedAbove: "",
        rentalCharacterization: "",
        percentageOccupied: "",
        marketRentMonthly: "",
        inPlaceRentMonthly: "",
        mostRecentLeaseRentMonthly: "",
        currentLeaseTermMonthly: ""
      },
      propertyLocation: {
        address: null,
        zipCode: "",
        state: "",
        city: ""
      }
    }
  ]
};

export const FesLoanCreationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    // case UPDATE_FES_LOAN_DETAILS: {
    //   const { sectionName, fieldName, fieldValue } = action.payload;
    //   const nextState = produce(state, draftState => {
    //     draftState.loanDetails[sectionName] = {
    //       ...draftState.loanDetails[sectionName],
    //       ...{ [fieldName]: fieldValue }
    //     };
    //   });
    //   return nextState;
    // }
    // case UPDATE_FES_PROPERTY_DETAILS: {
    //   const { sectionName, fieldName, fieldValue, propertyId } = action.payload;
    //   const nextState = produce(state, draftState => {
    //     const propertyBeingEdited = draftState.propertyDetails.find(
    //       item => item.propertyId === propertyId
    //     );
    //     if (propertyBeingEdited) {
    //       propertyBeingEdited[sectionName] = {
    //         ...propertyBeingEdited[sectionName],
    //         ...{ [fieldName]: fieldValue }
    //       };
    //       draftState.propertyDetails = draftState.propertyDetails.filter(
    //         item => {
    //           if (item.propertyId !== propertyId) {
    //             return item;
    //           }
    //           return { ...propertyBeingEdited };
    //         }
    //       );
    //     }
    //   });
    //   // console.log("nextState",nextState)
    //   return nextState;
    // }
    // case VALIDATE_FES_FORM: {
    //   const nextState = produce(state, draftState => {
    //     draftState.validateForm.validate = true;
    //   });
    //   return nextState;
    // }
    // case EDIT_FES_SECTION: {
    // console.log(action)
    // const nextState = produce(state, draftState => {
    //   draftState.editSection.error = "";
    //   draftState.editSection.sectionBeingEdited = action.payload;
    //   draftState.editSection.editSectionName = action.sectionValue;
    // draftState.loading = false;
    // });
    // console.log('EDIT_FES_SECTION',nextState)
    //   return nextState;
    // }
    // case SET_SECTION_PREVIOUS_STATE: {
    //   const { dataObj } = action.payload;
    //   const Key = action.payload.keyInfo;
    // console.log('action', action)
    // console.log("dataObjdataObj", dataObj)
    //   const nextState = produce(state, draftState => {
    //     if (action.payload.propertyData) {
    //       draftState.propertyDetails[0][Key] = dataObj;
    //     } else {
    //       draftState.loanDetails[Key] = dataObj;
    //     }
    //   });
    //   console.log("SET_SECTION_PREVIOUS_STATE", nextState);
    //   return nextState;
    // }
    // case GET_FES_LOAN_BY_ID_SUCCESS: {
    //   const nextState = produce(state, draftState => {
    //     draftState.loanDetails = {
    //       ...draftState.loanDetails,
    //       ...action.payload.loanDetails
    //     };
    //   });
    //   return nextState;
    // }
    // case GET_FES_PROPERTY_BY_ID_SUCCESS: {
    //   const nextState = produce(state, draftState => {
    //     draftState.propertyDetails = action.payload;
    //   });
    //   return nextState;
    // }
    default:
      return state;
  }
};
