import React, {
  useEffect,
  useState,
  useLayoutEffect,
  ReactElement
} from "react";
import { Grid, IconButton, FormControlLabel, Checkbox } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useStyles } from "./style";
import { ttfAction } from "../../constants";
import { CircularProgressWithLabel } from "../CircularProgressWithLabel";
import { TTFReviewCacheProvider } from "../../../../browser-cache/local-forage/TTFReviewCacheProvider";
import {
  getPresignedURL,
  getPresignedURLForDrawDocs
} from "../../../../network/apiService";
import { BootstrapTooltip, InfoOnlyTooltip } from "../../../../utils/Tooltip";
import {
  FirstPageIcon,
  LastPageIcon,
  NextPageIcon,
  PrevPageIcon,
  RefreshIcon,
  ZoomInIcon,
  ZoomOutIcon,
  DownloadIcon,
  RedirectIcon,
  SplitscreenViewIcon,
  RotateClockwiseIcon
} from "../../../Icons";
import BookmarksOutlinedIcon from "@mui/icons-material/BookmarksOutlined";
import { URLParamsType } from "../../../../utils/constants";

import {
  Worker,
  Viewer,
  RotateDirection,
  RenderPage,
  RenderPageProps
} from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import {
  pageNavigationPlugin,
  RenderGoToPageProps
} from "@react-pdf-viewer/page-navigation";
import {
  defaultLayoutPlugin,
  ToolbarProps,
  ToolbarSlot
} from "@react-pdf-viewer/default-layout";
import { getConfig } from "../../../../config/config";
import { findMinMax } from "../../ttfHelperFunctions";

const updateControlValues = (
  payload: any,
  documentReviewDispatch: any,
  state: any
) => {
  documentReviewDispatch({
    type: ttfAction.UPDATE_PDF_CONTROLS,
    payload: {
      value: payload,
      tabToUpdate: state.activeTab
    }
  });
};

interface PDFViewerProps {
  state: any;
  documentReviewDispatch: any;
  cacheKey?: any;
  useCache?: boolean;
  url?: string;
  name: string;
  highlightChecked?: boolean;
  highlightDisabled?: boolean;
  onChangeHighlightSelect?: Function;
  drawId?: string;
  overRideDrawIdCheck?: boolean;
}

export const PDFViewer = (props: PDFViewerProps) => {
  const cacheProvider = TTFReviewCacheProvider();
  const pixelPadding = 7;
  const [PDFData, setPDFData] = useState<null | string>(null);
  const [currentAngle, setCurrentAngle] = useState<number>(0);
  const [downloadProgress, setDownloadProgress] = useState<any>(null);
  const [currentRequest, setCurrentRequest] = useState<any>(null);
  const [redownload, setRedownload] = useState<boolean>(false);

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage } = pageNavigationPluginInstance;
  // eslint-disable-next-line
  const classes = useStyles();
  const {
    state,
    documentReviewDispatch,
    cacheKey,
    url,
    useCache = true,
    name,
    highlightChecked,
    highlightDisabled,
    overRideDrawIdCheck = false,
    onChangeHighlightSelect
  } = props;
  const { loanId, loanType, loanStage, drawId } = useParams<URLParamsType>();
  const { activePageNumber, highlightCoordinates = [] } =
    state[state.activeTab];
  const stringifiedHighlightCoordinates = JSON.stringify(highlightCoordinates);

  const onClickOpenInNewTab = () => {
    window.open(
      `${
        getConfig().redirectUrl
      }internal/loan/createLoan/${loanType}/${loanStage}/${loanId}/documentViewer/${cacheKey}/${name}`
    );
  };

  const onClickDownload = (downloadUrl: string) => {
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", name); // any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const handleRotate = (props: any) => {
    props.onClick();
    setCurrentAngle((currentAngle: number) => (currentAngle + 90) % 360);
  };

  const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <Toolbar>
      {(slots: ToolbarSlot) => {
        const {
          CurrentPageInput,
          Rotate,
          GoToFirstPage,
          GoToLastPage,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          ShowSearchPopover,
          Zoom,
          ZoomIn,
          ZoomOut
        } = slots;
        return (
          <div
            style={{
              alignItems: "center",
              display: "flex",
              width: "100%"
            }}
          >
            <div
              style={{ padding: "0px 2px", marginLeft: 2.6 }}
              className={classes.searchButton}
            >
              <ShowSearchPopover />
            </div>
            <GoToFirstPage>
              {(firstPageProps: RenderGoToPageProps) => (
                <GoToPreviousPage>
                  {(prevPageProps: RenderGoToPageProps) => (
                    <GoToNextPage>
                      {(nextPageProps: RenderGoToPageProps) => (
                        <GoToLastPage>
                          {(lastPageProps: RenderGoToPageProps) => (
                            <>
                              <div style={{ padding: "0px 2px" }}>
                                <BootstrapTooltip
                                  title={
                                    firstPageProps.isDisabled
                                      ? ""
                                      : "First page"
                                  }
                                  placement="bottom"
                                  arrow
                                >
                                  <IconButton
                                    aria-label="first-page"
                                    data-testid="first-page"
                                    onClick={firstPageProps.onClick}
                                    className={classes.toolbarIcon}
                                    disabled={firstPageProps.isDisabled}
                                    size="large"
                                  >
                                    <FirstPageIcon size={"18"} />
                                  </IconButton>
                                </BootstrapTooltip>
                              </div>
                              <div style={{ padding: "0px 2px" }}>
                                <BootstrapTooltip
                                  title={
                                    prevPageProps.isDisabled
                                      ? ""
                                      : "Previous page"
                                  }
                                  placement="bottom"
                                  arrow
                                >
                                  <IconButton
                                    aria-label="previous-page"
                                    data-testid="previous-page"
                                    onClick={prevPageProps.onClick}
                                    className={classes.toolbarIcon}
                                    style={{ paddingRight: 0 }}
                                    disabled={prevPageProps.isDisabled}
                                    size="large"
                                  >
                                    <PrevPageIcon size={"18"} />
                                  </IconButton>
                                </BootstrapTooltip>
                              </div>
                              <div className={classes.pageInput}>
                                <CurrentPageInput /> / <NumberOfPages />
                              </div>
                              <div style={{ padding: "0px 2px" }}>
                                <BootstrapTooltip
                                  title={
                                    nextPageProps.isDisabled ? "" : "Next page"
                                  }
                                  placement="bottom"
                                  arrow
                                >
                                  <IconButton
                                    aria-label="next-page"
                                    data-testid="next-page"
                                    onClick={nextPageProps.onClick}
                                    className={classes.toolbarIcon}
                                    style={{ paddingRight: 0 }}
                                    disabled={nextPageProps.isDisabled}
                                    size="large"
                                  >
                                    <NextPageIcon size={"18"} />
                                  </IconButton>
                                </BootstrapTooltip>
                              </div>
                              <div style={{ padding: "0px 2px" }}>
                                <BootstrapTooltip
                                  title={
                                    lastPageProps.isDisabled ? "" : "Last page"
                                  }
                                  placement="bottom"
                                  arrow
                                >
                                  <IconButton
                                    aria-label="last-page"
                                    data-testid="last-page"
                                    onClick={lastPageProps.onClick}
                                    className={classes.toolbarIcon}
                                    disabled={lastPageProps.isDisabled}
                                    size="large"
                                  >
                                    <LastPageIcon size={"18"} />
                                  </IconButton>
                                </BootstrapTooltip>
                              </div>
                            </>
                          )}
                        </GoToLastPage>
                      )}
                    </GoToNextPage>
                  )}
                </GoToPreviousPage>
              )}
            </GoToFirstPage>

            <Zoom>
              {(zoomProps: { onZoom: Function; scale: number }) => (
                <ZoomOut>
                  {(zoomOutProps: {
                    enableShortcuts: boolean;
                    onClick: React.MouseEventHandler<HTMLAnchorElement>;
                  }) => (
                    <ZoomIn>
                      {(zoomInProps: {
                        enableShortcuts: boolean;
                        onClick: React.MouseEventHandler<HTMLAnchorElement>;
                      }) => (
                        <>
                          <div style={{ padding: "0px 2px" }}>
                            <BootstrapTooltip
                              title={zoomProps.scale === 0.1 ? "" : "Zoom out"}
                              placement="bottom"
                              arrow
                            >
                              <IconButton
                                aria-label="zoom-out"
                                data-testid="zoom-out"
                                onClick={zoomOutProps.onClick}
                                href={""}
                                className={`${classes.toolbarIcon} ${classes.zoomOut}`}
                                disabled={zoomProps.scale === 0.1}
                                size="large"
                              >
                                <ZoomOutIcon size={"18"} />
                              </IconButton>
                            </BootstrapTooltip>
                          </div>
                          <div style={{ padding: "0px 2px" }}>
                            <Zoom />
                          </div>
                          <div style={{ padding: "0px 2px" }}>
                            <BootstrapTooltip
                              title={zoomProps.scale === 10 ? "" : "Zoom in"}
                              placement="bottom"
                              arrow
                            >
                              <IconButton
                                aria-label="zoom-in"
                                data-testid="zoom-in"
                                onClick={zoomInProps.onClick}
                                href={""}
                                className={classes.toolbarIcon}
                                disabled={zoomProps.scale === 10}
                                size="large"
                              >
                                <ZoomInIcon size={"18"} />
                              </IconButton>
                            </BootstrapTooltip>
                          </div>
                        </>
                      )}
                    </ZoomIn>
                  )}
                </ZoomOut>
              )}
            </Zoom>
            <div
              style={{
                padding: "0px 2px",
                paddingTop: 2
              }}
            >
              <Rotate direction={RotateDirection.Forward}>
                {(props: {
                  onClick: React.MouseEventHandler<HTMLAnchorElement>;
                }) => (
                  <BootstrapTooltip
                    title={"Rotate clockwise"}
                    placement="bottom"
                    arrow
                  >
                    <IconButton
                      aria-label="rotate-clockwise"
                      data-testid="rotate-clockwise"
                      onClick={(e) => handleRotate(props)}
                      href={""}
                      className={classes.toolbarIcon}
                      size="large"
                    >
                      <RotateClockwiseIcon viewBox={"0 3 16 16"} />
                    </IconButton>
                  </BootstrapTooltip>
                )}
              </Rotate>
            </div>
            {highlightChecked != null && (
              <div
                style={{
                  padding: "0px 8px",
                  position: "relative",
                  top: -2
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={highlightChecked}
                      onChange={onChangeHighlightSelect as any}
                      disabled={highlightDisabled}
                    />
                  }
                  label="Highlight"
                  className={classes.highlightCheckbox}
                />
              </div>
            )}
            {useCache && (
              <div
                style={{
                  padding: "0px 2px"
                }}
              >
                <BootstrapTooltip
                  title={"Open in new tab"}
                  placement="bottom"
                  arrow
                >
                  <IconButton
                    aria-label="open-in-new-tab"
                    data-testid="open-in-new-tab"
                    onClick={onClickOpenInNewTab}
                    className={classes.toolbarIcon}
                    size="large"
                  >
                    <RedirectIcon color="#183238" width={20} height={20} />
                  </IconButton>
                </BootstrapTooltip>
              </div>
            )}
            <div
              style={{
                padding: "0px 2px",
                marginRight: 5
              }}
            >
              <BootstrapTooltip title={"Download"} placement="bottom" arrow>
                <IconButton
                  aria-label="download"
                  data-testid="download"
                  onClick={(e: any) => onClickDownload(PDFData || "")}
                  className={classes.toolbarIcon}
                  size="large"
                >
                  <DownloadIcon size={"21"} viewBox={"0 1 16 16"} />
                </IconButton>
              </BootstrapTooltip>
            </div>
          </div>
        );
      }}
    </Toolbar>
  );

  const sidebarTabs = (defaultTabs: any) => {
    defaultTabs[0].icon = <SplitscreenViewIcon />;
    defaultTabs[1].icon = (
      <BookmarksOutlinedIcon
        style={{ width: 13, height: 16 }}
        viewBox="3 0 18 18"
      />
    );
    return [defaultTabs[0], defaultTabs[1]];
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs
  });

  const downloadFromServer = async () => {
    const ourRequest = axios.CancelToken.source();
    setCurrentRequest(ourRequest);
    let downloadURL = url;
    if (!downloadURL && cacheKey) {
      if (drawId && !overRideDrawIdCheck) {
        const res: any = await getPresignedURLForDrawDocs(
          drawId,
          Number(cacheKey)
        );
        downloadURL = res?.data?.downloadLinks?.[0].url;
      } else if (loanId) {
        const responseData: any = await getPresignedURL(
          "LOAN",
          loanId,
          cacheKey
        );
        downloadURL = responseData?.data?.url;
      }
    }
    if (!downloadURL) return null;

    let response;
    response = await axios({
      url: downloadURL,
      cancelToken: ourRequest.token,
      responseType: "blob",
      onDownloadProgress(progressEvent: any) {
        const progress = Math.round(
          (progressEvent?.loaded / progressEvent?.total) * 100
        );
        setDownloadProgress(progress);
      }
    });
    const { data } = response;
    if (cacheKey) {
      cacheProvider.setItem(cacheKey, data);
    }
    return data;
  };

  const readFile = async (useCache = false) => {
    try {
      const data =
        (useCache && cacheKey && (await cacheProvider.getItem(cacheKey))) ||
        (await downloadFromServer());
      if (url) {
        setPDFData(url);
      } else {
        setPDFData(window.URL.createObjectURL(data));
      }
    } catch {
      console.info(`Error reading data from cache`);
    }
  };

  const triggerRender = () => {
    if (currentRequest !== null) {
      currentRequest.cancel();
      setCurrentRequest(null);
    }
    setPDFData(null);
    const payload = {
      activePageNumber: 0,
      isDocumentLoading: true
    };
    updateControlValues(payload, documentReviewDispatch, state);
    setDownloadProgress(null);
    readFile(useCache);
  };

  useEffect(() => {
    setCurrentAngle(0);
  }, [cacheKey]);

  useEffect(() => {
    triggerRender();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cacheKey, redownload, url, stringifiedHighlightCoordinates]);

  const onDocumentLoadSuccess = (e: any) => {
    const payload = {
      totalPages: e?.doc?.numPages,
      activePageNumber: highlightCoordinates.length
        ? findMinMax(highlightCoordinates, "pageNo").min
        : 1,
      isDocumentLoading: false
    };
    updateControlValues(payload, documentReviewDispatch, state);
  };

  const onRenderPage: RenderPage = (props: RenderPageProps) => {
    const highlightSpans: JSX.Element[] = [];
    highlightCoordinates.forEach((item: any) => {
      if ((props.pageIndex + 1).toString() === item?.pageNo?.toString()) {
        const { left, top, width, height }: any = getBoundingRectCoordinates(
          item,
          props.width,
          props.height
        );
        highlightSpans.push(
          <span
            style={{
              position: "absolute",
              left: left - pixelPadding,
              width: width + 2 * pixelPadding,
              height: height + 2 * pixelPadding,
              top: top - pixelPadding,
              border: "2px solid red"
            }}
          ></span>
        );
      }
    });
    return (
      <>
        {props.canvasLayer.children}
        <div
          style={{
            alignItems: "center",
            height: "100%",
            justifyContent: "center",
            left: 0,
            position: "absolute",
            top: "0px",
            width: "100%"
          }}
        >
          {highlightSpans.map((ele) => ele)}
        </div>
        {props.annotationLayer.children}
        {props.textLayer.children}
      </>
    );
  };

  const getBoundingRectCoordinates = (
    item: any,
    canvasWidth: number,
    canvasHeight: number
  ) => {
    switch (currentAngle) {
      case 0: {
        const left = item.topLeft?.[0] * canvasWidth;
        const top = item.topLeft?.[1] * canvasHeight;
        const width = (item.bottomRight?.[0] - item.topLeft?.[0]) * canvasWidth;
        const height =
          (item.bottomRight?.[1] - item.topLeft?.[1]) * canvasHeight;
        return { left, top, width, height };
      }
      case 90: {
        const left = canvasWidth - item.bottomRight?.[1] * canvasWidth;
        const top = item.topLeft?.[0] * canvasHeight;
        const width = (item.bottomRight?.[1] - item.topLeft?.[1]) * canvasWidth;
        const height =
          (item.bottomRight?.[0] - item.topLeft?.[0]) * canvasHeight;
        return { left, top, width, height };
      }
      case 180: {
        const left = canvasWidth - item.bottomRight?.[0] * canvasWidth;
        const top = canvasHeight - item.bottomRight?.[1] * canvasHeight;
        const width = (item.bottomRight?.[0] - item.topLeft?.[0]) * canvasWidth;
        const height =
          (item.bottomRight?.[1] - item.topLeft?.[1]) * canvasHeight;
        return { left, top, width, height };
      }
      case 270: {
        const left = item.topLeft?.[1] * canvasWidth;
        const top = canvasHeight - item.bottomRight?.[0] * canvasHeight;
        const width = (item.bottomRight?.[1] - item.topLeft?.[1]) * canvasWidth;
        const height =
          (item.bottomRight?.[0] - item.topLeft?.[0]) * canvasHeight;
        return { left, top, width, height };
      }
      default:
        const left = item.topLeft?.[0] * canvasWidth;
        const top = item.topLeft?.[1] * canvasHeight;
        const width = (item.bottomRight?.[0] - item.topLeft?.[0]) * canvasWidth;
        const height =
          (item.bottomRight?.[1] - item.topLeft?.[1]) * canvasHeight;
        return { left, top, width, height };
    }
  };

  useLayoutEffect(() => {
    if (activePageNumber) {
      jumpToPage(activePageNumber - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePageNumber]);

  const reDownloadDoc = () => {
    setRedownload((prevState) => !prevState);
  };

  if (!PDFData) {
    return (
      <Grid className={classes.pdfContainer} style={{ alignItems: "center" }}>
        <InfoOnlyTooltip arrow placement="top" title="Re-download">
          <IconButton
            aria-label="Re-download"
            component="button"
            onClick={reDownloadDoc}
            style={{ position: "absolute", right: 0, zIndex: 1 }}
            size="large"
          >
            <RefreshIcon color="#808080" width={16} height={16} />
          </IconButton>
        </InfoOnlyTooltip>
        <CircularProgressWithLabel value={downloadProgress} />
      </Grid>
    );
  }
  return (
    <Grid className={classes.pdfContainer} id="pdfContainer">
      <Worker workerUrl={`${process.env.PUBLIC_URL}/workers/pdf.worker.min.js`}>
        <Viewer
          fileUrl={PDFData}
          plugins={[defaultLayoutPluginInstance, pageNavigationPluginInstance]}
          onDocumentLoad={onDocumentLoadSuccess}
          renderPage={onRenderPage}
          initialRotation={currentAngle}
        />
      </Worker>
    </Grid>
  );
};
