import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Grid,
  TextField,
  Dialog,
  Tooltip,
  Theme,
  Button,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Autocomplete } from "@mui/material";
import {
  publicClient,
  PropertyLocation,
  ViewModeTextField,
  WarningModal,
  WarningTag,
  PropertyInformation,
  LoanStage,
  CreateLoanParamsBridgeLoan,
  IconTooltip,
  GridTextFieldForm,
  WarningModalProps
} from "@toorak/tc-common-fe-sdk";
import CloseIcon from "@mui/icons-material/Close";
import { useAutoCorrectStyles } from "@toorak/tc-common-fe-sdk/dist/components/input/autocomplete/AutoCompleteForm";
import { useParams } from "react-router-dom";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { RootState } from "../../stores/rootReducer";
import { delay, throttle } from "../../utils/utils";
import arrowDown from "../../images/createloan/arrow-down.svg";

import {
  CityTextField,
  StateTextField,
  PostalCodeTextField
} from "../form-components/PropertyLocation";
import {
  updatePropertyDetails,
  validateForm as validateFormCheck,
  previousLoanState,
  // valueEditing,
  postPropertyData,
  // POST_PROPERTY_SUCCESS,
  // POST_PROPERTY_FAILED,
  postLoan,
  updateDuplicateLoan,
  keepCreditEvalSync,
  validateAddress
} from "../create-loan.action";
import iconMinimize from "../../images/icon_minimize.svg";
import iconMaximize from "../../images/icon_maximize.svg";
import { getConfig, getLoanType } from "../../config/config";
import { formatPropertyAddress } from "./PropertyDetailsCard";
import { getAffectedWaivers } from "../../stores/WaiverRequest/WaiverRequest.action";
import { globalSaveOnPropertyFieldChange } from "./PropertyDetailsPropertyEconomics";

import { getEvaluationRequestBody } from "../evaluation-results/helpers/ruleRequestBody";
import {
  evaluateLoan,
  getOverriddenOutputs,
  getWaiverList,
  updateOverriddenInredux
} from "../../stores/EvaluationResults/EvaluationResults.action";
import { getBridgeLoanGuarantorInformationToPass } from "../CreateLoanHeader";
import infoLightImg from "../../images/infowhite.svg";
import editImage from "../../images/Edit.svg";

import {
  showLoader,
  hideLoader
} from "../../stores/loaderAndException/loaderAndException.action";
import { BridgeLoanData } from "../form-components/FormComponentsProps";
import {
  getFesRulesRequestObject,
  postFesLoan,
  postFesPropertyData
} from "../../stores/FrontEndSizer/fesLoanCreation.action";
import { isLATUser } from "../../utils/AccessManagement";
import { isEmptyValue } from "../../utils/formatChecks";
import { isPropertyAddressSame } from "./PropertyDetailsHelper";
import { setAutoSaveValuesCall } from "../../stores/loanAndPropertyDetails/loanAndPropertyDetails.reducer";
import { handlePropUpdateAfterSubmit } from "./PropertyDetailsForm";
import { requestWaiverMessage } from "../../utils/constants";
import { fesEditFunctionSave } from "../../frontend-sizer/FrontendSizerSectionMapping";
import { useAppraisalForm } from "../appraisal/shared/useAppraisalForm";
import { ObjectType } from "../../masterView/common";
import {
  loanTypeFailed,
  setAutoSaveCall,
  updatePropertySuccess,
  valueEditing
} from "../create-loan.reducer";

export interface ZipCodeSuggestion {
  city: string;
  mailable_city: boolean;
  state: string;
  state_abbreviation: string;
  zipCode: any;
}
export const emptyZipCodeSuggestion = {
  city: "",
  mailable_city: false,
  state: "",
  state_abbreviation: "",
  zipCode: ""
};
const editableFlags: any = {};
let changedValueFields: any = [];
let originalPropertyLocation: any;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      input: {
        backgroundColor: "#ffffff !important"
      }
    },
    container: {
      borderRadius: 4,
      display: "flex",
      flexDirection: "column",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      backgroundColor: "#ffffff",
      marginTop: theme.spacing(3)
      // fontFamily: "OpenSans"
    },
    sectionWrapper: {
      padding: `${theme.spacing(3)} 0px`,
      display: "flex"
    },
    headingTypography: {
      textAlign: "left",
      fontSize: "14px !important",
      fontWeight: 600,
      color: "#32325d",
      lineHeight: 1.57
    },
    wh_16: {
      width: "16px !important",
      height: "16px !important"
    },
    headingImageContainer: {
      justifyContent: "flex-end",
      display: "flex"
    },
    editImage: {
      width: 16,
      height: 16,
      marginRight: 16,
      objectFit: "contain",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    editImageDisplay: {
      display: "none"
    },
    savebutton: {
      width: 70,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4"
    },

    cancelButton: {
      width: 69,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      color: "#32325d",
      backgroundColor: "#fff",
      marginLeft: 10,
      "&:hover": {
        background: "none"
      }
    },
    headingImage: {
      width: 16,
      height: 16,
      objectFit: "contain",
      cursor: "pointer"
    },
    divider: {
      height: "1px",
      border: "solid 1px #f2f2f2",
      padding: "0px !important"
    },
    formSelectContainer: {
      justifyContent: "space-around",
      display: "flex",
      flexDirection: "row",
      width: "95%",
      padding: "8px 0px 0px 0px"
    },
    iconInfo: {
      width: "16px !important",
      height: "16px !important",
      color: "#8897aa",
      "&:hover": {
        color: "#5e72e4"
      }
    },
    labelInformation: {
      fontSize: 12,
      fontWeight: 600,
      color: "#8897aa",
      height: "14px",
      "&:hover": {
        color: "#5e72e4"
      }
    },

    modalDeleteButton: {
      marginRight: 12,
      color: "#ffffff",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      marginTop: 18,
      borderWidth: 0
    },
    modalCancelButton: {
      color: "#32325d",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      backgroundColor: "#ffffff",
      marginTop: 18,
      borderWidth: 1,
      borderColor: "#ececec"
    },
    modalDeleteButtonConatainer: {
      marginTop: 50
    },
    modalButton: {
      marginRight: 12,
      color: "#32325d",
      backgroundColor: "#ffffff",
      padding: "3.5px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      marginTop: 18,
      borderWidth: 0,
      "&:hover": {
        backgroundColor: "none !important"
      }
    },
    icon: {
      width: 16,
      height: 16,
      marginLeft: "16px"
    },
    errorContainer: {
      // padding: "3px 7px 3px 7px",
      height: "23px",
      backgroundColor: "#f75676",
      borderRadius: 6,
      marginLeft: 6,
      display: "flex",
      alignItems: "center"
    },
    errorTypography: {
      color: "#ffffff",
      fontSize: 10,
      fontWeight: "bold",
      marginLeft: 4,
      textTransform: "uppercase"
    },
    ml_auto: {
      marginLeft: "auto"
    },
    c_pointer: {
      cursor: "pointer"
    }
  })
);
interface LocationSuggestion {
  street_line: string;
  secondary: string;
  city: string;
  state: string;
  zipcode: string;
  entries: number;
}

export interface PropertyDetailsPropertyLocationProps {
  propertyLocation?: PropertyLocation | null;
  propertyId: string;
  isEvaluationPanelVisible: boolean;
  isFesLoan?: boolean;
  propertyInformation?: PropertyInformation | null;
  data?: BridgeLoanData;
  isAllowedToEdit?: boolean;
  runEvalAfterUpdatePostSubmit: any;
  index?: number;
  isMandatoryServicerField?: any;
  isEditable?: any;
  setCommentObj?: any;
  reasonCommentObj?: any;
}

export function PropertyDetailsPropertyLocation({
  propertyLocation,
  propertyId,
  isEvaluationPanelVisible,
  data,
  isAllowedToEdit,
  runEvalAfterUpdatePostSubmit,
  index,
  isMandatoryServicerField,
  isEditable,
  setCommentObj,
  reasonCommentObj,
}: PropertyDetailsPropertyLocationProps) {
  const colSpan = 4;

  const dispatch = useDispatch();
  const autoClasses = useAutoCorrectStyles();
  const validateForm = useSelector<RootState, { validate: boolean }>(
    (state) => state.createLoanStore.validateForm
  );
  const { loanId, loanStage, loanType } = useParams<{
    loanId: string;
    loanStage: any;
    loanType: string;
  }>() as any;
  const { thirtyYearLoanInformation, fesLoanInformation } = useSelector<
    RootState,
    any
  >((state) => state.createLoanStore?.loanDetails);
  const takeoutPartnerId = useMemo(() => {
    if (loanStage === LoanStage.fes) {
      return fesLoanInformation?.takeoutPartnerId;
    } else {
      return thirtyYearLoanInformation?.payload?.takeoutPartnerId;
    }
  }, [loanStage, fesLoanInformation, thirtyYearLoanInformation]);

  const [callAutoSave, setCallAutoSave] = useState<{
    value: boolean;
    impactingFieldChange: boolean;
  }>({ value: false, impactingFieldChange: false });
  const {
    bridgeLoanBorrowerInformation,
    bridgeLoanInformation,
    bridgeLoanEconomics,
    // loanState,
    loanSizerType,
    bridgeLoanGuarantorInformation
  } = useSelector<RootState, any>((state) => state.createLoanStore.loanDetails);
  // const [tooltipClicked] = React.useState<any>("");
  const [infoIconMsg] = React.useState<string>("Information");
  const [editManually, setEditManually] = React.useState<boolean>(true);
  const [isSaveDisable, setIsSaveDisable] = React.useState<boolean>(false);
  const [ifUpdateRequired, setIfUpdateRequired] =
    React.useState<boolean>(false);
  useEffect(() => {
    if (callAutoSave.value) {
      postPropertyAutoSave(loanId);
      setCallAutoSave({ value: false, impactingFieldChange: false });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [propertyLocation, callAutoSave]);

  const loanEvaluationResult = useSelector<
    RootState,
    { loanResult: any; propertiesResults: any }
  >((state) => state.evaluationResultsStore?.loanEvaluationResult);
  // eslint-disable-next-line
  const [open, setOpen] = React.useState(false);
  const [openConfirmPopup, setOpenConfirmPopup] = React.useState(false);
  const [openWarning, setOpenWarning] = useState<WarningModalProps | null>(
    null
  );
  const [warningMessage, setWarningMessage] = useState<{
    message?: string;
    newValue?: any;
    showWarning: boolean;
  }>({ showWarning: false });
  const overriddenValues = useSelector<RootState, any>(
    (state) => state.evaluationResultsStore.overriddenValues
  );
  const { waiversCreated } = useSelector<RootState, any>(
    (state) => state.evaluationResultsStore
  );
  const [completeWarningMessage, setCompleteWarningMessage] = useState<{
    message?: string;
    newValue?: any;
    showWarning: boolean;
  }>({ showWarning: false });
  const [warningMsgForSameAddress, setWarningMsgForSameAddress] = useState<{
    message?: string;
    showWarning: boolean;
  }>({ showWarning: false });
  const [warningMsgForComplete, setWarningMsgForComplete] = useState<{
    message?: string;
    showWarning: boolean;
  }>({ showWarning: false });
  const classes = useStyles();
  const { propertyDetails, loanState } = useSelector<RootState, any>(
    (state) => state.createLoanStore.loanDetails
  );
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleOpenConfirmClose = () => {
    setOpenConfirmPopup(false);
  };
  let infoContent = useSelector<RootState, any>(
    (state) => state.createLoanStore.infoContentProperty
  );
  if (infoContent) {
    infoContent = JSON.parse(JSON.stringify(infoContent));
  }
  const postPropertyAutoSave = (impactingFieldChanged: string) => {
    if (!data) return;
    if (!isEvaluationPanelVisible || ifUpdateRequired) {
      if (loanStage !== LoanStage.fes) {
        dispatch(setAutoSaveCall(true));
        const promise2 = postPropertyData(loanId, loanStage, propertyDetails);
        promise2
          .then((response: CreateLoanParamsBridgeLoan) => {
            // dispatch({
            //   type: POST_PROPERTY_SUCCESS,
            //   payload: {
            //     reservation: response
            //   }
            // });
            dispatch(updatePropertySuccess());
            if (impactingFieldChanged) {
              getEvaluationRequestBody(
                loanId,
                loanStage,
                data,
                loanEvaluationResult,
                propertyDetails,
                loanType
              )
                .then(({ evaluate, loanEvaluationRequest }) => {
                  if (evaluate && loanEvaluationRequest) {
                    if (ifUpdateRequired) {
                      loanEvaluationRequest.ruleAttributs = {
                        ruleGroup: [],
                        ruleField: [],
                        ruleResult: []
                      };
                    }
                    dispatch(
                      evaluateLoan(loanEvaluationRequest, loanId, loanStage)
                    );
                    if (ifUpdateRequired) postEditSuccess();
                  }else{
                    dispatch(setAutoSaveCall(false));
                  }
                })
                .catch((error: any) => {
                  dispatch(setAutoSaveCall(false));
                  console.error(
                    "error while sending evaluation request",
                    error
                  );
                });
            }else{
              dispatch(setAutoSaveCall(false));
            }
          })
          .catch((error: any) => {
            console.error(error);
            dispatch(setAutoSaveCall(false));
            // dispatch({ type: POST_PROPERTY_FAILED, error });
            dispatch(
              loanTypeFailed({
                error
              })
            );
          });
      } else {
        dispatch(
          setAutoSaveValuesCall({
            callAutoSave: true,
            tabType: "properties",
            changedFieldId: "city"
          })
        );
      }
    }
  };

  const otherAddresses = useSelector<RootState, LocationSuggestion[]>((state) =>
    state.createLoanStore.loanDetails.propertyDetails.map((item: any) => {
      if (item.propertyId === propertyId) {
        return null;
      }
      if (
        item.propertyLocation &&
        typeof item.propertyLocation?.payload?.address === "string"
      ) {
        return {
          city: item.propertyLocation.payload.city,
          state: item.propertyLocation.payload.state,
          street_line: item.propertyLocation.payload.address,
          zipcode: item.propertyLocation.payload.postalCode
        };
      }
      return item.propertyLocation?.payload?.address;
    })
  );
  const activePropertyId = useSelector<RootState, any>(
    (state) => state.createLoanStore.loanDetails.activePropertyId
  );
  const [errors, setErrors] = useState(0);
  const [isMinimized, setMinimized] = useState(false);
  const [locationEdited, setLocationEdited] = useState(false);
  // const [isEditable, setEditable] = useState(false);
  const [resetData, setResetData] = useState(false);
  const [addressInputValue, setAddressInputValue] = React.useState("");
  const [addressOptions, setAddressOptions] = React.useState<
    LocationSuggestion[]
  >([]);
  const [zipCodeInputValue, setZipCodeInputValue] = React.useState("");
  const [selectedZipCodeValue, setSelectedZipCodeValue] =
    React.useState<ZipCodeSuggestion>();
  const [zipCodeOptions, setZipCodeOptions] = React.useState<
    ZipCodeSuggestion[]
  >([]);
  const [streetAddress, setStreetAddress] = React.useState<string>("");
  const { loanDetails } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );
  const [addressInputValueError, setAddressInputValueError] = useState("");
  const [cityValueError, setCityValueError] = useState("");
  const [stateValueError, setStateValueError] = useState("");
  const [zipcodeValueError, setZipcodeValueError] = useState("");

  let loanData: any;
  let propertyData: any;

  const currentAddress: any = useMemo(
    () => {
      if (propertyLocation?.address) {
        if (typeof propertyLocation!.address === "string") {
          return {
            city: propertyLocation.city,
            state: propertyLocation.state,
            street_line: propertyLocation.address,
            zipcode: propertyLocation.zipCode,
            secondary: "",
            entries: 0
          };
        }
        return propertyLocation?.address;
      }
      return null;
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [
      /* eslint-disable-next-line react-hooks/exhaustive-deps */
      propertyLocation?.address,
      /* eslint-disable-next-line react-hooks/exhaustive-deps */
      propertyLocation?.city,
      /* eslint-disable-next-line react-hooks/exhaustive-deps */
      propertyLocation?.state,
      /* eslint-disable-next-line react-hooks/exhaustive-deps */
      propertyLocation?.zipCode
    ]
  );
  const fetch = React.useMemo(
    () =>
      // @ts-ignore
      throttle(
        (
          request: { input: string },
          callback: (results?: LocationSuggestion[]) => void
        ) => {
          publicClient
            .get(
              `https://us-autocomplete-pro.api.smartystreets.com/lookup?key=${
                getConfig().smartyStreetsApiKey
              }&search=${request.input}`
            )
            .then((results: any) =>
              callback(results.data.suggestions as LocationSuggestion[])
            )
            .catch((e: any) => {
              console.log(e);
            });
        },
        200
      ),
    []
  );

  const {
    city: appraisalCity,
    address: appraisalAddress,
    postalCode: appraisalPostalCode,
    state: appraisalState
  } = useAppraisalForm();
  useEffect(() => {
    const TCPropertyZipcode =
      propertyDetails[0]?.propertyLocation?.payload?.zipCode;
    if (
      appraisalPostalCode &&
      loanState === LoanStatusEnum.DataEntry &&
      !TCPropertyZipcode
    ) {
      const appraisalPropLoca: LocationSuggestion = {
        city: appraisalCity?.toString() || "",
        state: appraisalState?.toString() || "",
        zipcode: appraisalPostalCode?.toString(),
        street_line: appraisalAddress?.toString() || "",
        secondary: "",
        entries: 1
      };
      dispatchNewData(appraisalPropLoca);
    }
    // eslint-disable-next-line
  }, [appraisalPostalCode, loanState]);

  const fetchZipCode = React.useMemo(
    () =>
      // @ts-ignore
      throttle(
        (
          request: { input: string },
          callback: (results?: {
            zipSuggested: ZipCodeSuggestion[];
            zipcodes: any;
          }) => void
        ) => {
          publicClient
            .get(
              `https://us-zipcode.api.smartystreets.com/lookup?auth-id=${
                getConfig().smartyStreetsApiKey
              }&zipcode=${request.input}`
            )
            .then((results: any) =>
              callback({
                zipSuggested: results.data[0]
                  .city_states as ZipCodeSuggestion[],
                zipcodes: results.data[0].zipcodes
              })
            )
            .catch((e: any) => {
              console.log(e);
            });
        },
        200
      ),
    []
  );
  const clearManualEntry = () => {
    setEditManually(false);
    setZipCodeInputValue("");
    setZipCodeOptions([]);
    setStreetAddress("");
    setSelectedZipCodeValue(emptyZipCodeSuggestion);
  };
  useEffect(() => {
    if (editManually) {
      clearManualEntry();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [activePropertyId]);

  useEffect(() => {
    let active = true;
    const property = propertyLocation?.address || "";
    if (addressInputValue === "" || addressInputValue === property.toString()) {
      setAddressOptions(
        propertyLocation!.address ? [propertyLocation!.address] : []
      );
      return undefined;
    }
    fetch({ input: addressInputValue }, (results?: LocationSuggestion[]) => {
      if (active) {
        let newOptions = [] as LocationSuggestion[];

        if (propertyLocation!.address) {
          newOptions = [propertyLocation!.address];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setAddressOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [addressInputValue]);

  useEffect(() => {
    let active = true;
    if (zipCodeInputValue.length < 4 || zipCodeInputValue.length > 7) {
      return undefined;
    }
    fetchZipCode(
      { input: zipCodeInputValue },
      (results?: { zipSuggested: ZipCodeSuggestion[]; zipcodes: any }) => {
        if (active) {
          if (results) {
            const { zipSuggested, zipcodes } = results;
            let newOptions = [] as ZipCodeSuggestion[];
            if (zipSuggested && zipcodes) {
              const zipCode = zipcodes[0].zipcode;
              const temp = zipSuggested.map((ele) => {
                return { ...ele, ...{ zipCode } };
              });
              newOptions = [...newOptions, ...temp];
            }
            setZipCodeOptions(newOptions);
          }
        }
      }
    );

    return () => {
      active = false;
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [zipCodeInputValue]);

  useEffect(() => {
    if (resetData && originalPropertyLocation) {
      dispatch(
        previousLoanState(originalPropertyLocation, "propertyLocation", true)
      );
      setResetData(!resetData);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    if (validateForm.validate) {
      const isAddressSame = currentAddress
        ? isPropertyAddressSame(currentAddress, otherAddresses)
        : false;

      if (
        (typeof propertyLocation!.address === "string" &&
          !propertyLocation!.address) ||
        !propertyLocation!.address?.street_line
      ) {
        setAddressInputValueError("This field is required");
      } else if (isAddressSame) {
        setAddressInputValueError("Duplicate address");
      } else if (propertyLocation?.invalidAddress) {
        setAddressInputValueError("Location not found");
      } else {
        setAddressInputValueError("");
      }

      if (!propertyLocation!.city) {
        setCityValueError("This field is required");
      } else if (propertyLocation?.invalidAddress) {
        setCityValueError("Location not found");
      } else {
        setCityValueError("");
      }

      if (!propertyLocation!.state) {
        setStateValueError("This field is required");
      } else if (propertyLocation?.invalidAddress) {
        setStateValueError("Location not found");
      } else {
        setStateValueError("");
      }

      if (!propertyLocation!.zipCode) {
        setZipcodeValueError("This field is required");
      } else if (propertyLocation?.invalidAddress) {
        setZipcodeValueError("Location not found");
      } else {
        setZipcodeValueError("");
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    validateForm,
    addressInputValueError,
    cityValueError,
    stateValueError,
    zipcodeValueError,
    propertyId,
    propertyLocation
  ]);

  const showOverrideOption = () => {
    if (propertyLocation?.invalidAddress) {
      if (
        !propertyLocation!.city ||
        !propertyLocation!.state ||
        !propertyLocation!.zipCode ||
        (typeof propertyLocation!.address === "string" &&
          !propertyLocation!.address) ||
        !propertyLocation!.address?.street_line
      ) {
        return false;
      }
      return true;
    }
    return false;
  };
  useEffect(() => {
    const errorCount = [
      addressInputValueError,
      cityValueError,
      stateValueError,
      zipcodeValueError
    ].filter((item) => item).length;
    setErrors(errorCount);
    if (validateForm.validate) {
      dispatch(
        updatePropertyDetails(propertyId, {
          propertyLocation: {
            errors: errorCount
          }
        })
      );
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    addressInputValueError,
    cityValueError,
    stateValueError,
    zipcodeValueError,
    dispatch
  ]);

  useEffect(() => {
    if (validateForm.validate) {
      dispatch(
        updatePropertyDetails(propertyId, {
          propertyLocation: {
            errors
          }
        })
      );
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [validateForm]);

  useEffect(() => {
    if (loanState === LoanStatusEnum.DueDiligenceReview) {
      dispatch(valueEditing(false));
      for (const i in editableFlags) editableFlags[i] = false;
      setLocationEdited(!locationEdited);
      // setEditable(!isEditable);
      setResetData(!resetData);
      changedValueFields = [];
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [loanState]);

  useEffect(() => {
    if (isEditable) {
      editableFlags[`editProp${activePropertyId}`] = true;
      setStreetAddress(
        formatPropertyAddress(propertyLocation, true)
      );
      setEditManually(true);
    } else {
      editableFlags[`editProp${activePropertyId}`] = false;
      setEditManually(false);
    }
  }, [isEditable, propertyLocation, activePropertyId]);

  const saveOnFieldChange = () => {
    dispatch(validateFormCheck(true));
    if (errors === 0) {
      globalSaveOnPropertyFieldChange(changedValueFields);

      // }
    }
  };

  // --------------------DUPLICATE LOAN FUNCTIONS BEGINS------------------------------------
  // useEffect(() => {
  //   if (propertyLocation?.duplicateLoanIds) {
  //     const duplicateLoanIds = JSON.parse(
  //       JSON.stringify(propertyLocation?.duplicateLoanIds)
  //     );

  //     if (isLATUser()) {
  //       if (Array.isArray(duplicateLoanIds) && duplicateLoanIds.length) {
  //         setIsDuplicate(true);
  //       } else {
  //         setIsDuplicate(false);
  //       }
  //     } else {
  //       const duplicatePropertyInfo = JSON.parse(
  //         propertyLocation?.duplicatePropertyInfo
  //       );
  //       if (
  //         Array.isArray(
  //           duplicatePropertyInfo?.sameOriginatorduplicateLoanInfo
  //         ) &&
  //         duplicatePropertyInfo?.sameOriginatorduplicateLoanInfo.length
  //       ) {
  //         setIsDuplicate(true);
  //       } else {
  //         setIsDuplicate(false);
  //       }
  //       // }
  //     }
  //   }
  // }, [propertyLocation?.duplicateLoanIds]);

  const fetchLoanData = async () => {
    try {
      const bridgeLoanGuarantorInformationToPass =
        getBridgeLoanGuarantorInformationToPass(bridgeLoanGuarantorInformation);
      loanData = await postLoan(
        {
          loanId,
          loanType,
          borrowerInformation: bridgeLoanBorrowerInformation,
          guarantorInfo: bridgeLoanGuarantorInformationToPass,
          loanConfig: loanDetails?.loanConfig,
          ...bridgeLoanInformation.payload,
          ...bridgeLoanEconomics.payload
        },
        true
      );
      return loanData;
    } catch (e) {
      console.log("error while fethcing loan data--", e);
    }
  };

  const fetchPropertyData = async (newValue: any) => {
    try {
      const result = await postPropertyData(
        loanId,
        loanStage,
        propertyDetails,
        0,
        true
      );
      console.log("prop response", result);

      propertyData = result.map((item: any) => {
        if (item.loanPropertyId === +activePropertyId) {
          const address = {
            ...item,
            ...{
              propertyLocation: {
                addressLine1: newValue.street_line,
                addressLine2: newValue.secondary,
                city: newValue.city,
                country: "",
                postalCode: newValue.zipcode,
                state: newValue.state
              }
            }
          };
          return address;
        }
        return item;
      });
      console.log("propertyData---", propertyData);
      return propertyData;
    } catch (error) {
      console.log("error while fetching property data", error);
    }
  };

  // ------------------------- BELOW CODE NOT TO BE REMOVED ----------------------------
  //   const getNewPropObj = (newValue: any, propArr: any[]) => {
  //     return propArr[1].filter((prop: any) => {
  //       const {
  //         addressLine1,
  //         addressLine2,
  //         city,
  //         country,
  //         postalCode,
  //         state
  //       } = prop.propertyLocation;
  //       return (
  //         addressLine1 === newValue.street_line &&
  //         addressLine2 === newValue.secondary &&
  //         city === newValue.city &&
  //         country === "" &&
  //         postalCode === newValue.zipcode &&
  //         state === newValue.state
  //       );
  //     });
  //   };

  // const postPropertyDetailsData = async (results: any[], newValue: any) => {
  //   try {
  //     dispatch(showLoader());

  //     const loanObj = results[0];
  //     loanObj.loanStage = loanStage;
  //     delete loanObj.loanEconomics;
  //     //   const newValueProps = getNewPropObj(newValue, results);
  //     const requestBody = {
  //       loanObject: loanObj,
  //       propertiesArray: results[1]
  //     };
  //     const response = await checkDuplicateLoan(requestBody, loanStage);
  //     console.log("data from api--", response);
  //     setWarning(response, newValue);
  //     dispatch(hideLoader());
  //   } catch (error) {
  //     dispatch(hideLoader());
  //     console.log("error while calling api", error);
  //   }
  // };

  // const setWarning = (response: any, newValue: any) => {
  //   if (response?.data?.metaData?.success) {
  //     switch (response?.data?.metaData?.type) {
  //       case "PARTIAL": {
  //         const {
  //           addressLine1,
  //           addressLine2,
  //           city,
  //           country,
  //           postalCode,
  //           state
  //         } = response?.data?.details[
  //           response?.data?.details.length - 1
  //         ]?.propertyAddress;
  //         const primaryLoanId = response?.data?.details
  //           .flatMap((item: any) => item.loanPropertyDetails)
  //           .map((i: any) => i.primaryLoanId)
  //           .toString();
  //         console.log("primaryLoanId--", primaryLoanId);
  //         setWarningMessage({
  //           message: `There is an existing loan ${loanId} with the same originator loan id ${primaryLoanId} and address ${addressLine1} ${addressLine2}
  //         ${city} ${state} ${country} ${postalCode}.
  //         Do you still want to proceed with adding the property?`,
  //           newValue,
  //           showWarning: true
  //         });
  //         break;
  //       }
  //       case "COMPLETE": {
  //         const {
  //           addressLine1,
  //           addressLine2,
  //           city,
  //           country,
  //           postalCode,
  //           state
  //         } = response?.data?.details[
  //           response?.data?.details.length - 1
  //         ]?.propertyAddress;
  //         const { loanId, loanState, primaryLoanId } = response?.data?.details[
  //           response?.data?.details.length - 1
  //         ]?.loanPropertyDetails[0];
  //         // setShowWarning(true);
  //         setCompleteWarningMessage({
  //           message: `Sorry the property cannot be added. There is an existing loan ${loanId}
  //   with the same originator loan id ${primaryLoanId} and address ${addressLine1} ${addressLine2}
  //   ${city} ${state} ${country} ${postalCode} in stage that is ${loanState}`,
  //           newValue,
  //           showWarning: true
  //         });
  //         break;
  //       }
  //       default: {
  //       }
  //     }
  //   } else {
  //     dispatchNewData(newValue);
  //   }
  // };
  //------------------------------------------------------
  const dispatchNewData = async (newValue: any, isOverridden?: boolean) => {
    try {
      dispatch(
        updatePropertyDetails(propertyId, {
          propertyLocation: {
            payload: {
              address: newValue,
              city: newValue?.city ?? "",
              state: newValue?.state ?? "",
              zipCode: newValue?.zipcode ?? "",
              duplicatePropertyInfo: newValue?.duplicatePropertyInfo ?? null,
              duplicateLoanIds: newValue?.duplicateLoanIds ?? null,
              invalidAddress: false,
              locationValidationStatus: isOverridden ? "OVERRIDDEN" : "COMPLETE"
            }
          },
          propertyDefaultName: ""
        })
      );
      setCityValueError("");
      setStateValueError("");
      setZipcodeValueError("");
      setCallAutoSave({ value: true, impactingFieldChange: true });
    } catch (error) {
      console.log("error while updating duplicate loan", error);
    }
  };

  const markAddressAsValid = async () => {
    try {
      dispatch(showLoader(true));
      const resp: any = await validateAddress(
        currentAddress?.city,
        currentAddress?.state,
        currentAddress?.zipcode
      );
      if (resp[0]?.zipcodes?.length) {
        dispatch(
          updatePropertyDetails(propertyId, {
            propertyLocation: {
              payload: {
                invalidAddress: false,
                locationValidationStatus: "OVERRIDDEN"
              }
            }
          })
        );
        setCityValueError("");
        setStateValueError("");
        setZipcodeValueError("");
        setCallAutoSave({ value: true, impactingFieldChange: true });
      } else {
        setOpenWarning({
          isOpen: true,
          header: "Address validation failed.",
          body: (
            <Grid container direction="column">
              <Grid item xs={12} style={{ padding: 15 }}>
                <div>
                  Sorry, No match found for the combination of city, state and
                  zip code entered in this property. <br /> Please enter the
                  address again.
                </div>
              </Grid>
            </Grid>
          ),
          handleClose: async () => {
            setOpenWarning(null);
            // cancelEdit()
          }
        });
      }
      dispatch(hideLoader(true));
    } catch (error) {
      dispatch(hideLoader(true));
      console.log({ error });
    }
  };
  const updateDuplicateData = async (newValue: any) => {
    try {
      dispatch(showLoader());

      const apiResult = await Promise.all([
        fetchLoanData(),
        fetchPropertyData(newValue)
      ]);
      const reqBody = {
        loanObject: apiResult[0],
        propertiesArray: apiResult[1]
      };
      const response = await updateDuplicateLoan(reqBody, loanStage);
      setIsDuplicate(true);
      const foo = response?.data[response?.data.length - 1];
      if (foo) {
        newValue.duplicateLoanIds = JSON.parse(
          JSON.stringify(foo?.propertyId?.propertyLocationId?.duplicateLoanIds)
        );

        newValue.duplicatePropertyInfo = JSON.parse(
          JSON.stringify(
            foo?.propertyId?.propertyLocationId?.duplicatePropertyInfo
          )
        );
        console.log("response---", newValue);
      }

      dispatchNewData(newValue);
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      console.log("error while updating duplicate loan", error);
    }
  };

  const getDuplicateToShow = (isLength: boolean) => {
    if (isLATUser()) {
      return Array.isArray(propertyLocation?.duplicateLoanIds) &&
        propertyLocation?.duplicateLoanIds
        ? isLength
          ? propertyLocation?.duplicateLoanIds.length
          : propertyLocation?.duplicateLoanIds.toString()
        : 0;
    }
    const duplicatePropertyInfo = JSON.parse(
      propertyLocation?.duplicatePropertyInfo
    );
    const temp: any[] = [];
    if (Array.isArray(duplicatePropertyInfo?.sameOriginatorduplicateLoanInfo)) {
      duplicatePropertyInfo?.sameOriginatorduplicateLoanInfo?.forEach(
        (item: any) => {
          if (propertyLocation?.duplicateLoanIds.includes(item.loanId)) {
            temp.push(item.loanId);
          }
        }
      );
    }
    return isLength ? temp.length : temp.toString();
  };

  // --------------------DUPLICATE LOAN FUNCTIONS ENDS------------------------------------

  const reEvaluationResult = (isSubmitting: boolean) => {
    postFesLoan(loanDetails, loanId, loanType, loanStage);
    postFesPropertyData(loanId, propertyDetails, loanSizerType).then(() => {
      // offsetting to keep main thread free
      setTimeout(() => {
        dispatch(keepCreditEvalSync(true));
      }, 1000);
    });
    dispatch(
      evaluateLoan(
        getFesRulesRequestObject(
          loanDetails,
          loanId,
          loanType,
          loanStage,
          isSubmitting,
          true,
          loanEvaluationResult,
          changedValueFields
        ),
        loanId,
        loanStage,
        isSubmitting,
        isSubmitting
      )
    );
  };

  function getManualSaveAddress(
    streetAddressValue: string,
    selectedZipCodeValue: ZipCodeSuggestion | undefined,
    propertyLocation: PropertyLocation | null | undefined
  ) {
    const newValue = {
      street_line:
        streetAddressValue.trim() ||
        propertyLocation?.address?.street_line ||
        "",
      secondary: "",
      city:
        selectedZipCodeValue?.city ||
        propertyLocation?.city ||
        propertyLocation?.address?.city ||
        "",
      state:
        selectedZipCodeValue?.state_abbreviation ||
        propertyLocation?.state ||
        propertyLocation?.address?.state ||
        "",
      zipcode:
        selectedZipCodeValue?.zipCode ||
        propertyLocation?.zipCode ||
        propertyLocation?.address?.zipcode ||
        "",
      entries: 0
    };
    return newValue;
  }
  function isManualSaveDisabled(
    streetAddressValue: string,
    selectedZipCodeValue: ZipCodeSuggestion | undefined,
    propertyLocation: PropertyLocation | null | undefined
  ) {
    const newValue = getManualSaveAddress(
      streetAddressValue,
      selectedZipCodeValue,
      propertyLocation
    );
    const a =
      isEmptyValue(newValue.city) ||
      isEmptyValue(newValue.state) ||
      isEmptyValue(newValue.zipcode) ||
      isEmptyValue(streetAddressValue.trim());
    return a;
  }

  const cancelEdit = () => {
    setIsSaveDisable(false);
    dispatch(valueEditing(false));
    for (const i in editableFlags) editableFlags[i] = false;
    // setEditable(!isEditable);
    setLocationEdited(!locationEdited);
    setResetData(!resetData);
    changedValueFields = [];
    setIfUpdateRequired(true);
  };

  const postEditSuccess = () => {
    dispatch(valueEditing(false));
    editableFlags[`editProp${activePropertyId}`] = false;
    // setEditable(!isEditable);
    changedValueFields = [];
    setIfUpdateRequired(true);
  };

  const handleEditAfterSubmit = async (
    isFromManual: boolean = false,
    newPropLoca?: any
  ) => {
    try {
      dispatch(showLoader(true));
      let updatedAddress = JSON.parse(JSON.stringify(propertyLocation));
      if (newPropLoca?.street_line) {
        updatedAddress.city = newPropLoca.city;
        updatedAddress.state = newPropLoca.state;
        updatedAddress.zipCode = newPropLoca.zipcode;
        updatedAddress.address.state = newPropLoca.state;
        updatedAddress.address.city = newPropLoca.city;
        updatedAddress.address.zipcode = newPropLoca.zipcode;
        updatedAddress.address.street_line = newPropLoca.street_line;
      }
      if (loanStage !== LoanStage.fes) {
        const isDupliAfterEdit = await handlePropUpdateAfterSubmit(
          loanId,
          loanStage,
          propertyId,
          updatedAddress
        );
        console.log(updatedAddress, isDupliAfterEdit);
        if (isDupliAfterEdit.isDupli) {
          // setEditable(!isEditable);
          dispatch(hideLoader(true));
          setOpenWarning({
            isOpen: true,
            header: "Duplicate Loan",
            body: (
              <Grid container direction="column">
                <Grid item xs={12} style={{ padding: 15 }}>
                  <div>
                    Sorry the property cannot be modified. There is an existing
                    loan <b>{isDupliAfterEdit.dupliData?.loanId || ""}</b> with
                    {loanStage !== LoanStage.fes ? " either " : " "}the same
                    property address <b>{isDupliAfterEdit.propString}</b>{" "}
                    {loanStage !== LoanStage.fes ? "or Originator Loan Id" : ""}{" "}
                    <b>{isDupliAfterEdit.dupliData?.primaryLoanId || ""}</b>{" "}
                    {isDupliAfterEdit.dupliData?.loanStage ? "in" : ""}{" "}
                    <b>{isDupliAfterEdit.dupliData?.loanStage || ""}</b>{" "}
                    {isDupliAfterEdit.dupliData?.loanStage ? "stage" : ""} that
                    is submitted.
                  </div>
                </Grid>
              </Grid>
            ),
            handleClose: async () => {
              setOpenWarning(null);
              // cancelEdit()
            }
          });
          return;
        }
      }
      console.log(propertyDetails, propertyLocation);
      dispatch(hideLoader(true));
      setOpenWarning({
        isOpen: true,
        header: "Warning",
        primaryBtnName: "Yes",
        handleOptional: () => {
          setOpenWarning(null);
          // cancelEdit();
        },
        body: (
          <Grid container direction="column">
            <Grid item xs={12} style={{ padding: 15 }}>
              <div>{requestWaiverMessage}</div>
            </Grid>
          </Grid>
        ),
        handleClose: async () => {
          setOpenWarning(null);
          if (loanStage === LoanStage.fes) {
            if (isFromManual) {
              dispatchNewData(newPropLoca, true);
            }
            await delay(500);
            fesEditFunctionSave(
              false,
              takeoutPartnerId,
              changedValueFields,
              true
            );
            postEditSuccess();
            return;
          }
          dispatch(showLoader(true));
          let overriddenValuesToUse: {};
          let updatedCreatedWaivers: ObjectType[] = [];
          try {
            overriddenValuesToUse = overriddenValues?.overriddenValues;
            const { reRequestWaiverObject } = await getAffectedWaivers(
              changedValueFields,
              waiversCreated,
              loanId,
              loanStage,
              loanType
            );
            // skipping when no partner and loan is of dscr
            // https://toorakcapital.atlassian.net/browse/TA-4313
            if (
              typeof reRequestWaiverObject === "object" &&
              Object.keys(reRequestWaiverObject)?.length &&
              (loanType === getLoanType[0].displayValue ||
                (loanType === getLoanType[1].displayValue && takeoutPartnerId))
            ) {
              updatedCreatedWaivers = waiversCreated.filter(
                (item: ObjectType) =>
                  !Object.keys(reRequestWaiverObject).includes(item.waiverId)
              );
              const resp = await getOverriddenOutputs(
                loanId,
                loanStage,
                takeoutPartnerId
              );
              overriddenValuesToUse = resp?.data;
              dispatch(updateOverriddenInredux(resp));
              dispatch(getWaiverList(loanId, loanStage));
            }
          } catch (e) {
            console.log(e);
            return;
          }
          let newPropertyDetails: any;
          if (isFromManual) {
            dispatchNewData(newPropLoca, true);
            let bar = JSON.parse(JSON.stringify(propertyDetails));

            bar.forEach((item: any) => {
              if (item.propertyId === propertyId) {
                item.propertyLocation.payload = updatedAddress;
              }
            });

            // bar[propertyId].propertyLocation.payload = updatedAddress;
            newPropertyDetails = bar;
            console.log(bar, propertyLocation);
          } else newPropertyDetails = propertyDetails;
          postPropertyData(
            loanId,
            loanStage,
            newPropertyDetails,
            null,
            false,
            true
          )
            .then((response: CreateLoanParamsBridgeLoan) => {
              // dispatch({
              //   type: POST_PROPERTY_SUCCESS,
              //   payload: {
              //     reservation: response
              //   }
              // });
              dispatch(updatePropertySuccess());

              // console.log(newPropertyDetails, "updatedAddress", updatedAddress);

              runEvalAfterUpdatePostSubmit(
                "",
                overriddenValuesToUse,
                false,
                takeoutPartnerId,
                newPropertyDetails,
                updatedCreatedWaivers.length ? updatedCreatedWaivers : null
              );
              postEditSuccess();
              dispatch(hideLoader(true));
            })
            .catch((e) => {
              console.log(e);
              dispatch(hideLoader(true));
              // setEditable(!isEditable);
            });
        }
      });
    } catch (e) {
      console.log("error while deleting property", e);
      dispatch(hideLoader(true));
      // setEditable(!isEditable);
    }
  };  

  return (
    <div className={classes.container}>
      {/* Minimize Section start */}
      <Grid container spacing={3} className={classes.sectionWrapper}>
        <Grid item xs={12} container style={{ padding: "15px 35px 11px 35px" }}>
          <Grid item xs={9} sm={11}>
            <Typography className={classes.headingTypography}>
              Property {index} Location
            </Typography>
            {isDuplicate ? (
              <div className={classes.errorContainer}>
                <WarningRoundedIcon
                  className={classes.icon}
                  htmlColor="#ffffff"
                />
                <Typography
                  key={0}
                  variant="h5"
                  className={classes.errorTypography}
                >
                  Duplicate Property - {getDuplicateToShow(true)}
                </Typography>
                <Grid item>
                  <WarningTag
                    tooltipClicked={`This Property found in following loans:
                    ${getDuplicateToShow(false)} `}
                    infoIconMsg={infoIconMsg}
                    infoLightImg={infoLightImg}
                    // setTooltipClicked={setTooltipClicked}
                  />
                </Grid>
              </div>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={3} sm={1} className={classes.headingImageContainer}>
            {/* {isAllowedToEdit && (
              <img
                className={
                  editableFlags[`editProp${activePropertyId}`] ||
                  !isEvaluationPanelVisible
                    ? classes.editImageDisplay
                    : classes.editImage
                }
                src={editImage}
                alt="Edit Section"
                onClick={() => {
                  dispatch(valueEditing(true));
                  for (let i = 0; i < propertyDetails.length; i++) {
                    if (propertyDetails[i].propertyId === activePropertyId) {
                      originalPropertyLocation = JSON.parse(
                        JSON.stringify(propertyDetails[i].propertyLocation)
                      );
                    }
                  }
                  // setEditable(!isEditable);
                  editableFlags[`editProp${activePropertyId}`] = true;
                }}
                onKeyDown={() => {
                  dispatch(valueEditing(true));
                  originalPropertyLocation = JSON.parse(
                    JSON.stringify(
                      propertyDetails[activePropertyId - 1].propertyLocation
                    )
                  );
                  // setEditable(!isEditable);
                  editableFlags[`editProp${activePropertyId}`] = true;
                }}
              />
            )} */}
            <Tooltip title={isMinimized ? "Expand" : "Collapse"}>
              <img
                className={classes.headingImage}
                src={isMinimized ? iconMaximize : iconMinimize}
                alt={isMinimized ? "Maximize section" : "Minimize section"}
                onClick={() => {
                  setMinimized(!isMinimized);
                }}
                onKeyDown={() => {
                  setMinimized(!isMinimized);
                }}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      {/* Minimize Section end */}
      {/* Divider Section start */}
      <Grid
        item
        xs={12}
        sm={12}
        style={{
          display: isMinimized ? "none" : ""
        }}
      >
        <div className={classes.divider} />
      </Grid>
      {/* Divider Section end */}
      <Grid
        container
        spacing={4}
        style={{
          padding: 24,
          display: isMinimized ? "none" : ""
        }}
      >
        {showOverrideOption() && (
          <Grid container item xs={12} style={{ paddingLeft: 30 }}>
            <FormControlLabel
              onChange={markAddressAsValid}
              value="Mark as valid address"
              control={<Checkbox color="primary" checked={false} />}
              label="Mark as valid address"
              labelPlacement="end"
            />
          </Grid>
        )}
        {(editableFlags[`editProp${activePropertyId}`] ||
          !isEvaluationPanelVisible) &&
          !editManually && (
            <></>
            // <Grid xs={12} container direction="row" justifyContent="flex-end">
            //   <Grid item>
            //     <img
            //       className={classes.editImage}
            //       src={editImage}
            //       onClick={() => {
            //         setStreetAddress(
            //           formatPropertyAddress(propertyLocation, true)
            //         );
            //         setEditManually(true);
            //       }}
            //       alt="Edit Section"
            //     />
            //   </Grid>
            //   <Grid item>
            //     <IconTooltip
            //       topic="Information"
            //       infoIconData="You can use this option to enter the address manually"
            //     />
            //   </Grid>
            // </Grid>
          )}
        {editManually ? (
          <>
            <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
              <GridTextFieldForm
                required
                value={streetAddress}
                isViewMode={false}
                onChange={(e: any) => {
                  setStreetAddress(e.target.value.replace(/\s+/g, " "));
                }}
                label="Address"
                infoIconData={
                  infoContent &&
                  infoContent[
                    `fieldInfo.bridge.${loanStage.toLowerCase()}.properties.propertyLocation.addressLine1`
                  ]
                }
                error={false}
                helperText=""
                data-testid="data-testid"
                valueTestId="value-test-id"
              />
            </Grid>
            <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
              <div
                style={{
                  flexDirection: "column",
                  flex: 1,
                  marginBottom: 1
                }}
              >
                <div
                  style={{
                    fontWeight: 600,
                    fontSize: 12,
                    color: "#8897aa",

                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                >
                  <div
                    style={{
                      marginBottom: 4,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between"
                    }}
                  >
                    <div
                      style={{
                        display: "flex"
                      }}
                    >
                      Postal Code
                      <span
                        style={{
                          color: "#f75676",
                          marginLeft: "4px",
                          fontSize: "12px"
                        }}
                      >
                        <sup>*</sup>
                      </span>
                    </div>
                    <IconTooltip
                      topic="Information"
                      infoIconData="Enter a valid zip code. City and State will be suggested according to your zip code"
                    />
                  </div>
                </div>
                <Autocomplete
                  id="property-details-zipcode"
                  style={{
                    padding: "0px !important",
                    flex: 1,
                    marginTop: 4
                  }}
                  popupIcon={
                    <img
                      src={arrowDown}
                      alt="Expand"
                      className={classes.wh_16}
                    />
                  }
                  getOptionLabel={(option) =>
                    option.city
                      ? `${option.city} ${option.state_abbreviation} ${option.zipCode}`
                      : selectedZipCodeValue?.zipCode ||
                        propertyLocation?.zipCode ||
                        ""
                  }
                  filterOptions={(x) => x}
                  options={zipCodeOptions}
                  classes={autoClasses}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  value={selectedZipCodeValue?.zipCode || ""}
                  disableClearable
                  onChange={async (
                    event: any,
                    newValue: ZipCodeSuggestion | null
                  ) => {
                    setZipCodeOptions(
                      newValue ? [newValue, ...zipCodeOptions] : zipCodeOptions
                    );
                    if (newValue) {
                      setSelectedZipCodeValue(newValue);
                    }
                  }}
                  onInputChange={(event, newInputValue, reason) => {
                    setZipCodeInputValue(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      // label="Add a location"
                      value={selectedZipCodeValue?.zipCode || ""}
                      variant="outlined"
                      style={{
                        borderColor: "#cad1d7",
                        fontSize: 14,
                        color: "#323536",
                        padding: "0px"
                      }}
                      fullWidth
                      error={false}
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
              <CityTextField
                value={
                  selectedZipCodeValue?.city || propertyLocation?.city || ""
                }
                error={false}
                helperText=""
                isViewMode={
                  isEvaluationPanelVisible &&
                  !editableFlags[`editProp${activePropertyId}`]
                }
                editedLabel={false}
                infoIconData={
                  infoContent &&
                  infoContent[
                    `fieldInfo.bridge.${loanStage.toLowerCase()}.properties.propertyLocation.city`
                  ]
                }
              />
            </Grid>
            <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
              <StateTextField
                value={
                  selectedZipCodeValue?.state_abbreviation ||
                  propertyLocation?.state ||
                  ""
                }
                error={false}
                helperText=""
                isViewMode={
                  isEvaluationPanelVisible &&
                  !editableFlags[`editProp${activePropertyId}`]
                }
                editedLabel={false}
                infoIconData={
                  infoContent &&
                  infoContent[
                    `fieldInfo.bridge.${loanStage.toLowerCase()}.properties.propertyLocation.state`
                  ]
                }
              />
            </Grid>
            <Grid container style={{ padding: 10 }}>
              <Grid item>
                {/* <Button
                  variant="contained"
                  size="small"
                  color="info"
                  className={classes.modalButton}
                  disabled={isManualSaveDisabled(
                    streetAddress,
                    selectedZipCodeValue,
                    propertyLocation
                  )}
                  style={
                    isManualSaveDisabled(
                      streetAddress,
                      selectedZipCodeValue,
                      propertyLocation
                    )
                      ? {
                          cursor: "no-drop",
                          color: "#fff",
                          background: "#5e72e4"
                        }
                      : {
                          cursor: "pointer",
                          color: "#fff",
                          background: "#5e72e4"
                        }
                  }
                  onClick={() => {
                    if (
                      !isManualSaveDisabled(
                        streetAddress,
                        selectedZipCodeValue,
                        propertyLocation
                      )
                    ) {
                      const newValue: LocationSuggestion = getManualSaveAddress(
                        streetAddress,
                        selectedZipCodeValue,
                        propertyLocation
                      );
                      const isAddressSame = isPropertyAddressSame(
                        newValue,
                        otherAddresses
                      );
                      // eslint-disable-next-line
                      let addressValueError = "";
                      if (isAddressSame) {
                        addressValueError = "Duplicate address";
                        setWarningMsgForSameAddress({ showWarning: true });
                      } else {
                        if (!isEditable) dispatchNewData(newValue, true);
                      }
                      clearManualEntry();
                      if (isEditable) {
                        handleOpen();
                        handleEditAfterSubmit(true, newValue);
                      }
                    }
                  }}
                >
                  Save
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  size="small"
                  data-testid="modal-delete-button"
                  className={classes.modalButton}
                  onClick={() => {
                    clearManualEntry();
                  }}
                >
                  Cancel
                </Button> */}
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
              {isEvaluationPanelVisible &&
              !editableFlags[`editProp${activePropertyId}`] ? (
                <ViewModeTextField
                  label="Address"
                  value={
                    formatPropertyAddress(propertyLocation) !== ""
                      ? formatPropertyAddress(propertyLocation)
                      : ""
                  }
                  editedLabel={
                    editableFlags[`locationEdited${activePropertyId}`]
                  }
                  infoIconData={
                    infoContent &&
                    infoContent[
                      `fieldInfo.bridge.${loanStage.toLowerCase()}.properties.propertyLocation.addressLine1`
                    ]
                  }
                />
              ) : (
                <div
                  style={{
                    flexDirection: "column",
                    flex: 1,
                    // alignSelf: "flex-end",
                    marginBottom: 1
                  }}
                >
                  <div
                    style={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#8897aa",

                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}
                  >
                    <div
                      style={{
                        marginBottom: 4,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                      }}
                    >
                      <div
                        style={{
                          display: "flex"
                        }}
                      >
                        Address
                        {/* <div style={{ color: "#f75676", margin: 1 }}> *</div> */}
                        <span
                          style={{
                            color: "#f75676",
                            marginLeft: "4px",
                            fontSize: "12px"
                          }}
                        >
                          <sup>*</sup>
                        </span>
                        {validateForm.validate && addressInputValueError && (
                          <span
                            style={{
                              color: "#f75676",
                              marginLeft: "4px",
                              fontSize: "12px"
                            }}
                          >
                            Click on edit icon to manually enter address
                          </span>
                        )}
                      </div>
                      {infoContent &&
                        infoContent[
                          `fieldInfo.bridge.${loanStage.toLowerCase()}.properties.propertyLocation.addressLine1`
                        ] && (
                          <IconTooltip
                            topic="Information"
                            infoIconData={
                              infoContent &&
                              infoContent[
                                `fieldInfo.bridge.${loanStage.toLowerCase()}.properties.propertyLocation.addressLine1`
                              ]
                            }
                          />
                        )}
                    </div>
                  </div>
                  <Autocomplete
                    id="property-details-address"
                    style={{
                      padding: "0px !important",
                      flex: 1,
                      marginTop: 4
                    }}
                    popupIcon={
                      <img
                        src={arrowDown}
                        alt="Expand"
                        className={classes.wh_16}
                      />
                    }
                    getOptionLabel={
                      (option) =>
                        typeof option === "string"
                          ? option
                          : option.street_line
                          ? `${option.street_line} ${option.city} ${option.state} ${option.zipcode}`
                          : ""
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    filterOptions={(x) => x}
                    options={addressOptions}
                    classes={autoClasses}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    value={propertyLocation!.address as any}
                    disableClearable
                    onChange={async (
                      event: any,
                      newValue: LocationSuggestion | null
                    ) => {
                      setAddressOptions(
                        newValue
                          ? [newValue, ...addressOptions]
                          : addressOptions
                      );
                      if (newValue) {
                        const isAddressSame = isPropertyAddressSame(
                          newValue,
                          otherAddresses
                        );
                        let addressValueError = "";

                        if (isAddressSame) {
                          addressValueError = "Duplicate address";
                          setWarningMsgForSameAddress({ showWarning: true });
                        } else {
                          if (isEditable) setIfUpdateRequired(false);
                          dispatchNewData(newValue);
                          setIsSaveDisable(true);
                          return;

                          // ------------------------- BELOW CODE NOT TO BE REMOVED ----------------------------

                          // const apiResult = await Promise.all([
                          //   fetchLoanData(),
                          //   fetchPropertyData(newValue)
                          // ]);
                          // console.log("-----", activePropertyId, newValue);
                          // postPropertyDetailsData(apiResult, newValue);
                        }
                        setAddressInputValueError(addressValueError);
                      }
                    }}
                    onInputChange={(event, newInputValue, reason) => {
                      if (
                        !JSON.stringify(changedValueFields).includes(
                          "postalCode"
                        )
                      ) {
                        changedValueFields.push("city");
                        changedValueFields.push("state");
                        changedValueFields.push("postalCode");
                      }
                      setAddressInputValue(newInputValue);
                      if (isEvaluationPanelVisible && !locationEdited) {
                        editableFlags[`locationEdited${activePropertyId}`] =
                          true;
                        setLocationEdited(!locationEdited);
                      }
                      if (reason !== "reset") {
                        dispatch(
                          updatePropertyDetails(propertyId, {
                            propertyLocation: {
                              payload: {
                                city: "",
                                state: "",
                                zipCode: ""
                              }
                            }
                          })
                        );
                        setAddressInputValueError("This field is required");
                        setCityValueError("This field is required");
                        setStateValueError("This field is required");
                        setZipcodeValueError("This field is required");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        // label="Add a location"
                        variant="outlined"
                        style={{
                          borderColor:
                            validateForm.validate && addressInputValueError
                              ? "#f75676"
                              : "#cad1d7",
                          fontSize: 14,
                          color: "#323536",
                          padding: "0px"
                        }}
                        fullWidth
                        error={
                          validateForm.validate && addressInputValueError !== ""
                        }
                      />
                    )}
                  />
                  {validateForm.validate && addressInputValueError && (
                    <div
                      style={{
                        fontWeight: 400,
                        fontSize: 12,
                        color: "#f75676",
                        margin: "5px 0px 0px"
                      }}
                    >
                      {addressInputValueError}
                    </div>
                  )}
                </div>
              )}
            </Grid>
            <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
              <CityTextField
                value={propertyLocation!.city}
                error={validateForm.validate && cityValueError !== ""}
                helperText={validateForm.validate ? cityValueError : ""}
                isViewMode={
                  isEvaluationPanelVisible &&
                  !editableFlags[`editProp${activePropertyId}`]
                }
                editedLabel={false}
                isServicerMandatory={
                  isMandatoryServicerField && isMandatoryServicerField("city")
                }
                infoIconData={
                  infoContent &&
                  infoContent[
                    `fieldInfo.bridge.${loanStage.toLowerCase()}.properties.propertyLocation.city`
                  ]
                }
              />
            </Grid>
            <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
              <StateTextField
                value={propertyLocation!.state}
                error={validateForm.validate && stateValueError !== ""}
                helperText={validateForm.validate ? stateValueError : ""}
                isViewMode={
                  isEvaluationPanelVisible &&
                  !editableFlags[`editProp${activePropertyId}`]
                }
                isServicerMandatory={
                  isMandatoryServicerField && isMandatoryServicerField("state")
                }
                editedLabel={false}
                infoIconData={
                  infoContent &&
                  infoContent[
                    `fieldInfo.bridge.${loanStage.toLowerCase()}.properties.propertyLocation.state`
                  ]
                }
              />
            </Grid>
            <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
              <PostalCodeTextField
                value={propertyLocation!.zipCode}
                error={validateForm.validate && zipcodeValueError !== ""}
                helperText={validateForm.validate ? zipcodeValueError : ""}
                isViewMode={
                  isEvaluationPanelVisible &&
                  !editableFlags[`editProp${activePropertyId}`]
                }
                isServicerMandatory={
                  isMandatoryServicerField &&
                  isMandatoryServicerField("postalCode")
                }
                editedLabel={false}
                infoIconData={
                  infoContent &&
                  infoContent[
                    `fieldInfo.bridge.${loanStage.toLowerCase()}.properties.propertyLocation.postalCode`
                  ]
                }
              />
            </Grid>
            {/* {isSaveDisable ? (
              <Grid container item xs={12} sm={12} md={12} xl={12}>
                <Button
                  variant="contained"
                  color="info"
                  className={
                    editableFlags[`editProp${activePropertyId}`]
                      ? classes.savebutton
                      : classes.editImageDisplay
                  }
                  // startIcon={<SaveOutlinedIcon />}
                  disabled={!isSaveDisable}
                  onClick={() => {
                    handleOpen();
                    handleEditAfterSubmit();
                  }}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  color="info"
                  // data-testid="save-button"
                  className={
                    editableFlags[`editProp${activePropertyId}`]
                      ? classes.cancelButton
                      : classes.editImageDisplay
                  }
                  // startIcon={<SaveOutlinedIcon />}
                  // onClick={handleSave}
                  onClick={() => {
                    console.log(
                      changedValueFields,
                      "changedValueFields",
                      propertyLocation?.address
                    );
                    cancelEdit();
                    // handleOpenConfirmClose();
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            ) : null} */}
          </>
        )}
      </Grid>
      {openWarning && (
        <WarningModal
          isOpen={openWarning.isOpen}
          handleClose={openWarning.handleClose}
          handleOptional={openWarning.handleOptional}
          primaryBtnName={openWarning.primaryBtnName}
          header={openWarning.header}
          body={openWarning.body}
        />
      )}
      <WarningModal
        isOpen={warningMessage?.showWarning}
        handleClose={() => {
          setWarningMessage({ showWarning: false });
          updateDuplicateData(warningMessage?.newValue);
        }}
        header="Warning"
        body={warningMessage?.message || ""}
        primaryBtnName="Yes"
        handleOptional={() => setWarningMessage({ showWarning: false })}
      />
      <WarningModal
        isOpen={completeWarningMessage?.showWarning}
        handleClose={() => {
          setCompleteWarningMessage({ showWarning: false });
        }}
        header="Warning"
        body={completeWarningMessage?.message || ""}
        primaryBtnName="OK"
      />

      <WarningModal
        isOpen={warningMsgForSameAddress?.showWarning}
        handleClose={() => {
          setWarningMsgForSameAddress({ showWarning: false });
        }}
        header="Warning"
        body="Two properties cannot have the same address."
        primaryBtnName="OK"
      />

      <WarningModal
        isOpen={warningMsgForComplete?.showWarning}
        handleClose={() => {
          setWarningMsgForComplete({ showWarning: false });
        }}
        header="Warning"
        body={warningMessage?.message || ""}
        primaryBtnName="OK"
      />

      <Dialog
        open={openConfirmPopup}
        onClose={handleOpenConfirmClose}
        aria-labelledby="duplicate-property-modal"
        aria-describedby="duplicate-property-modal-description"
      >
        <div style={{ padding: 20, width: 500, maxWidth: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexGrow: 1,
              alignItems: "center"
            }}
          >
            <div style={{ fontSize: 14, fontWeight: 600, color: "#32325d" }}>
              Warning
            </div>
            <CloseIcon
              htmlColor="#414169"
              style={{ width: 16, height: 16, cursor: "pointer" }}
              onClick={() => {
                handleOpenConfirmClose();
              }}
            />
          </div>
          <div
            style={{
              fontSize: 12,
              lineHeight: 1.5,
              color: "#32325d",
              marginTop: 40
            }}
          >
            {requestWaiverMessage}
          </div>
          {/* <div className={classes.modalDeleteButtonConatainer}>
            <Button
              variant="outlined"
              data-testid="modal-delete-button"
              className={classes.modalButton}
              onClick={() => {
                if (loanStage === "FES") {
                  reEvaluationResult(true);
                } else {
                  saveOnFieldChange();
                }
                if (errors === 0) {
                  dispatch(valueEditing(false));
                  editableFlags[`editProp${activePropertyId}`] = false;
                  // setEditable(!isEditable);
                  changedValueFields = [];
                }
                handleOpenConfirmClose();
              }}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              data-testid="modal-cancel-button"
              className={classes.modalCancelButton}
              onClick={() => {
                dispatch(valueEditing(false));
                for (const i in editableFlags) editableFlags[i] = false;
                // setEditable(!isEditable);
                setLocationEdited(!locationEdited);
                setResetData(!resetData);
                changedValueFields = [];
                handleOpenConfirmClose();
              }}
            >
              Cancel
            </Button>
          </div> */}
        </div>
      </Dialog>
    </div>
  );
}
