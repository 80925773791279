import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      input: {
        backgroundColor: "#ffffff !important"
      }
    },
    dnone: { display: "none" },
    container: {
      borderRadius: 4,
      display: "flex",
      flexDirection: "column",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      backgroundColor: "#ffffff",
      marginTop: theme.spacing(3)
    },
    deleteBtn: {
      boxShadow: "0 1px 3px 0 rgb(0 0 0 / 22%)",
      position: "absolute",
      top: "32px",
      right: "8px",
      height: "30px",
      width: "30px",
      minWidth: "initial",
      color: "#32325d",
      background: "#fff",
      borderRadius: "4px",
      "& span": {
        margin: "0 !important"
      },
      "&:hover": {
        background: "none"
      }
    },
    modalButton: {
      marginRight: 12,
      color: "#ffffff",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      marginTop: 18,
      borderWidth: 0,
      "&:hover": {
        backgroundColor: "#5566d6 !important"
      }
    },
    modalDeleteButton: {
      marginRight: 12,
      color: "#ffffff",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      marginTop: 18,
      borderWidth: 0
    },
    modalCancelButton: {
      color: "#32325d",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      backgroundColor: "#ffffff",
      marginTop: 18,
      borderWidth: 1,
      borderColor: "#ececec"
    },
    modalDeleteButtonConatainer: {
      marginTop: 50
    },
    sectionWrapper: {
      padding: "12px",
      display: "flex",
      width: "100%"
    },
    primaryGuarantorWrapper: {
      padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(
        1
      )} ${theme.spacing(3)}`,
      display: "flex",
      color: "#32325d"
    },
    sectionButtonWrapper: {
      padding: "0 14px 14px 14px",
      display: "flex",
      width: "100%",
      margin: "0"
    },
    editImage: {
      width: 16,
      height: 16,
      marginRight: 16,
      objectFit: "contain",
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        display: "none"
      }
    },
    editImageDisplay: {
      display: "none"
    },
    savebutton: {
      width: 70,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4",
      marginLeft: 38,
      marginBottom: 15
    },

    cancelButton: {
      width: 69,
      height: 28,
      borderRadius: 4,
      color: "#32325d",
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#ffffff",
      marginLeft: 10,
      "&:hover": {
        background: "none"
      }
    },
    guarantorHeadingTypography: {
      textAlign: "left",
      fontSize: "14px !important",
      fontWeight: 600,
      color: "#32325d",
      lineHeight: 0.25,
      padding: "0px"
    },
    headingImageContainer: {
      justifyContent: "flex-end",
      display: "flex",
      margin: "-10px"
    },
    headingImage: {
      width: 16,
      height: 16,
      objectFit: "contain",
      cursor: "pointer"
    },
    divider: {
      height: "1px",
      border: "solid 1px #f2f2f2",
      padding: "0px !important"
    },
    addBorrowerBtn: {
      minWidth: 135,
      width: "100%",
      padding: "3.5px 17px",

      color: "#32325d",
      backgroundColor: "#ffffff",
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      borderColor: "#ececec",
      marginBottom: "15px",
      fontSize: 12,
      fontWeight: 600,
      "&:hover": {
        background: "none"
      },
      [theme.breakpoints.up("sm")]: {
        marginRight: "20px",
        width: "auto",
        marginBottom: "0px"
      }
    },
    addGuarantorBtn: {
      // minWidth: 140,
      // width: "100%",
      // marginBottom: "15px",
      // [theme.breakpoints.up("sm")]: {
      //   marginRight: "20px",
      //   width: "auto",
      //   marginBottom: "0px"
      // }
      color: "#32325d",
      padding: "3.5px 17px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      backgroundColor: "#ffffff",
      borderWidth: 1,
      borderColor: "#ececec",
      "&:hover": {
        background: "none"
      }
    },
    borrwerContainer: {
      margin: "10px 10px 0 10px",
      position: "relative"
    },
    guarantorContainer: {
      margin: "10px 10px 0 10px",
      position: "relative"
    }
  })
);
