import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";

import { IconTooltip } from "@toorak/tc-common-fe-sdk";

import { isLATUser } from "../../utils/AccessManagement";
import { useStyles } from "./RateLockAudit.style";
import { getUserFullAndOrgName } from "../../stores/EvaluationResults/EvaluationResults.action";
import { OriginatorIconWithDots, ToorakIconWithDots } from "../Icons";

export const statusMapping: any = {
  "rate_lock_requested": "Requested",
  "rate_lock_applied": "Locked",
  "rate_lock_rejected": "Rejected"
};

export default function RateLockAuditView(props: any) {
  const { rateLockAudits, creditLines, takeoutPartnerId, landMarks } = props;
  const [previousDecisions, setPreviousDecisions] = useState<any>([]);
  const [currentDecision, setCurrentDecision] = useState<any>(null);

  const classes = useStyles();
  const isLAT = isLATUser();

  function getDataForAudit() {
    const auditArray: any = [];
    const currentPartnerDetails = creditLines?.find((creditLine: any) => creditLine?.id === takeoutPartnerId);
    const programName = isLAT ? currentPartnerDetails?.shortName : currentPartnerDetails?.programName;

    const customerDetails = {
      customers: landMarks?.customers,
      accountMapping: landMarks?.accountMapping,
      accounts: landMarks?.accounts
    };

    rateLockAudits?.map((lockAudit: any) => {
      const actionBy: any = getUserFullAndOrgName(
        customerDetails,
        lockAudit.createdBy,
        lockAudit.date
      );
      auditArray.push({
        programName,
        status: statusMapping[lockAudit?.name],
        color: statusMapping[lockAudit?.name] === "Requested" ? "#fb623f" : statusMapping[lockAudit?.name] === "Locked" ? "#00b768" : "#f75676",
        reason: lockAudit?.comments || "",
        initials: actionBy?.NameInitials,
        Icon: actionBy?.accountTypeFirstLetter !== "O" ? ToorakIconWithDots : OriginatorIconWithDots,
        name: actionBy?.userFullName,
        date: actionBy?.dateTime,
        isLAT: actionBy?.accountTypeFirstLetter !== "O"
      })
    });

    setPreviousDecisions(auditArray?.slice(0, auditArray?.length - 1));
    setCurrentDecision(auditArray[auditArray.length - 1]);
  };

  useEffect(() => {
    getDataForAudit();
  }, [props]);

  function lockUi(lockData: any, isCurrentDecision: boolean, index: number) {
    const { Icon } = lockData;
    return (
      <div style={{ marginBottom: "16px", marginLeft: "16px", width: "80%" }}>
        <div style={{ display: "flex", justifyContent: "start", marginBottom: "6px" }}>
          <Typography className={classes.font}>{isCurrentDecision ? "Current Decision:" : "Previous Decision:"} &ensp;</Typography>
          <Typography className={classes.font} style={{ color: lockData?.color }}>{lockData?.status}</Typography>
          <Typography className={`${classes?.programBorder} ${classes.font}`}></Typography>
          <Typography
            component="span"
            className={classes.font}
            sx={{ color: "#3800ff" }}
          >
            {lockData?.programName}
          </Typography>
        </div>
        {
          lockData?.reason
            ? <div style={{ display: "flex", justifyContent: "start", marginBottom: "6px" }}>
              <Typography className={classes.font}>Reason: &ensp;</Typography>
              <Typography className={classes.font} style={{ fontWeight: "normal", wordWrap: "break-word", width: "80%" }} component="span">{lockData?.reason}</Typography>
            </div>
            : null
        }
        <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
          <Avatar className={lockData?.isLAT ? classes.latProfileImage : classes.orgProfileImage}>
            <Typography className={classes.font}>{lockData?.initials.toUpperCase()}</Typography>
          </Avatar>
          &ensp;
          <Typography style={{ color: "#32325d", fontSize: "12px", fontWeight: "normal" }}>{lockData?.name}</Typography>
          &ensp;
          <Icon />
          &ensp;
          <Typography style={{ color: "#32325d", fontSize: "12px", fontWeight: "normal" }}>{lockData?.date}</Typography>
        </div>
      </div>
    )
  };

  return (
    <Grid container style={{ maxHeight: "200px", overflowY: "auto", padding: "22px 28px" }}>
      <Grid item xs={12}>
        <Typography sx={{ marginBottom: "16px", fontSize: "14px" }}>Rate Lock History</Typography>
      </Grid>
      <Grid container>
        <Grid item xs={10}>
          {previousDecisions?.map((lockData: any, idx: number) => {
            return <div className={classes.infoContainer}>
              <Grid
                item
                xs={1}
                sm={1}
                md={1}
                xl={1}
                style={{ display: "flex" }}
              >
                <span className={classes.Line} />
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={12}
                xl={12}
                className={classes.firstEdit}
              >
                <span className={classes.oval}>
                  <IconTooltip
                    topic="Edit_white"
                    infoIconData="edit"
                  />
                </span>
                {lockUi(lockData, false, idx)}
              </Grid>
            </div>
          })}
          {currentDecision ? <div className={classes.infoContainer}>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              xl={12}
              className={classes.firstEdit}
            >
              <span className={classes.oval}>
                <IconTooltip
                  topic="Edit_white"
                  infoIconData="edit"
                />
              </span>
              {lockUi(currentDecision, true, 0)}
            </Grid>
          </div> : null}
        </Grid>
      </Grid>
    </Grid>
  )
}
