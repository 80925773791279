/* eslint-disable no-async-promise-executor */
import { publicClient } from "@toorak/tc-common-fe-sdk";
import { getConfig } from "../../config/config";
import { isLATUser } from "../../utils/AccessManagement";
import { getCookie } from "../../utils/cookies";
import { constructName } from "../../utils/utils";
import { hideLoader } from "../loaderAndException/loaderAndException.action";
import {
  actionFail,
  actionStart,
  actionSuccess,
  commentSuccess,
  internalCommentSuccess,
  updateCommentRedux
} from "./comment.reducer";
import { ObjectType } from "../../masterView/common";

// export const COMMENT_SUCCESS = "COMMENT_SUCCESS";
// export const UPDATE_COMMENT = "UPDATE_COMMENT";
// export const INTERNAL_COMMENT_SUCCESS = "INTERNAL_COMMENT_SUCCESS";
// export const COMMENT_THREAD_SUCCESS = "COMMENT_THREAD_SUCCESS";
// export const COMMENT_SUCCESS_EXCEPTION_WAIVER =
//   "COMMENT_SUCCESS_EXCEPTION_WAIVER";
// export const RESET_COMMENTS = "RESET_COMMENTS";
// export const ACTION_FAIL = "ACTION_FAIL";
// export const ACTION_START = "ACTION_START";
// export const ACTION_SUCCESS = "ACTION_SUCCESS";
// export const NEW_COMMENT_ADDED = "NEW_COMMENT_ADDED";
export function getCommentThread(
  identifier: string,
  identifierID: string,
  identifierSecondary: string,
  identifierSecondaryID: string
) {
  return new Promise((resolve, reject) => {
    const url = `${
      getConfig().apiUrl
    }/chatservice/${identifier}/${identifierID}/chats?secondaryIdentifierName=${identifierSecondary}&secondaryIdentifierValue=${identifierSecondaryID}&internal=false`;
    publicClient
      .get(url)
      .then((res: any) => resolve(res.data))
      .catch(reject);
  });
}
// const personPartyId: string | any = getCookie("person_id");

export interface GetComments {
  chatId: string;
  partyId: string;
  type?: string;
  hideSpinner?: boolean;
  hostId?: string;
}

interface CommentBucket {
  accountMapping: any;
  accounts: any;
  chatLines: any;
  customers: any;
  documents: any;
}
export function updateWaiverComments(
  commentData: GetComments,
  waiverChat: any,
  customerDetails: any,
  isCarryForwardedWaiver?: boolean
): any {
  return async (dispatch: any) => {
    let bucketItems: CommentBucket = {
      accountMapping: customerDetails.accountMapping,
      accounts: customerDetails.accounts,
      customers: customerDetails.customers,
      chatLines: waiverChat.chatLines,
      documents: []
    };
    const commentDocs = customerDetails?.documents?.filter(
      (doc: any) => doc.primaryIdentifierValue === commentData.chatId
    );
    const personPartyId: string | any = getCookie("person_id");
    if (commentDocs?.length) {
      bucketItems = { ...bucketItems, documents: commentDocs };
    }
    dispatch(actionStart());
    if (waiverChat) {
      const unreadCountUrl = `${
        getConfig().apiUrl
      }/chatservice/${personPartyId}/chats?chatIds=${
        commentData.chatId
      }&type=unread`;
      const unreadResponse = await publicClient.get(unreadCountUrl);
      // dispatch({
      //   type: COMMENT_SUCCESS,
      //   payload: {
      //     buckets: bucketItems,
      //     chatId: commentData.chatId,
      //     type: commentData.type,
      //     hostId: commentData.hostId,
      //     count: unreadResponse?.data[0]?.count || 0
      //   }
      // });
      dispatch(
        commentSuccess({
          buckets: bucketItems,
          chatId: commentData.chatId,
          type: commentData.type,
          hostId: commentData.hostId,
          count: unreadResponse?.data[0]?.count || 0
        })
      );
      updateLastReadComment(commentData.chatId, commentData.partyId);
    } else {
      dispatch(hideLoader());
    }
    if (isLATUser() && waiverChat.internalChatlines.length) {
      let internalChatBucket = {
        ...bucketItems,
        chatLines: waiverChat.internalChatlines
      };
      // dispatch({
      //   type: INTERNAL_COMMENT_SUCCESS,
      //   payload: {
      //     buckets: internalChatBucket,
      //     chatId: commentData.chatId,
      //     type: commentData.type,
      //     hostId: commentData.hostId
      //   }
      // });
      dispatch(
        internalCommentSuccess({
          buckets: internalChatBucket,
          chatId: commentData.chatId,
          type: commentData.type,
          hostId: commentData.hostId
        })
      );
    }
    // dispatch({
    //   type: ACTION_SUCCESS
    // });
    dispatch(actionSuccess());
  };
}

export async function getComments(commentData: GetComments, dispatch: any) {
  const personPartyId: string | any = getCookie("person_id");
  if (!commentData.hideSpinner) {
    // console.log("commentData.hideSpinner", commentData.hideSpinner);
    // dispatch(showLoader());
  }
  try {
    const url = `${getConfig().apiUrl}/chatservice/${
      commentData.chatId
    }/lines?limit=200`;

    const aggregateUrl = `${getConfig().apiUrl}/aggregate/chatservice/${
      commentData.chatId
    }/lines?limit=200`;

    const unreadCountUrl = `${
      getConfig().apiUrl
    }/chatservice/${personPartyId}/chats?chatIds=${
      commentData.chatId
    }&type=unread`;

    // dispatch({
    //   type: ACTION_START
    // });
    dispatch(actionSuccess());
    const promiseArr = [];
    const promise1 = !commentData.hostId
      ? await publicClient.get(url)
      : await publicClient.get(aggregateUrl);
    promiseArr.push(promise1);
    const promise2 = await publicClient.get(unreadCountUrl);
    if (promise2.data.length > 0) {
      promiseArr.push(promise2);
    }
    Promise.all(promiseArr)
      .then((response) => {
        if (response[0]?.data || response[1]?.data) {
          // dispatch({
          //   type: COMMENT_SUCCESS,
          //   payload: {
          //     buckets: response[0]?.data,
          //     chatId: commentData.chatId,
          //     type: commentData.type,
          //     hostId: commentData.hostId,
          //     count: response[1]?.data[0]?.count || 0
          //   }
          // });
          dispatch(
            commentSuccess({
              buckets: response[0]?.data,
              chatId: commentData.chatId,
              type: commentData.type,
              hostId: commentData.hostId,
              count: response[1]?.data[0]?.count || 0
            })
          );
          updateLastReadComment(commentData.chatId, commentData.partyId);
        } else {
          dispatch(hideLoader());
        }
      })
      .catch((err) => {
        console.error("err in promise", err);
      });

    if (isLATUser()) {
      const internalCommentUrl = `${getConfig().apiUrl}/aggregate/chatservice/${
        commentData.chatId
      }/lines?type=internal`;
      const res = await publicClient.get(internalCommentUrl);
      if (res.data.chatLines.length > 0) {
        // dispatch({
        //   type: INTERNAL_COMMENT_SUCCESS,
        //   payload: {
        //     buckets: res.data,
        //     chatId: commentData.chatId,
        //     type: commentData.type,
        //     hostId: commentData.hostId
        //   }
        // });
        dispatch(
          internalCommentSuccess({
            buckets: res.data,
            chatId: commentData.chatId,
            type: commentData.type,
            hostId: commentData.hostId
          })
        );
      }
    }
    // dispatch({
    //   type: ACTION_SUCCESS
    // });
    dispatch(actionSuccess());
    if (!commentData.hideSpinner) {
      dispatch(hideLoader());
    }
  } catch (err) {
    const e: any = err;
    if (!commentData.hideSpinner) {
      dispatch(hideLoader());
    }
    // dispatch({
    //   type: ACTION_FAIL,
    //   error: {
    //     message: e.message
    //   }
    // });
    console.error(e?.message);
    dispatch(actionFail());
  }
}

export function createCommentThread(
  identifier: string,
  identifierID: string,
  identifierSecondary: string,
  identifierSecondaryID: string
): Promise<string> {
  return new Promise((resolve, reject) => {
    const url = `${
      getConfig().apiUrl
    }/chatservice/${identifier}/${identifierID}/chats?secondaryIdentifierName=${identifierSecondary}&secondaryIdentifierValue=${identifierSecondaryID}`;
    publicClient
      .post(url, {})
      .then((res: any) => resolve(res.data))
      .catch(reject);
  });
}
export function createComment(
  chatId: string,
  partyId: string,
  lineText: string,
  hasDocuments: boolean,
  approvalType: string,
  // callBack?: string,
  type: string = "",
  users?: any[]
): Promise<string> {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl}/chatservice/${chatId}/lines`;
    const body = {
      partyId,
      lineText,
      hasDocuments,
      tags: [approvalType],
      type,
      users
    };
    publicClient
      .post(url, body)
      .then((res: any) => {
        // callBack(res.data)
        const personPartyId: string = getCookie("person_id") || "";
        updateLastReadComment(chatId, personPartyId)
          .then(() => {
            resolve(res.data);
          })
          .catch(() => {
            resolve(res.data);
          });
      })
      .catch(reject);
  });
}

export function updateComment(
  chatId: string,
  chatLineSequenceId: string,
  lineText: string
): Promise<string> {
  return new Promise((resolve, reject) => {
    const url = `${
      getConfig().apiUrl
    }/chatservice/${chatId}/lines/${chatLineSequenceId}`;
    const body = {
      lineText,
      tags: [null]
    };
    publicClient
      .put(url, body)
      .then((res: any) => {
        // callBack(res.data)
        resolve(res.data);
      })
      .catch(reject);
  });
}

export function updateLastReadComment(
  chatId: string,
  personPartyId: string | any
) {
  const url = `${getConfig().apiUrl}/chatservice/${chatId}/users`;

  const body = {
    partyId: personPartyId,
    lastReadOn: new Date().toISOString()
  };
  return publicClient.post(url, body);
}

export function updateEditedCommentStore(
  value: any,
  loanId: string,
  commentID: string
): any {
  return async (dispatch: any) => {
    // dispatch({
    //   type: UPDATE_COMMENT,
    //   payload: { value, loanId, commentID }
    // });
    dispatch(updateCommentRedux({ value, loanId, commentID }));
  };
}

export const getChatMeta = (
  primaryIdentifier: string,
  primaryIdentifierValue: string,
  secondaryIdentifier: string,
  secondaryIdentifierValue: string
) => {
  const url: string = `${
    getConfig().apiUrl
  }/chatservice/${primaryIdentifier}/${primaryIdentifierValue}/chats?secondaryIdentifierName=${secondaryIdentifier}&secondaryIdentifierValue=${secondaryIdentifierValue}&internal=false`;
  return publicClient.get(url);
};

export const createChatId = (
  primaryIdentifier: string,
  primaryIdentifierValue: string,
  secondaryIdentifier: string,
  secondaryIdentifierValue: string
) => {
  const url: string = `${
    getConfig().apiUrl
  }/chatservice/${primaryIdentifier}/${primaryIdentifierValue}/chats?secondaryIdentifierName=${secondaryIdentifier}&secondaryIdentifierValue=${secondaryIdentifierValue}`;
  return publicClient.post(url, {});
};

// get all chat count
export function getRUChatCount(chatidsObj: any, chatIds: string[]) {
  const userId: string = "99999999-9999-9999-9999-999999999999";
  const url: string = `${
    getConfig().apiUrl
  }/chatservice/${userId}/chats?chatIds=${chatIds.join(
    ","
  )}&type=readunread&includeFirstChat=true`;
  return new Promise((resolve, reject) => {
    publicClient
      .get(url)
      .then((res: any) => {
        const chatCounts: any = {};
        if (res.data.length) {
          res.data.forEach((item: any, index: number) => {
            chatCounts[chatidsObj[item.id]] = {
              count: item.count,
              chatId: item.id
            };
            if (index === res.data.length - 1) {
              resolve(chatCounts);
            }
          });
        } else {
          resolve(chatCounts);
        }
      })
      .catch((err: any) => {
        resolve({});
      });
  });
}

// get unread chat count
export function getUChatCount(chatidsObj: any, chatIds: string[]) {
  const userId: any = getCookie("person_id");
  const url: string = `${
    getConfig().apiUrl
  }/chatservice/${userId}/chats?chatIds=${chatIds.join(
    ","
  )}&type=unread&includeFirstChat=true`;
  return new Promise((resolve, reject) => {
    publicClient
      .get(url)
      .then((res: any) => {
        const chatCounts: any = {};
        if (res.data.length) {
          res.data.forEach((item: any, index: number) => {
            chatCounts[chatidsObj[item.id]] = {
              count: item.count,
              chatId: item.id
            };
            if (index === res.data.length - 1) {
              resolve(chatCounts);
            }
          });
        } else {
          resolve(chatCounts);
        }
      })
      .catch((err: any) => {
        resolve({});
      });
  });
}

export const getChatsList = (
  chatId: string,
  limit: number = 200
): Promise<any> => {
  const url: string = `${
    getConfig().apiUrl
  }/aggregate/chatservice/${chatId}/lines?limit=${limit}`;
  return new Promise((resolve) => {
    publicClient
      .get(url)
      .then((res: any) => {
        resolve(res.data);
      })
      .catch(() => {
        resolve({});
      });
  });
};

export const getChatListAggregate = (
  primaryIdentifier: string,
  primaryIdentifierValue: string,
  secondaryIdentifier: string,
  secondaryIdentifierValue: string,
  currChatId: string = ""
) => {
  return new Promise(async (resolve, reject) => {
    let chatId: string = currChatId;
    if (!chatId) {
      const cData: any = await getChatMeta(
        primaryIdentifier,
        primaryIdentifierValue,
        secondaryIdentifier,
        secondaryIdentifierValue
      );
      if (!cData?.data?.length) {
        const newCdata: any = await createChatId(
          primaryIdentifier,
          primaryIdentifierValue,
          secondaryIdentifier,
          secondaryIdentifierValue
        );
        chatId = newCdata?.data?.id;
      } else {
        chatId = cData?.data[0]?.id;
      }
    }
    // const allCount: any = await getRUChatCount({ [chatId]: chatId }, [chatId]);
    const unreadCount: any = await getUChatCount({ [chatId]: chatId }, [
      chatId
    ]);
    const chatList: any = await getChatsList(chatId);
    resolve({
      chatId,
      unreadCount,
      chatList
    });
  });
};

export const customersListPrep = (esList: any[]) => {
  return esList
    .map((itm: any) => {
      const { firstName, middleName, lastName, partyId } =
        itm?._source?.customer || {};
      return {
        title: constructName(firstName, middleName, lastName),
        key: partyId
      };
    })
    .filter((item: any) => item.title && item.key);
};

export const getCustomersList = async (rolesList: string[]) => {
  const url = `${getConfig().apiUrl}/aggregate/v2/customer/roles`;
  const requestBody: ObjectType = {
    roleNames: rolesList
  };

  let response: any = await publicClient.post(url, requestBody);
  const { data } = response;
  let elasticSearchHits: any = [];
  for (let item of data["access"]) {
    const customerArr = data["customers"];
    const customer = customerArr.find(
      (citem: any) => citem.partyId === item.personId
    );
    elasticSearchHits.push({ _source: { access: item, customer } });
  }

  return { data: { response: { hits: { hits: elasticSearchHits } } } };
};
