import React, { useRef } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import iconSettings from "../images/icon_settings.svg";
import { MenuItem, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { LoanServicerStatus } from "./constants";
import { useStylesServicer } from "../assetManagement/ServicerOnboarding/LoanDetails/ServicerOnboarding.styles";
import { HtmlWhiteTooltip } from "./Tooltip";
import useOutsideClick from "../custom-hooks/useOutsideClick";

export interface availableOptionsInterface {
  title: string | JSX.Element;
}

interface DropDownCommonInterface {
  availableOptions: availableOptionsInterface[];
  setIsMoreActionsVisible: any;
  isMoreActionsVisible: boolean;
  handleActionBtnClick: (item: any) => any;
  headerText?: string;
  displayIcon?: string;
  disabledList?: any;
  tickedItem?: string | LoanServicerStatus;
  styleMenuItem?: any;
  styleOuterContainer?: any;
  styleHeaderText?: any;
  withoutBox?: boolean;
  tooltipText?: string;
  specialOptionsHtml?: any;
  customMenuStyle?: any;
}

export const DropDownCommon = (props: DropDownCommonInterface) => {
  const {
    availableOptions,
    setIsMoreActionsVisible,
    isMoreActionsVisible,
    handleActionBtnClick,
    headerText,
    displayIcon,
    disabledList,
    tickedItem,
    styleMenuItem,
    customMenuStyle = false,
    styleOuterContainer,
    withoutBox,
    tooltipText,
    styleHeaderText,
    specialOptionsHtml
  } = props;
  const classes = useStylesServicer();
  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => {
    if (isMoreActionsVisible) setIsMoreActionsVisible(!isMoreActionsVisible);
  });
  const getDisplayIcon = (type: string | undefined) => {
    switch (type) {
      case "iconSettings":
        return (
          <img
            style={{ width: 16, height: 16 }}
            src={iconSettings}
            alt="settings icon"
          />
        );
      case "MoreVert": {
        return <MoreVertIcon />;
      }
      default:
        return null;
    }
  };

  function showHeaderText() {
    return (
      <Typography
        variant="h5"
        className={classes.dropDownText}
        style={styleHeaderText ? styleHeaderText : null}
      >
        {headerText}
      </Typography>
    );
  }
  return (
    <div
      className={classes.dropDownOuterContainer}
      style={styleOuterContainer ? styleOuterContainer : null}
    >
      <div
        className={!headerText ? "more-action-iconBtn" : undefined}
        style={{
          display: "flex",
          alignItems: "center",
          cursor: availableOptions.length ? "pointer" : "no-drop",
          opacity: availableOptions.length ? 1 : 0.5,
          border: withoutBox ? "0px" : undefined,
          boxShadow: withoutBox ? "none" : undefined
        }}
        onClick={(e: any) => {
          e.stopPropagation();
          if (availableOptions.length)
            setIsMoreActionsVisible(!isMoreActionsVisible);
        }}
        role="button"
        // onKeyDown={() => {
        //   if (availableOptions.length)
        //     setIsMoreActionsVisible(!isMoreActionsVisible);
        // }}
        tabIndex={0}
      >
        {getDisplayIcon(displayIcon)}

        {tooltipText ? (
          <HtmlWhiteTooltip
            title={<Typography>{tooltipText}</Typography>}
            placement="left"
            arrow
          >
            {showHeaderText()}
          </HtmlWhiteTooltip>
        ) : (
          <>{showHeaderText()}</>
        )}
        {headerText && (
          <KeyboardArrowLeftIcon
            fontSize="large"
            htmlColor="#32325d"
            style={{
              transform: isMoreActionsVisible
                ? "rotate(90deg)"
                : "rotate(270deg)",
              objectFit: "contain"
            }}
            className={classes.arrowMobileViewServicer}
          />
        )}
      </div>
      {isMoreActionsVisible && (
        <div
          ref={ref}
          className={classes.dropDownContainerSO}
          style={styleMenuItem ? styleMenuItem : { minWidth: 120 }}
        >
          <div style={{ position: "relative" }}>
            {availableOptions.map((item: any) => (
              <MenuItem
                onClick={() => {
                  setIsMoreActionsVisible(false);
                  handleActionBtnClick(item);
                }}
                role="button"
                onKeyDown={() => {
                  setIsMoreActionsVisible(false);
                }}
                disabled={disabledList && disabledList.includes(item.title)}
                tabIndex={0}
                key={item.title}
                className={classes.menuItem}
                style={
                  customMenuStyle
                    ? customMenuStyle
                    : {
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                      }
                }
              >
                {tickedItem &&
                  item.title &&
                  tickedItem.toUpperCase() === item.title.toUpperCase() && (
                    <>&#10003; </>
                  )}

                {specialOptionsHtml ? (
                  specialOptionsHtml(item.title)
                ) : (
                  <>{item.title}</>
                )}
              </MenuItem>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
