import React, { useEffect, useState } from "react";
import { HtmlTooltip, LoanStage } from "@toorak/tc-common-fe-sdk";
import {
  Grid,
  Typography,
  InputLabel,
  Button,
  InputBase,
  CircularProgress
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
// import { useHistory } from "react-router-dom";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { RadioButton } from "@toorak/tc-common-fe-sdk";
import { useStyles as useStylesException } from "./ExceptionCardStyle";
import { formStyles, waiverFormStyles } from "../waiver-form/Common.style";
import { CustomizedHook } from "./EnquiryTags";
import { RootState } from "../../stores/rootReducer";
import SubmitIcon from "../../images/submit_icon.svg";
import { validStringWithoutSpace } from "./ResponseAndDocumentUploadCard";
import {
  // deleteUploadedDocument,
  postLoanEnquiry
} from "../../stores/ExceptionsResults/ExceptionActions";
import { uuidV4 } from "../../utils/utils";
import { FileBar } from "../waiver-form/FileBar";
import { handleFileUpload, uploadFileToDocuments } from "./DocumentUploadApis";
import { getCookie } from "../../utils/cookies";
import { ShowDialog } from "../waiver-form/ShowDialog";
import {
  // clearLoader,
  hideLoader,
  showLoader
} from "../../stores/loaderAndException/loaderAndException.action";
import { clearLoader } from "../../stores/loaderAndException/loaderAndException.reducer";
import { PropertyLocation } from "../scenario/PropertyLocation";
import { extensionList } from "../../utils/constants";
import { setActiveInteractionsModal } from "../create-loan.action";
import { deleteUploadedDocumentException } from "../../stores/ExceptionsResults/ExceptionsReducer";

export interface LoanEnquiryCardProps {
  exceptionsResult?: any;
  isViewDetailsVisible?: boolean;
  exceptionId?: string;
  loanDetails?: any;
  fromCreateScenario?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    textAreaWrapper: {
      width: "100%",
      height: "89px",
      border: "1px solid #cad1d7",
      borderRadius: "4px"
    },
    textArea: {
      fontFamily: "sans-serif !important",
      width: "100%",
      height: "100%",
      padding: "14px 12px 14px 12px",
      resize: "none",
      border: "none",
      borderRadius: "4px",
      fontSize: "14px"
    },
    labelRoot: {
      color: "#8897aa",
      fontSize: 12
    },
    descriptionPadding: {
      paddingTop: "35px",
      paddingBottom: "10px"
    },
    sectionPadding: {
      paddingTop: "15px",
      paddingBottom: "10px",
      paddingRight: "10px"
    },
    submitEnquiry: {
      padding: "5px 0px 10px 20px",
      borderTop: "1px solid #f2f2f2",
      background: "white"
    },
    red: {
      color: "#f75676",
      fontSize: "12px"
    },
    inputRoot: {
      border: "1px solid #cad1d7 !important",
      borderRadius: "4px",
      color: "rgba(0,0,0, 0.9)"
    },
    submitButton: {
      height: "36px"
    },
    contentDisplay: {
      display: "flex",
      alignSelf: "center",
      paddingLeft: "10px"
    },
    labelText: {
      fontFamily: "'Open Sans', sans-serif !important",
      color: "#8897aa",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      fontSize: "12px",
      paddingBottom: 5,
      paddingTop: 15
    },
    radioContainer: {
      padding: "15px 20px 15px 20px",
      backgroundColor: "#ffffff"
    }
  })
);

export const LoanEnquiryCard = (props: any) => {
  const exceptionClasses = useStylesException();
  const formClass = formStyles();
  const classes = useStyles();
  const classesWaiver = waiverFormStyles();
  const { loanId, loanStage } = useParams<{
    loanId: string;
    loanStage: LoanStage;
  }>() as any;
  // const history = useHistory();
  const dispatch = useDispatch();
  const [filesList, setFilesList] = useState<any>([]);
  const [dialogdetails, setDialogdetails] = useState<any>({});
  let enquiryId = "";
  const [openDialog, setOpenDialog] = useState(false);
  const tagsToSend: any[] = [];
  const [value, setValue] = useState<string>("");
  const [valid, setValid] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [inquiryType, setInquiryType] = useState("VisibleToToorak");
  const [submitDisable, setSubmitDisable] = React.useState<string[]>([]);
  const { takeoutPartnerId } = useSelector<RootState, any>(
    (state) =>
      state.createLoanStore?.loanDetails.thirtyYearLoanInformation.payload
  );
  const {
    fromCreateScenario,
    heading,
    label,
    descriptionLabel,
    closeModal,
    type,
    status
  } = props;
  const partyId: string = getCookie("person_id") || "";
  const id = uuidV4();
  const docsTags = useSelector<RootState, any>(
    (state) => state.createLoanStore.tags
  );
  useEffect(() => {
    if (validStringWithoutSpace(value) !== null && value.length > 0) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [value]);

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };
  selectedTags.forEach((item: any) => {
    tagsToSend.push(item.name);
  });
  const fileUploadCallBack = (body: any) => {
    closeModal();
    // history.goBack();
    // if (getCookie("loginFlow") === "originator") {
    //   dispatch(setActiveTabAndProperty("exceptionsEnquiries"));
    // } else {
    //   dispatch(setActiveTabAndProperty("exceptionsEnquiries"));
    // }
  };
  const startUpload = async () => {
    const body: any[] = [];
    for (let i = 0; i < filesList.length; i++) {
      /*@ts-ignore*/
      if (filesList[i]?.body !== null && filesList[i]?.body !== []) {
        body.push(filesList[i]?.body[0]);
      }
    }
    uploadFileToDocuments(
      body,
      "EXCEPTION",
      "",
      "",
      "",
      fileUploadCallBack,
      enquiryId
    );
  };
  const postLoanEnquiryCallBack = (id_: string) => {
    enquiryId = id_;
    if (filesList.length > 0) {
      startUpload();
    }
  };

  const handleSubmit = () => {
    dispatch(showLoader());
    dispatch(
      postLoanEnquiry(
        value,
        tagsToSend,
        loanId,
        loanStage,
        partyId,
        inquiryType,
        postLoanEnquiryCallBack,
        type,
        status,
        takeoutPartnerId
      )
    );
    setValue("");
    closeModal();
    dispatch(hideLoader());
    setTimeout(() => {
      dispatch(setActiveInteractionsModal(false, loanId, loanStage));
      /*@ts-ignore*/
    }, [1000]);
    // if (filesList.length === 0) {
    //   history.goBack();
    //   if (getCookie("loginFlow") === "originator") {
    //     dispatch(setActiveTabAndProperty("exceptionsEnquiries"));
    //   } else {
    //     dispatch(setActiveTabAndProperty("exceptionsEnquiries"));
    //   }
    // }
  };

  const setSubmitDisableWrapper = (
    id: string,
    addNewToArray: boolean,
    error?: any
  ) => {
    if (addNewToArray) {
      setSubmitDisable((state) => [...state, id]);
    } else {
      setSubmitDisable((state) => state.filter((ele) => ele !== id));
    }
    if (error) {
      alert("Network Error, Please upload documents again");
      dispatch(clearLoader());
    }
  };

  const handleFileUploadWrapper = (e: any) => {
    handleFileUpload(e, {
      setOpenDialog,
      filesList,
      setDialogdetails,
      setFilesList,
      loanId,
      setSubmitDisable: setSubmitDisableWrapper
    });
  };

  let sendBtnText = "Submit";
  const isFileUploadInProgress = submitDisable.length > 0;
  if (isFileUploadInProgress) {
    sendBtnText = "Files upload in progress";
  }

  const deleteUploadedDocumentWrapper = (file: any, uniqueId: string) => {
    dispatch(deleteUploadedDocumentException({ actionId: uniqueId }));
    const fileIndex = filesList.findIndex(
      (item?: any) => item.docsId === file.docsId
    );
    filesList.splice(fileIndex, 1);
    setFilesList([...filesList]);
  };

  const handleInquiryType = (event: any) => {
    setInquiryType(event.target.value);
  };

  useEffect(() => {
    if (isFileUploadInProgress) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [dispatch, isFileUploadInProgress]);
  const radioGroupArr = !fromCreateScenario
    ? [
        {
          dataTestId: "visible-to-toorak",
          label: "Visible to Toorak",
          value: "VisibleToToorak"
        },
        {
          dataTestId: "visible-to-toorak-originator",
          label: "Visible to Toorak & Originator",
          value: "VisibleToToorakOriginator"
        }
      ]
    : [
        {
          dataTestId: "scenario-bridge-loan",
          label: "Bridge",
          value: "Bridge Loan"
        },
        {
          dataTestId: "scenario-dscr-loan",
          label: "Investor DSCR",
          value: "30 Year Loan"
        }
      ];
  return (
    <>
      <Grid>
        {heading && (
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={exceptionClasses.container}
          >
            <Typography className={formClass.headingTypography}>
              {heading}
            </Typography>
          </Grid>
        )}
        <Grid
          xs={12}
          sm={12}
          md={12}
          xl={12}
          className={exceptionClasses.container}
        >
          <Grid
            // xs={!fromCreateScenario ? 6 : 3}
            // sm={!fromCreateScenario ? 6 : 3}
            // md={!fromCreateScenario ? 6 : 3}
            // xl={!fromCreateScenario ? 6 : 3}
            style={{ marginTop: 4 }}
          >
            <RadioButton
              label={label}
              radioGroupName="radio-type"
              onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                handleInquiryType(e)
              }
              value={inquiryType}
              dataTestId="radio-button"
              helperText=""
              display="flex"
              data={radioGroupArr}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.radioContainer}
        >
          {docsTags && docsTags.tags ? (
            <>
              <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputLabel className={classes.labelText}>
                  Tags (Field name, Doc name etc.)
                </InputLabel>
              </Grid>
              <Grid container item xs={12}>
                <CustomizedHook
                  tags={docsTags.tags}
                  setSelectedTags={setSelectedTags}
                />
              </Grid>
              <InputLabel className={classes.labelText}>
                Use a comma to separate tags, or choose from the list.
              </InputLabel>
            </>
          ) : null}
          {fromCreateScenario ? (
            <Grid item xs={12}>
              <InputLabel classes={{ root: classes.labelRoot }}>
                Subject<sup className={classes.red}>*</sup>
              </InputLabel>
              <InputBase
                fullWidth
                placeholder="Write here"
                classes={{ input: classes.inputRoot }}
              />
            </Grid>
          ) : null}
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={classes.descriptionPadding}
          >
            <InputLabel classes={{ root: classes.labelRoot }}>
              {descriptionLabel} <sup className={classes.red}>*</sup>
            </InputLabel>
            <div className={classes.textAreaWrapper}>
              <textarea
                className={classes.textArea}
                required
                value={value}
                name="create-loan-description"
                onChange={handleChange}
                placeholder="Write here"
              />
            </div>
          </Grid>
          {fromCreateScenario ? <PropertyLocation /> : null}
          {filesList?.length > 0 ? (
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              data-testid="file-upload-card"
            >
              {filesList && filesList?.length
                ? Object.entries(filesList)?.map(
                    ([key, item]: [any, any], i) => (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        key={item.uniqueId}
                        className={classesWaiver.fileSectionWrappers}
                      >
                        <FileBar
                          deleteHandler={() =>
                            deleteUploadedDocumentWrapper(item, item.uniqueId)
                          }
                          value={item}
                        />
                      </Grid>
                    )
                  )
                : null}
            </Grid>
          ) : null}
        </Grid>
        <Grid container direction="row" alignItems="stretch">
          <Grid
            container
            item
            xs={12}
            className={classes.submitEnquiry}
            alignItems="baseline"
          >
            <Grid item className={classes.sectionPadding}>
              <Button
                variant="contained"
                startIcon={
                  isFileUploadInProgress ? (
                    <CircularProgress size={14} style={{ color: "white" }} />
                  ) : (
                    <img alt="submit" src={SubmitIcon} />
                  )
                }
                className={classes.submitButton}
                onClick={handleSubmit}
                type="submit"
                disabled={isFileUploadInProgress || !valid}
                color="info"
              >
                {sendBtnText}
              </Button>
            </Grid>
            <Grid item>
              <input
                style={{
                  display: "none"
                }}
                id={`raised-button-file-${id}`}
                multiple
                type="file"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleFileUploadWrapper(event)
                }
                onClick={(
                  event: React.MouseEvent<HTMLInputElement, MouseEvent>
                ) => {
                  const element = event.target as HTMLInputElement;
                  element.value = "";
                }}
              />
              <label htmlFor={`raised-button-file-${id}`}>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classesWaiver.uploadButton}
                  disabled={filesList.length === 50}
                >
                  <AttachFileIcon style={{ transform: "rotate(30deg)" }} />
                </Button>
              </label>
            </Grid>
            <Grid item alignItems="center" className={classes.contentDisplay}>
              <HtmlTooltip
                placement="right-end"
                title={
                  <>
                    <Typography>Upload Restrictions</Typography>
                    <ul>
                      <li>Max size: 20MB</li>
                      <li>Max number of docs: 50</li>
                      <li>
                        File type:
                        <ul>
                          {extensionList.map((item: any) => {
                            return <li>{item}</li>;
                          })}
                        </ul>
                      </li>
                    </ul>
                  </>
                }
              >
                <InfoOutlinedIcon />
              </HtmlTooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <ShowDialog
          dialogdetails={dialogdetails}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
        />
      </Grid>
    </>
  );
};
