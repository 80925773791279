/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Grid,
  Theme,
  Tooltip,
  Button,
  Dialog
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import {
  PropertyInformation,
  CreateLoanParamsBridgeLoan
} from "@toorak/tc-common-fe-sdk";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import { RootState } from "../../stores/rootReducer";
import { BridgeLoanData } from "../form-components/FormComponentsProps";

import {
  OriginalAppraisalValueTextField,
  OriginalAppraisalRepairedValueTextField,
  PurchasePriceTextField,
  CostBasisTextField,
  GrossPotentialRentTextField,
  AnnualPropertyTaxesTextField,
  InsuranceTextField,
  AnnualHOAFeeTextField,
  AssignmentFeesTextField,
  RentalCashflowRatioTextField
} from "../form-components/PropertyEconomics";
import {
  updatePropertyDetails,
  postPropertyData,
  updateEditComments,
  previousLoanState,
  // valueEditing,
  // POST_PROPERTY_SUCCESS,
  // POST_PROPERTY_FAILED,
  validateForm as validateFormCheck,
  editCommentMissing,
  keepCreditEvalSync,
  updatePreviousLoanStateAction
} from "../create-loan.action";
import iconMinimize from "../../images/icon_minimize.svg";
import iconMaximize from "../../images/icon_maximize.svg";
import editImage from "../../images/Edit.svg";
import {
  evaluateLoan,
  getOverriddenOutputs,
  updateOverriddenInredux
} from "../../stores/EvaluationResults/EvaluationResults.action";
import {
  checkFieldValidity,
  isEmptyValue,
  isValidCurrency,
  isValidPercentage,
  enableReasonField,
  editHistoryValue,
  sanitizePercentage,
  sanitizeValueByType
} from "../../utils/formatChecks";
import { getAffectedWaivers } from "../../stores/WaiverRequest/WaiverRequest.action";
import { bridgePropertyFields } from "../constants/loanFieldType";
import { getEvaluationRequestBody } from "../evaluation-results/helpers/ruleRequestBody";

import { isLATUser } from "../../utils/AccessManagement";
import { AppraisalReportedTextField } from "../form-components/PropertyInformation";
import { getCookie } from "../../utils/cookies";
import {
  hideLoader,
  showLoader
} from "../../stores/loaderAndException/loaderAndException.action";
import { requestWaiverMessage } from "../../utils/constants";
import { useAppraisalForm } from "../appraisal/shared/useAppraisalForm";
import { isHidden } from "./PropertyDetailsPropertyInformation";
import { computePropertyError } from "./PropertyDetailsHelper";
import { UpdatedPropertyEconomics } from "../../masterView/common";
import { getLoanType } from "../../config/config";
import {
  loanTypeFailed,
  setAutoSaveCall,
  updatePropertySuccess,
  valueEditing
} from "../create-loan.reducer";

export let globalSaveOnPropertyFieldChange: any;
let commentObj: any = [];
export function prepareCommentObjectProperty(
  field: string,
  value: any,
  comment: string,
  clearComments?: boolean,
  valueChange?: boolean
) {
  if (clearComments) {
    commentObj = [];
    return;
  }
  // if (!isLATUser()) {
  //   comment = "";
  // }
  for (let i = 0; i < commentObj.length; i++) {
    if (commentObj[i].field === field) {
      if (valueChange) {
        commentObj[i].value = value;
        return;
      }
      if (comment.trim() === "") {
        commentObj.splice(i, 1);
        return;
      }

      commentObj[i].comment = comment;
      commentObj[i].value = value;
      return;
    }
  }
  if (comment.trim() === "" && !valueChange) {
    return;
  }
  const fieldCommentObj = {
    field,
    value,
    comment,
    updatedBy: getCookie("person_id")
  };
  commentObj.push(fieldCommentObj);
}

let changedValueFields: any = [];
const editableFlags: any = {};
let originalPropertyEconomics: any;
const cleanCurrency = (text: string) => {
  return (
    text
      .replace(/[^0-9.$]/g, "")
      // eslint-disable-next-line no-plusplus, no-param-reassign
      .replace(
        /[$]/g,
        (
          (i) => (m: string) =>
            !i++ ? m : ""
        )(0)
      )
      // eslint-disable-next-line no-plusplus, no-param-reassign
      .replace(
        /[.]/g,
        (
          (i) => (m: string) =>
            !i++ ? m : ""
        )(0)
      )
      .replace(/[$]/g, (c, i) => (i !== 0 ? "" : c))
      .replace(/^\./, "")
      .replace(/^\$\./, "$")
      .replace(/\.(\d\d)\d?$/, ".$1")
  );
};

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD"
});

const sanitizeCurrency = (text: string) => {
  const result = text.replace(/\.$/, "");
  const validCurrency =
    result === ""
      ? result
      : currencyFormatter.format(
          Number.parseFloat(result.replace(/[^0-9.]/g, ""))
        );
  return validCurrency;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      input: {
        backgroundColor: "#ffffff !important"
      }
    },
    container: {
      borderRadius: 4,
      display: "flex",
      flexDirection: "column",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      backgroundColor: "#ffffff",
      marginTop: theme.spacing(3)
      // fontFamily: "OpenSans"
    },
    sectionWrapper: {
      padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
      display: "flex"
    },
    headingTypography: {
      textAlign: "left",
      fontSize: "14px !important",
      fontWeight: 600,
      color: "#32325d",
      lineHeight: 1.57
    },
    modalDeleteButtonConatainer: {
      marginTop: 50
    },
    modalDeleteButton: {
      marginRight: 12,
      color: "#ffffff",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      marginTop: 18,
      borderWidth: 0
    },
    modalCancelButton: {
      color: "#32325d",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      backgroundColor: "#ffffff",
      marginTop: 18,
      borderWidth: 1,
      borderColor: "#ececec"
    },
    modalButton: {
      marginRight: 12,
      color: "#ffffff",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      marginTop: 18,
      borderWidth: 0,
      "&:hover": {
        backgroundColor: "#5566d6 !important"
      }
    },
    headingImageContainer: {
      justifyContent: "flex-end",
      display: "flex"
    },
    headingImage: {
      width: 16,
      height: 16,
      objectFit: "contain",
      cursor: "pointer"
    },
    divider: {
      height: "1px",
      border: "solid 1px #f2f2f2",
      padding: "0px !important"
    },
    editImage: {
      width: 16,
      height: 16,
      marginRight: 16,
      objectFit: "contain",
      cursor: "pointer"
    },
    editImageDisplay: {
      display: "none"
    },
    savebutton: {
      width: 70,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4"
    },

    cancelButton: {
      width: 69,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      color: "#32325d",
      backgroundColor: "#fff",
      "&:hover": {
        background: "none"
      },
      marginLeft: 10
    },
    formSelectContainer: {
      justifyContent: "space-around",
      display: "flex",
      flexDirection: "row",
      width: "95%",
      padding: "8px 0px 0px 0px"
    },
    iconInfo: {
      width: "16px !important",
      height: "16px !important",
      color: "#32355d",
      "&:hover": {
        color: "#5e72e4"
      }
    },
    labelInformation: {
      fontSize: 12,
      fontWeight: 600,
      color: "#8897aa",
      height: "14px",
      "&:hover": {
        color: "#5e72e4"
      }
    }
  })
);

export interface PropertyDetailsPropertyEconomicsProps {
  propertyEconomics?: UpdatedPropertyEconomics | null;
  propertyId: string;
  isEvaluationPanelVisible: boolean;
  propertyInformation?: PropertyInformation | null;
  data?: BridgeLoanData;
  isAllowedToEdit: boolean;
  isMandatoryServicerField?: (field: string) => boolean;
  index?: number;
}

export function PropertyDetailsPropertyEconomics(
  props: PropertyDetailsPropertyEconomicsProps
) {
  const {
    propertyEconomics,
    propertyId,
    isEvaluationPanelVisible,
    data,
    isAllowedToEdit,
    index,
    isMandatoryServicerField
  } = props;
  const colSpan = 4;
  const classes = useStyles();
  const dispatch = useDispatch();

  const validateForm = useSelector<RootState, { validate: boolean }>(
    (state) => state.createLoanStore.validateForm
  );
  const overriddenValues = useSelector<RootState, any>(
    (state) => state.evaluationResultsStore.overriddenValues
  );
  const { loanId, loanStage, loanType } = useParams<any>() as any;
  const [callAutoSave, setCallAutoSave] = useState<{
    value: boolean;
    impactingFieldChange: boolean;
  }>({ value: false, impactingFieldChange: false });

  useEffect(() => {
    if (callAutoSave.value) {
      postPropertyAutoSave(loanId, propertyDetails);
      setCallAutoSave({ value: false, impactingFieldChange: false });
    }
  }, [propertyEconomics]);

  const { loanDetails } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );
  const {
    propertyDetails,
    loanState,
    loanRuleVersions,
    bridgeIsGroundUpLoan
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = loanDetails;

  const { purchasePrice: appraisalPurchasePrice } = useAppraisalForm();
  useEffect(() => {
    if (appraisalPurchasePrice && loanState === LoanStatusEnum.DataEntry) {
      dispatch(
        updatePropertyDetails(propertyId, {
          propertyEconomics: {
            payload: {
              purchasePrice: cleanCurrency(
                sanitizeCurrency(appraisalPurchasePrice.toString()) || ""
              )
            }
          }
        })
      );
    }
  }, [appraisalPurchasePrice, loanState]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [open, setOpen] = React.useState(false);
  const activePropertyId = useSelector<RootState, any>(
    (state) => state.createLoanStore.loanDetails.activePropertyId
  );
  const [errors, setErrors] = useState(0);
  const [isMinimized, setMinimized] = useState(false);
  const [isEditable, setEditable] = useState(false);
  const [resetData, setResetData] = useState(false);
  const [
    originalAsIsAppraisalValueEdited,
    setOriginalAsIsAppraisalValueEdited
  ] = useState(false);
  const [purchasePriceEdit, setPurchasePriceEdit] = useState(false);
  const [costBasisEdited, setCostBasisEdited] = useState(false);
  const [
    originalRepairedAppraisalValueEdited,
    setoriginalRepairedAppraisalValueEdited
  ] = useState(false);
  const [appraisalReportedError, setAppraisalReportedError] = useState("");
  const [potentialRentEdited, setPotentialRentEdited] = useState(false);
  const [assignmentFeesEdited, setassignmentFeesEdited] = useState(false);
  const [insuranceEdited, setInsuranceEdited] = useState(false);
  const [annualTaxEdited, setAnnualTaxEdited] = useState(false);
  const [appraisalReportedEdited, setAppraisalReportedEdited] = useState(false);
  // Error
  const [appraisalValueError, setAppraisalValueError] = useState<string>("");
  const [repairedAppraisalValueError, setRepairedAppraisalValueError] =
    useState<string>("");
  const [purchasePriceError, setPurchasePriceError] = useState<string>("");
  const [costBasisError, setCostBasisError] = useState<string>("");
  const [grossPotentialRentError, setGrossPotentialRentError] =
    useState<string>("");
  const [annualPropertyTaxesError, setAnnualPropertyTaxesError] =
    useState<string>("");
  const [assignmentFeesError, setAssignmentFeesError] = useState<string>("");
  const [insuranceError, setInsuranceError] = useState<string>("");
  const [fieldError, setFieldError] = useState<{ [index: string]: string }>({});
  const editHistory = useSelector<RootState, any>(
    (state) => state.createLoanStore.editHistory
  );
  const { takeoutPartnerId } = useSelector<RootState, any>(
    (state) =>
      state.createLoanStore?.loanDetails.thirtyYearLoanInformation.payload
  );
  let editHistory2: any;
  editHistory2 = editHistory;
  const loanEvaluationResult = useSelector<
    RootState,
    { loanResult: any; propertiesResults: any }
  >((state) => state.evaluationResultsStore?.loanEvaluationResult);
  const { waiversCreated } = useSelector<RootState, any>(
    (state) => state.evaluationResultsStore
  );
  let infoContent = useSelector<RootState, any>(
    (state) => state.createLoanStore.infoContentProperty
  );
  if (infoContent) {
    infoContent = JSON.parse(JSON.stringify(infoContent));
  }
  useEffect(() => {
    if (resetData && originalPropertyEconomics) {
      dispatch(
        previousLoanState(originalPropertyEconomics, "propertyEconomics", true)
      );
      setResetData(!resetData);
    }
  });

  useEffect(() => {
    if (activePropertyId && resetData) {
      onCancel();
      updatePreviousLoanState();
      dispatch(
        previousLoanState(originalPropertyEconomics, "propertyEconomics", true)
      );
      setResetData(!resetData);
    }
  }, [activePropertyId]);

  const onCancel = () => {
    dispatch(valueEditing(false));
    for (const i in editableFlags) editableFlags[i] = false;
    closeForm();
    setResetData(!resetData);
    prepareCommentObjectProperty("", "", "", true);
  };

  useEffect(() => {
    const errorCount = [
      appraisalValueError,
      repairedAppraisalValueError,
      purchasePriceError,
      costBasisError,
      grossPotentialRentError,
      assignmentFeesError,
      annualPropertyTaxesError,
      insuranceError,
      appraisalReportedError
    ].filter((item) => item).length;
    setErrors(errorCount);
    if (validateForm.validate) {
      dispatch(
        updatePropertyDetails(propertyId, {
          propertyEconomics: {
            errors: errorCount
          }
        })
      );
    }
  }, [
    dispatch,
    appraisalValueError,
    repairedAppraisalValueError,
    purchasePriceError,
    costBasisError,
    grossPotentialRentError,
    assignmentFeesError,
    annualPropertyTaxesError,
    insuranceError,
    appraisalReportedError
  ]);

  useEffect(() => {
    if (validateForm.validate) {
      dispatch(
        updatePropertyDetails(propertyId, {
          propertyEconomics: {
            errors
          }
        })
      );
    }
  }, [validateForm]);

  useEffect(() => {
    if (loanState === LoanStatusEnum.DueDiligenceReview) {
      dispatch(valueEditing(false));
      for (const i in editableFlags) editableFlags[i] = false;
      setEditable(!isEditable);
      changedValueFields = [];
      setAnnualTaxEdited(false);
      setoriginalRepairedAppraisalValueEdited(false);
      setOriginalAsIsAppraisalValueEdited(false);
      setPotentialRentEdited(false);
      setassignmentFeesEdited(false);
      setCostBasisEdited(false);
      setPurchasePriceEdit(false);
      setInsuranceEdited(false);
      setAppraisalReportedEdited(false);
    }
  }, [loanState]);

  function validatePropertyEconomics() {
    const errorObject: any = {};
    if (!propertyEconomics?.originalAppraisalValue) {
      setAppraisalValueError("This field is required");
    } else if (
      !checkFieldValidity(
        propertyEconomics?.originalAppraisalValue,
        bridgePropertyFields.originalAppraisalValue
      )
    ) {
      setAppraisalValueError("Please enter value in proper currency format");
    } else {
      setAppraisalValueError("");
    }

    if (!propertyEconomics?.originalRepairedAppraisalValue) {
      setRepairedAppraisalValueError("This field is required");
    } else if (
      !checkFieldValidity(
        propertyEconomics?.originalRepairedAppraisalValue,
        bridgePropertyFields.originalRepairedAppraisalValue
      )
    ) {
      setRepairedAppraisalValueError(
        "Please enter value in proper currency format"
      );
    } else {
      setRepairedAppraisalValueError("");
    }

    if (!propertyEconomics?.purchasePrice) {
      setPurchasePriceError("This field is required");
    } else if (
      !checkFieldValidity(
        propertyEconomics?.purchasePrice,
        bridgePropertyFields.purchasePrice
      )
    ) {
      setPurchasePriceError("Please enter value in proper currency format");
    } else {
      setPurchasePriceError("");
    }
    if (isEmptyValue(propertyEconomics?.costBasis)) {
      if (!bridgePropertyFields.costBasis.optionalIn?.includes(loanStage))
        setCostBasisError("This field is required");
    } else if (
      !checkFieldValidity(
        propertyEconomics?.costBasis,
        bridgePropertyFields.costBasis
      )
    ) {
      setCostBasisError("Please enter value in proper currency format");
    } else {
      setCostBasisError("");
    }

    if (!propertyEconomics?.grossPotentialRent) {
      setGrossPotentialRentError("This field is required");
    } else if (
      !checkFieldValidity(
        propertyEconomics?.grossPotentialRent,
        bridgePropertyFields.grossPotentialRent
      )
    ) {
      setGrossPotentialRentError(
        "Please enter value in proper currency format"
      );
    } else {
      setGrossPotentialRentError("");
    }

    if (!propertyEconomics?.assignmentFees) {
      setAssignmentFeesError("This field is required");
    } else if (
      !checkFieldValidity(
        propertyEconomics?.assignmentFees,
        bridgePropertyFields?.assignmentFees
      )
    ) {
      setAssignmentFeesError("Please enter value in proper currency format");
    } else {
      setAssignmentFeesError("");
    }

    if (!propertyEconomics?.annualPropertyTaxes) {
      setAnnualPropertyTaxesError("This field is required");
    } else if (
      !checkFieldValidity(
        propertyEconomics?.annualPropertyTaxes,
        bridgePropertyFields.annualPropertyTaxes
      )
    ) {
      setAnnualPropertyTaxesError(
        "Please enter value in proper currency format"
      );
    } else {
      setAnnualPropertyTaxesError("");
    }
    if (!propertyEconomics?.annualHoaFee) {
      errorObject.annualHoaFee = "This field is required";
    } else if (
      !checkFieldValidity(
        propertyEconomics?.annualHoaFee,
        bridgePropertyFields.annualHoaFee
      )
    ) {
      errorObject.annualHoaFee = "Please enter value in proper currency format";
    } else {
      errorObject.annualHoaFee = "";
    }
    if (!propertyEconomics?.rentalCashflowRatio) {
      errorObject.rentalCashflowRatio = "This field is required";
    } else if (
      !checkFieldValidity(
        propertyEconomics?.rentalCashflowRatio,
        bridgePropertyFields.rentalCashflowRatio
      )
    ) {
      errorObject.rentalCashflowRatio = "Please enter numeric value";
    } else {
      errorObject.rentalCashflowRatio = "";
    }
    if (!propertyEconomics?.insurance) {
      setInsuranceError("This field is required");
    } else if (
      !checkFieldValidity(
        propertyEconomics?.insurance,
        bridgePropertyFields.insurance
      )
    ) {
      setInsuranceError("Please enter value in proper currency format");
    } else {
      setInsuranceError("");
    }

    if (isEmptyValue(propertyEconomics?.appraisalReported)) {
      setAppraisalReportedError("This field is required");
    } else if (
      !checkFieldValidity(
        propertyEconomics!.appraisalReported,
        bridgePropertyFields.appraisalReported
      )
    ) {
      setAppraisalReportedError("Please enter value in proper currency format");
    } else {
      setAppraisalReportedError("");
    }
    setFieldError(errorObject);
  }
  useEffect(() => {
    if (validateForm.validate) {
      validatePropertyEconomics();
    }
  }, [propertyId, propertyEconomics, validateForm]);

  const handleBlur =
    (fieldName: string, fieldType: string) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      let formattedValue = event.target.value;
      switch (fieldType) {
        case "currency":
          const sanitizedCurrency = sanitizeCurrency(event.target.value);
          if (isValidCurrency(sanitizedCurrency))
            formattedValue = sanitizedCurrency;
          break;
        case "percentage":
          const sanitizedPercentage = sanitizePercentage(event.target.value);
          if (isValidPercentage(sanitizedPercentage))
            formattedValue = sanitizedPercentage;
          break;
        default:
      }
      if (
        fieldName === "costBasis" &&
        isEmptyValue(propertyEconomics?.costBasis)
      ) {
        if (bridgePropertyFields.costBasis.optionalIn?.includes(loanStage))
          setCostBasisError("");
      }
      const payload = {
        [fieldName]: formattedValue
      };
      const propertyInfoCopy: any = propertyEconomics;
      if (
        event?.target.parentElement?.parentElement?.innerText.includes(
          "Reason For Editing"
        )
      ) {
        let commentFieldName: string = fieldName;
        switch (fieldName) {
          case "originalAppraisalValue":
            commentFieldName = "originalAsIsAppraisalValue";
            break;
          case "appraisalReported":
            commentFieldName = "appraisalReportedNoiNcf";
            break;
          case "originalRepairedAppraisalValue":
            commentFieldName = "originalAsRepairedAppraisedValue";
            break;
        }
        prepareCommentObjectProperty(
          `data.properties[${activePropertyId}].propertyEconomics.${commentFieldName}`,
          propertyInfoCopy[fieldName],
          event.target.value
        );
        return;
      }
      dispatch(
        updatePropertyDetails(propertyId, {
          propertyEconomics: {
            payload
          }
        })
      );
      setCallAutoSave({ value: true, impactingFieldChange: true });
    };

  const onAppraisalReportedChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (isEvaluationPanelVisible) {
      prepareCommentObjectProperty(
        `data.properties[${activePropertyId}].propertyEconomics.appraisalReportedNoiNcf`,
        cleanCurrency(event.target.value),
        "",
        false,
        true
      );
      if (
        !JSON.stringify(changedValueFields).includes("appraisalReportedNoiNcf")
      ) {
        setAppraisalReportedEdited(!appraisalReportedEdited);
        editableFlags[`appraisalReportedEdited${activePropertyId}`] = true;
        changedValueFields.push("appraisalReportedNoiNcf");
      }
    }
    dispatch(
      updatePropertyDetails(propertyId, {
        propertyEconomics: {
          payload: {
            appraisalReported: cleanCurrency(event.target.value)
          }
        }
      })
    );
    if (!isValidCurrency(cleanCurrency(event.target.value))) {
      setAppraisalReportedError("Please enter value in proper currency format");
    } else {
      setAppraisalReportedError("");
    }
  };
  const onAppraisalValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (isEvaluationPanelVisible) {
      prepareCommentObjectProperty(
        `data.properties[${activePropertyId}].propertyEconomics.originalAsIsAppraisalValue`,
        cleanCurrency(event.target.value),
        "",
        false,
        true
      );

      if (
        !JSON.stringify(changedValueFields).includes(
          "originalAsIsAppraisalValue"
        )
      ) {
        setOriginalAsIsAppraisalValueEdited(!originalAsIsAppraisalValueEdited);
        editableFlags[`originalAsIsAppraisalValueEdited${activePropertyId}`] =
          true;
        changedValueFields.push("originalAsIsAppraisalValue");
      }
    }
    dispatch(
      updatePropertyDetails(propertyId, {
        propertyEconomics: {
          payload: {
            originalAppraisalValue: cleanCurrency(event.target.value)
          }
        }
      })
    );

    if (!isValidCurrency(cleanCurrency(event.target.value))) {
      setAppraisalValueError("Please enter value in proper currency format");
    } else {
      setAppraisalValueError("");
    }
  };

  const postPropertyAutoSave = (
    impactingFieldChanged: boolean = false,
    extraData?: any
  ) => {
    if (!isEvaluationPanelVisible) {
      dispatch(setAutoSaveCall(true));
      // const extraneousData = extraData || {};
      const promise2 = postPropertyData(loanId, loanStage, propertyDetails);
      promise2
        .then((response: CreateLoanParamsBridgeLoan) => {
          // dispatch({
          //   type: POST_PROPERTY_SUCCESS,
          //   payload: {
          //     reservation: response
          //   }
          // });
          dispatch(updatePropertySuccess());

          if (impactingFieldChanged && data) {
            getEvaluationRequestBody(
              loanId,
              loanStage,
              data,
              loanEvaluationResult,
              propertyDetails
            )
              .then(({ evaluate, loanEvaluationRequest }) => {
                if (evaluate && loanEvaluationRequest) {
                  dispatch(
                    evaluateLoan(loanEvaluationRequest, loanId, loanStage)
                  );
                }else{
                  dispatch(setAutoSaveCall(false));
                }
              })
              .catch((e) => {
                dispatch(setAutoSaveCall(false));
                console.log("error while sending evaluation request", e);
              });
          }else{
            dispatch(setAutoSaveCall(false));
          }
        })
        .catch((error) => {
          console.log("err", error);
          dispatch(setAutoSaveCall(false));
          // dispatch({ type: POST_PROPERTY_FAILED, error });
          dispatch(
            loanTypeFailed({
              error
            })
          );
        });
    }
  };

  const onRepairedAppraisalValueChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (isEvaluationPanelVisible) {
      prepareCommentObjectProperty(
        `data.properties[${activePropertyId}].propertyEconomics.originalAsRepairedAppraisedValue`,
        cleanCurrency(event.target.value),
        "",
        false,
        true
      );
      if (
        !JSON.stringify(changedValueFields).includes(
          "originalAsRepairedAppraisedValue"
        )
      ) {
        editableFlags[
          `originalRepairedAppraisalValueEdited${activePropertyId}`
        ] = true;
        setoriginalRepairedAppraisalValueEdited(
          !originalRepairedAppraisalValueEdited
        );
        changedValueFields.push("originalAsRepairedAppraisedValue");
      }
    }
    dispatch(
      updatePropertyDetails(propertyId, {
        propertyEconomics: {
          payload: {
            originalRepairedAppraisalValue: cleanCurrency(event.target.value)
          }
        }
      })
    );
    if (!isValidCurrency(cleanCurrency(event.target.value))) {
      setRepairedAppraisalValueError(
        "Please enter value in proper currency format"
      );
    } else {
      setRepairedAppraisalValueError("");
    }
  };
  const onPurchasePriceChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (isEvaluationPanelVisible) {
      prepareCommentObjectProperty(
        `data.properties[${activePropertyId}].propertyEconomics.purchasePrice`,
        cleanCurrency(event.target.value),
        "",
        false,
        true
      );
      if (!JSON.stringify(changedValueFields).includes("purchasePrice")) {
        setPurchasePriceEdit(!purchasePriceEdit);
        editableFlags[`purchasePriceEdit${activePropertyId}`] = true;
        changedValueFields.push("purchasePrice");
      }
    }
    dispatch(
      updatePropertyDetails(propertyId, {
        propertyEconomics: {
          payload: {
            purchasePrice: cleanCurrency(event.target.value)
          }
        }
      })
    );

    if (!isValidCurrency(cleanCurrency(event.target.value))) {
      setPurchasePriceError("Please enter value in proper currency format");
    } else {
      setPurchasePriceError("");
    }
  };

  const saveOnFieldChange = async (changedFieldInput: any, element?: any) => {
    dispatch(showLoader());

    const { DueDiligenceReview } = LoanStatusEnum;
    const isDueDiligenceReviewState =
      !isLATUser() && loanState === DueDiligenceReview;

    if (isLATUser() || isDueDiligenceReviewState) {
      let commentMissing = false;
      // eslint-disable-next-line
      commentObj.map((item: any) => {
        if (item.comment === "") {
          commentMissing = true;
        }
      });
      if (commentMissing || commentObj.length !== changedFieldInput.length) {
        dispatch(editCommentMissing(true));
        dispatch(hideLoader());
        return Promise.resolve("false");
      }
    }
    const propertyInfoAndEconomicsError = computePropertyError(
      { ...propertyDetails, loanRuleVersions },
      loanType,
      loanStage,
      bridgeIsGroundUpLoan,
      loanDetails
    );
    if (propertyInfoAndEconomicsError === 0) {
      let overriddenValuesToUse = overriddenValues;
      const { reRequestWaiverObject, openWaivers } = await getAffectedWaivers(
        changedFieldInput,
        waiversCreated,
        loanId,
        loanStage
      );
      const refreshWaiver =
        (typeof reRequestWaiverObject === "object" &&
          Object.keys(reRequestWaiverObject)?.length) ||
        openWaivers.length;
      // skipping when no partner and loan is of dscr
      // https://toorakcapital.atlassian.net/browse/TA-4313
      if (
        refreshWaiver &&
        (loanType === getLoanType[0].displayValue ||
          (loanType === getLoanType[1].displayValue && takeoutPartnerId))
      ) {
        const resp = await getOverriddenOutputs(
          loanId,
          loanStage,
          takeoutPartnerId
        );
        overriddenValuesToUse = resp?.data;
        dispatch(updateOverriddenInredux(resp));
      }
      postPropertyData(
        loanId,
        loanStage,
        propertyDetails,
        null,
        false,
        true
      ).then((res: any) => {
        dispatch(keepCreditEvalSync(true));
      });
      data &&
        getEvaluationRequestBody(
          loanId,
          loanStage,
          data,
          loanEvaluationResult,
          propertyDetails
        ).then(({ evaluate, loanEvaluationRequest }) => {
          if (evaluate && loanEvaluationRequest) {
            // loanEvaluationRequest.loanFact.loanRuleResult.toorakProceedsTest.totalMarketDataDeductions = 1.2;
            loanEvaluationRequest.ruleAttributs = {
              ruleGroup: [],
              ruleField: [],
              ruleResult: []
            };
            loanEvaluationRequest.resultObject = overriddenValuesToUse;
            dispatch(
              evaluateLoan(
                loanEvaluationRequest,
                loanId,
                loanStage,
                true,
                false,
                propertyDetails,
                refreshWaiver
              )
            );
            dispatch(updateEditComments(`${loanId}_${loanStage}`, commentObj));
            commentObj = [];
          }
        });
      dispatch(hideLoader());
      return Promise.resolve("true");
    }
    dispatch(hideLoader());
    return false;
  };

  globalSaveOnPropertyFieldChange = saveOnFieldChange;


  const onCostBasisChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (isEvaluationPanelVisible) {
      prepareCommentObjectProperty(
        `data.properties[${activePropertyId}].propertyEconomics.costBasis`,
        cleanCurrency(event.target.value),
        "",
        false,
        true
      );
      if (
        !JSON.stringify(changedValueFields).includes(
          "costBasisAfterCostIncurred"
        )
      ) {
        editableFlags[`costBasisEdited${activePropertyId}`] = true;
        setCostBasisEdited(!costBasisEdited);
        changedValueFields.push("costBasisAfterCostIncurred");
      }
    }
    dispatch(
      updatePropertyDetails(propertyId, {
        propertyEconomics: {
          payload: {
            costBasis: cleanCurrency(event.target.value)
          }
        }
      })
    );
    if (isEmptyValue(event.target.value)) {
      if (!bridgePropertyFields.costBasis.optionalIn?.includes(loanStage)) {
        setCostBasisError("This field is required");
      } else {
        setCostBasisError("");
      }
    } else if (!isValidCurrency(cleanCurrency(event.target.value))) {
      setCostBasisError("Please enter value in proper currency format");
    } else {
      setCostBasisError("");
    }
  };

  const onGrossPotentialRentChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (isEvaluationPanelVisible) {
      prepareCommentObjectProperty(
        `data.properties[${activePropertyId}].propertyEconomics.grossPotentialRent`,
        cleanCurrency(event.target.value),
        "",
        false,
        true
      );

      if (!JSON.stringify(changedValueFields).includes("grossPotentialRent")) {
        editableFlags[`potentialRentEdited${activePropertyId}`] = true;
        setPotentialRentEdited(!potentialRentEdited);
        changedValueFields.push("grossPotentialRent");
      }
    }
    dispatch(
      updatePropertyDetails(propertyId, {
        propertyEconomics: {
          payload: {
            grossPotentialRent: cleanCurrency(event.target.value)
          }
        }
      })
    );

    if (!isValidCurrency(cleanCurrency(event.target.value))) {
      setGrossPotentialRentError(
        "Please enter value in proper currency format"
      );
    } else {
      setGrossPotentialRentError("");
    }
  };

  const onAssignmentFeesChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (isEvaluationPanelVisible) {
      prepareCommentObjectProperty(
        `data.properties[${activePropertyId}].propertyEconomics.assignmentFees`,
        cleanCurrency(event.target.value),
        "",
        false,
        true
      );

      if (!JSON.stringify(changedValueFields).includes("assignmentFees")) {
        editableFlags[`assignmentFeesEdited${activePropertyId}`] = true;
        setassignmentFeesEdited(!assignmentFeesEdited);
        changedValueFields.push("assignmentFees");
      }
    }
    dispatch(
      updatePropertyDetails(propertyId, {
        propertyEconomics: {
          payload: {
            assignmentFees: cleanCurrency(event.target.value)
          }
        }
      })
    );

    if (!isValidCurrency(cleanCurrency(event.target.value))) {
      setAssignmentFeesError("Please enter value in proper currency format");
    } else {
      setAssignmentFeesError("");
    }
  };

  const onAnnualPropertyTaxesChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (isEvaluationPanelVisible) {
      prepareCommentObjectProperty(
        `data.properties[${activePropertyId}].propertyEconomics.annualPropertyTaxes`,
        cleanCurrency(event.target.value),
        "",
        false,
        true
      );
      if (!JSON.stringify(changedValueFields).includes("annualPropertyTaxes")) {
        editableFlags[`annualTaxEdited${activePropertyId}`] = true;
        setAnnualTaxEdited(!annualTaxEdited);
        changedValueFields.push("annualPropertyTaxes");
      }
    }
    dispatch(
      updatePropertyDetails(propertyId, {
        propertyEconomics: {
          payload: {
            annualPropertyTaxes: cleanCurrency(event.target.value)
          }
        }
      })
    );

    if (!isValidCurrency(cleanCurrency(event.target.value))) {
      setAnnualPropertyTaxesError(
        "Please enter value in proper currency format"
      );
    } else {
      setAnnualPropertyTaxesError("");
    }
  };

  const handleFieldChange = (value: any, fieldId: any) => {
    const sanitizedValue = sanitizeValueByType(
      value,
      bridgePropertyFields?.[fieldId]?.fieldTpe
    );
    if (isEvaluationPanelVisible) {
      prepareCommentObjectProperty(
        `data.properties[${activePropertyId}].propertyEconomics.${fieldId}`,
        sanitizedValue,
        "",
        false,
        true
      );
      if (!JSON.stringify(changedValueFields).includes(fieldId)) {
        editableFlags[`${fieldId}Edited${activePropertyId}`] = true;
        changedValueFields.push(fieldId);
      }
    }
    dispatch(
      updatePropertyDetails(propertyId, {
        propertyEconomics: {
          payload: {
            [fieldId]: sanitizedValue
          }
        }
      })
    );

    if (checkFieldValidity(sanitizedValue, bridgePropertyFields[fieldId])) {
      setFieldError((prev) => ({
        ...prev,
        [fieldId]: "Please enter value in proper format"
      }));
    } else {
      setFieldError((prev) => ({
        ...prev,
        [fieldId]: ""
      }));
    }
  };
  const onInsuranceChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (isEvaluationPanelVisible) {
      prepareCommentObjectProperty(
        `data.properties[${activePropertyId}].propertyEconomics.insurance`,
        cleanCurrency(event.target.value),
        "",
        false,
        true
      );

      if (!JSON.stringify(changedValueFields).includes("insurance")) {
        editableFlags[`insuranceEdited${activePropertyId}`] = true;
        setInsuranceEdited(!insuranceEdited);
        changedValueFields.push("insurance");
      }
    }
    dispatch(
      updatePropertyDetails(propertyId, {
        propertyEconomics: {
          payload: {
            insurance: cleanCurrency(event.target.value)
          }
        }
      })
    );

    if (!isValidCurrency(cleanCurrency(event.target.value))) {
      setInsuranceError("Please enter value in proper currency format");
    } else {
      setInsuranceError("");
    }
  };

  function closeForm() {
    setEditable(!isEditable);
    changedValueFields = [];
    setAnnualTaxEdited(false);
    setoriginalRepairedAppraisalValueEdited(false);
    setOriginalAsIsAppraisalValueEdited(false);
    setPotentialRentEdited(false);
    setCostBasisEdited(false);
    setPurchasePriceEdit(false);
    setInsuranceEdited(false);
  }

  const updatePreviousLoanState = () => {
    originalPropertyEconomics = updatePreviousLoanStateAction(
      propertyDetails,
      activePropertyId,
      "propertyEconomics"
    );
  };

  const handleServiceFlowRender = (field: string): boolean => {
    return (
      !isMandatoryServicerField ||
      (isMandatoryServicerField && isMandatoryServicerField(field))
    );
  };

  return (
    <div className={classes.container}>
      {/* Minimize Section start */}
      <Grid container spacing={3} className={classes.sectionWrapper}>
        <Grid item xs={12} container>
          <Grid item xs={6} sm={11}>
            <Typography className={classes.headingTypography}>
              Property {index} Economics
            </Typography>
          </Grid>
          <Grid item xs={6} sm={1} className={classes.headingImageContainer}>
            {isAllowedToEdit && (
              <img
                className={
                  editableFlags[`editProp${activePropertyId}`] ||
                  !isEvaluationPanelVisible
                    ? classes.editImageDisplay
                    : classes.editImage
                }
                src={editImage}
                alt="Edit Section"
                onClick={() => {
                  for (const i in editableFlags) editableFlags[i] = false;
                  updatePreviousLoanState();
                  dispatch(valueEditing(true));
                  setEditable(!isEditable);
                  editableFlags[`editProp${activePropertyId}`] = true;
                }}
                onKeyDown={() => {
                  dispatch(valueEditing(true));
                  updatePreviousLoanState();
                  setEditable(!isEditable);
                  editableFlags[`editProp${activePropertyId}`] = true;
                }}
              />
            )}
            <Tooltip title={isMinimized ? "Expand" : "Collapse"}>
              <img
                className={classes.headingImage}
                src={isMinimized ? iconMaximize : iconMinimize}
                alt={isMinimized ? "Maximize section" : "Minimize section"}
                onClick={() => {
                  setMinimized(!isMinimized);
                }}
                onKeyDown={() => {
                  setMinimized(!isMinimized);
                }}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      {/* Minimize Section end */}
      {/* Divider Section start */}
      <Grid
        item
        xs={12}
        sm={12}
        style={{
          display: isMinimized ? "none" : ""
        }}
      >
        <div className={classes.divider} />
      </Grid>
      {/* Divider Section end */}
      <Grid
        container
        spacing={4}
        style={{
          padding: 24,
          paddingTop: isMandatoryServicerField ? 0 : 24,
          marginTop: isMandatoryServicerField ? "-26px" : "-16px",
          display: isMinimized ? "none" : ""
        }}
      >
        {handleServiceFlowRender("originalAsIsAppraisalValue") && (
          <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
            <OriginalAppraisalValueTextField
              isViewMode={
                isEvaluationPanelVisible &&
                !editableFlags[`editProp${activePropertyId}`]
              }
              isServicerMandatory={
                isMandatoryServicerField &&
                isMandatoryServicerField("originalAsIsAppraisalValue")
              }
              value={propertyEconomics!.originalAppraisalValue}
              onChange={onAppraisalValueChange}
              error={validateForm.validate && appraisalValueError !== ""}
              helperText={validateForm.validate ? appraisalValueError : ""}
              onBlur={handleBlur("originalAppraisalValue", "currency")}
              editedLabel={
                editableFlags[
                  `originalAsIsAppraisalValueEdited${activePropertyId}`
                ]
              }
              enableReason={enableReasonField(
                changedValueFields,
                "originalAsIsAppraisalValue",
                loanState
              )}
              editedData={editHistoryValue(
                editHistory2[
                  `data.properties[${activePropertyId}].propertyEconomics.originalAsIsAppraisalValue`
                ],
                "currency"
              )}
              infoIconData={
                infoContent &&
                infoContent[
                  `fieldInfo.bridge.${loanStage.toLowerCase()}.properties.propertyEconomics.originalAsIsAppraisalValue`
                ]
              }
            />
          </Grid>
        )}
        {handleServiceFlowRender("originalAsRepairedAppraisedValue") && (
          <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
            <OriginalAppraisalRepairedValueTextField
              isViewMode={
                isEvaluationPanelVisible &&
                !editableFlags[`editProp${activePropertyId}`]
              }
              isServicerMandatory={
                isMandatoryServicerField &&
                isMandatoryServicerField("originalAsRepairedAppraisedValue")
              }
              value={propertyEconomics!.originalRepairedAppraisalValue}
              onChange={onRepairedAppraisalValueChange}
              error={
                validateForm.validate && repairedAppraisalValueError !== ""
              }
              helperText={
                validateForm.validate ? repairedAppraisalValueError : ""
              }
              onBlur={handleBlur("originalRepairedAppraisalValue", "currency")}
              editedLabel={
                editableFlags[
                  `originalRepairedAppraisalValueEdited${activePropertyId}`
                ]
              }
              enableReason={enableReasonField(
                changedValueFields,
                "originalRepairedAppraisalValue",
                loanState
              )}
              editedData={editHistoryValue(
                editHistory2[
                  `data.properties[${activePropertyId}].propertyEconomics.originalAsRepairedAppraisedValue`
                ],
                "currency"
              )}
              infoIconData={
                infoContent &&
                infoContent[
                  `fieldInfo.bridge.${loanStage.toLowerCase()}.properties.propertyEconomics.originalAsRepairedAppraisedValue`
                ]
              }
            />
          </Grid>
        )}
        {handleServiceFlowRender("purchasePrice") && (
          <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
            <PurchasePriceTextField
              isViewMode={
                isEvaluationPanelVisible &&
                !editableFlags[`editProp${activePropertyId}`]
              }
              isServicerMandatory={
                isMandatoryServicerField &&
                isMandatoryServicerField("purchasePrice")
              }
              value={propertyEconomics!.purchasePrice}
              onChange={onPurchasePriceChange}
              error={validateForm.validate && purchasePriceError !== ""}
              helperText={validateForm.validate ? purchasePriceError : ""}
              onBlur={handleBlur("purchasePrice", "currency")}
              editedLabel={
                editableFlags[`purchasePriceEdit${activePropertyId}`]
              }
              editedData={editHistoryValue(
                editHistory2[
                  `data.properties[${activePropertyId}].propertyEconomics.purchasePrice`
                ],
                "currency"
              )}
              enableReason={enableReasonField(
                changedValueFields,
                "purchasePrice",
                loanState
              )}
              infoIconData={
                infoContent &&
                infoContent[
                  `fieldInfo.bridge.${loanStage.toLowerCase()}.properties.propertyEconomics.purchasePrice`
                ]
              }
            />
          </Grid>
        )}
        {handleServiceFlowRender("costBasis") && (
          <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
            <CostBasisTextField
              required={
                !bridgePropertyFields.costBasis.optionalIn?.includes(loanStage)
              }
              isViewMode={
                isEvaluationPanelVisible &&
                !editableFlags[`editProp${activePropertyId}`]
              }
              isServicerMandatory={
                isMandatoryServicerField &&
                isMandatoryServicerField("costBasis")
              }
              value={propertyEconomics!.costBasis}
              onChange={onCostBasisChange}
              error={validateForm.validate && costBasisError !== ""}
              helperText={validateForm.validate ? costBasisError : ""}
              onBlur={handleBlur("costBasis", "currency")}
              editedLabel={editableFlags[`costBasisEdited${activePropertyId}`]}
              enableReason={enableReasonField(
                changedValueFields,
                "costBasis",
                loanState
              )}
              editedData={editHistoryValue(
                editHistory2[
                  `data.properties[${activePropertyId}].propertyEconomics.costBasis`
                ],
                "currency"
              )}
              infoIconData={
                infoContent &&
                infoContent[
                  `fieldInfo.bridge.${loanStage.toLowerCase()}.properties.propertyEconomics.costBasis`
                ]
              }
            />
          </Grid>
        )}
        {handleServiceFlowRender("grossPotentialRent") && (
          <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
            <GrossPotentialRentTextField
              isViewMode={
                isEvaluationPanelVisible &&
                !editableFlags[`editProp${activePropertyId}`]
              }
              isServicerMandatory={
                isMandatoryServicerField &&
                isMandatoryServicerField("grossPotentialRent")
              }
              value={propertyEconomics!.grossPotentialRent}
              onChange={onGrossPotentialRentChange}
              error={validateForm.validate && grossPotentialRentError !== ""}
              helperText={validateForm.validate ? grossPotentialRentError : ""}
              onBlur={handleBlur("grossPotentialRent", "currency")}
              editedLabel={
                editableFlags[`potentialRentEdited${activePropertyId}`]
              }
              enableReason={enableReasonField(
                changedValueFields,
                "grossPotentialRent",
                loanState
              )}
              editedData={editHistoryValue(
                editHistory2[
                  `data.properties[${activePropertyId}].propertyEconomics.grossPotentialRent`
                ],
                "currency"
              )}
              infoIconData={
                infoContent &&
                infoContent[
                  `fieldInfo.bridge.${loanStage.toLowerCase()}.properties.propertyEconomics.grossPotentialRent`
                ]
              }
            />
          </Grid>
        )}
        {handleServiceFlowRender("assignmentFees") && (
          <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
            <AssignmentFeesTextField
              isViewMode={
                isEvaluationPanelVisible &&
                !editableFlags[`editProp${activePropertyId}`]
              }
              value={propertyEconomics!.assignmentFees ?? ""}
              onChange={onAssignmentFeesChange}
              error={validateForm.validate && assignmentFeesError !== ""}
              helperText={validateForm.validate ? assignmentFeesError : ""}
              onBlur={handleBlur("assignmentFees", "currency")}
              editedLabel={
                editableFlags[`assignmentFeesEdited${activePropertyId}`]
              }
              enableReason={enableReasonField(
                changedValueFields,
                "assignmentFees",
                loanState
              )}
              editedData={editHistoryValue(
                editHistory2[
                  `data.properties[${activePropertyId}].propertyEconomics.assignmentFees`
                ],
                "currency"
              )}
              infoIconData={
                infoContent &&
                infoContent[
                  `fieldInfo.bridge.${loanStage.toLowerCase()}.properties.propertyEconomics.assignmentFees`
                ]
              }
            />
          </Grid>
        )}
        {handleServiceFlowRender("annualPropertyTaxes") && (
          <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
            <AnnualPropertyTaxesTextField
              isViewMode={
                isEvaluationPanelVisible &&
                !editableFlags[`editProp${activePropertyId}`]
              }
              isServicerMandatory={
                isMandatoryServicerField &&
                isMandatoryServicerField("annualPropertyTaxes")
              }
              value={propertyEconomics!.annualPropertyTaxes}
              onChange={onAnnualPropertyTaxesChange}
              error={validateForm.validate && annualPropertyTaxesError !== ""}
              helperText={validateForm.validate ? annualPropertyTaxesError : ""}
              onBlur={handleBlur("annualPropertyTaxes", "currency")}
              editedLabel={editableFlags[`annualTaxEdited${activePropertyId}`]}
              editedData={editHistoryValue(
                editHistory2[
                  `data.properties[${activePropertyId}].propertyEconomics.annualPropertyTaxes`
                ],
                "currency"
              )}
              enableReason={enableReasonField(
                changedValueFields,
                "annualPropertyTaxes",
                loanState
              )}
              infoIconData={
                infoContent &&
                infoContent[
                  `fieldInfo.bridge.${loanStage.toLowerCase()}.properties.propertyEconomics.annualPropertyTaxes`
                ]
              }
            />
          </Grid>
        )}
        {handleServiceFlowRender("insurance") && (
          <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
            <InsuranceTextField
              isViewMode={
                isEvaluationPanelVisible &&
                !editableFlags[`editProp${activePropertyId}`]
              }
              isServicerMandatory={
                isMandatoryServicerField &&
                isMandatoryServicerField("insurance")
              }
              value={propertyEconomics!.insurance}
              onChange={onInsuranceChange}
              error={validateForm.validate && insuranceError !== ""}
              helperText={validateForm.validate ? insuranceError : ""}
              onBlur={handleBlur("insurance", "currency")}
              editedLabel={editableFlags[`insuranceEdited${activePropertyId}`]}
              editedData={editHistoryValue(
                editHistory2[
                  `data.properties[${activePropertyId}].propertyEconomics.insurance`
                ],
                "currency"
              )}
              enableReason={enableReasonField(
                changedValueFields,
                "insurance",
                loanState
              )}
              infoIconData={
                infoContent &&
                infoContent[
                  `fieldInfo.bridge.${loanStage.toLowerCase()}.properties.propertyEconomics.insurance`
                ]
              }
            />
          </Grid>
        )}
        {!isHidden("appraisalReported", loanDetails) &&
          handleServiceFlowRender("appraisalReported") && (
            <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
              <AppraisalReportedTextField
                value={propertyEconomics!.appraisalReported}
                onChange={onAppraisalReportedChange}
                error={validateForm.validate && appraisalReportedError !== ""}
                helperText={validateForm.validate ? appraisalReportedError : ""}
                onBlur={handleBlur("appraisalReported", "currency")}
                isViewMode={
                  isEvaluationPanelVisible &&
                  !editableFlags[`editProp${activePropertyId}`]
                }
                editedLabel={
                  editableFlags[`appraisalReportedEdited${activePropertyId}`]
                }
                enableReason={enableReasonField(
                  changedValueFields,
                  "appraisalReportedNoiNcf",
                  loanState
                )}
                editedData={editHistoryValue(
                  editHistory2[
                    `data.properties[${activePropertyId}].propertyEconomics.appraisalReportedNoiNcf`
                  ],
                  "currency"
                )}
                infoIconData={
                  infoContent &&
                  infoContent[
                    `fieldInfo.bridge.${loanStage.toLowerCase()}.properties.propertyEconomics.appraisalReportedNoiNcf`
                  ]
                }
              />
            </Grid>
          )}
        {!isHidden("annualHoaFee", loanDetails) &&
          handleServiceFlowRender("annualHoaFee") && (
            <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
              <AnnualHOAFeeTextField
                isViewMode={
                  isEvaluationPanelVisible &&
                  !editableFlags[`editProp${activePropertyId}`]
                }
                isServicerMandatory={
                  isMandatoryServicerField &&
                  isMandatoryServicerField("annualHoaFee")
                }
                value={propertyEconomics!.annualHoaFee}
                onChange={(event: any) => {
                  handleFieldChange(event.target.value, "annualHoaFee");
                }}
                error={
                  validateForm.validate &&
                  !isEmptyValue(fieldError.annualHoaFee)
                }
                helperText={
                  validateForm.validate ? fieldError.annualHoaFee : ""
                }
                onBlur={handleBlur("annualHoaFee", "currency")}
                editedLabel={
                  editableFlags[`annualHoaFeeEdited${activePropertyId}`]
                }
                editedData={editHistoryValue(
                  editHistory2[
                    `data.properties[${activePropertyId}].propertyEconomics.annualHoaFee`
                  ],
                  "currency"
                )}
                enableReason={enableReasonField(
                  changedValueFields,
                  "annualHoaFee",
                  loanState
                )}
                infoIconData={
                  infoContent &&
                  infoContent[
                    `fieldInfo.bridge.${loanStage.toLowerCase()}.properties.propertyEconomics.annualHoaFee`
                  ]
                }
              />
            </Grid>
          )}
        {!isHidden("rentalCashflowRatio", loanDetails) &&
          handleServiceFlowRender("rentalCashflowRatio") && (
            <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
              <RentalCashflowRatioTextField
                isViewMode={
                  isEvaluationPanelVisible &&
                  !editableFlags[`editProp${activePropertyId}`]
                }
                isServicerMandatory={
                  isMandatoryServicerField &&
                  isMandatoryServicerField("rentalCashflowRatio")
                }
                value={propertyEconomics!.rentalCashflowRatio}
                onChange={(event: any) => {
                  handleFieldChange(event.target.value, "rentalCashflowRatio");
                }}
                error={
                  validateForm.validate &&
                  !isEmptyValue(fieldError.rentalCashflowRatio)
                }
                helperText={
                  validateForm.validate ? fieldError.rentalCashflowRatio : ""
                }
                onBlur={handleBlur("rentalCashflowRatio", "percentage")}
                editedLabel={
                  editableFlags[`rentalCashflowRatioEdited${activePropertyId}`]
                }
                editedData={editHistoryValue(
                  editHistory2[
                    `data.properties[${activePropertyId}].propertyEconomics.rentalCashflowRatio`
                  ],
                  "percentage"
                )}
                enableReason={enableReasonField(
                  changedValueFields,
                  "rentalCashflowRatio",
                  loanState
                )}
                infoIconData={
                  infoContent &&
                  infoContent[
                    `fieldInfo.bridge.${loanStage.toLowerCase()}.properties.propertyEconomics.rentalCashflowRatio`
                  ]
                }
              />
            </Grid>
          )}
        {editableFlags[`editProp${activePropertyId}`] && (
          <Grid container item xs={12} sm={12} md={12} xl={12}>
            <Button
              variant="contained"
              color="info"
              // data-testid="save-button"
              className={
                editableFlags[`editProp${activePropertyId}`]
                  ? classes.savebutton
                  : classes.editImageDisplay
              }
              // startIcon={<SaveOutlinedIcon />}
              disabled={changedValueFields.length === 0}
              onClick={() => {
                handleOpen();

                //              saveOnFieldChange();
                //          changedValueFields = [];
              }}
              // onClick={() => {
              //   dispatch(validateForm());
              // }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="info"
              // data-testid="save-button"
              className={
                editableFlags[`editProp${activePropertyId}`]
                  ? classes.cancelButton
                  : classes.editImageDisplay
              }
              // startIcon={<SaveOutlinedIcon />}
              // onClick={handleSave}
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </Button>
          </Grid>
        )}
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="duplicate-property-modal"
        aria-describedby="duplicate-property-modal-description"
      >
        <div style={{ padding: 20, width: 500, maxWidth: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexGrow: 1,
              alignItems: "center"
            }}
          >
            <div style={{ fontSize: 14, fontWeight: 600, color: "#32325d" }}>
              Warning
            </div>
            <CloseIcon
              htmlColor="#414169"
              style={{ width: 16, height: 16, cursor: "pointer" }}
              onClick={() => handleClose()}
            />
          </div>
          <div
            style={{
              fontSize: 12,
              lineHeight: 1.5,
              color: "#32325d",
              marginTop: 40
            }}
          >
            {requestWaiverMessage}
          </div>
          <div className={classes.modalDeleteButtonConatainer}>
            <Button
              variant="contained"
              color="primary"
              data-testid="modal-delete-button"
              className={classes.modalButton}
              onClick={() => {
                handleClose();
                if (errors === 0) {
                  dispatch(validateFormCheck(true));
                  handleClose();
                  globalSaveOnPropertyFieldChange(
                    changedValueFields,
                    propertyEconomics
                  ).then((res: any) => {
                    if (res !== "false") {
                      dispatch(valueEditing(false));
                      for (const i in editableFlags) editableFlags[i] = false;
                      closeForm();
                      changedValueFields = [];
                      // updatePreviousLoanState();
                    }
                  });
                }
              }}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              data-testid="modal-cancel-button"
              className={classes.modalCancelButton}
              onClick={() => {
                onCancel();
                handleClose();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
