import React, { useContext, useMemo } from "react";
import {
  Grid,
  Table as MuiTable,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Radio,
  Typography
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import moment from "moment";
import Table from "../../masterView/CustomTable";
import { ObjectType } from "../../masterView/common";
import { labelFieldName30year } from "../guideline-outputs/ToorakProceedsFields";
import { getLoanPricingFields } from "../guideline-outputs/LoanPricingFields";
import { GuidlinesComparisionContext } from "./PricingAndGuidlinesSection";
import { GuidlinesComparisionContextForDashboard } from "../../take-out-partners/TKOPModalContainer";
import { ZeroStateLoan } from "../loan-lists/DetailsView/ZeroStateLoan";
import { MasterViewStyles } from "../../masterView/styles";
import { getOriginatorLabel } from "./ResultsSummaryCard";
import { isLATUser } from "../../utils/AccessManagement";
import { BasicDropDown } from "../../ui-components/BasicDropDown";
import CustomChip from "./program-partner-selection/CustomChip";
import CheckCirclePass from "../../images/createloan/CheckCirclePass.svg";
import FailedIcon from "../../images/createloan/FailedTestIcon.svg";
import CriticalIcon from "../../images/createloan/CriticalFailedTestIcon.svg";
import LoaderSvg from "../../icons/Loader.svg";

const chipClasses = {
  failedChip: {
    background: "#DEBB01",
    color: "#ffffff",
    fontSize: "10px",
    borderRadius: "6px",
    padding: "0px",
    marginLeft: "4px"
  },
  criticalFailed: {
    background: "#F75676",
    color: "#ffffff",
    fontSize: "10px",
    borderRadius: "6px",
    padding: "0px"
  }
}

const largestDataPoint = '300px';

export const rateLockFields = [
  {
    label: "Pre/Post Close Rate Lock Expiry",
    field: "rateLockExpiryDate,rateLockPeriod"
  },
  {
    label: "Rate Adj. - Lock Period",
    field: "rateLockAdjustment"
  }
];

export const getEligiblePartnersCols = (partnerList: ObjectType[], hideEmptyCell: boolean = false) => {
  const emptyCell: any = {
    id: "empty",
    title: "",
    width: `${largestDataPoint}`,
    headStyle: {
      padding: "0rem",
      fontWeight: "600",
      background: "#fff",
      borderRight: "0px solid #dedede"
    }
  }
  const cols: ObjectType[] = [];
  if (!hideEmptyCell) {
    cols.push(emptyCell)
  }
  // eslint-disable-next-line no-unused-expressions
  partnerList?.forEach((partnerData: ObjectType, index: number) => {
    const partnerId = partnerData.key || partnerData.id;
    cols.push({
      id: partnerId,
      title: partnerData.label,
      // width: `calc((100% - ${hideEmptyCell ? 0 : largestDataPoint}) / ${partnerList.length})`,
      width: `calc((100%${hideEmptyCell ? "" : " - " + largestDataPoint}) / ${partnerList.length})`,
      "data-testid": `partner-pricing-data-${partnerData.label}`,
      headStyle: {
        padding: "1rem",
        fontWeight: "600",
        textAlign: "center",
        background: "#fff",
        borderRight: "1px solid #dedede"
      }
    });
  }
  );
  return cols;
};

export const getfinalPricingCols = (partnerList: ObjectType[]) => {
  const cols: ObjectType = [
    {
      id: "label",
      title: "Final Toorak Pricing",
      "data-testid": "final-toorak-pricing",
      width: `${largestDataPoint}`,
      headStyle: {
        padding: "1rem",
        fontWeight: "600"
      },
      style: {
        padding: "1rem",
        borderRight: "1px solid #dedede"
      }
    }
  ];

  partnerList.forEach((partnerData: ObjectType) => {
    const partnerId = partnerData.key || partnerData.id;
    cols.push({
      id: partnerId,
      "data-testid": `final-toorak-pricing-${partnerId}`,
      title: "",
      width: `calc((100% - ${largestDataPoint}) / ${partnerList.length})`,
      headStyle: {
        padding: "1rem",
        fontWeight: "600"
      },
      style: {
        padding: "1rem",
        borderRight: "1px solid #dedede",
        textAlign: "center"
      }
    })
  }
  );
  return cols;
};

export const getPricingAdjustmentsCols = (partnerList: ObjectType[]) => {
  const cols: ObjectType = [
    {
      id: "label",
      title: "Pricing Adjustments",
      "data-testid": "pricing-adjustment",
      width: `${largestDataPoint}`,
      headStyle: {
        padding: "1rem",
        fontWeight: "600"
      },
      style: {
        padding: "1rem",
        borderRight: "1px solid #dedede"
      }
    }
  ];
  partnerList.forEach((partnerData: ObjectType, index: number) => {
    const partnerId = partnerData.key || partnerData.id;
    cols.push({
      id: partnerId,
      title: "",
      "data-testid": `pricing-adjustment-${partnerId}`,
      width: `calc((100% - ${largestDataPoint}) / ${partnerList.length})`,
      headStyle: {
        padding: "1rem",
        fontWeight: "600"
      },
      style: {
        padding: "1rem",
        borderRight: "1px solid #dedede",
        textAlign: "center"
      }
    })
  }
  );
  return cols;
};

export const getRateLockCols = (partnerList: ObjectType[]) => {
  const cols: ObjectType = [
    {
      id: "label",
      title: "Initial lock term cost",
      "data-testid": "rate-lock-adjustment",
      width: `${largestDataPoint}`,
      headStyle: {
        padding: "1rem",
        fontWeight: "600"
      },
      style: {
        padding: "1rem",
        borderRight: "1px solid #dedede"
      }
    }
  ];
  partnerList.forEach((partnerData: ObjectType) => {
    const partnerId = partnerData.key || partnerData.id;
    cols.push({
      id: partnerId,
      title: "",
      "data-testid": `rate-lock-adjustment-${partnerId}`,
      width: `calc((100% - ${largestDataPoint}) / ${partnerList.length})`,
      headStyle: {
        padding: "1rem",
        fontWeight: "600"
      },
      style: {
        padding: "1rem",
        borderRight: "1px solid #dedede",
        textAlign: "center"
      }
    })
  }

  );
  return cols;
};

const getRateLockExpiryDate = (
  rateLockExpiryDate: number,
  lockInPeriod: number,
  extendByDays: number = 0
) => {
  const expiry = moment
    .utc(rateLockExpiryDate)
    .add(parseInt(`${extendByDays}`, 10), "days");
  const expiryDate = `${expiry.format("ddd, MMM DD, YYYY")}`;

  return `${expiryDate} | ${lockInPeriod} Days`;
};

export const getRowsData = (fields: ObjectType[], partnersList: ObjectType[]) => {
  return fields
    .filter(
      (fieldVal: ObjectType) =>
        (fieldVal?.hideCondition && fieldVal?.hideCondition()) ||
        !fieldVal?.hideCondition
    )
    .map((fieldData: ObjectType) => {
      let rowData: ObjectType = {
        label: fieldData.label
      };
      partnersList?.forEach((partnerData: ObjectType) => {
        const partnerId = partnerData.key || partnerData.id;
        rowData = {
          ...rowData,
          [partnerId]:
            (fieldData.label !== "Pre/Post Close Rate Lock Expiry"
              ? partnerData.data?.[fieldData.field]
              : partnerData.data?.[fieldData.field.split(",")[0]] &&
              getRateLockExpiryDate(
                partnerData.data?.[fieldData.field.split(",")[0]],
                Number.parseInt(
                  partnerData.data?.[fieldData.field.split(",")[1]] || 0,
                  10
                )
              )) ?? "--"
        };
      });
      return rowData;
    });
};

export const PricingComparision = (props: any) => {
  const { programSelectConfig, partnersData } = props;
  const {
    isLockPopUp,
    currentSelectedPartner,
    setCurrentSelectedPartner,
    eligibleTakeOutPartnerResult,
    takeOutPartnerLists,
    loansDropdownArray,
    handleLoanIdChange,
    showPricingOnlyForEligible = false,
  } = programSelectConfig;
  const { dailyCreditLimit, eligiblePartners } = eligibleTakeOutPartnerResult ?? {};

  const context1 = useContext(GuidlinesComparisionContext);
  const context2 = useContext(GuidlinesComparisionContextForDashboard);

  const rateSheetType = context1?.rateSheetType ?? context2?.rateSheetType;
  const sortedPartnersData: any = partnersData?.sort((partner1: any, partner2: any) => partner1.criticalFailed - partner2.criticalFailed) || [];

  const { filteredPartners, ineligiblePartners } = useMemo(() => {
    if (!showPricingOnlyForEligible) {
      return { filteredPartners: sortedPartnersData, ineligiblePartners: [] };
    }
    const ineligiblePartners = sortedPartnersData?.filter((partnerData: ObjectType) => partnerData.criticalFailed > 0);
    const filteredPartners = sortedPartnersData?.filter((partnerData: ObjectType) => partnerData.criticalFailed === 0);
    return { filteredPartners, ineligiblePartners };
  }, [showPricingOnlyForEligible, sortedPartnersData])

  const eligiblePartnersColumns = getEligiblePartnersCols(sortedPartnersData, false);
  const finalPricingCols = getfinalPricingCols(filteredPartners);
  const pricingAdjustmentsCols = getPricingAdjustmentsCols(filteredPartners);
  const rateLockCols = getRateLockCols(partnersData);

  const classes = MasterViewStyles();

  const gridTemplate = useMemo(() => {
    return eligiblePartnersColumns?.reduce((prev: string, next: any) => {
      return `${prev}${next?.width ? `${next.width}px ` : "1fr "}`;
    }, "");
  }, [eligiblePartnersColumns]);

  const EligiblePartnersTableRow = (props: any) => {
    let cumulativeWidth = 0;
    return (
      <TableRow
        component="div"
        style={{
          gridTemplateColumns: `${gridTemplate}`,
          display: "flex"
        }}
      >
        {eligiblePartnersColumns?.map((col: any, index: number) => {
          const currentPartnerData = partnersData.find((partner: any) => partner?.id === col?.id);
          if (col?.frozen) {
            if (index !== 0) {
              cumulativeWidth += eligiblePartnersColumns[index - 1].width;
            }
          }
          return (
            <TableCell
              component="div"
              key={col.id}
              className={`${col?.headClass ?? ""}`}
              style={{
                ...col?.headStyle,
                ...(col?.frozen && {
                  left: cumulativeWidth,
                  zIndex: 1
                }),
                width: col.width || "auto",
              }}
            >
              {col.title ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>
                    {(!showPricingOnlyForEligible || (showPricingOnlyForEligible && currentPartnerData.criticalFailed === 0)) && <Radio
                      checked={
                        [
                          currentSelectedPartner?.partnerId,
                          currentSelectedPartner?.assignedPartner
                        ].includes(col?.id) &&
                        eligibleTakeOutPartnerResult?.dailyCreditLimit[col?.id]?.isWithinLimit
                      }
                      disabled={!eligibleTakeOutPartnerResult?.dailyCreditLimit[col?.id]?.isWithinLimit}
                      onChange={(e) => {
                        // if (currentPartnerData?.failedCount || currentPartnerData?.criticalCount) {
                        //   handleViewChange("testResult");
                        // } 
                          setCurrentSelectedPartner({
                            partnerId: col?.id,
                            assignedPartner: isLATUser()
                            ? col.title
                            : getOriginatorLabel(col?.title, takeOutPartnerLists)
                          });
                      }}
                    />}
                    {isLATUser() ? col.title : getOriginatorLabel(col?.title, takeOutPartnerLists)}
                    <div>
                      {currentPartnerData?.criticalFailed && CustomChip ? <CustomChip
                        size="small"
                        label={
                          <div className="div-space-between">
                            <img
                              src={FailedIcon}
                              alt="info-icon"
                              className="mr-8"
                            />
                            <span style={{ fontSize: "12px", color: "white" }}>{` Critical Fails - ${currentPartnerData?.criticalFailed}`} {/* ${test[Object.keys(test)[0]]?.length} */}</span>
                          </div>
                        }
                        variant="outlined"
                        className={chipClasses.criticalFailed}
                      /> : null}
                      {currentPartnerData?.failedCount && CustomChip ? <CustomChip
                        size="small"
                        label={
                          <div className="div-space-between">
                            <img
                              src={CriticalIcon}
                              alt="info-icon"
                              className="mr-8"
                            />{" "}
                            <span style={{ fontSize: "12px", color: "white" }}>{`Non-Critical Fails - ${currentPartnerData?.failedCount - currentPartnerData?.criticalFailed}`} {/* ${test[Object.keys(test)[0]]?.length} */}</span>
                          </div>
                        }
                        variant="outlined"
                        className={chipClasses.failedChip}
                      /> : null}
                    </div>

                    {!(currentPartnerData?.criticalFailed || currentPartnerData?.failedCount) ? <img
                      src={CheckCirclePass}
                      alt="check-pass-circle"
                      className="mr-8"
                    /> : null}
                    {!eligibleTakeOutPartnerResult?.dailyCreditLimit[col?.id]?.isWithinLimit && (
                      <Grid style={{ color: "red" }}>
                        {"  "}
                        *Daily credit limit reached
                      </Grid>
                    )}
                  </div>
                </div>
              ) : (
                <BasicDropDown
                  // selected={selected}
                  selected={loansDropdownArray[0].value}
                  handleChange={handleLoanIdChange}
                  menuOptions={loansDropdownArray}
                  selectStyles={{ fontSize: "12px", width: "100%", height: "100%", borderRadius: "0px", boxShadow: "none !important", border: "none !important" }}
                  dropDownLabel={""}
                  width="100%"
                  height="100%"
                />
              )}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  const tableWidth: string = useMemo(() => {
    if (filteredPartners.length === partnersData.length) {
      return "100%";
    }
    const ratio = filteredPartners.length / partnersData.length;
    return `calc( 100% * ${ratio} + ${largestDataPoint} * ${1 - ratio})`;
  }, [filteredPartners, partnersData])

  return (
    <>
      {partnersData?.length !== 0 && (
        <>
          {isLockPopUp && eligiblePartners && eligiblePartners.length > 1 && (
            <Grid>
              <Grid
                style={{
                  display: "flex",
                  border: "1px solid #dedede",
                  borderBottom: "none",
                  alignItems: "center",
                  backgroundColor: "#fffcf0",
                  padding: "8px",
                  marginLeft: "299px",
                  marginTop: "20px"
                }}
              >
                <InfoOutlinedIcon
                  style={{ width: 15, height: 15, }}
                  htmlColor="#32325d"
                />
                <Typography
                  variant="h4"
                  style={{
                    fontSize: 12,
                    color: "#32325d",
                    paddingLeft: 5,
                    lineHeight: 1.6,
                    // paddingTop:"10px"
                  }}
                >
                  By default, we have selected the Best Pricing for the
                  loan, but you can click on the Program Name to switch
                  between them.
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid
            data-testid="pricing-comparision"
            style={{
              // marginTop: "1rem",
              borderTop: "1px solid #dedede",
              borderLeft: "1px solid #dedede"
            }}
          >
            <TableContainer>
              {isLockPopUp ? (
                <MuiTable component="div">
                  <TableHead component="div">
                    <EligiblePartnersTableRow />
                  </TableHead>
                </MuiTable>
              ) : (
                <Table rowsData={[]} columnsData={eligiblePartnersColumns} />
              )}
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ maxWidth: tableWidth, minWidth: tableWidth }}>
                  <Table
                    rowsData={getRowsData(
                      getLoanPricingFields(rateSheetType),
                      partnersData
                    )}
                    columnsData={finalPricingCols}
                    customTableRowStyle={{ display: "flex" }}
                  />
                  <Table
                    rowsData={getRowsData(
                      labelFieldName30year(rateSheetType),
                      partnersData
                    )}
                    columnsData={pricingAdjustmentsCols}
                    customTableRowStyle={{ display: "flex" }}
                  />
                  {!isLockPopUp && (
                    <Table
                      rowsData={getRowsData(rateLockFields, partnersData)}
                      columnsData={rateLockCols}
                    />
                  )}
                </div>
                {showPricingOnlyForEligible &&
                  ineligiblePartners?.map(() => {
                    return (
                      <div style={{
                        paddingTop: "8%",
                        textAlign: "center",
                        color: "#8b8b8b",
                        borderWidth: "0px 1px 1px 0px",
                        borderColor: "#e0e0e0",
                        borderStyle: "solid"
                      }}>
                        <div className="rotate-container">
                          <img src={LoaderSvg} className="rotating-svg" />
                        </div>
                        <Typography variant="h6">Cannot Price the loan under this program due to eligibility fail. Please review the Test result tab</Typography>
                      </div>
                    )
                  })
                }
              </div>
            </TableContainer>
          </Grid>
        </>
      )}
      {!partnersData?.length && (
        <ZeroStateLoan
          textToShow="There are no results to show for the this loan."
          secondTextToShow=""
        />
      )}
    </>
  );
};