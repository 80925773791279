import React, { useState, useCallback, useMemo } from "react";
import { IconButton, Popover } from "@mui/material";
import { DateRange } from "react-date-range";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import moment from "moment";
import { ObjectType } from "../masterView/common";

const DateSelector = (props: any) => {
  const { range, setRange, filter } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onCalenderClick = (event: any) => {
    setAnchorEl(event.currentTarget.parentElement);
  };
  return <>
    <Popover
      id="mouse-over-popover"
      data-testid="filter-popover"
      open={Boolean(anchorEl)}
      onClose={handlePopoverClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
      disableRestoreFocus
      PaperProps={{
        style: {
          marginTop: "5px",
          marginLeft: "-8px"
        }
      }}
    >
      <DateRange
        ranges={range}
        moveRangeOnFirstSelection={false}
        onChange={val => setRange(val, filter.key)}
      />
    </Popover>
    <div style={{ margin: "5px 0px" }}>
      <span>{filter.title}</span>
      <div
        style={{
          border: "solid 1px #e9ecef",
          background: "white",
          padding: "7px",
          display: "flex",
          marginTop: "5px",
          justifyContent: "space-between"
        }}
      >
        <div>
          {moment(range[0].startDate).format("MMM DD, YYYY")} -{" "}
          {moment(range[0].endDate).format("MMM DD, YYYY")}
        </div>
        <IconButton
          onClick={onCalenderClick}
          data-testid="date-range-button"
          style={{ padding: "0px" }}
          size="large">
          <CalendarTodayIcon />
        </IconButton>
      </div>
    </div>
  </>;
};

const LoanSubmissionFilter = [
  {
    key: "loanSubmissionDate"
  }
];
const RateLockExpiryFilter = [
  {
    key: "rateLockExpiryDate"
  }
];

const defaultDateRange: ObjectType = {
  loanSubmissionDate: [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "loanSubmissionDate",
      showDateDisplay: false
    }
  ],
  rateLockExpiryDate: [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "rateLockExpiryDate",
      showDateDisplay: false
    }
  ]
};

export function LoanSubmissionDateFilter(props: any) {
  const { setRange, range } = props;

  const state: ObjectType = useMemo(() => {
    return {
      loanSubmissionDate:
        range?.loanSubmissionDate ?? defaultDateRange.loanSubmissionDate
    };
  }, [range]);

  return (
    <div
      data-testid="date-range-picker-component"
      style={{
        display: "flex",
        flexDirection: "column",
        background: "#f6f9fc",
        width: "100%",
        padding: " 5px 16px"
      }}
    >
      {LoanSubmissionFilter.map((filter: ObjectType) => (
        <DateSelector
          range={state[filter.key]}
          filter={filter}
          setRange={setRange}
          key={filter.key}
        />
      ))}
    </div>
  );
}

export function RateLockExpiryDateFilter(props: any) {
  const { setRange, range } = props;

  const state: ObjectType = useMemo(() => {
    return {
      rateLockExpiryDate:
        range?.rateLockExpiryDate ?? defaultDateRange.rateLockExpiryDate
    };
  }, [range]);

  return (
    <div
      data-testid="date-range-picker-component"
      style={{
        display: "flex",
        flexDirection: "column",
        background: "#f6f9fc",
        width: "100%",
        padding: " 5px 16px"
      }}
    >
      {RateLockExpiryFilter.map((filter: ObjectType) => (
        <DateSelector
          range={state[filter.key]}
          filter={filter}
          setRange={setRange}
          key={filter.key}
        />
      ))}
    </div>
  );
}
