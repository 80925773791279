/* eslint-disable @typescript-eslint/camelcase */
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import { LoanStage } from "@toorak/tc-common-fe-sdk";
// Query related constants
export const appendToQueryForLATToggle = (val1?: any) => {
  const preToggle = {
    terms: {
      "loan.loanStage.raw": [LoanStage.fes]
    }
  };
  const allToggle = {
    bool: {
      must: [
        {
          terms: {
            "loan.loanStage.raw": [LoanStage.fes]
          }
        },
        {
          terms: {
            "loan.loanState.raw": [
              LoanStatusEnum.DataEntry,
              LoanStatusEnum.ExcelError
            ]
          }
        }
      ]
    }
  };
  return val1 === 2 ? preToggle : allToggle;
};

export const appendToQueryForLAT1 = () => {
  return {
    bool: {
      must: [
        {
          terms: {
            "loan.loanStage.raw": [LoanStage.pre]
          }
        },
        {
          terms: {
            "loan.loanState.raw": [
              LoanStatusEnum.DataEntry,
              LoanStatusEnum.ExcelError,
              LoanStatusEnum.Submit
            ]
          }
        },
        {
          nested: {
            path: "loan.loanDetailId.loanLandmarks",
            query: {
              bool: {
                must: [
                  {
                    terms: {
                      "loan.loanDetailId.loanLandmarks.value.raw": [
                        LoanStage.pre
                      ]
                    }
                  },
                  {
                    term: {
                      "loan.loanDetailId.loanLandmarks.name.raw":
                        "loan_creation"
                    }
                  }
                ]
              }
            }
          }
        }
      ]
    }
  };
};

export const appendToQueryForLAT2 = () => {
  return {
    bool: {
      must: [
        {
          terms: {
            "loan.loanStage.raw": [LoanStage.post]
          }
        },
        {
          terms: {
            "loan.loanState.raw": [
              LoanStatusEnum.DataEntry,
              LoanStatusEnum.ExcelError,
              LoanStatusEnum.Submit
            ]
          }
        },
        {
          nested: {
            path: "loan.loanDetailId.loanLandmarks",
            query: {
              bool: {
                must: [
                  {
                    terms: {
                      "loan.loanDetailId.loanLandmarks.value.raw": [
                        LoanStage.post
                      ]
                    }
                  },
                  {
                    term: {
                      "loan.loanDetailId.loanLandmarks.name.raw":
                        "loan_creation"
                    }
                  }
                ]
              }
            }
          }
        }
      ]
    }
  };
};

export const noOnHoldQuery = {
  bool: {
    should: [
      {
        terms: {
          "loan.onHoldBy.raw": ["NONE"]
        }
      },
      {
        bool: {
          must_not: {
            exists: {
              field: "loan.onHoldBy"
            }
          }
        }
      }
    ]
  }
};

export const onHoldQuery = {
  bool: {
    must: {
      exists: {
        field: "loan.onHoldBy"
      }
    },
    must_not: {
      terms: {
        "loan.onHoldBy.raw": ["NONE"]
      }
    }
  }
};
