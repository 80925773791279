import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const searchFilterStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignSelf: "flex-end",
      flex: 1,
      display: "flex",
      alignItems: "center",
      borderRadius: "20px",
      backgroundColor: "#f7fafc !important",
      padding: "0px 16px 0 4px !important",
      // maxWidth: "250px !important",
      width: "200px !important",
      height: "36px !important",
      border: "solid 1px #E9ECEF",
      "& input": {
        border: "none !important"
      },
      "&.Mui-focused": {
        border: "2px solid #5e72e4"
      }
    },
    searchIcon: {
      width: "16px",
      height: "16px",
      color: "#32325D",
      position: "relative",
      top: "1px",
      left: "3px",
      marginRight: "5px"
    }
  })
);

export const autoCompleteSearchBoxFilterStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "250px",
      "& .MuiChip-root": {
        backgroundColor: "#e0e0e0"
      },
      minWidth: "200px"
    },
    borderPadding: {
      paddingBottom: "8px !important"
    },
    header: {
      fontWeight: "bold",
      fontSize: "12px"
    },
    selectedOptions: {
      maxHeight: "400px",
      overflowY: "auto"
    },
    inputbody: {
      "& .MuiChip-root": {
        backgroundColor: "#e0e0e0"
      },
      "& .MuiInputBase-root": {
        height: "40px !important",
        borderRadius: "20px",
        backgroundColor: "#f7fafc !important"
      },
      "& .MuiInputBase-input": {
        // border: "1px solid #E9ECEF",
        // width: "calc(100% - 2px)",
        padding: "2px 10px !important",
        margin: "2px !important",
        // height: "28px !important"
      }
    },
    textField: {
      width: "100%",
      border: "1px solid",
      height: "30px"
    },
    autoComplete: {
      border: "none !important"
    },
    searchIcon: {
      width: "16px",
      height: "16px",
      color: "#32325D",
      position: "relative",
      top: "1px",
      left: "3px",
      marginRight: "5px"
    },
    onHoverInputStyles: {
      "& input": {
        border: "none !important"
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "2px solid #5e72e4"
      }
    }
  })
);
