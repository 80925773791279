import { push } from "connected-react-router";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ToorakLogo from "../images/standaloneDocuments/trk-logo.png";
import etExpand from "../images/createloan/et-expand.svg";
import outlineDashboard from "../images/createloan/ic-outline-analytics.svg";
import { Button } from "@mui/material";

export const ResolutionNotSupported = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="resolution-not-supported">
      <div className="trk-home-logo">
        <img src={ToorakLogo} alt="Toorak-logo" />
      </div>
      <div className="resolution-icon">
        <img src={etExpand} alt="Toorak-logo" aria-hidden="true" />
      </div>
      <br />
      <div className="resolution-message">
        This device resolution is not supported.
        <br />
        Please switch to a larger screens size.
      </div>
      <br />
      <div className="resolution-message">or</div>
      <br />
      <br />
      <Button
        variant="contained"
        data-testid="dashboard-button"
        color="primary"
        onClick={() => navigate("/loans/list?tab=1")}
      >
        <img
          src={outlineDashboard}
          alt="dashboard-icon"
          className="outline-dashboard-icon"
          aria-hidden="true"
        />
        Go to Dashboard
      </Button>
    </div>
  );
};
