import { Button, CircularProgress } from "@mui/material";
import { WarningModalProps } from "@toorak/tc-common-fe-sdk";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updatePredictionStatus } from "../../stores/budgetReview/budgetReview.action";
import {
  ExtractionInProgressState,
  ExtractionProcessState
} from "../BudgetReview.constant";
import { useBudgetStyles } from "../BudgetReview.style";
import { addEmptyLineItems } from "./TotalBudgetArea";

interface BudgetFailedModalProps extends WarningModalProps {
  hideSecondaryBtn?: boolean;
}

interface LineItemsManualEntryProps {
  budgetReviewResponse: any;
  stopPolling: () => void;
  setFieldsData: (data: any) => void;
  isApprovalView: boolean;
  isManualEntry: boolean;
  setIsManualEntry: (value: boolean) => void;
}

export function LineItemsManualEntry({
  budgetReviewResponse,
  stopPolling,
  setFieldsData,
  isApprovalView,
  isManualEntry,
  setIsManualEntry
}: LineItemsManualEntryProps) {
  const dispatch = useDispatch();
  const budgetClasses = useBudgetStyles();
  const [openWarning, setOpenWarning] = useState<BudgetFailedModalProps | null>(
    null
  );

  useEffect(() => {
    const isExtractionFailed =
      budgetReviewResponse?.processState === ExtractionProcessState.failed;
    const isExtractionNotCompleted = ExtractionInProgressState?.includes(
      budgetReviewResponse?.processState
    );
    if (!isExtractionNotCompleted && !isExtractionFailed) return;
    let bodyText = isExtractionNotCompleted
      ? "Document extraction is still in progress, Do you want to proceed with manual entry?"
      : "Document extraction failed. Please proceed with manual entry.";
    let primaryBtnName = isExtractionNotCompleted ? "Yes" : "OK";

    setOpenWarning({
      isOpen: true,
      header: "Warning",
      hideSecondaryBtn: !isExtractionNotCompleted,
      body: (
        <>
          <div
            className={budgetClasses.budgetAddComment}
            style={{ paddingRight: 20, fontSize: 13 }}
          >
            {bodyText}
          </div>
        </>
      ),
      primaryBtnName: primaryBtnName,
      handleClose: () => {
        setOpenWarning(null);
      }
    });
    // eslint-disable-next-line
  }, [budgetReviewResponse?.processState]);

  function handleManualEntry() {
    function handleManualEntryCallback(resp: any) {
      if (
        ExtractionInProgressState?.includes(budgetReviewResponse?.processState)
      ) {
        stopPolling();
      }
      let formattedLineItems = JSON.parse(
        JSON.stringify([...resp.extractedInfoProcessed.lineItems])
      );
      for (const item of formattedLineItems) {
        item.budget = `$${item.budget}.00`;
      }
      setFieldsData(formattedLineItems);
      setOpenWarning(null);
      setIsManualEntry(true);
    }
    const newLineItems = addEmptyLineItems({ val: 10 }, [], true);
    updatePredictionStatus(
      budgetReviewResponse,
      ExtractionProcessState.predictionSkippedManualEntry,
      newLineItems,
      dispatch,
      handleManualEntryCallback
    );
  }

  return (
    <>
      {openWarning &&
        ExtractionInProgressState.includes(
          budgetReviewResponse?.processState
        ) &&
        !isManualEntry && (
          <div className={budgetClasses.extractionPending}>
            <CircularProgress
              size={40}
              className={budgetClasses.extractionCircular}
            />
            <div className={budgetClasses.extractionHeader}>
              Document Extraction in Progress
            </div>

            <div className={budgetClasses.extractionText}>
              {openWarning.body}
            </div>
            <div>
              <Button
                variant="contained"
                className={budgetClasses.extractionButton}
                data-testid={`manual-entry-btn`}
                onClick={handleManualEntry}
                disabled={isApprovalView}
                color="info"
              >
                {openWarning.primaryBtnName}
              </Button>
            </div>
          </div>
        )}
    </>
  );
}
