import { ObjectType } from "../../masterView/common";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface EvaluationResultStore {
  loading: boolean;
  isEvaluationPanelVisible: boolean;
  loanEvaluationResult: {
    loanResult: any;
    propertiesResults: any;
    reasonForFail: any;
    errorAddressList: any;
  };
  unreadWaivers: any;
  compensatingFactorsOptions: string[];
  waiversCreated: any;
  documentsUploaded: any;
  overriddenValues: any;
  customerInfo: any | undefined;
  displayUserName: any;
  editHistory: any;
  aggregateRuleVersions: ObjectType;
  rateLockOptions: ObjectType[];
  rateSheetType: string;
  rateExtensionOptions: ObjectType[];
}
export const initialStateEvaluationResult: EvaluationResultStore = {
  isEvaluationPanelVisible: false,
  loading: false,
  loanEvaluationResult: {
    loanResult: {},
    propertiesResults: {},
    reasonForFail: {},
    errorAddressList: {}
  },
  unreadWaivers: {},
  waiversCreated: [],
  compensatingFactorsOptions: [],
  documentsUploaded: [],
  overriddenValues: {},
  customerInfo: {},
  displayUserName: "",
  editHistory: {},
  aggregateRuleVersions: {},
  rateLockOptions: [],
  rateSheetType: "",
  rateExtensionOptions: []
};

const evaluationResultsSlice = createSlice({
  name: "evaluationResults",
  initialState: initialStateEvaluationResult,
  reducers: {
    evaluateLoanFailed: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.isEvaluationPanelVisible = false;
    },

    modifyWFLRule: (
      state,
      action: PayloadAction<{
        changedValue: any;
        loanId: string;
        fieldName: string;
        sectionName: string;
      }>
    ) => {
      state.loanEvaluationResult.loanResult[action.payload.loanId][
        action.payload.sectionName
      ][action.payload.fieldName] = action.payload.changedValue;
    },

    updateRuleResults: (state, action: PayloadAction<{ ruleResults: any }>) => {
      state.loanEvaluationResult = {
        ...state.loanEvaluationResult,
        loanResult: action.payload.ruleResults.loanResult,
        propertiesResults: action.payload.ruleResults.propertiesResults
      };
    },

    updateLoanEvaluation: (state, action: PayloadAction<any>) => {
      const { key, value, section, loanId, activePropertyId } = action.payload;
      if (activePropertyId !== undefined) {
        state.loanEvaluationResult.propertiesResults[activePropertyId][section][
          key
        ] = value;
      } else {
        state.loanEvaluationResult.loanResult[loanId][section][key] = value;
      }
    },

    setRulesPreviousState: (state, action: PayloadAction<any>) => {
      const { prevObj, section, loanId, activePropertyId } = action.payload;
      if (activePropertyId !== undefined) {
        state.loanEvaluationResult.propertiesResults[activePropertyId][
          section
        ] = prevObj;
      } else if (section) {
        state.loanEvaluationResult.loanResult[loanId][section] = prevObj;
      } else {
        state.loanEvaluationResult.loanResult[loanId] = prevObj;
      }
    },

    evaluateLoanSuccess: (state, action: PayloadAction<any>) => {
      const {
        loanEvaluationResult,
        isSubmitted,
        reasonForFail,
        errorAddressList
      } = action.payload;
      state.loading = false;
      state.isEvaluationPanelVisible =
        state.isEvaluationPanelVisible || isSubmitted;
      state.loanEvaluationResult.loanResult =
        loanEvaluationResult.loanResult || {};
      state.loanEvaluationResult.reasonForFail = reasonForFail;
      state.loanEvaluationResult.errorAddressList = errorAddressList;
      state.loanEvaluationResult.propertiesResults =
        loanEvaluationResult?.propertiesResults;
    },

    changeEvalPanelVisibility: (state, action: PayloadAction<boolean>) => {
      state.isEvaluationPanelVisible = action.payload;
    },
    updateCompensatingFactorsOptions: (state, action: PayloadAction<any>) => {
      state.compensatingFactorsOptions = action.payload;
    },

    setWaiverList: (
      state,
      action: PayloadAction<{ waiver: any; documents: any }>
    ) => {
      state.waiversCreated = action.payload.waiver;
      state.documentsUploaded = action.payload.documents;
    },

    getOverriderValues: (state, action: PayloadAction<{ overidden: any }>) => {
      state.overriddenValues = action.payload.overidden;
    },

    requestedWaierUserName: (state, action: PayloadAction<any>) => {
      state.customerInfo = action.payload;
    },

    updateWaiverList: (state, action: PayloadAction<{ waiver: any }>) => {
      if (state?.waiversCreated?.length) {
        state.waiversCreated = [...state.waiversCreated, action.payload.waiver];
      } else {
        state.waiversCreated = [action.payload.waiver];
      }
    },

    updateWaiverStatus: (
      state,
      action: PayloadAction<{ waiverId: string; waiver: any }>
    ) => {
      const { waiverId, waiver } = action.payload;
      state.waiversCreated = state.waiversCreated?.map(
        (item: { waiverId: string; waiverResponses: any }) => {
          if (item.waiverId !== waiverId) {
            return item;
          }
          let temp = item.waiverResponses;
          if (temp) {
            temp.push(waiver);
          } else {
            temp = [];
            temp.push(waiver);
          }
          return { ...item, ...{ waiverResponses: temp } };
        }
      );
    },

    deleteWaiverFromRedux: (
      state,
      action: PayloadAction<{ waiverId: string }>
    ) => {
      if (state?.waiversCreated instanceof Array) {
        state.waiversCreated = state?.waiversCreated?.filter(
          (item) => item?.waiverId !== action.payload.waiverId
        );
      }
    },

    setUnreadObjects: (
      state,
      action: PayloadAction<{ unreadWaivers: any }>
    ) => {
      state.unreadWaivers = action.payload.unreadWaivers;
    },

    deleteUnreadWaiver: (
      state,
      action: PayloadAction<{ waiverId: string }>
    ) => {
      const temp = state.unreadWaivers;
      delete temp[action.payload.waiverId];
      state.unreadWaivers = temp;
    },

    resetEvaluationStore: (state) => {
      return initialStateEvaluationResult;
    },

    uploadExcelLoanEvalChange: (
      state,
      action: PayloadAction<{ data: any }>
    ) => {
      return action.payload.data;
    },

    waiverRequestedUserName: (state, action: PayloadAction<any>) => {
      state.displayUserName = action.payload;
    },

    setAggregateRuleVersions: (state, action: PayloadAction<any>) => {
      state.aggregateRuleVersions = action.payload;
    },

    setRateLockOptions: (state, action: PayloadAction<any>) => {
      state.rateLockOptions = action.payload;
    },

    setRateSheetType: (state, action: PayloadAction<any>) => {
      state.rateSheetType = action.payload;
    },

    setRateExtensionOptions: (state, action: PayloadAction<any>) => {
      state.rateExtensionOptions = action.payload;
    }
  }
});

export const {
  // evaluateLoanLoading,
  evaluateLoanFailed,
  modifyWFLRule,
  updateRuleResults,
  updateLoanEvaluation,
  setRulesPreviousState,
  evaluateLoanSuccess,
  changeEvalPanelVisibility,
  setWaiverList,
  getOverriderValues,
  requestedWaierUserName,
  updateWaiverList,
  updateWaiverStatus,
  deleteWaiverFromRedux,
  setUnreadObjects,
  deleteUnreadWaiver,
  resetEvaluationStore,
  uploadExcelLoanEvalChange,
  waiverRequestedUserName,
  updateCompensatingFactorsOptions,
  setAggregateRuleVersions,
  setRateLockOptions, 
  setRateSheetType,
  setRateExtensionOptions
} = evaluationResultsSlice.actions;

export const EvaluationResultsReducer = evaluationResultsSlice.reducer;
