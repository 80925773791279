import { createSlice } from "@reduxjs/toolkit";
import {
  // CreateLocationStore,
  // empty30yearLoanDetails,
  // emptyBridgeLoanDetails,
  // emptyLoanDetails,
  mergeArrayOfObjects,
  getFilteredTags,
  emptyBorrower,
  BorrowerInformation,
  copyFileList,
  updateTagCategory,
  initialState
} from "./create-loan.reducerHelper";

import { MMMDDYYYYDateFormat } from "../utils/utils";
import { getCookie } from "../utils/cookies";
import {
  reorderPropertyType,
  reorderOriginator
} from "./loan-lists/utils/commonStatusFormatter";
import { InternalDDStatusEnum, allDateFields } from "../utils/constants";

const loanSlice = createSlice({
  name: "loan",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    setCustomColumnLoanList: (state, action) => {
      state.customColumnList = action.payload;
    },
    setOpenColumnReorder: (state, action) => {
      state.openColumnReorder = action.payload;
    },
    setGetLoanLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    updateLoanLevelData: (state, action) => {
      const { fieldId, value } = action.payload;
      state.loanDetails[fieldId] = value;
    },
    updateLoanConfig: (state, action) => {
      const { field, value } = action.payload;
      state.loanDetails.loanConfig = state.loanDetails.loanConfig
        ? { ...state.loanDetails.loanConfig, [field]: value }
        : { [field]: value };
    },
    updateSummaryDetailsReducer: (state, action) => {
      const { key, value, summaryObject, dueDDParty } = action.payload;
      if (summaryObject) {
        state.loanDetails.loanSummary[key] = value;
      } else {
        state.loanDetails.loanConfig[key] = value;
      }
      if (dueDDParty) {
        state.loanDetails[key] = value;
      }
      if (
        state.loanDetails?.loanConfig["appraisal"] === "Yes" &&
        state.loanDetails?.loanConfig["streetView"] === "Yes" &&
        state.loanDetails?.loanConfig["zillow"] === "Yes" &&
        state.loanDetails?.loanConfig["truliaCrime"] === "Yes" &&
        // intentionally == only. Do not change
        ((state.loanDetails?.loanConfig?.ttfVersionId == null &&
          state.loanDetails?.loanConfig?.tapeToFile === "Yes") ||
          state.loanDetails?.loanSummary?.teamIndiaTapetoFile === "Yes")
      ) {
        state.loanDetails.loanConfig.master = "Pass";
      } else {
        state.loanDetails.loanConfig.master = "--";
      }
    },
    updateSummaryDetailsProperty: (state, action) => {
      const { key, value, index } = action.payload;
      if (state.loanDetails.propertyDetails?.[index]?.propertySummary) {
        state.loanDetails.propertyDetails[index].propertySummary[key] = value;
      }
    },
    addExceptionGrade: (state, action) => {
      const exceptionGrade = action.payload.exceptionGrade || "";
      state.loanDetails.exceptionGrade = exceptionGrade;
    },
    addInternalGrade: (state, action) => {
      const workflowGrade = action.payload.workflowGrade || "";
      state.loanDetails.workflowGrade = workflowGrade;
    },
    updateTTFSubmitState: (state, action) => {
      const ttfSubmissionState = action.payload || false;
      state.loanDetails.isTapeToFileSubmitted = ttfSubmissionState;
    },
    setLoanPreviousState: (state, action) => {
      const { dataObj, keyInfo, propertyData } = action.payload;
      const Key = keyInfo;
      // const nextState = produce(state, (draftState) => {
      if (!action.payload.propertyData) {
        if (
          Key === "bridgeLoanGuarantorInformation" ||
          Key === "bridgeLoanBorrowerInformation"
        ) {
          state.loanDetails[Key] = dataObj;
        } else {
          state.loanDetails[Key].payload = dataObj;
        }
      } else {
        const activeId = state.loanDetails.activePropertyId;
        let indexValue = 0;
        for (let i = 0; i < state.loanDetails.propertyDetails.length; i++) {
          if (state.loanDetails.propertyDetails[i].propertyId === activeId) {
            indexValue = i;
          }
        }
        let keyName: string;
        keyName = Key;
        switch (keyName) {
          case "propertyInformation": {
            state.loanDetails.propertyDetails[indexValue][keyName] = dataObj;
            break;
          }
          case "propertyEconomics": {
            state.loanDetails.propertyDetails[indexValue][keyName] = dataObj;
            break;
          }
          case "propertyLocation": {
            state.loanDetails.propertyDetails[indexValue][keyName] = dataObj;
            break;
          }
          case "unitsInformation": {
            state.loanDetails.propertyDetails[indexValue][keyName] = dataObj;
            break;
          }
          default:
            return state;
        }
      }
    },
    updatePreviousStageRedux: (state, action) => {
      const { viewMode, availableLoanStage } = action.payload;
      state.isViewMode = viewMode;
      state.availableLoanStages = availableLoanStage;
    },
    updateIsWithdrawnState: (state, action) => {
      state.isWithdrawnLoan = action.payload;
    },
    setLoanByIdSuccess: (state, action) => {
      const { loan, keepTab } = action.payload;
      state.loading = false;
      const activeTab = loan?.loanDetails?.activeTab;
      if (keepTab !== true) {
        state.activeTab = activeTab || "loanDetails";
      }
      state.loanDetails = {
        ...state.loanDetails,
        ...loan.loanDetails
      };
    },
    condoQuestOpened: (state, action) => {
      const { condoQuestOpen, clearCondoValue, prevValue } = action.payload;
      state.condoQuestOpen = condoQuestOpen;
      if (clearCondoValue) {
        state.loanDetails.fesLoanCondo = prevValue;
      }
    },
    // getPropertyFailed: (state) => {
    //   // No state change needed for this case
    // },
    gurrantorFieldChanged: (state, action) => {
      let gurrantorIds = action.payload.gurrantorChangedIds;
      let secondaryGuarantorEdited = action.payload.secondaryGurrantorEdited;
      if (gurrantorIds === "") {
        gurrantorIds = [];
      } else {
        gurrantorIds = JSON.parse(gurrantorIds);
      }
      state.gurrantorFieldChange = gurrantorIds;
      state.secondaryGurrantorEdited = secondaryGuarantorEdited;
    },
    borrowerFieldChanged: (state, action) => {
      let {
        borrowerChangedIds,
        borrowerFieldEmpty,
        secondaryBorrowerEdited,
        totalBorrowerFieldChange
      } = action.payload;
      if (borrowerChangedIds === "") {
        borrowerChangedIds = [];
      } else {
        borrowerChangedIds = JSON.parse(borrowerChangedIds);
      }
      state.borrowerFieldChange = borrowerChangedIds;
      state.emptyValueFieldCount = borrowerFieldEmpty;
      state.secondaryBorrowerEdited = secondaryBorrowerEdited;
      state.totalBorrowerFieldChange = totalBorrowerFieldChange;
    },
    updateBridgeLoanGroundUp: (state, action) => {
      state.loanDetails.bridgeIsGroundUpLoan = action.payload || false;
    },
    unitFieldChanged: (state, action) => {
      let { unitChangedIds, totalUnitFieldChange } = action.payload;
      if (unitChangedIds === "") {
        unitChangedIds = [];
      } else {
        unitChangedIds = JSON.parse(unitChangedIds);
      }
      state.unitFieldChange = unitChangedIds;
      state.totalUnitFieldChange = totalUnitFieldChange;
    },
    setLoanByIdFailed: (state) => {
      state.getLoan.loading = false;
      state.error = "error";
    },
    saveLoanDetailsForm: (state, action) => {
      const { loanType } = action.payload;
      state.loanDetails.loanType = loanType || state.loanDetails.loanType;
      state.loading = false;
    },
    getCreditScoreBucketsSuccess: (state, action) => {
      const { buckets } = action.payload;
      state.error = null;
      state.creditScoreBuckets = buckets;
      state.loading = false;
    },
    getLoanPurposesSuccess: (state, action) => {
      const { loanPurposes } = action.payload;
      state.error = null;
      state.loanPurposes = loanPurposes;
      state.loading = false;
    },
    getAppraisalVendorsSuccess: (state, action) => {
      const { appraisalVendors } = action.payload;
      state.error = null;
      state.appraisalVendors = appraisalVendors;
      state.loading = false;
    },
    loanTypeSuccess: (state, action) => {
      const { loanTypes } = action.payload;
      state.error = null;
      state.loanTypes = loanTypes;
      state.loading = false;
    },
    setMandatoryResultsSuccess: (state, action) => {
      const { docType, data } = action.payload;
      docType === "ApprovedPre"
        ? (state.loanDetails.prePurchaseMandatoryResult = data)
        : (state.loanDetails.mandatoryResult = data);
      state.loading = false;
    },
    setMandatoryDocumentsSuccess: (state, action) => {
      const { docType, response } = action.payload;
      docType === "ApprovedPre"
        ? (state.loanDetails.prePurchaseMandatoryDocs = response)
        : (state.loanDetails.mandatoryDocs = response);
      state.loading = false;
    },
    tagsSuccess: (state, action) => {
      const { tags, loanState, loanStage } = action.payload.tags;
      state.error = null;
      state.loading = false;
      let filteredTags = tags.tags.filter(
        (ele: { tagType: string }) => ele.tagType === "DOCUMENT"
      );
      let docsCategory = tags.tags.filter(
        (ele: { tagType: string }) => ele.tagType === "CATEGORY"
      );
      filteredTags.forEach((element: { tagCategory: string | null }) => {
        if (element.tagCategory === null) {
          element.tagCategory = "Others";
        }
      });
      tags.tags = filteredTags;
      if (
        (loanState === "APPROVED" && loanStage === "post") ||
        loanStage === "tv"
      ) {
        state.prePurchaseTags = tags;
      } else if (loanState === "SUBMIT") {
        state.tags = tags;
        state.docsCategory = docsCategory;
      }
    },
    dashboardTagsSuccess: (state, action) => {
      const {
        InvestorDSCRPRESUBMIT,
        InvestorDSCRPOSTSUBMIT,
        BridgeLoanPRESUBMIT,
        BridgeLoanPOSTSUBMIT
      } = action.payload.dashboardTags;
      state.error = null;
      state.loading = false;
      state.dashboardTags.InvestorDSCRPRESUBMIT = getFilteredTags(
        InvestorDSCRPRESUBMIT
      );
      state.dashboardTags.InvestorDSCRPOSTSUBMIT = getFilteredTags(
        InvestorDSCRPOSTSUBMIT
      );
      state.dashboardTags.BridgeLoanPRESUBMIT =
        getFilteredTags(BridgeLoanPRESUBMIT);
      state.dashboardTags.BridgeLoanPOSTSUBMIT =
        getFilteredTags(BridgeLoanPOSTSUBMIT);
    },
    valueEditing: (state, action) => {
      state.error = null;
      state.sectionBeingEdited = action.payload;
      state.loading = false;
    },
    loanListSuccess: (state, action) => {
      const { loanLists } = action.payload;

      state.error = null;
      state.loanLists = loanLists;
      state.cachedLoanListData = mergeArrayOfObjects(
        state.cachedLoanListData,
        loanLists,
        "toorakLoanId"
      );
      state.loading = false;
    },
    loanListCount: (state, action) => {
      const { tab, loanLists, loanAmount } = action.payload;

      state.loanCount[tab] = loanLists;
      state.loanAmount[tab] = loanAmount;
    },
    loanListMultiDispatch: (state, action) => {
      const { tab, loanCount, loanAmount, loanLists } = action.payload;

      state.loanCount[tab] = loanCount;
      state.loanAmount[tab] = loanAmount;
      state.error = null;
      state.loanLists = loanLists;
      state.cachedLoanListData = mergeArrayOfObjects(
        state.cachedLoanListData,
        loanLists,
        "toorakLoanId"
      );
      state.loading = false;
    },
    updateLoanListTabCount: (state, action) => {
      const { tabinfo, count, loanAmount } = action.payload;

      state.loanCount[tabinfo] = count;
      state.loanAmount[tabinfo] = loanAmount;
    },
    sortLoanListSuccess: (state, action) => {
      const { loanLists } = action.payload;

      state.error = null;
      state.loanLists = loanLists;
      state.cachedLoanListData = mergeArrayOfObjects(
        state.cachedLoanListData,
        loanLists,
        "toorakLoanId"
      );
      state.loading = false;
    },
    setRawLoanListData: (state, action) => {
      const { loanLists } = action.payload;

      state.error = null;
      state.rawLoanListData = loanLists;
    },
    filterDataSuccess: (state, action) => {
      let buckets = action.payload?.loanLists?.response?.aggregations;
      if (Object.keys(action.payload?.loanLists?.response?.aggregations)) {
        let renamedBuckets =
          action.payload?.loanLists?.response?.aggregations?.LoanStatus
            ?.buckets;
        if (renamedBuckets) {
          const DueDiligenceReviewIndex = renamedBuckets.findIndex(
            (obj: { key: string }) => obj.key === "Due Diligence Review"
          );
          if (DueDiligenceReviewIndex > -1)
            renamedBuckets[DueDiligenceReviewIndex].key = "Review In Progress";
          buckets = {
            ...buckets,
            LoanStatus: {
              buckets: [...renamedBuckets]
            }
          };
        }
      }
      const combinedDDData = { ...buckets, ...state.dropDownData };

      state.error = null;
      state.filterData = combinedDDData;
      state.loading = false;
    },
    dropDownDataSuccess: (state, action) => {
      let buckets;
      let dummyBucket: any;
      const isFEFilteredValue = action.payload?.loanLists?.isFEFilteredValue;
      if (isFEFilteredValue) {
        const columnName = action.payload?.loanLists?.searchColumName;
        const filters = action.payload?.loanLists?.data;
        buckets = { [columnName]: { buckets: filters } };
      } else if (
        Object.keys(action.payload?.loanLists?.response?.aggregations)[0] ===
        "activeInteractions"
      ) {
        const dummyArray = [
          "Open Conditions",
          "Open Inquiries",
          "Open Waivers"
        ];
        const newArray = [];
        for (let i = 0; i < dummyArray.length; i++) {
          newArray.push({ key: dummyArray[i] });
        }
        buckets = {
          activeInteractions: {
            buckets: [...newArray]
          }
        };
      } else if (
        action.payload?.loanLists?.response?.aggregations?.property
          ?.propertyType
      ) {
        let k = [];
        const newArray = [];
        dummyBucket = reorderPropertyType(
          action.payload?.loanLists?.response?.aggregations?.property
            ?.propertyType?.buckets
        );
        for (let i = 0; i < Object.keys(dummyBucket).length; i++) {
          k.push(dummyBucket[i]);
        }
        for (let i = 0; i < k.length; i++) {
          newArray.push({ key: k[i] });
        }
        buckets = {
          propertyType: {
            buckets: [...newArray]
          }
        };
      } else if (
        Object.keys(action.payload?.loanLists?.response?.aggregations)[0] ===
        "originator"
      ) {
        let key = [];
        let newArray = [];
        dummyBucket = reorderOriginator(
          action.payload?.loanLists?.response?.aggregations?.originator?.buckets
        );
        for (let i = 0; i < Object.keys(dummyBucket).length; i++) {
          key.push(dummyBucket[i]);
        }
        for (let j = 0; j < key.length; j++) {
          newArray.push({ key: key[j] });
        }
        buckets = {
          originator: {
            buckets: [...newArray]
          }
        };
      } else {
        buckets = action.payload?.loanLists?.response?.aggregations;
      }
      const combinedDDData = { ...buckets };

      state.error = null;
      state.dropDownData = combinedDDData;
      state.loading = false;
    },
    compactSearchUpdate: (state, action) => {
      state.compactViewSearchValue = action.payload;
    },
    headerFiltersUpdateSuccess: (state, action) => {
      const { selections } = action.payload;
      state.detailedViewSelectedFilterTags = selections;
      state.error = null;
      state.loading = false;
    },
    rescindSuccessDialog: (state, action) => {
      const { val } = action.payload;

      state.rescindDialog = val;
    },
    activeInteractionsModal: (state, action) => {
      const { actIntArray, actIntModal } = action.payload;

      state.activeInteractionsModal = actIntModal;
      state.activeInteractionsModalCount = actIntArray.length;
      state.activeInteractionsModalArray = actIntArray;
    },
    updateActIntLoanList: (state, action) => {
      const loanId = action.payload;

      let newArray = state.activeInteractionsModalArray;
      let loanListsIndex = state.loanLists.findIndex(
        (item) => item.toorakLoanId === loanId
      );
      let duplicateLoanLists = JSON.parse(JSON.stringify(state.loanLists));
      duplicateLoanLists[loanListsIndex].activeInteractions.count =
        newArray.length + 1;
      state.loanLists = duplicateLoanLists;
      state.cachedLoanListData = mergeArrayOfObjects(
        state.cachedLoanListData,
        duplicateLoanLists,
        "toorakLoanId"
      );
    },
    setActiveInteractionsArray: (state, action) => {
      const { waiverId, loanId } = action.payload;
      let newArray = state.activeInteractionsModalArray.filter(
        (item: { _source: { resourceId: any } }) => {
          return item._source?.resourceId !== waiverId;
        }
      );

      state.activeInteractionsModalArray = newArray;
      state.activeInteractionsModalCount = newArray.length;
      state.activeInteractionsModal = true;
      let loanListsIndex = state.loanLists.findIndex(
        (item) => item.toorakLoanId === loanId
      );
      let duplicateLoanLists = JSON.parse(JSON.stringify(state.loanLists));
      duplicateLoanLists[loanListsIndex].activeInteractions.count =
        newArray.length;
      state.loanLists = duplicateLoanLists;
      state.cachedLoanListData = mergeArrayOfObjects(
        state.cachedLoanListData,
        duplicateLoanLists,
        "toorakLoanId"
      );
    },
    deleteActIntAskQuest: (state, action) => {
      const { chatSequenceId, exceptionId, waiverId } = action.payload;
      let arr = [...state?.activeInteractionsModalArray];
      let index = state.activeInteractionsModalArray.findIndex(
        (item: any) =>
          (item?._source.resource === "exception" &&
            item?._source?.exception?.exceptionId === exceptionId) ||
          (item?._source.resource === "waiver" &&
            item?._source?.waiver?.waiverType?.toLowerCase() === "exception" &&
            item?._source?.waiver?.waiverKeys?.[0] === exceptionId &&
            item?._source?.resourceId === waiverId)
      );
      const chatSequeneceIdIndex = arr[index]._source.responses.findIndex(
        (item: { chatSequenceId: any }) =>
          item.chatSequenceId === chatSequenceId
      );

      let duplicateArr = JSON.parse(JSON.stringify(arr));
      duplicateArr[index]._source.responses.splice(chatSequeneceIdIndex, 1);
      state.activeInteractionsModalArray = [...duplicateArr];
    },
    addResponseToActiveIntArray: (state, action) => {
      const { data, id, type, waiverId } = action.payload;
      let arr = state?.activeInteractionsModalArray;
      if (arr?.length) {
        let index = state.activeInteractionsModalArray.findIndex(
          (item: {
            _source: {
              resource: string;
              exception: { exceptionId: any };
              waiver: { waiverType: string; waiverKeys: any[]; waiverId: any };
              resourceId: any;
            };
          }) =>
            item?._source.resource === "exception"
              ? item?._source?.exception?.exceptionId === id &&
              (type === item?._source?.resource || type === "Condition")
              : item?._source?.resource === "waiver" &&
                item?._source?.waiver?.waiverType?.toLowerCase() === "exception"
                ? item?._source?.waiver?.waiverKeys?.[0] === id &&
                type === item?._source?.resource &&
                waiverId === item?._source?.resourceId
                : item?._source?.waiver?.waiverId === id &&
                type === item?._source?.resource
        );
        let newArr = JSON.parse(
          JSON.stringify(state.activeInteractionsModalArray)
        );
        if (data?.exceptions?.response && newArr?.[index]?._source) {
          newArr[index]._source.responses = data.exceptions.response;
          let newResp = newArr[index]._source.responses.map(
            (item: {
              chatSequenceId: string | number;
              chatLines: any;
              accounts: any;
              customers: any;
              accountMapping: any;
            }) => {
              let chatLiness = data.chatLines.map(
                (items: { [x: string]: any }) => {
                  if (items[item.chatSequenceId])
                    return items[item.chatSequenceId];
                }
              );
              chatLiness = chatLiness.filter((chat: any) => chat !== undefined);
              item.chatLines = chatLiness[0];
              item.accounts = data.accounts;
              item.customers = data.customers;
              item.accountMapping = data.accountMapping;

              return item;
            }
          );

          newArr[index]._source.responses = newResp;
        }
        if (data?.waiverData?.waivers) {
          let waivers = data.waiverData.waivers.map(
            (waiver: { waiverId: any; documents: any }) => {
              data.waiverData.documents.some(
                (doc: { primaryIdentifierValue: any; docs: any }) => {
                  if (waiver.waiverId === doc.primaryIdentifierValue) {
                    waiver.documents = doc.docs;
                  }
                }
              );
              return waiver;
            }
          );
          let newExp = {
            waivers: [...waivers],
            customers: data.waiverData.customers,
            accountMapping: data.waiverData.accountMapping,
            accounts: data.waiverData.accounts,
            documents: data.waiverData.documents,
            properties: data.waiverData.properties,
            waiverHistory: data.waiverData.waiverHistory
          };

          newArr[index]._source.waiverResult = newExp;
        }

        state.activeInteractionsModalArray[index]._source =
          newArr[index]._source;
      }
    },
    closeActiveInteractionsModal: (state) => {
      state.activeInteractionsModal = false;
      state.activeInteractionsModalCount = 0;
      state.activeInteractionsModalArray = [];
      state.activeIntError = false;
    },
    filtersUpdateSuccess: (state, action) => {
      const { selections, viewType } = action.payload;

      switch (viewType) {
        case "compactViewSelectedFiltersList":
          state.compactViewSelectedFiltersList = selections;
          break;
        case "detailedViewSelectedFiltersList":
          state.detailedViewSelectedFiltersList = selections;
          break;
        default:
          state.selectedLoanListFilters = selections;
      }
      state.error = null;
      state.loading = false;
    },
    updateSortConfigLoan: (state, action) => {
      state.sortConfig = action.payload;
    },
    postLoanSuccess: (state, action) => {
      state.error = null;
      state.loading = false;
    },
    updateFesLoanDetails: (state, action) => {
      const { sectionName, fieldName, fieldValue } = action.payload;
      state.loanDetails[sectionName] = {
        ...state.loanDetails[sectionName],
        ...{ [fieldName]: fieldValue }
      };
    },
    updateFESLoanDetailsInBulk: (state, action) => {
      const { sectionName, payload } = action.payload;
      state.loanDetails[sectionName] = {
        ...state.loanDetails[sectionName],
        ...payload
      }
    },
    setCreditSyncFlag: (state, action) => {
      state.startCreditEvalSync = action.payload.startSync;
    },
    updateFesPropertyDetails: (state, action) => {
      const { sectionName, fieldName, fieldValue, propertyId } = action.payload;
      const propertyBeingEdited: { [index: string]: any } | undefined =
        state.loanDetails.propertyDetails.find(
          (item) => item.propertyId === propertyId
        );
      if (propertyBeingEdited) {
        propertyBeingEdited[sectionName] = {
          ...propertyBeingEdited[sectionName],
          ...{ [fieldName]: fieldValue }
        };
        state.loanDetails.propertyDetails =
          state.loanDetails.propertyDetails.filter((item) => {
            if (item.propertyId !== propertyId) {
              return item;
            }
            return { ...propertyBeingEdited };
          });
      }
    },
    getFesLoanByIdSuccess: (state, action) => {
      state.loanDetails = {
        ...state.loanDetails,
        ...action.payload.loanDetails
      };
    },
    getFesPropertyByIdSuccess: (state, action) => {
      state.loanDetails.propertyDetails = action.payload;
    },
    setPageBlock: (state, action) => {
      state.isPageBlocked = action.payload;
    },
    deleteBridgeLoanGuarantor: (state, action) => {
      const guarantorId = action.payload;
      const indexOfDeletedGuarantor =
        state.loanDetails.bridgeLoanGuarantorInformation.findIndex(
          (element) => element?.guarantorId === guarantorId
        );
      const indexOfPrimaryGuarantor =
        state.loanDetails.bridgeLoanGuarantorInformation.findIndex(
          (element) => element.payload.isPrimaryGuarantor
        );
      state.loanDetails.bridgeLoanGuarantorInformation =
        state.loanDetails.bridgeLoanGuarantorInformation.filter(
          (element) => element?.guarantorId !== guarantorId
        );
      if (
        indexOfDeletedGuarantor === indexOfPrimaryGuarantor &&
        state.loanDetails.bridgeLoanGuarantorInformation.length > 0
      ) {
        state.loanDetails.bridgeLoanGuarantorInformation[0].payload.isPrimaryGuarantor =
          true;
      }
    },
    // deleteThirtyYearLoanGuarantor: (state, action) => {
    //   const { guarantorId } = action.payload;
    //   state.loanDetails.thirtyYearGuarantorInformation =
    //     state.loanDetails.thirtyYearGuarantorInformation.filter(
    //       (element) => element?.guarantorId !== guarantorId
    //     );
    // },
    updatePropertySuccess: (state) => {
      state.error = null;
      state.loading = false;
    },
    updateDeletePropertyDetails: (state, action) => {
      const propertyId = action.payload;
      const indexOfDeletedProperty =
        state.loanDetails.propertyDetails.findIndex(
          (element) => element.propertyId === propertyId
        );
      state.loanDetails.propertyDetails =
        state.loanDetails.propertyDetails.filter(
          (element) => element.propertyId !== propertyId
        );
      if (
        indexOfDeletedProperty === 0 &&
        state.loanDetails.propertyDetails.length === 0
      ) {
        state.loanDetails.activePropertyId = "";
      } else if (
        indexOfDeletedProperty >= state.loanDetails.propertyDetails.length
      ) {
        state.loanDetails.activePropertyId =
          state.loanDetails.propertyDetails[
            state.loanDetails.propertyDetails.length - 1
          ].propertyId;
      } else {
        state.loanDetails.activePropertyId =
          state.loanDetails.propertyDetails[indexOfDeletedProperty].propertyId;
      }
    },
    updatePrimaryGuarantor: (state, action) => {
      const { guarantorId, guarantorInformation } = action.payload;
      const result = state.loanDetails.bridgeLoanGuarantorInformation.find(
        (element) => element?.guarantorId === guarantorId
      );
      const oldPrimaryGuarantor =
        state.loanDetails.bridgeLoanGuarantorInformation.find(
          (element) => element.payload.isPrimaryGuarantor === true
        );
      if (result && oldPrimaryGuarantor) {
        const guarantorInformationBody =
          guarantorInformation.bridgeLoanGuarantorInformation[0];
        oldPrimaryGuarantor.payload.isPrimaryGuarantor = false;
        result.payload =
          guarantorInformation && guarantorInformationBody.payload
            ? {
              ...result.payload,
              ...guarantorInformationBody.payload
            }
            : result.payload;

        state.loanDetails.bridgeLoanGuarantorInformation =
          state.loanDetails.bridgeLoanGuarantorInformation.map((element) => {
            if (element?.guarantorId === guarantorId) {
              return result;
            }
            if (element?.guarantorId === oldPrimaryGuarantor?.guarantorId) {
              return oldPrimaryGuarantor;
            }
            return element;
          });
      }
    },
    // updateThirtyYearLoanPrimaryGuarantor: (state, action) => {
    //   const { guarantorId, guarantorInformation } = action.payload;
    //   const result = state.loanDetails.thirtyYearGuarantorInformation.find(
    //     (element) => element?.guarantorId === guarantorId
    //   );
    //   const oldPrimaryGuarantor =
    //     state.loanDetails.thirtyYearGuarantorInformation.find(
    //       (element) => element?.payload?.isPrimaryGuarantor === true
    //     );
    //   if (result && oldPrimaryGuarantor) {
    //     const guarantorInformationBody =
    //       guarantorInformation.thirtyYearGuarantorInformation[0];
    //     oldPrimaryGuarantor.payload.isPrimaryGuarantor = false;
    //     result.payload =
    //       guarantorInformation && guarantorInformationBody.payload
    //         ? {
    //             ...result.payload,
    //             ...guarantorInformationBody.payload
    //           }
    //         : result.payload;

    //     state.loanDetails.thirtyYearGuarantorInformation =
    //       state.loanDetails.thirtyYearGuarantorInformation.map((element) => {
    //         if (element?.guarantorId === guarantorId) {
    //           return result;
    //         }
    //         if (element?.guarantorId === oldPrimaryGuarantor?.guarantorId) {
    //           return oldPrimaryGuarantor;
    //         }
    //         return element;
    //       });
    //   }
    // },
    fetchEditHistory: (state, action) => {
      const { audit, customers, noKeysUpdate } = action.payload;
      if (audit !== undefined) {
        let editHistoryCopy = JSON.parse(JSON.stringify(audit));
        Object.keys(editHistoryCopy).forEach(function (key, index) {
          if (editHistoryCopy[key].length > 3) {
            editHistoryCopy[key].splice(3, editHistoryCopy[key].length);
          }
          editHistoryCopy[key].forEach((item: any, index: number) => {
            if (item?.key.includes("loanUserType")) {
              item.preValue =
                item.preValue === "Borrower" ? "Entity" : item.preValue;
              item.value = item.value === "Borrower" ? "Entity" : item.value;
            }
            try {
              if (allDateFields.includes(item.key.split(".").pop())) {
                const date = MMMDDYYYYDateFormat(item.preValue);
                item.preValue =
                  JSON.stringify(new Date(item.preValue)) !== "null" &&
                    date !== "Invalid date"
                    ? date
                    : item.preValue;
                const editedDate = MMMDDYYYYDateFormat(item.value);
                item.value =
                  JSON.stringify(new Date(item.value)) !== "null" &&
                    editedDate !== "Invalid date"
                    ? editedDate
                    : item.value;
              }
            } catch (e) {
              console.log(e, item);
            }
            if (item.preValue === null || item.preValue === "") {
              item.preValue = "-";
            }
            if (
              item.action === "create" &&
              [
                "",
                "initial",
                "Initial",
                "updated by system",
                "value recalculated"
              ].includes(item.comments)
            ) {
              editHistoryCopy[key].splice(index, 1);
            }

            for (let i = 0; i < customers.length; i++) {
              if (item.updatedBy === customers[i].partyId) {
                item.updatedBy = `${customers[i].firstName} ${customers[i].lastName} `;
              }
            }
            item.updatedBy = item.updatedBy === null ? "" : item.updatedBy;
          });
          if (editHistoryCopy[key].length === 0) {
            delete editHistoryCopy[key];
          }
        });
        state.editHistory = editHistoryCopy;
        const editedFieldKeys = Object.keys(editHistoryCopy)
        if (!noKeysUpdate) {
          state.editedFields = editedFieldKeys;
        }
      }
    },
    setEditedFieldKeys: (state, action) => {
      const fieldKeys = action.payload;
      const editedFields = new Set<string>([...state.editedFields, ...new Set<string>(fieldKeys)]);
      state.editedFields = Array.from(editedFields);
    },
    fetchInfoIconContent: (state, action) => {
      const { infoContent, infoType } = action.payload;
      switch (infoType) {
        case "loan": {
          state.infoContentLoan = infoContent;
          break;
        }
        case "properties": {
          state.infoContentProperty = infoContent;
          break;
        }
        case "result": {
          state.infoContentResult = infoContent;
          break;
        }
      }
    },
    updateBridgeLoanGurantorRedux: (state, action) => {
      const { guarantorId, guarantorInformation } = action.payload;
      try {
        const result = state.loanDetails.bridgeLoanGuarantorInformation.find(
          (element) => element?.guarantorId === guarantorId
        );
        if (result) {
          const guarantorInformationBody =
            guarantorInformation.bridgeLoanGuarantorInformation[0];
          result.payload =
            guarantorInformation && guarantorInformationBody.payload
              ? {
                ...result.payload,
                ...guarantorInformationBody.payload
              }
              : result.payload;
          result.errors =
            guarantorInformationBody.errors ||
              guarantorInformationBody.errors === 0
              ? guarantorInformationBody.errors
              : result.errors;
          state.loanDetails.bridgeLoanGuarantorInformation =
            state.loanDetails.bridgeLoanGuarantorInformation.map((element) => {
              if (element?.guarantorId === guarantorId) {
                return result;
              }
              return element;
            });
        } else {
          state.loanDetails.bridgeLoanGuarantorInformation.push(
            guarantorInformation[0]
          );
        }
      } catch (e) {
        console.error(e);
        state.loanDetails.bridgeLoanGuarantorInformation.push(
          guarantorInformation[0]
        );
      }
    },
    primaryGuarantorOrBorrowerCheck: (state, action) => {
      state.missingPrimaryGuarantorOrBorrower = action.payload;
    },
    missingEditComments: (state, action) => {
      const { isMissing } = action.payload;
      state.missingEditedComments = isMissing;
    },
    fetchPartyId: (state, action) => {
      const data = action.payload;
      let tempObj = { ...state.nameWithPartyId };
      for (let i = 0; i < data?.length; i++) {
        if (data[i].accountName) {
          if (!tempObj[data[i].partyId]) {
            tempObj[data[i].partyId] = data[i].accountName;
          }
        } else if (!tempObj[data[i].partyId]) {
          data[i].firstName = data[i].firstName
            ? data[i].firstName.charAt(0).toUpperCase() +
            data[i].firstName.slice(1)
            : data[i].firstName;
          data[i].middleName = data[i].middleName
            ? data[i].middleName.charAt(0).toUpperCase() +
            data[i].middleName.slice(1)
            : data[i].middleName;
          data[i].lastName = data[i].lastName
            ? data[i].lastName.charAt(0).toUpperCase() +
            data[i].lastName.slice(1)
            : data[i].lastName;
          tempObj[data[i].partyId] = [
            data[i].firstName,
            data[i].middleName,
            data[i].lastName
          ]
            .filter(Boolean)
            .join(" ");
        }
      }
      state.nameWithPartyId = tempObj;
    },
    setPartyId: (state, action) => {
      state.nameWithPartyId = {
        ...state.nameWithPartyId,
        ...action.payload
      };
    },
    updateBorrowerDetails: (state, action) => {
      const { borrowerId, borrowerDetails } = action.payload;
      const borrowerBeingEdited =
        state.loanDetails.bridgeLoanBorrowerInformation.find(
          (item) => item.borrowerId === borrowerId
        );
      if (borrowerBeingEdited) {
        const newBorrowerPayload = {
          ...borrowerBeingEdited.payload,
          ...borrowerDetails.payload
        };
        const newBorrowerObj = {
          ...borrowerBeingEdited,
          ...{ payload: newBorrowerPayload }
        };
        const borrowerArray =
          state.loanDetails.bridgeLoanBorrowerInformation.map((item) => {
            if (item.borrowerId !== borrowerId) {
              return item;
            }
            return newBorrowerObj;
          });
        state.loanDetails.bridgeLoanBorrowerInformation = borrowerArray;
      } else {
        const borrowerArray = [
          ...state.loanDetails.bridgeLoanBorrowerInformation,
          ...[borrowerDetails]
        ];
        state.loanDetails.bridgeLoanBorrowerInformation = borrowerArray;
      }
    },
    updateFESBorrowerDetails: (state, action) => {
      const { borrowerDetail } = action.payload;
      state.loanDetails.fesBorrowerInformation = {
        ...state.loanDetails.fesBorrowerInformation,
        ...borrowerDetail.payload
      }
    },
    updatePrimaryBorrower: (state, action) => {
      const { borrowerId } = action.payload;
      const currentPrimaryBorrower =
        state.loanDetails.bridgeLoanBorrowerInformation.find(
          (item) => item.payload.isPrimaryBorrower
        );
      const borrowerChosen =
        state.loanDetails.bridgeLoanBorrowerInformation.find(
          (item) => item.borrowerId === borrowerId
        );
      if (borrowerChosen) {
        const newBorrowerObj = {
          ...borrowerChosen,
          ...{
            payload: {
              ...borrowerChosen.payload,
              ...{ isPrimaryBorrower: true }
            }
          }
        };
        if (currentPrimaryBorrower) {
          const currentPrimaryBorrowerChanged = {
            ...currentPrimaryBorrower,
            ...{
              payload: {
                ...currentPrimaryBorrower.payload,
                ...{ isPrimaryBorrower: false }
              }
            }
          };
          state.loanDetails.bridgeLoanBorrowerInformation =
            state.loanDetails.bridgeLoanBorrowerInformation.map((item) => {
              if (item.borrowerId === borrowerId) {
                return newBorrowerObj;
              }
              if (item.borrowerId === currentPrimaryBorrower.borrowerId) {
                return currentPrimaryBorrowerChanged;
              }
              return item;
            });
        }
      }
    },
    deleteBorrowerDetails: (state, action) => {
      const { borrowerId, borrowerType, onlyClearValues } = action.payload;
      if (onlyClearValues) {
        const borrowerBeingEdited =
          state.loanDetails.bridgeLoanBorrowerInformation.find(
            (item) => item.borrowerId === borrowerId
          );
        if (borrowerBeingEdited) {
          let newBorrowerPayload: any;
          const nextIndividualBorrower =
            state.loanDetails.bridgeLoanBorrowerInformation.find(
              (item) =>
                item.borrowerId !== borrowerId &&
                item.payload.borrowerType === "Individual"
            );
          if (borrowerType === "Entity") {
            newBorrowerPayload = {
              ...emptyBorrower.payload,
              ...{ isPrimaryBorrower: false, borrowerType }
            };
          } else {
            const isPrimary = !nextIndividualBorrower;
            newBorrowerPayload = {
              ...emptyBorrower.payload,
              ...{ isPrimaryBorrower: isPrimary, borrowerType }
            };
          }
          const newBorrowerObj = {
            ...borrowerBeingEdited,
            ...{ payload: newBorrowerPayload }
          };
          let borrowerArray: BorrowerInformation[] = [];
          if (borrowerBeingEdited.payload.isPrimaryBorrower) {
            borrowerArray = state.loanDetails.bridgeLoanBorrowerInformation.map(
              (item) => {
                if (item.borrowerId !== borrowerId) {
                  if (
                    nextIndividualBorrower?.payload &&
                    item.borrowerId === nextIndividualBorrower.borrowerId
                  ) {
                    nextIndividualBorrower.payload.isPrimaryBorrower = true;
                    return nextIndividualBorrower;
                  }
                  return item;
                }
                return newBorrowerObj;
              }
            );
          } else {
            borrowerArray = state.loanDetails.bridgeLoanBorrowerInformation.map(
              (item) => {
                if (item.borrowerId !== borrowerId) {
                  return item;
                }
                return newBorrowerObj;
              }
            );
          }
          state.loanDetails.bridgeLoanBorrowerInformation = borrowerArray;
        }
      } else {
        const borrowerBeingEdited =
          state.loanDetails.bridgeLoanBorrowerInformation.find(
            (item: BorrowerInformation) => item.borrowerId === borrowerId
          );
        if (
          borrowerBeingEdited &&
          borrowerBeingEdited.payload?.isPrimaryBorrower
        ) {
          const nextIndividualBorrower =
            state.loanDetails.bridgeLoanBorrowerInformation.find(
              (item) =>
                item.borrowerId !== borrowerId &&
                item.payload.borrowerType === "Individual"
            );
          const borrowerArray =
            state.loanDetails.bridgeLoanBorrowerInformation.filter((item) => {
              if (item.borrowerId !== borrowerId) {
                if (
                  nextIndividualBorrower?.payload &&
                  item.borrowerId === nextIndividualBorrower.borrowerId
                ) {
                  nextIndividualBorrower.payload.isPrimaryBorrower = true;
                  return nextIndividualBorrower;
                }
                return item;
              }
            });
          state.loanDetails.bridgeLoanBorrowerInformation = borrowerArray;
        } else {
          const borrowerArray =
            state.loanDetails.bridgeLoanBorrowerInformation.filter(
              (item) => item.borrowerId !== borrowerId
            );
          state.loanDetails.bridgeLoanBorrowerInformation = borrowerArray;
        }
      }
    },
    setAutoSaveCall: (state, action) => {
      const autoSave = action.payload;
      state.autoSave = autoSave;
    },
    increaseAutoSaveCallCount: (state) => {
      state.autoSaveCount = state.autoSaveCount + 1;
    },
    decreaseAutoSaveCallCount: (state) => {
      state.autoSaveCount = state.autoSaveCount - 1;
    },
    // updateThirtyYearLoanGuarantor: (state, action) => {
    //   const { guarantorId, guarantorInformation } = action.payload;
    //   const result = state.loanDetails.thirtyYearGuarantorInformation.find(
    //     (element) => element?.guarantorId === guarantorId
    //   );
    //   if (result) {
    //     const guarantorInformationBody =
    //       guarantorInformation.thirtyYearGuarantorInformation[0];
    //     result.payload =
    //       guarantorInformation && guarantorInformationBody.payload
    //         ? {
    //             ...result.payload,
    //             ...guarantorInformationBody.payload
    //           }
    //         : result.payload;
    //     result.errors =
    //       guarantorInformationBody.errors ||
    //       guarantorInformationBody.errors === 0
    //         ? guarantorInformationBody.errors
    //         : result.errors;
    //     state.loanDetails.thirtyYearGuarantorInformation =
    //       state.loanDetails.thirtyYearGuarantorInformation.map((element) => {
    //         if (element?.guarantorId === guarantorId) {
    //           return result;
    //         }
    //         return element;
    //       });
    //   } else {
    //     state.loanDetails.thirtyYearGuarantorInformation.push(
    //       guarantorInformation[0]
    //     );
    //   }
    // },
    deleteWaiverFromActInt: (state, action) => {
      const waiverId = action.payload;
      const waiversObj = state?.activeInteractionsModalArray?.filter(
        (item: any) => {
          return item._source?.resourceId !== waiverId;
        }
      );
      state.activeInteractionsModal = true;
      state.activeInteractionsModalCount = waiversObj.length;
      state.activeInteractionsModalArray = waiversObj;
    },
    getPropertyDetails: (state, action) => {
      const { propertyDetails, keepTab } = action.payload;
      state.loanDetails.propertyDetails = propertyDetails;
      if (keepTab !== true) {
        state.loanDetails.activePropertyId = propertyDetails[0]?.propertyId;
      }
    },
    updateLoanStatusLoan: (state, action) => {
      state.error = null;
      state.loanDetails.loanState = action.payload;
      state.loading = false;
    },
    updateAssignedTo: (state, action) => {
      state.loanDetails.assignedTo = action.payload;
    },
    updateILPStateRedux: (state, action) => {
      state.error = null;
      state.loanDetails.workflowState = action.payload;
      state.loading = false;
    },
    updateTTFStateRedux: (state, action) => {
      state.error = null;
      state.loanDetails.tapeToFileStatus = action.payload;
      state.loading = false;
    },
    updateCompleteDateRedux: (state, action) => {
      state.loanDetails.loanLandmarks.push({
        comments: null,
        createdBy: getCookie("person_id"),
        date: new Date().toISOString(),
        name: "update_sub_status",
        value: InternalDDStatusEnum.ReviewCompleted
      });
    },
    updatePropertyDetailsRedux: (state, action) => {
      const { propertyId, propertyDetails } = action.payload;
      const result = state.loanDetails.propertyDetails.find(
        (element) => element.propertyId === propertyId
      );

      if (result) {
        const {
          propertyEconomics,
          propertyInformation,
          propertyLocation,
          propertyDefaultName,
          propertySummary,
          fesPropertyEconomics,
          fesPropertyInformation,
        } = propertyDetails;
        result.propertyDefaultName =
          propertyDefaultName === ""
            ? propertyDefaultName
            : result.propertyDefaultName;
        if (propertyInformation)
          result.propertyInformation = {
            payload: {
              ...result?.propertyInformation?.payload,
              ...propertyInformation.payload
            },
            errors: propertyInformation?.errors ?? result.propertyInformation.errors
          };
        if (propertyLocation)
          result.propertyLocation = {
            payload: {
              ...result?.propertyLocation?.payload,
              ...propertyLocation.payload
            },
            errors: propertyLocation?.errors ?? result.propertyLocation.errors
          };
        if (propertyEconomics)
          result.propertyEconomics = {
            payload: {
              ...result?.propertyEconomics?.payload,
              ...propertyEconomics.payload
            },
            errors: propertyEconomics?.errors ?? result.propertyEconomics.errors
          };
        if (propertySummary)
          result.propertySummary = {
            payload: {
              ...result?.propertySummary?.payload,
              ...propertySummary.payload
            },
            errors: propertySummary?.errors ?? result.propertySummary.errors
          };
        if (fesPropertyEconomics)
          result.fesPropertyEconomics = {
            payload: {
              ...result?.fesPropertyEconomics?.payload,
              ...fesPropertyEconomics.payload
            },
            errors: fesPropertyEconomics?.errors ?? result.fesPropertyEconomics.errors
          };
        if (fesPropertyInformation)
          result.fesPropertyInformation = {
            payload: {
              ...result?.fesPropertyInformation?.payload,
              ...fesPropertyInformation.payload
            },
            errors: fesPropertyInformation?.errors ?? result.fesPropertyInformation.errors
          };
        state.loanDetails.propertyDetails =
          state.loanDetails.propertyDetails.map((element) => {
            if (element.propertyId === propertyId) {
              return result;
            }
            return element;
          });
      } else {
        const { position } = action.payload;
        if (position) {
          state.loanDetails.propertyDetails.splice(position, 0, {
            ...propertyDetails,
            propertyId
          });
        } else {
          state.loanDetails.propertyDetails.push({
            ...propertyDetails,
            propertyId
          });
        }
        state.loanDetails.activePropertyId = propertyId;
      }
      state.loading = false;
    },
    updateBridgeLoanDetailsRedux: (state, action) => {
      const { bridgeLoanDetails } = action.payload;
      const result = {
        bridgeLoanBorrowerInformation:
          state.loanDetails.bridgeLoanBorrowerInformation,
        bridgeLoanGuarantorInformation:
          state.loanDetails.bridgeLoanGuarantorInformation,
        bridgeLoanEconomics: state.loanDetails.bridgeLoanEconomics,
        bridgeLoanInformation: state.loanDetails.bridgeLoanInformation
      };
      const { bridgeLoanEconomics, bridgeLoanInformation } = bridgeLoanDetails;

      result.bridgeLoanEconomics.payload = bridgeLoanEconomics
        ? {
          ...result.bridgeLoanEconomics.payload,
          ...bridgeLoanEconomics.payload
        }
        : result.bridgeLoanEconomics.payload;
      result.bridgeLoanInformation.payload = bridgeLoanInformation
        ? {
          ...result.bridgeLoanInformation.payload,
          ...bridgeLoanInformation.payload
        }
        : result.bridgeLoanInformation.payload;

      result.bridgeLoanEconomics.errors = bridgeLoanEconomics
        ? bridgeLoanEconomics.errors
        : result.bridgeLoanEconomics.errors;
      result.bridgeLoanInformation.errors = bridgeLoanInformation
        ? bridgeLoanInformation.errors
        : result.bridgeLoanInformation.errors;

      state.loanDetails.bridgeLoanBorrowerInformation =
        result.bridgeLoanBorrowerInformation;
      state.loanDetails.bridgeLoanGuarantorInformation =
        result.bridgeLoanGuarantorInformation;
      state.loanDetails.bridgeLoanEconomics = result.bridgeLoanEconomics;
      state.loanDetails.bridgeLoanInformation = result.bridgeLoanInformation;

      state.loading = false;
    },
    updateThirtyYearLoanDetailsRedux: (state, action) => {
      const { thirtyYearLoanDetails } = action.payload;

      const result = {
        thirtyYearBorrowerInformation:
          state.loanDetails.thirtyYearBorrowerInformation,
        thirtyYearGuarantorInformation:
          thirtyYearLoanDetails.thirtyYearGuarantorInformation,
        thirtyYearLoanEconomics: state.loanDetails.thirtyYearLoanEconomics,
        thirtyYearLoanInformation: state.loanDetails.thirtyYearLoanInformation
      };
      const {
        thirtyYearBorrowerInformation,
        thirtyYearLoanInformation,
        thirtyYearLoanEconomics
      } = thirtyYearLoanDetails;
      result.thirtyYearBorrowerInformation.payload =
        thirtyYearBorrowerInformation
          ? {
            ...result.thirtyYearBorrowerInformation.payload,
            ...thirtyYearBorrowerInformation.payload
          }
          : result.thirtyYearBorrowerInformation.payload;
      result.thirtyYearLoanInformation.payload = thirtyYearLoanInformation
        ? {
          ...result.thirtyYearLoanInformation.payload,
          ...thirtyYearLoanInformation.payload
        }
        : result.thirtyYearLoanInformation.payload;
      result.thirtyYearLoanEconomics.payload = thirtyYearLoanEconomics
        ? {
          ...result.thirtyYearLoanEconomics.payload,
          ...thirtyYearLoanEconomics.payload
        }
        : result.thirtyYearLoanEconomics.payload;
      result.thirtyYearBorrowerInformation.errors =
        thirtyYearBorrowerInformation
          ? thirtyYearBorrowerInformation.errors
          : result.thirtyYearBorrowerInformation.errors;
      result.thirtyYearLoanInformation.errors = thirtyYearLoanInformation
        ? thirtyYearLoanInformation.errors
        : result.thirtyYearLoanInformation.errors;
      result.thirtyYearLoanEconomics.errors = thirtyYearLoanEconomics
        ? thirtyYearLoanEconomics.errors
        : result.thirtyYearLoanEconomics.errors;

      state.loanDetails.thirtyYearBorrowerInformation =
        result.thirtyYearBorrowerInformation;
      state.loanDetails.thirtyYearLoanInformation =
        result.thirtyYearLoanInformation;
      state.loanDetails.thirtyYearBorrowerInformation =
        result.thirtyYearBorrowerInformation;

      state.loading = false;
    },
    setActivePropertyId: (state, action) => {
      const propertyId = action.payload;
      state.loanDetails.activePropertyId = propertyId;
    },
    setloanToDelete: (state, action) => {
      const { loanNumber } = action.payload;
      state.loanToDelete = loanNumber;
    },
    setLoanToConvert: (state, action) => {
      const { loanNumber } = action.payload;
      state.loanToConvert = loanNumber;
    },
    loanToCheckUpload: (state, action) => {
      const { loanNumber } = action.payload;
      state.loanTocheckUpload = loanNumber;
    },
    setActiveTabAndPropertyRedux: (state, action) => {
      const { activeTab, propertyId, callback } = action.payload;
      state.activeTab = activeTab;
      if (propertyId) state.loanDetails.activePropertyId = propertyId;
      if (callback) callback();
    },
    setActiveEditLoanSection: (state, action) => {
      const { activeSection, addOrDelete } = action.payload;
      if (addOrDelete === "add") {
        state.selectedEditSection += activeSection;
      } else {
        state.selectedEditSection = state.selectedEditSection.replace(
          activeSection,
          ""
        );
      }
    },
    validateFormRedux: (state, action) => {
      const { isSubmitValidation } = action.payload;
      if (isSubmitValidation) {
        state.submitForm.clicked = true;
      }
      state.validateForm = {
        validate: true,
        isSubmitValidation
      };
    },
    validateFormForSubmit: (state, action) => {
      const { error } = action.payload;
      state.submitForm = { ...state.submitForm, ...error };
    },
    updateFilesInDocsRedux: (state, action) => {
      const { file, currentSection } = action.payload;

      const myFileArray = copyFileList(file);
      const documentDetails =
        currentSection === "postClose"
          ? state.loanDetails.documentDetails
          : state.loanDetails.prePurchaseDocDetails;
      documentDetails.files = myFileArray;
    },
    // updatePercentageInDocs: (state, action) => {
    //   const { uniqueId, percentage, currentSection } = action.payload;
    //   const documentDetails =
    //     currentSection === "postClose"
    //       ? state.loanDetails.documentDetails
    //       : state.loanDetails.prePurchaseDocDetails;
    //   const result = documentDetails.files.find(
    //     (element) => element.uniqueId === uniqueId
    //   );
    //   if (result && result.percentCompleted < percentage) {
    //     result.percentCompleted = percentage;
    //   }
    //   documentDetails.files = documentDetails.files.map((element) => {
    //     if (element.uniqueId === uniqueId) {
    //       return result;
    //     }
    //     return element;
    //   });
    // },
    updateSuccessCountInDocs: (state, action) => {
      const { success, noOfDeletedFiles, currentSection } = action.payload;
      const documentDetails =
        currentSection === "postClose"
          ? state.loanDetails.documentDetails
          : state.loanDetails.prePurchaseDocDetails;
      let successCount;
      if (!success) {
        successCount = documentDetails.successfulUploads
          ? documentDetails.successfulUploads - noOfDeletedFiles
          : 0;
      } else {
        successCount = documentDetails.successfulUploads + 1;
      }
      documentDetails.successfulUploads = successCount;
    },
    updateTagsInDocs: (state, action) => {
      const { tags, filesList, currentSection } = action.payload;
      let myFile = copyFileList(filesList);
      const myFiles = updateTagCategory(myFile, tags);
      const documentDetails =
        currentSection === "postClose"
          ? state.loanDetails.documentDetails
          : state.loanDetails.prePurchaseDocDetails;
      documentDetails.taggedArray = tags;
      documentDetails.files = myFiles;
    },
    updateFailureCountInDocs: (state, action) => {
      const { error, currentSection } = action.payload;
      const documentDetails =
        currentSection === "postClose"
          ? state.loanDetails.documentDetails
          : state.loanDetails.prePurchaseDocDetails;
      let errors;
      if (error) {
        errors = documentDetails.failedUploads + 1;
      } else {
        errors = documentDetails.failedUploads
          ? documentDetails.failedUploads - 1
          : 0;
      }
      documentDetails.failedUploads = errors < 0 ? 0 : errors;
    },
    setDocsByIdSuccess: (state, action) => {
      const {
        docs,
        files,
        taggedArray,
        failedUploads,
        successfulUploads,
        confidenceScoreMap,
        currentSection
      } = action.payload;
      const documentDetails = currentSection?.includes("postClose")
        ? state.loanDetails.documentDetails
        : state.loanDetails.prePurchaseDocDetails;
      documentDetails.files = docs || files;
      documentDetails.taggedArray = taggedArray;
      documentDetails.failedUploads = failedUploads;
      documentDetails.successfulUploads = successfulUploads;
      documentDetails.docsLoaded = true;
      documentDetails.confidenceScoreMap = confidenceScoreMap;
    },
    updateCurrentFilesUploaded: (state, action) => {
      const { currentTab, isClear } = action.payload;
      const documentDetails = currentTab?.includes("postClose")
        ? state.loanDetails.documentDetails
        : state.loanDetails.prePurchaseDocDetails;
      documentDetails.currentFilesUploaded = isClear
        ? 0
        : documentDetails.currentFilesUploaded + 1;
    },
    setTermsheetByIdSuccess: (state, action) => {
      const { docs, failedUploads, successfulUploads } = action.payload;
      const { termSheetDetails } = state.loanDetails;
      termSheetDetails.files = docs;
      termSheetDetails.failedUploads = failedUploads;
      termSheetDetails.successfulUploads = successfulUploads;
      termSheetDetails.docsLoaded = true;
    },
    setSectionPreviousState: (state, action) => {
      const { dataObj } = action.payload;
      const Key = action.payload.keyInfo;

      if (action.payload.propertyData) {
        switch (Key) {
          case "fesPropertyEconomics":
            state.loanDetails.propertyDetails[0].fesPropertyEconomics.payload = dataObj;
            break;
          case "fesPropertyInformation":
            state.loanDetails.propertyDetails[0].fesPropertyInformation.payload =
              dataObj;
            break;
          default:
            break;
        }
      } else {
        state.loanDetails[Key] = dataObj;
      }
    },
    deletePropertyUnits: (state, action) => {
      const propertyDetails = action.payload;
      state.loanDetails.propertyDetails = propertyDetails;
    },
    updatePropertyUnits: (state, action) => {
      const { unitInformation, unitId, activePropertyId } = action.payload;
      const propertyIndex = state.loanDetails.propertyDetails.findIndex(
        (e) => e.propertyId === activePropertyId
      );
      if (propertyIndex >= 0) {
        const result = state.loanDetails.propertyDetails[
          propertyIndex
        ].unitsInformation.find((element: any) => [element.unitId, element?.payload?.unitParamId].includes(unitId));
        if (result) {
          result.payload = unitInformation?.payload
            ? {
              ...result.payload,
              ...unitInformation.payload
            }
            : result.payload;
          result.errors =
            unitInformation.errors || unitInformation.errors === 0
              ? unitInformation.errors
              : result.errors;
          state.loanDetails.propertyDetails[propertyIndex].unitsInformation =
            state.loanDetails.propertyDetails[
              propertyIndex
            ].unitsInformation.map((element: any) => {
              if ([element?.unitId, element?.payload?.unitParamId].includes(unitId)) {
                return result;
              }
              return element;
            });
        } else {
          state.loanDetails.propertyDetails[
            propertyIndex
          ].unitsInformation.push(unitInformation);
        }
      }
    },
    updateCreateLoanStoreForSelectedLoan: (state, action) => {
      const { data, fullReplace } = action.payload;
      if (fullReplace) {
        return data;
      }
      state.validateForm.validate = true;
      state.loanDetails = {
        ...state.loanDetails,
        ...data.loanDetails
      };
    },
    resetLoanStore: (state) => initialState,
    loanTypeFailed: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    },
    setSelectedLoanList: (state, action) => {
      state.selectedLoans = action.payload;
    },
    updateSelectedList: (state, action) => {
      state.selectedItems = action.payload;
    },
    selectAllLoans: (state, action) => {
      state.selectedAllLoans = action.payload;
    },
    loanUserMapSuccess: (state, action) => {
      state.loanUserMap = action.payload.loanUserMap;
    },
    updateLoanStage: (state, action) => {
      state.loanStage = action.payload;
    },
    updateTimeInterval: (state, action) => {
      state.timeInterval = action.payload;
    },
    updateCompactTab: (state, action) => {
      state.compactTabSelected = action.payload;
    },
    activeIntFailed: (state) => {
      state.activeIntError = true;
    },
    updateLoanlistView: (state, action) => {
      state.viewtype = action.payload;
    },
    updateCompactTabIndex: (state, action) => {
      state.compactTabIndex = action.payload;
    },
    updateSelectedLoansRedux: (state, action) => {
      state.loanIds = action.payload.loanIds;
      state.selectedLoanDetails = action.payload.selectedLoanDetails;
    },
    updateLoanListSearch: (state, action) => {
      state.searchValue = action.payload.value;
    },
    setSearchType: (state, action) => {
      state.advanceSearch = action.payload;
    },
    updateLoadingFlag: (state, action) => {
      state.loading = action.payload.loadingFlag;
    },
    showSummaryCheckListModal: (state) => {
      state.summaryCheckListModal = true;
    },
    hideSummaryCheckListModal: (state) => {
      state.summaryCheckListModal = false;
    },
    singleLoanRow: (state, action) => {
      state.singleLoanDetails = action.payload;
    },
    switchLoanSizerType: (state, action) => {
      state.loanDetails.loanSizerType = action.payload;
    },
    setRateSheetData: (state, action) => {
      state.rateSheetData = action.payload.rateSheetData;
    },
    setVersionDate: (state, action) => {
      const { response, activeVersion, dataType } = action.payload;
      response.forEach((data: { version: any; effectiveStartDate: any }) => {
        if (data.version === activeVersion) {
          if (dataType === "RATE_SHEET") {
            state.versionDate = data.effectiveStartDate;
          } else {
            state.ruleVersionDate = data.effectiveStartDate;
          }
        }
      });
    },
    setRateSheetVersions: (state, action) => {
      state.rateSheetVersions = action.payload.rateSheetVersions;
    },
    setOverlaysVersions: (state, action) => {
      state.overlaysVersions = action.payload.overlaysVersions;
    },
    updateSelectedVersionRedux: (state, action) => {
      const { sectionName, selectedVersion, reset } = action.payload;
      if (reset) {
        state.selectedOverlayVersion = selectedVersion;
        state.selectedRatesVersion = selectedVersion;
        state.loanDetails.thirtyYearLoanInformation.payload.rateLockedRateSheet = selectedVersion;
      } else if (sectionName.includes("Overlays")) {
        state.selectedOverlayVersion = selectedVersion;
      } else {
        state.selectedRatesVersion = selectedVersion;
        state.loanDetails.thirtyYearLoanInformation.payload.rateLockedRateSheet = selectedVersion;
      }
    },
    setDueDiligenceInfo: (state, action) => {
      state.dueDiligenceInformation = action.payload.dueDiligenceInfo;
    },
    updateLoanRuleVersionsRedux: (state, action) => {
      state.loanDetails.loanRuleVersions = action.payload.loanRuleVersions;
      state.loanDetails.selectedLoanRuleVersions =
        action.payload.loanRuleVersions;
    },
    updateSelectedLoanRuleVersions: (state, action) => {
      state.loanDetails.selectedLoanRuleVersions =
        action.payload;
    },
    updateToggleSelectionsRedux: (state, action) => {
      state.toggleSelections = action.payload.toggleSelections;
    },
    updateOnHoldInfo: (state, action) => {
      const { data } = action.payload.onHoldInfo;
      state.loanDetails.onHoldBy = data;
    },
    updateLoanLandmark: (state, action) => {
      state.loanDetails.loanLandmarks = action.payload.data;
    },
    customerInfo: (state, action) => {
      state.customerInfo = action.payload.customerInfo;
    },
    updateConfidenceScore: (state, action) => {
      const { documentDetails } = state.loanDetails;
      documentDetails.confidenceScoreMap[action.payload] = 1;
    },
    setLoanTableFunding: (state, action) => {
      state.loanDetails.loanDetailId.fundingType = action.payload?.fundingType;
    },
    setWarehouseFunding: (state, action) => {
      state.loanDetails.loanDetailId.isWarehouseFunded =
        action.payload?.isWarehouseFunded;
    },
    setIsLoanAdvanceFunded: (state, action) => {
      state.isAdvanceFunded = action.payload;
    },
    setTableFundingFlag: (state, action) => {
      state.isLoanTableFunded = action.payload;
    },
    setWarehouseFundingFlag: (state, action) => {
      state.isWarehouseFunded = action.payload;
    },
    updateEligiblePartners: (state, action) => {
      state.error = null;
      const eligibleTakeoutPartners: string[] = action.payload;
      state.loanDetails.thirtyYearLoanInformation.payload.eligibleTakeoutPartners = eligibleTakeoutPartners;
      state.loading = false;
    }
  }
});

export const {
  setTermsheetByIdSuccess,
  setLoading,
  setCustomColumnLoanList,
  setOpenColumnReorder,
  setGetLoanLoading,
  updateLoanLevelData,
  updateLoanConfig,
  updateSummaryDetailsReducer,
  updateSummaryDetailsProperty,
  addExceptionGrade,
  addInternalGrade,
  updateTTFSubmitState,
  setLoanPreviousState,
  updatePreviousStageRedux,
  updateIsWithdrawnState,
  setLoanByIdSuccess,
  condoQuestOpened,
  // getPropertyFailed,
  gurrantorFieldChanged,
  borrowerFieldChanged,
  updateBridgeLoanGroundUp,
  unitFieldChanged,
  setLoanByIdFailed,
  saveLoanDetailsForm,
  getCreditScoreBucketsSuccess,
  getLoanPurposesSuccess,
  getAppraisalVendorsSuccess,
  loanTypeSuccess,
  setDocsByIdSuccess,
  setMandatoryResultsSuccess,
  setMandatoryDocumentsSuccess,
  tagsSuccess,
  dashboardTagsSuccess,
  valueEditing,
  loanListSuccess,
  loanListCount,
  setLoanToConvert,
  updateTagsInDocs,
  loanListMultiDispatch,
  updateLoanListTabCount,
  sortLoanListSuccess,
  setRawLoanListData,
  filterDataSuccess,
  dropDownDataSuccess,
  validateFormRedux,
  setActiveEditLoanSection,
  compactSearchUpdate,
  headerFiltersUpdateSuccess,
  rescindSuccessDialog,
  activeInteractionsModal,
  updateActIntLoanList,
  loanToCheckUpload,
  setActiveInteractionsArray,
  deleteActIntAskQuest,
  setActiveTabAndPropertyRedux,
  addResponseToActiveIntArray,
  closeActiveInteractionsModal,
  filtersUpdateSuccess,
  updateSortConfigLoan,
  postLoanSuccess,
  updateSuccessCountInDocs,
  updateFesLoanDetails,
  updateFESLoanDetailsInBulk,
  setCreditSyncFlag,
  updateFesPropertyDetails,
  getFesPropertyByIdSuccess,
  setPageBlock,
  updateFailureCountInDocs,
  deleteBridgeLoanGuarantor,
  updatePropertySuccess,
  updateDeletePropertyDetails,
  updatePrimaryGuarantor,
  fetchEditHistory,
  setEditedFieldKeys,
  fetchInfoIconContent,
  updateBridgeLoanGurantorRedux,
  primaryGuarantorOrBorrowerCheck,
  missingEditComments,
  fetchPartyId,
  updateFilesInDocsRedux,
  setPartyId,
  updateBorrowerDetails,
  updateFESBorrowerDetails,
  updatePrimaryBorrower,
  deleteBorrowerDetails,
  setAutoSaveCall,
  // updateThirtyYearLoanGuarantor,
  deleteWaiverFromActInt,
  getPropertyDetails,
  updateLoanStatusLoan,
  updateILPStateRedux,
  updateTTFStateRedux,
  updateCompleteDateRedux,
  updatePropertyDetailsRedux,
  updateBridgeLoanDetailsRedux,
  updateThirtyYearLoanDetailsRedux,
  setActivePropertyId,
  setloanToDelete,
  setSectionPreviousState,
  deletePropertyUnits,
  updatePropertyUnits,
  updateCreateLoanStoreForSelectedLoan,
  validateFormForSubmit,
  loanTypeFailed,
  setSelectedLoanList,
  updateSelectedList,
  selectAllLoans,
  loanUserMapSuccess,
  updateLoanStage,
  updateTimeInterval,
  updateCompactTab,
  activeIntFailed,
  updateAssignedTo,
  updateLoanlistView,
  updateCompactTabIndex,
  updateSelectedLoansRedux,
  getFesLoanByIdSuccess,
  updateLoanListSearch,
  setSearchType,
  updateLoadingFlag,
  showSummaryCheckListModal,
  hideSummaryCheckListModal,
  singleLoanRow,
  switchLoanSizerType,
  setRateSheetData,
  setVersionDate,
  setRateSheetVersions,
  setOverlaysVersions,
  updateSelectedVersionRedux,
  setDueDiligenceInfo,
  updateLoanRuleVersionsRedux,
  updateSelectedLoanRuleVersions,
  updateToggleSelectionsRedux,
  updateOnHoldInfo,
  updateLoanLandmark,
  customerInfo,
  updateConfidenceScore,
  setLoanTableFunding,
  setWarehouseFunding,
  setTableFundingFlag,
  setWarehouseFundingFlag,
  updateCurrentFilesUploaded,
  updateEligiblePartners,
  setIsLoanAdvanceFunded,
  resetLoanStore,
  increaseAutoSaveCallCount,
  decreaseAutoSaveCallCount
} = loanSlice.actions;

export const createLoanReducer = loanSlice.reducer;
