import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "200px"
    },
    commentBox: {
      "& label": {
        width: "100%",
        "& div": {
          "& input": {
            marginBottom: "20px"
          }
        }
      }
    },
    labelContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      // marginTop: "1px",
      width: "100%"
    },
    editedLabelContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: "1px",
      width: "100%"
    },
    labelInformation: {
      fontSize: 12,
      fontWeight: 600,
      color: "#8897aa",
      lineHeight: "16px",
      // height: "14px",
      "&:hover": {
        color: "#5e72e4"
      },
      display: "inline-block",
      whiteSpace: "normal"
    },
    labelRequired: {
      color: "#f75676",
      marginLeft: "4px",
      fontSize: "12px",
      // marginBottom: "4px",
      paddingTop: 1,
      display: "inline-block",
      verticalAlign: "top"
    },
    labelRequiredComments: {
      color: "#f75676",
      marginLeft: "4px",
      fontSize: "12px",
      marginBottom: "4px",
      marginTop: "5px"
    },
    iconInfoContainer: {
      height: "16px",
      textAlign: "end"
    },
    iconInfo: {
      width: "16px !important",
      height: "16px !important",
      color: "#32325d",
      "&:hover": {
        color: "#5e72e4"
      }
    },
    autoComplete: {
      "& .MuiAutocomplete-inputRoot": {
        backgroundColor: "#f7fafc",
        borderColor: "#CAD1D7",
        paddingRight: "40px !important"
      },
      "& .MuiAutocomplete-popupIndicator": {
        color: "#172B4D"
      },
      "& .MuiAutocomplete-clearIndicator": {
        color: "#172B4D"
      },
      "& .MuiChip-root": {
        backgroundColor: "#D9EFFF",
        color: "#FFF",
        borderRadius: "6px"
      },
      "& .MuiChip-label": {
        color: "#172B4D"
      },
      "& .MuiAutocomplete-tag": {
        maxWidth: `calc(90% - 6px)`
      },
      "& .MuiChip-deleteIcon": {
        color: "#7A8AA0"
      }
    },
    optionName: {
      overflow: "hidden",
      display: "inline-block",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    },
    optionWrapper: {
      display: "flex"
    }
  })
);
