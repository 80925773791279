/* eslint-disable @typescript-eslint/camelcase */
import { getCookie } from "../../utils/cookies";
import { uuidV4 } from "../../utils/utils";

export enum ttfAction {
  UPDATE_PDF_CONTROLS = "UPDATE_PDF_CONTROLS",
  UPDATE_TAB = "UPDATE_TAB",
  UPDATE_TAGGING_TAB_VALUES = "UPDATE_TAGGING_TAB_VALUES",
  UPDATE_SPLIT_PARAMS = "UPDATE_SPLIT_PARAMS",
  UPDATE_STATUS = "UPDATE_STATUS",
  UPDATE_TTF_FIELDS = "UPDATE_TTF_FIELDS",
  UPDATE_FIELDSET = "UPDATE_FIELDSET",
  UPDATE_TTF_PARAMS = "UPDATE_TTF_PARAMS",
  UPDATE_DOCUMENT_REVIEW = "UPDATE_DOCUMENT_REVIEW",
  UPDATE_DOCUMENT_SPLIT_VALUES = "UPDATE_DOCUMENT_SPLIT_VALUES",
  SET_INITIALIZED = "SET_INITIALIZED",
  UPDATE_PAGE_OR_HIGHLIGHT_SET = "UPDATE_PAGE_OR_HIGHLIGHT_SET"
}

export enum tabStatus {
  PENDING = "Pending",
  SKIPPED = "Skipped",
  INPROGRESS = "In-Progress",
  COMPLETED = "Completed"
}

export enum CLOSING_PACKAGE_STATUS {
  PREDICTION_IN_PROGRESS = "PREDICTION_IN_PROGRESS",
  PREDICTION_COMPLETED = "PREDICTION_COMPLETED",
  PREDICTION_FAILED = "PREDICTION_FAILED",
  SPLIT_IN_PROGRESS = "SPLIT_IN_PROGRESS",
  SPLIT_COMPLETED = "SPLIT_COMPLETED",
  SPLIT_FAILED = "SPLIT_FAILED",
  PENDING = "Pending",
  SKIPPED = "Skipped",
  INPROGRESS = "Split_In-Progress",
  SAVE_IN_PROGRESS = "In-Progress",
  COMPLETED = "Completed",
  REVIEW_PENDING = "REVIEW_PENDING",
  REVIEW_IN_PROGRESS = "REVIEW_IN_PROGRESS",
  REVIEW_COMPLETED = "REVIEW_COMPLETED"
}

export enum tabIds {
  DOCUMENTS_SPLIT = "DOCUMENTS_SPLIT",
  DOCUMENT_TAGGING = "DOCUMENT_TAGGING",
  DOCUMENT_REVIEW = "DOCUMENT_REVIEW"
}

export enum pageType {
  STARTING = "starting",
  LAST = "last"
}

export enum splitBtnType {
  SAVE = "save",
  SPLIT = "split"
}

export enum ButtonLabels {
  START_REVIEW_PROCESS = "Start Review Process",
  START_DOCUMENT_SPLIT = "Start Document Split"
}

export enum defaultComment {
  updatedThroughTTF = "Updated through TTF"
}

export const PROGRESS_MESSAGE: any = {
  [CLOSING_PACKAGE_STATUS.SPLIT_IN_PROGRESS]: "Splitting in Progress...",
  [CLOSING_PACKAGE_STATUS.PREDICTION_IN_PROGRESS]: "Prediction in Progress..."
};

export enum loaderMessage {
  IN_PROGRESS = "PDF is almost ready..."
}
export const NO_DOCUMENTS_WARNING_INFO = {
  header: "No Documents",
  body:
    "No Closing Package / minimum set of documents detected. Please \n upload them to continue.",
  okButtonLabel: "OK"
};

export const NO_MINIMUM_SET_OF_DOCUMENTS = {
  header: "No Required Documents",
  body: "No required documents are available. Please upload to continue.",
  okButtonLabel: "OK"
};

export const LOW_CONFIDENCE_THRESHOLD = 0.9;

export enum DOC_MAPPING_ENTITIES {
  noMapping = "No Mapping",
  borrower = "BORROWER",
  guarantor = "GUARANTOR",
  property = "PROPERTY"
}

export enum mappingSelectionMode {
  singleSelect = "SINGLE_SELECT",
  multiSelect = "MULTI_SELECT",
  singleBorrowerSelect = "SINGLE_BORROWER_SELECT",
  singleGuarantorSelect = "SINGLE_GUARANTOR_SELECT",
  multiBorrowerSelect = "MULTI_BORROWER_SELECT",
  multiGuarantorSelect = "MULTI_GUARANTOR_SELECT",
}

export const TAG_STATUS = {
  initialState: {
    showInprogresslabel: false,
    showComponent: false
  },
  showInProgressLabelAndComponent: {
    isUpdateInProgress: true,
    showProgressLabelComponent: true
  },
  hideInProgressLabelAndShowComponent: {
    isUpdateInProgress: false,
    showProgressLabelComponent: true
  }
};

export const tagGroups = [
  ["APPR", "BPOO"],
  ["HUDD", "LNDB"]
];

export const notToBeCountedDocs = [
  "waiver_decision_snap",
  "purchase_advice",
  "purchase_advise",
  "FEMA_",
  "SiteXPro_",
  "Crime Map_",
  "Zillow_",
  "Google Search_",
  "Google Maps_",
  "Toorak Properties_"
];

export const CLOSING_PACKAGE_CODE = "CLPK";

export const BRIDGE_REQUIRED_TAGS_FOR_TTFREVIEW = [
  {
    docCode: "NOTE"
  },
  {
    docCode: "APPR"
  },
  {
    docCode: "BPOO"
  },
  {
    docCode: "HUDD"
  },
  {
    docCode: "LNDB"
  },
  {
    docCode: "GUAR",
    shouldBeOmitted: (loanDetails: any) => {
      const areAllBorrowersIndividual = loanDetails.bridgeLoanBorrowerInformation.every(
        (obj: any) => obj?.payload?.borrowerType === "Individual"
      );
      if (areAllBorrowersIndividual) {
        return loanDetails.bridgeLoanGuarantorInformation.every(
          (obj: any) => obj?.payload?.guarantorType === "Individual"
        );
      }
      return false;
    }
  },
  {
    docCode: "DCRR"
  }
];
export const DSCR_REQUIRED_TAGS_FOR_TTFREVIEW = [
  {
    docCode: "NOTE"
  },
  {
    docCode: "APPR"
  },
  {
    docCode: "BPOO"
  },
  {
    docCode: "HUDD"
  },
  {
    docCode: "LNDB"
  },
  {
    docCode: "GUAR",
    shouldBeOmitted: (loanDetails: any) => {
      const areAllBorrowersIndividual = loanDetails.bridgeLoanBorrowerInformation.every(
        (obj: any) => obj?.payload?.borrowerType === "Individual"
      );
      if (areAllBorrowersIndividual) {
        return loanDetails.bridgeLoanGuarantorInformation.every(
          (obj: any) => obj?.payload?.guarantorType === "Individual"
        );
      }
      return false;
    }
  },
  {
    docCode: "DCRR"
  },
  {
    docCode: "DTRV"
  },
  {
    docCode: "LRAG"
  },
  {
    docCode: "COTS"
  },
  {
    docCode: "EVOI"
  },
  {
    docCode: "DFLI"
  },
  {
    docCode: "PPRD"
  },
  {
    docCode: "POFL"
  }
];

export const IMAGE_FILE_TYPE = ["jpg", "jpeg", "png"];

export const EXCEL_FILE_TYPE = ["doc", "docx", "xlsx", "xls"];

export const PDF_FILE_TYPE = ["pdf"];

export const VIEW_OFFICE_APP_LINK =
  "https://view.officeapps.live.com/op/embed.aspx";

export const TAG_INTERNAL_PAYLOAD = {
  tagType: "MANUAL",
  tagStatus: "",
  confidence_score: 1,
  tag: null
};

export const TAG_UPDATE_PAYLOAD = {
  tagType: "MANUAL",
  tagStatus: null,
  createdBy: null,
  updatedBy: getCookie("person_id"),
  confidence_score: 1,
  tag: null
};

export const OVERRIDDEN = "OVERRIDDEN";

export const EMPTY_SPLIT_DOCUMENT_INFO = {
  splitDocumentInfoId: uuidV4(),
  tagCode: "",
  tagScores: null,
  splitType: OVERRIDDEN,
  status: null,
  s3Path: null,
  fileName: null,
  externalDocumentId: null,
  newData: true,
  segments: [
    {
      splitDocumentSegmentId: uuidV4(),
      startPageOffsetExclusive: "",
      endPageOffsetInclusive: "",
      sectionOrder: 1,
      createdBy: null,
      updatedBy: null
    }
  ],
  createdBy: null,
  updatedBy: null
};

export enum STARTPAGEMODIFIER {
  INCREMENT = "INCREMENT",
  DECREMENT = "DECREMENT"
}

export const ACTIVE_TAB_CRITERIA = [tabStatus.COMPLETED, tabStatus.INPROGRESS];

export enum SPLIT_TAB_VIEW_MODE {
  LISTVIEW,
  EDITVIEW
}

export const POLL_FOR_STATUSES = [
  CLOSING_PACKAGE_STATUS.PREDICTION_IN_PROGRESS,
  CLOSING_PACKAGE_STATUS.SPLIT_IN_PROGRESS
];
