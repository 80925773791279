/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useContext
} from "react";
import { useParams } from "react-router-dom";
import { LoanStage } from "@toorak/tc-common-fe-sdk";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "../common-style/CreateLoan.style";
import {
  isLATUser,
  isRole,
  isILP,
  isPurchasedLoansOnly
} from "../../utils/AccessManagement";
import { getLoanType } from "../../config/config";
import {
  InternalDDStatusEnum,
  InternalRolesEnum,
  LoanProcessTypeEnum
} from "../../utils/constants";
//import { setIsWithdrawnLoan } from "../create-loan.action";
import { RootState } from "../../stores/rootReducer";
import { usePriorStages } from "../appraisal/shared/loanLandmarks";
import { tabStatus } from "../ttf-review/constants";
import { CreditLineLoanViewContext } from "../CreateLoan";
import { updateIsWithdrawnState } from "../create-loan.reducer";

const approvedToorvalStatuses = [
  LoanStatusEnum.Approved,
  LoanStatusEnum.ApprovedConditionally
];

export const TextOnlyTooltip = withStyles({
  tooltip: {
    color: "#32325d",
    backgroundColor: "white",
    fontFamily: `"Open Sans", sans-serif !important`,
    fontSize: "12px",
    boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
    width: "170px",
    padding: "12px 12px 12px 20px"
  },
  arrow: {
    color: "white"
  }
})(Tooltip);
/** This component displays loan stage and loan type */
export const DisplayLoanStageAndType = (props: {
  loanState: LoanStatusEnum;
  loanDetails?: any;
  ttfVersionId?: any;
  bundleStatus?: string;
  isTableFunding: boolean;
  isWarehouseFunding: boolean;
  rateLockExpirtDateObj?: { [key: string]: any };
  activeInteractions?: any;
  dueDepositAmountObj?: any;
  servicerState?: string;
  purchasedStateInfo?: {
    loanStatus: string;
    color: string;
    delinquencyStatus?: string | null;
  };
  loanTypeProp?: string;
  needEnhancedReview?: any;
  takeoutPartner?: string;
  isLoanAdvanceFunded?: boolean;
}) => {
  const classes = useStyles();
  const { loanStage, loanType } = useParams<{
    loanId: string;
    loanStage: LoanStage;
    loanType: string;
  }>() as any;
  const {
    loanState,
    loanDetails,
    bundleStatus,
    ttfVersionId,
    isTableFunding,
    isWarehouseFunding,
    rateLockExpirtDateObj,
    activeInteractions,
    dueDepositAmountObj,
    servicerState,
    loanTypeProp,
    needEnhancedReview,
    takeoutPartner,
    isLoanAdvanceFunded,
    purchasedStateInfo
  } = props;
  const { creditLines }: any = useContext(CreditLineLoanViewContext);
  // const currentUser = getCookie("person_id");
  const [title, setTitle] = useState(`${getLoanType[0].name}`);
  const [pendingActInt, setPendingActInt] = useState<any>([]);
  // const { irCompletedBy, qcCompletedBy } = getCompletedBy(
  //   loanDetails?.loanLandmarks
  // );
  const dispatch = useDispatch();
  useEffect(() => {
    if ([loanTypeProp, loanType].includes(getLoanType[1].displayValue)) {
      setTitle(`${getLoanType[1].name}`);
    } else if ([loanTypeProp, loanType].includes(getLoanType[2].displayValue)) {
      setTitle(`${getLoanType[2].name}`);
    } else {
      setTitle(`${getLoanType[0].name}`);
    }
  }, [loanType, loanTypeProp]);

  useEffect(() => {
    if (loanState === LoanStatusEnum.Withdrawn) {
      dispatch(updateIsWithdrawnState(true));
    }
  }, [loanState]);

  const { activeInteractionsModalCount } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );
  const { appraisalLandmarks } = useSelector<RootState, any>(
    (state) => state.appraisalStore
  );

  useEffect(() => {
    if (!isILP()) {
      let pendingActIntVal: any = [];
      let conditionsObj = activeInteractions?.conditions && {
        label: "Open Conditions",
        value: activeInteractions?.conditions
      };
      if (conditionsObj?.value) {
        pendingActIntVal.push(conditionsObj);
      }
      let waiversObj = activeInteractions?.waivers && {
        label: "Open Waivers",
        value: activeInteractions?.waivers
      };
      if (waiversObj?.value) {
        pendingActIntVal.push(waiversObj);
      }
      let inquiriesObj = activeInteractions?.inquiries && {
        label: "Open Inquiries",
        value: activeInteractions?.inquiries
      };
      if (inquiriesObj?.value) {
        pendingActIntVal.push(inquiriesObj);
      }
      setPendingActInt(pendingActIntVal);
    }
  }, [activeInteractions]);

  const getLoanStage = useCallback(() => {
    if (loanStage === LoanStage.pre) {
      return { label: "Pre-Close" };
    }
    if (loanStage === LoanStage.post || isPurchasedLoansOnly()) {
      return { label: "Post-Close" };
    }
    if (loanStage === LoanStage.tv) {
      return { label: "TOORVAL" };
    }
    return { label: "Guideline Evaluation" };
  }, [loanStage]);

  const getLoanState = useCallback(() => {
    // if (loanStage === LoanStage.tv) return null
    if (loanStage === LoanStage.tv) {
      if (loanState === LoanStatusEnum.DataEntry) {
        return {
          label: "Data Entry"
        };
      } else if (loanState === LoanStatusEnum.Approved) {
        return {
          label: "Approved",
          style: { backgroundColor: "#2fce89", color: "#ffffff" }
        };
      } else if (loanState === LoanStatusEnum.ApprovedConditionally) {
        return {
          label: "Approved Conditionally",
          style: { backgroundColor: "#2fce89", color: "#ffffff" }
        };
      } else if (loanState === LoanStatusEnum.Rejected) {
        return {
          label: "Rejected",
          style: { backgroundColor: "#f75576", color: "#ffffff" }
        };
      } else if (loanState === LoanStatusEnum.Submit) {
        return {
          label: "Under Review"
        };
      } else if (loanState === LoanStatusEnum.UnderReview) {
        return {
          label: "Under Review"
        };
      }
    } else {
      if (
        loanState === LoanStatusEnum.ExcelError ||
        loanState === LoanStatusEnum.ExcelSuccess
      ) {
        return { label: "Data Entry" };
      }
      if (loanState === LoanStatusEnum.Submit && loanStage !== LoanStage.fes) {
        return { label: "Data Entry" };
      }
      if (
        loanState !== LoanStatusEnum.DataEntry &&
        loanStage === LoanStage.fes
      ) {
        return { label: "Preliminary Sizing" };
      }
      if (loanState === LoanStatusEnum.Purchased && !isLATUser()) {
        return { label: "Funded" };
      }
      if (loanState === LoanStatusEnum.DueDiligenceReview) {
        return { label: "Review In Progress" };
      }
      return { label: loanState };
    }
  }, [loanState]);

  const getStateByKey = () => {
    const relativeState = { label: loanDetails.workflowState };
    if (relativeState.label.includes("IR")) {
      return {
        label:
          loanDetails?.roleOfAssignee === InternalRolesEnum.LAT_QC
            ? "QC"
            : "Initial Reviewer"
      };
    }
    if (relativeState.label.includes("QC")) {
      return { label: "QC" };
    }
    if (relativeState.label === InternalDDStatusEnum.ApproverPending) {
      return { label: "Approver" };
    }
    if (relativeState.label.includes("Review")) {
      if (isRole(InternalRolesEnum.LAT_INQUIRY)) {
        return { label: "Inquiries" };
      }
      return { label: "Approver" };
    }
    return { label: "Unassigned" };
  };

  // const getRelativeState = () => {
  //   let relWFState = loanDetails?.workflowState;
  //   if (relWFState.includes("Review")) {
  //     return { label: relWFState };
  //   }
  //   if (
  //     qcCompletedBy !== null &&
  //     qcCompletedBy === currentUser &&
  //     !isRole(InternalRolesEnum.LAT_ADMIN) &&
  //     !isRole(InternalRolesEnum.LAT_APPROVER) &&
  //     !isRole(InternalRolesEnum.LAT_INQUIRY)
  //   ) {
  //     relWFState = InternalDDStatusEnum.QCCompleted;
  //   } else if (
  //     loanDetails?.assignedTo !== currentUser &&
  //     irCompletedBy !== null &&
  //     irCompletedBy === currentUser &&
  //     !isRole(InternalRolesEnum.LAT_ADMIN) &&
  //     !isRole(InternalRolesEnum.LAT_APPROVER) &&
  //     !isRole(InternalRolesEnum.LAT_INQUIRY)
  //   ) {
  //     relWFState = InternalDDStatusEnum.IRCompleted;
  //   }
  //   return { label: relWFState };
  // };
  const priorStages = usePriorStages();
  const multitakeoutPartner = creditLines?.find(
    (item: any) => takeoutPartner === item?.id
  );
  const multitakeoutPartnerName = isLATUser()
    ? multitakeoutPartner?.shortName ?? ""
    : multitakeoutPartner?.programName ?? "";

  const { isPendingRateLock, isApprovedRateLock, isRejectedRateLock } = useMemo(() => {
    const isPendingRateLock = loanDetails?.loanDetailId?.rateLocks?.find((lock: any) => lock?.rateLockStatus === "PENDING");
    const isApprovedRateLock = loanDetails?.loanDetailId?.rateLocks?.find((lock: any) => lock?.rateLockStatus === "APPROVED");
    const isRejectedRateLock = loanDetails?.loanDetailId?.rateLocks?.find((lock: any) => lock?.rateLockStatus === "REJECTED");
    return { isPendingRateLock, isApprovedRateLock, isRejectedRateLock }
  }, [loanDetails?.loanDetailId?.rateLocks]);

  const loanTags = useMemo(() => {
    const loanStateObj = getLoanState();
    const tagMap = new Map();
    let tags: any = [{ label: title }];
    tags.push(getLoanStage());
    if (isTableFunding) {
      tags.push({
        label: "TABLE FUND",
        style: { backgroundColor: "#3d55df" }
      });
    }
    if (isWarehouseFunding) {
      tags.push({
        label: "WAREHOUSE FUNDED",
        style: { backgroundColor: "#3d55df" }
      });
    }
    if (isLoanAdvanceFunded) {
      tags.push({
        label: "ADVANCE FUND",
        style: { backgroundColor: "#3d55df" }
      });
    }
    if (
      takeoutPartner &&
      ![
        LoanStatusEnum.ExcelError,
        LoanStatusEnum.ExcelSuccess,
        LoanStatusEnum.DataEntry
      ].includes(loanState)
    ) {
      tags.push({
        label: multitakeoutPartnerName,
        style: { backgroundColor: "#D3D3D3", color: "black" },
        "data-testid": "assigned-parter-tag"
      });
    }
    if (isLATUser()) {
      if (needEnhancedReview === "Yes") {
        tags.push({
          label: "NEED ENHANCED REVIEW",
          style: { backgroundColor: "#3d55df" }
        });
      }
      if (loanDetails?.loanProcessType === LoanProcessTypeEnum.Express) {
        tags.push({
          label: LoanProcessTypeEnum.Express,
          style: { backgroundColor: "#1071ee" }
        });
      } else if (loanDetails?.loanProcessType === LoanProcessTypeEnum.Premier) {
        tags.push({
          label: LoanProcessTypeEnum.Premier,
          style: { backgroundColor: "#b87300" }
        });
      }
    }
    if (loanStage !== LoanStage.tv && priorStages.has(LoanStage.tv)) {
      const latestLandmarkData = appraisalLandmarks?.length
        ? appraisalLandmarks.slice(-1)[0]
        : {};
      tags.push({
        label: "TOORVAL",
        style: {
          backgroundColor: approvedToorvalStatuses.includes(
            latestLandmarkData?.appraisal_status
          )
            ? "#2ece89"
            : "#f75676"
        }
      });
    }
    if (servicerState) {
    } else if (purchasedStateInfo) {
      tags.push({
        label: purchasedStateInfo.loanStatus,
        style: { backgroundColor: purchasedStateInfo.color }
      });
      if (purchasedStateInfo.delinquencyStatus) {
        tags.push({
          label: purchasedStateInfo.delinquencyStatus,
          style: { backgroundColor: "#ffad17" } //orange
        });
      }
    } else {
      tags.push(getLoanState());
    }
    if (
      loanStateObj?.label === LoanStatusEnum.PurchaseInitiated &&
      bundleStatus
    ) {
      tags.push({
        label: bundleStatus,
        style: { backgroundColor: "#d9efff", color: "#32325d" }
      });
    }
    if (isILP()) {
      if (loanDetails?.workflowState !== null) {
        tags.push({
          label:
            loanDetails.workflowState === InternalDDStatusEnum.ReviewInProgress
              ? "SDD Review In Progress"
              : loanDetails.workflowState
        });
      }
      if (
        loanDetails?.workflowState !== null &&
        loanDetails?.workflowState !== InternalDDStatusEnum.UnAssignedIR
      ) {
        tags.push(getStateByKey());
      }
      if (ttfVersionId == null) {
        if (loanDetails.tapeToFileStatus !== null) {
          tags.push({ label: loanDetails.tapeToFileStatus });
        }
      } else {
        const docReviewStatus = [
          loanDetails.loanSummary.documentsTaggingStatus || "Pending",
          loanDetails.loanSummary.documentsSplitStatus || "Pending",
          loanDetails.loanSummary.ttfReviewStatus || "Pending",
          loanDetails.ddReportStatus,
          loanDetails.loanSummary.dataReconcilationStatus || "Pending"
        ];
        const isAllCompleted = docReviewStatus.every(
          (item: any) => item === tabStatus.COMPLETED
        );
        tags.push({
          label: `Doc Review`,
          tooltipLabel: (
            <>
              <li className={classes.pendingItem}>
                {`Tagging: ${docReviewStatus[0]}`}
              </li>
              <li className={classes.pendingItem}>
                {`Splitting: ${docReviewStatus[1]}`}
              </li>
              <li className={classes.pendingItem}>
                {`Review: ${docReviewStatus[2]}`}
              </li>
              <li className={classes.pendingItem}>
                {`DD Report: ${docReviewStatus[3]}`}
              </li>
              <li className={classes.pendingItem}>
                {`Reconcile: ${docReviewStatus[4]}`}
              </li>
            </>
          ),
          style: {
            backgroundColor: isAllCompleted ? "#2ece89" : "#fb623f",
            color: "#ffffff"
          }
        });
      }
    }
    const loanStates = ["Data Entry", "Excel Error", "submit"];
    if (
      activeInteractions?.total &&
      !isILP() &&
      (!loanStates.includes(loanStateObj?.label as string) ||
        (loanStage !== LoanStage.fes &&
          loanStateObj?.label === LoanStatusEnum.Submit))
    ) {
      tags.push({
        label: `Active Interactions: ${activeInteractionsModalCount}`,
        tooltipLabel: (
          <>
            {pendingActInt.map((item: any, index: number) => {
              return (
                <li className={classes.pendingItem}>
                  {item.label} : {item.value}
                </li>
              );
            })}
          </>
        ),
        style: { backgroundColor: "#fb623f", color: "#ffffff" }
      });
    }
    if (rateLockExpirtDateObj?.show && !isApprovedRateLock) {
      tags.push(rateLockExpirtDateObj);
    }
    if (dueDepositAmountObj?.show) {
      tags.push(dueDepositAmountObj);
    }
    if (isPendingRateLock) {
      const pushObj = {
        label: `${multitakeoutPartnerName?.toUpperCase()}: RATE LOCK REQUESTED`,
        style: { backgroundColor: "#fb623f", color: "#ffffff" }
      };
      tags.push(pushObj);
    }
    return tags.filter((tag: { label: string }) => {
      // filter duplicates
      const { label } = tag;
      try {
        if (tagMap.has(label)) return false;
        tagMap.set(label.toLowerCase(), tag);
      } catch (e) {
        return false;
      }
      return true;
    });
  }, [
    title,
    getLoanStage,
    getLoanState,
    bundleStatus,
    rateLockExpirtDateObj,
    isTableFunding,
    purchasedStateInfo,
    isWarehouseFunding,
    needEnhancedReview,
    dueDepositAmountObj,
    activeInteractions,
    loanDetails?.workflowState,
    loanDetails?.tapeToFileStatus,
    loanDetails?.loanSummary?.documentsTaggingStatus,
    loanDetails?.loanSummary?.documentsSplitStatus,
    loanDetails?.loanSummary?.ttfReviewStatus,
    loanDetails?.loanSummary?.dataReconcilationStatus,
    loanDetails?.ddReportStatus,
    appraisalLandmarks,
    pendingActInt,
    servicerState,
    isLoanAdvanceFunded,
    isPendingRateLock
  ]);
  return (
    <>
      {loanTags.map((item: any) => {
        return (
          <Typography
            key={item?.label}
            variant="h5"
            className={classes.itemTypography}
            style={item.style}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <span>{item.label}</span>
              {item.tooltipLabel && (
                <TextOnlyTooltip
                  classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
                  placement="right"
                  arrow
                  title={item.tooltipLabel}
                >
                  <InfoOutlinedIcon
                    className={classes.icon}
                    htmlColor="#ffffff"
                    style={{ marginLeft: "2px" }}
                  />
                </TextOnlyTooltip>
              )}
            </span>
          </Typography>
        );
      })}
    </>
  );
};
