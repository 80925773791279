import React from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useDrawsStyles } from "../Draws.styles";

import { drawRightTabNames } from "../Constant";
import { ExternalDiscussions } from "./ExternalDiscussions";
// import { EvaluationResults } from "./EvaluationResults";
import { TabPanelProps } from "../../../utils/constants";

const a11yProps = (index: number) => {
  return {
    id: `draws-right-tab-${index}`,
    "aria-controls": `draws-right-tabpanel-${index}`
  };
};

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`draws-right-tabpanel-${index}`}
      aria-labelledby={`draws-right-tab-${index}`}
      style={{ width: "100%" }}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export const DrawRightPanelWrapper = ({ drawId }: any) => {
  const classes = useDrawsStyles();

  const [value, setValue] = React.useState(0);
  return (
    <>
      <Box style={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          aria-label="draw right tabs"
          TabIndicatorProps={{
            style: {
              left: value === 0 ? 20 : 170,
              width: 110
            }
          }}
        >
          {drawRightTabNames.map((item: any, idx: number) => (
            <div key={idx}>
              <Tab
                label={item.label}
                {...a11yProps(idx)}
                className={`${idx === value ? classes.tabSelected : null}`}
                onClick={() => setValue(idx)}
              />
            </div>
          ))}
        </Tabs>
      </Box>
      {/* <TabPanel value={value} index={0}>
        <EvaluationResults />
      </TabPanel> */}
      <TabPanel value={value} index={0}>
        <ExternalDiscussions drawId={drawId} />
      </TabPanel>
    </>
  );
};
