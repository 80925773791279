import { Grid } from "@mui/material";
import { LoanStage } from "@toorak/tc-common-fe-sdk";
import React from "react";
import { onlyUpdateAppraisalForm } from "../../stores/appraisal/appraisal.action";
import { updatePropertyDetailsInRedux } from "../../stores/loanAndPropertyDetails/loanAndPropertyDetails.action";
import { updatePropertyByloanId } from "../create-loan-new.action";
import {
  checkDuplicateLoan,
  convertLoan,
  copyLoan,
  deleteLoan,
  getLoanById,
  getPropertyOriginalValuesByLoanId
  // GET_PROPERTY_DETAILS
} from "../create-loan.action";
import { formatPropertyResponse } from "../CreateLoan";
import { getPropertyDetails } from "../create-loan.reducer";

export const showDuplicateDetals = (duplList: any[]) => {
  return duplList?.map((item: any) => {
    let propString = "";
    if (item.properties) {
      const { addressLine1, city, state, postalCode } =
        item.properties[0]?.propertyLocation;
      propString = `${addressLine1}, ${city}, ${state}, ${postalCode}`;
      propString =
        item.properties.length <= 1
          ? propString
          : `${propString} + ${item.properties.length - 1}`;
    }
    const propStage =
      item.loanStage === "FES"
        ? "Guideline Evaluation"
        : `${item.loanStage}-Close`;
    return (
      <div>
        <b>{item.loanId}</b>: {propString}, {propStage}
      </div>
    );
  });
};

export const getDupliPopupHTML = (
  topic: string,
  duplicateProperties: any[],
  extraData: any[]
) => {
  let stageText = "";
  if (topic === "TOORVAL_FES") {
    stageText = "Toorval and Guideline stage";
  } else {
    stageText = "Toorval stage";
  }

  return (
    <>
      <Grid container direction="column">
        <Grid item xs={12} style={{ padding: 20 }}>
          <div>
            There are existing loans in the system with the same property
            address or originator loan id in the {stageText} that are submitted.
          </div>
          <br />
          <div>
            {topic === "TOORVAL_FES"
              ? showToorvalDuplicateDetals(duplicateProperties, extraData)
              : showToorvalDuplicateDetals(duplicateProperties)}
          </div>
          <br />
          <div>
            The existing loan will be converted and merged with the current
            loan. Do you want to proceed?
          </div>
          <br />
          <br />
        </Grid>
      </Grid>
    </>
  );
};

export const handleToorvalDuplicateLoans = (
  metaData: any,
  duplResultsDetails: any,
  showErrorPopup: any
) => {
  if (!duplResultsDetails.convertArray.length) {
    showErrorPopup(metaData.ToorvalLoans, "TOORVAL_ONLY");
  } else {
    showErrorPopup(
      duplResultsDetails.convertArray,
      "TOORVAL_FES",
      metaData.ToorvalLoans
    );
  }
};

export const updatePropInfoWithToorvalID = async (
  loanId: string,
  loanStage: LoanStage,
  toorvalId: string,
  dispatch: any,
  isGetRequired?: boolean
) => {
  try {
    await updatePropertyByloanId(loanId, loanStage, toorvalId);
    if (isGetRequired) {
      const resp: any = await getPropertyOriginalValuesByLoanId(
        loanId,
        loanStage
      );

      updatePropertyDetailsInRedux(resp.data, dispatch);
      let formattedPropertyData = formatPropertyResponse(
        resp.data,
        loanStage,
        "BridgeLoan"
      );
      // dispatch({
      //   type: GET_PROPERTY_DETAILS,
      //   payload: {
      //     propertyDetails: formattedPropertyData
      //   }
      // });
      dispatch(
        getPropertyDetails({
          propertyDetails: formattedPropertyData
          // thirtyYearLoanPropertyDetails: formattedPropertyData
        })
      );
    }
    return Promise.resolve();
  } catch (e) {
    console.log(e);
    return Promise.reject();
  }
};

export const convertSingleToorvalFesLoan = async (
  dupliLoansList: any[],
  loanStage: LoanStage,
  loanId: string,
  dispatch: any
) => {
  try {
    await onlyUpdateAppraisalForm({
      toorakLoanID: dupliLoansList[0].loanId,
      appraisalID: dupliLoansList[0].appraisalId,
      form: {
        convertedTo: loanStage
      }
    });
    await updatePropInfoWithToorvalID(
      loanId,
      loanStage,
      dupliLoansList[0].loanId,
      dispatch,
      true
    );

    return Promise.resolve();
  } catch (e) {
    console.error("loan conversion didnot happened", e);
    return Promise.reject(e);
  }
};

export const convertSingleToorvalLoan = async (
  dupliLoansList: any[],
  loanStage: LoanStage,
  loanId: string
) => {
  try {
    try {
      await convertLoan(
        dupliLoansList[0].loanId,
        dupliLoansList[0].loanStage,
        loanStage,
        true
      );
    } catch (e) {
      return Promise.reject(e);
    }
    // if (isDocsImported) {
    //   await deleteMultipleDocs(multiDocsDelBody);
    // }
    await onlyUpdateAppraisalForm({
      toorakLoanID: dupliLoansList[0].loanId,
      appraisalID: dupliLoansList[0].appraisalId,
      form: {
        convertedTo: loanStage
      }
    });
    await copyLoan(loanId, dupliLoansList[0].loanId, loanStage);
    await deleteLoan(loanId, loanStage);
    try {
      await updatePropertyByloanId(loanId, loanStage, dupliLoansList[0].loanId);
    } catch (e) {
      console.log(e);
    }
    return Promise.resolve();
  } catch (e) {
    console.error("loan conversion didnot happened", e);
    return Promise.reject(e);
  }
};

export const showToorvalDuplicateDetals = (
  duplList: any[],
  extraData: any[] = []
) => {
  let loanLIst = [...duplList, ...extraData];
  return loanLIst.map((item: any) => {
    let propString = "";
    if (item.properties.length) {
      let address = "",
        city = "",
        state = "",
        postalCode = "";
      if (item.loanStage === LoanStage.tv) {
        address = item.properties[0].address;
        city = item.properties[0].city;
        state = item.properties[0].state;
        postalCode = item.properties[0].postalCode;
      } else {
        const { propertyLocation } = item.properties[0];
        address = propertyLocation.addressLine1;
        city = propertyLocation.city;
        state = propertyLocation.state;
        postalCode = propertyLocation.postalCode;
      }
      propString = `${address}, ${city}, ${state}, ${postalCode}`;
      propString =
        item.properties.length <= 1
          ? propString
          : `${propString} + ${item.properties.length - 1}`;
    }
    return (
      <div>
        <b>{item.loanId}</b>: {propString}, {item.loanStage}
      </div>
    );
  });
};

export function getDuplicateCheckBody(
  loanId: string,
  loanStage: LoanStage,
  primaryLoanId?: string
): Promise<any> {
  const promise1 = getLoanById(loanId, loanStage);
  const promise2 = getPropertyOriginalValuesByLoanId(loanId, loanStage);
  return Promise.all([promise1, promise2])
    .then(async (response: any) => {
      const responseData = response[0].data;
      if (primaryLoanId) {
        responseData.loanInfo.primaryLoanId = primaryLoanId;
      }
      const loanInfo = {
        loanInfo: responseData.loanInfo,
        loanStage: responseData.loanStage,
        loanState: responseData.loanState,
        loanType: responseData.loanType,
        loanId: responseData.loanDetailId.loanId
      };
      const requestBody = [
        {
          loanObject: loanInfo,
          propertiesArray: response[1].data
        }
      ];
      return Promise.resolve(requestBody);
    })
    .catch((e) => Promise.reject(e));
}

export const checkAndHandleDupliLoans = async (
  loanId: string,
  loanStage: LoanStage
): Promise<any> => {
  try {
    const requestBody = await getDuplicateCheckBody(loanId, loanStage);
    const response2 = await checkDuplicateLoan(requestBody, loanStage);
    return Promise.resolve(validateResponse(response2));
  } catch (e) {
    return Promise.reject(e);
  }
};

function validateResponse(response: any) {
  if (!response.data?.metaData?.success) {
    return {
      success: true
    };
  } else {
    const { duplResultsDetails, metaData } = response.data;
    // based on priority AND>OR>convert

    // console.log(duplResultsDetails, "resl");
    if (
      duplResultsDetails.andArray.length > 0 ||
      duplResultsDetails.orArray.length > 0 ||
      duplResultsDetails.convertArray.length > 0
    ) {
      const Details = {
        duplicateProperties: duplResultsDetails.andArray.length
          ? duplResultsDetails.andArray
          : duplResultsDetails.orArray.length
          ? duplResultsDetails.orArray
          : duplResultsDetails.convertArray,
        topic: metaData.duplResultsType
      };
      return {
        success: false,
        moreInfo: Details
      };
    } else {
      return {
        success: true
      };
    }
  }
}
