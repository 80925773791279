/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable prefer-const */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import MenuList from "@mui/material/MenuList";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { DialogTitle } from "@toorak/tc-common-fe-sdk";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Dialog, DialogContent } from "@mui/material";
import { IconButtonDropdownStylesWhite } from "../waiver-form/DocumentsForm.style";
import { SettingsIconDark } from "../Icons";
import { InfoOnlyTooltip } from "../../utils/Tooltip";
import {
  attachLoansToScenario,
  getLoansListToAttachToScenario,
  getScenarioDetailFromAggregate,
  getScenarioListFromElasticSearch,
  scenarioApproval,
  updateLoansInRedux,
  updateScenarioDetailsListInRedux,
  updateScenarioViewDetailsInRedux
  // updateScenarioStatusInRedux
} from "../../stores/Scenario/ScenarioActions";
import {
  showLoader,
  hideLoader
  // clearLoader
} from "../../stores/loaderAndException/loaderAndException.action";
import { isLATUser } from "../../utils/AccessManagement";
import { AttachScenarioDialog } from "./AttachScenarioDialog";
import { RootState } from "../../stores/rootReducer";
import { CommentsBlock } from "../loan-lists/comments-section/components/CommentsBlock";
import useOutsideClick from "../../custom-hooks/useOutsideClick";
import { useDebounce } from "../../custom-hooks/useDebounce";
import { updateScenarioStatus } from "../../stores/Scenario/ScenarioReducer";
import { clearLoader } from "../../stores/loaderAndException/loaderAndException.reducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex"
    },
    paper: {
      marginRight: theme.spacing(2)
    },
    actionsButton: {
      borderRadius: "4px",
      // boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      // backgroundColor: "#5e72e4",
      paddingTop: "5px",
      paddingBottom: "5px"
    },
    menuItem: {
      fontSize: "12px",
      color: "#32325d"
      // padding: "8px 10px",
    }
  })
);

export const scenarioAction = [
  {
    name: "Seems OK",
    approvalType: "Approve"
  },
  {
    name: "Doesn't fit Toorak Guidelines",
    approvalType: "Reject"
  }
];

export function ScenarioApprovalDropDown(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [dialogdetails, setDialogdetails] = React.useState<any>({});
  //const [searchedLoanId, setSearchedLoanId] = React.useState<string>("");
  const searchedLoanId = "";
  const [isMoreActionsDropdownVisible, setIsMoreActionsDropdownVisible] =
    useState(false);
  const {
    waiverId,
    orgPartyId,
    scenarioIdFromList,
    fromExportActions,
    loanType,
    isDetailView,
    fStatus,
    attachedLoanArr
  } = props;
  const { scenarioId } = useParams<{
    scenarioId: string;
  }>() as any;
  const attachedLoanIdsArr: any[] = [];
  if (attachedLoanArr?.length)
    attachedLoanArr.forEach((item: any) => {
      attachedLoanIdsArr.push(item.loanId);
    });
  const iconButtonOverrideClasses = IconButtonDropdownStylesWhite();
  const [open, setOpen] = React.useState(false);
  const [approvalType, setApprovalType] = useState<string>("");
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const handleOpen = () => {
    setOpen(true);
    if (isMoreActionsDropdownVisible)
      setIsMoreActionsDropdownVisible(!isMoreActionsDropdownVisible);
  };
  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
    setApprovalType("");
  };

  const loanListArr = useSelector<
    RootState,
    { scenarioListResult: any[] | any }
  >(
    (state: { scenarioResultsStore: { loansListDetail: any } }) =>
      state.scenarioResultsStore?.loansListDetail
  );
  const handleAttachLoanToScenario = () => {
    dispatch(showLoader());
    setOpenDialog(false);
    const attachLoansToScenarioPromise = attachLoansToScenario(
      scenarioIdFromList,
      selectedTags
    );
    attachLoansToScenarioPromise
      .then((res: any) => {
        if (!isDetailView) {
          setTimeout(() => {
            const listView = getScenarioListFromElasticSearch();
            listView
              .then((response: any) => {
                dispatch(
                  updateScenarioDetailsListInRedux(
                    response.data?.response?.hits?.hits,
                    response.data?.response?.hits?.total?.value
                  )
                );
                dispatch(hideLoader());
              })
              .catch((err: any) => {
                dispatch(clearLoader());
              });
          }, 3000);
        } else {
          const scenarioDetails = getScenarioDetailFromAggregate(scenarioId);
          scenarioDetails
            .then((response: any) => {
              dispatch(updateScenarioViewDetailsInRedux(response));
              dispatch(hideLoader());
            })
            .catch((err) => {
              dispatch(clearLoader());
            });
        }
      })
      .catch((err: any) => {
        dispatch(clearLoader());
      });
  };
  const dailogHandler = () => {
    const dialogData = {
      topic: "delete",
      // head: "Attach to Loan ID"
      head: "Attach Loan ID to Scenario ID"
    };
    setDialogdetails(dialogData);
    setOpenDialog(true);
  };
  const handleActions = (event: React.MouseEvent<EventTarget>, type?: any) => {
    if (type !== "Attach Loan" && type !== "Print" && type !== "Export") {
      handleOpen();
      setApprovalType(type);
    } else if (type === "Attach Loan") {
      dispatch(showLoader());
      setIsMoreActionsDropdownVisible(false);
      const loanListPromise = getLoansListToAttachToScenario(
        loanType,
        searchedLoanId,
        attachedLoanIdsArr
      );
      loanListPromise
        .then((res: any) => {
          if (res?.data?.response?.hits?.hits?.length > 0) {
            dispatch(updateLoansInRedux(res.data.response.hits.hits));
            dailogHandler();
          }
          dispatch(hideLoader());
        })
        .catch((err: any) => {
          dispatch(clearLoader());
        });
    } else if (type === "Print") {
      setOpen(false);
      setIsMoreActionsDropdownVisible(false);
      if (document.getElementById("root")) {
        document.title = isDetailView
          ? `Toorak Capital Partners | ${scenarioId}`
          : `Toorak Capital Partners | Sceanrios List`;
      }
      window.print();
      if (document.getElementById("root")) {
        document.title =
          "Toorak Capital Partners | Investments | Summit New Jersey";
      }
    }
  };

  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef?.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);
  const actionsExport = [
    {
      name: "Print"
    }
    // {
    //   name: "Export"
    // }
  ];
  const actionsScenario = isLATUser()
    ? [
        {
          name: "Seems OK"
        },
        {
          name: "Doesn't fit Toorak Guidelines"
        }
      ]
    : [];

  if (isDetailView) {
    actionsScenario.push({
      name: "Print"
    });
  }
  if (!fStatus) {
    actionsScenario.push({
      name: "Attach Loan"
    });
  }
  const actionsList = !fromExportActions ? actionsScenario : actionsExport;

  const debouncedSearchTerm = useDebounce(searchedLoanId, 500);
  useEffect(() => {
    if (debouncedSearchTerm && searchedLoanId) {
      dispatch(showLoader());
      const loanListPromise = getLoansListToAttachToScenario(
        loanType,
        searchedLoanId,
        attachedLoanIdsArr
      );
      loanListPromise
        .then((res: any) => {
          if (res?.data?.response?.hits?.hits?.length > 0) {
            dispatch(updateLoansInRedux(res.data.response.hits.hits));
            dailogHandler();
          }
          dispatch(hideLoader());
        })
        .catch((err: any) => {
          dispatch(clearLoader());
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const handleOnChange = async (e: any) => {
    const val = e.target.value;
    try {
      const loanList: any = await getLoansListToAttachToScenario(loanType, val);

      const res = loanList?.data?.response?.hits?.hits?.length;
      if (res > 0) {
        dispatch(updateLoansInRedux(loanList.data.response.hits.hits));
      }
    } catch (error) {
      dispatch(clearLoader());
    }
  };

  const approveScenario = () => {
    setOpen(false);
    dispatch(showLoader());
    const isApproved = approvalType === scenarioAction[0].name && true;
    setIsMoreActionsDropdownVisible(false);
    const promise = scenarioApproval(approvalType, isApproved, waiverId);
    promise
      .then((response: any) => {
        if (response.status === 200 && scenarioId !== undefined) {
          const scenarioDetails = getScenarioDetailFromAggregate(scenarioId);
          scenarioDetails
            .then((resp: any) => {
              dispatch(updateScenarioViewDetailsInRedux(resp));
              dispatch(hideLoader());
            })
            .catch((err) => {
              dispatch(clearLoader());
            });
        }
        if (response.status === 200 && orgPartyId !== undefined) {
          dispatch(updateScenarioStatus(response.data));
        } else {
          dispatch(showLoader());
        }
      })
      .catch((err: any) => {
        dispatch(clearLoader());
      });
    dispatch(hideLoader());
  };

  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => {
    if (isMoreActionsDropdownVisible)
      setIsMoreActionsDropdownVisible(!isMoreActionsDropdownVisible);
  });

  const approvalTypeHeading =
    approvalType && approvalType === scenarioAction[0].name
      ? "Approve Scenario Request"
      : "Reject Scenario Request";
  return (
    <>
      {actionsScenario.length ? (
        <div
          className={classes.root}
          key={scenarioId}
          style={fromExportActions ? { position: "absolute", top: "16px" } : {}}
          data-testid="scenario-actions"
        >
          <div>
            <InfoOnlyTooltip
              // title={!fromExportActions ? "Actions on Scenario" : ""}
              title="Print"
              arrow
              placement="right"
            >
              <IconButton
                ref={anchorRef}
                aria-label="actions"
                component="button"
                data-testid="scenario-action"
                style={{ boxShadow: "rgb(0 0 0 / 22%) 0px 1px 3px 0px" }}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={(e: any) => {
                  e.stopPropagation();
                  setIsMoreActionsDropdownVisible(
                    !isMoreActionsDropdownVisible
                  );
                }}
                // disabled={dropDownDisabled}
                classes={iconButtonOverrideClasses}
                size="large"
              >
                {!fromExportActions ? (
                  <SettingsIconDark size={"17"} />
                ) : (
                  <PrintOutlinedIcon />
                )}
              </IconButton>
            </InfoOnlyTooltip>

            <Popper
              open={open || isMoreActionsDropdownVisible}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
              data-testid="pooper-dropdown"
              style={{ position: "absolute", zIndex: 1 }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom"
                  }}
                  data-testid="grow-div"
                >
                  <Paper data-testid="paper-div" ref={ref}>
                    {isMoreActionsDropdownVisible ? (
                      <MenuList
                        // autoFocusItem={open}
                        id="menu-list-grow"
                        data-testid="menuList-dropdown"
                      >
                        {actionsList.map((item: any) => (
                          <MenuItem
                            className={classes.menuItem}
                            onClick={(e: any) => handleActions(e, item.name)}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </MenuList>
                    ) : null}
                    {/* </ClickAwayListener> */}
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </div>
      ) : null}
      <AttachScenarioDialog
        dialogDetails={dialogdetails}
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
        setSelectedTags={setSelectedTags}
        // setSearchedLoanId={setSearchedLoanId}
        // searchedLoanId={searchedLoanId}
        selectedTags={selectedTags}
        tagData={loanListArr}
        handleSave={handleAttachLoanToScenario}
        handleChange={handleOnChange}
        name="loanId"
        label="Select Toorak Loan ID / Originator Loan ID"
      />
      {open && approvalType !== "" && (
        <Dialog
          open={open}
          onClose={(e: React.MouseEvent<EventTarget>) => handleClose(e)}
          aria-labelledby="waiver-approval-modal"
          aria-describedby="waiver-approval-modal-description"
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={(event: React.MouseEvent<EventTarget>) =>
              handleClose(event)
            }
            style={{ padding: "20px", borderBottom: "1px solid #f2f2f2" }}
          >
            {approvalTypeHeading}
          </DialogTitle>
          <DialogContent style={{ padding: "20px 24px" }}>
            {/* <Grid container item xs={12}> */}
            <CommentsBlock
              hostId={scenarioId || scenarioIdFromList}
              hostType="scenario"
              loanId={scenarioIdFromList || scenarioId}
              loanStage={loanType}
              hostName="scenario"
              approvalType={approvalType}
              approvalComment
              onApproval={approveScenario}
              showSenderAvatar
            />
            {/* </Grid> */}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
