import { Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

export const useStylesServicer = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      overflow: "hidden"
    },
    menuItem: {
      fontWeight: 500,
      marginTop: -6,
      fontStyle: "normal",
      color: "#32325d",
      fontSize: 12,
      "&:hover": {
        backgroundColor: "lightcyan"
      },
      "&:focus": {
        backgroundColor: "lightcyan"
      }
    },
    checkLineWrapper: {
      display: "flex",
      alignItems: "center",
      padding: "0px 20px"
    },
    input: {
      padding: 0
    },
    missingDocs: {
      marginBottom: "2px",
      paddingLeft: "20px"
    },
    mainMenuItem: {
      color: "#32325d",
      cursor: "pointer",
      fontSize: "14px",
      textTransform: "uppercase",
      padding: "20px 25px 20px 25px",
      fontWeight: 600
    },
    zeroState: {
      justifyContent: "center",
      padding: "15px 20px",
      minHeight: 75
    },
    mainMenuListContainer: {
      borderBottom: "1px solid #e2e4f1",
      padding: "0 0 10px 0",
      listStyle: "none",
      textAlign: "left",
      marginBottom: "15px"
    },
    cell: {
      "& .MuiTableCell-body, .MuiTableCell-head": {
        border: "1px ridge #DCDCDC",
        width: "50px",
        padding: "15px"
      },
      "& .MuiTableCell-head": {
        fontWeight: 600
      }
    },
    hyperLink: {
      color: "#5e72e4",
      cursor: "pointer",
      listStyle: "none",
      "&:hover": {
        textDecoration: "underline"
      }
    },
    imgUser: {
      textAlign: "right",
      backgroundColor: "#105CBE",
      borderRadius: "8px",
      height: "70%",
      position: "relative",
      top: "9px",
      left: "10px",
      border: "1px solid rgba(0, 40, 86, 0.22)",
      width: "135px"
    },
    paper: {
      margin: "24px",
      width: "calc(100% - 48px)",
      marginTop: theme.spacing(5),
      overflowX: "scroll"
    },
    modalContainer: {
      flexDirection: "column",
      minWidth: "400px",
      minHeight: "100px",
      justifyContent: "center"
    },
    header: {
      background: "#f6f9fc",
      padding: "15px 20px",
      color: "#32325d",
      fontSize: "14px",
      fontWeight: 600
    },
    sortIcon: {
      height: "16px",
      width: "16px",
      marginBottom: "0",
      cursor: "pointer",
      padding: "0"
    },
    filterIcon: {
      height: "16px",
      width: "16px"
    },
    deleteIcon: {
      height: "16px",
      width: "16px"
    },
    editIcon: {
      height: "16px",
      width: "16px"
    },
    bodyWrapper: {
      paddingTop: "60px",
      [theme.breakpoints.down("md")]: {
        paddingTop: "46px"
      },
      [theme.breakpoints.down("lg")]: {
        paddingTop: "46px"
      }
    },
    pageDropdown: {
      marginLeft: "5px",
      marginTop: "-21px",
      flexGrow: 0,
      maxWidth: "18%",
      flexBasis: "18%"
    },
    handleButton: {
      marginRight: 12,
      color: "#ffffff",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      marginLeft: 8,
      border: "solid 1px #5566d6",
      backgroundColor: "#5566d6",
      "&:hover": {
        backgroundColor: "#5566d6 !important",
        boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)"
      }
    },
    fileBarWrapper: {
      padding: "10px 0px"
    },
    textAreaDescriptionWrapper: {
      width: "100%",
      border: "1px solid #cad1d7",
      borderRadius: "4px",
      marginTop: "-5px"
    },
    sendToListBtn: {
      color: "rgb(50, 50, 93)",
      border: "0px",
      minWidth: "16px",
      minHeight: "16px",
      height: "16px",
      padding: 0,
      margin: "6px 0 0 10px"
    },
    list: {
      marginBottom: "5px"
    },
    containerParentDiv: {
      width: "250px",
      height: "auto",
      whiteSpace: "initial",
      position: "fixed",
      padding: "10px 15px 10px 15px",
      border: "1px solid #888888",
      backgroundColor: "white",
      display: "block",
      zIndex: 999999,
      left: "50%",
      maxHeight: "250px",
      overflowX: "hidden"
    },
    textArea: {
      fontFamily: "sans-serif !important",
      width: "100%",
      height: "100%",
      padding: "14px 12px 14px 12px",
      resize: "none",
      border: "none",
      borderRadius: "4px",
      fontSize: "14px"
    },
    clearSearchIcon: {
      width: "16px",
      height: "16px",
      cursor: "pointer"
    },
    exportBtn: {
      margin: 15,
      height: 30,
      color: "#32325d",
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      border: "1px solid rgba(0, 0, 0, 0.23)",
      padding: "12px 6px"
    },
    selectEmpty: {
      height: "2.25rem",
      borderRadius: "3px",
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      paddingLeft: "3px",
      fontSize: "0.75rem",
      fontWeight: 600,
      background: "#ffffff !important",
      "&:selected": {
        backgroundColor: "#ffffff !important"
      }
    },
    selectRoot: {
      "& .MuiSelect-select": {
        marginTop: "0px",
        minHeight: "0.1876em",
        paddingBottom: "7px !important"
      },
      "& .MuiSelect-select:focus": {
        backgroundColor: "#ffffff !important",
        margin: "0px",
        marginTop: "1px !important"
      },
      "& legend": {
        display: "none"
      },
      "& .MuiListItemText-primary": {
        position: "relative",
        top: "5px"
      },
      "& .MuiListItemText-root": {
        marginTop: "-8px"
      },
      "& .MuiTypography-body1": {
        fontSize: "12px",
        overflow: "hidden",
        textOverflow: "ellipsis"
      },
      "& fieldset": {
        top: "0"
      }
    },
    inputValue: {
      border: "solid 1px #cad1d7",
      borderRadius: "4px",
      background: "#ffffff",
      color: "#000000",
      padding: "2px 5px",
      height: "36px",
      width: "85%"
    },
    EmailContainer: {
      margin: "20px 0"
    },
    bulkHeading: {
      fontSize: "14px",
      lineHeight: 1.69,
      color: "#32325d"
    },
    bulkOptions: {
      fontSize: "12px",
      lineHeight: 1.5,
      color: "#32325d"
    },
    subject: {
      paddingTop: "7px",
      marginRight: "10px",
      fontSize: "14px"
    },
    subjectContainer: {
      marginTop: "15px"
    },
    fileContainer: {
      backgroundColor: "#f7fafc",
      padding: "5px"
    },
    searchContainer: {
      alignSelf: "flex-end",
      flex: 1,
      display: "flex",
      alignItems: "center",
      borderRadius: "20px",
      backgroundColor: "#f7fafc !important",
      padding: "0px 16px 0 4px !important",
      maxWidth: "171px !important",
      height: "36px !important",
      border: "solid 1px #E9ECEF !important",
      fontSize: "12px"
    },
    dropdown: {
      width: "250px",
      marginTop: "-23px",
      padding: "0px 0px 10px 17px"
    },
    titleContainer: {
      height: "100%",
      display: "flex",
      borderLeft: "solid 1px #e9ecef",
      alignItems: "center",
      paddingLeft: "24px",
      fontSize: "14px",
      fontWeight: "bold",
      justifyContent: "start"
    },
    panelContainer: {
      borderRadius: 4,
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      backgroundColor: "#ffffff",
      marginTop: 24,
      height: "calc(100% - 24px)"
    },
    panelTab: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "24px 70px 0px 24px"
    },
    tabBorder: {
      border: "solid 1px #f2f2f2",
      height: 1
    },
    tabHeader: {
      cursor: "pointer",
      color: "#32325d",
      fontSize: 14,
      fontWeight: 600
    },
    tabIdentifier: {
      height: 0,
      borderRadius: 6,
      borderStyle: "solid",
      borderColor: "#5e72e4",
      background: "#5e72e4",
      margin: "22px 8px 0px 8px",
      borderWidth: 2,
      opacity: 1,
      padding: "0px 16px"
    },
    updateServicerPop: {
      fontSize: 12,
      lineHeight: 1.5,
      color: "#32325d",
      marginTop: 30,
      padding: 20,
      paddingBlock: 0,
      wordBreak: "break-word"
    },
    arrowMobileViewServicer: {
      width: 16,
      height: 16,
      [theme.breakpoints.down("sm")]: {
        marginLeft: "3px !important"
      }
    },
    radioItemServicer: {
      marginRight: "3rem"
    },
    dropDownContainerSO: {
      borderRadius: 4,
      boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: "#ffffff",
      padding: 0,
      paddingTop: 8,
      paddingBottom: 6,
      marginTop: 34,
      position: "absolute",
      zIndex: 2,
      maxHeight: 200 /* Set the desired height */,
      overflowY: "auto"
    },
    dropDownItem: {
      // cursor: "pointer",
      fontSize: 12,
      color: "#32325d",
      paddingTop: 0,
      paddingBottom: 6,
      lineHeight: 2,
      fontWeight: 600
    },
    dropDownText: {
      marginLeft: 6,
      marginRight: 4,
      color: "#32325d",
      fontSize: 12,
      fontWeight: 600
    },
    subHeaderWrapper: {
      color: theme.palette.text.secondary,
      borderRadius: "1px",
      display: "flex",
      alignItems: "center"
    },
    chipRoot: {
      fontSize: 12,
      fontWeight: 700,
      backgroundColor: "#e9fafd",
      borderRadius: 6,
      color: "#32325d",
      marginLeft: "1rem",
      height: "24px",
      marginTop: "0.4rem"
    },
    loanHeading: {
      color: "#32325d",
      paddingLeft: 1,
      marginLeft: 24,
      fontFamily: "OpenSans",
      fontSize: "24px !important",
      paddingTop: 6,
      width: "auto",
      whiteSpace: "nowrap"
    },
    dropDownOuterContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      marginRight: "1rem",
      marginLeft: "1rem"
    },
    textAreaDiscussionWrapper: {
      width: "100%",
      height: "89px",
      border: "1px solid #cad1d7",
      borderRadius: "4px",
      marginTop: "1.5%",
      "&:disabled": {
        cursor: "no-drop"
      }
    },
    CssTextarea: {
      fontFamily: "'Open Sans', sans-serif !important",
      border: "none",
      width: "100%",
      reSize: "both",
      padding: "14px 12px 14px 12px",
      "border-radius": "4px",
      outline: "none",
      boxSizing: "border-box",
      fontSize: "14px",
      height: "100%",
      background: "transparent",
      "&:disabled": {
        cursor: "no-drop"
      }
    },
    asterick: {
      color: "red"
    }
  })
);

export const useStylesServicerFields = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      input: {
        backgroundColor: "#ffffff !important"
      }
    },
    container: {
      borderRadius: 4,
      display: "flex",
      flexDirection: "column",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      backgroundColor: "#ffffff",
      marginTop: theme.spacing(3)
      // fontFamily: "OpenSans"
    },
    calculatedOutput: {
      marginLeft: 6,
      // textAlign: "left",
      fontSize: 10,
      fontWeight: "bold",
      color: "#172b4d",
      // lineHeight: 1.8,
      borderRadius: 9,
      backgroundColor: "#d9efff",
      padding: "0 6px",
      height: 16,
      marginTop: 2,
      [theme.breakpoints.down("md")]: {
        marginLeft: "0px",
        marginTop: 10
      },
      [theme.breakpoints.only("sm")]: {
        marginTop: 4,
        marginLeft: "5px"
      }
    },
    sectionWrapper: {
      padding: `14px ${theme.spacing(3)}`,
      display: "flex"
    },
    passedTestName: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.5,
      color: "#32325d",
      display: "flex",
      alignItems: "center"
    },
    passedTestIcon: {
      marginLeft: 6,
      width: 16,
      height: 16
    },
    primaryGuarantorWrapper: {
      padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(
        1
      )} ${theme.spacing(3)}`,
      display: "flex",
      color: "#32325d"
    },
    sectionButtonWrapper: {
      padding: `${theme.spacing(0)} ${theme.spacing(3)} ${theme.spacing(
        3
      )} ${theme.spacing(3)}`,
      display: "flex"
    },
    guarantorHeadingTypography: {
      textAlign: "left",
      fontSize: "14px !important",
      fontWeight: 600,
      color: "#32325d",
      lineHeight: 1.57
    },
    headingImageContainer: {
      justifyContent: "flex-end",
      display: "flex"
    },
    headingImage: {
      width: 16,
      height: 16,
      objectFit: "contain",
      cursor: "pointer",
      verticalAlign: "top"
    },
    editImage: {
      width: 16,
      height: 16,
      marginRight: 16,
      objectFit: "contain",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    editImageDisplay: {
      display: "none"
    },
    savebutton: {
      width: 70,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4"
    },
    modalButton: {
      marginRight: 12,
      color: "#ffffff",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      marginTop: 18,
      borderWidth: 0,
      "&:hover": {
        backgroundColor: "#5566d6 !important"
      }
    },
    cancelButton: {
      width: 69,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      color: "#32325d",
      backgroundColor: "#fff",
      marginLeft: 10,
      "&:hover": {
        background: "none"
      }
    },
    divider: {
      height: "1px",
      border: "solid 1px #f2f2f2",
      padding: "0px !important"
    },
    formSelectContainer: {
      justifyContent: "space-around",
      display: "flex",
      flexDirection: "row",
      width: "95%",
      padding: "8px 0px 0px 0px"
    },
    modalDeleteButton: {
      marginRight: 12,
      color: "#ffffff",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      marginTop: 18,
      borderWidth: 0
    },
    modalCancelButton: {
      color: "#32325d",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      backgroundColor: "#ffffff",
      marginTop: 18,
      borderWidth: 1,
      borderColor: "#ececec"
    },
    modalDeleteButtonConatainer: {
      marginTop: 50
    },
    iconInfo: {
      width: "16px !important",
      height: "16px !important",
      color: "#32355d",
      "&:hover": {
        color: "#5e72e4"
      }
    },
    labelInformation: {
      fontSize: 12,
      fontWeight: 600,
      color: "#8897aa",
      height: "14px",
      "&:hover": {
        color: "#5e72e4"
      }
    }
  })
);
