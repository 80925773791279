import jwt_decode from "jwt-decode";
import { DrawRolesEnum } from "./constants";
import { getCookie, removeCookie, setCookie } from "./cookies";

export enum Roles {
  LAT = "LAT",
  Originator = "originator",
  Appraiser = "APPRAISER",
  AppraisalApprover = "LAT_APPROVER",
  DRAW_MANAGER = "AM_DRAW_MANAGER"
}
export function getRoles() {
  const token = getCookie("id_token") || "";
  const parsedToken: any = token && jwt_decode(token);
  if (!parsedToken) return [];
  const { roles } = parsedToken;
  return roles;
}
export function isLATUser() {
  return getCookie("loginFlow") === "LAT";
}

export function isOrigUser() {
  return getCookie("loginFlow")?.toLowerCase() === "originator";
}

export function matchesOrganizationName(origName: string) {
  return (
    getCookie("organizationName")?.toLowerCase() === origName.toLowerCase()
  );
}

export function isGuideLine() {
  return getCookie("GUIDE") === "selected";
}

export function isRole(type: string) {
  let roleArr: any = getCookie("roles");
  if (roleArr !== null || roleArr !== undefined) {
    roleArr = JSON.parse(roleArr);
    if (Array.isArray(roleArr)) {
      return !!roleArr.filter((role) => role === type).length;
    }
  }
  return false;
}
export function isPrivilege(type: string) {
  let privilagesArr: any = getCookie("privilages");
  if (privilagesArr !== null || privilagesArr !== undefined) {
    privilagesArr = JSON.parse(privilagesArr);
    if (Array.isArray(privilagesArr)) {
      return !!privilagesArr.filter((privilege) => privilege === type).length;
    }
  }
  return false;
}

export function isRoleBridge() {
  const isOriginatorOnlyBridge =
    isRole("ORIGINATOR_MEMBER") || isRole("ORIGINATOR_ADMIN")
      ? false
      : isRole("ORIGINATOR_BR_MEMBER") && isRole("ORIGINATOR_TY_MEMBER")
      ? false
      : isRole("ORIGINATOR_BR_MEMBER")
      ? true
      : false;
  return isOriginatorOnlyBridge;
}
export function isRoleDSCR() {
  const isOriginatorOnlyDSCR =
    isRole("ORIGINATOR_MEMBER") || isRole("ORIGINATOR_ADMIN")
      ? false
      : isRole("ORIGINATOR_BR_MEMBER") && isRole("ORIGINATOR_TY_MEMBER")
      ? false
      : isRole("ORIGINATOR_TY_MEMBER")
      ? true
      : false;
  return isOriginatorOnlyDSCR;
}
export function getExcelDataInSession() {
  const excelUploadDataInSession = sessionStorage.getItem("excelUpload");
  if (excelUploadDataInSession) {
    const excelUploadData = JSON.parse(excelUploadDataInSession);
    return excelUploadData;
  }
  return null;
}

export function getFileUpladBody(
  fileName: string,
  pidentifierVal: string,
  pidentifier: string = "LOAN",
  sidentifier?: string,
  sidentifierVal?: string,
  isDocSelf?: boolean
) {
  // topic can be waiver or exception
  // topicId can be waiverId or exceptionId

  //   const bucketName = bucketName;
  const accId: string = getCookie("org_id") || "";
  //   acc_id = acc_id !== null ? JSON.parse(acc_id) : "";
  // if (!accId) return {};
  let Bucketkey;
  if (isDocSelf) {
    const date = new Date();
    const key =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" + // Month starts from 0
      date.getDate() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes() +
      ":" +
      date.getSeconds();
    Bucketkey = `SECURITIZATION/BRIDGE/${key}/${fileName}`;
  } else {
    Bucketkey = `ACCOUNT/${accId}/${pidentifier.toUpperCase()}/${pidentifierVal}`;
    Bucketkey = !sidentifier
      ? `${Bucketkey}/DOCUMENTS/${new Date().getTime()}/${fileName}`
      : `${Bucketkey}/${sidentifier.toUpperCase()}/${sidentifierVal}/DOCUMENTS/${new Date().getTime()}/${fileName}`;
  }
  return {
    // BucketName: `${bucketName}`,
    Bucketkey
  };
}

export function checkUser(user: string[]) {
  const userLoggedIn = getCookie("loginFlow");
  return userLoggedIn && user?.length && user.includes(userLoggedIn);
}

export function checkIfHasRole(candidateRoleTypes: string[]) {
  const roles = getRoles();
  return roles.some((role: string) => {
    return candidateRoleTypes.some((cRoleType: string) => {
      // Returns true if a user role contains the value of a login role
      return role.toUpperCase().includes(cRoleType.toUpperCase());
    });
  });
}

export function isILP() {
  return window.location.pathname.startsWith("/internal/loan");
}

export function pathflow() {
  return window.location.pathname.startsWith("/internal/drawDetails");
}

export function isAssetManagement() {
  if (isOrigUser()) {
    return window.location.pathname.startsWith("/asset/home");
  }
  return (
    window.location.pathname.startsWith("/asset/home") ||
    window.location.pathname.startsWith("/drawDetails") ||
    window.location.pathname.startsWith("/serviceronboarding") ||
    window.location.pathname.startsWith("/purchasedLoanDetails")
  );
}

export const isPurchasedLoansOnly = (): boolean => {
  return window.location.pathname.startsWith("/purchasedLoanDetails/loan");
};

export const isDrawAMRole = () => {
  return (
    isRole(DrawRolesEnum.DRAW_MEMBER) || isRole(DrawRolesEnum.DRAW_MANAGER)
  );
};

export const isServicerOnboardingOnly = (): boolean => {
  return window.location.pathname.startsWith("/serviceronboarding/loan");
};

/** check if loan is on-hold or not, default value is null
 * for on-hold value but if we add and remove it will show NONE */
export function isLoanOnHold(onHoldBy: string | null): boolean {
  let loanOnHold = true;
  if (onHoldBy === null || onHoldBy === undefined || onHoldBy === "NONE") {
    loanOnHold = false;
  }
  return loanOnHold;
}

// set tback the original org id when we create loan from LAT
export function setBackToOriginalOrgId() {
  const isCurrentOrg = getCookie("current_org_id");
  if (isCurrentOrg) {
    setCookie("org_id", getCookie("current_org_id"));
    removeCookie("current_org_id");
  }
}
