/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
// import produce from "immer";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import {
//   UPDATE_BULK_INQUIRY_RESPONSE,
//   SET_BULK_APPROVER_ACTIONS_DATA,
//   SET_BULK_SELECTED_INQUIRIES,
//   SET_BULK_ACTION_FORM,
//   UPDATE_BULK_VISIBLE_ORG_RESPONSE,
//   GET_APPROVER_DASHBOARD_LOADING,
//   MAP_WAIVER_TO_EXCEPTIONS,
//   SET_TAB_VALUE,
//   SET_BULK_APPROVER_VTO_DATA,
//   SET_BULK_APPROVER_INQUIRY_DATA,
//   UPDATE_COMPLETE_DETAILS,
//   ADD_VIEWMORE_DETAILS,
//   SET_ORIGINATOR_NAMES,
//   SET_SPOC_NAMES,
//   BULK_CONDITION_STATUS_UPDATE,
//   UPDATE_PAGE_SIZE,
//   UPDATE_SETVIEWMORE_TO_BULK_ARRAY,
//   UPDATE_WORKFLOW_STATE_BULK,
//   GET_EXCEPTIONS_INQUIRIES,
//   SET_APPLIED_FILTERS,
//   INITIAL_COMMENT_SOFT_UPDATE_BULK,
//   SOFT_UPDATE_BULK_INQ
// } from "./ApproverDashboard.actions";
import { InternalDDStatusEnum, ResponseTypeEnum } from "../../utils/constants";

export interface ApproverDashboardStore {
  bulkInquiryData: any;
  bulkVisibleToOrgData: any;
  bulkSelectedInquiries: any[];
  bulkActionFormData: any;
  approverDashboardLoading: boolean;
  tab: string;
  filters: any;
  originatorNames: any;
  bulkExceptionsInquiries: any;
  spocNames: any;
}

const initialState: ApproverDashboardStore = {
  bulkInquiryData: {
    loans: [],
    from: 1,
    totalCount: 0,
    totalPages: 0,
    size: 10
  },
  bulkSelectedInquiries: [],
  bulkActionFormData: {},
  bulkVisibleToOrgData: {
    loans: [],
    from: 1,
    totalCount: 0,
    totalPages: 0,
    size: 10
  },
  approverDashboardLoading: false,
  tab: "internal",
  bulkExceptionsInquiries: {
    loanId: null,
    exceptions: []
  },
  filters: {
    ageing: [],
    conditionStatus: [],
    originatorResponse: [],
    fundingType: [],
    grade: [],
    loanType: [],
    originators: [],
    recommendations: [],
    tag: [],
    loanId: null,
    tab: "internal",
    originatorNames: [],
    spoc: []
  },
  originatorNames: [],
  spocNames: []
};

const ApproverDashboardSlice = createSlice({
  name: "approverDashboard",
  initialState: initialState,
  reducers: {
    getApproverDashLoading: (state) => {
      state.approverDashboardLoading = true;
    },
    setBulkApproverActionsData: (state, action: PayloadAction<any>) => {
      let { bulkInquiryRes, bulkVTORes } = action.payload;
      if (bulkInquiryRes?.loans?.length === 0) {
        bulkInquiryRes.totalCount = 0;
        bulkInquiryRes.totalPages = 0;
      }
      if (bulkVTORes?.loans?.length === 0) {
        bulkVTORes.totalCount = 0;
        bulkVTORes.totalPages = 0;
      }
      state.bulkInquiryData = bulkInquiryRes;
      state.bulkVisibleToOrgData = bulkVTORes;
      // filter.tab = state.tab;
      // draftState.filters = filter;
    },
    setBulkSelectedInquiries: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.bulkSelectedInquiries = payload;
    },
    setBulkActionFormData: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.bulkActionFormData = payload;
    },
    setBulkApproverVtoData: (state, action: PayloadAction<any>) => {
      const { bulkVTORes } = action.payload;
      if (bulkVTORes?.loans?.length === 0) {
        bulkVTORes.totalCount = 0;
        bulkVTORes.totalPages = 0;
      }
      state.bulkVisibleToOrgData = bulkVTORes;
    },
    setBulkApproverInquiryData: (state, action: PayloadAction<any>) => {
      const { bulkInquiryRes } = action.payload;
      if (bulkInquiryRes?.loans?.length === 0) {
        bulkInquiryRes.totalCount = 0;
        bulkInquiryRes.totalPages = 0;
      }
      state.bulkInquiryData = bulkInquiryRes;
    },
    updateCompleteDetails: (state, action: PayloadAction<any>) => {
      const { data, loanId } = action.payload;
      // this is to update the store after creating conditions from sdd bulk
      const filteredData: any = JSON.parse(JSON.stringify(data));
      filteredData.exceptions.exceptions = data?.exceptions?.exceptions?.filter(
        (item: any) => {
          return (
            item.visibility === "external" &&
            item?.type?.toLowerCase() === "inquiry"
          );
        }
      );
      const newArr = JSON.parse(
        JSON.stringify(state?.bulkVisibleToOrgData.loans)
      );
      if (newArr.length) {
        const loanIndex: any = state?.bulkVisibleToOrgData.loans?.findIndex(
          (item: any) => item?.toorakLoanId === loanId
        );
        const exceptions = filteredData?.exceptions?.exceptions?.filter(
          (eachException: any) => {
            return (
              // check if the response is "make it visible to org"
              eachException.visibility === "external" &&
              eachException.response?.some((eachResponse: any) => {
                return eachResponse.responseType === ResponseTypeEnum.Approver;
              })
            );
          }
        );
        const exceptionIds = exceptions?.map((eachException: any) => {
          return eachException.exceptionId;
        });
        newArr[loanIndex].completeDetails.exceptions.exceptions = exceptions;
        const newCompleteDetailsObj = newArr[loanIndex]?.completeDetails ?? {};
        const tempLoanDetails = {
          ...newCompleteDetailsObj,
          ...filteredData
        };
        newArr[loanIndex].completeDetails = tempLoanDetails;
        newArr[loanIndex].exceptionIds = exceptionIds;
      }
      state.bulkVisibleToOrgData.loans = newArr;
      state.bulkVisibleToOrgData.totalCount =
        state.bulkVisibleToOrgData.totalCount + 1;
    },
    updateBulkInqResponse: (state, action: PayloadAction<any>) => {
      const { bulkData, loanId } = action.payload;
      const newArr = JSON.parse(JSON.stringify(state?.bulkInquiryData.loans));
      if (newArr?.length) {
        const index = state?.bulkInquiryData.loans?.findIndex(
          (item: any) => item?.toorakLoanId === loanId
        );
        if (index !== -1 && newArr?.[index]) {
          newArr[index].completeDetails = bulkData;
          state.bulkInquiryData.loans = newArr;
        }
      }
    },
    updateBulkVOResponse: (state, action: PayloadAction<any>) => {
      const { bulkData, loanId } = action.payload;
      const newArr = JSON.parse(
        JSON.stringify(state?.bulkVisibleToOrgData?.loans)
      );
      if (newArr?.length) {
        const index = state?.bulkVisibleToOrgData.loans?.findIndex(
          (item: any) => item?.toorakLoanId === loanId
        );
        if (index !== -1 && newArr?.[index]) {
          newArr[index].completeDetails = {
            ...(newArr[index].completeDetails ?? {}),
            ...bulkData
          };
          // newArr[index].completeDetails = bulkData;
        }
        state.bulkVisibleToOrgData.loans = newArr;
      }
    },
    mapWaiverToExceptions: (state, action: PayloadAction<any>) => {
      const { waiver, loanId } = action.payload;
      const newArr = JSON.parse(
        JSON.stringify(state?.bulkVisibleToOrgData?.loans)
      );
      if (newArr?.length) {
        const index = state?.bulkVisibleToOrgData.loans?.findIndex(
          (item: any) => item?.toorakLoanId === loanId
        );
        if (index !== -1 && newArr?.[index]) {
          newArr[index].completeDetails.waiverResult = waiver;
        }
        state.bulkVisibleToOrgData.loans = newArr;
      }
    },
    setTabValue: (state, action: PayloadAction<any>) => {
      const { value } = action.payload;
      state.tab = value;
    },
    addViewMoreDetails: (state, action: PayloadAction<any>) => {
      const { data, exceptionId, setViewMore = false, loanId } = action.payload;

      const newArr = JSON.parse(
        JSON.stringify(state?.bulkVisibleToOrgData?.loans)
      );
      if (newArr?.length) {
        const loanIndex = state?.bulkVisibleToOrgData.loans?.findIndex(
          (item: any) => item?.toorakLoanId === loanId
        );
        const exceptionIndex = newArr[
          loanIndex
        ]?.completeDetails?.exceptions.exceptions?.findIndex(
          (item: any) => item?.exceptionId === exceptionId
        );
        const exceptionsArr =
          newArr[loanIndex]?.completeDetails?.exceptions?.exceptions;
        if (
          exceptionIndex !== -1 &&
          data?.exceptions?.response &&
          exceptionsArr?.[exceptionIndex]
        ) {
          exceptionsArr[exceptionIndex].responses = data.exceptions.response;
          exceptionsArr[exceptionIndex].exceptions = data.exceptions;
          const newResp = exceptionsArr[exceptionIndex].responses.map(
            (item: any) => {
              let chatLiness = data.chatLines.map((items: any) => {
                if (items[item.chatSequenceId])
                  return items[item.chatSequenceId];
              });
              chatLiness = chatLiness.filter((chat: any) => chat !== undefined);
              item.chatLines = chatLiness[0];
              item.accounts = data.accounts;
              item.customers = data.customers;
              item.accountMapping = data.accountMapping;
              item.latestComment = data.latestComment ?? [];

              return item;
            }
          );
          exceptionsArr[exceptionIndex].responses = newResp;
          exceptionsArr[exceptionIndex].viewMore = setViewMore;
        }
        if (data?.waiverData?.waivers) {
          const waivers = data.waiverData.waivers.map(
            (waiver: any, index: any) => {
              waiver.documents =
                data.waiverData.documents[index] &&
                data.waiverData.documents[index].primaryIdentifierValue ===
                  waiver.waiverId
                  ? data.waiverData.documents[index]?.docs
                  : [];
              return waiver;
            }
          );
          const newExp = {
            waivers: [...waivers],
            customers: data.waiverData.customers,
            accountMapping: data.waiverData.accountMapping,
            accounts: data.waiverData.accounts,
            documents: data.waiverData.documents,
            properties: data.waiverData.properties
          };

          exceptionsArr[exceptionIndex].waiverResult = newExp;
        }
        state.bulkVisibleToOrgData.loans[
          loanIndex
        ].completeDetails.exceptions.exceptions[exceptionIndex] =
          exceptionsArr[exceptionIndex];
        state.bulkVisibleToOrgData.loans[
          loanIndex
        ].completeDetails.waiverResult =
          exceptionsArr[exceptionIndex].waiverResult;
      }
    },
    bulkConditionStatusUpdate: (state, action: PayloadAction<any>) => {
      const { data, workflowState, conclusionComment } = action.payload;
      //  const arr = JSON.parse(
      //    JSON.stringify(state?.exceptionsResult.exceptions)
      //  );
      //  if (arr.length) {
      const loansArr = JSON.parse(
        JSON.stringify(state.bulkVisibleToOrgData.loans)
      );
      if (loansArr.length) {
        data?.forEach((eachData: any, index: any) => {
          const loanIndex = loansArr.findIndex((eachLoan: any) => {
            return eachData.loanId === eachLoan.toorakLoanId;
          });
          const { exceptions } = loansArr[loanIndex].completeDetails.exceptions;
          const tempIndex: any = exceptions.findIndex(
            (item: any) =>
              item?.exceptionId === eachData?.exceptionId &&
              eachData.visibility === "external"
          );
          if (tempIndex !== -1) {
            exceptions[tempIndex].response.push(eachData);
            exceptions[tempIndex].workflowState = workflowState;
            if (conclusionComment !== "") {
              exceptions[tempIndex].conclusionComment = conclusionComment;
            }
            exceptions[tempIndex].status = workflowState;
            loansArr[loanIndex].completeDetails.exceptions.exceptions =
              exceptions;
          }
        });
        state.bulkVisibleToOrgData.loans = loansArr;
      }
    },
    setOriginatorNames: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.originatorNames = payload;
    },
    updatedPageSize: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      if (state.tab === "internal") {
        state.bulkInquiryData.size = payload;
      } else {
        state.bulkVisibleToOrgData.size = payload;
      }
    },
    setSpocNames: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.spocNames = payload;
    },
    updateSetViewmoreBulkArray: (state, action: PayloadAction<any>) => {
      const { exceptionId, loanId, setViewMore } = action.payload;
      const loansArr = JSON.parse(
        JSON.stringify(state.bulkVisibleToOrgData.loans)
      );
      if (loansArr.length) {
        const loanIndex = loansArr.findIndex((eachLoan: any) => {
          return loanId === eachLoan.toorakLoanId;
        });
        const { exceptions } = loansArr[loanIndex].completeDetails.exceptions;
        const tempIndex: any = exceptions.findIndex(
          (item: any) =>
            item?.exceptionId === exceptionId && item.visibility === "external"
        );
        exceptions[tempIndex].viewMore = setViewMore;
        loansArr[loanIndex].completeDetails.exceptions.exceptions = exceptions;
        state.bulkVisibleToOrgData.loans = loansArr;
      }
    },
    updateWorkflowStateBulk: (state, action: PayloadAction<any>) => {
      const { workflowState, loanId } = action.payload;
      const inqLoanIndex = state.bulkInquiryData.loans.findIndex(
        (eachLoan: any) => {
          return eachLoan.toorakLoanId === loanId;
        }
      );
      const tempLoanArr = JSON.parse(
        JSON.stringify(state.bulkInquiryData.loans)
      );
      tempLoanArr[inqLoanIndex].workflowState = workflowState;
      state.bulkInquiryData.loans = tempLoanArr;
      // update vto tab data
      if (workflowState === InternalDDStatusEnum.ReviewCompleted) {
        const vtoLoanIndex = state.bulkVisibleToOrgData.loans.findIndex(
          (eachLoan: any) => {
            return eachLoan.toorakLoanId === loanId;
          }
        );
        const tempVtoLoanArr = JSON.parse(
          JSON.stringify(state.bulkVisibleToOrgData.loans)
        );
        tempVtoLoanArr[vtoLoanIndex].workflowState = workflowState;
        state.bulkVisibleToOrgData.loans = tempVtoLoanArr;
      }
    },
    getExceptionsInquiries: (state, action: PayloadAction<any>) => {
      const { loanId, bulkExceptionAndInq } = action.payload;
      state.bulkExceptionsInquiries.loanId = loanId;
      state.bulkExceptionsInquiries.exceptions =
        bulkExceptionAndInq?.exceptions?.exceptions ?? [];
    },
    setAppliedFilters: (state, action: PayloadAction<any>) => {
      const { filters } = action.payload;
      state.filters = filters;
    },
    initailCommentSoftUpdateBulk: (state, action: PayloadAction<any>) => {
      const { initialComment, exceptionId, loanId } = action.payload;
      const tempVTOLoans = JSON.parse(
        JSON.stringify(state.bulkVisibleToOrgData.loans)
      );
      const loanIndex = tempVTOLoans.findIndex((eachLoan: any) => {
        return eachLoan.toorakLoanId === loanId;
      });
      const { exceptions } = tempVTOLoans[loanIndex].completeDetails.exceptions;
      const tempIndex: any = exceptions.findIndex(
        (item: any) =>
          item?.exceptionId === exceptionId && item.visibility === "external"
      );
      if (tempIndex > -1) {
        exceptions[tempIndex].initialComment =
          initialComment; /** *update the initial comment of the exception */
      }
      tempVTOLoans[loanIndex].completeDetails.exceptions.exceptions =
        exceptions;
      const { latestComment } = tempVTOLoans[loanIndex].completeDetails;
      const lcIndex = latestComment.findIndex((eachComment: any) => {
        return eachComment.exceptionId === exceptionId;
      });
      if (lcIndex > -1) {
        latestComment[lcIndex].accountType =
          "LAT"; /** find the index of exception latest comment and update the actionBy */
      }
      tempVTOLoans[loanIndex].completeDetails.latestComment = latestComment;
      state.bulkVisibleToOrgData.loans = tempVTOLoans;
    },
    softUpdateBulkInq: (state, action: PayloadAction<any>) => {
      const data = action.payload;
      const tempBulkInquiryData = JSON.parse(
        JSON.stringify(state.bulkInquiryData)
      );
      data.forEach((eachItem: any) => {
        const { chatSequenceId } = eachItem;
        const loanIndex = tempBulkInquiryData.loans?.findIndex(
          (eachLoan: any) => {
            return eachLoan.toorakLoanId === eachItem.loanId;
          }
        );

        const exceptionIndex = tempBulkInquiryData.loans?.[
          loanIndex
        ]?.completeDetails.exceptions.exceptions.findIndex(
          (eachException: any) => {
            return eachException.exceptionId === eachItem.exceptionId;
          }
        );
        if (exceptionIndex !== -1) {
          tempBulkInquiryData.loans[
            loanIndex
          ]?.completeDetails.exceptions.exceptions?.[
            exceptionIndex
          ].response.push(eachItem);
          tempBulkInquiryData.loans[
            loanIndex
          ].completeDetails.exceptions.exceptions[exceptionIndex].status =
            eachItem.tags?.[0];
          tempBulkInquiryData.loans[
            loanIndex
          ].completeDetails.exceptions.exceptions[
            exceptionIndex
          ].workflowState = eachItem.tags?.[0];
          const chatlines: any = {
            [chatSequenceId]: [eachItem]
          };
          tempBulkInquiryData.loans[loanIndex].completeDetails.chatLines.push(
            chatlines
          );
        }
      });
      state.bulkInquiryData = tempBulkInquiryData;
    }
  }
});

export const {
  getApproverDashLoading,
  setBulkSelectedInquiries,
  setBulkApproverActionsData,
  setBulkActionFormData,
  setBulkApproverVtoData,
  setBulkApproverInquiryData,
  updateCompleteDetails,
  updateBulkInqResponse,
  updateBulkVOResponse,
  mapWaiverToExceptions,
  setTabValue,
  addViewMoreDetails,
  bulkConditionStatusUpdate,
  setOriginatorNames,
  updatedPageSize,
  setSpocNames,
  updateSetViewmoreBulkArray,
  updateWorkflowStateBulk,
  getExceptionsInquiries,
  setAppliedFilters,
  initailCommentSoftUpdateBulk,
  softUpdateBulkInq
} = ApproverDashboardSlice.actions;

export const ApproverDashboardReducer = ApproverDashboardSlice.reducer;

// export const ApproverDashboardReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case GET_APPROVER_DASHBOARD_LOADING: {
//       return produce(state, (draftState) => {
//         draftState.approverDashboardLoading = true;
//       });
//     }
//     case SET_BULK_APPROVER_ACTIONS_DATA: {
//       let { bulkInquiryRes, bulkVTORes } = action.payload;
//       return produce(state, (draftState) => {
//         if (bulkInquiryRes?.loans?.length === 0) {
//           bulkInquiryRes.totalCount = 0;
//           bulkInquiryRes.totalPages = 0;
//         }
//         if (bulkVTORes?.loans?.length === 0) {
//           bulkVTORes.totalCount = 0;
//           bulkVTORes.totalPages = 0;
//         }
//         draftState.bulkInquiryData = bulkInquiryRes;
//         draftState.bulkVisibleToOrgData = bulkVTORes;
//         // filter.tab = state.tab;
//         // draftState.filters = filter;
//       });
//     }
//     case SET_BULK_SELECTED_INQUIRIES: {
//       const { payload } = action;
//       return produce(state, (draftState) => {
//         draftState.bulkSelectedInquiries = payload;
//       });
//     }
//     case SET_BULK_ACTION_FORM: {
//       const { payload } = action;
//       return produce(state, (draftState) => {
//         draftState.bulkActionFormData = payload;
//       });
//     }
//     case SET_BULK_APPROVER_VTO_DATA: {
//       const { bulkVTORes } = action.payload;
//       return produce(state, (draftState) => {
//         if (bulkVTORes?.loans?.length === 0) {
//           bulkVTORes.totalCount = 0;
//           bulkVTORes.totalPages = 0;
//         }
//         draftState.bulkVisibleToOrgData = bulkVTORes;
//       });
//     }
//     case SET_BULK_APPROVER_INQUIRY_DATA: {
//       const { bulkInquiryRes } = action.payload;
//       return produce(state, (draftState) => {
//         if (bulkInquiryRes?.loans?.length === 0) {
//           bulkInquiryRes.totalCount = 0;
//           bulkInquiryRes.totalPages = 0;
//         }
//         draftState.bulkInquiryData = bulkInquiryRes;
//       });
//     }
//     case UPDATE_COMPLETE_DETAILS: {
//       const { data, loanId } = action.payload;
//       // this is to update the store after creating conditions from sdd bulk
//       return produce(state, (draftState) => {
//         const filteredData: any = JSON.parse(JSON.stringify(data));
//         filteredData.exceptions.exceptions =
//           data?.exceptions?.exceptions?.filter((item: any) => {
//             return (
//               item.visibility === "external" &&
//               item?.type?.toLowerCase() === "inquiry"
//             );
//           });
//         const newArr = JSON.parse(
//           JSON.stringify(state?.bulkVisibleToOrgData.loans)
//         );
//         if (newArr.length) {
//           const loanIndex: any = state?.bulkVisibleToOrgData.loans?.findIndex(
//             (item: any) => item?.toorakLoanId === loanId
//           );
//           const exceptions = filteredData?.exceptions?.exceptions?.filter(
//             (eachException: any) => {
//               return (
//                 // check if the response is "make it visible to org"
//                 eachException.visibility === "external" &&
//                 eachException.response?.some((eachResponse: any) => {
//                   return (
//                     eachResponse.responseType === ResponseTypeEnum.Approver
//                   );
//                 })
//               );
//             }
//           );
//           const exceptionIds = exceptions?.map((eachException: any) => {
//             return eachException.exceptionId;
//           });
//           newArr[loanIndex].completeDetails.exceptions.exceptions = exceptions;
//           const newCompleteDetailsObj =
//             newArr[loanIndex]?.completeDetails ?? {};
//           const tempLoanDetails = {
//             ...newCompleteDetailsObj,
//             ...filteredData
//           };
//           newArr[loanIndex].completeDetails = tempLoanDetails;
//           newArr[loanIndex].exceptionIds = exceptionIds;
//         }
//         draftState.bulkVisibleToOrgData.loans = newArr;
//         draftState.bulkVisibleToOrgData.totalCount =
//           state.bulkVisibleToOrgData.totalCount + 1;
//       });
//     }
//     case UPDATE_BULK_INQUIRY_RESPONSE: {
//       const { bulkData, loanId } = action.payload;
//       return produce(state, (draftState) => {
//         const newArr = JSON.parse(JSON.stringify(state?.bulkInquiryData.loans));
//         if (newArr?.length) {
//           const index = state?.bulkInquiryData.loans?.findIndex(
//             (item: any) => item?.toorakLoanId === loanId
//           );
//           if (index !== -1 && newArr?.[index]) {
//             newArr[index].completeDetails = bulkData;
//             draftState.bulkInquiryData.loans = newArr;
//           }
//         }
//       });
//     }
//     case UPDATE_BULK_VISIBLE_ORG_RESPONSE: {
//       const { bulkData, loanId } = action.payload;
//       return produce(state, (draftState) => {
//         const newArr = JSON.parse(
//           JSON.stringify(state?.bulkVisibleToOrgData?.loans)
//         );
//         if (newArr?.length) {
//           const index = state?.bulkVisibleToOrgData.loans?.findIndex(
//             (item: any) => item?.toorakLoanId === loanId
//           );
//           if (index !== -1 && newArr?.[index]) {
//             newArr[index].completeDetails = {
//               ...(newArr[index].completeDetails ?? {}),
//               ...bulkData
//             };
//             // newArr[index].completeDetails = bulkData;
//           }
//           draftState.bulkVisibleToOrgData.loans = newArr;
//         }
//       });
//     }

//     case MAP_WAIVER_TO_EXCEPTIONS: {
//       const { waiver, loanId } = action.payload;
//       return produce(state, (draftState) => {
//         const newArr = JSON.parse(
//           JSON.stringify(state?.bulkVisibleToOrgData?.loans)
//         );
//         if (newArr?.length) {
//           const index = state?.bulkVisibleToOrgData.loans?.findIndex(
//             (item: any) => item?.toorakLoanId === loanId
//           );
//           if (index !== -1 && newArr?.[index]) {
//             newArr[index].completeDetails.waiverResult = waiver;
//           }
//           draftState.bulkVisibleToOrgData.loans = newArr;
//         }
//       });
//     }
//     case SET_TAB_VALUE: {
//       const { value } = action.payload;
//       return produce(state, (draftState) => {
//         draftState.tab = value;
//       });
//     }

//     case ADD_VIEWMORE_DETAILS: {
//       return produce(state, (draftState) => {
//         const {
//           data,
//           exceptionId,
//           setViewMore = false,
//           loanId
//         } = action.payload;

//         const newArr = JSON.parse(
//           JSON.stringify(state?.bulkVisibleToOrgData?.loans)
//         );
//         if (newArr?.length) {
//           const loanIndex = state?.bulkVisibleToOrgData.loans?.findIndex(
//             (item: any) => item?.toorakLoanId === loanId
//           );
//           const exceptionIndex = newArr[
//             loanIndex
//           ]?.completeDetails?.exceptions.exceptions?.findIndex(
//             (item: any) => item?.exceptionId === exceptionId
//           );
//           const exceptionsArr =
//             newArr[loanIndex]?.completeDetails?.exceptions?.exceptions;
//           if (
//             exceptionIndex !== -1 &&
//             data?.exceptions?.response &&
//             exceptionsArr?.[exceptionIndex]
//           ) {
//             exceptionsArr[exceptionIndex].responses = data.exceptions.response;
//             exceptionsArr[exceptionIndex].exceptions = data.exceptions;
//             const newResp = exceptionsArr[exceptionIndex].responses.map(
//               (item: any) => {
//                 let chatLiness = data.chatLines.map((items: any) => {
//                   if (items[item.chatSequenceId])
//                     return items[item.chatSequenceId];
//                 });
//                 chatLiness = chatLiness.filter(
//                   (chat: any) => chat !== undefined
//                 );
//                 item.chatLines = chatLiness[0];
//                 item.accounts = data.accounts;
//                 item.customers = data.customers;
//                 item.accountMapping = data.accountMapping;
//                 item.latestComment = data.latestComment ?? [];

//                 return item;
//               }
//             );
//             exceptionsArr[exceptionIndex].responses = newResp;
//             exceptionsArr[exceptionIndex].viewMore = setViewMore;
//           }
//           if (data?.waiverData?.waivers) {
//             const waivers = data.waiverData.waivers.map(
//               (waiver: any, index: any) => {
//                 waiver.documents =
//                   data.waiverData.documents[index] &&
//                   data.waiverData.documents[index].primaryIdentifierValue ===
//                     waiver.waiverId
//                     ? data.waiverData.documents[index]?.docs
//                     : [];
//                 return waiver;
//               }
//             );
//             const newExp = {
//               waivers: [...waivers],
//               customers: data.waiverData.customers,
//               accountMapping: data.waiverData.accountMapping,
//               accounts: data.waiverData.accounts,
//               documents: data.waiverData.documents,
//               properties: data.waiverData.properties
//             };

//             exceptionsArr[exceptionIndex].waiverResult = newExp;
//           }
//           draftState.bulkVisibleToOrgData.loans[
//             loanIndex
//           ].completeDetails.exceptions.exceptions[exceptionIndex] =
//             exceptionsArr[exceptionIndex];
//           draftState.bulkVisibleToOrgData.loans[
//             loanIndex
//           ].completeDetails.waiverResult =
//             exceptionsArr[exceptionIndex].waiverResult;
//         }
//       });
//     }
//     case BULK_CONDITION_STATUS_UPDATE: {
//       return produce(state, (draftState) => {
//         const { data, workflowState, conclusionComment } = action.payload;
//         //  const arr = JSON.parse(
//         //    JSON.stringify(state?.exceptionsResult.exceptions)
//         //  );
//         //  if (arr.length) {
//         const loansArr = JSON.parse(
//           JSON.stringify(state.bulkVisibleToOrgData.loans)
//         );
//         if (loansArr.length) {
//           data?.forEach((eachData: any, index: any) => {
//             const loanIndex = loansArr.findIndex((eachLoan: any) => {
//               return eachData.loanId === eachLoan.toorakLoanId;
//             });
//             const { exceptions } =
//               loansArr[loanIndex].completeDetails.exceptions;
//             const tempIndex: any = exceptions.findIndex(
//               (item: any) =>
//                 item?.exceptionId === eachData?.exceptionId &&
//                 eachData.visibility === "external"
//             );
//             if (tempIndex !== -1) {
//               exceptions[tempIndex].response.push(eachData);
//               exceptions[tempIndex].workflowState = workflowState;
//               if (conclusionComment !== "") {
//                 exceptions[tempIndex].conclusionComment = conclusionComment;
//               }
//               exceptions[tempIndex].status = workflowState;
//               loansArr[loanIndex].completeDetails.exceptions.exceptions =
//                 exceptions;
//             }
//           });
//           draftState.bulkVisibleToOrgData.loans = loansArr;
//         }
//         //  }
//       });
//     }
//     case SET_ORIGINATOR_NAMES: {
//       const { payload } = action;
//       return produce(state, (draftState) => {
//         draftState.originatorNames = payload;
//       });
//     }
//     case UPDATE_PAGE_SIZE: {
//       const { payload } = action;
//       return produce(state, (draftState) => {
//         if (state.tab === "internal") {
//           draftState.bulkInquiryData.size = payload;
//         } else {
//           draftState.bulkVisibleToOrgData.size = payload;
//         }
//       });
//     }
//     case UPDATE_SETVIEWMORE_TO_BULK_ARRAY: {
//       const { exceptionId, loanId, setViewMore } = action.payload;
//       return produce(state, (draftState) => {
//         const loansArr = JSON.parse(
//           JSON.stringify(state.bulkVisibleToOrgData.loans)
//         );
//         if (loansArr.length) {
//           const loanIndex = loansArr.findIndex((eachLoan: any) => {
//             return loanId === eachLoan.toorakLoanId;
//           });
//           const { exceptions } = loansArr[loanIndex].completeDetails.exceptions;
//           const tempIndex: any = exceptions.findIndex(
//             (item: any) =>
//               item?.exceptionId === exceptionId &&
//               item.visibility === "external"
//           );
//           exceptions[tempIndex].viewMore = setViewMore;
//           loansArr[loanIndex].completeDetails.exceptions.exceptions =
//             exceptions;
//           draftState.bulkVisibleToOrgData.loans = loansArr;
//         }
//       });
//     }
//     case UPDATE_WORKFLOW_STATE_BULK: {
//       const { workflowState, loanId } = action.payload;
//       return produce(state, (draftState) => {
//         const inqLoanIndex = state.bulkInquiryData.loans.findIndex(
//           (eachLoan: any) => {
//             return eachLoan.toorakLoanId === loanId;
//           }
//         );
//         const tempLoanArr = JSON.parse(
//           JSON.stringify(state.bulkInquiryData.loans)
//         );
//         tempLoanArr[inqLoanIndex].workflowState = workflowState;
//         draftState.bulkInquiryData.loans = tempLoanArr;
//         // update vto tab data
//         if (workflowState === InternalDDStatusEnum.ReviewCompleted) {
//           const vtoLoanIndex = state.bulkVisibleToOrgData.loans.findIndex(
//             (eachLoan: any) => {
//               return eachLoan.toorakLoanId === loanId;
//             }
//           );
//           const tempVtoLoanArr = JSON.parse(
//             JSON.stringify(state.bulkVisibleToOrgData.loans)
//           );
//           tempVtoLoanArr[vtoLoanIndex].workflowState = workflowState;
//           draftState.bulkVisibleToOrgData.loans = tempVtoLoanArr;
//         }
//       });
//     }
//     case GET_EXCEPTIONS_INQUIRIES: {
//       const { loanId, bulkExceptionAndInq } = action.payload;
//       return produce(state, (draftState) => {
//         draftState.bulkExceptionsInquiries.loanId = loanId;
//         draftState.bulkExceptionsInquiries.exceptions =
//           bulkExceptionAndInq?.exceptions?.exceptions ?? [];
//       });
//     }
//     case SET_APPLIED_FILTERS: {
//       const { filters } = action.payload;
//       return produce(state, (draftState) => {
//         draftState.filters = filters;
//       });
//     }
//     case INITIAL_COMMENT_SOFT_UPDATE_BULK: {
//       const { initialComment, exceptionId, loanId } = action.payload;
//       return produce(state, (draftState) => {
//         const tempVTOLoans = JSON.parse(
//           JSON.stringify(state.bulkVisibleToOrgData.loans)
//         );
//         const loanIndex = tempVTOLoans.findIndex((eachLoan: any) => {
//           return eachLoan.toorakLoanId === loanId;
//         });
//         const { exceptions } =
//           tempVTOLoans[loanIndex].completeDetails.exceptions;
//         const tempIndex: any = exceptions.findIndex(
//           (item: any) =>
//             item?.exceptionId === exceptionId && item.visibility === "external"
//         );
//         if (tempIndex > -1) {
//           exceptions[tempIndex].initialComment =
//             initialComment; /** *update the initial comment of the exception */
//         }
//         tempVTOLoans[loanIndex].completeDetails.exceptions.exceptions =
//           exceptions;
//         const { latestComment } = tempVTOLoans[loanIndex].completeDetails;
//         const lcIndex = latestComment.findIndex((eachComment: any) => {
//           return eachComment.exceptionId === exceptionId;
//         });
//         if (lcIndex > -1) {
//           latestComment[lcIndex].accountType =
//             "LAT"; /** find the index of exception latest comment and update the actionBy */
//         }
//         tempVTOLoans[loanIndex].completeDetails.latestComment = latestComment;
//         draftState.bulkVisibleToOrgData.loans = tempVTOLoans;
//       });
//     }
//     default: {
//       return state;
//     }
//   }
// };
