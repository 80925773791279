import { LoanStage } from "@toorak/tc-common-fe-sdk";
import { Roles } from "../../utils/AccessManagement";
import { LoanServicerNames, LoanServicerStatus } from "../../utils/constants";

const { inProgress, initiated, completed, pending, transferred, reviewed } =
  LoanServicerStatus || {};

export const ServicerHistoryHeader = [
  { value: "Bridge" },
  { value: "DSCR" },
  { value: "Comment" },
  { value: "Date Time Stamp" },
  { value: "Action By" }
];

export const reInitiateStatusArray: LoanServicerStatus[] = [
  LoanServicerStatus.initiated,
  LoanServicerStatus.transferred
];
const { bsi, fci, shellpoint, fay } = LoanServicerNames;
export const allowedServicer = [fci, bsi, shellpoint, fay];
export const allowedOnBoardStatus = [
  inProgress,
  initiated,
  pending,
  transferred,
  reviewed
];
export const actionBtn = [
  {
    title: pending,
    role: [Roles.LAT],
    showInStage: [LoanStage.post, LoanStage.pre],
    hidden: false
  },
  {
    title: inProgress,
    role: [Roles.LAT],
    showInStage: [LoanStage.post, LoanStage.pre],
    hidden: false
  },
  {
    title: reviewed,
    role: [Roles.LAT],
    showInStage: [LoanStage.post, LoanStage.pre],
    hidden: false
  },
  {
    title: initiated,
    role: [Roles.LAT],
    showInStage: [LoanStage.post, LoanStage.pre],
    hidden: false
  },
  {
    title: transferred,
    role: [Roles.LAT],
    showInStage: [LoanStage.post, LoanStage.pre],
    hidden: false
  },
  {
    title: completed,
    role: [Roles.LAT],
    showInStage: [LoanStage.post, LoanStage.pre],
    hidden: false
  }
];

export const servicerUpdateBtn = [
  {
    title: LoanServicerNames.bsi,
    role: [Roles.LAT],
    showInStage: [LoanStage.post, LoanStage.pre],
    hidden: false
  },
  {
    title: LoanServicerNames.fci,
    role: [Roles.LAT],
    showInStage: [LoanStage.post, LoanStage.pre],
    hidden: false
  },
  // {
  //   title: LoanServicerNames.situs,
  //   role: [Roles.LAT],
  //   showInStage: [LoanStage.post, LoanStage.pre],
  //   hidden: false
  // },
  {
    title: LoanServicerNames.shellpoint,
    role: [Roles.LAT],
    showInStage: [LoanStage.post, LoanStage.pre],
    hidden: false
  },
  {
    title: LoanServicerNames.fay,
    role: [Roles.LAT],
    showInStage: [LoanStage.post, LoanStage.pre],
    hidden: false
  }
];
