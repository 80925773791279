import React from "react";
import { Box, Grid, IconButton, Popover, Theme, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { openGoogleMap } from "./KeyMetricsTextField";
import { IconButtonDropdownStylesWithoutStyle } from "../create-loan/waiver-form/DocumentsForm.style";
import { BlueInfoIcon } from "../create-loan/Icons";
import { getConfig, getLoanType } from "../config/config";
import { CloseIcon } from "../icons/close";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverBox: {
      // boxShadow: "1px 1px 2px 2px rgba(98, 115, 132, 0.22)",
      maxHeight: 250,
      maxWidth: 400,
      // padding: theme.spacing(2.5)
      padding: theme.spacing(1.5)
    },
    container: {
      padding: "4px 4px 0px 4px"
    },
    popoverInfoText: {
      justifyContent: "space-between",
      fontSize: 12,
      color: "#32325d",
      lineHeight: 1.83,
      wordBreak: "break-all",
      display: "flex"
    },
    closeButton: {
      padding: 0
    }
  })
);

export const PopOverInfo = (props: {
  popOverData: any[];
  redirectionType?: any;
  fromScenario?: any;
  attachedLoanArr?: any;
  onClose?: any;
}) => {
  const {
    popOverData,
    redirectionType,
    fromScenario,
    attachedLoanArr,
    onClose
  } = props;
  const classes = useStyles();
  const iconButtonDropdownStyles = IconButtonDropdownStylesWithoutStyle();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleRedirection = (ele: any, index: number) => {
    if (redirectionType === "googleMap") {
      openGoogleMap(ele);
    }
    if (redirectionType === "loanDetails") {
      if (attachedLoanArr && attachedLoanArr?.length) {
        const { loanType, loanStage, loanId } = attachedLoanArr[index];
        const loanTypePath = loanType.includes("30")
          ? getLoanType[1].displayValue
          : loanType.replace(/\s/g, "");
        const url = `${
          getConfig().redirectUrl
        }loan/createLoan/${loanTypePath}/${loanStage}/${loanId}`;
        window.open(url);
      }
    }
  };

  return <>
    {!fromScenario ? (
      <IconButton
        aria-label="info-property"
        component="button"
        classes={iconButtonDropdownStyles}
        onClick={handleClick}
        size="large">
        <BlueInfoIcon />
      </IconButton>
    ) : (
      <InfoOutlinedIcon onClick={handleClick} style={{ cursor: "pointer" }} />
    )}
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "left"
      }}
      style={{ zIndex: 9999 }}
    >
      <Box className={classes.popoverBox}>
        {popOverData.map((ele, index: number) => {
          return (
            <Grid
              container
              justifyContent="space-between"
              key={ele}
              className={classes.container}
            >
              <Typography
                className={classes.popoverInfoText}
                style={{
                  color: redirectionType ? "#5e72e4" : "#32325d",
                  cursor: redirectionType ? "pointer" : "default",
                  fontWeight: "bold"
                }}
                onClick={() => handleRedirection(ele, index)}
              >
                {ele}
              </Typography>
              {onClose ? (
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    onClose(ele);
                    handleClose();
                  }}
                  className={classes.closeButton}
                  size="large">
                  <CloseIcon color="rgb(94, 114, 228)" />
                </IconButton>
              ) : null}
            </Grid>
          );
        })}
      </Box>
    </Popover>
  </>;
};
