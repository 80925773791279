/* eslint-disable no-console */
/* eslint-disable no-eval */
/* eslint-disable no-nested-ternary */
/* eslint-disable default-case */
/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, ChangeEvent, useEffect } from "react";
import { Theme, Typography, Grid, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
// import { RadioButton, CreateLoanParamsBridgeLoan } from "@toorak/tc-common-fe-sdk";
import { useDispatch, useSelector } from "react-redux";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import {
  GridTextFieldForm,
  DropDown,
  LoanStage
} from "@toorak/tc-common-fe-sdk";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import { useParams } from "react-router-dom";
import moment from "moment";
import { RootState } from "../../../stores/rootReducer";
import { MMMDDYYYYDateFormat } from "../../../utils/utils";
import {
  updatePropertyUnitsInfo,
  // deletePropertyUnits,
  // UNIT_FIELD_CHANGED,
  postPropertyData
} from "../../create-loan.action";
import {
  rentDescription,
  rentDescriptionOld
} from "../../constants/loanCreationDropDownValues";
import {
  checkFieldValidity,
  editHistoryValue,
  errorDateText,
  formatValueByType,
  isEmptyValue,
  sanitizeNumericInput,
  sanitizeValueByType
} from "../../../utils/formatChecks";
import { DatePickerComponent } from "../../../frontend-sizer/FrontendSizerComponents";
import {
  globalAutoSaveOnPropertyFieldChange,
  prepareCommentObjectProperty as commentFuncn
} from "./ThirtyYearLoanPropertyInformation";
import { prepareCommentObjectFes } from "../../../frontend-sizer/FrontendSizerSectionMapping";
import { isLATUser } from "../../../utils/AccessManagement";
import { setAutoSaveValuesCall } from "../../../stores/loanAndPropertyDetails/loanAndPropertyDetails.reducer";
import {
  hideLoader,
  showLoader
} from "../../../stores/loaderAndException/loaderAndException.action";
import { EvaluationResultStore } from "../../../stores/EvaluationResults/EvaluationResults.reducer";
import {
  deletePropertyUnits,
  unitFieldChanged
} from "../../create-loan.reducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      input: {
        backgroundColor: "#ffffff !important"
      }
    },
    formControl: {
      marginTop: theme.spacing(1),
      border: "1px solid red"
    },
    labelRequired: {
      color: "#f75676",
      fontSize: "12px",
      // marginLeft: "4px",
      paddingLeft: "3px"
      // marginBottom: "4px"
    },
    iconInfo: {
      width: "16px !important",
      height: "16px !important",
      color: "#8897aa",
      "&:hover": {
        color: "#5e72e4"
      }
    },
    selectEmpty: {
      marginTop: 18,
      padding: 3,
      notchedOutline: {},
      color: "#323536",
      fontSize: 14,
      "&:before": {
        borderColor: "#cad1d7"
      },
      "&:after": {
        borderColor: "#cad1d7"
      }
    },
    sectionWrapper: {
      padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
      display: "flex"
    },
    primaryGuarantorWrapper: {
      padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(
        1
      )} ${theme.spacing(3)}`,
      display: "flex",
      color: "#32325d"
    },
    sectionButtonWrapper: {
      padding: `${theme.spacing(0)} ${theme.spacing(3)} ${theme.spacing(
        3
      )} ${theme.spacing(3)}`,
      display: "flex"
    },
    addGuarantorBtn: {
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginRight: "20px"
      }
    },
    savebutton: {
      width: 70,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4"
    },

    cancelButton: {
      width: 69,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      color: "#32325d",
      backgroundColor: "#fff",
      marginLeft: 10,
      "&:hover": {
        background: "none"
      }
    },
    editImageDisplay: {
      display: "none"
    },
    modalDeleteButtonConatainer: {
      marginTop: 50
    },
    modalButton: {
      marginRight: 12,
      color: "#ffffff",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      marginTop: 18,
      borderWidth: 0,
      "&:hover": {
        backgroundColor: "#5566d6 !important"
      }
    },
    modalCancelButton: {
      color: "#32325d",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      backgroundColor: "#ffffff",
      marginTop: 18,
      borderWidth: 1,
      borderColor: "#ececec"
    }
  })
);
export interface PropertyDetailsUnitInformationProps {
  unitInformation: {
    [index: string]: string | null;
    unitId: string;
    rentalDescription: string;
    marketRent: string;
    thirdField: string;
  };
  isEvaluationPanelVisible: boolean;
  index: number;
  numberOfUnits: number;
  isMinimized: boolean;
  resetEditableState: any;
  cancelForm?: boolean;
}

export const getDropDownData = (
  reduxFieldName: string,
  dropDownData: any = [],
  loanRuleVersions: any
) => {
  if (reduxFieldName === "leaseStatus" && loanRuleVersions?.length) {
    const ruleVersionObject = loanRuleVersions.find(
      (ele: any) => ele.name === "RULE_VERSION"
    );
    if (ruleVersionObject && ruleVersionObject.version === "v0.0") {
      return rentDescriptionOld.map((item: any) => ({
        value: item,
        label: item
      }));
    }
    return dropDownData;
  }
  return dropDownData;
};

export const PropertyDetailsUnitInformation = (props: any) => {
  const {
    unitInformation,
    isEvaluationPanelVisible,
    index,
    numberOfUnits,
    isMinimized,
    fields,
    isEditable,
    cancelForm,
    changedValueFields,
    setChangedValueFields,
    currentFieldEdit,
    setCurrentFieldEdit
  } = props;
  const validateForm = useSelector<RootState, { validate: boolean }>(
    (state: { createLoanStore: { validateForm: any } }) =>
      state.createLoanStore.validateForm
  );
  let infoContent = useSelector<RootState, any>(
    (state) => state.createLoanStore.infoContentProperty
  );
  const { loanEvaluationResult } = useSelector<
    RootState,
    EvaluationResultStore
  >((state) => state.evaluationResultsStore);

  if (infoContent) {
    infoContent = JSON.parse(JSON.stringify(infoContent));
  }
  const evalInfoContent = (infoContentId: string, unitInfo: any) => {
    const request = {
      unitInfo
    };
    let message = "";
    if (request && infoContent) {
      message =
        infoContent[
          `fieldInfo.${loanType}.${loanStage.toLowerCase()}.properties.propertyUnit.${infoContentId}`
        ];
      let firstEvaluation;
      try {
        firstEvaluation = eval(message);
        if (firstEvaluation && firstEvaluation.includes("undefined"))
          return null;
        try {
          const secondEval = eval(firstEvaluation);
          return secondEval;
        } catch (error) {
          if (firstEvaluation.includes("undefined")) return null;
          return firstEvaluation;
        }
      } catch (error) {
        return null;
      }
    }
    return null;
  };
  const { activePropertyId, loanRuleVersions } = useSelector<RootState, any>(
    (state) => state.createLoanStore.loanDetails
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const [fieldErrors, setFieldErrors] = useState<{ [index: string]: string }>(
    {}
  );
  const { loanId, loanStage, loanType } = useParams<any>() as any;

  let prepareCommentObjectProperty: any;
  if (loanStage === LoanStage.fes) {
    prepareCommentObjectProperty = prepareCommentObjectFes;
  } else {
    prepareCommentObjectProperty = commentFuncn;
  }
  const editHistory = useSelector<RootState, any>(
    (state) => state.createLoanStore.editHistory
  );
  let editHistory2: any;
  editHistory2 = editHistory;
  const {
    propertyDetails,
    loanState
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useSelector<RootState, any>((state) => state.createLoanStore.loanDetails);

  const colSpan = 4;
  const colSpanAdd = isEvaluationPanelVisible ? 6 : 5;

  const [callAutoSave, setCallAutoSave] = useState<{
    value: boolean;
    impactingFieldChange: boolean;
    changedFieldId?: string;
  }>({ value: false, impactingFieldChange: false });

  /** This whole function is to be removed by Aug 30 2021 or so(once old loans in pipeline are removed)
   * This code is added as per client request to support old loans in pipeline.
   */
  useEffect(() => {
    if (callAutoSave.value) {
      if (loanStage !== "FES") {
        globalAutoSaveOnPropertyFieldChange(loanId, propertyDetails);
        setCallAutoSave({ value: false, impactingFieldChange: false });
      } else {
        dispatch(
          setAutoSaveValuesCall({
            callAutoSave: true,
            tabType: "properties",
            changedFieldId: callAutoSave.changedFieldId || ""
          })
        );
      }
    }
  }, [callAutoSave]);
  useEffect(() => {
    if (cancelForm) {
      // setEditable(!isEditable);
      console.log("empty setChangedValueFields");
      setChangedValueFields({});
    }
  }, [cancelForm]);
  useEffect(() => {
    if (validateForm.validate && unitInformation?.payload) {
      let error: { [index: string]: any } = {};
      fields.forEach((item: any) => {
        if (
          !item.optionalCondition ||
          !item.optionalCondition({
            unitInformation: unitInformation?.payload
          }) ||
          (!isEmptyValue(unitInformation.payload[item.reduxFieldName]) &&
            !checkFieldValidity(unitInformation.payload[item.reduxFieldName], {
              fieldTpe: item.fieldType,
              extraData:
                item.extraData && item.extraData?.map((ele: any) => ele.value)
            }))
        ) {
          let errorText = "error...";
          switch (item.fieldType) {
            case "dropDown":
              const dropDownData = getDropDownData(
                item.reduxFieldName,
                item.dropDownData,
                loanRuleVersions
              );
              errorText =
                unitInformation.payload[item.reduxFieldName] === ""
                  ? "This field is required"
                  : checkFieldValidity(
                      unitInformation.payload[item.reduxFieldName],
                      {
                        fieldTpe: "dropDown",
                        extraData: dropDownData?.map((ele: any) => ele.value)
                      }
                    )
                  ? ""
                  : "This field is required";
              break;
            case "number":
              errorText =
                unitInformation.payload[item.reduxFieldName] === null ||
                unitInformation.payload[item.reduxFieldName] === ""
                  ? "This field is required"
                  : checkFieldValidity(
                      unitInformation.payload[item.reduxFieldName],
                      {
                        fieldTpe: "number"
                      }
                    ) || unitInformation.payload[item.reduxFieldName] === 0
                  ? ""
                  : "Enter a valid number";
              break;
            case "decimal":
              errorText = isEmptyValue(
                unitInformation.payload[item.reduxFieldName]
              )
                ? "This field is required"
                : checkFieldValidity(
                    unitInformation.payload[item.reduxFieldName],
                    {
                      fieldTpe: "decimal"
                    }
                  )
                ? ""
                : "Enter a valid number";
              break;
            case "currency":
              errorText =
                unitInformation.payload[item.reduxFieldName] === ""
                  ? "This field is required"
                  : checkFieldValidity(
                      unitInformation.payload[item.reduxFieldName],
                      {
                        fieldTpe: "currency"
                      }
                    )
                  ? ""
                  : "Enter a valid currency value";
              break;
            case "date":
              errorText = isEmptyValue(
                unitInformation.payload[item.reduxFieldName]
              )
                ? "This field is required"
                : checkFieldValidity(
                    unitInformation.payload[item.reduxFieldName],
                    {
                      fieldTpe: "date"
                    }
                  )
                ? ""
                : errorDateText(unitInformation.payload[item.reduxFieldName]);
              break;
          }
          error = { ...error, ...{ [item.reduxFieldName]: errorText } };
        }
      });
      setFieldErrors(error);
    }
  }, [validateForm, unitInformation]);

  const handleAddUnits = async () => {
    try {
      dispatch(showLoader(true));
      let currentProperty = propertyDetails.find(
        (ele: any) => Number(ele.propertyId) === Number(activePropertyId)
      );
      let newUnit: any = {
        errors: null,
        unitId: null,
        payload: {
          leaseStatus: rentDescription[0],
          currentLeaseTerm: "",
          marketRentMonthly: null,
          inPlaceLeaseRent: null,
          recentLeaseRent: null,
          leaseStartDate: null,
          leaseEndDate: null
        }
      };
      currentProperty = {
        ...currentProperty,
        ...{
          unitsInformation: [...currentProperty.unitsInformation, ...[newUnit]]
        }
      };

      /** filter properties and replace current propety with modified property */
      const propertyDetailsToSend = propertyDetails.map((ele: any) => {
        if (Number(ele.propertyId) === Number(activePropertyId)) {
          return currentProperty;
        }
        return ele;
      });
      console.log(propertyDetails, propertyDetailsToSend);
      const promise = await postPropertyData(
        loanId,
        loanStage,
        propertyDetailsToSend
      );
      const resp = promise.data.find(
        (ele: any) => Number(ele.loanPropertyId) === Number(activePropertyId)
      );
      let maxUnitId = 0;
      resp.propertyId.propertyUnits.forEach((ele: any) => {
        if (ele.propertyUnitId > maxUnitId) maxUnitId = ele.propertyUnitId;
      });
      const newUnits = { ...newUnit, ...{ unitId: maxUnitId } };
      dispatch(updatePropertyUnitsInfo(maxUnitId, activePropertyId, newUnits));
      dispatch(hideLoader(true));
    } catch (error) {
      dispatch(hideLoader(true));
      console.log(error);
    }
  };
  const handleDeleteUnits = () => {
    dispatch(
      deletePropertyUnits({ unitId: unitInformation.unitId, activePropertyId })
    );
  };
  function updateRedux(reduxFieldName: string, value: any) {
    let payload = {
      [reduxFieldName]: value as string
    };
    if (reduxFieldName === "leaseStatus") {
      payload = {
        ...payload,
        ...{
          inPlaceLeaseRent: "",
          recentLeaseRent: "",
          marketRentMonthly: "",
          currentLeaseTerm: ""
        }
      };
    }

    dispatch(
      updatePropertyUnitsInfo(unitInformation.unitId, activePropertyId, {
        payload
      })
    );
  }
  const onblur =
    (
      fieldType: string,
      reduxFieldName: string,
      fieldName?: string,
      ruleFieldName?: string,
      editHistoryName?: string
    ) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (
        event?.target.parentElement?.parentElement?.innerText.includes(
          "Reason For Editing"
        )
      ) {
        if (!ruleFieldName) {
          ruleFieldName = editHistoryName;
        }
        prepareCommentObjectProperty(
          `data.properties[${activePropertyId}].propertyUnit[${index}].${ruleFieldName}`,
          unitInformation.payload[`${reduxFieldName}`],
          event.target.value as string
        );
        return;
      }
      if (isEvaluationPanelVisible) {
        // dispatch({
        //   type: UNIT_FIELD_CHANGED,
        //   payload: {
        //     unitChangedIds: JSON.stringify(changedValueFields),
        //     totalUnitFieldChange: Object.keys(changedValueFields)
        //   }
        // });
        dispatch(
          unitFieldChanged({
            unitChangedIds: JSON.stringify(changedValueFields),
            totalUnitFieldChange: Object.keys(changedValueFields)
          })
        );
      }
    };
  const onChange =
    (
      currentIndex: string,
      fieldType: string,
      reduxFieldName: string,
      fieldName?: string,
      ruleFieldName?: string,
      editHistoryName?: string
    ) =>
    (
      event:
        | ChangeEvent<HTMLInputElement>
        | ChangeEvent<{ value: unknown | string }>
    ) => {
      if (isEvaluationPanelVisible) {
        if (!ruleFieldName) {
          ruleFieldName = editHistoryName;
        }
        prepareCommentObjectProperty(
          `data.properties[${activePropertyId}].propertyUnit[${index}].${ruleFieldName}`,
          event.target.value,
          "",

          false,
          true
        );
      }
      if (!(reduxFieldName in changedValueFields)) {
        if (isEvaluationPanelVisible) {
          setChangedValueFields({
            ...changedValueFields,
            ...{ [reduxFieldName]: true }
          });
        }
      }
      updateCurrentFieldEdit(currentIndex);

      const formattedValue = event.target.value;
      updateRedux(reduxFieldName, formattedValue);
      setCallAutoSave({
        value: true,
        impactingFieldChange: true,
        changedFieldId: reduxFieldName
      });

      if (isEvaluationPanelVisible) {
        const changedValues = {
          ...changedValueFields,
          ...{ [reduxFieldName]: true }
        };
        dispatch(
          unitFieldChanged({
            unitChangedIds: JSON.stringify(changedValueFields),
            totalUnitFieldChange: Object.keys(changedValueFields)
          })
        );
      }
    };

  const onDateChange = (
    date: any,
    reduxFieldName: string,
    fieldName: string,
    editHistoryName?: string
  ) => {
    if (date) {
      if (isEvaluationPanelVisible) {
        prepareCommentObjectProperty(
          `data.properties[${activePropertyId}].propertyUnit[${index}].${editHistoryName}`,
          MMMDDYYYYDateFormat(date),
          "",
          false,
          true
        );
      }
      if (!(reduxFieldName in changedValueFields)) {
        if (isEvaluationPanelVisible) {
          setChangedValueFields({
            ...changedValueFields,
            ...{ [reduxFieldName]: true }
          });
        }
      }
      if (isEvaluationPanelVisible) {
        const changedValues = {
          ...changedValueFields,
          ...{ [reduxFieldName]: true }
        };
        dispatch(
          unitFieldChanged({
            unitChangedIds: JSON.stringify(changedValueFields),
            totalUnitFieldChange: Object.keys(changedValueFields)
          })
        );
      }
      updateRedux(reduxFieldName, date);
      setCallAutoSave({ value: true, impactingFieldChange: true });
    }
    // prepareCommentObjectProperty(
    //   `data.properties[${activePropertyId}].propertyUnit[${index}].${reduxFieldName}`,
    //   MMMDDYYYYDateFormat(unitInformation[`${reduxFieldName}`]),
    //   date as string
    // );
  };
  const dateFocusOut =
    (
      date: any,
      reduxFieldName: string,
      ruleFieldName?: any,
      editHistoryName?: string
    ) =>
    (event: ChangeEvent<HTMLInputElement> | ChangeEvent<{ value: string }>) => {
      prepareCommentObjectProperty(
        `data.properties[${activePropertyId}].propertyUnit[${index}].${editHistoryName}`,
        MMMDDYYYYDateFormat(
          unitInformation?.payload
            ? unitInformation.payload[`${reduxFieldName}`]
            : unitInformation[`${reduxFieldName}`]
        ),
        event.target.value as string
      );
    };
  const handleChange =
    (
      currentIndex: string,
      fieldType: string,
      reduxFieldName: string,
      ruleFieldName?: any,
      editHistoryName?: string
    ) =>
    (event: ChangeEvent<HTMLInputElement> | ChangeEvent<{ value: string }>) => {
      const formattedValue = sanitizeValueByType(event.target.value, fieldType);
      if (isEvaluationPanelVisible) {
        if (!ruleFieldName) {
          ruleFieldName = editHistoryName;
        }
        prepareCommentObjectProperty(
          `data.properties[${activePropertyId}].propertyUnit[${index}].${ruleFieldName}`,
          formattedValue,
          "",
          false,
          true
        );
      }
      if (!(reduxFieldName in changedValueFields)) {
        if (isEvaluationPanelVisible) {
          switch (reduxFieldName) {
            case "inPlaceLeaseRent":
              setChangedValueFields({
                ...changedValueFields,
                ...{ inPlaceLeaseRentMonthly: true }
              });
              break;
            case "currentLeaseTerm":
              setChangedValueFields({
                ...changedValueFields,
                ...{ currentLeaseTermMonths: true }
              });
              break;
            case "recentLeaseRent":
              setChangedValueFields({
                ...changedValueFields,
                ...{ mostRecentLeaseRentMonthly: true }
              });
              break;
            default:
              setChangedValueFields({
                ...changedValueFields,
                ...{ [reduxFieldName]: true }
              });
          }
        }
      }

      updateCurrentFieldEdit(currentIndex);

      updateRedux(reduxFieldName, formattedValue);
      if (isEvaluationPanelVisible) {
        const changesValues = {
          ...changedValueFields,
          ...{ [reduxFieldName]: true }
        };
        // dispatch({
        //   type: UNIT_FIELD_CHANGED,
        //   payload: {
        //     unitChangedIds: JSON.stringify(changesValues),
        //     totalUnitFieldChange: Object.keys(changesValues)
        //   }
        // });
        dispatch(
          unitFieldChanged({
            unitChangedIds: JSON.stringify(changesValues),
            totalUnitFieldChange: Object.keys(changesValues)
          })
        );
      }
    };

  const updateCurrentFieldEdit = (currentIndex: string) => {
    if (!currentFieldEdit.includes(currentIndex)) {
      setCurrentFieldEdit([...currentFieldEdit, currentIndex]);
    }
  };

  const handleBlur =
    (
      fieldType: string,
      reduxFieldName: string,
      ruleFieldName?: string,
      editHistoryName?: string
    ) =>
    (event: any) => {
      if (
        event?.target.parentElement?.parentElement?.innerText.includes(
          "Reason For Editing"
        )
      ) {
        if (!ruleFieldName) {
          ruleFieldName = editHistoryName;
        }
        prepareCommentObjectProperty(
          `data.properties[${activePropertyId}].propertyUnit[${index}].${ruleFieldName}`,
          unitInformation.payload[`${reduxFieldName}`],
          event.target.value as string
        );
      }
      if (
        !event?.target.parentElement?.parentElement?.innerText.includes(
          "Reason For Editing"
        )
      ) {
        let formattedValue = event.target.value;
        switch (fieldType) {
          case "number":
            formattedValue = sanitizeNumericInput(event.target.value);
            break;
          case "percentage":
            formattedValue = formatValueByType(
              event.target.value,
              "percentageWithout100x"
            );
            break;
          case "currency":
            formattedValue = formatValueByType(event.target.value, "currency");
            break;
          default: // return same value
        }
        if (isEvaluationPanelVisible) {
          // dispatch({
          //   type: UNIT_FIELD_CHANGED,
          //   payload: {
          //     unitChangedIds: JSON.stringify(changedValueFields),
          //     totalUnitFieldChange: Object.keys(changedValueFields)
          //   }
          // });
          dispatch(
            unitFieldChanged({
              unitChangedIds: JSON.stringify(changedValueFields),
              totalUnitFieldChange: Object.keys(changedValueFields)
            })
          );
        }
        updateRedux(reduxFieldName, formattedValue);
        setCallAutoSave({
          value: true,
          impactingFieldChange: true,
          changedFieldId: reduxFieldName
        });
      }
    };
  useEffect(() => {
    fields.forEach((ele: any) => {
      const { fieldType, reduxFieldName, rules } = ele;
      if (fieldType === "formula") {
        const rulesValue = rules(unitInformation.payload || {});
        if (
          unitInformation?.payload &&
          unitInformation.payload[reduxFieldName] !== rulesValue
        ) {
          updateRedux(reduxFieldName, rulesValue);
        }
      }
    });
  }, [unitInformation.payload]);
  return (
    <div key={`${activePropertyId}-${index + 1}`}>
      <Grid
        container
        item
        xs={12}
        sm={8}
        md={5}
        lg={colSpanAdd}
        xl={3}
        className={classes.primaryGuarantorWrapper}
        style={{
          display: isMinimized ? "none" : ""
        }}
      >
        <Grid item style={{ paddingTop: 7 }}>
          <Typography style={{ fontWeight: 600 }}>Unit {index + 1}</Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={4}
        className={classes.sectionWrapper}
        style={{
          display: isMinimized ? "none" : ""
        }}
      >
        {fields
          .filter(
            (ele: any) =>
              !ele.requiredLeaseStatus ||
              (ele.requiredLeaseStatus &&
                ele.requiredLeaseStatus.includes(
                  unitInformation.payload?.leaseStatus
                ))
          )
          .map((item: any) => {
            const {
              fieldId,
              fieldLabel,
              fieldType,
              dropDownData,
              reduxFieldName,
              rules,
              getValues,
              ruleFieldName,
              editedLabel,
              editHistoryName,
              optionalCondition
            } = item;
            const historyName = ruleFieldName || editHistoryName;
            switch (fieldType) {
              case "dropDown":
                return (
                  <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
                    <DropDown
                      formControlTestId={`${fieldId}-formControlTestId`}
                      inputLabelId={fieldId}
                      inputLabelTestId={`${fieldId}-inputLabelTestId`}
                      label={fieldLabel}
                      selectId={`${fieldId}-selectId`}
                      isLATView={isLATUser()}
                      selectTestId={`${fieldId}-selectTestId`}
                      selectionValue={
                        unitInformation.payload
                          ? unitInformation.payload[reduxFieldName]
                          : ""
                      }
                      editedLabel={
                        changedValueFields[editedLabel] &&
                        !cancelForm &&
                        currentFieldEdit.includes(index)
                      }
                      selectionOnChange={onChange(
                        index,
                        fieldType,
                        reduxFieldName,
                        item.fieldName,
                        item?.ruleFieldName,
                        editHistoryName
                      )}
                      enableReason={
                        changedValueFields[editedLabel] &&
                        !isLATUser() &&
                        loanState === LoanStatusEnum.DueDiligenceReview &&
                        !cancelForm &&
                        currentFieldEdit.includes(index)
                      }
                      onBlur={onblur(
                        fieldType,
                        reduxFieldName,
                        item.fieldName,
                        item?.ruleFieldName,
                        editHistoryName
                      )}
                      selectionData={getDropDownData(
                        reduxFieldName,
                        dropDownData,
                        loanRuleVersions
                      )}
                      helperText={fieldErrors[item.reduxFieldName] || ""}
                      isViewMode={isEvaluationPanelVisible && !isEditable}
                      editedData={
                        editHistory2[
                          `data.properties[${activePropertyId}].propertyUnit[${index}].${historyName}`
                        ]
                      }
                      infoIconData={evalInfoContent(
                        editedLabel,
                        unitInformation.payload
                      )}
                    />
                  </Grid>
                );
              case "number":
              case "decimal":
              case "string":
              case "percentage":
              case "currency":
                return (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={6}
                    md={colSpan}
                    xl={colSpan}
                    key={`${fieldLabel}-${index + 1}`}
                  >
                    <GridTextFieldForm
                      required={
                        !optionalCondition ||
                        !optionalCondition({
                          unitInformation: unitInformation.payload
                        })
                      }
                      value={
                        unitInformation.payload
                          ? unitInformation.payload[reduxFieldName]
                          : ""
                      }
                      error={
                        !!(
                          fieldErrors[item.reduxFieldName] &&
                          fieldErrors[item.reduxFieldName] !== ""
                        )
                      }
                      label={fieldLabel}
                      helperText={fieldErrors[item.reduxFieldName] || ""}
                      onChange={handleChange(
                        index,
                        fieldType,
                        reduxFieldName,
                        item?.ruleFieldName,
                        editHistoryName
                      )}
                      onBlur={handleBlur(
                        fieldType,
                        reduxFieldName,
                        item?.ruleFieldName,
                        editHistoryName
                      )}
                      // editedLabel={changedValueFields[changeFieldName]}
                      editedLabel={
                        changedValueFields[editedLabel] &&
                        !cancelForm &&
                        currentFieldEdit.includes(index)
                      }
                      isViewMode={isEvaluationPanelVisible && !isEditable}
                      enableReason={
                        changedValueFields[editedLabel] &&
                        !isLATUser() &&
                        loanState === LoanStatusEnum.DueDiligenceReview &&
                        !cancelForm &&
                        currentFieldEdit.includes(index)
                      }
                      editedData={editHistoryValue(
                        editHistory2[
                          `data.properties[${activePropertyId}].propertyUnit[${index}].${historyName}`
                        ],
                        fieldType
                      )}
                      infoIconData={evalInfoContent(
                        editedLabel,
                        unitInformation.payload
                      )}
                    />
                  </Grid>
                );
              case "date":
                return (
                  <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
                    <DatePickerComponent
                      value={
                        unitInformation.payload
                          ? unitInformation.payload[reduxFieldName]
                          : null
                      }
                      isViewMode={isEvaluationPanelVisible && !isEditable}
                      data-testid="data-test-id"
                      onChange={(e) => {
                        const temp = moment.utc(e).toDate();
                        // eslint-disable-next-line no-restricted-globals
                        if (e && isNaN(temp?.getTime())) return false;
                        onDateChange(
                          e,
                          reduxFieldName,
                          item.fieldName,
                          editHistoryName
                        );
                      }}
                      label={fieldLabel}
                      error={
                        !!(
                          fieldErrors[item.fieldName] &&
                          fieldErrors[item.fieldName] !== ""
                        )
                      }
                      editedLabel={
                        changedValueFields[editedLabel] && !cancelForm
                      }
                      enableReason={
                        changedValueFields[editedLabel] &&
                        !isLATUser() &&
                        loanState === LoanStatusEnum.DueDiligenceReview &&
                        !cancelForm
                      }
                      helperText={fieldErrors[item.fieldName] || ""}
                      onBlur={dateFocusOut(
                        reduxFieldName,
                        reduxFieldName,
                        item.fieldName,
                        editHistoryName
                      )}
                      editedData={
                        editHistory2[
                          `data.properties[${activePropertyId}].propertyUnit[${index}].${historyName}`
                        ]
                      }
                      required
                    />
                  </Grid>
                );
              case "formula":
                return (
                  <Grid item xs={12} sm={6} md={colSpan} xl={colSpan}>
                    <GridTextFieldForm
                      required
                      value={rules(unitInformation.payload || {})}
                      isViewMode
                      label={fieldLabel}
                      viewModeStyle={{ height: "100%" }}
                    />
                  </Grid>
                );
              case "ruleOutput":
                return (
                  <Grid item xs={12} sm={6} md={colSpan} xl={colSpan}>
                    <GridTextFieldForm
                      required
                      value={getValues({
                        unitInformation,
                        loanEvaluationResult,
                        activePropertyId
                      })}
                      isViewMode
                      label={fieldLabel}
                      viewModeStyle={{ height: "100%" }}
                    />
                  </Grid>
                );
              default:
                return <div> No items to render</div>;
            }
          })}
      </Grid>
      {!isEvaluationPanelVisible && (
        <Grid
          container
          item
          xs={12}
          md={colSpanAdd}
          sm={10}
          xl={3}
          spacing={1}
          style={{
            display: isMinimized ? "none" : ""
          }}
          className={classes.sectionButtonWrapper}
        >
          {index === numberOfUnits - 1 ? (
            <Grid
              item
              xs={12}
              sm={5}
              md={5}
              xl={5}
              style={{ paddingTop: "5px" }}
            >
              <Button
                variant="contained"
                data-testid="download-button"
                size="small"
                color="info"
                className={classes.addGuarantorBtn}
                startIcon={<AddTwoToneIcon style={{ color: "#32325d" }} />}
                onClick={handleAddUnits}
              >
                Add Unit
              </Button>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={5} md={6} xl={6} style={{ marginTop: "1px" }}>
            <Button
              variant="contained"
              data-testid="delete-button"
              className={classes.addGuarantorBtn}
              size="small"
              color="info"
              startIcon={
                <DeleteOutlineRoundedIcon style={{ color: "#32325d" }} />
              }
              disabled={!(numberOfUnits > 1)}
              onClick={handleDeleteUnits}
            >
              Delete Unit
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
};
