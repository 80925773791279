/* eslint-disable no-param-reassign */
/* eslint-disable dot-notation */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
// import uuidValidate from "uuid-validate";
import { publicClient, UserRole } from "@toorak/tc-common-fe-sdk";
import { getConfig } from "../../config/config";
import {
  isLATUser as isLAT,
  isRoleBridge,
  isRoleDSCR,
  isRole
} from "../../utils/AccessManagement";
import { getCookie } from "../../utils/cookies";
import {
  getNotificationDate,
  getMultiFetchQuery,
  setIntervalX
} from "../../utils/utils";
import {
  hideLoader,
  showLoader
} from "../loaderAndException/loaderAndException.action";
import { getCustomerData } from "../SettlementAndPurchase/SettlementAndPurchase.action";
import { tabsType } from "../../create-loan/dashboard/dashboardFilter/constants";
import { getErrorConfigObject } from "../../ErrorHandler/ErrorConfigData";
import {
  getUnreadCountWaiverCondition,
  setAllCreatedBy,
  setAllUserName,
  setAllUserNameGraph,
  setCommFilterCounts,
  setCommListData,
  setCommTotalCount,
  setLoanIds,
  setLoanPipelineData,
  setNotification,
  setOrgCustomerData,
  setPercentileOfLoanTypes,
  setSPOCInfo,
  setTradeId,
  setUnreadCountNotification,
  setVariationOfLoanPurchased,
  setVariationOfLoanPurchasedDayWise,
  setWaiverMappedExceptions,
  updateNotIndex,
  setFilters,
  addResponseToCommListArray,
  deleteExceptionResponse,
  setAllUnreadCount
} from "./dashboard.reducer";

// export const GET_VARIATION_OF_LOAN_PURCHASED =
//   "GET_VARIATION_OF_LOAN_PURCHASED";
// export const GET_VARIATION_OF_LOAN_PURCHASED_ACTION_FAIL =
//   "GET_VARIATION_OF_LOAN_PURCHASED_ACTION_FAIL";

// export const GET_PERCENTILE_OF_LOAN_TYPES = "GET_PERCENTILE_OF_LOAN_TYPES";
// export const GET_PERCENTILE_OF_LOAN_TYPES_ACTION_FAIL =
//   "GET_PERCENTILE_OF_LOAN_TYPES_ACTION_FAIL";
// export const GET_LOAN_PIPELINE_DATA = "GET_LOAN_PIPELINE_DATA";
// export const GET_LOAN_PIPELINE_DATA_ACTION_FAIL =
//   "GET_LOAN_PIPELINE_DATA_ACTION_FAIL";
// export const GET_VARIATION_OF_LOAN_PURCHASED_DAY_WISE =
//   "GET_VARIATION_OF_LOAN_PURCHASED_DAY_WISE";
// export const GET_ALL_USER_NAME = "GET_ALL_USER_NAME";
// export const GET_NOTIFICATION = "GET_NOTIFICATION";
// export const GET_NOTIFICATION_ACTION_FAIL = "GET_NOTIFICATION_ACTION_FAIL";
// export const SET_FILTERS = "SET_FILTERS";
// export const UPDATE_FROM_INDEX = "UPDATE_FROM_INDEX";
// export const GET_ALL_USER_NAME_GRAPH = "GET_ALL_USER_NAME_GRAPH";
// export const UPDATE_NOTIFICATION_STATUS = "UPDATE_NOTIFICATION_STATUS";
// export const UPDATE_TAB = "UPDATE_TAB";
// export const GET_ALL_UNREAD_COUNT = "GET_ALL_UNREAD_COUNT";
// export const GET_ALL_UNREAD_COUNT_ACTION_FAIL =
//   "GET_ALL_UNREAD_COUNT_ACTION_FAIL";
// export const GET_UNREAD_COUNT_NOTIFICATION = "GET_UNREAD_COUNT_NOTIFICATION";
// export const UPDATE_NOT_INDEX = "UPDATE_NOT_INDEX";
// export const UPDATE_NOT_CLICK = "UPDATE_NOT_CLICK";
// export const UPDATE_VIEW_DETAILS_PATH = "UPDATE_VIEW_DETAILS_PATH";
// export const GET_UNREAD_COUNT_WAIVER_CONDITION =
//   "GET_UNREAD_COUNT_WAIVER_CONDITION";
// export const SELECT_ALL_LOAN_STAGE = "SELECT_ALL_LOAN_STAGE";
// export const RESET_lOAN_STAGE_AND_STATUS = "RESET_lOAN_STAGE_AND_STATUS";
// export const SET_LOAN_TYPE_FILTERS = "SET_LOAN_TYPE_FILTERS";
// export const SET_ORG_PARTY_ID_FILTERS = "SET_ORG_PARTY_ID_FILTERS";
// export const SET_CREATEDBY_FILTERS = "SET_CREATEDBY_FILTERS";
// export const ADD_RESPONSE_TO_COMMLIST_ARRAY = "ADD_RESPONSE_TO_COMMLIST_ARRAY";
// export const DELETE_EXCEPTION_RESPONSE = "DELETE_EXCEPTION_RESPONSE";
// export const SET_LOANID_FILTERS = "SET_LOANID_FILTERS";
// export const SET_LOAN_STAGE_FILTERS = "SET_LOAN_STAGE_FILTERS";
// export const SET_WAIVER_STATUS_FILTERS = "SET_WAIVER_STATUS_FILTERS";
// export const SET_WAIVER_TYPE_FILTERS = "SET_WAIVER_TYPE_FILTERS";
// export const SET_INQUERY_STATUS_FILTERS = "SET_INQUERY_STATUS_FILTERS";
// export const SET_CONDITION_STATUS_FILTERS = "SET_CONDITION_STATUS_FILTERS";
// export const SET_SEARCH_DATA_FILTERS = "SET_SEARCH_DATA_FILTERS";
// export const SET_COMM_LIST_DATA = "SET_COMM_LIST_DATA";
// export const SET_COMM_TOTAL_COUNT = "SET_COMM_TOTAL_COUNT";
// export const SET_COMM_FILTER_COUNTS = "SET_COMM_FILTER_COUNTS";
// export const SET_ORG_CUSTOMER_DATA = "SET_ORG_CUSTOMER_DATA";
// export const SET_LOAN_ID = "SET_LOAN_ID";
// export const SET_TRADEID_FILTERS = "SET_TRADEID_FILTERS";
// export const SET_TRADE_ID = "SET_TRADE_ID";
// export const SET_SPOC_INFO = "SET_SPOC_INFO";
// export const DISABLE_DB_RESET_FILTER = "DISABLE_RESET_FILTER";
// export const IS_FULLSCREEN_COMMUNICATION_ENABLED =
//   "IS_FULLSCREEN_COMMUNICATION_ENABLED";
// export const UPDATE_FULLSCREEN_UPDATED_FLAG = "UPDATE_FULLSCREEN_UPDATED_FLAG";
// export const SET_ALL_CREATEDBY = "SET_ALL_CREATEDBY";
// export const GET_ALL_CREATEDBY_FAIL = "GET_ALL_CREATEDBY_FAIL";
// export const SET_WAIVER_MAPPED_EXCEPTIONS = "SET_WAIVER_MAPPED_EXCEPTIONS";
// export const SET_SPOCID_FILTERS = "SET_SPOCID_FILTERS";

let tabRequestBody: any;
// export const disableResetFilter: any = (val: boolean) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: DISABLE_DB_RESET_FILTER,
//       payload: {
//         disableDBReset: val
//       }
//     });
//   };
// };

// export const setCreatedBy: any = (data: any) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_ALL_CREATEDBY,
//       payload: {
//         data
//       }
//     });
//   };
// };

// export const setOrgCustomerData: any = (data: any) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_ORG_CUSTOMER_DATA,
//       payload: {
//         data
//       }
//     });
//   };
// };

// export const setLoanIds: any = (data: any) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_LOAN_ID,
//       payload: {
//         data
//       }
//     });
//   };
// };

// export const setTradeIds: any = (data: any) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_TRADE_ID,
//       payload: {
//         data
//       }
//     });
//   };
// };

// export const setSPOCInfo: any = (data: any) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_SPOC_INFO,
//       payload: {
//         data
//       }
//     });
//   };
// };

// export const setCommAllCount: any = (countObj: any) => {
//   return (dispatch: any) => {
//     const { waiverCount, condtionCount, discussionCount } = countObj;
//     dispatch({
//       type: SET_COMM_FILTER_COUNTS,
//       payload: {
//         waiverCount,
//         condtionCount,
//         discussionCount
//       }
//     });
//   };
// };

// export const setCommTotalCount: any = (count: number) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_COMM_TOTAL_COUNT,
//       payload: {
//         count
//       }
//     });
//   };
// };

export function exportFromCommunication(category: any): Promise<any> {
  return new Promise((resolve, reject) => {
    const newTabRequest = JSON.parse(JSON.stringify(tabRequestBody));
    switch (category) {
      case "waivers":
        newTabRequest.query.bool.must_not.push({
          terms: {
            "waiver.waiverType.raw": ["Scenario"]
          }
        });

        break;
      case "scenarios":
        newTabRequest.query.bool.must.push({
          terms: {
            "waiver.waiverType.raw": ["Scenario"]
          }
        });

        break;
      case "conditions":
        newTabRequest.query.bool.must_not.push({
          terms: {
            "exception.type.raw": ["Inquiry"]
          }
        });

        break;
      case "inquiries":
        newTabRequest.query.bool.must_not.push({
          terms: {
            "exception.type.raw": ["EXCEPTION"]
          }
        });

        break;
      default:
        return newTabRequest;
    }
    newTabRequest.size = 100;

    const query = { searchQuery: newTabRequest };
    const url = `${getConfig().apiUrl}/aggregate/export/resources/${category}`;
    publicClient
      .post(url, query, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((res: any) => resolve(res.data))
      .catch(reject);
  });
}

// export const setCommListData: any = (dbfilterListData: any[]) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_COMM_LIST_DATA,
//       payload: {
//         dbfilterListData
//       }
//     });
//   };
// };

export function exportInquiresOrConditions(
  category: any,
  selectedIds: any
): Promise<any> {
  return new Promise((resolve, reject) => {
    const commonQuery = getCommonQuery(category, selectedIds);
    const query = { searchQuery: commonQuery };
    const url = `${getConfig().apiUrl}/aggregate/export/resources/${category}`;
    publicClient
      .post(url, query, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((res: any) => resolve(res.data))
      .catch(reject);
  });
}

function getCommonQuery(category: any, selectedIds: any) {
  let accountIds: any;
  accountIds = localStorage.getItem("account_ids");
  accountIds = JSON.parse(accountIds);
  const commQuery: any = {
    _source: ["*"],
    aggs: {
      customerIdBucket: {
        terms: {
          field: "createdByUser.partyId.raw",
          size: 500
        }
      }
    },
    from: 0,
    size: selectedIds.length,
    sort: [{ updatedOn: { order: "desc" } }],
    query: {
      bool: {
        must: [
          {
            terms: { "resourceId.raw": selectedIds }
          },
          {
            term: { "resource.raw": "exception" }
          },
          {
            bool: {
              should: [
                {
                  terms: {
                    "status.raw": [
                      "OPEN",
                      "Raised",
                      "open",
                      "REOPENED",
                      "",
                      "REQUESTED",
                      "RE-REQUESTED",
                      "REBUTTAL",
                      "REBUTTALED"
                    ]
                  }
                },
                {
                  bool: {
                    must_not: [
                      {
                        terms: {
                          "status.raw": [
                            "OPEN",
                            "Raised",
                            "open",
                            "REOPENED",
                            "",
                            "REQUESTED",
                            "RE-REQUESTED",
                            "REBUTTAL",
                            "REBUTTALED"
                          ]
                        }
                      },
                      {
                        term: {
                          "resource.raw": "discussion"
                        }
                      }
                    ],
                    must: {
                      range: {
                        updatedOn: {
                          gte: "now-7d/d"
                        }
                      }
                    }
                  }
                },
                {
                  bool: {
                    must: [
                      {
                        term: {
                          "resource.raw": "discussion"
                        }
                      },
                      {
                        range: {
                          updatedOn: {
                            gte: "now-7d/d"
                          }
                        }
                      }
                    ],
                    must_not: [
                      {
                        term: {
                          "secondaryIdentifierName.raw": "loan"
                        }
                      }
                    ]
                  }
                }
              ]
            }
          },
          {
            bool: {
              must_not: {
                terms: {
                  "exception.visibility.raw": ["internal"]
                }
              }
            }
          }
        ],
        must_not: [
          {
            terms: { "currentLoanState.raw": ["Rejected"] }
          },
          {
            bool: {
              must: [
                {
                  terms: { "resource.raw": ["waiver"] }
                },
                {
                  terms: {
                    "currentLoanState.raw": ["Purchased"]
                  }
                }
              ]
            }
          },
          {
            terms: {
              "exception.type.raw": [category]
            }
          }
        ]
      }
    }
  };
  if (!isRole(UserRole.LAT_TREASURER)) {
    if (getCookie("loginFlow") === "originator") {
      commQuery.query.bool.must.push({
        terms: {
          "originatorId.raw": [getCookie("org_id")]
        }
      });
    } else {
      commQuery.query.bool.must.push({
        terms: {
          "originatorId.raw": accountIds
        }
      });
      commQuery.aggs.originatorIdBucket = {
        terms: {
          field: "originatorId.raw",
          size: 500
        }
      };
    }
  }
  return commQuery;
}

function isLATUser(type?: any) {
  let searchQuery;
  let accountIds: any;
  accountIds = localStorage.getItem("account_ids");
  accountIds = JSON.parse(accountIds);
  if (type !== "commData") {
    if (getCookie("loginFlow") === "originator") {
      searchQuery = {
        terms: {
          "loan.loanDetailId.originatorPartyId.raw": [getCookie("org_id")]
        }
      };
    } else {
      searchQuery = {
        terms: {
          "loan.loanDetailId.originatorPartyId.raw": accountIds
        }
      };
    }
    return searchQuery;
  }
  if (type === "commData") {
    if (getCookie("loginFlow") === "originator") {
      searchQuery = {
        terms: {
          "originatorId.raw": [getCookie("org_id")]
        }
      };
    } else {
      searchQuery = {
        terms: {
          "originatorId.raw": accountIds
        }
      };
    }
    return searchQuery;
  }
  return {};
}

export function getPersonId() {
  return getCookie("person_id");
}
export function getVariationOfLoanPurchased(
  interval: any,
  format: any,
  gte: any,
  lte: any,
  fromInner?: any,
  value?: any,
  user?: any
): any {
  return async (dispatch: any) => {
    try {
      const searchQuery = isLATUser();
      const url = `${getConfig().apiUrl}/search/${
        getConfig().aggregateUrl
      }/_search`;
      const mustQuery: any = [
        {
          range: {
            "bundle.closingDate": {
              gte,
              lt: lte
            }
          }
        },
        {
          terms: searchQuery?.terms
        }
      ];
      if (!isLAT() && user && user !== "All Users") {
        const loanUser = { term: { "loan.loanCreatedBy.raw": user } };
        mustQuery.push(loanUser);
      } else if (isLAT() && user && user !== "All Originators") {
        const loanUser = { term: { "loan.loanCreatedBy.raw": user } };
        mustQuery.push(loanUser);
      }
      const query = {
        bool: {
          must: mustQuery
        }
      };
      if (isRole(UserRole.LAT_TREASURER)) {
        if (query.bool.must.length > 1) {
          const indexToRemove = getESIndexToDelete(query.bool.must);
          query.bool.must.splice(indexToRemove, 1);
        } else {
          delete query.bool.must;
        }
      }
      const resp = await publicClient.post(
        url,
        {
          size: 0,
          query,
          aggs: {
            usersFilter: {
              terms: {
                field: "loan.loanCreatedBy.raw"
              }
            },
            amountPerMonth: {
              date_histogram: {
                field: "bundle.closingDate",
                calendar_interval: interval,
                format
              },
              aggs: {
                amount: {
                  sum: {
                    script: {
                      source:
                        "if(doc.containsKey('result.settlementResults.fundedLoanAmount.raw') && doc['result.settlementResults.fundedLoanAmount.raw'].size() >0) { return Double.parseDouble(doc['result.settlementResults.fundedLoanAmount.raw'].value.replace(',','').replace('$',''));} else {return 0;}"
                    }
                  }
                }
              }
            }
          }
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      if (!fromInner) {
        // dispatch({
        //   type: GET_VARIATION_OF_LOAN_PURCHASED,
        //   payload: {
        //     loanPurchased:
        //       resp.data?.response?.aggregations?.amountPerMonth?.buckets
        //   }
        // });
        dispatch(
          setVariationOfLoanPurchased({
            loanPurchased:
              resp.data?.response?.aggregations?.amountPerMonth?.buckets
          })
        );
      } else if (fromInner) {
        // dispatch({
        //   type: GET_VARIATION_OF_LOAN_PURCHASED_DAY_WISE,
        //   payload: {
        //     loanPurchasedDayWise:
        //       resp.data?.response?.aggregations?.amountPerMonth?.buckets,
        //     value,
        //     originatorIdData:
        //       resp.data?.response?.aggregations?.usersFilter?.buckets
        //   }
        // });
        dispatch(
          setVariationOfLoanPurchasedDayWise({
            loanPurchasedDayWise:
              resp.data?.response?.aggregations?.amountPerMonth?.buckets,
            value,
            originatorIdData:
              resp.data?.response?.aggregations?.usersFilter?.buckets
          })
        );
      }
    } catch (err) {
      const e: any = err;
      console.error(e);
      // dispatch({
      //   type: GET_VARIATION_OF_LOAN_PURCHASED_ACTION_FAIL,
      //   error: {
      //     message: e.message
      //   }
      // });
      // dispatch(getVariationOfLoanPurchasedActionFail({
      //   error: {
      //     message: e.message
      //   }
      // }));
    }
  };
}

export function getPercentileOfLoanTypes(): any {
  return async (dispatch: any) => {
    try {
      const searchQuery = isLATUser();
      const mustQuery: any = [
        {
          terms: searchQuery?.terms
        }
      ];
      const query = {
        bool: {
          must: mustQuery
        }
      };
      if (isRole(UserRole.LAT_TREASURER)) {
        if (query.bool.must.length > 1) {
          const indexToRemove = getESIndexToDelete(query.bool.must);
          query.bool.must.splice(indexToRemove, 1);
        } else {
          delete query.bool.must;
        }
      }
      const url = `${getConfig().apiUrl}/search/${
        getConfig().aggregateUrl
      }/_search`;
      const resp = await publicClient.post(
        url,
        {
          size: 0,
          query,
          aggs: {
            amountPerMonth: {
              terms: {
                field: "loan.loanType.raw"
              },
              aggs: {
                amount: {
                  sum: {
                    script: {
                      source:
                        "if(doc.containsKey('result.settlementResults.fundedLoanAmount.raw') && doc['result.settlementResults.fundedLoanAmount.raw'].size() > 0 && doc['result.settlementResults.fundedLoanAmount.raw'].value != 'N/A') {return Double.parseDouble(doc['result.settlementResults.fundedLoanAmount.raw'].value.replace(',','').replace('$',''));} else {return 0;}"
                    }
                  }
                }
              }
            }
          }
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );

      // dispatch({
      //   type: GET_PERCENTILE_OF_LOAN_TYPES,
      //   payload: {
      //     percentileOfLoans:
      //       resp.data?.response?.aggregations?.amountPerMonth?.buckets
      //   }
      // });
      dispatch(
        setPercentileOfLoanTypes({
          percentileOfLoans:
            resp.data?.response?.aggregations?.amountPerMonth?.buckets
        })
      );
    } catch (err) {
      const e: any = err;
      console.error(e);
      // dispatch({
      //   type: GET_PERCENTILE_OF_LOAN_TYPES_ACTION_FAIL,
      //   error: {
      //     message: e.message
      //   }
      // });
    }
  };
}
// eslint-disable-next-line consistent-return
function getESIndexToDelete(arrayRef: any, type?: string): any {
  let index;
  if (type !== "commData") {
    for (let i = 0; i < arrayRef.length; i++) {
      if (
        arrayRef[i]?.terms &&
        JSON.stringify(arrayRef[i]?.terms).includes("originatorPartyId")
      ) {
        index = i;
      }
    }
  } else {
    for (let i = 0; i < arrayRef.length; i++) {
      if (
        arrayRef[i]?.terms &&
        JSON.stringify(arrayRef[i]?.terms).includes("originatorId")
      ) {
        index = i;
      }
    }
  }
  return index;
}
export function getLoanPipelineData(loanType?: any): any {
  return async (dispatch: any) => {
    try {
      const searchQuery = isLATUser();
      const url = `${getConfig().apiUrl}/search/${
        getConfig().aggregateUrl
      }/_search`;
      const mustQuery: any = [
        {
          terms: searchQuery?.terms
        }
      ];
      if (loanType && loanType !== "All Loan Type") {
        const loanTypeQuery = {
          term: {
            "loan.loanType.raw":
              loanType === "30 Year" ? "30 Year Loan" : loanType
          }
        };
        mustQuery[mustQuery.length] = loanTypeQuery;
      }
      const query = {
        bool: {
          must: mustQuery
        }
      };
      if (isRole(UserRole.LAT_TREASURER)) {
        if (query.bool.must.length > 1) {
          const indexToRemove = getESIndexToDelete(query.bool.must);
          query.bool.must.splice(indexToRemove, 1);
        } else {
          delete query.bool.must;
        }
      }
      const resp = await publicClient.post(
        url,
        {
          size: 0,
          query,
          aggs: {
            amountPerStage: {
              terms: {
                field: "loan.loanStage.raw"
              },
              aggs: {
                amountPerState: {
                  terms: {
                    field: "loan.loanState.raw"
                  },
                  aggs: {
                    amount: {
                      sum: {
                        script: {
                          source:
                            "if(doc['loan.loanType.raw'].size() > 0 && doc['loan.loanType.raw'].value == 'Bridge Loan') { if(doc.containsKey('loan.loanInfo.loanStructure.raw') && doc['loan.loanInfo.loanStructure.raw'].size() > 0){ if(doc['loan.loanInfo.loanStructure.raw'].value == 'Single Funding (With Rehab Escrow)' && doc.containsKey('loan.loanEconomics.originalMaximumLoanAmount.raw') &&  doc['loan.loanEconomics.originalMaximumLoanAmount.raw'].size() >0) {return Double.parseDouble(doc['loan.loanEconomics.originalMaximumLoanAmount.raw'].value.replace(',','').replace('$',''));} else if(doc.containsKey('loan.loanEconomics.originalLoanAmount.raw') &&  doc['loan.loanEconomics.originalLoanAmount.raw'].size() >0) {return Double.parseDouble(doc['loan.loanEconomics.originalLoanAmount.raw'].value.replace(',','').replace('$',''));} else {return 0;}}} else if(doc['loan.loanType.raw'].size() > 0 && doc['loan.loanType.raw'].value == '30 Year Loan' && doc.containsKey('loan.loanEconomics.originalLoanAmount.raw') &&  doc['loan.loanEconomics.originalLoanAmount.raw'].size() >0) {return Double.parseDouble(doc['loan.loanEconomics.originalLoanAmount.raw'].value.replace(',','').replace('$',''));} else {return 0;} "
                        }
                      }
                    }
                  }
                },
                amount: {
                  sum: {
                    script: {
                      source:
                        "if(doc['loan.loanType.raw'].size() > 0 && doc['loan.loanType.raw'].value == 'Bridge Loan') { if(doc.containsKey('loan.loanInfo.loanStructure.raw') && doc['loan.loanInfo.loanStructure.raw'].size() > 0){ if(doc['loan.loanInfo.loanStructure.raw'].value == 'Single Funding (With Rehab Escrow)' && doc.containsKey('loan.loanEconomics.originalMaximumLoanAmount.raw') &&  doc['loan.loanEconomics.originalMaximumLoanAmount.raw'].size() >0) {return Double.parseDouble(doc['loan.loanEconomics.originalMaximumLoanAmount.raw'].value.replace(',','').replace('$',''));} else if(doc.containsKey('loan.loanEconomics.originalLoanAmount.raw') &&  doc['loan.loanEconomics.originalLoanAmount.raw'].size() >0) {return Double.parseDouble(doc['loan.loanEconomics.originalLoanAmount.raw'].value.replace(',','').replace('$',''));} else {return 0;}}} else if(doc['loan.loanType.raw'].size() > 0 && doc['loan.loanType.raw'].value == '30 Year Loan' && doc.containsKey('loan.loanEconomics.originalLoanAmount.raw') &&  doc['loan.loanEconomics.originalLoanAmount.raw'].size() >0) {return Double.parseDouble(doc['loan.loanEconomics.originalLoanAmount.raw'].value.replace(',','').replace('$',''));} else {return 0;} "
                    }
                  }
                }
              }
            }
          }
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      dispatch(
        setLoanPipelineData({
          loanPipelineData:
            resp.data?.response?.aggregations?.amountPerStage?.buckets
        })
      );
      // dispatch({
      //   type: GET_LOAN_PIPELINE_DATA,
      //   payload: {
      //     loanPipelineData:
      //       resp.data?.response?.aggregations?.amountPerStage?.buckets
      //   }
      // });
    } catch (err) {
      const e: any = err;
      console.log(e);
      // dispatch({
      //   type: GET_LOAN_PIPELINE_DATA_ACTION_FAIL,
      //   error: {
      //     message: e.message
      //   }
      // });
    }
  };
}

export const getDBFilterAggregateResults: any = (reqBody: any) => {
  return (dispatch: any) => {
    const url = `${getConfig().apiUrl}/search/${
      getConfig().dashboardUrl
    }/_search`;
    publicClient
      .post(url, reqBody)
      .then((resp: any) => {
        const resourceTypesCount: any =
          resp?.data?.response?.aggregations?.resourceBucket?.buckets;
        const countObj: any = {};
        if (resourceTypesCount?.length)
          resourceTypesCount.forEach((itm: any) => {
            countObj[itm.key] = itm.doc_count;
          });
        dispatch(
          setCommFilterCounts({
            waiverCount: countObj["waiver"] || 0,
            condtionCount: countObj["exception"] || 0,
            discussionCount: countObj["discussion"] || 0
          })
        );
      })
      .catch((err: any) => {
        console.log("err getting agg results");
      });
  };
};

export interface CommunicationFilterProps {
  loanType?: string[];
  orgPartyId?: string[];
  createdBySelected?: string[];
  loanStage?: any;
  selectedWaiverStatus?: any;
  selectedInquiryStatus?: any;
  selectedConditionStatus?: any;
  selectedWaiverType?: any;
  loanIdSelected?: string[];
  tradeIdSelected?: string[];
  spocIdsSelected?: string[];
  searchData?: string;
  pageNum?: any;
  selectedTab?: any;
  prevCommList: any[];
  prevOrgIds: any;
  prevCreatedBy: any;
  prevLoanId: any;
  prevTradeId: any;
  prevSpoc: any;
}
export function getCommunicationFilterData(
  selectedFilters: CommunicationFilterProps
): any {
  return async (dispatch: any) => {
    const {
      loanType,
      orgPartyId,
      createdBySelected,
      loanStage,
      selectedWaiverStatus,
      selectedInquiryStatus,
      selectedConditionStatus,
      selectedWaiverType,
      loanIdSelected,
      tradeIdSelected,
      searchData,
      pageNum,
      selectedTab,
      prevCommList,
      prevOrgIds,
      prevCreatedBy,
      prevLoanId,
      prevTradeId,
      spocIdsSelected,
      prevSpoc
    } = selectedFilters;
    try {
      dispatch(showLoader());
      const userTypeTerm: any = {};
      const isLAt = isLAT();
      if (!isLAt) {
        userTypeTerm["terms"] = {
          "originatorId.raw": [getCookie("org_id")]
        };
      } else if (isLAt && !isRole(UserRole.LAT_TREASURER)) {
        let accountIds = localStorage.getItem("account_ids");
        accountIds = accountIds ? JSON.parse(accountIds) : [];
        userTypeTerm["terms"] = {
          "originatorId.raw": accountIds
        };
      }
      const url = `${getConfig().apiUrl}/search/${
        getConfig().dashboardUrl
      }/_search`;
      const mustQuery: any = [];
      let aggMustQuery: any = [];
      if (userTypeTerm?.terms) {
        mustQuery.push(userTypeTerm);
      }
      if (loanType?.length) {
        const loanTypeQuery = { terms: { "loanType.raw": loanType } };
        mustQuery.push(loanTypeQuery);
      }
      if (orgPartyId?.length) {
        const loanTypeQuery = { terms: { "originatorId.raw": orgPartyId } };
        mustQuery.push(loanTypeQuery);
      }
      if (createdBySelected?.length) {
        const loanTypeQuery = {
          terms: { "createdByUser.partyId.raw": createdBySelected }
        };
        mustQuery.push(loanTypeQuery);
      }
      if (loanStage?.length) {
        const loanStageQuery = { terms: { "loanStage.raw": loanStage } };
        mustQuery.push(loanStageQuery);
      }
      if (loanIdSelected?.length) {
        const loanIdQuery = { terms: { "loanId.raw": loanIdSelected } };
        mustQuery.push(loanIdQuery);
      }

      if (tradeIdSelected?.length) {
        const loanIdQuery = { terms: { "tradeId.raw": tradeIdSelected } };
        mustQuery.push(loanIdQuery);
      }
      if (spocIdsSelected?.length) {
        const loanIdQuery = {
          terms: { "spocPartyInfo.fullName.raw": spocIdsSelected }
        };
        mustQuery.push(loanIdQuery);
      }

      if (pageNum === 0) aggMustQuery = [...mustQuery];
      const statusShouldMust: any[] = [];
      const aggStatusShouldMust: any[] = [];
      /* update agg waiver query */
      if (
        pageNum === 0 &&
        selectedWaiverStatus.length &&
        selectedWaiverType.length
      ) {
        aggStatusShouldMust.push({
          bool: {
            must: [
              {
                terms: {
                  "resource.raw": [tabsType.waiver]
                }
              },
              {
                terms: {
                  "status.raw": selectedWaiverStatus
                }
              },
              {
                terms: {
                  "waiver.waiverType.raw": selectedWaiverType
                }
              }
            ]
          }
        });
      } else if (pageNum === 0 && selectedWaiverStatus.length) {
        aggStatusShouldMust.push({
          bool: {
            must: [
              {
                terms: {
                  "resource.raw": [tabsType.waiver]
                }
              },
              {
                terms: {
                  "status.raw": selectedWaiverStatus
                }
              }
            ]
          }
        });
      } else if (selectedWaiverType.length) {
        aggStatusShouldMust.push({
          bool: {
            must: [
              {
                terms: {
                  "resource.raw": [tabsType.waiver]
                }
              },
              {
                terms: {
                  "waiver.waiverType.raw": selectedWaiverType
                }
              }
            ]
          }
        });
      }

      if (
        (selectedTab === tabsType.all || selectedTab === tabsType.waiver) &&
        selectedWaiverStatus.length &&
        selectedWaiverType.length
      ) {
        statusShouldMust.push({
          bool: {
            must: [
              {
                terms: {
                  "resource.raw": [tabsType.waiver]
                }
              },
              {
                terms: {
                  "status.raw": selectedWaiverStatus
                }
              },
              {
                terms: {
                  "waiver.waiverType.raw": selectedWaiverType
                }
              }
            ]
          }
        });
      } else if (
        (selectedTab === tabsType.all || selectedTab === tabsType.waiver) &&
        selectedWaiverStatus.length
      ) {
        statusShouldMust.push({
          bool: {
            must: [
              {
                terms: {
                  "resource.raw": [tabsType.waiver]
                }
              },
              {
                terms: {
                  "status.raw": selectedWaiverStatus
                }
              }
            ]
          }
        });
      } else if (
        (selectedTab === tabsType.all || selectedTab === tabsType.waiver) &&
        selectedWaiverType.length
      ) {
        statusShouldMust.push({
          bool: {
            must: [
              {
                terms: {
                  "resource.raw": [tabsType.waiver]
                }
              },
              {
                terms: {
                  "waiver.waiverType.raw": selectedWaiverType
                }
              }
            ]
          }
        });
      }

      /* update agg E&I query */
      if (
        pageNum === 0 &&
        (selectedInquiryStatus.length || selectedConditionStatus.length)
      ) {
        const typeParams: any = [];
        let exceptionStatus: any = [];
        if (selectedInquiryStatus.length) {
          typeParams.push("Inquiry");
          exceptionStatus = [...selectedInquiryStatus];
        }
        if (selectedConditionStatus.length) {
          typeParams.push("EXCEPTION");
          exceptionStatus = [...selectedConditionStatus];
        }
        aggStatusShouldMust.push({
          bool: {
            must: [
              {
                terms: {
                  "resource.raw": ["exception"]
                }
              },
              {
                terms: {
                  "exception.type.raw": typeParams
                }
              },
              {
                terms: {
                  "exception.status.raw": exceptionStatus
                }
              }
            ]
          }
        });
      }
      if (
        (selectedTab === tabsType.all ||
          selectedTab === tabsType.exception ||
          selectedTab === tabsType.discussion) &&
        (selectedInquiryStatus.length || selectedConditionStatus.length)
      ) {
        const typeParams: any = [];
        let exceptionStatus: any = [];
        if (selectedInquiryStatus.length) {
          typeParams.push("Inquiry");
          exceptionStatus = [...selectedInquiryStatus];
        }
        if (selectedConditionStatus.length) {
          typeParams.push("EXCEPTION");
          exceptionStatus = [...selectedConditionStatus];
        }
        statusShouldMust.push({
          bool: {
            must: [
              {
                terms: {
                  "resource.raw": ["exception"]
                }
              },
              {
                terms: {
                  "exception.type.raw": typeParams
                }
              },
              {
                terms: {
                  "exception.status.raw": exceptionStatus
                }
              }
            ]
          }
        });
      }
      if (statusShouldMust.length) {
        mustQuery.push({
          bool: {
            should: statusShouldMust
          }
        });
      }
      if (aggStatusShouldMust.length) {
        aggMustQuery.push({
          bool: {
            should: aggStatusShouldMust
          }
        });
      }
      if (selectedTab) {
        const loanTypeQuery = { term: { "resource.raw": selectedTab } };
        mustQuery.push(loanTypeQuery);
      } else {
        const loanTypeQuery = {
          terms: { "resource.raw": ["waiver", "exception", "discussion"] }
        };
        mustQuery.push(loanTypeQuery);
      }
      if (pageNum === 0) {
        aggMustQuery.push({
          terms: { "resource.raw": ["waiver", "exception", "discussion"] }
        });
      }
      if (searchData) {
        const searchQuery = {
          multi_match: {
            query: searchData,
            fields: ["*"],
            analyzer: "standard"
          }
        };
        mustQuery.push(searchQuery);
        if (pageNum === 0) aggMustQuery.push(searchQuery);
      }
      const commonQuery: any = {
        bool: {
          should: [
            {
              terms: {
                "status.raw": [
                  "OPEN",
                  "Raised",
                  "open",
                  "REOPENED",
                  "",
                  "REQUESTED",
                  "RE-REQUESTED",
                  "REBUTTAL",
                  "REBUTTALED"
                ]
              }
            },
            {
              bool: {
                must_not: [
                  {
                    terms: {
                      "status.raw": [
                        "OPEN",
                        "Raised",
                        "open",
                        "REOPENED",
                        "",
                        "REQUESTED",
                        "RE-REQUESTED",
                        "REBUTTAL",
                        "REBUTTALED"
                      ]
                    }
                  },
                  {
                    term: {
                      "resource.raw": "discussion"
                    }
                  }
                ],
                must: {
                  range: {
                    updatedOn: {
                      gte: "now-7d/d"
                    }
                  }
                }
              }
            },
            {
              bool: {
                must: [
                  {
                    term: {
                      "resource.raw": "discussion"
                    }
                  },
                  {
                    range: {
                      updatedOn: {
                        gte: "now-7d/d"
                      }
                    }
                  }
                ],
                must_not: [
                  {
                    term: {
                      "secondaryIdentifierName.raw": "loan"
                    }
                  }
                ]
              }
            }
          ]
        }
      };
      mustQuery.push(commonQuery);
      if (pageNum === 0) aggMustQuery.push(commonQuery);
      // if (!isLAt) {
      const internalQuery: any = {
        bool: {
          must_not: {
            terms: {
              "exception.visibility.raw": ["internal"]
            }
          }
        }
      };
      mustQuery.push(internalQuery);
      aggMustQuery.push(internalQuery);
      // }
      handleRolePrivileges(mustQuery);
      if (pageNum === 0) handleRolePrivileges(aggMustQuery);
      const mustNotQuery = [
        {
          terms: {
            "currentLoanState.raw": ["Rejected"]
          }
        },
        {
          bool: {
            must: [
              {
                terms: {
                  "resource.raw": ["waiver"]
                }
              },
              {
                terms: {
                  "currentLoanState.raw": ["Purchased"]
                }
              }
            ]
          }
        }
      ];
      const query = {
        bool: {
          must: mustQuery,
          must_not: mustNotQuery
        }
      };
      if (pageNum === 0) {
        const aggReqBody: any = {
          query: {
            bool: {
              must: aggMustQuery,
              must_not: mustNotQuery
            }
          },
          aggs: {
            resourceBucket: {
              terms: {
                field: "resource.raw"
              }
            }
          },
          _source: false,
          size: 0,
          from: 0
        };
        dispatch(getDBFilterAggregateResults(aggReqBody));
      }
      // dispatch(showLoader());
      const reqBody: any = {
        query,
        _source: {
          includes: [
            "createdOn",
            "updatedOn",
            "loanId",
            "loanType",
            "loanStage",
            "currentLoanStage",
            "primaryLoanId",
            "primaryBorrower.customer.firstName",
            "primaryBorrower.customer.lastName",
            "primaryBorrower.customer.accountName",
            "updatedUserRole",
            ...(selectedTab === tabsType.all || selectedTab === tabsType.waiver
              ? ["waiver", "scenarioId"]
              : []),
            "discussion",
            "properties",
            "exception",
            "onHoldDetails",
            "loanState",
            "responses",
            "waiverResult",
            "currentLoanState",
            "status",
            "dominantPropertyType",
            ...(selectedTab === tabsType.all ||
            selectedTab === tabsType.exception
              ? ["dueDiligencePartyInfo.accountName", "tradeId"]
              : []),
            "resource",
            "resourceId",
            "originator.partyId"
          ]
        },
        sort: [
          {
            updatedOn: {
              order: "desc"
            }
          }
        ],
        size: 20,
        from: pageNum * 20
      };

      if (isLAt)
        reqBody["aggs"] = {
          originatorIdBucket: {
            terms: {
              field: "originatorId.raw",
              size: 500
            }
          },
          customerIdBucket: {
            terms: {
              field: "createdByUser.partyId.raw",
              size: 500
            }
          },
          loanIdBucket: {
            terms: {
              field: "loanId.raw",
              size: 1000
            }
          },
          tradeIdBucket: {
            terms: {
              field: "tradeId.raw",
              size: 500
            }
          },
          spocParty: {
            terms: {
              field: "spocPartyInfo.fullName.raw",
              size: 500
            }
          }
        };
      else {
        reqBody["aggs"] = {
          customerIdBucket: {
            terms: {
              field: "createdByUser.partyId.raw",
              size: 500
            }
          },
          loanIdBucket: {
            terms: {
              field: "loanId.raw",
              size: 1000
            }
          },
          tradeIdBucket: {
            terms: {
              field: "tradeId.raw",
              size: 500
            }
          }
        };
      }

      tabRequestBody = reqBody;
      const resp = await publicClient.post(url, reqBody, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      // console.log(resp, "resp");
      const { aggregations, hits } = resp?.data?.response || {};
      const orgIds = aggregations?.originatorIdBucket?.buckets;
      const custIds = aggregations?.customerIdBucket?.buckets;
      const loanIds = aggregations?.loanIdBucket?.buckets;
      const tradeIds = aggregations?.tradeIdBucket?.buckets;
      const spocPartyInfo = aggregations?.spocParty?.buckets ?? [];

      // dispatch(setSPOCInfo(spocPartyInfo));

      if (loanIds.length) {
        const newLoanIds: any = [];
        loanIds.forEach((element: any) => {
          if (element.key && prevLoanId.indexOf(element.key) === -1) {
            newLoanIds.push(element.key);
          }
        });
        const loanIdsArray = [...prevLoanId, ...newLoanIds];
        if (loanIdsArray.length) {
          dispatch(setLoanIds(loanIdsArray.sort()));
        }
      }
      if (spocPartyInfo.length) {
        const newSpoc: any = [];
        spocPartyInfo.forEach((element: any) => {
          if (element.key && prevSpoc.indexOf(element.key) === -1) {
            newSpoc.push(element.key);
          }
        });
        const spocArray = [...prevSpoc, ...newSpoc];
        if (spocArray.length) {
          dispatch(setSPOCInfo({ data: spocArray }));
        }
      }
      if (tradeIds.length) {
        const newTradeId: any = [];
        tradeIds.forEach((element: any) => {
          if (element.key && prevTradeId.indexOf(element.key) === -1) {
            newTradeId.push(element.key);
          }
        });
        const tradeIdsArray = [...prevTradeId, ...newTradeId];
        if (tradeIdsArray.length) {
          dispatch(setTradeId({ data: tradeIdsArray.sort() }));
        }
      }
      if (custIds.length) {
        const prevCreatedByNew: any = pageNum !== 0 ? prevCreatedBy : {};
        const newIds: string[] = [];
        /* Check if any id's which is not in reducer */
        custIds.forEach((itm: any) => {
          if (!(itm.key in prevCreatedByNew)) {
            newIds.push(itm.key);
          }
        });
        if (prevCreatedBy && !Object.keys(prevCreatedBy).length) {
          dispatch(getCreatedByUsers(newIds));
        }
      }
      if (isLAt && orgIds?.length) {
        /* Reset org ids if starting from page 0 */
        const prevOrgIdss: any = pageNum !== 0 ? prevOrgIds : {};
        const newIds: string[] = [];
        /* Check if any id's which is not in reducer */
        orgIds.forEach((itm: any) => {
          if (!(itm.key in prevOrgIdss)) {
            newIds.push(itm.key);
          }
        });
        if (newIds?.length && !Object.keys(prevOrgIds || {}).length) {
          const customerObj: any = await getCustomerData(newIds);
          const newOrjIds: any = {
            ...prevOrgIdss,
            ...customerObj
          };
          dispatch(setOrgCustomerData(newOrjIds));
        }
      }
      const commList: any[] = hits?.hits || [];
      const totalItems: number = Number(hits?.total?.value);
      const discussionArray = hits?.hits?.filter(
        (element: any) => element._source.resource === "discussion"
      );
      const newArray =
        discussionArray &&
        discussionArray.map((item: any) => item._source.discussion.id);
      dispatch(getUnReadComments(newArray));

      dispatch(getUnReadCommentComm(commList));
      // waiver with REBUTTAL status should always be at the top of the list. LPP-5736
      const rebuttalList = prevCommList
        .concat(commList)
        .filter((item) => item._source.status === "REBUTTAL");
      const tempCommList: any[] = prevCommList
        .concat(commList)
        .filter((item) => item._source.status !== "REBUTTAL");
      dispatch(
        setCommListData({
          dbfilterListData: [...rebuttalList, ...tempCommList]
        })
      );
      dispatch(setCommTotalCount(totalItems));
      dispatch(hideLoader());
    } catch (err) {
      console.log(err, "error while retriving db search data");
      dispatch(hideLoader());
    }
  };
}

// export function updateList(value: any): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: UPDATE_FROM_INDEX,
//       payload: value
//     });
//   };
// }
// export function notificationClickedUpdate(value: any): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: UPDATE_NOT_CLICK,
//       payload: value
//     });
//   };
// }
// export function updateViewDetailsPath(value: any): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: UPDATE_VIEW_DETAILS_PATH,
//       payload: value
//     });
//   };
// }
// export function updateSelectedTab(value: any): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: UPDATE_TAB,
//       payload: value
//     });
//   };
// }

export const getWaiverMappedToConditions: any = (exceptionIds: any) => {
  return async (dispatch: any) => {
    try {
      const url = `${getConfig().apiUrl}/search/${
        getConfig().dashboardUrl
      }/_search`;
      const org_id = getCookie("org_id");
      const reqBody = {
        query: {
          bool: {
            must: [
              {
                terms: {
                  "waiver.waiverKeys.raw": exceptionIds
                }
              },
              ...(isLAT()
                ? []
                : [
                    {
                      terms: {
                        "originatorId.raw": [org_id]
                      }
                    }
                  ])
            ],
            must_not: []
          }
        },

        _source: ["*"],

        sort: [
          {
            updatedOn: {
              order: "desc"
            }
          }
        ],
        size: 100,
        from: 0
      };
      const resp = await publicClient.post(url, reqBody, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      const { data } = resp;
      // dispatch({
      //   type: SET_WAIVER_MAPPED_EXCEPTIONS,
      //   payload: data.response.hits.hits
      // });
      dispatch(
        setWaiverMappedExceptions({
          data: data.response.hits.hits
        })
      );
    } catch (err) {
      console.log(err);
    }
  };
};
export const getCreatedByUsers: any = (partyIds: any) => {
  return async (dispatch: any) => {
    try {
      // const custIdLen: number = partyIds.length;
      // const custIdArr: string[] = partyIds
      //   .filter((item: any) => uuidValidate(item))
      //   .map((item: string) => {
      //     return `partyIds=${item}`;
      //   });

      // if (custIdLen === 1) custIdArr.unshift(custIdArr[0]);

      const url = `${getConfig().apiUrl}/customer/fetch`;
      const response = await publicClient.post(url, { partyIds });
      const { data } = response;

      const newCreatedBy: any = {
        ...data
      };
      dispatch(setAllCreatedBy(newCreatedBy));
    } catch (err) {
      const e: any = err;
      // dispatch({
      //   type: GET_PERCENTILE_OF_LOAN_TYPES_ACTION_FAIL,
      //   error: {
      //     message: e?.message
      //   }
      // });
      console.error(e);
    }
  };
};

export function getAllUsers(orgIds: any, fromGraph?: boolean): any {
  return async (dispatch: any) => {
    try {
      let partyIds = "";
      if (orgIds.length === 1) {
        orgIds.forEach((item: any) => {
          partyIds = `partyIds=${item}&partyIds=${item}`;
        });
      } else if (orgIds.length > 1) {
        orgIds.forEach((item: any) => {
          partyIds = partyIds.concat(`partyIds=${item}&`);
        });
      }
      if (orgIds.length > 0 && partyIds) {
        // const url = `${getConfig().apiUrl}/customer?${partyIds}`;
        // const response = await publicClient.get(url);
        const url = `${getConfig().apiUrl}/customer/fetch`;
        const response = await publicClient.post(url, { partyIds: orgIds });
        if (!fromGraph) {
          // dispatch({
          //   type: GET_ALL_USER_NAME,
          //   payload: {
          //     userNames: response?.data
          //   }
          // });
          dispatch(
            setAllUserName({
              userNames: response?.data
            })
          );
        } else {
          // dispatch({
          //   type: GET_ALL_USER_NAME_GRAPH,
          //   payload: {
          //     userNamesLoan: response?.data
          //   }
          // });
          dispatch(
            setAllUserNameGraph({
              userNamesLoan: response?.data
            })
          );
        }
      }
    } catch (err) {
      const e: any = err;
      // dispatch({
      //   type: GET_PERCENTILE_OF_LOAN_TYPES_ACTION_FAIL,
      //   error: {
      //     message: e?.message
      //   }
      // });
      console.error(e);
    }
  };
}

export function getUnReadCommentComm(data: any): any {
  return async (dispatch: any) => {
    const partyId = getPersonId();
    const payload: any = getMultiFetchQuery(data);
    try {
      const url = `${
        getConfig().apiUrl
      }/notification/${partyId}/events/multi-fetch`;
      const response = await publicClient.post(url, payload, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      // return response.data;
      // dispatch({
      //   type: GET_UNREAD_COUNT_WAIVER_CONDITION,
      //   payload: response.data
      // });
      dispatch(
        getUnreadCountWaiverCondition({
          countData: response.data
        })
      );
    } catch (err) {
      const e: any = err;
      console.error(e);
      // dispatch({
      //   type: GET_NOTIFICATION_ACTION_FAIL,
      //   error: {
      //     message: e.message
      //   }
      // });
    }
  };
}
export function getNotification(
  notificationCurrentSize: number,
  afterScroll?: any
): any {
  return async (dispatch: any) => {
    const partyId = getPersonId();
    try {
      const size = 20 + notificationCurrentSize;
      const url = `${
        getConfig().apiUrl
      }/notification/${partyId}/notification?page=0&createdOnStart=${getNotificationDate()}&size=${size}&from=0&sort=createdOn,DESC`;
      const response = await publicClient.get(url, {
        errorConfig: getErrorConfigObject({ skipErrorHandling: true })
      } as any);
      // dispatch({
      //   type: GET_NOTIFICATION,
      //   payload: {
      //     notifications: response.data,
      //     afterScroll
      //   }
      // });
      dispatch(
        setNotification({
          notifications: response.data
        })
      );
      dispatch(getNotificationCount());
      // dispatch({
      //   type: UPDATE_NOT_INDEX,
      //   payload: notificationCurrentSize
      // });
      dispatch(updateNotIndex(notificationCurrentSize));
    } catch (err) {
      const e: any = err;
      console.error(e);
      // dispatch({
      //   type: GET_NOTIFICATION_ACTION_FAIL,
      //   error: {
      //     message: e.message
      //   }
      // });
    }
  };
}

export function getNotificationCount(): any {
  return async (dispatch: any) => {
    const partyId = getPersonId();
    try {
      const url = `${
        getConfig().apiUrl
      }/notification/${partyId}/notification/count?status=UNREAD&createdOnStart=${getNotificationDate()}`;
      const response = await publicClient.get(url);
      // dispatch({
      //   type: GET_UNREAD_COUNT_NOTIFICATION,
      //   payload: {
      //     count: response.data.numberOfNotifications
      //   }
      // });
      dispatch(
        setUnreadCountNotification({
          count: response.data.numberOfNotifications
        })
      );
    } catch (err) {
      const e: any = err;
      console.error(e);
      // dispatch({
      //   type: GET_NOTIFICATION_ACTION_FAIL,
      //   error: {
      //     message: e?.message
      //   }
      // });
    }
  };
}

export function updateDashboardFilters(
  loanTypeSelected: string[],
  orgPartyIdSelected: string[],
  createdBySelected: string[],
  loanStageSelected: string[],
  waiverStatusSelected: string[],
  waiverTypeSelected: string[],
  inquiryStatusSelected: string[],
  conditionStatusSelected: string[],
  loanIdSelected: string[],
  tradeIdSelected: string[],
  searchedData: string,
  spocIdsSelected: string[]
): any {
  return async (disaptch: any) => {
    disaptch(
      setFilters({
        loanTypeSelected,
        orgPartyIdSelected,
        createdBySelected,
        loanStageSelected,
        waiverStatusSelected,
        waiverTypeSelected,
        inquiryStatusSelected,
        conditionStatusSelected,
        loanIdSelected,
        tradeIdSelected,
        searchedData,
        spocIdsSelected
      })
    );
  };
}

// export const updateLoanTypeFilter: any = (loanTypeSelected: string[]) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_LOAN_TYPE_FILTERS,
//       payload: {
//         loanTypeSelected
//       }
//     });
//   };
// };

// export const updateOrgPartyIdFilter: any = (orgPartyIdSelected: string[]) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_ORG_PARTY_ID_FILTERS,
//       payload: {
//         orgPartyIdSelected
//       }
//     });
//   };
// };

// export const updateCreatedByFilter: any = (createdBySelected: string[]) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_CREATEDBY_FILTERS,
//       payload: {
//         createdBySelected
//       }
//     });
//   };
// };

export const getDashboardViewMore: any = (
  loanId: any,
  loanStage: any,
  visibility: any,
  id: any,
  cardType: any
) => {
  return async (dispatch: any) => {
    try {
      let scrollPosHTML = 0;
      let scrollPosDashboard = 0;
      let dashboardEle = document.getElementById("commsContainer");
      let htmlEle = document.getElementsByTagName("html")[0];
      if (dashboardEle) {
        scrollPosDashboard = dashboardEle.scrollTop;
        scrollPosHTML = htmlEle.scrollTop;
      }
      dispatch(showLoader(true));
      const visibilityVal = isLATUser() ? "internal,external" : visibility;
      const url = `${
        getConfig().apiUrl
      }/aggregate/loans/${loanId}/resource/${id}?resourceType=${cardType}&secondaryIdentifierName=LOAN_STAGE&secondaryIdentifierValue=${loanStage}&includeHistory=true&loanStage=${loanStage}&visibility=${visibilityVal}`;
      const response = await publicClient.get(url);
      // dispatch({
      //   type: ADD_RESPONSE_TO_COMMLIST_ARRAY,
      //   payload: { data: response.data, id }
      // });
      dispatch(
        addResponseToCommListArray({
          data: response.data,
          id
        })
      );
      dispatch(hideLoader(true));
      if (dashboardEle) {
        htmlEle.scrollTop = scrollPosHTML;
        dashboardEle.scrollTop = scrollPosDashboard;
        setIntervalX(
          () => {
            const loaderEle: any = document.querySelector(
              '[data-testid="loader"]'
            );
            if (dashboardEle && loaderEle?.style?.opacity === "1") {
              htmlEle.scrollTop = scrollPosHTML;
              dashboardEle.scrollTop = scrollPosDashboard;
            }
          },
          250,
          14
        );
      }
    } catch (err) {
      const e: any = err;
      console.error(e);
      dispatch(hideLoader(true));
    }
  };
};

export function deleteResponseAndUpdateDashBoard(
  loanStage: string,
  loanId: string,
  exceptionId: string,
  reponseId: string,
  chatSequenceId: string
): any {
  return async (dispatch: any) => {
    try {
      const url = `${
        getConfig().apiUrl
      }/exceptionservice/${loanId}/exceptions/${exceptionId}/responses/${reponseId}?loanStage=${loanStage}`;
      await publicClient.delete(url);
      // dispatch({
      //   type: DELETE_EXCEPTION_RESPONSE,
      //   payload: {
      //     chatSequenceId,
      //     exceptionId
      //   }
      // });
      dispatch(
        deleteExceptionResponse({
          chatSequenceId,
          exceptionId
        })
      );
    } catch (e) {
      console.error("deleteResponseByResponseId", e);
    }
  };
}

// export const updateLoanIdFilter = (loanIdSelected: string[]): any => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_LOANID_FILTERS,
//       payload: {
//         loanIdSelected
//       }
//     });
//   };
// };

// export const updateTradeIdFilter = (tradeIdSelected: string[]): any => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_TRADEID_FILTERS,
//       payload: {
//         tradeIdSelected
//       }
//     });
//   };
// };

// export const updateSPOCIdFilter = (spocIdsSelected: string[]): any => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_SPOCID_FILTERS,
//       payload: {
//         spocIdsSelected
//       }
//     });
//   };
// };

// export const updateLoanStageFilter = (loanStageSelected: string[]): any => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_LOAN_STAGE_FILTERS,
//       payload: {
//         loanStageSelected
//       }
//     });
//   };
// };

// export const updateWaiverStatusFilter = (
//   waiverStatusSelected: string[]
// ): any => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_WAIVER_STATUS_FILTERS,
//       payload: {
//         waiverStatusSelected
//       }
//     });
//   };
// };

// export const updateWaiverTypeFilter = (waiverTypeSelected: string[]): any => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_WAIVER_TYPE_FILTERS,
//       payload: {
//         waiverTypeSelected
//       }
//     });
//   };
// };

// export const updateInqueryStatusFilter = (
//   inquiryStatusSelected: string[]
// ): any => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_INQUERY_STATUS_FILTERS,
//       payload: {
//         inquiryStatusSelected
//       }
//     });
//   };
// };

// export const updateConditionStatusFilter = (
//   conditionStatusSelected: string[]
// ): any => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_CONDITION_STATUS_FILTERS,
//       payload: {
//         conditionStatusSelected
//       }
//     });
//   };
// };

// export const updateSearchDataFilter = (searchedData: string): any => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_SEARCH_DATA_FILTERS,
//       payload: {
//         searchedData
//       }
//     });
//   };
// };

export function updateNotificationStatus(notificationId: any): any {
  return async (dispatch: any) => {
    const header = {
      headers: { "Content-Type": "application/json" }
    };
    const url = `${
      getConfig().apiUrl
    }/notification/partyId/${getPersonId()}/status/READ`;
    // eslint-disable-next-line no-multi-assign
    const requestBodyToApprove = [{ notificationId }];
    await publicClient.put(url, requestBodyToApprove, header);
  };
}
export function getUnReadComments(chatIds: any) {
  return async (dispatch: any) => {
    try {
      const partyId = getPersonId();
      const url = `${
        getConfig().apiUrl
      }/chatservice/${partyId}/chats?chatIds=${chatIds.join(",")}&type=unread`;
      if (chatIds?.length) {
        const response = await publicClient.get(url);
        // dispatch({
        //   type: GET_ALL_UNREAD_COUNT,
        //   payload: {
        //     unreadCount: response?.data
        //   }
        // });
        dispatch(
          setAllUnreadCount({
            unreadCount: response?.data
          })
        );
      }
    } catch (err) {
      const e: any = err;
      console.error(e);
      // dispatch({
      //   type: GET_ALL_UNREAD_COUNT_ACTION_FAIL,
      //   error: {
      //     message: e.message
      //   }
      // });
    }
  };
}

function handleRolePrivileges(mustQuery: any) {
  if (isRoleBridge()) {
    mustQuery[mustQuery.length] = {
      bool: {
        must_not: {
          terms: {
            "loanType.raw": ["30 Year Loan"]
          }
        }
      }
    };
  } else if (isRoleDSCR()) {
    mustQuery[mustQuery.length] = {
      bool: {
        must_not: {
          terms: {
            "loanType.raw": ["Bridge Loan"]
          }
        }
      }
    };
  }
}

// export function handleFullScreenCommunication(): any {
//   // console.log("click full screen in action");
//   return async (dispatch: any) => {
//     dispatch({
//       type: IS_FULLSCREEN_COMMUNICATION_ENABLED
//     });
//   };
// }

// export function updateFullScreenUpdatedFlag(flag: boolean): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: UPDATE_FULLSCREEN_UPDATED_FLAG,
//       payload: flag
//     });
//   };
// }
