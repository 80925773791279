import React, { useEffect, useState, useMemo } from "react";
import {
  ClickAwayListener,
  Paper,
  Popper,
  Typography,
  Button
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../stores/rootReducer";
import { ApplyButton } from "../create-loan/loan-lists/BulkOperationPopup/ApplyButton";
import { LoanTypeFilter } from "../create-loan/loan-lists/LoanTypeFilter";
// import { updateTableSelectedFilters } from "../stores/masterView/masterView.action";
import { MasterViewStore } from "../stores/masterView/masterView.reducer";
import { useColumnTypeComponents } from "../custom-hooks/useColumnTypeComponents";
import { filterMasterView } from "./formatter";
import { getLoanTypeFilterMasterViewOptions } from "../create-loan/loan-lists/utils/constants";
import { updateTableSelectedFilters } from "../stores/servicerOnboarding/servicerOnboarding.reducer";

interface ColumnLoanTypeFilterProps {
  handleFilterToggle?: Function;
  isLoading: any;
  isFrozen: boolean;
}
const columnName = "loan.loanType.raw";
const tableFundingKey = "loan.loanDetailId.fundingType.raw";
const otherTagsKey = "loan.loanProcessType.raw";
const warehouseKey = "loan.loanDetailId.isWarehouseFunded";

export const ColumnLoanTypeFilterMasterView = (
  props: ColumnLoanTypeFilterProps
) => {
  const {
    isLoading,
    handleFilterToggle = () => {
      /* default value */
    },
    isFrozen
  } = props;
  const { tableFilterConfig }: any = useSelector<RootState, MasterViewStore>(
    (state) => state.masterViewStore
  );

  const { dropDownData } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );
  const columnLoanTypeFilter = true;
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<{ [key: string]: any }>({});
  const [localSelections, setLocalSelections] = useState<string[]>([]);
  const { checked, tfChecked, otherTagsChecked, warehouseChecked } =
    useMemo(() => {
      const LoanTypeSelected = tableFilterConfig?.[columnName] ?? [];
      const tableFundingSelected = tableFilterConfig?.[tableFundingKey] ?? [];
      const otherTagsSelected = tableFilterConfig?.[otherTagsKey] ?? [];
      const warehouseSelected = tableFilterConfig?.[warehouseKey] ?? [];
      return {
        checked: LoanTypeSelected,
        tfChecked: tableFundingSelected,
        otherTagsChecked: otherTagsSelected,
        warehouseChecked: warehouseSelected
      };
    }, [tableFilterConfig]);

  useEffect(() => {
    setSelected({
      "loan.loanDetailId.fundingType.raw": tfChecked,
      "loan.loanProcessType.raw": otherTagsChecked,
      [warehouseKey]: warehouseChecked
    });
  }, [tfChecked, otherTagsChecked, warehouseChecked]);

  useEffect(() => {
    setSelected({
      "loan.loanDetailId.fundingType.raw": tableFilterConfig?.[tableFundingKey],
      "loan.loanProcessType.raw": tableFilterConfig?.[otherTagsKey],
      [warehouseKey]: tableFilterConfig?.[warehouseKey]
    });
  }, [tableFilterConfig]);

  const loanTypeOptions = useMemo(() => {
    return (
      dropDownData?.loanTypeName?.buckets?.map((itm: any) => {
        return {
          ...itm,
          viewText: itm.key === "30 Year Loan" ? "Investor DSCR" : itm.key
        };
      }) ?? []
    );
  }, [dropDownData]);

  const {
    sortedOptions,
    handleListKeyDown,
    handleToggle,
    handleClose,
    handleCheckBoxToggle,
    open,
    setOpen,
    anchorRef,
    useStyles
  } = useColumnTypeComponents({
    handleFilterToggle,
    columnName,
    loanTypeOptions,
    setSelected,
    setLocalSelections,
    columnLoanTypeFilter,
    checked,
    tfChecked,
    otherTagsChecked,
    selected,
    localSelections
  });
  const classes = useStyles();

  const handleCloseFilter = (e: any) => {
    handleClose(e);
    changeZIndex(anchorRef.current);
  }

  const changeZIndex = (ele: any, override?: boolean) => {
    const state = override ?? open;
    let headerEle = ele?.closest('.MuiTableCell-root');
    if (headerEle) {
      headerEle.style.zIndex = state ? isFrozen ? 1 : 0 : 2;
    }
  };

  const aggregatedOptions = useMemo(() => {
    return getLoanTypeFilterMasterViewOptions(sortedOptions);
  }, [sortedOptions]);

  const handleApply = () => {
    const colNames = Object.keys(selected);
    const selectedOptions = { ...tableFilterConfig, ...selected };

    colNames.forEach((colName: string) => {
      const isColFiltered = selected?.[colName];
      if (!isColFiltered?.length) {
        delete selectedOptions?.[colName];
      }
    });

    dispatch(updateTableSelectedFilters({ ...selectedOptions }));
    setOpen(false);
    setLocalSelections([]);
  };

  const isfilterApplied = useMemo(() => {
    return checked.length || tfChecked.length || otherTagsChecked.length;
  }, [checked, tfChecked, otherTagsChecked]);

  return (
    <>
      {filterMasterView({
        open,
        handleToggle,
        anchorRef,
        isfilterApplied,
        classes,
        changeZIndex
      })}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
        style={{ zIndex: 1, position: "absolute", top: 35, willChange: "transform"  }}
      >
        <Paper
          classes={{
            root: classes.menuRoot
          }}
        >
          {isLoading && (
            <Typography
              classes={{
                root: classes.zeroState
              }}
            >
              Fetching Results
            </Typography>
          )}

          {!isLoading && (
            <ClickAwayListener onClickAway={(e) => handleCloseFilter(e)}>
              <div>
                <LoanTypeFilter
                  aggregatedOptions={aggregatedOptions}
                  classes={classes}
                  handleListKeyDown={handleListKeyDown}
                  handleCheckBoxToggle={handleCheckBoxToggle}
                  selected={selected}
                />
                <div style={{ padding: "1rem 0 1rem 10px" }}>
                  <ApplyButton
                    buttonText="Apply"
                    onClick={handleApply}
                    style={{ padding: "3px" }}
                    disabled={!localSelections.length}
                  />
                  <Button
                    aria-controls="cancel"
                    variant="contained"
                    color="primary"
                    style={{ padding: "3px" }}
                    onClick={handleCloseFilter}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </ClickAwayListener>
          )}
        </Paper>
      </Popper>
    </>
  );
};
