/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
// import { produce } from "immer";
// import { LOAN_SAVE_REQUESTS, RESET_RETRY_REQUEST_STORE } from "./retry-request.action";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface RetryRequestStore {
  [index: string]: {
    [index: string]: {
      config: any;
    };
  };
}
const initialState: RetryRequestStore = {
  loanSaveRequest: {},
  propertySaveRequest: {}
};

const retryRequestSlice = createSlice({
  name: "retryRequest",
  initialState,
  reducers: {
    loanSaveRequests: (
      state,
      action: PayloadAction<{
        requestCategory: string;
        loanId: string;
        config?: any;
      }>
    ) => {
      const { requestCategory, loanId, config } = action.payload;

      if (config) {
        if (state[requestCategory]) {
          const temp = state[requestCategory][loanId];
          if (temp) {
            state[requestCategory][loanId] = {
              ...temp,
              ...{ config }
            };
          } else {
            state[requestCategory] = {
              ...state[requestCategory],
              ...{ [loanId]: { config } }
            };
          }
        } else {
          state = {
            ...state,
            ...{ [requestCategory]: { [loanId]: { config } } }
          };
        }
      } else if (state[requestCategory] && state[requestCategory][loanId]) {
        // delete state[requestCategory][loanId];
        // if (Object.keys(state[requestCategory]).length === 0) {
        //   delete state[requestCategory];
        // }
        const temp = JSON.parse(JSON.stringify(state[requestCategory]));
        delete temp[loanId];
        state[requestCategory] = temp;
      }
    },
    resetRetryRequestReducer: () => initialState
  }
});

export const { loanSaveRequests, resetRetryRequestReducer } =
  retryRequestSlice.actions;
export const RetryRequestReducer = retryRequestSlice.reducer;

// export const RetryRequestReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case LOAN_SAVE_REQUESTS: {
//       const { requestCategory, loanId, config } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         // draftState.loaderCount += 1;
//         if (config) {
//           if (draftState[requestCategory]) {
//             const temp = draftState[requestCategory][loanId];
//             if (temp) {
//               draftState[requestCategory][loanId] = {
//                 ...temp,
//                 ...{ config }
//               };
//             } else {
//               draftState[requestCategory] = {
//                 ...draftState[requestCategory],
//                 ...{ [loanId]: { config } }
//               };
//             }
//           } else {
//             draftState = {
//               ...draftState,
//               ...{ [requestCategory]: { [loanId]: { config } } }
//             };
//           }
//         } else if (
//           draftState[requestCategory] &&
//           draftState[requestCategory][loanId]
//         ) {
//           const temp = draftState[requestCategory];
//           delete temp[loanId];
//           draftState[requestCategory] = temp;
//         }
//       });
//       return nextState;
//     }
//     case RESET_RETRY_REQUEST_STORE:
//       return initialState;
//     default:
//       return state;
//   }
// };
