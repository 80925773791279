import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const accordianStyles = makeStyles((theme: Theme) => ({
  smallAccordionSvg: {
    fontSize: 16
  },
  accordion: {
    "&:before": {
      backgroundColor: "transparent"
    },
    "& .Mui-expanded": {
      minHeight: "0px"
    }
  },
  accordionExpanded: {
    margin: "0 !important"
  },
  accordionSummary: {
    borderBottom: "1px solid #e9ecef",
    padding: "0.5rem 1rem",
    minHeight: "0px",
    fontSize: "12px",
    fontWeight: 600,
    "& .MuiAccordionSummary-content": {
      margin: "0px"
    }
  },
  accordionDetails: {
    padding: 0,
    display: "inherit"
  }
}));

export const filterWrapperStyles = makeStyles(() =>
  createStyles({
    filterWrapper: {
      margin: "1rem",
      background: "#ffffff",
      boxShadow: "0 2px 8px rgb(0 0 0 / 15%)",
      borderRadius: "4px",
      minWidth: "15%",
      height: "fit-content"
    },
    actionButtonsWrapper: {
      display: "flex",
      justifyContent: "space-around",
      padding: "0.5rem 1rem"
    },
    filtersContainer: {
      maxHeight: "70vh",
      overflowY: "auto"
    },
    cancelButton: {
      width: 69,
      height: 28,
      borderRadius: 4,
      marginLeft: 10,
      boxShadow: "0 1px 3px 0 rgb(0 0 0 / 20%)",
      color: "#32325d",
      backgroundColor: "#fff",
      "&:hover": {
        background: "none"
      },
      border: "none"
    },
    badgeFilter: {
      height: "6px !important",
      width: "6px !important",
      zIndex: "auto"
    }
  })
);

export const zeroStateStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      height: "calc(100vh - 380px)",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "120px"
    },
    notCreatedLoan: {
      margin: "auto",
      fontSize: "16px",
      textAlign: "center",
      color: "#32325d"
    }
  })
);

export const financibilityHeaderStyles = makeStyles(() =>
  createStyles({
    aggrgations: {
      display: "flex",
      flexDirection: "column",
      margin: "0 1rem"
    },
    aggregationsWrapper: {
      display: "flex",
      padding: "1rem"
    }
  })
);

export const loanListStyles = makeStyles(() =>
  createStyles({
    loanListContainer: {
      display: "flex",
      boxShadow: "rgb(0 0 0 / 15%) 0px 2px 8px",
      borderRadius: "4px",
      flexDirection: "column"
    },
    loanListContainerNew: {
      display: "flex",
      borderRadius: "4px",
      boxShadow: "none",
      flexDirection: "column",
      background: "#f6f9fc"
    },
    listWrapper: {
      width: "inherit",
      background: "#fff",
      boxShadow: "rgb(0 0 0 / 15%) 0px 2px 8px",
      marginBottom: "30px"
    },
    accordianHeader: {
      display: "flex",
      flexDirection: "row"
    },
    loanListHeader: {
      display: "flex",
      flexDirection: "row",
      padding: "0.5rem 1rem",
      border: "1px solid rgb(233, 236, 239)",
      background: "#f6f9fc"
    },
    selecteAllCheckBox: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginRight: "10px",
      fontWeight: 600
    },
    searchWrapper: {
      flexGrow: 3,
      display: "flex"
    },
    wareHouseDropDown: {
      flexGrow: 2,
      display: "flex",
      alignItems: "center",
      justifyContent: "end"
    },
    exportWrapper: {
      margin: "0px 1rem 0 2rem",
      alignItems: "center",
      display: "flex"
    }
  })
);

export const modalHeaderStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column"
  },
  sectionWrapper: {
    display: "flex",
    backgroundColor: "#ffffff",
    borderRadius: 4
  },
  smallAccordionSvg: {
    fontSize: 16
  },
  guidePanelDetail: {
    padding: "0px"
  },
  guidelinChildeAccordianDetail: {
    padding: "5px"
  },
  guidelinChildeAccordian: {
    padding: "5px",
    borderRadius: "0px !important",
    border: "solid 1px #dedede",
    margin: "0px !important",
    "& .MuiAccordionDetails-root": {
      display: "initial"
    }
  },
  msgbox: {
    padding: "10px 15px",
    textAlign: "initial",
    margin: "5px",
    width: "calc(100% - 10px)",
    border: "solid 1px #d4e1ef",
    borderRadius: "4px"
  },
  dialogConainer: {
    width: "100%",
    overflow: "auto",
    "& .MuiTableContainer-root": {
      overflow: "initial"
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none"
    }
  },
  header: {
    color: "#32325d",
    fontSize: "14px",
    fontWeight: 600
  },
  customTableTest: {
    "& .MuiTableCell-root": {
      border: "1px solid #dedede"
    }
    // "& .PrivateTabIndicator-colorSecondary": {
  },
  redDot: {
    height: "9px",
    width: "9px",
    backgroundColor: "#f75676",
    borderRadius: "50%",
    display: "inline-block"
  },
  greenDot: {
    height: "9px",
    width: "9px",
    backgroundColor: "#2ece89",
    borderRadius: "50%",
    display: "inline-block"
  },
  customTable: {
    background: "#f6f9fc",
    "& .MuiTableCell-root": {
      border: "1px solid #dedede"
    }
  },
  customRow: {
    background: "#fff",
    height: "52px"
  },
  testsAccordian: {
    "& .MuiPaper-elevation1": {
      boxShadow: "none"
    }
  },
  testsPricingChip: {
    padding: "1px 3px",
    borderRadius: "13px",
    border: "solid 1px #dde1f3",
    backgroundColor: "#fff",
    width: "125px",
    height: "26px",
    "& .MuiTabs-root": {
      height: "inherit",
      minHeight: "auto"
    },
    "& .MuiTab-root": {
      minWidth: "auto !important",
      minHeight: "auto",
      padding: "2px 14px",
      fontSize: "10px",
      width: "58px",
      height: 22
    },
    "& .Mui-selected": {
      backgroundColor: "#5e72e4",
      color: "#fff",
      borderRadius: "10px",
      fontWeight: "bold"
    },
    "& .MuiAppBar-colorDefault": {
      padding: "0px",
      backgroundColor: "transparent",
      backgroundImage: "none"
    },
    "& .PrivateTabIndicator-colorPrimary": {
      backgroundColor: "transparent"
    }
  },
  boxWidth: {
    width: "200px",
    textAlign: "center"
  }
}));

export const useStylesBootstrap = makeStyles(theme => ({
  arrow: {
    color: theme.palette.common.white,
    "&::before": {
      boxShadow: theme.shadows[3],
      boxSizing: "border-box"
    }
  },
  tooltip: {
    color: "rgba(0, 0, 0, 0.87)",
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[2],
    padding: "11px",
    fontSize: theme.typography.pxToRem(12)
  }
}));

export const loanCardStyles = makeStyles(() =>
  createStyles({
    partnerIconButton: {
      width: "28px",
      height: "28px",
      borderRadius: "4px",
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      border: "1px solid #ececec"
    },
    partnerButton: {
      color: "#32325d",
      height: "28px",
      borderRadius: "4px",
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      border: "1px solid #ececec"
    },
    cardWrapper: {
      padding: "1rem",
      borderBottom: "1px solid #e9ecef",
      width: "100%"
    },
    loanDetailsWrapper: {
      width: "inherit",
      display: "inline-block",
      position: "relative"
    },
    commentIconWrapper: {
      height: "100%",
      display: "flex",
      alignSelf: "center",
      marginLeft: "auto"
    },
    commentIcon: {
      height: "100%",
      marginRight: "4rem"
    },
    fundingSourceDropDown: {
      display: "flex",
      alignItems: "center",
      marginRight: "1rem",
      minWidth: "13rem"
    }
  })
);

export const loanDetailsStyles = makeStyles(() =>
  createStyles({
    tagWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    wrapper: {
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap"
    },
    bundleWrapper: {
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap"
    },
    bundleText: {
      textAlign: "left",
      fontSize: "14px !important",
      lineHeight: 1.57
    },
    loanId: {
      textAlign: "left",
      fontSize: "14px !important",
      fontWeight: "bold",
      lineHeight: 1.57,
      color: "#5566d6 !important",
      userSelect: "text",
      cursor: "pointer",
      whiteSpace: "nowrap"
    },
    originatorDataWrapper: {
      display: "inline-flex",
      alignItems: "center"
      // maxWidth: "14rem"
    },
    originatorName: {
      fontWeight: 600,
      color: "#32325d",
      lineHeight: 1.57,
      margin: "0 5px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    },
    onHoldWraper: {
      display: "flex"
    },
    wareHouseLinesWrapper: {
      display: "flex"
    },
    wareHouseLineCard: {
      margin: "10px 5px 10px 0",
      display: "flex",
      padding: "0.4rem"
    },
    concentration: {
      padding: "0 0.5rem",
      margin: "0 5px 0 5px",
      borderRadius: "10px",
      color: "#fff"
    },
    eligibility: {
      padding: "0 0.5rem",
      margin: "0 5px 0 5px",
      borderRadius: "10px",
      color: "#fff"
    },
    redirectIcon: {
      padding: "0px",
      borderRadius: 0,
      height: "fitContent"
    },
    closingDateText: {
      fontWeight: 600,
      color: "#32325d",
      lineHeight: 1.57
    },
    purchaseDateText: {
      fontWeight: 600,
      color: "#32325d",
      lineHeight: 1.57
    },
    tagsWrapper: {
      display: "flex",
      margin: "0.5rem 0"
    }
  })
);
