/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */

import React, { useState, useEffect } from "react";
import {
  Popover,
  IconButton,
  Badge,
  FormGroup,
  FormControlLabel,
  Typography,
  Checkbox
} from "@mui/material";
import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { DatePickerRange } from "../create-loan/purchase-and-settlements/components/DatePickerRange";
import { DateRangeIcon } from "./DateRangeIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    badgeFilter: {
      height: "6px !important",
      width: "6px !important",
      backgroundColor: "#5566d6",
      zIndex: "auto",
      minWidth: "6px"
    }
  })
);

interface ListOptions {
  id?: string;
  key: string;
  viewText?: string;
}
interface DateRangeFilterOptions {
  handleDateRangeChange: any;
  columnName: any;
  filterSelections?: any;
  filterTitle: string;
}

const DateRangeFilter = (props: DateRangeFilterOptions) => {
  const {
    handleDateRangeChange,
    columnName,
    filterSelections,
    filterTitle
  } = props;
  const classes = useStyles();

  const defaultStartDate: any = moment()
    .startOf("day")
    .toDate();
  const defaultEndDate: any = moment()
    .startOf("day")
    .toDate();
  const defaultDateRange: any[] = [
    {
      startDate: defaultStartDate,
      endDate: defaultEndDate,
      key: "selection"
    }
  ];

  const [dateRange, setDateRange] = useState<any>(defaultDateRange);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openCalender, setOpenCalender] = useState<boolean>(false);
  const [nullValue, setNullValue] = useState<boolean>(false);

  const handleClose = () => {
    setOpenCalender(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenCalender(true);
    setAnchorEl(event.currentTarget);
  };

  const getDateconverted = (date: any) => {
    const convertedDate = `${date?.getFullYear()}-${
      date?.getMonth() > 8 ? date?.getMonth() + 1 : `0${date?.getMonth() + 1}`
    }-${date?.getDate() > 9 ? date?.getDate() : `0${date?.getDate()}`}`;
    return convertedDate;
  };

  const applyDateRangeFilter = (
    nStartDate: any,
    nEndDate: any,
    reset?: boolean
  ) => {
    let format: any;
    if (columnName === "loan.loanDetailId.loanConfigId.datePurchased") {
      format = nullValue
        ? {
            format: "EEE, dd MMM yyyy HH:mm:ss zzz",
            gte: null,
            lte: null,
            nullValue
          }
        : {
            format: "EEE, dd MMM yyyy HH:mm:ss zzz",
            gte: nStartDate.toUTCString(),
            lte: nEndDate.toUTCString(),
            nullValue
          };
    } else if (
      columnName === "borrowerDetails.loanUser.originalCreditScoreReportDate"
    ) {
      format = {
        format: "EEE, dd MMM yyyy HH:mm:ss zzz",
        gte: nStartDate.toUTCString(),
        lte: nEndDate.toUTCString(),
        nullValue: false
      };
    } else if (columnName === "firstOpenConditionDate") {
      if (nullValue) {
        format = {
          exists: {
            field: "firstOpenConditionDate"
          }
        };
      } else {
        const startDate: any = getDateconverted(nStartDate);
        const timezone: any = new Date()
          .toString()
          .match(/([A-Z]+[\+-][0-9]+)/)?.[1];
        let gmtVal: any = "";
        if (timezone?.includes("+")) {
          gmtVal = new Date()
            .toString()
            .match(/([A-Z]+[\+-][0-9]+)/)?.[1]
            .replace(/(.{2})$/, ":$1");
        }
        const endDate: any = getDateconverted(nEndDate);
        format = {
          format: "yyyy-MM-dd zzzz",
          gte: `${startDate} ${gmtVal}`,
          lte: `${endDate} ${gmtVal}`
        };
      }
    } else {
      const startDate: any = getDateconverted(nStartDate);
      const endDate: any = getDateconverted(nEndDate);
      format = {
        format: "yyyy-MM-dd",
        gte: startDate,
        lte: endDate
      };
    }
    handleDateRangeChange(
      {
        [columnName]: [format]
      },
      filterTitle
    );
  };

  const setValueToNull = () => {
    if (nullValue) setDateRange(defaultDateRange);
    else {
      setDateRange([
        {
          startDate: null,
          endDate: null,
          key: "selection"
        }
      ]);
    }
    setNullValue(!nullValue);
  };
  const getNullValueComponent = () => {
    const val = columnName === "firstOpenConditionDate";

    return val;
  };

  useEffect(() => {
    if (filterSelections?.[columnName]?.[0]) {
      const dateRangeVal: any = [
        {
          startDate: moment(filterSelections?.[columnName]?.[0].gte).toDate(),
          endDate: moment(filterSelections?.[columnName]?.[0].lte).toDate(),
          key: "selection"
        }
      ];
      setDateRange(dateRangeVal);
    } else {
      setDateRange(defaultDateRange);
    }
  }, [filterSelections]);

  useEffect(() => {
    if (columnName === "firstOpenConditionDate") {
      if (
        filterSelections?.["firstOpenConditionDate"] &&
        Object.keys(filterSelections?.["firstOpenConditionDate"]?.[0]).includes(
          "exists"
        )
      ) {
        setNullValue(true);
        setDateRange([
          {
            startDate: null,
            endDate: null,
            key: "selection"
          }
        ]);
      } else if (filterSelections?.["firstOpenConditionDate"]?.[0]) {
        setNullValue(false);
        const dateRangeVal: any = [
          {
            startDate: moment(
              filterSelections?.["firstOpenConditionDate"]?.[0].gte
            ).toDate(),
            endDate: moment(
              filterSelections?.["firstOpenConditionDate"]?.[0].lte
            ).toDate(),
            key: "selection"
          }
        ];
        setDateRange(dateRangeVal);
      } else {
        setNullValue(false);
        setDateRange(defaultDateRange);
      }
    }
  }, [filterSelections]);

  // const handleClose = (event: React.MouseEvent<EventTarget>) => {
  //   if (
  //     anchorRef.current &&
  //     anchorRef.current.contains(event.target as HTMLElement)
  //   ) {
  //     return;
  //   }
  //   setOpen(false);
  // };
  return (
    <>
      <IconButton size="small" aria-haspopup="true" onClick={handleClick}>
        <Badge
          color="primary"
          variant="dot"
          invisible={!filterSelections || !filterSelections[columnName]?.length}
          classes={{ badge: classes.badgeFilter }}
        >
          <div style={{ margin: "2px 2px 0px 0px" }}>
            {!filterSelections || !filterSelections[columnName]?.length ? (
              <CalendarTodayIcon
                style={{
                  fontSize: "small",
                  padding: "1px !important",
                  color: "#000000"
                }}
              />
            ) : (
              <DateRangeIcon />
            )}
          </div>
        </Badge>
      </IconButton>
      {openCalender && (
        <Popover
          id="mouse-over-popover"
          data-testid="filter-popover"
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          disableRestoreFocus
          PaperProps={{
            style: {
              marginTop: "5px",
              marginLeft: "-8px",
              padding: "5px"
            }
          }}
        >
          <div
            style={{
              margin: getNullValueComponent()
                ? "2em 0.833em"
                : "5px 0px 0px 0px",
              display: "flex",
              justifyContent: getNullValueComponent()
                ? "space-between"
                : "flex-end"
            }}
          >
            {getNullValueComponent() && (
              <>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={nullValue}
                        onChange={() => setValueToNull()}
                        color="primary"
                      />
                    }
                    style={{ marginLeft: 0 }}
                    label={
                      <Typography style={{ fontSize: 11 }}>
                        FILTER BY NULL DATE
                      </Typography>
                    }
                  />
                </FormGroup>

                <CloseIcon
                  htmlColor="#414169"
                  onClick={() => {
                    setOpenCalender(false);
                    setDateRange(defaultDateRange);
                    setNullValue(false);
                  }}
                  style={{
                    width: 16,
                    height: 16,
                    cursor: "pointer"
                  }}
                />
              </>
            )}
          </div>
          <DatePickerRange
            title="SELECT DATE RANGE (Format - MM/DD/YYYY)"
            dateInputFormat="MM/dd/yyyy"
            dateRange={dateRange}
            placeholder=""
            maxDate={moment(
              moment(moment().toDate())
                .add(6, "M")
                .format("YYYY-MM-DD")
            ).toDate()}
            onChange={(item: any) => {
              setDateRange([item.selection]);
            }}
            onApply={() => {
              if (!dateRange.length) return;
              const newStartDate: any = dateRange[0].startDate;
              const newEndDate: any = dateRange[0].endDate;
              // setStartDate(newStartDate);
              // setEndDate(newEndDate);
              setOpenCalender(false);
              applyDateRangeFilter(newStartDate, newEndDate);
            }}
            cancelButtonText="Reset"
            onCancel={() => {
              setDateRange(defaultDateRange);
              setNullValue(false);
            }}
            nullDateValue={nullValue}
          />
        </Popover>
      )}
    </>
  );
};

export default DateRangeFilter;
