/*
 * General utils for managing cookies in Typescript.
 */
import Cookies from "js-cookie";

export function setCookie(name: any, value: any, domain?: string) {
  const date = new Date();
  date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);
  const secureFlag: any = window.location.hostname !== "localhost";
  let options: { expires?: Date; secure?: boolean; domain?: string } = {
    expires: date,
    secure: secureFlag
  };
  options = domain ? { ...options, domain } : options;
  Cookies.set(name, value, options);
}

export function getCookie(name: string) {
  return Cookies.get(name) === undefined ? null : Cookies.get(name);
}

/**
 * This function is used to set cookies whose value is key value pair.
 * @param cookieName name of the cookie that will be set.
 * @param identifierValue value of key
 * @param value value that has to be stored in cookie for identifierValue
 *
 * ex: if we want to set value of availableLoanStage for a loan, then do this
 * setCookieWithIdentifier("availableLoanStage",BR123123,["PRE","POST"])
 * it will be stored as {BR123123:["PRE","POST"]}
 */
export const setCookieWithIdentifier = (
  cookieName: string,
  identifierValue: string,
  value: any
) => {
  const cookieValue = getCookie(cookieName);
  const parsedCookieValue = cookieValue ? JSON.parse(cookieValue) : {};
  const newCookieValue = {
    ...parsedCookieValue,
    ...{ [identifierValue]: value }
  };
  setCookie(cookieName, newCookieValue);
};
export const getCookieWithIdentifier = (
  cookieName: string,
  identifierValue: string
) => {
  try {
    const cookieValue = getCookie(cookieName);
    if (cookieValue) {
      const parsedCookieValue = JSON.parse(cookieValue);
      return parsedCookieValue[identifierValue];
    }
    return null;
  } catch (error) {
    return null;
  }
};
export function removeCookie(name: string) {
  Cookies.remove(name);
}

export function deleteAllCookies() {
  Cookies.remove('access_token');
  Cookies.remove('id_token');
  Cookies.remove('fetchingToken');
  Cookies.remove('box_id_token');
  Cookies.remove('refresh_token');
  Cookies.remove('id_token_expires_in');
  Cookies.remove('ad_access_token');
  Cookies.remove('ad_id_token');
  Cookies.remove('ad_refresh_token');
  Cookies.remove('ad_id_token_expires_in');
  Cookies.remove('ad_code');
  const allCookies = Object.keys(Cookies.get());
  for (let i = 0; i < allCookies.length; i++) {
    /*
      Added to make same experience to user when
      note location updated.  
    */
    if (allCookies[i] === "notesPinCords") i++;
    Cookies.remove(allCookies[i]);
  }
}
