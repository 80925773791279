/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoanStage, UserRole, publicClient } from "@toorak/tc-common-fe-sdk";
import { getConfig } from "../../config/config";
import {
  DiscussionModel,
  DiscussionTabModel,
  FilterModel,
  discussionEntriesPerPage,
  usersKey,
  tagsKey,
  selectAllName,
  originatorsLabel,
  toorakUsersLabel,
  FilterOptionModel,
  getInitialFilterState,
  commentSuccess,
  updateNewStore,
  updatePartyIdMap,
  updateFilter,
  getChatsSuccess
} from "./Discussions.reducer";
import {
  showLoader,
  hideLoader
} from "../loaderAndException/loaderAndException.action";
import { isRole } from "../../utils/AccessManagement";
import { getCookie } from "../../utils/cookies";
// import { updateScenarioAttachedToLoanInRedux } from "../Scenario/ScenarioActions";
import { isNullOrUndefined } from "../../utils/formatChecks";
import { updateAttachedScenarioInRedux } from "../Scenario/ScenarioReducer";

// export const GET_ALL_CHATS = "GET_ALL_CHATS";
// export const GET_CHATS_SUCCESS = "GET_CHATS_SUCCESS";
// export const CREATE_DISCUSSION = "CREATE_DISCUSSION";
// export const ADD_CHAT = "ADD_CHAT";

// export const COMMENT_SUCCESS = "COMMENT_SUCCESS";
// export const COMMENT_THREAD_SUCCESS = "COMMENT_THREAD_SUCCESS";
// export const ACTION_FAIL = "ACTION_FAIL";
// export const UPDATE_DOCS_IN_DISCUSSION = "UPDATE_DOCS_IN_DISCUSSION";
// export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
// export const DELETE_DISCUSSION_DOCS = "DELETE_DISCUSSION_DOCS";
// export const UPDATE_NEW_STORE = "UPDATE_NEW_STORE";
// export const UPDATE_PARTYID_MAP = "UPDATE_PARTYID_MAP";
// export const UPDATE_FILTER = "UPDATE_FILTER";
// export const UPDATE_SELECTED_TAB = "UPDATE_SELECTED_TAB";

async function getChats(
  loanId: string,
  hits: any[],
  partyIdMap: { [key: string]: string },
  getUnreadCounts: boolean
): Promise<{ [key: string]: DiscussionModel }> {
  /**
   * get all the discussions under the loanId
   * calculate the partyId list
   * update name information
   * arrange in latest first order
   */
  const chats: { [key: string]: DiscussionModel } = {};
  const chatIdArray: string[] = [];
  const partyIdList: string[] = [];
  hits.forEach((hitsObject: any) => {
    if (hitsObject?._source?.loanId && hitsObject._source.loanId === loanId) {
      const { discussion } = hitsObject._source;
      const chat: DiscussionModel = {
        chatId: discussion.id,
        partyId: discussion.chatLine.partyId,
        createdOn: discussion.createdOn
          ? discussion.createdOn
          : new Date().toISOString(),
        title: discussion.title ? discussion.title : "",
        tags: discussion.tags,
        description: discussion.chatLine.lineText,
        name: partyIdMap[discussion.chatLine.partyId]
          ? partyIdMap[discussion.chatLine.partyId]
          : " ",
        role:
          discussion?.partyAccount?.accountType === "ORIGINATOR" ? "O" : "T",
        unreadCount: 0
      };
      chatIdArray.push(discussion.id);
      partyIdList.push(discussion.chatLine.partyId);
      chats[discussion.id] = chat;
    }
  });

  if (Object.keys(partyIdMap).length === 0) {
    const responseArray: any = await getPartyIdsInfo(partyIdList);
    const newPartyIdMap: { [key: string]: string } = {};
    for (const response of responseArray) {
      newPartyIdMap[response.partyId] =
        (response.firstName ? `${response.firstName} ` : "") +
        (response.middleName ? `${response.middleName} ` : "") +
        (response.lastName ? response.lastName : "");
    }
    Object.keys(chats).forEach((chatId: string) => {
      chats[chatId].name = newPartyIdMap[chats[chatId]?.partyId]
        ? newPartyIdMap[chats[chatId].partyId]
        : "";
    });
  }

  if (getUnreadCounts) {
    const unreadCountObject = await getUnreadCount(chatIdArray);
    if (Object.keys(unreadCountObject).length > 0) {
      chatIdArray.forEach((chatId: string) => {
        chats[chatId].unreadCount = unreadCountObject[chatId]
          ? unreadCountObject[chatId]
          : 0;
      });
    }
  }
  return chats;
}

// function getDateNumber(date: number | any): number {
//   if (!isNaN(date)) {
//     return date;
//   } else {
//     return Date.parse(date);
//   }
// }

function getPartyIdsInfo(partyIds: string[]) {
  return new Promise((resolve, reject) => {
    if (partyIds.length === 0) {
      reject(new Error("Fail")); // LPP-4303 since reject will flow the statement. To break the statement flow added return.
      return;
    }
    // let queryString = `partyIds=${partyIds[0]}`; // minimum 2 partyIds required
    // // eslint-disable-next-line no-restricted-syntax
    // for (const partyId of partyIds) {
    //   if (partyId) {
    //     queryString += `&partyIds=${partyId}`;
    //   } else {
    //     reject(new Error("Fail"));
    //   }
    // }

    const url = `${getConfig().apiUrl}/customer/fetch`;
    publicClient
      .post(url, { partyIds })
      .then((res: any) => {
        resolve(res.data);
      })
      .catch(reject);
  });
}

async function getUnreadCount(
  chatIds: string[]
): Promise<{ [key: string]: number }> {
  return new Promise((resolve, reject) => {
    if (chatIds.length === 0) {
      return resolve({});
    }
    const queryString = chatIds.join();
    const personPartyId: string | any = getCookie("person_id");

    const url = `${
      getConfig().apiUrl
    }/chatservice/${personPartyId}/chats?chatIds=${queryString}&type=unread`;

    publicClient
      .get(url)
      .then((res: any) => {
        const resolvedData: { [key: string]: number } = {};
        if (res.data instanceof Array) {
          res.data.forEach((unreadChat: any) => {
            if (unreadChat.id) {
              resolvedData[unreadChat.id.toString()] = unreadChat.count;
            }
          });
        }
        resolve(resolvedData);
      })
      .catch(reject);
  });
}

export function getDiscussionForChat(
  loanId: string,
  loanStage: LoanStage,
  chatId: string
): any {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(showLoader());
      const url = `${getConfig().apiUrl}/search/${
        getConfig().dashboardUrl
      }/_search`;
      const boolMustArray: any[] = [];

      if (getCookie("loginFlow") === "originator") {
        let accountIds: any;
        accountIds = getCookie("org_id");
        // accountIds = JSON.parse(accountIds);
        boolMustArray.push(getTermBlock("originatorId.raw", [accountIds]));
      } else if (!isRole(UserRole.LAT_TREASURER)) {
        let accountIds: any;
        accountIds = localStorage.getItem("account_ids");
        accountIds = JSON.parse(accountIds);
        boolMustArray.push(getTermBlock("originatorId.raw", accountIds));
      }
      boolMustArray.push(getTermBlock("resource.raw", ["discussion"]));
      boolMustArray.push(getTermBlock("identifierName.raw", ["LOAN"]));
      boolMustArray.push(
        getTermBlock("secondaryIdentifierName.raw", ["LOAN_STAGE"])
      );
      boolMustArray.push(
        getTermBlock("secondaryIdentifierValue.raw", [loanStage])
      );
      boolMustArray.push(getTermBlock("identifierValue.raw", [loanId]));
      boolMustArray.push(getTermBlock("discussion.id", [parseInt(chatId)]));
      const queryObject = {
        query: {
          bool: {
            must: boolMustArray
          }
        },
        _source: ["*"],
        sort: ["_score"],
        size: 100,
        from: 0
      };
      const resp = await publicClient.post(url, queryObject, {
        headers: {
          "Content-Type": "application/json"
        }
      });

      const state = getState();

      const storePartyIdMap: { [key: string]: string } = state?.discussionStore
        ?.partyIdMap
        ? state.discussionStore.partyIdMap
        : {};

      const hits: any[] = resp.data?.response?.hits?.hits
        ? resp.data.response.hits.hits
        : [];

      const allDiscussions: { [key: string]: DiscussionModel } = await getChats(
        loanId,
        hits,
        storePartyIdMap,
        false
      );
      // dispatch({
      //   type: GET_CHATS_SUCCESS,
      //   payload: { response: allDiscussions }
      // });
      dispatch(getChatsSuccess({ response: allDiscussions }));
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
    }
  };
}

function getMultiMatchBlock(searchText: string): any {
  return {
    multi_match: {
      query: searchText,
      fields: ["discussion.chatLine.lineText", "discussion.title"],
      type: "phrase_prefix"
    }
  };
}

export function getTermBlock(
  termName: string,
  termValues: string[] | number[]
): any {
  return {
    terms: {
      [termName]: termValues
    }
  };
}

export function getRangeBlock(termName: string, termValues: any): any {
  return {
    range: {
      [termName]: termValues
    }
  };
}
function getTags(filterModel: FilterModel) {
  if (filterModel[tagsKey]) {
    const alltags = [];
    const selectedTags = [];
    const tagOptions = filterModel[tagsKey].options;
    for (const key in tagOptions) {
      if (tagOptions.hasOwnProperty(key)) {
        if (tagOptions[key].name !== "Select All") {
          alltags.push(tagOptions[key].name);
        }
        if (tagOptions[key].checked) {
          selectedTags.push(tagOptions[key].name);
        }
      }
    }
    return selectedTags.includes("Select All") ? alltags : selectedTags;
  }
  return [];
}
function getFilters(filterModel: FilterModel): { [key: string]: string[] } {
  const filters: { [key: string]: string[] } = {};
  Object.keys(filterModel).forEach((filterKey: string) => {
    let options: FilterOptionModel = {};
    if (filterKey === usersKey) {
      // remove Originators, Toorak Users text in case of userKey
      const userOptions = filterModel[filterKey].options;
      Object.keys(userOptions).forEach((optionKey: string) => {
        if (userOptions[optionKey].isOption) {
          options[optionKey] = userOptions[optionKey];
        }
      });
    } else {
      options = filterModel[filterKey].options;
    }
    const optionKeys: string[] = Object.keys(options);
    if (optionKeys.length > 1) {
      // by default selectAll will be present
      let selectAllId: string = "";
      const selectedIds: string[] = [];
      const allIds: string[] = [];

      optionKeys.forEach((optionKey: string) => {
        if (options[optionKey].name === selectAllName) {
          selectAllId = optionKey;
        } else {
          allIds.push(optionKey);
          if (options[optionKey].checked) {
            selectedIds.push(optionKey);
          }
        }
      });
      if (options[selectAllId]?.checked) {
        // if selectAll selected, select all options
        filters[filterKey] = allIds;
      } else {
        /**
         * choose the selected Ids
         * If nothing is selected, choose all options
         */
        if (selectedIds.length === 0) {
          filters[filterKey] = allIds;
        } else {
          filters[filterKey] = selectedIds;
        }
      }
    }
  });
  return filters;
}

async function getUserFilters(
  loanId: string,
  loanStage: LoanStage,
  partyIdMap: { [key: string]: string }
): Promise<{
  userFilters: FilterModel;
  partyIdMap: { [key: string]: string };
}> {
  const filterState: FilterModel = getInitialFilterState();
  const originatorFiltersResult = await getSpecificUserFilter(
    loanId,
    loanStage,
    1,
    partyIdMap
  );
  const toorakUsersFiltersResult = await getSpecificUserFilter(
    loanId,
    loanStage,
    2,
    originatorFiltersResult.partyIdMap
  );
  let tagAttchedFilter: any = [];
  let tagAttchedFilterOrg: any = [];
  let tagAttchedFilterLat: any = [];
  if (
    originatorFiltersResult &&
    originatorFiltersResult.filterOption &&
    originatorFiltersResult.filterOption[tagsKey]
  ) {
    tagAttchedFilterOrg = originatorFiltersResult.filterOption[tagsKey];
    delete originatorFiltersResult.filterOption[tagsKey];
  }
  if (
    toorakUsersFiltersResult &&
    toorakUsersFiltersResult.filterOption &&
    toorakUsersFiltersResult.filterOption[tagsKey]
  ) {
    tagAttchedFilterLat = toorakUsersFiltersResult.filterOption[tagsKey];
    delete toorakUsersFiltersResult.filterOption[tagsKey];
  }
  tagAttchedFilter = tagAttchedFilterOrg.concat(tagAttchedFilterLat);
  const dummyObj: any = {
    filterName: tagsKey,
    options: {
      selectAll: {
        name: "Select All",
        checked: false,
        isOption: true
      }
    }
  };
  tagAttchedFilter?.forEach((element: any) => {
    dummyObj.options[element.name] = element;
  });
  let options = { ...filterState[usersKey].options };
  options = {
    ...options,
    ...originatorFiltersResult.filterOption,
    ...toorakUsersFiltersResult.filterOption
  };
  filterState[usersKey].options = options;
  if (tagAttchedFilter && tagAttchedFilter.length > 0) {
    filterState[tagsKey] = dummyObj;
  }
  return {
    userFilters: filterState,
    partyIdMap: toorakUsersFiltersResult.partyIdMap
  };
}

async function getSpecificUserFilter(
  loanId: string,
  loanStage: LoanStage,
  tabNumber: number,
  partyIdMap: { [key: string]: string }
): Promise<{
  filterOption: FilterOptionModel;
  partyIdMap: { [key: string]: string };
}> {
  const url = `${getConfig().apiUrl}/search/${
    getConfig().dashboardUrl
  }/_search`;
  const boolMustArray: any[] = [];
  if (getCookie("loginFlow") === "originator") {
    let accountIds: any;
    accountIds = getCookie("org_id");
    boolMustArray.push(getTermBlock("originatorId.raw", [accountIds]));
  } else if (!isRole(UserRole.LAT_TREASURER)) {
    let accountIds: any;
    accountIds = localStorage.getItem("account_ids");
    accountIds = JSON.parse(accountIds);
    boolMustArray.push(getTermBlock("originatorId.raw", accountIds));
  }
  boolMustArray.push(getTermBlock("resource.raw", ["discussion"]));
  boolMustArray.push(getTermBlock("identifierName.raw", ["LOAN"]));
  boolMustArray.push(getTermBlock("identifierValue.raw", [loanId]));
  boolMustArray.push(
    getTermBlock("secondaryIdentifierName.raw", ["LOAN_STAGE"])
  );
  boolMustArray.push(getTermBlock("secondaryIdentifierValue.raw", [loanStage]));
  if (tabNumber === 1) {
    boolMustArray.push(
      getTermBlock("discussion.partyAccount.accountType.raw", ["ORIGINATOR"])
    );
  } else if (tabNumber === 2) {
    boolMustArray.push(
      getTermBlock("discussion.partyAccount.accountType.raw", ["LAT"])
    );
  }
  let queryObject: { [key: string]: any } = {};
  queryObject = {
    aggs: {
      partyIdBucket: {
        terms: {
          field: "discussion.chatLine.partyId.raw"
        }
      },
      tagsBucket: {
        terms: {
          field: "discussion.tags.raw",
          size: 100
        }
      }
    }
  };
  const queryBoolObject: { [key: string]: any } = {
    query: {
      bool: {
        must: boolMustArray
      }
    },
    size: 100,
    from: 0,
    sort: [{ createdOn: { order: "desc" } }]
  };
  queryObject = {
    ...queryObject,
    ...queryBoolObject
  };
  const resp = await publicClient.post(url, queryObject, {
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (
    resp.data.response?.aggregations?.partyIdBucket?.buckets?.length > 0 ||
    resp.data.response?.aggregations?.tagsBucket?.buckets?.length > 0
  ) {
    const { buckets } = resp.data.response.aggregations.partyIdBucket;
    const tagBuckets = resp.data.response.aggregations.tagsBucket;
    const filterOptions: any = {};
    let newPartyIdMap: any;
    if (resp.data.response?.aggregations?.partyIdBucket?.buckets?.length > 0) {
      const partyIdList: string[] = [];
      buckets.forEach((bucket: any) => {
        partyIdList.push(bucket.key);
      });
      if (tabNumber === 1) {
        filterOptions[originatorsLabel] = {
          name: originatorsLabel,
          checked: false,
          isOption: false
        };
      } else if (tabNumber === 2) {
        filterOptions[toorakUsersLabel] = {
          name: toorakUsersLabel,
          checked: true,
          isOption: false
        };
      }
      // check if name is available for the partyId
      let isNameAvailable = true;
      partyIdList.forEach((partyId: string) => {
        if (partyIdMap[partyId]) {
          isNameAvailable = isNameAvailable && true;
        }
        isNameAvailable = isNameAvailable && false;
      });
      newPartyIdMap = { ...partyIdMap };
      if (!isNameAvailable) {
        // if name not available, getPartyIdInfos
        const responseArray: any = await getPartyIdsInfo(partyIdList);
        for (const response of responseArray) {
          newPartyIdMap[response.partyId] =
            (response.firstName ? `${response.firstName} ` : "") +
            (response.middleName ? `${response.middleName} ` : "") +
            (response.lastName ? response.lastName : "");
        }
      }
      partyIdList.forEach((partyId: string) => {
        filterOptions[partyId] = {
          name: newPartyIdMap[partyId] ? newPartyIdMap[partyId] : "",
          checked: false,
          isOption: true
        }
      });
    }
    if (resp.data.response?.aggregations?.tagsBucket?.buckets?.length > 0) {
      const dummyArray = [];
      for (let index = 0; index < tagBuckets.buckets.length; index++) {
        const dummyObj: any = {};
        dummyObj.name = tagBuckets.buckets[index].key;
        dummyObj.checked = false;
        dummyObj.isOption = true;
        dummyArray.push(dummyObj);
      }
      filterOptions[tagsKey] = dummyArray;
    }
    return { filterOption: filterOptions, partyIdMap: newPartyIdMap };
  }
  return { filterOption: {}, partyIdMap };
}

export function getAllDiscussions(
  loanId: string,
  loanStage: LoanStage,
  tabNumber: number,
  lastEntry: number,
  filters: FilterModel,
  searchText: string,
  haveAggregation: boolean = false
): any {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    let userFilters: FilterModel =
      state.discussionStore.loanId === loanId
        ? state.discussionStore.filters
        : filters;
    let partyIdMap: { [key: string]: string } = state?.discussionStore
      ?.partyIdMap
      ? state.discussionStore.partyIdMap
      : {};
    if (haveAggregation && tabNumber === 0) {
      // get filters,
      // call getAllDiscussions with the updated filters
      const getUserFilterResults = await getUserFilters(
        loanId,
        loanStage,
        partyIdMap
      );
      userFilters = getUserFilterResults.userFilters;
      partyIdMap = getUserFilterResults.partyIdMap;
      let selectAllUserId: string = "";
      Object.keys(userFilters[usersKey].options).forEach(
        (optionKey: string) => {
          if (userFilters[usersKey].options[optionKey].name === selectAllName) {
            selectAllUserId = optionKey;
          }
        }
      );
      let diffFilters: FilterModel =
        state.discussionStore.loanId === loanId
          ? state.discussionStore.filters
          : filters;
      if (!diffFilters[usersKey]) {
        diffFilters = getInitialFilterState();
      }
      Object.keys(diffFilters[usersKey].options).forEach((optionKey: string) => {
        if (
          diffFilters[usersKey].options[optionKey].checked &&
          userFilters[usersKey].options[optionKey]
        ) {
          userFilters[usersKey].options[optionKey].checked = true;
          if (
            diffFilters[usersKey].options[optionKey].name !== selectAllName &&
            userFilters[usersKey].options[selectAllUserId]?.checked
          ) {
            userFilters[usersKey].options[selectAllUserId].checked = false;
          }
        }
      });
      // dispatch({
      //   type: UPDATE_PARTYID_MAP,
      //   payload: {
      //     partyIdMap
      //   }
      // });
      // dispatch({
      //   type: UPDATE_FILTER,
      //   payload: {
      //     filters: userFilters
      //   }
      // });
      dispatch(updatePartyIdMap({ partyIdMap }));

      dispatch(updateFilter({ filters: userFilters }));
    }

    const url = `${getConfig().apiUrl}/search/${
      getConfig().dashboardUrl
    }/_search`;
    // const url = `${getConfig().apiUrl}/aggregate/scenarios/search`;
    const boolMustArray: any[] = [];
    let partyIdList: string[] = [];
    let tagsList: string[] = [];

    partyIdList = getFilters(userFilters)[usersKey]
      ? getFilters(userFilters)[usersKey]
      : [];
    tagsList = getTags(userFilters);
    const scenarioBoolArr = [
      {
        terms: {
          "resource.raw": ["waiver"]
        }
      },
      {
        terms: {
          "identifierName.raw": ["SCENARIO"]
        }
      },
      {
        term: {
          "waiver.attachedLoans.loanId.raw": loanId
        }
      },
      {
        term: {
          "waiver.attachedLoans.loanStage.raw": loanStage
        }
      }
    ];
    if (searchText.length > 0 && searchText.trim().length > 0) {
      boolMustArray.push(getMultiMatchBlock(searchText));
    }
    let termsOrgRawId = [];
    if (getCookie("loginFlow") === "originator") {
      let accountIds: any;
      accountIds = getCookie("org_id");
      termsOrgRawId = getTermBlock("originatorId.raw", [accountIds]);
      boolMustArray.push(getTermBlock("originatorId.raw", [accountIds]));
    } else if (!isRole(UserRole.LAT_TREASURER)) {
      let accountIds: any;
      accountIds = localStorage.getItem("account_ids");
      accountIds = JSON.parse(accountIds);
      termsOrgRawId = getTermBlock("originatorId.raw", accountIds);
      boolMustArray.push(getTermBlock("originatorId.raw", accountIds));
    }
    boolMustArray.push(getTermBlock("resource.raw", ["discussion"]));
    boolMustArray.push(getTermBlock("identifierName.raw", ["LOAN"]));
    boolMustArray.push(getTermBlock("identifierValue.raw", [loanId]));
    boolMustArray.push(
      getTermBlock("secondaryIdentifierName.raw", ["LOAN_STAGE"])
    );
    boolMustArray.push(
      getTermBlock("secondaryIdentifierValue.raw", [loanStage])
    );
    if (partyIdList.length > 0) {
      boolMustArray.push(
        getTermBlock("discussion.chatLine.partyId.raw", partyIdList)
      );
    }
    if (tagsList.length > 0) {
      boolMustArray.push(getTermBlock("discussion.tags.raw", tagsList));
    }
    switch (tabNumber) {
      case 0: {
        boolMustArray.push(
          getTermBlock("discussion.partyAccount.accountType.raw", [
            "ORIGINATOR",
            "LAT"
          ])
        );
        break;
      }
      case 1: {
        boolMustArray.push(
          getTermBlock("discussion.partyAccount.accountType.raw", [
            "ORIGINATOR"
          ])
        );
        break;
      }
      case 2: {
        boolMustArray.push(
          getTermBlock("discussion.partyAccount.accountType.raw", ["LAT"])
        );
        break;
      }
      default:
        break;
    }
    let queryObject: { [key: string]: any } = {};
    const mustArr = Object.keys(termsOrgRawId).length
      ? [
          {
            ...termsOrgRawId
          },
          {
            bool: {
              should: [
                {
                  bool: {
                    must: boolMustArray
                  }
                },
                {
                  bool: {
                    must: scenarioBoolArr
                  }
                }
              ]
            }
          }
        ]
      : [
          {
            bool: {
              should: [
                {
                  bool: {
                    must: boolMustArray
                  }
                },
                {
                  bool: {
                    must: scenarioBoolArr
                  }
                }
              ]
            }
          }
        ];
    const queryBoolObject: { [key: string]: any } = {
      query: {
        bool: {
          must: mustArr
        }
      },
      size: discussionEntriesPerPage,
      from: lastEntry,
      sort: [{ createdOn: { order: "desc" } }]
    };
    queryObject = {
      ...queryObject,
      ...queryBoolObject
    };

    try {
      dispatch(showLoader());
      const resp = await publicClient.post(url, queryObject, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      const hitsDataForScenario = resp.data.response?.hits?.hits;
      if (
        !isNullOrUndefined(hitsDataForScenario)
        // hitsDataForScenario.length
      ) {
        const filteredList = hitsDataForScenario.filter((item: any) =>
          item?._id?.includes("waiver")
        );
        dispatch(
          updateAttachedScenarioInRedux({
            attachedScenarioDetails: filteredList
          })
        );
      }
      const totalHits: number = resp.data.response?.hits?.total?.value
        ? resp.data.response.hits.total.value
        : 0;
      const hits: any[] = resp.data?.response?.hits?.hits
        ? resp.data.response.hits.hits
        : [];
      const allDiscussions: { [key: string]: DiscussionModel } = await getChats(
        loanId,
        hits,
        partyIdMap,
        true
      );

      const discussionNewState: { [key: string]: DiscussionTabModel } = {
        [tabNumber.toString()]: {
          filteredDiscussions: allDiscussions,
          loading: false,
          totalEntries: totalHits
        }
      };
      const isNotFirstPage: boolean =
        lastEntry >= discussionEntriesPerPage &&
        loanId === state.discussionStore.loanId;
      // dispatch({
      //   type: UPDATE_NEW_STORE,
      //   payload: {
      //     loanId,
      //     newState: discussionNewState,
      //     isNotFirstPage,
      //     tabNumber
      //   }
      // });
      dispatch(
        updateNewStore({
          loanId,
          newState: discussionNewState,
          isNotFirstPage,
          tabNumber
        })
      );
      if (haveAggregation) {
        // only for have aggregation, no filters and no search
        dispatch(getAllDiscussions(loanId, loanStage, 1, 0, {}, "", false));
        dispatch(getAllDiscussions(loanId, loanStage, 2, 0, {}, "", false));
      }
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
    }
  };
}
export function createCommentThread1(
  identifier: string,
  identifierID: string,
  identifierSecondary: string,
  identifierSecondaryID: string,
  title: string,
  tags?: any[]
): Promise<string> {
  return new Promise((resolve, reject) => {
    const url = `${
      getConfig().apiUrl
    }/chatservice/${identifier}/${identifierID}/chats?secondaryIdentifierName=${identifierSecondary}&secondaryIdentifierValue=${identifierSecondaryID}`;
    if (tags && tags.length > 0) {
      publicClient
        .post(url, {
          title,
          tags
        })
        .then((res: any) => {
          resolve(res.data);
        })
        .catch(reject);
    } else {
      publicClient
        .post(url, {
          title
        })
        .then((res: any) => {
          resolve(res.data);
        })
        .catch(reject);
    }
  });
}

export function createComment(
  chatId: string,
  partyId: string,
  lineText: string,
  hasDocuments: string,
  approvalType: string
): Promise<string> {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl}/chatservice/${chatId}/lines`;
    const body = {
      partyId,
      lineText,
      hasDocuments
    };
    publicClient
      .post(url, body)
      .then((res: any) => {
        resolve(res.data);
      })
      .catch(reject);
  });
}

export function getDiscussionComments(chatId: string) {
  return new Promise((resolve, reject) => {
    // dispatch({ type: TAGS_LOADING });
    const url = `${getConfig().apiUrl}/aggregate/chatservice/${chatId}/lines`;
    publicClient
      .get(url)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}

export const mapAccounts = (responseData: any) => {
  const mappedObject: any = {};
  responseData.accountMapping.forEach((accountMap: any) => {
    const mappedValue: any = {
      name: "",
      role: ""
    };
    const filteredAccounts = responseData.accounts.filter((account: any) => {
      return accountMap.accountId === account.partyId;
    });
    if (filteredAccounts.length > 0) {
      mappedValue.role =
        filteredAccounts[0].accountType === "ORIGINATOR" ? "O" : "T";
    }
    const filteredCustomers = responseData.customers.filter((customer: any) => {
      return accountMap.personId === customer.partyId;
    });
    if (filteredCustomers.length > 0) {
      mappedValue.name =
        (filteredCustomers[0].firstName
          ? `${filteredCustomers[0].firstName} `
          : "") +
        (filteredCustomers[0].middleName
          ? `${filteredCustomers[0].middleName} `
          : "") +
        (filteredCustomers[0].lastName ? filteredCustomers[0].lastName : "");
    }

    mappedObject[accountMap.personId] = mappedValue;
  });
  return populateComments(
    responseData.chatLines,
    mappedObject,
    responseData.documents ? responseData.documents : []
  );
};

export const populateComments = (
  chatLines: any,
  mappedObject: any,
  documents: any[]
) => {
  const comments: any = {};
  chatLines.forEach((chatLine: any) => {
    const comment: any = {};
    comment.chatLineSequenceId = chatLine.chatLineSequenceId;
    comment.name = mappedObject[chatLine.partyId]?.name
      ? mappedObject[chatLine.partyId].name
      : "";
    comment.type = mappedObject[chatLine.partyId]?.role
      ? mappedObject[chatLine.partyId].role
      : "T";
    comment.createdOn = chatLine.createdOn;
    comment.lineText = chatLine.lineText;
    comment.partyId = chatLine.partyId;
    comment.documents = [];
    for (const document of documents) {
      if (
        document?.secondaryIdentifierValue?.toString() ===
        chatLine.chatLineSequenceId.toString()
      ) {
        comment.documents = document.docs;
      }
    }
    comments[chatLine.chatLineSequenceId] = comment;
  });
  return comments;
};

export function getComments(chatId: string) {
  return async (dispatch: any) => {
    try {
      // dispatch({ type: TAGS_LOADING });
      const url = `${getConfig().apiUrl}/chatservice/${chatId}/lines`;
      const response = await publicClient.get(url);
      // dispatch({
      //   type: COMMENT_SUCCESS,
      //   payload: response.data
      // });
      dispatch(commentSuccess(response.data));
    } catch (err) {
      const e: any = err;
      console.error(e);
      // dispatch(actionFail(err));
      // dispatch({
      //   type: ACTION_FAIL,
      //   error: {
      //     message: e?.message
      //   }
      // });
    }
  };
}

//
// export function updateDocumentInDiscussion(
//   bucketId: string,
//   updatedFileList: any
// ): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: UPDATE_DOCS_IN_DISCUSSION,
//       payload: {
//         bucketId,
//         files: updatedFileList
//       }
//     });
//   };
// }

export function postDocument(
  chatId: string,
  chatSequenceId: string,
  fileDetail: any
) {
  return new Promise((resolve, reject) => {
    // dispatch({ type: TAGS_LOADING });
    const header = {
      headers: { "Content-Type": "text/plain" }
    };
    if (fileDetail?.length) {
      fileDetail.forEach((item: any) => (item.chatDocument = "true"));
    }
    const url = `${
      getConfig().apiUrl
    }/docs/CHAT/${chatId}/documents?secondary_identifier_name=CHAT_SEQUENCE&secondary_identifier_value=${chatSequenceId}`;
    publicClient
      .post(url, fileDetail, header)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}
