import { Theme } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

export const KeyDealMetricStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      overflow: "hidden"
    },
    // bodyWrapper: {
    //   // paddingTop: "60px",
    //   [theme.breakpoints.down("sm")]: {
    //     paddingTop: "46px"
    //   },
    //   [theme.breakpoints.down("md")]: {
    //     paddingTop: "46px"
    //   }
    // },
    containerSpace: {
      [theme.breakpoints.down("sm")]: {
        padding: "10px !important"
      }
    },
    paperHeader: {
      backgroundImage: "linear-gradient(93deg, #0fcbef 4%, #1071ee 90%)",
      boxShadow: "none",
      paddingRight: 20,
      paddingLeft: 20,
      position: "fixed",
      zIndex: 2
    },
    wrapper: {
      display: "flex"
    },
    logo: {
      // paddingTop: theme.spacing(1)
      // [theme.breakpoints.up("md")]: {
      //   maxWidth: "20%",
      //   flexBasis: "20%"
      // }
    },
    imgLogo: {
      width: "212px"
    },
    backDrop: { zIndex: 9999, backgroundColor: "rgba(65,65,105, 0.25)" },
    modal: {
      backgroundColor: "#ffffff",
      height: "92%",
      overflow: "hidden",
      width: "80%",
      marginTop: "50px",
      overflowY: "auto"
    },
    containerHeader: {
      backgroundColor: "#ffffff",
      borderBottom: "1px solid #f2f2f2",
      padding: "20px 20px 20px 20px",
      position: "fixed",
      top: "0",
      width: "80%"
    },
    closeIcon: {
      width: 20,
      height: 20,
      cursor: "pointer",
      marginTop: 5
    },
    heading: {
      fontSize: 16,
      fontWeight: 600,
      color: "#32325d"
    },
    sectionHeader: {
      padding: "16px 25px 16px 25px",
      backgroundColor: "#f6f9fc",
      marginTop: "15px"
    },
    sectionHeading: {
      fontSize: 14,
      fontWeight: 600,
      color: "#32325d"
    },
    printButton: {
      color: "#32325d !important",
      width: "83px",
      height: "28px",
      padding: "6px 13px 6px 7px",
      borderRadius: "4px",
      boxShadow: "0 3px 4px 0 rgba(0, 0, 0, 0.14)",
      border: "solid 1px #ececec",
      backgroundColor: "#fff !important"
    }
  })
);
