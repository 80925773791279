import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useDrawsStyles } from "./Draws.styles";
import {
  getAvailableLoanIds,
  getDrawDetailsbyLoanId,
  // getDrawDetailsByDrawId,
  deleteDrawById,
  // updateTableSortOptions,
  // updateTableFilters,
  // updateTableSearchConfig,
  // updateGlobalSearchValue,
  getFieldHistory,
  getProvisionHistory,
  getDrawHistory
} from "../../stores/drawRequest/drawRequest.action";
import { RootState } from "../../stores/rootReducer";

import { useNavigate, useParams } from "react-router-dom";
// import { BankDetailsWrapper } from "./BankDetailsWrapper";
import {
  ConfirmFormModal,
  DrawStage,
  // ExpandCollapse,
  SnackBarMessage
} from "@toorak/tc-common-fe-sdk";
import {
  hideLoader,
  showLoader
} from "../../stores/loaderAndException/loaderAndException.action";
import { isOrigUser } from "../../utils/AccessManagement";
import { DrawDetailsRecapInfo } from "./DrawDetailsRecapInfo";
import { DrawRequestStore } from "../../stores/drawRequest/drawRequest.interface";
import { DrawTabs } from "./DrawDataLeftPanel/DrawTabs";
import { isAMTView } from "../AssetManagement.utils";

import { snackBarDetails } from "./Constant";
import { DrawDetailsHeader } from "./DrawHeader/DrawDetailsHeader";
import { isBulkDrawViewEnabled } from "./DrawDetailsConstants";
import { DrawRightPanelWrapper } from "./DrawDataRightPanel/DrawRightPanelWrapper";
import {
  clearDrawRedux,
  setSelectedLoans,
  updateDrawExcelData,
  updateSearchValue,
  updateTableFilters,
  updateTableSearchOptions,
  updateTableSortOptions
} from "../../stores/drawRequest/drawRequest.reducer";

export const DrawDetails = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useDrawsStyles();

  // const [availableLoanIds, setAvailableLoanIds] = useState<any>([]);
  // const [isMinimizedLoanDetail, setIsMinimizedLoanDetail] = useState<boolean>(
  //   false
  // );
  // const [isMinimizedLoanEconomics, setIsMinimizedLoanEconomics] = useState<
  // boolean > false;
  // const [isMinimizedProvision, setIsMinimizedProvision] = useState<boolean>(
  //   false
  // );
  const [isSubmitClicked, setIsSubmitClicked] = useState<boolean>(false);
  const [selectedLoanId, setSelectedLoanId] = useState<string | null>(null);
  const [selectedProperty, setSelectedProperty] = useState<string[]>([]);
  const [drawObj, setDrawObj] = useState<any>({});
  const [openConfirmBox, setOpenConfirmBox] = React.useState(false);
  const [confirmationMgs, setConfirmationMgs] = useState<any>({
    text: "",
    header: ""
  });
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackBarMsg, setSnackBarMsg] = useState<any>({
    messageType: "",
    message: ""
  });
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [provisionHistory, setProvisionHistory] = useState<any>([]);
  const {
    draft,
    drawSubmitted,
    initialApproval,
    finalApproval,
    inProgress,
    WireUnsuccessful,
    DrawInQueue,
    hold
  } = DrawStage;

  const { drawId } = useParams<any>() as any;
  const isOrig = isOrigUser();
  let isPropOptionSet: boolean = false;

  let {
    drawRequestType,
    loanIds,
    drawDetails,
    tableSortConfig,
    tableSearchConfig,
    tableFilterConfig
  } = useSelector<RootState, DrawRequestStore>(
    (state) => state.drawRequestStore
  );
  const isInDraftStage = drawDetails.stage === draft;

  useEffect(() => {
    if (isAMTView()) return;
    dispatch(getAvailableLoanIds(drawRequestType !== "asset"));
  }, [drawRequestType, dispatch]);

  useEffect(() => {
    if (loanIds?.length) {
      clearFilter();
      dispatch(setSelectedLoans([]));
      // setAvailableLoanIds(loanIds);
    }
    //eslint-disable-next-line
  }, [loanIds]);

  useEffect(() => {
    if (drawDetails) {
      setDrawObj(drawDetails);
      let loanId: string = drawDetails?.primaryLoanId as string;
      setSelectedLoanId(loanId);

      drawDetails.propertyDetails &&
        setSelectedProperty(drawDetails.propertyDetails);
      if (!isOrig) {
        if (drawDetails.primaryLoanId) {
          dispatch(
            getProvisionHistory(
              drawDetails.primaryLoanId,
              provisionHistorycallBack
            )
          );
        }
      }
    }
    //eslint-disable-next-line
  }, [
    drawDetails.primaryLoanId,
    drawDetails.stage,
    drawDetails.propertyDetails
  ]);

  const provisionHistorycallBack = (data: any) => {
    setProvisionHistory(data);
  };

  const updateIsSubmitClicked = (val: boolean) => {
    setIsSubmitClicked(val);
  };

  useEffect(() => {
    if (
      props.isBulkDraw &&
      drawDetails?.primaryLoanId &&
      !drawDetails.propertyDetails &&
      isInDraftStage
    ) {
      handleLoanIdChange(drawDetails?.primaryLoanId);
      // setTimeout(() => {
      // }, 500);
    } else {
      if (!drawDetails?.primaryLoanId && !isPropOptionSet) return;
      if (isAMTView()) return;
      if (!isAMTView() && isInDraftStage) {
        dispatch(
          getDrawDetailsbyLoanId(
            drawRequestType !== "asset",
            drawDetails?.primaryLoanId,
            false
          )
        );
        // eslint-disable-next-line
        isPropOptionSet = true;
      }
    }
    //eslint-disable-next-line
  }, [drawDetails?.primaryLoanId]);

  useEffect(() => {
    let loanId: string = drawDetails?.primaryLoanId as string;
    if (!isAMTView() || !loanId) return;
    dispatch(getDrawHistory(loanId));
    // eslint-disable-next-line
  }, [drawDetails.primaryLoanId]);

  // useEffect(() => {
  //   if (!drawId) return;
  //   dispatch(getDrawDetailsByDrawId(drawId, isOrig));
  // }, [drawId, dispatch, isOrig]);

  useEffect(() => {
    if (!isInDraftStage && !isBulkDrawViewEnabled()) {
      dispatch(getFieldHistory(drawId, isOrig));
    }
    // eslint-disable-next-line
  }, [isInDraftStage, dispatch, drawId, isOrig]);

  const clearFilter = () => {
    tableFilterConfig !== undefined && dispatch(updateTableFilters(undefined));
    tableSortConfig !== undefined &&
      dispatch(updateTableSortOptions(undefined));
    tableSearchConfig !== undefined &&
      dispatch(updateTableSearchOptions(undefined));

    dispatch(updateSearchValue(""));
  };

  const updateIsEditing = (val: boolean) => {
    if (val) {
      //to get the bank details options
      dispatch(
        getDrawDetailsbyLoanId(
          drawRequestType !== "asset",
          drawDetails?.primaryLoanId,
          false
        )
      );
    }

    setIsEditing(val);
  };

  const isAmounteditable = () => {
    return isInDraftStage
      ? isInDraftStage
      : isOrig
      ? isEditing && [drawSubmitted, DrawInQueue, hold].includes(drawObj.stage)
      : isEditing &&
        [
          drawSubmitted,
          inProgress,
          initialApproval,
          DrawInQueue,
          WireUnsuccessful,
          finalApproval
        ].includes(drawObj.stage);
  };

  const goBackToLoanListPage = () => {
    if (isOrig) {
      navigate("/loans/list?tab=8");
    } else {
      navigate("/asset/home?tab=0");
    }
    dispatch(clearDrawRedux());
  };

  const handleLoanIdChange = (newVal: string | null) => {
    setSelectedLoanId(newVal);
    if (newVal) {
      dispatch(
        getDrawDetailsbyLoanId(
          drawRequestType !== "asset",
          newVal,
          true,
          drawDetails.drawAmountDetails,
          drawObj
        )
      );
    }
  };

  const handleConfirmation = () => {
    setOpenConfirmBox(false);
  };

  const deleteDraw = () => {
    dispatch(showLoader());
    deleteDrawById(drawId).then((res: any) => {
      dispatch(hideLoader());
    });
    setOpenConfirmBox(false);
    setSnackBarMsg({
      messageType: "success",
      message: "Draw is successfully deleted."
    });
    setOpenSnackBar(true);
    if (isBulkDrawViewEnabled() && isOrig) {
      const excelUploadDataInSession =
        sessionStorage.getItem("drawExcelUpload");
      if (excelUploadDataInSession) {
        const excelUploadData = JSON.parse(excelUploadDataInSession);
        const drawIndex = excelUploadData.findIndex(
          (item?: any) => item.drawId.toString() === drawId
        );
        excelUploadData.splice(drawIndex, 1);
        if (excelUploadData.length) {
          excelUploadData[0].selected = true;
          dispatch(updateDrawExcelData(excelUploadData));
          sessionStorage.setItem(
            "drawExcelUpload",
            JSON.stringify(excelUploadData)
          );
          navigate(`/drawDetails/${excelUploadData[0].drawId}`);
        } else {
          setTimeout(() => {
            goBackToLoanListPage();
          }, 1500);
        }
      }
    } else {
      setTimeout(() => {
        goBackToLoanListPage();
      }, 1500);
    }
  };

  const showDeleteDrawWarning = () => {
    setConfirmationMgs({
      text: `Are you sure you want to delete this Draw?`,
      header: `Delete Draw`
    });
    setOpenConfirmBox(true);
  };

  const showErrorMsg = (field: any) => {
    const returnJSX = (
      <div className={classes.errorMgs}>This is a mandatory field.</div>
    );
    const returnNetFundsJSX = (
      <div className={classes.errorMgs}>
        Net Funds To Borrower must be positive.
      </div>
    );
    switch (field) {
      case "selectedLoanId": {
        if (selectedLoanId === "") return returnJSX;
        return null;
      }
      case "selectedProperty": {
        if (!selectedProperty.length) return returnJSX;
        return null;
      }
      case "drawAmount": {
        if (!drawObj?.drawAmountDetails?.drawAmount) return returnJSX;
        return null;
      }
      case "wireFee": {
        if (!drawObj?.drawAmountDetails?.wireFee) return returnJSX;
        return null;
      }
      case "costOfInspection": {
        if (
          drawObj?.drawAmountDetails?.costOfInspection === null ||
          drawObj?.drawAmountDetails?.costOfInspection === undefined ||
          drawObj?.drawAmountDetails?.costOfInspection === ""
        )
          return returnJSX;
        return null;
      }
      case "titleServiceFee": {
        if (
          drawObj?.drawAmountDetails?.titleServiceFee === null ||
          drawObj?.drawAmountDetails?.titleServiceFee === undefined ||
          drawObj?.drawAmountDetails?.titleServiceFee === ""
        )
          return returnJSX;
        return null;
      }
      case "netFundsToBorrower": {
        if (drawObj?.drawAmountDetails?.netFundsToBorrower <= 0)
          return returnNetFundsJSX;
        return null;
      }
    }
  };

  const rightPanelWidth = isInDraftStage ? 12 : 8;

  return (
    <>
      <Grid item container xs={12} sm={12} md={12} xl={12}>
        <DrawDetailsHeader
          drawId={drawId}
          isOrig={isOrig}
          showDeleteDrawWarning={showDeleteDrawWarning}
          updateIsSubmitClicked={updateIsSubmitClicked}
          goBackToLoanListPage={goBackToLoanListPage}
          updateIsEditing={updateIsEditing}
          isEditing={isEditing}
          toorakLoanId={drawDetails.toorakLoanId}
        />

        {!isInDraftStage && (
          <DrawDetailsRecapInfo
            drawStage={drawObj.stage}
            netFundsToBorrower={drawObj.drawAmountDetails?.netFundsToBorrower}
            classes={classes}
            drawId={drawId}
          />
        )}
      </Grid>

      <Grid
        item
        container
        xs={rightPanelWidth}
        data-testid="draw-view-wrapper-Container"
        className={classes.insightContainer}
      >
        <DrawTabs
          isSubmitClicked={isSubmitClicked}
          selectedLoanId={selectedLoanId}
          drawObj={drawObj}
          isAmounteditable={isAmounteditable}
          isBulkDraw={props.isBulkDraw}
          showErrorMsg={showErrorMsg}
          isEditing={isEditing}
          provisionHistory={provisionHistory}
        />
      </Grid>
      {!isInDraftStage && (
        <Grid
          item
          container
          xs={4}
          data-testid="draw-view-wrapper-Container"
          // className={classes.}
        >
          <>
            <Grid item container xs={1}></Grid>

            <Grid
              item
              container
              xs={11}
              className={classes.insightContainer}
              style={{ height: 1200 }}
            >
              <DrawRightPanelWrapper drawId={drawId} />
            </Grid>
          </>
        </Grid>
      )}

      <ConfirmFormModal
        isOpen={openConfirmBox}
        handleClose={() => {
          handleConfirmation();
        }}
        confirmCondition={false}
        header={confirmationMgs.header}
        body={
          <p style={{ padding: "0px 20px 10px 20px", fontSize: "14px" }}>
            {confirmationMgs.text}
          </p>
        }
        size="xs"
        successHandler={deleteDraw}
        primaryBtnName={"Delete"}
        disableBackdropClick
        footerStyles={{
          marginTop: "0px",
          padding: "0px 20px 20px 20px"
        }}
        headerStyles={{
          paddingBottom: "10px",
          borderBottom: "1px solid #979797"
        }}
      />
      <SnackBarMessage
        open={openSnackBar}
        verticalPlacement={snackBarDetails.verticalPlacement}
        horizontalPlacement={snackBarDetails.horizontalPlacement}
        autoHideDuration={snackBarDetails.autoHideDuration}
        handleClose={() => setOpenSnackBar(false)}
        messageType={snackBarMsg.messageType}
        message={snackBarMsg.message}
      />
    </>
  );
};
