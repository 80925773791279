/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { produce } from "immer";
// import {
//   GET_VARIATION_OF_LOAN_PURCHASED,
//   GET_PERCENTILE_OF_LOAN_TYPES,
//   GET_LOAN_PIPELINE_DATA,
//   GET_VARIATION_OF_LOAN_PURCHASED_DAY_WISE,
//   GET_ALL_USER_NAME,
//   GET_NOTIFICATION,
//   SET_FILTERS,
//   UPDATE_FROM_INDEX,
//   GET_ALL_USER_NAME_GRAPH,
//   UPDATE_TAB,
//   GET_ALL_UNREAD_COUNT,
//   GET_UNREAD_COUNT_NOTIFICATION,
//   UPDATE_NOT_INDEX,
//   UPDATE_NOT_CLICK,
//   UPDATE_VIEW_DETAILS_PATH,
//   GET_UNREAD_COUNT_WAIVER_CONDITION,
//   SELECT_ALL_LOAN_STAGE,
//   RESET_lOAN_STAGE_AND_STATUS,
//   SET_LOAN_TYPE_FILTERS,
//   SET_ORG_PARTY_ID_FILTERS,
//   SET_LOAN_STAGE_FILTERS,
//   SET_WAIVER_STATUS_FILTERS,
//   SET_WAIVER_TYPE_FILTERS,
//   SET_INQUERY_STATUS_FILTERS,
//   SET_CONDITION_STATUS_FILTERS,
//   SET_SEARCH_DATA_FILTERS,
//   SET_COMM_LIST_DATA,
//   SET_COMM_TOTAL_COUNT,
//   SET_COMM_FILTER_COUNTS,
//   SET_ORG_CUSTOMER_DATA,
//   DISABLE_DB_RESET_FILTER,
//   SET_LOAN_ID,
//   SET_TRADE_ID,
//   IS_FULLSCREEN_COMMUNICATION_ENABLED,
//   SET_ALL_CREATEDBY,
//   SET_CREATEDBY_FILTERS,
//   SET_WAIVER_MAPPED_EXCEPTIONS,
//   SET_LOANID_FILTERS,
//   SET_TRADEID_FILTERS,
//   ADD_RESPONSE_TO_COMMLIST_ARRAY,
//   DELETE_EXCEPTION_RESPONSE,
//   UPDATE_FULLSCREEN_UPDATED_FLAG,
//   SET_SPOCID_FILTERS,
//   SET_SPOC_INFO
// } from "./dashboard.action";
import {
  groupByMonth,
  groupByLoan,
  groupByDefaultData,
  groupByUserNames,
  groupByValue,
  getALlOriginatorIdsLoanPurchased,
  getUnReadNot,
  getUnReadNotCount
} from "../../utils/utils";
import { tabsType } from "../../create-loan/dashboard/dashboardFilter/constants";
import { ObjectType } from "../../masterView/common";

export interface DashboardResultStore {
  loanPurchased: any;
  percentileOfLoans: any;
  loanPipelineData: any;
  allCommunicationData: any;
  allExceptionEnquiryData: any;
  allWaiverData: any;
  allDiscussionData: any;
  loanPurchasedDayWise: any;
  orgloanPurchasedDayWise: any;
  originatorIds: any;
  originatorIdsLoanPurchased: any;
  userNames: any;
  notifications: any;
  loanPurchasedFilteredData: any;
  listSize: any;
  loanTypeSelected: any;
  loanIdSelected: string[];
  tradeIdSelected: string[];
  spocIdsSelected: string[];
  orgPartyIdSelected: string[];
  createdBySelected: string[];
  searchData: any;
  aggregations: any;
  disable: boolean;
  userNamesLoan: any;
  afterScroll: any;
  unReadCount: number; // unread notification count
  bellNotifications: any;
  selectedTab: any;
  unreadCount: any; // discussion unread comments count
  notificationCurrentSize: any;
  disableNotScroll: boolean;
  notificationClicked: boolean;
  viewDetailsFromDashboard: boolean;
  countData: any;
  loanStageSelected: Array<string>;
  selectedWaiverStatus: Array<string>;
  selectedWaiverType: Array<string>;
  selectedInquiryStatus: Array<string>;
  selectedConditionStatus: Array<string>;
  selectedLoanIds: Array<string>;
  filterFromDiscussion: any;
  commListData: any[];
  commSearchtotalCount: number;
  commAllCount: number;
  commWaiverCount: number;
  commCondtionCount: number;
  commDiscussionCount: number;
  orgIdsCustomerObj: any;
  disableDBReset: boolean;
  isFullScreenCommEnabled: boolean;
  fullScreenStatusUpdated: boolean;
  createdBy: any;
  waiverMappedExceptions: any;
  loanId: any;
  tradeIdArray: any;
  spocPartyInfo: Array<ObjectType>;
}
const initialState: DashboardResultStore = {
  loanPurchased: [],
  percentileOfLoans: [],
  loanPipelineData: [],
  allCommunicationData: [],
  allExceptionEnquiryData: [],
  allWaiverData: [],
  allDiscussionData: [],
  loanPurchasedDayWise: [],
  originatorIds: [],
  userNames: [],
  notifications: [],
  orgloanPurchasedDayWise: [],
  loanPurchasedFilteredData: [],
  listSize: 0,
  loanTypeSelected: [],
  loanIdSelected: [],
  tradeIdSelected: [],
  spocIdsSelected: [],
  orgPartyIdSelected: [],
  createdBySelected: [],
  searchData: "",
  aggregations: [],
  disable: false,
  originatorIdsLoanPurchased: [],
  userNamesLoan: [],
  afterScroll: false,
  unReadCount: 0,
  bellNotifications: [],
  selectedTab: "",
  unreadCount: [],
  notificationCurrentSize: 0,
  disableNotScroll: false,
  notificationClicked: false,
  viewDetailsFromDashboard: false,
  countData: [],
  loanStageSelected: [],
  selectedWaiverStatus: [],
  selectedWaiverType: [],
  selectedInquiryStatus: [],
  selectedConditionStatus: [],
  selectedLoanIds: [],
  filterFromDiscussion: {},
  commListData: [],
  commSearchtotalCount: 0,
  commAllCount: 0,
  commWaiverCount: 0,
  commCondtionCount: 0,
  commDiscussionCount: 0,
  orgIdsCustomerObj: {},
  disableDBReset: true,
  isFullScreenCommEnabled: false,
  createdBy: [],
  waiverMappedExceptions: [],
  fullScreenStatusUpdated: false,
  loanId: [],
  tradeIdArray: [],
  spocPartyInfo: []
};

const dashboardResultSlice = createSlice({
  name: "dashboardResult",
  initialState,
  reducers: {
    setOrgCustomerData(state, action: PayloadAction<{ data: any }>) {
      state.orgIdsCustomerObj = action.payload;
    },
    setLoanIds(state, action: PayloadAction<any>) {
      state.loanId = action.payload;
    },

    setSPOCInfo(state, action: PayloadAction<{ data: any }>) {
      state.spocPartyInfo = action.payload.data;
    },

    disableDBReset(state, action: PayloadAction<boolean>) {
      state.disableDBReset = action.payload;
    },

    setTradeId(state, action: PayloadAction<{ data: any[] }>) {
      state.tradeIdArray = action.payload.data;
    },

    setCommFilterCounts(
      state,
      action: PayloadAction<{
        waiverCount: number;
        condtionCount: number;
        discussionCount: number;
      }>
    ) {
      const { waiverCount, condtionCount, discussionCount } = action.payload;
      state.commWaiverCount = waiverCount || 0;
      state.commCondtionCount = condtionCount || 0;
      state.commDiscussionCount = discussionCount || 0;
      state.commAllCount =
        state.commWaiverCount +
        state.commCondtionCount +
        state.commDiscussionCount;
    },
    setCommTotalCount(state, action: PayloadAction<number>) {
      state.commSearchtotalCount = action.payload;
    },
    setWaiverMappedExceptions(state, action: PayloadAction<{ data: any[] }>) {
      const waiverArr = action.payload.data.map(
        (item: any) => item._source?.waiver
      );
      state.waiverMappedExceptions = waiverArr;
    },
    setCommListData(state, action: PayloadAction<{ dbfilterListData: any[] }>) {
      state.commListData = action.payload.dbfilterListData;
    },
    setVariationOfLoanPurchased(
      state,
      action: PayloadAction<{ loanPurchased: any }>
    ) {
      let { loanPurchased } = action.payload;
      loanPurchased = groupByMonth(loanPurchased);
      state.loanPurchased = loanPurchased;
    },
    setPercentileOfLoanTypes(
      state,
      action: PayloadAction<{ percentileOfLoans: any }>
    ) {
      let { percentileOfLoans } = action.payload;
      percentileOfLoans = groupByLoan(percentileOfLoans);
      state.percentileOfLoans = percentileOfLoans;
    },
    setLoanPipelineData(
      state,
      action: PayloadAction<{ loanPipelineData: any }>
    ) {
      let { loanPipelineData } = action.payload;
      loanPipelineData = groupByDefaultData(loanPipelineData);
      state.loanPipelineData = loanPipelineData;
    },
    setVariationOfLoanPurchasedDayWise(
      state,
      action: PayloadAction<{
        loanPurchasedDayWise: any;
        originatorIdData: any;
        value: string;
      }>
    ) {
      let { loanPurchasedDayWise, originatorIdData, value } = action.payload;
      const originatorIdsLoanPurchased =
        getALlOriginatorIdsLoanPurchased(originatorIdData);

      if (value === "Number") {
        loanPurchasedDayWise = groupByValue(loanPurchasedDayWise, value);
      } else {
        loanPurchasedDayWise = groupByMonth(loanPurchasedDayWise);
      }

      loanPurchasedDayWise.selectedValue = value;

      state.loanPurchasedDayWise = loanPurchasedDayWise;
      state.originatorIdsLoanPurchased = originatorIdsLoanPurchased;
    },
    setAllUserName(state, action: PayloadAction<{ userNames: any }>) {
      let { userNames } = action.payload;
      userNames = groupByUserNames(userNames);
      state.userNames = userNames;
    },
    getUnreadCountWaiverCondition(
      state,
      action: PayloadAction<{ countData: any }>
    ) {
      let { countData } = action.payload;
      countData = getUnReadNotCount(countData);
      state.countData = countData;
    },
    setAllUserNameGraph(state, action: PayloadAction<{ userNamesLoan: any }>) {
      const { userNamesLoan } = action.payload;
      state.userNamesLoan = userNamesLoan;
    },
    setNotification(state, action: PayloadAction<{ notifications: any }>) {
      const { notifications } = action.payload;
      let bellNotifications: any = [];
      bellNotifications = getUnReadNot(notifications);
      state.notifications = notifications;
      state.bellNotifications = bellNotifications;
      state.disableNotScroll = notifications.length < 20;
    },
    setUnreadCountNotification(
      state,
      action: PayloadAction<{ count: number }>
    ) {
      const { count } = action.payload;
      state.unReadCount = count;
    },
    setFilters(state, action: PayloadAction<any>) {
      const {
        loanTypeSelected,
        orgPartyIdSelected,
        createdBySelected,
        loanStageSelected,
        waiverStatusSelected,
        waiverTypeSelected,
        inquiryStatusSelected,
        conditionStatusSelected,
        loanIdSelected,
        tradeIdSelected,
        spocIdsSelected,
        searchedData
      } = action.payload;

      state.loanTypeSelected = loanTypeSelected;
      state.orgPartyIdSelected = orgPartyIdSelected;
      state.createdBySelected = createdBySelected;
      state.loanIdSelected = loanIdSelected;
      state.tradeIdSelected = tradeIdSelected;
      state.spocIdsSelected = spocIdsSelected;
      state.loanStageSelected = loanStageSelected;
      state.selectedWaiverStatus = waiverStatusSelected;
      state.selectedWaiverType = waiverTypeSelected;
      state.selectedInquiryStatus = inquiryStatusSelected;
      state.selectedConditionStatus = conditionStatusSelected;
      state.searchData = searchedData;
    },
    setLoanTypeFilters(state, action: PayloadAction<string[]>) {
      state.loanTypeSelected = action.payload;
    },
    setOrgPartyIdFilters(state, action: PayloadAction<string[]>) {
      state.orgPartyIdSelected = action.payload;
    },
    setCreatedByFilters(state, action: PayloadAction<string[]>) {
      state.createdBySelected = action.payload;
    },
    setLoanIdFilters(state, action: PayloadAction<string[]>) {
      state.loanIdSelected = action.payload;
    },
    updateTradeIdFilter(state, action: PayloadAction<string[]>) {
      state.tradeIdSelected = action.payload;
    },
    updateSPOCIdFilter(state, action: PayloadAction<string[]>) {
      state.spocIdsSelected = action.payload;
    },
    updateLoanStageFilter(state, action: PayloadAction<string[]>) {
      state.loanStageSelected = action.payload;
    },
    updateWaiverStatusFilter(state, action: PayloadAction<string[]>) {
      state.selectedWaiverStatus = action.payload;
    },
    updateWaiverTypeFilter(state, action: PayloadAction<string[]>) {
      state.selectedWaiverType = action.payload;
    },
    setInquiryStatusFilters(state, action: PayloadAction<string[]>) {
      state.selectedInquiryStatus = action.payload;
    },
    setConditionStatusFilters(state, action: PayloadAction<string[]>) {
      state.selectedConditionStatus = action.payload;
    },
    setSearchDataFilters(state, action: PayloadAction<string>) {
      state.searchData = action.payload;
    },
    updateFromIndex(state, action: PayloadAction<number>) {
      state.listSize = action.payload + 20;
    },
    updateNotIndex(state, action: PayloadAction<number>) {
      state.notificationCurrentSize = action.payload + 20;
    },
    notificationClickedUpdate(state, action: PayloadAction<boolean>) {
      state.notificationClicked = action.payload;
    },
    updateViewDetailsPath(state, action: PayloadAction<boolean>) {
      state.viewDetailsFromDashboard = action.payload;
    },
    setAllUnreadCount(state, action: PayloadAction<{ unreadCount: number }>) {
      state.unreadCount = action.payload.unreadCount;
    },
    updateSelectedTab(state, action: PayloadAction<number>) {
      switch (action.payload) {
        case 0:
          state.selectedTab = "";
          break;
        case 1:
          state.selectedTab = tabsType.waiver;
          break;
        case 2:
          state.selectedTab = tabsType.exception;
          break;
        case 3:
          state.selectedTab = tabsType.discussion;
          break;
        default:
          state.selectedTab = "";
      }
    },
    selectAllLoanStage(
      state,
      action: PayloadAction<{ isChecked: boolean; allLoanStages: any[] }>
    ) {
      if (action.payload.isChecked) {
        state.loanStageSelected = action.payload.allLoanStages;
      } else {
        state.loanStageSelected = [];
      }
    },
    resetLoanStageAndStatus(state) {
      state.loanStageSelected = [];
      state.selectedWaiverStatus = [];
      state.selectedWaiverType = [];
      state.selectedInquiryStatus = [];
      state.selectedConditionStatus = [];
    },
    handleFullScreenCommunication(state) {
      state.isFullScreenCommEnabled = !state.isFullScreenCommEnabled;
      state.fullScreenStatusUpdated = true;
    },
    updateFullScreenUpdatedFlag(state, action: PayloadAction<boolean>) {
      state.fullScreenStatusUpdated = action.payload;
    },
    deleteExceptionResponse(
      state,
      action: PayloadAction<{ chatSequenceId: any; exceptionId: any }>
    ) {
      const { chatSequenceId, exceptionId } = action.payload;
      let arr = [...state?.commListData];
      let index = state.commListData.findIndex(
        (item: any) =>
          (item?._source.resource === "exception" &&
            item?._source?.exception?.exceptionId === exceptionId) ||
          (item?._source.resource === "waiver" &&
            item?._source?.waiver?.waiverType?.toLowerCase() === "exception" &&
            item?._source?.waiver?.waiverKeys?.[0] === exceptionId)
      );
      const chatSequeneceIdIndex = arr[index]._source.responses.findIndex(
        (item?: any) => item.chatSequenceId === chatSequenceId
      );

      let duplicateArr = JSON.parse(JSON.stringify(arr));
      duplicateArr[index]._source.responses.splice(chatSequeneceIdIndex, 1);
      state.commListData = [...duplicateArr];
    },
    addResponseToCommListArray(
      state,
      action: PayloadAction<{ data: any; id: any }>
    ) {
      const { data, id } = action.payload;
      let arr = state?.commListData;
      if (arr?.length) {
        let index = state.commListData.findIndex((item: any) =>
          item?._source.resource === "exception"
            ? item?._source?.exception?.exceptionId === id
            : item?._source?.resource === "waiver" &&
              item?._source?.waiver?.waiverType?.toLowerCase() === "exception"
            ? item?._source?.waiver?.waiverKeys?.[0] === id
            : item?._source?.waiver?.waiverId === id
        );
        let newArr = JSON.parse(JSON.stringify(state.commListData));
        if (data?.exceptions?.response && newArr?.[index]?._source) {
          newArr[index]._source.responses = data.exceptions.response;
          newArr[index]._source.exception = { ...data.exceptions };
          let newResp = newArr[index]._source.responses.map((item: any) => {
            let chatLiness = data.chatLines.map((items: any) => {
              if (items[item.chatSequenceId]) return items[item.chatSequenceId];
            });
            chatLiness = chatLiness.filter((chat: any) => chat !== undefined);
            item.chatLines = chatLiness[0];
            item.accounts = data.accounts;
            item.customers = data.customers;
            item.accountMapping = data.accountMapping;

            return item;
          });

          newArr[index]._source.responses = newResp;
        }
        if (data?.waiverData?.waivers) {
          let waivers = data.waiverData.waivers.map(
            (waiver: any, index: any) => {
              waiver.documents =
                data.waiverData.documents[index] &&
                data.waiverData.documents[index].primaryIdentifierValue ===
                  waiver.waiverId
                  ? data.waiverData.documents[index]?.docs
                  : [];
              return waiver;
            }
          );
          let newExp = {
            waivers: [...waivers],
            customers: data.waiverData.customers,
            accountMapping: data.waiverData.accountMapping,
            accounts: data.waiverData.accounts,
            documents: data.waiverData.documents,
            properties: data.waiverData.properties
          };

          newArr[index]._source.waiverResult = newExp;
          newArr[index]._source.waivers = [...waivers];
        }

        state.commListData[index]._source = newArr[index]._source;
      }
    },
    setAllCreatedBy(state, action: PayloadAction<{ data: any }>) {
      state.createdBy = action.payload;
    }
  }
});

export const {
  disableDBReset,
  setOrgCustomerData,
  setLoanIds,
  setTradeId,
  setSPOCInfo,
  setCommFilterCounts,
  setCommTotalCount,
  setWaiverMappedExceptions,
  setCommListData,
  setVariationOfLoanPurchased,
  setPercentileOfLoanTypes,
  setLoanPipelineData,
  setVariationOfLoanPurchasedDayWise,
  setAllUserName,
  getUnreadCountWaiverCondition,
  setAllUserNameGraph,
  setNotification,
  setUnreadCountNotification,
  setFilters,
  setLoanTypeFilters,
  setOrgPartyIdFilters,
  setCreatedByFilters,
  setLoanIdFilters,
  updateTradeIdFilter,
  updateSPOCIdFilter,
  updateLoanStageFilter,
  updateWaiverStatusFilter,
  updateWaiverTypeFilter,
  setInquiryStatusFilters,
  setConditionStatusFilters,
  setSearchDataFilters,
  updateFromIndex,
  updateNotIndex,
  notificationClickedUpdate,
  updateViewDetailsPath,
  setAllUnreadCount,
  updateSelectedTab,
  selectAllLoanStage,
  resetLoanStageAndStatus,
  handleFullScreenCommunication,
  updateFullScreenUpdatedFlag,
  deleteExceptionResponse,
  addResponseToCommListArray,
  setAllCreatedBy
} = dashboardResultSlice.actions;

export const DashboardResultReducer = dashboardResultSlice.reducer;

// export const DashboardResultReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case DISABLE_DB_RESET_FILTER: {
//       const nextState = produce(state, (draftState) => {
//         draftState.disableDBReset = action.payload.disableDBReset;
//       });
//       return nextState;
//     }
//     case SET_ORG_CUSTOMER_DATA: {
//       const nextState = produce(state, (draftState) => {
//         draftState.orgIdsCustomerObj = action.payload.data;
//       });
//       return nextState;
//     }
//     case SET_LOAN_ID: {
//       const nextState = produce(state, (draftState) => {
//         draftState.loanId = action.payload.data;
//       });
//       return nextState;
//     }
//     case SET_TRADE_ID: {
//       const nextState = produce(state, (draftState) => {
//         draftState.tradeIdArray = action.payload.data;
//       });
//       return nextState;
//     }
//     case SET_SPOC_INFO: {
//       const nextState = produce(state, (draftState) => {
//         draftState.spocPartyInfo = action.payload.data;
//       });
//       return nextState;
//     }
//     case SET_COMM_FILTER_COUNTS: {
//       const nextState = produce(state, (draftState) => {
//         const { waiverCount, condtionCount, discussionCount } = action.payload;
//         draftState.commWaiverCount = waiverCount || 0;
//         draftState.commCondtionCount = condtionCount || 0;
//         draftState.commDiscussionCount = discussionCount || 0;
//         draftState.commAllCount =
//           draftState.commWaiverCount +
//           draftState.commCondtionCount +
//           draftState.commDiscussionCount;
//       });
//       return nextState;
//     }
//     case SET_COMM_TOTAL_COUNT: {
//       const nextState = produce(state, (draftState) => {
//         draftState.commSearchtotalCount = action.payload.count;
//       });
//       return nextState;
//     }
//     case SET_WAIVER_MAPPED_EXCEPTIONS: {
//       const nextState = produce(state, (draftState) => {
//         const waiverArr = action.payload.map((item: any) => {
//           return item._source?.waiver;
//         });
//         draftState.waiverMappedExceptions = waiverArr;
//       });
//       return nextState;
//     }

//     case SET_COMM_LIST_DATA: {
//       const nextState = produce(state, (draftState) => {
//         draftState.commListData = action.payload.dbfilterListData;
//       });
//       return nextState;
//     }
//     case GET_VARIATION_OF_LOAN_PURCHASED: {
//       let { loanPurchased } = action.payload;
//       loanPurchased = groupByMonth(loanPurchased);
//       const nextState = produce(state, (draftState) => {
//         draftState.loanPurchased = loanPurchased;
//       });
//       return nextState;
//     }
//     case GET_PERCENTILE_OF_LOAN_TYPES: {
//       let { percentileOfLoans } = action.payload;
//       percentileOfLoans = groupByLoan(percentileOfLoans);
//       const nextState = produce(state, (draftState) => {
//         draftState.percentileOfLoans = percentileOfLoans;
//       });
//       return nextState;
//     }
//     case GET_LOAN_PIPELINE_DATA: {
//       let { loanPipelineData } = action.payload;
//       loanPipelineData = groupByDefaultData(loanPipelineData);
//       const nextState = produce(state, (draftState) => {
//         draftState.loanPipelineData = loanPipelineData;
//       });
//       return nextState;
//     }
//     case GET_VARIATION_OF_LOAN_PURCHASED_DAY_WISE: {
//       let { loanPurchasedDayWise } = action.payload;
//       const { originatorIdData } = action.payload;
//       const { value } = action.payload;
//       const originatorIdsLoanPurchased =
//         getALlOriginatorIdsLoanPurchased(originatorIdData);
//       if (value === "Number") {
//         loanPurchasedDayWise = groupByValue(loanPurchasedDayWise, value);
//       } else {
//         loanPurchasedDayWise = groupByMonth(loanPurchasedDayWise);
//       }
//       loanPurchasedDayWise.selectedValue = value;
//       const nextState = produce(state, (draftState) => {
//         draftState.loanPurchasedDayWise = loanPurchasedDayWise;
//         draftState.originatorIdsLoanPurchased = originatorIdsLoanPurchased;
//       });
//       return nextState;
//     }
//     case GET_ALL_USER_NAME: {
//       let { userNames } = action.payload;
//       userNames = groupByUserNames(userNames);
//       const nextState = produce(state, (draftState) => {
//         draftState.userNames = userNames;
//       });
//       return nextState;
//     }
//     case GET_UNREAD_COUNT_WAIVER_CONDITION: {
//       let countData = action.payload;
//       countData = getUnReadNotCount(countData);
//       const nextState = produce(state, (draftState) => {
//         draftState.countData = countData;
//       });
//       return nextState;
//     }
//     case GET_ALL_USER_NAME_GRAPH: {
//       const { userNamesLoan } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         draftState.userNamesLoan = userNamesLoan;
//       });
//       return nextState;
//     }
//     case GET_NOTIFICATION: {
//       const { notifications } = action.payload;
//       // if (state.notificationCurrentSize > 0 && afterScroll) {
//       //   notifications = state.notifications.concat(notifications);
//       // }
//       let bellNotifications: any = [];
//       bellNotifications = getUnReadNot(notifications);
//       const nextState = produce(state, (draftState) => {
//         draftState.notifications = notifications;
//         draftState.bellNotifications = bellNotifications;
//         draftState.disableNotScroll = notifications.length < 20;
//       });
//       return nextState;
//     }
//     case GET_UNREAD_COUNT_NOTIFICATION: {
//       const { count } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         draftState.unReadCount = count;
//       });
//       return nextState;
//     }
//     case SET_FILTERS: {
//       const {
//         loanTypeSelected,
//         orgPartyIdSelected,
//         createdBySelected,
//         loanStageSelected,
//         waiverStatusSelected,
//         waiverTypeSelected,
//         inquiryStatusSelected,
//         conditionStatusSelected,
//         loanIdSelected,
//         tradeIdSelected,
//         spocIdsSelected,
//         searchedData
//       } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         draftState.loanTypeSelected = loanTypeSelected;
//         draftState.orgPartyIdSelected = orgPartyIdSelected;
//         draftState.createdBySelected = createdBySelected;
//         draftState.loanIdSelected = loanIdSelected;
//         draftState.tradeIdSelected = tradeIdSelected;
//         draftState.spocIdsSelected = spocIdsSelected;
//         draftState.loanStageSelected = loanStageSelected;
//         draftState.selectedWaiverStatus = waiverStatusSelected;
//         draftState.selectedWaiverType = waiverTypeSelected;
//         draftState.selectedInquiryStatus = inquiryStatusSelected;
//         draftState.selectedConditionStatus = conditionStatusSelected;
//         draftState.searchData = searchedData;
//       });
//       return nextState;
//     }
//     case SET_LOAN_TYPE_FILTERS: {
//       const nextState = produce(state, (draftState) => {
//         draftState.loanTypeSelected = action.payload.loanTypeSelected;
//       });
//       return nextState;
//     }
//     case SET_ORG_PARTY_ID_FILTERS: {
//       const nextState = produce(state, (draftState) => {
//         draftState.orgPartyIdSelected = action.payload.orgPartyIdSelected;
//       });
//       return nextState;
//     }
//     case SET_CREATEDBY_FILTERS: {
//       const nextState = produce(state, (draftState) => {
//         draftState.createdBySelected = action.payload.createdBySelected;
//       });
//       return nextState;
//     }

//     case SET_LOANID_FILTERS: {
//       const nextState = produce(state, (draftState) => {
//         draftState.loanIdSelected = action.payload.loanIdSelected;
//       });
//       return nextState;
//     }

//     case SET_TRADEID_FILTERS: {
//       const nextState = produce(state, (draftState) => {
//         draftState.tradeIdSelected = action.payload.tradeIdSelected;
//       });
//       return nextState;
//     }

//     case SET_SPOCID_FILTERS: {
//       const nextState = produce(state, (draftState) => {
//         draftState.spocIdsSelected = action.payload.spocIdsSelected;
//       });
//       return nextState;
//     }

//     case SET_LOAN_STAGE_FILTERS: {
//       const nextState = produce(state, (draftState) => {
//         draftState.loanStageSelected = action.payload.loanStageSelected;
//       });
//       return nextState;
//     }
//     case SET_WAIVER_STATUS_FILTERS: {
//       const nextState = produce(state, (draftState) => {
//         draftState.selectedWaiverStatus = action.payload.waiverStatusSelected;
//       });
//       return nextState;
//     }
//     case SET_WAIVER_TYPE_FILTERS: {
//       const nextState = produce(state, (draftState) => {
//         draftState.selectedWaiverType = action.payload.waiverTypeSelected;
//       });
//       return nextState;
//     }

//     case SET_INQUERY_STATUS_FILTERS: {
//       const nextState = produce(state, (draftState) => {
//         draftState.selectedInquiryStatus = action.payload.inquiryStatusSelected;
//       });
//       return nextState;
//     }
//     case SET_CONDITION_STATUS_FILTERS: {
//       const nextState = produce(state, (draftState) => {
//         draftState.selectedConditionStatus =
//           action.payload.conditionStatusSelected;
//       });
//       return nextState;
//     }
//     case SET_SEARCH_DATA_FILTERS: {
//       const nextState = produce(state, (draftState) => {
//         draftState.searchData = action.payload.searchedData;
//       });
//       return nextState;
//     }
//     case UPDATE_FROM_INDEX: {
//       const listSize = action.payload;
//       const nextState = produce(state, (draftState) => {
//         draftState.listSize = listSize + 20;
//       });
//       return nextState;
//     }
//     case UPDATE_NOT_INDEX: {
//       const notificationCurrentSize = action.payload;
//       const nextState = produce(state, (draftState) => {
//         draftState.notificationCurrentSize = notificationCurrentSize + 20;
//       });
//       return nextState;
//     }
//     case UPDATE_NOT_CLICK: {
//       const value = action.payload;
//       const nextState = produce(state, (draftState) => {
//         draftState.notificationClicked = value;
//       });
//       return nextState;
//     }
//     case UPDATE_VIEW_DETAILS_PATH: {
//       const value = action.payload;
//       const nextState = produce(state, (draftState) => {
//         draftState.viewDetailsFromDashboard = value;
//       });
//       return nextState;
//     }
//     case GET_ALL_UNREAD_COUNT: {
//       const { unreadCount } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         draftState.unreadCount = unreadCount;
//       });
//       return nextState;
//     }
//     case UPDATE_TAB: {
//       const nextState = produce(state, (draftState) => {
//         switch (action.payload) {
//           case 0:
//             draftState.selectedTab = "";
//             break;
//           case 1:
//             draftState.selectedTab = tabsType.waiver;
//             break;
//           case 2:
//             draftState.selectedTab = tabsType.exception;
//             break;
//           case 3:
//             draftState.selectedTab = tabsType.discussion;
//             break;
//           default:
//             draftState.selectedTab = "";
//         }
//       });
//       return nextState;
//     }
//     case SELECT_ALL_LOAN_STAGE: {
//       const nextState = produce(state, (draftState) => {
//         if (action.isChecked) {
//           draftState.loanStageSelected = action.allLoanStages;
//         } else {
//           draftState.loanStageSelected = [];
//         }
//       });
//       return nextState;
//     }
//     case RESET_lOAN_STAGE_AND_STATUS: {
//       const nextState = produce(state, (draftState) => {
//         draftState.loanStageSelected = [];
//         draftState.selectedWaiverStatus = [];
//         draftState.selectedWaiverType = [];
//         draftState.selectedInquiryStatus = [];
//         draftState.selectedConditionStatus = [];
//       });
//       return nextState;
//     }
//     case IS_FULLSCREEN_COMMUNICATION_ENABLED: {
//       // const { isFullScreenCommEnabled } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         draftState.isFullScreenCommEnabled = !state.isFullScreenCommEnabled;
//         draftState.fullScreenStatusUpdated = true;
//       });
//       return nextState;
//     }
//     case UPDATE_FULLSCREEN_UPDATED_FLAG: {
//       const nextState = produce(state, (draftState) => {
//         draftState.fullScreenStatusUpdated = action.payload;
//       });
//       return nextState;
//     }

//     case DELETE_EXCEPTION_RESPONSE: {
//       const { chatSequenceId, exceptionId } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         let arr = [...state?.commListData];
//         let index = state.commListData.findIndex(
//           (item: any) =>
//             (item?._source.resource === "exception" &&
//               item?._source?.exception?.exceptionId === exceptionId) ||
//             (item?._source.resource === "waiver" &&
//               item?._source?.waiver?.waiverType?.toLowerCase() ===
//                 "exception" &&
//               item?._source?.waiver?.waiverKeys?.[0] === exceptionId)
//         );
//         const chatSequeneceIdIndex = arr[index]._source.responses.findIndex(
//           (item?: any) => item.chatSequenceId === chatSequenceId
//         );

//         let duplicateArr = JSON.parse(JSON.stringify(arr));
//         duplicateArr[index]._source.responses.splice(chatSequeneceIdIndex, 1);
//         draftState.commListData = [...duplicateArr];
//       });
//       return nextState;
//     }

//     case ADD_RESPONSE_TO_COMMLIST_ARRAY: {
//       const nextState = produce(state, (draftState) => {
//         const { data, id } = action.payload;
//         let arr = state?.commListData;
//         if (arr?.length) {
//           let index = state.commListData.findIndex((item: any) =>
//             item?._source.resource === "exception"
//               ? item?._source?.exception?.exceptionId === id
//               : item?._source?.resource === "waiver" &&
//                 item?._source?.waiver?.waiverType?.toLowerCase() === "exception"
//               ? item?._source?.waiver?.waiverKeys?.[0] === id
//               : item?._source?.waiver?.waiverId === id
//           );
//           let newArr = JSON.parse(JSON.stringify(state.commListData));
//           if (data?.exceptions?.response && newArr?.[index]?._source) {
//             newArr[index]._source.responses = data.exceptions.response;
//             newArr[index]._source.exception = { ...data.exceptions };
//             let newResp = newArr[index]._source.responses.map((item: any) => {
//               let chatLiness = data.chatLines.map((items: any) => {
//                 if (items[item.chatSequenceId])
//                   return items[item.chatSequenceId];
//               });
//               chatLiness = chatLiness.filter((chat: any) => chat !== undefined);
//               item.chatLines = chatLiness[0];
//               item.accounts = data.accounts;
//               item.customers = data.customers;
//               item.accountMapping = data.accountMapping;

//               return item;
//             });

//             newArr[index]._source.responses = newResp;
//           }
//           if (data?.waiverData?.waivers) {
//             let waivers = data.waiverData.waivers.map(
//               (waiver: any, index: any) => {
//                 waiver.documents =
//                   data.waiverData.documents[index] &&
//                   data.waiverData.documents[index].primaryIdentifierValue ===
//                     waiver.waiverId
//                     ? data.waiverData.documents[index]?.docs
//                     : [];
//                 return waiver;
//               }
//             );
//             let newExp = {
//               waivers: [...waivers],
//               customers: data.waiverData.customers,
//               accountMapping: data.waiverData.accountMapping,
//               accounts: data.waiverData.accounts,
//               documents: data.waiverData.documents,
//               properties: data.waiverData.properties
//             };

//             newArr[index]._source.waiverResult = newExp;
//             newArr[index]._source.waivers = [...waivers];
//           }

//           draftState.commListData[index]._source = newArr[index]._source;
//         }
//       });
//       return nextState;
//     }

//     case SET_ALL_CREATEDBY: {
//       const { data } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         draftState.createdBy = data;
//       });
//       return nextState;
//     }
//     default:
//       return state;
//   }
// };
