import React from "react";
import { TextField, Theme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "1px 14px 1px 0px",
    "&$focused $notchedOutline": {
      borderColor: "#5e72e4 !important",
      borderWidth: 2
    }
  }
}));

export const CustomizedTextField = (props: any) => {
  const classes = useStyles();
  const tempProps: { [index: string]: any } = props;
  const {
    helperText,
    error,
    labelStyles,
    size,
    style
  } = props;
  return (
    <TextField
      id="dateFieldTextID"
      data-testid={tempProps["data-testid"]}
      {...props.props}
      onKeyDown={evt => {
        const charCode = evt.which ? evt.which : evt.keyCode;
        if (charCode !== 9) {
          evt.preventDefault();
          return false;
        }
        return true;
      }}
      variant="outlined"
      placeholder=""
      helperText={helperText}
      error={error}
      size={size}
      style={{
        ...style,
        borderColor: error ? "#f75676" : "#cad1d7",
        width: "100%",
        pointerEvents: "none"
      }}
      InputProps={{
        ...props?.props?.InputProps,
        classes: {
          root: classes.root
        }
      }}
      InputLabelProps={{
        shrink: false,
        style: {
          position: "relative",
          fontSize: 12,
          color: "#8897aa",
          fontWeight: 600,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          marginTop: "5px",
          lineHeight: "16px",
          ...labelStyles
        }
      }}
    />
  );
};
