/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Typography, Grid, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  AdjustedAsIsValue,
  CityAndState,
  ESRIPopulationDensityTextField,
  ZillowCBSATextField
} from "../../form-components/PropertyLoanFeatures";
import { RootState } from "../../../stores/rootReducer";
import { getLoanType } from "../../../config/config";
import { ExpandCollapse, LoanStage } from "@toorak/tc-common-fe-sdk";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      input: {
        backgroundColor: "#ffffff !important"
      }
    },
    container: {
      borderRadius: 4,
      display: "flex",
      flexDirection: "column",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      backgroundColor: "#ffffff",
      marginTop: theme.spacing(3)
      // fontFamily: "OpenSans"
    },
    sectionWrapper: {
      padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
      display: "flex"
    },
    headingTypography: {
      textAlign: "left",
      fontSize: "14px !important",
      fontWeight: 600,
      color: "#32325d",
      lineHeight: 1.57
    },
    headingImageContainer: {
      justifyContent: "flex-end",
      display: "flex"
    },
    headingImage: {
      width: 16,
      height: 16,
      objectFit: "contain",
      cursor: "pointer"
    },
    divider: {
      height: "1px",
      border: "solid 1px #f2f2f2",
      padding: "0px !important"
    },
    formSelectContainer: {
      justifyContent: "space-around",
      display: "flex",
      flexDirection: "row",
      width: "95%",
      padding: "8px 0px 0px 0px"
    },
    iconInfo: {
      width: "16px !important",
      height: "16px !important",
      color: "#32355d",
      "&:hover": {
        color: "#5e72e4"
      }
    },
    labelInformation: {
      fontSize: 12,
      fontWeight: 600,
      color: "#8897aa",
      height: "14px",
      "&:hover": {
        color: "#5e72e4"
      }
    },
    calculatedOutput: {
      marginLeft: 6,
      // textAlign: "left",
      fontSize: 10,
      fontWeight: "bold",
      color: "#172b4d",
      // lineHeight: 1.8,
      borderRadius: 9,
      backgroundColor: "#d9efff",
      padding: "0 6px",
      height: 16,
      marginTop: 2,
      [theme.breakpoints.down("md")]: {
        marginLeft: "0px",
        marginTop: 10
      },
      [theme.breakpoints.only("sm")]: {
        marginTop: 4,
        marginLeft: "5px"
      }
    }
  })
);

export interface PropertyDetailsLoanFeaturesProps {
  loanFeatures: {
    esriPopulationDensity: string;
    zillowCbsa: string;
    adjustedAsIsValue: string;
    cityAndState: string;
  };
  isEvaluationPanelVisible: boolean;
}

export function ThirtyYearPropertyDetailsLoanFeatures(
  props: PropertyDetailsLoanFeaturesProps
) {
  const [isMinimized, setMinimized] = useState(false);
  const { loanType, loanStage } = useParams<any>() as any;

  const loanTypeMapping =
    loanType === getLoanType[0].displayValue ? "bridge" : loanType;
  let infoContentResult = useSelector<RootState, any>(
    (state) => state.createLoanStore.infoContentResult
  );
  if (infoContentResult) {
    infoContentResult = JSON.parse(JSON.stringify(infoContentResult));
  }
  const { loanFeatures } = props;
  const colSpan = 4;
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {/* Minimize Section start */}
      <Grid container spacing={3} className={classes.sectionWrapper}>
        <Grid item xs={12} container>
          <Grid container item xs={6} sm={11}>
            <Typography variant="h6" className={classes.headingTypography}>
              Loan Features
            </Typography>
            <Typography variant="h6" className={classes.calculatedOutput}>
              CALCULATED OUTPUTS
            </Typography>
          </Grid>
          <Grid item xs={6} sm={1} className={classes.headingImageContainer}>
            <ExpandCollapse
              isMinimize={isMinimized}
              clicked={() => {
                setMinimized(!isMinimized);
              }}
              keyDown={() => {
                setMinimized(!isMinimized);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* Minimize Section end */}
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: 24,
          paddingRight: 24,
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h6"
            style={{
              textAlign: "left",
              fontSize: 14,
              fontWeight: 600,
              color: "#32325d",
              lineHeight: 1.57,
            }}
          >
            Loan Features
          </Typography>
          <Typography variant="h6" className={classes.calculatedOutput}>
            CALCULATED OUTPUTS
          </Typography>
        </div>
        <img
          src={isMinimized ? iconMaximize : iconMinimize}
          alt={isMinimized ? "Maximize section" : "Minimize section"}
          onClick={() => {
            setMinimized(!isMinimized);
          }}
          onKeyDown={() => {
            setMinimized(!isMinimized);
          }}
          style={{
            width: 16,
            height: 16,
            objectFit: "contain",
          }}
        />
      </div> */}
      {/* Divider Section start */}
      <Grid
        item
        xs={12}
        sm={12}
        style={{
          display: isMinimized ? "none" : ""
        }}
      >
        <div className={classes.divider} />
      </Grid>
      {/* Divider Section end */}
      <Grid
        container
        spacing={4}
        style={{
          padding: 24,
          display: isMinimized ? "none" : ""
        }}
      >
        <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
          <ESRIPopulationDensityTextField
            value={loanFeatures.esriPopulationDensity}
            infoIconData={
              infoContentResult &&
              infoContentResult[
                `fieldInfo.${loanTypeMapping}.${loanStage.toLowerCase()}.result.propertyResults.loanFeatures.esriPopulationDensity`
              ]
            }
            isViewMode
          />
        </Grid>
        <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
          <ZillowCBSATextField
            value={loanFeatures.zillowCbsa}
            infoIconData={
              infoContentResult &&
              infoContentResult[
                `fieldInfo.${loanTypeMapping}.${loanStage.toLowerCase()}.result.propertyResults.loanFeatures.zillowCbsa`
              ]
            }
            isViewMode
          />
        </Grid>
        <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
          <AdjustedAsIsValue
            value={loanFeatures.adjustedAsIsValue}
            isViewMode
          />
        </Grid>
        {loanStage !== LoanStage.fes && (
          <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
            <CityAndState value={loanFeatures.cityAndState} isViewMode />
          </Grid>
        )}
      </Grid>
    </div>
  );
}
