import React from "react";
import { Grid, Typography } from "@mui/material";
import { useParams } from "react-router";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useDispatch, useSelector } from "react-redux";
import { LoanStage, ConfirmationDialog } from "@toorak/tc-common-fe-sdk";
import { UserDetailsCard } from "./UserDetailsCard";
import { ResponseAndDocumentUploadCard } from "./ResponseAndDocumentUploadCard";
import { useStyles } from "./ExceptionCardStyle";
import { CommentsBlock } from "../loan-lists/comments-section/components/CommentsBlock";
import {
  isNullOrUndefined,
  isViewOnly,
  nameToInitials
} from "../../utils/formatChecks";
import { deleteResponseByResponseId } from "../../stores/ExceptionsResults/ExceptionActions";
import { isILP } from "../../utils/AccessManagement";
import { constructName, getDateInFormat } from "../../utils/utils";
import { RootState } from "../../stores/rootReducer";
import { deleteResponseAndUpdateDashBoard } from "../../stores/dashboardSection/dashboard.action";
import { deleteResponseAndUpdateArr } from "../create-loan.action";
// import {
//   showLoader,
//   hideLoader
//   // clearLoader
// } from "../../stores/loaderAndException/loaderAndException.action";
// import {
//   getUserLists
//   // updateUserListInRedux
// } from "../../stores/Scenario/ScenarioActions";
// import { getCookie } from "../../utils/cookies";
// import { updateUserList } from "../../stores/Scenario/ScenarioReducer";
// import { clearLoader } from "../../stores/loaderAndException/loaderAndException.reducer";

export interface ActionCardProps {
  chatDetails?: any;
  waiver?: any;
  status?: any;
  exceptionTypeProp?: any;
  excepId?: any;
  loanIdComm?: any;
  loanStageComm?: any;
  activeInteractionsModal?: any;
  condtType?: any;
  exceptionId?: string;
  cardType?: any;
  viewMore?: boolean;
  waiverId?: any;
  getDetails?: any;
  currentLoanStage?: any;
  from?: string;
  addCommentCB?: any;
  exception?: any;
}

export const ActionsCard = (props: ActionCardProps) => {
  const classes = useStyles();
  const {
    loanId,
    loanStage,
    type: exceptionType
  } = useParams<{
    loanId: string;
    exceptionId: string;
    loanStage: LoanStage;
    loanType: string;
    type: string;
  }>() as any;

  let { exceptionId } = useParams<{
    exceptionId: string;
  }>() as any;
  if (!exceptionId && props.exceptionId) {
    exceptionId = props.exceptionId;
  }
  const {
    chatDetails,
    status,
    excepId,
    exceptionTypeProp,
    loanIdComm,
    loanStageComm,
    activeInteractionsModal,
    condtType,
    cardType,
    viewMore,
    waiverId,
    getDetails,
    currentLoanStage,
    from,
    addCommentCB,
    exception
  } = props;
  const { isWithdrawnLoan } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );
  const userTags = useSelector<RootState, any>(
    (state) => state.scenarioResultsStore.userLists
  );

  const { exceptionResponseId, responseType, chatSequenceId, visibility } =
    chatDetails;
  // console.log("chatDetails", chatDetails, status);
  // const currentURL = window.location.href;
  // const notFromApprover = !currentURL.includes("view");
  const [openConfirmBox, setOpenConfirmBox] = React.useState(false);
  const confirmDelete = () => {
    setOpenConfirmBox(true);
  };
  const handleConfirmation = () => {
    setOpenConfirmBox(false);
  };
  const dispatch = useDispatch();
  const chatLinesLength = chatDetails.chatLines && chatDetails.chatLines.length;
  const responseHeading = (response: string) => {
    let type = response;
    switch (response.toLowerCase()) {
      case "satisfy_exception":
      case "satisfy_exceptions":
      case "satisfy exception":
        type = "Satisfy Condition";
        break;
      case "satisfy_inquiry":
        type = "Satisfy Inquiry";
        break;
      case "ask_a_question":
      case "ask a question":
        type = "Question";
        break;
      case "rescind_condition":
        type = "Condition Rescinded";
        break;
      case "waive_condition":
        type = "Condition Waived";
        break;
      case "close_condition":
        type = "Condition Satisfied";
        break;
      default:
        break;
    }
    return type;
  };
  const partyId =
    chatDetails.chatLines && chatDetails.chatLines.length
      ? chatDetails.chatLines[0].partyId
      : "";
  const accountId = chatDetails?.accountMapping?.find(
    (it: any) => it.personId === partyId
  )?.accountId;
  const accountType = chatDetails?.accounts?.find(
    (it: any) => it.partyId === accountId
  )?.accountType;

  const ResponseData = chatDetails?.customers?.find(
    (item: any) => item.partyId === partyId
  );
  const firstName = !isNullOrUndefined(ResponseData?.firstName)
    ? ResponseData?.firstName
    : "";
  const lastName = !isNullOrUndefined(ResponseData?.lastName)
    ? ResponseData?.lastName
    : "";
  const middleName = !isNullOrUndefined(ResponseData?.middleName)
    ? ResponseData?.middleName
    : "";
  const NameInitials = nameToInitials(`${firstName} ${lastName}`);
  const userFullName = constructName(firstName, middleName, lastName);
  const formatedDate = getDateInFormat(
    chatDetails.chatLines && chatDetails.chatLines.length
      ? chatDetails.chatLines[0].createdOn
      : ""
  );

  const description =
    chatDetails.chatLines && chatDetails.chatLines.length
      ? chatDetails.chatLines[0].lineText
      : "";
  const handleDeleteResponse = () => {
    if (activeInteractionsModal) {
      // to do
      dispatch(
        deleteResponseAndUpdateArr(
          loanStage || loanStageComm,
          loanId || loanIdComm,
          exceptionId || excepId,
          exceptionResponseId,
          chatSequenceId,
          waiverId
        )
      );
    } else if (window.location.href.indexOf("tab=0") > -1) {
      dispatch(
        deleteResponseAndUpdateDashBoard(
          loanStage || loanStageComm,
          loanId || loanIdComm,
          exceptionId || excepId,
          exceptionResponseId,
          chatSequenceId
        )
      );
    }
    dispatch(
      deleteResponseByResponseId(
        loanStage || loanStageComm,
        loanId || loanIdComm,
        exceptionId || excepId,
        exceptionResponseId,
        chatSequenceId
      )
    );
    setTimeout(() => {
      if (getDetails) {
        getDetails(true);
      }
    }, 1500);
  };
  const isConditionsAndInquiriesTab =
    window.location.href.indexOf("createLoan") !== -1;
  const statusDisable = [
    "approved",
    "approved-conditionally",
    "satisfied",
    "rescind",
    "rescinded",
    "rejected",
    "waived"
  ].includes(status?.toLowerCase());
  return (
    <>
      {description &&
      formatedDate !== "invalid date" &&
      responseType &&
      responseHeading(responseType) ? (
        <Grid
          className={
            isILP() && from !== "approver"
              ? [classes.container, classes.commentCard].join(" ")
              : classes.container
          }
          style={
            viewMore
              ? isConditionsAndInquiriesTab
                ? { paddingLeft: 5, paddingRight: 5 }
                : { paddingLeft: 0, paddingRight: 0 }
              : {}
          }
        >
          <Grid container alignItems="center" spacing={0}>
            <Grid
              item
              xs={8}
              sm={7}
              // md={12}
              // lg={12}
              // xl={12}
              container
              justifyContent="flex-start"
              alignItems="center"
            >
              <UserDetailsCard
                heading={responseType && responseHeading(responseType)}
                userImg="some img will come here"
                accountNameImg={NameInitials}
                userFullName={userFullName}
                accountType={accountType}
                date={formatedDate}
              />
            </Grid>
            {responseType === "ASK_A_QUESTION" &&
              (chatLinesLength === 0 || chatLinesLength === 1) && (
                <Grid
                  item
                  xs={4}
                  sm={5}
                  // md={12}
                  // lg={12}
                  // xl={12}
                  container
                  justifyContent="flex-end"
                >
                  {!statusDisable &&
                    !isWithdrawnLoan &&
                    !isILP() &&
                    !isViewOnly(
                      loanStage || loanStageComm,
                      loanId || loanIdComm,
                      currentLoanStage
                    ) && (
                      <DeleteOutlinedIcon
                        fontSize="small"
                        style={{
                          fontSize: "16px",
                          color: "black",
                          cursor: "pointer"
                        }}
                        onClick={() => confirmDelete()}
                      />
                    )}
                </Grid>
              )}
          </Grid>
          <Grid>
            <div className="multiline-ellipsis-container">
              <div className="multiline-content">
                <Typography className={classes.descriptionException}>
                  {description}
                </Typography>
                <div className="ellipsis-dots">...</div>
              </div>
            </div>
          </Grid>
          <Grid>
            <ResponseAndDocumentUploadCard
              isRequired
              conditionType={condtType}
              exceptionId={exceptionId}
              waiverId={waiverId}
              documentsDetails={
                chatDetails.documents || [chatDetails.chatLines?.[0]?.documents]
              }
            />
          </Grid>
          <Grid>
            <div
              style={{
                fontSize: 12,
                lineHeight: 1.5,
                color: "#32325d",
                marginTop: 20
              }}
            >
              {chatDetails.chatLines ? (
                <CommentsBlock
                  exceptionId={exceptionId || excepId}
                  statusProp={status}
                  userTags={userTags}
                  hostId={exceptionId || excepId}
                  activeInteractionsModal={activeInteractionsModal}
                  loanId={loanId || loanIdComm}
                  loanStage={loanStage || loanStageComm}
                  isWithdrawnLoan={isWithdrawnLoan}
                  chatLines={chatDetails.chatLines}
                  chatSequenceId={chatDetails.chatSequenceId}
                  exceptionResponseId={chatDetails.exceptionResponseId}
                  chatDetails={chatDetails}
                  showSenderAvatar
                  unreadCount={chatDetails.count}
                  cardType={cardType}
                  visibility={visibility}
                  responseType={chatDetails?.responseType}
                  exceptionType={exceptionType || exceptionTypeProp}
                  condtType={exceptionType || exceptionTypeProp}
                  waiverId={waiverId}
                  currentLoanStage={currentLoanStage}
                  addCommentCB={addCommentCB}
                  exception={exception}
                />
              ) : null}
            </div>
          </Grid>
        </Grid>
      ) : null}
      <ConfirmationDialog
        onCancel={handleConfirmation}
        onConfirm={handleDeleteResponse}
        open={openConfirmBox}
        msg="Are you sure you want to delete the Question ?"
      />
    </>
  );
};
