import { Typography, Grid } from "@mui/material";
import { IconTooltip, ExpandCollapse } from "@toorak/tc-common-fe-sdk";

import React, { useState } from "react";
import { creditEvalStyles } from "../../credit-evaluation/styles/CreditEvalStyles";

export const MinimizableSection = (props: {
  sectionLabel: string;
  children: any;
  sectionId: string;
  showCalculatedOutputTag: boolean;
  editIconProps?: {
    showEditIcon: boolean;
    handleOnClick: any;
  };
}) => {
  const classes = creditEvalStyles();
  const [isMinimized, setMinimized] = useState(false);
  const {
    sectionLabel,
    sectionId,
    children,
    showCalculatedOutputTag,
    editIconProps
  } = props;
  return (
    <Grid container item xs={12} className={classes.gridOne} id={sectionId}>
      <Grid className={classes.container}>
        <Grid container spacing={3} className={classes.sectionWrapper}>
          <Grid item xs={12} container style={{ alignItems: "center" }}>
            <Grid
              item
              xs={6}
              sm={11}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Typography className={classes.headingTypography}>
                {sectionLabel}
              </Typography>
              {showCalculatedOutputTag && (
                <Typography variant="h6" className={classes.calculatedOutput}>
                  CALCULATED OUTPUTS
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={6}
              sm={1}
              className={classes.headingImageContainer}
              style={{
                justifyContent:
                  editIconProps && editIconProps.showEditIcon
                    ? "space-evenly"
                    : "flex-end"
              }}
            >
              {editIconProps?.showEditIcon && (
                <IconTooltip
                  clicked={editIconProps.handleOnClick}
                  keyDown={editIconProps.handleOnClick}
                  topic="Edit"
                />
              )}
              <ExpandCollapse
                isMinimize={isMinimized}
                clicked={() => {
                  setMinimized(!isMinimized);
                }}
                keyDown={() => {
                  setMinimized(!isMinimized);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          style={{
            display: isMinimized ? "none" : ""
          }}
        >
          <div className={classes.divider} />
        </Grid>
        {isMinimized ? null : children}
      </Grid>
    </Grid>
  );
};
