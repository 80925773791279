import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import { useDrawsStyles } from "./Draws.styles";
import { useNavigate } from "react-router-dom";

export const DrawsButtonGroup = (props: { defaultValue: string }) => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const { defaultValue } = props;
  const [alignment, setAlignment] = useState<string>("Draws");
  const drawClasses = useDrawsStyles();
  useEffect(() => {
    setAlignment(defaultValue);
  }, [defaultValue]);
  const handleChange = (event: any, newAlignment: string) => {
    if (!newAlignment) return;
    if (newAlignment === "Provisions") {
      navigate("/drawDetails/provision");
    } else {
      navigate("/asset/home?tab=0");
    }
    newAlignment && setAlignment(newAlignment);
  };

  return (
    <ToggleButtonGroup
      aria-label="text alignment"
      value={alignment}
      onChange={handleChange}
      exclusive
      className={drawClasses.headerButton}
    >
      <ToggleButton value="Draws" className={drawClasses.buttonGroup}>
        Draw List
      </ToggleButton>
      <ToggleButton value="Provisions" className={drawClasses.buttonGroup}>
        Provisions
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
