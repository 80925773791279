import { Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import React from "react";

const useStyles = makeStyles(() =>
  createStyles({
    applyButton: {
      // marginRight: 12,
      color: "#ffffff",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      // marginTop: 10,
      // marginLeft: 16,
      // marginBottom: 5,
      borderWidth: 0,
      "&:hover": {
        backgroundColor: "#5566d6 !important"
      },
      "&:disabled": {
        opacity: "0.5"
      }
    }
  })
);

export function ApplyButton(props: any) {
  const { buttonText, onClick, disabled, style = {}, customStyle } = props;
  const classes = useStyles();
  return (
    <Button
      aria-controls="simple-menu"
      aria-haspopup="true"
      variant="contained"
      color="primary"
      className={`${classes.applyButton} ${customStyle}`}
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
      {buttonText}
    </Button>
  );
}
