/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from "react";
import {
  Theme,
  ThemeProvider,
  StyledEngineProvider,
  Typography,
  Grid,
  Button,
  Dialog
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import CloseIcon from "@mui/icons-material/Close";
import { ExpandCollapse, LoanStage, MuiTheme } from "@toorak/tc-common-fe-sdk";
import { PropertyDetailsUnitInformation } from "./PropertyDetailsUnitInformation";
import "../../../App.css";
import editImage from "../../../images/Edit.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../stores/rootReducer";
import {
  previousLoanState,
  // UNIT_FIELD_CHANGED,
  // valueEditing,
  validateForm as validateFormCheck,
  updatePreviousLoanStateAction
} from "../../create-loan.action";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import {
  globalSaveThirtyYrOnFieldChangeProperty,
  prepareCommentObjectProperty
} from "./ThirtyYearLoanPropertyInformation";
import { fesEditFunctionSave } from "../../../frontend-sizer/FrontendSizerSectionMapping";
import { useParams } from "react-router-dom";
import { requestWaiverMessage } from "../../../utils/constants";
import { unitFieldChanged, valueEditing } from "../../create-loan.reducer";

declare module "@mui/styles" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const editableFlags: any = {};
let originalUnitInfo: any;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      input: {
        backgroundColor: "#ffffff !important"
      }
    },
    container: {
      borderRadius: 4,
      display: "flex",
      flexDirection: "column",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      backgroundColor: "#ffffff",
      marginTop: theme.spacing(3)
      // fontFamily: "OpenSans"
    },
    sectionWrapper: {
      padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
      display: "flex"
    },
    modalButton: {
      marginRight: 12,
      color: "#ffffff",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      marginTop: 18,
      borderWidth: 0,
      "&:hover": {
        backgroundColor: "#5566d6 !important"
      }
    },
    modalDeleteButton: {
      marginRight: 12,
      color: "#ffffff",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      marginTop: 18,
      borderWidth: 0
    },
    modalCancelButton: {
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      color: "#32325d",
      backgroundColor: "#ffffff",
      marginTop: 18,
      borderWidth: 1,
      borderColor: "#ececec"
    },
    modalDeleteButtonConatainer: {
      marginTop: 50
    },
    primaryGuarantorWrapper: {
      padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(
        1
      )} ${theme.spacing(3)}`,
      display: "flex",
      color: "#32325d"
    },
    editImageDisplay: {
      display: "none"
    },
    editImage: {
      width: 16,
      height: 16,
      marginRight: 16,
      objectFit: "contain",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    savebutton: {
      width: 70,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4",
      marginLeft: 20,
      marginBottom: 15
    },
    cancelButton: {
      width: 69,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      color: "#32325d",
      backgroundColor: "#fff",
      "&:hover": {
        background: "none"
      },
      marginLeft: 10
    },
    sectionButtonWrapper: {
      padding: `${theme.spacing(0)} ${theme.spacing(3)} ${theme.spacing(
        3
      )} ${theme.spacing(3)}`,
      display: "flex"
    },
    guarantorHeadingTypography: {
      textAlign: "left",
      fontSize: "14px !important",
      fontWeight: 600,
      color: "#32325d",
      lineHeight: 1.57
    },
    headingImageContainer: {
      justifyContent: "flex-end",
      display: "flex"
    },
    headingImage: {
      width: 16,
      height: 16,
      objectFit: "contain",
      cursor: "pointer"
    },
    divider: {
      height: "1px",
      border: "solid 1px #f2f2f2",
      padding: "0px !important"
    }
  })
);

export interface MultipleUnitsWrapperProps {
  unitInformation: any;
  isEvaluationPanelVisible: boolean;
  unitInfoFields: any;
  isAllowedToEdit?: any;
}

export const MultipleUnitsWrapper = (props: MultipleUnitsWrapperProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isEditable, setEditable] = useState(false);
  const {
    unitInformation,
    isEvaluationPanelVisible,
    unitInfoFields,
    isAllowedToEdit
  } = props;
  const [isMinimized, setMinimized] = useState(false);
  const [cancelForm, setCancelForm] = useState(false);
  const [currentIndex, setCurrentIndex] = useState([]);
  const activePropertyId = useSelector<RootState, any>(
    (state) => state.createLoanStore.loanDetails.activePropertyId
  );
  const {
    propertyDetails,
    loanState
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useSelector<RootState, any>((state) => state.createLoanStore.loanDetails);
  const { loanStage } = useParams<any>() as any;
  //==================================================================
  const { unitFieldChange } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );
  const { takeoutPartnerId } = useSelector<RootState, any>(
    (state) =>
      state.createLoanStore?.loanDetails.thirtyYearLoanInformation.payload
  );
  const [open, setOpen] = React.useState(false);
  const [changedValueFields, setChangedValueFields] = useState<{
    [index: string]: boolean;
  }>({});

  const [resetData, setResetData] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const initialEditState = () => {
    // dispatch({
    //   type: UNIT_FIELD_CHANGED,
    //   payload: {
    //     unitChangedIds: ""
    //   }
    // });
    dispatch(
      unitFieldChanged({
        unitChangedIds: ""
      })
    );
  };

  useEffect(() => {
    if (
      loanState === LoanStatusEnum.DueDiligenceReview &&
      isEditable === true
    ) {
      initialEditState();
      dispatch(valueEditing(false));
      setEditable(!isEditable);
    }
    // eslint-disable-next-line
  }, [loanState]);
  // eslint-disable-next-line
  useEffect(() => {
    if (resetData && originalUnitInfo) {
      dispatch(previousLoanState(originalUnitInfo, "unitsInformation", true));
      setResetData(!resetData);
    }
    // eslint-disable-next-line
  });

  useEffect(() => {
    if (activePropertyId && resetData) {
      onCancel();
      updatePreviousLoanState();
      dispatch(previousLoanState(originalUnitInfo, "unitsInformation", true));
      setResetData(!resetData);
    }
    // eslint-disable-next-line
  }, [activePropertyId]);

  const onCancel = () => {
    initialEditState();
    dispatch(valueEditing(false));
    setEditable(false);
    setResetData(true);
    prepareCommentObjectProperty("", "", "", true);
    setCancelForm(true);
    setCurrentIndex([]);
  };
  // useEffect(() => {
  //   // eslint-disable-next-line
  //   if (
  //     loanState === LoanStatusEnum.DueDiligenceReview &&
  //     isEditable === true
  //   ) {
  //     initialEditState();
  //     dispatch(valueEditing(false));
  //     setEditable(!isEditable);
  //   }
  //   // eslint-disable-next-line
  // }, [loanState, isEditable]);

  const saveOnFieldChange = async (
    changedValueFields: any,
    resultEdit?: any,
    extraValueEdited?: boolean
  ) => {
    dispatch(validateFormCheck(true));
    const isSaved = globalSaveThirtyYrOnFieldChangeProperty(
      Object.keys(changedValueFields),
      unitInformation
    );
    return isSaved;
  };

  const updatePreviousLoanState = () => {
    originalUnitInfo = updatePreviousLoanStateAction(
      propertyDetails,
      activePropertyId,
      "unitsInformation"
    );
  };

  //=======================================================================

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={MuiTheme}>
        <div
          className={classes.container}
          test-id="multiple-unit-wrapper"
          key={`${activePropertyId}`}
        >
          {/* Section Header guarantor  */}
          <Grid container spacing={3} className={classes.sectionWrapper}>
            <Grid item xs={12} container>
              <Grid item xs={9} sm={11}>
                <Typography className={classes.guarantorHeadingTypography}>
                  Unit Information
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sm={1}
                className={classes.headingImageContainer}
              >
                {isAllowedToEdit && (
                  <img
                    className={
                      isEditable || !isEvaluationPanelVisible
                        ? classes.editImageDisplay
                        : classes.editImage
                    }
                    src={editImage}
                    alt="Edit Section"
                    onClick={() => {
                      setCancelForm(false);
                      initialEditState();
                      for (const i in editableFlags) editableFlags[i] = false;
                      updatePreviousLoanState();
                      dispatch(valueEditing(true));
                      setEditable(!isEditable);
                      editableFlags[`editProp${activePropertyId}`] = true;
                    }}
                    onKeyDown={() => {
                      setCancelForm(false);

                      initialEditState();
                      for (const i in editableFlags) editableFlags[i] = false;
                      dispatch(valueEditing(true));
                      setEditable(!isEditable);
                      editableFlags[`editProp${activePropertyId}`] = true;
                      updatePreviousLoanState();
                    }}
                  />
                )}
                <ExpandCollapse
                  isMinimize={isMinimized}
                  clicked={() => {
                    setMinimized(!isMinimized);
                  }}
                  keyDown={() => {
                    setMinimized(!isMinimized);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Section divider guarantor  */}
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              display: isMinimized ? "none" : ""
            }}
          >
            <div className={classes.divider} test-id="devider" />
          </Grid>
          {/* Section unit info  */}
          {unitInformation?.map((element: any, index: any) => {
            return (
              <PropertyDetailsUnitInformation
                unitInformation={element}
                isEvaluationPanelVisible={isEvaluationPanelVisible}
                index={index}
                numberOfUnits={unitInformation.length}
                isMinimized={isMinimized}
                isEditable={isEditable}
                fields={unitInfoFields}
                originalUnitInfo={originalUnitInfo}
                cancelForm={cancelForm}
                changedValueFields={changedValueFields}
                setChangedValueFields={setChangedValueFields}
                currentFieldEdit={currentIndex}
                setCurrentFieldEdit={setCurrentIndex}
              />
            );
          })}
          {isEditable && (
            <Grid container item xs={12} sm={12} md={12} xl={12}>
              <Button
                variant="contained"
                color="info"
                disabled={Object.keys(unitFieldChange)?.length === 0}
                className={
                  isEditable ? classes.savebutton : classes.editImageDisplay
                }
                onClick={() => {
                  handleOpen();
                }}
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="info"
                // data-testid="save-button"
                className={
                  isEditable ? classes.cancelButton : classes.editImageDisplay
                }
                onClick={() => {
                  onCancel();
                }}
              >
                Cancel
              </Button>
            </Grid>
          )}
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="duplicate-property-modal"
            aria-describedby="duplicate-property-modal-description"
          >
            <div style={{ padding: 20, width: 500, maxWidth: "100%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexGrow: 1,
                  alignItems: "center"
                }}
              >
                <div
                  style={{ fontSize: 14, fontWeight: 600, color: "#32325d" }}
                >
                  Warning
                </div>
                <CloseIcon
                  htmlColor="#414169"
                  style={{ width: 16, height: 16, cursor: "pointer" }}
                  onClick={() => {
                    handleClose();
                  }}
                />
              </div>
              <div
                style={{
                  fontSize: 12,
                  lineHeight: 1.5,
                  color: "#32325d",
                  marginTop: 40
                }}
              >
                {requestWaiverMessage}
              </div>
              <div className={classes.modalDeleteButtonConatainer}>
                <Button
                  variant="contained"
                  color="primary"
                  data-testid="modal-delete-button"
                  className={classes.modalButton}
                  onClick={() => {
                    handleClose();
                    if (loanStage === LoanStage.fes) {
                      fesEditFunctionSave(
                        true,
                        takeoutPartnerId,
                        unitFieldChange,
                        true
                      ).then((res: any) => {
                        if (res !== false) {
                          initialEditState();
                          dispatch(valueEditing(false));
                          setEditable(!isEditable);
                          setCancelForm(true);
                          setCurrentIndex([]);
                        }
                      });
                    } else {
                      saveOnFieldChange(unitFieldChange, false, true).then(
                        (res: any) => {
                          if (res !== "false") {
                            initialEditState();
                            dispatch(valueEditing(false));
                            setEditable(!isEditable);
                            setCancelForm(true);
                            setCurrentIndex([]);
                            // updatePreviousLoanState();
                          }
                        }
                      );
                    }
                  }}
                >
                  Yes
                </Button>
                <Button
                  variant="outlined"
                  data-testid="modal-cancel-button"
                  className={classes.modalCancelButton}
                  onClick={() => {
                    onCancel();
                    handleClose();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Dialog>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
