import { LoanStage } from "@toorak/tc-common-fe-sdk";
import { getLoanType } from "../../../../config/config";
import {
  formatValueByType,
  sanitizeValueByType
} from "../../../../utils/formatChecks";
import { getRequiredLeaseStatus } from "../../PropertyDetailsForm";
import {
  customComponent,
  ExpandCollapseComponent,
  getIsExpanded,
  PropertyAddressComponent,
  UnitInfoComponents
} from "../AggregateInputFields";
import { RowObjType } from "../AggregatePropertyTable";

/* ------------------------------------CONFIGS STARTS HERE------------------------------------ */
interface FieldCellStyle {
  style?: any;
}
export interface FieldInfo {
  fieldId: string; // same as loan service
  sectionId: string;
  ruleFieldId: string; // there are fields whose id are different in rule and loan/property service.Needed for edit flow.
  fieldLabel: string;
  fieldType: string;
  isRulesOutput?: boolean;
  enableClear?: boolean;
  customComponent?: (data: any) => any;
  getValue?: (data: any) => any;
  isDisabled?: (data: any) => boolean;
  fieldCellStyle?: FieldCellStyle;
  excludeFromEditTable?: boolean;
}
const commonCellStyles: FieldCellStyle = {
  style: {
    minWidth: 130,
    maxWidth: 130,
    padding: "8px 5px 8px 5px",
    border: "none"
  }
};
const firstGroupColumnStyle: FieldCellStyle = {
  style: {
    ...commonCellStyles.style,
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6
  }
};

const bridgeLoanFields: FieldInfo[] = [
  {
    fieldId: "costBasis",
    fieldLabel: "Cost Basis (After Cost Incurred)($)",
    fieldType: "currency",
    ruleFieldId: "costBasis",
    sectionId: "propertyEconomics",
    fieldCellStyle: commonCellStyles,
    ...customComponent,
    getValue: (data: any) => {
      const {
        propertyDetails,
        singlePropertyDetails,
        propertyId,
        isAggregate,
        fieldType
      } = data;
      if (!isAggregate && singlePropertyDetails) {
        return singlePropertyDetails?.propertyEconomics?.costBasis;
      }
      if (propertyDetails?.length) {
        if (isAggregate) {
          let returnVal = 0;
          propertyDetails.forEach((ele: any) => {
            const cur = ele.propertyEconomics?.costBasis;
            if (cur) {
              returnVal += Number(sanitizeValueByType(cur, fieldType));
            }
          });
          return returnVal;
        }
        if (propertyId) {
          const property = propertyDetails.find((ele: any) => {
            return ele.loanPropertyId === propertyId;
          });
          return property?.propertyEconomics?.costBasis;
        }
      }
      return null;
    }
  },
  {
    fieldId: "originalAsIsAppraisalValue",
    fieldLabel: 'Original "As Is" Appraisal Value($)',
    fieldType: "currency",
    ruleFieldId: "originalAsIsAppraisalValue",
    sectionId: "propertyEconomics",
    fieldCellStyle: commonCellStyles,
    ...customComponent,
    getValue: (data: any) => {
      const {
        propertyDetails,
        singlePropertyDetails,
        propertyId,
        isAggregate,
        fieldType
      } = data;
      if (!isAggregate && singlePropertyDetails) {
        return singlePropertyDetails?.propertyEconomics
          ?.originalAsIsAppraisalValue;
      }
      if (propertyDetails?.length) {
        if (isAggregate) {
          let returnVal = 0;
          propertyDetails.forEach((ele: any) => {
            const cur = ele.propertyEconomics?.originalAsIsAppraisalValue;
            if (cur) {
              returnVal += Number(sanitizeValueByType(cur, fieldType));
            }
          });
          return returnVal;
        }
        if (propertyId) {
          const property = propertyDetails.find((ele: any) => {
            return ele.loanPropertyId === propertyId;
          });
          return property?.propertyEconomics?.originalAsIsAppraisalValue;
        }
      }
      return null;
    }
  },
  {
    fieldId: "originalAsRepairedAppraisedValue",
    fieldLabel: "As Repaired Value (ARV)($)",
    fieldType: "currency",
    ruleFieldId: "originalAsRepairedAppraisedValue",
    sectionId: "propertyEconomics",

    fieldCellStyle: commonCellStyles,
    ...customComponent,
    getValue: (data: any) => {
      const {
        propertyDetails,
        singlePropertyDetails,
        propertyId,
        isAggregate,
        fieldType
      } = data;
      if (!isAggregate && singlePropertyDetails) {
        return singlePropertyDetails?.propertyEconomics
          ?.originalAsRepairedAppraisedValue;
      }
      if (propertyDetails?.length) {
        if (isAggregate) {
          let returnVal = 0;
          propertyDetails.forEach((ele: any) => {
            const cur = ele.propertyEconomics?.originalAsRepairedAppraisedValue;
            if (cur) {
              returnVal += Number(sanitizeValueByType(cur, fieldType));
            }
          });
          return returnVal;
        }
        if (propertyId) {
          const property = propertyDetails.find((ele: any) => {
            return ele.loanPropertyId === propertyId;
          });
          return property?.propertyEconomics?.originalAsRepairedAppraisedValue;
        }
      }
      return null;
    }
  },
  {
    fieldId: "purchasePrice",
    fieldLabel: "Purchase Price($)",
    fieldType: "currency",
    ruleFieldId: "purchasePrice",
    sectionId: "propertyEconomics",

    fieldCellStyle: commonCellStyles,
    ...customComponent,
    getValue: (data: any) => {
      const {
        propertyDetails,
        singlePropertyDetails,
        propertyId,
        isAggregate,
        fieldType
      } = data;
      if (!isAggregate && singlePropertyDetails) {
        return singlePropertyDetails?.propertyEconomics?.purchasePrice;
      }
      if (propertyDetails?.length) {
        if (isAggregate) {
          let returnVal = 0;
          propertyDetails.forEach((ele: any) => {
            const cur = ele.propertyEconomics?.purchasePrice;
            if (cur) {
              returnVal += Number(sanitizeValueByType(cur, fieldType));
            }
          });
          return returnVal;
        }
        if (propertyId) {
          const property = propertyDetails.find((ele: any) => {
            return ele.loanPropertyId === propertyId;
          });
          return property?.propertyEconomics?.purchasePrice;
        }
      }
      return null;
    }
  }
];
const bridgeLoanFieldsFES: FieldInfo[] = [
  {
    fieldId: "originalAsIsAppraisalValue",
    fieldLabel: 'Original "As Is" Appraisal Value($)',
    fieldType: "currency",
    ruleFieldId: "originalAsIsAppraisalValue",
    sectionId: "propertyEconomics",
    fieldCellStyle: commonCellStyles,
    ...customComponent,
    getValue: (data: any) => {
      const {
        propertyDetails,
        singlePropertyDetails,
        propertyId,
        isAggregate,
        fieldType
      } = data;
      if (!isAggregate && singlePropertyDetails) {
        return singlePropertyDetails?.propertyEconomics
          ?.originalAsIsAppraisalValue;
      }
      if (propertyDetails?.length) {
        if (isAggregate) {
          let returnVal = 0;
          propertyDetails.forEach((ele: any) => {
            const cur = ele.propertyEconomics?.originalAsIsAppraisalValue;
            if (cur) {
              returnVal += Number(sanitizeValueByType(cur, fieldType));
            }
          });
          return returnVal;
        }
        if (propertyId) {
          const property = propertyDetails.find((ele: any) => {
            return ele.loanPropertyId === propertyId;
          });
          return property?.propertyEconomics?.originalAsIsAppraisalValue;
        }
      }
      return null;
    }
  },
  {
    fieldId: "originalAsRepairedAppraisedValue",
    fieldLabel: "As Repaired Value (ARV)($)",
    fieldType: "currency",
    ruleFieldId: "originalAsRepairedAppraisedValue",
    sectionId: "propertyEconomics",

    fieldCellStyle: commonCellStyles,
    ...customComponent,
    getValue: (data: any) => {
      const {
        propertyDetails,
        singlePropertyDetails,
        propertyId,
        isAggregate,
        fieldType
      } = data;
      if (!isAggregate && singlePropertyDetails) {
        return singlePropertyDetails?.propertyEconomics
          ?.originalAsRepairedAppraisedValue;
      }
      if (propertyDetails?.length) {
        if (isAggregate) {
          let returnVal = 0;
          propertyDetails.forEach((ele: any) => {
            const cur = ele.propertyEconomics?.originalAsRepairedAppraisedValue;
            if (cur) {
              returnVal += Number(sanitizeValueByType(cur, fieldType));
            }
          });
          return returnVal;
        }
        if (propertyId) {
          const property = propertyDetails.find((ele: any) => {
            return ele.loanPropertyId === propertyId;
          });
          return property?.propertyEconomics?.originalAsRepairedAppraisedValue;
        }
      }
      return null;
    }
  },
  {
    fieldId: "purchasePrice",
    fieldLabel: "Purchase Price($)",
    fieldType: "currency",
    ruleFieldId: "purchasePrice",
    sectionId: "propertyEconomics",

    fieldCellStyle: commonCellStyles,
    ...customComponent,
    getValue: (data: any) => {
      const {
        propertyDetails,
        singlePropertyDetails,
        propertyId,
        isAggregate,
        fieldType
      } = data;
      if (!isAggregate && singlePropertyDetails) {
        return singlePropertyDetails?.propertyEconomics?.purchasePrice;
      }
      if (propertyDetails?.length) {
        if (isAggregate) {
          let returnVal = 0;
          propertyDetails.forEach((ele: any) => {
            const cur = ele.propertyEconomics?.purchasePrice;
            if (cur) {
              returnVal += Number(sanitizeValueByType(cur, fieldType));
            }
          });
          return returnVal;
        }
        if (propertyId) {
          const property = propertyDetails.find((ele: any) => {
            return ele.loanPropertyId === propertyId;
          });
          return property?.propertyEconomics?.purchasePrice;
        }
      }
      return null;
    }
  }
];
const dscrLoanFields: FieldInfo[] = [
  // {
  //   fieldId: "costBasis",
  //   fieldLabel: "Cost Basis (After Cost Incurred)($)",
  //   fieldType: "currency",
  //   ruleFieldId: "costBasis",
  //   sectionId: "propertyEconomics",
  //   fieldCellStyle: commonCellStyles,
  //   ...customComponent,
  //   getValue: (data: any) => {
  //     const {
  //       propertyDetails,
  //       singlePropertyDetails,
  //       propertyId,
  //       isAggregate,
  //       fieldType
  //     } = data;
  //     if (!isAggregate && singlePropertyDetails) {
  //       return singlePropertyDetails?.propertyEconomics?.costBasis;
  //     }
  //     if (propertyDetails?.length) {
  //       if (isAggregate) {
  //         let returnVal = 0;
  //         propertyDetails.forEach((ele: any) => {
  //           const cur = ele.propertyEconomics?.costBasis;
  //           if (cur) {
  //             returnVal += Number(sanitizeValueByType(cur, fieldType));
  //           }
  //         });
  //         return returnVal;
  //       }
  //       if (propertyId) {
  //         const property = propertyDetails.find((ele: any) => {
  //           return ele.loanPropertyId === propertyId;
  //         });
  //         return property?.propertyEconomics?.costBasis;
  //       }
  //     }
  //     return null;
  //   }
  // },
  {
    fieldId: "originalAsIsAppraisalValue",
    fieldLabel: 'Original "As Is" Appraisal Value($)',
    fieldType: "currency",
    ruleFieldId: "originalAsIsAppraisalValue",
    sectionId: "propertyEconomics",
    fieldCellStyle: commonCellStyles,
    ...customComponent,
    getValue: (data: any) => {
      const {
        propertyDetails,
        singlePropertyDetails,
        propertyId,
        isAggregate,
        fieldType
      } = data;
      if (!isAggregate && singlePropertyDetails) {
        return singlePropertyDetails?.propertyEconomics
          ?.originalAsIsAppraisalValue;
      }
      if (propertyDetails?.length) {
        if (isAggregate) {
          let returnVal = 0;
          propertyDetails.forEach((ele: any) => {
            const cur = ele.propertyEconomics?.originalAsIsAppraisalValue;
            if (cur) {
              returnVal += Number(sanitizeValueByType(cur, fieldType));
            }
          });
          return returnVal;
        }
        if (propertyId) {
          const property = propertyDetails.find((ele: any) => {
            return ele.loanPropertyId === propertyId;
          });
          return property?.propertyEconomics?.originalAsIsAppraisalValue;
        }
      }
      return null;
    }
  },
  {
    fieldId: "purchasePrice",
    fieldLabel: "Purchase Price($)",
    fieldType: "currency",
    ruleFieldId: "purchasePrice",
    sectionId: "propertyEconomics",
    fieldCellStyle: commonCellStyles,
    ...customComponent,
    getValue: (data: any) => {
      const {
        propertyDetails,
        singlePropertyDetails,
        propertyId,
        isAggregate,
        fieldType
      } = data;
      if (!isAggregate && singlePropertyDetails) {
        return singlePropertyDetails?.propertyEconomics?.purchasePrice;
      }
      if (propertyDetails?.length) {
        if (isAggregate) {
          let returnVal = 0;
          propertyDetails.forEach((ele: any) => {
            const cur = ele.propertyEconomics?.purchasePrice;
            if (cur) {
              returnVal += Number(sanitizeValueByType(cur, fieldType));
            }
          });
          return returnVal;
        }
        if (propertyId) {
          const property = propertyDetails.find((ele: any) => {
            return ele.loanPropertyId === propertyId;
          });
          return property?.propertyEconomics?.purchasePrice;
        }
      }
      return null;
    }
  },
  {
    fieldId: "thirdPartyValuation",
    fieldLabel: "Third Party Valuation($)",
    enableClear: true,
    fieldType: "currency",
    ruleFieldId: "thirdPartyValuation",
    sectionId: "propertyEconomics",
    fieldCellStyle: commonCellStyles,
    ...customComponent,
    getValue: (data: any) => {
      const {
        propertyDetails,
        singlePropertyDetails,
        propertyId,
        isAggregate,
        fieldType
      } = data;
      if (!isAggregate && singlePropertyDetails) {
        return singlePropertyDetails?.propertyEconomics?.thirdPartyValuation;
      }
      if (propertyDetails?.length) {
        if (isAggregate) {
          let returnVal = 0;
          propertyDetails.forEach((ele: any) => {
            const cur = ele.propertyEconomics?.thirdPartyValuation;
            if (cur) {
              returnVal += Number(sanitizeValueByType(cur, fieldType));
            }
          });
          return returnVal;
        }
        if (propertyId) {
          const property = propertyDetails.find((ele: any) => {
            return ele.loanPropertyId === propertyId;
          });
          return property?.propertyEconomics?.thirdPartyValuation;
        }
      }
      return null;
    }
  },
  {
    fieldId: "annualPropertyTaxes",
    fieldLabel: "Annual Property Taxes($)",
    fieldType: "currency",
    ruleFieldId: "annualPropertyTaxes",
    sectionId: "propertyEconomics",
    fieldCellStyle: commonCellStyles,
    ...customComponent,
    getValue: (data: any) => {
      const {
        propertyDetails,
        singlePropertyDetails,
        propertyId,
        isAggregate,
        fieldType
      } = data;
      if (!isAggregate && singlePropertyDetails) {
        return singlePropertyDetails?.propertyEconomics?.annualPropertyTaxes;
      }
      if (propertyDetails?.length) {
        if (isAggregate) {
          let returnVal = 0;
          propertyDetails.forEach((ele: any) => {
            const cur = ele.propertyEconomics?.annualPropertyTaxes;
            if (cur) {
              returnVal += Number(sanitizeValueByType(cur, fieldType));
            }
          });
          return returnVal;
        }
        if (propertyId) {
          const property = propertyDetails.find((ele: any) => {
            return ele.loanPropertyId === propertyId;
          });
          return property?.propertyEconomics?.annualPropertyTaxes;
        }
      }
      return null;
    }
  },
  {
    fieldId: "annualHazardInsurance",
    fieldLabel: "Annual Hazard Insurance($)",
    fieldType: "currency",
    ruleFieldId: "annualHazardInsurance",
    sectionId: "propertyEconomics",
    fieldCellStyle: commonCellStyles,
    ...customComponent,
    getValue: (data: any) => {
      const {
        propertyDetails,
        singlePropertyDetails,
        propertyId,
        isAggregate,
        fieldType
      } = data;
      if (!isAggregate && singlePropertyDetails) {
        return singlePropertyDetails?.propertyEconomics?.annualHazardInsurance;
      }
      if (propertyDetails?.length) {
        if (isAggregate) {
          let returnVal = 0;
          propertyDetails.forEach((ele: any) => {
            const cur = ele.propertyEconomics?.annualHazardInsurance;
            if (cur) {
              returnVal += Number(sanitizeValueByType(cur, fieldType));
            }
          });
          return returnVal;
        }
        if (propertyId) {
          const property = propertyDetails.find((ele: any) => {
            return ele.loanPropertyId === propertyId;
          });
          return property?.propertyEconomics?.annualHazardInsurance;
        }
      }
      return null;
    }
  },
  {
    fieldId: "annualFloodInsurance",
    fieldLabel: "Annual Flood Insurance($)",
    fieldType: "currency",
    ruleFieldId: "annualFloodInsurance",
    sectionId: "propertyEconomics",
    fieldCellStyle: commonCellStyles,
    ...customComponent,
    getValue: (data: any) => {
      const {
        propertyDetails,
        singlePropertyDetails,
        propertyId,
        isAggregate,
        fieldType
      } = data;
      if (!isAggregate && singlePropertyDetails) {
        return singlePropertyDetails?.propertyEconomics?.annualFloodInsurance;
      }
      if (propertyDetails?.length) {
        if (isAggregate) {
          let returnVal = 0;
          propertyDetails.forEach((ele: any) => {
            const cur = ele.propertyEconomics?.annualFloodInsurance;
            if (cur) {
              returnVal += Number(sanitizeValueByType(cur, fieldType));
            }
          });
          return returnVal;
        }
        if (propertyId) {
          const property = propertyDetails.find((ele: any) => {
            return ele.loanPropertyId === propertyId;
          });
          return property?.propertyEconomics?.annualFloodInsurance;
        }
      }
      return null;
    }
  },
  {
    fieldId: "annualHoaFee",
    fieldLabel: "Annual HOA Fee($)",
    fieldType: "currency",
    ruleFieldId: "annualHOAFee",
    sectionId: "propertyEconomics",
    fieldCellStyle: commonCellStyles,
    ...customComponent,
    getValue: (data: any) => {
      const {
        propertyDetails,
        singlePropertyDetails,
        propertyId,
        isAggregate,
        fieldType
      } = data;
      if (!isAggregate && singlePropertyDetails) {
        return singlePropertyDetails?.propertyEconomics?.annualHoaFee;
      }
      if (propertyDetails?.length) {
        if (isAggregate) {
          let returnVal = 0;
          propertyDetails.forEach((ele: any) => {
            const cur = ele.propertyEconomics?.annualHoaFee;
            if (cur) {
              returnVal += Number(sanitizeValueByType(cur, fieldType));
            }
          });
          return returnVal;
        }
        if (propertyId) {
          const property = propertyDetails.find((ele: any) => {
            return ele.loanPropertyId === propertyId;
          });
          return property?.propertyEconomics?.annualHoaFee;
        }
      }
      return null;
    }
  },
  {
    fieldId: "marketRentMonthly",
    fieldLabel: "Market Rent Monthly($)",
    fieldType: "currency",
    ruleFieldId: "marketRentMonthly",
    sectionId: "propertyUnit",
    fieldCellStyle: firstGroupColumnStyle,
    ...UnitInfoComponents,
    getValue: (data: any) => {
      const {
        propertyDetails,
        singlePropertyDetails,
        propertyId,
        isAggregate,
        perUnitValue,
        unitObj,
        fieldType
      } = data;
      function getUnitDataByProperty(property: any) {
        if (property) {
          let returnVal = 0;
          const units = property.propertyUnit;

          if (units?.length) {
            units.forEach((element: any) => {
              const cur = element.marketRentMonthly;
              returnVal += Number(sanitizeValueByType(cur, fieldType));
            });
          }
          if (perUnitValue && unitObj) {
            return unitObj?.marketRentMonthly;
          }
          return returnVal;
        }
        return null;
      }
      if (!isAggregate && singlePropertyDetails) {
        return getUnitDataByProperty(singlePropertyDetails);
      }
      if (propertyDetails?.length) {
        if (isAggregate) {
          let returnVal = 0;
          propertyDetails.forEach((ele: any) => {
            const units = ele.propertyUnit;
            if (units?.length) {
              units.forEach((element: any) => {
                const cur = element.marketRentMonthly;
                returnVal += Number(sanitizeValueByType(cur, fieldType));
              });
            }
          });
          return returnVal;
        }
        if (propertyId) {
          const property = propertyDetails.find((ele: any) => {
            return ele.loanPropertyId === propertyId;
          });
          return getUnitDataByProperty(property);
        }
      }
      return null;
    }
  },
  {
    fieldId: "inPlaceLeaseRentMonthly",
    fieldLabel: "In Place Lease Rent Monthly($)",
    fieldType: "currency",
    ruleFieldId: "inPlaceLeaseRentMonthly",
    sectionId: "propertyUnit",
    fieldCellStyle: commonCellStyles,
    ...UnitInfoComponents,
    isDisabled: (data: any) => {
      // this is handled only per unit object now. by default all fields are editable.
      const { unitObj } = data;
      if (unitObj) {
        const leaseStatus = unitObj?.leaseStatus;
        const requiredLeaseStatus = getRequiredLeaseStatus(
          "inPlaceLeaseRentMonthly"
        );
        if (requiredLeaseStatus.includes(leaseStatus)) {
          return false;
        }
        return true;
      }
      return false;
    },
    getValue: (data: any) => {
      const {
        propertyDetails,
        singlePropertyDetails,
        propertyId,
        isAggregate,
        perUnitValue,
        fieldType,
        unitObj
      } = data;
      function getUnitDataByProperty(property: any) {
        if (property) {
          let returnVal = 0;
          const units = property.propertyUnit;

          if (units?.length) {
            units.forEach((element: any) => {
              const cur = element.inPlaceLeaseRentMonthly;
              returnVal += Number(sanitizeValueByType(cur, fieldType));
            });
          }
          if (unitObj && perUnitValue) {
            return unitObj?.inPlaceLeaseRentMonthly;
          }
          return returnVal;
        }
        return null;
      }
      if (!isAggregate && singlePropertyDetails) {
        return getUnitDataByProperty(singlePropertyDetails);
      }
      if (propertyDetails?.length) {
        if (isAggregate) {
          let returnVal = 0;
          propertyDetails.forEach((ele: any) => {
            const units = ele.propertyUnit;
            if (units?.length) {
              units.forEach((element: any) => {
                const cur = element.inPlaceLeaseRentMonthly;
                returnVal += Number(sanitizeValueByType(cur, fieldType));
              });
            }
          });
          return returnVal;
        }
        if (propertyId) {
          const property = propertyDetails.find((ele: any) => {
            return ele.loanPropertyId === propertyId;
          });
          return getUnitDataByProperty(property);
        }
      }
      return null;
    }
  },
  {
    fieldId: "monthlyGrossRent",
    excludeFromEditTable: true,
    fieldLabel: "Monthly Unit Rent($)",
    fieldType: "currency",
    ruleFieldId: "monthlyGrossRent",
    isRulesOutput: true,
    sectionId: "propertyUnit",
    fieldCellStyle: commonCellStyles,
    ...customComponent,
    getValue: (data: any) => {
      const {
        propertyDetails,
        propertyId,
        isAggregate,
        perUnitValue,
        fieldType,
        unitId,
        loanEvaluationResult
      } = data;
      function getUnitResult(unitIdentifier: any, perPropResult: any) {
        const unitResult = perPropResult[`unit${unitIdentifier}`];
        return unitResult?.monthlyGrossRent;
      }
      function getResultByProperty(
        property: any,
        propertiesResults: any,
        aggregatePerProp: boolean,
        unitIdentifier?: any
      ) {
        const propertyResult = propertiesResults[property.loanPropertyId];
        if (propertyResult) {
          if (aggregatePerProp) {
            let returnVal = 0;
            const units = property?.propertyUnit || [];
            units.forEach((ele: any) => {
              const unitResult = getUnitResult(
                ele.propertyUnitId,
                propertyResult
              );
              returnVal += Number(sanitizeValueByType(unitResult, fieldType));
            });
            return returnVal;
          }
          if (unitIdentifier) {
            return getUnitResult(unitIdentifier, propertyResult);
          }
        }
        return null;
      }
      const propertiesResults = loanEvaluationResult?.propertiesResults;
      if (propertiesResults && propertyDetails.length) {
        if (isAggregate) {
          let returnVal = 0;
          propertyDetails.forEach((element: any) => {
            const propertyAggregate = getResultByProperty(
              element,
              propertiesResults,
              true
            );
            returnVal += Number(
              sanitizeValueByType(propertyAggregate, fieldType)
            );
          });
          return returnVal;
        }
        const property = propertyDetails.find(
          (ele: any) => ele.loanPropertyId === propertyId
        );
        if (unitId && propertyId && perUnitValue) {
          return getResultByProperty(
            property,
            propertiesResults,
            false,
            unitId
          );
        }
        return getResultByProperty(property, propertiesResults, true);
      }
      return null;
    }
  }
];
const dscrLoanFieldsFES: FieldInfo[] = [
  // {
  //   fieldId: "costBasis",
  //   fieldLabel: "Cost Basis (After Cost Incurred)($)",
  //   fieldType: "currency",
  //   ruleFieldId: "costBasis",
  //   sectionId: "propertyEconomics",
  //   fieldCellStyle: commonCellStyles,
  //   ...customComponent,
  //   getValue: (data: any) => {
  //     const {
  //       propertyDetails,
  //       singlePropertyDetails,
  //       propertyId,
  //       isAggregate,
  //       fieldType
  //     } = data;
  //     if (!isAggregate && singlePropertyDetails) {
  //       return singlePropertyDetails?.propertyEconomics?.costBasis;
  //     }
  //     if (propertyDetails?.length) {
  //       if (isAggregate) {
  //         let returnVal = 0;
  //         propertyDetails.forEach((ele: any) => {
  //           const cur = ele.propertyEconomics?.costBasis;
  //           if (cur) {
  //             returnVal += Number(sanitizeValueByType(cur, fieldType));
  //           }
  //         });
  //         return returnVal;
  //       }
  //       if (propertyId) {
  //         const property = propertyDetails.find((ele: any) => {
  //           return ele.loanPropertyId === propertyId;
  //         });
  //         return property?.propertyEconomics?.costBasis;
  //       }
  //     }
  //     return null;
  //   }
  // },
  {
    fieldId: "originalAsIsAppraisalValue",
    fieldLabel: "As Is Valuation($)",
    fieldType: "currency",
    ruleFieldId: "originalAsIsAppraisalValue",
    sectionId: "propertyEconomics",
    fieldCellStyle: commonCellStyles,
    ...customComponent,
    getValue: (data: any) => {
      const {
        propertyDetails,
        singlePropertyDetails,
        propertyId,
        isAggregate,
        fieldType
      } = data;
      if (!isAggregate && singlePropertyDetails) {
        return singlePropertyDetails?.propertyEconomics
          ?.originalAsIsAppraisalValue;
      }
      if (propertyDetails?.length) {
        if (isAggregate) {
          let returnVal = 0;
          propertyDetails.forEach((ele: any) => {
            const cur = ele.propertyEconomics?.originalAsIsAppraisalValue;
            if (cur) {
              returnVal += Number(sanitizeValueByType(cur, fieldType));
            }
          });
          return returnVal;
        }
        if (propertyId) {
          const property = propertyDetails.find((ele: any) => {
            return ele.loanPropertyId === propertyId;
          });
          return property?.propertyEconomics?.originalAsIsAppraisalValue;
        }
      }
      return null;
    }
  },
  {
    fieldId: "purchasePrice",
    fieldLabel: "Property Acquisition Price($)",
    fieldType: "currency",
    ruleFieldId: "purchasePrice",
    sectionId: "propertyEconomics",
    fieldCellStyle: commonCellStyles,
    ...customComponent,
    getValue: (data: any) => {
      const {
        propertyDetails,
        singlePropertyDetails,
        propertyId,
        isAggregate,
        fieldType
      } = data;
      if (!isAggregate && singlePropertyDetails) {
        return singlePropertyDetails?.propertyEconomics?.purchasePrice;
      }
      if (propertyDetails?.length) {
        if (isAggregate) {
          let returnVal = 0;
          propertyDetails.forEach((ele: any) => {
            const cur = ele.propertyEconomics?.purchasePrice;
            if (cur) {
              returnVal += Number(sanitizeValueByType(cur, fieldType));
            }
          });
          return returnVal;
        }
        if (propertyId) {
          const property = propertyDetails.find((ele: any) => {
            return ele.loanPropertyId === propertyId;
          });
          return property?.propertyEconomics?.purchasePrice;
        }
      }
      return null;
    }
  },
  {
    fieldId: "thirdPartyValuation",
    fieldLabel: "Third Party Valuation - Property Value($)",
    fieldType: "currency",
    ruleFieldId: "thirdPartyValuation",
    sectionId: "propertyEconomics",
    fieldCellStyle: commonCellStyles,
    ...customComponent,
    getValue: (data: any) => {
      const {
        propertyDetails,
        singlePropertyDetails,
        propertyId,
        isAggregate,
        fieldType
      } = data;
      if (!isAggregate && singlePropertyDetails) {
        return singlePropertyDetails?.propertyEconomics?.thirdPartyValuation;
      }
      if (propertyDetails?.length) {
        if (isAggregate) {
          let returnVal = 0;
          propertyDetails.forEach((ele: any) => {
            const cur = ele.propertyEconomics?.thirdPartyValuation;
            if (cur) {
              returnVal += Number(sanitizeValueByType(cur, fieldType));
            }
          });
          return returnVal;
        }
        if (propertyId) {
          const property = propertyDetails.find((ele: any) => {
            return ele.loanPropertyId === propertyId;
          });
          return property?.propertyEconomics?.thirdPartyValuation;
        }
      }
      return null;
    }
  },
  {
    fieldId: "annualPropertyTaxes",
    fieldLabel: "Annual Property Taxes($)",
    fieldType: "currency",
    ruleFieldId: "annualPropertyTaxes",
    sectionId: "propertyEconomics",
    fieldCellStyle: commonCellStyles,
    ...customComponent,
    getValue: (data: any) => {
      const {
        propertyDetails,
        singlePropertyDetails,
        propertyId,
        isAggregate,
        fieldType
      } = data;
      if (!isAggregate && singlePropertyDetails) {
        return singlePropertyDetails?.propertyEconomics?.annualPropertyTaxes;
      }
      if (propertyDetails?.length) {
        if (isAggregate) {
          let returnVal = 0;
          propertyDetails.forEach((ele: any) => {
            const cur = ele.propertyEconomics?.annualPropertyTaxes;
            if (cur) {
              returnVal += Number(sanitizeValueByType(cur, fieldType));
            }
          });
          return returnVal;
        }
        if (propertyId) {
          const property = propertyDetails.find((ele: any) => {
            return ele.loanPropertyId === propertyId;
          });
          return property?.propertyEconomics?.annualPropertyTaxes;
        }
      }
      return null;
    }
  },
  {
    fieldId: "annualHazardInsurance",
    fieldLabel: "Annual Hazard Insurance($)",
    fieldType: "currency",
    ruleFieldId: "annualHazardInsurance",
    sectionId: "propertyEconomics",
    fieldCellStyle: commonCellStyles,
    ...customComponent,
    getValue: (data: any) => {
      const {
        propertyDetails,
        singlePropertyDetails,
        propertyId,
        isAggregate,
        fieldType
      } = data;
      if (!isAggregate && singlePropertyDetails) {
        return singlePropertyDetails?.propertyEconomics?.annualHazardInsurance;
      }
      if (propertyDetails?.length) {
        if (isAggregate) {
          let returnVal = 0;
          propertyDetails.forEach((ele: any) => {
            const cur = ele.propertyEconomics?.annualHazardInsurance;
            if (cur) {
              returnVal += Number(sanitizeValueByType(cur, fieldType));
            }
          });
          return returnVal;
        }
        if (propertyId) {
          const property = propertyDetails.find((ele: any) => {
            return ele.loanPropertyId === propertyId;
          });
          return property?.propertyEconomics?.annualHazardInsurance;
        }
      }
      return null;
    }
  },
  {
    fieldId: "annualFloodInsurance",
    fieldLabel: "Annual Flood Insurance($)",
    fieldType: "currency",
    ruleFieldId: "annualFloodInsurance",
    sectionId: "propertyEconomics",
    fieldCellStyle: commonCellStyles,
    ...customComponent,
    getValue: (data: any) => {
      const {
        propertyDetails,
        singlePropertyDetails,
        propertyId,
        isAggregate,
        fieldType
      } = data;
      if (!isAggregate && singlePropertyDetails) {
        return singlePropertyDetails?.propertyEconomics?.annualFloodInsurance;
      }
      if (propertyDetails?.length) {
        if (isAggregate) {
          let returnVal = 0;
          propertyDetails.forEach((ele: any) => {
            const cur = ele.propertyEconomics?.annualFloodInsurance;
            if (cur) {
              returnVal += Number(sanitizeValueByType(cur, fieldType));
            }
          });
          return returnVal;
        }
        if (propertyId) {
          const property = propertyDetails.find((ele: any) => {
            return ele.loanPropertyId === propertyId;
          });
          return property?.propertyEconomics?.annualFloodInsurance;
        }
      }
      return null;
    }
  },
  {
    fieldId: "annualHoaFee",
    fieldLabel: "Annual HOA Fee($)",
    fieldType: "currency",
    ruleFieldId: "annualHOAFee",
    sectionId: "propertyEconomics",
    fieldCellStyle: commonCellStyles,
    ...customComponent,
    getValue: (data: any) => {
      const {
        propertyDetails,
        singlePropertyDetails,
        propertyId,
        isAggregate,
        fieldType
      } = data;
      if (!isAggregate && singlePropertyDetails) {
        return singlePropertyDetails?.propertyEconomics?.annualHoaFee;
      }
      if (propertyDetails?.length) {
        if (isAggregate) {
          let returnVal = 0;
          propertyDetails.forEach((ele: any) => {
            const cur = ele.propertyEconomics?.annualHoaFee;
            if (cur) {
              returnVal += Number(sanitizeValueByType(cur, fieldType));
            }
          });
          return returnVal;
        }
        if (propertyId) {
          const property = propertyDetails.find((ele: any) => {
            return ele.loanPropertyId === propertyId;
          });
          return property?.propertyEconomics?.annualHoaFee;
        }
      }
      return null;
    }
  },
  {
    fieldId: "marketRentMonthly",
    fieldLabel: "Market Rent Monthly($)",
    fieldType: "currency",
    ruleFieldId: "marketRentMonthly",
    sectionId: "propertyUnit",
    fieldCellStyle: firstGroupColumnStyle,
    ...UnitInfoComponents,
    getValue: (data: any) => {
      const {
        propertyDetails,
        singlePropertyDetails,
        propertyId,
        isAggregate,
        perUnitValue,
        unitObj,
        fieldType
      } = data;
      function getUnitDataByProperty(property: any) {
        if (property) {
          let returnVal = 0;
          const units = property.propertyUnit;

          if (units?.length) {
            units.forEach((element: any) => {
              const cur = element.marketRentMonthly;
              returnVal += Number(sanitizeValueByType(cur, fieldType));
            });
          }
          if (perUnitValue && unitObj) {
            return unitObj?.marketRentMonthly;
          }
          return returnVal;
        }
        return null;
      }
      if (!isAggregate && singlePropertyDetails) {
        return getUnitDataByProperty(singlePropertyDetails);
      }
      if (propertyDetails?.length) {
        if (isAggregate) {
          let returnVal = 0;
          propertyDetails.forEach((ele: any) => {
            const units = ele.propertyUnit;
            if (units?.length) {
              units.forEach((element: any) => {
                const cur = element.marketRentMonthly;
                returnVal += Number(sanitizeValueByType(cur, fieldType));
              });
            }
          });
          return returnVal;
        }
        if (propertyId) {
          const property = propertyDetails.find((ele: any) => {
            return ele.loanPropertyId === propertyId;
          });
          return getUnitDataByProperty(property);
        }
      }
      return null;
    }
  },
  {
    fieldId: "inPlaceLeaseRentMonthly",
    fieldLabel: "In Place Lease Rent Monthly($)",
    fieldType: "currency",
    ruleFieldId: "inPlaceLeaseRentMonthly",
    sectionId: "propertyUnit",
    fieldCellStyle: commonCellStyles,
    ...UnitInfoComponents,
    isDisabled: (data: any) => {
      // this is handled only per unit object now. by default all fields are editable.
      const { unitObj } = data;
      if (unitObj) {
        const leaseStatus = unitObj?.leaseStatus;
        const requiredLeaseStatus = getRequiredLeaseStatus(
          "inPlaceLeaseRentMonthly"
        );
        if (requiredLeaseStatus.includes(leaseStatus)) {
          return false;
        }
        return true;
      }
      return false;
    },
    getValue: (data: any) => {
      const {
        propertyDetails,
        singlePropertyDetails,
        propertyId,
        isAggregate,
        perUnitValue,
        fieldType,
        unitObj
      } = data;
      function getUnitDataByProperty(property: any) {
        if (property) {
          let returnVal = 0;
          const units = property.propertyUnit;

          if (units?.length) {
            units.forEach((element: any) => {
              const cur = element.inPlaceLeaseRentMonthly;
              returnVal += Number(sanitizeValueByType(cur, fieldType));
            });
          }
          if (unitObj && perUnitValue) {
            return unitObj?.inPlaceLeaseRentMonthly;
          }
          return returnVal;
        }
        return null;
      }
      if (!isAggregate && singlePropertyDetails) {
        return getUnitDataByProperty(singlePropertyDetails);
      }
      if (propertyDetails?.length) {
        if (isAggregate) {
          let returnVal = 0;
          propertyDetails.forEach((ele: any) => {
            const units = ele.propertyUnit;
            if (units?.length) {
              units.forEach((element: any) => {
                const cur = element.inPlaceLeaseRentMonthly;
                returnVal += Number(sanitizeValueByType(cur, fieldType));
              });
            }
          });
          return returnVal;
        }
        if (propertyId) {
          const property = propertyDetails.find((ele: any) => {
            return ele.loanPropertyId === propertyId;
          });
          return getUnitDataByProperty(property);
        }
      }
      return null;
    }
  },
  {
    fieldId: "monthlyGrossRent",
    excludeFromEditTable: true,
    fieldLabel: "Monthly Unit Rent($)",
    fieldType: "currency",
    ruleFieldId: "monthlyGrossRent",
    isRulesOutput: true,
    sectionId: "propertyUnit",
    fieldCellStyle: commonCellStyles,
    ...customComponent,
    getValue: (data: any) => {
      const {
        propertyDetails,
        propertyId,
        isAggregate,
        perUnitValue,
        fieldType,
        unitId,
        loanEvaluationResult
      } = data;
      function getUnitResult(unitIdentifier: any, perPropResult: any) {
        const unitResult = perPropResult[`unit${unitIdentifier}`];
        return unitResult?.monthlyGrossRent;
      }
      function getResultByProperty(
        property: any,
        propertiesResults: any,
        aggregatePerProp: boolean,
        unitIdentifier?: any
      ) {
        const propertyResult = propertiesResults[property.loanPropertyId];
        if (propertyResult) {
          if (aggregatePerProp) {
            let returnVal = 0;
            const units = property?.propertyUnit || [];
            units.forEach((ele: any) => {
              const unitResult = getUnitResult(
                ele.propertyUnitId,
                propertyResult
              );
              returnVal += Number(sanitizeValueByType(unitResult, fieldType));
            });
            return returnVal;
          }
          if (unitIdentifier) {
            return getUnitResult(unitIdentifier, propertyResult);
          }
        }
        return null;
      }
      const propertiesResults = loanEvaluationResult?.propertiesResults;
      if (propertiesResults && propertyDetails.length) {
        if (isAggregate) {
          let returnVal = 0;
          propertyDetails.forEach((element: any) => {
            const propertyAggregate = getResultByProperty(
              element,
              propertiesResults,
              true
            );
            returnVal += Number(
              sanitizeValueByType(propertyAggregate, fieldType)
            );
          });
          return returnVal;
        }
        const property = propertyDetails.find(
          (ele: any) => ele.loanPropertyId === propertyId
        );
        if (unitId && propertyId && perUnitValue) {
          return getResultByProperty(
            property,
            propertiesResults,
            false,
            unitId
          );
        }
        return getResultByProperty(property, propertiesResults, true);
      }
      return null;
    }
  }
];
const firstRowColumnHeader: FieldInfo[] = [
  {
    fieldId: "firstColumnFirstRow",
    fieldLabel: "", // empty cell in first row first column
    fieldType: "string",
    ruleFieldId: "",
    sectionId: "firstColumnFirstRow",
    fieldCellStyle: {
      style: {
        minWidth: 190,
        maxWidth: 190,
        border: "none"
      }
    },
    getValue: (data: any) => {
      return null;
    }
  }
];
const secondRowColumnHeader: FieldInfo[] = [
  {
    fieldId: "firstColumnsecondRow",
    fieldLabel: "Aggregated Values",
    fieldType: "string",
    ruleFieldId: "",
    sectionId: "firstColumnsecondRow",
    fieldCellStyle: {
      style: {
        minWidth: 190,
        maxWidth: 190,
        border: "none"
      }
    },
    getValue: (data: any) => {
      return null;
    }
  }
];
const allocateOrDivideLabelHeader: FieldInfo[] = [
  {
    fieldId: "allocateOrDivide",
    fieldLabel: "Aggregation / Distribution",
    fieldType: "string",
    ruleFieldId: "",
    sectionId: "allocateOrDivide",
    fieldCellStyle: {
      style: {
        minWidth: 190,
        maxWidth: 190,
        border: "none"
      }
    },
    getValue: (data: any) => {
      return null;
    }
  }
];
const propertyAddressColumn: FieldInfo[] = [
  {
    fieldId: "propertyAddress",
    fieldLabel: "Property Address",
    fieldType: "string",
    ruleFieldId: "",
    sectionId: "propertyAddress",
    fieldCellStyle: {
      style: {
        minWidth: 190,
        maxWidth: 190,
        border: "none"
      }
    },
    ...PropertyAddressComponent,
    getValue: (data: any) => {
      return null;
    }
  }
];
const expandCollapseField: FieldInfo = {
  fieldId: "expandCollapseField",
  fieldLabel: "Expand Collapse Field",
  fieldType: "icon",
  ruleFieldId: "",
  sectionId: "expandCollapseField",
  fieldCellStyle: {
    style: {
      minWidth: 30,
      maxWidth: 30,
      padding: 5,
      border: "none",
      borderTopRightRadius: 6,
      borderBottomRightRadius: 6
    }
  },
  ...ExpandCollapseComponent,
  getValue: (data: any) => {
    return null;
  }
};
const getRows = (fields: FieldInfo[], extraRows: FieldInfo[]) => {
  const cols = fields.filter(ele => !ele.excludeFromEditTable);
  const fieldSet = [...cols];
  fieldSet.unshift(...extraRows);
  return fieldSet;
};
const getExtraRowHeaders = (rowId: string) => {
  switch (rowId) {
    case "firstRowForLabel":
      return firstRowColumnHeader;
    case "aggregateValueRow":
      return secondRowColumnHeader;
    case "propertyAddressColumn":
      return propertyAddressColumn;
    case "aggregateDistributeRow":
      return allocateOrDivideLabelHeader;
    default:
      return [];
  }
};

/* ------------------------------------CONFIGS ENDS HERE------------------------------------ */

/* ------------------------FUCNTIONS FOR AGGREGATE PROPERTY STARTSHERE---------------------- */

const commonRowBorder = {
  // borderTop: "1px solid white",
  borderBottom: "1px solid white"
};
export const getRowSpecificData = (rowId: string) => {
  switch (rowId) {
    case "firstRowForLabel":
      return {
        rowId: "firstRowForLabel",
        rowStyle: { height: 60, borderBottom: "1px solid white" }
      };
    case "aggregateValueRow":
      return {
        rowId: "aggregateValueRow",
        rowStyle: {
          height: 60,
          backgroundColor: "#e9fafd",
          ...commonRowBorder
        }
      };
    case "aggregateDistributeRow":
      return {
        rowId: "aggregateDistributeRow",
        rowStyle: {
          height: 60,
          backgroundColor: "#f6f9fc",
          ...commonRowBorder
        }
      };
    default:
      return { rowStyle: { ...commonRowBorder } };
  }
};
export const getRowHeader = (
  loanType: string,
  loanStage: LoanStage,
  rowId: string
): RowObjType => {
  const returnVal: FieldInfo[] = getRows(
    getAggregatePropertyFields(loanType, loanStage),
    getExtraRowHeaders(rowId)
  );
  if (loanType === getLoanType[1].displayValue) {
    returnVal.push(expandCollapseField);
  }

  return { ...getRowSpecificData(rowId), fields: returnVal };
};

export const getColumns = (loanType: string, loanStage: LoanStage) => {
  const returnVal: FieldInfo[] = getRows(
    getAggregatePropertyFields(loanType, loanStage),
    getExtraRowHeaders("propertyAddressColumn")
  );
  if (loanType === getLoanType[1].displayValue) {
    returnVal.push(expandCollapseField);
  }
  return {
    rowStyle: { ...commonRowBorder },
    fields: returnVal,
    columnGroups:
      loanType === getLoanType[1].displayValue
        ? {
            hasGroup: true,
            style: {
              backgroundColor: "#f6f9fc",
              padding: 6,
              borderRadius: 6,
              border: "1px solid #e9ecef"
            },
            groupRange: {
              startIndex: 8
            },
            isExpanded: (data: any) => getIsExpanded(data)
          }
        : undefined
  };
};

export function getPropertyAggregateFieldValue(
  getValue: any,
  fieldType: string,
  functionArgs: any,
  noFormatting?: boolean
) {
  try {
    const val = typeof getValue === "function" ? getValue(functionArgs) : null;
    if (noFormatting) return val;
    return formatValueByType(`${val}`, fieldType);
  } catch (error) {
    console.log(error);
    return null;
  }
}
export function getAggregatePropertyFields(
  loanType: string,
  loanStage: LoanStage
) {
  switch (loanType) {
    case getLoanType[0].displayValue:
      return loanStage === LoanStage.fes
        ? bridgeLoanFieldsFES
        : bridgeLoanFields;
    case getLoanType[1].displayValue:
      return loanStage === LoanStage.fes ? dscrLoanFieldsFES : dscrLoanFields;
    default:
      return [] as FieldInfo[];
  }
}
