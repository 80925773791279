/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { LoanStage } from "@toorak/tc-common-fe-sdk";
import { RootState } from "../../stores/rootReducer";
import { ExceptionsCard } from "./ExceptionsCard";
// import { ZeroState as ZeroStateException } from "./ZeroState";
import { getLoanById, getTags } from "../create-loan.action";
import {
  getWaiverByLoanId
  //resetExceptionStore
} from "../../stores/ExceptionsResults/ExceptionActions";
import { isLATUser, isILP } from "../../utils/AccessManagement";
import { ResponseTypeEnum } from "../../utils/constants";
import { InputSectionWrapper } from "../../credit-evaluation/InputSectionWrapper";
import { mapWaiverToExceptions } from "./ExceptionsAndEnquiries";
import { getLoanType } from "../../config/config";
import { resetExceptionStore } from "../../stores/ExceptionsResults/ExceptionsReducer";

const ZeroStateException = React.lazy(() =>
  import("./ZeroState").then((module) => ({
    default: module.ZeroState
  }))
);

export const AllExceptionsAndEnquiries = (props?: any) => {
  const { spanValue, sidePanel } = props;
  const dispatch = useDispatch();
  const exceptionsResult = useSelector<RootState, any>(
    (state) => state.exceptionsResultsStore?.exceptionsResult?.exceptions
  );
  const { takeoutPartnerId } = useSelector<RootState, any>(
    (state) =>
      state.createLoanStore?.loanDetails.thirtyYearLoanInformation.payload
  );
  const waiversResult = useSelector<RootState, any>(
    (state) => state.exceptionsResultsStore?.waiverResult.waivers
  );

  const { filteredExceptionsArray } = useSelector<RootState, any>(
    (state) => state.exceptionsResultsStore
  );

  const org = mapWaiverToExceptions(exceptionsResult, waiversResult);

  const exceptionsAndEnq: any = sidePanel
    ? org
    : filteredExceptionsArray?.exceptions ?? [];

  const { tags, loanDetails } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );

  let sortedExceptionResultsOrg = useMemo(() => {
    if (!(exceptionsAndEnq instanceof Array)) return exceptionsAndEnq;

    return !isLATUser()
      ? exceptionsAndEnq.filter((item: any) => item.visibility !== "internal")
      : exceptionsAndEnq;
  }, [exceptionsAndEnq]);

  sortedExceptionResultsOrg =
    sortedExceptionResultsOrg instanceof Array
      ? sortedExceptionResultsOrg.slice().sort((a: any, b: any) => {
          let adate;
          let bdate;
          if (a.type.toLowerCase() === "inquiry") {
            const sentToOriginatorInfoOfA = a.response.find(
              (val: any) =>
                val.responseType === ResponseTypeEnum.SendtoOriginator
            );
            if (sentToOriginatorInfoOfA) {
              adate = sentToOriginatorInfoOfA.createdOn;
            } else {
              adate = a.createdOn;
            }
          } else {
            adate = a.createdOn;
          }
          if (b.type.toLowerCase() === "inquiry") {
            const sentToOriginatorInfoOfB = b.response.find(
              (val: any) =>
                val.responseType === ResponseTypeEnum.SendtoOriginator
            );
            if (sentToOriginatorInfoOfB) {
              bdate = sentToOriginatorInfoOfB.createdOn;
            } else {
              bdate = b.createdOn;
            }
          } else {
            bdate = b.createdOn;
          }
          return Date.parse(bdate) - Date.parse(adate);
        })
      : sortedExceptionResultsOrg;

  const exceptionUserAndAccountDetails = useSelector<RootState, any>(
    (state) => state.exceptionsResultsStore?.exceptionsResult
  );
  const { loanId, loanType, loanStage } = useParams<{
    loanId: string;
    loanStage: LoanStage;
    loanType: string;
  }>() as any;
  const [ddName, setDDname] = useState<any>("");
  useEffect(() => {
    const promise1 = getLoanById(loanId, loanStage);
    Promise.all([promise1]).then(async (resp: any) => {
      const accountName = resp[0]?.data?.dueDiligencePartyInfo?.accountName;
      setDDname(accountName);
    });
    const exceptions =
      exceptionsResult &&
      exceptionsResult instanceof Array &&
      exceptionsResult
        .filter((item: any) => {
          return item.visibility === "external";
        })
        .map((item: any) => {
          return item.exceptionId;
        });
    if (exceptions && exceptions.length) {
      dispatch(
        getWaiverByLoanId(loanId, loanStage, undefined, undefined, exceptions)
      );
    } else {
      getWaiverByLoanId(loanId, loanStage);
    }
  }, [loanId, loanStage, exceptionsResult, dispatch]);
  useEffect(() => {
    dispatch(resetExceptionStore());
  }, [dispatch]);

  useEffect(() => {
    if (!tags?.tags?.length) {
      if (loanType === getLoanType[1].displayValue) {
        dispatch(getTags("30_YR_Loan", loanStage, "SUBMIT"));
        if (loanStage === LoanStage.post) {
          dispatch(getTags("30_YR_Loan", loanStage, "APPROVED"));
        }
      } else {
        dispatch(getTags("BRIDGE", loanStage, "SUBMIT"));
        if (loanStage === LoanStage.post) {
          dispatch(getTags("BRIDGE", loanStage, "APPROVED"));
        }
      }
    }
    // eslint-disable-next-line
  }, []);

  const conditions =
    sortedExceptionResultsOrg && sortedExceptionResultsOrg.length > 0
      ? sortedExceptionResultsOrg.filter(
          (item: any) => item.type.toLowerCase() === "exception"
        )
      : "";
  const inquiries =
    // eslint-disable-next-line no-nested-ternary
    sortedExceptionResultsOrg && sortedExceptionResultsOrg.length > 0
      ? isILP()
        ? sortedExceptionResultsOrg.filter(
            (item: any) => item.type.toLowerCase() === "inquiry"
          )
        : sortedExceptionResultsOrg.filter(
            (item: any) =>
              item.type.toLowerCase() === "inquiry" &&
              item.visibility !== "internal"
          )
      : "";

  return (
    <>
      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        data-tesid="all-exceptions-wrapper"
        className="all-exception-wrapper"
      >
        <section data-testid="all-exceptions-enquiries">
          {sortedExceptionResultsOrg instanceof Array &&
          sortedExceptionResultsOrg.length > 0 ? (
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              data-tesid="all-exceptions-container"
            >
              {sidePanel && conditions && conditions.length > 0 && (
                <>
                  <InputSectionWrapper
                    sectionLabel={`CONDITIONS (${conditions.length})`}
                    sectionId="input-scenario-id"
                  >
                    {conditions.map(
                      (item: any) =>
                        item.type && (
                          <div style={{ backgroundColor: "#f8f9ff" }}>
                            <div
                              key={item.exceptionId}
                              style={{
                                borderRadius: spanValue ? "8px" : "0px",
                                border: spanValue
                                  ? "solid 1px #d6d8ea"
                                  : "0px solid",
                                margin: spanValue ? "4px 5px 3px 5px" : "0px",
                                backgroundColor: "#ffffff"
                              }}
                              className="export-pdf-card"
                            >
                              <ExceptionsCard
                                exceptionsResult={item}
                                isViewDetailsVisible
                                loanDetails={ddName}
                                spanValue={spanValue}
                                exceptionId={item.exceptionId}
                                exceptionUserAndAccountDetails={
                                  exceptionUserAndAccountDetails
                                }
                                waivers={item.waivers}
                                sidePanel={sidePanel}
                                orgId={loanDetails?.originatorInfo?.partyId}
                              />
                            </div>
                          </div>
                        )
                    )}
                  </InputSectionWrapper>
                </>
              )}

              {sidePanel && inquiries && inquiries.length > 0 && (
                <>
                  <InputSectionWrapper
                    sectionLabel={`INQUIRIES (${inquiries.length})`}
                    sectionId="input-scenario-id"
                  >
                    {inquiries.map(
                      (item: any) =>
                        item.type && (
                          <div style={{ backgroundColor: "#f8f9ff" }}>
                            <div
                              key={item.exceptionId}
                              style={{
                                borderRadius: spanValue ? "8px" : "0px",
                                border: spanValue
                                  ? "solid 1px #d6d8ea"
                                  : "0px solid",
                                margin: spanValue ? "7px 5px 0px 5px" : "0px",
                                backgroundColor: "#ffffff"
                              }}
                              className="export-pdf-card"
                            >
                              <ExceptionsCard
                                exceptionsResult={item}
                                isViewDetailsVisible
                                loanDetails={ddName}
                                spanValue={spanValue}
                                exceptionId={item.exceptionId}
                                exceptionUserAndAccountDetails={
                                  exceptionUserAndAccountDetails
                                }
                                waivers={item?.waivers}
                                sidePanel={sidePanel}
                                orgId={loanDetails?.originatorInfo?.partyId}
                              />
                            </div>
                          </div>
                        )
                    )}
                  </InputSectionWrapper>
                </>
              )}

              {!sidePanel && sortedExceptionResultsOrg instanceof Array
                ? sortedExceptionResultsOrg &&
                  loanType === getLoanType[1].displayValue
                  ? sortedExceptionResultsOrg
                      .filter(
                        (item) => item.takeoutPartnerId === takeoutPartnerId
                      )
                      .map(
                        (item: any, index: number) =>
                          item.type && (
                            <div
                              key={item.exceptionId}
                              style={{
                                borderRadius: spanValue ? "8px" : "0px",
                                border: spanValue
                                  ? "solid 1px #d6d8ea"
                                  : "0px solid",
                                margin: spanValue ? "10px" : "0px"
                              }}
                              className="export-pdf-card"
                            >
                              <ExceptionsCard
                                exceptionsResult={item}
                                isViewDetailsVisible
                                loanDetails={ddName}
                                spanValue={spanValue}
                                exceptionId={item.exceptionId}
                                waivers={item.waivers}
                                exceptionUserAndAccountDetails={
                                  exceptionUserAndAccountDetails
                                }
                                index={index}
                                orgId={loanDetails?.originatorInfo?.partyId}
                              />
                            </div>
                          )
                      )
                  : sortedExceptionResultsOrg.map(
                      (item: any, index: number) =>
                        item.type && (
                          <div
                            key={item.exceptionId}
                            style={{
                              borderRadius: spanValue ? "8px" : "0px",
                              border: spanValue
                                ? "solid 1px #d6d8ea"
                                : "0px solid",
                              margin: spanValue ? "10px" : "0px"
                            }}
                            className="export-pdf-card"
                          >
                            <ExceptionsCard
                              exceptionsResult={item}
                              isViewDetailsVisible
                              loanDetails={ddName}
                              spanValue={spanValue}
                              exceptionId={item.exceptionId}
                              waivers={item.waivers}
                              exceptionUserAndAccountDetails={
                                exceptionUserAndAccountDetails
                              }
                              index={index}
                              orgId={loanDetails?.originatorInfo?.partyId}
                            />
                          </div>
                        )
                    )
                : null}
            </Grid>
          ) : (
            <React.Suspense fallback={null}>
              <ZeroStateException />
            </React.Suspense>
          )}
        </section>
      </Grid>
    </>
  );
};
