import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noPreviewContainer: {
      display: "flex",
      flexDirection: "column",
      borderRadius: 4,
      justifyContent: "start",
      paddingTop: "200px",
      overflow: "auto",
      alignItems: "center",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      width: "100%",
      height: "100%"
    },
    noPreviewHeading: {
      margin: "31px 0 14px 0",
      color: "#32325d",
      fontSize: "14px"
    },
    noFileIcon: {
      height: "28px !important",
      width: "32px !important"
    },
    downloadBtn: {
      padding: "10px 16px",
      borderRadius: 4,
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      fontSize: "13px",
      borderWidth: 1,
      borderColor: "#ececec",
      width: "146px",
      height: "36px"
    }
  })
);
