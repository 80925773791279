import { produce } from "immer";
import { createSlice } from "@reduxjs/toolkit";
import {
  LoanStructureTypes,
  ToorakProductEnum
} from "../../create-loan/constants/loanCreationDropDownValues";
import {
  loanInfoParamsForBridge,
  loanInfoParamsForDSCR,
  guarantorParams,
  propertyParamsForBridge,
  propertyParamsForDSCR
} from "../../create-loan/tape-to-file/TapeToFileParams";
import {
  formatData,
  sortByOrder
} from "../../create-loan/tape-to-file/TapeToFileUtils";
import { tapeToFileStatusEnum } from "../../utils/constants";
import { resolvePath } from "../../utils/utils";
type objString = {
  [key: string]: boolean;
};

// import {
//   UPDATE_DATA_TO_TAPE,
//   UPDATE_DATA_TO_TAPE_PROPERTY,
//   UPDATE_PARAMS,
//   CLEANUP_PARAMS,
//   SET_SAVED_PARAMS,
//   SET_TTF_STATUS,
//   SET_ORIGINAL_PARAMS,
//   UPDATE_BULK_TTF_LOADER
// } from "./TapeToFile.action";

export interface TapeToFileStore {
  loanInfoParams: any;
  guarantorParams: any;
  propertyParams: any;
  originalLoanInfoParam: any;
  primaryLoanId: string;
  sellerName: string;
  tapeToFileStatus: string;
  bulkTTFLoader: boolean;
  firstPaymentDate: any;
  escrowPropertyInfo: any;
  escrowloanInfo: any;
  toorakProduct: string;
}

const initialState: TapeToFileStore = {
  loanInfoParams: [],
  guarantorParams: [],
  propertyParams: [],
  originalLoanInfoParam: {},
  primaryLoanId: "",
  sellerName: "",
  tapeToFileStatus: tapeToFileStatusEnum.ToDo,
  bulkTTFLoader: false,
  firstPaymentDate: "",
  escrowPropertyInfo: [],
  escrowloanInfo: {},
  toorakProduct: ""
};

const tapeToFileSlice = createSlice({
  name: "tapeToFile",
  initialState: initialState,
  reducers: {
    updateDataToTape: (state, action) => {
      const { payload, loanType } = action.payload;
      const isDSCR = loanType.includes("DSCR");
      const escrowInfoObj: objString = {};
      escrowInfoObj.loanSummary = payload.loanDetailId.loanSummaryId;
      escrowInfoObj.loanEscrowMap = payload.loanEscrowMap;
      const param = isDSCR ? loanInfoParamsForDSCR : loanInfoParamsForBridge;

      state.loanInfoParams = [];
      state.guarantorParams = [];
      state.toorakProduct = payload.loanInfo.toorakProduct;
      state.primaryLoanId = payload.loanInfo.primaryLoanId;
      state.firstPaymentDate = payload.loanInfo.firstPaymentDateOfLoan;
      state.sellerName = payload.originatorInfo.sellerName;
      state.escrowloanInfo = escrowInfoObj;
      state.tapeToFileStatus =
        payload.tapeToFileStatus || tapeToFileStatusEnum.ToDo;

      param.forEach((element) => {
        const obj = {
          skipRules: element.skipRules
        };
        prepareObj(obj, element, payload, state);
        state.loanInfoParams.push(obj);
      });

      let borrowerUserMap: any[] = [];
      let guarantorUserMap: any[] = [];

      payload.loanUserMap.forEach((user: { loanUserType: string }) => {
        if (user.loanUserType !== "Guarantor") {
          borrowerUserMap.push(user);
        } else {
          guarantorUserMap.push(user);
        }
      });

      if (borrowerUserMap.length > 1) {
        borrowerUserMap = sortGuarantorData(borrowerUserMap);
      }

      if (guarantorUserMap.length > 1) {
        guarantorUserMap = sortGuarantorData(guarantorUserMap);
      }

      prepareBorrowerOrGuarantorArray(
        state,
        borrowerUserMap,
        "Borrower",
        loanType
      );
      prepareBorrowerOrGuarantorArray(
        state,
        guarantorUserMap,
        "Guarantor",
        loanType
      );
    },
    updateParamsRedux: (state, action) => {
      const { payload, param, index } = action.payload;
      if (param === "LoanInfo") {
        state.loanInfoParams = payload;
      } else if (param === "Guarantor") {
        state.guarantorParams = payload;
      } else {
        state.propertyParams[index] = payload;
      }
    },
    updateDataToTapeProperty: (state, action) => {
      const { payload, loanType } = action.payload;
      let sortedPayload = sortByOrder(
        payload,
        "loanPropertyId",
        "loanPropertyOrder"
      );

      sortedPayload.forEach((property: { propertyUnit: string | any[] }) => {
        if (property?.propertyUnit?.length > 1) {
          property.propertyUnit = sortByOrder(
            property.propertyUnit,
            "propertyUnitId",
            "propertyUnitOrder"
          );
        }
      });

      const isDSCR = loanType.includes("DSCR");
      const params = isDSCR ? propertyParamsForDSCR : propertyParamsForBridge;

      state.propertyParams = [];
      const escrowPropObj: any[] = [];

      sortedPayload.forEach(
        (property: { [x: string]: any[]; loanPropertyId: any }) => {
          const propertyUnit: {}[] = [];
          escrowPropObj.push(property);
          params.forEach((element: any) => {
            const key = element.key;
            let obj: any = {};

            if (key && key.length) {
              property[element.path].forEach((unit, index) => {
                key.forEach((prop: string | number, index2: number) => {
                  obj = {};
                  obj.path = `${element.path}.${index}.${prop}`;
                  obj.type = element.type[index2];
                  obj.values = element.values[index2];
                  obj.aliasKey = element.aliasKey[index2];
                  obj.rulesKey = element.rulesKey[index2];
                  obj.tagArray = element.tags[index2];
                  obj.label = `${element.label[index2]} Unit ${index + 1}`;

                  if (obj.label.includes("Rent")) {
                    obj.label += " ($)";
                  } else if (obj.label.includes("Term")) {
                    obj.label += " (Months)";
                  }

                  obj.loanInfo = unit[prop];
                  obj.diligenceInfo = "";
                  obj.originalLoanInfo = "";
                  obj.documentInfo = "";
                  obj.documentInfoDB = "";
                  obj.commentsInfo = "";
                  obj.commentsDisabled = element.commentsDisabled;
                  obj.field = `${element.rulesKey[index2]}Prop${
                    property.loanPropertyId
                  }Unit${index + 1}`;
                  obj.unitIndex = index;
                  obj.loanPropertyId = property.loanPropertyId;

                  propertyUnit.push(obj);
                });
              });
            } else {
              element.field = `${element.rulesKey}Prop${property.loanPropertyId}`;
              obj.loanPropertyId = property.loanPropertyId;
              prepareObj(obj, element, property, state);
              propertyUnit.push(obj);
            }
          });

          state.propertyParams.push(propertyUnit);
          state.escrowPropertyInfo = escrowPropObj;
        }
      );
    },
    clearParams: (state) => {
      state.loanInfoParams.forEach(
        (param: {
          documentInfo: string;
          documentInfoDB: string;
          commentsInfo: string;
        }) => {
          param.documentInfo = "";
          param.documentInfoDB = "";
          param.commentsInfo = "";
        }
      );

      state.guarantorParams.forEach(
        (param: {
          documentInfo: string;
          documentInfoDB: string;
          commentsInfo: string;
        }) => {
          param.documentInfo = "";
          param.documentInfoDB = "";
          param.commentsInfo = "";
        }
      );

      state.propertyParams.forEach((property: any[]) => {
        property.forEach((param) => {
          param.documentInfo = "";
          param.documentInfoDB = "";
          param.commentsInfo = "";
        });
      });
    },
    setOriginalParams: (state, action) => {
      state.originalLoanInfoParam = action.payload;
    },
    setSavedParams: (state, action) => {
      const {
        diligencePayload,
        documentPayload,
        originalLoanInfoPayload,
        isILPStateCompleted
      } = action.payload;

      if (!isILPStateCompleted) {
        documentPayload.forEach((param: any) => {
          const key = Object.keys(param)[0];
          const val: any = Object.values(param)[0];
          const { value, comments } = val;

          let index = state.loanInfoParams.findIndex(
            (item: { field: string }) => item.field === key
          );

          if (index !== -1) {
            setState(state.loanInfoParams[index], value, comments);
          } else {
            index = state.guarantorParams.findIndex(
              (item: { field: string }) => item.field === key
            );

            if (index !== -1) {
              setState(state.guarantorParams[index], value, comments);
            } else {
              state.propertyParams.find((prop: { field: string }[]) => {
                index = prop.findIndex(
                  (item: { field: string }) => item.field === key
                );

                if (index !== -1) {
                  setState(prop[index], value, comments);
                  return true;
                }

                return false;
              });
            }
          }
        });
      }

      if (
        diligencePayload?.loans?.guarantorInfo?.length ||
        diligencePayload?.loans?.borrowerInfo?.length
      ) {
        addDiligenceForGuarantor(state, diligencePayload.loans);
      }

      if (
        diligencePayload?.loans?.loanInfo ||
        diligencePayload?.loans?.loanEconomics ||
        diligencePayload?.loans?.guarantorInfo ||
        diligencePayload?.loans?.borrowerInfo
      ) {
        addDiligenceForLoanInfo(state, diligencePayload.loans);
      }

      if (diligencePayload?.properties) {
        addDiligenceForProperties(state, diligencePayload.properties);
      }

      if (originalLoanInfoPayload?.loans) {
        addOriginalLoanInfo(state, originalLoanInfoPayload);
      }
    },
    setTtfStatus: (state, action) => {
      state.tapeToFileStatus = action.payload || tapeToFileStatusEnum.ToDo;
    },
    updateBulkTTFLoader: (state, action) => {
      state.bulkTTFLoader = action.payload;
    }
  }
});

// export const TapeToFileReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case UPDATE_DATA_TO_TAPE: {
//       const { payload, loanType } = action;
//       const nextState = produce(state, (draftState) => {
//         const isDSCR = loanType.includes("DSCR");
//         const escrowInfoObj: any = {};
//         escrowInfoObj.loanSummary = payload.loanDetailId.loanSummaryId;
//         escrowInfoObj.loanEscrowMap = payload.loanEscrowMap;
//         let param: any = isDSCR
//           ? loanInfoParamsForDSCR
//           : loanInfoParamsForBridge;
//         draftState.loanInfoParams = [];
//         draftState.guarantorParams = [];
//         draftState.toorakProduct = payload.loanInfo.toorakProduct;
//         draftState.primaryLoanId = payload.loanInfo.primaryLoanId;
//         draftState.firstPaymentDate = payload.loanInfo.firstPaymentDateOfLoan;
//         draftState.sellerName = payload.originatorInfo.sellerName;
//         draftState.escrowloanInfo = escrowInfoObj;
//         draftState.tapeToFileStatus =
//           payload.tapeToFileStatus || tapeToFileStatusEnum.ToDo;
//         // prepare loanInfo obj for the modal
//         param.forEach((element: any) => {
//           let obj: any = {};
//           obj.skipRules = element.skipRules;
//           prepareObj(obj, element, payload, draftState);
//           draftState.loanInfoParams.push(obj);
//         });
//         let borrowerUserMap: any = [];
//         let guarantorUserMap: any = [];
//         payload.loanUserMap.forEach((user: any) => {
//           if (user.loanUserType !== "Guarantor") {
//             borrowerUserMap.push(user);
//           } else {
//             guarantorUserMap.push(user);
//           }
//         });
//         if (borrowerUserMap.length > 1) {
//           borrowerUserMap = sortGuarantorData(borrowerUserMap);
//         }
//         if (guarantorUserMap.length > 1) {
//           guarantorUserMap = sortGuarantorData(guarantorUserMap);
//         }
//         prepareBorrowerOrGuarantorArray(
//           draftState,
//           borrowerUserMap,
//           "Borrower",
//           loanType
//         );
//         prepareBorrowerOrGuarantorArray(
//           draftState,
//           guarantorUserMap,
//           "Guarantor",
//           loanType
//         );
//       });
//       return nextState;
//     }

//     case UPDATE_PARAMS: {
//       const { payload, param, index } = action;
//       const nextState = produce(state, (draftState) => {
//         if (param === "LoanInfo") {
//           draftState.loanInfoParams = payload;
//         } else if (param === "Guarantor") {
//           draftState.guarantorParams = payload;
//         } else {
//           draftState.propertyParams[index] = payload;
//         }
//       });
//       return nextState;
//     }

//     case UPDATE_DATA_TO_TAPE_PROPERTY: {
//       const { payload, loanType } = action;
//       let sortedPayload = sortByOrder(
//         payload,
//         "loanPropertyId",
//         "loanPropertyOrder"
//       );

//       sortedPayload.forEach((property: any) => {
//         if (property?.propertyUnit?.length > 1) {
//           property.propertyUnit = sortByOrder(
//             property.propertyUnit,
//             "propertyUnitId",
//             "propertyUnitOrder"
//           );
//         }
//       });

//       const nextState = produce(state, (draftState) => {
//         const isDSCR = loanType.includes("DSCR");
//         let params: any = isDSCR
//           ? propertyParamsForDSCR
//           : propertyParamsForBridge;
//         draftState.propertyParams = [];
//         let escrowPropObj: any = [];
//         sortedPayload.forEach((property: any) => {
//           const propertyUnit: any = [];
//           escrowPropObj.push(property);
//           params.forEach((element: any) => {
//             const key = element.key;
//             let obj: any = {};
//             if (key && key.length) {
//               property[element.path].forEach((unit: any, index: number) => {
//                 key.forEach((prop: string, index2: number) => {
//                   obj = {};
//                   obj.path = `${element.path}.${index}.${prop}`;
//                   obj.type = element.type[index2];
//                   obj.values = element.values[index2];
//                   obj.aliasKey = element.aliasKey[index2];
//                   obj.rulesKey = element.rulesKey[index2];
//                   obj.tagArray = element.tags[index2];
//                   obj.label = `${element.label[index2]} Unit ${index + 1}`;
//                   if (obj.label.includes("Rent")) {
//                     obj.label += " ($)";
//                   } else if (obj.label.includes("Term")) {
//                     obj.label += " (Months)";
//                   }
//                   obj.loanInfo = unit[prop];
//                   obj.diligenceInfo = "";
//                   obj.originalLoanInfo = "";
//                   obj.documentInfo = "";
//                   obj.documentInfoDB = "";
//                   obj.commentsInfo = "";
//                   obj.commentsDisabled = element.commentsDisabled;
//                   obj.field = `${element.rulesKey[index2]}Prop${
//                     property.loanPropertyId
//                   }Unit${index + 1}`;
//                   obj.unitIndex = index;
//                   obj.loanPropertyId = property.loanPropertyId;
//                   propertyUnit.push(obj);
//                 });
//               });
//             } else {
//               element.field = `${element.rulesKey}Prop${property.loanPropertyId}`;
//               obj.loanPropertyId = property.loanPropertyId;
//               prepareObj(obj, element, property, draftState);
//               propertyUnit.push(obj);
//             }
//           });
//           draftState.propertyParams.push(propertyUnit);
//           draftState.escrowPropertyInfo = escrowPropObj;
//         });
//       });
//       return nextState;
//     }
//     case CLEANUP_PARAMS: {
//       const nextState = produce(state, (draftState) => {
//         draftState.loanInfoParams.forEach((param: any) => {
//           param.documentInfo = "";
//           param.documentInfoDB = "";
//           param.commentsInfo = "";
//         });
//         draftState.guarantorParams.forEach((param: any) => {
//           param.documentInfo = "";
//           param.documentInfoDB = "";
//           param.commentsInfo = "";
//         });
//         draftState.propertyParams.forEach((property: any) => {
//           property.forEach((param: any) => {
//             param.documentInfo = "";
//             param.documentInfoDB = "";
//             param.commentsInfo = "";
//           });
//         });
//       });
//       return nextState;
//     }
//     case SET_ORIGINAL_PARAMS: {
//       const nextState = produce(state, (draftState) => {
//         draftState.originalLoanInfoParam = action.payload;
//       });
//       return nextState;
//     }
//     case SET_SAVED_PARAMS: {
//       const {
//         diligencePayload,
//         documentPayload,
//         originalLoanInfoPayload,
//         isILPStateCompleted
//       } = action;
//       const nextState = produce(state, (draftState) => {
//         if (!isILPStateCompleted) {
//           documentPayload.forEach((param: any) => {
//             const key = Object.keys(param)[0];
//             const val: any = Object.values(param)[0];
//             const { value, comments } = val;
//             let index = draftState.loanInfoParams.findIndex(
//               (item: any) => item.field === key
//             );
//             if (index !== -1) {
//               setState(draftState.loanInfoParams[index], value, comments);
//             } else {
//               index = draftState.guarantorParams.findIndex(
//                 (item: any) => item.field === key
//               );
//               if (index !== -1) {
//                 setState(draftState.guarantorParams[index], value, comments);
//               } else {
//                 draftState.propertyParams.find((prop: any) => {
//                   index = prop.findIndex((item: any) => item.field === key);
//                   if (index !== -1) {
//                     setState(prop[index], value, comments);
//                     return true;
//                   }
//                   return false;
//                 });
//               }
//             }
//           });
//         }
//         if (
//           diligencePayload?.loans?.guarantorInfo?.length ||
//           diligencePayload?.loans?.borrowerInfo?.length
//         ) {
//           addDiligenceForGuarantor(draftState, diligencePayload.loans);
//         }
//         if (
//           diligencePayload?.loans?.loanInfo ||
//           diligencePayload?.loans?.loanEconomics ||
//           diligencePayload?.loans?.guarantorInfo ||
//           diligencePayload?.loans?.borrowerInfo
//         ) {
//           addDiligenceForLoanInfo(draftState, diligencePayload.loans);
//         }
//         if (diligencePayload?.properties) {
//           addDiligenceForProperties(draftState, diligencePayload.properties);
//         }
//         if (originalLoanInfoPayload?.loans) {
//           addOriginalLoanInfo(draftState, originalLoanInfoPayload);
//         }
//       });
//       return nextState;
//     }
//     case SET_TTF_STATUS: {
//       const nextState = produce(state, (draftState) => {
//         draftState.tapeToFileStatus =
//           action.payload || tapeToFileStatusEnum.ToDo;
//       });
//       return nextState;
//     }
//     case UPDATE_BULK_TTF_LOADER: {
//       const nextState = produce(state, (draftState) => {
//         draftState.bulkTTFLoader = action.payload;
//       });
//       return nextState;
//     }
//     default:
//       return state;
//   }
// };

export function addDiligenceForLoanInfo(draftState: any, loanInfoData: any) {
  draftState.loanInfoParams.forEach((param: any) => {
    param.diligenceInfo =
      param.path !== "" ? resolvePath(param.path, loanInfoData) : "";
  });
}

function addOriginalLoanInfo(draftState: any, loanInfoData: any) {
  draftState.loanInfoParams.forEach((param: any) => {
    param.originalLoanInfo =
      param.path !== "" ? resolvePath(param.path, loanInfoData.loans) : "";
  });
  addOriginalPropertyInfo(draftState, loanInfoData.properties);
  addOriginalGuarantorInfo(draftState, loanInfoData);
}

function addOriginalPropertyInfo(draftState: any, propertyInfoData: any) {
  draftState.propertyParams.forEach((element: any) => {
    element.forEach((propElem: any) => {
      const index = propertyInfoData.findIndex(
        (item: any) => item.loanPropertyId === propElem.loanPropertyId
      );
      propElem.originalLoanInfo =
        propElem.path !== ""
          ? resolvePath(propElem.path, propertyInfoData[index])
          : "";
    });
  });
}

function addOriginalGuarantorInfo(draftState: any, loanInfoData: any) {
  let borrowerUserMap: any = [];
  let guarantorUserMap: any = [];
  loanInfoData?.loans.loanUserMap.forEach((user: any) => {
    if (user.loanUserType !== "Guarantor") {
      borrowerUserMap.push(user);
    } else {
      guarantorUserMap.push(user);
    }
    if (borrowerUserMap.length > 1) {
      borrowerUserMap = sortGuarantorData(borrowerUserMap);
    }
    if (guarantorUserMap.length > 1) {
      guarantorUserMap = sortGuarantorData(guarantorUserMap);
    }
    draftState.guarantorParams.forEach((param: any) => {
      if (param.userType === "Borrower") {
        param.originalLoanInfo =
          param.path !== ""
            ? resolvePath(param.path, borrowerUserMap[param.borrowerIndex])
            : "";
      } else {
        param.originalLoanInfo =
          param.path !== ""
            ? resolvePath(param.path, guarantorUserMap[param.guarantorIndex])
            : "";
      }
    });
  });
}

function sortGuarantorData(userMap: any) {
  userMap.sort((a: any, b: any) => {
    if (Number(a?.loanUserSequence) !== Number(b?.loanUserSequence)) {
      return Number(a?.loanUserSequence) - Number(b?.loanUserSequence);
    }
    if (a.isPrimary !== b.isPrimary) {
      if (a.isPrimary) {
        return -1;
      }
      return 1;
    }
    return Number(a?.loanUserMapId) - Number(b?.loanUserMapId);
  });
  return userMap;
}

function addDiligenceForProperties(draftState: any, propertyInfoData: any) {
  draftState.propertyParams.forEach((element: any, index: number) => {
    element.forEach((propElem: any) => {
      propElem.diligenceInfo =
        propElem.path !== ""
          ? resolvePath(propElem.path, propertyInfoData[index])
          : "";
      if (
        propElem.diligenceInfo === "SFR" &&
        propElem?.rulesKey === "propertyType"
      ) {
        propElem.diligenceInfo = "Single Family Detached";
      }
    });
  });
}

function postProcessingUserMapData(param: any, userInfo: any, index: any) {
  if (param.field.includes("Name") || param.field.includes("Foreign")) {
    param.diligenceInfo = userInfo?.[index]?.customer?.[param.identifier] || "";
  } else {
    param.diligenceInfo = userInfo?.[index]?.[param.identifier] || "";
  }
  if (userInfo?.[index]?.customer?.[param.identifier] === true) {
    param.diligenceInfo = "Yes";
  } else if (userInfo?.[index]?.customer?.[param.identifier] === false) {
    param.diligenceInfo = "No";
  }
  return param.diligenceInfo;
}

function addDiligenceForGuarantor(draftState: any, loanData: any) {
  draftState.guarantorParams.forEach((param: any) => {
    if (param.field.includes("BorrowingEntityName")) {
      param.diligenceInfo =
        loanData?.borrowerInfo?.[param.borrowerIndex]?.customer?.accountName ||
        "";
    } else if (param.field.includes("Guarantor")) {
      param.diligenceInfo = postProcessingUserMapData(
        param,
        loanData?.guarantorInfo,
        param.guarantorIndex
      );
    } else if (param.field.includes("Borrower")) {
      param.diligenceInfo = postProcessingUserMapData(
        param,
        loanData?.borrowerInfo,
        param.borrowerIndex
      );
    }
  });
}

function prepareObj(obj: any, element: any, parent: any, draftState: any) {
  obj.loanInfo = element.path !== "" ? resolvePath(element.path, parent) : "";
  obj.label = element.label;
  if (obj.loanInfo === "SFR" && element?.rulesKey === "propertyType") {
    obj.loanInfo = "Single Family Detached";
  }
  initializeObj(obj, element, draftState);
}

function prepareBorrowerOrGuarantorArray(
  draftState: any,
  userMap: any[],
  type: string,
  loanType: string
) {
  if (userMap.length) {
    userMap.forEach((user: any, index: number) => {
      guarantorParams.forEach((ele: any) => {
        const element = JSON.parse(JSON.stringify(ele));
        let obj: any = {};
        obj.loanInfo = resolvePath(element.path, user);
        obj.userType = type;
        if (type === "Borrower") {
          obj.borrowerIndex = index;
          obj.aliasKey = element.borrower;
        } else {
          obj.guarantorIndex = index;
          obj.aliasKey = element.guarantor;
        }
        obj.identifier =
          element.borrowerIdentifier !== undefined
            ? element.borrowerIdentifier
            : element.borrower;
        if (
          element.partyType === user.customer.partyType ||
          element.path.includes("originalCreditScoreMedian") ||
          element.path.includes("originalCreditScoreReportDate") ||
          element.path.includes("foreignNationalString")
        ) {
          obj.path = element.path;
          obj.label = `${type} ${index + 1} ${element.label}`;
          obj.loanUserMapId = user.loanUserMapId;
          element.field = `${type}${obj.loanUserMapId}${element.label.replace(
            / /g,
            ""
          )}`;
          if (
            element.path.includes("foreignNationalString") &&
            !loanType.includes("DSCR")
          ) {
            element.values.pop();
          }
          initializeObj(obj, element, draftState);
          draftState.guarantorParams.push(obj);
        }
      });
    });
  }
}

function transformDropDown(element: any, draftState: any) {
  if (draftState.toorakProduct === ToorakProductEnum.Rental) {
    if (element.field === "loanStructure") {
      element.values = [LoanStructureTypes.singleDraw];
    } else if (element.field === "propertyType") {
      element.values = element.values.filter(
        (val: string) => !["5+ Unit Multifamily", "Mixed Use"].includes(val)
      );
    }
  }
}

function initializeObj(obj: any, element: any, draftState: any) {
  obj.path = element.path;
  if (element.aliasKey !== undefined) {
    obj.aliasKey = element.aliasKey;
  }
  if (element.rulesKey !== undefined) {
    obj.rulesKey = element.rulesKey;
  }
  if (element.values !== undefined) {
    transformDropDown(element, draftState);
    obj.values = element.values;
  }
  obj.type = element.type;
  obj.tagArray = element.tags;
  obj.commentsDisabled = element.commentsDisabled;
  obj.diligenceInfo = "";
  obj.originalLoanInfo = "";
  obj.documentInfo = "";
  obj.documentInfoDB = "";
  obj.commentsInfo = "";
  obj.field = element.field;
}

function setState(item: any, value: string, comments: string) {
  item.documentInfoDB = value;
  item.documentInfo = formatData(value, item.type);
  item.commentsInfo = comments;
}

export const {
  updateDataToTape,
  updateParamsRedux,
  updateDataToTapeProperty,
  clearParams,
  setOriginalParams,
  setSavedParams,
  setTtfStatus,
  updateBulkTTFLoader
} = tapeToFileSlice.actions;

export const TapeToFileReducer = tapeToFileSlice.reducer;
