import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

export const useStylesHeader = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
      color: "#fff",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(12),
      marginRight: "2rem",
      "&:focus": {
        opacity: 1
      },
      "&:disabled": {
        color: "#fff"
      },
      "&:last-child": {
        marginRight: "0"
      }
    },
    btnCreateloan: {
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    tabHeading: {
      fontSize: "14px !important"
    },
    statusText: {
      fontSize: "10px",
      fontWeight: 600,
      margin: 0,
      display: "flex",
      textAlign: "left",
      justifyContent: "center",
      alignItems: "center"
    },
    statusTextPadding1: {
      padding: "0px 0px 0px 3px"
    },
    statusTextPadding2: {
      padding: "0px 4px 0px 4px"
    },
    paperHeader: {
      // flexDirection: "row",
      height: "auto",
      backgroundImage: "linear-gradient(93deg, #0fcbef 4%, #1071ee 90%)",
      // backgroundImage: "linear-gradient(90deg, #014DB0 55%, #009BC9 90%)",
      boxShadow: "none",
      // paddingTop: theme.spacing(1),
      // paddingRight: theme.spacing(3),
      // paddingLeft: theme.spacing(3),
      paddingRight: 20,
      paddingLeft: 20,
      // paddingBottom: "3px"
      position: "fixed",
      zIndex: 2
    },
    wrapper: {
      display: "flex"
      // margin: ""auto"
      // [theme.breakpoints.up("sm")]: {
      //   maxWidth: "86%",
      //   flexBasis: "86%"
      // }
    },
    logo: {
      marginRight: "2rem"
      // paddingTop: theme.spacing(1)
      // [theme.breakpoints.up("md")]: {
      //   maxWidth: "20%",
      //   flexBasis: "20%"
      // }
    },
    // menuTabContainer: {
    //   [theme.breakpoints.up("md")]: {
    //     maxWidth: "80%",
    //     flexBasis: "80%"
    //   }
    // },
    imgLogo: {
      width: "212px",
      // height: 46
    },
    userInfo: {
      // alignSelf: "center",
      // paddingTop: "2px",
      display: "flex",
      marginLeft: "1rem"
      // [theme.breakpoints.up("md")]: {
      //   maxWidth: "14%",
      //   flexBasis: "14%"
      // }
    },
    imgCapitalContainer: {
      textAlign: "right",
      paddingRight: "7px",
      whiteSpace: "nowrap",
      margin: "auto",
      [theme.breakpoints.down("sm")]: {
        visibility: "hidden"
      }
    },
    imgCapital: {
      width: "36px",
      height: "20px"
    },
    notifyIconContainer: {
      margin: "auto !important",
      textAlign: "center"
    },
    textContainer: {
      textTransform: "none",
      color: "#fff",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(12),
      display: "flex"
    },
    profileImage: {
      width: "36px",
      height: "36px",
      borderRadius: "50%",
      // background: "#512DA8"
      background: "#002856",
      fontSize: "20px",
      color: "#fff",
      textAlign: "center",
      lineHeight: "36px"
    },
    icon: {
      color: "white",
      margin: "9px",
      height: "23px",
      position: "relative",
      cursor: "pointer"
    },
    imgUser: {
      textAlign: "right",
      backgroundColor: "#105CBE",
      borderRadius: "8px",
      height: "70%",
      position: "relative",
      top: "9px",
      left: "10px",
      border: "1px solid rgba(0, 40, 86, 0.22)",
      width: "135px"
    },
    imgUserToorakConnect: {
      textAlign: "right",
      backgroundColor: "#105CBE",
      borderRadius: "8px",
      height: "70%",
      position: "relative",
      top: "9px",
      left: "10px",
      border: "1px solid rgba(0, 40, 86, 0.22)",
      minWidth: "112px",
      width: "120px"
    },
    imgUser2: {
      textAlign: "right",
      margin: "auto"
    },
    indicatorHeader: {
      height: "4px",
      borderRadius: "30px",
      // maxWidth: "32px",
      backgroundColor: "#ffffff"
      // marginLeft: "40px"
    },
    customWidth: {
      width: "277px",
      maxHeight: "581px",
      borderRadius: "4px",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      backgroundColor: "#ffffff",
      position: "absolute",
      top: "32px",
      zIndex: 3,
      right: "15px"
    },
    hamburgerMenu: {
      textAlign: "right",
      margin: "auto"
    },
    overlayBG: {
      backgroundColor: "rgba(0, 0, 0, 0.4)",
      position: "fixed",
      left: "0",
      top: "0",
      right: "0",
      height: "100%",
      width: "100%",
      zIndex: 2
    },
    hambergerMenuList: {
      backgroundColor: "#fff",
      borderLeft: "1px solid #e2e4f1",
      position: "fixed",
      height: "100%",
      right: "0",
      top: "0",
      width: "275px",
      zIndex: 3
    },
    show: {
      display: "block"
    },
    userInfoBox: {
      borderBottom: "1px solid #e2e4f1",
      color: "#32325d",
      display: "flex",
      alignItems: "center",
      textAlign: "left",
      padding: "25px",
      width: "100%",
      "& .MuiListItem-gutters": {
        borderBottom: "0",
        padding: "0 !Important"
      }
    },
    userImage: {
      marginRight: "10px",
      width: "40px"
    },
    userDetail: {
      textAlign: "left",
      width: "calc(100% - 40px)"
    },
    userName: {
      color: "#32325d",
      fontSize: "14px",
      fontWeight: 600,
      margin: "0"
    },
    eMail: {
      fontSize: "11px"
    },
    mainMenuListContainer: {
      borderBottom: "1px solid #e2e4f1",
      padding: "0 0 10px 0",
      listStyle: "none",
      textAlign: "left",
      marginBottom: "15px"
    },
    mainMenuItem: {
      color: "#32325d",
      cursor: "pointer",
      fontSize: "14px",
      textTransform: "uppercase",
      padding: "20px 25px 20px 25px",
      fontWeight: 600
    },
    profileMenuListContainer: {
      borderBottom: "1px solid #e2e4f1",
      padding: "0 0 10px 0",
      listStyle: "none",
      textAlign: "left",
      marginBottom: "15px"
    },
    profileMenuItem: {
      color: "#32325d",
      cursor: "pointer",
      fontSize: "12px",
      padding: "15px 25px 15px 25px"
    },
    menuLogo: {
      position: "absolute",
      bottom: "0",
      padding: "10px",
      textAlign: "center",
      width: "100%"
    },
    menuImgCapital: {
      height: "35px",
      width: "75px"
    },
    menuClose: {
      position: "absolute",
      color: "000",
      right: "0",
      top: "0"
    },
    iconImg: {
      display: "inline-block",
      verticalAlign: "middle",
      height: "18px",
      margin: "0 5px 2px 0",
      width: "18px"
    },
    colorNormal: {
      color: "#32325d",
      fontSize: "12px"
    },
    colorBlue: {
      color: "blue",
      fontSize: "12px"
    },
    listItemIcon: {
      minWidth: "30px",
      color: "#32325d",
      padding: "8px 0px 12px 0px"
    },
    IconText: {
      padding: "12px 0px"
    },
    moreMenuContainer: {
      display: "flex",
      height: "100%",
      alignItems: "flex-end",
      position: "relative"
    },
    moreButton: {
      height: "25px",
      color: "#fff",
      fontFamily: "'Open Sans', sans-serif !important",
      fontSize: "14px",
      fontWeight: 400,
      textTransform: "uppercase",
      marginBottom: "16px",
      "&:focus": {
        color: "#fff"
      },
      "&:hover": {
        color: "#fff"
      }
    },
    dropdownListContainer: {
      display: "flex",
      minWidth: "150px"
    }
  })
);
