import React from "react";
import { Box, Button, AppBar, Typography, Grid } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../stores/rootReducer";
import { setActiveTabAndProperty } from "./create-loan.action";
import { isILP } from "../utils/AccessManagement";
import { KeyDealMetrics } from "../key-deal-metrics/KeyDealMetrics";
import { getLoanTypeByLoanId } from "../utils/formatChecks";
import { AnalyticsIcon, BackIcon, RedirectIcon } from "./Icons";
import { LoanStage } from "@toorak/tc-common-fe-sdk";
import queryString from "query-string";
import { setTabView } from "../stores/SettlementAndPurchase/SettlementAndPurchase.reducer";
import { getConfig } from "../config/config";

export interface DynamicHeaderProps {
  path: string;
  heading?: any;
  takeoutPartnerId?: string;
}
export const DynamicHeader = (props: DynamicHeaderProps) => {
  const { heading, takeoutPartnerId } = props;
  const { loanType, loanStage, loanId } = useParams<{
    loanId: string;
    loanStage: LoanStage;
    loanType: string;
  }>() as any;
  const dispatch = useDispatch();
  const urlParams: any = queryString.parse(
    new URL(window.location.href).search
  );
  const navigate = useNavigate();
  const viewDetailsFromDashboard = useSelector<RootState, any>(
    (state) => state.dashboardStore.viewDetailsFromDashboard
  );
  const showKeyDealMetrics = !(
    heading.includes("Create Discussion") ||
    heading.includes("Loan Discussion") ||
    heading.includes("Scenario ID")
  );
  const [openKeyMetrics, setOpenKeyMetrics] = React.useState<boolean>(false);

  const goToLoanDetail = () => {
    window.open(`/internal/loan/createLoan/${loanType}/${loanStage}/${loanId}`, "_self");
  };

  const goToLoanDetailInNewTab = () => {
    window.open(
      `${
        getConfig().redirectUrl
      }loan/createLoan/${loanType}/${loanStage}/${loanId}`
    );
  };

  const handleClickBack = () => {
    if (!viewDetailsFromDashboard) {
      if (isILP()) {
        if (urlParams?.from === "communication") {
          navigate("/internal/loans/list?tab=1");
          dispatch(setTabView(1));
        } else if (urlParams?.from === "action") {
          navigate("/internal/loans/list?tab=2");
          dispatch(setTabView(2));
        } else if (urlParams?.from === "admin_inquiry_action") {
          navigate("/internal/loans/list?tab=3");
          dispatch(setTabView(3));
        } else {
          window.open(`/internal/loan/createLoan/${loanType}/${loanStage}/${loanId}`, "_self");
        }
      } else {
        navigate(`/loan/createLoan/${loanType}/${loanStage}/${loanId}`);
      }
      if (
        heading.includes("Create Discussion") ||
        heading.includes("Loan Discussion")
      ) {
        dispatch(setActiveTabAndProperty("discussions"));
      } else {
        if (isILP()) {
          dispatch(setActiveTabAndProperty("shadowDiligence"));
        } else {
          dispatch(setActiveTabAndProperty("exceptionsEnquires"));
        }
      }
      if (
        heading.includes("Create Scenario") ||
        heading.includes("Scenario ID")
      ) {
        navigate(`/loans/list?tab=4`);
      }
    } else {
      navigate(`/loans/list?tab=0`);
    }
  };
  return (
    <>
      <AppBar
        color="default"
        position="static"
        style={{ position: "fixed", height: "60px", top: "0" }}
        data-testid="app-dynamic-header"
      >
        {openKeyMetrics && (
          <KeyDealMetrics
            loanId={loanId}
            loanStage={loanStage}
            loanType={getLoanTypeByLoanId(loanId)}
            onClose={() => {
              setOpenKeyMetrics(false);
            }}
            takeoutPartnerId={takeoutPartnerId}
          />
        )}
        <Box
          className="button-typography-wrapper-left"
          style={{ padding: "4px 0px" }}
        >
          <Grid container>
            <Grid
              item
              xs={(isILP() && urlParams?.from) || (!isILP() && loanId) ? 7 : 9}
            >
              <div
                className="subheader-mobile-view"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  variant="contained"
                  data-testid="back-button"
                  color="primary"
                  startIcon={<BackIcon />}
                  onClick={handleClickBack}
                >
                  <span className="mobile-view-hide">Back</span>
                </Button>
                <Typography>{heading}</Typography>
              </div>
            </Grid>
            <Grid
              item
              xs={(isILP() && urlParams?.from) || (!isILP() && loanId) ? 5 : 3}
              style={{ textAlign: "right" }}
            >
              {isILP() && urlParams?.from && (
                <Button
                  data-testid="nav-back"
                  variant="contained"
                  color="primary"
                  onClick={() => goToLoanDetail()}
                >
                  Loan Detail
                </Button>
              )}
              {showKeyDealMetrics && (
                <Button
                  data-testid="nav-back"
                  variant="contained"
                  color="primary"
                  startIcon={<AnalyticsIcon />}
                  onClick={() => setOpenKeyMetrics(true)}
                >
                  Key Deal Metrics
                </Button>
              )}

              {!isILP() &&
                loanId &&
                window.location.href.indexOf("view") !== -1 && (
                  <Button
                    data-testid="nav-back"
                    variant="contained"
                    color="primary"
                    startIcon={
                      <RedirectIcon color="#000000" width="14" height="14" />
                    }
                    onClick={goToLoanDetailInNewTab}
                  >
                    Open Loan Details
                  </Button>
                )}
            </Grid>
          </Grid>
        </Box>
      </AppBar>
    </>
  );
};
