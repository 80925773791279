/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */

// import { produce } from "immer";
import { createSlice } from "@reduxjs/toolkit";
import { v1 as uuid1 } from "uuid";
// import { RESET_DISCUSSION_STORE } from "../../create-loan/create-loan.action";
// import {
//   GET_ALL_CHATS,
//   GET_CHATS_SUCCESS,
//   CREATE_DISCUSSION,
//   ADD_CHAT,
//   COMMENT_SUCCESS,
//   COMMENT_THREAD_SUCCESS,
//   UPDATE_DOCS_IN_DISCUSSION,
//   DELETE_DOCUMENT,
//   DELETE_DISCUSSION_DOCS,
//   UPDATE_NEW_STORE,
//   UPDATE_FILTER,
//   UPDATE_PARTYID_MAP,
//   UPDATE_SELECTED_TAB,
// } from "./Discussions.action";

export const selectAllName: string = "Select All";
export const usersKey: string = "Users";
export const tagsKey: string = "Tags Attached";
export const originatorsLabel: string = "Originators";
export const toorakUsersLabel: string = "Toorak Users";
export const discussionEntriesPerPage: number = 10;

export interface DiscussionModel {
  chatId: string;
  partyId: string;
  createdOn: string | any;
  title: string;
  tags: any;
  description: string;
  name: string;
  role: string;
  unreadCount: Number;
}

export interface DiscussionCommentModel {
  name: String;
  createdOn: String;
  type: String;
  comment: String;
}
export interface FilterModel {
  [key: string]: {
    filterName: string;
    options: FilterOptionModel;
  };
}
export interface FilterOptionModel {
  [key: string]: {
    name: string;
    checked: boolean;
    isOption: boolean;
    type?: string;
    code?: string;
  };
}
export interface AllFilterModel {
  [key: string]: FilterModel;
}
export interface DiscussionTabModel {
  filteredDiscussions: {
    [key: string]: DiscussionModel;
  };
  loading: boolean;
  totalEntries: number;
}

export interface DiscussionStore {
  loading: boolean;
  discussionInfo: { [key: string]: DiscussionModel };
  comments: {};
  thread: {};
  docFiles: {};
  loanId: string;
  tabNumber: number;
  discussions: {
    [id: string]: DiscussionTabModel;
  };
  filters: FilterModel;
  partyIdMap: { [key: string]: string };
}
export function getInitialFilterState(): FilterModel {
  return {
    [usersKey]: {
      filterName: usersKey,
      options: {
        [uuid1().toString()]: {
          name: selectAllName,
          checked: false,
          isOption: true
        }
      }
    }
  };
}
const initialState: DiscussionStore = {
  loading: false,
  discussionInfo: {},
  comments: {},
  thread: {},
  docFiles: {},
  partyIdMap: {},
  loanId: "",
  tabNumber: 0,
  discussions: {
    "0": {
      filteredDiscussions: {},
      loading: false,
      totalEntries: 0
    },
    "1": {
      filteredDiscussions: {},
      loading: false,
      totalEntries: 0
    },
    "2": {
      filteredDiscussions: {},
      loading: false,
      totalEntries: 0
    }
  },
  filters: getInitialFilterState()
};

const discussionSlice = createSlice({
  name: "discussion",
  initialState,
  reducers: {
    getAllChatsSuccess: (state) => {
      state.loading = false;
    },
    getChatsSuccess: (state, action) => {
      state.discussionInfo = action.payload.response;
    },
    createDiscussion: (state) => {
      // Logic for createDiscussion if needed
    },
    addChat: (state) => {
      // Logic for addChat if needed
    },
    commentThreadSuccess: (state, action) => {
      state.thread = { ...action.payload, ...state.thread };
    },
    commentSuccess: (state, action) => {
      state.comments = { ...action.payload };
    },
    updateDocumentInDiscussion: (state, action) => {
      const { bucketId, files } = action.payload;
      state.docFiles = { ...state.docFiles, [bucketId]: files };
    },
    deleteDiscussionDocs: (state) => {
      state.docFiles = {};
    },
    deleteDocumentDiscussion: (state, action) => {
      const { docFiles } = action.payload;
      state.docFiles = docFiles;
    },
    updateNewStore: (state, action) => {
      const { newState, loanId, tabNumber, isNotFirstPage } = action.payload;
      const tabIndex = tabNumber.toString();
      let discussionTemp: { [id: string]: DiscussionTabModel } = {
        ...state.discussions
      };

      if (isNotFirstPage) {
        discussionTemp[tabIndex].filteredDiscussions = {
          ...discussionTemp[tabIndex].filteredDiscussions,
          ...newState[tabIndex].filteredDiscussions
        };
      } else {
        discussionTemp[tabIndex].filteredDiscussions =
          newState[tabIndex].filteredDiscussions;
      }

      discussionTemp[tabIndex].totalEntries = newState[tabIndex].totalEntries;
      state.loanId = loanId;
      state.discussions = discussionTemp;
    },
    updateFilter: (state, action) => {
      state.filters = { ...action.payload.filters };
    },
    updatePartyIdMap: (state, action) => {
      const { partyIdMap } = action.payload;
      state.partyIdMap = { ...state.partyIdMap, ...partyIdMap };
    },
    updateSelectedTab: (state, action) => {
      state.tabNumber = action.payload.tabNumber;
    },
    resetDiscussionStore: () => initialState
  }
});

export const {
  getAllChatsSuccess,
  getChatsSuccess,
  createDiscussion,
  addChat,
  commentThreadSuccess,
  commentSuccess,
  updateDocumentInDiscussion,
  deleteDiscussionDocs,
  deleteDocumentDiscussion,
  updateNewStore,
  updateFilter,
  updatePartyIdMap,
  updateSelectedTab,
  resetDiscussionStore
} = discussionSlice.actions;

export const DiscussionReducer = discussionSlice.reducer;

// export const DiscussionReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case GET_ALL_CHATS: {
//       return {
//         ...state,
//         loading: false,
//       };
//     }
//     case GET_CHATS_SUCCESS: {
//       return {
//         ...state,
//         discussionInfo: action.payload.response,
//       };
//     }
//     case CREATE_DISCUSSION: {
//       return {
//         ...state,
//       };
//     }
//     case ADD_CHAT: {
//       return {
//         ...state,
//       };
//     }
//     case COMMENT_THREAD_SUCCESS: {
//       const buckets = action.payload;
//       const combinedDDData = { ...buckets, ...state.thread };
//       const nextState = produce(state, (draftState) => {
//         /* eslint-disable no-param-reassign */
//         draftState.thread = combinedDDData;
//         /* eslint-disable no-param-reassign */
//       });
//       return nextState;
//     }
//     case COMMENT_SUCCESS: {
//       const buckets = action.payload;
//       const combinedDDData = { ...buckets };
//       const nextState = produce(state, (draftState) => {
//         /* eslint-disable no-param-reassign */
//         draftState.comments = combinedDDData;
//         /* eslint-disable no-param-reassign */
//       });
//       return nextState;
//     }
//     case UPDATE_DOCS_IN_DISCUSSION: {
//       const { bucketId, files } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         /* eslint-disable no-param-reassign */
//         let documentFiles: any = { ...draftState.docFiles };
//         documentFiles[bucketId] = files;
//         draftState.docFiles = documentFiles;
//         /* eslint-disable no-param-reassign */
//       });
//       return nextState;
//     }
//     case DELETE_DISCUSSION_DOCS: {
//       const nextState = produce(state, (draftState) => {
//         /* eslint-disable no-param-reassign */
//         draftState.docFiles = {};
//         /* eslint-disable no-param-reassign */
//       });
//       return nextState;
//     }
//     case DELETE_DOCUMENT: {
//       const { docFiles } = action.payload;

//       const nextState = produce(state, (draftState) => {
//         draftState.docFiles = docFiles;
//         /* eslint-disable no-param-reassign */
//       });
//       return nextState;
//     }
//     case UPDATE_NEW_STORE: {
//       const newState: { [key: string]: DiscussionTabModel } =
//         action.payload.newState;
//       const loanId: string = action.payload.loanId;
//       const tabNumber: number = action.payload.tabNumber;
//       const isNotFirstPage: boolean = action.payload.isNotFirstPage;
//       let tabIndex: string = tabNumber.toString();
//       const nextState = produce(state, (draftState) => {
//         let updatedState: { [key: string]: DiscussionTabModel } = {
//           ...draftState.discussions,
//         };
//         let discussions: { [key: string]: DiscussionModel } = {
//           ...updatedState[tabIndex].filteredDiscussions,
//         };
//         let newStateDiscussions: { [key: string]: DiscussionModel } = {
//           ...newState[tabIndex].filteredDiscussions,
//         };
//         if (isNotFirstPage) {
//           //discussions = discussions.concat(newStateDiscussions);
//           Object.keys(newStateDiscussions).map((discussionKey: string) => {
//             discussions[discussionKey] = newStateDiscussions[discussionKey];
//             return "";
//           });
//         } else {
//           discussions = newStateDiscussions;
//         }
//         updatedState[tabIndex].filteredDiscussions = discussions;
//         updatedState[tabIndex].totalEntries = newState[tabIndex].totalEntries;
//         draftState.discussions = updatedState;
//         draftState.loanId = loanId;
//         /* eslint-disable no-param-reassign */
//       });
//       return nextState;
//     }
//     case UPDATE_FILTER: {
//       const filters: FilterModel = action.payload.filters;
//       const nextState = produce(state, (draftState) => {
//         draftState.filters = { ...filters };
//         /* eslint-disable no-param-reassign */
//       });
//       return nextState;
//     }
//     case UPDATE_PARTYID_MAP: {
//       const partyIdMap: { [key: string]: string } = action.payload.partyIdMap;
//       const nextState = produce(state, (draftState) => {
//         let newPartyIdMap = {
//           ...draftState.partyIdMap,
//           ...partyIdMap,
//         };
//         draftState.partyIdMap = newPartyIdMap;
//         /* eslint-disable no-param-reassign */
//       });
//       return nextState;
//     }
//     case UPDATE_SELECTED_TAB: {
//       const tabNumber: number = action.payload.tabNumber;
//       const nextState = produce(state, (draftState) => {
//         draftState.tabNumber = tabNumber;
//         /* eslint-disable no-param-reassign */
//       });
//       return nextState;
//     }
//     case RESET_DISCUSSION_STORE:{
//       return initialState;
//     }
//     default:
//       return state;
//   }
// };
