import React, { useEffect, useState } from "react";
import { Box, Theme, InputBase, InputAdornment } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subHeader: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #e2e4f1"
    },
    searchInput: {
      backgroundColor: "#fff",
      fontSize: 12,
      fontWeight: 600,
      color: "#8897aa",
      border: "1px solid #e9ecef",
      borderRadius: 30,
      width: "100%"
    },
    searchIcon: {
      left: 5,
      position: "relative"
    },
    totalCountWrap: {
      display: "flex",
      marginTop: 8
    },
    divider: {
      height: 16,
      margin: "0 6px",
      width: 2,
      backgroundColor: "#979797"
    },
    clearIcon: {
      backgroundColor: "transparent",
      border: "none"
    }
  })
);

interface SearchFilterProps {
  columnName: "all" | string;
  onSearch?: any;
  searchValue?: string;
  //title?: any;
  filterTitle?: any;
  setOpenFilter?: (arg0: boolean) => void;
}

const SearchFilter = (props: SearchFilterProps) => {
  const {
    columnName,
    onSearch = () => {},
    searchValue = "",
    setOpenFilter,
    // title,
    filterTitle
  } = props;
  const classes = useStyles();
  const [searchVal, setSearchVal] = useState("");

  useEffect(() => {
    setSearchVal(searchValue);
  }, [searchValue]);

  const handleSearch = (e: React.KeyboardEvent<HTMLDivElement>, title: any) => {
    if (e.key !== "Enter") {
      return;
    }
    if (searchVal.trim() !== "") {
      const headerFilterTags = { [columnName]: { [title]: searchVal } };
      onSearch({ [columnName]: searchVal }, headerFilterTags);
      if (setOpenFilter) setOpenFilter(false);
    }
  };

  const clearSearch = () => {
    setSearchVal("");
    onSearch({ [columnName]: "" });
  };

  return (
    <Box display="flex" justifyContent="flex-end" width="100%">
      <InputBase
        placeholder="Search here"
        className={classes.searchInput}
        startAdornment={<SearchIcon className={classes.searchIcon} />}
        onChange={e => setSearchVal(e.target.value)}
        onKeyPress={e => handleSearch(e, filterTitle)}
        value={searchVal}
        endAdornment={
          <InputAdornment
            position="end"
            component="button"
            className={classes.clearIcon}
          >
            {searchVal !== "" && (
              <ClearIcon className={classes.searchIcon} onClick={clearSearch} />
            )}
          </InputAdornment>
        }
      />
    </Box>
  );
};

export default SearchFilter;
