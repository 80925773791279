import { createSlice } from "@reduxjs/toolkit";
// import { produce } from "immer";

// import {
//   SET_CUSTOMER_DATA,
//   SET_CUSTOMER_DATA_COPYTOBOX,
//   SET_ALL_CUSTOMER_DATA,
//   SET_ALL_CUSTOMER_DATA_COPYTOBOX,
//   SET_FILTER_DATA,
//   SET_FILTER_DATA_COPYTOBOX,
//   SET_SEARCH_VALUE_COPYTOBOX,
//   SET_SEARCH_VALUE,
//   SET_ALL_LOANDETAILS_REALINSIGHTS,
//   SET_DOCUMENT_TAG_INFO,
//   SET_LOANDETAILS_REALINSIGHTS,
//   SET_ALL_REALINSIGHTS_DATA,
//   SET_LOAN_DATA_REALINSIGHTS,
//   SET_TAGGING_LOANID_INFO
// } from "./assetManagement.action";

export interface AssetManagementStore {
  AllCustomerData: any[];
  AllCustomerDataCopytobox: any[];
  AllLoanDetailsRealInsights: any[];
  documentTagInfo: any[];
  loantaginfo: any[];
  AllRealInsightsData: any[];
  CustomerData: any[];
  CustomerDataCopytobox: any[];
  LoanDataRealInsights: any[];
  LoanDetailsRealInsights: any[];
  filterData: any[];
  filterDataCopytobox: any[];
  searchValue: any;
  searchValueCopytobox: any;
}

const initialState: AssetManagementStore = {
  AllCustomerData: [],
  AllCustomerDataCopytobox: [],
  AllLoanDetailsRealInsights: [],
  documentTagInfo: [],
  loantaginfo: [],
  AllRealInsightsData: [],
  CustomerData: [],
  CustomerDataCopytobox: [],
  LoanDetailsRealInsights: [],
  LoanDataRealInsights: [],
  filterData: [],
  filterDataCopytobox: [],
  searchValue: "",
  searchValueCopytobox: ""
};

const assetManagementSlice = createSlice({
  name: "AssetManagement",
  initialState,
  reducers: {
    setAllCustomerData: (state, action) => {
      state.AllCustomerData = action.payload.data;
    },
    setAllCustomerDataCopytobox: (state, action) => {
      state.AllCustomerDataCopytobox = action.payload.data;
    },
    setAllLoanDetailsRealInsights: (state, action) => {
      state.AllLoanDetailsRealInsights = action.payload.data;
    },
    setDocumentTagInfo: (state, action) => {
      state.documentTagInfo = action.payload.data;
    },
    setTaggingLoanIdInfo: (state, action) => {
      state.loantaginfo = action.payload.data;
    },
    // Uncomment and add other cases as needed
    // setDrawDocs: (state, action) => {
    //   state.drawDocs = action.payload.data;
    // },
    setAllRealInsightsData: (state, action) => {
      state.AllRealInsightsData = action.payload.data;
    },
    setCustomerData: (state, action) => {
      state.CustomerData = action.payload.data;
    },
    setCustomerDataCopytobox: (state, action) => {
      state.CustomerDataCopytobox = action.payload.data;
    },
    setLoanDataRealInsights: (state, action) => {
      state.LoanDataRealInsights = action.payload.data;
    },
    setLoanDetailsRealInsights: (state, action) => {
      state.LoanDetailsRealInsights = action.payload.data;
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload.data;
    },
    setFilterDataCopytobox: (state, action) => {
      state.filterDataCopytobox = action.payload.data;
    },
    setSearchValueAsset: (state, action) => {
      state.searchValue = action.payload.data;
    },
    setSearchValueCopytobox: (state, action) => {
      state.searchValueCopytobox = action.payload.data;
    }
  }
});

export const {
  setAllCustomerData,
  setAllCustomerDataCopytobox,
  setAllLoanDetailsRealInsights,
  setDocumentTagInfo,
  setTaggingLoanIdInfo,
  // setDrawDocs,
  setAllRealInsightsData,
  setCustomerData,
  setCustomerDataCopytobox,
  setLoanDataRealInsights,
  setLoanDetailsRealInsights,
  setFilterData,
  setFilterDataCopytobox,
  setSearchValueAsset,
  setSearchValueCopytobox
} = assetManagementSlice.actions;

export const AssetManagementReducer = assetManagementSlice.reducer;

// export const AssetManagementReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case SET_ALL_CUSTOMER_DATA: {
//       const { data } = action.payload;
//       const nextState = produce(state, draftState => {
//         draftState.AllCustomerData = data;
//       });
//       return nextState;
//     }

//     case SET_ALL_CUSTOMER_DATA_COPYTOBOX: {
//       const { data } = action.payload;
//       const nextState = produce(state, draftState => {
//         draftState.AllCustomerDataCopytobox = data;
//       });
//       return nextState;
//     }
//     case SET_ALL_LOANDETAILS_REALINSIGHTS: {
//       const { data } = action.payload;
//       const nextState = produce(state, draftState => {
//         draftState.AllLoanDetailsRealInsights = data;
//       });
//       return nextState;
//     }

//     case SET_DOCUMENT_TAG_INFO: {
//       const { data } = action.payload;
//       const nextState = produce(state, draftState => {
//         draftState.documentTagInfo = data;
//       });
//       return nextState;
//     }

//     case SET_TAGGING_LOANID_INFO: {
//       const { data } = action.payload;
//       const nextState = produce(state, draftState => {
//         draftState.loantaginfo = data;
//       });
//       return nextState;
//     }

//     // case GET_DOCS_BY_DRAW_ID: {
//     //   const { data } = action.payload;
//     //   const nextState = produce(state, draftState => {
//     //     draftState.drawDocs = data;
//     //   });
//     //   return nextState;
//     // }

//     case SET_ALL_REALINSIGHTS_DATA: {
//       const { data } = action.payload;
//       const nextState = produce(state, draftState => {
//         draftState.AllRealInsightsData = data;
//       });
//       return nextState;
//     }

//     case SET_CUSTOMER_DATA: {
//       const { data } = action.payload;
//       const nextState = produce(state, draftState => {
//         draftState.CustomerData = data;
//       });
//       return nextState;
//     }

//     case SET_CUSTOMER_DATA_COPYTOBOX: {
//       const { data } = action.payload;
//       const nextState = produce(state, draftState => {
//         draftState.CustomerDataCopytobox = data;
//       });
//       return nextState;
//     }

//     case  SET_LOAN_DATA_REALINSIGHTS: {
//       const { data } = action.payload;
//       const nextState = produce(state, draftState => {
//         draftState.LoanDataRealInsights = data;
//       });
//       return nextState;
//     }

//     case SET_LOANDETAILS_REALINSIGHTS:{
//       const { data } = action.payload;
//       const nextState = produce(state, draftState => {
//         draftState.LoanDetailsRealInsights = data;
//       });
//       return nextState;
//     }

//     case SET_FILTER_DATA: {
//       const { data } = action.payload;
//       const nextState = produce(state, draftState => {
//         draftState.filterData = data;
//       });
//       return nextState;
//     }

//     case SET_FILTER_DATA_COPYTOBOX: {
//       const { data } = action.payload;
//       const nextState = produce(state, draftState => {
//         draftState.filterDataCopytobox = data;
//       });
//       return nextState;
//     }

//     case SET_SEARCH_VALUE: {
//       const { data } = action.payload;
//       const nextState = produce(state, draftState => {
//         draftState.searchValue = data;
//       });
//       return nextState;
//     }

//     case SET_SEARCH_VALUE_COPYTOBOX: {
//       const { data } = action.payload;
//       const nextState = produce(state, draftState => {
//         draftState.searchValueCopytobox = data;
//       });
//       return nextState;
//     }

//     default:
//       return state;
//   }
// };
