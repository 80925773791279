import React, { useCallback, useEffect, useState } from "react";
import { debounce, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { LoanStage } from "@toorak/tc-common-fe-sdk";
import { SubmitException } from "./SubmitException";
import { DiscussionField } from "../form-components/DiscussionField";
import {
  postChatBodyByChatId
  // deleteUploadedDocument
  // updateDocumentInException,
  // addToBulkSatisfyPayload,
  // deleteFromBulkSatisfyPayload
} from "../../stores/ExceptionsResults/ExceptionActions";
import { deleteUploadedDocumentException } from "../../stores/ExceptionsResults/ExceptionsReducer";
import { handleDownload } from "../waiver-form/FileUploadAPIs";
import { FileBar } from "../waiver-form/FileBar";
import { waiverFormStyles } from "../waiver-form/Common.style";
import { DocsProgressCard } from "../documents/DocsProgressBar";
import { RootState } from "../../stores/rootReducer";
import { ShowDialog } from "../waiver-form/ShowDialog";
import { ObjectType } from "../../masterView/common";
import {
  updateDocumentInException,
  addToBulkSatisfyPayload,
  deleteFromBulkSatisfyPayload
} from "../../stores/ExceptionsResults/ExceptionsReducer";

export interface ResponseAndDocumentUploadCardProps {
  type?: string;
  actionId?: any;
  actionType?: any;
  isRequired?: any;
  documentsDetails?: any;
  handleEnquirySubmit?: any;
  exceptionsResult?: any;
  loanDetail?: any;
  passedFile?: any;
  conditionType?: any;
  exceptionId?: string;
  showViewDetails?: any;
  getDetails?: any;
  isDashboard?: any;
  waiverId?: any;
  clearUploadedFile?: any;
  loanType?: string;
  loanId?: string;
  loanStage?: LoanStage;
  visibility?: any;
  exceptionData?: ObjectType;
  customStyle?:any
}

export const validStringWithoutSpace = (str: string) => {
  return str.match(".*[\\S].*");
};
export const ResponseAndDocumentUploadCard = (
  props: ResponseAndDocumentUploadCardProps
) => {
  const classes = waiverFormStyles();
  const [valid, setValid] = useState<boolean>(false);
  let { exceptionId } = useParams<{
    exceptionId: string;
  }>() as any;
  const {
    type,
    actionId,
    actionType,
    isRequired,
    documentsDetails,
    handleEnquirySubmit,
    loanDetail,
    passedFile,
    conditionType,
    getDetails,
    showViewDetails,
    isDashboard,
    waiverId,
    clearUploadedFile,
    loanType,
    loanId,
    loanStage,
    visibility,
    exceptionData,
    customStyle
  } = props;

  if (!exceptionId && props.exceptionId) {
    exceptionId = props.exceptionId;
  }
  let bulkSatisfyPayload = useSelector<RootState, any>(
    (state) => state.exceptionsResultsStore?.bulkSatisfyPayload
  );
  bulkSatisfyPayload = bulkSatisfyPayload.filter(
    (val: any) => val.exceptionId === exceptionId
  );
  const dispatch = useDispatch();
  const [filesList, setFilesList] = useState<any>([]);
  const [value, setValue] = useState<string>("");
  const [dialogdetails, setDialogdetails] = useState<any>({});
  const [openDialog, setOpenDialog] = useState(false);
  const [fileInfoChangedFlag, setFileInfoChangedFlag] = useState(false);
  const hasDocuments = !!filesList?.length;
  const isConditionsAndInquiriesTab =
    window.location.href.indexOf("createLoan") !== -1;
  useEffect(() => {
    if (bulkSatisfyPayload?.length) {
      if (bulkSatisfyPayload[0].filesList?.length) {
        updateFilesList(bulkSatisfyPayload[0].filesList);
      }
      setValue(bulkSatisfyPayload[0].lineText);
    }
    // eslint-disable-next-line
  }, [exceptionId]);

  useEffect(() => {
    if (actionType === "Satisfy Condition") {
      if (
        (value !== undefined &&
          validStringWithoutSpace(value) !== null &&
          value.length > 0) ||
        hasDocuments
      ) {
        if (isDashboard) {
          debouncedBulkSatisfyPayloadUpdate({
            exceptionId,
            filesList,
            value,
            loanId,
            loanStage,
            visibility,
            flag: true
          });
        }
        setValid(true);
      } else {
        if (isDashboard) {
          debouncedBulkSatisfyPayloadUpdate({
            exceptionId,
            flag: false
          });
        }
        setValid(false);
      }
    } else {
      if (
        value !== undefined &&
        validStringWithoutSpace(value) !== null &&
        value.length > 0
      ) {
        setValid(true);
      } else {
        setValid(false);
      }
    }
    // eslint-disable-next-line
  }, [value, fileInfoChangedFlag]);

  const debouncedBulkSatisfyPayloadUpdate = useCallback(
    debounce(async (data: any) => {
      if (data.flag) {
        const { exceptionId, filesList, loanId, loanStage, visibility } = data;
        dispatch(
          addToBulkSatisfyPayload({
            exceptionId,
            filesList,
            lineText: data.value,
            loanId,
            loanStage,
            visibility
          })
        );
      } else {
        dispatch(deleteFromBulkSatisfyPayload(data.exceptionId));
      }
    }, 300),
    []
  );

  const updateFilesList = (value: any) => {
    setFilesList(value);
    setFileInfoChangedFlag(!fileInfoChangedFlag);
  };

  const activeInteractionsModal = useSelector<RootState, any>(
    (state) => state.createLoanStore.activeInteractionsModal
  );
  const docsTags = useSelector<RootState, any>((state) =>
    isDashboard || activeInteractionsModal
      ? state.createLoanStore.dashboardTags[`${loanType}${loanStage}SUBMIT`]
      : state.createLoanStore.tags
  );
  const handleChangeTagWrapper = async (
    file: any,
    selectedTag: any[],
    i: number
  ) => {
    const fileList = JSON.parse(JSON.stringify(filesList));
    const f: any = fileList.find(
      (item: any) => item.uniqueId === file.uniqueId
    );
    const copyTags = selectedTag?.map((item) => item.name);
    const addTagsBody = selectedTag.map((item) => {
      return {
        createdBy: "<userId>",
        tag: { name: item.name, code: item.code },
        tagStatus: "VALID/",
        tagType: "MANUAL",
        updatedBy: "<userId>"
      };
    });
    if (f) {
      if (!f.tag) {
        f.tag = [];
        f.tagCodeList = [];
      }
      f.tag = copyTags;
      f.tagCodeList = addTagsBody;
      if (f?.body?.[0]) {
        f.body[0].tags = addTagsBody;
      }
      updateFilesList([...fileList]);
    }
  };
  const handleTagRemove = async (file: any, tag: any, index: number) => {
    const fileList = JSON.parse(JSON.stringify(filesList));
    const fileByName = fileList[index]?.tag?.filter(
      (item: any) => item !== tag
    );
    let tagByName = fileList[index]?.tagCodeList?.filter(
      (item: any) => item.tag?.name !== tag
    );
    if (!Array.isArray(tagByName)){
      tagByName = tagByName ? [tagByName]: [];
    }
    fileList[index].tag = fileByName;
    fileList[index].tagCodeList = tagByName;
    if (fileList[index]?.body?.[0]){
      fileList[index].body[0].tags = tagByName
    }
    updateFilesList([...fileList]);
  };

  const debouncedChatUpdate = useCallback(
    debounce(async (data: any) => {
      if (validStringWithoutSpace(data) !== null) {
        dispatch(postChatBodyByChatId(actionId, data, hasDocuments));
      }
    }, 300),
    []
  );
  const handleChange = (event: any) => {
    setValue(event.target.value);
    debouncedChatUpdate(event.target.value);
  };
  const handleTagClick = (index: any, uniqueId: any) => (checked: boolean) => {
    const fileList = JSON.parse(JSON.stringify(filesList));
    const selectedFile = fileList.find(
      (item: any) => item.uniqueId === uniqueId
    );
    selectedFile.tagRequired = !selectedFile.tagRequired;
    if (!selectedFile.tagRequired) {
      selectedFile.tag = [];
      selectedFile.tagCodeList = [];
      if (fileList[index].body?.[0]){
        fileList[index].body[0].tags = [];
      }
    }
    updateFilesList([...fileList]);
  };
  const deleteUploadedDocumentWrapper = (file: any, id: string) => {
    dispatch(deleteUploadedDocumentException({ actionId: id }));
    // dispatch(deleteUploadedDocumentException(file, id));
    const fileList = JSON.parse(JSON.stringify(filesList));
    const fileIndex = fileList.findIndex(
      (item?: any) => item.docsId === file.docsId
    );
    fileList.splice(fileIndex, 1);
    updateFilesList(fileList);
    if (type !== "inquiry") {
      dispatch(updateDocumentInException({ filesList: fileList, actionId }));
    }
    if (isConditionsAndInquiriesTab) {
      if (clearUploadedFile) {
        clearUploadedFile();
      }
    } else {
      if (clearUploadedFile) {
        clearUploadedFile(null);
      }
    }
  };
  return (
    <>
      {isRequired || type === "inquiry" ? null : (
        <DiscussionField
          actionType={actionType}
          actionId={actionId}
          handleChange={(e: any) => handleChange(e)}
          value={value}
          placeholder="Write here"
        />
      )}
      {filesList?.length > 0 && !isRequired ? (
        <div data-testid="file-upload-card">
          {filesList && filesList?.length
            ? Object.entries(filesList)?.map(([key, item]: [any, any], i) => (
                <div
                  key={item.uniqueId}
                  className={classes.fileSectionWrappers}
                >
                  <FileBar
                    deleteHandler={() =>
                      deleteUploadedDocumentWrapper(item, actionId)
                    }
                    tagClickedHandler={handleTagClick(i, item.uniqueId)}
                    type={type}
                    value={item}
                    isChecked={item.tagRequired}
                  />
                  {item?.tagRequired ? (
                    <DocsProgressCard
                      value={item}
                      i={i}
                      docsTags={docsTags}
                      filesList={filesList}
                      isAllowedToEdit
                      exceptionId={exceptionId}
                      handleTagRemove={handleTagRemove}
                      handleChangeTag={handleChangeTagWrapper}
                      customStyle={customStyle}
                    />
                  ) : null}
                </div>
              ))
            : null}
        </div>
      ) : (
        documentsDetails &&
        documentsDetails[0] &&
        isRequired &&
        documentsDetails[0].secondaryIdentifierValue.toString() === "1" &&
        documentsDetails[0]?.docs?.map((item: any, key: number) => {
          return (
            <section className={classes.fileBarWrapper}>
              <Grid item xs={12} className={classes.fileContainer}>
                <Grid container>
                  <FileBar
                    downloadHandler={() =>
                      handleDownload(
                        item,
                        documentsDetails[0].primaryIdentifierValue,
                        documentsDetails[0].primaryIdentifierName.toLowerCase()
                      )
                    }
                    type={type}
                    value={item}
                  />
                  {item?.tags?.length > 0 ? (
                    <DocsProgressCard
                      value={item}
                      i={key}
                      docsTags={docsTags}
                      selectedTags={item.tag}
                      filesList={filesList}
                      isAllowedToEdit
                      exceptionId={exceptionId}
                      handleTagRemove={handleTagRemove}
                      handleChangeTag={handleChangeTagWrapper}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </section>
          );
        })
      )}
      {isRequired ? null : (
        <Grid>
          <SubmitException
            actionId={actionId}
            actionType={actionType}
            setFilesList={updateFilesList}
            filesList={filesList}
            valid={valid}
            setDialogdetails={setDialogdetails}
            setOpenDialog={setOpenDialog}
            handleEnquirySubmit={handleEnquirySubmit}
            type={type}
            loanDetail={loanDetail}
            passedFile={passedFile}
            clearUploadedFile={clearUploadedFile}
            getDetails={getDetails}
            showViewDetails={showViewDetails}
            conditionType={conditionType}
            waiverId={waiverId}
            fileInfoChangedFlag={fileInfoChangedFlag}
            setFileInfoChangedFlag={setFileInfoChangedFlag}
            isDashboard={isDashboard}
            exceptionData={exceptionData}
          />
        </Grid>
      )}
      <Grid>
        <ShowDialog
          dialogdetails={dialogdetails}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
        />
      </Grid>
    </>
  );
};
