/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */

// import { produce } from "immer";
// import {
//   AGGREGATE_DIVIDE,
//   CANCEL_AGGREGATE_MODAL,
//   EXPAND_COLLAPSE_ROW_CLICK,
//   KEEP_PREVIOUS_REDUCER_VALUE,
//   RESET_FIELD_VALUES
// } from "./aggregatePropertyTabActions";
// import {
//   UPDATE_LOAN_DETAILS,
//   UPDATE_LOAN_FIELDS,
//   UPDATE_PROPERTY_DETAIL,
//   UPDATE_PROPINFO_TOORVALID,
//   UPDATE_PROPERTY_FIELDS,
//   UPDATE_FILES_IN_CREDIT_EVAL,
//   SET_AUTO_SAVE_VALUES,
//   UPDATE_AUTO_SAVED_FIELDS,
//   CANCEL_AUTOSAVE,
//   SHOW_MESSAGE,
//   HIDE_MESSAGE,
//   DELETE_DOCS_FROM_FILELIST,
//   UPDATE_UNIT_INFO_FIELDS,
//   CLEAR_ESCROW_MAP,
//   OVERRIDE_CHECKLIST
// } from "./loanAndPropertyDetails.action";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface LoanInformation {
  [index: string]: any;
  existingDebtCurrency: string | null;
  closingCostsCurrency: string | null;
  appraiserDYAsStabilizedPercentage: string | null;
  exceptions: string | null;
  approved: string | null;
  comments: string | null;
  excessFirstLossCurrency: string | null;
  interestReserveIRCurrency: string | null;
  twelveMonthIRCurrency: string | null;
  annualDSInitialLoanCurrency: string | null;
  annualDSMaxLoanCurrency: string | null;
}
export interface DocumnetDetails {
  files?: any[];
  taggedArray?: any[];
  failedUploads?: number;
  successfulUploads?: number;
}
export interface LoanCredit {
  [index: string]: any;
  readyforPurchase: string | null;
  summaryOutputSent: string | null;
  purchaseAdviceRequirement: string | null;
  recommendation: string | null;
  additionalNotes: string | null;
  purchaseDate: Date | null;
  inPlaceOccupancyNumeric: number | null;
  inPlaceNOINumeric: number | null;
  inPlaceDSCRNumeric: number | null;
  stabilizedDSCRNumeric: number | null;
  takeoutDSCRNumeric: number | null;
  covenants: string | null;
  appraiserName: string | null;
  appraisalCompany: string | null;
  appraisalManagementCompany: string | null;
  appraisalFormType: string | null;
  licenseNumber: string | null;
  appraisalDate: Date | null;
  appraisalComments: string | null;
  restricted: string | null;
  appraisalReview: string | null;
  appraisalReviewDate: Date | null;
  appraisalReviewComments: string | null;
  engineeringReview: string | null;
  engineeringReviewDate: Date | null;
  engineeringReviewComments: string | null;
  environmentalReview: string | null;
  environmentalReviewDate: Date | null;
  environmentalReviewComments: string | null;
  seismicReview: string | null;
  seismicReviewDate: Date | null;
  seismicReviewComments: string | null;
  truliaCrimeCheck: string | null;
  phase1ClosingDate: string | null;
  phase1HoldbackPercentage: string | null;
  phase1HoldbackAmount: string | null;
  phase1ClosingDatePayment: string | null;
}
export interface LoanDetails {
  [index: string]: any;
  loanInfo: LoanInformation;
  loanCredit: LoanCredit;
  loanConfig: any;
}
export interface PropertyLocation {
  [index: string]: any;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  country: string | null;
  locationValidationStatus: string | null;
  postalCode: string | null;
  state: string | null;
}

export interface PropertyInformation {
  [index: string]: any;
  description: string | null;
  propertyType: string | null;
}
export interface PropertyUnit {
  [index: string]: any;
  propertyUnitId: string | number | null;
  propertyUnitOrder: string | null;
  leaseStatus: string | null;
  marketRentMonthly: string | null;
  inPlaceLeaseRentMonthly: string | null;
  currentLeaseTermMonthly: string | null;
}
export interface PropertyDetails {
  [index: string]: any;
  loanPropertyId: number;
  loanPropertyOrder: number;
  propertyLocation: PropertyLocation;
  propertyInfo: PropertyInformation;
  propertyUnit: PropertyUnit[];
}

export interface AutoSaveValues {
  callAutoSave: boolean;
  tabType: string; // "Loan" | "Property";
  changedFieldId: string[];
  cancelAutoSave?: boolean;
  tabChanged?: {
    prevTab: string;
  };
}
export interface MessagePopUps {
  showMessage: boolean;
  message: any; // should support string, html etc
  header: any; // should support string, html etc
}
export interface LoanAndPropertyReducerType {
  [index: string]: any;
  loanDetails: LoanDetails;
  propertyDetails: PropertyDetails[];
  creditEvalDocuments: DocumnetDetails;
  autoSaveValues: AutoSaveValues;
  messagePopUps?: MessagePopUps;
  aggregatePropertyDetails: {
    [index: string]: any;
    expandedRows: Set<any>;
  };
  propInfotoorvalId?: string | null;
  reducerCopy?: LoanAndPropertyReducerType;
}
/** ***IMPORTANT***
 * In LoanAndPropertyReducerType loan details and property details should have fields
 * that are present in back end services. Dont add fields like active property id,
 * error count etc inside these objects. Keep this reducer as clean as possible
 * (CreateLoanReducer will be refactored and this reducer will later become the reducer for loan details page. This approach of having same object as back end service will help us reduce api calls we are making to get the laon/property object for various features like duplicate loan) */
const initialState: LoanAndPropertyReducerType = {
  loanDetails: {
    loanConfig: null,
    loanInfo: {
      existingDebtCurrency: null,
      closingCostsCurrency: null,
      appraiserDYAsStabilizedPercentage: null,
      exceptions: null,
      approved: null,
      comments: null,
      excessFirstLossCurrency: null,
      interestReserveIRCurrency: null,
      twelveMonthIRCurrency: null,
      annualDSInitialLoanCurrency: null,
      annualDSMaxLoanCurrency: null
    },
    loanCredit: {
      readyforPurchase: null,
      summaryOutputSent: null,
      purchaseAdviceRequirement: null,
      recommendation: null,
      additionalNotes: null,
      purchaseDate: null,
      inPlaceOccupancyNumeric: null,
      inPlaceNOINumeric: null,
      inPlaceDSCRNumeric: null,
      stabilizedDSCRNumeric: null,
      takeoutDSCRNumeric: null,
      covenants: null,
      appraiserName: null,
      appraisalCompany: null,
      appraisalManagementCompany: null,
      appraisalFormType: null,
      licenseNumber: null,
      appraisalDate: null,
      appraisalComments: null,
      restricted: null,
      appraisalReview: null,
      appraisalReviewDate: null,
      appraisalReviewComments: null,
      engineeringReview: null,
      engineeringReviewDate: null,
      engineeringReviewComments: null,
      environmentalReview: null,
      environmentalReviewDate: null,
      environmentalReviewComments: null,
      seismicReview: null,
      seismicReviewDate: null,
      seismicReviewComments: null,
      truliaCrimeCheck: null,
      phase1ClosingDate: null,
      phase1HoldbackPercentage: null,
      phase1HoldbackAmount: null,
      phase1ClosingDatePayment: null
    }
  },
  propertyDetails: [],
  creditEvalDocuments: {
    files: []
  },
  propInfotoorvalId: null,
  autoSaveValues: {
    callAutoSave: false,
    tabType: "loan",
    changedFieldId: []
  },
  aggregatePropertyDetails: {
    expandedRows: new Set()
  }
};
const loanAndPropertySlice = createSlice({
  name: "loanAndProperty",
  initialState: initialState,
  reducers: {
    updateLoanFields: (state, action: PayloadAction<any>) => {
      const { sectionName, fieldName, fieldValue } = action.payload;
      state.loanDetails[sectionName] = {
        ...state.loanDetails[sectionName],
        ...{ [fieldName]: fieldValue }
      };
    },
    updateLoanDetails: (state, action: PayloadAction<any>) => {
      const { loanDetails } = action.payload;
      state.loanDetails = loanDetails;
    },
    updatePropertyDetail: (state, action: PayloadAction<any>) => {
      const { propertyDetails } = action.payload;
      state.propertyDetails = propertyDetails;
    },
    updateToorvalId: (state, action: PayloadAction<any>) => {
      const { toorvalId } = action.payload;
      state.propInfotoorvalId = toorvalId;
    },
    updatePropertyFieldsLoanAndProp: (state, action: PayloadAction<any>) => {
      const { sectionName, fieldName, fieldValue, propertyId } = action.payload;
      const editedProperty: PropertyDetails | undefined =
        state.propertyDetails.find((ele) => ele.loanPropertyId === propertyId);

      if (editedProperty) {
        editedProperty[sectionName] = {
          ...editedProperty[sectionName],
          ...{ [fieldName]: fieldValue }
        };
        state.propertyDetails = state.propertyDetails.map((ele) => {
          if (ele.loanPropertyId === propertyId) {
            return editedProperty;
          }
          return ele;
        });
      }
    },
    updateUnitInfoFields: (state, action: PayloadAction<any>) => {
      const { fieldName, fieldValue, propertyId, unitId } = action.payload;
      let editedProperty: PropertyDetails | undefined =
        state.propertyDetails.find((ele) => ele.loanPropertyId === propertyId);

      if (editedProperty) {
        let editedUnit: PropertyUnit | undefined =
          editedProperty.propertyUnit.find(
            (ele) => ele.propertyUnitId === unitId
          );
        if (editedUnit) {
          editedUnit = { ...editedUnit, [fieldName]: fieldValue };
        }
        const editedPropertyUnits: PropertyUnit[] =
          editedProperty.propertyUnit.map((ele) => {
            if (ele.propertyUnitId === unitId)
              return editedUnit as PropertyUnit;
            return ele;
          });
        editedProperty = {
          ...editedProperty,
          propertyUnit: editedPropertyUnits
        };
        state.propertyDetails = state.propertyDetails.map((ele) => {
          if (ele.loanPropertyId === propertyId) {
            return editedProperty as PropertyDetails;
          }
          return ele;
        });
      }
    },
    aggregateDivide: (state, action: PayloadAction<any>) => {
      const {
        fieldId,
        sectionId,
        value,
        checkIsDisabledUnit,
        perProperty,
        loanPropertyId
      }: any = action.payload;

      state.propertyDetails = state.propertyDetails.map((ele) => {
        if (sectionId === "propertyUnit") {
          // perProperty should be true only when we are using aggregate/divide for aggregate unit info fields. if it is true, then for all other properties except the one being edited, we can return as it is.
          if (
            perProperty &&
            loanPropertyId &&
            loanPropertyId !== ele.loanPropertyId
          ) {
            return ele;
          }
          let unitArray = ele.propertyUnit;
          unitArray = unitArray.map((item: any) => {
            if (!checkIsDisabledUnit({ unitObj: item }))
              return { ...item, [fieldId]: value };
            return item;
          });
          const returnObj = {
            ...ele,
            [sectionId]: unitArray
          };
          return returnObj;
        }
        const sectionObj = ele[sectionId];
        const returnObj = {
          ...ele,
          [sectionId]: { ...sectionObj, [fieldId]: value }
        };
        return returnObj;
      });
    },
    cancelAggregateModal: (state, action: PayloadAction<any>) => {
      // const { reset } = action.payload;
      if (action.payload && state.reducerCopy) {
        state.aggregatePropertyDetails =
          state.reducerCopy.aggregatePropertyDetails;
        state.propertyDetails = state.reducerCopy.propertyDetails;
        state.loanDetails = state.reducerCopy.loanDetails;
        delete state.reducerCopy;
      } else {
        delete state.reducerCopy;
      }
    },
    updateFilesInnCreditEval: (state, action: PayloadAction<any>) => {
      const { file } = action.payload;
      const myFileArray: any[] = file;
      state.creditEvalDocuments.files = myFileArray;
    },
    deleteDocFromFileList: (state, action: PayloadAction<any>) => {
      const { docsId } = action.payload;
      const filteredArray = state.creditEvalDocuments.files?.filter((item) => {
        return item.docsId !== docsId;
      });
      state.creditEvalDocuments.files = filteredArray;
    },
    setAutoSaveValuesCall: (state, action: PayloadAction<any>) => {
      const { callAutoSave, tabType, changedFieldId }: any = action.payload;
      state.autoSaveValues.callAutoSave = callAutoSave;
      state.autoSaveValues.tabType = tabType;
      state.autoSaveValues.cancelAutoSave = false;
      state.autoSaveValues.changedFieldId = [
        ...state.autoSaveValues.changedFieldId,
        changedFieldId
      ];
      // when tab is changed and field is edited set this below variable to auto save the fields not saved in previous tab
      if (
        state.autoSaveValues.tabType !== tabType &&
        state.autoSaveValues.changedFieldId.length
      ) {
        state.autoSaveValues.tabChanged = {
          prevTab: state.autoSaveValues.tabType
        };
      }
    },
    updateLastAutoSavedFields: (state, action: PayloadAction<any>) => {
      const { savedFields } = action.payload;
      const savedFieldsLength = savedFields.length;
      const changedFieldsInRedux = state.autoSaveValues.changedFieldId;
      const changedFieldIdLength = changedFieldsInRedux.length;
      // if length of changedFieldId in redux is same as the savedFields, then there is no recent update to save in db
      if (savedFieldsLength === changedFieldIdLength) {
        state.autoSaveValues.changedFieldId = [];
        state.autoSaveValues.callAutoSave = false;
      } else if (savedFieldsLength < changedFieldIdLength) {
        state.autoSaveValues.changedFieldId = changedFieldsInRedux.slice(
          savedFieldsLength,
          undefined
        );
      }
      state.autoSaveValues.tabChanged = undefined;
    },
    resetFieldValues: (state, action: PayloadAction<any>) => {
      const { fieldId, sectionId } = action.payload as {
        fieldId: string;
        sectionId: string;
      };
      state.propertyDetails = state.propertyDetails.map((ele, propIndex) => {
        if (sectionId === "propertyUnit") {
          const units = ele.propertyUnit.map((item, unitIndex) => {
            return {
              ...item,
              [fieldId]:
                state.reducerCopy?.propertyDetails?.[propIndex]?.propertyUnit?.[
                  unitIndex
                ]?.[fieldId]
            };
          });
          return {
            ...ele,
            propertyUnit: units
          };
        }
        return {
          ...ele,
          [sectionId]: {
            ...ele[sectionId],
            [fieldId]:
              state.reducerCopy?.propertyDetails?.[propIndex]?.[sectionId]?.[
                fieldId
              ]
          }
        };
      });
    },
    keepPrevReducerVal: (state) => {
      state.reducerCopy = state;
    },
    cancelAutoSavee: (state) => {
      state.autoSaveValues.cancelAutoSave = true;
    },
    showPopUpMessages: (state, action: PayloadAction<any>) => {
      const { message, header } = action.payload;
      state.messagePopUps = { showMessage: true, message, header };
    },
    hideMessages: (state) => {
      state.messagePopUps = {
        showMessage: false,
        message: "",
        header: ""
      };
    },
    expandCollapseRowClick: (state, action: PayloadAction<any>) => {
      const { rowId } = action.payload;
      const { expandedRows } = state.aggregatePropertyDetails;
      if (expandedRows.has(rowId)) {
        state.aggregatePropertyDetails.expandedRows.delete(rowId);
      } else {
        state.aggregatePropertyDetails.expandedRows.add(rowId);
      }
    },
    clearEscrowMap: (state) => {
      state.propertyDetails.forEach((prop: any) => {
        prop.propertyEscrowMap = [];
      });
      state.loanDetails.loanEscrowMap = [];
    },
    overRideChecklist: (state, action: PayloadAction<any>) => {
      if (state.loanDetails.loanDetailId)
        state.loanDetails.loanDetailId.loanSummaryId.checkListOverridenBy =
          action.payload;
    }
  }
});

export const {
  updateLoanFields,
  updateLoanDetails,
  updatePropertyDetail,
  updateToorvalId,
  updatePropertyFieldsLoanAndProp,
  updateUnitInfoFields,
  aggregateDivide,
  cancelAggregateModal,
  updateFilesInnCreditEval,
  deleteDocFromFileList,
  setAutoSaveValuesCall,
  updateLastAutoSavedFields,
  resetFieldValues,
  keepPrevReducerVal,
  cancelAutoSavee,
  showPopUpMessages,
  hideMessages,
  expandCollapseRowClick,
  clearEscrowMap,
  overRideChecklist
} = loanAndPropertySlice.actions;
export const loanAndPropertyStore = loanAndPropertySlice.reducer;

// export const loanAndPropertyStoree = (state = initialState, action: any) => {
//   switch (action.type) {
//     case UPDATE_LOAN_FIELDS: {
//       const { sectionName, fieldName, fieldValue } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         draftState.loanDetails[sectionName] = {
//           ...draftState.loanDetails[sectionName],
//           ...{ [fieldName]: fieldValue }
//         };
//       });
//       return nextState;
//     }
//     case UPDATE_LOAN_DETAILS: {
//       const { loanDetails } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         draftState.loanDetails = loanDetails;
//       });
//       return nextState;
//     }
//     case UPDATE_PROPERTY_DETAIL: {
//       const { propertyDetails } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         draftState.propertyDetails = propertyDetails;
//       });
//       return nextState;
//     }
//     case UPDATE_PROPINFO_TOORVALID: {
//       const { toorvalId } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         draftState.propInfotoorvalId = toorvalId;
//       });
//       return nextState;
//     }
//     case UPDATE_PROPERTY_FIELDS: {
//       const { sectionName, fieldName, fieldValue, propertyId } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         const editedProperty: PropertyDetails | undefined =
//           draftState.propertyDetails.find(
//             (ele) => ele.loanPropertyId === propertyId
//           );

//         if (editedProperty) {
//           editedProperty[sectionName] = {
//             ...editedProperty[sectionName],
//             ...{ [fieldName]: fieldValue }
//           };
//           draftState.propertyDetails = draftState.propertyDetails.map((ele) => {
//             if (ele.loanPropertyId === propertyId) {
//               return editedProperty;
//             }
//             return ele;
//           });
//         }
//       });
//       return nextState;
//     }
//     case UPDATE_UNIT_INFO_FIELDS: {
//       const { fieldName, fieldValue, propertyId, unitId } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         let editedProperty: PropertyDetails | undefined =
//           draftState.propertyDetails.find(
//             (ele) => ele.loanPropertyId === propertyId
//           );

//         if (editedProperty) {
//           let editedUnit: PropertyUnit | undefined =
//             editedProperty.propertyUnit.find(
//               (ele) => ele.propertyUnitId === unitId
//             );
//           if (editedUnit) {
//             editedUnit = { ...editedUnit, [fieldName]: fieldValue };
//           }
//           const editedPropertyUnits: PropertyUnit[] =
//             editedProperty.propertyUnit.map((ele) => {
//               if (ele.propertyUnitId === unitId)
//                 return editedUnit as PropertyUnit;
//               return ele;
//             });
//           editedProperty = {
//             ...editedProperty,
//             propertyUnit: editedPropertyUnits
//           };
//           draftState.propertyDetails = draftState.propertyDetails.map((ele) => {
//             if (ele.loanPropertyId === propertyId) {
//               return editedProperty as PropertyDetails;
//             }
//             return ele;
//           });
//         }
//       });
//       return nextState;
//     }
//     case AGGREGATE_DIVIDE: {
//       const {
//         fieldId,
//         sectionId,
//         value,
//         checkIsDisabledUnit,
//         perProperty,
//         loanPropertyId
//       } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         draftState.propertyDetails = state.propertyDetails.map((ele) => {
//           if (sectionId === "propertyUnit") {
//             // perProperty should be true only when we are using aggregate/divide for aggregate unit info fields. if it is true, then for all other properties except the one being edited, we can return as it is.
//             if (
//               perProperty &&
//               loanPropertyId &&
//               loanPropertyId !== ele.loanPropertyId
//             ) {
//               return ele;
//             }
//             let unitArray = ele.propertyUnit;
//             unitArray = unitArray.map((item: any) => {
//               if (!checkIsDisabledUnit({ unitObj: item }))
//                 return { ...item, [fieldId]: value };
//               return item;
//             });
//             const returnObj = {
//               ...ele,
//               [sectionId]: unitArray
//             };
//             return returnObj;
//           }
//           const sectionObj = ele[sectionId];
//           const returnObj = {
//             ...ele,
//             [sectionId]: { ...sectionObj, [fieldId]: value }
//           };
//           return returnObj;
//         });
//       });
//       return nextState;
//     }
//     case CANCEL_AGGREGATE_MODAL: {
//       const { reset } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         if (reset && state.reducerCopy) {
//           draftState.aggregatePropertyDetails =
//             state.reducerCopy.aggregatePropertyDetails;
//           draftState.propertyDetails = state.reducerCopy.propertyDetails;
//           draftState.loanDetails = state.reducerCopy.loanDetails;
//           delete draftState.reducerCopy;
//         } else {
//           delete draftState.reducerCopy;
//         }
//       });
//       return nextState;
//     }
//     case UPDATE_FILES_IN_CREDIT_EVAL: {
//       const { file } = action.payload;
//       const myFileArray: any[] = file;
//       const nextState = produce(state, (draftState) => {
//         draftState.creditEvalDocuments.files = myFileArray;
//       });
//       return nextState;
//     }
//     case DELETE_DOCS_FROM_FILELIST: {
//       const { docsId } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         const filteredArray = state.creditEvalDocuments.files?.filter(
//           (item) => {
//             return item.docsId !== docsId;
//           }
//         );
//         draftState.creditEvalDocuments.files = filteredArray;
//       });
//       return nextState;
//     }
//     case SET_AUTO_SAVE_VALUES: {
//       const { callAutoSave, tabType, changedFieldId } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         draftState.autoSaveValues.callAutoSave = callAutoSave;
//         draftState.autoSaveValues.tabType = tabType;
//         draftState.autoSaveValues.cancelAutoSave = false;
//         draftState.autoSaveValues.changedFieldId = [
//           ...draftState.autoSaveValues.changedFieldId,
//           changedFieldId
//         ];
//         // when tab is changed and field is edited set this below variable to auto save the fields not saved in previous tab
//         if (
//           state.autoSaveValues.tabType !== tabType &&
//           state.autoSaveValues.changedFieldId.length
//         ) {
//           draftState.autoSaveValues.tabChanged = {
//             prevTab: state.autoSaveValues.tabType
//           };
//         }
//       });
//       return nextState;
//     }
//     case UPDATE_AUTO_SAVED_FIELDS: {
//       const { savedFields } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         const savedFieldsLength = savedFields.length;
//         const changedFieldsInRedux = state.autoSaveValues.changedFieldId;
//         const changedFieldIdLength = changedFieldsInRedux.length;
//         // if length of changedFieldId in redux is same as the savedFields, then there is no recent update to save in db
//         if (savedFieldsLength === changedFieldIdLength) {
//           draftState.autoSaveValues.changedFieldId = [];
//           draftState.autoSaveValues.callAutoSave = false;
//         } else if (savedFieldsLength < changedFieldIdLength) {
//           draftState.autoSaveValues.changedFieldId = changedFieldsInRedux.slice(
//             savedFieldsLength,
//             undefined
//           );
//         }
//         draftState.autoSaveValues.tabChanged = undefined;
//       });
//       return nextState;
//     }
//     case RESET_FIELD_VALUES: {
//       const { fieldId, sectionId } = action.payload as {
//         fieldId: string;
//         sectionId: string;
//       };
//       const nextState = produce(state, (draftState) => {
//         draftState.propertyDetails = state.propertyDetails.map(
//           (ele, propIndex) => {
//             if (sectionId === "propertyUnit") {
//               const units = ele.propertyUnit.map((item, unitIndex) => {
//                 return {
//                   ...item,
//                   [fieldId]:
//                     state.reducerCopy?.propertyDetails?.[propIndex]
//                       ?.propertyUnit?.[unitIndex]?.[fieldId]
//                 };
//               });
//               return {
//                 ...ele,
//                 propertyUnit: units
//               };
//             }
//             return {
//               ...ele,
//               [sectionId]: {
//                 ...ele[sectionId],
//                 [fieldId]:
//                   state.reducerCopy?.propertyDetails?.[propIndex]?.[
//                     sectionId
//                   ]?.[fieldId]
//               }
//             };
//           }
//         );
//       });
//       return nextState;
//     }
//     case KEEP_PREVIOUS_REDUCER_VALUE: {
//       const nextState = produce(state, (draftState) => {
//         draftState.reducerCopy = state;
//       });
//       return nextState;
//     }
//     case CANCEL_AUTOSAVE: {
//       const nextState = produce(state, (draftState) => {
//         draftState.autoSaveValues.cancelAutoSave = true;
//       });
//       return nextState;
//     }
//     case SHOW_MESSAGE: {
//       const { message, header } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         draftState.messagePopUps = { showMessage: true, message, header };
//       });
//       return nextState;
//     }
//     case HIDE_MESSAGE: {
//       const nextState = produce(state, (draftState) => {
//         draftState.messagePopUps = {
//           showMessage: false,
//           message: "",
//           header: ""
//         };
//       });
//       return nextState;
//     }
//     case EXPAND_COLLAPSE_ROW_CLICK: {
//       const { rowId } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         const { expandedRows } = state.aggregatePropertyDetails;
//         if (expandedRows.has(rowId)) {
//           draftState.aggregatePropertyDetails.expandedRows.delete(rowId);
//         } else {
//           draftState.aggregatePropertyDetails.expandedRows.add(rowId);
//         }
//       });
//       return nextState;
//     }
//     case CLEAR_ESCROW_MAP: {
//       const nextState = produce(state, (draftState) => {
//         draftState.propertyDetails.forEach((prop: any) => {
//           prop.propertyEscrowMap = [];
//         });
//         draftState.loanDetails.loanEscrowMap = [];
//       });
//       return nextState;
//     }
//     case OVERRIDE_CHECKLIST: {
//       const nextState = produce(state, (draftState) => {
//         draftState.loanDetails.loanDetailId.loanSummaryId.checkListOverridenBy =
//           action.payload;
//       });
//       return nextState;
//     }
//     default:
//       return state;
//   }
// };
