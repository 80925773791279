/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import Badge from "@mui/material/Badge";
import { push } from "connected-react-router";
import { IconTooltip, LoanTypes } from "@toorak/tc-common-fe-sdk";
import { useStyles } from "../common-style/dashboard.style";
import { RootState } from "../../stores/rootReducer";
import { ZeroState as ZeroStateException } from "../exceptions-and-enquiries/ZeroState";
import {
  getNotification,
  updateNotificationStatus,
  // updateList,
  // notificationClickedUpdate,
  // updateViewDetailsPath,
  getPersonId
} from "../../stores/dashboardSection/dashboard.action";
import { setTabView } from "../../stores/SettlementAndPurchase/SettlementAndPurchase.reducer";
import { getDateInFormat } from "../../utils/utils";
// import { setCookieWithIdentifier } from "../../utils/cookies";
import { redirectToLoanPage } from "../create-loan.action";
import { updateReadStatusDash } from "../../stores/ExceptionsResults/ExceptionActions";
import oIcon from "../../images/exceptions/o.svg";
import tIcon from "../../images/exceptions/t.svg";
import { getLoanType } from "../../config/config";
import { isLoanOnHold } from "../../utils/AccessManagement";
import {
  notificationClickedUpdate,
  updateFromIndex,
  updateViewDetailsPath
} from "../../stores/dashboardSection/dashboard.reducer";
// import { IconTooltip } from "@toorak/tc-common-fe-sdk";

interface Props {
  value?: number;
  fromBell?: any;
}

export const renderAccountTypeImage = (
  accountType: string,
  fromCompactLoanTable?: string
) => {
  return (
    <span>
      {accountType === "LAT" ? (
        <img
          src={tIcon}
          alt="lat"
          style={{
            margin: !fromCompactLoanTable
              ? "0px 5px 1px 5px"
              : "0px 5px 1px 4px"
          }}
        />
      ) : accountType === "ORIGINATOR" ? (
        <img
          src={oIcon}
          alt="Originator"
          // style={{ padding: "1px 3px 3px 3px" }}
          style={{ margin: "0px 0px 1px 0px" }}
        />
      ) : (
        <>
          <img
            src={oIcon}
            alt="Originator"
            style={{ margin: "0px 4px 3px 1px" }}
          />
          <span style={{ marginRight: "5px" }}>&</span>
          <img
            src={tIcon}
            alt="lat"
            // style={{ padding: "1px 3px 1px 3px" }}
            style={{ margin: "0px 5px 2px 0px" }}
          />
        </>
      )}
    </span>
  );
};

export const UnreadCountTag = (props: any) => {
  const {
    status,
    unreadCount,
    background,
    color,
    padding,
    display,
    marginBottom,
    flexBasis,
    align,
    fromCompactLoanTable,
    fontWeight
  } = props;
  const margin = props?.marginRight || 0;
  const width = props?.maxWidth;

  if (status === "") return null;
  return (
    <Grid
      item
      justifyContent="flex-end"
      style={{
        borderRadius: "5px",
        background,
        textAlign: "center",
        padding,
        marginRight: margin,
        display,
        marginBottom,
        flexBasis,
        alignItems: align,
        maxWidth: width
      }}
    >
      <span
        style={{
          color,
          fontSize: 10,
          fontWeight,
          // display: "flex",
          // alignItems: "center"
          alignSelf: "center"
        }}
      >
        <span>
          <span
            style={{
              paddingTop:
                status === "LAT" ||
                (status === "ORIGINATOR" && fromCompactLoanTable)
                  ? "0px"
                  : "0px ",
              paddingRight: "3px",
              display: "inline-block"
              // verticalAlign: "middle"
            }}
          >
            {unreadCount}
          </span>
          {status && fromCompactLoanTable
            ? renderAccountTypeImage(status, fromCompactLoanTable)
            : status}{" "}
          {/* {status && renderAccountTypeImage(status)} */}
        </span>
        {props?.infoIconData ? (
          <span style={{ marginBottom: "2px" }}>
            <IconTooltip
              // topic="Information_white"
              topic="Information"
              infoIconData={props?.infoIconData}
            />
          </span>
        ) : null}
      </span>
    </Grid>
  );
};
export const Notification = (props: Props) => {
  const { fromBell } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getNotification(0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let prevHorizontalScroll = 0;

  async function handleScroll(event: React.UIEvent<HTMLDivElement>) {
    const target = event.target as HTMLDivElement;

    if (!isAtBottom(event)) {
      return;
    }
    if (prevHorizontalScroll !== target.scrollLeft) {
      prevHorizontalScroll = target.scrollLeft;
      return;
    }
    if (!disableNotScroll) {
      dispatch(getNotification(notificationCurrentSize, true));
    }
  }
  function isAtBottom(event: React.UIEvent<HTMLDivElement>): boolean {
    const target = event.target as HTMLDivElement;
    return target.clientHeight + target.scrollTop === target.scrollHeight;
  }

  function redirectToNotification(event: any, element: any) {
    event.stopPropagation();
    dispatch(updateFromIndex(-20));
    dispatch(notificationClickedUpdate(true));
    dispatch(updateViewDetailsPath(true));
    dispatch(updateNotificationStatus(element?.status?.notificationId));
    const loanTypePath = element?.redirectLink?.attributes?.loanType.includes(
      "30"
    )
      ? getLoanType[1].displayValue
      : element?.redirectLink?.attributes?.loanType.replace(/\s/g, "");
    const scenarioId = element && element?.redirectLink?.attributes.scenarioId;
    const exceptionId =
      element && element?.redirectLink?.attributes.exceptionId;
    const waiversId = element && element?.redirectLink?.attributes.waiversId;
    switch (element?.redirectLink?.page?.toUpperCase()) {
      case "WAIVER":
        if (element?.redirectLink) {
          // setCookieWithIdentifier(
          //   "waiverDetails",
          //   element?.redirectLink?.attributes?.waiverId,
          //   JSON.stringify({
          //     subject: element?.redirectLink?.attributes?.waiverTitle,
          //     reason: element?.redirectLink?.attributes?.waiverDescription,
          //     details: {
          //       tab: "LOAN",
          //       id: "",
          //       backLocation: window.location.pathname,
          //       testGroup: "testGroup",
          //       representableTestName:
          //         element?.redirectLink?.attributes?.waiverTitle,
          //       waiverId:element?.redirectLink?.attributes?.waiverId
          //     }
          //   })
          // );
          navigate(
              `/loan/waiver/request/${element?.redirectLink?.attributes?.loanStage}/${element?.redirectLink?.attributes?.loanId}/view/${element?.redirectLink?.attributes?.waiverId}`
            );
        }
        break;
      case "EXCEPTION":
        if (element?.redirectLink?.attributes?.exceptionId) {
          navigate(
              `/loan/create/${loanTypePath}/${element?.redirectLink?.attributes?.loanStage}/${element?.redirectLink?.attributes?.loanId}/${element?.redirectLink?.attributes?.exceptionType}/${element?.redirectLink?.attributes?.exceptionId}/${element?.redirectLink?.attributes?.visibility}/view`
            );
          updateReadStatusDashboard(exceptionId);
        }
        if (element?.redirectLink?.attributes?.waiverId) {
          if (element?.redirectLink?.attributes?.isRescindView) {
            navigate(
                `/loan/create/${loanTypePath}/${element?.redirectLink?.attributes?.loanStage}/${element?.redirectLink?.attributes?.loanId}/${element?.redirectLink?.attributes?.exceptionType}/${element?.redirectLink?.attributes?.exceptionId}/${element?.redirectLink?.attributes?.visibility}/view?isRescindView=true`
              );
          } else {
            navigate(
                `/loan/create/${loanTypePath}/${element?.redirectLink?.attributes?.loanStage}/${element?.redirectLink?.attributes?.loanId}/${element?.redirectLink?.attributes?.exceptionType}/${element?.redirectLink?.attributes?.exceptionId}/${element?.redirectLink?.attributes?.visibility}/view`
              );
          }
          updateReadStatusDashboard(waiversId);
        }
        break;
      case "LOAN":
        if (element?.redirectLink?.tab === "DISCUSSION") {
          navigate(
              `/loan/create/${loanTypePath}/${element?.redirectLink?.attributes?.loanStage}/${element?.redirectLink?.attributes?.loanId}/discussion/${element?.redirectLink?.attributes?.chatId}/view`
            );
        } else if (
          window.location.href.split("/").pop() ===
          element?.redirectLink?.attributes?.loanId
        ) {
          // in loan detail page with same loan ID
        } else {
          dispatch(
            redirectToLoanPage(
              loanTypePath,
              element?.redirectLink?.attributes?.loanStage,
              element?.redirectLink?.attributes?.loanId,
              navigate
            )
          );
        }
        break;
      case "TOORVAL":
        dispatch(
          redirectToLoanPage(
            loanTypePath,
            element?.redirectLink?.attributes?.loanStage,
            element?.redirectLink?.attributes?.loanId,
            navigate
          )
        );
        break;
      case "LOAN_LIST":
        navigate(`/loans/list?tab=1`);
        dispatch(setTabView(1));
        break;
      case "BUNDLE":
        if (element?.redirectLink) {
          const attrs: any = element?.redirectLink?.attributes;
          const commentType: string =
            attrs.loanId === attrs.bundleId ? "bundle" : "loan";
          const loanTypeF: string =
            attrs.loanType === LoanTypes.ThirtyYearLoan ? "DSCR" : "Bridge";
          const uriPath = `/bundle/${attrs.bundleId}/loanType/${loanTypeF}/view`;
          if (commentType === "bundle") {
            navigate(`${uriPath}?openBundleComment=true`);
          } else {
            navigate(`${uriPath}?openLoanComment=${attrs.loanId}`);
          }
        }
        break;
      case "SCENARIO":
        navigate(`/loan/scenario/${scenarioId}/view`);
        break;
      case "FINANCIBILITY":
        navigate(
            `/loans/list?tab=10&loanId=${element?.redirectLink?.attributes?.loanId}&primaryLoanId=${element?.redirectLink?.attributes?.primaryLoanId}`
          );
        break;
      default:
        navigate(`/loans/list?tab=1`);
        dispatch(setTabView(1));
    }
  }

  const notifications = useSelector<RootState, any>(
    (state) => state.dashboardStore.notifications
  );
  const bellNotifications = useSelector<RootState, any>(
    (state) => state.dashboardStore.bellNotifications
  );
  const unReadCount = useSelector<RootState, any>(
    (state) => state.dashboardStore.unReadCount
  );
  const notificationCurrentSize = useSelector<RootState, any>(
    (state) => state.dashboardStore.notificationCurrentSize
  );
  const disableNotScroll = useSelector<RootState, any>(
    (state) => state.dashboardStore.disableNotScroll
  );

  const countData = useSelector<RootState, any>(
    (state) => state.dashboardStore.countData
  );

  const updateReadStatusDashboard = (id: string) => {
    const itemClicked = countData[id]?.filter(
      (item: any) => item?.resource?.resourceValue === id
    );
    const resourceIdArray =
      itemClicked &&
      itemClicked?.map((ele: any) => {
        const obj = {
          resourceId: ele.resource.resourceId
        };
        return obj;
      });
    if (resourceIdArray) {
      dispatch(updateReadStatusDash(resourceIdArray, getPersonId()));
    }
  };

  const classes = useStyles();

  const onHoldUI = (value: any) => {
    const onHoldLoan = isLoanOnHold(value?.redirectLink?.attributes?.onHoldBy);
    return onHoldLoan && <span className={classes.onHoldTag}>ON HOLD</span>;
  };

  return (
    <>
      <Grid
        container
        xs={12}
        className={`${!fromBell ? classes.notWrapper : classes.notWrapperBell}`}
        item
      >
        <Grid item className={classes.tabStyle} xs={8}>
          Notifications{" "}
          {!fromBell && (
            <>
              <Badge
                badgeContent={unReadCount}
                data-testid="notification-badge"
                overlap="circular"
                variant="standard"
                className="notificationNumberDashboard"
              />
              <Grid
                style={{
                  height: 0,
                  borderRadius: 6,
                  borderStyle: "solid",
                  /* borderColor: "#5e72e4",
                  background: "#5e72e4", */
                  borderColor: "transparent",
                  background: "transparent",
                  margin: "12px 8px 0px 8px",
                  borderWidth: 2,
                  padding: "0px 16px"
                }}
              />
            </>
          )}
        </Grid>
        {fromBell ? (
          <UnreadCountTag
            status="UNREAD"
            display="flex"
            align="center"
            unreadCount={unReadCount}
            background="#d9efff"
            color="#32325d"
            padding="0px 4px"
            fontWeight="bold"
          />
        ) : null}
      </Grid>
      {!fromBell && (
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          item
          className={`${
            !fromBell ? classes.notificationScroll : classes.fromBellNot
          }`}
          onScroll={handleScroll}
        >
          {notifications ? (
            notifications.map((element: any) => {
              return (
                <>
                  <div
                    className={classes.notWrapperContent}
                    onClick={(event: any) => {
                      redirectToNotification(event, element);
                    }}
                  >
                    {element.status?.status !== "READ" && (
                      <div className={classes.unReadNotDot} />
                    )}
                    <p className={classes.msg}>
                      {element?.notificationMessage}
                      {onHoldUI(element)}
                    </p>
                    <p className={classes.lightColor}>
                      {getDateInFormat(element?.createdOn)}
                    </p>
                  </div>
                </>
              );
            })
          ) : (
            <ZeroStateException fromComm />
          )}
        </Grid>
      )}
      {fromBell && (
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          item
          className={`${
            !fromBell ? classes.notificationScroll : classes.fromBellNot
          }`}
          onScroll={handleScroll}
        >
          {bellNotifications ? (
            bellNotifications.map((element: any) => {
              return (
                <div
                  className={classes.notWrapperContent}
                  onClick={(event: any) => {
                    redirectToNotification(event, element);
                  }}
                >
                  <p className={classes.msg}>
                    {element?.notificationMessage} {onHoldUI(element)}
                  </p>
                  <Grid container xs={12}>
                    <Grid item xs={8} className={classes.lightColor}>
                      {getDateInFormat(element?.createdOn)}
                    </Grid>
                    <UnreadCountTag
                      status={element?.status?.status}
                      unreadCount={null}
                      background="#d9efff"
                      color="#32325d"
                      padding="0px 4px"
                      fontWeight="bold"
                    />
                  </Grid>
                </div>
              );
            })
          ) : (
            <ZeroStateException fromComm />
          )}
        </Grid>
      )}
    </>
  );
};
