import { FormField } from "../../../stores/appraisal/appraisal.reducer";

const localStorageName = 'persist-appraisal-form-data';
let formFields = new Map();

export const addToorvalFormUpdate = (loanId: string, fieldName: string, value: FormField) => {
  getStoredData(loanId)
  formFields.set(fieldName, value)
  localStorage.setItem(localStorageName, JSON.stringify({
    loanId: loanId,
    formFields: [...formFields.entries()]
  }))
};

export const getToorvalFormUpdates = (loanId: string) => {
  const storedData = getStoredData(loanId)
  return storedData?.formFields
};

const getStoredData = (loanId: string)=>{
  let data: any = null
  try {
    data = JSON.parse(localStorage.getItem(localStorageName) || '')
  } catch (_e) { }
  if (data?.loanId !== loanId) {
    formFields = new Map();
    return null
  } else if (data?.formFields?.length > 0){
    formFields = new Map(data.formFields)
  }
  return data 
}
