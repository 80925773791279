import {
  cleanDecimal,
  formatValueByType,
  isEmptyValue,
  isValidEmailFormat,
  sanitizeNumber,
  sanitizeNumericInput,
  sanitizePhoneInput
} from "../../utils/formatChecks";
import { USStates } from "../constants/loanCreationDropDownValues";
const { MMMDDYYYYDateFormat } = require("../../utils/utils");

export const formatData = (value: any, type: string) => {
  if (value !== undefined && value !== "" && type) {
    return typeCastForReadOnly(value, type);
  }
  return "";
};
export const formatCommaCheck = (value: any) => {
  if (isNullOrEmpty(value) || value === undefined) {
    return "";
  } else {
    value = value.toString();
    value = value.replace(/,/g, "");
  }
  return value;
};

export const isNullOrEmpty = (value: any) => {
  return [null, ""].includes(value);
};

function isValueUnique(fieldSet: any[], valueToCheck: any, objectToCheck: string, keyToCheck: string, indexToCheck: number) {
  const matchingItems = fieldSet
    .map((item, index) => ({ ...item, index })) // Attach index to each item
    .filter(item => item.field === keyToCheck && item[objectToCheck] === valueToCheck);

  // Consider as unique if length is 0 or if length is 1 and matches the index
  return matchingItems.length === 0 || 
         (matchingItems.length === 1 && matchingItems[0].index === indexToCheck);
}
export const typeCast = (value: any, type: string) => {
  switch (type) {
    case "number":
    case "creditscore": {
      const val = value === "" ? "" : sanitizeNumericInput(value);
      if (val !== "") {
        return parseInt(sanitizeNumericInput(val));
      }
      return val;
    }
    case "ein": {
      const val = value === "" ? "" : sanitizeNumericInput(value, true);
      return val.replace(/(\d{2})(\d{7})/, "$1-$2");
    }
    case "ssn": {
      const val = value === "" ? "" : sanitizeNumericInput(value, true);
      return val.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
    }
    case "postalcode": {
      return value === ""
        ? ""
        : sanitizeNumericInput(value, true)?.toString()?.slice(0, 5);
    }
    case "abaroutingnumber": {
      return value === "" ? "" : sanitizeNumericInput(value, true);
    }
    case "phone": {
      const val = value === "" ? "" : sanitizePhoneInput(value);
      return val.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    }
    case "Number": {
      return value === "" ? "" : sanitizeNumber(value);
    }
    case "alphanumeric": {
      return value.replace(/[^a-z0-9]/gi, "");
    }
    case "currency":
    case "percentage": {
      return cleanDecimal(value);
    }
    case "currencyWithMinus": {
      return parseFloat(value) < 0
        ? `-${cleanDecimal(value)}`
        : cleanDecimal(value);
    }
    case "date": {
      const formatedVal = MMMDDYYYYDateFormat(value);
      return formatedVal;
    }
    case "dateOnly": {
      const formatedVal = MMMDDYYYYDateFormat(value);
      return formatedVal;
    }
    case "autoProperty": {
      return value;
    }
    default: {
      return value;
    }
  }
};

const isPostalCode = (value: string) => /^\d{5}$/gm.test(value);

const isStateSpaceZip = (value: string) =>
  new RegExp(`^\\b(${USStates.join("|")})\\b \\d{5}$`).test(value);

export const typeCastOnBlur = (value: any, type: string, callback?: any, fieldSet = [], indexToCheck?: number) => {
  switch (type) {
    case "currency": {
      let val = formatValueByType(value, type);
      if (val && typeof val === "string" && val !== "") {
        val = val.replace("$ ", "");
        val = val.replace("$", "");
      }
      return val === "" ? null : val;
    }
    case "percentage": {
      let val: any = parseFloat(value).toFixed(4);
      if (isNaN(val)) {
        return "";
      }
      return val;
    }
    case "Number":
      if (isEmptyValue(value)) {
        return value;
      }
      const formattedValue: any = value?.toString();
      if (isNaN(formattedValue)) {
        return "";
      }
      return formattedValue;
    case "number": {
      if (isEmptyValue(value)) {
        return value;
      }
      const formattedValue = Number(value);
      if (isNaN(formattedValue)) {
        return null;
      }
      return formattedValue;
    }
    case "creditscore": {
      if (value < 300 || value > 850) {
        if (value && callback) {
          callback();
        }
        return "";
      }
      if (isEmptyValue(value)) {
        return value;
      }
      const formattedValue: any = Number(value).toString();
      if (isNaN(formattedValue)) {
        return "";
      }
      return formattedValue;
    }
    case "ein": {
      if (!value) {
        return "";
      }
      if (value.length < 9) {
        callback?.();
        return "";
      }
      const valueToCheck = value.replace(/(\d{2})(\d{7})/, "$1-$2");
      if (!isValueUnique(fieldSet, valueToCheck, "documentInfo", 'eiNumber', indexToCheck as number)) {
        callback?.("EINs cannot be repeated for different borrowers");
        return "";
      }
      return valueToCheck;
    }
    case "email": {
      if (!value) {
        return "";
      }
      if (isValidEmailFormat(value)) {
        return value;
      }
      callback?.();
      return "";
    }
    case "ssn": {
      if (!value) {
        return "";
      }
      if (value.length < 9) {
        callback?.();
        return "";
      }
      const valueToCheck = value.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
      if (!isValueUnique(fieldSet, valueToCheck, "documentInfo", 'ssNumber', indexToCheck as number)) {
        callback?.("SSNs cannot be repeated for different borrowers / guarantors");
        return "";
      }
      return valueToCheck;
    }
    case "postalcode": {
      if (!value) {
        return "";
      }
      if (value.length < 5) {
        callback?.();
        return "";
      }
      return value;
    }
    case "abaroutingnumber": {
      if (!value) {
        return "";
      }
      if (value.length < 9) {
        callback?.();
        return "";
      }
      return sanitizeNumericInput(value, true);
    }
    case "phone": {
      if (!value) {
        return "";
      }
      if (value.length < 10) {
        callback?.();
        return "";
      }
      return value.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    }
    case "autoProperty": {
      return value;
    }
    case "billingaddress": {
      return getFormattedAddress(value, false, callback);
    }
    default: {
      let val = typeCast(value, type);
      if (typeof val === "string") {
        val = val.trim();
      }
      return val;
    }
  }
};

export const getFormattedAddress = (
  value: string,
  getInputBack: boolean,
  callback?: Function
) => {
  let splitUp = value.split(", ");
  if (
    splitUp.length >= 4 &&
    isPostalCode(splitUp[splitUp.length - 1]) &&
    USStates.includes(splitUp[splitUp.length - 2])
  ) {
    return value;
  }

  if (splitUp.length >= 3 && isStateSpaceZip(splitUp[splitUp.length - 1])) {
    splitUp = [
      ...splitUp.slice(0, splitUp.length - 1),
      ...splitUp[splitUp.length - 1].split(" ")
    ];
    return splitUp.join(", ");
  }

  if (value && callback) {
    callback(
      "Address should have street address, city, valid US state and zip codes delimited by commas."
    );
  }
  return getInputBack ? value : "";
};

export const typeCastForReadOnly = (value: any, type: string) => {
  if (type.includes("currency")) {
    let val = value;
    if (val) {
      val = typeCast(value.toString(), type);
      val = typeCastOnBlur(val, type);
    }
    val = formatValueByType(val, type);
    if (val && typeof val === "string" && val !== "") {
      val = val.replace("$ ", "");
      val = val.replace("$", "");
    }
    return val;
  } else if (type === "date" || type === "dateOnly") {
    const formatedVal = MMMDDYYYYDateFormat(value);
    return formatedVal;
  } else if (value === true) {
    if (type === "yesnodropdown") {
      return "Yes";
    }
    return "Y";
  } else if (value === false) {
    if (type === "yesnodropdown") {
      return "No";
    }
    return "N";
  } else if (type === "percentage") {
    let val = value;
    if (val) {
      val = typeCast(value.toString(), type);
      val = typeCastOnBlur(val, type);
    }
    let formatedVal = formatValueByType(val, "percentageWithout100x");
    if (formatedVal?.[formatedVal?.length - 1] === "%") {
      formatedVal = formatedVal.slice(0, -1);
    }
    return formatedVal;
  } else {
    return value;
  }
};

export const sortByOrder = (obj: any, id: string, order: string) => {
  const newObj = JSON.parse(JSON.stringify(obj));
  return newObj.sort((a: any, b: any) => {
    if (!isEmptyValue(a?.[order]) && !isEmptyValue(b?.[order])) {
      return parseInt(a[order]) - parseInt(b[order]);
    }
    return parseInt(a?.[id]) - parseInt(b?.[id]);
  });
};
