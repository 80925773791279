import React from 'react'
import DownloadIcon from '../../../images/standaloneDocuments/download.png';
import { getDownloadUrl } from '../../services/file-utility.component';
import './bootstrap.min.css';

type Props = {
    listData: any
};


function ListCard<ListCardProps>({ listData }: Props) {
    async function download() {
        // pass the id of the file which json you want to read
        const data = await getDownloadUrl(listData.fileId);
        window.open(data.box_download_link,"_blank");    
    }
    return (
        <div className="widget-list">
            <div className="main-widget">
                <div className="widget-detail">
                    <div className="widget-header">
                        <h3 className="widget-title">{listData.name}</h3>
                        <span className="widget-updates-status">Updated Date: <span>{listData.updatedDt}</span></span>
                    </div>
                    <p>{listData.description}</p>
                </div>
                <div className="widget-download">
                    <button className="btn-download" onClick={download}><img className="img-btn" src={DownloadIcon} alt="" /> Download</button>
                </div>
            </div>
        </div>
    )

}

export default ListCard