import { LoanStage } from "@toorak/tc-common-fe-sdk";
import {
  savedSuccessMessage,
  saveFailedMessage,
  snackBarOpenAndClose,
  snackBarOpenAndCloseReconcileView
} from "../../create-loan/ttf-review/document-review/popup-messages/toastMessages";
import {
  addLoanAndUserLevelBaseDataInfo,
  addLoanLevelBaseDataInfo,
  addPropertyLevelBaseDataInfo,
  addPropertyLevelExtractedDataInfoInitialState,
  addUserLevelBaseDataInfo,
  addUserLevelDiligenceInfo,
  addUserLevelExtractedDataInfoInitialState,
  formatAddressFromRedux,
  prepareLoanFieldsForRedux,
  preparePropertyFieldsForRedux,
  setBundleData,
  sortByKey,
  updateAddress,
  updateLoanDetailsInStore,
  updatePropertyDetailsInStore,
  updateTTFDBCallFlag,
  userFieldsInfo
} from "../../create-loan/ttf-review/ttfHelperFunctions";
import {
  AutomaticTaggedDocumentType,
  TagsInternalType
} from "../../create-loan/ttf-review/types";
import {
  getCategories,
  getCategoryStatus,
  getExtractedFieldsPerLoan,
  getExtractionStatusPerDoc,
  getLoanInfoForTTF,
  getPropertyInfoForTTF,
  getTTFData,
  saveExtractionData,
  saveTTFData
} from "../../network/apiService";
import {
  hideLoader,
  showLoader
} from "../loaderAndException/loaderAndException.action";
import { TABLE_FUNDING } from "../../utils/constants";
import { getLoanType } from "../../config/config";
import { getLoanHistory } from "../../create-loan/create-loan.action";
import {
  getAutomaticTaggedDoc,
  setAddress,
  updateDocsSplit,
  updateReconciliationData,
  updateSaveSubmit,
  updateTtfData
} from "./DocumentReview.reducer";
import { setBundleLoanData } from "../SettlementAndPurchase/SettlementAndPurchase.reducer";
import { ObjectType } from "../drawRequest/drawRequest.interface";

// export const SET_DOC_SPLIT_INITIAL_DATA = "SET_DOC_SPLIT_INITIAL_DATA";
// export const SET_ADDRESS = "SET_ADDRESS";
// export const UPDATE_DOCS_SPLIT_PARAMS = "UPDATE_DOCS_SPLIT_PARAMS";
// export const SET_AUTO_TAGGED_DOCUMENTS = "SET_AUTO_TAGGED_DOCUMENTS";
// export const UPDATE_TTF_DATA = "UPDATE_TTF_DATA";
// export const UPDATE_LOAN_FIELDS = "UPDATE_LOAN_FIELDS";
// export const UPDATE_PROPERTY_FIELDS = "UPDATE_PROPERTY_FIELDS";
// export const UPDATE_TTF_FIELDS = "UPDATE_TTF_FIELDS";
// export const UPDATE_FIELDSET = "UPDATE_FIELDSET";
// export const UPDATE_SAVE_AND_SUBMIT_DATA = "UPDATE_SAVE_AND_SUBMIT_DATA";
// export const UPDATE_AUTO_TAGGED_DOCUMENTS = "UPDATE_AUTO_TAGGED_DOCUMENTS";
// export const ADD_TO_AUTO_TAGGED_DOCS = "ADD_TO_AUTO_TAGGED_DOCS";
// export const UPDATE_CATEGORY_STATUS = "UPDATE_CATEGORY_STATUS";
// export const UPDATE_RECONCILIATION_DATA = "UPDATE_RECONCILIATION_DATA";
// export const UPDATE_LOAN_FIELDS_RECONCILED_VIEW =
//   "UPDATE_LOAN_FIELDS_RECONCILED_VIEW";
// export const UPDATE_PROPERTY_FIELDS_RECONCILED_VIEW =
//   "UPDATE_PROPERTY_FIELDS_RECONCILED_VIEW";
// export const UPDATE_TOAST = "UPDATE_TOAST";
// export const SET_EXTRACTION_RESPONSE = "SET_EXTRACTION_STATUS";

// export const setInitialDocSplitData = (data: any) => {
//   return async (dispatch: any) => {
//     dispatch({
//       type: SET_DOC_SPLIT_INITIAL_DATA,
//       payload: data
//     });
//   };
// };

export const setLoanDetailsForDocReview = (
  propertyDetails: any,
  userFields: any
) => {
  const address = formatAddressFromRedux(propertyDetails);
  const borrowers = userFieldsInfo(userFields, "Borrower");
  const guarantors = userFieldsInfo(userFields, "Guarantor");
  // return async (dispatch: any) => {
  return setAddress({ address, borrowers, guarantors });
  // };
};

export const updateDocsSplitParams = (newObj: any) => {
  return async (dispatch: any) => {
    dispatch(updateDocsSplit(newObj));
    // dispatch({
    //   type: UPDATE_DOCS_SPLIT_PARAMS,
    //   payload: newObj
    // });
  };
};

// export const updateAutoTaggedDocuments = (newObj: any): any => {
//   return async (dispatch: any) => {
//     dispatch(updateAutoTaggedDoc(newObj));
//     // dispatch({
//     //   type: UPDATE_AUTO_TAGGED_DOCUMENTS,
//     //   payload: newObj
//     // });
//   };
// };
// export const addToAutoTaggedDocuments = (newObj: any): any => {
//   return async (dispatch: any) => {
//     dispatch(addToAutoTaggedDoc(newObj));
//     // dispatch({
//     //   type: ADD_TO_AUTO_TAGGED_DOCS,
//     //   payload: newObj
//     // });
//   };
// };

export const setExtractionResponse = (payload: any): any => {
  return async (dispatch: any) => {
    dispatch(setExtractionResponse(payload));
    // dispatch({
    //   type: SET_EXTRACTION_RESPONSE,
    //   payload
    // });
  };
};

export const getAutomaticTaggedDocuments = (
  data: any,
  fromTaggingScreen?: boolean
) => {
  const filteredDocuments = data.data.map(
    (document: AutomaticTaggedDocumentType) => {
      const tagSinternal = document?.TagsInternal ?? [];
      const fileType = document?.name.split(".").pop() || "";
      if (tagSinternal?.length === 1) return { ...document, fileType };
      // Give precendence to the MANUAL updated tag
      const manualTags = tagSinternal.filter(
        (tagInternal: TagsInternalType) => tagInternal?.tagType === "MANUAL"
      );
      return { ...document, TagsInternal: manualTags, fileType };
    }
  );
  // return async (dispatch: any) => {
  return getAutomaticTaggedDoc({
    ...data,
    data: filteredDocuments,
    fromTaggingScreen
  });
  // dispatch({
  //   type: SET_AUTO_TAGGED_DOCUMENTS,
  //   payload: { ...data, data: filteredDocuments, fromTaggingScreen }
  // });
  // };
};

export const setLoanLevelExtractedData = (
  loanFields: any,
  userFields: any,
  extractedInfo: any
) => {
  addLoanLevelBaseDataInfo(
    "extractedInfo",
    loanFields,
    extractedInfo?.loans,
    true
  );
  if (extractedInfo?.loanUserMap?.[0]?.loanUserMapId == null) {
    addUserLevelExtractedDataInfoInitialState(
      "extractedInfo",
      userFields,
      extractedInfo?.loanUserMap,
      true
    );
  } else {
    addUserLevelBaseDataInfo(
      "extractedInfo",
      userFields,
      extractedInfo?.loanUserMap,
      true
    );
  }
};

export const setPropertyLevelExtractedData = (
  propertyFields: any,
  extractedInfo: any
) => {
  if (extractedInfo?.properties?.[0]?.loanPropertyId == null) {
    addPropertyLevelExtractedDataInfoInitialState(
      "extractedInfo",
      propertyFields,
      extractedInfo?.properties,
      true
    );
  } else {
    addPropertyLevelBaseDataInfo(
      "extractedInfo",
      propertyFields,
      extractedInfo?.properties,
      true
    );
  }
};

export const setOtherLoanData = (
  loanFields: any,
  userFields: any,
  extractedInfo: any,
  savedDocumentInfo: any,
  submittedDocumentInfo: any
) => {
  if (!Array.isArray(extractedInfo)) {
    setLoanLevelExtractedData(loanFields, userFields, extractedInfo);
  }
  addLoanAndUserLevelBaseDataInfo(
    "savedDocumentInfo",
    loanFields,
    userFields,
    savedDocumentInfo
  );
  addLoanAndUserLevelBaseDataInfo(
    "submittedDocumentInfo",
    loanFields,
    userFields,
    submittedDocumentInfo
  );
};

export const setOtherPropertyData = (
  propertyFields: any,
  extractedInfo: any,
  savedDocumentInfo: any,
  submittedDocumentInfo: any
) => {
  if (!Array.isArray(extractedInfo)) {
    setPropertyLevelExtractedData(propertyFields, extractedInfo);
  }
  addPropertyLevelBaseDataInfo(
    "savedDocumentInfo",
    propertyFields,
    savedDocumentInfo?.properties
  );
  addPropertyLevelBaseDataInfo(
    "submittedDocumentInfo",
    propertyFields,
    submittedDocumentInfo?.properties
  );
};

export const getTapeToFileDataForDocReview = async (
  dispatch: any,
  documentReviewDispatch: any,
  loanId: string,
  loanStage: LoanStage,
  loanDetails: any,
  tagList: any[],
  leaseStatusDropdown: any,
  loanType: string,
  documentList: ObjectType[],
  editHistory: any
) => {
  try {
    const isDSCR = loanType.includes("DSCR");
    const reqPayload: any = {
      loanStage,
      loanType: isDSCR
        ? getLoanType[1].loanCreationName
        : getLoanType[0].loanCreationName,
      fundingType:
        loanDetails?.loanDetailId?.fundingType === TABLE_FUNDING
          ? TABLE_FUNDING
          : "normal_funding",
      loanProcessType: loanDetails?.loanProcessType,
      originatorId: loanDetails?.originatorInfo?.partyId,
      ttfVersionId: loanDetails?.loanDetailId?.loanConfigId?.ttfVersionId
    };
    if (isDSCR) {
      reqPayload.rateType = loanDetails?.loanEconomics?.rateType;
      reqPayload.firstPaymentDate =
        loanDetails?.loanInfo?.firstPaymentDateOfLoan;
      reqPayload.isDTRVPresent = documentList.some(
        (doc: ObjectType) => doc?.TagsInternal?.[0]?.tag?.code === "DTRV"
      );
    }
    // dispatch(showLoader());
    const ttfPreRequisiteApiCalls = [
      getLoanInfoForTTF(loanId, loanStage),
      getPropertyInfoForTTF(loanId, loanStage),
      getTTFData(loanId),
      getCategories(reqPayload),
      getCategoryStatus(reqPayload, loanId),
      getExtractedFieldsPerLoan(loanId),
      getExtractionStatusPerDoc(loanId)
    ];
    (Promise as any)
      .allSettled(ttfPreRequisiteApiCalls)
      .then(async (responseArr: any) => {
        const mustPassResponses = [...responseArr];
        mustPassResponses.splice(5, 2);
        dispatch(hideLoader());
        if (
          mustPassResponses.every(
            (response: any) => response.status === "fulfilled"
          )
        ) {
          loanDetails = updateLoanDetailsInStore(
            dispatch,
            responseArr?.[0]?.value?.data,
            loanId,
            loanStage,
            loanType
          );

          let { propertyDetails } = updatePropertyDetailsInStore(
            dispatch,
            responseArr?.[1]?.value?.data,
            loanId,
            loanStage,
            loanType
          );
          const savedDocumentInfo =
            responseArr?.[2]?.value?.data?.documentInfo || {};
          const submittedDocumentInfo =
            responseArr?.[2]?.value?.data?.submittedDocumentInfo || {};
          let categories = sortByKey(
            responseArr?.[3]?.value?.data?.categories,
            "order",
            ""
          );
          const extractedInfo =
            responseArr?.[5]?.status === "rejected"
              ? []
              : responseArr?.[5]?.value?.data?.extractedDocumentInfo;
          const extractionResponse =
            responseArr?.[6]?.status === "rejected"
              ? []
              : responseArr?.[6]?.value?.data;
          categories = categories.map((obj: any) => {
            obj.status = responseArr?.[4]?.value?.data?.[obj?.id];
            return obj;
          });
          const dataPoints = JSON.parse(
            JSON.stringify(responseArr?.[3]?.value?.data?.dataFields)
          );
          dataPoints.forEach((field: any) => {
            const categoriesNamesList: string[] = [];
            field?.categories?.forEach((category: any) => {
              const categoryObj = categories.find(
                (cat: any) => cat?.id === category
              );
              if (categoryObj) {
                categoriesNamesList.push(categoryObj?.name);
              }
            });
            field.categoriesNamesList = categoriesNamesList;
          });
          let reqDocs = dataPoints.map((dp: any) => dp.documents);
          reqDocs = Array.from(new Set(reqDocs.flat()));
          const { loanFields, userFields } = prepareLoanFieldsForRedux(
            loanDetails,
            tagList,
            isDSCR,
            savedDocumentInfo,
            dataPoints,
            "documentInfo",
            editHistory
          );
          const { propertyFields, escrowPropObj } =
            preparePropertyFieldsForRedux(
              propertyDetails,
              tagList,
              leaseStatusDropdown,
              loanDetails?.loanInfo?.toorakProduct,
              isDSCR,
              savedDocumentInfo,
              dataPoints,
              "documentInfo",
              editHistory
            );
          setOtherLoanData(
            loanFields,
            userFields,
            extractedInfo,
            savedDocumentInfo,
            submittedDocumentInfo
          );
          setOtherPropertyData(
            propertyFields,
            extractedInfo,
            savedDocumentInfo,
            submittedDocumentInfo
          );

          const escrowInfoObj = {
            loanSummary: loanDetails.loanDetailId.loanSummaryId,
            loanEscrowMap: loanDetails.loanEscrowMap
          };
          const address = updateAddress(propertyDetails);
          const borrowers = userFieldsInfo(userFields, "Borrower");
          const guarantors = userFieldsInfo(userFields, "Guarantor");
          dispatch(
            updateTtfData({
              loanFields,
              userFields,
              propertyFields,
              savedDocumentInfo,
              submittedDocumentInfo,
              extractedInfo,
              extractionResponse,
              categories,
              dataPoints,
              primaryLoanId: loanDetails?.loanInfo?.primaryLoanId,
              sellerName: loanDetails?.originatorInfo?.sellerName,
              address,
              borrowers,
              guarantors,
              reqDocs,
              escrowInfoObj,
              escrowPropObj
            })
          );
          // dispatch({
          //   type: UPDATE_TTF_DATA,
          //   payload: {
          //     loanFields,
          //     userFields,
          //     propertyFields,
          //     savedDocumentInfo,
          //     submittedDocumentInfo,
          //     extractedInfo,
          //     extractionResponse,
          //     categories,
          //     dataPoints,
          //     primaryLoanId: loanDetails?.loanInfo?.primaryLoanId,
          //     sellerName: loanDetails?.originatorInfo?.sellerName,
          //     address,
          //     borrowers,
          //     guarantors,
          //     reqDocs,
          //     escrowInfoObj,
          //     escrowPropObj
          //   }
          // });
          updateTTFDBCallFlag(documentReviewDispatch, {
            selectedCategories: categories.map((item: any) => item.name),
            isTTFDBDataAvailable: true
          });
        }
      })
      .catch(async (errors: any) => {
        dispatch(hideLoader());
        console.error(errors);
      });
  } catch (e) {
    console.error(e);
    dispatch(hideLoader());
  }
};

export const saveTapeToFileData = async (
  dispatch: any,
  saveObj: any,
  loanId: string,
  isJustSave: boolean,
  documentReviewDispatch: any
) => {
  try {
    let extractedInfo;
    if (saveObj?.extractedInfo) {
      extractedInfo = saveObj.extractedInfo;
      delete saveObj.extractedInfo;
    }
    if (isJustSave) {
      dispatch(showLoader());
      const response = await saveTTFData(saveObj, loanId);
      if (response) {
        dispatch(hideLoader());
        if (documentReviewDispatch) {
          snackBarOpenAndClose(documentReviewDispatch, savedSuccessMessage);
        } else {
          snackBarOpenAndCloseReconcileView(dispatch, savedSuccessMessage);
        }
      }
    } else {
      await saveTTFData(saveObj, loanId);
    }
    if (extractedInfo && !Array.isArray(extractedInfo)) {
      saveObj.extractedInfo = extractedInfo;
      await saveExtractionData(
        {
          extractedDocumentInfo: extractedInfo
        },
        loanId
      );
    }
    dispatch(updateSaveSubmit(saveObj));
    // dispatch({
    //   type: UPDATE_SAVE_AND_SUBMIT_DATA,
    //   payload: saveObj
    // });
  } catch (e) {
    console.error(e);
    dispatch(hideLoader());
    if (documentReviewDispatch) {
      snackBarOpenAndClose(documentReviewDispatch, saveFailedMessage);
    } else {
      snackBarOpenAndCloseReconcileView(dispatch, saveFailedMessage);
    }
  }
};

export const getTapeToFileDataForDataReconciliation = async (
  dispatch: any,
  loanId: string,
  loanStage: LoanStage,
  tagList: any[],
  leaseStatusDropdown: any,
  loanType: string,
  isRefreshed: boolean,
  editHistory: any
) => {
  try {
    const isDSCR = loanType.includes("DSCR");
    dispatch(showLoader(true));
    const reconciliationPreRequisiteApiCallsFirstSet = [
      getLoanInfoForTTF(loanId, loanStage),
      getPropertyInfoForTTF(loanId, loanStage)
    ];
    Promise.all(reconciliationPreRequisiteApiCallsFirstSet).then(
      async (responseArrOne: any) => {
        const loanDetails = updateLoanDetailsInStore(
          dispatch,
          responseArrOne?.[0]?.data,
          loanId,
          loanStage,
          loanType
        );
        const reconciliationPreRequisiteApiCallsSecondSet = [
          getTTFData(loanId),
          getCategories({
            loanStage,
            loanType: isDSCR
              ? getLoanType[1].loanCreationName
              : getLoanType[0].loanCreationName,
            fundingType:
              loanDetails?.loanDetailId?.fundingType === TABLE_FUNDING
                ? TABLE_FUNDING
                : "normal_funding",
            loanProcessType: loanDetails?.loanProcessType,
            originatorId: loanDetails?.originatorInfo?.partyId,
            ttfVersionId: loanDetails?.loanDetailId?.loanConfigId?.ttfVersionId
          })
        ];
        Promise.all(reconciliationPreRequisiteApiCallsSecondSet).then(
          async (responseArrTwo: any) => {
            dispatch(hideLoader());
            const { propertyDetails, formattedPropertyData } =
              updatePropertyDetailsInStore(
                dispatch,
                responseArrOne?.[1]?.data,
                loanId,
                loanStage,
                loanType
              );
            if (isRefreshed) {
              dispatch(getLoanHistory(`${loanId}_${loanStage}`));
              setBundleData(dispatch, loanId, loanStage, loanDetails, formattedPropertyData, loanType);
            }
            const savedReconciledInfo =
              responseArrTwo?.[0]?.data?.savedReconciledInfo || {};
            const submittedReconciledInfo =
              responseArrTwo?.[0]?.data?.submittedReconciledInfo || {};
            const submittedDocumentInfo =
              responseArrTwo?.[0]?.data?.submittedDocumentInfo || {};
            const diligenceInfo =
              responseArrTwo?.[0]?.data?.diligenceInfo || [];
            const originalLoanInfo =
              responseArrTwo?.[0]?.data?.originalLoanInfo || {};
            let categories = sortByKey(
              responseArrTwo?.[1]?.data?.categories,
              "order",
              ""
            );
            categories = categories.map((obj: any) => {
              obj.status = "";
              return obj;
            });
            const dataPoints = responseArrTwo?.[1]?.data?.dataFields;
            const { loanFields, userFields } = prepareLoanFieldsForRedux(
              loanDetails,
              tagList,
              isDSCR,
              savedReconciledInfo,
              dataPoints,
              "documentInfo",
              editHistory
            );
            const { propertyFields } = preparePropertyFieldsForRedux(
              propertyDetails,
              tagList,
              leaseStatusDropdown,
              loanDetails?.loanInfo?.toorakProduct,
              isDSCR,
              savedReconciledInfo,
              dataPoints,
              "documentInfo",
              editHistory
            );
            addLoanAndUserLevelBaseDataInfo(
              "ttfInfo",
              loanFields,
              userFields,
              submittedDocumentInfo
            );
            addPropertyLevelBaseDataInfo(
              "ttfInfo",
              propertyFields,
              submittedDocumentInfo?.properties
            );
            addLoanAndUserLevelBaseDataInfo(
              "originalLoanInfo",
              loanFields,
              userFields,
              originalLoanInfo,
              false,
              loanId
            );
            addPropertyLevelBaseDataInfo(
              "originalLoanInfo",
              propertyFields,
              originalLoanInfo?.properties
            );

            addLoanLevelBaseDataInfo(
              "diligenceInfo",
              loanFields,
              {...diligenceInfo?.loans, loanResults: diligenceInfo?.results },
              false,
              loanId
            );
            addUserLevelDiligenceInfo(
              "diligenceInfo",
              userFields,
              diligenceInfo?.loans?.borrowerInfo,
              diligenceInfo?.loans?.guarantorInfo
            );

            addPropertyLevelBaseDataInfo(
              "diligenceInfo",
              propertyFields,
              diligenceInfo?.properties
            );
            dispatch(
              updateReconciliationData({
                categories,
                dataPoints,
                loanFields,
                userFields,
                propertyFields,
                savedReconciledInfo,
                submittedReconciledInfo,
                submittedDocumentInfo,
                diligenceInfo,
                originalLoanInfo
              })
            );
            // dispatch({
            //   type: UPDATE_RECONCILIATION_DATA,
            //   payload: {
            //     categories,
            //     dataPoints,
            //     loanFields,
            //     userFields,
            //     propertyFields,
            //     savedReconciledInfo,
            //     submittedReconciledInfo,
            //     submittedDocumentInfo,
            //     diligenceInfo,
            //     originalLoanInfo
            //   }
            // });
            dispatch(hideLoader(true));
          }
        );
      }
    );
  } catch (e) {
    console.error(e);
    dispatch(hideLoader(true));
  }
};
