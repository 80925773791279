import React, { ChangeEvent, useMemo } from "react";
import { alpha } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import { TextFieldProps, InputAdornment, TextField } from "@mui/material";
import {
  formatValueByType,
  isEmptyValue,
  sanitizeValueByType
} from "../../../../utils/formatChecks";
// import MuiTextField from '@mui/material/TextField';
// import { styled } from '@mui/material/styles';

// const TextFieldS = styled(MuiTextField)(({ theme }) => ({
//   '& .MuiOutlinedInput-root': {
//     paddingLeft: 0,
//   },
//   '& .MuiInputAdornment-root': {
//     backgroundColor: theme.palette.divider,
//     padding: '28px 14px',
//     borderTopLeftRadius: theme.shape.borderRadius + 'px',
//     borderBottomLeftRadius: theme.shape.borderRadius + 'px',
//   },
// }));

const useStylesInput = makeStyles(theme => ({
  root: {
    "label + &": {
      marginTop: "5px"
    },
    border: "none",
    "& .Mui-focused.MuiInputLabel-root": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      "text-decoration-line": "none",
      border: "solid 1px var(--color-purple-dark-2)"
    }
  },
  input: {
    position: "relative",
    fontSize: 14,
    // padding: "13px 14px",
    alignSelf: "flex-end",
    fontWeight: 600,
    color: "#32325d",
    "&:disabled": {
      cursor: "not-allowed"
    }
  }
}));
const useStyles = makeStyles(_theme => ({
  borderTextArea: {
    width: "100%",
    border: "1px solid #cad1d7",
    transition: _theme.transitions.create(["box-shadow"]),
    backgroundColor: "#ffffff",
    // "&:focus": {
    //   boxShadow: `${fade(_theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   "text-decoration-line": "none",
    //   border: "solid 1px var(--color-purple-dark-2)"
    // },
    borderRadius: 4
  },
  adornedEnd: {
    "& .MuiInputAdornment-positionEnd": {
      marginLeft: "0px"
    }
  }
}));

export const NewTextFieldForm = (
  props: TextFieldProps & {
    valueTestId?: string;
    fieldType: string;
    inputStyle?: any;
    value?: any;
    viewtextStyles?: any;
    endIcon?: React.ReactNode;
    disableClear?: boolean; // if set true, if user clears the value, function for onblur will not be called.
  }
) => {
  const {
    style,
    inputStyle,
    valueTestId,
    value,
    fieldType = "string",
    onBlur,
    endIcon,
    disableClear,
    disabled,
    title
  } = props;
  const getStringValue = (val: any) => {
    if (!isEmptyValue(val)) {
      return val.toString();
    }
    return null;
  };
  const inputClasses = useStylesInput();
  const classes = useStyles();
  const [fieldBeignEdited, setFieldBeingEdited] = React.useState<boolean>(
    false
  );
  const [updatedValues, setUpdatedValues] = React.useState<any>(null);
  const formattedValue = useMemo(
    () => formatValueByType(getStringValue(value), fieldType),
    [value, fieldType]
  );
  const handleOnFocus = () => {
    setFieldBeingEdited(true);
    const val = sanitizeValueByType(value, fieldType);
    setUpdatedValues(val);
  };

  const handleOnBlur = (val: any) => {
    let returnVal = formatValueByType(getStringValue(val), fieldType);
    if (returnVal) {
      switch (fieldType) {
        case "percentage": {
          returnVal = returnVal.replace(/%/, "");
          break;
        }
        case "currency": {
          returnVal = returnVal.replace("$", "");
          break;
        }
        default:
      }
    }
    setFieldBeingEdited(false);
    if (disableClear && isEmptyValue(returnVal)) {
      return;
    }
    if (onBlur) onBlur(returnVal);
    // Call on blur here
  };
  const onChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const foo = sanitizeValueByType(event.target.value, fieldType);
    setUpdatedValues(foo);
  };
  return (
    <div className={classes.borderTextArea}>
      <TextField
        title={title}
        disabled={disabled}
        onFocus={handleOnFocus}
        id={valueTestId || "outlined-start-adornment"}
        className={classes.adornedEnd}
        InputProps={{
          endAdornment: endIcon ? (
            <InputAdornment position="end">{endIcon}</InputAdornment>
          ) : null,

          classes: inputClasses,
          style: { borderColor: "#cad1d7", ...inputStyle }
        }}
        value={fieldBeignEdited ? updatedValues : formattedValue}
        onChange={e => onChange(e)}
        onBlur={e => {
          handleOnBlur(updatedValues);
        }}
        style={{
          flex: 1,
          width: "100%",
          ...style
        }}
      />
    </div>
  );
};
