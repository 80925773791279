/* eslint-disable react/jsx-wrap-multilines */
import React from "react";
import { Theme, Button, Typography, Dialog, Grid, Hidden } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import AddToPhotosOutlinedIcon from "@mui/icons-material/AddToPhotosOutlined";
import AddIcon from "@mui/icons-material/Add";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import { PreSubmitStates } from "../../utils/constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionBarContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      borderRadius: 4,
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      backgroundColor: "#ffffff",
      paddingLeft: 24
    },
    container: {
      borderRadius: 4,
      display: "flex",
      flexDirection: "column",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      backgroundColor: "#ffffff",
      marginTop: theme.spacing(3)
    },
    actionsTypography: {
      textAlign: "left",
      fontSize: 14,
      fontWeight: 600,
      color: "#32325d",
      lineHeight: 1.57,
      padding: "0px 24px"
    },
    button: {
      marginRight: 10,
      marginTop: 18,
      marginBottom: 18,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginLeft: "10px"
      }
    },
    modalDeleteButtonConatainer: {
      marginTop: 50
    },
    modalDeleteButton: {
      marginRight: 12,
      color: "#ffffff",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      marginTop: 18,
      borderWidth: 0,
      "&:hover": {
        backgroundColor: "#5566d6 !important"
      }
    },
    modalCancelButton: {
      color: "#32325d",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      backgroundColor: "#ffffff",
      marginTop: 18,
      borderWidth: 1,
      borderColor: "#ececec"
    },
    deletePropertyWrapper: {
      padding: 20,
      width: 500,
      maxWidth: "100%"
    },
    deletePropertyContainer: {
      display: "flex",
      justifyContent: "space-between",
      flexGrow: 1,
      alignItems: "center"
    },
    deletePropertyDiv: {
      fontSize: 14,
      fontWeight: 600,
      color: "#32325d"
    },
    closeIcon: {
      width: 16,
      height: 16,
      cursor: "pointer"
    },
    deleteWarning: {
      fontSize: 12,
      lineHeight: 1.5,
      color: "#32325d",
      marginTop: 40
    }
  })
);

export interface PropertyDetailsActionBarProps {
  onDeleteClicked: (id: string) => void;
  onAddClicked: () => void;
  isDeleteable: boolean;
  onDuplicateClicked: (id: string) => void;
  activePropertyId: string;
  loanState: LoanStatusEnum;
  isEvaluationPanelVisible?: boolean;
}

export function PropertyDetailsActionBar(props: PropertyDetailsActionBarProps) {
  const {
    onAddClicked,
    onDeleteClicked,
    onDuplicateClicked,
    activePropertyId,
    isDeleteable,
    loanState,
    isEvaluationPanelVisible
  } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const isPreSubmitState: boolean = PreSubmitStates.includes(loanState);

  return (
    <div className={classes.container}>
      <Grid container alignItems="center">
        <Grid sm={2} md={4} lg={6}>
          <Typography variant="h6" className={classes.actionsTypography}>
            {/* @ts-ignore */}
            <Hidden smDown>Actions</Hidden>
          </Typography>
        </Grid>
        <Grid
          container
          xs={12}
          sm={10}
          md={8}
          lg={6}
          // spacing={1}
          justifyContent="flex-end"
          alignItems="flex-end"
          direction="row"
        >
          {/* <Grid
            container
            item
            xs={4}
            sm={4}
            md={3}
            lg={4}
            justify="flex-end"
            alignItems="flex-end"
          > */}
          <Button
            variant="contained"
            size="small"
            color="info"
            data-testid="add-property-button"
            className={classes.button}
            startIcon={<AddIcon />}
            disabled={isEvaluationPanelVisible}
            onClick={() => {
              onAddClicked();
            }}
          >
            Add Property
          </Button>
          {/* </Grid> */}
          {/* <Grid
            container
            item
            xs={4}
            sm={4}
            md={4}
            lg={4}
            justify="flex-end"
            alignItems="flex-end"
          > */}
          <Button
            variant="contained"
            size="small"
            color="info"
            data-testid="duplicate-property-button"
            className={classes.button}
            startIcon={<AddToPhotosOutlinedIcon />}
            disabled={isEvaluationPanelVisible}
            onClick={() => {
              onDuplicateClicked(activePropertyId);
            }}
          >
            Duplicate Property
          </Button>
          {/* </Grid> */}
          {/* <Grid item xs={4} sm={4} md={5} lg={4}> */}
          <Button
            variant="contained"
            size="small"
            color="info"
            data-testid="delete-property-button"
            className={classes.button}
            startIcon={<DeleteOutlineRoundedIcon />}
            disabled={isDeleteable}
            style={{ marginRight: 24 }}
            onClick={() => {
              setOpen(true);
            }}
          >
            Delete Property
          </Button>
          {/* </Grid> */}
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="delete-property-modal"
        aria-describedby="delete-property-modal-description"
      >
        <div className={classes.deletePropertyWrapper}>
          <div className={classes.deletePropertyContainer}>
            <div className={classes.deletePropertyDiv}>Delete Property</div>
            <CloseIcon
              htmlColor="#414169"
              className={classes.closeIcon}
              onClick={() => handleClose()}
            />
          </div>
          <div className={classes.deleteWarning}>
            {isPreSubmitState
              ? `Are you sure you want to delete this property?`
              : `Are you sure you want to delete this property? The evaluation tests will be run again and if there is a waiver request it would be deleted.`}
          </div>
          <div className={classes.modalDeleteButtonConatainer}>
            <Button
              variant="contained"
              color="info"
              data-testid="modal-delete-button"
              className={classes.modalDeleteButton}
              onClick={() => {
                onDeleteClicked(activePropertyId);
                handleClose();
              }}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              data-testid="modal-cancel-button"
              className={classes.modalCancelButton}
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
