/* eslint-disable import/no-default-export */
import { useCallback, useLayoutEffect } from "react";

export default function useLazyScroll(
  scrollRef: React.RefObject<HTMLDivElement>,
  onScroll: Function,
  additionalCondition: boolean
) {
  const handleScroll = useCallback(() => {
    if (!scrollRef.current) return;
    if (
      Math.ceil(
        scrollRef.current.clientHeight + scrollRef.current.scrollTop + 1
      ) >= scrollRef.current.scrollHeight &&
      scrollRef.current.scrollTop &&
      additionalCondition
    ) {
      onScroll();
    }
  }, [additionalCondition, onScroll, scrollRef]);

  useLayoutEffect(() => {
    if (!scrollRef.current) return;
    const scrollElem = scrollRef.current;
    scrollElem.addEventListener("scroll", handleScroll);
    // eslint-disable-next-line consistent-return
    return () => scrollElem?.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleScroll, scrollRef]);
}
