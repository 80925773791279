import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Button
} from "@mui/material";
import React from "react";

export const RadioButtonGroup = (props: any) => {
  const {
    radioList,
    selectedValue,
    onChange,
    classes,
    radioGroupTitle,
    showReset,
    onReset,
    resetButtonText,
    disableReset,
    itemColSize = null,
  } = props;

  return (
    <>
      <Grid className={classes.loanTypeLabel}>
        <span>{radioGroupTitle}</span>
      </Grid>
      <RadioGroup
        row
        aria-label={radioGroupTitle}
        name={radioGroupTitle}
        data-testid="radio-button"
        value={selectedValue}
        onChange={onChange}
        className={classes.formSelectContainer}
      >
        <Grid container className={classes.radioWrap}>
          {radioList.map((item: any) => {
            if (item.isHidden) return <></>;
            return (
              <Grid
                xs={itemColSize}
                item
                id={`${item.value}-radio-container`}
                className={item.customClass}
                key={item.value}
              >
                <FormControlLabel
                  id={`${item.value}-radio-buttons`}
                  value={item.value}
                  control={<Radio size="small" data-testid={item.value} />}
                  disabled={item.isDisabled}
                  label={
                    <Typography style={{ fontSize: 12, fontWeight: "normal" }}>
                      {item.label}
                    </Typography>
                  }
                />
              </Grid>
            );
          })}
          {showReset && (
            <Button
              className={classes.reset}
              variant="outlined"
              onClick={onReset}
              disabled={disableReset}
            >
              {resetButtonText}
            </Button>
          )}
        </Grid>
      </RadioGroup>
    </>
  );
};
