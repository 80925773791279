// export const LOAN_COUNTER_INCREMENT = "LOAN_COUNTER_INCREMENT";
// export const LOAN_COUNTER_DECRERMENT = "LOAN_COUNTER_DECRERMENT";
// export const CLEAR_LOADER = "CLEAR_LOADER";
// export const STORE_API_ERROR = "STORE_API_ERROR";
// export const CLEAR_ERROR = "CLEAR_ERROR";

import {
  clearError,
  loanCounterDecrement,
  loanCounterIncrement,
  storeApiError
} from "./loaderAndException.reducer";

/**
 * @param higherOrderLoader can be used in case we want 2 type of loaders in same component.
 * for example in excel upload flow normal loader should come inside the component where as
 * on submit there should be a loader that will come on top of the screen.
 */
export function showLoader(higherOrderLoader?: boolean, context?: string): any {
  return async (dispatch: any) => {
    // dispatch({
    //   type: LOAN_COUNTER_INCREMENT,
    //   payload: { higherOrderLoader, context }
    // });

    dispatch(loanCounterIncrement({ higherOrderLoader, context }));
  };
}

/**
 * @param higherOrderLoader can be used in case we want 2 type of loaders in same component.
 * for example in excel upload flow normal loader should come inside the component where as
 * on submit there should be a loader that will come on top of the screen.
 */
export function hideLoader(higherOrderLoader?: boolean, context?: string): any {
  // console.log("called hide loader", higherOrderLoader)
  return async (dispatch: any) => {
    // dispatch({
    //   type: LOAN_COUNTER_DECRERMENT,
    //   payload: { higherOrderLoader, context }
    // });
    dispatch(loanCounterDecrement({ higherOrderLoader, context }));
  };
}
/**
 * This is used to set loaderCount to 0
 */
// export function clearLoader(): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: CLEAR_LOADER
//     });
//   };
// }

export const storeAPIError: any = (errorConfigToSave: any) => {
  return async (dispatch: any) => {
    // dispatch({ type: STORE_API_ERROR, payload: { errorConfigToSave } });

    dispatch(storeApiError({ errorConfigToSave }));
  };
};

export const clearErrors: any = (data: {
  errorId: string;
  autoClose?: boolean;
  closeAll?: boolean;
}) => {
  return async (dispatch: any) => {
    // dispatch({ type: CLEAR_ERROR, payload: { ...data } });
    dispatch(clearError({ ...data }));
  };
};
