/* eslint-disable no-shadow */
/* eslint-disable import/no-default-export */
import { Card, Grid } from "@mui/material";
import React, { useState } from "react";
import { ObjectType } from "../masterView/common";
import { loanListStyles } from "./styles";
import { TKOPListView } from "./TKOPListView";
import { TKOPDDReviewListView } from "./TKOPDDReviewListView";
import { TKOPNonDDReviewListView } from "./TKOPNonDDReviewListView";

export interface LoanListProps {
  unAssignedLoans: Map<string, ObjectType>;
  activeServicerTab: string;
  ddReviewLoans: Map<string, ObjectType>;
  ddNonReviewLoans: Map<string, ObjectType>;
  originatorNames: ObjectType[];
}

// const views = [{ tabLabel: "Loan View" }, { tabLabel: "Bundle View" }];

export const TKOPListBlock = (props: LoanListProps) => {
  const {
    unAssignedLoans,
    activeServicerTab,
    ddReviewLoans,
    ddNonReviewLoans,
    originatorNames
  } = props;
  const [
    isNonDDreviewLoanAccordianStateSet,
    setNonDDreviewLoanExpanded
  ] = useState(true);
  const [isDDreviewLoanAccordianStateSet, setDDreviewLoanExpanded] = useState(
    false
  );
  const loanListclasses = loanListStyles();

  const setDDreviewLoanState = () => {
    setDDreviewLoanExpanded(!isDDreviewLoanAccordianStateSet);
  };
  const setNonDDreviewLoanState = () => {
    setNonDDreviewLoanExpanded(!isNonDDreviewLoanAccordianStateSet);
  };

  return (
    <div>
      {activeServicerTab === "UnassignedLoans" ? (
        <Card className={loanListclasses.loanListContainer}>
          <TKOPListView
            list={unAssignedLoans}
            className={loanListclasses.listWrapper}
          />
        </Card>
      ) : (
        <Card className={loanListclasses.loanListContainerNew}>
          <Grid container>
            <Grid item className={loanListclasses.listWrapper}>
              <TKOPNonDDReviewListView
                isNonDDreviewLoanAccordianStateSet={
                  isNonDDreviewLoanAccordianStateSet
                }
                setNonDDreviewLoanState={setNonDDreviewLoanState}
                list={ddNonReviewLoans}
                originators={originatorNames}
              />
            </Grid>
            <Grid item className={loanListclasses.listWrapper}>
              <TKOPDDReviewListView
                setDDreviewLoanState={setDDreviewLoanState}
                isDDreviewLoanAccordianStateSet={
                  isDDreviewLoanAccordianStateSet
                }
                list={ddReviewLoans}
              />
            </Grid>
          </Grid>
        </Card>
      )}
    </div>
  );
};
