import React, {
  useCallback,
  useContext,
  useEffect,
  // useMemo,
  useRef,
  useState
} from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

// import { useDispatch } from "react-redux";
import { ZeroStateLoan } from "../create-loan/loan-lists/DetailsView/ZeroStateLoan";
import { ObjectType } from "../masterView/common";
import LoanCard from "./LoanCard";
import { zeroStateStyles } from "./styles";
import { tkopStyles } from "./tkop.style";
import { useMultiSelection } from "./Utils";
import { TakeOutPartnerContext } from "./takeoutPartner";
import { ListHeader } from "./ListHeader";
import useLazyScroll from "../custom-hooks/useLazyScroll";

export interface LoanListHeaderProps {
  list: Map<string, ObjectType>;
  originators: ObjectType[];
  isNonDDreviewLoanAccordianStateSet: boolean;
  setNonDDreviewLoanState: Function;
}

export const TKOPNonDDReviewListView = (props: any) => {
  const {
    appliedFiltersData,
    fetchOnlyDDReviewNotInitaitedData,
    creditLines
  } = useContext(TakeOutPartnerContext);
  const {
    list,
    isNonDDreviewLoanAccordianStateSet,
    setNonDDreviewLoanState
  } = props;
  const classes = zeroStateStyles();
  const classesTKOP = tkopStyles();

  const [updatedLoansData, setUpdatedLoansData] = useState({});
  const {
    selectedItems,
    setSelectedItems,
    updateSelectedItems
  } = useMultiSelection({ key: "loanId" });

  useEffect(() => {
    setSelectedItems(new Map());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFiltersData]);

  const onBulkSuccessCallBack = (data: ObjectType) => {
    setUpdatedLoansData({ ...updatedLoansData, ...data });
  };

  const loanList: ObjectType[] = list;

  const scrollRefNonDD = useRef<HTMLDivElement>(null);
  const onScroll = useCallback(() => {
    if (loanList.length < 20) return;
    fetchOnlyDDReviewNotInitaitedData(
      "",
      loanList.length,
      appliedFiltersData,
      false
    );
  }, [loanList, appliedFiltersData, fetchOnlyDDReviewNotInitaitedData]);

  useLazyScroll(scrollRefNonDD, onScroll, true);

  return (
    <>
      <Accordion
        elevation={0}
        onChange={() =>
          setNonDDreviewLoanState(!isNonDDreviewLoanAccordianStateSet)
        }
        classes={{
          root: classesTKOP.accordion,
          expanded: classesTKOP.accordionExpanded
        }}
        expanded={isNonDDreviewLoanAccordianStateSet}
      >
        <AccordionSummary
          expandIcon={
            isNonDDreviewLoanAccordianStateSet ? (
              <RemoveIcon
                fontSize="small"
                className={classesTKOP.smallAccordionSvg}
                color="primary"
              />
            ) : (
              <AddIcon
                fontSize="small"
                className={classesTKOP.smallAccordionSvg}
                color="primary"
              />
            )
          }
          classes={{ root: classesTKOP.accordionSummaryList }}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <ListHeader
            partners={creditLines.map((item: any) => {
              return { value: item.id, label: item.shortName };
            })}
            onSelectAll={setSelectedItems}
            selectedItems={selectedItems}
            itemsList={loanList}
            isDropDownNeeded
            listHeaderText={`DD Review Not Initiated Loans (${loanList.length})`}
            onSuccessCallBack={onBulkSuccessCallBack}
            idKey="loanId"
          />
        </AccordionSummary>

        <AccordionDetails
          classes={{
            root: classesTKOP.accordionDetails
          }}
        >
          <Grid
            style={{ maxHeight: "40vh", overflowY: "auto" }}
            ref={scrollRefNonDD}
          >
            {loanList.map((loan: ObjectType) => {
              return (
                <LoanCard
                  key={loan.loanId}
                  isSelected={selectedItems.has(loan.loanId)}
                  loanDetails={loan}
                  isDropDownNeeded
                  updateSelectedLoans={updateSelectedItems}
                />
              );
            })}
            {loanList.length === 0 && (
              <ZeroStateLoan
                textToShow="There are no loans available!"
                secondTextToShow=""
                customClasses={classes}
              />
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
