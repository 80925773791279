/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconButton } from "@mui/material";
import { push } from "connected-react-router";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getConfig } from "../config/config";
import { updateLastReadComment } from "../stores/commentsSection/comment.action";
import {
  getCommunicationData,
  updateReadStatus
} from "../stores/ExceptionsResults/ExceptionActions";
import { getLoanDetails } from "../stores/ILP/ILP.action";
import { getCookie } from "../utils/cookies";
import { MoveRightIcon, RedirectIcon } from "./Icons";
import { IconButtonDropdownStyles } from "./waiver-form/DocumentsForm.style";
import { RootState } from "../stores/rootReducer";

export interface NewTabProps {
  path: string;
  openInNewTab?: boolean;
  secondaryParams?: any;
  style?: any;
  attachedLoanArr?: any;
  loanTag?: any;
  overridenClasses?: any;
  completeButtonStyle?: any;
  isVisibleToOrig?: boolean;
}
export const OpenInNewTab = (props: NewTabProps) => {
  const {
    path,
    openInNewTab,
    secondaryParams,
    attachedLoanArr,
    style,
    overridenClasses,
    isVisibleToOrig,
    completeButtonStyle
  } = props;
  const navigate = useNavigate();
  const { takeoutPartnerId } = useSelector<RootState, any>(
    state =>
      state.createLoanStore?.loanDetails.thirtyYearLoanInformation.payload
  );
  const dispatch = useDispatch();
  const iconButtonOverrideClasses =
    overridenClasses || IconButtonDropdownStyles();

  const handlenewTabClick = () => {
    if (openInNewTab === false) {
      navigate(path);
    } else if (secondaryParams?.chatId && secondaryParams?.chatDetails) {
      const { chatId, chatDetails } = secondaryParams;
      updateLastReadComment(chatId, getCookie("person_id"));
      dispatch(
        getLoanDetails(
          chatDetails.secondaryIdentifierValue,
          chatDetails.identifierValue,
          viewDetailsCallBack
        )
      );
    } else {
      localStorage.setItem("attachedLoanArr", JSON.stringify(attachedLoanArr));
      let url = `${getConfig().redirectUrl}${path}`;
      if (
        takeoutPartnerId &&
        !["null", "undefined"].includes(takeoutPartnerId)
      ) {
        url = `${getConfig().redirectUrl}${path}?pId=${takeoutPartnerId}`;
      }
      window.open(url);
      if (secondaryParams?.exceptionId && secondaryParams?.personPartyId) {
        const { exceptionId, resourceId, personPartyId } = secondaryParams;
        if (resourceId) {
          dispatch(updateReadStatus(exceptionId, resourceId, personPartyId));
        }
      }
    }
  };

  const viewDetailsCallBack = (
    loanId: any,
    inquiryId: any,
    loanDetails: any
  ) => {
    dispatch(getCommunicationData(getCookie("person_id"), "internal"));
    const urlPath = `internal/loan/create/${loanDetails.loanType
      .split(" ")
      .join("")}/${
      loanDetails.stage
    }/${loanId}/inquiry/${inquiryId}/view?from=communication`;
    window.open(`${getConfig().redirectUrl}${urlPath}`);
  };

  return isVisibleToOrig ? (
    <div
      aria-label="redirect-link"
      onClick={handlenewTabClick}
      style={completeButtonStyle}
    >
      <MoveRightIcon height={18} width={18} />
    </div>
  ) : (
    <IconButton
      aria-label="redirect-link"
      component="button"
      size="large"
      classes={iconButtonOverrideClasses}
      onClick={handlenewTabClick}
      style={
        openInNewTab === false
          ? { backgroundColor: "none" }
          : style || {
              backgroundColor: "#3d55df",
              boxShadow: "rgb(0 0 0 / 22%) 0px 1px 3px 0px"
            }
      }
    >
      {openInNewTab === false ? <MoveRightIcon /> : <RedirectIcon />}
    </IconButton>
  );
};
