/* eslint-disable */

import { Theme, Tooltip } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";

export const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 12
  }
}))(Tooltip);

export const useAutoCorrectDropdownStyles = makeStyles((theme: Theme) => ({
  root: {
    "&.MuiCheckbox-root": {
      color: "rgb(122, 138, 160)",
      paddingLeft: 0,
      paddingRight: 32
    }
  },
  colorSecondary: {
    "&.MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#5e72e4 !important"
    }
  }
}));

export const useAutoCorrectStyles = makeStyles((theme: Theme) => ({
  root: {
    "&.MuiCheckbox-root": {
      color: "rgb(122, 138, 160)",
      paddingLeft: 0,
      paddingRight: 32
    },
    "&.Mui-focused input": {
      boxShadow: "0 0 0 0 lightgrey !important"
    },
    colorSecondary: {
      "&.MuiCheckbox-colorSecondary.Mui-checked": {
        color: "#5e72e4 !important"
      }
    }
  },

  inputRoot: {
    '&.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
      {
        marginTop: "2px !important",
        marginBottom: "2px !important",
        //   paddingTop:"4px !important",
        paddingLeft: "2px !important",
        color: "#32325d !important",
        border: "0px transparent !important"
      },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #5e72e4"
    }
  },
  paper: {
    "&.MuiAutocomplete-paper": {
      borderRadius: 4,
      boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: "#ffffff"
    }
  },
  endAdornment: {
    "&.MuiAutocomplete-endAdornment": {
      marginTop: 4
    }
  },

  option: {
    "&.MuiAutocomplete-option": {
      fontSize: "12px",
      fontWeight: "normal",

      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 2,
      paddingTop: "0px !important",
      paddingLeft: "14px !important",
      paddingBottom: "0px !important",
      height: 24,
      marginTop: 6,
      marginBottom: 6,
      letterSpacing: "normal",
      color: " #32325d",
      "& .MuiSvgIcon-root": {
        height: "16px !important",
        width: "30px !important",
        paddingLeft: 14
      }
    },
    '&.MuiAutocomplete-option[data-focus="true"]': {
      fontWeight: 600,
      backgroundColor: "#f6f9fc !important"
    },
    '&.MuiAutocomplete-option[aria-selected="true"]': {
      backgroundColor: " #ffffff"
    }
  }
}));

export const docFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    tagListBox: {
      //   marginRight: "132px",
      minHeight: "841px",
      borderRadius: "4px",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      backgroundColor: "#ffffff"
    },
    m_tb: {
      marginTop: "24px",
      marginBottom: "98px"
    },
    componentCointainer: {
      display: "flex",
      flexDirection: "row",
      backgroundColor: "#f8f9ff"
    },
    browseIcon: {
      width: "15px",
      height: "15px",
      marginRight: "7px"
    },
    browseButtton: {
      width: "124px",
      height: "36px",
      borderRadius: "4px",
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      border: "solid 1px #ececec",
      backgroundColor: "#ffffff",
      textTransform: "none",
      fontSize: "12px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.83,
      letterSpacing: "normal",
      color: " #32325d"
    },
    documentsContainer: {
      margin: "21px 0px 14px 0px"
    },
    documentHeader: {
      height: "22px",
      fontSize: "14px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.57",
      letterSpacing: "normal",
      color: "#32325d",
      width: "78px",
      padding: "24px 24px"
    },
    infoSymbol: {
      width: "15px",
      height: "13px",
      marginTop: "2px"
    },
    mt_3: {
      marginTop: "3px"
    },
    infoMargin: {
      margin: "0px 3px"
    },

    c_pointer: {
      cursor: "pointer"
    },
    infoHead: {
      width: "27px",
      height: "22px",
      marginLeft: "6px",
      fontSize: "14px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.57,
      letterSpacing: "normal",
      color: "#32325d",
      fontWeight: 600
    },
    p10: {
      padding: "10px"
    },
    hrTagList: {
      height: "1px",
      border: "solid 1px #f2f2f2"
    },
    infoBox: {
      height: "85px",
      borderRadius: "4px",
      backgroundColor: "#e9fafd",
      margin: "12px 15px 0px 15px"
    },
    h_28: {
      height: "28px"
    },
    rowFlex: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start"
    },
    m10: {
      margin: "10px"
    },
    fw_wrap: {
      flexWrap: "wrap"
    },
    docsListBox: {
      // width: 308px;
      borderRadius: "1px",
      border: "solid 1px #e1e8ee",
      backgrounColor: "#fefefe",
      marginTop: "12px"
    },
    bg_red: {
      backgroundColor: "#f75676 !important"
    },
    infoClose: {
      marginLeft: "auto",
      width: "15px",
      height: "15px"
    },
    infoMsg: {
      height: "34px",
      fontSize: "13px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#32325d",
      marginLeft: "10px"
    },
    preCloseDocs: {
      //   width: "157px",
      height: "18px",
      fontSize: "12px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.5,
      letterSpacing: "normal",
      color: "#32325d",
      textAlign: "center"
    },
    tickBlueChange: {
      filter:
        "invert(84%) sepia(35%) saturate(5603%) hue-rotate(145deg) brightness(94%) contrast(100%)"
    },
    docsListContainer: {
      margin: "15px 24px"
      //   width: "302px"
    },
    docsNameList: {
      padding: "12px 18px"
    },
    hrStyle: {
      borderColor: "#e1e8ee",
      marginBottom: "0px",
      marginTop: "0px"
    },
    tagDocsList: {
      // width: "308px",
      // height: "595px",
      // opacity: 0.14,
      backgroundColor: "#f6f9fc"
    },
    taglistBorder: {
      borderTop: "solid 1px #e1e8ee"
    },
    docsList: {
      //   width: "244px",
      height: "28px",
      fontSize: "13px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 2.33,
      letterSpacing: "normal",
      color: "#32325d"
      //   font-size: 12px;
    },
    d_none: {
      display: "none"
    },
    docsListIcon: {
      //   border: "solid 2px #bbbbbb"
      paddingTop: "7px",
      margin: "0px 14px 0px 3px"
    },
    tagListInfoIcon: {
      marginTop: "2px",
      paddingTop: "6px",
      height: "22px !important",
      width: "16px !important"
    },
    tickGreyChange: {
      filter:
        "invert(100%) sepia(0%) saturate(2%) hue-rotate(197deg) brightness(101%) contrast(101%)"
    },
    ml_4: { marginLeft: "4px" },
    ml_auto: {
      marginLeft: "auto"
    },
    mr_6: {
      marginRight: "6px"
    },
    mr_10: {
      marginRight: "10px"
    },
    leftBoxesMargin: {
      margin: "24px 24px 0px 0px"
    },
    browseBox: {
      height: "239px",
      borderRadius: "4px",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      border: "solid 1px #deebf3",
      backgroundColor: "#ffffff",
      width: "inherit"

      //   marginRight: "",
      //   marginLeft: "8%"
      //   width: "790px",
    },
    browseContainer: {
      //   width: "770px",
      height: "152px",
      borderRadius: "6px",
      border: "dotted 2px #8897aa",
      backgroundColor: "#ffffff",
      margin: "10px",
      //   textAlign: "center",
      display: "flex",
      //   alignItems: "center",
      justifyContent: "center"
    },
    browseButton: {
      width: "124px",
      height: "36px",
      borderRadius: "4px",
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      border: "solid 1px #ececec",
      backgroundColor: "#ffffff",
      textTransform: "none",
      fontSize: "12px",
      fontHeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.83,
      letterSpacing: "normal",
      color: " #32325d"
    },
    fwss_normal: {
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal"
    },
    BrowseInfo: {
      lineHeight: 1.25,
      letterSpacing: "0.09px",
      color: "#7a8aa0",
      height: "16px",
      fontSize: "12px"
    },
    BrowseText: {
      //   width: "390px",
      height: "66px",
      fontSize: "14px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.07,
      letterSpacing: "0.1px",
      textAlign: "center",
      color: "#7a8aa0",
      marginTop: "20px"
    },
    Browse: {
      width: "67px",
      height: "22px",
      fontSize: "12px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.83,
      letterSpacing: "normal",
      color: "#32325d"
    },
    iconShape: {
      width: "22px",
      height: "26px",
      //   backgroundColor: "#02669f",
      objectFit: "contain"
    },
    // ic-outline-file-copy {
    //     width: 22px;
    //     height: 28px;
    //   }
    w_100: { width: "100%" },
    w_95: { width: "95%" },
    uploadDocsText: { margin: "24px 10px 24px 36px" },

    uploadDocs: {
      //   width: "186px",
      height: "22px",
      //   fontFamily: "NotoSansJP",
      fontSize: "18px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#022d45"
    },
    uploadDocsCount: {
      marginTop: "24px",
      width: "16px",
      height: "16px",
      fontFamily: "NotoSansJP",
      fontSize: "12px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.5,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#ffffff",
      background: "blue",
      borderRadius: "50%"
    },
    progressBarBox: {
      // width: 1192px;
      display: "flex",
      justifyContent: "center",
      minHeight: "1164px",
      borderRadius: "16px",
      boxShadow: "0 2px 4px 0 #d5e1e9",
      backgroundColor: "#f5f6f9"
    },
    Group10: {
      marginTop: "250px",
      marginBottom: "10px",
      width: "430px",
      height: "256px",
      objectFit: "contain"
    },

    progressCard: {
      margin: "14px 0px 0px 0px",
      minHeight: "164px",
      borderRadius: "4px",
      boxShadow: "0 6px 9px 0 rgba(144, 164, 183, 0.22)",
      backgroundColor: " #ffffff"
    },
    progressContainer: {
      padding: "10px",
      borderRadius: "4px",
      backgroundColor: "#f7fafc"
    },
    errorCountIcon: {
      marginLeft: "6px",
      marginTop: "3px"
    },
    mt_10: {
      marginTop: "10px"
    },
    fileName: {
      height: "18px",
      fontSize: "12px",
      lineHeight: 1.5,
      letterSpacing: "normal",
      color: "#454b60"
    },
    initProgress: {
      height: "8px",
      backgroundColor: "#e9ecef",
      //   opacity: 0.39,
      borderRadius: "4px",
      boxShadow: "inset 0 1px 3px 0 rgba(92, 85, 113, 0.5)",
      margin: "5px 0 5px 0"
    },
    fileListBox: {
      minHeight: "581px",
      borderRadius: "4px",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      backgroundColor: "#ffffff",
      width: "inherit"
    },
    activeProgress: {
      marginTop: "9px",

      height: "4px",
      backgroundColor: "#009e55;"
    },
    fss_normal: {
      fontStretch: "normal",
      fontStyle: "normal"
    },
    addTagText: {
      width: "279px",
      height: "14px",
      fontSize: "12px",
      marginTop: "9px",
      marginBottom: "15px",
      fontWeight: 600,
      lineHeight: 1.5,
      letterSpacing: "normal",
      color: "#8897aa"
    },
    tagRequired: {
      fontSize: "12px",
      color: " #f75676",
      lineHeight: "normal"
    },
    mt_0: {
      marginTop: "0px !important"
    },
    formControl: {
      marginTop: "8px",

      width: "279px",
      height: "40px",
      borderRadius: "3px",
      border: "solid 1px #8c939b"
    },
    tagBadge: {
      //   minWidth: "135px",
      margin: "2px 2px",
      padding: "2px",
      backgroundColor: "#11ceef",
      height: "21px",
      borderRadius: "6px"
    },
    errorBadge: {
      backgroundColor: "#f75676",
      marginLeft: "13px"
    },
    mt_20: {
      marginTop: "20px"
    },
    tagText: {
      //   width: "120px",
      margin: "3px 6px 3px 7px",
      height: "14px",
      fontSize: "10px",
      fontWeight: "bold",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#ffffff"
    },
    mr_4: {
      marginRight: "4px"
      //   backgroundColor: "#041d3e"
    },
    retrytext: {
      //   width: "39px",
      height: "22px",
      fontFamily: "NotoSansJP",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.57,
      letterSpacing: "0.53px",
      color: "#0067a4"
    },
    ErrorMsg: {
      color: "#f75676"
    },
    QueueMsg: {
      color: "#fb633f"
    },
    SuccessMsg: {
      color: "#2ece89"
    },

    Msg: {
      //   width: "324px",
      height: "14px",
      fontSize: "12px",
      lineHeight: 1.5,
      letterSpacing: "normal",
      marginTop: "3px"
    },
    uploadDocsHead: {
      height: "22px",
      fontSize: "14px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.57,
      letterSpacing: "normal",
      color: "#32325d"
    },
    uploadDocsHeadMargin: {
      marginTop: "22px",
      marginLeft: "24px"
    },
    uploadDocsHeadPadding: {
      paddingTop: "22px",
      paddingLeft: "24px"
    },
    h_44: {
      height: "44px !important"
    },
    noDocs: {
      height: "26px",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 26,
      letterSpacing: "0.31px",
      textAlign: "center",
      color: "#32325d"
    },
    uploadOutcome: {
      marginRight: "17px",
      width: "94px",
      height: "41px"
    },
    arrow: {
      border: "solid black",
      borderWidth: "0 3px 3px 0",
      display: "inline-block",
      padding: "3px"
    },
    up: {
      transform: "rotate(-135deg)"
    },
    down: {
      transform: "rotate(45deg)"
    },
    wh_16: {
      width: "16px !important",
      height: "16px !important",
      margin: "3px"
    },
    dialogActionArea: {
      display: "block",
      paddingLeft: "20px",
      paddingBottom: "15px"
    },
    dialogDeleteButton: {
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: 1.83,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#ffffff",
      // width: "89px",
      textTransform: "none",
      height: "36px",
      borderRadius: "4px",
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      "&:hover": {
        backgroundColor: "#5566d6 !important"
      }
    },
    dialogCancelBtn: {
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      backgroundColor: "#ffffff",
      border: "solid 1px #ececec",
      color: "#32325d",
      "&:hover": {
        backgroundColor: "#ffffff !important"
      }
    },

    dialogText: {
      height: "18px",
      marginTop: "16px",
      marginBottom: "40px",
      fontSize: "12px",
      lineHeight: 1.5,
      letterSpacing: "normal"
    },
    docsErrorMsg: {
      height: 14,
      fontSize: 12,
      lineHeight: 1.5,
      color: "#f75676"
    },
    fileTime: {
      fontSize: 10,
      lineHeight: 1.6,
      letterSpacing: "normal",
      color: "#8897aa",
      height: 14,
      marginTop: -7,
      paddingLeft: 23
    },
    dialogHead: {
      height: "18px",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: 1.29,
      letterSpacing: "normal",
      color: "#32325d"
    }
  })
);
export const IconButtonDropdownStylesWithoutStyle = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: "0px 3px",
      "&.MuiIconButton-root": {
        "&:hover": {
          background: "none"
        },
        "&.Mui-disabled": {
          cursor: "no-drop",
          pointerEvents: "auto"
        }
      }
    }
  })
);

export const IconButtonDropdownStyles = makeStyles((theme: Theme) => ({
  root: {
    "&.MuiIconButton-root": {
      padding: 7,
      backgroundColor: "#5f72e4 !important",
      borderRadius: "4px",
      "&:hover": {
        boxShadow:
          "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
        background: "#3d55df !important"
      },
      "&.Mui-disabled": {
        cursor: "no-drop",
        pointerEvents: "auto"
      }
    }
  },
  viewDetailsbutton: {
    color: "#fff",
    fontSize: "0.8125rem",
    boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24);",
    borderRadius: "4px",
    backgroundColor: "#5e72e4 !important",
    padding: "4px 8px",
    marginLeft: "10px",
    marginTop: "5px",
    "&:hover": {
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      backgroundColor: "#3d55df !important",
      color: "#fff"
    }
  }
}));

export const ConditionsIconStyles = makeStyles((theme: Theme) => ({
  root: {
    "&.MuiIconButton-root": {
      padding: "7px",
      backgroundColor: "white !important",
      borderRadius: "4px",
      "&:hover": {
        boxShadow:
          "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
        background: "#white !important"
      },
      "&.Mui-disabled": {
        cursor: "no-drop",
        pointerEvents: "auto"
      }
    }
  }
}));

export const ConditionsIconDisabledStyles = makeStyles((theme: Theme) => ({
  root: {
    "&.MuiIconButton-root": {
      padding: "7px",
      backgroundColor: "white",
      borderRadius: "4px",
      "&:hover": {
        boxShadow:
          "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
        cursor: "no-drop"
      }
    }
  }
}));

export const IconButtonStylesSubmitAction = makeStyles((theme: Theme) => ({
  root: {
    "&.MuiIconButton-root": {
      padding: "8.5px",
      backgroundColor: "#5f72e4",
      borderLeft: "1px solid #1f2c86",
      borderRadius: "0px 4px 4px 0px",
      boxShadow:
        "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
      "&:hover": {
        boxShadow:
          "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
        background: "#3d55df"
      },
      "&.Mui-disabled": {
        cursor: "no-drop",
        pointerEvents: "auto"
      }
    }
  }
}));

export const IconButtonDropdownStylesSubmit = makeStyles((theme: Theme) => ({
  root: {
    "&.MuiButtonBase-root": {
      borderRadius: "4px 0px 0px 4px"
    },
    "&..MuiGrid-item": {
      paddingRight: "0px !important"
    }
  }
}));

export const IconButtonDropdownStylesWhite = makeStyles((theme: Theme) => ({
  root: {
    "&.MuiIconButton-root": {
      padding: "7px",
      backgroundColor: "rgb(255, 255, 255)",
      borderRadius: "4px",
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      "&:hover": {
        boxShadow:
          "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
        backgroundColor: "rgb(255, 255, 255)"
      },
      "&.Mui-disabled": {
        cursor: "no-drop",
        pointerEvents: "auto"
      }
    }
  }
}));

export const IconButtonStylesSubmitDisable = makeStyles((theme: Theme) => ({
  root: {
    "&.MuiIconButton-root": {
      padding: "8.5px",
      backgroundColor: "#afb9f2",
      borderLeft: "1px solid #1f2c86",
      borderRadius: "0px 4px 4px 0px",
      // boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
      "&:hover": {
        boxShadow:
          "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
        background: "#afb9f2",
        cursor: "no-drop"
      }
    }
  }
}));

export const IconDisabledButtonDropdownStyles = makeStyles((theme: Theme) => ({
  root: {
    "&.MuiIconButton-root": {
      padding: "7px",
      backgroundColor: "#5f72e4",
      borderRadius: "4px",
      "&:hover": {
        boxShadow:
          "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
        background: "#3d55df",
        cursor: "no-drop"
      }
    }
  }
}));

export const IconButtonDropdownStylesWithoutBG = makeStyles((theme: Theme) => ({
  root: {
    "&.MuiIconButton-root": {
      padding: "7px",
      cursor: "default",
      "&:hover": {
        background: "none"
      },
      "&.Mui-disabled": {
        cursor: "no-drop",
        pointerEvents: "auto"
      }
    }
  }
}));

export const IconButtonWhite = makeStyles((theme: Theme) => ({
  root: {
    "&.MuiIconButton-root": {
      padding: "2px",
      backgroundColor: "rgb(255, 255, 255)",
      borderRadius: "4px",
      boxShadow: "0px 0px 3px 0px rgb(0 0 0 / 20%)",
      "&:hover": {
        boxShadow:
          "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
        backgroundColor: "rgb(255, 255, 255)"
      },
      "&.Mui-disabled": {
        cursor: "no-drop",
        pointerEvents: "auto"
      }
    }
  }
}));
