import React from "react";
import CounselApp from "@toorak/tc-counsel-web-app";
import { Header } from "../create-loan/home/Header";

function CounselWrapper() {
  return (
    <div>
      <Header
        value={0}
        setTabValue={0}
        showTab={false}
        showNotificationIcon={true}
      />
      <div style={{ paddingTop: "80px" }}>
        <CounselApp />
      </div>
    </div>
  );
}

export default CounselWrapper;
