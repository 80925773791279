export const logicTests = [
  "agedCreditReport",
  "agedPropertyEvaluaution",
  "appraisalDateTest",
  "businessPurposeOccupancy",
  "dscrSubType",
  "guarantorsOwnershipTest",
  "leverageTest",
  "loanOriginationDate",
  "ltvLtcLimit",
  "maxSquareFt",
  "minAllowedInterestRate",
  "minDscrLoanAmount",
  "minSquareFt",
  "minToorakPrice",
  "portFolioLoanTest",
  "recourseTest",
  "sectionEligibility",
  "subordinateFinancing",
  "unitCheck",
  "vacantRefiance"
];

export const maxMinParameters = [
  "maxAsIsLtvTest",
  "maxLoanSize",
  "maxLtcTest",
  "minCbsaValue$100k",
  "minCbsaValue$150k",
  "minCbsaValue$175k",
  "minFico",
  "minLoanSize",
  "minPopulationDensity",
  "minPropertyValue",
  "minRent",
  "sectionEligibility"
];

export const finalCalculation = [
  "balanceEligibility",
  "creditEligibility",
  "overallLoanEligibility"
];

export const maxMinParametersTestKey = maxMinParameters.map(
  item => `testNames.${item}`
);
export const maxMinParametersTestPathKey = maxMinParameters.map(
  item => `loanResult.maxMinParameters.${item}.message.FAIL`
);

export const logicTestsTestKey = logicTests.map(item => `testNames.${item}`);
export const logicTestsTestPathKey = logicTests.map(
  item => `loanResult.logicTests.${item}.message.FAIL`
);

export const finalCalculationTestKey = finalCalculation.map(
  item => `testNames.${item}`
);
export const finalCalculationTestPathKey = finalCalculation.map(
  item => `loanResult.finalCalculation.${item}.message.FAIL`
);

export const templateKeys = [
  ...logicTestsTestKey,
  ...maxMinParametersTestKey,
  ...finalCalculationTestKey,
  ...logicTestsTestPathKey,
  ...maxMinParametersTestPathKey,
  ...finalCalculationTestPathKey
];
