import React from "react";

export const ExportIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#32325D" fillRule="nonzero">
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      d="M13.947 10.413c-.034-.08-.081-.155-.14-.22l-2-2c-.262-.261-.686-.261-.947 0-.261.262-.261.686 0 .947l.867.86H8c-.368 0-.667.298-.667.667 0 .368.299.666.667.666h3.727l-.867.86c-.126.126-.197.296-.197.474 0 .177.07.348.197.473.125.126.296.197.473.197.178 0 .348-.07.474-.197l2-2c.061-.063.11-.138.14-.22.066-.162.066-.344 0-.507zm-4.614 2.92H4c-.368 0-.667-.298-.667-.666V3.333c0-.368.299-.666.667-.666h3.333v2c0 1.104.896 2 2 2H12c.27-.002.511-.165.613-.414.105-.248.05-.535-.14-.726l-4-4c-.055-.052-.118-.095-.186-.127h-.06l-.187-.067H4c-1.105 0-2 .896-2 2v9.334c0 1.104.895 2 2 2h5.333c.369 0 .667-.299.667-.667 0-.368-.298-.667-.667-.667zm-.666-9.726l1.726 1.726h-1.06c-.368 0-.666-.298-.666-.666v-1.06z"
                      transform="translate(-959 -701) translate(959 700) translate(0 1)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
