import { produce } from "immer";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { UPDATE_NOTIFICATION_PARAM } from "./Notification.action";
export interface NotificationStore {
  notificationParams: any;
}

const initialState: NotificationStore = {
  notificationParams: []
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    updateNotificationParam: (
      state,
      action: PayloadAction<{
        availablePreferences: any[];
        savedPreferences: any[];
      }>
    ) => {
      const { availablePreferences, savedPreferences } = action.payload;
      const tempObj: any = availablePreferences.map((item: any) => ({
        group: item.group,
        topic: item.topic,
        email: item.email === "available" ? true : false,
        web: item.web === "available" ? true : false,
        toggleEmail: false,
        toggleWeb: false,
        immediate: item.immediate === "available" ? true : false,
        summary: item.summary === "available" ? true : false,
        toggleImmediate: false,
        toggleSummary: false,
        notificationGroupId: item.notificationGroupId
      }));

      savedPreferences.forEach((element: any) => {
        const index = tempObj.findIndex(
          (el: any) => el.group === element.group && el.topic === element.topic
        );
        if (index > -1) {
          tempObj[index].notificationPreferenceId =
            element.notificationPreferenceId;
          tempObj[index].toggleEmail = element.email;
          tempObj[index].toggleWeb = element.web;
          tempObj[index].toggleImmediate = element.immediate;
          tempObj[index].toggleSummary = element.summary;
        }
      });

      state.notificationParams = tempObj;
    }
    // Add other reducers as needed
  }
});

export const { updateNotificationParam } = notificationSlice.actions;

export const NotificationReducer = notificationSlice.reducer;

// export const NotificationReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case UPDATE_NOTIFICATION_PARAM: {
//       const { availablePreferences, savedPreferences } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         let tempObj = availablePreferences.map((item: any) => {
//           return {
//             group: item.group,
//             topic: item.topic,
//             email: item.email === "available" ? true : false,
//             web: item.web === "available" ? true : false,
//             toggleEmail: false,
//             toggleWeb: false,
//             immediate: item.immediate === "available" ? true : false,
//             summary: item.summary === "available" ? true : false,
//             toggleImmediate: false,
//             toggleSummary: false,
//             notificationGroupId: item.notificationGroupId
//           };
//         });
//         savedPreferences.forEach((element: any) => {
//           const index = tempObj.findIndex((el: any) =>
//             (el.group === element.group && el.topic === element.topic)
//           );
//           if(index > -1) {
//             tempObj[index].notificationPreferenceId =
//               element.notificationPreferenceId;
//             tempObj[index].toggleEmail = element.email;
//             tempObj[index].toggleWeb = element.web;
//             tempObj[index].toggleImmediate = element.immediate;
//             tempObj[index].toggleSummary = element.summary;
//           }
//         });
//         draftState.notificationParams = tempObj;
//       });
//       return nextState;
//     }
//     default:
//       return state;
//   }
// }
