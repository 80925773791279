export const errorMessageJson: {
  [index: string]: { heading?: string; message: string };
} = {
  postLoanAutoSave: {
    message: "Failed to save data. Click on Save button to save data."
  },
  postLoan: {
    message: "We are facing issue while saving loan data."
  },
  postPropertyAutoSave: {
    message: "Failed to save data. Click on Save button to save data."
  },
  postProperty: {
    message: "We are facing issue while saving property data."
  },
  getLoan: {
    heading: "Failed to load loan data.",
    message: "Could not load the loan data. Please try again."
  },
  getProperty: {
    heading: "Failed to load property data.",
    message: "Could not load the property data. Please try again."
  },
  getWaiver: {
    heading: "Failed to load waiver.",
    message: "Could not load the waiver data."
  },
  getRuleResults: {
    heading: "Failed to load evaluation results.",
    message: "Could not load the evaluation results."
  },
  ruleExecution: {
    heading: "Failed to execute the rules.",
    message: "There is some issue evaluating the loan. Please try again."
  }
};
