import React, { useState } from "react";
import { Grid } from "@mui/material";
import { ExpandCollapse } from "@toorak/tc-common-fe-sdk";
import iconSuccess from "../../images/icon_test_pass.svg";
import { useStyles } from "./FailedTestSectionWrapper";
import { PassedResultCard, PassedResultCardProps } from "./PassedResultCard";

export function PassedSectionWrapper(props: {
  isMinimizable?: boolean;
  showIntermediateValues?: boolean;
  item: any;
  resultType?: string;
  infoContentResult?: any;
}) {
  const {
    isMinimizable,
    showIntermediateValues,
    item,
    resultType,
    infoContentResult
  } = props;
  const [isMinimized, setIsMinimized] = useState<boolean>(false);
  const classes = useStyles();
  return (
    <Grid
      key={item.testGroupName}
      data-testid="passed-section-wrapper"
      style={{ backgroundColor: "#ffffff", borderRadius: "5px" }}
    >
      <Grid
        container
        className={
          !isMinimizable || isMinimized
            ? classes.sectionWrapperMinimized
            : classes.sectionWrapper
        }
      >
        <Grid item xs={12} container>
          <Grid
            xs={6}
            sm={11}
            style={{
              fontSize: 12,
              fontWeight: 600,
              lineHeight: 1.5,
              color: "#32325d",
              display: "flex",
              alignItems: "center"
              // paddingLeft: 10
            }}
          >
            {item.representableTestGroupName}
            <img
              src={iconSuccess}
              alt="Test Success"
              style={{ marginLeft: 6, width: 16, height: 16 }}
            />
          </Grid>
          <Grid item xs={6} sm={1} className={classes.headingImageContainer}>
            {isMinimizable && (
              <ExpandCollapse
                isMinimize={isMinimized}
                clicked={() => {
                  setIsMinimized(!isMinimized);
                }}
                keyDown={() => {
                  setIsMinimized(!isMinimized);
                }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      {showIntermediateValues && (
        <Grid
          container
          xs={12}
          style={{
            display: isMinimized ? "none" : "",
            padding: 10
          }}
        >
          {item.intermediateValues
            ?.filter((ele: any) => ele.fieldValue)
            .map((intermediateItem: Partial<PassedResultCardProps>) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ padding: 15, marginTop: "5px" }}
                >
                  <PassedResultCard
                    fieldName={intermediateItem.fieldName}
                    fieldType={intermediateItem.fieldType}
                    isTest={intermediateItem.isTest}
                    fieldValue={intermediateItem.fieldValue}
                    propertyId={intermediateItem.propertyId}
                    resultType={resultType}
                    infoContentResult={infoContentResult}
                  />
                </Grid>
              );
            })}
        </Grid>
      )}
    </Grid>
  );
}
