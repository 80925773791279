import { TaskTracker } from "../stores/budgetReview/budgetReview.interface";

export function sortArrayByUpdatedOn(arr: TaskTracker[]): TaskTracker[] {
  if (!arr?.length) return [];
  return arr.sort((a, b) => {
    const dateA = new Date(a.updatedOn);
    const dateB = new Date(b.updatedOn);
    return dateB.getTime() - dateA.getTime();
  });
}

export function checkPropertyMapped(obj: any): boolean {
  // Check if the object has the "propertyId" field
  if (
    obj.staticData?.documentData &&
    obj.staticData.documentData.hasOwnProperty("propertyId")
  ) {
    // Check if the value of "propertyId" is not null and not undefined
    return obj.staticData?.documentData?.propertyId !== null;
  }
  return false;
}
