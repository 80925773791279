import { Alert } from "@mui/material";
import { Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatPropertyAddress } from "../../create-loan/property-details/PropertyDetailsCard";
import {
  // getBulkBudgetReviewData,
  updateConsolidatedUnit
} from "../../stores/budgetReview/budgetReview.action";
import { BudgetReviewStore } from "../../stores/budgetReview/budgetReview.interface";
import { RootState } from "../../stores/rootReducer";
import {
  PropTypeOptions,
  PropTypeUpdateOption
} from "../BudgetReview.constant";

import { WarningModal } from "@toorak/tc-common-fe-sdk";
import { BudgetHeaderRightErrors } from "./BudgetHeaderRightErrors";
import { DropDownCommon } from "../../utils/DropDownCommon";
import {
  isUnitShown,
  setSelectedBudgetDoc,
  setUnitVal,
  updateSelectedPropDetails
} from "../../stores/budgetReview/budgetReview.reducer";
const { consolidated, unit } = PropTypeOptions;

// export const checkOldDocs = (
//   selectedProp: any,
//   BulkDocsData: any[],
//   budgetReviewResponse: any
// ) => {
//   let dupliProp: any[] = [];
//   BulkDocsData.forEach((docItem: any) => {
//     if (
//       docItem.extractedInfoProcessed &&
//       docItem.extractedInfoProcessed.propertyId === selectedProp.propertyId &&
//       budgetReviewResponse.externalDocumentId !== docItem.externalDocumentId
//     ) {
//       // Duplicate case: Modify propertyId and propertyInfo to null
//       const modifiedOldItem = {
//         ...docItem,
//         extractedInfoProcessed: {
//           ...docItem.extractedInfoProcessed,
//           propertyId: null,
//           propertyInfo: null
//         }
//       };
//       dupliProp.push(modifiedOldItem);
//     } else if (
//       budgetReviewResponse.externalDocumentId === docItem.externalDocumentId
//     ) {
//       // Update case: Modify propertyId and propertyInfo based on selectedProp
//       const modifiedNewItem = {
//         ...docItem,
//         extractedInfoProcessed: {
//           ...docItem.extractedInfoProcessed,
//           propertyId: selectedProp.propertyId,
//           propertyInfo: selectedProp.propertyInfo
//         }
//       };
//       dupliProp.push(modifiedNewItem);
//     }
//   });
//   return dupliProp;
// };

export function BudgetHeaderRight() {
  const dispatch = useDispatch();
  const [isPropTypeVisible, setIsPropTypeVisible] = useState<boolean>(false);
  const [isUnitUpdated, setIsUnitUpdated] = useState<boolean>(false);
  const [PropTypeSelected, setPropTypeSelected] =
    useState<PropTypeOptions>(consolidated);
  const [PropAddressSelected, setPropAddressSelected] = useState<string>("");
  const [PropAddressUpdateOption, setPropAddressUpdateOption] = useState<any[]>(
    []
  );
  const [isPropAddressVisible, setIsPropAddressVisible] =
    useState<boolean>(false);
  const propertyDetails = useSelector<RootState, any[]>(
    (state) => state.createLoanStore.loanDetails.propertyDetails
  );

  const {
    budgetReviewHeader,
    budgetReviewResponse,
    BulkDocsData,
    budgetDocsList
  } = useSelector<RootState, BudgetReviewStore>(
    (state) => state.budgetReviewStore
  );

  const [showToast, setShowToast] = useState({ isShown: false, message: "" });
  const [popUpOpen, setPopUpOpen] = useState<boolean>(false);

  const hideSnackBar = () => {
    setShowToast({ isShown: false, message: "" });
  };

  function getPropertyDetails(isString?: boolean) {
    const selectProp = propertyDetails.find(
      (property: any) =>
        // eslint-disable-next-line
        property.loanPropertyOrder ==
        budgetReviewResponse.staticData.documentData?.propertyId
    );
    const { street_line, state, city, zipcode } =
      selectProp.propertyLocation.payload.address;
    let selectedAddress = `${street_line}, ${city}, ${state}, ${zipcode}`;
    return isString
      ? selectedAddress
      : selectProp.propertyLocation.payload.address;
  }

  useEffect(() => {
    if (!budgetReviewResponse) return;

    // if (budgetReviewResponse?.extractedInfoProcessed?.isUnit) {
    //   setPropTypeSelected(unit);
    //   dispatch({
    //     type: IS_UNIT_SHOWN,
    //     payload: true
    //   });
    // } else {
    //   setPropTypeSelected(consolidated);
    // }
    // if (budgetReviewResponse?.extractedInfoProcessed?.propertyInfo) {
    if (budgetReviewResponse.staticData.documentData?.propertyId) {
      const selectProp = propertyDetails.find(
        (property: any) =>
          // eslint-disable-next-line
          property.loanPropertyOrder ==
          budgetReviewResponse.staticData.documentData.propertyId
      );
      const { street_line, state, city, zipcode } =
        selectProp.propertyLocation.payload.address;
      const selectedAddress = `${street_line}, ${city}, ${state}, ${zipcode}`;
      setPropAddressSelected(selectedAddress);
    } /*  else {
      setPropAddressSelected("");
    } */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetReviewResponse, dispatch]);

  useEffect(() => {
    if (
      !budgetReviewHeader.selectedPropInfo &&
      budgetReviewHeader.selectedPropId
    ) {
      const { street_line, state, city, zipcode } = getPropertyDetails();
      const selectedPropInfo = {
        addressLine1: street_line,
        state,
        city,
        postalCode: zipcode
      };
      dispatch(
        updateSelectedPropDetails({
          selectedPropInfo,
          selectedPropId: propertyDetails[0].loanPropertyOrder
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetReviewHeader]);

  useEffect(() => {
    if (!propertyDetails.length) return;
    const { street_line, state, city, zipcode } =
      propertyDetails[0].propertyLocation.payload.address;
    const selectedPropInfo = {
      addressLine1: street_line,
      state,
      city,
      postalCode: zipcode
    };
    const defaultAddressString = `${street_line}, ${city}, ${state}, ${zipcode}`;
    setPropAddressSelected(defaultAddressString);
    dispatch(
      updateSelectedPropDetails({
        selectedPropInfo,
        selectedPropId: propertyDetails[0].loanPropertyOrder
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyDetails]);

  useEffect(() => {
    if (isUnitUpdated || !budgetReviewResponse?.extractedInfoProcessed) {
      return; // Only run for the first time and when budgetReviewResponse is available
    }

    const isUnit =
      budgetReviewHeader.isUnitShown ||
      budgetReviewResponse?.extractedInfoProcessed?.isUnit;
    setPropTypeSelected(isUnit ? unit : consolidated);
    dispatch(isUnitShown(isUnit));
    setIsUnitUpdated(true);
    // eslint-disable-next-line
  }, [
    //eslint-disable-next-line
    budgetReviewHeader.isUnitShown,
    // eslint-disable-next-line
    budgetReviewResponse?.extractedInfoProcessed,
    dispatch
  ]);

  useEffect(() => {
    if (!budgetReviewResponse || !BulkDocsData.length) return;
    const tempPropAddressUpdateOption = propertyDetails.map((propLocation) => ({
      title: formatPropertyAddress(propLocation.propertyLocation.payload),
      numberOfUnits: propLocation.propertyInformation.payload.numberOfUnits,
      loanPropertyOrder: propLocation.loanPropertyOrder,
      propertyInfo: propLocation.propertyLocation.payload.address,
      mappedDocsData: getMappedDocsData(propLocation.loanPropertyOrder)
    }));
    updatePropertyDetails(tempPropAddressUpdateOption);
    // checkIfPropAddressMapped(tempPropAddressUpdateOption);
    // eslint-disable-next-line
  }, [budgetReviewResponse, propertyDetails, BulkDocsData]);

  function getMappedDocsData(loanPropertyOrder: any) {
    let selectedPropData = BulkDocsData.find((docsData) => {
      if (docsData.staticData.documentData?.propertyId) {
        // eslint-disable-next-line
        return docsData.staticData.documentData.propertyId == loanPropertyOrder;
      }
      return false;
    });
    return selectedPropData;
  }

  const updatePropertyDetails = (tempPropAddressUpdateOption: any[]) => {
    if (
      propertyDetails.length === 0 ||
      !BulkDocsData.length ||
      !budgetReviewHeader.budgetReviewStatus
    )
      return;

    setPropAddressUpdateOption(tempPropAddressUpdateOption);

    const setProperty = tempPropAddressUpdateOption.find(
      (item) => item.loanPropertyOrder === budgetReviewHeader.selectedPropId
    );

    if (setProperty) {
      // setPropAddressSelected(setProperty.title); //default value
      dispatch(
        updateSelectedPropDetails({
          selectedPropInfo: setProperty.propertyInfo,
          selectedPropId: Number(setProperty.loanPropertyOrder)
        })
      );
      dispatch(setUnitVal(setProperty.numberOfUnits));
    } else {
      //setting default value
      // setPropAddressSelected(""); //default value
      dispatch(
        updateSelectedPropDetails({
          selectedPropInfo: null,
          selectedPropId: null
        })
      );
      dispatch(setUnitVal(tempPropAddressUpdateOption[0].numberOfUnits));
    }
  };

  const handlePropTypeChangeClick = (item: any) => {
    if (PropTypeSelected === item.title) return;
    setPropTypeSelected(item.title);
    setIsPropTypeVisible(false);
    dispatch(
      updateConsolidatedUnit(budgetReviewResponse, item.title === "Unit")
    );
  };

  const handlePropAddressChangeClick = (item: any) => {
    if (item.title === PropAddressSelected) return;

    // const isViewMode = !isLoginPartyId(budgetStatusHistory[0].assigneePartyId)
    //   ? true
    //   : (BudgetApproverStatus as string[]).includes(
    //       budgetReviewHeader.budgetReviewStatus
    //     );
    // if (isViewMode) {
    let selectedPropData = item.mappedDocsData;
    if (selectedPropData) {
      const newDoc = budgetDocsList.find(
        // eslint-disable-next-line
        (doc) => selectedPropData.externalDocumentId == doc.id
      );
      dispatch(setSelectedBudgetDoc(newDoc));
    } else {
      setPopUpOpen(true);
      return;
    }
    // } else {
    //   const cb = (response: any) => {
    //     dispatch({
    //       type: UPDATE_SELECTED_PROP_DETAILS,
    //       payload: {
    //         selectedPropInfo: item.propertyInfo,
    //         selectedPropId: item.propertyId
    //       }
    //     });
    //     dispatch({
    //       type: SET_BUDGET_GET_RESPONSE,
    //       payload: response
    //     });
    //     const { street_line, state, city, zipcode } = item.propertyInfo;
    //     let selectedAddress = `${street_line} ${state} ${city} ${zipcode}`;
    //     setPropAddressSelected(selectedAddress);
    //     dispatch(getBulkBudgetReviewData(loanId));

    //     setShowToast({ isShown: true, message: "Saved Successfully" });
    //   };
    //   const bulkUpdateCB = (bulkResp: any[]) => {
    //     let dupliProp: any[] = checkOldDocs(
    //       item,
    //       bulkResp,
    //       budgetReviewResponse
    //     );
    //     // if (dupliProp.length > 1) {
    //     //update in bulk
    //     dispatch(
    //       updateBudgetReviewBulk(
    //         dupliProp,
    //         budgetReviewResponse.externalDocumentId,
    //         cb
    //       )
    //     );
    //     // } else {
    //     //   let newProp = {
    //     //     ...budgetReviewHeader,
    //     //     ...{
    //     //       selectedPropInfo: item.propertyInfo,
    //     //       selectedPropId: item.propertyId
    //     //     }
    //     //   };
    //     //   dispatch(showLoader());
    //     //   dispatch(
    //     //     updateBudgetReview(
    //     //       lineItemsList,
    //     //       budgetReviewResponse,
    //     //       budgetButtonInfo,
    //     //       budgetReviewResponse.status,
    //     //       newProp,
    //     //       cb
    //     //     )
    //     //   );
    //     // }
    //   };
    //   dispatch(getBulkBudgetReviewData(loanId, bulkUpdateCB));
    // }
    setIsPropTypeVisible(false);
  };

  return (
    <>
      <Snackbar
        open={showToast.isShown}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        onClose={hideSnackBar}
      >
        <Alert severity="success">{showToast.message}</Alert>
      </Snackbar>
      <WarningModal
        isOpen={popUpOpen}
        handleClose={() => setPopUpOpen(false)}
        primaryBtnName="OK"
        header="Warning"
        body="There is no document for this property"
      />

      <div style={{ display: "flex", alignItems: "center" }}>
        Property Address:
        <DropDownCommon
          availableOptions={PropAddressUpdateOption}
          setIsMoreActionsVisible={setIsPropAddressVisible}
          isMoreActionsVisible={isPropAddressVisible}
          handleActionBtnClick={handlePropAddressChangeClick}
          headerText={PropAddressSelected}
          styleMenuItem={{ minWidth: 120, marginTop: 20 }}
        />
        <div style={{ marginLeft: 15 }}>
          <DropDownCommon
            availableOptions={PropTypeUpdateOption}
            setIsMoreActionsVisible={setIsPropTypeVisible}
            isMoreActionsVisible={isPropTypeVisible}
            handleActionBtnClick={handlePropTypeChangeClick}
            headerText={PropTypeSelected}
            styleMenuItem={{ minWidth: 120, marginTop: 20 }}
          />
        </div>
        <BudgetHeaderRightErrors />
      </div>
    </>
  );
}
