import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import React from "react";
import HistoryIcon from "@mui/icons-material/HistoryOutlined";
import { useDrawsStyles } from "./Draws.styles";
import { sanitizeCurrency } from "../../utils/formatChecks";
import { getDrawDateCreated } from "../AssetManagement.utils";
import { HtmlWhiteTooltip } from "../../utils/Tooltip";

// const HtmlTooltip = withStyles((theme: Theme) => ({
//   tooltip: {
//     backgroundColor: "#f5f5f9",
//     color: "white",
//     width: "660px",
//     maxWidth: "660px !important",
//     boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
//     marginLeft: 30,
//     padding: "0px",
//     fontSize: theme.typography.pxToRem(12)
//   },
//   arrow: {
//     "&:before": {
//       border: "1px solid #E6E8ED"
//     },
//     height: "20px !important",
//     width: "15px !important",
//     marginLeft: "-15px !important",
//     color: theme.palette.common.white
//   }
// }))(Tooltip);

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: "blue",
//     color: theme.palette.common.white
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14
//   }
// }));

export const DrawHistoryComponent = ({
  historyList
}: {
  historyList: any[];
}) => {
  const classes = useDrawsStyles();
  const [tooltipIsOpen, setTooltipIsOpen] = React.useState<boolean>(false);

  return (
    <>
      {historyList && historyList?.length > 0 ? (
        <div
          className={classes.editedLabel}
          style={{ backgroundColor: "#5e72e4" }}
          data-testid="draw-history"
        >
          <div className={classes.tagTitle}>{"DRAW HISTORY"}</div>
          <HtmlWhiteTooltip
            placement={"right-end"}
            open={tooltipIsOpen}
            onOpen={() => setTooltipIsOpen(true)}
            onClose={() => setTooltipIsOpen(false)}
            // classes={{ arrow: classes.tooltipArrow}}
            title={
              <TableContainer component={Paper}>
                <Table style={{ width: 660 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        className={classes.tooltipTableHead}
                      >
                        Draw Request ID
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tooltipTableHead}
                      >
                        Date Requested
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tooltipTableHead}
                      >
                        Approved Draw Amount ($)
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tooltipTableHead}
                      >
                        Draw Paid Date
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tooltipTableHead}
                      >
                        Net Funds to Borrower ($)
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tooltipTableHead}
                      >
                        Draw Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {historyList.map(drawitem => (
                      <TableRow key={drawitem.id}>
                        <TableCell
                          component="th"
                          scope="drawitem"
                          className={classes.tooltipTableCell}
                        >
                          {drawitem.drawId}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.tooltipTableCell}
                        >
                          {getDrawDateCreated(drawitem.drawRequestedDate)}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.tooltipTableCell}
                        >
                          {sanitizeCurrency(
                            drawitem.approvedDrawAmount?.toString()
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.tooltipTableCell}
                        >
                          {getDrawDateCreated(drawitem.drawPaidDate)}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.tooltipTableCell}
                        >
                          {sanitizeCurrency(
                            drawitem.netFundsToBorrower?.toString()
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.tooltipTableCell}
                        >
                          {drawitem.drawStatus}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            }
            arrow
          >
            <span>{}</span>
          </HtmlWhiteTooltip>
          <HistoryIcon
            fontSize="large"
            style={{ color: "#fff" }}
            onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
            data-testid="draw-history-icon"
          />
        </div>
      ) : null}
    </>
  );
};
