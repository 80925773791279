import React from "react";
import { TextFieldForm } from "@toorak/tc-common-fe-sdk";
import { TextFieldProps } from "./FormComponentsProps";

export function ToorakLoanTypeRehabTextField(props: TextFieldProps) {
  const { error, helperText, isViewMode, value, infoIconData } = props;
  const valueToPass = value && value !== "" ? value : <span>&#8213;</span>;
  return (
    <TextFieldForm
      disabled
      value={valueToPass}
      label="Toorak Loan Type - Rehab"
      error={error}
      infoIconData={infoIconData}
      helperText={helperText}
      data-testid="toorak-loan-type-rehab"
      valueTestId="toorak-loan-type-rehab-value"
      isViewMode={isViewMode}
    />
  );
}

export function CBSATextField(props: TextFieldProps) {
  const { error, helperText, isViewMode, infoIconData } = props;
  const value = props.value !== "" ? props.value : <span>&#8213;</span>;
  return (
    <TextFieldForm
      disabled
      value={value}
      label="CBSA"
      error={error}
      infoIconData={infoIconData}
      helperText={helperText}
      data-testid="cbsa"
      valueTestId="cbsa-value"
      isViewMode={isViewMode}
    />
  );
}

export function MedianValueTextField(props: TextFieldProps) {
  const { error, helperText, isViewMode, infoIconData } = props;
  const value = props.value !== "" ? props.value : <span>&#8213;</span>;
  return (
    <TextFieldForm
      disabled
      value={value}
      label="Median Value ($)"
      error={error}
      helperText={helperText}
      infoIconData={infoIconData}
      data-testid="median-value"
      valueTestId="median-value-value"
      isViewMode={isViewMode}
    />
  );
}

export function LTMHPATextField(props: TextFieldProps) {
  const { error, helperText, isViewMode, infoIconData } = props;
  const value = props.value !== "" ? props.value : <span>&#8213;</span>;
  return (
    <TextFieldForm
      disabled
      value={value}
      label="LTM HPA"
      error={error}
      infoIconData={infoIconData}
      helperText={helperText}
      data-testid="ltm-hpa"
      valueTestId="ltm-hpa-value"
      isViewMode={isViewMode}
    />
  );
}

export function DaysOnMarketTextField(props: TextFieldProps) {
  const { error, helperText, isViewMode, infoIconData } = props;
  const value = props.value !== "" ? props.value : <span>&#8213;</span>;
  return (
    <TextFieldForm
      disabled
      value={value}
      label="Days on Market"
      error={error}
      helperText={helperText}
      infoIconData={infoIconData}
      data-testid="days-on-market"
      valueTestId="days-on-market-value"
      isViewMode={isViewMode}
    />
  );
}

export function LoanBalancePerUnitTextField(props: TextFieldProps) {
  const { value, error, helperText, isViewMode, infoIconData } = props;

  return (
    <TextFieldForm
      disabled
      value={value}
      label="Loan Balance Per Unit"
      error={error}
      infoIconData={infoIconData}
      helperText={helperText}
      data-testid="toorak-loan-type-sfr"
      valueTestId="toorak-loan-type-sfr-value"
      isViewMode={isViewMode}
    />
  );
}

export function ESRIPopulationDensityTextField(props: TextFieldProps) {
  const { value, error, helperText, isViewMode, infoIconData } = props;

  return (
    <TextFieldForm
      disabled
      value={value}
      label="ESRI Population Density"
      error={error}
      infoIconData={infoIconData}
      helperText={helperText}
      data-testid="toorak-loan-type-sfr"
      valueTestId="toorak-loan-type-sfr-value"
      isViewMode={isViewMode}
    />
  );
}

export function ZillowCBSATextField(props: TextFieldProps) {
  const { value, error, helperText, isViewMode, infoIconData } = props;

  return (
    <TextFieldForm
      disabled
      value={value}
      label="Zillow CBSA"
      error={error}
      infoIconData={infoIconData}
      helperText={helperText}
      data-testid="toorak-loan-type-sfr"
      valueTestId="toorak-loan-type-sfr-value"
      isViewMode={isViewMode}
    />
  );
}

export function AdjustedAsIsValue(props: TextFieldProps) {
  const { value, error, helperText, isViewMode } = props;

  return (
    <TextFieldForm
      disabled
      value={value}
      label='Adjusted "As Is" Value'
      error={error}
      helperText={helperText}
      data-testid="toorak-loan-type-sfr"
      valueTestId="toorak-loan-type-sfr-value"
      isViewMode={isViewMode}
    />
  );
}
export function CityAndState(props: TextFieldProps) {
  const { value, error, helperText, isViewMode } = props;

  return (
    <TextFieldForm
      disabled
      value={value}
      label="City And State"
      error={error}
      helperText={helperText}
      data-testid="CityAndState"
      valueTestId="CityAndState-value"
      isViewMode={isViewMode}
    />
  );
}
