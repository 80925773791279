import { ConfirmFormModal, GridTextFieldForm } from "@toorak/tc-common-fe-sdk";
import React, { useEffect } from "react";
import { useStyles } from "./DocumentsForm.style";

export const DocsTrackingIdModal = ({
  showAddTracking,
  setShowAddTracking,
  handleSave,
  oldTrackingId
}: {
  showAddTracking: boolean;
  setShowAddTracking: any;
  handleSave: any;
  oldTrackingId: any;
}) => {
  const classes = useStyles();
  //  const [showAddTracking, setShowAddTracking] = React.useState<boolean>(false);
  const [newDocsTrackingId, setNewDocsTrackingId] = React.useState<string>("");
  const { confirmModal } = classes;

  useEffect(() => {
    setNewDocsTrackingId(oldTrackingId);
  }, [oldTrackingId, showAddTracking]);

  const handleClose = () => {
    setShowAddTracking(false);
    setNewDocsTrackingId(() => oldTrackingId);
  };
  return (
    <ConfirmFormModal
      isOpen={showAddTracking}
      handleClose={(event: any, reason: string) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      header="Add Tracking ID"
      body={
        <div className={confirmModal}>
          <GridTextFieldForm
            value={newDocsTrackingId}
            style={{
              width: 350
            }}
            // viewModeStyle={viewModeStyle}
            onBlur={() => {}}
            // editedLabel={editedLabel}
            // editedData={editedData}
            isViewMode={false}
            label="Tracking ID"
            valueTestId="add-docs-tracking-id"
            onChange={(e) => setNewDocsTrackingId(e.target.value)}
            // isNotInfo={isNotInfo}
            // isLATView={isLATView}
            // infoIconData={infoIconData}
          />
        </div>
      }
      primaryBtnName="Save"
      confirmCondition={
        newDocsTrackingId
          ? oldTrackingId
            ? newDocsTrackingId === oldTrackingId
            : newDocsTrackingId.trim().replace(/\s/g, "") === ""
          : true
      }
      size="lg"
      style={{ width: "670px !important" }}
      successHandler={() => handleSave(newDocsTrackingId)}
      disableBackdropClick
    />
  );
};
