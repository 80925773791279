/* eslint-disable no-nested-ternary */
import { Grid, Typography, IconButton, Box, Tab } from "@mui/material";
import React, { useMemo, useState } from "react";
import { BootstrapTooltip } from "../utils/Tooltip";
import { convertToMillions } from "../utils/utils";
import { tkopStyles } from "./tkop.style";
import iconDarkInfo from "../images/createloan/tkop/icons-dark-info.png";
import iconDarkInfoLight from "../images/createloan/tkop/icons-dark-info-light.png";
import iconBellWarningLight from "../images/createloan/tkop/icons-bell-warning.png";
import { ObjectType } from "../masterView/common";
import { useStylesBootstrap } from "./styles";

export const PartnerTabCard = (props: any) => {
  const { tabData, selectedTab, setSelectedTab } = props;
  const classes = tkopStyles();
  const [showPurchaseAmount, setShowPurchaseAmount] = useState<boolean>(false);
  const [showPipeLineAmount, setShowPipeLineAmount] = useState<boolean>(false);
  const isActiveTab = useMemo(() => tabData.id === selectedTab, [
    selectedTab,
    tabData
  ]);
  return (
    <Tab
      label={
        <div style={{ width: "100%" }} data-testid="tkop-dashboard-active-tab">
          <Typography variant="caption">
            <Box fontWeight="bold">{tabData.title}</Box>
          </Typography>
          <Grid container>
            <Grid xs={12} sm={12}>
              <Typography data-testid="loan-count" style={{ fontSize: "10px" }}>
                Buy Box Tips{" "}
                <BootstrapTooltip
                  title={
                    <>
                      {tabData?.toolTipData?.map((data: ObjectType) => {
                        return (
                          <li>
                            <span>{`${data?.label} - ${data?.value}`}</span>
                          </li>
                        );
                      })}
                    </>
                  }
                  classes={useStylesBootstrap()}
                >
                  <IconButton
                    aria-label="pin note"
                    component="span"
                    style={{
                      padding: "0px"
                    }}
                    size="large"
                  >
                    <img
                      src={isActiveTab ? iconDarkInfoLight : iconDarkInfo}
                      alt="icons-Dark-Unpin"
                    />
                  </IconButton>
                </BootstrapTooltip>
              </Typography>
              <Grid className={classes.loanBar}>
                <Grid
                  className={classes.loanBarPercent}
                  style={{
                    background: "#32325d",
                    borderRadius: "5px 0px 0px 5px",
                    width: `${tabData.purchasedLoanAmountPercent}%`,
                    border: showPurchaseAmount ? "solid 2px #5e72e4" : "none"
                  }}
                  onMouseEnter={() => {
                    setShowPurchaseAmount(true);
                  }}
                  onMouseLeave={() => setShowPurchaseAmount(false)}
                >
                  {" "}
                </Grid>
                <Grid
                  className={classes.loanBarPercent}
                  style={{
                    background: "#2ece89",
                    width: `${tabData.pipelineLoanAmountPercent}%`,
                    border: showPipeLineAmount ? "solid 2px #5e72e4" : "none"
                  }}
                  onMouseEnter={() => {
                    setShowPipeLineAmount(true);
                  }}
                  onMouseLeave={() => setShowPipeLineAmount(false)}
                >
                  {" "}
                </Grid>
                <Grid
                  className={classes.loanBarPercent}
                  style={{
                    background: "#fff",
                    borderRadius: "0px 5px 5px 0px",
                    width: `${100 -
                      tabData.purchasedLoanAmountPercent -
                      tabData.pipelineLoanAmountPercent}%`
                  }}
                >
                  {" "}
                </Grid>
              </Grid>
              <Typography style={{ fontSize: "10px", height: "14px" }}>
                {showPurchaseAmount
                  ? `Purchased Amount ${convertToMillions(
                      tabData.purchasedLoanAmount
                    )}`
                  : showPipeLineAmount
                  ? `Pipeline Amount ${convertToMillions(
                      tabData.pipelineLoanAmount
                    )}`
                  : tabData.totalLoanAmount -
                      tabData.purchasedLoanAmount -
                      tabData.pipelineLoanAmount <=
                    0
                  ? "Balance $0M"
                  : `Balance ${convertToMillions(
                      tabData.totalLoanAmount -
                        tabData.purchasedLoanAmount -
                        tabData.pipelineLoanAmount
                    )}  `}
                {tabData.totalLoanAmount -
                  tabData.purchasedLoanAmount -
                  tabData.pipelineLoanAmount <=
                  0 && (
                  <img src={iconBellWarningLight} alt="bell-warning-icon" />
                )}
              </Typography>
            </Grid>
          </Grid>
        </div>
      }
      className={`${isActiveTab ? classes.tabSelected : ""}`}
      onClick={() => setSelectedTab(tabData.id)}
    />
  );
};

export const UnAssignedLoansTabCard = (props: any) => {
  const {
    tabData,
    selectedTab,
    setSelectedTab,
    unAssignedLaonAmountAndCount
  } = props;
  const isActiveTab = useMemo(() => tabData.id === selectedTab, [
    selectedTab,
    tabData
  ]);
  const classes = tkopStyles();
  return (
    <Tab
      label={
        <div style={{ width: "100%" }} data-testid="tkop-dashboard-active-tab">
          <Typography variant="caption">
            <Box fontWeight="bold">{tabData.title}</Box>
          </Typography>
          <Grid container>
            <Grid xs={12} sm={12}>
              <Typography data-testid="loan-count" display="inline">
                {unAssignedLaonAmountAndCount.count
                  ? `${unAssignedLaonAmountAndCount.count} Loans`
                  : "0 Loan"}
              </Typography>
              <Typography
                display="inline"
                className={`${isActiveTab ? classes.unpipe : classes.pipe}`}
              >
                {}
              </Typography>
              {unAssignedLaonAmountAndCount.loanAmount ? (
                <Typography display="inline">
                  Total{" "}
                  {convertToMillions(unAssignedLaonAmountAndCount.loanAmount)}
                </Typography>
              ) : (
                <Typography display="inline">
                  Total ${unAssignedLaonAmountAndCount.loanAmount}
                </Typography>
              )}
            </Grid>
          </Grid>
        </div>
      }
      className={`${isActiveTab ? classes.tabSelected : ""}`}
      onClick={() => setSelectedTab(tabData.id)}
    />
  );
};
