import { Grid, TextField } from "@mui/material";
import { useStylesStatusUpdate } from "@toorak/tc-common-fe-sdk";
import React, { Suspense, useState } from "react";
import { useAutoCorrectStyles } from "../../../create-loan/waiver-form/DocumentsForm.style";
import { useDrawsStyles } from "../Draws.styles";

const ConfirmFormModal = React.lazy(() =>
  import("@toorak/tc-common-fe-sdk").then(module => ({
    default: module.ConfirmFormModal
  }))
);

type DrawStageUpdatePopupProps = {
  showStatusUpdate: boolean;
  setShowStatusUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  handleConfirmStageUpdate: (updateComments: string) => void;
};

export const DrawStageUpdatePopup = ({
  showStatusUpdate,
  setShowStatusUpdate,
  handleConfirmStageUpdate
}: DrawStageUpdatePopupProps) => {
  const classesLoc = useStylesStatusUpdate();
  const classes = useDrawsStyles();
  const autoClasses = useAutoCorrectStyles();
  const [updateComments, setUpdateComments] = useState<string>("");
  const getStatusUpdateComment = () => {
    return (
      <Grid container direction="column" spacing={2} style={{ marginTop: -28 }}>
        <div className={classesLoc.tableWrapper} />
        <Grid item style={{ width: "100%", marginTop: 20 }}>
          <div className={classesLoc.checkLineWrapper}>
            Add your comment here
            <span className={`${classes.asterick}`}>*</span>
          </div>
          <div className={classesLoc.checkLineWrapper}>
            <TextField
              id="lat-multiline-comment"
              style={{ width: "100%", minWidth: 460, marginTop: 8, padding: 0 }}
              value={updateComments}
              onChange={e => setUpdateComments(e.target.value)}
              multiline
              inputRef={input => input && input.focus()}
              InputProps={{
                className: classesLoc.input
              }}
              rows={3}
              classes={autoClasses}
              placeholder="Write here"
              variant="outlined"
            />
          </div>
        </Grid>
      </Grid>
    );
  };
  async function successHandler() {
    await handleConfirmStageUpdate(updateComments);
    setUpdateComments("");
  }
  return (
    <Suspense fallback={<span>Loading...</span>}>
      <ConfirmFormModal
        isOpen={showStatusUpdate}
        handleClose={() => {
          setShowStatusUpdate(false);
          setUpdateComments("");
        }}
        header="Update Status"
        body={getStatusUpdateComment()}
        primaryBtnName="Update"
        confirmCondition={updateComments.replace(/\s/g, "") === ""}
        size="lg"
        style={{ width: "670px !important" }}
        successHandler={successHandler}
        disableBackdropClick
      />
    </Suspense>
  );
};
