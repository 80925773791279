import React from "react";
import { TextFieldForm } from "@toorak/tc-common-fe-sdk";
import { TextFieldProps } from "./FormComponentsProps";

export function CityTextField(props: TextFieldProps) {
  const {
    value,
    error,
    helperText,
    isViewMode,
    editedLabel,
    infoIconData,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      disabled
      value={value}
      label="City"
      error={error}
      infoIconData={infoIconData}
      isViewMode={isViewMode}
      inputStyle={{ backgroundColor: "#f8f9ff" }}
      helperText={helperText}
      data-testid="city"
      valueTestId="city-value"
      editedLabel={editedLabel}
      isServicerMandatory={isServicerMandatory}
    />
  );
}

export function StateTextField(props: TextFieldProps) {
  const {
    value,
    error,
    helperText,
    isViewMode,
    editedLabel,
    infoIconData,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      disabled
      inputStyle={{ backgroundColor: "#f8f9ff" }}
      value={value}
      label="State"
      infoIconData={infoIconData}
      error={error}
      isViewMode={isViewMode}
      helperText={helperText}
      data-testid="state"
      valueTestId="state-value"
      editedLabel={editedLabel}
      isServicerMandatory={isServicerMandatory}
    />
  );
}

export function PostalCodeTextField(props: TextFieldProps) {
  const {
    value,
    error,
    helperText,
    isViewMode,
    editedLabel,
    infoIconData,
    isServicerMandatory
  } = props;

  return (
    <TextFieldForm
      disabled
      inputStyle={{ backgroundColor: "#f8f9ff" }}
      value={value}
      label="Postal Code"
      infoIconData={infoIconData}
      error={error}
      isViewMode={isViewMode}
      helperText={helperText}
      data-testid="postal-code"
      valueTestId="postal-code-value"
      editedLabel={editedLabel}
      isServicerMandatory={isServicerMandatory}
    />
  );
}
