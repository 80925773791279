import { Theme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const tkopStyles = makeStyles((theme: Theme) =>
  createStyles({
    tkopContainer: {
      "& .MuiTabs-indicator": {
        display: "none"
      },
      "& .MuiAppBar-colorPrimary": {
        backgroundColor: "transparent",
        padding: "25px 0px"
      },
      "& .MuiPaper-elevation4": {
        boxShadow: "none"
      },
      "& .MuiTypography-root": {
        fontSize: "12px"
      },
      "& .MuiTab-root": {
        width: "227px",
        height: "80px",
        margin: "10px 24px",
        borderRadius: "4px",
        boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
        backgroundColor: "#fff"
      },
      "& .MuiTab-textColorInherit": {
        padding: "5px"
      },
      "& .MuiTabs-flexContainer": {
        justifyContent: "center"
      }
    },
    tabSelected: {
      color: "#fff !important",
      backgroundColor: "#5e72e4 !important"
    },
    pipe: {
      borderLeft: "1px solid black",
      height: "12px",
      margin: "0px 10px"
    },
    unpipe: {
      borderLeft: "1px solid #fff",
      height: "12px",
      margin: "0px 10px"
    },
    wrapPadding: {
      padding: "8px 20px",
      backgroundColor: "#f6f9fc",
      borderBottom: "solid 1px #e9ecef"
    },
    search: {
      position: "relative",
      width: "160px",
      display: "inline-block",
      marginLeft: "20px",
      "& .svg-icon": {
        fontSize: "16px"
      }
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1
    },
    inputRoot: {
      color: "inherit",
      width: "100%"
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: "36px",
      width: "100%",
      backgroundColor: "#fff",
      border: "1px solid #e9ecef",
      borderRadius: "20px",
      fontSize: "12px"
    },
    moreSectionPadding: {
      padding: "10px 20px"
    },
    filterHeading: {
      padding: theme.spacing(2),
      paddingBottom: 0,
      "& svg": {
        fontSize: 16,
        marginRight: "5px"
      }
    },
    filterText: {
      fontSize: 12,
      color: "#32325d"
    },
    filterOutterContainer: {
      width: "194px",
      // maxWidth: "650px",
      backgroundColor: "#ffffff"
    },
    rootFullSC: {
      height: "48px",
      width: "100%",
      backgroundColor: "#FFF"
    },
    filterWrapper: {
      background: "#ffffff",
      boxShadow: "0 2px 8px rgb(0 0 0 / 15%)",
      borderRadius: "4px",
      minWidth: "15%",
      height: "fit-content"
    },
    ovalDot: {
      position: "absolute",
      left: "22px",
      top: "8px",
      width: "6px",
      height: "6px",
      margin: "0 0 10px 2.3px",
      borderRadius: "4px",
      backgroundColor: "#5566d6"
    },
    actionButtonsWrapper: {
      display: "flex",
      justifyContent: "space-around",
      padding: "0.5rem 1rem"
    },
    filtersContainer: {
      maxHeight: "70vh",
      overflowY: "auto"
    },
    cancelButton: {
      width: 69,
      height: 28,
      borderRadius: 4,
      marginLeft: 10,
      boxShadow: "0 1px 3px 0 rgb(0 0 0 / 20%)",
      color: "#32325d",
      backgroundColor: "#fff",
      "&:hover": {
        background: "none"
      },
      border: "none"
    },
    badgeFilter: {
      height: "6px !important",
      width: "6px !important",
      zIndex: "auto"
    },
    smallAccordionSvg: {
      fontSize: 16
    },
    accordion: {
      "&:before": {
        backgroundColor: "transparent"
      },
      "& .Mui-expanded": {
        minHeight: "0px"
      },
      "& .MuiAccordionSummary-root.Mui-focused": {
        backgroundColor: "#f6f9fc"
      },
      "& .MuiAccordionSummary-expandIconWrapper": {
        padding: 12,
        marginRight: -12
      }
    },
    accordionExpanded: {
      margin: "0 !important"
    },
    accordionSummary: {
      borderBottom: "1px solid #e9ecef",
      padding: "0.5rem 1rem",
      minHeight: "0px",
      fontSize: "12px",
      background: "#fff",
      fontWeight: 600,
      "& .MuiAccordionSummary-content": {
        margin: "0px",
        display: "initial"
      }
    },
    accordionSummaryList: {
      border: "1px solid #e9ecef",
      padding: "0.5rem 1rem",
      minHeight: "0px",
      fontSize: "12px",
      background: "#f6f9fc",
      fontWeight: 600,
      "& .MuiAccordionSummary-content": {
        margin: "0px",
        display: "initial"
      }
    },
    accordionDetails: {
      padding: 0,
      display: "inherit"
    },
    loanBar: {
      height: "12px",
      borderRadius: "5px",
      margin: "4px 0px",
      border: "solid 1px #e9ecef",
      display: "flex"
    },
    loanBarPercent: {
      height: "10px",
      display: "inline-block"
    }
  })
);
