import {
  DialogContent,
  Grid,
  Table,
  TableContainer,
  Typography
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { LoanHistoryStyle } from "../../create-loan/loan-lists/utils/LoanHistoryStyles";
import { getTimeFromDate } from "../../utils/utils";
import { TableBodyComponent } from "../../create-loan/purchase-and-settlements/components/TableBodyComponent";
import { TableHeader } from "../../create-loan/purchase-and-settlements/components/TableHeader";
import { ServicerHistoryHeader } from "./constants";
// import { getOriginatorServicerMappingHistorybyId } from "../../stores/servicerOnboarding/servicerOnboarding.action";
import { useSelector } from "react-redux";
import { RootState } from "../../stores/rootReducer";
import { ServicerOnboardingStore } from "../../stores/servicerOnboarding/servicerOnboarding.interface";

interface ServicerHistoryProps {
  originatorName: string;
  onClose: any;
}

export const ServicerHistoryPopUp = (props: ServicerHistoryProps) => {
  const { onClose, originatorName } = props;
  const classes = LoanHistoryStyle();
  // const dispatch = useDispatch();
  const [historyFormatted, setHistoryFormatted] = useState<any[]>([]);
  const [history, setHistory] = useState<any>([]);
  // const [hasError, setHasError] = useState(false);

  const { servicerHistoryById } = useSelector<
    RootState,
    ServicerOnboardingStore
  >(state => state.servicerOnboardingStore);
  useEffect(() => {
    setHistory(servicerHistoryById);
  }, [servicerHistoryById]);

  useEffect(() => {
    if (!history) return;
    let historyEle = history.map((item: any) => {
      let historyRow = [];
      const {
        createdAt,
        updatedBy,
        previousBridgeServicer,
        newBridgeServicer,
        previousDscrServicer,
        newDscrServicer,
        comment
      } = item;

      const date = getTimeFromDate(createdAt, "MM/DD/YYYY, HH:mm");
      let BridgeData = "";
      let dscrInfo = "";
      BridgeData = `${previousBridgeServicer} to ${newBridgeServicer}`;
      dscrInfo = `${previousDscrServicer} to ${newDscrServicer}`;
      const userDetails = {
        action: "Servicer Updated",
        userFullName: updatedBy
      };
      historyRow.push(
        { value: BridgeData },
        { value: dscrInfo },
        { value: comment },
        { value: date },
        { userDetails }
      );
      return historyRow;
    });
    setHistoryFormatted([historyEle]);
  }, [history]);

  // const getUserDetails = useCallback(
  //   (data: any) => {
  //     const customerDetails = {
  //       customers: landMarks?.customers,
  //       accountMapping: landMarks?.accountMapping,
  //       accounts: landMarks?.accounts
  //     };
  //     let actionByProps: any = {};
  //     if (data.createdBy?.trim() === "") {
  //       actionByProps = {
  //         ...actionByProps,
  //         NameInitials: undefined,
  //         userFullName: "Toorak Connect",
  //         accountTypeFirstLetter: ""
  //       };
  //     } else {
  //       actionByProps =
  //         genFormattedUserDetails(
  //           customerDetails,
  //           data.createdBy,
  //           data.date || data.createdOn
  //         ) || {};
  //     }
  //     let actionLabel = LoanActions[data.name];
  //     if (data.name === "update_hold_status") {
  //       actionLabel = data.value === "NONE" ? "Revoked on Hold" : "Put on Hold";
  //     }
  //     if (data.name === "update_loan_scenarios") {
  //       if (data.value === "add scenario") actionLabel = "Scenario Added";
  //       else if (data.value === "delete scenario")
  //         actionLabel = "Scenario Deleted";
  //     }
  //     return { ...actionByProps, action: actionLabel };
  //   },
  //   [landMarks]
  // );

  // const landmarksPrep = (landMarksData: any) => {

  //   setHistory([historyData]);
  // };

  // const sortCallBack = useCallback(
  //   (sortOrder: string) => {
  //     if (historyFormatted?.length) {
  //       const hostoryMarks = JSON.parse(JSON.stringify(historyFormatted[0]));
  //       hostoryMarks.sort((a: any, b: any) =>
  //         sortOrder === "ASC"
  //           ? Date.parse(a[3].value) - Date.parse(b[3].value)
  //           : Date.parse(b[3].value) - Date.parse(a[3].value)
  //       );
  //       setHistory([hostoryMarks]);
  //     }
  //   },
  //   [historyFormatted]
  // );

  const loader = () => {
    return (
      <>
        <Grid className={classes.loaderModal}>
          <Grid
            xs={12}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid>
              <>
                {/* <CircularProgress size={35} style={{ marginLeft: 15 }} /> */}
                <Typography>Unable to fetch Servicer History!</Typography>
              </>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open
        className={classes.dialog}
        style={{ height: "90%" }}
        onClose={() => onClose(false)}
        aria-labelledby="servicer-history-dialog"
        aria-describedby="servicer-status-history"
      >
        <DialogContent>
          <Grid
            container
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100% - 75px)"
            }}
          >
            {/* main modal body */}
            <Grid item xs={12} sm={12}>
              {/* modal header */}
              {history.length > 0 && (
                <Grid container style={{ padding: "20px 28px" }}>
                  <Grid item xs={11}>
                    <b>Servicer History ({originatorName})</b>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end"
                    }}
                  >
                    <CloseIcon
                      htmlColor="#414169"
                      className={classes.closeIcon}
                      onClick={() => onClose(false)}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            {/* {!landMarks && !isAppraisalHistoryLoading ? ( */}
            {history.length === 0 ? (
              loader()
            ) : (
              <Grid container style={{ height: "330px", overflowY: "auto" }}>
                {/* modal body */}
                <TableContainer className={classes.tableWrapper}>
                  <Table stickyHeader id="pricingTable1">
                    <TableHeader
                      data={ServicerHistoryHeader.map((value: any) => {
                        return value;
                      })}
                      // sortCallBack={sortCallBack}
                      // filterCallBack={filterCallBack}
                      isHistoryTable
                      style={{
                        th: {
                          // padding: "10px 0px 7px 30px",
                          borderRight: "1px solid rgb(190 199 208 / 51%)",
                          fontWeight: 600
                        }
                      }}
                    />
                    {historyFormatted.length > 0 &&
                      historyFormatted.map((rowData: any) => {
                        return (
                          <TableBodyComponent
                            style={{
                              td: {
                                height: "64px",
                                width: "25%",
                                borderRight: "1px solid rgb(190 199 208 / 51%)"
                              }
                            }}
                            data={rowData}
                          />
                        );
                      })}
                  </Table>
                </TableContainer>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
