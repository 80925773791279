import { Radio, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

export const CustomCSSRadio = withStyles({
  root: {
    color: "rgba(0, 0, 0, 0.54)",
    "&$checked": {
      color: "rgba(0, 0, 0, 0.54)"
    },
    "&.MuiFormControlLabel-root": {
      margin: "0px"
    }
  },
  checked: {}
})(Radio);

export const usePricingAndGuidlinesStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalButton: {
      marginRight: 12,
      color: "#ffffff",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      borderWidth: 0,
      "&:hover": {
        backgroundColor: "#5566d6 !important"
      }
    },
    modalHeader: {
      display: "flex",
      justifyContent: "space-between",
      flexGrow: 1,
      alignItems: "center",
      padding: "20px 20px 10px"
    },
    customTooltip: {
      backgroundColor: "white",
      color: "black",
      fontSize: 12,
      border: "1px solid lightgrey",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)"
    },
    customArrow: {
      color: "white",
      // border: "1px solid lightgrey",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)"
    },
    downStyle: {
      marginTop: "45px" // Add margin-top here
    },
    ItemStyle: {
      borderBottom: "1px solid lightgrey",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      fontWeight: 400
    },
    rcleStyle: {
      color: "#1ad11a" // Set the color to green
    },
    lectStyle: {
      border: "1px solid lightgrey", // Add border style here
      borderRadius: "5px" // Add border-radius here
    },
    labelStyle: {
      width: "60%", // 60% width for label
      justifyContent: "center",
      display: "flex",
      alignItems: "center"
    },
    programmeSelectListDD: {
      width: 150,
      color: "#000",
      display: "flex",
      justifyContent: "space-between",
      padding: "12px 6px"
    },
    commonListDDText: {
      marginLeft: 5,
      fontWeight: 400,
      fontFamily: "system-ui",
      fontSize: 14
    },
    highlightText: {
      display: "flex",
      justifyContent: "space-evenly",
      padding: 10,
      alignItems: "center",
      marginTop: 25,
      borderRadius: 5,
      backgroundColor: "#ffe5b7"
    },
    menuItemStyle: {
      borderBottom: "1px solid lightgrey",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      fontWeight: 400
    },
    exportBtn: {
      margin: 12,
      height: 30,
      color: "#32325d",
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      border: "1px solid  rgba(0, 0, 0, 0.23)"
    },
    standardMargin: {
      marginLeft: 24,
      marginRight: 24
    },
    checkCircleStyle: {
      color: "#1ad11a" // Set the color to green
    },
    iconStyle: {
      width: "40%", // 40% width for CheckCircleIcon
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    modalButtonDisabled: {
      color: "#ffffff !important",
      opacity: "0.6",
      // pointerEvents: "unset !important",
      marginRight: 12,
      backgroundColor: "#5f72e4",
      "&:hover": {
        backgroundColor: "#5f72e4 !important"
      }
    },
    modalHeaderText: {
      fontSize: 14,
      fontWeight: 600,
      color: "#32325d",
      width: "100%"
    },
    modalSubHeaderText: {
      fontSize: 11,
      color: "#32325d",
      width: "100%",
      paddingLeft: "20px"
    },
    modalBody: {
      fontSize: 12,
      lineHeight: 1.5,
      color: "#32325d",
      wordBreak: "break-word"
    },
    closeIcon: { width: 16, height: 16, cursor: "pointer" },
    dialogCancelBtn: {
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      backgroundColor: "#ffffff",
      border: "solid 1px #ececec",
      color: "#32325d",
      "&:hover": {
        backgroundColor: "#ffffff !important"
      }
    },
    modalPrimaryButton: { padding: 20, marginTop: 20 },
    horizontalLine: {
      borderBottom: "1px solid lightgrey",
      padding: 0
    },
    selectProgramHeader: {
      borderRight: "1px solid lightgrey",
      borderTop: "1px solid lightgrey"
    }
  })
);
