import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    programBorder: {
      width: "1px",
      height: "14px",
      margin: "3px 7.5px 2px",
      border: "solid 1px #979797",
      display: "flex"
    },
    orgProfileImage: {
      width: "22px",
      height: "22px",
      borderRadius: "50%",
      background: "#5e85eb",
      fontSize: "12px",
      color: "#fff",
      textAlign: "center",
      lineHeight: "24px"
    },
    latProfileImage: {
      width: "22px",
      height: "22px",
      borderRadius: "50%",
      background: "#ebaf5e",
      fontSize: "12px",
      color: "#fff",
      textAlign: "center",
      lineHeight: "24px"
    },
    font: {
      fontSize: "12px"
    },
    Line: {
      width: "1px",
      height: "calc(100% - 4px)",
      border: "1px solid #5c70ec",
      position: "absolute",
      top: "20px",
      left: "8px",
      "&:first-child:": {
        top: "36px"
      }
    },
    infoContainer: {
      position: "relative"
    },
    firstEdit: {
      marginTop: "15px"
    },
    oval: {
      width: "23px",
      height: "23px",
      backgroundColor: "#5c70ec",
      borderRadius: "50%",
      textAlign: "center",
      position: "relative"
    },
    ellipses: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  })
);
