import { PropertyEconomics } from "@toorak/tc-common-fe-sdk";
import moment from "moment";

export const getFormatDate = (value: string, isLocalTime?: boolean) => {
  if (value) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];

    const date = isLocalTime ? moment(value) : moment.utc(value);
    const formattedDate = `${months[date.month()]} ${`0${date.date()}`.slice(
      -2
    )}, ${date.year()}`;
    return formattedDate;
  }
  return "";
};

export interface ObjectType {
  [key: string]: any;
}

export function getBusinessDatesCount(givenDate: Date) {
  const startDate = new Date(givenDate);
  const endDate = new Date();
  let count = -1;
  let curDate = new Date(startDate.getTime());
  // treating any loans submitted on Sat or Sun as if submitted on upcoming Mon 12 AM
  if (curDate.getDay() === 0) {
    curDate = new Date(curDate.setDate(curDate.getDate() + 1));
    curDate = new Date(
      curDate.getFullYear(),
      curDate.getMonth(),
      curDate.getDate()
    );
  } else if (curDate.getDay() === 6) {
    curDate = new Date(curDate.setDate(curDate.getDate() + 2));
    curDate = new Date(
      curDate.getFullYear(),
      curDate.getMonth(),
      curDate.getDate()
    );
  }
  while (curDate.getTime() < endDate.getTime()) {
    const dayOfWeek = curDate.getDay();
    if (![0, 6].includes(dayOfWeek)) {
      count++;
    }
    curDate.setDate(curDate.getDate() + 1);
  }

  if (count === -1) {
    count = 0;
  }
  return count;
}

export const filterMap = (viewType: string) => {
  const returnObj = [
    {
      key: "originator.accountName.raw",
      id: "originator",
      name: "Originators"
    },
    {
      key: "properties.propertyinfo.propertyType.raw",
      id: "property",
      name: "Property Type"
    },
    {
      key: "loan.loanStage.raw",
      id: "loanStage",
      name: "Loan Stage"
    },
    {
      key: "spocPartyInfo.fullName.raw",
      id: "spocParty",
      name: "SPOC"
    },
    {
      key: "loan.loanState.raw",
      id: "loanStatus",
      name: "Loan Status"
    }
  ];
  return returnObj;
};

export const isTruthy = (param: any) => {
  return ["true", true, "Yes", "Overridden", "Approved"].includes(param);
};

// TODO Remove from here once it gets updated in PropertyEconomics type(sdk)
export type UpdatedPropertyEconomics = PropertyEconomics & {
  decliningMarkets?: string | null;
};
