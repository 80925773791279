import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      overflow: "hidden"
    },
    reinitiate: {
      width: 91,
      height: 36,
      borderRadius: "4",
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4",
      marginLeft: "10px"
    },
    mainMenuItem: {
      color: "#32325d",
      cursor: "pointer",
      fontSize: "14px",
      textTransform: "uppercase",
      padding: "20px 25px 20px 25px",
      fontWeight: 600
    },
    zeroState: {
      justifyContent: "center",
      padding: "15px 20px",
      minHeight: 75
    },
    mainMenuListContainer: {
      borderBottom: "1px solid #e2e4f1",
      padding: "0 0 10px 0",
      listStyle: "none",
      textAlign: "left",
      marginBottom: "15px"
    },
    cell: {
      "& .MuiTableCell-body, .MuiTableCell-head": {
        border: "1px ridge #DCDCDC",
        width: "50px",
        padding: "15px"
      },
      "& .MuiTableCell-head": {
        fontWeight: 600
      }
    },
    hyperLink: {
      color: "#5e72e4",
      cursor: "pointer",
      listStyle: "none",
      "&:hover": {
        textDecoration: "underline"
      }
    },
    imgUser: {
      textAlign: "right",
      backgroundColor: "#105CBE",
      borderRadius: "8px",
      height: "70%",
      position: "relative",
      top: "9px",
      left: "10px",
      border: "1px solid rgba(0, 40, 86, 0.22)",
      width: "135px"
    },
    paper: {
      margin: "24px",
      width: "calc(100% - 48px)",
      marginTop: theme.spacing(5),
      overflowX: "scroll"
    },
    modalContainer: {
      flexDirection: "column",
      minWidth: "400px",
      minHeight: "100px",
      justifyContent: "center"
    },
    header: {
      background: "#f6f9fc",
      padding: "15px 20px",
      color: "#32325d",
      fontSize: "14px",
      fontWeight: 600
    },
    sortIcon: {
      height: "16px",
      width: "16px",
      marginBottom: "0",
      cursor: "pointer",
      padding: "0"
    },
    filterIcon: {
      height: "16px",
      width: "16px"
    },
    deleteIcon: {
      height: "16px",
      width: "16px"
    },
    editIcon: {
      height: "16px",
      width: "16px"
    },
    bodyWrapper: {
      paddingTop: "60px",
      [theme.breakpoints.down("md")]: {
        paddingTop: "46px"
      },
      [theme.breakpoints.down("lg")]: {
        paddingTop: "46px"
      }
    },
    pageDropdown: {
      marginLeft: "5px",
      marginTop: "-21px",
      flexGrow: 0,
      maxWidth: "18%",
      flexBasis: "18%"
    },
    handleButton: {
      marginRight: 12,
      color: "#ffffff",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      marginLeft: 8,
      border: "solid 1px #5566d6",
      backgroundColor: "#5566d6",
      "&:hover": {
        backgroundColor: "#5566d6 !important",
        boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)"
      }
    },
    fileBarWrapper: {
      padding: "10px 0px"
    },
    textAreaDescriptionWrapper: {
      width: "100%",
      border: "1px solid #cad1d7",
      borderRadius: "4px",
      marginTop: "-5px"
    },
    sendToListBtn: {
      color: "rgb(50, 50, 93)",
      border: "0px",
      minWidth: "16px",
      minHeight: "16px",
      height: "16px",
      padding: 0,
      margin: "6px 0 0 10px"
    },
    list: {
      marginBottom: "5px"
    },
    containerParentDiv: {
      width: "250px",
      height: "auto",
      whiteSpace: "initial",
      position: "fixed",
      padding: "10px 15px 10px 15px",
      border: "1px solid #888888",
      backgroundColor: "white",
      display: "block",
      zIndex: 999999,
      left: "50%",
      maxHeight: "250px",
      overflowX: "hidden"
    },
    textArea: {
      fontFamily: "sans-serif !important",
      width: "100%",
      height: "100%",
      padding: "14px 12px 14px 12px",
      resize: "none",
      border: "none",
      borderRadius: "4px",
      fontSize: "14px"
    },
    clearSearchIcon: {
      width: "16px",
      height: "16px",
      cursor: "pointer"
    },
    selectEmpty: {
      height: "2.25rem",
      borderRadius: "3px",
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      paddingLeft: "3px",
      fontSize: "0.75rem",
      fontWeight: 600,
      background: "#ffffff !important",
      "&:selected": {
        backgroundColor: "#ffffff !important"
      }
    },
    selectRoot: {
      "& .MuiSelect-select": {
        marginTop: "0px",
        minHeight: "0.1876em",
        paddingBottom: "7px !important"
      },
      "& .MuiSelect-select:focus": {
        backgroundColor: "#ffffff !important",
        margin: "0px",
        marginTop: "1px !important"
      },
      "& legend": {
        display: "none"
      },
      "& .MuiListItemText-primary": {
        position: "relative",
        top: "5px"
      },
      "& .MuiListItemText-root": {
        marginTop: "-8px"
      },
      "& .MuiTypography-body1": {
        fontSize: "12px",
        overflow: "hidden",
        textOverflow: "ellipsis"
      },
      "& fieldset": {
        top: "0"
      }
    },
    inputValue: {
      border: "solid 1px #cad1d7",
      borderRadius: "4px",
      background: "#ffffff",
      color: "#000000",
      padding: "2px 5px",
      height: "36px",
      width: "85%"
    },
    EmailContainer: {
      margin: "20px 0"
    },
    bulkHeading: {
      fontSize: "14px",
      lineHeight: 1.69,
      color: "#32325d"
    },
    bulkOptions: {
      fontSize: "12px",
      lineHeight: 1.5,
      color: "#32325d"
    },
    subject: {
      paddingTop: "7px",
      marginRight: "10px",
      fontSize: "14px"
    },
    subjectContainer: {
      marginTop: "15px"
    },
    fileContainer: {
      backgroundColor: "#f7fafc",
      padding: "5px"
    },
    searchContainer: {
      alignSelf: "flex-end",
      flex: 1,
      display: "flex",
      alignItems: "center",
      borderRadius: "20px",
      backgroundColor: "#f7fafc !important",
      padding: "0px 16px 0 4px !important",
      maxWidth: "171px !important",
      height: "36px !important",
      border: "solid 1px #E9ECEF !important",
      fontSize: "12px"
    },
    dropdown: {
      width: "250px",
      marginTop: "-23px",
      padding: "0px 0px 10px 17px"
    }
  })
);
