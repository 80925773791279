import { LoanStage, publicClient, UserRole } from "@toorak/tc-common-fe-sdk";
import Cookies from "js-cookie";
import { getConfig } from "../../config/config";
import {
  formatLoanDetailsResponse,
  formatPropertyResponse
} from "../../create-loan/CreateLoan";
import { LoanServicerNames } from "../../utils/constants";
import { getLoanTypeDisplayValue } from "../../utils/utils";
import {
  showLoader,
  hideLoader
} from "../loaderAndException/loaderAndException.action";
import {
  getCalculatedMandatoryFields,
  initialState,
  setActiveServicerTab,
  setLineFieldOptions,
  setOnboardingData,
  setOnboardingStatusHistory,
  setPurchasedDocsDetails,
  setPurchasedLoanData,
  setServicerMandatoryDocs,
  setServicerMandatoryFields,
  updateAllOriginatorServicerMappingHistory,
  updateAssigneeList,
  updateInternalNotes,
  updateLineObj,
  updateOnboardingData,
  updateOnboardingDataStatus,
  updateOriginatorServicerMappingHistoryById,
  updateServicerCount,
  updateServicerDetails,
  updateServicerMappingData
} from "./servicerOnboarding.reducer";
import { ObjectType } from "./servicerOnboarding.interface";
import { downloadExportedTape } from "../../create-loan/create-loan.action";
import { getUsername } from "../../assetManagement/AssetManagement.utils";
import { getCookie } from "../../utils/cookies";
import { formatValueByType } from "../../utils/formatChecks";
import { isRole } from "../../utils/AccessManagement";

export const apiUrlHost =
  window.location.hostname === "dev.toorakcapital.info" ||
  window.location.hostname === "localhost"
    ? "https://api-dev.toorakcapital.info"
    : getConfig().apiUrl;

export function getAllSOAssignee(): any {
  return async (dispatch: any) => {
    try {
      const url = `${apiUrlHost}/serviceronboarding/ammembers`;
      let response = (await publicClient.get(url)).data;
      response = response.filter(
        (item: any) => item?.amMemberName?.trim() !== ""
      );
      dispatch(updateAssigneeList(response?.length > 0 ? response : []));
    } catch (err) {
      console.error(err);
    }
  };
}

export async function updateAssigneTo(toorakLoanIds: any, value: any) {
  try {
    const owner = getCookie("org_id");
    const url = `${apiUrlHost}/serviceronboarding/assign?owner=${owner}`;
    const reqBody = {
      servicerOnboardingList: toorakLoanIds,
      assignedTo: value
    };
    const response = await publicClient.patch(url, reqBody);
    if (response.status === 200) {
      return Promise.resolve();
    }
  } catch (err) {
    console.error(err);
    return Promise.reject();
  }
}
// export function updateTableSearchConfig(data: any): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: UPDATE_TABLE_SEARCH_OPTIONS,
//       payload: data
//     });
//   };
// }

// export function updateTableSortOptions(data: any): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: UPDATE_TABLE_SORT_OPTIONS,
//       payload: data
//     });
//   };
// }

// export function updateTableSelectedFilters(data: any): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: UPDATE_TABLE_FILTERS,
//       payload: data
//     });
//   };
// }

// export function setSelectedLoans(data: any): any {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_SELECTED_LOANS,
//       payload: data
//     });
//   };
// }

// export function setSelectedRowData(data: any): any {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_SELECTED_ROW_DATA,
//       payload: data
//     });
//   };
// }

// export function setPurchasedLoanDetails(data: any): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: SET_PURCHASED_LOAN_DATA,
//       payload: data
//     });
//   };
// }

// export function setPurchasedLoanResults(data: any): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: SET_PURCHASED_LOAN_RESULT,
//       payload: data
//     });
//   };
// }

// export function setPurchasedLoanSettlement(data: any): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: SET_PURCHASED_LOAN_SETTLEMENT,
//       payload: data
//     });
//   };
// }

// export function setPurchasedPropertyDetails(data: any): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: SET_PURCHASED_PROPERY_DATA,
//       payload: data
//     });
//   };
// }

// export function setPurchasedDocsDetails(data: any[]): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: SET_PURCHASED_DOCS_DETAILS,
//       payload: data
//     });
//   };
// }

// export function updateGlobalSearchValue(data: any): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: UPDATE_SEARCH_VALUE,
//       payload: data
//     });
//   };
// }

export function getServicerMappingListData(): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const url = `${apiUrlHost}/serviceronboarding/servicermappingrequests`;
      const response = await publicClient.get(url);
      dispatch(
        updateServicerMappingData(response.data.length > 0 ? response.data : [])
      );

      if (response.data.length > 0) {
        dispatch(updateServicerDetails({})); // Assuming updateServicerDetails takes an object payload
      }
      dispatch(hideLoader());
    } catch (err) {
      dispatch(hideLoader());
      console.error(err);
    }
  };
}

// export function updateGlobalSearchMappingValue(data: any): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: UPDATE_SEARCH_MAPPING_VALUE,
//       payload: data
//     });
//   };
// }

// export function updateServicerMappingDetails(obj: any): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: UPDATE_SERVICER_MAPPING_DETAILS,
//       payload: obj
//     });
//   };
// }

export function getAllOriginatorServicerMappingHistory(): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const url = `${apiUrlHost}/serviceronboarding/servicermappingrequests/history`;
      const response = (await publicClient.get(url)).data;
      dispatch(
        updateAllOriginatorServicerMappingHistory(
          response.length > 0 ? response : []
        )
      );
      dispatch(hideLoader());
    } catch (err) {
      dispatch(hideLoader());
      console.error(err);
    }
  };
}
export function getOriginatorServicerMappingHistorybyId(id: string): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const url = `${apiUrlHost}/serviceronboarding/servicermappingrequests/history/${id}`;
      const response = (await publicClient.get(url)).data;
      dispatch(
        updateOriginatorServicerMappingHistoryById(
          response.length > 0 ? response.reverse() : []
        )
      );
      dispatch(hideLoader());
    } catch (err) {
      dispatch(hideLoader());
      console.error(err);
    }
  };
}

export function getCalculatedFields(
  id: string,
  servicerName: string,
  loanStage: LoanStage
): any {
  return async (dispatch: any) => {
    if (!(id && servicerName)) return;
    try {
      dispatch(showLoader());
      const url = `${apiUrlHost}/serviceronboarding/getMandatoryFieldsForServicer/${servicerName.toUpperCase()}/${id}/${loanStage}`;
      let resp = (await publicClient.get(url)).data[0];
      resp = {
        ...resp,
        ...{
          escrowBalance: formatValueByType(
            resp?.escrowBalance?.toString(),
            "currency"
          )
        }
      };
      dispatch(getCalculatedMandatoryFields(resp));
      dispatch(hideLoader());
    } catch (err) {
      dispatch(hideLoader());
      console.error(err);
    }
  };
}

export const getServicerLoanDetails = async (
  loanId: string,
  loanStage: LoanStage
) => {
  try {
    const url = `${apiUrlHost}/serviceronboarding/onboardingrequests/loan/${loanId}/${loanStage}`;

    let response = (await publicClient.get(url)).data;
    // response.documents = response.documents.filter(
    //   (item: any) =>
    //     !item.path.includes("/SETTLEMENT/") &&
    //     !item.path.includes(
    //       "/PURCHASE_ADVICE_SIGNED/PURCHASE_ADVICE_SIGNED_DOC_VAL/DOCUMENTS"
    //     )
    // );
    const formattedResp = formatLoanDetailsResponse(
      response.loanData,
      loanStage
    );

    const {
      loanState,
      bridgeLoanBorrowerInformation,
      bridgeLoanEconomics,
      bridgeLoanInformation,
      thirtyYearGuarantorInformation,
      thirtyYearLoanEconomics,
      thirtyYearLoanInformation,
      loanType,
      loanSummary,
      bridgeLoanGuarantorInformation,
      loanDetailId
    } = formattedResp.loanDetails;
    const {
      lateCharge,
      lateChargePercentage,
      originalMonthlyPiPayment,
      armLookBackDays,
      armRoundFlag,
      armRoundingFactor
    } = response.loanData?.loanEconomics;

    const {
      monthlyQualifyingPayment,
      prePaymentPenaltyMonths,
      monthlyTaxAmount,
      monthlyInsuranceAmount
    } = response.results?.loanResult?.loanEconomics || {};
    const formattedPropertyData = formatPropertyResponse(
      response.properties,
      loanStage,
      loanType
    );

    let respForRedux = {
      loanBorrowerInformation: bridgeLoanBorrowerInformation,
      loanState,
      loanSummary,
      loanDetailId,
      originatorInfo: response.loanData.originatorInfo,
      servicerName: response.loanData.loanDetailId?.loanConfigId?.servicerName,
      loanType: getLoanTypeDisplayValue(loanType),
      drawDetails: { ...response.bundleResults?.drawDetails?.[0] }
    };
    let tempExtraFields = {};
    if (loanType === "30 Year Loan") {
      const LoanEconomicsWithExtraFields = {
        ...thirtyYearLoanEconomics.payload,
        ...{
          lateCharge,
          lateChargePercentage,
          armRoundFlag,
          armRoundingFactor,
          originalMonthlyPiPayment,
          armLookBackDays,
          monthlyQualifyingPayment,
          prepaymentPenaltyMonthsResults: prePaymentPenaltyMonths
        }
      };
      const thirtyYearLoanInfoWithExtraFields = {
        ...thirtyYearLoanInformation.payload,
        ...{
          monthlyTaxAmount,
          monthlyInsuranceAmount
        }
      };
      tempExtraFields = {
        loanInformation: thirtyYearLoanInfoWithExtraFields,
        loanGuarantorInformation: thirtyYearGuarantorInformation,
        loanEconomics: LoanEconomicsWithExtraFields
      };
    } else {
      const LoanEconomicsWithExtraFields = {
        ...bridgeLoanEconomics.payload,
        ...{
          originalMonthlyPiPayment,
          lateCharge,
          lateChargePercentage,
          armRoundFlag,
          armRoundingFactor,
          monthlyQualifyingPayment,
          prepaymentPenaltyMonthsResults: prePaymentPenaltyMonths
        }
      };

      tempExtraFields = {
        loanInformation: bridgeLoanInformation.payload,
        loanGuarantorInformation: bridgeLoanGuarantorInformation,
        loanEconomics: LoanEconomicsWithExtraFields
      };
    }
    respForRedux = { ...respForRedux, ...tempExtraFields };
    return Promise.resolve({
      loanDetails: respForRedux,
      docsDetails: response.documents,
      propertyDetails: formattedPropertyData,
      loanResults: response.results?.loanResult,
      loanSettlement: {
        ...response.results?.settlementResults,
        ...response.bundleResults?.bundleDetails
      }
    });
  } catch (e) {
    console.error(e);
    return Promise.reject();
  }
};

export const updateServicerMapping = async (
  reqBody: any,
  onboardingLoanDetails?: any
) => {
  try {
    const firstName = Cookies.get("firstName");
    const lastName = Cookies.get("lastName");
    const ownerName = `${firstName} ${lastName}`;
    const url = `${apiUrlHost}/serviceronboarding/servicermappingrequests?owner=${ownerName}`;
    let resp = (await publicClient.put(url, reqBody)).data;
    if (onboardingLoanDetails) {
      let temp = { ...onboardingLoanDetails };
      let servicerName =
        reqBody?.dscrServicer === "default"
          ? reqBody.bridgeServicer
          : reqBody.dscrServicer;

      temp = { ...temp, servicerName };
      return Promise.resolve(temp);
    }
    return Promise.resolve(resp);
  } catch (e) {
    console.error(e);
    return Promise.reject();
  }
};

const getWarehouseLine = async (primaryLoanId: string) => {
  const url = `${apiUrlHost}/serviceronboarding/onboardingrequests/warehouseLine?primaryLoanId=${primaryLoanId}`;
  try {
    let resp = (await publicClient.get(url)).data;
    return Promise.resolve(resp);
  } catch (e) {
    console.error(e);
    return Promise.resolve({ investorWarehouseLine: null });
  }
};

export const getCurrentOnboardingData = async (id: string) => {
  try {
    const url = `${apiUrlHost}/serviceronboarding/onboardingrequest/${id}`;
    let resp = (await publicClient.get(url)).data;
    const isWarehouseLine = resp.investorWarehouseLine ?? "true";
    if (isWarehouseLine !== "true") return Promise.resolve(resp);
    const wareLinrresp = await getWarehouseLine(resp.originatorLoanId);
    resp = {
      ...resp,
      investorWarehouseLine: wareLinrresp?.investorWarehouseLine
    };
    return Promise.resolve(resp);
  } catch (e) {
    console.error(e);
    return Promise.reject();
  }
};

const getReduxName = (field: string) => {
  switch (field) {
    case "accountName":
      return "borrowingEntityName";

    case "email":
      return "borrowerEmail";
    case "partyType":
      return "borrowerType";
    case "contactNumber":
      return "billingPhoneNumber";
    case "addressLine1":
    case "state":
    case "pincode":
    case "city":
      return "billingAddress";
    default:
      return field;
  }
};

export const getServicerMandatoryFields = async (
  servicerName: string,
  dispatch: any
) => {
  try {
    const url = `${apiUrlHost}/serviceronboarding/fields/${servicerName.toUpperCase()}`;
    let resp = (await publicClient.get(url)).data;
    let loanEconomics: string[] = [];
    let loanInfo: string[] = [];
    let loanUserMap: any = [];
    let propertyEconomics: string[] = [];
    let propertyLocation: string[] = [];
    let propertyInformation: string[] = [];
    let loanFeature: string[] = [];
    let loanSettlement: string[] = [];
    let loanSummary: string[] = [];
    let propertyUnit: string[] = [];
    let drawDetails: string[] = [];
    let resultsToorakInterests: string[] = [];
    resp.mandatory.forEach((item: string) => {
      let split = item.split(".");
      if (split[split.length - 2] === "loanEconomics") {
        loanEconomics.push(split[split.length - 1]);
      }
      if (
        split[split.length - 2] === "loanInfo" ||
        split[split.length - 2] === "loanSummaryId"
      ) {
        loanInfo.push(split[split.length - 1]);
      }
      if (split[1] === "loanUserMap") {
        loanUserMap.push(getReduxName(split[split.length - 1]));
      }
      if (split[split.length - 2] === "propertyEconomics") {
        propertyEconomics.push(split[split.length - 1]);
      }
      if (split[split.length - 2] === "drawDetails") {
        drawDetails.push(split[split.length - 1]);
      }

      if (split[split.length - 2] === "propertyLocation") {
        propertyLocation.push(split[split.length - 1]);
      }
      if (split[split.length - 2] === "propertyUnit") {
        propertyLocation.push(split[split.length - 1]);
      }
      if (split[split.length - 2] === "propertyinfo") {
        propertyInformation.push(split[split.length - 1]);
      }
      if (split[split.length - 2] === "loanFeatures") {
        loanFeature.push(split[split.length - 1]);
      }
      if (split[split.length - 3] === "loanResult") {
        if (split[split.length - 2] === "toorakInterests") {
          resultsToorakInterests.push(split[split.length - 1]);
        }
      }
      if (split[split.length - 2] === "settlementResults") {
        if (split[split.length - 1] === "nextPaymentDueDate") {
          loanInfo.push(split[split.length - 1]);
        } else {
          loanSettlement.push(split[split.length - 1]);
        }
      }
      if (split[split.length - 2] === "bundleDetails") {
        loanSettlement.push(split[split.length - 1]);
      }
      if (
        ["originatorInfo", "loanCredit", "loanData"].includes(
          split[split.length - 2]
        )
      ) {
        loanSummary.push(split[split.length - 1]);
      }
    });
    if (loanEconomics.includes("interestRateAsOfCutOffDate")) {
      //for dscr loans
      loanEconomics.push("interestRate");
    }
    loanUserMap = [...new Set(loanUserMap)];
    dispatch(
      setServicerMandatoryFields({
        loanEconomics,
        loanInfo,
        loanUserMap,
        propertyEconomics,
        propertyLocation,
        propertyInformation,
        propertyUnit,
        loanSummary,
        loanFeature,
        loanSettlement,
        resultsToorakInterests,
        drawDetails
      })
    );

    return Promise.resolve(resp);
  } catch (e) {
    console.error(e);
    return Promise.reject();
  }
};

export const getStatusHistory = (id: string): any => {
  return async (dispatch: any) => {
    try {
      const url = `${apiUrlHost}/serviceronboarding/onboardingrequests/statusHistory/${id}`;
      let resp = (await publicClient.get(url)).data;
      dispatch(setOnboardingStatusHistory(resp));
    } catch (e) {
      console.error(e);
    }
  };
};

export const updateOnboardingStatus = (
  reqBody: any,
  id: number,
  onboardingStatusHistory?: any[] | undefined,
  cb?: any
): any => {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const firstName = Cookies.get("firstName");
      const lastName = Cookies.get("lastName");
      const ownerName = `${firstName} ${lastName}`;
      const url = `${apiUrlHost}/serviceronboarding/update-status/${id}?owner=${ownerName}`;
      let resp = (await publicClient.put(url, reqBody)).data;
      if (resp) {
        if (onboardingStatusHistory) {
          resp["status"] = reqBody.onboardStatus;
          dispatch(updateOnboardingDataStatus(reqBody.onboardStatus));
          let tempAudit = [...[resp], ...onboardingStatusHistory];
          dispatch(setOnboardingStatusHistory(tempAudit));
        }
      }
      cb && cb();
      dispatch(hideLoader());
    } catch (e) {
      console.error(e);
      dispatch(hideLoader());
    }
  };
};

export const updateAddDataReqByServicer = (reqBody: any, onbReqId: any): any => {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const url = `${apiUrlHost}/serviceronboarding/updateAdditionalDataField`;
      await publicClient.put(url, reqBody);
      await getCurrentOnboardingData(onbReqId);
      dispatch(hideLoader());
    } catch (e) {
      console.error(e);
      dispatch(hideLoader());
    }
  };
};

export const updateBulkOnboardingStatus = (
  reqBody: any,
  id: number,
  loanStage: LoanStage,
  cb?: any
): any => {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const firstName = Cookies.get("firstName");
      const lastName = Cookies.get("lastName");
      const ownerName = `${firstName} ${lastName}`;
      const url = `${apiUrlHost}/serviceronboarding/bulkupdate/${id}/loanStage=${loanStage}?owner=${ownerName}`;
      await publicClient.put(url, reqBody);
      if (cb) {
        cb();
      }
      dispatch(hideLoader());
    } catch (e) {
      console.error(e);
      dispatch(hideLoader());
    }
  };
};

export const getWareHouseLineOptions = (onboardingData: any): any => {
  return async (dispatch: any) => {
    try {
      const investorCodesUrl = `${apiUrlHost}/serviceronboarding/onboardingrequests/investorCodes?originatorName=${onboardingData.originatorName}&loanType=${onboardingData.loanType}&primaryLoanId=${onboardingData.originatorLoanId}`;
      const blockCodeUrl = `${apiUrlHost}/serviceronboarding/onboardingrequests/blockCodes`;

      const investorCodeResp = (await publicClient.get(investorCodesUrl)).data;
      const blockCodeResp = (await publicClient.get(blockCodeUrl)).data;
      const filterBlockCodes = blockCodeResp.filter(
        (obj: any) => obj.blockCode !== "" && obj.blockCode !== "\t"
      );
      const blockDefaultCode = filterBlockCodes.find(
        (item: any) => item.originatorName === onboardingData.originatorName
      );

      const lineFieldOptions = {
        blockCode: filterBlockCodes,
        investorCode: investorCodeResp.investorCodes,
        investorDefaultValue: investorCodeResp.defaultValue?.investorCode || "",
        blockDefaultCode: blockDefaultCode ? blockDefaultCode.blockCode : ""
      };
      dispatch(setLineFieldOptions(lineFieldOptions));
    } catch (e) {
      console.error(e);
    }
  };
};

export const updateWarehouseData = (
  reqBody: any,
  id: number,
  wireLineObj?: any
): any => {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const firstName = Cookies.get("firstName");
      const lastName = Cookies.get("lastName");
      const ownerName = `${firstName} ${lastName}`;
      const url = `${apiUrlHost}/serviceronboarding/update-status/${id}?owner=${ownerName}`;
      // eslint-disable-next-line
      let resp = (await publicClient.put(url, reqBody)).data;
      wireLineObj && dispatch(updateLineObj(wireLineObj));
      dispatch(hideLoader());
    } catch (e) {
      console.error(e);
      dispatch(hideLoader());
    }
  };
};

export const clearPurchasedLoansData = (): any => {
  return (dispatch: any) => {
    const {
      servicerDocsDetails,
      onboardingData,
      activeServicerTab,
      onboardingLoanDetails
    } = initialState;
    dispatch(setPurchasedDocsDetails(servicerDocsDetails));
    dispatch(setOnboardingData(onboardingData));
    dispatch(setActiveServicerTab(activeServicerTab));
    dispatch(setPurchasedLoanData(onboardingLoanDetails));
  };
};

// export function setSelectedMappings(data: any): any {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_SELECTED_MAPPINGS,
//       payload: data
//     });
//   };
// }

export const getMandatoryDocsByServicerName = (
  servicerName: LoanServicerNames
): any => {
  return async (dispatch: any) => {
    dispatch(showLoader());
    // const url = `${apiUrlHost}/serviceronboarding/documents/FCI`;
    const url = `${apiUrlHost}/serviceronboarding/documents/${servicerName.toUpperCase()}`;
    try {
      let resp = (await publicClient.get(url)).data;
      let mandatoryDocs = resp.filter(
        (item: { mandatory: any }) => item.mandatory
      );
      dispatch(setServicerMandatoryDocs(mandatoryDocs));

      dispatch(hideLoader());
    } catch (e) {
      dispatch(hideLoader());
      console.error(e);
    }
  };
};

export function updateOriginatorServicerMappingDetails(
  reqBody: any,
  loanid: string,
  onboardingData: ObjectType
): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      // const reqBody = data;
      // const url = `${apiUrlHost}/serviceronboarding/servicermappingrequests/${data.originatorId}`;
      const firstName = Cookies.get("firstName");
      const lastName = Cookies.get("lastName");
      const ownerName = `${firstName} ${lastName}`;
      const url = `${apiUrlHost}/serviceronboarding/servicerupdate/${loanid}?owner=${ownerName}`;
      await publicClient.put(url, reqBody);
      const updatedTo =
        reqBody.dscrServicer === "default"
          ? reqBody.bridgeServicer
          : reqBody.dscrServicer;
      const onboardingResp = {
        ...onboardingData,
        ...{ servicerName: updatedTo }
      };

      dispatch(setOnboardingData(onboardingResp));
      //add this if end point returns mapping list
      // dispatch({
      //   type: UPDATED_SERVICER_MAPPING_DATA,
      //   payload: response.data.length > 0 ? response.data : []
      // });
      // const url1 = `${apiUrlHost}/serviceronboarding/servicermappingrequests`;
      // const response1 = await publicClient.get(url1);
      // dispatch({
      //   type: UPDATE_SERVICER_MAPPING_DATA,
      //   payload: response1.data.length > 0 ? response1.data : []
      // });
      // //remove till here
      // getServicerMappingListData();
      // dispatch({
      //   type: UPDATE_SERVICER_DETAILS,
      //   payload: reqBody
      // });

      dispatch(hideLoader());
    } catch (err) {
      dispatch(hideLoader());
      console.error(err);
    }
  };
}

// export const setActiveTabServicer = (activeServicerTab: string) => {
//   return {
//     type: SET_ACTIVE_SERVICER_TAB,
//     payload: { activeServicerTab }
//   };
// };

// export const updateOnBoardingStatusHistory = (data: ObjectType) => {
//   return {
//     type: SET_ONBOARDING_STATUS_HISTORY,
//     payload: data
//   };
// };

// export const updateOnBoardingData = (data: ObjectType) => {
//   return {
//     type: UPDATE_ONBOARDING_DATA_STATUS,
//     payload: data
//   };
// };

export function sendEmailToOriginator(reqBody: any, callback: any): any {
  return async (dispatch: any) => {
    try {
      const url = `${apiUrlHost}/serviceronboarding/onboardingrequests/missingDocs`;
      const response = await publicClient.post(url, reqBody);
      if (response) {
        callback(response);
      } else {
        dispatch(hideLoader());
      }
    } catch (err) {
      console.error(err);
      dispatch(hideLoader());
    }
  };
}

// export function updateLineAmountDetails(obj: any) {
//   return async (dispatch: any) => {
//     dispatch({
//       type: UPDATE_LINE_OBJ,
//       payload: obj
//     });
//   };
// }

// export function setLineFieldOptions(payload: any): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: SET_LINE_FIELD_OPTIONS,
//       payload
//     });
//   };
// }

export function exportGCSpath(gcsPath: string): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const url = `${apiUrlHost}/serviceronboarding/datatapes?gcsPath=${gcsPath}`;
      const response = await publicClient.get(url);
      if (response?.data?.length && typeof response.data?.[0] === "string") {
        downloadExportedTape(response.data);
        setTimeout(() => {
          dispatch(hideLoader());
        }, 300);
      } else {
        dispatch(hideLoader());
      }
    } catch (err) {
      console.error(err);
      dispatch(hideLoader());
    }
  };
}
export function getServicerData(
  filterObj: any,
  page: number,
  size: number,
  cb?: any,
  dontUpdateList?: boolean
): any {
  return async (dispatch: any) => {
    try {
      !dontUpdateList && dispatch(showLoader());
      const url = `${apiUrlHost}/serviceronboarding/onboardingrequestsForPagination?page=${page}&size=${size}`;
      // filterObj["offsetTime"] = new Date().getTimezoneOffset();
      let accountIds: any = [];
      if (!isRole(UserRole.LAT_TREASURER)) {
        let accounts = localStorage.getItem("account_ids");
        accountIds = accounts ? JSON.parse(accounts) : [];
      }
      filterObj["accountIds"] = accountIds;
      const response = (await publicClient.post(url, filterObj)).data;
      if (dontUpdateList) {
        cb && cb(response.onboardingRequests);
        return;
      }
      dispatch(
        updateOnboardingData(
          response.onboardingRequests?.length > 0
            ? response.onboardingRequests
            : []
        )
      );

      dispatch(
        updateServicerCount(
          response.onboardingRequests?.length > 0
            ? response.totalOnboardingRequests
            : 0
        )
      );
      setTimeout(() => {
        cb && cb(response.onboardingRequests);
        dispatch(hideLoader());
      }, 200);
    } catch (err) {
      dispatch(hideLoader());
      console.error(err);
    }
  };
}

export async function getFilterOption(key: string) {
  let accountIds: any = [];
  if (!isRole(UserRole.LAT_TREASURER)) {
    let accounts = localStorage.getItem("account_ids");
    accountIds = accounts ? JSON.parse(accounts) : [];
  }
  let reqBody = { accountIds };

  let url = `${apiUrlHost}/serviceronboarding/onboardingrequests/getValuesForColumn/${key}`;
  let resp = await publicClient.post(url, reqBody);
  return resp;
}

export function downloadDatatapeForBulkLoans(reqBody: any) {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const url = `${apiUrlHost}/serviceronboarding/onboardingrequests/downloaddatatapeforbulkloans`;
      const resp = await publicClient.post(url, reqBody);
      dispatch(hideLoader());
      if (resp?.data && typeof resp.data === "string") {
        downloadExportedTape(resp.data);
      }
    } catch (err) {
      console.error(err);
      dispatch(hideLoader());
    }
  };
}

export const postInternalDiscussion = (
  noteChat: any,
  onboardingRequestId: any,
  tempInternalNotes: any[]
): any => {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const username = getUsername();
      const url = `${apiUrlHost}/serviceronboarding/${onboardingRequestId}/internalnote?userName=${username}`;
      const response = (await publicClient.post(url, noteChat)).data;
      if (response) {
        tempInternalNotes.unshift(response);
        dispatch(updateInternalNotes(tempInternalNotes));
      }
      dispatch(hideLoader());
    } catch (err) {
      console.error(err);
      dispatch(hideLoader());
    }
  };
};

export const getInternalNotes = (onboardingRequestId: string): any => {
  return async (dispatch: any) => {
    try {
      const url = `${apiUrlHost}/serviceronboarding/internalnote?onboardingRequestId=${onboardingRequestId}`;
      const response = (await publicClient.get(url)).data;
      let responseRev = [...response].reverse();
      if (response) {
        dispatch(updateInternalNotes(responseRev));
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export function generateDataTape(
  loanId: string,
  loanStage: string,
  obj: any
): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const url = `${apiUrlHost}/serviceronboarding/onboardingrequests/downloaddatatape/${loanId}/${loanStage}`;
      const response = await publicClient.post(url, obj);
      if (response?.data && typeof response.data === "string") {
        downloadExportedTape(response.data);
        setTimeout(() => {
          dispatch(hideLoader());
        }, 300);
      } else {
        dispatch(hideLoader());
      }
    } catch (err) {
      console.error(err);
      dispatch(hideLoader());
    }
  };
}

export function exportOnboardingDetailsForSingleLoan(id: number) {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const url = `${apiUrlHost}/serviceronboarding/export/onboardingrequests/${id}`;
      const resp = await publicClient.post(url);
      dispatch(hideLoader());
      if (resp?.data && typeof resp?.data?.[0] === "string") {
        downloadExportedTape(resp.data);
      }
    } catch (err) {
      console.error(err);
      dispatch(hideLoader());
    }
  };
}

export function exportOnboardingDetailsForBulkLoans(reqBody: any) {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const url = `${apiUrlHost}/serviceronboarding/export/onboardingrequestsForBulkLoans`;
      const resp = (await publicClient.post(url, reqBody))?.data;
      dispatch(hideLoader());
      if (resp && typeof resp?.[0] === "string") {
        downloadExportedTape(resp);
      }
    } catch (err) {
      console.error(err);
      dispatch(hideLoader());
    }
  };
}
