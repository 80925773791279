/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useState, ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Typography, Grid, Theme, Button, Dialog } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
// import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import {
  CreateLoanParamsThirtyYearLoan,
  PropertyInformation
} from "@toorak/tc-common-fe-sdk";
import { useParams } from "react-router-dom";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import CloseIcon from "@mui/icons-material/Close";
import {
  previousLoanState,
  updatePropertyDetails,
  // valueEditing,
  validateForm as validateFormCheck,
  postPropertyData,
  updateEditComments,
  // POST_PROPERTY_SUCCESS,
  // POST_PROPERTY_FAILED,
  editCommentMissing,
  keepCreditEvalSync,
  updatePreviousLoanStateAction
} from "../../create-loan.action";
import { RootState } from "../../../stores/rootReducer";
import iconMinimize from "../../../images/icon_minimize.svg";
import iconMaximize from "../../../images/icon_maximize.svg";
import editImage from "../../../images/Edit.svg";
import { getLoanType } from "../../../config/config";
import {
  PropertyTypeDropDown,
  NoOfUnitsTextField,
  PropertyValuationDateTextField,
  PercentageOccupiedField,
  SquareFootageTextField,
  PropertyAcquisitionDateTextField
} from "../../form-components/PropertyInformation";
import { getEvaluationRequestBody } from "../../evaluation-results/helpers/ruleRequestBody";
import {
  evaluateLoan,
  getOverriddenOutputs,
  updateOverriddenInredux
} from "../../../stores/EvaluationResults/EvaluationResults.action";
import { isLATUser, isOrigUser } from "../../../utils/AccessManagement";
import { getCookie } from "../../../utils/cookies";
import {
  checkFieldValidity,
  errorDateText,
  getErrorMessage,
  isEmptyValue,
  enableReasonField,
  formatNumberToCommaSeparated
} from "../../../utils/formatChecks";
import { thirtyYearPropertyfields } from "../../constants/loanFieldType";
import { getValueFromResults } from "../../evaluation-results/EvaluationHelper";
import { computePropertyError } from "../PropertyDetailsHelper";
import {
  hideLoader,
  showLoader
} from "../../../stores/loaderAndException/loaderAndException.action";
import { thirtyYearPropertyTypeDropDown } from "../../constants/loanCreationDropDownValues";
import { getAffectedWaivers } from "../../../stores/WaiverRequest/WaiverRequest.action";
import { requestWaiverMessage } from "../../../utils/constants";
import { MMMDDYYYYDateFormat } from "../../../utils/utils";
import {
  loanTypeFailed,
  updatePropertySuccess,
  valueEditing,
  setAutoSaveCall
} from "../../create-loan.reducer";

let changedValueFields: any = [];

let originalPropertyInfo: any;
export let commentObjProperty: any = [];
export let globalSaveThirtyYrOnFieldChangeProperty: any;
export let globalAutoSaveOnPropertyFieldChange: any;

export function prepareCommentObjectProperty(
  field: string,
  value: any,
  comment: string,
  clearComments?: boolean,
  setCommentObj?: any,
  enableReasonField?: boolean,
  reasonCommentObj?: any,
  fieldLabel?: string,
  valueChange?: boolean,
  sectionName?: string | undefined
) {
  if (clearComments) {
    commentObjProperty = [];
    return;
  }
  // if (!isLATUser()) {
  //   comment = "";
  // }
  for (let i = 0; i < commentObjProperty.length; i++) {
    if (commentObjProperty[i].field === field) {
      if (valueChange) {
        commentObjProperty[i].value = value;
        return;
      }
      if (comment.trim() === "") {
        commentObjProperty.splice(i, 1);
        return;
      }

      commentObjProperty[i].comment = comment;
      commentObjProperty[i].value = value;
      return;
    }
  }
  if (comment.trim() === "" && !valueChange) {
    return;
  }
  const fieldCommentObj = {
    field,
    value,
    comment,
    fieldLabel,
    updatedBy: getCookie("person_id")
  };

  const currentSectionName = sectionName || "Property Information";
  const tempCurrentCommentObj = commentObjProperty?.[currentSectionName] || [];
  /*
  arrayWithDuplicates.filter((item, index, self) => 
    index === self.findIndex(t => 
        JSON.stringify(t) === JSON.stringify(item)
    )
);
  
  */
  const tempCurrentObj = [...tempCurrentCommentObj, fieldCommentObj]?.filter((item: any, index: number, self: any) => index === self.findIndex((t: any) =>
    JSON.stringify(t) === JSON.stringify(item)
  ));
  commentObjProperty[currentSectionName] = [...tempCurrentObj];

  if (enableReasonField && !isOrigUser()) {
    setCommentObj({ ...reasonCommentObj, [currentSectionName]: [...tempCurrentObj] });
  }
};
const sanitizeNumericInput = (text: string) => {
  return text.replace(/\D/g, "");
};

const isValidPercentage = (text: string) => {
  return /^\d+(\.\d+)?%{0,1}$/.test(text);
};
const cleanPercentage = (text: string) => {
  return (
    text
      .replace(/[^0-9.%]/g, "")
      // eslint-disable-next-line no-plusplus, no-param-reassign
      .replace(
        /\./g,
        (
          (i) => (m: string) =>
            !i++ ? m : ""
        )(0)
      )
      // eslint-disable-next-line no-plusplus, no-param-reassign
      .replace(
        /[%]/g,
        (
          (i) => (m: string) =>
            !i++ ? m : ""
        )(0)
      )
      .replace(/^\./, "")
      .replace(/^$%/, "")
      .replace(/\.(\d\d\d\d)\d?$/, ".$1")
  );
};

const sanitizePercentage = (text: string) => {
  const result = text.replace(/\.$/, "").replace(/%/, "");
  return `${result}%`;
};

const propertyTypeValues = thirtyYearPropertyTypeDropDown.map((item) => ({
  value: item,
  label: item === "SFR" ? "Single Family Detached" : item
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      input: {
        backgroundColor: "#ffffff !important"
      }
    },
    container: {
      borderRadius: 4,
      display: "flex",
      flexDirection: "column",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      backgroundColor: "#ffffff",
      marginTop: theme.spacing(3)
      // fontFamily: "OpenSans"
    },
    sectionWrapper: {
      padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
      display: "flex"
    },
    headingTypography: {
      textAlign: "left",
      fontSize: "14px !important",
      fontWeight: 600,
      color: "#32325d",
      lineHeight: 1.57
    },
    headingImageContainer: {
      justifyContent: "flex-end",
      display: "flex"
    },
    headingImage: {
      width: 16,
      height: 16,
      objectFit: "contain",
      cursor: "pointer"
    },
    divider: {
      height: "1px",
      border: "solid 1px #f2f2f2",
      padding: "0px !important"
    },
    formSelectContainer: {
      justifyContent: "space-around",
      display: "flex",
      flexDirection: "row",
      width: "95%",
      padding: "8px 0px 0px 0px"
    },
    iconInfo: {
      width: "16px !important",
      height: "16px !important",
      color: "#32355d",
      "&:hover": {
        color: "#5e72e4"
      }
    },
    editImage: {
      width: 16,
      height: 16,
      marginRight: 16,
      objectFit: "contain",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    modalCancelButton: {
      color: "#32325d",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      backgroundColor: "#ffffff",
      marginTop: 18,
      borderWidth: 1,
      borderColor: "#ececec"
    },
    modalDeleteButtonConatainer: {
      marginTop: 50
    },
    editImageDisplay: {
      display: "none"
    },
    savebutton: {
      width: 70,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4"
    },
    cancelButton: {
      width: 69,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      marginLeft: 10,
      color: "#32325d",
      backgroundColor: "#fff",
      "&:hover": {
        background: "none"
      }
    },
    labelInformation: {
      fontSize: 12,
      fontWeight: 600,
      color: "#8897aa",
      height: "14px",
      "&:hover": {
        color: "#5e72e4"
      }
    },
    modalButton: {
      marginRight: 12,
      color: "#ffffff",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      marginTop: 18,
      borderWidth: 0
    }
  })
);

export interface PropertyDetailsPropertyInformationProps {
  propertyInformation?: PropertyInformation | null;
  propertyId: string;
  isEvaluationPanelVisible: boolean;
  loanType?: string;
  isAllowedToEdit: boolean;
  data?: any;
  index?: number;
  isMandatoryServicerField?: any;
  isEditable?: any;
  setCommentObj?: any;
  reasonCommentObj?: any;
}

export function ThirtyYearLoanPropertyInformation(
  props: PropertyDetailsPropertyInformationProps
) {
  const {
    propertyInformation,
    propertyId,
    isEvaluationPanelVisible,
    isAllowedToEdit,
    data,
    index,
    isMandatoryServicerField,
    isEditable,
    setCommentObj,
    reasonCommentObj
  } = props;
  const colSpan = 4;
  const classes = useStyles();
  const dispatch = useDispatch();

  const validateForm = useSelector<RootState, { validate: boolean }>(
    (state) => state.createLoanStore.validateForm
  );
  const loanBundleDetails = useSelector<RootState, any>(
    state => state.spStore.loanBundleDetails
  );
  const [errors, setErrors] = useState(0);
  const [isMinimized, setMinimized] = useState(false);
  const [selectedPropertyTypeError, setSelectedPropertyTypeError] =
    useState("");
  const [noOfUnitsError, setNoOfUnitsError] = useState("");
  const [propertyEvaluationDateError, setPropertyEvaluationDateError] =
    useState("");
  const [propertyAcquisitionDateError, setPropertyAcquisitionDateError] =
    useState("");
  const { takeoutPartnerId } = useSelector<RootState, any>(
    (state) =>
      state.createLoanStore?.loanDetails.thirtyYearLoanInformation.payload
  );
  const [squareFootageError, setSquareFootageError] = useState("");
  const [percentageOccupiedError, setPercentageOccupiedError] = useState("");
  // const [isEditable, setEditable] = useState(false);
  const [propertyTypeEdited, setPropertyTypeEdited] = useState(false);
  const [numberOfUnitsEdited, setNumberOfUnitsEdited] = useState(false);
  const [acquistionDateEdited, setAcquistionDateEdited] = useState(false);
  const [valuetionDateEdited, setValuetionDateEdited] = useState(false);
  const [squareFootageEdited, setSquareFootageEdited] = useState(false);
  // const [postRehabEdited, setPostRehabEdited] = useState(false);
  // const [changeUseCaseEdited, setChangeUseCaseEdited] = useState(false);
  const [percentChangedEdited, setPercentChangedEdited] = useState(false);
  const [editableFlags, setEditableFlags] = useState<any>({});

  const {
    // bridgeLoanGuarantorInformation,
    propertyDetails,
    loanState,
    loanRuleVersions
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useSelector<RootState, any>((state) => state.createLoanStore.loanDetails);
  const activePropertyId = useSelector<RootState, any>(
    (state) => state.createLoanStore.loanDetails.activePropertyId
  );
  const { loanId, loanStage, loanType } = useParams<any>() as any;
  const [resetData, setResetData] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const [callAutoSave, setCallAutoSave] = useState<{
    value: boolean;
    impactingFieldChange: boolean;
  }>({ value: false, impactingFieldChange: false });

  const overriddenValues = useSelector<RootState, any>(
    (state) => state.evaluationResultsStore.overriddenValues
  );
  const { waiversCreated } = useSelector<RootState, any>(
    (state) => state.evaluationResultsStore
  );

  useEffect(() => {
    if (callAutoSave.value) {
      postPropertyAutoSave(loanId, propertyDetails);
      setCallAutoSave({ value: false, impactingFieldChange: false });
    }
  }, [callAutoSave]);

  useEffect(() => {
    if (activePropertyId && resetData) {
      onCancel();
      updatePreviousLoanState();
      dispatch(
        previousLoanState(originalPropertyInfo, "propertyInformation", true)
      );
      setResetData(!resetData);
    }
  }, [activePropertyId]);

  useEffect(() => {
    if (isEditable) {
      const tempEditableFlags: any = { ...editableFlags };
      for (const i in tempEditableFlags) tempEditableFlags[i] = false;
      updatePreviousLoanState();
      dispatch(valueEditing(true));
      setEditableFlags({ ...tempEditableFlags, [`editProp${activePropertyId}`]: true });
    } else {
      setEditableFlags({ ...editableFlags, [`editProp${activePropertyId}`]: false });
      dispatch(valueEditing(false));
    }
  }, [isEditable, activePropertyId]);

  const onCancel = () => {
    const tempEditableFlags: any = { ...editableFlags };
    for (const i in tempEditableFlags) tempEditableFlags[i] = false;
    dispatch(valueEditing(false));
    // setEditable(!isEditable);
    setResetData(!resetData);
    changedValueFields = [];
    setAcquistionDateEdited(false);
    setSquareFootageEdited(false);
    setValuetionDateEdited(false);
    setPercentChangedEdited(false);
    setPropertyTypeEdited(false);
    setNumberOfUnitsEdited(false);
    setEditableFlags({ ...tempEditableFlags });
    prepareCommentObjectProperty("", "", "", true);
  };

  const updatePreviousLoanState = () => {
    originalPropertyInfo = updatePreviousLoanStateAction(
      propertyDetails,
      activePropertyId,
      "propertyInformation"
    );
  };

  const postPropertyAutoSave = (
    impactingFieldChanged: boolean = false,
    extraData?: any
  ) => {
    if (!isEvaluationPanelVisible) {
      dispatch(setAutoSaveCall(true));
      // const extraneousData = extraData || {};
      const promise2 = postPropertyData(loanId, loanStage, propertyDetails);
      promise2
        .then((response: CreateLoanParamsThirtyYearLoan) => {
          // dispatch({
          //   type: POST_PROPERTY_SUCCESS,
          //   payload: {
          //     reservation: response
          //   }
          // });
          dispatch(updatePropertySuccess());
          if (impactingFieldChanged) {
            getEvaluationRequestBody(
              loanId,
              loanStage,
              data,
              loanEvaluationResult,
              propertyDetails,
              getLoanType[1].displayValue,
              loanBundleDetails?.loanbundleData?.bundleDetails
            )
              .then(({ evaluate, loanEvaluationRequest }) => {
                if (evaluate && loanEvaluationRequest) {
                  dispatch(
                    evaluateLoan(loanEvaluationRequest, loanId, loanStage)
                  );
                } else {
                  dispatch(setAutoSaveCall(false));
                }
              })
              .catch((e) => {
                dispatch(setAutoSaveCall(false));
                console.log("error while sending evaluation request", e);
              });
          } else {
            dispatch(setAutoSaveCall(false));
          }
        })
        .catch((error) => {
          console.log("err", error);
          // dispatch({ type: POST_PROPERTY_FAILED, error });
          dispatch(setAutoSaveCall(false));
          dispatch(
            loanTypeFailed({
              error
            })
          );
        });
    }
  };
  globalAutoSaveOnPropertyFieldChange = postPropertyAutoSave;

  const handleClose = () => {
    setOpen(false);
  };
  const editHistory = useSelector<RootState, any>(
    (state) => state.createLoanStore.editHistory
  );
  let editHistory2: any;
  editHistory2 = editHistory;
  let infoContent = useSelector<RootState, any>(
    (state) => state.createLoanStore.infoContentProperty
  );
  if (infoContent) {
    infoContent = JSON.parse(JSON.stringify(infoContent));
  }
  useEffect(() => {
    if (resetData && originalPropertyInfo) {
      dispatch(
        previousLoanState(originalPropertyInfo, "propertyInformation", true)
      );
      setResetData(!resetData);
    }
  });
  const loanEvaluationResult = useSelector<
    RootState,
    { loanResult: any; propertiesResults: any }
  >((state) => state.evaluationResultsStore?.loanEvaluationResult);

  useEffect(() => {
    if (loanState === LoanStatusEnum.DueDiligenceReview && isEditable) {
      const tempEditableFlags: any = { ...editableFlags };
      for (const i in editableFlags) tempEditableFlags[i] = false;
      dispatch(valueEditing(false));
      // setEditable(!isEditable);
      setResetData(!resetData);
      changedValueFields = [];
      setAcquistionDateEdited(false);
      // setPostRehabEdited(false);
      setSquareFootageEdited(false);
      setPercentChangedEdited(false);
      setValuetionDateEdited(false);
      // setChangeUseCaseEdited(false);
      setPropertyTypeEdited(false);
      setNumberOfUnitsEdited(false);
      setEditableFlags({ ...tempEditableFlags })
    }
  }, [loanState]);

  useEffect(() => {
    if (validateForm.validate) {
      if (isEmptyValue(propertyInformation?.propertyType)) {
        setSelectedPropertyTypeError("This field is required");
      } else if (
        !checkFieldValidity(
          propertyInformation?.propertyType,
          thirtyYearPropertyfields.propertyType
        )
      ) {
        setSelectedPropertyTypeError(
          getErrorMessage(thirtyYearPropertyfields.propertyType?.fieldTpe)
        );
      } else {
        setSelectedPropertyTypeError("");
      }
      if (isEmptyValue(propertyInformation?.propertyValuationDate)) {
        setPropertyEvaluationDateError("This field is required");
      } else if (
        !checkFieldValidity(
          propertyInformation?.propertyValuationDate,
          thirtyYearPropertyfields.propertyValuationDate
        )
      ) {
        setPropertyEvaluationDateError(
          errorDateText(propertyInformation?.propertyValuationDate)
        );
      } else {
        setPropertyEvaluationDateError("");
      }
      if (isEmptyValue(propertyInformation?.propertyAcquisitionDate)) {
        setPropertyAcquisitionDateError("This field is required");
      } else if (
        !checkFieldValidity(
          propertyInformation?.propertyAcquisitionDate,
          thirtyYearPropertyfields.propertyAcquisitionDate
        )
      ) {
        setPropertyAcquisitionDateError(
          errorDateText(propertyInformation?.propertyAcquisitionDate)
        );
      } else {
        setPropertyAcquisitionDateError("");
      }
      if (isEmptyValue(propertyInformation!.numberOfUnits)) {
        setNoOfUnitsError("This field is required");
      } else if (
        !checkFieldValidity(
          propertyInformation?.numberOfUnits,
          thirtyYearPropertyfields.numberOfUnits
        )
      ) {
        setNoOfUnitsError(
          getErrorMessage(thirtyYearPropertyfields.numberOfUnits?.fieldTpe)
        );
      } else {
        setNoOfUnitsError("");
      }
      if (isEmptyValue(propertyInformation?.squareFootage)) {
        setSquareFootageError("This field is required");
      } else if (
        !checkFieldValidity(
          propertyInformation?.squareFootage,
          thirtyYearPropertyfields.squareFootage
        )
      ) {
        setSquareFootageError(
          getErrorMessage(thirtyYearPropertyfields.squareFootage?.fieldTpe)
        );
      } else {
        setSquareFootageError("");
      }
      if (
        !percentageOccupiedError &&
        isEmptyValue(propertyInformation?.percentageOccupied)
      ) {
        setPercentageOccupiedError("This field is required");
      } else if (
        !checkFieldValidity(
          propertyInformation?.percentageOccupied,
          thirtyYearPropertyfields.percentageOccupied
        )
      ) {
        setPercentageOccupiedError(
          getErrorMessage(thirtyYearPropertyfields.percentageOccupied?.fieldTpe)
        );
      } else if (
        !percentageOccupiedError &&
        propertyInformation?.percentageOccupied
      ) {
        setPercentageOccupiedError("");
      }
    }
  }, [validateForm, propertyId, propertyInformation]);

  useEffect(() => {
    const errorCount = [
      selectedPropertyTypeError,
      noOfUnitsError,
      propertyEvaluationDateError,
      squareFootageError
      // percentageOccupiedError
    ].filter((item) => item).length;

    setErrors(errorCount);
    if (validateForm.validate) {
      dispatch(
        updatePropertyDetails(propertyId, {
          propertyInformation: {
            errors: errorCount
          }
        })
      );
    }
  }, [
    selectedPropertyTypeError,
    noOfUnitsError,
    propertyEvaluationDateError,
    squareFootageError,
    percentageOccupiedError,
    dispatch
  ]);

  // useEffect(() => {
  //   if (validateForm.validate) {
  //     dispatch(
  //       updatePropertyDetails(propertyId, {
  //         propertyInformation: {
  //           errors
  //         }
  //       })
  //     );
  //   }
  // }, [validateForm]);

  const propertyTypeFocusOut = (event: ChangeEvent<{ value: unknown }>) => {
    const value =
      propertyInformation?.propertyType === "SFR"
        ? "Single Family Detached"
        : propertyInformation?.propertyType;
    prepareCommentObjectProperty(
      `data.properties[${activePropertyId}].propertyinfo.propertyType`,
      value as string,
      event.target.value as string
    );
  };

  // Handle Changes
  const handlePropertyTypeChange = (event: ChangeEvent<{ value: unknown }>) => {
    if (isEvaluationPanelVisible) {
      // if (!isLATUser()) {
      const value =
        event.target.value === "SFR"
          ? "Single Family Detached"
          : event.target.value;
      prepareCommentObjectProperty(
        `data.properties[${activePropertyId}].propertyinfo.propertyType`,
        value,
        "",
        false,
        setCommentObj,
        true,
        reasonCommentObj,
        "Property Type",
        true
      );
      // }
      if (!JSON.stringify(changedValueFields).includes("propertyType")) {
        setEditableFlags({ ...editableFlags, [`propertyTypeEdited${activePropertyId}`]: true });
        setPropertyTypeEdited(!propertyTypeEdited);
        changedValueFields.push("propertyType");
      }
    }
    dispatch(
      updatePropertyDetails(propertyId, {
        propertyInformation: {
          payload: { propertyType: event.target.value as string }
        }
      })
    );
    setCallAutoSave({ value: true, impactingFieldChange: true });

    setSelectedPropertyTypeError("");
  };

  const valuationDateFocusOut = (event: ChangeEvent<{ value: unknown }>) => {
    prepareCommentObjectProperty(
      `data.properties[${activePropertyId}].propertyinfo.recentPropertyValuationDate`,
      MMMDDYYYYDateFormat(propertyInformation?.propertyValuationDate),
      event.target.value as string
    );
  };

  const handlePropertyEvaluationDateChange = (date: any) => {
    if (date) {
      if (isEvaluationPanelVisible) {
        // if (!isLATUser()) {
        prepareCommentObjectProperty(
          `data.properties[${activePropertyId}].propertyinfo.recentPropertyValuationDate`,
          MMMDDYYYYDateFormat(date),
          "",
          false,
          setCommentObj,
          true,
          reasonCommentObj,
          "Recent Property Valuation Date",
          true
        );
        // }
        if (
          !JSON.stringify(changedValueFields).includes(
            "mostRecentPropertyValuationDate"
          )
        ) {
          setValuetionDateEdited(!valuetionDateEdited);
          setEditableFlags({ ...editableFlags, [`evaluationDateEdited${activePropertyId}`]: true });
          changedValueFields.push("mostRecentPropertyValuationDate");
        }
      }
      dispatch(
        updatePropertyDetails(propertyId, {
          propertyInformation: {
            payload: { propertyValuationDate: date }
          }
        })
      );
      setCallAutoSave({ value: true, impactingFieldChange: true });

      setPropertyEvaluationDateError("");
    }
  };

  const acquistionDateFocusOut = (event: ChangeEvent<{ value: unknown }>) => {
    prepareCommentObjectProperty(
      `data.properties[${activePropertyId}].propertyinfo.propertyAcquisitionDate`,
      MMMDDYYYYDateFormat(propertyInformation?.propertyAcquisitionDate),
      event.target.value as string
    );
  };

  const handlePropertyAcquisitionDateChange = (date: any) => {
    if (date) {
      if (isEvaluationPanelVisible) {
        // if (!isLATUser()) {
        prepareCommentObjectProperty(
          `data.properties[${activePropertyId}].propertyinfo.propertyAcquisitionDate`,
          MMMDDYYYYDateFormat(date),
          "",
          false,
          setCommentObj,
          true,
          reasonCommentObj,
          "Property Acquisition Date",
          true
        );
        // }
        if (
          !JSON.stringify(changedValueFields).includes(
            "propertyAcquisitionDate"
          )
        ) {
          setAcquistionDateEdited(!acquistionDateEdited);
          setEditableFlags({ ...editableFlags, [`acquistionDateEdited${activePropertyId}`]: true });
          changedValueFields.push("propertyAcquisitionDate");
        }
      }
      dispatch(
        updatePropertyDetails(propertyId, {
          propertyInformation: {
            payload: { propertyAcquisitionDate: date }
          }
        })
      );
      setCallAutoSave({ value: true, impactingFieldChange: true });

      setPropertyAcquisitionDateError("");
    }
  };
  const onSquareFootageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const sanitizedNumericInput =
      formatNumberToCommaSeparated(sanitizeNumericInput(event.target.value)) ||
      "";
    if (isEvaluationPanelVisible) {
      // if (!isLATUser()) {
      prepareCommentObjectProperty(
        `data.properties[${activePropertyId}].propertyinfo.squareFootage`,
        sanitizedNumericInput,
        "",
        false,
        setCommentObj,
        true,
        reasonCommentObj,
        "Pre-Rehab Square Footage",
        true
      );
      // }
      if (
        !JSON.stringify(changedValueFields).includes("preRehabSquareFootage")
      ) {
        setSquareFootageEdited(!squareFootageEdited);
        setEditableFlags({ ...editableFlags, [`squareFootageEdited${activePropertyId}`]: true });
        changedValueFields.push("preRehabSquareFootage");
      }
    }

    dispatch(
      updatePropertyDetails(propertyId, {
        propertyInformation: {
          payload: {
            squareFootage: sanitizedNumericInput
          }
        }
      })
    );
    if (!sanitizedNumericInput) {
      setSquareFootageError("This field is required");
    } else {
      setSquareFootageError("");
    }
  };

  const handleBlur =
    (fieldName: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const propertyInfoCopy: any = propertyInformation;
      if (
        event?.target.parentElement?.parentElement?.innerText.includes(
          "Reason For Editing"
        )
      ) {
        let commentFieldName: string = fieldName;
        switch (fieldName) {
          case "squareFootage":
            commentFieldName = "squareFootage";
            break;
          case "numberOfUnits":
            commentFieldName = "propertyUnits";
            break;
        }
        prepareCommentObjectProperty(
          `data.properties[${activePropertyId}].propertyinfo.${commentFieldName}`,
          propertyInfoCopy[fieldName],
          event.target.value
        );
      }
      setCallAutoSave({ value: true, impactingFieldChange: true });
    };

  const onNoOfUnitsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const sanitizedNumericInput =
      formatNumberToCommaSeparated(sanitizeNumericInput(event.target.value)) ||
      "";
    if (isEvaluationPanelVisible) {
      // if (!isLATUser()) {
      prepareCommentObjectProperty(
        `data.properties[${activePropertyId}].propertyinfo.propertyUnits`,
        sanitizedNumericInput,
        "",
        false,
        setCommentObj,
        true,
        reasonCommentObj,
        "Number Of Units",
        true
      );
      // }
      if (!JSON.stringify(changedValueFields).includes("numberOfUnits")) {
        setNumberOfUnitsEdited(!numberOfUnitsEdited);
        setEditableFlags({ ...editableFlags, [`numberOfUnitsEdited${activePropertyId}`]: true });
        changedValueFields.push("numberOfUnits");
      }
    }
    dispatch(
      updatePropertyDetails(propertyId, {
        propertyInformation: {
          payload: { numberOfUnits: sanitizedNumericInput }
        }
      })
    );
    if (!sanitizedNumericInput) {
      setNoOfUnitsError("This field is required");
    } else {
      setNoOfUnitsError("");
    }
  };
  const onPercentageOccupiedChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (isEvaluationPanelVisible) {
      // if (!isLATUser()) {
      prepareCommentObjectProperty(
        `data.properties[${activePropertyId}].propertyinfo.occupiedPercentage`,
        cleanPercentage(event.target.value),
        "",
        false,
        setCommentObj,
        true,
        reasonCommentObj,
        "Percentage Occupied",
        true
      );
      // }
      if (!JSON.stringify(changedValueFields).includes("percentageOccupied")) {
        setPercentChangedEdited(!percentChangedEdited);
        setEditableFlags({ ...editableFlags, [`percentChangedEdited${activePropertyId}`]: true });
        changedValueFields.push("percentageOccupied");
      }
    }
    dispatch(
      updatePropertyDetails(propertyId, {
        propertyInformation: {
          payload: {
            percentageOccupied: cleanPercentage(event.target.value)
          }
        }
      })
    );
    if (!isValidPercentage(event.target.value)) {
      setPercentageOccupiedError("Please enter value in decimal");
    } else {
      setPercentageOccupiedError("");
    }
  };
  const onPercentageOccupiedFocusOut = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const propertyInfoCopy: any = propertyInformation;
    if (
      event?.target.parentElement?.parentElement?.innerText.includes(
        "Reason For Editing"
      )
    ) {
      prepareCommentObjectProperty(
        `data.properties[${activePropertyId}].propertyinfo.occupiedPercentage`,
        propertyInfoCopy.percentageOccupied,
        event.target.value
      );
      return;
    }
    if (!isValidPercentage(sanitizePercentage(event.target.value))) {
      setPercentageOccupiedError("Please enter value in decimal");
    } else {
      dispatch(
        updatePropertyDetails(propertyId, {
          propertyInformation: {
            payload: {
              percentageOccupied: sanitizePercentage(event.target.value)
            }
          }
        })
      );
      setCallAutoSave({ value: true, impactingFieldChange: true });
      setPercentageOccupiedError("");
    }
  };

  const saveOnFieldChange = async (changedFieldInput: any) => {
    dispatch(showLoader());
    dispatch(validateFormCheck(true));
    if (
      isLATUser() ||
      (!isLATUser() && loanState === LoanStatusEnum.DueDiligenceReview)
    ) {
      let commentMissing = false;
      // eslint-disable-next-line
      commentObjProperty.map((item: any) => {
        if (item.comment === "") {
          commentMissing = true;
        }
      });
      if (
        commentMissing ||
        commentObjProperty.length < changedFieldInput.length
      ) {
        dispatch(editCommentMissing(true));
        dispatch(hideLoader());
        return Promise.resolve("false");
      }
    }
    let activeProperty = propertyDetails.find((property: any) => {
      return property.propertyId === activePropertyId;
    });
    const loanDetailsErrorCount =
      computePropertyError(
        { ...activeProperty, loanRuleVersions },
        loanType,
        loanStage,
        false
      ) || 0;
    if (errors === 0 && loanDetailsErrorCount === 0) {
      let overriddenValuesToUse = overriddenValues;
      const { reRequestWaiverObject, openWaivers } = await getAffectedWaivers(
        changedFieldInput,
        waiversCreated,
        loanId,
        loanStage,
        loanType
      );
      const refreshWaiver =
        (typeof reRequestWaiverObject === "object" &&
          Object.keys(reRequestWaiverObject)?.length) ||
        openWaivers.length;
      // skipping when no partner and loan is of dscr
      // https://toorakcapital.atlassian.net/browse/TA-4313
      if (
        refreshWaiver &&
        (loanType === getLoanType[0].displayValue ||
          (loanType === getLoanType[1].displayValue && takeoutPartnerId))
      ) {
        const resp = await getOverriddenOutputs(
          loanId,
          loanStage,
          takeoutPartnerId
        );
        overriddenValuesToUse = resp?.data;
        dispatch(updateOverriddenInredux(resp));
      }
      postPropertyData(
        loanId,
        loanStage,
        propertyDetails,
        null,
        false,
        true
      ).then((res: any) => {
        dispatch(keepCreditEvalSync(true));
      });
      getEvaluationRequestBody(
        loanId,
        loanStage,
        data,
        loanEvaluationResult,
        propertyDetails,
        getLoanType[1].displayValue,
        loanBundleDetails?.loanbundleData?.bundleDetails
      ).then(({ evaluate, loanEvaluationRequest }) => {
        if (evaluate && loanEvaluationRequest) {
          // loanEvaluationRequest.loanFact.loanRuleResult.toorakProceedsTest.totalMarketDataDeductions = 1.2;
          loanEvaluationRequest.ruleAttributs = {
            ruleGroup: [],
            ruleField: [],
            ruleResult: []
          };
          loanEvaluationRequest.resultObject = overriddenValuesToUse;
          dispatch(
            evaluateLoan(
              loanEvaluationRequest,
              loanId,
              loanStage,
              true,
              false,
              propertyDetails,
              refreshWaiver
            )
          );
          dispatch(
            updateEditComments(`${loanId}_${loanStage}`, commentObjProperty)
          );
          commentObjProperty = [];
        } else {
        }
      });
      dispatch(hideLoader());
      return Promise.resolve("true");
    }
    dispatch(hideLoader());
    return Promise.resolve("false");
  };
  globalSaveThirtyYrOnFieldChangeProperty = saveOnFieldChange;
  const handleServiceFlowRender = (field: string): boolean => {
    return (
      !isMandatoryServicerField ||
      (isMandatoryServicerField && isMandatoryServicerField(field))
    );
  };
  return (
    <div className={classes.container}>
      {/* Minimize Section start */}
      <Grid container spacing={3} className={classes.sectionWrapper}>
        <Grid item xs={12} container>
          <Grid item xs={6} sm={11}>
            <Typography className={classes.headingTypography}>
              Property {index} Information
            </Typography>
          </Grid>
          <Grid item xs={6} sm={1} className={classes.headingImageContainer}>
            {/* {isAllowedToEdit && (
              <img
                className={
                  editableFlags[`editProp${activePropertyId}`] ||
                  !isEvaluationPanelVisible
                    ? classes.editImageDisplay
                    : classes.editImage
                }
                src={editImage}
                alt="Edit Section"
                onClick={() => {
                  for (const i in editableFlags) editableFlags[i] = false;
                  updatePreviousLoanState();
                  dispatch(valueEditing(true));
                  // setEditable(!isEditable);
                  editableFlags[`editProp${activePropertyId}`] = true;
                }}
                onKeyDown={() => {
                  for (const i in editableFlags) editableFlags[i] = false;
                  dispatch(valueEditing(true));
                  // setEditable(!isEditable);
                  updatePreviousLoanState();
                  editableFlags[`editProp${activePropertyId}`] = true;
                }}
              />
            )} */}
            <img
              className={classes.headingImage}
              src={isMinimized ? iconMaximize : iconMinimize}
              alt={isMinimized ? "Maximize section" : "Minimize section"}
              onClick={() => {
                setMinimized(!isMinimized);
              }}
              onKeyDown={() => {
                setMinimized(!isMinimized);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* Minimize Section end */}
      {/* Divider Section start */}
      <Grid
        item
        xs={12}
        sm={12}
        style={{
          display: isMinimized ? "none" : ""
        }}
      >
        <div className={classes.divider} />
      </Grid>
      {/* Divider Section end */}{" "}
      <Grid
        container
        spacing={4}
        style={{
          padding: 24,
          paddingTop: isMandatoryServicerField
            ? !handleServiceFlowRender("recentPropertyValuationDate")
              ? 0
              : 24
            : 24,
          marginTop: isMandatoryServicerField
            ? !handleServiceFlowRender("recentPropertyValuationDate")
              ? "-26px"
              : "-16px"
            : "-16px",
          display: isMinimized ? "none" : ""
        }}
      >
        {handleServiceFlowRender("propertyType") && (
          <Grid item xs={12} sm={6} md={colSpan} xl={colSpan}>
            <PropertyTypeDropDown
              selectionValue={propertyInformation!.propertyType}
              selectionOnChange={handlePropertyTypeChange}
              selectionData={propertyTypeValues}
              helperText={
                validateForm.validate ? selectedPropertyTypeError : ""
              }
              isViewMode={
                isEvaluationPanelVisible &&
                !editableFlags[`editProp${activePropertyId}`]
              }
              isLATView={isLATUser()}
              onBlur={propertyTypeFocusOut}
              editedLabel={
                false
              }
              editedData={
                editHistory2[
                `data.properties[${activePropertyId}].propertyinfo.propertyType`
                ]
              }
              isServicerMandatory={
                isMandatoryServicerField &&
                isMandatoryServicerField("propertyType")
              }
              enableReason={
                false
                //   enableReasonField(
                //   changedValueFields,
                //   "propertyType",
                //   loanState
                // )
              }
              infoIconData={
                infoContent &&
                infoContent[
                `fieldInfo.${loanType}.${loanStage.toLowerCase()}.properties.propertyInfo.propertyType`
                ]
              }
            />
          </Grid>
        )}
        {handleServiceFlowRender("propertyUnits") && (
          <Grid item xs={12} sm={6} md={colSpan} xl={colSpan}>
            <NoOfUnitsTextField
              value={propertyInformation!.numberOfUnits}
              onChange={onNoOfUnitsChange}
              error={validateForm.validate && noOfUnitsError !== ""}
              helperText={validateForm.validate ? noOfUnitsError : ""}
              isViewMode={
                isEvaluationPanelVisible &&
                !editableFlags[`editProp${activePropertyId}`]
              }
              editedLabel={
                false
              }
              enableReason={false}
              isServicerMandatory={
                isMandatoryServicerField &&
                isMandatoryServicerField("propertyUnits")
              }
              onBlur={handleBlur("numberOfUnits")}
              editedData={
                editHistory2[
                `data.properties[${activePropertyId}].propertyinfo.propertyUnits`
                ]
              }
              infoIconData={
                infoContent &&
                infoContent[
                `fieldInfo.${loanType}.${loanStage.toLowerCase()}.properties.propertyInfo.propertyUnits`
                ]
              }
            />
          </Grid>
        )}
        {handleServiceFlowRender("recentPropertyValuationDate") && (
          <Grid item xs={12} sm={6} md={colSpan} xl={colSpan}>
            <PropertyValuationDateTextField
              value={propertyInformation!.propertyValuationDate}
              onChange={handlePropertyEvaluationDateChange}
              required
              error={
                validateForm.validate && propertyEvaluationDateError !== ""
              }
              helperText={
                validateForm.validate ? propertyEvaluationDateError : ""
              }
              isViewMode={
                isEvaluationPanelVisible &&
                !editableFlags[`editProp${activePropertyId}`]
              }
              editedLabel={false}
              enableReason={false}
              isServicerMandatory={
                isMandatoryServicerField &&
                isMandatoryServicerField("recentPropertyValuationDate")
              }
              onBlur={valuationDateFocusOut}
              editedData={
                editHistory2[
                `data.properties[${activePropertyId}].propertyinfo.recentPropertyValuationDate`
                ]
              }
              infoIconData={
                infoContent &&
                infoContent[
                `fieldInfo.${loanType}.${loanStage.toLowerCase()}.properties.propertyInfo.recentPropertyValuationDate`
                ]
              }
            />
          </Grid>
        )}
        {handleServiceFlowRender("propertyAcquisitionDate") && (
          <Grid item xs={12} sm={6} md={colSpan} xl={colSpan}>
            <PropertyAcquisitionDateTextField
              required
              value={propertyInformation!.propertyAcquisitionDate as Date}
              onChange={handlePropertyAcquisitionDateChange}
              error={
                validateForm.validate && propertyAcquisitionDateError !== ""
              }
              helperText={
                validateForm.validate ? propertyAcquisitionDateError : ""
              }
              onBlur={acquistionDateFocusOut}
              isViewMode={
                isEvaluationPanelVisible &&
                !editableFlags[`editProp${activePropertyId}`]
              }
              editedLabel={false}
              enableReason={false}
              isServicerMandatory={
                isMandatoryServicerField &&
                isMandatoryServicerField("propertyAcquisitionDate")
              }
              editedData={
                editHistory2[
                `data.properties[${activePropertyId}].propertyinfo.propertyAcquisitionDate`
                ]
              }
              infoIconData={
                infoContent &&
                infoContent[
                `fieldInfo.${loanType}.${loanStage.toLowerCase()}.properties.propertyInfo.propertyAcquisitionDate`
                ]
              }
            />
          </Grid>
        )}
        {handleServiceFlowRender("squareFootage") && (
          <Grid item xs={12} sm={6} md={colSpan} xl={colSpan}>
            <SquareFootageTextField
              value={propertyInformation!.squareFootage}
              onChange={onSquareFootageChange}
              error={validateForm.validate && squareFootageError !== ""}
              helperText={validateForm.validate ? squareFootageError : ""}
              isViewMode={
                isEvaluationPanelVisible &&
                !editableFlags[`editProp${activePropertyId}`]
              }
              editedLabel={false}
              enableReason={false}
              onBlur={handleBlur("squareFootage")}
              editedData={
                editHistory2[
                `data.properties[${activePropertyId}].propertyinfo.squareFootage`
                ]
              }
              infoIconData={
                infoContent &&
                infoContent[
                `fieldInfo.${loanType}.${loanStage.toLowerCase()}.properties.propertyInfo.squareFootage`
                ]
              }
              isServicerMandatory={
                isMandatoryServicerField &&
                isMandatoryServicerField("squareFootage")
              }
            />
          </Grid>
        )}
        {handleServiceFlowRender("occupiedPercentage") && (
          <Grid item xs={12} sm={6} md={colSpan} xl={colSpan}>
            <PercentageOccupiedField
              value={getValueFromResults(
                loanEvaluationResult,
                loanId,
                "propertyResult",
                "loanEconomics.percentOccupied",
                "percentage",
                activePropertyId
              )}
              onChange={onPercentageOccupiedChange}
              error={validateForm.validate && percentageOccupiedError !== ""}
              helperText={validateForm.validate ? percentageOccupiedError : ""}
              onBlur={onPercentageOccupiedFocusOut}
              isViewMode={true}
              editedLabel={false}
              editedData={
                editHistory2[
                `data.properties[${activePropertyId}].propertyinfo.occupiedPercentage`
                ]
              }
              infoIconData={
                infoContent &&
                infoContent[
                `fieldInfo.${loanType}.${loanStage.toLowerCase()}.properties.propertyInfo.occupiedPercentage`
                ]
              }
              isServicerMandatory={
                isMandatoryServicerField &&
                isMandatoryServicerField("occupiedPercentage")
              }
            />
          </Grid>
        )}
        {/* {editableFlags[`editProp${activePropertyId}`] && (
          <Grid container item xs={12} sm={12} md={12} xl={12}>
            <Button
              variant="contained"
              color="info"
              className={
                editableFlags[`editProp${activePropertyId}`]
                  ? classes.savebutton
                  : classes.editImageDisplay
              }
              onClick={() => {
                handleOpen();
              }}
              disabled={changedValueFields.length === 0}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="info"
              className={
                editableFlags[`editProp${activePropertyId}`]
                  ? classes.cancelButton
                  : classes.editImageDisplay
              }
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </Button>
          </Grid>
        )} */}
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="duplicate-property-modal"
        aria-describedby="duplicate-property-modal-description"
      >
        <div style={{ padding: 20, width: 500, maxWidth: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexGrow: 1,
              alignItems: "center"
            }}
          >
            <div style={{ fontSize: 14, fontWeight: 600, color: "#32325d" }}>
              Warning
            </div>
            <CloseIcon
              htmlColor="#414169"
              style={{ width: 16, height: 16, cursor: "pointer" }}
              onClick={() => handleClose()}
            />
          </div>
          <div
            style={{
              fontSize: 12,
              lineHeight: 1.5,
              color: "#32325d",
              marginTop: 40
            }}
          >
            {requestWaiverMessage}
          </div>
          <div className={classes.modalDeleteButtonConatainer}>
            <Button
              variant="contained"
              color="primary"
              data-testid="modal-delete-button"
              className={classes.modalButton}
              onClick={() => {
                if (errors === 0) {
                  // if (saveOnFieldChange(changedValueFields)) {
                  //   dispatch(valueEditing(false));
                  //   editableFlags[`editProp${activePropertyId}`] = false;
                  //   setEditable(!isEditable);
                  //   changedValueFields = [];
                  // }
                  saveOnFieldChange(changedValueFields).then((res: any) => {
                    if (res !== "false") {
                      // updatePreviousLoanState();
                      // setResetData(false);
                      dispatch(valueEditing(false));
                      editableFlags[`editProp${activePropertyId}`] = false;
                      changedValueFields = [];
                      // setEditable(!isEditable);
                    }
                  });
                }
                handleClose();
              }}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              data-testid="modal-cancel-button"
              className={classes.modalCancelButton}
              onClick={() => {
                handleClose();
                onCancel();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
