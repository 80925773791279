/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Popover, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import iconLink from "../../images/guidelineOutput/link.svg";
import { RootState } from "../../stores/rootReducer";
import { setActiveTabAndProperty } from "../create-loan.action";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      border: "1px solid red"
    },
    failReasonContainer: {
      padding: "0 17px 17px 14px"
    },
    failReason: {
      fontsize: "9px !important",
      color: "#32325d",
      fontWeight: 500
    },
    subHeader: {
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: 1.67,
      float: "left"
    },
    failReasonDescription: {
      fontSize: "11px",
      color: "#32325d",
      fontWeight: 500,
      paddingTop: "2px",
      display: "block",
      width: "100%"
    },
    failedAddressDesc: {
      fontSize: "12px",
      color: "#32325d",
      fontWeight: 500,
      paddingTop: "2px"
    },
    failedAddress: {
      // padding: theme.spacing(0),
      color: "#32325d",
      fontSize: "11px",
      lineHeight: "2"
    },
    popoverBox: {
      // boxShadow: "1px 1px 2px 2px rgba(98, 115, 132, 0.22)",
      maxHeight: "400px",
      padding: theme.spacing(2.5)
    },
    addressContainer: {
      borderRadius: 4,
      display: "flex",
      flexDirection: "column",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      backgroundColor: "#ffffff",
      marginTop: theme.spacing(3)
    },
    sectionWrapper: {
      margin: "0px !important",
      padding: "12px 36px 12px 12px !important",
      display: "flex"
    },
    moreBtn: {
      color: "#5e72e4"
    }
  })
);

export const FailedPropertyAddress = (props: {
  errorAddressList?: any;
  testName: string;
  section?: string;
}) => {
  const { errorAddressList, testName, section } = props;
  const classes = useStyles();
  const failedAddress: any = [];
  const dispatch = useDispatch();
  const { activePropertyId } = useSelector<RootState, any>(
    (state) => state.createLoanStore.loanDetails
  );
  const { activeTab } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );
  if (errorAddressList) {
    Object.keys(errorAddressList).forEach((errorProperty) => {
      if (errorProperty === testName) {
        errorAddressList[errorProperty].forEach((address: any) => {
          failedAddress.push(address);
        });
      }
    });
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const redirectToProperty = (propertyId: string) => {
    if (
      activeTab !== "propertyDetails" ||
      propertyId !== activePropertyId.toString()
    ) {
      dispatch(
        setActiveTabAndProperty("propertyDetails", JSON.parse(propertyId))
      );
    }
  };
  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      className={classes.failReasonContainer}
    >
      {failedAddress.length > 0 && (
        <Typography
          className={
            section === "failureResultCard"
              ? classes.failReason
              : classes.subHeader
          }
        >
          Property(s) in which this test failed ({failedAddress.length}):
        </Typography>
      )}
      {failedAddress.map((ele: any, index: any) => {
        if (index < 3) {
          return (
            <Typography
              className={
                section === "failureResultCard"
                  ? classes.failReasonDescription
                  : classes.failedAddressDesc
              }
              style={
                section === "failureResultCard" &&
                (activeTab !== "propertyDetails" ||
                  ele.propertyId !== activePropertyId.toString())
                  ? { cursor: "pointer" }
                  : {}
              }
              onClick={() => {
                if (
                  section === "failureResultCard" &&
                  (activeTab !== "propertyDetails" ||
                    ele.propertyId !== activePropertyId.toString())
                ) {
                  redirectToProperty(ele.propertyId);
                }
              }}
            >
              {section === "failureResultCard" ? (
                <span>&#45;</span>
              ) : (
                <span>&nbsp;</span>
              )}
              {ele.propertyAddress}{" "}
              {section === "failureResultCard" && (
                <img src={iconLink} alt="Request Link" height="14" width="14" />
              )}
              {section === "failureResultCard" ? "" : <span>&#44;</span>}
            </Typography>
          );
        }
        if (index === 3) {
          return (
            <Button
              className={classes.moreBtn}
              aria-describedby={id}
              onClick={handleClick}
            >
              + {failedAddress.length - 3} More
            </Button>
          );
        }
        return true;
      })}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
      >
        <Box className={classes.popoverBox}>
          {failedAddress.map((ele: any, index: any) => {
            if (index >= 3) {
              return (
                <Typography
                  className={classes.failedAddress}
                  style={
                    section === "failureResultCard" &&
                    (activeTab !== "propertyDetails" ||
                      ele.propertyId !== activePropertyId.toString())
                      ? { cursor: "pointer" }
                      : {}
                  }
                  onClick={() => {
                    if (
                      section === "failureResultCard" &&
                      (activeTab !== "propertyDetails" ||
                        ele.propertyId !== activePropertyId.toString())
                    ) {
                      redirectToProperty(ele.propertyId);
                    }
                  }}
                >
                  - {ele.propertyAddress}{" "}
                  {section === "failureResultCard" && (
                    <img
                      src={iconLink}
                      alt="Request Link"
                      height="14"
                      width="14"
                    />
                  )}
                </Typography>
              );
            }
            return true;
          })}
        </Box>
      </Popover>
    </Grid>
  );
};
