import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      input: {
        backgroundColor: "#ffffff !important"
      }
    },
    rectangle: {
      backgroundColor: "#f8f9ff"
    },
    path: {
      width: "16px",
      height: "16px",
      color: "#32325d"
    },
    loanInfoContainer: {
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        justifyContent: "center"
      },
      [theme.breakpoints.only("sm")]: {
        display: "flex",
        justifyContent: "flex-start",
        paddingLeft: "12px"
      }
    },
    loanInfo: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: 1.29,
      color: "#32325d",
      display: "flex",
      alignSelf: "center",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        textAlign: "center"
      }
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      borderRadius: "6px",
      border: "solid 1px #dddddd",
      backgroundColor: "#ffffff",
      boxShadow: "0 2px 0.75rem 0 rgba(50, 50, 93, 0.21)",
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(7)
    },
    deleteButtonTrue: {
      width: 70,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4"
    },
    deleteButtonFalse: {
      width: 72,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "white",
      marginLeft: "15px",
      color: "black"
    },
    deleteHeader: {
      fontSize: 14,
      fontWeight: 600,
      color: "#32325d",
      display: "inline-block"
    },
    deleteBody: {
      fontSize: 12,
      lineHeight: 1.5,
      color: "#32325d",
      wordBreak: "break-word"
    },
    backButton: {
      display: "inline-block",
      position: "relative",
      top: "3px",
      marginRight: "20px",
      cursor: "pointer"
    },
    formSelectContainer: {
      justifyContent: "space-between",
      display: "flex",
      flexDirection: "row",
      width: "95%",
      padding: "15px 0px 0px 0px"
    },
    headerContainer: {
      justifyContent: "space-between",
      display: "flex",
      flexDirection: "row",
      width: "100%",
      alignItems: "center",
      padding: "1.1rem 1.6rem 1.1rem 1.6rem",
      borderBottom: "solid 1px #f2f2f2",
      [theme.breakpoints.down("sm")]: {
        padding: "1.1rem 0.9rem 1.1rem 0.9rem"
      },
      [theme.breakpoints.only("sm")]: {
        display: "flex",
        justifyContent: "flex-start",
        padding: "0.9rem"
      }
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: "95%",
      fontSize: "0.75rem",
      "&: selected": {
        fontWeight: "bold !important"
      }
    },
    selectEmpty: {
      height: "2.25rem",
      borderRadius: "3px",
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      paddingLeft: "3px",
      fontSize: "0.75rem",
      fontWeight: 600,
      background: "#ffffff !important",
      "&:selected": {
        backgroundColor: "#ffffff !important"
      }
    },
    paragraphContainer: {
      padding: "39px 77px 44px 77px",
      [theme.breakpoints.down("sm")]: {
        padding: "10px !important"
      }
    },
    uploadText: {
      fontSize: "0.75rem",
      lineHeight: "1.57",
      letterSpacing: "0.1px",
      textAlign: "center",
      color: "#7a8aa0",
      padding: "0px 35px",
      [theme.breakpoints.down("sm")]: {
        textOverflow: "ellipsis",
        padding: "10px !important"
      }
    },
    dragDropContainer: {
      margin: "25px 25px 10px 25px",
      alignItems: "center",
      borderRadius: "6px",
      border: "dashed 1px #8897aa",
      padding: "42px",
      [theme.breakpoints.down("sm")]: {
        padding: "42px"
      }
    },
    uploadContainer: {
      margin: "25px 25px 10px 25px",
      width: "650px",
      alignItems: "center",
      borderRadius: "6px",
      border: "dashed 1px #8897aa",
      padding: "42px",
      [theme.breakpoints.down("sm")]: {
        padding: "42px"
      }
    },
    bottomButtonWrapper: {
      margin: "20px 0 30px",
      textAlign: "center"
    },
    divider: {
      width: "100%",
      textAlign: "center",
      border: "solid 1px #f2f2f2"
    },
    icons: {
      height: "16px",
      width: "16px",
      color: "#32325d"
    },
    radioWrap: {
      paddingLeft: "20px",
      justifyContent: "space-between"
    },
    downloadContainer: {
      display: "flex",
      justifyContent: "flex-end",
      cursor: "no-drop",
      maxWidth: "172px"
    },
    downloadBtn: {
      fontsize: "12px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "10px",
        width: "100%"
      },
      [theme.breakpoints.only("sm")]: {
        marginTop: "0px",
        marginRight: "5px"
      }
    },
    centered: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      fontFamily: "Inter-Regular_",
      fontSize: "8px",
      fontWeight: 500,
      lineHeight: "2",
      letterSpacing: "0.07px",
      textAlign: "center",
      color: "#1071ee"
    },
    fileName: {
      fontFamily: "OpenSans",
      fontSize: "12px",
      lineHeight: "2",
      letterSpacing: "0.09px",
      textAlign: "center",
      color: "#1071ee",
      paddingTop: "5px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    fileIconContainer: {
      position: "relative",
      textAlign: "center"
    },
    errorText: {
      fontFamily: "OpenSans",
      fontSize: "12px",
      color: "#f75676",
      alignSelf: "flex-start",
      marginLeft: "27px"
    },
    uploadExcelLoading: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      width: "15%"
    },
    createLoanHeader: {
      position: "fixed",
      top: "0",
      height: "60px"
    },
    createLoanBodyWrapper: {
      paddingTop: "60px"
    },
    loanTypeContainer: {
      borderRadius: 20,
      border: "solid 1px #d8d5d5",
      backgroundColor: "#ffffff",
      padding: 8,
      textAlign: "center",
      cursor: "pointer"
    },
    loanTypeContainerError: {
      borderRadius: 20,
      border: "solid 1px #f75676",
      backgroundColor: "#ffffff",
      padding: 8,
      textAlign: "center",
      cursor: "pointer"
    },
    loanTypeText: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      textAlign: "center",
      color: "#32325d",
      textTransform: "uppercase"
    },
    loanTypeLabel: {
      width: "100%",
      marginTop: 25,
      paddingLeft: 25
    },
    errorLabel: {
      width: "100%",
      paddingLeft: 25
    },
    selectionErrorText: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      color: "#f75676"
    },
    originatorDropDownContainer: {
      padding: "24px 24px 0",
      width: "100%"
    },
    originatorSelectRoot: {
      boxShadow: "0 0 3px 0 rgb(0 0 0 / 22%)",
      borderRadius: "4px",
      fontSize: 12,
      "&:focus": {
        margin: 0,
        borderRadius: 4,
        marginBottom: "0 !important",
        backgroundColor: "#ffffff !important"
      },
      "& .MuiSelect-select:focus": {
        margin: 0,
        marginTop: 2,
      }
    },
    originatorIcon: {
      right: 12,
      top: "calc(50% - 10px)",
      fill: "#32325d",
      width: 18,
      height: 18
    },
    originatorListItem: {
      fontSize: 12,
      "&:focus": {
        backgroundColor: "#f6f9fc !important"
      },
      "&:hover": {
        backgroundColor: "#f6f9fc !important"
      }
    },
    modalButton: {
      marginRight: 12,
      color: "#ffffff",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      marginTop: 18,
      borderWidth: 0,
      "&:hover": {
        backgroundColor: "#5566d6 !important"
      }
    },
    search: {
      padding: "8px",
      position: "relative",
      width: "290px",
      "& .svg-icon": {
        fontSize: "16px"
      }
    },
    checkListItem: {
      padding: "12px 20px",
      alignItems: "flex-start"

      // backgroundColor: "#f6f9fc"
    },
    filterTerms: {
      fontSize: "12px",
      fontWeight: "normal",
      color: "#32325d",
      padding: "0px"
    },
    inputRoot: {
      color: "inherit",
      width: "100%",
      backgroundColor: "#f7fafc",
      border: "1px solid #e9ecef",
      borderRadius: "20px !important",
      paddingLeft: "8px"
    },
    inputInput: {
      padding: "8px",
      width: "100%",
      fontSize: "12px",
      "&:focus": {
        boxShadow: "none !important"
      }
    },
    dialogCancelBtn: {
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      backgroundColor: "#ffffff",
      border: "solid 1px #ececec",
      color: "#32325d",
      "&:hover": {
        backgroundColor: "#ffffff !important"
      }
    },
    dialogDeleteButton: {
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: 1.83,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#ffffff",
      // width: "89px",
      textTransform: "none",
      height: "36px",
      borderRadius: "4px",
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      "&:hover": {
        backgroundColor: "#5566d6 !important"
      }
    },
    paper: {
      minWidth: "700px"
    },
    headerButton: {
      backgroundColor: "#fff",
      display: "flex"
    },
    button: {
      backgroundColor: "#fff",
      fontSize: "12px",
      width: "50%",
      fontWeight: 600,
      height: "35px",
      border: "1px solid #d8d5d5 !important",
      padding: "10px 20px",
      letterSpacing: "0.5px",
      margin: "0 1rem 0 0",
      borderRadius: "16px !important",
      marginLeft: "0px !important",
      color: theme.palette.text.secondary,
      "&.$Mui-selected": {
        backgroundColor: "#5e72e4 !important",
        fontWeight: "600 !important",
        color: "#fff !important"
      },
      "&:disabled": {
        color: "#32325d",
        opacity: 0.5,
        fontWeight: 600,
        cursor: "no-drop",
        borderColor: "#d8d5d5 !important"
      },
      textTransform: "none"
    },
    formLabel: {
      paddingBottom: "0.8rem",
      fontSize: "12px",
      fontWeight: "normal",
      "& .MuiFormLabel-asterisk": {
        color: "red"
      }
    },
  })
);
