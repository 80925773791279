/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from "react";
import {
  Badge,
  Button,
  ClickAwayListener,
  IconButton,
  Paper,
  Popper
} from "@mui/material";
import { useStyles } from "./useStyles";
import { FilterFunnelIcon } from "../MasterViewIcons";
import { useAction } from "./useAction";
import { AutoCompleteAsync } from "../autoCompleteAsync/AutoCompleteAsync";

const KEY = "key";

export const AutoCompleteFilter = (props: any) => {
  const {
    options = [],
    isLoading,
    columnName,
    columnLabel,
    checked = [],
    handleAutoCompleteSearch,
    handleUpdateSelectedCheckboxes,
    searchConfig,
    filterSelections,
    isFrozen
  } = props;
  const anchorRef = useRef<HTMLButtonElement>(null);
  const action = useAction(
    anchorRef,
    columnName,
    columnLabel,
    handleAutoCompleteSearch,
    handleUpdateSelectedCheckboxes,
    searchConfig,
    filterSelections,
    options,
    KEY,
    isFrozen
  );
  const styles = useStyles();

  const funnelIconView = (
    <IconButton
      size="small"
      ref={anchorRef}
      aria-controls={action.open ? "menu-list-grow" : undefined}
      aria-haspopup="true"
      onClick={(e) => action.onOpenFunction(e)}
    >
      <Badge
        color="primary"
        variant="dot"
        invisible={checked.length === 0}
        classes={{ badge: styles.badgeFilter }}
      >
        <div style={{ margin: "2px 2px 0px 0px" }}>
          <FilterFunnelIcon filled={checked.length > 0} />
        </div>
      </Badge>
    </IconButton>
  );

  return (
    <>
      {funnelIconView}
      <Popper
        open={action.open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
        style={{
          zIndex: 1,
          position: "absolute",
          top: 35,
          left: "inherit",
          willChange: "transform"
        }}
      >
        <Paper style={{ backgroundColor: "white" }}>
          <ClickAwayListener onClickAway={action.handleClose as any}>
            <div className={styles.root}>
              <AutoCompleteAsync
                options={action.uniqueOptions}
                isLoading={isLoading}
                dataKey={KEY}
                setIsLastNodeVisible={action.setIsVisible}
                selectedOptions={action.selectedTags}
                setSelectedOptions={action.setSelectedTags}
                inputLabel={`Search ${columnLabel}`}
                searchValue={action.searchValue}
                setSearchValue={action.setSearchValue}
              />
              <div className={styles.divButtonPlacement}>
                <Button
                  variant="contained"
                  color="primary"
                  className={styles.dialogDeleteButton}
                  onClick={action.handleSave}
                  data-testid="delete-confirm-btn"
                  disabled={
                    action.selectedTags?.length === 0 && !action.isFilterApplied
                  }
                >
                  Apply
                </Button>
                <Button
                  autoFocus
                  onClick={(event: React.MouseEvent<EventTarget>) =>
                    action.handleClose(event, true)
                  }
                  color="primary"
                  disabled={
                    action.selectedTags?.length === 0 && !action.isFilterApplied
                  }
                  className={`${styles.dialogCancelBtn} ${styles.dialogDeleteButton}`}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};
