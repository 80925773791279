import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { mergeArrays } from "../documentReview/DocumentReview.reducer";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import { notToBeCountedDocs } from "../../create-loan/ttf-review/constants";
import { checkIfStringStartsWith } from "../../utils/utils";
import { isArray } from "../../utils/objectUtil";

export interface PLDocumentReviewStore {
  splitDocsState?: any;
  autoTaggedPLDocuments: { isLoading: boolean; data: any };
  loanDetails: {};
  address: any;
  borrowers: any;
  guarantors: any;
  nonTCLoanList: any[];
}

const initialState: PLDocumentReviewStore = {
  loanDetails: {},
  address: [],
  borrowers: [],
  guarantors: [],
  nonTCLoanList: [],
  autoTaggedPLDocuments: { isLoading: false, data: [] }
};

export const getAutomaticPLTaggedDocuments = (
  data: any,
  fromTaggingScreen?: boolean
) => {
  const filteredDocuments = data.data.map(
    (document: any) => {
      const tagSinternal = document?.TagsInternal ?? [];
      const fileType = document?.name.split(".").pop() || "";
      if (tagSinternal?.length === 1) return { ...document, fileType };
      // Give precendence to the MANUAL updated tag
      const manualTags = tagSinternal.filter(
        (tagInternal: any) => tagInternal?.tagType === "MANUAL"
      );
      return { ...document, TagsInternal: manualTags, fileType };
    }
  );
  // return async (dispatch: any) => {
  return setPLTaggedDoc({
    ...data,
    data: filteredDocuments,
    fromTaggingScreen
  });
};

const purchasedLoansDocReviewSlice = createSlice({
  name: "purchasedLoansDocReview",
  initialState,
  reducers: {
    setLoanDetails: (state, action: PayloadAction<any>) => {
      let loanDetails = action.payload;
      loanDetails = {
        ...loanDetails,
        ...{ loanState: LoanStatusEnum.Purchased }
      };
      state.loanDetails = loanDetails;
    },
    setPLDetailsForDocReview: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      const { address, borrowers, guarantors } = payload;
      state.address = address;
      state.borrowers = borrowers;
      state.guarantors = guarantors;
    },
    setPLTaggedDoc: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      const tempObj: any = { ...payload };
      const newData = tempObj.data.map((item: any) => ({
        ...item,
        isOpenState: false
      }));
      if (payload?.fromTaggingScreen) {
        state.autoTaggedPLDocuments.data = newData;
      } else if (tempObj?.clear) {
        state.autoTaggedPLDocuments.data = [];
      } else {
        state.autoTaggedPLDocuments.data = mergeArrays(
          state.autoTaggedPLDocuments.data,
          newData
        ) as any[];
      }
      state.autoTaggedPLDocuments.isLoading = tempObj.isLoading;
      state.autoTaggedPLDocuments.data =
          state.autoTaggedPLDocuments.data.filter(
            (val: any) => !checkIfStringStartsWith(notToBeCountedDocs, val.name)
          );
    },
    updatePLAutoTaggedDoc: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      const tempObj = {
        data: payload,
        isLoading: false
      };
      state.autoTaggedPLDocuments = tempObj;
      if (state.autoTaggedPLDocuments?.data && isArray(state.autoTaggedPLDocuments.data)){
      state.autoTaggedPLDocuments.data =
          state.autoTaggedPLDocuments.data.filter(
            (val: any) => !checkIfStringStartsWith(notToBeCountedDocs, val.name)
          );
      }
        // postProcessDocs(draftState);
    },
    setNonTCList: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.nonTCLoanList = payload;
    },
  }
});

export const {
  setLoanDetails,
  updatePLAutoTaggedDoc,
  setPLTaggedDoc,
  setPLDetailsForDocReview,
  setNonTCList
} = purchasedLoansDocReviewSlice.actions;
export const purchasedLoansDocReviewReducer =
  purchasedLoansDocReviewSlice.reducer;
