/* eslint-disable react-hooks/exhaustive-deps */
import { getConfig } from "../../config/config";
import {
  ComparableFields,
  HouseCanaryForm,
  ToorakReconciliationForm,
  HouseCanaryListingHistory,
  HouseCanarySalesHistory,
  SubjectHistoryForm,
  FormField
} from "../../stores/appraisal/appraisal.reducer";
import { formatValueByType, isViewOnly } from "../../utils/formatChecks";
import { getRoles, checkUser } from "../../utils/AccessManagement";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { RootState } from "../../stores/rootReducer";
import { useParams } from "react-router-dom";
import {
  getHouseCanaryValue,
  getHouseCanaryBlockGroupValueDistribution,
  getHouseCanaryListingHistory,
  getHouseCanarySalesHistory
} from "../../stores/appraisal/appraisal.action";
import { useEffect, useState } from "react";
import { useAppraisalForm } from "./shared/useAppraisalForm";
import { getCookie } from "../../utils/cookies";
import { LoanStage } from "@toorak/tc-common-fe-sdk";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";

export enum ToorvalRoles {
  ORIGINATOR_MEMBER = "ORIGINATOR_MEMBER",
  APPRAISER = "APPRAISER",
  LAT_APPROVER = "LAT_APPROVER",
  LAT = "LAT",
  ORIGINATOR = "originator"
}

export enum ToorvalLoginFlows {
  LAT = "LAT",
  ORIGINATOR = "originator"
}

export class KeyGen {
  keys: any = {};
  getKey(string: string): string {
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
      const charCode = string.charCodeAt(i);
      hash += charCode;
    }
    const result = String(hash);
    if (this.keys[result]) return this.getKey(result + string);
    if (!this.keys[result]) this.keys[result] = true;
    return result;
  }
}

export function attemptSanitize(
  value: string | number | null,
  fieldType: string | undefined,
  action?: string
) {
  const fieldAction = `${fieldType}-${action || "any"}`;
  let formattedValue;
  switch (fieldAction) {
    case "currency-blur":
      formattedValue = formatValueByType(value, "currency");
      break;
    case "currency-change":
      formattedValue = value;
      break;
    case "decimal-blur":
      formattedValue = formatValueByType(value, "decimal");
      break;
    case "decimal-change":
      formattedValue = value;
      break;
    default:
      formattedValue = value;
      break;
  }
  return formattedValue;
}

export function getZillowURL(comparable: Partial<ComparableFields>) {
  const { address, city, state, postalCode } = comparable;
  if (!address && !city && !state && !postalCode) return null;
  const fullAddress = getFullAddress(comparable);
  const backup = (fullAddress || "").replace(/\//g, "-").split(" ").join("-");
  return `https://www.zillow.com/homes/${backup}`;
}

export function getFullAddress({
  address,
  city,
  state,
  postalCode
}: Partial<ComparableFields>) {
  if (!address && !city && !state && !postalCode) return null;
  return `${address || ""}, ${city || ""}, ${state || ""}, ${postalCode || ""}`;
}

export function getAppraisalServiceUrl() {
  const apiUrl = new URL(getConfig().apiUrl);
  const { protocol, hostname } = apiUrl;
  return `${protocol}//${
    window.location.hostname === "localhost" ? "api-" : ""
  }${hostname}/appraisal`;
}

export enum AppraisalStatus {
  dataEntry = "data entry",
  underReview = "under review",
  approved = "approved",
  approvedConditionally = "approved conditionally",
  rejected = "rejected"
}

export type AppraisalStatusType = FormField | AppraisalStatus;

interface AppraisalStatusToReadableType {
  [key: string]: string;
}
export const AppraisalStatusToReadable: AppraisalStatusToReadableType = {
  "data entry": "Data Entry",
  "under review": "Under Review",
  approved: "Approved",
  "approved conditionally": "Approved Conditionally",
  rejected: "Rejected"
};

export const LoanAppraisalStatusToReadable: AppraisalStatusToReadableType = {
  "Data Entry": "Data Entry",
  "Under Review": "Under Review",
  Approved: "Approved",
  "Approved Conditionally": "Approved Conditionally",
  Rejected: "Rejected",
  submit: "Under Review"
};

export function getAppraisalStatusFilterOptions(options: { key: string }[]) {
  if (options && options.length)
    return options
      .map(({ key }) => ({
        key: AppraisalStatusToReadable[key]
      }))
      .filter(({ key }) => key);
}

export function matchRole(role: string, skipLoginFlow: boolean = false) {
  if (role === ToorvalRoles.LAT) return checkIsLAT();
  const loginFlow = checkUser([role]);
  if (skipLoginFlow === false && loginFlow === true) return true;
  const roles = getRoles();
  const isOne = isOneOf(roles, role);
  if (isOne === true) return true;
  return false;
}

export function onlyHasRole(role: string, bypassRoles?: string[]) {
  const roles = bypassRoles || getRoles();
  return hasOnlyValid(roles, role);
}

export function matchRoles(
  mustBeRoles: string[],
  mustNotBeRoles: string[],
  skipLoginFlow = true
) {
  const matchingMustBeRoles = mustBeRoles.some((role: string) => {
    return matchRole(role, skipLoginFlow);
  });
  const matchingMustNotBeRoles = mustNotBeRoles.some((role: string) => {
    return matchRole(role, skipLoginFlow);
  });
  return matchingMustBeRoles && !matchingMustNotBeRoles;
}

export function getFilterStage() {
  const { loanStage } =
    JSON.parse(localStorage.getItem("filters") || "{}")?.[
      `${getCookie("person_id")}`
    ] ?? {};

  return loanStage;
}

export function getFilter() {
  const data =
    JSON.parse(localStorage.getItem("filters") || "{}")?.[
      `${getCookie("person_id")}`
    ] ?? {};
  return data;
}

export function getIsToorvalFilter() {
  const {
    detailedViewSelectedFiltersList,
    compactViewSelectedFiltersList,
    loanStage
  } = getFilter();
  const filters = {
    ...detailedViewSelectedFiltersList?.filterSelections,
    ...compactViewSelectedFiltersList?.filterSelections
  };
  return (
    filters["loan.loanStage.raw"]?.some(
      (str: string) => str === LoanStage.tv
    ) || loanStage === "Toorval Appraisal"
  );
}

export function hasOnlyValid(array: string[], string: string): boolean {
  return !array.some((role: string) => role !== string);
}

export function isOneOf(array: string[], string: string): boolean {
  return Array.isArray(array) && array.includes(string);
}

export function checkIsLAT(): boolean {
  const roles = getRoles()?.join("").toUpperCase();
  return Array.isArray(roles) && roles.includes(ToorvalRoles.LAT);
}

interface UseView {
  (
    callback?: (options: {
      isOneOf: (array: string[], string: string) => boolean;
      matchRole: (role: string) => boolean;
      status: AppraisalStatusType;
      ToorvalRoles: any;
      isLAT: boolean;
      isAppraiser: boolean;
      isOriginator: boolean;
      isDataEntry: boolean;
      loanId: string;
      loanStage: string;
      isToorakViewOnly: boolean;
      isFrozen: boolean;
      isEditMode: boolean;
      setIsEditMode: any;
      showEditButton: boolean;
    }) => any
  ): any;
}

export const useView: UseView = (callback = () => ({})) => {
  const { loanId, loanStage } = useParams() as any;
  const status = useSelector<RootState, any>(
    (state) => state.createLoanStore.loanDetails.loanState
  );
  const isConverted =
    useSelector<RootState, any>(
      (state) => state.appraisalStore.form.convertedTo
    ) !== null || loanStage !== LoanStage.tv;
  const isDataEntry = status === LoanStatusEnum.DataEntry && !isConverted;
  const isOriginator = matchRole(ToorvalRoles.ORIGINATOR);
  const isLAT = matchRole(ToorvalRoles.LAT);
  const isAppraiser = matchRole(ToorvalRoles.APPRAISER);
  const isToorakViewOnly = isViewOnly(loanStage, loanId) || false;
  const isFrozen =
    [
      LoanStatusEnum.Approved,
      LoanStatusEnum.ApprovedConditionally,
      LoanStatusEnum.Rejected
    ].includes(status) || isConverted;
  const showEditButton =
    !isConverted &&
    (status === LoanStatusEnum.UnderReview || status === LoanStatusEnum.Submit);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const options = {
    isFrozen,
    isToorakViewOnly,
    loanStage,
    loanId,
    isOneOf,
    matchRole,
    status,
    ToorvalRoles,
    isLAT,
    isOriginator,
    isAppraiser,
    isDataEntry,
    isEditMode,
    setIsEditMode,
    showEditButton
  };
  const result = callback(options);
  return { ...result, ...options };
};

export function useHouseCanaryForm(): HouseCanaryForm {
  return useSelector<RootState, HouseCanaryForm>(
    (state) => state.appraisalStore.houseCanaryForm,
    shallowEqual
  );
}

export function useSubjectHistoryForm(): SubjectHistoryForm {
  return useSelector<RootState, SubjectHistoryForm>(
    (state) => state.appraisalStore.subjectHistoryForm,
    shallowEqual
  );
}

export function useHouseCanaryListingHistoryForm(): HouseCanaryListingHistory[] {
  return useSelector<RootState, HouseCanaryListingHistory[]>(
    (state) => state.appraisalStore.houseCanaryListingHistory,
    shallowEqual
  );
}

export function useHouseCanarySalesHistoryForm(): HouseCanarySalesHistory[] {
  return useSelector<RootState, HouseCanarySalesHistory[]>(
    (state) => state.appraisalStore.houseCanarySalesHistory,
    shallowEqual
  );
}

export function useToorakReconcilliationForm(): ToorakReconciliationForm {
  return useSelector<RootState, ToorakReconciliationForm>(
    (state) => state.appraisalStore.toorakReconciliationForm,
    shallowEqual
  );
}

export function useShowAppraisalErrors(): boolean {
  return useSelector<RootState, boolean>(
    (state) => state.appraisalStore.showErrors,
    shallowEqual
  );
}

export function useShowAppraisalApprovalCommentPopup(): boolean {
  return useSelector<RootState, boolean>(
    (state) => state.appraisalStore.showApprovalCommentPopup,
    shallowEqual
  );
}

export function useShowToorvalErrors(): {
  toorvalErrors: number;
} {
  return {
    toorvalErrors: document.querySelectorAll('input[aria-invalid="true"]')
      ?.length
  };
}

// removes fields which are null, undefined
export function removeNullish(options: any) {
  return Object.keys(options)
    .filter((k) => {
      // check for nullish  or undefined
      return options[k] ?? false;
    })
    .reduce((a, k) => ({ ...a, [k]: options[k] }), {});
}

export function useHouseCanaryData() {
  const form = useAppraisalForm();
  const { loanId } = useParams() as any;

  const dispatch = useDispatch();
  useEffect(() => {
    if (loanId) dispatch(getHouseCanaryValue(form));
    if (loanId) dispatch(getHouseCanaryBlockGroupValueDistribution(form));
  }, [loanId]);
  return { appraisalForm: form, loanId };
}

export function useHouseCanaryListingHistory() {
  const form = useAppraisalForm();
  const addresss = form.address;
  const dispatch = useDispatch();
  useEffect(() => {
    if (addresss) dispatch(getHouseCanaryListingHistory(form));
  }, [addresss]);
}

export function useHouseCanarySalesHistory() {
  const form = useAppraisalForm();
  const addresss = form.address;
  const dispatch = useDispatch();
  useEffect(() => {
    if (addresss) dispatch(getHouseCanarySalesHistory(form));
  }, [addresss]);
}
