import React, { useCallback } from "react";
import ChatIcon from "@mui/icons-material/Chat";
import { CommentBox, CommentsData } from "./";
import {
  createComment,
  getChatListAggregate,
  updateLastReadComment
} from "../../stores/commentsSection/comment.action";
import { constructName, getTimeFromDate } from "../../utils/utils";
import { getCookie } from "../../utils/cookies";
import { useDispatch } from "react-redux";
import {
  hideLoader,
  showLoader
} from "../../stores/loaderAndException/loaderAndException.action";
import { Button } from "@mui/material";

export interface CommentOpen {
  open?: boolean;
  data?: any;
  chatId?: string;
  chatList?: CommentsData[];
  chatCount?: number;
  unreadCount?: number;
  loanId?: string;
}

interface CommentWidgetProps {
  sIden: string;
  loanId: string;
  primaryLoanId?: string;
  isReadOnly?: boolean;
  buttonLabel?: string;
  tagGroup?: "customer";
  roles: string[];
  openComments?: CommentOpen;
  setOpenComments?: any;
  showAs?: "button" | "text";
}

const CommentWidget = (props: CommentWidgetProps) => {
  const {
    sIden,
    loanId,
    primaryLoanId = "",
    isReadOnly = false,
    tagGroup = "customer",
    buttonLabel = "",
    roles,
    showAs = "button",
    openComments,
    setOpenComments
  } = props;
  const dispatch = useDispatch();
  const firstName = getCookie("firstName") as string;
  const lastName = getCookie("lastName") as string;

  const commentListPrep = (
    customers: any[],
    chatLines: any[],
    unreadCount: number
  ) => {
    if (!chatLines.length) return [];
    const customersObj: any = customers.reduce(
      (a, v) => ({
        ...a,
        [v.partyId]: v
      }),
      {}
    );
    return chatLines.map((itm: any, index: number) => {
      let tags: string[] = [];
      if (itm.users.length) {
        tags = itm.users.map((item: any) => {
          return constructName(
            customersObj[item.partyId]?.firstName,
            customersObj[item.partyId]?.middleName,
            customersObj[item.partyId]?.lastName
          );
        });
      }
      let unread: boolean = false;
      if (index > chatLines.length - unreadCount - 1) unread = true;
      return {
        firstName: customersObj[itm.partyId]?.firstName,
        middleName: customersObj[itm.partyId]?.middleName,
        lastName: customersObj[itm.partyId]?.lastName,
        comment: itm.lineText,
        commentedDate: getTimeFromDate(itm.createdOn, "DD MMM YYYY, HH:mm"),
        tags,
        unread
      };
    });
  };

  const commentClose = () => {
    if (openComments?.chatId) {
      const personPartyId: string = getCookie("person_id") || "";
      updateLastReadComment(openComments?.chatId, personPartyId).then(() => {
        setOpenComments({ open: false, data: {} });
      });
    } else setOpenComments({ open: false, data: {} });
  };

  const commentOpen = (cData: CommentOpen) => {
    dispatch(showLoader());
    getChatListAggregate("LOAN", loanId, sIden, sIden)
      .then((res: any) => {
        const chatList: CommentsData[] = commentListPrep(
          res?.chatList?.customers,
          res?.chatList?.chatLines,
          res?.unreadCount[res.chatId]?.count || 0
        );
        dispatch(hideLoader());
        setOpenComments({
          ...cData,
          chatId: res.chatId,
          chatList,
          chatCount: chatList.length,
          loanId
        });
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const onSaveComments = useCallback(
    (comment: string, users: any[] = []) => {
      if (!openComments?.chatId) return;
      dispatch(showLoader());
      const personPartyId: string = getCookie("person_id") || "";
      createComment(
        openComments?.chatId,
        personPartyId,
        comment,
        false,
        "",
        "",
        users.map(itm => {
          return {
            partyId: itm?.key
          };
        })
      )
        .then(() => {
          getChatListAggregate("LOAN", loanId, sIden, sIden)
            .then((res: any) => {
              dispatch(hideLoader());
              const chatList: CommentsData[] = commentListPrep(
                res?.chatList?.customers,
                res?.chatList?.chatLines,
                res?.unreadCount[res.chatId]?.count || 0
              );
              setOpenComments({
                ...openComments,
                chatList,
                chatCount: chatList.length,
                loanId
              });
            })
            .catch(() => {
              console.log();
            });
        })
        .catch(() => dispatch(hideLoader()));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openComments]
  );

  return (
    <>
      {openComments?.loanId === loanId && openComments?.open && (
        <CommentBox
          open={openComments?.open || false}
          chatCount={openComments?.chatCount || 0}
          badgeText={`LOAN ID - ${loanId}${
            primaryLoanId ? `/${primaryLoanId}` : ""
          }`}
          commentsList={openComments?.chatList || []}
          onClose={commentClose}
          userProps={{
            firstName,
            lastName
          }}
          onSave={onSaveComments}
          tagProps={{
            hide: isReadOnly,
            type: tagGroup,
            props: {
              roles
            }
          }}
        />
      )}

      {showAs === "text" && buttonLabel && (
        <span
          onClick={() =>
            commentOpen({
              open: true,
              data: {
                loanId,
                primaryLoanId
              }
            })
          }
        >
          {buttonLabel}
        </span>
      )}

      {showAs === "button" && (
        <Button
          aria-label="actions"
          component="button"
          aria-haspopup="true"
          style={{ minWidth: "unset", width: "100%" }}
          onClick={() =>
            commentOpen({
              open: true,
              data: {
                loanId,
                primaryLoanId
              }
            })
          }
        >
          <ChatIcon />
          {buttonLabel && <span style={{ marginLeft: 10 }}>{buttonLabel}</span>}
        </Button>
      )}
    </>
  );
};
export default CommentWidget;
