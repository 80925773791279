import {
  Box,
  Button,
  Container,
  Grid,
  InputAdornment,
  InputBase,
  Paper,
  TableContainer
} from "@mui/material";
import React, {
  useRef,
  useEffect,
  useState,
  useMemo,
  useCallback
} from "react";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import CustomTable from "../../../masterView/CustomTable";
import { MasterViewStyles } from "../../../masterView/styles";
import { useDrawsStyles } from "../../DrawRequest/Draws.styles";
import { FilterFunnelIcon } from "../../../masterView/MasterViewIcons";
// import exportIcon from "../../images/export.svg";
import { useDispatch, useSelector } from "react-redux";
import { servicerMappingColumns } from "./originatorServicerMappingColumns";
import {
  getServicerMappingListData
  // setSelectedMappings,
  // updateGlobalSearchMappingValue
  // updateTableSearchConfig
  // updateTableSelectedFilters
  // updateTableSortOptions
} from "../../../stores/servicerOnboarding/servicerOnboarding.action";
import { RootState } from "../../../stores/rootReducer";
import { ServicerOnboardingStore } from "../../../stores/servicerOnboarding/servicerOnboarding.interface";
import {
  hideLoader,
  showLoader
} from "../../../stores/loaderAndException/loaderAndException.action";
import { setTabView } from "../../../stores/SettlementAndPurchase/SettlementAndPurchase.reducer";
import { Header } from "../../../create-loan/home/Header";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
  setSelectedMappings,
  updateGlobalSearchMappingValue,
  updateTableSearchOptions,
  updateTableSelectedFilters,
  updateTableSortOptions
} from "../../../stores/servicerOnboarding/servicerOnboarding.reducer";
// import { push } from "connected-react-router";

export const ServicerOriginatorMapping = () => {
  const classes = MasterViewStyles();
  const navigate = useNavigate();
  const drawClasses = useDrawsStyles();
  const dispatch = useDispatch();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState("");
  const [tableData, setTableData] = useState<any>([]);
  const [mappingResult, setMappingResult] = useState<any>([]);
  const [isCollapsed, setCollapsed] = useState<any>(false);
  const [filtersOptions, setFiltersOptions] = useState<any>({});
  const [fewRowsSelected, setFewRowsSelected] = useState(false);

  let {
    servicerMappingResult,
    onboardingCustomColumnList,
    onboardingTableSortConfig,
    onboardingTableSearchConfig,
    onboardingTableFilterConfig,
    onboardingSelectedMappings,
    onboardingSearchMappingValue,
    servicerMappingObj
  } = useSelector<RootState, ServicerOnboardingStore>(
    (state) => state.servicerOnboardingStore
  );
  useEffect(() => {
    dispatch(getServicerMappingListData());
    // eslint-disable-next-line
  }, [dispatch]);
  useEffect(() => {
    if (onboardingSelectedMappings?.length > 0) {
      setFewRowsSelected(true);
    } else {
      setFewRowsSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingSelectedMappings]);
  useEffect(() => {
    let clonedArray = JSON.parse(JSON.stringify(mappingResult));
    if (inputValue.trim() !== "") {
      clonedArray = clonedArray.filter(
        (item: any) =>
          item?.originatorName
            ?.toLowerCase()
            .includes(inputValue.trim().toLowerCase()) ||
          item?.bridgeServicer
            ?.toLowerCase()
            .includes(inputValue.trim().toLowerCase()) ||
          item?.dscrServicer
            ?.toLowerCase()
            .includes(inputValue.trim().toLowerCase()) ||
          item?.comment?.toLowerCase().includes(inputValue.trim().toLowerCase())
      );
    }
    if (onboardingTableFilterConfig) {
      for (const key in onboardingTableFilterConfig) {
        if (onboardingTableFilterConfig.hasOwnProperty(key)) {
          if (key === "updatedDate") {
            const startDate = new Date(
              onboardingTableFilterConfig[key][0].gte
            ).setHours(0, 0, 0, 0);
            const endDate = new Date(
              onboardingTableFilterConfig[key][0].lte
            ).setHours(23, 59, 59, 999);
            clonedArray = clonedArray.filter((item: any) => {
              let tempdate = new Date(item[key]);
              return (
                tempdate.setHours(23, 59, 59, 999) >= startDate &&
                tempdate.setHours(0, 0, 0, 0) <= endDate
              );
            });
          } else {
            if (
              onboardingTableFilterConfig[key] &&
              onboardingTableFilterConfig[key].length > 0
            ) {
              clonedArray = clonedArray.filter(
                (item: any) =>
                  onboardingTableFilterConfig &&
                  onboardingTableFilterConfig[key].includes(item[key])
              );
            }
          }
        }
      }
    }
    if (onboardingTableSearchConfig) {
      for (const key in onboardingTableSearchConfig) {
        if (onboardingTableSearchConfig.hasOwnProperty(key)) {
          if (
            onboardingTableSearchConfig[key] &&
            onboardingTableSearchConfig[key].trim() !== ""
          ) {
            if (key === "onboardingRequestId") {
              clonedArray = clonedArray.filter(
                (item: any) =>
                  onboardingTableSearchConfig &&
                  item[key]
                    ?.toString()
                    .includes(onboardingTableSearchConfig[key]?.toString())
              );
            } else {
              clonedArray = clonedArray.filter(
                (item: any) =>
                  onboardingTableSearchConfig &&
                  item[key]
                    ?.toLowerCase()
                    .includes(onboardingTableSearchConfig[key]?.toLowerCase())
              );
            }
          }
        }
      }
    }
    if (onboardingTableSortConfig) {
      const key = Object.keys(onboardingTableSortConfig)[0];
      const type = onboardingTableSortConfig[key];
      if (
        [
          "originatorName",
          "bridgeServicer",
          "dscrServicer",
          "comment"
        ].includes(key)
      ) {
        if (type === "" || type === "DESC") {
          clonedArray.sort((a: any, b: any) => b[key].localeCompare(a[key]));
        } else {
          clonedArray.sort((a: any, b: any) => a[key].localeCompare(b[key]));
        }
      } else if ("onboardingRequestId" === key) {
        if (type === "" || type === "DESC") {
          clonedArray.sort((a: any, b: any) => b[key] - a[key]);
        } else {
          clonedArray.sort((a: any, b: any) => a[key] - b[key]);
        }
      } else {
        if (type === "" || type === "DESC") {
          clonedArray.sort(
            (a: any, b: any) => Date.parse(b[key]) - Date.parse(a[key])
          );
        } else {
          clonedArray.sort(
            (a: any, b: any) => Date.parse(a[key]) - Date.parse(b[key])
          );
        }
      }
    }
    setTableData(clonedArray);
    //eslint-disable-next-line
  }, [
    onboardingTableFilterConfig,
    onboardingTableSearchConfig,
    onboardingSearchMappingValue,
    onboardingTableSortConfig
  ]);
  const boxColumns = useMemo(() => {
    const args = {
      classes
    };
    setTableData(servicerMappingResult);
    setMappingResult(servicerMappingResult);
    if (servicerMappingResult.length) {
      setTimeout(() => {
        clearFilter();
        handleFilterToggle("onboardStatus");
      }, 500);
    }
    return servicerMappingColumns(args);
    // eslint-disable-next-line
  }, [isCollapsed, servicerMappingResult]);

  useEffect(() => {
    if (servicerMappingObj && Object.keys(servicerMappingObj).length) {
      let clonedArray = JSON.parse(JSON.stringify(tableData));
      let mappingCloneArray = JSON.parse(JSON.stringify(mappingResult));
      let filteredIndex = clonedArray.findIndex(
        (it: any) => it.originatorId === servicerMappingObj.originatorId
      );
      let mappingIndex = mappingCloneArray.findIndex(
        (it: any) => it.originatorId === servicerMappingObj.originatorId
      );
      if (filteredIndex > -1) {
        clonedArray[filteredIndex].bridgeServicer =
          servicerMappingObj.bridgeServicer;
        clonedArray[filteredIndex].dscrServicer =
          servicerMappingObj.dscrServicer;
        setTableData(clonedArray);
      }
      if (mappingIndex > -1) {
        mappingCloneArray[mappingIndex].bridgeServicer =
          servicerMappingObj.bridgeServicer;
        mappingCloneArray[mappingIndex].dscrServicer =
          servicerMappingObj.dscrServicer;
        setMappingResult(mappingCloneArray);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [servicerMappingObj]);
  const updatedCustomColumnList = useMemo(() => {
    if (!onboardingCustomColumnList.length) return boxColumns;
    const columnsClone = [...boxColumns];
    const map = new Map();
    const updatedMap = new Map();
    onboardingCustomColumnList.forEach((item: any, index: number) =>
      map.set(item.label, { data: item, order: index })
    );
    columnsClone.forEach((item: any, index: number) => {
      const col = map.get(item.title) || {};
      updatedMap.set(item.title, {
        ...col.data,
        ...item,
        order: col.order || index,
        hidden: item?.hidden || col.data?.hidden
      });
    });
    const mergedArr = Array.from(updatedMap.values());
    mergedArr.sort((a, b) => a.order - b.order);
    return mergedArr;
  }, [onboardingCustomColumnList, boxColumns]);
  const { tableCols } = useMemo(() => {
    const cols = updatedCustomColumnList.filter(
      (it: any) => !it.hidden || it.id === "checkboxId"
    );
    return { tableCols: cols };
  }, [updatedCustomColumnList]);
  const handleSearchChange = useCallback(
    (config: { [key: string]: string }) => {
      const updatedSearchConfig = { ...onboardingTableSearchConfig, ...config };
      dispatch(updateTableSearchOptions(updatedSearchConfig));
    },
    [onboardingTableSearchConfig, dispatch]
  );
  const onSort = useCallback(
    (config: { [key: string]: any[] }) => {
      dispatch(updateTableSortOptions(config));
    },
    [dispatch]
  );
  const handleFilterToggle = useCallback(
    (columnName: string) => {
      let clonedArray = JSON.parse(JSON.stringify(mappingResult));
      let distinctValue: any = [];
      clonedArray.forEach((element: any) => {
        if (
          element[columnName] &&
          distinctValue.indexOf(element[columnName]) === -1
        ) {
          distinctValue.push(element[columnName]);
        }
      });
      let filterObj: any = [];
      distinctValue.forEach((element: any) => {
        filterObj.push({ key: element, doc_count: Math.random() });
      });
      setFiltersOptions({
        ...filtersOptions,
        [columnName]: filterObj
      });
    },
    // eslint-disable-next-line
    [filtersOptions]
  );
  const handleCheckBoxChange = useCallback(
    (config: { [key: string]: any[] }) => {
      const updatedSelectedFiltersConfig = {
        ...onboardingTableFilterConfig,
        ...config
      };
      dispatch(updateTableSelectedFilters(updatedSelectedFiltersConfig));
    },
    [onboardingTableFilterConfig, dispatch]
  );
  const noOfFrozenCols: number = useMemo(() => {
    return tableCols.filter((itm: any) => itm.frozen)?.length ?? 0;
  }, [tableCols]);
  const handleDateRangeFilter = useCallback(
    (config: { [key: string]: string[] }) => {
      const colName = Object.keys(config)[0];
      const isColFiltered = config?.[colName] ?? [];
      const filterSelections: any = [...isColFiltered];
      const temp: any = { ...onboardingTableFilterConfig };
      if (!filterSelections.length) {
        delete temp?.[colName];
        dispatch(updateTableSelectedFilters(temp));
      } else {
        filterSelections.range = true;
        const updatedSelectedFiltersConfig = {
          ...onboardingTableFilterConfig,
          [colName]: filterSelections
        };
        dispatch(updateTableSelectedFilters(updatedSelectedFiltersConfig));
      }
    },
    [onboardingTableFilterConfig, dispatch]
  );
  const onCollapseColumn = useCallback(() => {
    setCollapsed((isCollapsed: boolean) => !isCollapsed);
  }, []);
  const handleSelectedLoan = (e: any, data: any) => {
    let loanArr: any;
    if (e.target.checked) {
      const selectedLoan = data;
      loanArr = [...onboardingSelectedMappings, ...selectedLoan];
    } else {
      loanArr = onboardingSelectedMappings.filter(
        (item: any) => item !== data[0]
      );
    }
    dispatch(setSelectedMappings(loanArr));
  };
  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      const loanArr = tableData.map((obj: any) => obj?.originatorId);
      dispatch(setSelectedMappings(loanArr));
    } else {
      dispatch(setSelectedMappings([]));
    }
  };
  const clearSearch = () => {
    setInputValue("");
    dispatch(updateGlobalSearchMappingValue(""));
  };
  const handleSearchForServicerId = (val: any) => {
    setInputValue(val);
    dispatch(updateGlobalSearchMappingValue(val.trim()));
  };
  const clearFilter = () => {
    dispatch(showLoader());
    onboardingTableFilterConfig !== undefined &&
      dispatch(updateTableSelectedFilters(undefined));
    onboardingTableSortConfig !== undefined &&
      dispatch(updateTableSortOptions(undefined));
    onboardingTableSearchConfig !== undefined &&
      dispatch(updateTableSearchOptions(undefined));
    dispatch(updateGlobalSearchMappingValue(""));
    setInputValue("");
    setTimeout(() => {
      dispatch(hideLoader());
    }, 50);
  };
  // const handleExport = () => {};

  const goBackToServicerOnbaordingPage = () => {
    navigate("/asset/home?tab=1");
  };

  return (
    <div className={classes.root} data-testid="loanlist-container">
      {/* header section */}
      <div style={{ position: "relative", zIndex: 999 }}>
        <section>
          <Header
            value={4}
            setTabValue={setTabView}
            showNotificationIcon={false}
          />
        </section>
        <section style={{ paddingTop: "60px" }}>
          <div className={drawClasses.topInsightContainer}>
            <div>
              <Grid item container xs={12} sm={12} md={12} xl={12}>
                <Grid item container xs={12} sm={12} md={12} xl={12}>
                  <Box
                    className="button-typography-wrapper-left"
                    style={{ padding: "4px 0px", marginLeft: "10px" }}
                  >
                    <Button
                      variant="contained"
                      data-testid="back-button"
                      color="primary"
                      startIcon={<KeyboardArrowLeftIcon />}
                      onClick={goBackToServicerOnbaordingPage}
                    >
                      Back
                    </Button>
                    <span>Originator & Servicer Mapping</span>
                  </Box>
                </Grid>
                <Grid item container xs={7} sm={7} md={7} xl={7}>
                  <InputBase
                    type="search"
                    placeholder={"Search"}
                    inputProps={{ "aria-label": "Type Keyword" }}
                    className={`${drawClasses.searchBox} inputSearch`}
                    value={inputValue}
                    data-testid="onboarding-view-input"
                    autoFocus={false}
                    onChange={(event) => {
                      handleSearchForServicerId(event.target.value);
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon className={drawClasses.searchIcon} />
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        {inputValue !== "" && (
                          <ClearIcon
                            className={drawClasses.clearSearchIcon}
                            onClick={(event: any) => clearSearch()}
                          />
                        )}
                      </InputAdornment>
                    }
                  />
                  {(onboardingTableSearchConfig !== undefined ||
                    onboardingTableSortConfig !== undefined ||
                    (onboardingTableFilterConfig !== undefined &&
                      Object.values(onboardingTableFilterConfig).every(
                        (x: any) => x?.length !== 0
                      )) ||
                    onboardingSearchMappingValue !== "") && (
                    <Button
                      className={drawClasses.clearButton}
                      startIcon={<FilterFunnelIcon filled={true} />}
                      disableRipple
                      disableElevation
                      onClick={clearFilter}
                      data-testid="onboarding-view-clear-filter-btn"
                    >
                      Clear Filter
                    </Button>
                  )}
                </Grid>
                <Grid item container xs={5} sm={5} md={5} xl={5}>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{
                      justifyItems: "flex-end",
                      justifyContent: "flex-end"
                    }}
                  >
                    {/* <button
                      data-testid="onboarding-view-export"
                      className={`${drawClasses.exportContainer}`}
                      onClick={handleExport}
                    >
                      <img
                        className={drawClasses.image}
                        src={exportIcon}
                        alt="Export"
                      ></img>
                      Export
                    </button> */}
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <Box>
              <Container maxWidth={false}>
                <TableContainer
                  ref={scrollRef}
                  component={Paper}
                  className={drawClasses.boxTableContainer}
                >
                  <CustomTable
                    columnsData={servicerMappingColumns({})}
                    rowsData={tableData}
                    classes={classes}
                    isCollapsed={isCollapsed}
                    filtersOptions={filtersOptions}
                    tableFilterConfig={onboardingTableFilterConfig}
                    tableSortConfig={onboardingTableSortConfig}
                    tableSearchConfig={onboardingTableSearchConfig}
                    handleSearchChange={handleSearchChange}
                    onSort={onSort}
                    handleFilterToggle={handleFilterToggle}
                    handleCheckBoxChange={handleCheckBoxChange}
                    handleDateRangeFilter={handleDateRangeFilter}
                    showCheckBox
                    selectedLoans={onboardingSelectedMappings}
                    collapsColumnIndex={noOfFrozenCols - 1}
                    showCollapseButton={false}
                    expandColumnIndex={1}
                    onCollapseColumn={onCollapseColumn}
                    onRowSelect={handleSelectedLoan}
                    selectAllRows={handleSelectAll}
                    fewRowsSelected={fewRowsSelected}
                    setFewRowsSelected={setFewRowsSelected}
                    allSelected={
                      !!(
                        tableData.length &&
                        onboardingSelectedMappings.length === tableData.length
                      )
                    }
                    from={"servicerMappingListData"}
                    idKey={"originatorId"}
                  />
                </TableContainer>
              </Container>
            </Box>
          </div>
        </section>
      </div>
    </div>
  );
};
