/* eslint-disable @typescript-eslint/no-empty-function */
import { Typography, Checkbox, List, ListItem, Radio } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useMemo, useState } from "react";
import { useStyles } from "../create-loan/common-style/CompactView.style";
import { SearchBarForMobile } from "../create-loan/loan-lists/LoanListSubHeader/SearchBarForMobile";
import { ObjectType } from "../masterView/common";

const styleOverrides = makeStyles({
  root: {
    maxWidth: "none !important",
    backgroundColor: "white !important"
  }
});

export const CheckBoxList = (props: any) => {
  const classes = useStyles();
  const searchClass = styleOverrides();
  const {
    showSelectAll,
    optionsList = [],
    checkedOptions = [],
    filterKey,
    handleCheckBox,
    handleSelectAll,
    handleSearch = () => { },
    searchValue,
    showSearch,
    autoSort,
    maxHeight = "none",
    isMultiSelect = true
  } = props;

  const [inputValue, setInputValue] = useState("");

  const handleChange = (event: any) => {
    handleCheckBox(event, filterKey);
  };

  const handleSelectAllChange = (event: any) => {
    handleSelectAll(event, filterKey, optionsList);
  };

  const clearSearch = () => {
    handleSearch("", filterKey);
    setInputValue("");
  };

  const handlefilterSearch = (event: any) => {
    handleSearch(event.target.value, filterKey);
  };

  const updatedOptions = useMemo(() => {
    if (!showSearch) return optionsList;
    return optionsList.filter((option: ObjectType) => {
      const optionVal = option.label.toLowerCase();
      return optionVal.includes(inputValue.toLowerCase());
    });
  }, [optionsList, showSearch, inputValue]);

  const sortedList = useMemo(() => {
    if (!autoSort) return updatedOptions;
    return updatedOptions.sort((a: ObjectType, b: ObjectType): number => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
  }, [updatedOptions, autoSort]);

  const allSelected = useMemo(() => {
    return checkedOptions?.length === sortedList?.length;
  }, [checkedOptions, sortedList]);

  return (
    <div style={{ width: "100%" }}>
      {showSearch && (
        <div
          style={{
            background: "#f6f9fc",
            padding: "5px 24px",
            display: "flex"
          }}
        >
          <SearchBarForMobile
            setInputValue={setInputValue}
            inputValue={inputValue}
            searchInput={searchValue}
            handleSearch={handlefilterSearch}
            clearSearch={clearSearch}
            customClassName={searchClass.root}
            viewType={"notDetail"}
          />
        </div>
      )}
      {sortedList.length > 0 && (
        <List className={classes.filterTitle} style={{ maxHeight: maxHeight, overflow: "auto" }}>
          {showSelectAll && (
            <ListItem
              key={`${filterKey}-selectAll`}
              className={`vertical-checkbox ${classes.filtersAll}`}
            >
              <Checkbox
                id={`${filterKey}-selectAll`}
                checked={allSelected}
                value="selectAll"
                disableRipple
                onChange={handleSelectAllChange}
              />
              <Typography component="span" className={classes.selectAll}>
                All
              </Typography>
            </ListItem>
          )}
          {sortedList.map((option: ObjectType) => {
            return (
              <ListItem
                key={option.id}
                className={`vertical-checkbox ${classes.filters}`}
              >
                {isMultiSelect && <Checkbox
                  id={option.id}
                  checked={checkedOptions?.includes(option.id)}
                  value={option.id}
                  disableRipple
                  onChange={handleChange}
                />}
                {!isMultiSelect && <Radio
                  id={option.id}
                  checked={checkedOptions?.includes(option.id)}
                  style={{ padding: "0px", marginRight: "10px" }}
                  value={option.id}
                  disableRipple
                  onChange={handleChange}
                />}
                <Typography component="span" className={classes.filterKeys}>
                  {option.label}
                </Typography>
              </ListItem>
            );
          })}
        </List>
      )}
    </div>
  );
};
