import React, { useEffect, useMemo, useState } from "react";
import {
  LoanStage,
  ViewModeTextField,
  WarningModal
} from "@toorak/tc-common-fe-sdk";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MinimizableSection } from "../../form-components/MinimizableSection";
import {
  getAggregatePropertyFields,
  getPropertyAggregateFieldValue
} from "./configs/PropertyAggregateFields";
import { RootState } from "../../../stores/rootReducer";
import { EvaluationResultStore } from "../../../stores/EvaluationResults/EvaluationResults.reducer";
import {
  LoanAndPropertyReducerType,
  cancelAggregateModal,
  keepPrevReducerVal
} from "../../../stores/loanAndPropertyDetails/loanAndPropertyDetails.reducer";
import { EditModal } from "./EditModal";
import // closeAggregateModal,
// keepOldReducerValue
"../../../stores/loanAndPropertyDetails/aggregatePropertyTabActions";
import { getAudit } from "../../../utils/objectUtil";
import { getCookie } from "../../../utils/cookies";
import {
  savePropertyDetails,
  updatePropertyDetailsInRedux
} from "../../../stores/loanAndPropertyDetails/loanAndPropertyDetails.action";
import {
  formatPropertyForRedux,
  getPropertyOriginalValuesByLoanId,
  // GET_PROPERTY_DETAILS,
  updateEditComments
} from "../../create-loan.action";
import { reRunRules } from "../../../stores/EvaluationResults/EvaluationResults.action";
import {
  hideLoader,
  showLoader
} from "../../../stores/loaderAndException/loaderAndException.action";
import { formatFesPropertyDetailsForRedux } from "../../../stores/FrontEndSizer/fesLoanCreation.action";
import { getPropertyDetails } from "../../create-loan.reducer";

export const AggregatePropertyForm = (props: { isAllowedToEdit: boolean }) => {
  const { isAllowedToEdit } = props;
  const { loanType, loanId, loanStage } = useParams<any>() as any;
  const dispatch = useDispatch();
  const [editFields, setEditFields] = useState<boolean>(false);
  const [warningData, setWarningData] = useState<{
    header: string;
    message: string;
  } | null>(null);

  const fields = useMemo(() => {
    return getAggregatePropertyFields(loanType, loanStage);
  }, [loanType, loanStage]);
  useEffect(() => {
    dispatch(showLoader(true));
    const promise2 = getPropertyOriginalValuesByLoanId(loanId, loanStage);
    promise2
      .then((resp: any) => {
        updatePropertyDetailsInRedux(resp.data, dispatch);
        dispatch(hideLoader(true));
      })
      .catch((e) => {
        console.log(e);
        dispatch(hideLoader(true));
      });
    // eslint-disable-next-line
  }, [loanId, loanStage]);
  const { loanEvaluationResult, waiversCreated, overriddenValues } =
    useSelector<RootState, EvaluationResultStore>(
      (state) => state.evaluationResultsStore
    );
  const {
    propertyDetails,
    aggregatePropertyDetails,
    loanDetails,
    reducerCopy
  } = useSelector<RootState, LoanAndPropertyReducerType>(
    (state) => state.loanAndPropertyStore
  );
  const { thirtyYearLoanInformation, fesLoanInformation } = useSelector<
    RootState,
    any
  >((state) => state.createLoanStore?.loanDetails);

  const takeoutPartnerId = useMemo(() => {
    if (loanStage === LoanStage.fes) {
      return fesLoanInformation?.takeoutPartnerId;
    }
    return thirtyYearLoanInformation?.payload?.takeoutPartnerId;
  }, [loanStage, fesLoanInformation, thirtyYearLoanInformation]);

  const dataObjForCalc = {
    propertyDetails,
    loanEvaluationResult,
    aggregatePropertyDetails
  };
  const handleEditClick = () => {
    dispatch(keepPrevReducerVal());
    setEditFields(true);
  };
  function handleClose() {
    setEditFields(false);
    dispatch(cancelAggregateModal(true));
  }
  async function handleSave(comment: string) {
    try {
      dispatch(showLoader(true));
      let ruleFieldMap = {};
      fields.forEach((ele) => {
        if (ele.ruleFieldId) {
          ruleFieldMap = { ...ruleFieldMap, [ele.fieldId]: ele.ruleFieldId };
        }
      });
      const auditData = getAudit({
        oldObj: reducerCopy?.propertyDetails,
        newObj: propertyDetails,
        keyPrefix: "data.properties",
        comment: comment || "Edited from aggregate property tab.",
        updatedById: getCookie("person_id") || "",
        ruleFieldMap,
        getChangedFieldIds: true
      });
      const { auditObject, changedFields, changedRuleFields } = auditData;
      // if there is no change, no need to call any APIs
      if (auditObject?.length) {
        await savePropertyDetails(loanId, loanType, loanStage, propertyDetails);
        dispatch(updateEditComments(`${loanId}_${loanStage}`, auditObject));
        dispatch(
          reRunRules({
            changedFieldsArr: changedFields,
            changedRuleFields,
            waiversCreated,
            loanId,
            loanStage,
            loanType,
            overriddenValues,
            loanData: loanDetails,
            propertyData: propertyDetails,
            ruleGroup: [],
            ruleField: [],
            evaluationData: loanEvaluationResult,
            takeoutPartnerId
          })
        );
        const propertyDetailsCopy = [...propertyDetails];
        const formattedPropertyData =
          loanStage === LoanStage.fes
            ? formatFesPropertyDetailsForRedux(propertyDetailsCopy)
            : formatPropertyForRedux(propertyDetailsCopy, loanType);
        // dispatch({
        //   type: GET_PROPERTY_DETAILS,
        //   payload: {
        //     propertyDetails: formattedPropertyData,
        //     thirtyYearLoanPropertyDetails: formattedPropertyData
        //   }
        // });
        dispatch(
          getPropertyDetails({
            propertyDetails: formattedPropertyData,
            thirtyYearLoanPropertyDetails: formattedPropertyData
          })
        );

        setEditFields(false);
        dispatch(cancelAggregateModal(false));
      } else {
        // setEditFields(false);
        // dispatch(cancelAggregateModal(false));
        setWarningData({
          header: "Information",
          message: "There is no edit. Please update value before saving."
        });
      }
      dispatch(hideLoader(true));
    } catch (error) {
      setEditFields(false);
      dispatch(cancelAggregateModal(true));
      dispatch(hideLoader(true));
      setWarningData({
        header: "Error",
        message:
          "Sorry, something went wrong. Please try again after some time."
      });
    }
  }
  return (
    <>
      {warningData && (
        <WarningModal
          isOpen
          handleClose={() => {
            setWarningData(null);
          }}
          header={warningData.header}
          body={warningData.message}
        />
      )}
      {editFields && (
        <EditModal
          handleClose={handleClose}
          handleSave={handleSave}
          loanType={loanType}
          loanStage={loanStage}
          data={{ ...dataObjForCalc }}
          askForComment
        />
      )}
      <MinimizableSection
        sectionId="aggregate-property-info"
        sectionLabel="Aggregate Property Details"
        showCalculatedOutputTag={false}
        editIconProps={{
          showEditIcon: isAllowedToEdit,
          handleOnClick: handleEditClick
        }}
      >
        <Grid
          container
          spacing={4}
          style={{
            padding: 24
          }}
        >
          {fields.map((ele) => {
            const { fieldLabel, fieldType, getValue } = ele;
            return (
              <Grid container item xs={12} sm={4} md={3}>
                <ViewModeTextField
                  label={fieldLabel}
                  value={getPropertyAggregateFieldValue(getValue, fieldType, {
                    ...dataObjForCalc,
                    fieldType,
                    isAggregate: true
                  })}
                />
              </Grid>
            );
          })}
        </Grid>
      </MinimizableSection>
    </>
  );
};
