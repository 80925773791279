import React from "react";
import { TableHead } from "@mui/material";
import { TableRowComponent } from "./TableRowComponent";

export const TableHeader = (props: any) => {
  const { data, style, colSpan, ...rest } = props;
  return (
    <TableHead>
      <TableRowComponent rowList={data} style={style} colSpan={colSpan} header {...rest}/>
    </TableHead>
  );
};
