import React, { useEffect, useMemo, useState } from "react";
import { Table as MuiTable, TableBody, TableHead } from "@mui/material";
import { ObjectType } from "./common";
import { ZeroStateLoan } from "../create-loan/loan-lists/DetailsView/ZeroStateLoan";
import { TableCustomRow } from "./TableRow";
import { TableHeader } from "./TableHeader";

// eslint-disable-next-line import/no-default-export
export default function CustomTable(props: any) {
  const {
    customTableRowStyle = {},
    rowsData,
    classes,
    isCollapsed,
    filtersOptions,
    tableFilterConfig,
    tableSearchConfig,
    tableSortConfig,
    filtersLoader,
    onSort,
    handleFilterToggle,
    handleCheckBoxChange,
    handleDateRangeFilter,
    handleSearchChange,
    idKey = "toorakLoanId",
    handleAutoCompleteSearch = () => {
      /* default value */
    },
    onCollapseColumn,
    showCollapseButton,
    columnsData,
    collapsColumnIndex,
    expandColumnIndex,
    showNoDataMsg,
    overRideStyles,
    onSelectedColor = "#fff9d9",
    onRowSelect,
    showCheckBox,
    selectAllRows,
    allSelected,
    selectedLoans,
    fewRowsSelected,
    disableSelectAll,
    handleUpdateSelectedCheckboxes = () => {
      /* default value */
    },
    disableRowSelection,
    from,
    selectAllTootltipText,
    handleDropdownSelection,
    onLinkClick,
    editClick,
    deleteClick
  } = props;

  const gridTemplate = useMemo(() => {
    const defaultColWidth = showCheckBox ? "50px " : "";
    return columnsData.reduce((prev: string, next: any) => {
      return `${prev}${next?.width ? `${next.width}px ` : "1fr "}`;
    }, defaultColWidth);
  }, [columnsData, showCheckBox]);

  const updatedColumns = useMemo(() => {
    if (isCollapsed) {
      const cols: ObjectType[] = [];
      columnsData.forEach((col: ObjectType) => {
        const temp = { ...col };
        if (col.frozen && !col.alwaysFrozen) {
          temp.frozen = false;
          cols.push(temp);
        } else {
          cols.push(temp);
        }
      });
      return cols;
    }
    return columnsData;
  }, [isCollapsed, columnsData]);
  
  const [
    lastSelectedRowSerialNumber,
    setlastSelectedRowSerialNumber
  ] = useState([-1]);
  const [sequenceRows, setSequenceRows] = useState<number[]>([]);
  const e = {
    target: {
      checked: true
    }
  };
  useEffect(() => {
    if (!onRowSelect) return;
    const rows: any = [];
    const ids: any = [];
    // eslint-disable-next-line no-unused-expressions
    rowsData?.map((row: any, k: number) => {
      if (sequenceRows.includes(k) && !selectedLoans.includes(row?.[idKey])) {
        // onRowSelect(e, [row?.[idKey]], [row]);
        ids.push(row?.[idKey]);
        rows.push(row);
      }
    });
    onRowSelect(e, ids, rows);
    setSequenceRows([]);
    setlastSelectedRowSerialNumber([-1]); // if user press shift click on first attempt, selects from start till selected row
    // eslint-disable-next-line
  }, [sequenceRows.length > 0]);

  const updateLastSelectedRowSerialNumber = (val: any, operation: string) => {
    if (operation === "push") {
      setlastSelectedRowSerialNumber(val);
    }
    if (operation === "pop") {
      setlastSelectedRowSerialNumber([]);
    }
  };

  return (
    <MuiTable component="div" className={classes?.table ?? ""}>
      <TableHead component="div" className={classes?.stickyTopZero}>
        <TableHeader
          classes={classes}
          columns={updatedColumns}
          gridTemplate={gridTemplate}
          customTableRowStyle={customTableRowStyle}
          showCheckBox={showCheckBox}
          selectAllRows={selectAllRows}
          allSelected={allSelected}
          fewRowsSelected={fewRowsSelected}
          isCollapsed={isCollapsed}
          showCollapseButton={showCollapseButton}
          collapsColumnIndex={collapsColumnIndex}
          expandColumnIndex={expandColumnIndex}
          handleCheckBoxChange={handleCheckBoxChange}
          handleFilterToggle={handleFilterToggle}
          tableSearchConfig={tableSearchConfig}
          filtersOptions={filtersOptions}
          tableFilterConfig={tableFilterConfig}
          filtersLoader={filtersLoader}
          overRideStyles={overRideStyles}
          handleDateRangeFilter={handleDateRangeFilter}
          handleSearchChange={handleSearchChange}
          handleUpdateSelectedCheckboxes={handleUpdateSelectedCheckboxes}
          handleAutoCompleteSearch={handleAutoCompleteSearch}
          tableSortConfig={tableSortConfig}
          onSort={onSort}
          onCollapseColumn={onCollapseColumn}
          disableSelectAll={disableSelectAll}
          selectAllTootltipText={selectAllTootltipText}
        />
      </TableHead>
      {(rowsData?.length !== 0 || !showNoDataMsg) && (
        <TableBody component="div" className={classes?.tableBody ?? ""}>
          {rowsData?.map((row: any, k: number) => (
            <TableCustomRow
              // key={row[idKey]} // commenting out because it will give issues if searched with the same loan which has duplicates
              gridTemplate={gridTemplate}
              customTableRowStyle={customTableRowStyle}
              classes={classes}
              row={row}
              columns={updatedColumns}
              isCollapsed={isCollapsed}
              showCollapseButton={showCollapseButton}
              collapsColumnIndex={collapsColumnIndex}
              expandColumnIndex={expandColumnIndex}
              idKey={idKey}
              onSelectedColor={onSelectedColor}
              showCheckBox={showCheckBox}
              onRowSelect={onRowSelect}
              handleDropdownSelection={handleDropdownSelection}
              onLinkClick={onLinkClick}
              editClick={editClick}
              deleteClick={deleteClick}
              selected={selectedLoans && selectedLoans.includes(row[idKey])}
              disableRowSelection={disableRowSelection}
              from={from}
              rowWithSerialNumber={{ serialNumber: k, row }}
              lastSelectedRowSerialNumber={lastSelectedRowSerialNumber}
              updateLastSelectedRowSerialNumber={
                updateLastSelectedRowSerialNumber
              }
              setSequenceRows={setSequenceRows}
            />
          ))}
        </TableBody>
      )}
      {rowsData?.length === 0 && showNoDataMsg && (
        <ZeroStateLoan
          textToShow="There are no loans available!"
          secondTextToShow=""
        />
      )}
    </MuiTable>
  );
}
