import { ObjectType } from "../masterView/common";
import { isLATUser } from "./AccessManagement";

const monetaryColumns: string[] = [
  "loan.loanEconomics.originalLoanAmount.raw",
  "loan.loanEconomics.originalMaximumLoanAmount.raw",
  "loan.loanEconomics.cutOffDateLoanAmount.raw",
  "loan.loanEconomics.cutOffDateMaximumLoanAmount.raw",
  "loan.loanEconomics.totalBudgetAmount.raw",
  "loan.loanEconomics.cashOutAmount.raw",
  "loan.loanEconomics.financedBudgetAmount.raw",
  "loan.loanEconomics.subsequentPeriodicCap.raw",
  "loan.loanEconomics.grossArmMargin.raw",
  "loan.loanEconomics.currentLoanBalance.raw"
];
const propertyColumns: string[] = [
  "properties.propertyinfo.postRehabSquareFootage",
  "properties.propertyinfo.propertyType",
  "properties.propertyinfo.preRehabSquareFootage",
  "properties.propertyinfo.changeInUseCase",
  "properties.propertyinfo.propertyAcquisitionDate",
  "properties.propertyinfo.propertyUnits",
  "properties.propertyinfo.recentPropertyValuationDate",
  "properties.propertyinfo.squareFootage",
  "properties.propertyUnit[0].rentDescription"
];
const propertyMonetaryColumns: string[] = [
  "properties.propertyEconomics.costBasis",
  "properties.propertyEconomics.annualPropertyTaxes",
  "properties.propertyEconomics.originalAsRepairedAppraisedValue",
  "properties.propertyEconomics.originalAsIsAppraisalValue",
  "properties.propertyEconomics.rentalCashflowRatio",
  "properties.propertyEconomics.insurance",
  "properties.propertyEconomics.grossPotentialRent",
  "properties.propertyEconomics.assignmentFees",
  "properties.propertyinfo.occupiedPercentage",
  "properties.propertyEconomics.annualHazardInsurance",
  "properties.propertyUnit[0].annualMarketRent",
  "properties.propertyEconomics.annualHoaFee",
  "properties.propertyEconomics.annualFloodInsurance",
  "properties.propertyEconomics.appraisalReportedNoiNcf"
];

const numberColumns: string[] = [
  "loan.loanEconomics.initialRateAdjustmentPeriod.raw",
  "loan.loanEconomics.costToLoanRatio.raw",
  "loan.loanDetailId.loanSummaryId.monthlyTaxAmount.raw",
  "loan.loanDetailId.loanSummaryId.monthlyInsuranceAmount.raw",
  "loan.loanDetailId.loanSummaryId.updatedEscrowBalance.raw",
  "loan.loanDetailId.loanSummaryId.nextPaymentDue.raw"
];
const taskColumns: ObjectType = {
  "tasks.tag.assigneePartyId.raw": "tag",
  "tasks.review.assigneePartyId.raw": "review",
  "tasks.reconcile.assigneePartyId.raw": "reconcile",
  "tasks.budgetReview.assigneePartyId.raw": "budgetReview",
  "tasks.budgetApprove.assigneePartyId.raw": "budgetApprove"
};
const customColumns: string[] = [
  "loan.loanEconomics.paymentAdjustmentFrequency.raw",
  "loan.loanEconomics.initialPeriodicCap.raw",
  "loan.loanEconomics.initialPayAdjustmentPeriod.raw"
];
const otherCustomColumns: string[] = [
  "loan.loanDetailId.loanSummaryId.taxesandInsuranceDue.raw",
  "numberOfGuarantors",
  "result.loanResult.loanEconomics.dscr",
  "loan.loanEconomics.rateAdjustmentFrequency.raw",
  "loan.loanEconomics.interestOnlyPeriod.raw",
  "properties.propertyEconomics.purchasePrice.raw",
  "loan.loanDetailId.loanSummaryId.taxes.raw",
  "loan.loanDetailId.loanSummaryId.escrowFund.raw",
  "loan.loanEconomics.interestRateAsOfCutOffDate",
  "borrowerDetails.loanUser.originalCreditScoreMedian.raw",
  "borrowerDetails.loanUser.originalCreditScoreReportDate",
  "loan.loanEconomics.financedInterestReserve.raw",
  "fields.loanDynamicStatus.raw",
  "guarantors.raw",
  "loan.loanType.raw",
  "loan.loanState.raw",
  "loan.loanEconomics.totalOriginationAndDiscountPoints.raw",
  "loan.loanEconomics.lifeRateCap.raw",
  "result.loanResult.riskScores.riskBucket.raw",
  "result.loanResult.riskScores.totalAggregateRisk",
  "result.loanResult.loanCharacterisations.needEnhancedReview.raw"
];

export const getQueryForAggregations: any = {
  propertyType: (key: string, tbField: string) => {
    return {
      property: {
        nested: {
          path: "properties"
        },
        aggs: {
          [key]: {
            terms: {
              field: tbField,
              size: 100
            }
          }
        }
      }
    };
  },
  needEnhancedReview: (key: string, tbField: string) => {
    return {
      needEnhancedReview2: {
        terms: {
          script: {
            source:
              "if(doc['result.loanResult.loanCharacterisations.needEnhancedReview.raw'].size()>0){return doc['result.loanResult.loanCharacterisations.needEnhancedReview.raw'].value } else if(doc['result.loanResult.loanCharacterization.needEnhancedReview.raw']!=null && doc['result.loanResult.loanCharacterization.needEnhancedReview.raw'].size()>0){return doc['result.loanResult.loanCharacterization.needEnhancedReview.raw'].value}else {return ''}"
          },
          size: 5,
          order: { _key: "asc" }
        }
      }
    };
  }
};
export function getQueryForSort(sort: string, sortColumn: string) {
  if (numberColumns.includes(sortColumn)) {
    return [
      {
        _script: {
          type: "number",
          script: {
            lang: "painless",
            source: `if(doc['${sortColumn}']===null || doc['${sortColumn}'].size()===0|| doc['${sortColumn}'].value==='' ){ return 0;}return Double.parseDouble('0'+doc['${sortColumn}'].value)`
          },
          order: sort
        }
      }
    ];
  }
  if (monetaryColumns.includes(sortColumn)) {
    return [
      {
        _script: {
          type: "number",
          script: {
            source: `if(doc.containsKey('${sortColumn}') && doc['${sortColumn}'].size() >0) { return Double.parseDouble(doc['${sortColumn}'].value.replace(',','').replace('$','').replace('%',''));} else {return 0;}`
          },
          order: sort
        }
      }
    ];
  }
  if (propertyColumns.includes(sortColumn?.replace(".raw", ""))) {
    return [
      {
        [sortColumn]: {
          mode: "max",
          order: sort,
          nested: {
            path: "properties"
          }
        }
      }
    ];
  }
  if (propertyMonetaryColumns.includes(sortColumn)) {
    return [
      {
        [sortColumn]: {
          mode: "max",
          order: sort,
          filter: {
            nested: {
              path: "properties"
            }
          }
        }
      }
    ];
  }
  if (taskColumns[sortColumn]) {
    return [
      {
        _script: {
          type: "string",
          script: {
            source: `params._source?.tasks?.${taskColumns[sortColumn]}?.assigneePartyCustomer?.firstName+' '+params._source?.tasks?.${taskColumns[sortColumn]}?.assigneePartyCustomer?.lastName`
          },
          order: sort
        }
      }
    ];
  }
  if (customColumns.includes(sortColumn)) {
    return [
      {
        _script: {
          type: "number",
          script: {
            lang: "painless",
            source: `if(doc['${sortColumn}']===null || doc['${sortColumn}'].size()===0|| doc['${sortColumn}'].value==='' ){ return 0;}return Double.parseDouble('0'+doc['${sortColumn}'].value.replace(',', '').replace('$', ''))`
          },
          order: sort
        }
      }
    ];
  }
  if (otherCustomColumns.includes(sortColumn)) {
    if (
      sortColumn === "loan.loanDetailId.loanSummaryId.taxesandInsuranceDue.raw"
    ) {
      return [
        {
          "loan.loanDetailId.loanSummaryId.taxesandInsuranceDue.raw": sort
        }
      ];
    }
    if (sortColumn === "numberOfGuarantors") {
      return [
        {
          _script: {
            type: "number",
            script:
              "if(params._source?.guarantors!==null){return params._source.guarantors.length} return 0;",
            order: sort
          }
        }
      ];
    }
    if (sortColumn === "result.loanResult.loanEconomics.dscr") {
      return [
        {
          _script: {
            type: "number",
            script: {
              lang: "painless",
              source:
                "if(doc['result.loanResult.loanEconomics.dscr']===null || doc['result.loanResult.loanEconomics.dscr'].size()===0 ){ return 0;}return Double.parseDouble('0'+params._source.result.loanResult.loanEconomics.dscr)"
            },
            order: sort
          }
        }
      ];
    }
    if (sortColumn === "loan.loanEconomics.rateAdjustmentFrequency.raw") {
      return [
        {
          _script: {
            type: "number",
            script: {
              lang: "painless",
              source: `if(doc['loan.loanEconomics.rateAdjustmentFrequency.raw']===null || doc['loan.loanEconomics.rateAdjustmentFrequency.raw'].size()===0|| doc['loan.loanEconomics.rateAdjustmentFrequency.raw'].value==='' ){ return -1;}return Double.parseDouble('0'+doc['loan.loanEconomics.rateAdjustmentFrequency.raw'].value)`
            },
            order: sort
          }
        }
      ];
    }
    if (sortColumn === "loan.loanEconomics.interestOnlyPeriod.raw") {
      return [
        {
          _script: {
            type: "number",
            script: {
              lang: "painless",
              source: `if(doc['loan.loanEconomics.interestOnlyPeriod.raw']===null || doc['loan.loanEconomics.interestOnlyPeriod.raw'].size()===0|| doc['loan.loanEconomics.interestOnlyPeriod.raw'].value==='' ){ return -1;}return Double.parseDouble('0'+doc['loan.loanEconomics.interestOnlyPeriod.raw'].value)`
            },
            order: sort
          }
        }
      ];
    }
    // ownershipOfEntity//loanCharacterisation
    if (sortColumn === "properties.propertyEconomics.purchasePrice.raw") {
      return [
        {
          _script: {
            type: "number",
            script: {
              lang: "painless",
              source:
                "if(params._source?.properties!==null){ def s=0;for(x in params._source.properties){if(x.propertyEconomics!=null&&x.propertyEconomics.purchasePrice!=null){try{s=s+Double.parseDouble(x.propertyEconomics.purchasePrice.replace(',', '').replace('$', ''))} catch(Exception e){}}} return s;} return 0;"
            },
            order: sort
          }
        }
      ];
    }
    if (sortColumn === "loan.loanDetailId.loanSummaryId.taxes.raw") {
      return [
        {
          _script: {
            type: "number",
            script: {
              lang: "painless",
              source:
                "if(doc.containsKey('loan.loanDetailId.loanSummaryId.taxes.raw') && doc['loan.loanDetailId.loanSummaryId.taxes.raw'].size() >0) { return Double.parseDouble(doc['loan.loanDetailId.loanSummaryId.taxes.raw'].value.replace(',','').replace('$','').replace('%',''));} else {return 0;}"
            },
            order: sort
          }
        }
      ];
    }
    if (sortColumn === "loan.loanDetailId.loanSummaryId.escrowFund.raw") {
      return [
        {
          _script: {
            type: "number",
            script: {
              lang: "painless",
              source:
                "if(params._source.loan?.loanDetailId?.loanSummaryId?.escrowFund===null || params._source.loan?.loanDetailId?.loanSummaryId?.escrowFund==='' ){ return 0;}return Double.parseDouble('0'+doc['loan.loanDetailId.loanSummaryId.escrowFund.raw'].value)"
            },
            order: sort
          }
        }
      ];
    }
    if (sortColumn === "loan.loanEconomics.interestRateAsOfCutOffDate") {
      return [
        {
          _script: {
            type: "number",
            script: {
              lang: "painless",
              source:
                "if(params._source.loan?.loanEconomics?.interestRateAsOfCutOffDate===null){ return 0;}return Double.parseDouble('0'+params._source.loan?.loanEconomics?.interestRateAsOfCutOffDate)"
            },
            order: sort
          }
        }
      ];
    }
    if (
      sortColumn === "borrowerDetails.loanUser.originalCreditScoreMedian.raw"
    ) {
      return [
        {
          _script: {
            type: "number",
            script: {
              lang: "painless",
              source:
                "if(doc['borrowerDetails.loanUser.partyId.raw'].size()<=0){return -1;}if(((doc['borrowerDetails.customer.partyType.raw'].size()>0 && doc['borrowerDetails.customer.partyType.raw'].value == 'person') || doc['borrowerDetails.customer.person.lastName.raw'].size()>0 || doc['borrowerDetails.loanUser.originalCreditScoreMedian.raw'].size()>0 )&& doc['borrowerDetails.loanUser.originalCreditScoreMedian.raw'].value!=''){ try {return Double.parseDouble(doc['borrowerDetails.loanUser.originalCreditScoreMedian.raw'].value.replace(',',''));}catch(Exception e){}} else if(doc['guarantorDetails.loanUser.originalCreditScoreMedian.raw'].size()>0 && doc['guarantorDetails.loanUser.originalCreditScoreMedian.raw'].value!=''){ try {return Double.parseDouble(doc['guarantorDetails.loanUser.originalCreditScoreMedian.raw'].value.replace(',',''));}catch(Exception e){}} return -1;"
            },
            order: sort
          }
        }
      ];
    }
    if (
      sortColumn === "borrowerDetails.loanUser.originalCreditScoreReportDate"
    ) {
      return [
        {
          _script: {
            type: "number",
            script: {
              lang: "painless",
              source:
                "if(params._source.loan?.loanUserMap===null || params._source.loan?.loanUserMap===[] ||params._source.loan?.loanUserMap.size()===0 ){ return 0;} if(params._source.loan?.loanUserMap.length>0){ def isPrimaryUsers=[];def seq=50;for(x in params._source.loan.loanUserMap){ def lseq = x['loanUserSequence']!=null? Double.parseDouble(x['loanUserSequence']):0; if(x['loanUserType']=='Borrower' && x['originalCreditScoreReportDate'] !==null && seq>lseq){seq=lseq;isPrimaryUsers.add(x)} } if(isPrimaryUsers.length>0){return isPrimaryUsers[0]['originalCreditScoreReportDate'];} }return 0;"
            },
            order: sort
          }
        }
      ];
    }

    if (sortColumn === "loan.loanEconomics.financedInterestReserve.raw") {
      return [
        {
          _script: {
            type: "number",
            script: {
              source:
                "if(doc.containsKey('loan.loanEconomics.financedInterestReserve.raw') && doc['loan.loanEconomics.financedInterestReserve.raw'].size() >0) { try{ return Double.parseDouble(doc['loan.loanEconomics.financedInterestReserve.raw'].value.replace(',','').replace('$','').replace('%',''));}catch(Exception e){return 0;}} else {return 0;}"
            },
            order: sort
          }
        }
      ];
    }
    if (sortColumn === "fields.loanDynamicStatus.raw") {
      return [
        {
          _script: {
            type: "number",
            script: {
              source:
                "if(doc['loan.lastLoanStatusUpdateDate'].empty) { return 0} long days = ((new Date().getTime() - doc['loan.lastLoanStatusUpdateDate'].value.millis) / (1000*60*60*24)); return days"
            },
            order: sort
          }
        }
      ];
    }
    if (sortColumn === "guarantors.raw") {
      return [
        {
          _script: {
            script:
              "if(doc.containsKey('guarantors')){return doc['guarantors'].size();}else{return 0}",
            order: sort,
            type: "number"
          }
        }
      ];
    }
    if (sortColumn === "loan.loanType.raw") {
      return [
        {
          _script: {
            type: "string",
            script: {
              lang: "painless",
              source:
                "if(doc['loan.loanType.raw'].value=='30 Year Loan'){return 'Investor DSCR'} else doc['loan.loanType.raw'].value"
            },
            order: sort
          }
        },
        "_score"
      ];
    }
    if (sortColumn === "loan.loanState.raw") {
      return [
        {
          _script: {
            type: "string",
            script: {
              lang: "painless",
              source: !isLATUser()
                ? "if(doc['loan.loanState.raw'].value=='Purchased'){return 'FUNDED'} else if(doc['loan.loanState.raw'].value=='Excel Error' || doc['loan.loanState.raw'].value=='Excel Success'){return 'DATA ENTRY'} else if(doc['loan.loanState.raw'].value=='submit' && doc['loan.loanStage.raw'].value=='FES'){return 'PRELIMINARY SIZING'} else if(doc['loan.loanState.raw'].value=='submit' && doc['loan.loanStage.raw'].value!='FES'){return 'DATA ENTRY'} else doc['loan.loanState.raw'].value"
                : "if(doc['loan.loanState.raw'].value=='Excel Error' || doc['loan.loanState.raw'].value=='Excel Success'){return 'DATA ENTRY'} else if(doc['loan.loanState.raw'].value=='submit' && doc['loan.loanStage.raw'].value=='FES'){return 'PRELIMINARY SIZING'} else if(doc['loan.loanState.raw'].value=='submit' && doc['loan.loanStage.raw'].value!='FES'){return 'DATA ENTRY'} else doc['loan.loanState.raw'].value"
            },
            order: sort
          }
        },
        "_score"
      ];
    }
    if (
      sortColumn === "loan.loanEconomics.totalOriginationAndDiscountPoints.raw"
    ) {
      return [
        {
          _script: {
            type: "number",
            script: {
              source:
                "if(doc.containsKey('loan.loanEconomics.totalOriginationAndDiscountPoints.raw') && doc['loan.loanEconomics.totalOriginationAndDiscountPoints.raw'].size() >0) { try{ return Double.parseDouble(doc['loan.loanEconomics.totalOriginationAndDiscountPoints.raw'].value.replace(',','').replace('$','').replace('%',''));} catch(Exception e){}} else {return -1;}"
            },
            order: sort
          }
        }
      ];
    }

    if (sortColumn === "loan.loanEconomics.lifeRateCap.raw") {
      return [
        {
          _script: {
            type: "number",
            script: {
              source:
                "if(doc.containsKey('loan.loanEconomics.lifeRateCap.raw') && doc['loan.loanEconomics.lifeRateCap.raw'].size() >0) { try{return Double.parseDouble(doc['loan.loanEconomics.lifeRateCap.raw'].value.replace(',','').replace('$','').replace('%',''));} catch(Exception e){}} else {return -1;}   "
            },
            order: sort
          }
        }
      ];
    }
    if (sortColumn === "result.loanResult.riskScores.riskBucket.raw") {
      return [
        {
          _script: {
            type: "number",
            script: {
              lang: "painless",
              source:
                "if(params._source?.result?.loanResult?.riskScores?.riskBucket!==null){  try{return Double.parseDouble('0'+params._source?.result?.loanResult?.riskScores?.riskBucket);} catch(Exception e){}} return 0;"
            },
            order: sort
          }
        }
      ];
    }
    if (sortColumn === "result.loanResult.riskScores.totalAggregateRisk") {
      return [
        {
          _script: {
            type: "number",
            script: {
              lang: "painless",
              source:
                "if(params._source?.result?.loanResult?.riskScores?.totalAggregateRisk!==null){  try{return Double.parseDouble('0'+params._source?.result?.loanResult?.riskScores?.totalAggregateRisk);} catch(Exception e){}} return 0;"
            },
            order: sort
          }
        }
      ];
    }
    if (
      sortColumn ===
      "result.loanResult.loanCharacterisations.needEnhancedReview.raw"
    ) {
      return [
        {
          _script: {
            type: "string",
            script: {
              lang: "painless",
              source:
                "if(doc['result.loanResult.loanCharacterisations.needEnhancedReview.raw'].size()>0){return doc['result.loanResult.loanCharacterisations.needEnhancedReview.raw'].value } else if(doc['result.loanResult.loanCharacterization.needEnhancedReview.raw']!=null && doc['result.loanResult.loanCharacterization.needEnhancedReview.raw'].size()>0){return doc['result.loanResult.loanCharacterization.needEnhancedReview.raw'].value}else {return ''}"
            },
            order: sort
          }
        }
      ];
    }
  }
  return [{ [sortColumn]: sort }, "_score"];
}
