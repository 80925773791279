import React, { useRef, useState } from "react";
import { Theme, MenuItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useOutsideClick from "../../../../custom-hooks/useOutsideClick";

const useStyles = makeStyles((theme: Theme) => ({
  menuItemCSS: {
    cursor: "pointer",
    fontSize: 12,
    color: "#32325d",
    paddingBottom: 6,
    lineHeight: 2,
    fontWeight: 600
  },
  moreVertiIconBtn: {
    justifyContent: "center"
  }
}));
interface ActionBtnOptions {
  title: string;
  id: string;
}
export const MoreVerticalIcon = (props: {
  actionButton: ActionBtnOptions[];
  handleActionBtnClick: (data: ActionBtnOptions) => any;
}) => {
  const classes = useStyles();
  const [isMoreActionsVisible, setIsMoreActionsVisible] = useState(false);
  const { actionButton, handleActionBtnClick } = props;
  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    if (isMoreActionsVisible) setIsMoreActionsVisible(!isMoreActionsVisible);
  });

  const moreActionList = () => {
    let displayList = null;
    displayList = actionButton.map((item) => {
      return (
        <MenuItem
          onClick={() => {
            setIsMoreActionsVisible(false);
            handleActionBtnClick(item);
          }}
          role="button"
          onKeyDown={() => {
            setIsMoreActionsVisible(false);
          }}
          tabIndex={0}
          key={item.title}
          className={classes.menuItemCSS}
        >
          {item.title}
        </MenuItem>
      );
    });

    return displayList;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end"
      }}
    >
      <div
        className={classes.moreVertiIconBtn}
        // className="more-action-iconBtn"
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer"
        }}
        onClick={(e: any) => {
          e.stopPropagation();
          setIsMoreActionsVisible(!isMoreActionsVisible);
        }}
        role="button"
        // onKeyDown={() => {
        //   setIsMoreActionsVisible(!isMoreActionsVisible);
        // }}
        tabIndex={0}
      >
        <MoreVertIcon style={{ width: 16, height: 16 }} />
      </div>
      {isMoreActionsVisible && (
        <div
          ref={ref}
          style={{
            borderRadius: 4,
            boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.16)",
            backgroundColor: "#ffffff",
            padding: 0,
            paddingTop: 8,
            paddingBottom: 6,
            marginTop: 32,
            position: "absolute",
            zIndex: 1,
            left: "auto"
          }}
        >
          {moreActionList()}
        </div>
      )}
    </div>
  );
};
