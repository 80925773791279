import { createTheme, Theme, adaptV4Theme } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

export const themeCustom = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          right: "auto",
          bottom: "auto"
        },
        paper: {
          margin: 0,
          boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.16)"
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: "none !important"
        }
      }
    }
  }
});

export const textFieldCustom = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          color: "#32325d ! important"
        }
      }
    }
  }
});

export const commentsStyles: any = {
  rightHeader: {
    padding: "5px",
    backgroundColor: "#d9efff",
    fontSize: "10px",
    fontWeight: "bold",
    borderRadius: "5px"
  }
};

export const confirmModel = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      maxHeight: "640px",
      width: "500px",
      padding: "0px 20px"
    },
    label: {
      color: "#8897aa",
      fontWeight: 600,
      fontSize: "12px"
    },
    textAreaWrapper: {
      width: "100%",
      height: "149px",
      border: "1px solid #cad1d7",
      borderRadius: "4px"
    },
    textArea: {
      fontFamily: "sans-serif !important",
      width: "100%",
      height: "100%",
      padding: "14px 12px 14px 12px",
      resize: "none",
      border: "none",
      borderRadius: "4px",
      fontSize: "14px"
    },
    requiredLabel: {
      color: "#f75676"
    }
  })
);

export const purchaseAdviceStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogBox: {
      padding: "0px"
    },
    body: {
      minHeight: "370px",
      width: "620px",
      padding: "20px"
    },
    header: {
      fontSize: "16px",
      fontWeight: 600
    },
    closeIcon: {
      width: "16px",
      height: "16px",
      cursor: "pointer"
    },
    innerBody: {
      marginTop: "10px"
    },
    fileIcon: {
      height: "36px",
      width: "28px"
    },
    centerItem: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    textOne: {
      fontSize: "14px",
      fontStyle: "normal"
    },
    inputFileLable: {
      marginBottom: 0,
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0
    },
    checkBox: {
      "&.MuiCheckbox-colorSecondary.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.26) !important"
      }
    },
    condHeading: {
      fontSize: "14px",
      fontWeight: 600,
      color: "#32325d"
    },
    menuPaper: {
      marginTop: "10px",
      borderRadius: "4px",
      boxShadow: "0 2px 8px 0 rgb(0 0 0 / 16%)",
      background: "#ffffff"
    },
    optionPrimary: {
      textTransform: "capitalize"
    },
    textNormal: {
      color: "#32325d"
    },
    subHeaderText: {
      fontSize: "12px",
      fontWeight: 600
    },
    headerText: {
      fontSize: "14px",
      fontWeight: 600
    },
    downloadAllButton: {
      padding: "6px 14px",
      fontSize: "12px",
      fontWeight: 600
    }
  })
);

export const autoCompleteStyles = makeStyles((theme: Theme) =>
  createStyles({
    zeroMargin: {
      "&.MuiAutocomplete-hasPopupIcon": {
        margin: "0px !important",
        marginTop: "-6px !important",
      },
      "& + .MuiFormControl-root.MuiTextField-root": {
        paddingTop: 20
      },
      "& label": {
        marginBottom: 2,
      },
      "&.MuiAutocomplete-popupIndicator": {
        padding: "4px",
        marginRight: "0px !important"
      }
    },
    zeroMarginTextField: {
      marginTop: "0px !important",
      marginBottom: "0px !important"
    },
    zeroMarginTextInput: {
      marginTop: "0px !important",
      marginBottom: "0px !important",
      padding: "0 !important"
    },
    onHoverInputStyles: {
      "& input": {
        border: "none !important"
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "2px solid #5e72e4"
      }
    }
  })
);

export const moreActionListStyles = makeStyles((theme: Theme) =>
  createStyles({
    uploadButton: {
      margin: "0px",
      fontSize: "12px",
      color: "#32325d",
      textTransform: "none",
      fontFamily: "'Open Sans', sans-serif !important",
      padding: "12px 18px",
      width: "100%",
      justifyContent: "space-between !important",
      "&:hover": {
        fontWeight: 600,
        backgroundColor: "#f6f9fc"
      }
    },
    uploadButtonlableClass: {
      width: "100%",
      margin: "0px !important"
    }
  })
);
