import React from "react";
import {
  Box,
  ClickAwayListener,
  Paper,
  Popper,
  MenuList,
  IconButton,
  Badge
} from "@mui/material";
import { Theme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { FilterFunnelIcon } from "./MasterViewIcons";
import SearchFilter from "./SearchFilter";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: "1px solid rgb(255 255 255 / 22%)",
      backgroundColor: "rgb(15 14 14 / 22%)",
      color: "#fff",
      fontSize: 10,
      display: "flex",
      textAlign: "left",
      borderRadius: 8
    },
    appName: {
      width: 100
    },
    userAvatar: {
      width: 36,
      height: 36,
      backgroundColor: "#5e72e4",
      borderRadius: "50%"
    },
    menuRoot: {
      width: 275,
      // marginTop: 22,
      padding: 20,
      boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.16)"
    },
    iconMargin: {
      marginRight: 5
    },
    signOut: {
      padding: "20px 16px",
      borderTop: "1px solid #e2e4f1",
      display: "flex",
      alignCenter: "center",
      "& p": {
        marginLeft: 5
      }
    },
    menuList: {
      padding: 0
    },
    avatarWrap: {
      gap: "15px",
      padding: "20px 16px"
    },
    avatarRoot: {
      width: 36,
      height: 36,
      fontSize: 20,
      backgroundColor: "#002856"
    },
    badgeFilter: {
      height: "6px !important",
      width: "6px !important",
      backgroundColor: "#5566d6",
      zIndex: "auto",
      minWidth: "6px"
    }
  })
);

interface ColumnSearchFilterProps {
  columnName: string;
  onSearch: any;
  searchValue: string;
  overRideStyles?: any;
  filterTitle?: any;
  isFrozen: boolean;
}

const ColumnSearchFilter = (props: ColumnSearchFilterProps) => {
  const {
    columnName,
    onSearch = () => {
      /* default value */
    },
    filterTitle,
    searchValue = "",
    isFrozen
    // overRideStyles
  } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const changeZIndex = (ele: any, override?: boolean) => {
    const state = override ?? open;
    let headerEle = ele?.closest('.MuiTableCell-root');
    if (headerEle) {
      headerEle.style.zIndex = state ? isFrozen ? 1 : 0 : 2;
    }
  };

  const handleToggle = (e: any) => {
    changeZIndex(e?.target);
    setOpen(isOpen => !isOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    changeZIndex(anchorRef.current);
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      changeZIndex(event?.target);
      setOpen(false);
    } else if (event.key === "Enter") {
      changeZIndex(event?.target, true);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);
  return (
    <>
      <IconButton
        size="small"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={e => handleToggle(e)}
      >
        <Badge
          color="primary"
          variant="dot"
          invisible={!searchValue}
          classes={{ badge: classes.badgeFilter }}
        >
          <div style={{ margin: "2px 2px 0px 0px" }}>
            <FilterFunnelIcon filled={!!searchValue} />
          </div>
        </Badge>
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
        style={{ zIndex: 1, position: "absolute", top: isFrozen ? 55: 30, left: isFrozen ? 0 : "unset", right: 0, willChange: "transform" }}
      >
        <Paper
          classes={{
            root: classes.menuRoot
          }}
        >
          <ClickAwayListener onClickAway={handleClose as any}>
            <MenuList
              autoFocusItem={open}
              id="menu-list-grow"
              onKeyDown={handleListKeyDown}
              className={`${classes.menuList}`}
            >
              <Box>
                <SearchFilter
                  filterTitle={filterTitle}
                  searchValue={searchValue}
                  onSearch={onSearch}
                  columnName={columnName}
                  setOpenFilter={setOpen}
                />
              </Box>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};

export default ColumnSearchFilter;
