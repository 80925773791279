import {
  createReactRouterV6Options,
  ReactIntegration
} from "@grafana/faro-react";
import {
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType
} from "react-router-dom";

import {
  FetchTransport,
  TransportItem,
  APIEvent,
  ConsoleInstrumentation,
  ConsoleTransport,
  ErrorsInstrumentation,
  initializeFaro,
  getWebInstrumentations,
  SessionInstrumentation,
  WebVitalsInstrumentation,
} from "@grafana/faro-web-sdk";
import { TracingInstrumentation } from "@grafana/faro-web-tracing";
import { useEffect } from "react";
import eventEmitter from '../../eventEmitter';
import { getCookie } from "../../utils/cookies";
import { getConfig } from "../../config/config";

const appVersion = process.env.REACT_APP_VERSION || '1.0.0';  // Default if not set

// Custom Batching Transport extending FetchTransport
class BatchingTransport extends FetchTransport {
  private queue: TransportItem<APIEvent>[] = [];
  private batchInterval = 5000; // Send logs every 5 seconds
  private maxQueueSize = 50; // Send logs immediately if queue exceeds 10 logs

  constructor(url: string, apiKey: string) {
    super({ url, apiKey }); // Call the constructor of FetchTransport with config object

    // Start the interval to send logs
    setInterval(() => this.sendLogs(), this.batchInterval);
  }

  send(items: TransportItem<APIEvent>[]): Promise<void> {
    // Add all items to the queue
    this.queue.push(...items);

    // If the queue exceeds max size, send immediately
    if (this.queue.length >= this.maxQueueSize) {
      this.sendLogs();
    }

    // Since the original send method is asynchronous, return a resolved Promise
    return Promise.resolve();
  }

  private sendLogs() {
    if (this.queue.length === 0) {
      return;
    }

    const logsToSend = this.queue.splice(0, this.queue.length);

    // Use the parent class's send method to send the batched logs
    super.send(logsToSend).catch((err) => {
      console.error('Failed to send logs:', err);
      // Re-queue the logs on failure
      this.queue.unshift(...logsToSend);
    });
  }
}


const initialize = (url: string, apiKey: string, email: string, userId: string) => {
  if (!apiKey || !url || !email || !userId || (window as any).faroInstance) return;

  console.log('Initializing Faro with:', { apiKey, url, email, userId });

  const faro = initializeFaro({
    instrumentations: [
      ...getWebInstrumentations(),
      new TracingInstrumentation(),
      new ErrorsInstrumentation(),
      new WebVitalsInstrumentation(),
      new ConsoleInstrumentation({ disabledLevels: [] }), // Capture console.log
      new SessionInstrumentation(),
      new ReactIntegration({
        router: createReactRouterV6Options({
          createRoutesFromChildren,
          matchRoutes,
          Routes,
          useLocation,
          useNavigationType
        })
      })
    ],
    transports: [
      new BatchingTransport(url, apiKey), // Use the custom batching transport
      new ConsoleTransport() // Still log to the console
    ],
    app: {
      name: 'tc-web-app-service',
      version: appVersion
    }
  });

  faro.api.setUser({
    email,
    id: userId
  });

  (window as any).faroInstance = faro;
};

const useFaroInitializer = () => {
  useEffect(() => {
    const email = getCookie("email") as string;
    const personId = getCookie("person_id") as string;
    const config = getConfig();

    // Initial Faro initialization
    initialize(config.faroUrl, config.faroAPIKey, email, personId);
    
    const handleUserLogin = () => {
      const newEmail = getCookie("email") as string;
      const newPersonId = getCookie("person_id") as string;
      const newConfig = getConfig();
      initialize(newConfig.faroUrl, newConfig.faroAPIKey, newEmail, newPersonId);
    };

    eventEmitter.addEventListener('userLogin', handleUserLogin);

    // Cleanup
    return () => {
      eventEmitter.removeEventListener('userLogin', handleUserLogin);
    };
  }, []); // Empty dependency array since the initialization relies on fresh values on each render
};

export default useFaroInitializer;
