import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { DownloadIcon } from "../../../../Icons";
import { useStyles } from "./style";

export const NoPreview = (props: any) => {
  const classes = useStyles();
  const { url, name, encrypted } = props;

  const downloadFile = () => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", name); // any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const textToBeShown = url ? "No Preview Available" : "No Document Found";

  return (
    <Grid className={classes.noPreviewContainer}>
      <FileCopyOutlinedIcon className={classes.noFileIcon} />
      <Typography className={classes.noPreviewHeading}>
        {textToBeShown}
      </Typography>
      {encrypted ? (
        <div
          style={{
            textAlign: "center",
            fontSize: 16,
            fontWeight: "bold"
          }}
        >
          This PDF is encrypted and can be previewed only with a password.
        </div>
      ) : url ? (
        <Button
          variant="contained"
          data-testid="download-btn"
          className={classes.downloadBtn}
          onClick={downloadFile}
          color="info"
        >
          <span style={{ margin: "6px 8px 0 0" }}>
            <DownloadIcon color="#fff" />
          </span>{" "}
          Download File{" "}
        </Button>
      ) : (
        <></>
      )}
    </Grid>
  );
};
