/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Dialog,
  Grid,
  ThemeProvider,
  Typography,
  Theme,
  StyledEngineProvider
} from "@mui/material";
import { ExpandCollapse, MuiTheme, LoanStage } from "@toorak/tc-common-fe-sdk";
import React, { useEffect, useState } from "react";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { CreateLoanParamsBridgeLoan } from "@toorak/tc-common-fe-sdk";
import { BundleStatuses, LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import { useStyles } from "../common-style/loanWrapperSections.style";
import { SingleBorrowerSection } from "./SingleBorrowerSection";
import { RootState } from "../../stores/rootReducer";
import {
  borrowerFieldChanged,
  postLoanSuccess,
  valueEditing,
  primaryGuarantorOrBorrowerCheck,
  setAutoSaveCall,
  setActiveEditLoanSection
} from "../create-loan.reducer";
import {
  BorrowerInformation,
  CreateLocationStore,
  getEmptyBorrower
} from "../create-loan.reducerHelper";
import { delay, uuidV4, yyyymmdd } from "../../utils/utils";
import {
  // BORROWER_FIELD_CHANGED,
  postLoan,
  postLoanAutoSave,
  // POST_LOAN_SUCCESS,
  previousLoanState,
  // primaryGuarantorOrBorrowerCheck,
  // setAutoSaveCall,
  // SET_ACTIVE_EDIT_LOAN_SECTION,
  updateEditComments,
  updateBorrowerInfo,
  //valueEditing,
  editCommentMissing,
  validateForm,
  keepCreditEvalSync,
  fetchAndDispatchLoan,
  updateBridgeLoanDetails,
  getBorrowerObjectForLoanSave,
  getPrimaryGuarantorInfo
} from "../create-loan.action";
import editImage from "../../images/Edit.svg";
import {
  evaluateLoan,
  //GET_OVERRIDDER_VALUES,
  postOverrideOutput,
  getOverriddenOutputs,
  updateOverriddenInredux
} from "../../stores/EvaluationResults/EvaluationResults.action";
import { getEvaluationRequestBody } from "../evaluation-results/helpers/ruleRequestBody";
import {
  countErrors,
  includesPrimaryBorrowerOrGuarantor
} from "../ErrorCounter";
import { getBridgeLoanGuarantorInformationToPass } from "../CreateLoanHeader";
import { isLATUser, isOrigUser } from "../../utils/AccessManagement";
import { MergeRecursive } from "../../utils/formatChecks";
import { getCookie, removeCookie, setCookie } from "../../utils/cookies";
import { getLoanType } from "../../config/config";
import {
  hideLoader,
  showLoader
} from "../../stores/loaderAndException/loaderAndException.action";
import {
  getAffectedWaivers,
  onFirstPaymentDateOfLoanSave
} from "../../stores/WaiverRequest/WaiverRequest.action";

import {
  staticBundleStatusList,
  LDAndGOCommonField
} from "../purchase-and-settlements/constants";
import {
  getBundleLoanData,
  postBundlesEditHistory,
  // setBundleLoanData,
  updateBundleFields
} from "../../stores/SettlementAndPurchase/SettlementAndPurchase.action";
import { requestWaiverMessage } from "../../utils/constants";
import { ServicerOnboardingStore } from "../../stores/servicerOnboarding/servicerOnboarding.interface";
import { updateDataInRedux } from "../../stores/loanAndPropertyDetails/loanAndPropertyDetails.action";
import { getOverriderValues } from "../../stores/EvaluationResults/EvaluationResults.reducer";
import { setBundleLoanData } from "../../stores/SettlementAndPurchase/SettlementAndPurchase.reducer";

declare module "@mui/styles" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

let originalBorrowerObject: any;
// eslint-disable-next-line import/no-mutable-exports
export let commentObj: any = [];
export function prepareCommentObject(
  field: string,
  value: any,
  comment: string,
  clearComments?: boolean,
  setCommentObj?: any,
  enableReasonField?: boolean,
  reasonCommentObj?: any,
  valueChange?: boolean,
  clearCommentsForFields?: string[],
  fieldLabel?: string,
  sectionName?: string
) {
  if (clearComments && !clearCommentsForFields) {
    commentObj = [];
    return;
  }
  if (clearCommentsForFields?.length) {
    commentObj = commentObj.filter((obj: any) => {
      let fieldId = obj.field.split(".");
      fieldId = fieldId[fieldId.length - 1];
      return !clearCommentsForFields?.includes(fieldId);
    });
    return;
  }
  // commented because org also can add comments on reason field
  // if (!isLATUser()) {
  //   comment = "";
  // }
  //
  for (let i = 0; i < commentObj.length; i++) {
    if (commentObj[i].field === field) {
      if (valueChange) {
        commentObj[i].value = value;
        return;
      }
      if (comment.trim() === "") {
        commentObj.splice(i, 1);
        return;
      }

      commentObj[i].comment = comment;
      commentObj[i].value = value;
      return;
    }
  }
  if (comment.trim() === "" && !valueChange) {
    return;
  }
  const splitString: any = field.split(".");
  const tempFieldLabel: any = fieldLabel || splitString[splitString.length - 1].replace(/[A-Z]/g, (letter: string) => ` ${letter}`) || ""

  const fieldCommentObj = {
    field,
    value,
    comment,
    fieldLabel: `${tempFieldLabel[0].toUpperCase()}${tempFieldLabel.slice(1)}`, // Fixed the closing parenthesis here
    // prevValue:"2",
    updatedBy: getCookie("person_id")
  };
  const currentSectionName = sectionName || "Borrower Information";
  const tempCurrentCommentObj = commentObj?.[currentSectionName] || [];
  const tempCurrentObj = new Map([...tempCurrentCommentObj, fieldCommentObj]?.map((c: any) => [c.field, c]));
  commentObj[currentSectionName] = [...tempCurrentObj.values()];

  // if (enableReasonField && !isOrigUser()) {
    setCommentObj({ ...reasonCommentObj, [currentSectionName]: [...tempCurrentObj.values()] });
  // }
}
export let globalSaveOnFieldChange: any;
export let cancelForm: any;

export const getEditHistoryValue = (fieldName: string, value: any) => {
  const dateField = ["originationDate"];
  let updateValue = "";
  if (dateField.includes(fieldName)) {
    updateValue = yyyymmdd(value);
  }
  return updateValue || value;
};

export const getUpdatedCommonField = (fieldName: any) => {
  const fieldValue: any = {
    partyType: "accountName",
    default: fieldName
  };
  return fieldValue[fieldName] || fieldValue.default;
};

export const getEditHistoryPath = (loanID: string, fieldItem: string) => {
  const settlementPath = ["interestRateAsOfCutOffDate"];
  let path = "";
  if (settlementPath.includes(fieldItem)) {
    const modifyField =
      fieldItem === "interestRateAsOfCutOffDate"
        ? "currentInterestRate"
        : fieldItem;
    path = `data.result.loanResults.${loanID}.settlementResults.${modifyField}`;
  } else {
    const modifyField =
      fieldItem === "financedInterestReserve"
        ? "interestReserveAndOthers_wireToCohen"
        : fieldItem;
    path = `data.bundle.loans[${loanID}].${modifyField}`;
  }
  return path;
};

export const handleSettlementAfterEdit = (
  changedValueFields: any[],
  commentDataObj: any[],
  loanId: string,
  bundleId: string,
  bundleStatus: BundleStatuses,
  dispatch: any
) => {
  const settlementEditHistoryReqBody: any[] = [];

  const commonChangedFields = LDAndGOCommonField;
  const maskVal: any = {
    cutOffLoanAmount: "cutOffDateLoanAmount"
  };
  changedValueFields.forEach((ele: string) => {
    const fKey = maskVal[ele] || ele;
    if (commonChangedFields.includes(fKey)) {
      if (commentDataObj?.length) {
        const fields = getUpdatedCommonField(fKey);
        const settlementComment = commentDataObj.find((item) =>
          item.field.includes(fields)
        );
        settlementEditHistoryReqBody.push({
          ...settlementComment,
          value: getEditHistoryValue(fields, settlementComment.value),
          field: getEditHistoryPath(loanId, fields)
        });
      }
    }
  });
  if (!settlementEditHistoryReqBody.length) {
    return;
  }
  if (staticBundleStatusList.indexOf(bundleStatus) <= 6) {
    updateBundleFields(bundleId, {
      bundleStatus: BundleStatuses.SPAG
    }).then(() => {
      dispatch(showLoader());
      getBundleLoanData(loanId, isLATUser())
        .then((res: any) => {
          dispatch(setBundleLoanData(res));
          dispatch(hideLoader());
        })
        .catch((err: any) => {
          console.log(err);
          dispatch(hideLoader());
        });
    });
  }
  postBundlesEditHistory(bundleId, settlementEditHistoryReqBody, dispatch);
};

export interface LoanDetailsBorrowerInformationProps {
  isAllowedToEdit: boolean;
  isSelectedSection?: any;
  isServicerOnboarding?: boolean;
  isMandatoryServicerField?: any;
  servicerLoanType?: string;
  // isFormEditing?: boolean;
  setCommentObj?: any;
  reasonCommentObj?: any
}
export function LoanDetailsBorrowerInformation(
  props: LoanDetailsBorrowerInformationProps
) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loanId, loanType, loanStage } = useParams<{
    loanId: string;
    loanType: string;
    loanStage: LoanStage;
  }>() as any;
  const {
    isAllowedToEdit,
    isServicerOnboarding,
    isMandatoryServicerField,
    servicerLoanType,
    // isFormEditing,
    setCommentObj,
    reasonCommentObj
  } = props;
  const [isMinimized, setIsMinimized] = useState<boolean>(false);
  let {
    bridgeLoanBorrowerInformation,
    bridgeLoanInformation,
    bridgeLoanEconomics,
    bridgeLoanGuarantorInformation,
    propertyDetails,
    loanState,
    bridgeIsGroundUpLoan,
    loanConfig,
    loanRuleVersions,
    loanSummary
  } = useSelector<RootState, any>((state) => state.createLoanStore.loanDetails);
  const { onboardingLoanDetails } = useSelector<
    RootState,
    ServicerOnboardingStore
  >((state) => state.servicerOnboardingStore);

  if (isServicerOnboarding) {
    const {
      loanBorrowerInformation,
      loanInformation,
      loanEconomics,
      // loanState,
      loanGuarantorInformation
    } = onboardingLoanDetails;
    bridgeLoanBorrowerInformation = loanBorrowerInformation;
    bridgeLoanInformation = loanInformation;
    bridgeLoanEconomics = loanEconomics;
    loanState = onboardingLoanDetails.loanState;
    bridgeLoanGuarantorInformation = loanGuarantorInformation;
  }

  const { thirtyYearLoanInformation, thirtyYearLoanEconomics } = useSelector<
    RootState,
    any
  >((state) => state.createLoanStore.loanDetails);
  const { loanDetails } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );
  const { isFormEditing } = useSelector<RootState, any>((state) => state.loanDetailsStore);

  const [resetData, setResetData] = useState(false);
  const [
    BridgeLoanBorrowerInformationCorrect,
    setbridgeLoanBorrowerInformationCorrect
  ] = useState<any[]>([]);

  const { borrowerFieldChange } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );
  const bridgeLoanGuarantorInformationToPass =
    getBridgeLoanGuarantorInformationToPass(bridgeLoanGuarantorInformation);

  const { autoSave } = useSelector<RootState, CreateLocationStore>(
    (state) => state.createLoanStore
  );
  const overriddenValues = useSelector<RootState, any>(
    (state) => state.evaluationResultsStore.overriddenValues
  );

  const { takeoutPartnerId } = useSelector<RootState, any>(
    (state) =>
      state.createLoanStore?.loanDetails.thirtyYearLoanInformation.payload
  );

  const { loanEvaluationResult, isEvaluationPanelVisible, waiversCreated } =
    useSelector<RootState, any>((state) => state.evaluationResultsStore);

  const loanBundleDetails = useSelector<RootState, any>(
    (state) => state.spStore.loanBundleDetails
  );
  const bundleId: string =
    loanBundleDetails?.loanbundleData?.bundleDetails?.bundleId;
  const bundleStatus: BundleStatuses = loanBundleDetails?.bundleStatus;

  useEffect(() => {
    if (!bridgeLoanBorrowerInformation?.length && isServicerOnboarding) {
      setbridgeLoanBorrowerInformationCorrect([
        {
          payload: { billingAddress: "", borrowerType: "Entity" },
          borrowerId: 2121
        }
      ]);
    } else {
      setbridgeLoanBorrowerInformationCorrect(
        bridgeLoanBorrowerInformation || []
      );
    }
  }, [bridgeLoanBorrowerInformation]);

  function handleAddBorrower() {
    const borrowerId = uuidV4();
    const nextIndividualBorrower = bridgeLoanBorrowerInformation.find(
      (item: any) =>
        item.borrowerId !== borrowerId &&
        item.payload.borrowerType === "Individual"
    );
    const emptyBorrowerPayload = {
      ...getEmptyBorrower(loanType).payload,
      ...{
        isPrimaryBorrower:
          loanType === getLoanType[0].displayValue
            ? false
            : !nextIndividualBorrower
      }
    };
    const newBorrower = {
      borrowerId,
      errors: null,
      payload: emptyBorrowerPayload
    };
    dispatch(setAutoSaveCall(true));
    dispatch(updateBorrowerInfo(borrowerId, newBorrower));
  }
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    const hasPrimaryBorrowerOrGuarantor = includesPrimaryBorrowerOrGuarantor(
      bridgeLoanBorrowerInformation,
      bridgeLoanGuarantorInformation,
      loanType
    );
    if (hasPrimaryBorrowerOrGuarantor) {
      setOpen(true);
    } else {
      dispatch(primaryGuarantorOrBorrowerCheck(true));
    }
  };
  function setNonEdit(activeSection: string, operation: string) {
    // dispatch({
    //   type: SET_ACTIVE_EDIT_LOAN_SECTION,
    //   payload: {
    //     activeSection,
    //     addOrDelete: operation
    //   }
    // });
    dispatch(
      setActiveEditLoanSection({
        activeSection,
        addOrDelete: operation
      })
    );
  }
  cancelForm = setNonEdit;
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (resetData) {
      dispatch(
        previousLoanState(
          originalBorrowerObject,
          "bridgeLoanBorrowerInformation",
          false
        )
      );
      setResetData(!resetData);
    }
  });
  // useEffect(() => {
  //   if (
  //     loanState === LoanStatusEnum.DueDiligenceReview &&
  //     isFormEditing === true
  //   ) {
  //     initialEditState();
  //     dispatch(valueEditing(false));
  //     setEditable(!isFormEditing);
  //     // setResetData(true);
  //     // setCancelForm(!cancelForm);
  //     setNonEdit("gurrantor", "delete");
  //   }
  // }, [loanState]);

  const getLoanPayLoadData = () => {
    const data = {
      borrowerInformation: bridgeLoanBorrowerInformation,
      guarantorInformation: bridgeLoanGuarantorInformation,
      loanInformation: null,
      loanEconomics: null,
      loanRuleVersions,
      loanSummary
    };
    switch (loanType) {
      case `${getLoanType[0].displayValue}`:
        data.loanInformation = bridgeLoanInformation.payload;
        data.loanEconomics = bridgeLoanEconomics.payload;
        break;
      case `${getLoanType[1].displayValue}`:
        data.loanEconomics = thirtyYearLoanEconomics.payload;
        data.loanInformation = thirtyYearLoanInformation.payload;
        break;
      default:
        break;
    }
    return data;
  };

  const OnSaveFieldsSuccess = async (res: any) => {
    if (res !== "false") {
      // await delay(2000); // TODO: Fix the asynchronous cache issue TA-2280
      removeCookie("latestBorrowerSequence");
      dispatch(fetchAndDispatchLoan(loanId, loanStage, true));
      initialEditState();
      dispatch(valueEditing(false));
      // setEditable(!isFormEditing);
      setNonEdit("borrower", "delete");
    }
  };

  const saveOnFieldChange = async (
    changedValueFields: any,
    resultEdit?: any,
    extraValueEdited?: boolean,
    sectionName?: any,
    extraData?: any,
    onSuccessCallBack?: Function
  ) => {
    dispatch(showLoader());
    const initializer = { reRequestWaiverObject: {}, openWaivers: [] };
    let { reRequestWaiverObject, openWaivers }: any = initializer;
    let refreshWaiver: boolean = false;
    let overriddenValuesToUse = overriddenValues;
    const loanDetailsErrorCount =
      countErrors(loanDetails, loanType, loanStage, bridgeIsGroundUpLoan)
        ?.loanerrorCount || 0;
    let lopin: any;

    const isSettlementAvailable =
      (isLATUser() && staticBundleStatusList.indexOf(bundleStatus) >= 2) ||
      staticBundleStatusList.indexOf(bundleStatus) >= 3;

    if (loanDetailsErrorCount > 0) {
      dispatch(validateForm(true));
      dispatch(hideLoader());
      return Promise.resolve("false");
    }
    let totalFieldsEdit = changedValueFields?.length;

    if (resultEdit === "warehouseLine") {
      totalFieldsEdit = Object.keys(changedValueFields).length;
    }

    if (extraValueEdited) {
      if (JSON.stringify(changedValueFields).includes("isPrimary")) {
        totalFieldsEdit -= 1;
      }
    }
    const { DueDiligenceReview } = LoanStatusEnum;
    const isDueDiligenceReviewState =
      !isLATUser() && loanState === DueDiligenceReview;
    if (
      (isLATUser() && !resultEdit) ||
      resultEdit === "warehouseLine" ||
      (isDueDiligenceReviewState && !resultEdit)
    ) {
      let commentMissing = false;
      // eslint-disable-next-line
      commentObj.map((item: any) => {
        if (item.comment === "") {
          commentMissing = true;
        }
      });
      if (commentMissing || commentObj.length < totalFieldsEdit) {
        dispatch(editCommentMissing(true));
        dispatch(hideLoader());
        return Promise.resolve("false");
      }
    }

    if (resultEdit) {
      const newDs = {
        loanResult: {
          [loanId]: {
            [resultEdit]: changedValueFields
          }
        }
      };
      lopin =
        overriddenValuesToUse?.loanResult ||
        overriddenValuesToUse?.propertiesResults
          ? MergeRecursive(overriddenValuesToUse, newDs)
          : newDs;
      try {
        await postOverrideOutput(loanId, loanStage, lopin, takeoutPartnerId);
        // dispatch({
        //   type: GET_OVERRIDDER_VALUES,
        //   payload: {
        //     overidden: lopin
        //   }
        // });
        dispatch(
          getOverriderValues({
            overidden: lopin
          })
        );
      } catch (e) {
        console.error(e);
        dispatch(hideLoader());
        return Promise.resolve("false");
      }
    } else {
      ({ reRequestWaiverObject, openWaivers } = await getAffectedWaivers(
        changedValueFields,
        waiversCreated,
        loanId,
        loanStage,
        loanType
      ));
      refreshWaiver =
        (typeof reRequestWaiverObject === "object" &&
          Object.keys(reRequestWaiverObject)?.length) ||
        openWaivers.length;
      // skipping when no partner and loan is of dscr
      // https://toorakcapital.atlassian.net/browse/TA-4313
      if (
        refreshWaiver &&
        (loanType === getLoanType[0].displayValue ||
          (loanType === getLoanType[1].displayValue && takeoutPartnerId))
      ) {
        const resp = await getOverriddenOutputs(
          loanId,
          loanStage,
          takeoutPartnerId
        );
        overriddenValuesToUse = resp?.data;
        dispatch(updateOverriddenInredux(resp));
      }
    }
    //  dispatch(deleteWaiverOnEdit(affectedresultsList,waiversCreated));
    const data = getLoanPayLoadData();
    if (
      changedValueFields.includes("nextPaymentDueDate") ||
      changedValueFields.includes("accrualDate")
    ) {
      const newDs = {
        loanResult: {
          [loanId]: {
            settlementResults: {
              nextPaymentDueDate: extraData?.nextPaymentDueDate,
              accrualDate: extraData?.accrualDate
            }
          }
        }
      };
      if (!changedValueFields.includes("nextPaymentDueDate"))
        delete newDs.loanResult[loanId].settlementResults.nextPaymentDueDate;
      if (!changedValueFields.includes("accrualDate"))
        delete newDs.loanResult[loanId].settlementResults.accrualDate;
      lopin =
        overriddenValuesToUse?.loanResult ||
        overriddenValuesToUse?.propertiesResults
          ? MergeRecursive(overriddenValuesToUse, newDs)
          : newDs;
      try {
        overriddenValuesToUse = lopin;
        await postOverrideOutput(loanId, loanStage, lopin, takeoutPartnerId);
        // dispatch({
        //   type: GET_OVERRIDDER_VALUES,
        //   payload: {
        //     overidden: lopin
        //   }
        // });
        dispatch(
          getOverriderValues({
            overidden: lopin
          })
        );
      } catch (e) {
        console.error(e);
        dispatch(hideLoader());
        return Promise.resolve("false");
      }
    }
    let loanPayload: any = {
      loanId,
      loanType,
      loanConfig,
      borrowerInformation: bridgeLoanBorrowerInformation,
      guarantorInfo: bridgeLoanGuarantorInformationToPass
    };

    let gLoanType = "";
    if (loanType === getLoanType[0].displayValue) {
      gLoanType = "bridge";
      loanPayload = {
        ...loanPayload,
        ...bridgeLoanInformation.payload,
        ...bridgeLoanEconomics.payload
      };
    } else {
      gLoanType = "30year";
      loanPayload = {
        ...loanPayload,
        ...thirtyYearLoanInformation.payload,
        ...thirtyYearLoanEconomics.payload
      };
    }

    // To get primary garuantor info
    if (loanType === getLoanType[0].displayValue) {
      const borrowerInfo = getBorrowerObjectForLoanSave(
        loanPayload.borrowerInformation,
        true
      );
      const modPrimaryInfo = (loanPayload.guarantorInfo || []).map(
        (item: any) => {
          return {
            ...item,
            isPrimary: false
          };
        }
      );
      const loanUserMap = [...borrowerInfo, ...modPrimaryInfo];
      const gResp: any = await getPrimaryGuarantorInfo(
        loanUserMap,
        gLoanType,
        loanPayload?.selectedToorakProduct
      );
      loanPayload = {
        ...loanPayload,
        guarantorInfo: loanPayload.guarantorInfo.map((item: any) => {
          if (gResp?.primaryGuarantorSequence === item?.loanUserSequence) {
            return {
              ...item,
              isPrimary: true
            };
          }
          return { ...item, isPrimary: false };
        })
      };
    }

    await postLoan(loanPayload, false, true).then(
      async (response: CreateLoanParamsBridgeLoan) => {
        const isFirstPaymentDateOfLoan = changedValueFields.find(
          (item: string) => item === "firstPaymentDateOfLoan"
        );
        let pricingPaymentDueDate = "";
        if (isFirstPaymentDateOfLoan) {
          const res: any = await onFirstPaymentDateOfLoanSave(loanId);
          if (res.data && res?.data[0]) {
            pricingPaymentDueDate = res?.data[0].pricingPaymentDueDate;
            dispatch(
              updateBridgeLoanDetails({
                bridgeLoanInformation: {
                  payload: {
                    pricingPaymentDueDate: res.data[0]?.pricingPaymentDueDate
                  }
                }
              })
            );
            dispatch(
              updateDataInRedux(
                "loanInfo",
                "pricingPaymentDueDate",
                res.data[0]?.pricingPaymentDueDate
              )
            );
          }
        }
        await delay(1000);
        dispatch(keepCreditEvalSync(true));
        // dispatch({
        //   type: POST_LOAN_SUCCESS,
        //   payload: {
        //     reservation: response
        //   }
        // });
        dispatch(
          postLoanSuccess({
            reservation: response
          })
        );
        getEvaluationRequestBody(
          loanId,
          loanStage,
          data,
          loanEvaluationResult,
          propertyDetails,
          loanType
        ).then(({ evaluate, loanEvaluationRequest }) => {
          if (evaluate && loanEvaluationRequest) {
            loanEvaluationRequest.ruleAttributs = {
              ruleGroup: [],
              ruleField: [],
              ruleResult: []
            };
            loanEvaluationRequest.resultObject = resultEdit
              ? lopin
              : overriddenValuesToUse;
            if (pricingPaymentDueDate)
              (
                loanEvaluationRequest.loanFact.loan.loanInfo as any
              ).pricingPaymentDueDate = pricingPaymentDueDate;
            dispatch(
              evaluateLoan(
                loanEvaluationRequest,
                loanId,
                loanStage,
                true,
                false,
                propertyDetails,
                refreshWaiver,
                null,
                true
              )
            );
            if (changedValueFields?.length && isSettlementAvailable) {
              handleSettlementAfterEdit(
                changedValueFields,
                commentObj,
                loanId,
                bundleId,
                bundleStatus,
                dispatch
              );
            }
            if (
              sectionName === "guarantorInformation" ||
              sectionName === "borrowerInformation"
            ) {
              const tempResponse: any = JSON.parse(JSON.stringify(response));
              const loanUserType =
                sectionName === "guarantorInformation"
                  ? "Guarantor"
                  : "Borrower";
              const loanUserMapFilter = tempResponse?.loanUserMap.filter(
                (item: any) => item.loanUserType === loanUserType
              );
              const tempComment = commentObj.map((item: any) => {
                const latestUserMap =
                  loanUserMapFilter[loanUserMapFilter.length - 1].loanUserMapId;
                if (item.field.includes("[undefined]")) {
                  const modifyField = item.field.replace(
                    "[undefined]",
                    `[${latestUserMap}]`
                  );
                  // eslint-disable-next-line no-param-reassign
                  item.field = modifyField;
                }
                return item;
              });
              commentObj = tempComment;
            }
            if (resultEdit !== true) {
              dispatch(
                updateEditComments(`${loanId}_${loanStage}`, commentObj)
              );
              commentObj = commentObj.filter((obj: any) => {
                let fieldId = obj.field.split(".");
                fieldId = fieldId[fieldId.length - 1];
                return !changedValueFields?.includes(fieldId);
              });
            }
            dispatch(hideLoader());
            return Promise.resolve("true");

            //  resolve("null");
          }
        });
        onSuccessCallBack?.(response);
      }
    );
  };

  const initialEditState = () => {
    const latestBorrowerSequence = bridgeLoanBorrowerInformation.reduce(
      (prev: any, current: any) => {
        return Number(prev.payload.borrowerLoanUserSequence) >
          Number(current.payload.borrowerLoanUserSequence)
          ? prev
          : current;
      }
    );

    setCookie(
      "latestBorrowerSequence",
      latestBorrowerSequence.payload.borrowerLoanUserSequence
    );

    // dispatch({
    //   type: BORROWER_FIELD_CHANGED,
    //   payload: {
    //     borrowerChangedIds: ""
    //   }
    // });

    dispatch(
      borrowerFieldChanged({
        borrowerChangedIds: ""
      })
    );
  };

  globalSaveOnFieldChange = saveOnFieldChange;

  useEffect(() => {
    if (autoSave) {
      // dispatch(setAutoSaveCall(false));
      const data = {
        borrowerInformation: bridgeLoanBorrowerInformation,
        guarantorInformation: bridgeLoanGuarantorInformation,
        loanInformation: null,
        loanEconomics: null,
        loanRuleVersions,
        loanSummary
      };
      switch (loanType) {
        case `${getLoanType[0].displayValue}`:
          data.loanInformation = bridgeLoanInformation.payload;
          data.loanEconomics = bridgeLoanEconomics.payload;
          break;
        case `${getLoanType[1].displayValue}`:
          data.loanInformation = thirtyYearLoanEconomics.payload;
          data.loanEconomics = thirtyYearLoanInformation.payload;
          break;
        default:
          break;
      }
      dispatch(
        postLoanAutoSave(
          true,
          isEvaluationPanelVisible,
          loanId,
          loanStage,
          loanType,
          data,
          propertyDetails,
          loanEvaluationResult,
          loanConfig
        )
      );
    }
  }, [bridgeLoanBorrowerInformation]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={MuiTheme}>
        <div className={classes.container}>
          {/* Section Header Borrower  */}
          <Grid
            container
            spacing={3}
            className={classes.sectionWrapper}
            style={{ margin: "0" }}
          >
            <Grid item xs={12} container style={{ padding: "12px" }}>
              <Grid item xs={9} sm={11}>
                <Typography className={classes.guarantorHeadingTypography}>
                  Borrower Information
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sm={1}
                className={classes.headingImageContainer}
              >
                {/* {isAllowedToEdit && (
                  <img
                    className={
                      isFormEditing || !isEvaluationPanelVisible
                        ? classes.editImageDisplay
                        : classes.editImage
                    }
                    src={editImage}
                    alt="Edit Section"
                    onClick={() => {
                      initialEditState();
                      dispatch(valueEditing(true));

                      originalBorrowerObject = JSON.parse(
                        JSON.stringify(bridgeLoanBorrowerInformation)
                      );
                      // setEditable(!isFormEditing);
                      setNonEdit("borrower", "add");
                    }}
                    onKeyDown={() => {
                      initialEditState();
                      dispatch(valueEditing(true));
                      originalBorrowerObject = JSON.parse(
                        JSON.stringify(bridgeLoanBorrowerInformation)
                      );
                      // setEditable(!isFormEditing);
                      setNonEdit("borrower", "add");
                    }}
                  />
                )} */}
                <ExpandCollapse
                  isMinimize={isMinimized}
                  clicked={() => {
                    setIsMinimized(!isMinimized);
                  }}
                  keyDown={() => {
                    setIsMinimized(!isMinimized);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Section divider Borrower  */}
          {!isMinimized ? (
            <>
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  display: isMinimized ? "none" : ""
                }}
              >
                <div className={classes.divider} />
              </Grid>
              {/* Section Primary Borrower  */}
              <Grid xs={12}>
                {Boolean(BridgeLoanBorrowerInformationCorrect?.length) &&
                  BridgeLoanBorrowerInformationCorrect.map(
                    (item: BorrowerInformation, index: number) => {
                      return (
                        <Grid
                          xs={12}
                          className={classes.borrwerContainer}
                          style={
                            index !==
                            BridgeLoanBorrowerInformationCorrect.length - 1
                              ? { borderBottom: "solid 1px #f2f2f2" }
                              : {}
                          }
                        >
                          <SingleBorrowerSection
                            isMinimized={isMinimized}
                            borrowerObject={item}
                            isServicerOnboarding={isServicerOnboarding}
                            index={index}
                            borrowerCount={
                              BridgeLoanBorrowerInformationCorrect?.length
                            }
                            isEvaluationPanelVisible={isEvaluationPanelVisible}
                            isViewMode={
                              isMandatoryServicerField
                                ? true
                                : isEvaluationPanelVisible && !isFormEditing
                            }
                            hasIndividualBorrower={BridgeLoanBorrowerInformationCorrect.find(
                              (ele: any) => ele.payload.isPrimaryBorrower
                            )}
                            isMandatoryServicerField={isMandatoryServicerField}
                            servicerLoanType={servicerLoanType}
                            firstEntityBorrowerId={
                              BridgeLoanBorrowerInformationCorrect.find(
                                (ele: any) =>
                                  ele.payload.borrowerType === "Entity"
                              )?.borrowerId
                            }
                            setCommentObj={setCommentObj}
                            reasonCommentObj={reasonCommentObj}
                          />
                        </Grid>
                      );
                    }
                  )}
              </Grid>
              {/* Section divider Borrower  */}
              {!isEvaluationPanelVisible && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    display: isMinimized ? "none" : ""
                  }}
                >
                  <div className={classes.divider} />
                </Grid>
              )}
              {(!isEvaluationPanelVisible || isFormEditing) &&
                !isMinimized &&
                !isServicerOnboarding && (
                  <Grid
                    xs={12}
                    className={classes.sectionButtonWrapper}
                    style={{
                      padding: "24px 24px 24px 40px"
                    }}
                  >
                    <Button
                      variant="contained"
                      data-testid="download-button"
                      color="info"
                      size="small"
                      startIcon={<AddTwoToneIcon style={{ color: "#FFF" }} />}
                      onClick={handleAddBorrower}
                      className={classes.addBorrowerBtn}
                    >
                      Add Borrower
                    </Button>
                  </Grid>
                )}
            </>
          ) : null}
          {/* {isFormEditing && !   && (
            <Grid container item xs={12} sm={12} md={12} xl={12}>
              <Button
                variant="contained"
                // disabled={
                //   !secondaryBorrowerEdited && borrowerFieldChange?.length === 0    //LPP-6441
                // }
                color="info"
                className={
                  isFormEditing ? classes.savebutton : classes.editImageDisplay
                }
                onClick={() => {
                  handleOpen();
                }}
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="info"
                // data-testid="save-button"
                className={
                  isFormEditing ? classes.cancelButton : classes.editImageDisplay
                }
                // startIcon={<SaveOutlinedIcon />}
                // onClick={handleSave}
                onClick={() => {
                  initialEditState();
                  dispatch(valueEditing(false));
                  // setEditable(!isFormEditing);
                  setResetData(true);
                  // setCancelForm(!cancelForm);
                  setNonEdit("borrower", "delete");
                  prepareCommentObject("", "", "", true, setCommentObj, false, borrowerFieldChange);
                }}
              >
                Cancel
              </Button>
            </Grid>
          )} */}
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="duplicate-property-modal"
            aria-describedby="duplicate-property-modal-description"
          >
            <div style={{ padding: 20, width: 500, maxWidth: "100%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexGrow: 1,
                  alignItems: "center"
                }}
              >
                <div
                  style={{ fontSize: 14, fontWeight: 600, color: "#32325d" }}
                >
                  Warning
                </div>
                <CloseIcon
                  htmlColor="#414169"
                  style={{ width: 16, height: 16, cursor: "pointer" }}
                  onClick={() => {
                    handleClose();
                    // setEditable(!isFormEditing);
                    // setResetData(!resetData);
                    // setCancelForm(!cancelForm);
                  }}
                />
              </div>
              <div
                style={{
                  fontSize: 12,
                  lineHeight: 1.5,
                  color: "#32325d",
                  marginTop: 40
                }}
              >
                {requestWaiverMessage}
              </div>
              <div className={classes.modalDeleteButtonConatainer}>
                <Button
                  variant="contained"
                  color="primary"
                  data-testid="modal-delete-button"
                  className={classes.modalButton}
                  onClick={() => {
                    handleClose();
                    saveOnFieldChange(
                      borrowerFieldChange,
                      false,
                      true,
                      "borrowerInformation",
                      {},
                      OnSaveFieldsSuccess
                    );
                  }}
                >
                  Yes
                </Button>
                <Button
                  variant="outlined"
                  data-testid="modal-cancel-button"
                  className={classes.modalCancelButton}
                  onClick={() => {
                    removeCookie("latestBorrowerSequence");
                    initialEditState();
                    handleClose();
                    dispatch(valueEditing(false));
                    // setEditable(!isFormEditing);
                    setResetData(!resetData);
                    // setCancelForm(!cancelForm);
                    setNonEdit("borrower", "delete");
                    prepareCommentObject("", "", "", true, setCommentObj, false, borrowerFieldChange);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Dialog>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
