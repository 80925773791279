import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import * as React from "react";
import { useParams } from "react-router-dom";
import { DrawAmountDetails } from "./DrawAmountDetails";
import { DrawDetailsLoanEconomics } from "./DrawDetailsLoanEconomics";
import { DrawLoanInformation } from "./DrawLoanInformation";
import { useDrawsStyles } from "../Draws.styles";
// import { DocsFormWrapper } from "../../DocumentsUpload/DocsFormWrapper";
import { isOrigUser } from "../../../utils/AccessManagement";
import { drawLeftTabNames } from "../Constant";
import { BankDetailsWrapper } from "./BankDetailsWrapper";
// import { InternalNotesTabs } from "../InternalNotes/InternalNotesTabs";
import { ProvisionHistoryWrapper } from "../Provision/ProvisionHistoryWrapper";
import { DrawStage } from "@toorak/tc-common-fe-sdk";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
};

const DocsFormWrapper = React.lazy(() =>
  import("../../DocumentsUpload/DocsFormWrapper").then((module) => ({
    default: module.DocsFormWrapper
  }))
);
const InternalNotesTabs = React.lazy(() =>
  import("../InternalNotes/InternalNotesTabs").then((module) => ({
    default: module.InternalNotesTabs
  }))
);

export const DrawTabs = ({
  selectedLoanId,
  drawObj,
  isAmounteditable,
  isSubmitClicked,
  showErrorMsg,
  isBulkDraw,
  isEditing,
  provisionHistory
}: any) => {
  const [value, setValue] = React.useState(0);
  const { drawId } = useParams<any>() as any;
  const isOrig = isOrigUser();
  const classes = useDrawsStyles();

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const internalsEnabled = () => {
    return (
      drawObj?.stage !== undefined && drawObj.stage.toUpperCase() !== "DRAFT"
    );
  };

  const noInternalTab = drawLeftTabNames.filter(
    (it) => it.label !== "Internal Discussions"
  );
  let tabNames = isOrig
    ? noInternalTab
    : drawObj?.stage === DrawStage.draft
    ? noInternalTab
    : drawLeftTabNames;
  const fieldWidth: 2 | 3 = drawObj?.stage === DrawStage.draft ? 2 : 3;
  return (
    <Box style={{ width: "100%", marginLeft: 20 }}>
      <Box style={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          aria-label="draw tabs"
          TabIndicatorProps={{
            style: {
              left: value === 0 ? 0 : value === 1 ? 110 : 220,
              width: value === 2 ? 170 : 110
            }
          }}
        >
          {tabNames.map((item: any, idx: number) => (
            <div className="tab-menu">
              <Tab
                label={item.label}
                {...a11yProps(idx)}
                className={`${idx === value ? classes.tabSelected : null}`}
                onClick={(e) => {
                  handleChange(e, idx);
                }}
              />
            </div>
          ))}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <DrawLoanInformation
          isSubmitClicked={isSubmitClicked}
          showErrorMsg={showErrorMsg}
          isBulkDraw={isBulkDraw}
          fieldWidth={fieldWidth}
        />

        <DrawDetailsLoanEconomics drawObj={drawObj} fieldWidth={fieldWidth} />
        {!isOrig && (
          <ProvisionHistoryWrapper provisionHistory={provisionHistory} />
        )}
        <DrawAmountDetails
          drawObj={drawObj}
          isAmounteditable={isAmounteditable}
          selectedLoanId={selectedLoanId}
          isSubmitClicked={isSubmitClicked}
          showErrorMsg={showErrorMsg}
          fieldWidth={fieldWidth}
        />
        <BankDetailsWrapper
          selectedLoanId={selectedLoanId}
          isSubmitClicked={isSubmitClicked}
          drawObj={drawObj}
          isEditing={isEditing}
          fieldWidth={fieldWidth}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DocsFormWrapper drawId={drawId} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Grid
          item
          container
          xs={12}
          className={classes.itemContainer}
          data-testid="draw-docs-wrapper"
          style={{ padding: "0px 5px" }}
        >
          {internalsEnabled() && <InternalNotesTabs />}
        </Grid>
      </TabPanel>
    </Box>
  );
};
