import { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const ErrorModalStyle = makeStyles((theme: Theme) =>
  createStyles({
    backDrop: { zIndex: 9999, backgroundColor: "rgba(65,65,105, 0.25)" },
    modal: {
      backgroundColor: "#ffffff",
      minWidth: "30%",
      maxHeight: "60%",
      overflow: "hidden",
      overflowY: "scroll"
    },
    flashMessageContainer: {
      backgroundColor: "#ffffff",
      padding: 10,
      borderRadius: 4
    },
    inlineMessageDiv: {
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      position: "fixed",
      top: "15%",
      right: 14,
      zIndex: 999999,
      backgroundColor: "#ffffff",
      borderRadius: 4,
      padding: "15px 10px 15px 15px",
      maxWidth: 290
    },
    errorHeader: {
      paddingLeft: 10,
      fontSize: 16,
      verticalAlign: "top"
    },
    errorBody: {
      fontSize: 14,
      maxWidth: 400,
      marginTop: 10,
      wordBreak: "break-word",
      textAlign: "center"
    },
    closeButton: {
      margin: 5
    },
    margin10: {
      margin: 10
    },
    divider: {
      height: "1px",
      border: "solid 1px #f2f2f2",
      padding: "0px !important",
      marginTop: 14,
      marginBottom: 14
    },
    paddingLeft5: {
      paddingLeft: 5
    }
  })
);
