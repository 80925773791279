import { Badge, IconButton, Tooltip } from "@mui/material";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import moment from "moment";
import React from "react";
import { getLoanType } from "../config/config";
import { AppraisalStatusToReadable } from "../create-loan/appraisal/util";
import { LoanProcessTypeEnum, TABLE_FUNDING, ADVANCE_FUNDING, } from "../utils/constants";
import { getFormatDate } from "./common";
import { FilterFunnelIcon } from "./MasterViewIcons";
import { sanitizeCurrency } from "../utils/formatChecks";
import { isString } from "../utils/utils";

const month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec"
];

const lookupData: any = {
  PRE: "Pre-Close",
  POST: "Post-Close",
  [LoanStatusEnum.DueDiligenceReview]: "Review In Progress",
  SFR: "Single Family Detached",
  [LoanStatusEnum.Submit]: LoanStatusEnum.DataEntry
};

export const DateFormatter = (props: {
  data: any;
  timeReq?: any;
  isLocalTime?: boolean;
  from?: string;
}) => {
  const { data, timeReq, isLocalTime, from } = props;
  if (!data) {
    return <span></span>;
  }
  let dateValue = getFormatDate(data, isLocalTime);
  let convertedDate;
  if (timeReq) {
    convertedDate = isLocalTime ? moment(data) : moment.utc(data);
    const hours = convertedDate.hours().toString().padStart(2, "0");
    const minutes = convertedDate.minutes().toString().padStart(2, "0");
    const seconds = convertedDate.seconds().toString().padStart(2, "0");
    dateValue = `${dateValue} ${hours}:${minutes}:${seconds}`;
  }
  return (
    <span
      style={{
        fontFamily: from === "drawRequest" ? "system-ui" : undefined
      }}
      title={dateValue}
    >
      {dateValue}
    </span>
  );
};
export const UTCDateFormatter = (props: { data: any }) => {
  const { data } = props;
  if (!data) {
    return <span></span>;
  }
  const date = moment.utc(data);
  const formattedDate = `${month[date.month()]} ${`0${date.date()}`.slice(
    -2
  )}, ${date.year()}`;
  return <span title={formattedDate}>{formattedDate}</span>;
};
export const LoanStageFormatter = (props: { data: any }) => {
  const { data } = props;
  const loanStage = lookupData[data.loanStage] || data.loanStatus;
  return <span title={loanStage}>{loanStage}</span>;
};
export const loanTypeFormatter = (props: any, classes: any) => {
  let displayValue = props?.loanType
    ? props.loanType?.split("L")?.[0]?.includes("30")
      ? props.actualLoanType
          ?.split("L")?.[0]
          ?.replace("30 Year", getLoanType[1].name)
          ?.replace(getLoanType[1].displayValue, getLoanType[1].name)
          ?.trim()
      : props.loanType
          ?.split("L")?.[0]
          ?.replace(getLoanType[1].displayValue, getLoanType[1].name)
          ?.trim()
    : "";
  if (props.toorakProduct) {
    if (props.toorakProduct.includes("30")) {
      displayValue = `${displayValue} - ${props.toorakProduct.replace(
        "30 Year",
        getLoanType[1].name
      )}`;
    } else {
      displayValue = `${displayValue} - ${props.toorakProduct}`;
    }
  }
  return (
    <>
      <div
        className={
          props?.column?.idx === 5
            ? classes.defaulttextWithMargin
            : classes.defaulttext
        }
        title={displayValue || ""}
      >
        <span>{displayValue}</span>
        <div className={classes.loanTypeTags}>
          {props?.fundingType === TABLE_FUNDING && (
            <Tooltip
              classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
              placement="right"
              arrow
              title="Table Fund Loan"
            >
              <span className={classes.fundingTag}>TF</span>
            </Tooltip>
          )}
          {props?.fundingType === ADVANCE_FUNDING && (
            <Tooltip
              classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
              placement="right"
              arrow
              title="Advance Funded"
            >
              <span className={classes.fundingTag}>AF</span>
            </Tooltip>
          )}
          {props?.isWarehouseFunded && (
            <Tooltip
              classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
              placement="right"
              arrow
              title="Warehouse Funded Loan"
            >
              <span className={classes.fundingTag}>WH</span>
            </Tooltip>
          )}
          {props?.loanProcessType === LoanProcessTypeEnum.Express && (
            <Tooltip
              classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
              placement="right"
              arrow
              title="Express Loan"
            >
              <span className={classes.expressTag}>EX</span>
            </Tooltip>
          )}
          {props?.loanProcessType === LoanProcessTypeEnum.Premier && (
            <Tooltip
              classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
              placement="right"
              arrow
              title="Premier Loan"
            >
              <span className={classes.premierTag}>PR</span>
            </Tooltip>
          )}
        </div>
      </div>
    </>
  );
};
export const filterMasterView = (props: any) => {
  const { anchorRef, open, isfilterApplied, classes, changeZIndex } = props;
  const handleToggle = (e: any) => {
    props.handleToggle();
    changeZIndex(e?.target);
  };
  return (
    <IconButton
      size="small"
      ref={anchorRef}
      aria-controls={open ? "menu-list-grow" : undefined}
      aria-haspopup="true"
      onClick={(e) => handleToggle(e)}
    >
      <Badge
        color="primary"
        variant="dot"
        invisible={!isfilterApplied}
        classes={{ badge: classes.badgeFilter }}
      >
        <FilterFunnelIcon filled={isfilterApplied} />
      </Badge>
    </IconButton>
  );
};
export const LoanStatusFormatter = (props: { data: any; classes: any }) => {
  const { data, classes } = props;
  const loanStatus =
    data.loanStatus === "Due Diligence Review"
      ? "Review In Progress"
      : data.loanStatus === "submit"
      ? "Submit"
      : data.loanStatus;
  return (
    <>
      <div className={classes.statusText} title={loanStatus}>
        {loanStatus}
      </div>
      {data.loanStatus === LoanStatusEnum.DueDiligenceReview && (
        <div className={classes.subStatusText} title={data.subStatus}>
          {data.subStatus}
        </div>
      )}
    </>
  );
};

export const valueFormatterForFilter = (item: any) => {
  const appraisalStatus = Object.keys(AppraisalStatusToReadable);
  if (["PRE", "POST", "Due Diligence Review", "SFR", "submit"].includes(item)) {
    // eslint-disable-next-line default-case
    switch (item) {
      case "PRE":
        return "Pre-Close";
      case "POST":
        return "Post-Close";
      case "Due Diligence Review":
        return "Review In Progress";
      case "SFR":
        return "Single Family Detached";
      case "submit":
        return "Submit";
    }
  }
  if (appraisalStatus.includes(item)) {
    return AppraisalStatusToReadable[item];
  }
  return item;
};

export const AmountFormatter = (props: { data: any; classes: any }) => {
  const { data, classes } = props;
  let valueString = data?.toString();
  if (isString(valueString) && valueString && !valueString.match(/[^$,.\d]/)) {
    valueString = sanitizeCurrency(valueString);
  }
  return (
    <span title={valueString} className={classes.defaulttextAmount}>
      {valueString}
    </span>
  );
};

export const PercentFormatter = (props: {
  data: any;
  classes: any;
  multiplyFactor?: number;
}) => {
  const { data, classes, multiplyFactor = 1 } = props;
  let valueString = data?.toString();
  if (![null, undefined, ""].includes(data) && !valueString.match(/[^%,.\d]/)) {
    const value = (Number(data) * multiplyFactor).toFixed(4);
    const paddedUnit = valueString.indexOf("%") === -1 ? "%" : "";
    valueString = `${value}${paddedUnit}`;
  }
  return (
    <span title={valueString} className={classes.defaulttextAmount}>
      {valueString}
    </span>
  );
};
