export enum CondtFilterGroupType {
  visiblity = "visiblity",
  waiver = "waiver",
  satisfied = "satisfied"
}

export const defaultWaiverState: any = [
  {
    value: "Select All",
    label: "Select All",
    checked: false,
    checkBoxDataTestId: "pre-slt-chx"
  },
  {
    label: "Waiver Requested",
    value: "",
    checked: false,
    checkBoxDataTestId: "w-requested-fltr-chx"
  },
  {
    label: "Waiver Approved",
    value: "APPROVED",
    checked: false,
    checkBoxDataTestId: "w-approved-fltr-chx"
  },
  {
    label: "Waiver Approve-Conditionally",
    value: "APPROVED-CONDITIONALLY",
    checked: false,
    checkBoxDataTestId: "w-condt-approved-fltr-chx"
  },

  {
    label: "Waiver Rebuttal",
    value: "REBUTTAL",
    checked: false,
    checkBoxDataTestId: "w-rebuttal-fltr-chx"
  },
  {
    label: "Waiver Rejected",
    value: "REJECTED",
    checked: false,
    checkBoxDataTestId: "w-rejected-fltr-chx"
  },
  {
    label: "Waiver Rescinded",
    value: "RESCIND",
    checked: false,
    checkBoxDataTestId: "w-rescind-fltr-chx"
  }
];

export const defaultVisiblityState: any = [
  {
    value: "Select All",
    label: "Select All",
    checked: false,
    checkBoxDataTestId: "visiblity-chx"
  },
  {
    value: "internal",
    label: "Only Toorak",
    checked: false,
    checkBoxDataTestId: "visiblity-ext-chx"
  },
  {
    value: "externalAndInternal",
    label: "Toorak & Originator",
    checked: false,
    checkBoxDataTestId: "visiblity-int&ext-chx"
  }
];

export const defaultSatisfiedState: any = [
  {
    value: "Select All",
    label: "Select All",
    checked: false,
    checkBoxDataTestId: "satisfied-selectall-state"
  },
  {
    value: "Satisfied Condition",
    label: "Satisfied Condition",
    checked: false,
    checkBoxDataTestId: "satisfied-condt-state"
  },
  {
    value: "Satisfied Inquiry",
    label: "Satisfied Inquiry",
    checked: false,
    checkBoxDataTestId: "satisfied-inq-state"
  }
];
