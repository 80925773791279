import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
// import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import { useStyles } from "../../../create-loan/documents/DocumentsForm.style";
import { getInternalTags } from "../../../stores/ILP/ILP.action";
import { useNavigate, useParams } from "react-router-dom";
import { tabStatus } from "../../../create-loan/ttf-review/constants";
import { NoDocumentsModal } from "../../../create-loan/documents/NoDocumentsModal";
import { ServicerOnboardingStore } from "../../../stores/servicerOnboarding/servicerOnboarding.interface";
import { RootState } from "../../../stores/rootReducer";
import {
  AssetManagementRolesEnum,
  URLParamsType
} from "../../../utils/constants";
import { isRole } from "../../../utils/AccessManagement";

export interface LoanStatusInterface {
  missingFieldList: string[];
  loanTypeAllowed: string;
}

export function LoanDetailsStatusResult(props: LoanStatusInterface) {
  const navigate = useNavigate();
  const classes = useStyles();
  const { missingFieldList, loanTypeAllowed } = props;
  const infoBoxDisplay = true;
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);
  const { servicerDocsDetails } = useSelector<
    RootState,
    ServicerOnboardingStore
  >((state) => state.servicerOnboardingStore);
  const {
    tagListBox,
    documentsContainer,
    preCloseDocs,
    rowFlex,
    docsListBox,
    hrTagList,
    docsListContainer,
    d_none,
    ml_auto,
    tagBadge,
    fss_normal,
    tagText
  } = classes;

  const { loanId, loanStage, dataReconcile } =
    useParams<URLParamsType>() as any;
  const { onBoardingId } = useParams<{
    onBoardingId: string;
  }>() as any;
  const dispatch = useDispatch();

  const getDocsStatus = (review: any) => {
    return (
      <span
        key={review.name}
        className={`${tagBadge} `}
        style={
          review.status === tabStatus.COMPLETED
            ? { backgroundColor: "green", borderRadius: 20 }
            : review.status === tabStatus.PENDING
            ? { backgroundColor: "orange", borderRadius: 20 }
            : { borderRadius: 20 }
        }
      >
        <span className={`${fss_normal} ${tagText}`}>
          {review.status.toUpperCase()}
        </span>
      </span>
    );
  };

  const handleCloseWarningDialog = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleReviewFields = () => {
    /**
     * 1000019968
     * 1100125062
     * serviceronboarding/loan/BridgeLoan/POST/1000019968/
     */
    if (servicerDocsDetails?.length === 0) {
      setIsConfirmationModalOpen(true);
      return;
    }
    dispatch(getInternalTags());
    // setTimeout(() => {
    navigate(
      `/serviceronboarding/loan/${loanTypeAllowed}/${loanStage}/${loanId}/${onBoardingId}/${dataReconcile}/documentReview`
    );
    // }, 500);
  };

  return (
    <div className={`${tagListBox} ${ml_auto}`}>
      <div className={documentsContainer}>
        <NoDocumentsModal
          isConfirmationModalOpen={isConfirmationModalOpen}
          handleCloseWarningDialog={handleCloseWarningDialog}
        />
        <hr className={infoBoxDisplay ? `${hrTagList}` : `${d_none}`}></hr>
        {isRole(AssetManagementRolesEnum.AM_SO) && (
          <Button
            variant="contained"
            style={{ marginLeft: 15 }}
            onClick={handleReviewFields}
            color="info"
          >
            Document Review
          </Button>
        )}
        <hr />
        {missingFieldList?.length > 0 ? (
          missingFieldList.map((status: any, index: number) => {
            return (
              <div key={index} className={docsListBox}>
                <div className={`${docsListContainer}`}>
                  <div
                    className={rowFlex}
                    style={{ justifyContent: "space-between" }}
                  >
                    <span className={`${preCloseDocs} ${rowFlex}`}>
                      {status.name}
                    </span>
                    {getDocsStatus(status)}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <span>{"Loading..."}</span>
        )}
      </div>
    </div>
  );
}
