import React, { useEffect, useRef, useState } from "react";
import { getLoansList } from "../../network/apiService";
import { useDebounce } from "../../custom-hooks/useDebounce";
import { SearchBarForMobile } from "../loan-lists/LoanListSubHeader/SearchBarForMobile";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { columnSourceMap } from "../loan-lists/utils/columnSource";
import { ObjectType } from "../../masterView/common";
import { getConfig, getLoanType } from "../../config/config";
import Table from "../../masterView/CustomTable";
import { ClickAwayListener, TableContainer, Card, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useLazyScroll from "../../custom-hooks/useLazyScroll";
import { isILP, isRole } from "../../utils/AccessManagement";
import { UserRole } from "@toorak/tc-common-fe-sdk";
import { statusFormatterNormal } from "../loan-lists/utils/commonStatusFormatter";

export const useStyles = makeStyles(() => ({
    loanIdText: {
        color: "#172b4d",
        fontSize: 12,
        // fontWeight: "bold",
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
            color:'#5566d6'
        },
        marginRight: "1rem"
    },
    noBorder: {
        border: "0px",
        padding: "0px 8px"
    },
    rowHover: {
        "&:hover": {
            background: "#eef6ff !important"
        }
    },
    stickyTopZero: {
        position: "sticky",
        top: 0,
        zIndex: 3
    },
    boxTableContainer: {
        position: "absolute",
        zIndex: 1,
        transform: "translateX(-100%)",
        marginTop: "0.4rem",
        width: "max-content",
        maxHeight: "250px",
        overflowY: "auto"
    },
    customHoverFocus: {
        color: "#172b4d",
    "&:hover, &.Mui-focusVisible": { 
        color: "#5566d6",
        backgroundColor: 'transparent'
    }
  }
}));

const getWithMatch = (loanString: string, searchText: string)=>{
 let temp = loanString.replaceAll(searchText.toUpperCase(), `$${searchText.toUpperCase()}$`)
 temp = temp.replaceAll(searchText, `$${searchText}$`)
 return temp.split('$').filter(it => it);
}

const cols = (openLoan: Function, redirect: Function, classes: any, searchValue:string) => {
    return [
        {
            id: "loanId",
            title: "Toorak Loan ID / Originator Loan ID",
            width: "240px",
            renderCustom: (data: any) => {
                const stringArray = getWithMatch(`${data?.loanId} / ${data?.primaryLoanId}`, searchValue);
                return <div className={classes.loanIdText} onClick={() => openLoan(data)}>
                    {
                        stringArray.map((it)=>{
                            return <span style={{fontWeight: it.toLowerCase() === searchValue.toLowerCase() ? 'bold': 'normal'}}>{it}</span>
                        })
                    }
                </div>;
            },
            rowClass: classes.noBorder
        },
        {
            id: "loanStatus",
            title: "Status",
            width: "150px",
             renderCustom: (data: any) => {
                return <span style={{textTransform:'capitalize'}}>{data.loanStatus}</span>
            },
            rowClass: classes.noBorder
        },
        {
            id: "loanStage",
            title: "",
            width: "100px",
            renderCustom: (data: any) => {
                return <IconButton className={classes.customHoverFocus} style={{ transform: "translateX(50%)" }} onClick={() => redirect(data)} > <OpenInNewIcon/> </IconButton>;
            },
            rowClass: classes.noBorder
        }
    ];
};

export const SearchBar = () => {
    const classes = useStyles();
    const scrollRef = useRef<HTMLDivElement>(null);
    const [searchValue, setSearchValue] = useState<string>("");
    const searchInput = useRef<HTMLDivElement>(null);
    const [options, setOptions] = useState<ObjectType[]>([]);
    const [openOptions, setOpenOptions] = useState(false);
    const debounceSearchValue: string = useDebounce<string>(searchValue, 500);

    const transformResponse = (data: ObjectType[]) => {
        return data?.map((loanData: ObjectType) => {
            return {
                loanId: loanData?._source?.loan?.loanDetailId?.loanId,
                primaryLoanId: loanData?._source?.loan?.loanInfo?.primaryLoanId,
                loanStatus: statusFormatterNormal({loanStageRaw:loanData?._source?.loan?.loanStage, status: loanData?._source?.loan?.loanState}).toLowerCase(),
                loanStage: loanData?._source?.loan?.loanStage,
                loanType: getLoanType[0].name === loanData?._source?.loan?.loanType ? getLoanType[0].displayValue : getLoanType[1].displayValue
            }
        })
    }

    const onScroll = () => {
        getList(debounceSearchValue, options.length).then((resp: any) => {
            const list = transformResponse(resp.data.response.hits.hits);
            setOptions([...options, ...list]);
            setOpenOptions(true);
        })
    }

    function getList(
        globalSearchKey: string,
        index: number = 0
    ) {
        let accountIds: any = localStorage.getItem("account_ids");
        accountIds = JSON.parse(accountIds);
        let mustArray: any = [
            {
                "bool": {
                    "should": [
                        {
                            multi_match: {
                                analyzer: "standard",
                                fields: ["loan.loanDetailId.toorakLoanId",
                                    "originator.accountName",
                                    "loan.loanInfo.primaryLoanId",
                                    "loan.loanDetailId.tradeId",
                                    "guarantorDetails.customer.firstName",
                                    "guarantorDetails.customer.lastName",
                                    "loan.loanCreatedUser.lastName",
                                    "loan.loanCreatedUser.firstName",
                                    "borrowerDetails.customer.lastName",
                                    "borrowerDetails.customer.firstName"],
                                query: globalSearchKey,
                                type: "phrase_prefix"
                            }
                        },
                        {
                            nested: {
                                path: "properties",
                                query: {
                                    multi_match: {
                                        query: globalSearchKey,
                                        fields: [
                                            "properties.propertyLocation.city",
                                            "properties.propertyLocation.addressLine1",
                                            "properties.propertyLocation.state",
                                            "properties.propertyLocation.postalCode"
                                        ],
                                        type: "phrase_prefix",
                                        analyzer: "standard"
                                    }
                                }
                            }
                        }
                    ]
                }
            }
        ];
        if (!isRole(UserRole.LAT_TREASURER)) {
            mustArray.push({
                terms: {
                    "loan.loanDetailId.originatorPartyId.raw": accountIds
                }
            });
        }
        let requestBody = {
            query: {
                bool: {
                    must: mustArray
                }
            },
            _source: [
                ...columnSourceMap.toorakLoanId,
                ...columnSourceMap.status,
                columnSourceMap.loanStage,
                "properties.propertyLocation.city",
                "properties.propertyLocation.addressLine1",
                "properties.propertyLocation.state",
                "properties.propertyLocation.postalCode"
            ],
            sort: [
                {
                    updatedOn: "desc"
                }
            ],
            aggs: {},
            from: index,
            size: 20
        };
        return getLoansList(requestBody, false, false);
    }

    useEffect(() => {
        if (!debounceSearchValue || debounceSearchValue.length < 3) return;
        getList(debounceSearchValue, 0).then((resp: any) => {
            setOptions(transformResponse(resp.data.response.hits.hits));
            setOpenOptions(true);
        })
    }, [debounceSearchValue]);

    const redirect = (data: ObjectType) => {
        const base = isILP() ? 'internal/' : "";
        window.open(
            `${getConfig().redirectUrl
            }${base}loan/createLoan/${data.loanType}/${data.loanStage}/${data.loanId}`
        );
    };

    const openLoan = (data: ObjectType) => {
        const base = isILP() ? 'internal/' : "";
        window.open(
            `${getConfig().redirectUrl
            }${base}loan/createLoan/${data.loanType}/${data.loanStage}/${data.loanId}`,
            '_self'
        );
    };

    const handleClose = () => {
        setOpenOptions(false);
    };

    const handleOpen = () => {
        setOpenOptions(true)
    };
    const columns = cols(openLoan, redirect, classes, searchValue);
    useLazyScroll(scrollRef, onScroll, true);

    return (
        <div style={{ 'width':'100%' }}>
            <SearchBarForMobile
                setInputValue={setSearchValue}
                inputValue={searchValue}
                searchInput={searchInput}
                handleSearch={handleOpen}
                clearSearch={() => setSearchValue("")}
                customClassName="inputSearch"
                fullWidth={false}
                placeHolder='Search'
                handleKeyPress={()=>{}}
            />
            {options.length > 0 && openOptions && (
                <ClickAwayListener onClickAway={handleClose}>
                    <div style={{
                        position: "relative",
                        float: "right"
                    }}>
                        <TableContainer
                            ref={scrollRef}
                            component={Card}
                            className={classes?.boxTableContainer}
                        >
                            <Table
                                rowsData={options}
                                columnsData={columns}
                                classes={classes}
                            />
                        </TableContainer>
                    </div>
                </ClickAwayListener>
            )
            }
        </div>
    )
}