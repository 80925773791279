/* eslint-disable no-param-reassign */
// import { produce } from "immer";
import { createSlice } from "@reduxjs/toolkit";
import { VideoConfigType } from "../../helpMenu/videoPlayer/VideoConfig";
// import {
//   HELP_MENU_CLICKED,
//   CONTACT_BUTTON_CLICKED,
//   SELECT_VIDEO,
//   CLEAR_SELECTED_VIDEO
// } from "./helpMenu.action";

export interface HelpMenuReducerType {
  helpMenuClicked: boolean;
  contactButtonClicked: boolean;
  selectedVideoConfigs: VideoConfigType | null;
}
const initialState: HelpMenuReducerType = {
  helpMenuClicked: false,
  contactButtonClicked: false,
  selectedVideoConfigs: null
};

const helpMenuSlice = createSlice({
  name: "helpMenu",
  initialState,
  reducers: {
    toggleHelpMenu: (state) => {
      state.helpMenuClicked = !state.helpMenuClicked;
    },
    toggleContactButton: (state) => {
      state.contactButtonClicked = !state.contactButtonClicked;
    },
    setSelectedVideo: (state, action) => {
      const { configs } = action.payload;
      state.selectedVideoConfigs = configs;
    },
    clearSelectedVideo: (state) => {
      state.selectedVideoConfigs = null;
    }
  }
});

export const {
  toggleHelpMenu,
  toggleContactButton,
  setSelectedVideo,
  clearSelectedVideo
} = helpMenuSlice.actions;

export const HelpMenuReducer = helpMenuSlice.reducer;

// export const HelpMenuReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case HELP_MENU_CLICKED: {
//       const nextState = produce(state, draftState => {
//         draftState.helpMenuClicked = !state.helpMenuClicked;
//       });
//       return nextState;
//     }
//     case CONTACT_BUTTON_CLICKED: {
//       const nextState = produce(state, draftState => {
//         draftState.contactButtonClicked = !state.contactButtonClicked;
//       });
//       return nextState;
//     }
//     case SELECT_VIDEO: {
//       const { configs } = action.payload;
//       const nextState = produce(state, draftState => {
//         draftState.selectedVideoConfigs = configs;
//       });
//       return nextState;
//     }
//     case CLEAR_SELECTED_VIDEO: {
//       const nextState = produce(state, draftState => {
//         draftState.selectedVideoConfigs = null;
//       });
//       return nextState;
//     }
//     default:
//       return state;
//   }
// };
