import React from "react";
import { Grid, IconButton, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import HistoryIcon from "@mui/icons-material/HistoryOutlined"
import { InfoOnlyTooltip } from "../../utils/Tooltip";
import { getConfig } from "../../config/config";

export interface Props {
	path: String;
	waiverOnException?: any;
	isGeneralWaiver?: boolean;
	stylePadding?:any;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		rescindIcon: {
			width: "32px",
			height: "32px",
			padding: "6px",
			borderRadius: "4px",
			boxShadow: "0 1px 3px 0 var(--black-22)",
			border: "solid 1px #ececec",
			backgroundColor: "var(--white)",
		}
	})
)

export function ExceptionCardRescindIcon(props: Props) {
	const { waiverOnException, path,isGeneralWaiver,stylePadding } = props;
	const classes = useStyles();
	let newPath = isGeneralWaiver ? path : `${getConfig().redirectUrl}${path}?isRescindView=true`
	return (
		(isGeneralWaiver || (waiverOnException?.length && waiverOnException.filter((item: any) => item.waiverInfo?.finalResponse === "RESCIND").length)) ?
			(
				<Grid item style={stylePadding}>

					<InfoOnlyTooltip
						title="Rescind Waiver History"
						arrow
						placement="left"
					>
						<span>
							<IconButton
								aria-label="actions"
								component="button"
								aria-haspopup="true"
								onClick={() => {
									window.open(`${newPath}`)
								}}
								// disabled={dropDownDisabled}
								className={classes.rescindIcon}
								size="large"
							>
								<HistoryIcon fontSize="large" />

							</IconButton>
						</span>
					</InfoOnlyTooltip>
				</Grid>
			) : null
	);
}
