import { Grid, IconButton, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const headerStyles = makeStyles((theme: Theme) => ({
  badge: {
    color: "#172b4d",
    backgroundColor: "#d9efff",
    padding: "4px 6px",
    borderRadius: "6px",
    fontSize: "10px",
    fontWeight: "bold"
  },
  rightSide: {
    display: "flex",
    alignItems: "center"
  },
  header: {
    color: "#32325d",
    fontSize: "14px",
    fontWeight: 600
  }
}));

export const CommentHeader = (props: {
  onClose: Function;
  badgeText: string;
  chatCount: number;
}) => {
  const { badgeText, onClose, chatCount } = props;
  const classes = headerStyles();
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <span className={classes.header}>Comments ({chatCount})</span>
      </Grid>
      <Grid item>
        <Grid container alignItems="center">
          <Grid item>
            <span className={classes.badge}>{badgeText}</span>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              onClick={(e: any) => onClose()}
              component="button"
              size="large">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
